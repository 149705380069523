<template>
    <div>
        <div class="content-load" v-if="is_loading_chat"></div>
        <div id="loader" v-if="is_loading_chat"></div>

        <notifications group="foo" position="top right" />
        <div class="modal-vue">
            <div class="overlay" v-if="showModal"></div>
            <div class="modal-body-chat" v-if="showModal">
                <div class="close-chat">
                    <a
                        href="javascript:void(0)"
                        @click="showModal = false"
                        class="text-primary size-24"
                    >
                        <i class="fa fa-times-circle"></i>
                    </a>
                </div>
                <!-- <div class="d-flex bd-highlight">
                    <div class="bd-highlight">
                        <h4>
                            Upload Gambar
                        </h4>
                    </div>
                    <div class="ms-auto bd-highlight">
                        <a href="javascript:void(0)" @click="showModal = false" class="text-primary size-24">
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div> -->
                <!-- <div class="line"></div>
                <input type="file" name="file" @change="uploadFile" ref="file">
                <div class="image-preview-chat">
                    <center>
                        <img class="preview" :src="imageData" v-if="imageData.length > 0">
                        <img src="../assets/no-photo.png" class="preview" v-else>
                    </center>
                </div> -->
                <div
                    class="upload-image-chat mt-5"
                    :style="{
                        'background-image': 'url(' + req.upload_image + ')',
                    }"
                >
                    <div class="mt-4 ml-4">
                        <center>
                            <label
                                for="upload_image"
                                class="upload-button single-image chat"
                            >
                                <input
                                    type="file"
                                    id="upload_image"
                                    @change="uploadImage()"
                                />
                                <i class="fa fa-upload"></i>
                            </label>
                        </center>
                    </div>
                </div>
                <div class="mt-3">
                    <input
                        type="text"
                        class="form-control chat-form"
                        placeholder="Tuliskan Pesanmu"
                        v-model="req.message"
                        :disabled="is_changing_upload_image == false"
                    />
                </div>
                <div class="mt-4 row">
                    <div class="col-md-12">
                        <button
                            class="btn btn-green w-100 btn-lg"
                            type="button"
                            disabled
                            v-if="is_submit_file"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Kirim
                        </button>
                        <button
                            class="btn btn-green w-100 btn-lg"
                            :disabled="is_changing_upload_image == false"
                            @click="submitFile(chat.room_id)"
                            v-else
                        >
                            <i class="fa fa-send me-1"></i>
                            Kirim
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <section class="message-area">
            <div class="chat-area">
                <div class="chatlist">
                    <div class="modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="mt-4 mb-4">
                                <div class="d-flex align-items-center"></div>
                                <ul class="nav nav-pills mt-2" role="tablist">
                                    <li class="nav-item">
                                        <a
                                            class="nav-link text-center active"
                                            data-bs-toggle="pill"
                                            href="#home"
                                            @click="filterChat('all')"
                                        >
                                            <svg
                                                class="icon-32"
                                                width="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M17.857 20.417C19.73 20.417 21.249 18.899 21.25 17.026V17.024V14.324C20.013 14.324 19.011 13.322 19.01 12.085C19.01 10.849 20.012 9.846 21.249 9.846H21.25V7.146C21.252 5.272 19.735 3.752 17.862 3.75H17.856H6.144C4.27 3.75 2.751 5.268 2.75 7.142V7.143V9.933C3.944 9.891 4.945 10.825 4.987 12.019C4.988 12.041 4.989 12.063 4.989 12.085C4.99 13.32 3.991 14.322 2.756 14.324H2.75V17.024C2.749 18.897 4.268 20.417 6.141 20.417H6.142H17.857Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M12.3711 9.06303L12.9871 10.31C13.0471 10.432 13.1631 10.517 13.2981 10.537L14.6751 10.738C15.0161 10.788 15.1511 11.206 14.9051 11.445L13.9091 12.415C13.8111 12.51 13.7671 12.647 13.7891 12.782L14.0241 14.152C14.0821 14.491 13.7271 14.749 13.4231 14.589L12.1921 13.942C12.0711 13.878 11.9271 13.878 11.8061 13.942L10.5761 14.589C10.2711 14.749 9.91609 14.491 9.97409 14.152L10.2091 12.782C10.2321 12.647 10.1871 12.51 10.0891 12.415L9.09409 11.445C8.84809 11.206 8.98309 10.788 9.32309 10.738L10.7001 10.537C10.8351 10.517 10.9521 10.432 11.0121 10.31L11.6271 9.06303C11.7791 8.75503 12.2191 8.75503 12.3711 9.06303Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                            </svg>
                                            <div class="mt-1">All Chat</div>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            class="nav-link text-center"
                                            data-bs-toggle="pill"
                                            href="#menu1"
                                            @click="filterChat('ongoing')"
                                        >
                                            <svg
                                                class="icon-32"
                                                width="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M14.7364 2.76175H8.0844C6.0044 2.75375 4.3004 4.41075 4.2504 6.49075V17.2277C4.2054 19.3297 5.8734 21.0697 7.9744 21.1147C8.0114 21.1147 8.0484 21.1157 8.0844 21.1147H16.0724C18.1624 21.0407 19.8144 19.3187 19.8024 17.2277V8.03775L14.7364 2.76175Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M14.4746 2.75V5.659C14.4746 7.079 15.6236 8.23 17.0436 8.234H19.7976"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M11.6406 9.90869V15.9497"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M13.9864 12.2642L11.6414 9.90918L9.29639 12.2642"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                            </svg>
                                            <div class="mt-1">On - Going</div>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            class="nav-link text-center"
                                            data-bs-toggle="pill"
                                            href="#menu2"
                                            @click="filterChat('complete')"
                                        >
                                            <svg
                                                class="icon-32"
                                                width="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M11.9846 21.606C11.9846 21.606 19.6566 19.283 19.6566 12.879C19.6566 6.474 19.9346 5.974 19.3196 5.358C18.7036 4.742 12.9906 2.75 11.9846 2.75C10.9786 2.75 5.26557 4.742 4.65057 5.358C4.03457 5.974 4.31257 6.474 4.31257 12.879C4.31257 19.283 11.9846 21.606 11.9846 21.606Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M9.38574 11.8746L11.2777 13.7696L15.1757 9.86963"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                            </svg>
                                            <div class="mt-1">Complete</div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content mt-4">
                                <div class="tab-pane active" id="home">
                                    <div class="scroll">
                                        <div class="chat-list">
                                            <template v-if="allChat.length > 0">
                                                <hr />
                                                <template
                                                    v-for="all in allChat"
                                                >
                                                    <div :key="all.room_id">
                                                        <a
                                                            href="javascript:void(0)"
                                                            @click="
                                                                findChat(
                                                                    all.room_id
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-bind:class="{
                                                                    actives:
                                                                        all.room_id ==
                                                                        chat?.room_id,
                                                                }"
                                                                class="line-bottom"
                                                            >
                                                                <div
                                                                    class="d-flex align-items-center ms-3"
                                                                >
                                                                    <div
                                                                        class="flex-shrink-0"
                                                                    >
                                                                        <template
                                                                            v-if="
                                                                                all
                                                                                    .header
                                                                                    .avatar ==
                                                                                null
                                                                            "
                                                                        >
                                                                            <div
                                                                                class="user-image"
                                                                                :style="{
                                                                                    backgroundImage: `url(${require('../assets/user.jpg')})`,
                                                                                }"
                                                                            ></div>
                                                                        </template>
                                                                        <template
                                                                            v-else
                                                                        >
                                                                            <div
                                                                                class="user-image"
                                                                                :style="{
                                                                                    'background-image':
                                                                                        'url(' +
                                                                                        all
                                                                                            .header
                                                                                            .avatar +
                                                                                        ')',
                                                                                }"
                                                                            ></div>
                                                                        </template>
                                                                    </div>
                                                                    <div
                                                                        class="flex-grow-1 ms-3"
                                                                    >
                                                                        <div
                                                                            class="d-flex"
                                                                        >
                                                                            <h3
                                                                                class="title-chat"
                                                                                :class="{
                                                                                    'fw-ekstra-bold':
                                                                                        all
                                                                                            .last_message
                                                                                            .read_by_user ==
                                                                                        null,
                                                                                }"
                                                                            >
                                                                                {{
                                                                                    all.header.name
                                                                                }}
                                                                            </h3>
                                                                            <div
                                                                                class="size-13 ms-auto gray-time me-3"
                                                                            >
                                                                                {{
                                                                                    all.last_message.time_parse
                                                                                }}
                                                                            </div>
                                                                        </div>
                                                                        <p
                                                                            class="chat-limit"
                                                                            v-bind:class="{
                                                                                'text-dark fw-bold':
                                                                                    all
                                                                                        .last_message
                                                                                        .read_by_user ==
                                                                                    null,
                                                                            }"
                                                                        >
                                                                            <template
                                                                                v-if="
                                                                                    all
                                                                                        .last_message
                                                                                        .message !=
                                                                                    null
                                                                                "
                                                                            >
                                                                                <span
                                                                                    class="size-12"
                                                                                >
                                                                                    {{
                                                                                        all
                                                                                            .last_message
                                                                                            .message
                                                                                    }}
                                                                                </span>
                                                                            </template>
                                                                            <template
                                                                                v-if="
                                                                                    all
                                                                                        .last_message
                                                                                        .attachment !=
                                                                                    null
                                                                                "
                                                                            >
                                                                                <span
                                                                                    class="size-12"
                                                                                >
                                                                                    <i
                                                                                        class="fa fa-camera me-1"
                                                                                    ></i>
                                                                                    Photo
                                                                                </span>
                                                                            </template>
                                                                            <template
                                                                                v-if="
                                                                                    all
                                                                                        .last_message
                                                                                        .attachment_data !=
                                                                                    null
                                                                                "
                                                                            >
                                                                                <i
                                                                                    class="fa fa-camera me-1"
                                                                                ></i>
                                                                                <span
                                                                                    class="size-12"
                                                                                    v-if="
                                                                                        all
                                                                                            .last_message
                                                                                            .attachment_data
                                                                                            .message &&
                                                                                        all
                                                                                            .last_message
                                                                                            .attachment_data
                                                                                            .message !=
                                                                                            'null'
                                                                                    "
                                                                                >
                                                                                    {{
                                                                                        all
                                                                                            .last_message
                                                                                            .attachment_data
                                                                                            .message
                                                                                    }}
                                                                                </span>
                                                                                <span
                                                                                    class="size-12"
                                                                                    v-else
                                                                                >
                                                                                    -
                                                                                </span>
                                                                            </template>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <div
                                                    class="text-center mt-4 size-16 fw-bold"
                                                >
                                                    <div class="mb-2">
                                                        <i
                                                            class="fa fa-wechat size-24"
                                                        ></i>
                                                    </div>
                                                    Tidak Ada Chat
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade mt-3" id="menu1">
                                    <div class="scroll">
                                        <div class="chat-list">
                                            <!--
                                            <template v-if="lists.length > 0">
                                                <hr />
                                                <template v-for="list in lists">
                                                    <div :key="list.room_id">
                                                        <a
                                                            href="javascript:void(0)"
                                                            @click="
                                                                findChat(
                                                                    list.room_id
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-bind:class="{
                                                                    actives:
                                                                        list.room_id ==
                                                                        chat?.room_id,
                                                                }"
                                                                class="line-bottom"
                                                            >
                                                                <div
                                                                    class="d-flex align-items-center ms-3"
                                                                >
                                                                    <div
                                                                        class="flex-shrink-0"
                                                                    >
                                                                        <template
                                                                            v-if="
                                                                                list
                                                                                    .header
                                                                                    .avatar ==
                                                                                null
                                                                            "
                                                                        >
                                                                            <div
                                                                                class="user-image"
                                                                                :style="{
                                                                                    backgroundImage: `url(${require('../assets/user.jpg')})`,
                                                                                }"
                                                                            ></div>
                                                                        </template>
                                                                        <template
                                                                            v-else
                                                                        >
                                                                            <div
                                                                                class="user-image"
                                                                                v-bind:style="{
                                                                                    'background-image':
                                                                                        'url(' +
                                                                                        list
                                                                                            .header
                                                                                            .avatar +
                                                                                        ')',
                                                                                }"
                                                                            ></div>
                                                                        </template>
                                                                    </div>
                                                                    <div
                                                                        class="flex-grow-1 ms-3"
                                                                    >
                                                                        <div
                                                                            class="d-flex"
                                                                        >
                                                                            <h3
                                                                                class="title-chat"
                                                                            >
                                                                                {{
                                                                                    list
                                                                                        .header
                                                                                        .name
                                                                                }}
                                                                            </h3>
                                                                            <div
                                                                                class="size-13 ms-auto gray-time me-3"
                                                                            >
                                                                                {{
                                                                                    moment(
                                                                                        list
                                                                                            .last_message
                                                                                            .time_parse
                                                                                    ).format(
                                                                                        "HH:mm"
                                                                                    )
                                                                                }}
                                                                            </div>
                                                                        </div>
                                                                        <p
                                                                            class="chat-limit"
                                                                        >
                                                                            <template
                                                                                v-if="
                                                                                    list
                                                                                        .last_message
                                                                                        .message !=
                                                                                    null
                                                                                "
                                                                            >
                                                                                <span
                                                                                    class="size-12"
                                                                                >
                                                                                    {{
                                                                                        list
                                                                                            .last_message
                                                                                            .message
                                                                                    }}
                                                                                </span>
                                                                            </template>
                                                                            <template
                                                                                v-if="
                                                                                    list
                                                                                        .last_message
                                                                                        .attachment !=
                                                                                    null
                                                                                "
                                                                            >
                                                                                <span
                                                                                    class="size-12"
                                                                                >
                                                                                    <i
                                                                                        class="fa fa-camera me-1"
                                                                                    ></i>
                                                                                    Photo
                                                                                </span>
                                                                            </template>
                                                                            <template
                                                                                v-if="
                                                                                    list
                                                                                        .last_message
                                                                                        .attachment_data !=
                                                                                    null
                                                                                "
                                                                            >
                                                                                <i
                                                                                    class="fa fa-camera me-1"
                                                                                ></i>
                                                                                <span
                                                                                    class="size-12"
                                                                                    v-if="
                                                                                        list
                                                                                            .last_message
                                                                                            .attachment_data
                                                                                            .message &&
                                                                                        list
                                                                                            .last_message
                                                                                            .attachment_data
                                                                                            .message !=
                                                                                            'null'
                                                                                    "
                                                                                >
                                                                                    {{
                                                                                        list
                                                                                            .last_message
                                                                                            .attachment_data
                                                                                            .message
                                                                                    }}
                                                                                </span>
                                                                                <span
                                                                                    class="size-12"
                                                                                    v-else
                                                                                >
                                                                                    -
                                                                                </span>
                                                                            </template>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </template>
                                            </template>
                                            -->
                                            <!-- <template v-else> -->
                                                <div
                                                    class="text-center mt-4 size-16 fw-bold"
                                                >
                                                    <div class="mb-2">
                                                        <i
                                                            class="fa fa-wechat size-24"
                                                        ></i>
                                                    </div>
                                                    Tidak Ada Chat
                                                </div>
                                            <!-- </template> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade mt-3" id="menu2">
                                    <div class="scroll">
                                        <div class="chat-list">
                                            <!--
                                            <template
                                                v-if="complete_list.length > 0"
                                            >
                                                <hr />
                                                <template
                                                    v-for="list in complete_list"
                                                >
                                                    <div :key="list.room_id">
                                                        <a
                                                            href="javascript:void(0)"
                                                            @click="
                                                                findChat(
                                                                    list.room_id
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-bind:class="{
                                                                    actives:
                                                                        list.room_id ==
                                                                        chat?.room_id,
                                                                }"
                                                                class="line-bottom"
                                                            >
                                                                <div
                                                                    class="d-flex align-items-center ms-3"
                                                                >
                                                                    <div
                                                                        class="flex-shrink-0"
                                                                    >
                                                                        <template
                                                                            v-if="
                                                                                list
                                                                                    .header
                                                                                    .avatar ==
                                                                                null
                                                                            "
                                                                        >
                                                                            <div
                                                                                class="user-image"
                                                                                :style="{
                                                                                    backgroundImage: `url(${require('../assets/user.jpg')})`,
                                                                                }"
                                                                            ></div>
                                                                        </template>
                                                                        <template
                                                                            v-else
                                                                        >
                                                                            <div
                                                                                class="user-image"
                                                                                v-bind:style="{
                                                                                    'background-image':
                                                                                        'url(' +
                                                                                        list
                                                                                            .header
                                                                                            .avatar +
                                                                                        ')',
                                                                                }"
                                                                            ></div>
                                                                        </template>
                                                                    </div>
                                                                    <div
                                                                        class="flex-grow-1 ms-3"
                                                                    >
                                                                        <div
                                                                            class="d-flex"
                                                                        >
                                                                            <h3
                                                                                class="title-chat"
                                                                            >
                                                                                {{
                                                                                    list
                                                                                        .header
                                                                                        .name
                                                                                }}
                                                                            </h3>
                                                                            <div
                                                                                class="size-13 ms-auto gray-time me-3"
                                                                            >
                                                                                {{
                                                                                    moment(
                                                                                        list
                                                                                            .last_message
                                                                                            .time_parse
                                                                                    ).format(
                                                                                        "HH:mm"
                                                                                    )
                                                                                }}
                                                                            </div>
                                                                        </div>
                                                                        <p
                                                                            class="chat-limit"
                                                                        >
                                                                            <template
                                                                                v-if="
                                                                                    list
                                                                                        .last_message
                                                                                        .message !=
                                                                                    null
                                                                                "
                                                                            >
                                                                                <span
                                                                                    class="size-12"
                                                                                >
                                                                                    {{
                                                                                        list
                                                                                            .last_message
                                                                                            .message
                                                                                    }}
                                                                                </span>
                                                                            </template>
                                                                            <template
                                                                                v-if="
                                                                                    list
                                                                                        .last_message
                                                                                        .attachment !=
                                                                                    null
                                                                                "
                                                                            >
                                                                                <span
                                                                                    class="size-12"
                                                                                >
                                                                                    <i
                                                                                        class="fa fa-camera me-1"
                                                                                    ></i>
                                                                                    Photo
                                                                                </span>
                                                                            </template>
                                                                            <template
                                                                                v-if="
                                                                                    list
                                                                                        .last_message
                                                                                        .attachment_data !=
                                                                                    null
                                                                                "
                                                                            >
                                                                                <i
                                                                                    class="fa fa-camera me-1"
                                                                                ></i>
                                                                                <span
                                                                                    class="size-12"
                                                                                    v-if="
                                                                                        list
                                                                                            .last_message
                                                                                            .attachment_data
                                                                                            .message &&
                                                                                        list
                                                                                            .last_message
                                                                                            .attachment_data
                                                                                            .message !=
                                                                                            'null'
                                                                                    "
                                                                                >
                                                                                    {{
                                                                                        list
                                                                                            .last_message
                                                                                            .attachment_data
                                                                                            .message
                                                                                    }}
                                                                                </span>
                                                                                <span
                                                                                    class="size-12"
                                                                                    v-else
                                                                                >
                                                                                    -
                                                                                </span>
                                                                            </template>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </template>
                                            </template>
                                            -->
                                            <!-- <template v-else> -->
                                                <div
                                                    class="text-center mt-4 size-16 fw-bold"
                                                >
                                                    <div class="mb-2">
                                                        <i
                                                            class="fa fa-wechat size-24"
                                                        ></i>
                                                    </div>
                                                    Tidak Ada Chat
                                                </div>
                                            <!-- </template> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chatbox">
                    <div
                        class="modal-dialog-scrollable"
                        style="background: #efeae2"
                    >
                        <div
                            class="modal-content"
                            :style="{
                                backgroundImage: `url(${require('../assets/bc-chat.png')})`,
                            }"
                            v-if="chat != null"
                        >
                            <div class="msg-head">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0">
                                                <template
                                                    v-if="
                                                        chat_list.customers_avatar ==
                                                        null
                                                    "
                                                >
                                                    <div
                                                        class="user-image"
                                                        :style="{
                                                            backgroundImage: `url(${require('../assets/user.jpg')})`,
                                                        }"
                                                    ></div>
                                                </template>
                                                <template v-else>
                                                    <div
                                                        class="user-image"
                                                        v-bind:style="{
                                                            'background-image':
                                                                'url(' +
                                                                chat_list.customers_avatar +
                                                                ')',
                                                        }"
                                                    ></div>
                                                </template>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                <div class="size-20 fw-bold">
                                                    {{
                                                        chat_list.customers_name
                                                    }}
                                                </div>
                                                <div class="text-gray">
                                                    {{ chat_list.room_id }}
                                                </div>
                                            </div>
                                            <div class="ml-auto">
                                                <template
                                                    v-if="
                                                        chat.status == 'closed'
                                                    "
                                                >
                                                    <button
                                                        class="btn btn-primary btn-radius px-4 btn-green"
                                                        disabled
                                                    >
                                                        Akhiri Percakapan
                                                    </button>
                                                </template>
                                                <template v-else>
                                                    <a
                                                        href="javascript:void(0)"
                                                        @click="
                                                            endChat(
                                                                chat.room_id
                                                            )
                                                        "
                                                        class="btn btn-primary btn-radius px-4 btn-green"
                                                    >
                                                        Akhiri Percakapan
                                                    </a>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-body">
                                <div class="msg-body">
                                    <ul>
                                        <template
                                            v-for="(
                                                chat, index
                                            ) in chat_list.chats"
                                        >
                                            <li
                                                v-bind:class="{
                                                    replay:
                                                        chat.sender
                                                            .sender_type ==
                                                            'bot_customer_service' ||
                                                        chat.sender
                                                            .sender_type ==
                                                            'users',
                                                    sender:
                                                        chat.sender
                                                            .sender_type ==
                                                        'customers',
                                                }"
                                                :key="index"
                                            >
                                                <p>
                                                    <template
                                                        v-if="
                                                            chat.message != null
                                                        "
                                                    >
                                                        {{ chat.message }}
                                                    </template>
                                                    <template
                                                        v-if="
                                                            chat.attachment_data !=
                                                            null
                                                        "
                                                    >
                                                        <div class="image-size">
                                                            <img
                                                                class="w-100"
                                                                :src="
                                                                    chat
                                                                        .attachment_data
                                                                        .image
                                                                "
                                                            />
                                                            <div
                                                                class="mt-2 text-left"
                                                                v-if="
                                                                    chat
                                                                        .attachment_data
                                                                        .message &&
                                                                    chat
                                                                        .attachment_data
                                                                        .message !=
                                                                        'null'
                                                                "
                                                            >
                                                                {{
                                                                    chat
                                                                        .attachment_data
                                                                        .message
                                                                }}
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template
                                                        v-if="
                                                            chat.attachment !=
                                                            null
                                                        "
                                                    >
                                                        <div class="image-size">
                                                            <img
                                                                class="w-100"
                                                                :src="
                                                                    chat.attachment
                                                                "
                                                            />
                                                        </div>
                                                    </template>
                                                </p>
                                                <span class="time">
                                                    {{chat.time_parse}}
                                                </span>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </div>
                            <div class="send-box-input">
                                <div
                                    class="d-flex align-items-center bd-highlight"
                                >
                                    <template v-if="chat.status == 'closed'">
                                        <div class="bd-highlight me-3 ms-3">
                                            <a
                                                href="javascript:void(0)"
                                                class="send-button cursor-drop"
                                            >
                                                <i class="fa fa-paperclip"></i>
                                            </a>
                                        </div>
                                        <div
                                            class="flex-grow-1 bd-highlight me-3"
                                        >
                                            <input
                                                type="search"
                                                class="form-control"
                                                placeholder="Tuliskan Pesanmu"
                                                disabled
                                            />
                                        </div>
                                        <div class="bd-highlight">
                                            <a
                                                href="javascript:void(0)"
                                                class="send-button disabled"
                                            >
                                                <i class="fa fa-send"></i>
                                            </a>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="bd-highlight me-3 ms-3">
                                            <a
                                                href="javascript:void(0)"
                                                class="send-button"
                                                @click="showModal = true"
                                            >
                                                <i class="fa fa-paperclip"></i>
                                            </a>
                                        </div>
                                        <div
                                            class="flex-grow-1 bd-highlight me-3"
                                        >
                                            <form
                                                @submit.prevent="
                                                    sendMessage(chat.room_id)
                                                "
                                            >
                                                <input
                                                    type="text"
                                                    class="form-control chat-input"
                                                    placeholder="Tuliskan Pesanmu"
                                                    v-model="message"
                                                />
                                            </form>
                                        </div>
                                        <div class="bd-highlight me-3">
                                            <a
                                                href="javascript:void(0)"
                                                @click="
                                                    sendMessage(chat.room_id)
                                                "
                                                class="send-button"
                                            >
                                                <i class="fa fa-send"></i>
                                            </a>
                                        </div>
                                    </template>
                                </div>
                                <!-- <div class="d-flex">
                                    <div class="input-group search-input">
                                        <span class="input-group-text" id="search-input">
                                            <a href="javascript:void(0)" class="text-primary" @click="showModal = true">
                                                <span icon="file"></span>
                                                <svg class="icon-32" width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7366 2.76175H8.08455C6.00455 2.75375 4.29955 4.41075 4.25055 6.49075V17.3397C4.21555 19.3897 5.84855 21.0807 7.89955 21.1167C7.96055 21.1167 8.02255 21.1167 8.08455 21.1147H16.0726C18.1416 21.0937 19.8056 19.4087 19.8026 17.3397V8.03975L14.7366 2.76175Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M14.4741 2.75V5.659C14.4741 7.079 15.6231 8.23 17.0431 8.234H19.7971" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M14.2936 12.9141H9.39355" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M11.8442 15.3639V10.4639" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                </svg>
                                            </a>
                                        </span>
                                        <template v-if="chat.status == 'closed'">
                                            <input type="search" class="form-control" placeholder="Tuliskan pesanmu" disabled>
                                            <span class="input-group-text right" id="search-input">
                                                <button class="btn btn-send btn-sm px-4" disabled>
                                                    <svg class="icon-32" width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.8325 8.17463L10.109 13.9592L3.59944 9.88767C2.66675 9.30414 2.86077 7.88744 3.91572 7.57893L19.3712 3.05277C20.3373 2.76963 21.2326 3.67283 20.9456 4.642L16.3731 20.0868C16.0598 21.1432 14.6512 21.332 14.0732 20.3953L10.106 13.9602" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    </svg>
                                                    Send
                                                </button>
                                            </span>
                                        </template>
                                        <template v-else>
                                            <input type="search" class="form-control" placeholder="Tuliskan pesanmu" v-model="message">
                                            <span class="input-group-text right" id="search-input">
                                                <a href="javascript:void(0)" @click="sendMessage(chat.room_id)" class="btn btn-send btn-sm px-4">
                                                    <svg class="icon-32" width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.8325 8.17463L10.109 13.9592L3.59944 9.88767C2.66675 9.30414 2.86077 7.88744 3.91572 7.57893L19.3712 3.05277C20.3373 2.76963 21.2326 3.67283 20.9456 4.642L16.3731 20.0868C16.0598 21.1432 14.6512 21.332 14.0732 20.3953L10.106 13.9602" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    </svg>
                                                    Send
                                                </a>
                                            </span>
                                        </template>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="no-message" v-else>
                            <div class="row justify-content-center">
                                <div class="col-md-10 mt-5">
                                    <div class="text-center mt-empty-chat">
                                        <img
                                            src="../assets/logo.png"
                                            width="220"
                                            alt=""
                                        />
                                        <h1 class="mt-5 fw-bold">
                                            Chatbox Trobens
                                        </h1>
                                        <div class="mt-2">
                                            Send and receive messages without
                                            keeping your phone online.<br />
                                            Use WhatsApp on up to 4 linked
                                            devices and 1 phone at the same
                                            time.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import moment from "moment";
import $ from "jquery";
import Api from "../api/Api";

export default {
    components: {},

    name: "Chat",
    data: () => ({
        moment: moment,
        user_id: "",
        lists: [],
        allChat: [],
        allChatRaw: [],
        complete_list: [],
        timer_list: "",
        timer_all_chat: "",
        timer_chat: "",
        timer_complete: "",
        search: "",
        chat: null,
        message: "",
        chat_list: {
            chats: [],
            customers_avatar: null,
            customers_name: null,
        },
        attachment: null,
        imageData: "",
        visible: false,
        transferred_at: "",
        showModal: false,
        is_loading_chat: false,
        is_changing_upload_image: false,
        req: {
            upload_image: "",
            message: null,
        },
        is_submit_file: false,
    }),
    computed: {},
    created() {
        // this.getUserLogin()
        // this.timer_list = setInterval(this.getListChat, 5000);
        this.timer_all_chat = setInterval(this.getAllListChat, 5000);
        this.timer_chat = setInterval(this.chatResult, 5000);
        // this.timer_complete = setInterval(this.getCompleteChatList, 5000);
    },
    methods: {
        filterChat(c) {
            if (c == 'all') {
                console.log('')
            }
            if (c == 'ongoing') {
                console.log('')
            }
            if (c == 'complete') {
                console.log('')
            }
        },
        uploadImage() {
            var upload_image = new FileReader();
            upload_image.onload = function (e) {
                $(".upload-image-chat").css(
                    "background-image",
                    "url(" + e.target.result + ")"
                );
            };
            upload_image.readAsDataURL($("#upload_image")[0].files[0]);
            this.is_changing_upload_image = true;
        },
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.user_id = res.data.data.hash;
                    this.getAllListChat();
                    this.getListChat();
                    this.getCompleteChatList();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getAllListChat() {
            if (`${this.user_id}` == '') return
            // this.is_loading_chat = true
            Api.get(`/cb/list-all-chat/users/${this.user_id}`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.allChatRaw = res.data.data;
                    this.allChat = this.allChatRaw;
                    this.filterChat('all')
                    // this.is_loading_chat = false
                })
                .catch((err) => {
                    // this.is_loading_chat = false
                    console.log(err);
                });
        },
        getListChat() {
            Api.get(`/cb/list-chats/users/${this.user_id}`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.lists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getCompleteChatList() {
            Api.get(`/cb/list-complete-chat/users/${this.user_id}`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.complete_list = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        findChat(room_id) {
            if (`${room_id}` == 'undefined') return

            let that = this

            that.is_loading_chat = true;
            Api.get(`/cb/retrieve-chat/${room_id}?prefix=users`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    that.chat = res.data.data;
                    that.chatResult();
                    that.is_loading_chat = false;
                })
                .catch((err) => {
                    that.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    that.is_loading_chat = false;
                });
        },
        chatResult() {
            if (`${this.chat?.room_id}` == 'undefined') return

            Api.get(`/cb/retrieve-chat/${this.chat?.room_id}?prefix=users`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.chat_list = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        sendMessage(room_id) {
            let that = this
            this.is_loading_chat = true;
            if (this.message == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Pesan Anda Belum Diisi",
                });
                this.is_loading_chat = false;
            }
            var data = {
                type: "text",
                message: this.message,
                participant_id: this.user_id,
            };
            Api.post(`/cb/send-message/users/${room_id}`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    that.chatResult();
                    that.getAllListChat();
                    that.message = null;
                    console.log(res);
                    that.is_loading_chat = false;
                })
                .catch(function (error) {
                    that.is_loading_chat = false;
                    console.error(error);
                });
        },
        endChat(room_id) {
            Api.post(`/cb/end-session/${room_id}`, "", {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    console.log(res);
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Chat telah berakhir",
                    });
                    setTimeout(() => (window.location.href = "/chat"), 1500);
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
        printToConsole() {
            console.log(this.message);
        },
        submitFile(room_id) {
            this.is_submit_file = true;
            var upload_image = $("#upload_image")[0].files[0];
            const formData = new FormData();

            if (this.is_changing_upload_image) {
                formData.append("image", upload_image);
            }
            formData.append("message", this.req.message);
            formData.append("type", "attachment");
            formData.append("participant_id", this.user_id);

            Api.post(`/cb/send-message/users/${room_id}`, formData, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                    "Content-Type": "multipart/form-data",
                },
            })
                .then((res) => {
                    console.log(res);
                    this.chatResult();
                    this.getAllListChat();
                    this.showModal = false;
                    this.attachment = "";
                    this.req.message = "";
                    this.imageData = "";
                    this.is_submit_file = false;
                    this.is_changing_upload_image = false;
                })
                .catch(function (error) {
                    console.log(error);
                    this.is_submit_file = false;
                });
        },
        cancelAutoUpdate() {
            // clearInterval(this.timer_list);
            clearInterval(this.timer_all_chat);
            clearInterval(this.timer_chat);
            // clearInterval(this.timer_complete);
        },
    },
    beforeUnmount() {
        this.cancelAutoUpdate();
    },
    async mounted() {
        await this.getUserLogin();
        await this.getListChat();
        await this.getAllListChat();
        await this.chatResult();
        await this.getCompleteChatList();
    },
};
</script>

<style scoped>
.text-tab {
    font-size: 13px;
    font-weight: 400;
    color: #8a92a6;
}
.text-tab.active {
    color: #e60013;
    font-weight: 500;
}
.modal-content {
    box-shadow: unset !important;
}
.message-area {
    height: 100vh;
    overflow: hidden;
    background: #f5f5f5;
    /* padding-bottom: 50px; */
}

.chat-area {
    position: relative;
    width: 100%;
    background-color: #fff;
    height: 100vh;
    overflow: hidden;
    min-height: calc(100% - 1rem);
}

.chatlist {
    outline: 0;
    height: 100%;
    overflow: hidden;
    width: 320px;
    float: left;
    padding: 15px 0px;
    box-shadow: rgb(0 0 0 / 6%) 0px 4px 25px;
    border-right: 1px solid #f5f5f5;
}
.line-bottom {
    border-bottom: 1px solid #f7f7f7;
    padding-bottom: 14px;
    padding-top: 14px;
}
.line-bottom.actives {
    /* border-left: 4px solid #E60013; */
    background: #f0f2f5;
    padding-top: 14px;
    padding-bottom: 14px;
}

.chat-area .modal-content {
    border: none;
    border-radius: 0;
    outline: 0;
    height: 100%;
}
.chat-area .modal-content.custom {
    border-radius: 15px;
}
.chat-area .modal-dialog-scrollable {
    height: 100% !important;
    position: relative;
}

.chatbox {
    width: auto;
    overflow: hidden;
    height: 100%;
}

.chatbox .modal-dialog,
.chatlist .modal-dialog {
    max-width: 100%;
    margin: 0;
}

.msg-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chat-area .form-control {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #222;
    background-color: #fafafa;
    background-clip: padding-box;
    padding: 14px 20px !important;
    border-radius: 10px;
}
.form-control.chat-form {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #222;
    background-color: #fff;
    background-clip: padding-box;
    padding: 14px 20px !important;
    border-radius: 10px;
}
.form-control:disabled {
    cursor: no-drop;
    background-color: #dee2e6 !important;
}

.chat-area .form-control:focus {
    outline: 0;
    box-shadow: inherit;
}
.form-control::placeholder {
    color: #54656f !important;
    font-size: 16px;
}

a.add img {
    height: 36px;
}

.chat-area .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    align-items: center;
    justify-content: space-between;
    flex-wrap: inherit;
}

.chat-area .nav-tabs .nav-item {
    width: 100%;
}

.chat-area .nav-tabs .nav-link {
    width: 100%;
    color: #180660;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: capitalize;
    margin-top: 5px;
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.chat-area .nav-tabs .nav-item.show .nav-link,
.chat-area .nav-tabs .nav-link.active {
    color: #222;
    background-color: #fff;
    border-color: transparent transparent #000;
}

.chat-area .nav-tabs .nav-link:focus,
.chat-area .nav-tabs .nav-link:hover {
    border-color: transparent transparent #000;
    isolation: isolate;
}

.chat-list h3 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: 0;
}

.chat-list p {
    color: #707c97;
    font-size: 11px;
    font-weight: 300;
    text-transform: capitalize;
    margin-bottom: 0;
}

.chat-list a.d-flex {
    /* margin-bottom: 15px; */
    position: relative;
    text-decoration: none;
    margin-right: 17px;
}

.chat-list .active {
    display: block;
    content: "";
    clear: both;
    position: absolute;
    bottom: 27px;
    left: 30px;
    height: 12px;
    width: 12px;
    background: #00db75;
    border-radius: 50%;
    border: 2px solid #fff;
}
.chat-list .non-active {
    display: block;
    content: "";
    clear: both;
    position: absolute;
    bottom: 27px;
    left: 30px;
    height: 12px;
    width: 12px;
    background: #c03221;
    border-radius: 50%;
    border: 2px solid #fff;
}

.msg-head h3 {
    color: #222;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0;
}
.msg-head .active {
    display: block;
    content: "";
    top: 19px;
    left: 51px;
    clear: both;
    position: absolute;
    height: 12px;
    width: 12px;
    background: #00db75;
    border-radius: 50%;
    border: 2px solid #fff;
}

.msg-head p {
    color: rgb(0 0 0 / 65%);
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: 0;
}

.msg-head {
    padding: 15px 20px;
    background: #fff;
    box-shadow: 0 2px 5px rgba(196, 205, 213, 0.25);
    /* border-bottom: 1px solid #f5f6fa; */
}

.moreoption {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.moreoption .navbar {
    padding: 0;
}

.moreoption li .nav-link {
    color: #222;
    font-size: 16px;
}

.moreoption .dropdown-toggle::after {
    display: none;
}

.moreoption .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: auto;
    right: 0;
    margin-top: 0.125rem;
}

.msg-body ul {
    overflow: hidden;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
}

.msg-body ul li {
    list-style: none;
    margin: 0px 0;
}

.msg-body ul li.sender {
    display: block;
    width: 100%;
    position: relative;
}

.msg-body ul li.sender:before {
    display: block;
    clear: both;
    content: "";
    position: absolute;
    top: -6px;
    left: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 15px 12px;
    border-color: transparent transparent #fff transparent;
    -webkit-transform: rotate(-37deg);
    -ms-transform: rotate(-37deg);
    transform: rotate(-37deg);
}

.msg-body ul li.sender p {
    color: #000;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    padding: 8px;
    background: #fff;
    display: inline-block;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 0;
}

.msg-body ul li.sender p b {
    display: block;
    color: #180660;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
}

.msg-body ul li.replay {
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
}

.msg-body ul li.replay:before {
    display: block;
    clear: both;
    content: "";
    position: absolute;
    bottom: 29px;
    right: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 15px 12px;
    border-color: transparent transparent #d9fdd3 transparent;
    -webkit-transform: rotate(37deg);
    -ms-transform: rotate(37deg);
    transform: rotate(37deg);
}

.msg-body ul li.replay p {
    color: #000;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    padding: 8px;
    background: #d9fdd3;
    display: inline-block;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-bottom: 0;
}

.msg-body ul li.replay p b {
    display: block;
    color: #061061;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
}

.msg-body ul li.replay:after {
    display: block;
    content: "";
    clear: both;
}

.time {
    display: block;
    color: #8a92a6;
    font-size: 11px;
    line-height: 3;
    font-weight: 400;
}

li.replay .time {
    margin-right: 13px;
}

.divider {
    position: relative;
    z-index: 1;
    text-align: center;
}

.msg-body h6 {
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #222;
    background: #fff;
    display: inline-block;
    padding: 0 5px;
    margin-bottom: 0;
}

.divider:after {
    display: block;
    content: "";
    clear: both;
    position: absolute;
    top: 12px;
    left: 0;
    border-top: 1px solid #ebebeb;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.send-box-input {
    padding: 15px 15px;
    background: #f0f2f5;
}

.send-box-input .form-control::placeholder {
    color: #cccccc;
}

.send-box-input .input-group-text {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    padding: 0.3rem 1rem !important;
    padding-left: 10px !important;
    text-align: unset !important;
}

.send-box button {
    border: none;
    background: #3867d6;
    padding: 0.375rem 5px;
    color: #fff;
    border-radius: 0.25rem;
    font-size: 14px;
    font-weight: 400;
    width: 24%;
    margin-left: 1%;
}

.send-box button i {
    margin-right: 5px;
}

.send-btns .button-wrapper {
    position: relative;
    width: 125px;
    height: auto;
    text-align: left;
    margin: 0 auto;
    display: block;
    background: #f6f7fa;
    border-radius: 3px;
    padding: 5px 15px;
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    overflow: hidden;
}

.send-btns .button-wrapper span.label {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    color: #343945;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 13px;
}

#upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.send-btns .attach .form-control {
    display: inline-block;
    width: 120px;
    height: auto;
    padding: 5px 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: #343945;
    background-color: #f6f7fa;
    background-clip: padding-box;
    border: 1px solid #f6f7fa;
    border-radius: 3px;
    margin-bottom: 5px;
}

.send-btns .button-wrapper span.label img {
    margin-right: 5px;
}

.button-wrapper {
    position: relative;
    width: 100px;
    height: 100px;
    text-align: center;
    margin: 0 auto;
}

button:focus {
    outline: 0;
}

.add-apoint {
    display: inline-block;
    margin-left: 5px;
}

.add-apoint a {
    text-decoration: none;
    background: #f6f7fa;
    border-radius: 8px;
    padding: 8px 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
    color: #343945;
}

.add-apoint a svg {
    margin-right: 5px;
}

.chat-icon {
    display: none;
}

.closess i {
    display: none;
}
.user-image {
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    /* border: 2px solid #e9ecef; */
}
.btn-radius {
    border-radius: 50px !important;
}
.btn-red {
    background: #fa2537 !important;
    border-color: #fa2537 !important;
}
.btn-green {
    background: #3d8824 !important;
    border-color: #3d8824 !important;
    color: #fff !important;
}
.btn-send {
    background: #3d8824 !important;
    border-color: #3d8824 !important;
    color: #fff !important;
    font-weight: 600;
}
.btn-get {
    background: #fff !important;
    border-color: #d9fdd3 !important;
    color: #219653 !important;
    font-size: 11px;
    font-weight: 500;
}
.button-circle {
    width: 35px;
    height: 35px;
    background: #fa2537;
    border-radius: 50px;
    padding: 3px 0px;
    text-align: center;
    color: #fff !important;
}
.pull-right {
    float: right;
}
.mb-2 {
    margin-bottom: 20px;
}
.mr-1 {
    margin-right: 10px;
}
.mr-2 {
    margin-right: 20px;
}
.mr-3 {
    margin-right: 30px;
}
.dropdown-toggle::after {
    display: none !important;
}
.dropdown-menu.hidden-content {
    border: none !important;
}
.size-14 {
    font-size: 14px !important;
}
.gray-time {
    color: #6c757d !important;
}
.circle-filter {
    width: 42px;
    height: 42px;
    background: #fff;
    border: 1px solid #bfbfbf;
    text-align: center;
    border-radius: 50px;
    padding: 4px;
    font-size: 18px;
}
.scroll {
    height: 100vh;
    overflow: scroll;
}
.modal-dialog-scrollable .modal-content {
    overflow: unset !important;
}
.modal-dialog-scrollable .modal-body {
    overflow-y: auto !important;
    display: flex !important;
    flex-direction: column-reverse !important;
    margin-top: auto !important;
    /* overflow: scroll !important; */
}
.chat-limit {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 210px;
}
/* .search-input{
        z-index: 9999 !important;
    } */
.input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #fff;
}

::-webkit-scrollbar-thumb {
    background: #cccccc;
}
.input-group-text.right {
    border-left: 0px solid #fff !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
.input-group-text {
    background: unset !important;
    border: unset !important;
}
.btn-send {
    padding: 7px 20px 4px 16px;
}
.size-11 {
    font-size: 11px;
}
.green-color {
    color: #219653;
}
.red-color {
    color: #eb5757;
}
.gray-color {
    color: #707c97;
}
.fw-thin {
    font-weight: 300;
}
.black-color {
    color: #000;
}
.form-check {
    font-size: 14px;
}
.dropdown-menu.show {
    box-shadow: 0 0.625rem 1.875rem rgb(0 0 0 / 10%);
    border-radius: 10px;
}
.form-check-input {
    border-color: #8a92a6;
}
.dropdown-menu {
    padding: 0 !important;
}
.input-group-text.position {
    align-items: end !important;
}
.nav-pills .nav-link {
    color: #8a92a6;
    font-size: 13px;
}
.nav-pills .nav-link.active {
    background: #e60013;
    color: #ffffff;
    font-weight: 600;
    border-radius: 12px;
}
.nav-link {
    padding: 0.5rem 15px;
}
.no-message {
    background: #f7f7f7;
    position: absolute;
    height: 100vh;
    top: 0;
    width: 100%;
    z-index: 9;
    /* border-bottom: 7px solid #d36a72; */
}
.mt-empty-chat {
    margin-top: 80px;
}
.modal-dialog.modal-custom {
    max-width: 500px;
    margin: 1.75rem auto;
    text-align: unset !important;
}
.modal-body.custom {
    flex-direction: unset !important;
    display: unset !important;
    padding: 20px;
    overflow: unset !important;
}
.image-preview-chat {
    border: 2px solid #e8e8e8;
    border-radius: 10px;
    padding: 10px;
    margin-top: 30px;
}
.image-success-upload {
    border: 2px solid #e8e8e8;
    border-radius: 10px;
    padding: 10px;
}
.image-preview-chat img {
    width: 100%;
}
.image-success-upload img {
    width: 100%;
}
.modal-vue .overlay {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-vue .modal-body-chat {
    position: absolute;
    width: 500px;
    z-index: 9999;
    margin: 0 auto;
    padding: 20px 30px;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 25px;
    border-radius: 15px;
}
.btn-outline-black {
    background: #fff !important;
    border-color: #000 !important;
    color: #000 !important;
}
.send-button {
    color: #63747d;
    font-size: 25px;
}
.send-button.disabled {
    cursor: no-drop;
}
.image-size {
    width: 250px;
}
.nav.nav-pills {
    padding-left: 2rem !important;
}
hr {
    color: #f7f7f7;
    margin: 0px;
}
.mt-active {
    margin-top: 0rem !important;
    margin-bottom: 1rem !important;
}
.mt-not-active {
    margin-top: 1rem;
    margin-bottom: 0rem;
}
.title-chat {
    font-size: 15px !important;
}
.upload-image-chat {
    background: #fff;
    border-style: dashed;
    border-color: #e8e8e9;
    box-sizing: border-box;
    border-radius: 20px;
    height: 170px;
    width: 100%;
    background-size: cover;
    background-position: center;
}
.upload-button.chat {
    margin-left: 0px;
    padding-left: 0px;
    font-size: 50px;
    margin-top: 18px;
    background: transparent;
}
.close-chat {
    position: absolute;
    right: 20px;
    top: 15px;
}
.text-left {
    text-align: left;
}
</style>
