<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="mb-3">
                <h3 class="fw-bold">List CRM Specialist</h3>
            </div>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-4 m-mx-0">
                    <div class="search-form">
                        <input
                            type="text"
                            class="form-control w-search-leads"
                            @keyup.enter="searchData"
                            v-model="keySearch"
                            placeholder="Search Full Name"
                        />
                        <button class="btn btn-primary" @click="searchData()">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
                <div class="me-4 m-mt-1 m-mx-0">
                    <router-link
                        :to="{ name: 'crm-specialist-form' }"
                        class="btn btn-primary px-5 btn-with-search"
                    >
                        Add New
                    </router-link>
                </div>
                <div class="m-mt-1" v-show="isRole(branchRoles)">
                    <button
                        class="btn btn-outline-black btn-filter px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
                <!-- <div class="me-2 m-mt-1">Rows per page</div>
                <div>
                    <select
                        class="form-control form-select pr-28 m-mt-1"
                        v-model="limit"
                        @change="searchData"
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                    </select>
                </div> -->
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <label class="fw-bold">Filter</label>
                    </div>
                    <div class="col-md-8">
                        <multiselect
                            v-model="branch"
                            :options="branchs"
                            label="name"
                            track-by="id"
                            placeholder="Select Branch"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-outline-black px-5 w-100"
                            :disabled="range == ''"
                            @click="clearFilter()"
                        >
                            Clear
                        </button>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-green px-5 w-100"
                            :disabled="branch == null"
                            @click="filter()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_my_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Full Name
                                </th>
                                <th class="text-center" scope="col">Gender</th>
                                <th class="text-center" scope="col">Role</th>
                                <th class="text-center" scope="col">Phone</th>
                                <th class="text-center" scope="col">Email</th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-show="isRole(branchRoles)"
                                >
                                    Branch Name
                                </th>
                                <th class="text-center" scope="col">Status</th>
                                <th class="text-center" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in data" :key="index">
                                <td class="text-center">
                                    {{ itemNumber(index) }}
                                </td>
                                <td class="">
                                    <router-link
                                        class="text-link"
                                        :to="{
                                            name: 'crm-specialist-detail',
                                            params: { id: item.id },
                                        }"
                                        >{{ item.name }}</router-link
                                    >
                                </td>
                                <td class="text-center">
                                    {{ titleCase(item.gender) }}
                                </td>
                                <td class="text-center">{{ item.role }}</td>
                                <td class="text-center">{{ item.phone }}</td>
                                <td class="text-center">{{ item.email }}</td>
                                <td
                                    class="text-center"
                                    v-show="isRole(branchRoles)"
                                >
                                    {{ item.branch_name }}
                                </td>
                                <td class="text-center">
                                    <span
                                        :class="[
                                            item.is_active
                                                ? 'pill green'
                                                : 'pill red',
                                        ]"
                                        >{{
                                            item.is_active
                                                ? "Active"
                                                : "Non Active"
                                        }}</span
                                    >
                                </td>
                                <td class="text-center">
                                    <template>
                                        <router-link
                                            :to="{
                                                name: 'crm-specialist-form',
                                                params: { id: item.id },
                                            }"
                                            class="me-2"
                                        >
                                            <i class="fa fa-pencil size-18"></i>
                                        </router-link>
                                    </template>
                                    <template v-if="item.is_active == true">
                                        <a
                                            href="javascript:void(0)"
                                            @click="setDelete(item.id)"
                                        >
                                            <i
                                                class="fa fa-ban size-18 text-danger"
                                            ></i>
                                        </a>
                                    </template>
                                    <template v-else>
                                        <a
                                            href="javascript:void(0)"
                                            class="cursor-drop"
                                        >
                                            <i
                                                class="fa fa-ban size-18 text-danger"
                                            ></i>
                                        </a>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="data.length == 0" class="text-center mt-section">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="d-flex" v-if="data.length > 0">
                <div class="ms-auto">
                    <div class="d-flex mt-4">
                        <div class="me-3">
                            <select
                                class="form-control form-select w-select"
                                v-model="limit"
                                @change="getData()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <b-pagination-nav
                            v-model="currentPage"
                            :number-of-pages="totalPage"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div
                class="overlay"
                v-if="deleteModal"
                @click="deleteModal = false"
            ></div>
            <div class="modal-body-chat sm" v-if="deleteModal">
                <div
                    class="d-flex align-items-lg-center justify-content-center"
                >
                    <img
                        class="me-3"
                        src="../../assets/delete-circle-icon.png"
                    />
                </div>
                <div
                    class="d-flex align-items-lg-center justify-content-center fw-bold my-5"
                >
                    Are You Sure Want To Delete This Data?
                </div>
                <div class="d-flex gap-2">
                    <button
                        class="button button-delete"
                        @click="deleteModal = false"
                        :disabled="isSaving"
                    >
                        Batal
                    </button>
                    <button
                        class="button button-cancel"
                        @click="deleteData"
                        :disabled="isSaving"
                    >
                        <span
                            class="spinner-border spinner-border-sm me-2"
                            v-if="isSaving"
                        ></span>
                        Ya
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        Multiselect,
    },
    data() {
        return {
            data: "",
            keySearch: "",
            hash: "",
            is_my_list_ready: false,
            deleteModal: false,
            currentPage: 0,
            totalPage: 0,
            limit: 10,
            isFilter: false,
            branchRoles: ["admin-crm"],
            roles: [],
            branch: null,
            branchs: [],
        };
    },
    created() {
        this.getData();
        this.getBranchs();
        this.getUserLogin();
    },
    methods: {
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.roles = res.data.data.roles;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        searchData() {
            this.getData();
        },
        changePage() {
            this.getData();
        },
        itemNumber(index) {
            let number = index + this.startIndex + 1;
            return (number < 10 ? "" : "") + number;
        },
        getData() {
            this.is_my_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist`,
                {
                    params: {
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 1,
                        limit: this.limit,
                        search: this.keySearch,
                        branch_id: this.branch?.id,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        getSorting() {},
        setDelete(hash) {
            this.deleteModal = true;
            this.hash = hash;
        },
        deleteData() {
            this.isSaving = true;
            var data = new FormData();

            data.append("id", this.hash);

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist/remove`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.deleteModal = false;
                    this.isSaving = false;
                    // this.$notify({
                    //   group: "foo",
                    //   type: "success",
                    //   title: "Success",
                    //   text: "Data berhasil di hapus",
                    // });
                    this.title = "Data Berhasil Dihapus!";
                    this.info = "Selamat! Anda telah berhasil menghapus data.";
                    this.isModal = "success";
                    this.getData();
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                    this.deleteModal = false;
                    this.title = "Data Gagal Dihapus!";
                    this.info = "Data gagal dihapus! Silakan ulangi kembali";
                    this.isModal = "fail";
                    this.isSaving = false;
                });
        },
        clearFilter() {
            this.branch = null;
            this.getData();
        },
        filter() {
            this.getData();
        },
        getBranchs() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/branches/dropdown`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.branchs = res.data.data;
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    computed: {
        startIndex() {
            return (this.currentPage - 1) * this.limit;
        },
    },
};
</script>

<style lang="scss" scoped>
.bt-size {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 159px;
    height: 44px;
    border-radius: 8px;
}
.search-icon-left {
    top: 11px;
    left: 10px;
}
.excel {
    width: 20px !important;
    height: 19.05px !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.button {
    display: flex;
    border-radius: 12px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    &-save {
        background: #48a2d4;
        border: 1px solid #48a2d4;
        color: white;
    }
    &-save:is(:hover, :focus) {
        background: #176f9e;
    }
    &-delete {
        background: #e60013;
        border: 1px solid #e60013;
        color: white;
    }
    &-delete:is(:hover, :focus) {
        background: #e94b58;
    }
    &-cancel {
        background: #ffffff;
        border: 1px solid #e94b58;
        color: #e94b58;
    }
    &-cancel:is(:hover, :focus) {
        background: #e94b58;
        border: 1px solid #ffffff;
        color: #ffffff;
    }
}
.pill {
    display: inline-block;
    width: 110px;
    height: 24px;
    border-radius: 40px;
    text-align: center;
    color: white;
    &.red {
        background-color: #e60013;
        border: 1px solid #e60013;
        outline: 1px solid #e60013;
    }

    &.green {
        background-color: #3d8824;
        border: 1px solid #3d8824;
        outline: 1px solid #3d8824;
    }
}
.text-link {
    text-decoration: underline;
    color: #24516a;
}
.w-search-leads {
    width: 320px;
}
.multiselect {
    color: #000;
}
</style>
