<template>
    <div class="padding-container">
        <div class="d-flex align-items-center bd-highlight">
            <div class="flex-grow-1 bd-highlight">
                <h3 class="fw-bold">List Kendaraan</h3>
            </div>
            <div class="bd-highlight">
                <a
                    href="/trawlcareer/vehicle/create"
                    class="btn btn-green btn-lg px-5"
                >
                    Tambah Armada
                </a>
            </div>
        </div>
        <div class="box mt-4">
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <div class="position-relative">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Cari armada"
                                v-model="req.keyword"
                            />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <select
                            class="form-control form-select"
                            v-model="req.partner"
                            @change="getList()"
                        >
                            <option value="">Pilih mitra</option>
                            <template v-for="(mitra, index) in mitra.list_data">
                                <option :value="mitra.id" :key="index">
                                    {{ mitra.nama_mitra }}
                                </option>
                            </template>
                        </select>
                    </div>
                    <div class="col-md-3 mt-3">
                        <select
                            name=""
                            class="form-control form-select"
                            v-model="req.status"
                            @change="getList()"
                        >
                            <option value="">Pilih status</option>
                            <option :value="false">Tidak Aktif</option>
                            <option :value="true">Aktif</option>
                        </select>
                    </div>
                </div>
                <div
                    class="modern-table-boxed mt-4"
                    v-if="searchLists.length > 0"
                >
                    <div class="d-table-row header">
                        <div class="d-table-cell">No</div>
                        <div class="d-table-cell">Armada</div>
                        <div class="d-table-cell">No Polisi</div>
                        <div class="d-table-cell">Tahun</div>
                        <div class="d-table-cell">Mitra</div>
                        <div class="d-table-cell">Status</div>
                        <div class="d-table-cell">Aksi</div>
                    </div>
                    <template v-for="(list, index) in searchLists">
                        <div class="d-table-row" :key="index">
                            <div class="d-table-cell">{{ index + 1 }}.</div>
                            <div class="d-table-cell">
                                {{ list.nama_kendaraan }}
                            </div>
                            <div class="d-table-cell">
                                {{ list.no_plat }}
                            </div>
                            <div class="d-table-cell">
                                Tahun {{ list.tahun_kendaraan }}
                            </div>
                            <div class="d-table-cell">
                                {{ list.mitra }}
                            </div>
                            <div class="d-table-cell">
                                <small
                                    class="tag green"
                                    v-if="list.status == true"
                                >
                                    Aktif
                                </small>
                                <small class="tag red" v-else>
                                    Tidak Aktif
                                </small>
                            </div>
                            <div class="d-table-cell shrink">
                                <a
                                    href="javascript:void(0)"
                                    @click="openModal(list.id)"
                                    class="btn btn-green btn-sm px-4"
                                >
                                    Lihat Detail
                                </a>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>

        <div class="d-flex" v-if="searchLists.length > 0">
            <div class="ms-auto">
                <div class="d-flex mt-4">
                    <div class="me-3">
                        <select
                            class="form-control form-select w-select"
                            v-model="per_page"
                            @change="getList()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <b-pagination-nav
                        :link-gen="linkGen"
                        v-model="currentPage"
                        :number-of-pages="list.total_page"
                        base-url="#"
                        first-number
                        align="right"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalDetail"></div>
            <div class="modal-body-chat pb-5" v-if="modalDetail">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">Detail Armada</div>
                    </div>
                    <div class="bd-highlight">
                        <a
                            href="javascript:void(0)"
                            @click="modalDetail = false"
                            class="size-20 text-primary"
                        >
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div class="line"></div>
                <div class="scroll" style="height: 430px">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="fw-bold mb-2">Nama Kendaraan</label>
                            <div class="disabled-box">
                                {{ detail.nama_kendaraan }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="fw-bold mb-2">Jenis Kendaraan</label>
                            <div class="disabled-box">
                                {{ detail.type }}
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="fw-bold mb-2">Tahun Kendaraan</label>
                            <div class="disabled-box">
                                {{ detail.tahun_kendaraan }}
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="fw-bold mb-2">Nomor Polisi</label>
                            <div class="disabled-box">
                                {{ detail.no_polisi }}
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="fw-bold mb-2">Mitra</label>
                            <div class="disabled-box">
                                {{ detail.mitra }}
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="fw-bold mb-2">Status</label>
                            <div class="disabled-box">
                                <template v-if="detail.is_verified == true">
                                    Aktif
                                </template>
                                <template v-else> Tidak Aktif </template>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-2">Catatan</label>
                            <div class="disabled-box">
                                {{ detail.description }}
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-2">Foto Kendaraan</label>
                            <div class="row">
                                <template
                                    v-for="(image, index) in detail.images"
                                >
                                    <div class="col-md-3" :key="index">
                                        <div
                                            class="vehicle-image-item"
                                            :style="{
                                                'background-image':
                                                    'url(' + image.image + ')',
                                            }"
                                        ></div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-2">Foto STNK</label>
                            <div class="row">
                                <div class="col-md-3">
                                    <div
                                        class="vehicle-image-item"
                                        :style="{
                                            'background-image':
                                                'url(' + detail.stnk + ')',
                                        }"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
// import moment from 'moment'

export default {
    components: {
        ListLoader,
    },
    name: "vehicleList",
    data() {
        return {
            modalDetail: false,
            is_list_ready: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page: 10,
            currentPage: 0,
            req: {
                keyword: "",
                partner: "",
                status: "",
            },
            mitra: {
                list_data: [],
            },
            detail: {},
        };
    },
    created() {
        this.getList();
        this.getMitra();
    },
    mounted() {},
    computed: {
        searchLists() {
            return this.list.list_data.filter((item) => {
                return item.nama_kendaraan
                    .toLowerCase()
                    .includes(this.req.keyword.toLowerCase());
            });
        },
    },
    methods: {
        currencyFormat(num) {
            return (
                "Rp. " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        getList() {
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/armada?partner=${this.req.partner}&status=${this.req.status}`,
                {
                    params: {
                        per_page: this.per_page,
                        page: this.$route.query.page,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        getMitra() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/partner?per_page=50`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.mitra = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        openModal(id) {
            this.modalDetail = true;
            this.getDetail(id);
        },
        getDetail(id) {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/armada/detail/${id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detail = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped>
.vehicle-image-item {
    width: 100%;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    border: 2px solid #e2e3e5;
}
</style>
