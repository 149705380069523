<template>
  <div class="padding-container">
    <div class="d-flex align-items-center mb-4">
      <h3 class="fw-bold">Kelola Staff</h3>
      <div class="ms-auto">
        <button class="add-button" @click="setModalAdd">
          Tambah Staff <span class="ms-4"><img class="pb-1" src="../../assets/plus-icon.png" /></span>
        </button>
      </div>
    </div>
    <div class="box">
      <div class="d-flex align-items-center">
        <h3 class="mb-0 fw-bold">Daftar Pegawai</h3>
        <div class="ms-auto">
          <div class="position-relative">
            <input v-model="keySearch" @keyup.enter="searchData" type="text" class="form-control" style="width: 155px; padding-right: 30px" placeholder="Search" />
            <span class="search-icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="mt-4" v-if="is_my_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">Nama Lengkap</div>
            <div class="d-table-cell">Username</div>
            <div class="d-table-cell">Email</div>
            <div class="d-table-cell">No.Telephone</div>
            <div class="d-table-cell">Tipe Staff</div>
            <div class="d-table-cell"></div>
          </div>
          <div class="d-table-row" v-for="(item, index) in data" :key="index">
            <div class="d-table-cell">{{ itemNumber(index) }}</div>
            <div class="d-table-cell">
              {{ item.name }}
            </div>
            <div class="d-table-cell">
              {{ item.username }}
            </div>
            <div class="d-table-cell">{{ item.email }}</div>
            <div class="d-table-cell">{{ item.phone }}</div>
            <div class="d-table-cell">{{ roleEmployee(item) }}</div>
            <div class="d-table-cell">
              <div class="d-flex gap-2">
                <a href="javascript:void(0)" @click="setEdit(item)"><img src="../../assets/edit-icon.png" /></a>
                <a href="javascript:void(0)" @click="setDelete(item.hash)"><img src="../../assets/delete-icon.png" /></a>
              </div>
            </div>
          </div>
        </div>

        <div v-if="data.length == 0" class="text-center mt-section">
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
    </div>
    <div class="overflow-auto mt-3">
      <b-pagination-nav v-if="data.length > 0" v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="editModal || addModal" @click="close"></div>
      <div class="modal-body-chat md" v-if="editModal || addModal">
        <div class="d-flex bd-highlight align-items-lg-center">
          <div class="flex-grow-1 bd-highlight">
            <img src="../../assets/flag-icon.png" />
          </div>
          <div class="bd-highlight">
            <a href="javascript:void(0)" @click="close" class="size-20 text-primary">
              <img src="../../assets/cross-gray-icon.png" />
            </a>
          </div>
        </div>
        <div class="size-18 fw-ekstra-bold mt-4">{{ addModal ? "Tambah Staff" : "Ubah Data" }}</div>
        <div>{{ addModal ? "Masukan data lengkap staff baru" : "Ubah data staff" }}</div>
        <div class="line"></div>
        <form>
          <div class="">
            <div class="size-16 mt-3 mb-1">Nama Lengkap</div>
            <input class="mb-2 form-input" v-model="req.name" placeholder="Masukan nama lengkap" />
            <div class="d-flex gap-4 mb-2">
              <div style="width: 100%">
                <div class="size-16 mb-1">Username</div>
                <input class="form-input" v-model="req.username" placeholder="Masukan Username" />
              </div>
              <div style="width: 100%">
                <div class="size-16 mb-1">No.Telephone</div>
                <input class="form-input" type="number" v-model="req.phone" placeholder="Nomer Telephone (10-13 digit)" />
              </div>
            </div>
            <div class="d-flex gap-4 mb-2">
              <div style="width: 100%">
                <div class="size-16 mb-1">Email</div>
                <input class="form-input" type="email" v-model="req.email" placeholder="Masukan Email Anda" />
              </div>
              <div style="width: 100%">
                <div class="size-16 mb-1">Password</div>
                <div class="mb-3 position-relative">
                  <input :type="[showPassword == true ? 'text' : 'password']" class="form-input" v-model="req.password" placeholder="Password" />
                  <span class="cursor-pointer eye-icon" @click="toggleShow">
                    <i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="size-16 mb-3">Tipe Staff</div>
              <div class="col-md-6">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="req.role" id="inlineCheckbox1" value="cashier" />
                  <label class="form-check-label" for="inlineCheckbox1">Kasir</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="req.role" id="inlineCheckbox2" value="customer-service" />
                  <label class="form-check-label" for="inlineCheckbox2">Customer Service</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="req.role" id="inlineCheckbox3" value="warehouse" />
                  <label class="form-check-label" for="inlineCheckbox3">Gudang</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="req.role" id="inlineCheckbox4" value="driver" />
                  <label class="form-check-label" for="inlineCheckbox4">Driver</label>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex gap-2 mt-5">
            <button class="button button-cancel" @click="cancel" :disabled="isSaving">Batal</button>
            <button v-if="editModal" type="submit" class="button button-save" @click="editData" :disabled="isSaving">
              <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
              Simpan
            </button>
            <button v-if="addModal" type="submit" class="button button-save" @click="addData" :disabled="isSaving">
              <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
              Tambahkan
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="deleteModal" @click="deleteModal = false"></div>
      <div class="modal-body-chat sm" v-if="deleteModal">
        <div class="d-flex align-items-lg-center justify-content-center">
          <img class="me-3" src="../../assets/delete-circle-icon.png" />
          <div class="size-18 fw-ekstra-bold">Request Pencairan</div>
        </div>
        <hr />
        <div class="d-flex align-items-lg-center justify-content-center fw-bold my-5">Apakah Anda yakin ingin menghapus data ini?</div>
        <div class="d-flex gap-2">
          <button class="button button-cancel" @click="deleteModal = false" :disabled="isSaving">Batal</button>
          <button class="button button-delete" @click="deleteData" :disabled="isSaving">
            <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
            Ya
          </button>
        </div>
      </div>
    </div>
    <PopupNotif :isModal="isModal" :title="title" :info="info" @send="receive"></PopupNotif>
  </div>
</template>
<script>
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";
import PopupNotif from "./PopupNotif.vue";

export default {
  props: {
    items: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ListLoader,
    PopupNotif,
  },
  data() {
    return {
      moment: moment,
      isSaving: false,
      showPassword: false,
      is_my_list_ready: false,
      isModal: "",
      title: "",
      info: "",
      editModal: false,
      deleteModal: false,
      addModal: false,
      keySearch: "",
      hash: "",
      data: "",
      req: {
        name: "",
        username: "",
        phone: "",
        email: "",
        password: null,
        role: [],
      },
      currentPage: 0,
      totalPage: 0,
      limit: 10,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    searchData() {
      this.getData();
    },
    changePage() {
      this.getData();
    },
    receive(data) {
      this.isModal = data;
    },
    close() {
      this.addModal = false;
      this.editModal = false;
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    setEdit(item) {
      this.req.name = item.name;
      this.req.username = item.username;
      this.req.email = item.email;
      this.req.phone = item.phone;
      this.req.role = item.role;
      this.editModal = true;
      this.hash = item.hash;
    },
    setDelete(hash) {
      this.deleteModal = true;
      this.hash = hash;
    },
    setModalAdd() {
      this.clear();
      this.addModal = true;
    },
    cancel() {
      this.close();
      this.clear();
    },
    clear() {
      this.req.name = "";
      this.req.username = "";
      this.req.email = "";
      this.req.phone = "";
      this.req.role = [];
      this.req.password = "";
    },
    getData() {
      // this.isModal = "";
      this.is_my_list_ready = true;
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/asset`, {
        params: {
          page: this.currentPage == 0 || this.currentPage == null ? 1 : this.currentPage,
          per_page: this.limit,
          search: this.keySearch ? this.keySearch : "''",
          type: "employee",
          driver: 0,
        },
        // params: { type: "employee", driver: 0 },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data = res.data.data;
          this.totalPage = res.data.last_page;
          this.is_my_list_ready = false;
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.is_my_list_ready = false;
        });
    },
    addData() {
      this.isSaving = true;
      var data = new FormData();

      data.append("name", this.req.name);
      data.append("username", this.req.email);
      data.append("email", this.req.email);
      data.append("phone", this.req.phone);
      data.append("password", this.req.password);
      this.req.role.forEach((el, index) => {
        data.append(`role[${index}]`, el);
      });
      Api.post(`${process.env.VUE_APP_BASE_URL}/partner/asset/employee`, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.addModal = false;
          this.isSaving = false;
          // this.$notify({
          //   group: "foo",
          //   type: "success",
          //   title: "Success",
          //   text: "Data berhasil di edit",
          // });
          this.title = "Data Berhasil Ditambahkan!";
          this.info = "Selamat! Anda telah berhasil menambahkan data.";
          this.isModal = "success";
          this.getData();
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          // this.addModal = false;
          this.title = "Data Gagal Ditambahkan!";
          this.info = "Data gagal ditambahkan! Silakan ulangi kembali";
          this.isModal = "fail";
          this.isSaving = false;
        });
    },
    editData() {
      this.isSaving = true;
      //   var data = JSON.stringify({
      //     name: this.req.name,
      //     username: this.req.email,
      //     email: this.req.email,
      //     phone: this.req.phone,
      //     password: this.req.password,
      //     role: this.req.role
      //   })
      var data = new FormData();
      data.append("name", this.req.name);
      data.append("username", this.req.username);
      data.append("email", this.req.email);
      data.append("phone", this.req.phone);
      if (this.req.password) {
        data.append("password", this.req.password);
      }
      this.req.role.forEach((el, index) => {
        data.append(`role[${index}]`, el);
      });

      Api.post(`${process.env.VUE_APP_BASE_URL}/partner/asset/employee/${this.hash}/update`, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.editModal = false;
          this.isSaving = false;
          // this.$notify({
          //   group: "foo",
          //   type: "success",
          //   title: "Success",
          //   text: "Data berhasil di edit",
          // });
          this.title = "Data Berhasil Diperbarui!";
          this.info = "Selamat! Anda telah berhasil memperbarui data.";
          this.isModal = "success";
          this.getData();
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.editModal = false;
          this.title = "Data Gagal Diperbarui!";
          this.info = "Data gagal diperbarui! Silakan ulangi kembali";
          this.isModal = "fail";
          this.isSaving = false;
        });
    },
    deleteData() {
      this.isSaving = true;

      Api.delete(`${process.env.VUE_APP_BASE_URL}/partner/asset/employee/${this.hash}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.deleteModal = false;
          this.isSaving = false;
          // this.$notify({
          //   group: "foo",
          //   type: "success",
          //   title: "Success",
          //   text: "Data berhasil di hapus",
          // });
          this.title = "Data Berhasil Dihapus!";
          this.info = "Selamat! Anda telah berhasil menghapus data.";
          this.isModal = "success";
          this.getData();
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.deleteModal = false;
          this.title = "Data Gagal Dihapus!";
          this.info = "Data gagal dihapus! Silakan ulangi kembali";
          this.isModal = "fail";
          this.isSaving = false;
        });
    },
    dateFormat(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    currency(number) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(number);
    },
    roleEmployee(item) {
      return item.role?.length > 0 ? item.role.join(", ") : "-";
    },
    itemNumber(index) {
      let number = index + this.startIndex + 1;
      return (number < 10 ? "0" : "") + number;
    },
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.limit;
    },
  },
};
</script>
<style lang="scss" scoped>
.add-button {
  background: #48a2d4;
  border-radius: 8px;
  border: 1px solid #48a2d4;
  color: white;
  padding: 10px 15px;
}
.add-button:is(:hover, :focus) {
  background: #176f9e;
}
.button {
  display: flex;
  border-radius: 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  &-save {
    background: #48a2d4;
    border: 1px solid #48a2d4;
    color: white;
  }
  &-save:is(:hover, :focus) {
    background: #176f9e;
  }
  &-delete {
    background: #e60013;
    border: 1px solid #e60013;
    color: white;
  }
  &-delete:is(:hover, :focus) {
    background: #e94b58;
  }
  &-cancel {
    background: #ffffff;
    border: 1px solid #e94b58;
    color: #e94b58;
  }
  &-cancel:is(:hover, :focus) {
    background: #e94b58;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
}
.form-input {
  background: rgba(239, 241, 249, 0.6);
  border-width: 0px;
  border: none;
  outline: none;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  padding: 10px;
}
.eye-icon {
  position: absolute;
  top: 10px;
  right: 12px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
input[type="checkbox"] {
  accent-color: green !important;
}
</style>
