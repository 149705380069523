<template>
    <div class="page-sidebar">
        <div class="p-4 mt-1">
            <center>
                <span class="logo-white">
                    <img :src="logo" class="m-none mt-3 mb-3" alt="" />
                </span>
                <div class="size-18 fw-medium mt-2">Trobens Admin</div>
            </center>
        </div>
        <ul class="sidebar-menu" id="system-menu">
            <li
                :class="{
                    'sidebar-menu-list has-child': true,
                    active: this.$route.name == 'Dashboard',
                }"
            >
                <router-link
                    :to="{ name: 'Dashboard' }"
                    class="has-child sidebar-menu-link"
                >
                    <i class="fa fa-home me-2"></i>
                    Dashboard
                </router-link>
            </li>
            <li class="sidebar-menu-list has-childa line m-none"></li>
            <li class="sidebar-menu-list has-child">
                <div class="has-child sidebar-menu-link text-gray">
                    TRAWLBENS PAGES
                </div>
            </li>
            <li
                :class="{
                    'sidebar-menu-list has-child': true,
                    active: this.$route.name == 'Chat',
                }"
            >
                <router-link
                    :to="{ name: 'Chat' }"
                    class="has-child sidebar-menu-link"
                >
                    <i class="fa fa-wechat me-2"></i>
                    Chat
                </router-link>
            </li>
            <li class="sidebar-menu-list has-child">
                <router-link
                    :to="{ name: 'admin-slider' }"
                    class="has-child sidebar-menu-link"
                >
                    <i class="fa fa-bar-chart-o me-2"></i>
                    Chat Report
                </router-link>
            </li>
            <li class="sidebar-menu-list has-child">
                <router-link
                    :to="{ name: 'admin-slider' }"
                    class="has-child sidebar-menu-link"
                >
                    <i class="fa fa-money me-2"></i>
                    Pembayaran Customer
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import logo from "../assets/logo-white.png";

export default {
    setup() {
        return {
            logo: logo,
        };
    },
};
</script>

<style lang="scss" scoped></style>
