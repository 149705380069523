<template>
  <div class="padding-container">
    <div class="d-flex bd-highlight mb-3 align-items-center">
      <div class="flex-grow-1 bd-highlight">
        <h4 class="fw-bold">Manajemen Driver</h4>
      </div>
      <div class="bd-highlight">
        <button class="add-button" @click="showModal = true">
          <span class="me-3"><img class="pb-1" src="../../assets/plus-icon.png" /></span> Tambah Driver
        </button>
      </div>
    </div>
    <div class="box">
      <div class="d-flex bd-highlight">
        <div class="flex-grow-1 bd-highlight">
          <h4 class="fw-bold">List Driver</h4>
        </div>
        <div class="row">
          <div class="col-md-6">
            <select name="" @change="filterData" class="form-control form-select" v-model="filter_status" style="width: 125px">
              <option value="" selected>Filter Status</option>
              <option value="open">Aktif</option>
              <option value="close">Tidak aktif</option>
            </select>
          </div>
          <div class="col-md-6">
            <div class="position-relative">
              <input v-model="keySearch" @keyup.enter="searchData" type="text" class="form-control" style="width: 125px" placeholder="Cari Driver" />
              <span class="search-icon">
                <i class="fa fa-search"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4" v-if="is_my_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">Nama Driver</div>
            <div class="d-table-cell">Nomor Telepon</div>
            <div class="d-table-cell">Email Driver</div>
            <div class="d-table-cell pl-56">Status</div>
          </div>
          <div class="d-table-row" v-for="(item, index) in data" :key="index">
            <div class="d-table-cell">{{ item.no }}</div>
            <div class="d-table-cell">
              <!-- {{ item.name }} -->
              <router-link class="link-opacity-50-hover underline" :to="{ name: 'detail-management-driver', params: { id: item.id } }">{{ item.driver }}</router-link>
            </div>
            <div class="d-table-cell">
              {{ item.phone }}
              <!-- <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" :title="item.partner_address"> {{ item.partner_regency }} <i class="fa fa-info-circle text-blue ms-1"></i> </a> -->
            </div>
            <div class="d-table-cell">{{ item.email }}</div>
            <div class="d-table-cell">
              <div class="tag green" v-if="item.availability == 'open'">Aktif</div>
              <div class="tag red" v-else>Tidak Aktif</div>
            </div>
          </div>
        </div>
        <div v-if="data.length == 0" class="text-center mt-section">
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
    </div>
    <div class="overflow-auto mt-3">
      <b-pagination-nav v-if="data.length > 0" v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="showModal" @click="showModal = false"></div>
      <div class="modal-body-chat vsm" v-if="showModal">
        <div class="d-flex bd-highlight align-items-lg-center">
          <div class="flex-grow-1 bd-highlight">
            <div class="size-18 fw-ekstra-bold">Tambah Driver</div>
          </div>
          <div class="bd-highlight">
            <a href="javascript:void(0)" @click="showModal = false" class="size-20 text-primary">
              <i class="fa fa-times-circle"></i>
            </a>
          </div>
        </div>
        <div class="line"></div>
        <div class="scroll">
          <div>
            <div class="size-16 mb-1">Foto Driver</div>
            <div class="position-relative" style="width: 280px">
              <EnlargeableImage class="preview" style="width: 280px" :src="driverPictureUrl" />
              <label for="upload" class="upload-icon upload-button cursor-pointer">
                <i class="fa fa-upload"></i>
                <input class="cursor-pointer" id="upload" type="file" name="file" ref="file" @change="uploadFileDriver" />
              </label>
            </div>
          </div>
          <div>
            <div class="size-16 mt-3 mb-1">Status</div>
            <div class="mb-3">
              <div class="radio form-check form-check-inline" :style="[req.availability == 'open' ? { border: '1px solid #48a2d4' } : { border: 'none' }]" for="inlineRadio1">
                <input class="form-check-input" v-model="req.availability" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="open" @input="fillForm" />
                <label class="form-check-label" for="inlineRadio1">Aktif</label>
              </div>
              <div class="radio form-check form-check-inline" :style="[{ marginRight: '0%' }, req.availability == 'close' ? { border: '1px solid #48a2d4' } : { border: 'none' }]" for="inlineRadio2">
                <input class="form-check-input" v-model="req.availability" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="close" @input="fillForm" />
                <label class="form-check-label" for="inlineRadio2">Tidak Aktif</label>
              </div>
            </div>
            <input class="mb-3 form-input" v-model="req.name" placeholder="Nama Driver" @input="fillForm" />
            <input class="mb-3 form-input" v-model="req.dob" type="text" onfocus="(this.type='date')" onblur="(this.type='text')" id="date" placeholder="Tanggal Lahir Driver" @input="fillForm" />
            <input class="mb-3 form-input" v-model="req.identity_address" placeholder="Alamat Sesuai KTP" @input="fillForm" />
            <input class="mb-3 form-input" v-model="req.domicile_address" placeholder="Alamat Domisili" @input="fillForm" />
            <input class="mb-3 form-input" v-model="req.identity_number" type="number" placeholder="Nomor KTP" @input="fillForm" />
            <input class="mb-3 form-input" v-model="req.phone" type="number" placeholder="Nomor Telephone" @input="fillForm" />
            <input class="mb-3 form-input" v-model="req.email" type="email" placeholder="Email" @input="fillForm" />
            <div class="mb-3 position-relative">
              <input :type="[showPassword == true ? 'text' : 'password']" class="form-input" v-model="req.password" placeholder="Password" @input="fillForm" />
              <span class="cursor-pointer eye-icon" @click="toggleShow">
                <i class="fa" :class="{ 'fa-eye-slash': !showPassword, 'fa-eye': showPassword }"></i>
              </span>
            </div>
            <div class="row mb-5">
              <div class="col-md-6">
                <div class="size-16">Foto KTP</div>
                <div class="position-relative" style="width: 125px">
                  <EnlargeableImage class="preview-2" :src="identityPictureUrl" />
                  <label for="upload-identity" class="upload-icon cursor-pointer">
                    <i class="fa fa-upload"></i>
                    <input class="cursor-pointer" id="upload-identity" type="file" name="file" ref="file" @change="uploadFileIdentity" />
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="size-16">Foto SIM</div>
                <div class="position-relative" style="width: 125px">
                  <EnlargeableImage class="preview-2" :src="licensePictureUrl" />
                  <label for="upload-license" class="upload-icon cursor-pointer">
                    <i class="fa fa-upload"></i>
                    <input class="cursor-pointer" id="upload-license" type="file" name="file" ref="file" @change="uploadFileLicense" />
                  </label>
                </div>
              </div>
              <div style="color: #48a2d4">*Hanya foto KTP dan SIM saja</div>
            </div>
          </div>
        </div>
        <div class="d-flex gap-2">
          <button class="button button-cancel" @click="cancel" :disabled="isSaving">Batal</button>
          <button class="button button-save" @click="submitData" :disabled="isSaving || isFilled">
            <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
            Simpan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import moment from "moment";
import noImage from "../../assets/no-photo.png";
import EnlargeableImage from "@diracleo/vue-enlargeable-image";
import ListLoader from "../../components/ListLoader.vue";

export default {
  components: {
    ListLoader,
    EnlargeableImage,
  },
  name: "ManagementDriver",
  data() {
    return {
      showPassword: false,
      showModal: false,
      is_my_list_ready: false,
      isSaving: false,
      isFilled: true,
      data: [],
      detailData: [],
      imageDriver: "",
      imageIdentity: "",
      imageLicense: "",
      req: {
        name: "",
        email: "",
        phone: "",
        password: "",
        identity_number: "",
        identity_address: "",
        domicile_address: "",
        dob: "",
        availability: "",
        driver_picture: "",
        identity_picture: "",
        license_picture: "",
      },
      currentPage: 0,
      totalPage: 0,
      limit: 10,
      keySearch: "",
      filter_status: "",
    };
  },
  created() {
    this.getListData();
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    filterData() {
      this.currentPage = 0;
      this.getListData();
    },
    searchData() {
      this.currentPage = 0;
      this.getListData();
    },
    changePage() {
      this.getListData();
    },
    dateFormat(date) {
      return moment(date).format("ddd, DD MMM YYYY");
    },
    getListData() {
      this.is_my_list_ready = true;
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/driver/list`, {
        params: { page: this.currentPage == 0 || this.currentPage == null ? 0 : this.currentPage - 1, limit: this.limit, search: this.keySearch, availability: this.filter_status },
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data = res.data.data.list_data;
          this.totalPage = res.data.data.total_page;
          this.is_my_list_ready = false;
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.is_my_list_ready = false;
        });
    },
    submitData() {
      this.isSaving = true;
      var data = new FormData();

      data.append("name", this.req.name);
      data.append("username", this.req.email);
      data.append("email", this.req.email);
      data.append("phone", this.req.phone);
      data.append("password", this.req.password);
      data.append("availability", this.req.availability);
      data.append("identity_number", this.req.identity_number);
      data.append("identity_address", this.req.identity_address);
      data.append("domicile_address", this.req.domicile_address);
      data.append("dob", this.req.dob);
      data.append("driver_picture", this.req.driver_picture);
      data.append("identity_picture", this.req.identity_picture);
      data.append("license_picture", this.req.license_picture);

      Api.post(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/driver`, data, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.showModal = false;
          this.isSaving = false;
          this.$notify({
            group: "foo",
            type: "success",
            title: "Success",
            text: "Data berhasil dibuat",
          });
          this.getListData();
          this.clear();
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.isSaving = false;
        });
    },
    cancel() {
      this.clear();
      this.showModal = false;
    },
    clear() {
      this.req.name = "";
      this.req.email = "";
      this.req.phone = "";
      this.req.password = "";
      this.req.availability = "";
      this.req.identity_number = "";
      this.req.identity_address = "";
      this.req.domicile_address = "";
      this.req.dob = "";
      this.req.driver_picture = "";
      this.req.identity_picture = "";
      this.req.license_picture = "";
      this.imageDriver = "";
      this.imageIdentity = "";
      this.imageLicense = "";
    },
    fillForm() {
      if (
        this.req.name != "" &&
        this.req.email != "" &&
        this.req.phone != "" &&
        this.req.password != "" &&
        this.req.availability != "" &&
        this.req.identity_number != "" &&
        this.req.identity_address != "" &&
        this.req.domicile_address != "" &&
        this.req.dob != "" &&
        this.req.driver_picture != "" &&
        this.req.identity_picture != "" &&
        this.req.license_picture != ""
      ) {
        this.isFilled = false;
      } else {
        this.isFilled = true;
      }
    },
    currency(number) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(number);
    },
    uploadFileDriver(event) {
      var input = event.target;
      this.req.driver_picture = input.files[0];
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageDriver = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
      this.fillForm();
    },
    uploadFileIdentity(event) {
      var input = event.target;
      this.req.identity_picture = input.files[0];

      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageIdentity = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
      this.fillForm();
    },
    uploadFileLicense(event) {
      var input = event.target;
      this.req.license_picture = input.files[0];

      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageLicense = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
      this.fillForm();
    },
  },
  computed: {
    driverPictureUrl() {
      return this.imageDriver.length > 0 ? this.imageDriver : noImage;
    },
    identityPictureUrl() {
      return this.imageIdentity.length > 0 ? this.imageIdentity : noImage;
    },
    licensePictureUrl() {
      return this.imageLicense.length > 0 ? this.imageLicense : noImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.row.foto {
  --bs-gutter-x: 0rem !important;
}
.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 134px;
  height: 30px;
}
.add-button {
  background: #48a2d4;
  border-radius: 8px;
  border: 1px solid #48a2d4;
  color: white;
  padding: 10px 15px;
}
.add-button:is(:hover, :focus) {
  background: #176f9e;
}
.form-input {
  background: rgba(239, 241, 249, 0.6);
  border-width: 0px;
  border: none;
  outline: none;
  border-radius: 10px;
  width: 280px;
  height: 40px;
  padding: 10px;
}
.insert-image {
  border-width: 0px;
  border: none;
  outline: none;
}
.button {
  display: flex;
  border-radius: 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 168px;
  height: 44px;
  &-save {
    background: #48a2d4;
    border: 1px solid #48a2d4;
    color: white;
  }
  &-save:is(:hover, :focus) {
    background: #176f9e;
  }
  &-save:disabled {
    background: #e8e8e9;
    border: 1px solid #ffffff;
  }
  &-cancel {
    background: #ffffff;
    border: 1px solid #e94b58;
    color: #e94b58;
  }
  &-cancel:is(:hover, :focus) {
    background: #e94b58;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
}
.radio {
  background: rgba(239, 241, 249, 0.6);
  border-radius: 10px;
  width: 133px;
  height: 35px;
  padding-top: 2%;
  padding-left: 10%;
}
.eye-icon {
  position: absolute;
  top: 10px;
  right: 12px;
}
input[type="file"] {
  display: none;
}
.preview :deep(img) {
  width: 280px;
  height: 120px;
  object-fit: cover;
}
.preview-2 :deep(img) {
  width: 125px;
  height: 100px;
  object-fit: cover;
}
.underline {
  text-decoration: underline;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
