<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/trawltruck/ho/driver" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">
                    Ubah Data Driver
                </h3>
            </div>
            <div class="row mt-5 m-mt-2">
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Nama Driver</label>
                    <input type="text" class="form-control" placeholder="Masukkan Nama Driver" v-model="req.driver_name">
                </div>
                <div class="col-md-6 m-mt-1">
                    <label class="fw-bold mb-2">Nama Mitra</label>
                    <select class="form-control form-select" disabled v-model="req.partner_id">
                        <option value="">Pilih Mitra</option>
                        <template v-for="(mitra, index) in mitras">
                            <option :value="mitra.id" :key="index">
                                {{ mitra.name }}
                            </option>
                        </template>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nomor KTP</label>
                    <input type="text" class="form-control" placeholder="Masukkan Nomor KTP" v-model="req.identity_number">
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nomor Telepon</label>
                    <input type="number" class="form-control" placeholder="Masukkan  Nomor Telepon" v-model="req.driver_phone" disabled>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Tanggal Lahir</label>
                    <input type="date" class="form-control" v-model="req.dob">
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Email</label>
                    <input type="email" class="form-control" placeholder="Masukkan Alamat Email" v-model="req.driver_email" disabled>
                </div>
                <!-- <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Username</label>
                    <input type="text" class="form-control" placeholder="Masukkan Username" v-model="req.username">
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Password</label>
                    <div class="position-relative">
                        <input type="text" class="form-control" id="password" placeholder="Password" v-if="showPassword" />
                        <input type="password" class="form-control" id="password" placeholder="Password" v-else />
                        <span class="search-icon cursor-pointer" @click="toggleShow">
                            <i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                        </span>
                    </div>
                </div> -->
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Alamat</label>
                    <textarea rows="5" cols="4" class="form-control" placeholder="Masukkan Alamat" v-model="req.domicile_address"></textarea>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Alamat KTP</label>
                    <textarea rows="5" cols="4" class="form-control" placeholder="Masukkan Alamat KTP" v-model="req.identity_address"></textarea>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Status</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="accept" value="accept" v-model="req.status">
                            <label class="form-check-label fw-bold" for="accept">Aktif</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="reject" value="reject" v-model="req.status">
                            <label class="form-check-label fw-bold" for="reject">Tidak Aktif</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold">Foto Driver</label>
                    <div class="text-gray mb-2">
                        Foto Close Up maks 5MB
                    </div>
                    <div class="image-view-1" :style="{'background-image': 'url(' + (req.driver_picture_url) + ')'}">
                        <div class="mt-4 ml-4">
                            <label for="driver_picture" class="upload-button single-image"> 
                                <i class="fa fa-upload"></i>
                                <input type="file" id="driver_picture" @change="uploadDriver()">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold">Foto KTP</label>
                    <div class="text-gray mb-2">
                        Full KTP maks 5MB
                    </div>
                    <div class="image-view-2" :style="{'background-image': 'url(' + (req.driver_picture_url) + ')'}">
                    </div>
                </div>  
                <div class="col-md-4 mt-3">
                    <label class="fw-bold">Foto SIM</label>
                    <div class="text-gray mb-2">
                        Full SIM maks 5MB
                    </div>
                    <div class="image-view-3" :style="{'background-image': 'url(' + (req.license_picture_url) + ')'}">
                    </div>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <!-- <a href="/trawltruck/ho/partner" class="btn btn-outline-black btn-lg px-5 me-3">Batal</a> -->
                            <a href="/trawltruck/ho/driver" class="btn btn-outline-black btn-lg px-5 me-3 m-w-100">Batal</a>
                            <button class="btn btn-green btn-lg px-5 me-3 m-w-100 m-mt-1" type="button" disabled v-if="is_save">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Update
                            </button>
                            <button class="btn btn-green btn-lg px-5 me-3 m-w-100 m-mt-1" @click="updateData()" v-else>Update</button>

                            <!-- <a href="javascript:void(0)" class="btn btn-primary btn-lg px-5" @click="modalSuccess = true">Update</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img src="../../assets/dashboard/checklis.png" width="80" alt="">
                    <h4 class="mb-0 fw-bold mt-4">
                        Perubahan Data Berhasil!
                    </h4>
                    <div class="mt-2">
                        Selamat! Anda berhasil merubah data.
                    </div>
                </div>
                <div class="mt-4">
                    <a href="/trawltruck/ho/driver" class="btn btn-green w-100 btn-lg">
                        Ok
                    </a>
                </div>
            </div>
        </div>

        <!-- <div class="modal-vue">
            <div class="overlay" v-if="modalError"></div>
            <div class="modal-body-chat vsm" v-if="modalError">
                <div class="mt-4 text-center">
                    <img src="../../assets/dashboard/close.png" width="80" alt="">
                    <h4 class="mb-0 fw-bold mt-4">
                        Penambahan Data Gagal!
                    </h4>
                    <div class="mt-2">
                        Mohon maaf terjadi kesalahan! Silakan ulangi kembali
                    </div>
                </div>
                <div class="mt-4">
                    <a href="javascript:void(0)" @click="modalError = false" class="btn btn-primary w-100 btn-lg">
                        Ok
                    </a>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
    import $ from 'jquery'
    import Api from "../../api/Api";

    export default {
        props: "",
        name: "ManagementDriversEdit",
        data() {
            return {
                showPassword: false,
                is_changing_driver: false,
                req: {
                },
                modalSuccess: false,
                modalError: false,
                mitras: [],
                is_save: false
            };
        },
        created(){
            this.getMitra()
            this.getDetail()
        },
        methods: {
            toggleShow() {
                this.showPassword = !this.showPassword;
            },
            uploadDriver() {
                var driver_picture = new FileReader();
                driver_picture.onload = function (e) {
                    $('.image-view-1').css('background-image', 'url('+ e.target.result + ')')
                };
                driver_picture.readAsDataURL($('#driver_picture')[0].files[0]);
                this.is_changing_driver = true
            },
            getMitra() {
                Api.get(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partner/list`, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    var data = res.data.data
                    this.mitras = data
                })
                .catch((err) => {
                    console.log(err);
                });
            },
            getDetail() {
                Api.get(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/driver/detail?id=${this.$route.params.id}`, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    var data = res.data.data
                    this.req = data
                })
                .catch((err) => {
                    console.log(err);
                });
            },
            updateData(){
                var driver_picture = $('#driver_picture')[0].files[0]
                var data = new FormData()

                data.append('id', this.req.id)
                data.append('name', this.req.driver_name)
                data.append('status', this.req.status)
                data.append('identity_number', this.req.identity_number)
                data.append('identity_address', this.req.identity_address)
                data.append('domicile_address', this.req.domicile_address)
                data.append('dob', this.req.dob)

                if(this.is_changing_driver) {
                    data.append('driver_picture',driver_picture)
                }
                this.is_save = true

                Api.post(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/driver/edit`, data, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    }
                })
                .then((res) => {
                    this.is_save = false
                    this.modalSuccess = true
                    this.is_changing_driver = false
                    console.log(res)
                })
                .catch((err) => {
                    this.is_save = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.message
                    });
                })
            }
        }
    };
</script>

<style scoped>
    .text-main-color{
        color: #24516A !important;
    }
    .text-gray-dashboard{
        color: #61616A;
    }
</style>