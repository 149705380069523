<template>
  <div class="padding-container">
    <div class="d-flex bd-highlight mb-3 align-items-center">
      <div class="flex-grow-1 bd-highlight">
        <h4 class="fw-bold">List Data Transaksi</h4>
      </div>
      <div class="bd-highlight">
        <button class="add-button d-flex justify-content-center align-items-center" style="width: 150px" @click="showModal = true"><img class="me-3" src="../../assets/plus-icon.png" /> Top Up</button>
      </div>
    </div>
    <div class="box">
      <div class="d-flex bd-highlight">
        <div class="flex-grow-1 bd-highlight">
          <div class="d-flex gap-2">
            <h4 class="fw-bold">List Transaksi</h4>
            <div class="tag green">Total Saldo : {{ currency(dataBalance) }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <input class="form-control" style="width: 120px" type="text" onfocus="(this.type='date')" v-model="datePicker" @change="searchData" placeholder="Pilih Tanggal" />
          </div>
        </div>
      </div>
      <div class="mt-4" v-if="is_my_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">Tanggal Top up</div>
            <div class="d-table-cell">Nominal Top up</div>
            <div class="d-table-cell pad-stat">Status</div>
            <div class="d-table-cell pad-act">Action</div>
          </div>
          <div class="d-table-row" v-for="(item, index) in data" :key="index">
            <div class="d-table-cell">{{ item.no }}</div>
            <div class="d-table-cell">
              {{ dateFormat(item.topup_date) }}
              <!-- <router-link class="text-dark fw-bold" :to="{ name: items.url, params: { partnerid: item.partner_code } }">{{ item.partner_code }}</router-link> -->
            </div>
            <div class="d-table-cell">
              {{ currency(item.amount) }}
              <!-- <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" :title="item.partner_address"> {{ item.partner_regency }} <i class="fa fa-info-circle text-blue ms-1"></i> </a> -->
            </div>
            <div class="d-table-cell">
              <span class="tag tag2" :class="[item.status == 'success' ? 'green' : item.status == 'pending' ? 'yellow' : item.status == 'expired' ? 'red' : '']">
                {{ setStatus(item.status) }}
              </span>
            </div>
            <div class="d-table-cell d-flex align-items-center">
              <button type="button" class="add-button d-flex justify-content-center align-items-center" style="width: 127px; height: 30px" @click="toggleModelDetail(item)">Detail</button>
            </div>
          </div>
        </div>
        <div v-if="data.length == 0" class="text-center mt-section">
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
    </div>
    <div class="overflow-auto mt-3">
      <b-pagination-nav v-if="data.length > 0" v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="showModalDetail" @click="showModalDetail = false"></div>
      <div class="modal-body-chat sm" v-if="showModalDetail">
        <div class="position-relative">
          <a href="javascript:void(0)" @click="showModalDetail = false" class="size-20 text-primary position-absolute top-0 end-0">
            <i class="fa fa-times-circle"></i>
          </a>
        </div>
        <div class="d-flex bd-highlight align-items-lg-center">
          <div class="flex-grow-1 bd-highlight">
            <img v-if="detailType == 'success'" src="../../assets/checklist.png" />
            <img v-if="detailType == 'expired'" src="../../assets/cross-icon.png" />
            <img v-if="detailType == 'pending'" src="../../assets/exclamation-icon.png" />
          </div>
        </div>
        <div class="size-18 fw-ekstra-bold" style="margin-top: 16px; margin-bottom: 20px">
          {{ detailType == "success" ? "Top up Berhasil" : detailType == "expired" ? "Top up Gagal" : "Top up Sedang Dalam Proses" }}
        </div>
        <div v-if="detailType == 'success' || detailType == 'expired'">
          <div class="d-flex justify-content-between">
            <div class="fw-ekstra-bold size-17">Tanggal Top up</div>
            <div>{{ dateFormat(detailData.topup_date) }}</div>
          </div>
          <div class="d-flex justify-content-between my-2">
            <div class="fw-ekstra-bold size-17">Nominal Top up</div>
            <div>{{ currency(detailData.amount) }}</div>
          </div>
          <div class="d-flex justify-content-between mb-4">
            <div class="fw-ekstra-bold size-17">Nama Bank</div>
            <div>{{ codeBank(detailData.payment.bank) }}</div>
          </div>
        </div>
        <div v-if="detailType == 'pending'">
          <div class="bg-box d-flex flex-column align-items-center justify-content-center">
            <div class="fw-bold size-18" style="margin-top: 16px">Nomor VA</div>
            <div class="fw-ekstra-bold size-20" style="color: #fb9727; margin-bottom: 16px; margin-top: 12px">{{ detailData.payment.va }}</div>
          </div>
          <div class="d-flex justify-content-between mb-2 mt-3">
            <div class="fw-ekstra-bold size-17">Nama Bank</div>
            <div>{{ codeBank(detailData.payment.bank) }}</div>
          </div>
          <div class="d-flex justify-content-between mb-4">
            <div class="fw-ekstra-bold size-17">Nominal Top up</div>
            <div>{{ currency(detailData.amount) }}</div>
          </div>
        </div>
        <div>
          <button class="button button-save" style="width: 100%" @click="showModalDetail = false">Oke</button>
        </div>
      </div>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="showModal">
        <div class="modal-body-chat sm" v-if="showModal">
          <div class="d-flex bd-highlight align-items-lg-center mb-2">
            <div class="flex-grow-1 bd-highlight">
              <div class="size-18 fw-bold mt-2">Top Up</div>
            </div>
            <div class="bd-highlight">
              <a href="javascript:void(0)" @click="showModal = false" class="size-20 text-primary">
                <i class="fa fa-times-circle"></i>
              </a>
            </div>
          </div>
          <div class="scroll p-1">
            <div class="my-3" style="color: #8b8d97">Tambahkan Informasi untuk Top up</div>
            <div class="bg-cstm fw-normal ps-3 p-2 size-16" style="border: 1px solid #d2d2d5">MB-JKT-001</div>
            <div class="d-flex gap-1 mt-4">
              <input type="radio" class="btn-check" name="options" id="option1" v-model="req.amount" @change="fillForm" value="50000" autocomplete="off" />
              <label class="btn bg-cstm size-50 py-2 w-100" for="option1">50<span>.000</span></label>

              <input type="radio" class="btn-check" name="options" id="option2" v-model="req.amount" @change="fillForm" value="100000" autocomplete="off" />
              <label class="btn bg-cstm size-50 py-2 w-100" for="option2">100<span>.000</span></label>
            </div>

            <div class="d-flex gap-1 mt-3">
              <input type="radio" class="btn-check" name="options" id="option3" v-model="req.amount" @change="fillForm" value="250000" autocomplete="off" />
              <label class="btn bg-cstm size-50 py-2 w-100" for="option3">250<span>.000</span></label>

              <input type="radio" class="btn-check" name="options" id="option4" v-model="req.amount" @change="fillForm" value="500000" autocomplete="off" />
              <label class="btn bg-cstm size-50 py-2 w-100" for="option4">500<span>.000</span></label>
            </div>
            <div class="form-check d-flex justify-content-between flex-row-reverse size-16 my-4 px-4">
              <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" @click="showInputManual = !showInputManual" />
              <label class="form-check-label size-16" for="defaultCheck1"> Isi Nominal Lain </label>
            </div>
            <div v-show="showInputManual">
              <div class="fw-bold mb-2">Isi Nominal Top Up</div>
              <money v-model="req.amount" @input="fillForm" v-bind="money" class="form-input mb-3 p-2"> </money>
            </div>
            <div class="fw-bold mb-2">Pilih Metode Pembayaran</div>
            <select class="form-input mb-3 p-2" v-model="req.bank" @input="fillForm">
              <option value="" selected>Pilih Bank</option>
              <option v-for="(item, index) in bankData" :key="index" :value="item.channel">{{ codeBank(item.name_label) }}</option>
            </select>
          </div>
          <div class="d-flex gap-2">
            <button class="button button-cancel" @click="cancel" :disabled="isSaving">Batal</button>
            <button class="button button-save" @click="submitData" :disabled="isSaving || isFilled">
              <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";
import { Money } from "v-money";

export default {
  props: {},
  components: {
    ListLoader,
    Money,
  },
  name: "ManagementOrder",
  data() {
    return {
      showModal: false,
      showModalDetail: false,
      showInputManual: false,
      is_my_list_ready: false,
      isSaving: false,
      isFilled: true,
      detailType: "",
      data: [],
      dataBalance: 0,

      detailData: [],
      driverData: [],
      fleetData: [],
      address: "",
      req: {
        amount: 0,
        bank: "",
      },
      currentPage: 0,
      totalPage: 0,
      limit: 10,
      keySearch: "",
      datePicker: "",
      filter_status: "",
      bankData: "",
      dataBank: ["bri", "bca", "mandiri", "bni", "bsi", "mega", "permata", "cimb", "danamon", "btpn", "maybank", "ocbc"],
      dummy: [
        {
          Tanggal_Top_up: "11 Januari 2022",
          Nominal_Top_up: "Rp 65.000.000",
          Status: "Menunggu Pembayaran",
        },
        {
          Tanggal_Top_up: "12 Januari 2022",
          Nominal_Top_up: "Rp 75.000.000",
          Status: "Expired",
        },
        {
          Tanggal_Top_up: "13 Januari 2022",
          Nominal_Top_up: "Rp 85.000.000",
          Status: "Berhasil",
        },
      ],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "Rp. ",
        suffix: "",
        precision: 0,
        masked: false,
      },
      month: ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"],
      year: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
    };
  },
  created() {
    this.getListData();
    this.getBalance();
    this.getBank();
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
  },
  methods: {
    searchData() {
      this.currentPage = 0;
      this.getListData();
      this.getBalance();
    },
    changePage() {
      this.getListData();
    },
    dateFormat(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    getListData() {
      this.is_my_list_ready = true;
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/topup/list`, {
        params: { page: this.currentPage == 0 || this.currentPage == null ? 0 : this.currentPage - 1, limit: this.limit, date: this.datePicker },
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data = res.data.data.list_data;
          this.totalPage = res.data.data.total_page;
          this.is_my_list_ready = false;
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          this.is_my_list_ready = false;
          console.log(err);
        });
    },
    getBank() {
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/topup/bank`, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.bankData = res.data.data;
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          console.log(err);
        });
    },
    getBalance() {
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/balance`, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.dataBalance = res.data.data;
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          console.log(err);
        });
    },
    toggleModelDetail(item) {
      this.detailType = item.status;
      this.detailData = item;
      this.showModalDetail = true;
    },
    clear() {
      this.req.amount = 0;
      this.req.bank = "";
      this.fillForm();
    },
    fillForm() {
      if (this.req.amount != 0 && this.req.bank != "") {
        this.isFilled = false;
      } else {
        this.isFilled = true;
      }
    },
    cancel() {
      this.clear();
      this.showModal = false;
    },
    setStatus(value) {
      switch (value) {
        case "pending":
          return "Menunggu Pembayaran";
        case "expired":
          return "Expired";
        case "success":
          return "Berhasil";
        default:
          return value;
      }
    },
    submitData() {
      this.isSaving = true;
      var data = new FormData();
      data.append("amount", this.req.amount);
      data.append("bank", this.req.bank);

      Api.post(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/topup`, data, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.showModal = false;
          this.isSaving = false;
          this.$notify({
            group: "foo",
            type: "success",
            title: "Success",
            text: "Data berhasil dibuat",
          });
          this.getListData();
          this.clear();
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.isSaving = false;
        });
    },
    codeBank(code) {
      var bank;
      switch (code) {
        case "bri":
          bank = "Bank Rakyat Indonesia";
          break;
        case "bca":
          bank = "Bank Central Asia";
          break;
        case "mandiri":
          bank = "Bank Mandiri";
          break;
        case "bni":
          bank = "Bank Negara Indonesia";
          break;
        case "bsi":
          bank = "Bank Syariah Indonesia";
          break;
        case "mega":
          bank = "Bank Mega";
          break;
        case "permata":
          bank = "Bank Permata";
          break;
        case "cimb":
          bank = "Bank CIMB Niaga";
          break;
        case "danamon":
          bank = "Bank Danamon Indonesia";
          break;
        case "btpn":
          bank = "Bank BTPN";
          break;
        case "maybank":
          bank = "Bank Maybank Indonesia";
          break;
        case "ocbc":
          bank = "Bank OCBC NISP";
          break;
        default:
          bank = code.toUpperCase();
      }
      return bank;
    },
    currency(number) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(number);
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll {
  height: 500px;
  overflow-y: scroll;
}
.row.foto {
  --bs-gutter-x: 0rem !important;
}
.add-button {
  background: #48a2d4;
  border-radius: 8px;
  border: 1px solid #48a2d4;
  color: white;
  padding: 10px 15px;
}
.add-button:is(:hover, :focus) {
  background: #176f9e;
}
.form-input {
  background: rgba(239, 241, 249, 0.6);
  border-width: 0px;
  border: none;
  outline: none;
  border-radius: 10px;
  width: 100%;
  height: 40px;
}
.tag2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 203px;
  height: 30px;
}
.button {
  display: flex;
  border-radius: 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  &-save {
    background: #48a2d4;
    border: 1px solid #48a2d4;
    color: white;
  }
  &-save:is(:hover, :focus) {
    background: #176f9e;
  }
  &-save:disabled {
    background: #e8e8e9;
    border: 1px solid #ffffff;
  }
  &-cancel {
    background: #ffffff;
    border: 1px solid #e94b58;
    color: #e94b58;
  }
  &-cancel:is(:hover, :focus) {
    background: #e94b58;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
}
.bg-box {
  background: #feead4;
  border: 1px solid #fedcb7;
  border-radius: 8px;
}
.bg-cstm {
  background-color: #f6f7fb;
  border-radius: 8px;
}
.btn-check input[type="radio"].btn-check-input:active + label.btn-check-label::before {
  background: #48a2d4 !important;
  border-color: none !important;
}
.pad-stat {
  padding-left: 89px !important;
}
.pad-act {
  padding-left: 52px !important;
}
</style>
