<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/crm/order" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Deal Order Detail</h3>
            </div>
            <div class="mt-4">
                <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                    <tr>
                        <td class="ps-0 w-200">Resi</td>
                        <td class="w-40">:</td>
                        <td>{{ detail.receipt ? detail.receipt : "-" }}</td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">Date</td>
                        <td class="w-40">:</td>
                        <td>{{ detail.date ? detail.date : "-" }}</td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">Price</td>
                        <td class="w-40">:</td>
                        <td>{{ currency(detail.price ? detail.price : 0) }}</td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">Route</td>
                        <td class="w-40">:</td>
                        <td class="capitalize">
                            {{ detail.route ? detail.route : "-" }}
                        </td>
                    </tr>
                    <tr v-if="$route.query.service == 'trawlpack'">
                        <td class="ps-0 w-200">Weight</td>
                        <td class="w-40">:</td>
                        <td>
                            {{ detail.pack_weight ? detail.pack_weight : 0 }}Kg
                        </td>
                    </tr>
                    <tr v-if="$route.query.service == 'trawlpack'">
                        <td class="ps-0 w-200">Item Category</td>
                        <td class="w-40">:</td>
                        <td class="capitalize">
                            <template
                                v-if="detail.pack_itemcategory == 'clothes'"
                                >Pakaian</template
                            >
                            <template
                                v-if="detail.pack_itemcategory == 'cosmetic'"
                                >Kosmetik</template
                            >
                            <template
                                v-if="detail.pack_itemcategory == 'accessories'"
                                >Aksesoris</template
                            >
                            <template
                                v-if="
                                    detail.pack_itemcategory ==
                                    'non_frozen_food'
                                "
                                >Makanan Non Frozen</template
                            >
                            <template
                                v-if="detail.pack_itemcategory == 'electronic'"
                                >Elektronik</template
                            >
                            <template
                                v-if="detail.pack_itemcategory == 'furniture'"
                                >Parabotan</template
                            >
                            <template v-if="detail.pack_itemcategory == 'bike'"
                                >Motor</template
                            >
                            <template v-if="detail.pack_itemcategory == 'other'"
                                >Lainnya</template
                            >
                        </td>
                    </tr>
                    <tr v-if="$route.query.service == 'trawlpack'">
                        <td class="ps-0 w-200">Packing</td>
                        <td class="w-40">:</td>
                        <td>
                            <template
                                v-if="detail.pack_packing == 'bubble wrap'"
                            >
                                Bubble Wrap
                            </template>
                            <template v-if="detail.pack_packing == 'cardboard'">
                                Kardus
                            </template>
                            <template v-if="detail.pack_packing == 'plastic'">
                                Plastik
                            </template>
                            <template v-if="detail.pack_packing == 'wood'">
                                Kayu
                            </template>
                            <template
                                v-if="detail.pack_packing == 'sandbag sm'"
                            >
                                Karung Kecil
                            </template>
                            <template
                                v-if="detail.pack_packing == 'sandbag md'"
                            >
                                Karung Sedang
                            </template>
                            <template v-if="detail.pack_packing == 'sandbag l'">
                                Karung Besar
                            </template>
                        </td>
                    </tr>
                    <tr v-if="$route.query.service == 'trawltruck'">
                        <td class="ps-0 w-200">Service</td>
                        <td class="w-40">:</td>
                        <td class="capitalize">
                            {{
                                detail.truck_service
                                    ? detail.truck_service
                                    : "-"
                            }}
                        </td>
                    </tr>
                    <tr v-if="$route.query.service == 'trawltruck'">
                        <td class="ps-0 w-200">Fleet</td>
                        <td class="w-40">:</td>
                        <td class="capitalize">
                            {{
                                (detail.fleet ? detail.fleet : "-")
                                    .split("-")
                                    .join(" ")
                            }}
                        </td>
                    </tr>
                    <tr v-if="$route.query.service == 'trawlcarrier'">
                        <td class="ps-0 w-200">Service</td>
                        <td class="w-40">:</td>
                        <td class="capitalize">
                            {{ detail.carrier_service.split("_").join(" ") }}
                        </td>
                    </tr>
                    <tr v-if="$route.query.service == 'trawlcarrier'">
                        <td class="ps-0 w-200">Unit</td>
                        <td class="w-40">:</td>
                        <td>
                            {{
                                detail.carrier_unit ? detail.carrier_unit : "-"
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">Assurance</td>
                        <td class="w-40">:</td>
                        <td>{{ currency(detail.assurance) }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";

export default {
    name: "CrmOrderDetail",
    components: {},
    data() {
        return {
            moment: moment,
            detail: {},
        };
    },
    created() {
        this.getDetail();
    },
    computed: {},
    methods: {
        getDetail() {
            var payload = {
                id: this.$route.query.id,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/orders/detail`, {
                params: payload,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.detail = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px;
}
tr {
    border-color: #e8e8e9;
    padding: 10px;
}
td {
    padding: 10px;
}
.w-200 {
    width: 200px;
}
.w-40 {
    width: 40px;
}
.avatar-image {
    border-radius: 50px;
    border: 2px solid #f1f5f9;
    width: 60px;
    height: 60px;
    object-fit: cover;
}
</style>
