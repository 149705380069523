<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/trawltruck/ho/topup" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4 pb-0">
            <div class="d-flex align-items-center mb-5">
                <h3 class="fw-bold mb-0">
                    Detail Data Asuransi
                </h3>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Customers
                </div>
                <div class="col-md-1">
                    :
                </div>
                <div class="col-md-9">
                    Bayangkara
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Vendor Asuransi
                </div>
                <div class="col-md-1">
                    :
                </div>
                <div class="col-md-9">
                    PT. Jaya Makmur
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Tanggal Pengajuan
                </div>
                <div class="col-md-1">
                    :
                </div>
                <div class="col-md-9">
                    14 Maret 2014
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Harga Total Barang
                </div>
                <div class="col-md-1">
                    :
                </div>
                <div class="col-md-9">
                    Rp500.000
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Besaran Asuransi
                </div>
                <div class="col-md-1">
                    :
                </div>
                <div class="col-md-9">
                    Rp100.000
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Jenis Barang
                </div>
                <div class="col-md-1">
                    :
                </div>
                <div class="col-md-9">
                    Elektronik
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Rekening
                </div>
                <div class="col-md-1">
                    :
                </div>
                <div class="col-md-9">
                    Mandiri - Suryo - 1239123123124
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Bukti Pembayaran Klaim
                </div>
                <div class="col-md-1">
                    :
                </div>
                <div class="col-md-9">
                    <div class="d-flex align-items-center">
                        <div>
                            <img src="../../assets/dashboard/image.png" width="30" alt="" />
                        </div>
                        <div class="ms-auto">
                            <a href="" class="text-green">
                                Download Bukti Pembayaran Klaim
                                <i class="fa fa-download ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Dokumen
                </div>
                <div class="col-md-1">
                    :
                </div>
                <div class="col-md-9">
                    <div class="d-flex align-items-center">
                        <div>
                            <img src="../../assets/dashboard/pdf.png" width="30" alt="" />
                        </div>
                        <div class="ms-auto">
                            <a href="" class="text-green">
                                Download Dokumen
                                <i class="fa fa-download ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Status
                </div>
                <div class="col-md-1">
                    :
                </div>
                <div class="col-md-9">
                    <small class="tag green px-4">Padi</small>
                </div>
            </div>
            <hr>
        </div>
    </div>
</template>

<script>
    export default {
        props: "",
        name: "ManagementTopupDetail",
        data() {
            return {
            };
        },
        mounted() {
        },
        methods: {
        }
    };
</script>

<style scoped>
    .text-main-color{
        color: #24516A !important;
    }
    .text-gray-dashboard{
        color: #61616A;
    }
</style>