<template>
    <div class="padding-container">
        <div class="box">
            <div class="d-flex bd-highlight">
                <div class="flex-grow-1 bd-highlight">
                    <h4 class="fw-bold">Pembayaran Customer</h4>
                </div>
                <div class="bd-highlight">
                    <button @click="exportData" class="btn btn-primary px-4">
                        <i class="fa fa-file-excel-o me-1"></i>
                        Export
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 mt-3">
                    <div class="position-relative">
                        <input
                            v-model="noReceipt"
                            value="noReceipt"
                            @keyup.enter="searchManifest"
                            type="text"
                            class="form-control"
                            placeholder="Search No.Resi"
                        />
                        <span class="search-icon">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="col-2 mt-3">
                    <input
                        type="date"
                        class="form-control"
                        v-model="filter.date_start"
                        placeholder="Start Date"
                        valueFormat="YYYY-MM-DD"
                    />
                </div>
                <div
                    class="col-1 d-flex justify-content-center align-items-center mt-3"
                >
                    <div class="fw-bold size-22 fs-6">S/D</div>
                </div>
                <div class="col-2 mt-3">
                    <input
                        type="date"
                        class="form-control"
                        v-model="filter.date_end"
                        :disabled="!filter.date_start"
                        valueFormat="YYYY-MM-DD"
                        @change="searchManifest"
                        placeholder="End Date"
                    />
                </div>
                <div class="col-3 mt-3">
                    <select
                        v-model="searchPaymentType"
                        name="payment_type"
                        @change="searchManifest"
                        class="form-control form-select"
                        placeholder="Jenis Pembayaran"
                    >
                        <option value="" disabled selected hidden>
                            Jenis Pembayaran
                        </option>
                        <option value="">Semua</option>
                        <option value="top">Turn off Payment</option>
                        <option value="va">Virtual Account</option>
                        <option value="cash">Cash</option>
                    </select>
                </div>
            </div>

            <!-- <div class="line mt-4"></div> -->

            <!-- <div class="modern-table-boxed mt-4">
        <div class="d-table-row header">
          <div class="d-table-cell">No Resi</div>
          <div class="d-table-cell">Alamat Pengirim</div>
          <div class="d-table-cell">Alamat Tujuan</div>
          <div class="d-table-cell">Order By</div>
          <div class="d-table-cell">Jenis Pembayaran</div>
        </div>
        <div class="d-table-row">
          <div class="d-table-cell">
            <a href="javascript:void(0)" class="text-dark fw-bold" @click="showModal = true"> TRB5467996643 </a>
          </div>
          <div class="d-table-cell">
            <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Jln.Rasamala no 35, Menteng Dalam, Tebet, Jakarta Selatan, Jakarta, Indonesia , 53527">
              Jakarta Selatan <i class="fa fa-info-circle text-blue ms-1"></i>
            </a>
          </div>
          <div class="d-table-cell">
            <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Jln.Rasamala no 35, Menteng Dalam, Tebet, Jakarta Selatan, Jakarta, Indonesia , 53527">
              Bandung <i class="fa fa-info-circle text-blue ms-1"></i>
            </a>
          </div>
          <div class="d-table-cell">Aplikasi</div>
          <div class="d-table-cell">
            <span class="tag green"> Virtual Account </span>
          </div>
        </div>
        <div class="d-table-row">
          <div class="d-table-cell">
            <a href="javascript:void(0)" class="text-dark fw-bold" @click="showModal = true"> TRB5467976557 </a>
          </div>
          <div class="d-table-cell">
            <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Jln.Rasamala no 35, Menteng Dalam, Tebet, Jakarta Selatan, Jakarta, Indonesia , 53527">
              Jakarta Selatan <i class="fa fa-info-circle text-blue ms-1"></i>
            </a>
          </div>
          <div class="d-table-cell">
            <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Jln.Rasamala no 35, Menteng Dalam, Tebet, Jakarta Selatan, Jakarta, Indonesia , 53527">
              Bandung <i class="fa fa-info-circle text-blue ms-1"></i>
            </a>
          </div>
          <div class="d-table-cell">Walk In</div>
          <div class="d-table-cell">
            <span class="tag blue"> Terms of Payment </span>
          </div>
        </div>
      </div> -->

            <!-- <div>
        <Table :items="this.items"></Table>
      </div> -->
            <div class="mt-4" v-if="is_my_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <div class="modern-table-boxed mt-4">
                    <div class="d-table-row header">
                        <div class="d-table-cell">No Resi</div>
                        <div class="d-table-cell">Kota Pengirim</div>
                        <div class="d-table-cell">Kota Tujuan</div>
                        <div class="d-table-cell">Order By</div>
                        <div class="d-table-cell">Jenis Pembayaran</div>
                    </div>
                    <div
                        class="d-table-row"
                        v-for="(item, index) in filterPaymentType(
                            data,
                            searchPaymentType
                        )"
                        :key="index"
                    >
                        <div class="d-table-cell">
                            <a
                                href="javascript:void(0)"
                                class="text-dark fw-bold"
                                @click="toggleModal(item)"
                            >
                                {{ item.receipt }}
                            </a>
                        </div>
                        <div class="d-table-cell">
                            <a
                                href="javascript:void(0)"
                                class="text-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                :title="item.origin_address"
                            >
                                {{ item.origin_regency }}
                                <i class="fa fa-info-circle text-blue ms-1"></i>
                            </a>
                        </div>
                        <div class="d-table-cell">
                            <a
                                href="javascript:void(0)"
                                class="text-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                :title="item.destination_address"
                            >
                                {{ item.destination_regency }}
                                <i class="fa fa-info-circle text-blue ms-1"></i>
                            </a>
                        </div>
                        <div class="d-table-cell">{{ item.order_by }}</div>
                        <div class="d-table-cell">
                            <span class="tag green">
                                {{ item.payment_type }}
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="data.length == 0" class="text-center mt-section">
                    <img src="../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="d-flex" v-if="data.length > 0">
            <div class="ms-auto">
                <div class="d-flex mt-4">
                    <div class="me-3">
                        <select
                            class="form-control form-select w-select"
                            v-model="limit"
                            @change="getCustomerPayment()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <b-pagination-nav
                        v-if="data.length > 0"
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="changePage"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div
                class="overlay"
                v-if="showModal"
                @click="showModal = false"
            ></div>
            <div class="modal-body-chat" v-if="showModal">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">Detail Resi</div>
                    </div>
                    <div class="bd-highlight">
                        <a
                            href="javascript:void(0)"
                            @click="showModal = false"
                            class="size-20 text-primary"
                        >
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div class="line"></div>
                <div class="scroll">
                    <div class="d-flex bd-highlight">
                        <div class="bd-highlight me-3">
                            <div
                                class="user-image"
                                :style="{
                                    'background-image':
                                        'url(' +
                                        require('../assets/logo-tb-order.png') +
                                        ')',
                                }"
                            ></div>
                        </div>
                        <div class="bd-highlight">
                            <div class="text-gray">Mitra Businnes</div>
                            <div class="mt-2 fw-bold size-18">
                                {{ detailData.package_detail.partner_name }}
                            </div>
                            <div>
                                {{
                                    dateFormat(
                                        detailData.package_detail.created_at
                                    )
                                }}
                            </div>
                        </div>
                        <div class="ms-auto p-2 bd-highlight fw-medium">
                            {{ detailData.receipt_code }}
                            <div class="mt-1">
                                <span class="tag green">
                                    {{ serviceType }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="d-flex align-items-center">
                        <div class="me-2">
                            <img
                                width="25px"
                                src="../assets/send-item.png"
                                alt="pengirim"
                            />
                        </div>
                        <div class="fw-bold size-14">Pengirim</div>
                    </div>
                    <div class="d-flex">
                        <div>
                            <div class="mt-2 size-16 fw-bold">
                                {{ detailData?.package_detail?.sender_name }}
                            </div>
                            <div class="text-dark size-14">
                                {{ detailData?.package_detail?.sender_phone }}
                            </div>
                        </div>
                        <!-- <div class="ms-auto">Kode Pos : <span class="fw-bold">56474</span></div> -->
                    </div>
                    <div class="mt-3 size-14">
                        {{ detailData?.package_detail?.sender_address }}
                    </div>
                    <div class="mt-4">
                        <div class="d-flex align-items-center">
                            <div class="me-2">
                                <img
                                    width="25px"
                                    src="../assets/receive-item.png"
                                    alt="pengirim"
                                />
                            </div>
                            <div class="fw-bold size-14">Penerima</div>
                        </div>
                        <div class="d-flex">
                            <div>
                                <div class="mt-2 size-16 fw-bold">
                                    {{
                                        detailData?.package_detail
                                            ?.receiver_name
                                    }}
                                </div>
                                <div class="text-dark size-14">
                                    {{
                                        detailData?.package_detail
                                            ?.receiver_phone
                                    }}
                                </div>
                            </div>
                            <div class="ms-auto">
                                Kode Pos :
                                <span class="fw-bold">{{
                                    detailData?.package_detail?.zip_code
                                }}</span>
                            </div>
                        </div>
                        <div class="mt-3 size-14">
                            {{ detailData?.package_detail?.receiver_address }}
                        </div>
                    </div>
                    <div class="box green mt-3 no-shadow p-3">
                        <div class="d-flex align-items-center">
                            <div>
                                <i class="fa fa-cart-plus size-26"></i>
                            </div>
                            <div class="ms-4">
                                <div class="fw-bold size-16">Darat</div>
                                <div class="mt-1 size-13">
                                    {{ detailData?.package_detail?.price_note }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="row foto"
                        v-for="(item, index) in detailData?.package_detail
                            ?.package_item"
                        :key="index"
                    >
                        <div class="col-md-12">
                            <template v-if="item.name != 'bike'">
                                <div class="size-16 fw-bold mt-4">
                                    Detail Barang {{ index + 1 }}
                                </div>
                                <div class="text-gray">
                                    {{ capitalizeFirstLetter(item.name) }}
                                </div>
                            </template>
                            <template v-else>
                                <div class="size-16 fw-bold mt-4">
                                    Detail Motor
                                </div>
                            </template>
                            <table class="mt-3">
                                <tr v-if="item.name != 'bike'">
                                    <td style="width: 200px">
                                        <span class="text-gray fw-medium">
                                            Keterangan Barang
                                        </span>
                                    </td>
                                    <td style="width: 50px">:</td>
                                    <td class="fw-bold">
                                        {{ item.name ? item.name : "-" }}
                                    </td>
                                </tr>
                                <tr v-if="item.name != 'bike'">
                                    <td style="width: 200px">
                                        <span class="text-gray fw-medium">
                                            Berat Barang
                                        </span>
                                    </td>
                                    <td style="width: 50px">:</td>
                                    <td class="fw-bold">
                                        {{ item.weight }} Kg
                                    </td>
                                </tr>
                                <tr v-if="item.name != 'bike'">
                                    <td style="width: 200px">
                                        <span class="text-gray fw-medium">
                                            Jumlah Koli
                                        </span>
                                    </td>
                                    <td style="width: 50px">:</td>
                                    <td class="fw-bold">{{ item.qty }} Koli</td>
                                </tr>
                                <tr v-if="item.name != 'bike'">
                                    <td style="width: 200px">
                                        <span class="text-gray fw-medium">
                                            Dimensi Barang
                                        </span>
                                    </td>
                                    <td style="width: 50px">:</td>
                                    <td class="fw-bold">
                                        {{ item.length }} x {{ item.width }} x
                                        {{ item.height }}
                                    </td>
                                </tr>
                                <tr v-if="item.name != 'bike'">
                                    <td style="width: 200px">
                                        <span class="text-gray fw-medium">
                                            Packing
                                        </span>
                                    </td>
                                    <td style="width: 50px">:</td>
                                    <td class="fw-bold">
                                        {{ getPackageType(item.handlings) }}
                                    </td>
                                </tr>
                                <tr v-if="item.name == 'bike'">
                                    <td style="width: 200px">
                                        <span class="text-gray fw-medium">
                                            Merek Motor
                                        </span>
                                    </td>
                                    <td style="width: 50px">:</td>
                                    <td class="fw-bold">
                                        {{
                                            detailData.package_detail?.bike
                                                ?.merk
                                        }}
                                    </td>
                                </tr>
                                <tr v-if="item.name == 'bike'">
                                    <td style="width: 200px">
                                        <span class="text-gray fw-medium">
                                            CC Motor
                                        </span>
                                    </td>
                                    <td style="width: 50px">:</td>
                                    <td class="fw-bold">
                                        {{
                                            detailData.package_detail?.bike?.cc
                                        }}
                                        CC
                                    </td>
                                </tr>
                                <tr v-if="item.name == 'bike'">
                                    <td style="width: 200px">
                                        <span class="text-gray fw-medium">
                                            Tipe Motor
                                        </span>
                                    </td>
                                    <td style="width: 50px">:</td>
                                    <td class="fw-bold">
                                        {{
                                            detailData.package_detail?.bike
                                                ?.type
                                        }}
                                    </td>
                                </tr>
                                <tr v-if="item.name == 'bike'">
                                    <td style="width: 200px">
                                        <span class="text-gray fw-medium">
                                            Keluaran Tahun
                                        </span>
                                    </td>
                                    <td style="width: 50px">:</td>
                                    <td class="fw-bold">
                                        {{
                                            detailData.package_detail?.bike
                                                ?.years
                                        }}
                                    </td>
                                </tr>
                                <tr v-if="item.name == 'bike'">
                                    <td style="width: 200px">
                                        <span class="text-gray fw-medium">
                                            Packing Wajib
                                        </span>
                                    </td>
                                    <td style="width: 50px">:</td>
                                    <td>
                                        <small class="tag green">
                                            Bubble Wrap
                                        </small>
                                        <small class="tag green">
                                            Karung Besar
                                        </small>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 200px">
                                        <span class="text-gray fw-medium">
                                            Tipe Pembayaran
                                        </span>
                                    </td>
                                    <td style="width: 50px">:</td>
                                    <td class="fw-bold">{{ paymentType }}</td>
                                </tr>
                                <tr>
                                    <td style="width: 200px">
                                        <span class="text-gray fw-medium">
                                            Metode Pengiriman
                                        </span>
                                    </td>
                                    <td style="width: 50px">:</td>
                                    <td class="fw-bold">{{ serviceType }}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-12">
                            <div class="size-16 fw-bold mt-4">
                                <template v-if="item.name != 'bike'">
                                    Estimasi Biaya Pengiriman Barang
                                    {{ index + 1 }}
                                </template>
                                <template v-else>
                                    Estimasi Biaya Pengiriman Motor
                                </template>
                            </div>
                            <!-- <div class="text-gray">Honda ( 1 Unit )</div> -->
                            <table class="mt-3">
                                <tr>
                                    <td style="width: 200px">
                                        <span class="text-gray fw-medium">
                                            Biaya Packing
                                            {{ getPackageType(item.handlings) }}
                                        </span>
                                    </td>
                                    <td style="width: 50px">:</td>
                                    <td class="fw-bold">
                                        {{ getPackagePrice(item.handlings) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 200px">
                                        <span class="text-gray fw-medium">
                                            Asuransi
                                        </span>
                                    </td>
                                    <td style="width: 50px">:</td>
                                    <td class="fw-bold">
                                        {{ getInsurancePrice(item.handlings) }}
                                    </td>
                                </tr>
                                <!-- <tr>
                  <td style="width: 200px">
                    <span class="text-gray fw-medium"> Biaya Kirim </span>
                  </td>
                  <td style="width: 50px">:</td>
                  <td class="fw-bold">Rp 500.000</td>
                </tr>
                <tr>
                  <td style="width: 200px">
                    <span class="text-gray fw-medium"> Biaya Penjemputan </span>
                  </td>
                  <td style="width: 50px">:</td>
                  <td class="fw-bold">Rp. 650.000</td>
                </tr>
                <tr>
                  <td style="width: 200px">
                    <span class="text-gray fw-medium"> Sub Total Biaya </span>
                  </td>
                  <td style="width: 50px">:</td>
                  <td class="fw-bold">Rp. 1.300.000</td>
                </tr> -->
                            </table>
                        </div>
                    </div>
                    <div class="size-16 fw-bold mt-4">
                        <template
                            v-if="detailData?.package_detail?.bike == null"
                        >
                            Foto Barang
                        </template>
                        <template v-else> Foto Motor </template>
                    </div>
                    <div
                        v-if="
                            detailData?.package_detail?.package_picture.length >
                            0
                        "
                    >
                        <div class="d-flex justify-content-start gap-2 mt-3">
                            <enlargeable-image
                                v-for="(item, index) in detailData
                                    ?.package_detail?.package_picture"
                                :key="index"
                                style="width: 50px !important"
                                :src="item.picture"
                            />
                            <!-- <img :src="item.picture" width="100%" alt="" /> -->
                        </div>
                    </div>
                    <div
                        v-if="
                            detailData?.package_detail?.package_picture
                                .length == 0
                        "
                        class="row foto mt-3"
                    >
                        <div class="col-md-2 me-2">
                            <img
                                src="../assets/no-photo.png"
                                width="100%"
                                alt=""
                            />
                        </div>
                    </div>
                    <div v-if="transporterType" class="size-16 fw-bold mt-4">
                        Armada Penjemputan
                    </div>
                    <div
                        v-if="transporterType"
                        class="box no-shadow border-gray mt-3 p-3"
                    >
                        <div class="d-flex align-items-center">
                            <div>
                                <img
                                    src="../assets/pickup.png"
                                    width="90"
                                    alt=""
                                />
                            </div>
                            <div class="ms-4">
                                <div class="fw-bold size-16">
                                    {{ transporterType }}
                                </div>
                                <div class="mt-1 size-13">
                                    Dimensi Max 200 x 130 x 120 Berat Max 700 kg
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="size-16 fw-bold mt-4">Rincian Pembayaran22</div>
                    <table class="mt-3">
                        <tr>
                            <td style="width: 200px">
                                <span class="text-gray fw-medium">
                                    Biaya Kirim
                                </span>
                            </td>
                            <td style="width: 50px">:</td>
                            <td class="fw-bold">
                                {{ currency(servicePrice) }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 200px">
                                <span class="text-gray fw-medium">
                                    Biaya Jasa Aplikasi
                                </span>
                            </td>
                            <td style="width: 50px">:</td>
                            <td class="fw-bold">
                                {{
                                    currency(
                                        detailData?.package_detail?.prices
                                            ?.platform_fee_price
                                    )
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 200px">
                                <span class="text-gray fw-medium">
                                    Biaya Penjemputan
                                </span>
                            </td>
                            <td style="width: 50px">:</td>
                            <td class="fw-bold">{{ currency(pickupPrice) }}</td>
                        </tr>
                        <!-- <tr>
              <td style="width: 200px">
                <span class="text-gray fw-medium"> Sub Total Biaya </span>
              </td>
              <td style="width: 50px">:</td>
              <td class="fw-bold">{{ subTotalPrice }}</td>
            </tr> -->
                        <tr>
                            <td style="width: 200px">
                                <span class="text-gray fw-medium"
                                    >Biaya Admin</span
                                >
                            </td>
                            <td style="width: 50px">:</td>
                            <td class="fw-bold">
                                {{ currency(paymentAdminCharge) }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="text-gray fw-medium">
                                    Diskon Pengiriman
                                </span>
                            </td>
                            <td>:</td>
                            <td class="fw-bold">
                                {{
                                    currency(
                                        detailData?.package_detail?.prices
                                            ?.discount_service_price
                                    )
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="text-gray fw-medium">
                                    Diskon Penjemputan
                                </span>
                            </td>
                            <td>:</td>
                            <td class="fw-bold">
                                {{
                                    currency(
                                        detailData?.package_detail?.prices
                                            ?.discount_pickup_price
                                    )
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 200px">
                                <span class="text-gray fw-medium">
                                    Total Charge Weight
                                </span>
                            </td>
                            <td style="width: 50px">:</td>
                            <td class="fw-bold">{{ totalChargeWeight }} Kg</td>
                        </tr>
                        <tr v-if="detailData?.package_detail?.bike == null">
                            <td style="width: 200px">
                                <span class="text-gray fw-medium">
                                    Biaya Tambahan
                                </span>
                            </td>
                            <td style="width: 50px">:</td>
                            <td class="fw-bold">
                                {{
                                    currency(
                                        detailData?.package_detail?.prices
                                            ?.additional_service_price
                                    )
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="text-gray fw-medium">
                                    Total Biaya
                                </span>
                            </td>
                            <td>:</td>
                            <td class="fw-bold">{{ totalAmount }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../api/Api";
import moment from "moment";
import ListLoader from "../components/ListLoader.vue";
import EnlargeableImage from "@diracleo/vue-enlargeable-image";

export default {
    components: {
        EnlargeableImage,
        ListLoader,
    },
    name: "CustomerPayment",
    data() {
        return {
            data: [],
            detailData: [],
            deliveryPrice: 0,
            searchPaymentType: "",
            showModal: false,
            coloumn: [
                "No Resi",
                "Alamat Pengirim",
                "Alamat Tujuan",
                "Order By",
                "Jenis Pembayaran",
            ],
            items: [],
            noReceipt: "",
            is_my_list_ready: false,
            isLoading: false,
            currentPage: 0,
            totalPage: 0,
            limit: 10,
            datePicker: "",
            paymentType: "",
            filter: {
                date_start: null,
                date_end: null,
            },
        };
    },
    created() {
        this.getCustomerPayment();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        changePage() {
            this.getCustomerPayment();
        },
        searchManifest() {
            this.currentPage = 0;
            this.getCustomerPayment();
        },
        currency(number) {
            if (!isNaN(number)) {
                return new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                }).format(number);
            } else {
                return number;
            }
        },
        getPackagePrice(value) {
            let result = 0;
            value.forEach((el) => {
                if (el.price_type == "handling") {
                    result = el.price_amount;
                }
            });
            return this.currency(result);
        },
        getPackageType(value) {
            let result = [];
            value.forEach((el) => {
                if (el.price_type == "handling") {
                    result.push(this.codePacking(el.price_description));
                }
            });
            return result.length == 0 ? "-" : result.join(", ");
        },
        getInsurancePrice(value) {
            let result = "-";
            value.forEach((el) => {
                if (
                    el.price_type == "insurance" &&
                    el.price_description == "insurance"
                ) {
                    result = el.price_amount;
                }
            });
            return result == "-" ? "-" : this.currency(result);
        },
        dateFormat(date) {
            return moment(date).format("ddd, DD MMM YYYY");
        },
        codePacking(value) {
            var packing;
            switch (value) {
                case "bubble wrap":
                    packing = "Bubble Wrap";
                    break;
                case "cardboard":
                    packing = "Kardus";
                    break;
                case "plastic":
                    packing = "Plastik";
                    break;
                case "wood":
                    packing = "Kayu";
                    break;
                case "sandbag sm":
                    packing = "Karung Kecil";
                    break;
                case "sandbag md":
                    packing = "Karung Sedang";
                    break;
                case "sandbag l":
                    packing = "Karung Besar";
                    break;
                case "bike":
                    packing = "Motor";
                    break;
                default:
                    packing = "";
            }
            return packing;
        },
        toggleModal(item) {
            this.showModal = true;
            this.paymentType = item.payment_type;
            this.getDetailReceipt(item.receipt);
        },
        filterPaymentType(data, type) {
            if (data && data.length) {
                if (type == "top") {
                    let result = data.filter((el) => {
                        if (el.payment_type == "Term of Payment") {
                            return el;
                        }
                    });
                    return result;
                }
                if (type == "va") {
                    let result = data.filter((el) => {
                        if (el.payment_type == "Virtual Account") {
                            return el;
                        }
                    });
                    return result;
                }

                return data;
            }

            return null;
        },
        getPaymentType() {
            this.getCustomerPayment();
        },
        getCustomerPayment() {
            this.is_my_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/fno/payment/customer`,
                {
                    params: {
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 1,
                        limit: this.limit,
                        search: this.noReceipt,
                        date_start: this.filter.date_start,
                        date_end: this.filter.date_end,
                        payment_type: this.searchPaymentType,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        exportData() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/fno/payment/customer/export`,
                {
                    params: {
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 1,
                        limit: this.limit,
                        search: this.noReceipt,
                        date: this.datePicker,
                        payment_type: this.searchPaymentType,
                    },
                    responseType: "blob",
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data_excel_request = res.data;
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        moment().format("DD MMM YYYY") + `.xls`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
        getDetailReceipt(receipt) {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/fno/payment/customer/detail`,
                {
                    params: { receipt_code: receipt },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detailData = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
    },
    computed: {
        transporterType() {
            return this.detailData?.package_detail?.transporter_type;
        },
        servicePrice() {
            let result = "-";
            this.detailData?.package_detail?.package_price?.forEach((el) => {
                if (
                    el.price_type == "service" &&
                    el.price_description == "service" &&
                    el.price_amount
                ) {
                    result = el.price_amount;
                }
            });
            return result == "-" ? "-" : parseInt(result);
        },
        platformFee() {
            let result = "-";
            this.detailData?.package_detail?.package_price?.forEach((el) => {
                if (
                    el.price_type == "platform" &&
                    el.price_description == "fee" &&
                    el.price_amount
                ) {
                    result = el.price_amount;
                }
            });
            return result == "-" ? "-" : parseInt(result);
        },
        pickupPrice() {
            let result = "-";
            this.detailData?.package_detail?.package_price?.forEach((el) => {
                if (
                    el.price_type == "delivery" &&
                    el.price_description == "pickup" &&
                    el.price_amount
                ) {
                    result = el.price_amount;
                }
            });
            return result == "-" ? "-" : parseInt(result);
        },
        discountPickup() {
            let result = "-";
            this.detailData?.package_detail?.package_price?.forEach((el) => {
                if (
                    el.price_type == "discount" &&
                    el.price_description == "pickup" &&
                    el.price_amount
                ) {
                    result = el.price_amount;
                }
            });
            return result == "-" ? "-" : this.currency(result);
        },
        discountService() {
            let result = "-";
            this.detailData?.package_detail?.package_price?.forEach((el) => {
                if (
                    el.price_type == "discount" &&
                    el.price_description == "service" &&
                    el.price_amount
                ) {
                    result = el.price_amount;
                }
            });
            return result == "-" ? "-" : this.currency(result);
        },
        totalChargeWeight() {
            return this.detailData?.package_detail?.total_weight
                ? parseInt(this.detailData?.package_detail?.total_weight)
                : "-";
        },
        subTotalPrice() {
            let tempPickup;
            let tempService;
            let result;
            if (this.servicePrice == "-") {
                tempService = 0;
            } else {
                tempService = this.servicePrice;
            }
            if (this.pickupPrice == "-") {
                tempPickup = 0;
            } else {
                tempPickup = this.pickupPrice;
            }
            result = tempPickup + tempService;
            return result == 0 ? "-" : this.currency(result);
        },
        paymentAdminCharge() {
            return this.detailData?.package_detail?.payment
                ? this.detailData?.package_detail?.payment
                      ?.payment_admin_charges
                : "-";
        },
        serviceType() {
            if (this.detailData?.package_detail?.service_code == "tps") {
                return "Reguler";
            }
            if (this.detailData?.package_detail?.service_code == "tpx") {
                return "Express";
            } else return "";
        },
        totalAmount() {
            return this.detailData?.package_detail?.total_amount
                ? this.currency(this.detailData?.package_detail?.total_amount)
                : "-";
        },
    },
};
</script>

<style scoped>
.scroll {
    height: 500px;
    overflow-y: scroll;
}
.row.foto {
    --bs-gutter-x: 0rem !important;
}
</style>
