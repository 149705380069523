<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="/crm/partnership/registered"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Detail Registered</h3>
                <div class="ms-auto">
                    <span class="capitalize tag green radius pe-3 ps-3">{{
                        $route.query.type
                    }}</span>
                </div>
            </div>
            <div class="mt-4">
                <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                    <tr class="" v-if="$route.query.type == 'trawlpack'">
                        <td class="ps-0 w-200">Parter Type</td>
                        <td class="w-40">:</td>
                        <td class="capitalize">
                            <template v-if="detail.partner_label">
                                Mitra
                                {{ detail.partner_label }}
                            </template>
                            <template v-else> - </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">Partner Code</td>
                        <td class="w-40">:</td>
                        <td>{{ detail.code }}</td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">Company Name</td>
                        <td class="w-40">:</td>
                        <td>{{ detail.name ? detail.name : "-" }}</td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">Owner Name</td>
                        <td class="w-40">:</td>
                        <td>
                            {{ detail.owner_name ? detail.owner_name : "-" }}
                        </td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">Phone Number</td>
                        <td class="w-40">:</td>
                        <td>
                            <template
                                v-if="
                                    $route.query.type == 'trawlpack' ||
                                    $route.query.type == 'trawlcarrier'
                                "
                            >
                                {{
                                    detail.contact_phone
                                        ? detail.contact_phone
                                        : "-"
                                }}
                            </template>
                            <template v-if="$route.query.type == 'trawltruck'">
                                {{ detail.phone ? detail.phone : "-" }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">Email</td>
                        <td class="w-40">:</td>
                        <td>
                            <template
                                v-if="
                                    $route.query.type == 'trawlpack' ||
                                    $route.query.type == 'trawlcarrier'
                                "
                            >
                                {{
                                    detail.contact_email
                                        ? detail.contact_email
                                        : "-"
                                }}
                            </template>
                            <template v-if="$route.query.type == 'trawltruck'">
                                {{ detail.email ? detail.email : "-" }}
                            </template>
                        </td>
                    </tr>
                    <tr v-if="$route.query.type == 'trawlcarrier'">
                        <td class="ps-0 w-200">Year</td>
                        <td class="w-40">:</td>
                        <td>
                            {{ detail.year ? detail.year : "-" }}
                        </td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">Bank Account Number</td>
                        <td class="w-40">:</td>
                        <td>
                            {{
                                detail.bank_account_number
                                    ? detail.bank_account_number
                                    : "-"
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">Bank Account Name</td>
                        <td class="w-40">:</td>
                        <td>
                            {{
                                detail.bank_account_name
                                    ? detail.bank_account_name
                                    : "-"
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">Bank Account N.A</td>
                        <td class="w-40">:</td>
                        <td>
                            {{ detail.bank_name ? detail.bank_name : "-" }}
                        </td>
                    </tr>
                    <tr
                        v-if="
                            $route.query.type == 'trawlpack' ||
                            $route.query.type == 'trawlcarrier'
                        "
                    >
                        <td class="ps-0 w-200">Province</td>
                        <td class="w-40">:</td>
                        <td>{{ detail.province ? detail.province : "-" }}</td>
                    </tr>
                    <tr
                        v-if="
                            $route.query.type == 'trawlpack' ||
                            $route.query.type == 'trawlcarrier'
                        "
                    >
                        <td class="ps-0 w-200">City/Regency</td>
                        <td class="w-40">:</td>
                        <td>{{ detail.regency ? detail.regency : "-" }}</td>
                    </tr>
                    <tr
                        v-if="
                            $route.query.type == 'trawlpack' ||
                            $route.query.type == 'trawlcarrier'
                        "
                    >
                        <td class="ps-0 w-200">Subdistrict</td>
                        <td class="w-40">:</td>
                        <td>
                            {{ detail.subdistrict ? detail.subdistrict : "-" }}
                        </td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">
                            <template v-if="$route.query.type == 'trawlpack'"
                                >Detail</template
                            >
                            Address
                        </td>
                        <td class="w-40">:</td>
                        <td>{{ detail.address ? detail.address : "-" }}</td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">Photo Owner</td>
                        <td class="w-40">:</td>
                        <td>
                            <img
                                :src="detail.avatar"
                                class="avatar-image"
                                v-if="detail.avatar"
                            />
                            <img
                                src="../../assets/no-photo.png"
                                v-else
                                class="avatar-image"
                            />
                        </td>
                    </tr>
                    <tr v-if="$route.query.type == 'trawlcarrier'">
                        <td class="ps-0 w-200">NPWP</td>
                        <td class="w-40">:</td>
                        <td>
                            <div
                                class="d-flex align-items-center"
                                v-if="detail.npwp"
                            >
                                <div>
                                    <img
                                        src="../../assets/dashboard/image.png"
                                        width="30"
                                        alt=""
                                    />
                                </div>
                                <div class="ms-auto">
                                    <a
                                        :href="detail.npwp"
                                        target="_blank"
                                        download
                                        class="text-green"
                                    >
                                        Download
                                        <i class="fa fa-download ms-2"></i>
                                    </a>
                                </div>
                            </div>
                            <template v-else> - </template>
                        </td>
                    </tr>
                    <tr v-if="$route.query.type == 'trawlcarrier'">
                        <td class="ps-0 w-200">KTP</td>
                        <td class="w-40">:</td>
                        <td>
                            <div
                                class="d-flex align-items-center"
                                v-if="detail.ktp"
                            >
                                <div>
                                    <img
                                        src="../../assets/dashboard/image.png"
                                        width="30"
                                        alt=""
                                    />
                                </div>
                                <div class="ms-auto">
                                    <a
                                        :href="detail.ktp"
                                        target="_blank"
                                        download
                                        class="text-green"
                                    >
                                        Download
                                        <i class="fa fa-download ms-2"></i>
                                    </a>
                                </div>
                            </div>
                            <template v-else> - </template>
                        </td>
                    </tr>
                    <tr v-if="$route.query.type == 'trawltruck'">
                        <td class="ps-0 w-200">Company Logo</td>
                        <td class="w-40">:</td>
                        <td>
                            <img
                                :src="detail.avatar"
                                v-if="detail.logo_picture_url"
                                class="avatar-image"
                            />
                            <img
                                src="../../assets/no-photo.png"
                                v-else
                                class="avatar-image"
                            />
                        </td>
                    </tr>
                    <tr v-if="$route.query.type == 'trawltruck'">
                        <td class="ps-0 w-200">NIB</td>
                        <td class="w-40">:</td>
                        <td>
                            <div
                                class="d-flex align-items-center"
                                v-if="detail.business_picture_url"
                            >
                                <div>
                                    <img
                                        src="../../assets/dashboard/image.png"
                                        width="30"
                                        alt=""
                                    />
                                </div>
                                <div class="ms-auto">
                                    <a
                                        :href="detail.business_picture_url"
                                        target="_blank"
                                        download
                                        class="text-green"
                                    >
                                        Download
                                        <i class="fa fa-download ms-2"></i>
                                    </a>
                                </div>
                            </div>
                            <template v-else> - </template>
                        </td>
                    </tr>
                    <tr v-if="$route.query.type == 'trawltruck'">
                        <td class="ps-0 w-200">KTP</td>
                        <td class="w-40">:</td>
                        <td>
                            <div
                                class="d-flex align-items-center"
                                v-if="detail.identity_picture_url"
                            >
                                <div>
                                    <img
                                        src="../../assets/dashboard/image.png"
                                        width="30"
                                        alt=""
                                    />
                                </div>
                                <div class="ms-auto">
                                    <a
                                        :href="detail.identity_picture_url"
                                        target="_blank"
                                        download
                                        class="text-green"
                                    >
                                        Download
                                        <i class="fa fa-download ms-2"></i>
                                    </a>
                                </div>
                            </div>
                            <template v-else> - </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">Driver</td>
                        <td class="w-40">:</td>
                        <td>
                            {{
                                detail.drivers_count ? detail.drivers_count : 0
                            }}
                        </td>
                    </tr>
                    <tr v-if="$route.query.type == 'trawltruck'">
                        <td class="ps-0 w-200">Fleet</td>
                        <td class="w-40">:</td>
                        <td>
                            {{ detail.fleet_count ? detail.fleet_count : 0 }}
                        </td>
                    </tr>
                    <tr v-if="$route.query.type == 'trawlcarrier'">
                        <td class="ps-0 w-200">Towing</td>
                        <td class="w-40">:</td>
                        <td>
                            {{ detail.towing_count ? detail.towing_count : 0 }}
                        </td>
                    </tr>
                    <tr v-if="$route.query.type == 'trawlcarrier'">
                        <td class="ps-0 w-200">Carrier</td>
                        <td class="w-40">:</td>
                        <td>
                            {{
                                detail.carrier_count ? detail.carrier_count : 0
                            }}
                        </td>
                    </tr>
                    <tr v-if="$route.query.type == 'trawlpack'">
                        <td class="ps-0 w-200">Armada</td>
                        <td class="w-40">:</td>
                        <td>
                            {{
                                detail.transporter_count
                                    ? detail.transporter_count
                                    : 0
                            }}
                        </td>
                    </tr>
                    <tr v-if="$route.query.type == 'trawlpack'">
                        <td class="ps-0 w-200">Kasir</td>
                        <td class="w-40">:</td>
                        <td>
                            {{
                                detail.cashier_count ? detail.cashier_count : 0
                            }}
                        </td>
                    </tr>
                    <tr v-if="$route.query.type == 'trawlpack'">
                        <td class="ps-0 w-200">Ware House</td>
                        <td class="w-40">:</td>
                        <td>
                            {{
                                detail.warehouse_count
                                    ? detail.warehouse_count
                                    : 0
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">Registered At</td>
                        <td class="w-40">:</td>
                        <td>
                            {{
                                moment(detail.created_at).format(
                                    "DD MMMM YYYY | HH:mm"
                                )
                            }}
                            WIB
                        </td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">Status</td>
                        <td class="w-40">:</td>
                        <td>
                            <span
                                class="tag green-solid px-5 radius mb-0"
                                v-if="detail.status == 'active'"
                            >
                                Active
                            </span>
                            <span class="tag red-solid px-5 radius mb-0" v-else>
                                Non Active
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
// import VueZoomImg from "vue-zoom-img";

export default {
    name: "crmPartnershipRegisteredDetail",
    components: {},
    // directives: {
    //     "zoom-img": VueZoomImg,
    // },
    data() {
        return {
            moment: moment,
            detail: {},
        };
    },
    created() {
        this.getDetail();
    },
    computed: {},
    methods: {
        getDetail() {
            var payload = {
                id: this.$route.query.id,
                type: this.$route.query.type,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/registered/detail`,
                {
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detail = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px;
}
tr {
    border-color: #e8e8e9;
    padding: 10px;
}
td {
    padding: 10px;
}
.w-200 {
    width: 200px;
}
.w-40 {
    width: 40px;
}
.avatar-image {
    border-radius: 50px;
    border: 2px solid #f1f5f9;
    width: 60px;
    height: 60px;
    object-fit: cover;
}
</style>
