<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/trawltruck/ho/driver" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4 pb-0">
            <div class="d-flex align-items-center mb-5">
                <h3 class="fw-bold mb-0">
                    Informasi Data Driver
                </h3>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Nama Driver
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.driver_name }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Nama Mitra
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.partner_name }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Nomor KTP
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.identity_number }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Nomor Telepon
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.driver_phone }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Usia
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.age }} Tahun
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Email
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.driver_email }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Alamat
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.domicile_address }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Status
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    <template v-if="detail.status == 'accept'">
                        <small class="tag green mb-0 px-3 capitalize">
                            Aktif
                        </small>
                    </template>
                    <template v-else>
                        <small class="tag red mb-0 px-3 capitalize">
                            Tidak Aktif
                        </small>
                    </template>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Foto Driver
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    <div class="d-flex align-items-center">
                        <div>
                            <img src="../../assets/dashboard/image.png" width="30" alt="" />
                        </div>
                        <div class="ms-auto">
                            <a :href="detail.driver_picture_url" target="_blank" download="" class="text-green">
                                Download Foto Driver
                                <i class="fa fa-download ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Foto KTP
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    <div class="d-flex align-items-center">
                        <div>
                            <img src="../../assets/dashboard/image.png" width="30" alt="" />
                        </div>
                        <div class="ms-auto">
                            <a :href="detail.identity_picture_url" target="_blank" download="" class="text-green">
                                Download Foto KTP
                                <i class="fa fa-download ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Foto SIM
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    <div class="d-flex align-items-center">
                        <div>
                            <img src="../../assets/dashboard/image.png" width="30" alt="" />
                        </div>
                        <div class="ms-auto">
                            <a :href="detail.license_picture_url" target="_blank" download="" class="text-green">
                                Download Foto SIM
                                <i class="fa fa-download ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
        </div>
        <div class="box mt-5">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">
                    Riwayat Perjalanan
                </h3>
            </div>
            <h5 class="mb-0 mt-2 text-green">
                Total Transaksi: {{ histories.list_data.length }}
            </h5>
            <div class="row mt-5 m-mt-1">
                <div class="col-md-5">
                    <div class="position-relative">
                        <input type="text" class="form-control form-control-lg" placeholder="Cari data..." v-model="req.search" @input="changeSearch()" />
                        <span class="search-icon">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_history_ready">
                <ListLoader/>
            </div>
            <div v-else>
                <template v-if="histories.list_data.length > 0">
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">Transaksi</th>
                                    <th class="text-center" scope="col">History Perjalanan</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(data, index) in histories.list_data">
                                    <tr :key="index">
                                        <td class="text-center">
                                            {{ index+1 }}
                                        </td>
                                        <td class="text-center">
                                            {{ data.code }}
                                        </td>
                                        <td class="text-center">
                                            {{ data.route }}
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-section">
                        <img src="../../assets/no-data.png" width="100" alt="">
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </template>
            </div>
            <div class="mt-4">
                <b-pagination-nav v-if="histories.list_data.length > 0" v-model="currentPage" :number-of-pages="histories.total_page" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from "../../api/Api";
    import { Tooltip } from "bootstrap";
    import ListLoader from '../../components/ListLoader.vue';

    export default {
        components: {
            ListLoader
        },
        name: "ManagementTruckDetail",
        data() {
            return {
                detail: {
                    user: {}
                },
                is_history_ready: false,
                histories: {
                    list_data: [],
                    next_page: 0,
                    total_data: 0,
                    total_page: 0,
                    current_page: 0
                },
                per_page: 10,
                currentPage: 0,
                req: {
                    search: ''
                }
            };
        },
        mounted() {
            new Tooltip(document.body, {
                selector: "[data-bs-toggle='tooltip']",
            });
        },
        created() {
            this.getDetail()
            this.getHistories()
        },
        computed: {
        },
        methods: {
            changePage() {
                this.getHistories();
            },
            changeSearch(){
                this.currentPage = 0
                this.getHistories()
            },
            getDetail() {
                this.is_list_ready = true
                Api.get(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/driver/detail?id=${this.$route.params.id}`, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    var data = res.data.data
                    this.detail = data
                    this.is_list_ready = false
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false
                });
            },
            getHistories() {
                this.is_history_ready = true
                Api.get(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/driver/history?id=${this.$route.params.id}`, {
                    params: {
                        search: this.req.search,
                        limit: this.per_page,
                        page: this.currentPage == 0 || this.currentPage == null ? 0 : this.currentPage - 1
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log(res)
                    var data = res.data.data
                    this.histories = data
                    this.is_history_ready = false
                })
                .catch((err) => {
                    console.log(err);
                    this.is_history_ready = false
                });
            },
        }
    };
</script>

<style scoped>
    .text-main-color{
        color: #24516A !important;
    }
    .text-gray-dashboard{
        color: #61616A;
    }
    .foto-box{
        width: 100px;
        height: 50px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 5px;
        position: relative;
    }.nav-link.dashboard.center{
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    .nav-link.dashboard.left{
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    .nav-link.dashboard.active{
        background-color: #E60013 !important;
    }
    .w-33{
        width: 33.33%;
    }
    th{
        border-color: #E8E8E9;
        padding: 10px !important;
    }
    tr{
        border-color: #E8E8E9;
        padding: 10px !important;
    }
    td{
        padding: 10px !important;
    }
    .table-light{
        background-color: #F6FAFF !important;
        --bs-table-border-color: #F6FAFF !important;
        --bs-table-bg: #F6FAFF !important;
    }
</style>