var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-wrapper chiller-theme",class:!_vm.isMobile ? 'toggled' : ''},[_c('notifications',{attrs:{"group":"foo","position":"top right"}}),_vm._m(0),_c('nav',{staticClass:"sidebar-wrapper",attrs:{"id":"sidebar"}},[_c('div',{staticClass:"sidebar-content"},[_vm._m(1),_c('div',{staticClass:"sidebar-header"},[_c('div',{},[_c('center',[_c('div',[_c('img',{attrs:{"src":require("../assets/logo.png"),"width":"70","alt":""}})]),_c('div',{staticClass:"mt-3 fw-bold size-16"},[_vm._v(" Dashboard Troben ")])])],1)]),_c('hr',{staticClass:"hr-horizontal mb-3"}),_c('div',{staticClass:"sidebar-menu"},[_c('ul',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.dashboardTrawlpackHoFinanceRoles)),expression:"isRole(dashboardTrawlpackHoFinanceRoles)"}]},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'dashboard-finance',
                            },attrs:{"href":"/trawlpack/ho/finance"}},[_c('i',{staticClass:"fa fa-dashboard"}),_vm._v(" Dashboard Finance ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.dashboardTrawlpackHoOperationRoles)),expression:"isRole(dashboardTrawlpackHoOperationRoles)"}]},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name ==
                                    'dashboard-operation',
                            },attrs:{"href":"/trawlpack/ho/operation"}},[_c('i',{staticClass:"fa fa-dashboard"}),_vm._v(" Dashboard Operation ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.roleOnlyAdmin)),expression:"isRole(roleOnlyAdmin)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'set-harga-layanan',
                            },attrs:{"href":"/service/setharga-layanan"}},[_c('i',{staticClass:"fa fa-money"}),_c('span',[_vm._v("Set Harga Layanan")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.TropackRoles)),expression:"isRole(TropackRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name ==
                                        'admin-corporate-list' ||
                                    this.$route.name ==
                                        'admin-trawlpack-mitra-edit' ||
                                    this.$route.name == 'admin-income-mb' ||
                                    this.$route.name ==
                                        'admin-income-mb-detail' ||
                                    this.$route.name == 'admin-income-ms' ||
                                    this.$route.name ==
                                        'admin-income-ms-detail' ||
                                    this.$route.name == 'warehouse-mb' ||
                                    this.$route.name ==
                                        'warehouse-mb-detail' ||
                                    this.$route.name == 'warehouse-mpw' ||
                                    this.$route.name ==
                                        'warehouse-mpw-detail' ||
                                    this.$route.name == 'warehouse-ms' ||
                                    this.$route.name ==
                                        'warehouse-ms-detail' ||
                                    this.$route.name ==
                                        'partner-disbursement' ||
                                    this.$route.name ==
                                        'partner-disbursement-detail' ||
                                    this.$route.name ==
                                        'agent-disbursement' ||
                                    this.$route.name ==
                                        'agent-disbursement-detail' ||
                                    this.$route.name == 'manifest' ||
                                    this.$route.name == 'detail-manifest' ||
                                    this.$route.name ==
                                        'assign-transporter' ||
                                    this.$route.name == 'receipt' ||
                                    this.$route.name ==
                                        'admin-customer-payment' ||
                                    this.$route.name ==
                                        'trawltruck-cubic-price' ||
                                    this.$route.name == 'harbors' ||
                                    this.$route.name == 'harbors-create' ||
                                    this.$route.name == 'harbors-edit',
                            },attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-cube"}),_c('span',[_vm._v("Troben Cargo")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu",class:this.$route.name == 'admin-corporate-list'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlpack-mitra-edit'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-corporate-create'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-corporate-edit'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlpack-partner-incoming-order'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'admin-income-mb'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-income-mb-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'admin-income-ms'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-income-ms-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'admin-income-mpw'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-income-mpw-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'admin-income-mt'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-income-mt-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'admin-income-mp'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-income-mp-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'warehouse-mb'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'warehouse-mb-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'warehouse-mpw'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'warehouse-mpw-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'warehouse-ms'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'warehouse-ms-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'partner-disbursement'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'partner-disbursement-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'agent-disbursement'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'agent-disbursement-detail'
                                    ? 'block'
                                    : '' || this.$route.name == 'manifest'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'detail-manifest'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'assign-transporter'
                                    ? 'block'
                                    : '' || this.$route.name == 'receipt'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-customer-payment'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-cubic-price'
                                    ? 'block'
                                    : '' || this.$route.name == 'harbors'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'harbors-create'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'harbors-edit'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-corporate-list'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-trawlpack-mitra-edit'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-corporate-create'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-corporate-edit'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-trawlpack-partner-incoming-order'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'admin-income-mb'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-income-mb-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'admin-income-ms'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-income-ms-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'admin-income-mpw'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-income-mpw-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'admin-income-mt'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-income-mt-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'admin-income-mp'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-income-mp-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'warehouse-mb'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'warehouse-mb-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'warehouse-mpw'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'warehouse-mpw-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'warehouse-ms'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'warehouse-ms-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'partner-disbursement'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'partner-disbursement-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'agent-disbursement'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'agent-disbursement-detail'
                                    ? 'none'
                                    : '' || this.$route.name != 'manifest'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'detail-manifest'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'assign-transporter'
                                    ? 'none'
                                    : '' || this.$route.name != 'receipt'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-customer-payment'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'trawltruck-cubic-price'
                                    ? 'none'
                                    : '' || this.$route.name != 'harbors'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'harbors-create'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'harbors-edit'
                                    ? 'none'
                                    : ''},[_c('ul',[_c('li',[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'admin-corporate-list' ||
                                                this.$route.name ==
                                                    'admin-trawlpack-mitra-edit',
                                        },attrs:{"href":"/trawlpack/general/order"}},[_c('i',{staticClass:"fa fa-file sub"}),_vm._v(" Daftar Pesanan ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.hideAdminRole)),expression:"isRole(hideAdminRole)"}]},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                'admin-trawlpack-partner-incoming-order',
                                        },attrs:{"href":"/trawlpack/partner/incoming-order"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Incoming Order ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.partnerIncomeRoles)),expression:"isRole(partnerIncomeRoles)"}],staticClass:"sidebar-dropdown1",class:{
                                        '': true,
                                        active:
                                            this.$route.name ==
                                            'admin-income-mb',
                                    }},[_c('a',{class:this.$route.name ==
                                            'admin-income-mb'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-mb-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-ms'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-ms-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-mpw'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-mpw-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-mt'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-mt-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-mp'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-mp-detail'
                                                ? 'active second'
                                                : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-dollar sub"}),_c('span',[_vm._v("Pendapatan Mitra")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu1",class:this.$route.name ==
                                            'admin-income-mb'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-mb-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-ms'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-ms-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-mpw'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-mpw-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-mt'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-mt-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-mp'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'admin-income-mp-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name !=
                                                      'admin-income-mb'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'admin-income-mb-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'admin-income-ms'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'admin-income-ms-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'admin-income-mpw'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'admin-income-mpw-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'admin-income-mt'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'admin-income-mt-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'admin-income-mp'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'admin-income-mp-detail'
                                                ? 'none'
                                                : ''},[_c('ul',[_c('li',[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'admin-income-mb' ||
                                                            this.$route
                                                                .name ==
                                                                'admin-income-mb-detail',
                                                    },attrs:{"href":"/trawlpack/ho/income/mitra-business"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Mitra Business ")])]),_c('li',[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'admin-income-ms' ||
                                                            this.$route
                                                                .name ==
                                                                'admin-income-ms-detail',
                                                    },attrs:{"href":"/trawlpack/ho/income/mitra-space"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Mitra Space ")])]),_c('li',[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'admin-income-mpw' ||
                                                            this.$route
                                                                .name ==
                                                                'admin-income-mpw-detail',
                                                    },attrs:{"href":"/trawlpack/ho/income/mitra-pool-warehouse"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Mitra Pool Warehouse ")])]),_c('li',[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'admin-income-mt' ||
                                                            this.$route
                                                                .name ==
                                                                'admin-income-mt-detail',
                                                    },attrs:{"href":"/trawlpack/ho/income/mitra-transporter"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Mitra Transporter ")])]),_c('li',[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'admin-income-mp' ||
                                                            this.$route
                                                                .name ==
                                                                'admin-income-mp-detail',
                                                    },attrs:{"href":"/trawlpack/ho/income/mitra-pos"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Mitra Pos ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.disbursementRoles)),expression:"isRole(disbursementRoles)"}]},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'partner-disbursement' ||
                                                this.$route.name ==
                                                    'partner-disbursement-detail',
                                        },attrs:{"href":"/trawlpack/ho/disbursement/partner"}},[_c('i',{staticClass:"fa fa-money sub"}),_vm._v(" Pencairan Mitra ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.dataItemRoles)),expression:"isRole(dataItemRoles)"}],staticClass:"sidebar-dropdown1",class:{
                                        '': true,
                                        active:
                                            this.$route.name ==
                                            'warehouse-mb',
                                    }},[_c('a',{class:this.$route.name ==
                                            'warehouse-mb'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'warehouse-mb-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'warehouse-mpw'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'warehouse-mpw-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'warehouse-ms '
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'warehouse-ms-detail'
                                                ? 'active second'
                                                : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-cubes sub"}),_c('span',[_vm._v("Data Barang")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu1",class:this.$route.name ==
                                            'warehouse-mb'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'warehouse-mb-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'warehouse-mpw'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'warehouse-mpw-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'warehouse-ms'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'warehouse-ms-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name !=
                                                      'warehouse-mb'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'warehouse-mb-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'warehouse-mpw'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'warehouse-mpw-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'warehouse-ms'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'warehouse-ms-detail'
                                                ? 'none'
                                                : ''},[_c('ul',[_c('li',[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'warehouse-mb' ||
                                                            this.$route
                                                                .name ==
                                                                'warehouse-mb-detail',
                                                    },attrs:{"href":"/trawlpack/ho/inventory/mitra-business"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Gudang M.Business ")])]),_c('li',[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'warehouse-mpw' ||
                                                            this.$route
                                                                .name ==
                                                                'warehouse-mpw-detail',
                                                    },attrs:{"href":"/trawlpack/ho/inventory/mitra-warehouse"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Gudang M.Warehouse ")])]),_c('li',[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'warehouse-ms' ||
                                                            this.$route
                                                                .name ==
                                                                'warehouse-ms-detail',
                                                    },attrs:{"href":"/trawlpack/ho/inventory/mitra-space"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Gudang M.Space ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trackingOrderRoles)),expression:"isRole(trackingOrderRoles)"}],staticClass:"sidebar-dropdown1",class:{
                                        '': true,
                                        active:
                                            this.$route.name == 'manifest',
                                    }},[_c('a',{class:this.$route.name == 'manifest'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'detail-manifest'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'assign-transporter'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'receipt'
                                                ? 'active second'
                                                : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-globe sub"}),_c('span',[_vm._v("Tracking Order")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu1",class:this.$route.name == 'manifest'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'detail-manifest'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'assign-transporter'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'receipt'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name !=
                                                      'manifest'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'detail-manifest'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'assign-transporter'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'receipt'
                                                ? 'none'
                                                : ''},[_c('ul',[_c('li',[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'manifest' ||
                                                            this.$route
                                                                .name ==
                                                                'detail-manifest',
                                                    },attrs:{"href":"/trawlpack/ho/tracking-order/manifest"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Cek Manifest ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.trackingAssignTransporterRoles
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        trackingAssignTransporterRoles\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'assign-transporter' ||
                                                            this.$route
                                                                .name ==
                                                                'assign-transporter',
                                                    },attrs:{"href":"/trawlpack/ho/tracking-order/assign-transporter"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Assign Transporter ")])]),_c('li',[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'receipt' ||
                                                            this.$route
                                                                .name ==
                                                                'receipt',
                                                    },attrs:{"href":"/trawlpack/ho/tracking-order/receipt"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Cek Resi ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.customerPaymentRoles)),expression:"isRole(customerPaymentRoles)"}]},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                'admin-customer-payment',
                                        },attrs:{"href":"/trawlpack/ho/finance/customer-payment"}},[_c('i',{staticClass:"fa fa-money sub"}),_vm._v(" Customer payment ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.rolePriceCubic)),expression:"isRole(rolePriceCubic)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                'trawltruck-cubic-price',
                                        },attrs:{"href":"/trawltruck/cubic/price"}},[_c('i',{staticClass:"fa fa-dollar sub"}),_c('span',[_vm._v("Cek Harga Kubikasi")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.roleJadwalKapal)),expression:"isRole(roleJadwalKapal)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'harbors' ||
                                                this.$route.name ==
                                                    'harbors-create' ||
                                                this.$route.name ==
                                                    'harbors-edit',
                                        },attrs:{"href":"/harbors"}},[_c('i',{staticClass:"fa fa-calendar sub"}),_c('span',[_vm._v("Jadwal Kapal")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.disbursementRoles)),expression:"isRole(disbursementRoles)"}]},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'agent-disbursement' ||
                                                this.$route.name ==
                                                    'agent-disbursement-detail',
                                        },attrs:{"href":"/trawlpack/ho/disbursement/sales-agent"}},[_c('i',{staticClass:"fa fa-money sub"}),_vm._v(" Pencairan Agen ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.InvoiceRoles)),expression:"isRole(InvoiceRoles)"}],staticClass:"sidebar-dropdown",class:{
                            '': true,
                            active:
                                this.$route.name ==
                                'trawlpack-send-invoice',
                        }},[_c('a',{class:this.$route.name == 'trawlpack-send-invoice'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'trawlpack-send-invoice-create'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'trawlpack-invoice'
                                    ? 'active'
                                    : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-clipboard sub"}),_c('span',[_vm._v("Invoice")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu",class:this.$route.name == 'trawlpack-send-invoice'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'trawlpack-send-invoice-create'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'trawlpack-invoice'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name !=
                                          'trawlpack-send-invoice'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'trawlpack-send-invoice-create'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'trawlpack-invoice'
                                    ? 'none'
                                    : ''},[_c('ul',[_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                    'trawlpack-send-invoice' ||
                                                this.$route.name ==
                                                    'trawlpack-send-invoice-create',
                                        },attrs:{"href":"/trawlpack/invoice/send"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Send Invoice ")])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'trawlpack-invoice',
                                        },attrs:{"href":"/trawlpack/invoice/print"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Invoice Page ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.TrotruckkRoles)),expression:"isRole(TrotruckkRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:this.$route.name ==
                                'dashboard-trawltruck-ho-finance'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'dashboard-trawltruck-ho-operation'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-ho-cek-tarif'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'detail-management-driver'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'management-fleet'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'detail-management-fleet'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'incoming-order'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'management-order'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-order-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-ho-disbursement'
                                    ? 'active mb-1'
                                    : '' || this.$route.name == 'topup'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'management-mitra'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-mitra-create'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-mitra-edit'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-mitra-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'management-truck'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-truck-create'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-truck-edit'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-truck-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-ho-order'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-ho-order-edit'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-ho-order-edit-status'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-ho-order-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-ho-order-detail-old'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-ho-order-create'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-drivers'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-drivers-create'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-drivers-edit'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-drivers-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-delivery-payment'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-delivery-payment-edit'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-delivery-payment-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-ho-disbursement'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-disbursement-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-master-route'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-master-route-create'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-master-route-edit'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-aggrement'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-aggrement-edit'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-aggrement-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-truck-stand-by'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'management-truck-stand-by-edit'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'register-driver'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'register-driver-reject'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'account-driver'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'detail-account-driver'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'suspend-driver'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'tracking-driver'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'tracking-driver-detail'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'disbursement-of-funds'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'disbursement-of-funds-detail'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'assign-driver'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'assign-driver-detail'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-withdraw-individu'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-withdraw-individu-detail'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-mitra-balance'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-mitra-balance-detail'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'truck-commission-sticker'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'truck-commission-sticker-detail'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'truck-sticker'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'truck-sticker-detail'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'truck-sticker-reject'
                                    ? 'active'
                                    : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-truck"}),_c('span',[_vm._v("Troben Truk")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu",class:this.$route.name ==
                                'dashboard-trawltruck-ho-finance'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'dashboard-trawltruck-ho-operation'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-ho-cek-tarif'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'register-driver'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'account-driver'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'detail-account-driver'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'register-driver-reject'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'suspend-driver'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'disbursement-of-funds'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'disbursement-of-funds-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'tracking-driver'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'tracking-driver-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'management-truck'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-truck-create'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-truck-edit'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-truck-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-ho-order'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-ho-order-edit'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-ho-order-edit-status'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-ho-order-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-ho-order-detail-old'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-ho-order-create'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-drivers'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-drivers-create'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-drivers-edit'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-drivers-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-delivery-payment'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-delivery-payment-edit'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-delivery-payment-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-ho-disbursement'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-disbursement-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-master-route'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-master-route-create'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-master-route-edit'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-aggrement'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-aggrement-edit'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-aggrement-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-truck-stand-by'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-truck-stand-by-edit'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'assign-driver'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'assign-driver-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-withdraw-individu'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-withdraw-individu-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-mitra-balance'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-mitra-balance-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'truck-commission-sticker'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'truck-commission-sticker-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'truck-sticker'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'truck-sticker-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'truck-sticker-reject'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name !=
                                          'dashboard-trawltruck-ho-finance'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'dashboard-trawltruck-ho-operation'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'trawltruck-ho-cek-tarif'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'register-driver'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'account-driver'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'detail-account-driver'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'register-driver-reject'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'suspend-driver'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'disbursement-of-funds'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'disbursement-of-funds-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'tracking-driver'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'tracking-driver-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'management-mitra'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-mitra-create'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-mitra-edit'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-mitra-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'management-truck'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-truck-create'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-truck-edit'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-truck-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-ho-order'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-ho-order-edit'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-ho-order-edit-status'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-ho-order-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-ho-order-detail-old'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-ho-order-create'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-drivers'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-drivers-create'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-drivers-edit'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-drivers-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-delivery-payment'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-delivery-payment-edit'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-delivery-payment-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-ho-disbursement'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-disbursement-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-master-route'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-master-route-create'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-master-route-edit'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-aggrement'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-aggrement-edit'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-aggrement-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-truck-stand-by'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'management-truck-stand-by-edit'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'trawltruck-mitra'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'trawltruck-mitra-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'trawltruck-mitra-edit'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'trawltruck-mitra-create'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'assign-driver'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'assign-driver-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'trawltruck-withdraw-individu'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'trawltruck-withdraw-individu-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'trawltruck-mitra-balance'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'trawltruck-mitra-balance-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'truck-commission-sticker'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'truck-commission-sticker-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'truck-sticker'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'truck-sticker-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'truck-sticker-reject'
                                    ? 'none'
                                    : ''},[_c('ul',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                        _vm.isRole(_vm.dashboardTrawltruckHoRoles)
                                    ),expression:"\n                                        isRole(dashboardTrawltruckHoRoles)\n                                    "}],staticClass:"sidebar-dropdown1",class:{
                                        '': true,
                                        active:
                                            this.$route.name ==
                                            'dashboard-trawltruck-ho-finance',
                                    }},[_c('a',{class:this.$route.name ==
                                            'dashboard-trawltruck-ho-finance'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'dashboard-trawltruck-ho-operation'
                                                ? 'active second'
                                                : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-dashboard sub"}),_c('span',[_vm._v("Dashboard")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu1",class:this.$route.name ==
                                            'dashboard-trawltruck-ho-finance'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'dashboard-trawltruck-ho-operation'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name !=
                                                      'dashboard-trawltruck-ho-finance'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'dashboard-trawltruck-ho-operation'
                                                ? 'none'
                                                : ''},[_c('ul',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.dashboardTrawltruckHoFinanceRoles
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        dashboardTrawltruckHoFinanceRoles\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                            'dashboard-trawltruck-ho-finance',
                                                    },attrs:{"href":"/trawltruck/ho/dashboard/finance"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Finance ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.dashboardTrawltruckHoOperationRoles
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        dashboardTrawltruckHoOperationRoles\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                            'dashboard-trawltruck-ho-operation',
                                                    },attrs:{"href":"/trawltruck/ho/dashboard/operation"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Operation ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.tarifHoTrawltruck)),expression:"isRole(tarifHoTrawltruck)"}],staticClass:"sidebar-dropdown1"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                'trawltruck-ho-cek-tarif',
                                        },attrs:{"href":"/trawltruck/ho/cek-tarif"}},[_c('i',{staticClass:"fa fa-dollar sub"}),_c('span',[_vm._v("Cek Tarif")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.financeRoles)),expression:"isRole(financeRoles)"}],staticClass:"sidebar-dropdown1"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'trawltruck-withdraw-individu' ||
                                                this.$route.name ==
                                                    'trawltruck-withdraw-individu-detail',
                                        },attrs:{"href":"/trawltruck/withdraw/individu"}},[_c('i',{staticClass:"fa fa-money sub"}),_c('span',[_vm._v("Tarik Dana")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.financeRoles)),expression:"isRole(financeRoles)"}],staticClass:"sidebar-dropdown1"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'trawltruck-mitra-balance' ||
                                                this.$route.name ==
                                                    'trawltruck-mitra-balance-detail',
                                        },attrs:{"href":"/trawltruck/mitra/balance"}},[_c('i',{staticClass:"fa fa-money sub"}),_c('span',[_vm._v("Saldo Mitra")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawltruckRoles)),expression:"isRole(trawltruckRoles)"}],staticClass:"sidebar-dropdown1",class:{
                                        '': true,
                                        active:
                                            this.$route.name ==
                                            'register-driver',
                                    }},[_c('a',{class:this.$route.name ==
                                            'register-driver'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'register-driver-reject'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'account-driver'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'detail-account-driver'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'suspend-driver'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'tracking-driver'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'tracking-driver-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'disbursement-of-funds'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'disbursement-of-funds-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'assign-driver'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'assign-driver-detail'
                                                ? 'active second'
                                                : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-user sub"}),_c('span',[_vm._v("Individu")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu1",class:this.$route.name ==
                                            'register-driver'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'register-driver-reject'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'account-driver'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'detail-account-driver'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'suspend-driver'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'tracking-driver'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'tracking-driver-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'disbursement-of-funds'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'disbursement-of-funds-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'assign-driver'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'assign-driver-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name !=
                                                      'register-driver'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'register-driver-reject'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'account-driver'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'detail-account-driver'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'suspend-driver'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'tracking-driver'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'tracking-driver-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'disbursement-of-funds'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'disbursement-of-funds-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'assign-driver'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'assign-driver-detail'
                                                ? 'none'
                                                : ''},[_c('ul',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(_vm.financeRoles)
                                                ),expression:"\n                                                    isRole(financeRoles)\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'disbursement-of-funds' ||
                                                            this.$route
                                                                .name ==
                                                                'disbursement-of-funds-detail',
                                                    },attrs:{"href":"/trawltruck/disbursement"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Pencairan Dana ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.assignDriverRoles
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        assignDriverRoles\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'assign-driver' ||
                                                            this.$route
                                                                .name ==
                                                                'assign-driver-detail',
                                                    },attrs:{"href":"/trawltruck/assign-driver"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Assign Register Driver ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.dashboardHoTrawltruckRoles
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        dashboardHoTrawltruckRoles\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'register-driver' ||
                                                            this.$route
                                                                .name ==
                                                                'register-driver-reject',
                                                    },attrs:{"href":"/trawltruck/driver/registration"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Register Driver ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.dashboardHoTrawltruckRoles
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        dashboardHoTrawltruckRoles\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'account-driver' ||
                                                            this.$route
                                                                .name ==
                                                                'detail-account-driver',
                                                    },attrs:{"href":"/trawltruck/driver/account"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Account Driver ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.dashboardHoTrawltruckRoles
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        dashboardHoTrawltruckRoles\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'tracking-driver' ||
                                                            this.$route
                                                                .name ==
                                                                'tracking-driver-detail',
                                                    },attrs:{"href":"/trawltruck/order/tracking"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Tracking Order ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.dashboardHoTrawltruckRoles
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        dashboardHoTrawltruckRoles\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                            'suspend-driver',
                                                    },attrs:{"href":"/trawltruck/driver/suspend"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Suspend Driver ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                        _vm.isRole(
                                            _vm.dashboardHoTrawltruckFinanceRoles
                                        )
                                    ),expression:"\n                                        isRole(\n                                            dashboardHoTrawltruckFinanceRoles\n                                        )\n                                    "}]},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'management-ho-order' ||
                                                this.$route.name ==
                                                    'management-ho-order-edit' ||
                                                this.$route.name ==
                                                    'management-ho-order-edit-status' ||
                                                this.$route.name ==
                                                    'management-ho-order-detail' ||
                                                this.$route.name ==
                                                    'management-ho-order-detail-old' ||
                                                this.$route.name ==
                                                    'management-ho-order-create',
                                        },attrs:{"href":"/trawltruck/ho/order"}},[_c('i',{staticClass:"fa fa-file-o sub"}),_c('span',[_vm._v("Management Order")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                        _vm.isRole(
                                            _vm.dashboardHoTrawltruckFinanceRoles
                                        )
                                    ),expression:"\n                                        isRole(\n                                            dashboardHoTrawltruckFinanceRoles\n                                        )\n                                    "}],staticClass:"sidebar-dropdown1",class:{
                                        '': true,
                                        active:
                                            this.$route.name ==
                                            'management-mitra',
                                    }},[_c('a',{class:this.$route.name ==
                                            'management-mitra'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-mitra-create'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-mitra-edit'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-mitra-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-truck'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-truck-create'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-truck-edit'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-truck-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-drivers'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-drivers-create'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-drivers-edit'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-drivers-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-delivery-payment'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-delivery-payment-edit'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-delivery-payment-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-master-route'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-master-route-create'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-master-route-edit'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-aggrement'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-aggrement-edit'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-aggrement-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-truck-stand-by'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-truck-stand-by-edit'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-ho-disbursement'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-disbursement-detail'
                                                ? 'active second'
                                                : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-building sub"}),_c('span',[_vm._v("Corporate")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu1",class:this.$route.name ==
                                            'management-mitra'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-mitra-create'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-mitra-edit'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-mitra-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-truck'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-truck-create'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-truck-edit'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-truck-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-drivers'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-drivers-create'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-drivers-edit'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-drivers-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-delivery-payment'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-delivery-payment-edit'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-delivery-payment-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-master-route'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-master-route-create'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-master-route-edit'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-aggrement'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-aggrement-edit'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-aggrement-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-truck-stand-by'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-truck-stand-by-edit'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-ho-disbursement'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'management-disbursement-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-mitra'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-mitra-create'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-mitra-edit'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-mitra-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-truck'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-truck-create'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-truck-edit'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-truck-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-drivers'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-drivers-create'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-drivers-edit'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-drivers-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-delivery-payment'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-delivery-payment-edit'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-delivery-payment-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-master-route'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-master-route-create'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-master-route-edit'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-aggrement'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-aggrement-edit'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-aggrement-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-truck-stand-by'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-truck-stand-by-edit'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-ho-disbursement'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'management-disbursement-detail'
                                                ? 'none'
                                                : ''},[_c('ul',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(_vm.financeRoles)
                                                ),expression:"\n                                                    isRole(financeRoles)\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'management-ho-disbursement' ||
                                                            this.$route
                                                                .name ==
                                                                'management-disbursement-detail',
                                                    },attrs:{"href":"/trawltruck/ho/disbursement"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Pencairan Dana ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.dashboardHoTrawltruckRoles
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        dashboardHoTrawltruckRoles\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'management-mitra' ||
                                                            this.$route
                                                                .name ==
                                                                'management-mitra-create' ||
                                                            this.$route
                                                                .name ==
                                                                'management-mitra-edit' ||
                                                            this.$route
                                                                .name ==
                                                                'management-mitra-detail',
                                                    },attrs:{"href":"/trawltruck/ho/partner"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Management Mitra ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.dashboardHoTrawltruckRoles
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        dashboardHoTrawltruckRoles\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'management-truck' ||
                                                            this.$route
                                                                .name ==
                                                                'management-truck-create' ||
                                                            this.$route
                                                                .name ==
                                                                'management-truck-edit' ||
                                                            this.$route
                                                                .name ==
                                                                'management-truck-detail',
                                                    },attrs:{"href":"/trawltruck/ho/fleet"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Management Truk ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.dashboardHoTrawltruckRoles
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        dashboardHoTrawltruckRoles\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'management-drivers' ||
                                                            this.$route
                                                                .name ==
                                                                'management-drivers-create' ||
                                                            this.$route
                                                                .name ==
                                                                'management-drivers-edit' ||
                                                            this.$route
                                                                .name ==
                                                                'management-drivers-detail',
                                                    },attrs:{"href":"/trawltruck/ho/driver"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Management Driver ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.dashboardHoTrawltruckFinanceRoles
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        dashboardHoTrawltruckFinanceRoles\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'management-delivery-payment' ||
                                                            this.$route
                                                                .name ==
                                                                'management-delivery-payment-edit' ||
                                                            this.$route
                                                                .name ==
                                                                'management-delivery-payment-detail',
                                                    },attrs:{"href":"/trawltruck/ho/delivery-payment"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Uang Jalan ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.dashboardHoTrawltruckRoles
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        dashboardHoTrawltruckRoles\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'management-master-route' ||
                                                            this.$route
                                                                .name ==
                                                                'management-master-route-create' ||
                                                            this.$route
                                                                .name ==
                                                                'management-master-route-edit',
                                                    },attrs:{"href":"/trawltruck/ho/master/route"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Management Rute ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.dashboardHoTrawltruckRoles
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        dashboardHoTrawltruckRoles\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'management-aggrement' ||
                                                            this.$route
                                                                .name ==
                                                                'management-aggrement-edit' ||
                                                            this.$route
                                                                .name ==
                                                                'management-aggrement-detail',
                                                    },attrs:{"href":"/trawltruck/ho/aggrement"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Perjanjian Kerjasama ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.dashboardHoTrawltruckRoles
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        dashboardHoTrawltruckRoles\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'management-truck-stand-by' ||
                                                            this.$route
                                                                .name ==
                                                                'management-truck-stand-by-edit',
                                                    },attrs:{"href":"/trawltruck/ho/truck-stand-by"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Truk Stand By ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.dashboardTrawlcarrier)),expression:"isRole(dashboardTrawlcarrier)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'truck-commission-sticker' ||
                                                this.$route.name ==
                                                    'truck-commission-sticker-detail',
                                        },attrs:{"href":"/truck/commission/sticker"}},[_c('i',{staticClass:"fa fa-money"}),_c('span',[_vm._v("Komisi Sticker Truck")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.truckStickerRoles)),expression:"isRole(truckStickerRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'truck-sticker' ||
                                                this.$route.name ==
                                                    'truck-sticker-detail' ||
                                                this.$route.name ==
                                                    'truck-sticker-reject',
                                        },attrs:{"href":"/truck/sticker"}},[_c('i',{staticClass:"fa fa-adjust"}),_c('span',[_vm._v("Sticker Truck")])])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.dashboardTrawlcarrier)),expression:"isRole(dashboardTrawlcarrier)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:this.$route.name ==
                                'carrier-ho-dashboard-finance'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'carrier-ho-dashboard-operation'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-order-list'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-vehicle-list'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-vehicle-create'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-driver-list'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-driver-history'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-driver-track'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-driver-create'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-disbursement'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-disbursement-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-manifest'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-manifest-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-order-assign-mitra'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'carrier-ho-refund'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'trocarrier-pricing'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'admin-manifest'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-manifest-detail'
                                    ? 'active mb-1'
                                    : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-inbox"}),_c('span',[_vm._v("Troben Carier")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu",class:this.$route.name ==
                                'carrier-ho-dashboard-finance'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'carrier-ho-dashboard-operation'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-order-list'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-vehicle-list'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-vehicle-create'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-driver-list'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-driver-history'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-driver-track'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-driver-create'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-disbursement'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-disbursement-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-manifest'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-manifest-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-trawlcareer-order-assign-mitra'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'carrier-ho-refund'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'carrier-ho-refund-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'trocarrier-pricing'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-order-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'admin-manifest'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-manifest-order'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name !=
                                          'carrier-ho-dashboard-finance'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'carrier-ho-dashboard-operation'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-trawlcareer-order-list'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-trawlcareer-vehicle-list'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-trawlcareer-vehicle-create'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-trawlcareer-driver-list'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-trawlcareer-driver-history'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-trawlcareer-driver-track'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-trawlcareer-driver-create'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-trawlcareer-disbursement'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-trawlcareer-disbursement-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-trawlcareer-manifest'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-trawlcareer-manifest-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-trawlcareer-order-assign-mitra'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'carrier-ho-refund'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'carrier-ho-refund-default'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'trocarrier-pricing'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-order-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'admin-manifest'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-manifest-order'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-wallet-income'
                                    ? 'none'
                                    : ''},[_c('ul',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.dashboardTrawlcarrier)),expression:"isRole(dashboardTrawlcarrier)"}],staticClass:"sidebar-dropdown1",class:{
                                        '': true,
                                        active:
                                            this.$route.name ==
                                            'carrier-ho-dashboard-finance',
                                    }},[_c('a',{class:this.$route.name ==
                                            'carrier-ho-dashboard-finance'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'carrier-ho-dashboard-operation'
                                                ? 'active second'
                                                : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-home"}),_c('span',[_vm._v("Dashboard")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu1",class:this.$route.name ==
                                            'carrier-ho-dashboard-finance'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'carrier-ho-dashboard-operation'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name !=
                                                      'carrier-ho-dashboard-finance'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'carrier-ho-dashboard-operation'
                                                ? 'none'
                                                : ''},[_c('ul',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.dashboardTrawlcarrierFinance
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        dashboardTrawlcarrierFinance\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                            'carrier-ho-dashboard-finance',
                                                    },attrs:{"href":"/carrier/ho/dasboard/finance"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Finance ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                                    _vm.isRole(
                                                        _vm.dashboardTrawlcarrierOperation
                                                    )
                                                ),expression:"\n                                                    isRole(\n                                                        dashboardTrawlcarrierOperation\n                                                    )\n                                                "}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                            'carrier-ho-dashboard-operation',
                                                    },attrs:{"href":"/carrier/ho/dasboard/operation"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Operation ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                        _vm.isRole(_vm.trawlcarrierFinanceRoles)
                                    ),expression:"\n                                        isRole(trawlcarrierFinanceRoles)\n                                    "}]},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'admin-trawlcareer-order-list' ||
                                                this.$route.name ==
                                                    'admin-trawlcareer-order-assign-mitra',
                                        },attrs:{"href":"/trawlcarrier/ho/order"}},[_c('i',{staticClass:"fa fa-file sub"}),_vm._v(" Pesanan ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlcarrierRoles)),expression:"isRole(trawlcarrierRoles)"}]},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'admin-trawlcareer-vehicle-list' ||
                                                this.$route.name ==
                                                    'admin-trawlcareer-vehicle-create',
                                        },attrs:{"href":"/trawlcarrier/ho/fleet"}},[_c('i',{staticClass:"fa fa-laptop sub"}),_vm._v(" Management Armada ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlcarrierRoles)),expression:"isRole(trawlcarrierRoles)"}]},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'admin-trawlcareer-driver-list' ||
                                                this.$route.name ==
                                                    'admin-trawlcareer-driver-create' ||
                                                this.$route.name ==
                                                    'admin-trawlcareer-driver-history' ||
                                                this.$route.name ==
                                                    'admin-trawlcareer-driver-track',
                                        },attrs:{"href":"/trawlcarrier/ho/driver"}},[_c('i',{staticClass:"fa fa-user sub"}),_vm._v(" Management Driver ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.financeRoles)),expression:"isRole(financeRoles)"}]},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'admin-trawlcareer-disbursement' ||
                                                this.$route.name ==
                                                    'admin-trawlcareer-disbursement-detail',
                                        },attrs:{"href":"/trawlcarrier/ho/disbursement"}},[_c('i',{staticClass:"fa fa-dollar sub"}),_vm._v(" Pencairan ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                                        _vm.isRole(_vm.trawlcarrierFinanceRoles)
                                    ),expression:"\n                                        isRole(trawlcarrierFinanceRoles)\n                                    "}]},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'admin-trawlcareer-manifest' ||
                                                this.$route.name ==
                                                    'admin-trawlcareer-manifest-detail',
                                        },attrs:{"href":"/trawlcarrier/ho/manifest"}},[_c('i',{staticClass:"fa fa-map-marker sub"}),_vm._v(" Tracking order ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.financeRoles)),expression:"isRole(financeRoles)"}]},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'carrier-ho-refund' ||
                                                this.$route.name ==
                                                    'carrier-ho-refund-detail',
                                        },attrs:{"href":"/carrier/ho/refund"}},[_c('i',{staticClass:"fa fa-money sub"}),_vm._v(" Pengembalian Dana ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.roleCarrierPricing)),expression:"isRole(roleCarrierPricing)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                'trocarrier-pricing',
                                        },attrs:{"href":"/trocarrier/pricing"}},[_c('i',{staticClass:"fa fa-dollar sub"}),_c('span',[_vm._v("Cek Harga Carier")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.manifestRoles)),expression:"isRole(manifestRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'admin-manifest' ||
                                                this.$route.name ==
                                                    'admin-manifest-detail',
                                        },attrs:{"href":"/trawlcarrier/partner/manifest"}},[_c('i',{staticClass:"fa fa-calculator sub"}),_c('span',[_vm._v("Manifest")])])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.orderTrawlcarrierRoles)),expression:"isRole(orderTrawlcarrierRoles)"}],staticClass:"sidebar-dropdown",class:{
                            '': true,
                            active: this.$route.name == 'admin-order-list',
                        }},[_c('a',{class:this.$route.name == 'admin-order-list'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-order-detail'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-order-confirmation'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-order-incoming'
                                    ? 'active'
                                    : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-shopping-cart"}),_c('span',[_vm._v("Order Carier")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu",class:this.$route.name == 'admin-order-list'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-order-confirmation'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-order-incoming'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-order-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name != 'admin-order-list'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-order-confirmation'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-order-incoming'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-order-detail'
                                    ? 'none'
                                    : ''},[_c('ul',[_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'admin-order-list',
                                        },attrs:{"href":"/trawlcarrier/partner/order"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Daftar Pesanan ")])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'admin-order-incoming',
                                        },attrs:{"href":"/trawlcarrier/partner/incoming-order"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Pesanan Masuk ")])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'admin-manifest',
                                        },attrs:{"href":"/trawlcarrier/partner/manifest"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Manifest ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.armadaRoles)),expression:"isRole(armadaRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:this.$route.name == 'admin-vehicle'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-vehicle-create'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-vehicle-edit'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'admin-driver'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'admin-assignment'
                                    ? 'active'
                                    : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-archive sub"}),_c('span',[_vm._v("Armada")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu",class:this.$route.name == 'admin-vehicle'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-vehicle-create'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-vehicle-edit'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'admin-driver'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'admin-assignment'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-wallet-income'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'admin-driver'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'admin-assignment'
                                    ? 'none'
                                    : ''},[_c('ul',[_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                    'admin-vehicle' ||
                                                this.$route.name ==
                                                    'admin-vehicle-create' ||
                                                this.$route.name ==
                                                    'admin-vehicle-edit',
                                        },attrs:{"href":"/trawlcarrier/partner/fleet"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Kendaraan ")])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'admin-driver',
                                        },attrs:{"href":"/trawlcarrier/partner/driver"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Driver ")])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'admin-assignment',
                                        },attrs:{"href":"/trawlcarrier/partner/assign"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Penugasan ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.CRMRoles)),expression:"isRole(CRMRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:this.$route.name == 'crm-specialist'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-specialist-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-specialist-form'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-registered'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-registered-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-candidate'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-candidate-create'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-candidate-edit'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-candidate-prospect'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-candidate-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-prospect-today'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-prospect-tomorrow'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-prospect-next-two-days'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-prospect-next-week'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-prospect-next-month'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-prospect-need-follow-up'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-prospect-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-requested-trawlcarrier'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-requested-trawlcarrier-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-requested-trawlpack'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-requested-trawlpack-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-today'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-tomorrow'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'crm-prospect-all'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-two-days'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-week'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-month'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-need-follow-up'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-prediction'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-lost'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-deal'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-follow-up-cro'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-follow-up-cro-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-follow-up-cro-prospect'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-follow-up-cro-prospect-deal'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-follow-up-cro-prospect-lost'
                                    ? 'active mb-1'
                                    : '' || this.$route.name == 'crm-target'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'crm-order-create'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'crm-order-edit'
                                    ? 'active mb-1'
                                    : '' || this.$route.name == 'crm-order'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'crm-order-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'crm-order-lost'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-order-lost-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'gratitude-journal'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'gratitude-journal-create'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'gratitude-journal-user'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'gratitude-journal-user-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'gratitude-journal-user-detail'
                                    ? 'active mb-1'
                                    : '' || this.$route.name == 'crm-tags'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'crm-tags-create'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'crm-tags-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'crm-branchs'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-branchs-create'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'crm-branchs-edit'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-branchs-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-customers-leads'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-customers-leads-prospect'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-customers-form'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-customers-form-edit'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-customers-leads-tags'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'trawltruck-mitra'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-mitra-create'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-mitra-edit'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-mitra-detail'
                                    ? 'active mb-1'
                                    : '' ||
                                      this.$route.name == 'dashboard-crm'
                                    ? 'active mb-1'
                                    : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-clipboard"}),_c('span',[_vm._v("CRM")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu",class:this.$route.name == 'crm-specialist'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-specialist-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-specialist-form'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-registered'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-registered-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-candidate'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-candidate-create'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-candidate-edit'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-candidate-prospect'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-candidate-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-prospect-today'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-prospect-tomorrow'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-prospect-next-two-days'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-prospect-next-week'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-prospect-next-month'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-prospect-need-follow-up'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-requested-trawlcarrier'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-requested-trawlcarrier-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-requested-trawlpack'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-requested-trawlpack-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-partnership-prospect-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-today'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-tomorrow'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'crm-prospect-all'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-two-days'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-week'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-month'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-need-follow-up'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-prediction'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-lost'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-deal'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-follow-up-cro'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-follow-up-cro-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-follow-up-cro-prospect'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-follow-up-cro-prospect-deal'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-follow-up-cro-prospect-lost'
                                    ? 'block'
                                    : '' || this.$route.name == 'crm-target'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'crm-order-create'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'crm-order-edit'
                                    ? 'block'
                                    : '' || this.$route.name == 'crm-order'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'crm-order-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'crm-order-lost'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-order-lost-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'gratitude-journal'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'gratitude-journal-create'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'gratitude-journal-user'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'gratitude-journal-user-detail'
                                    ? 'block'
                                    : '' || this.$route.name == 'crm-tags'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'crm-tags-create'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'crm-tags-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'crm-branchs'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-branchs-create'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'crm-branchs-edit'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-branchs-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-customers-leads'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-customers-leads-prospect'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-customers-form'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-customers-form-edit'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-customers-leads-tags'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'trawltruck-mitra'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-mitra-create'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-mitra-edit'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'trawltruck-mitra-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'dashboard-crm'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name != 'crm-specialist'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-specialist-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-specialist-form'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-registered'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-registered-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-candidate'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-candidate-create'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-candidate-edit'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-candidate-prospect'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-candidate-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-prospect-today'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-prospect-tomorrow'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-prospect-next-two-days'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-prospect-next-week'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-prospect-next-month'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-prospect-need-follow-up'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-prospect-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-requested-trawlcarrier'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-requested-trawlcarrier-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-requested-trawlpack'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-partnership-requested-trawlpack-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-today'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'crm-prospect-all'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-tomorrow'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-next-two-days'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-next-week'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-next-month'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-need-follow-up'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-prediction'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-lost'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-deal'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-follow-up-cro'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-follow-up-cro-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-follow-up-cro-prospect'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-follow-up-cro-prospect-deal'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-follow-up-cro-prospect-lost'
                                    ? 'none'
                                    : '' || this.$route.name != 'crm-target'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'crm-order-create'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'crm-order-edit'
                                    ? 'none'
                                    : '' || this.$route.name != 'crm-order'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'crm-order-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name == 'crm-order-lost'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-order-lost-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'gratitude-journal'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'gratitude-journal-create'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'gratitude-journal-user'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'gratitude-journal-user-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'crm-branchs'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-branchs-create'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'crm-branchs-edit'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-branchs-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'trawltruck-mitra'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'trawltruck-mitra-create'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'trawltruck-mitra-edit'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'trawltruck-mitra-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'dashboard-crm'
                                    ? 'none'
                                    : ''},[_c('ul',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmNonSpecialist)),expression:"isRole(crmNonSpecialist)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                            '': true,
                                            'active second':
                                                this.$route.name ==
                                                'dashboard-crm',
                                        },attrs:{"href":"/crm/dashboard"}},[_c('i',{staticClass:"fa fa-dashboard sub"}),_c('span',[_vm._v("Dashboard")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmNonSpecialist)),expression:"isRole(crmNonSpecialist)"}],staticClass:"sidebar-dropdown1"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'crm-specialist' ||
                                                this.$route.name ==
                                                    'crm-specialist-detail' ||
                                                this.$route.name ==
                                                    'crm-specialist-form',
                                        },attrs:{"href":"/crm/specialist"}},[_c('i',{staticClass:"fa fa-user"}),_c('span',[_vm._v("CRM Specialist")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmBd)),expression:"isRole(crmBd)"}],staticClass:"sidebar-dropdown1",class:{
                                        '': true,
                                        active:
                                            this.$route.name ==
                                            'crm-partnership-registered',
                                    }},[_c('a',{class:this.$route.name ==
                                            'crm-partnership-registered'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-registered-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-candidate'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-candidate-create'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-candidate-edit'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-candidate-prospect'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-candidate-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-prospect-today'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-prospect-tomorrow'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-prospect-next-two-days'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-prospect-next-week'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-prospect-next-month'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-prospect-need-follow-up'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-prospect-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-requested-trawlcarrier'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-requested-trawlcarrier-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-requested-trawlpack'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-requested-trawlpack-detail'
                                                ? 'active second'
                                                : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-hospital-o sub"}),_c('span',[_vm._v("Partnership")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu1",class:this.$route.name ==
                                            'crm-partnership-registered'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-registered-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-candidate'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-candidate-create'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-candidate-edit'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-candidate-prospect'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-candidate-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-prospect-today'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-prospect-tomorrow'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-prospect-next-two-days'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-prospect-next-week'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-prospect-next-month'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-prospect-need-follow-up'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-requested-trawlcarrier'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-requested-trawlcarrier-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-requested-trawlpack'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-requested-trawlpack-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-partnership-prospect-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-registered'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-registered-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-candidate'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-candidate-create'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-candidate-edit'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-candidate-prospect'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-candidate-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-prospect-today'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-prospect-tomorrow'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-prospect-next-two-days'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-prospect-next-week'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-prospect-next-month'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-prospect-need-follow-up'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-prospect-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-requested-trawlcarrier'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-requested-trawlcarrier-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-requested-trawlpack'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-partnership-requested-trawlpack-detail'
                                                ? 'none'
                                                : ''},[_c('ul',[_c('li',[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-registered' ||
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-registered-detail',
                                                    },attrs:{"href":"/crm/partnership/registered"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Registered ")])]),_c('li',[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-candidate' ||
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-candidate-create' ||
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-candidate-edit' ||
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-candidate-prospect' ||
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-candidate-detail',
                                                    },attrs:{"href":"/crm/partnership/candidate"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Candidate ")])]),_c('li',[_c('a',{class:{
                                                        second: true,
                                                        'active mb-2':
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-prospect-today' ||
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-prospect-tomorrow' ||
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-prospect-next-two-days' ||
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-prospect-next-week' ||
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-prospect-next-month' ||
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-prospect-need-follow-up' ||
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-prospect-detail',
                                                    },attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.isShowProspect =
                                                            !_vm.isShowProspect}}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Leads "),_c('i',{staticClass:"fa fa-angle-down pull-right"})])]),(
                                                    _vm.isShowProspect ||
                                                    this.$route.name ==
                                                        'crm-partnership-prospect-today' ||
                                                    this.$route.name ==
                                                        'crm-partnership-prospect-tomorrow' ||
                                                    this.$route.name ==
                                                        'crm-partnership-prospect-next-two-days' ||
                                                    this.$route.name ==
                                                        'crm-partnership-prospect-next-week' ||
                                                    this.$route.name ==
                                                        'crm-partnership-prospect-next-month' ||
                                                    this.$route.name ==
                                                        'crm-partnership-prospect-need-follow-up' ||
                                                    this.$route.name ==
                                                        'crm-partnership-prospect-detail'
                                                )?_c('div',{staticClass:"box p-2 ps-0 pe-0 mb-3"},[_c('li',[_c('a',{class:{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'crm-partnership-prospect-today',
                                                        },attrs:{"href":"/crm/partnership/prospect/today"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(2),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                                        'red-solid':
                                                                            this
                                                                                .$route
                                                                                .name ==
                                                                            'crm-partnership-prospect-today',
                                                                        'gray-solid':
                                                                            this
                                                                                .$route
                                                                                .name !=
                                                                            'crm-partnership-prospect-today',
                                                                    }},[_vm._v(" "+_vm._s(_vm.partnership_prospect_count?.today ? _vm.partnership_prospect_count?.today : 0)+" ")])])])])]),_c('li',[_c('a',{class:{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'crm-partnership-prospect-tomorrow',
                                                        },attrs:{"href":"/crm/partnership/prospect/tomorrow"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(3),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                                        'red-solid':
                                                                            this
                                                                                .$route
                                                                                .name ==
                                                                            'crm-partnership-prospect-tomorrow',
                                                                        'gray-solid':
                                                                            this
                                                                                .$route
                                                                                .name !=
                                                                            'crm-partnership-prospect-tomorrow',
                                                                    }},[_vm._v(" "+_vm._s(_vm.partnership_prospect_count?.tomorrow ? _vm.partnership_prospect_count?.tomorrow : 0)+" ")])])])])]),_c('li',[_c('a',{class:{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'crm-partnership-prospect-next-two-days',
                                                        },attrs:{"href":"/crm/partnership/prospect/next-two-days"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(4),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                                        'red-solid':
                                                                            this
                                                                                .$route
                                                                                .name ==
                                                                            'crm-partnership-prospect-next-two-days',
                                                                        'gray-solid':
                                                                            this
                                                                                .$route
                                                                                .name !=
                                                                            'crm-partnership-prospect-next-two-days',
                                                                    }},[_vm._v(" "+_vm._s(_vm.partnership_prospect_count?.next_2_days ? _vm.partnership_prospect_count?.next_2_days : 0)+" ")])])])])]),_c('li',[_c('a',{class:{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'crm-partnership-prospect-next-week',
                                                        },attrs:{"href":"/crm/partnership/prospect/next-week"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(5),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                                        'red-solid':
                                                                            this
                                                                                .$route
                                                                                .name ==
                                                                            'crm-partnership-prospect-next-week',
                                                                        'gray-solid':
                                                                            this
                                                                                .$route
                                                                                .name !=
                                                                            'crm-partnership-prospect-next-week',
                                                                    }},[_vm._v(" "+_vm._s(_vm.partnership_prospect_count?.next_week ? _vm.partnership_prospect_count?.next_week : 0)+" ")])])])])]),_c('li',[_c('a',{class:{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'crm-partnership-prospect-next-month',
                                                        },attrs:{"href":"/crm/partnership/prospect/next-month"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(6),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                                        'red-solid':
                                                                            this
                                                                                .$route
                                                                                .name ==
                                                                            'crm-partnership-prospect-next-month',
                                                                        'gray-solid':
                                                                            this
                                                                                .$route
                                                                                .name !=
                                                                            'crm-partnership-prospect-next-month',
                                                                    }},[_vm._v(" "+_vm._s(_vm.partnership_prospect_count?.next_month ? _vm.partnership_prospect_count?.next_month : 0)+" ")])])])])]),_c('li',[_c('a',{class:{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'crm-partnership-prospect-need-follow-up',
                                                        },attrs:{"href":"/crm/partnership/prospect/need-follow-up"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(7),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                                        'red-solid':
                                                                            this
                                                                                .$route
                                                                                .name ==
                                                                            'crm-partnership-prospect-need-follow-up',
                                                                        'gray-solid':
                                                                            this
                                                                                .$route
                                                                                .name !=
                                                                            'crm-partnership-prospect-need-follow-up',
                                                                    }},[_vm._v(" "+_vm._s(_vm.partnership_prospect_count?.need_follow_up ? _vm.partnership_prospect_count?.need_follow_up : 0)+" ")])])])])])]):_vm._e(),_c('li',[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-requested-trawlpack' ||
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-requested-trawlpack-detail',
                                                    },attrs:{"href":"/crm/partnership/requested-trawlpack"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Requested Cargo ")])]),_c('li',[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-requested-trawlcarrier' ||
                                                            this.$route
                                                                .name ==
                                                                'crm-partnership-requested-trawlcarrier-detail',
                                                    },attrs:{"href":"/crm/partnership/requested-trawlcarrier"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Requested Carier ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.mitraTruck)),expression:"isRole(mitraTruck)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'trawltruck-mitra' ||
                                                this.$route.name ==
                                                    'trawltruck-mitra-detail' ||
                                                this.$route.name ==
                                                    'trawltruck-mitra-edit' ||
                                                this.$route.name ==
                                                    'trawltruck-mitra-create',
                                        },attrs:{"href":"/trawltruck/mitra"}},[_c('i',{staticClass:"fa fa-heart-o sub"}),_c('span',[_vm._v("Mitra Truk")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRoles)),expression:"isRole(crmRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'crm-customers-leads' ||
                                                this.$route.name ==
                                                    'crm-customers-leads-prospect' ||
                                                this.$route.name ==
                                                    'crm-customers-form' ||
                                                this.$route.name ==
                                                    'crm-customers-form-edit' ||
                                                this.$route.name ==
                                                    'crm-customers-leads-tags',
                                        },attrs:{"href":"/crm/customers/leads"}},[_c('i',{staticClass:"fa fa-credit-card"}),_c('span',[_vm._v("Prospect and Leads Data")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRoles)),expression:"isRole(crmRoles)"}],staticClass:"sidebar-dropdown1",class:{
                                        '': true,
                                        active:
                                            this.$route.name ==
                                            'crm-prospect-today',
                                    }},[_c('a',{class:this.$route.name ==
                                            'crm-prospect-today'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-tomorrow'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-all'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-next-two-days'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-next-week'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-next-month'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-need-follow-up'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-detail'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-prediction'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-lost'
                                                ? 'active second'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-deal'
                                                ? 'active second'
                                                : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-book sub"}),_c('span',[_vm._v("Follow Up")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu1",class:this.$route.name ==
                                            'crm-prospect-today'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-tomorrow'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-all'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-next-two-days'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-next-week'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-next-month'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-need-follow-up'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-detail'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-prediction'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-lost'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name ==
                                                      'crm-prospect-deal'
                                                ? 'block'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-prospect-today'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-prospect-all'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-prospect-tomorrow'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-prospect-next-two-days'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-prospect-next-week'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-prospect-next-month'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-prospect-need-follow-up'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-prospect-detail'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-prospect-prediction'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-prospect-lost'
                                                ? 'none'
                                                : '' ||
                                                  this.$route.name !=
                                                      'crm-prospect-deal'
                                                ? 'none'
                                                : ''},[_c('ul',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRoles)),expression:"isRole(crmRoles)"}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-all',
                                                    },attrs:{"href":"/crm/prospect/all"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(8),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                                    'red-solid':
                                                                        this
                                                                            .$route
                                                                            .name ==
                                                                        'crm-prospect-all',
                                                                    'gray-solid':
                                                                        this
                                                                            .$route
                                                                            .name !=
                                                                        'crm-prospect-all',
                                                                }},[_vm._v(" "+_vm._s(_vm.prospect_count?.today + _vm.prospect_count?.tomorrow + _vm.prospect_count?.next_2_days + _vm.prospect_count?.next_month + _vm.prospect_count?.need_follow_up ? _vm.prospect_count?.today + _vm.prospect_count?.tomorrow + _vm.prospect_count?.next_2_days + _vm.prospect_count?.next_month + _vm.prospect_count?.need_follow_up : 0)+" ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRoles)),expression:"isRole(crmRoles)"}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-today',
                                                    },attrs:{"href":"/crm/prospect/today"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(9),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                                    'red-solid':
                                                                        this
                                                                            .$route
                                                                            .name ==
                                                                        'crm-prospect-today',
                                                                    'gray-solid':
                                                                        this
                                                                            .$route
                                                                            .name !=
                                                                        'crm-prospect-today',
                                                                }},[_vm._v(" "+_vm._s(_vm.prospect_count?.today ? _vm.prospect_count?.today : 0)+" ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRoles)),expression:"isRole(crmRoles)"}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-tomorrow',
                                                    },attrs:{"href":"/crm/prospect/tomorrow"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(10),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                                    'red-solid':
                                                                        this
                                                                            .$route
                                                                            .name ==
                                                                        'crm-prospect-tomorrow',
                                                                    'gray-solid':
                                                                        this
                                                                            .$route
                                                                            .name !=
                                                                        'crm-prospect-tomorrow',
                                                                }},[_vm._v(" "+_vm._s(_vm.prospect_count?.tomorrow ? _vm.prospect_count?.tomorrow : 0)+" ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRoles)),expression:"isRole(crmRoles)"}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-next-two-days',
                                                    },attrs:{"href":"/crm/prospect/next-two-days"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(11),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                                    'red-solid':
                                                                        this
                                                                            .$route
                                                                            .name ==
                                                                        'crm-prospect-next-two-days',
                                                                    'gray-solid':
                                                                        this
                                                                            .$route
                                                                            .name !=
                                                                        'crm-prospect-next-two-days',
                                                                }},[_vm._v(" "+_vm._s(_vm.prospect_count?.next_2_days ? _vm.prospect_count?.next_2_days : 0)+" ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRoles)),expression:"isRole(crmRoles)"}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-next-week',
                                                    },attrs:{"href":"/crm/prospect/next-week"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(12),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                                    'red-solid':
                                                                        this
                                                                            .$route
                                                                            .name ==
                                                                        'crm-prospect-next-week',
                                                                    'gray-solid':
                                                                        this
                                                                            .$route
                                                                            .name !=
                                                                        'crm-prospect-next-week',
                                                                }},[_vm._v(" "+_vm._s(_vm.prospect_count?.next_week ? _vm.prospect_count?.next_week : 0)+" ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRoles)),expression:"isRole(crmRoles)"}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-next-month',
                                                    },attrs:{"href":"/crm/prospect/next-month"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(13),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                                    'red-solid':
                                                                        this
                                                                            .$route
                                                                            .name ==
                                                                        'crm-prospect-next-month',
                                                                    'gray-solid':
                                                                        this
                                                                            .$route
                                                                            .name !=
                                                                        'crm-prospect-next-month',
                                                                }},[_vm._v(" "+_vm._s(_vm.prospect_count?.next_month ? _vm.prospect_count?.next_month : 0)+" ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRoles)),expression:"isRole(crmRoles)"}]},[_c('a',{class:{
                                                        sub: true,
                                                        active:
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-need-follow-up',
                                                    },attrs:{"href":"/crm/prospect/need-follow-up"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(14),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                                    'red-solid':
                                                                        this
                                                                            .$route
                                                                            .name ==
                                                                        'crm-prospect-need-follow-up',
                                                                    'gray-solid':
                                                                        this
                                                                            .$route
                                                                            .name !=
                                                                        'crm-prospect-need-follow-up',
                                                                }},[_vm._v(" "+_vm._s(_vm.prospect_count?.need_follow_up ? _vm.prospect_count?.need_follow_up : 0)+" ")])])])])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmSpecialistCro)),expression:"isRole(crmSpecialistCro)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'crm-follow-up-cro' ||
                                                this.$route.name ==
                                                    'crm-follow-up-cro-detail' ||
                                                this.$route.name ==
                                                    'crm-follow-up-cro-prospect' ||
                                                this.$route.name ==
                                                    'crm-follow-up-cro-prospect-deal' ||
                                                this.$route.name ==
                                                    'crm-follow-up-cro-prospect-lost',
                                        },attrs:{"href":"/crm/follow-up-cro"}},[_c('i',{staticClass:"fa fa-book"}),_c('span',[_vm._v("Follow Up CRO")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmNonSpecialist)),expression:"isRole(crmNonSpecialist)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'crm-target' ||
                                                this.$route.name ==
                                                    'crm-order-create' ||
                                                this.$route.name ==
                                                    'crm-order-edit',
                                        },attrs:{"href":"/crm/target"}},[_c('i',{staticClass:"fa fa-building sub"}),_c('span',[_vm._v("Company Target")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRepeatOrder)),expression:"isRole(crmRepeatOrder)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'crm-order' ||
                                                this.$route.name ==
                                                    'crm-order-detail',
                                        },attrs:{"href":"/crm/order"}},[_c('i',{staticClass:"fa fa-shopping-cart sub"}),_c('span',[_vm._v("Deal Order")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRepeatOrder)),expression:"isRole(crmRepeatOrder)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'crm-order-lost' ||
                                                this.$route.name ==
                                                    'crm-order-lost-detail',
                                        },attrs:{"href":"/crm/order-lost"}},[_c('i',{staticClass:"fa fa-shopping-cart sub"}),_c('span',[_vm._v("Lost Order")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmNonSpecialist)),expression:"isRole(crmNonSpecialist)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'gratitude-journal' ||
                                                this.$route.name ==
                                                    'gratitude-journal-create' ||
                                                this.$route.name ==
                                                    'gratitude-journal-user' ||
                                                this.$route.name ==
                                                    'gratitude-journal-user-detail',
                                        },attrs:{"href":"/crm/gratitude-journal"}},[_c('i',{staticClass:"fa fa-heart sub"}),_c('span',[_vm._v("Jurnal Syukur")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRoles)),expression:"isRole(crmRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'crm-tags' ||
                                                this.$route.name ==
                                                    'crm-tags-create' ||
                                                this.$route.name ==
                                                    'crm-tags-detail',
                                        },attrs:{"href":"/crm/tags"}},[_c('i',{staticClass:"fa fa-tags sub"}),_c('span',[_vm._v("Tags")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmAdminOnly)),expression:"isRole(crmAdminOnly)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                            second: true,
                                            active:
                                                this.$route.name ==
                                                    'crm-branchs' ||
                                                this.$route.name ==
                                                    'crm-branchs-create' ||
                                                this.$route.name ==
                                                    'crm-branchs-edit' ||
                                                this.$route.name ==
                                                    'crm-branchs-detail',
                                        },attrs:{"href":"/crm/branchs"}},[_c('i',{staticClass:"fa fa-bank"}),_c('span',[_vm._v("Trobens Branch")])])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.dashboardtrawltruckCorporateRoles)),expression:"isRole(dashboardtrawltruckCorporateRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name ==
                                    'dashboard-trawltruck-mitra',
                            },attrs:{"href":"/trawltruck/partner/dashboard"}},[_c('i',{staticClass:"fa fa-home"}),_c('span',[_vm._v("Dashboard Truk")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawltruckCorporateRoles)),expression:"isRole(trawltruckCorporateRoles)"}],staticClass:"sidebar-dropdown",class:{
                            '': true,
                            active: this.$route.name == 'management-driver',
                        }},[_c('a',{class:this.$route.name == 'management-driver'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'detail-management-driver'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'management-fleet'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'detail-management-fleet'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'incoming-order'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'management-order'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'management-order-detail'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'management-ho-disbursement'
                                    ? 'active'
                                    : '' || this.$route.name == 'topup'
                                    ? 'active'
                                    : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-building sub"}),_c('span',[_vm._v("Truk Corporate")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu",class:this.$route.name == 'management-driver'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'detail-management-driver'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'management-fleet'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'detail-management-fleet'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'incoming-order'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'management-order'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'management-order-detail'
                                    ? 'block'
                                    : '' || this.$route.name == 'topup'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name !=
                                          'management-driver'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'detail-management-driver'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'management-fleet'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'detail-management-fleet'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'incoming-order'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'incoming-order-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'management-order'
                                    ? 'none'
                                    : '' || this.$route.name != 'topup'
                                    ? 'none'
                                    : ''},[_c('ul',[_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                    'management-driver' ||
                                                this.$route.name ==
                                                    'detail-management-driver',
                                        },attrs:{"href":"/trawltruck/partner/driver"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Manajemen Driver ")])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                    'management-fleet' ||
                                                this.$route.name ==
                                                    'detail-management-fleet',
                                        },attrs:{"href":"/trawltruck/partner/fleet"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Manajemen Kendaraan ")])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'incoming-order',
                                        },attrs:{"href":"/trawltruck/incoming/order"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Incoming Order ")])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                    'management-order' ||
                                                this.$route.name ==
                                                    'management-order-detail',
                                        },attrs:{"href":"/trawltruck/partner/order"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Manajemen Order ")])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'management-disbursement',
                                        },attrs:{"href":"/trawltruck/partner/disbursement"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Manajemen Pencairan ")])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name == 'topup',
                                        },attrs:{"href":"/trawltruck/partner/topup"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Top up ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlpackOwnerRoles)),expression:"isRole(trawlpackOwnerRoles)"}],staticClass:"sidebar-dropdown",class:{
                            '': true,
                            active: this.$route.name == 'dashboard-mb',
                        }},[_c('a',{class:this.$route.name == 'dashboard-mb'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'dashboard-item-mb'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'dashboard-mpw'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'dashboard-item-mpw'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'dashboard-mtak'
                                    ? 'active'
                                    : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-globe"}),_c('span',[_vm._v("Dashboard Mitra")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu",class:this.$route.name == 'dashboard-mb'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'dashboard-item-mb'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'dashboard-mpw'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name != 'dashboard-mb'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name != 'dashboard-mtak'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name !=
                                          'dashboard-item-mpw'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'dashboard-item-mb'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'dashboard-mpw'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'dashboard-item-mpw'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'dashboard-mtak'
                                    ? 'none'
                                    : ''},[_c('ul',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlpackOwnerMBRoles)),expression:"isRole(trawlpackOwnerMBRoles)"}]},[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'dashboard-mb',
                                        },attrs:{"href":"/trawlpack/partner/dashboard/income"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Pendapatan Mitra ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlpackOwnerMBRoles)),expression:"isRole(trawlpackOwnerMBRoles)"}]},[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'dashboard-item-mb',
                                        },attrs:{"href":"/trawlpack/partner/dashboard/warehouse"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Barang Masuk Gudang ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlpackOwnerMPWRoles)),expression:"isRole(trawlpackOwnerMPWRoles)"}]},[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'dashboard-mpw',
                                        },attrs:{"href":"/trawlpack/partner/dashboard/income"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Pendapatan ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlpackOwnerMPWRoles)),expression:"isRole(trawlpackOwnerMPWRoles)"}]},[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'dashboard-item-mpw',
                                        },attrs:{"href":"/trawlpack/partner/dashboard/warehouse"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Barang Masuk ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlpackOwnerMTAKRoles)),expression:"isRole(trawlpackOwnerMTAKRoles)"}]},[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'dashboard-mtak',
                                        },attrs:{"href":"/trawlpack/partner/dashboard/income"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Pendapatan ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.walletRoles)),expression:"isRole(walletRoles)"}],staticClass:"sidebar-dropdown",class:{
                            '': true,
                            active:
                                this.$route.name == 'admin-wallet-income',
                        }},[_c('a',{class:this.$route.name == 'admin-wallet-income'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-wallet-disbursement'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-wallet-disbursement-request'
                                    ? 'active'
                                    : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-credit-card"}),_c('span',[_vm._v("Dompet")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu",class:this.$route.name == 'admin-wallet-income'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-wallet-disbursement'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'admin-wallet-disbursement-request'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-wallet-income'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-wallet-disbursement'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'admin-wallet-disbursement-request'
                                    ? 'none'
                                    : ''},[_c('ul',[_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'admin-wallet-income',
                                        },attrs:{"href":"/trawlcarrier/partner/wallet/income"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Pemasukan ")])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                    'admin-wallet-disbursement' ||
                                                this.$route.name ==
                                                    'admin-wallet-disbursement-request',
                                        },attrs:{"href":"/trawlcarrier/partner/wallet/disbursement"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Pencairan ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlpackOwnerMBRoles)),expression:"isRole(trawlpackOwnerMBRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'owner-income-mb',
                            },attrs:{"href":"/trawlpack/partner/income"}},[_c('i',{staticClass:"fa fa-home"}),_c('span',[_vm._v("Incoming Orders")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlpackOwnerMPWRoles)),expression:"isRole(trawlpackOwnerMPWRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'owner-income-mpw',
                            },attrs:{"href":"/trawlpack/partner/income"}},[_c('i',{staticClass:"fa fa-home"}),_c('span',[_vm._v("Incoming Orders")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlpackOwnerMTAKRoles)),expression:"isRole(trawlpackOwnerMTAKRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'owner-income-mtak',
                            },attrs:{"href":"/trawlpack/partner/income"}},[_c('i',{staticClass:"fa fa-home"}),_c('span',[_vm._v("Incoming Orders")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlpackOwnerMBRoles)),expression:"isRole(trawlpackOwnerMBRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name ==
                                    'status-manifest-mb',
                            },attrs:{"href":"/trawlpack/partner/manifest"}},[_c('i',{staticClass:"fa fa-home"}),_c('span',[_vm._v("Status Manifest")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlpackOwnerMPWRoles)),expression:"isRole(trawlpackOwnerMPWRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name ==
                                    'status-manifest-mpw',
                            },attrs:{"href":"/trawlpack/partner/manifest"}},[_c('i',{staticClass:"fa fa-home"}),_c('span',[_vm._v("Status Manifest")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlpackOwnerMTAKRoles)),expression:"isRole(trawlpackOwnerMTAKRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name ==
                                    'status-manifest-mtak',
                            },attrs:{"href":"/trawlpack/partner/manifest"}},[_c('i',{staticClass:"fa fa-home"}),_c('span',[_vm._v("Status Manifest")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlpackOwnerMBRoles)),expression:"isRole(trawlpackOwnerMBRoles)"}],staticClass:"sidebar-dropdown",class:{
                            '': true,
                            active: this.$route.name == 'warehouse-mw-mb',
                        }},[_c('a',{class:this.$route.name == 'warehouse-mw-mb'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'warehouse-p-mb'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'warehouse-rtd-mb'
                                    ? 'active'
                                    : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-globe"}),_c('span',[_vm._v("Warehouse")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu",class:this.$route.name == 'warehouse-mw-mb'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'warehouse-p-mb'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'warehouse-rtd-mb'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name != 'warehouse-mw-mb'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'warehouse-p-mb'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'warehouse-rtd-mb'
                                    ? 'none'
                                    : ''},[_c('ul',[_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'warehouse-mw-mb',
                                        },attrs:{"href":"/trawlpack/partner/warehouse/weight"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Ukur Timbang ")])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                    'warehouse-p-mb' ||
                                                this.$route.name ==
                                                    'warehouse-p-mb',
                                        },attrs:{"href":"/trawlpack/partner/warehouse/packing"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Packing ")])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                    'warehouse-rtd-mb' ||
                                                this.$route.name ==
                                                    'warehouse-rtd-mb',
                                        },attrs:{"href":"/trawlpack/partner/warehouse/delivery"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Siap Antar ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlpackOwnerMPWRoles)),expression:"isRole(trawlpackOwnerMPWRoles)"}],staticClass:"sidebar-dropdown",class:{
                            '': true,
                            active: this.$route.name == 'warehouse-rtd-mb',
                        }},[_c('a',{class:this.$route.name == 'warehouse-rtd-mb'
                                    ? 'active'
                                    : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-globe"}),_c('span',[_vm._v("Warehouse")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu",class:this.$route.name == 'warehouse-rtd-mb'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name != 'warehouse-rtd-mb'
                                    ? 'none'
                                    : ''},[_c('ul',[_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                    'warehouse-rtd-mb' ||
                                                this.$route.name ==
                                                    'warehouse-rtd-mb',
                                        },attrs:{"href":"/trawlpack/partner/warehouse/delivery"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Siap Antar ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlpackOwnerRoles)),expression:"isRole(trawlpackOwnerRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'owner-employee',
                            },attrs:{"href":"/trawlpack/partner/asset/staff"}},[_c('i',{staticClass:"fa fa-home"}),_c('span',[_vm._v("Kelola Staff")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.trawlpackOwnerRoles)),expression:"isRole(trawlpackOwnerRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'owner-transporter',
                            },attrs:{"href":"/trawlpack/partner/asset/fleet"}},[_c('i',{staticClass:"fa fa-home"}),_c('span',[_vm._v("Manajemen Kendaraan")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.roleOnlyAdmin)),expression:"isRole(roleOnlyAdmin)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'master-customer' ||
                                    this.$route.name ==
                                        'master-customer-detail',
                            },attrs:{"href":"/master/customer"}},[_c('i',{staticClass:"fa fa-user"}),_c('span',[_vm._v("Master Customer")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.roleOnlyAdmin)),expression:"isRole(roleOnlyAdmin)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'master-user' ||
                                    this.$route.name ==
                                        'master-user-detail',
                            },attrs:{"href":"/master/user"}},[_c('i',{staticClass:"fa fa-users"}),_c('span',[_vm._v("Master User")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.chatRoles)),expression:"isRole(chatRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active: this.$route.name == 'Chat',
                            },attrs:{"href":"/chat"}},[_c('i',{staticClass:"fa fa-wechat"}),_c('span',[_vm._v("Chat")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmStaffRoles)),expression:"isRole(crmStaffRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name ==
                                        'gratitude-journal' ||
                                    this.$route.name ==
                                        'gratitude-journal-create' ||
                                    this.$route.name ==
                                        'gratitude-journal-user' ||
                                    this.$route.name ==
                                        'gratitude-journal-user-detail',
                            },attrs:{"href":'/crm/gratitude-journal/user/' + _vm.user_id}},[_c('i',{staticClass:"fa fa-heart"}),_c('span',[_vm._v("Jurnal Syukur")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.truckStickerRoles)),expression:"isRole(truckStickerRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name ==
                                        'truck-commission-sticker-ga' ||
                                    this.$route.name ==
                                        'truck-commission-sticker-ga-detail' ||
                                    this.$route.name ==
                                        'truck-commission-sticker-ga-reject',
                            },attrs:{"href":"/truck/commission/sticker-ga"}},[_c('i',{staticClass:"fa fa-money"}),_c('span',[_vm._v("Komisi Sticker Truck")])])])])])])]),_c('main',{staticClass:"page-content"},[_c('div',{staticClass:"nav-top-main"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div'),_c('div',{staticClass:"ms-auto"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"dropdown"},[_c('div',{staticClass:"notifications toggle dd-trigger me-4 cursor-pointer"},[_c('span',{staticClass:"count",attrs:{"id":"notifications-count"}},[_vm._v(_vm._s(_vm.totalNotif))]),_c('span',{staticClass:"fa fa-bell-o size-22"})]),_c('div',{staticClass:"dropdown-menu"},[_vm._m(15),_c('hr'),(_vm.notif_all.length > 0)?[_c('div',{class:{
                                            'scroll-notif':
                                                _vm.notif_all.length > 4,
                                        }},[_c('div',{staticClass:"d-flex mb-3"},[_c('div',{staticClass:"ms-auto"},[_c('a',{staticClass:"text-green",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.readAllNotif()}}},[_c('u',[_vm._v("Tandai semua telah dibaca")])])])]),_vm._l((_vm.notif_all),function(notif,index){return [_c('a',{key:index,attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.readNotif(notif.id)}}},[_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('div',{staticClass:"me-2"},[_c('div',{staticClass:"user-avatar-sidebar user-notif",class:{
                                                                grayscale:
                                                                    notif.status ==
                                                                    'read',
                                                            },style:({
                                                                'background-image':
                                                                    'url(' +
                                                                    notif.icon +
                                                                    ')',
                                                            })},[(
                                                                    notif.status ==
                                                                    'pending'
                                                                )?_c('span',{staticClass:"position-absolute top-0 start-100 translate-middle p-alert-notif bg-warning border border-light rounded-circle"},[_c('span',{staticClass:"visually-hidden"},[_vm._v("New alerts")])]):_vm._e()])]),_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"size-16 fw-semibold",class:{
                                                                    'text-gray-dashboard':
                                                                        notif.status ==
                                                                        'read',
                                                                    'text-dark':
                                                                        notif.status ==
                                                                        'pending',
                                                                }},[_vm._v(" "+_vm._s(notif.title)+" ")]),_c('div',{staticClass:"ms-auto text-gray size-12"},[_vm._v(" "+_vm._s(notif.created_at)+" ")])]),_c('div',{class:{
                                                                'text-gray-dashboard':
                                                                    notif.status ==
                                                                    'read',
                                                                'text-dark':
                                                                    notif.status ==
                                                                    'pending',
                                                            }},[_vm._v(" "+_vm._s(notif.subtitle)+" ")])])])])]})],2)]:[_c('div',{staticClass:"mt-4"},[_c('center',[_c('img',{attrs:{"src":require("../assets/no-notif.png"),"width":"120","alt":""}}),_c('div',{staticClass:"mt-3 text-gray-dashboard"},[_vm._v(" Belum ada notifikasi ")])])],1)],_c('hr'),_vm._m(16)],2)]),_c('div',{staticClass:"dropdown-profile"},[_c('div',{staticClass:"toggle-profile cursor-pointer"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"me-2"},[(_vm.user.avatar != null)?[_c('div',{staticClass:"user-avatar-sidebar user-small",style:({
                                                    'background-image':
                                                        'url(' +
                                                        _vm.user.avatar +
                                                        ')',
                                                })})]:[_c('div',{staticClass:"user-avatar-sidebar user-small",style:({
                                                    'background-image':
                                                        'url(' +
                                                        require('../assets/user.jpg') +
                                                        ')',
                                                })})]],2),_c('div',{staticClass:"fw-medium size-14"},[_vm._v(" "+_vm._s(_vm.user.name)+" "),_c('i',{staticClass:"fa fa-angle-down ms-2"})])])]),_c('div',{staticClass:"dropdown-menu-profile"},[_c('router-link',{attrs:{"to":{ name: _vm.profileRole() }}},[_c('a',{staticClass:"size-14 fw-semibold text-dark"},[_vm._v(" Edit Profile ")])]),_c('hr'),_c('a',{staticClass:"text-danger size-14 fw-semibold",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.logout()}}},[_c('i',{staticClass:"fa fa-sign-out me-1"}),_vm._v(" Log Out ")])],1)])])])])]),_c('div',{staticClass:"container-fluid mt-nav-top"},[_c('router-view',{attrs:{"totalNotif":_vm.totalNotif,"message":this.$attrs.message},on:{"update-data-event":_vm.getCountNotif}})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn btn-sm btn-primary",attrs:{"id":"show-sidebar"}},[_c('i',{staticClass:"fa fa-angle-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-brand"},[_c('div',{attrs:{"id":"close-sidebar"}},[_c('i',{staticClass:"fa fa-angle-left"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Today ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Tomorrow ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Next 2 day ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Next Week ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Next Month ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Need Follow Up ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" All Follow Up ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Today ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Tomorrow ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Next 2 Days ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Next Week ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Next Month ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Need Follow Up ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex items-align-center"},[_c('div',{staticClass:"size-16 fw-semibold"},[_vm._v(" Notifikasi ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('a',{staticClass:"text-green",attrs:{"href":"/notification"}},[_vm._v(" Lihat Semua Notifikasi "),_c('i',{staticClass:"fa fa-angle-right ms-2"})])])
}]

export { render, staticRenderFns }