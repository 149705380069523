<template>
  <div>
    <div class="modal-vue">
      <div class="overlay" v-if="isModal == 'fail'"></div>
      <div class="modal-body-chat sm" v-if="isModal == 'fail'">
        <div class="d-flex align-items-lg-center justify-content-center flex-column">
          <img class="mb-3" src="../../assets/cross-red.png" />
          <div class="size-18 fw-ekstra-bold">{{ title }}</div>
        </div>
        <div class="d-flex align-items-lg-center justify-content-center fw-bold mb-5 mt-4">{{ info }}</div>
        <div class="d-flex gap-2">
          <button class="button button-fail" @click="send">OK</button>
        </div>
      </div>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="isModal == 'success'"></div>
      <div class="modal-body-chat sm" v-if="isModal == 'success'">
        <div class="d-flex align-items-lg-center justify-content-center flex-column">
          <img class="mb-3" src="../../assets/checklist.png" />
          <div class="size-18 fw-ekstra-bold">{{ title }}</div>
        </div>
        <div class="d-flex align-items-lg-center justify-content-center fw-bold mb-5 mt-4">{{ info }}</div>
        <div class="d-flex gap-2">
          <button class="button button-success" @click="send">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    info: {
      type: String,
      default: "",
    },
    isModal: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    send() {
      this.isModal = "";
      this.$emit("send", "");
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  display: flex;
  border-radius: 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  &-success {
    background: #3d8824;
    border: 1px solid #3d8824;
    color: white;
  }
  &-success:is(:hover, :focus) {
    background: #4fa532;
  }
  &-fail {
    background: #e60013;
    border: 1px solid #e60013;
    color: #ffffff;
  }
  &-fail:is(:hover, :focus) {
    background: #e94b58;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
}
</style>
