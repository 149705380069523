<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-3">Leads Detail</h3>
            </div>
            <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                <tr>
                    <td class="ps-0 w-200">Follow Up</td>
                    <td class="w-30">:</td>
                    <td>
                        <div class="d-flex">
                            <date-picker
                                v-model="detail.followup_at"
                                type="date"
                                :disabled="!is_edit_follow_up"
                                value-type="format"
                                format="YYYY-MM-DD"
                                v-bind:class="
                                    !is_edit_follow_up ? 'view' : 'm-mt-0-5'
                                "
                                :clearable="false"
                                placeholder="Masukkan tanggal"
                                style="width: 250px"
                            >
                            </date-picker>
                            <div class="ms-auto">
                                <a
                                    href="javascript:void(0)"
                                    class="text-blue-edit"
                                    @click="
                                        is_edit_follow_up = !is_edit_follow_up
                                    "
                                    v-if="!is_edit_follow_up"
                                >
                                    <i class="fa fa-pencil me-2"></i>
                                    Edit
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    class="text-green"
                                    v-if="is_edit_follow_up"
                                    @click="updateFollowUp()"
                                >
                                    <i class="fa fa-save me-2"></i>
                                    Save
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Partner Type</td>
                    <td class="w-30">:</td>
                    <td class="capitalize">
                        {{ detail.partner_type ? detail.partner_type : "-" }}
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Activity</td>
                    <td class="w-30">:</td>
                    <td class="capitalize">
                        {{ detail.activity ? detail.activity : "-" }}
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Company Name</td>
                    <td class="w-30">:</td>
                    <td>{{ detail.company ? detail.company : "-" }}</td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Owner Name</td>
                    <td class="w-30">:</td>
                    <td>{{ detail.pic ? detail.pic : "-" }}</td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Phone</td>
                    <td class="w-30">:</td>
                    <td>{{ detail.phone ? detail.phone : "-" }}</td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Email</td>
                    <td class="w-30">:</td>
                    <td>{{ detail.email ? detail.email : "-" }}</td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Note</td>
                    <td class="w-30">:</td>
                    <td>{{ detail.note ? detail.note : "-" }}</td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Owner</td>
                    <td class="w-30">:</td>
                    <td>{{ detail.owner ? detail.owner : "-" }}</td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Status</td>
                    <td class="w-30">:</td>
                    <td>
                        <small
                            class="tag blue-solid px-5 radius mb-0"
                            v-if="detail.status == 'process'"
                        >
                            Process
                        </small>
                        <small
                            class="tag green-solid px-5 radius mb-0"
                            v-else-if="detail.status == 'join'"
                        >
                            Join
                        </small>
                        <small class="tag red-solid px-5 radius mb-0" v-else>
                            Exit
                        </small>
                    </td>
                </tr>
            </table>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Leads Activities History</h3>
            </div>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-3 m-mx-0">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Search"
                            v-model="search"
                            @input="searchPage()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="ms-2">
                    <button
                        class="btn btn-primary btn-with-search px-5"
                        @click="openModalActivity()"
                    >
                        Add Activity
                    </button>
                </div>
            </div>
            <div class="mt-4" v-if="is_activity">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Created At
                                </th>
                                <th class="text-center" scope="col">Owner</th>
                                <th class="text-center" scope="col">
                                    Activity
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in activity.list_data"
                                :key="index"
                            >
                                <td class="text-center" style="width: 50px">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-center">
                                    {{
                                        moment(item.created_at).format(
                                            "DD/MM/YYYY"
                                        )
                                    }}
                                </td>
                                <td class="text-center">
                                    {{ item.owner ? item.owner : "-" }}
                                </td>
                                <td class="capitalize">
                                    <template
                                        v-for="(
                                            activity, index
                                        ) in item.activities"
                                    >
                                        <small
                                            class="tag gray mb-0"
                                            :key="index"
                                            >{{ activity }}</small
                                        >
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div
                    v-if="activity.list_data.length == 0"
                    class="text-center mt-section"
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="overflow-auto mt-3">
                <b-pagination-nav
                    v-if="activity.list_data.length > 0"
                    v-model="currentPage"
                    :number-of-pages="activity.total_page"
                    base-url="#"
                    first-number
                    align="right"
                    @input="changePage"
                ></b-pagination-nav>
            </div>

            <!-- MODAL -->

            <div class="modal-vue">
                <div class="overlay" v-if="modalAddActivity"></div>
                <div class="modal-body-chat md" v-if="modalAddActivity">
                    <div class="mt-3">
                        <h4 class="mb-0 fw-bold">Add New Activity</h4>
                        <div class="mt-4">
                            <label class="fw-bold mb-2">Activity</label>
                            <select
                                class="form-control form-select mt-2"
                                v-model="req.activity"
                            >
                                <option value="">Choose Activity</option>
                                <option value="canvasing">
                                    Canvasing/Visit
                                </option>
                                <option value="meeting">Meeting</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        <div class="mt-3" v-if="req.activity == 'canvasing'">
                            <label class="fw-bold">GMaps</label>
                            <GmapAutocomplete
                                @place_changed="processLocationChanged"
                                placeholder="Pilih lokasi anda"
                                class="form-control mt-2"
                            ></GmapAutocomplete>
                        </div>
                        <div class="mt-3" v-if="req.activity == 'canvasing'">
                            <label class="fw-bold">Upload Photo</label>
                            <div class="text-gray mb-2">Photo Max 5MB</div>
                            <label
                                type="file"
                                :class="!attachment ? 'upload__btn' : ''"
                            >
                                <img
                                    v-if="attachment"
                                    class="photo"
                                    :src="attachmentPictureUrl"
                                />
                                <p v-else class="mb-0">
                                    <i class="fa fa-plus"></i>
                                </p>
                                <input
                                    class="cursor-pointer"
                                    id="upload"
                                    type="file"
                                    name="file"
                                    ref="file"
                                    @change="uploadPhoto"
                                />
                            </label>
                        </div>
                        <div class="mt-3" v-if="req.activity == 'meeting'">
                            <div class="form-check form-check-inline">
                                <input
                                    v-model="req.meeting_type"
                                    class="form-check-input"
                                    type="radio"
                                    name="meeting_type"
                                    id="online"
                                    value="online"
                                />
                                <label class="form-check-label" for="online"
                                    >Online</label
                                >
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    v-model="req.meeting_type"
                                    class="form-check-input"
                                    type="radio"
                                    name="meeting_type"
                                    id="offline"
                                    value="offline"
                                />
                                <label class="form-check-label" for="offline"
                                    >Offline</label
                                >
                            </div>
                        </div>
                        <div
                            class="mt-3"
                            v-if="
                                req.activity == 'meeting' ||
                                req.activity == 'other'
                            "
                        >
                            <label class="fw-bold">Description</label>
                            <textarea
                                v-model="req.description"
                                cols="30"
                                rows="4"
                                class="form-control mt-2"
                                placeholder="Description"
                            ></textarea>
                        </div>
                    </div>
                    <div class="d-flex mt-5">
                        <div class="ms-auto">
                            <button
                                class="btn btn-outline-black px-5 btn-lg me-3"
                                @click="clearAddActivity()"
                            >
                                Cancel
                            </button>
                            <button
                                class="btn btn-green px-5 btn-lg"
                                type="button"
                                disabled
                                v-if="is_add_activity"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Save
                            </button>
                            <button
                                class="btn btn-green px-5 btn-lg"
                                :disabled="req.activity == ''"
                                @click="addNewActivity()"
                                v-else
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import { gmapApi } from "vue2-google-maps";
import noImage from "../../assets/no-photo.png";

export default {
    components: {
        ListLoader,
        DatePicker,
    },
    name: "crmPartnershipProspectDetail",
    data() {
        return {
            moment: moment,
            is_edit_follow_up: false,
            detail: "",
            per_page: 10,
            currentPage: 0,
            search: "",
            activity: {
                list_data: [],
            },
            is_activity: false,
            modalAddActivity: false,
            req: {
                activity: "",
                attachment: "",
                meeting_type: "online",
                description: "",
            },
            attachment: "",
            lat_search: "",
            lng_search: "",
            geo_address: "",
            is_add_activity: false,
        };
    },
    created() {
        this.getDetail();
    },
    computed: {
        attachmentPictureUrl() {
            return this.attachment.length > 0 ? this.attachment : noImage;
        },
        google: gmapApi,
    },
    methods: {
        getDetail() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/prospect/detail`,
                {
                    params: {
                        id: this.$route.params.id,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detail = res.data.data;
                    this.getListActivity();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getListActivity() {
            this.is_activity = true;
            let payload = {
                search: this.search,
                candidate_id: this.detail.candidate_id,
                limit: this.per_page,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/prospect/history/activities`,
                {
                    params: payload,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.activity = res.data.data;
                    this.is_activity = false;
                })
                .catch((err) => {
                    this.is_activity = false;
                    console.log(err);
                });
        },
        searchPage() {
            this.currentPage = 0;
            this.getListActivity();
        },
        changePage() {
            this.getListActivity();
        },
        updateFollowUp() {
            var data = {
                prospect_id: this.$route.params.id,
                followup_at: this.detail.followup_at,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/prospect/followup`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                        Accept: "application/json",
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_edit_follow_up = false;
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Data Updated!",
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        openModalActivity() {
            this.clearAddActivity();
            this.modalAddActivity = true;
        },
        clearAddActivity() {
            (this.modalAddActivity = false),
                (this.req.activity = ""),
                (this.attachment = ""),
                (this.req.attachment = ""),
                (this.req.description = ""),
                (this.req.meeting_type = "online");
        },
        uploadPhoto(event) {
            var input = event.target;
            this.req.attachment = input.files[0];
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.attachment = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
        processLocationChanged(ev) {
            this.lat_search = ev.geometry.location.toJSON().lat;
            this.lng_search = ev.geometry.location.toJSON().lng;
            this.geo_address = ev.formatted_address;
        },
        addNewActivity() {
            this.is_add_activity = true;
            var data = new FormData();
            if (this.req.activity == "canvasing") {
                data.append("prospect_id", this.$route.params.id);
                data.append("activity", this.req.activity);
                data.append("photo", this.req.attachment);
                data.append(
                    "geo_location",
                    this.lat_search + "," + this.lng_search
                );
                data.append("geo_address", this.geo_address);
            }
            if (this.req.activity == "meeting") {
                data.append("prospect_id", this.$route.params.id);
                data.append("activity", this.req.activity);
                data.append("desc", this.req.description);
                data.append("meeting_type", this.req.meeting_type);
            }
            if (this.req.activity == "other") {
                data.append("prospect_id", this.$route.params.id);
                data.append("activity", this.req.activity);
                data.append("desc", this.req.description);
            }
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/activity/create`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_add_activity = false;
                    this.modalAddActivity = false;
                    this.getListActivity();
                })
                .catch((err) => {
                    console.log(err.response);
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_add_activity = false;
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.left-50 {
    position: absolute;
    left: 50px;
}
.left-80 {
    position: relative;
    left: 80px;
}
.w-200 {
    width: 200px;
}
.w-30 {
    width: 30px;
}
.text-gray-dashboard {
    color: #61616a;
}
.search-icon-left {
    top: 11px;
    left: 10px;
}
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}
.nav-link.dashboard.mid {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.nav-link.dashboard.active {
    background-color: #e60013 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
}
.w-33 {
    width: 33%;
}
.w-34 {
    width: 34%;
}
@media (max-width: 576px) {
    .w-33 {
        width: 100%;
    }
    .w-34 {
        width: 100%;
    }
    .nav-link.dashboard.left {
        border-radius: 5px;
    }
    .nav-link.dashboard.right {
        border-radius: 5px;
    }
}
.vertical-align-unset {
    vertical-align: unset;
}
.w-mail-title {
    width: 80px;
}
.view {
    border-color: transparent;
    background-color: initial;
    color: initial;
    padding: 0px;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    border: dashed;
    border-radius: 10px;
    width: 13vw;
    height: 150px;
    padding: 55px;
    position: relative;
}
.photo {
    border-radius: 10px;
    cursor: pointer;
    width: 13vw;
    height: 150px;
    object-fit: cover;
    border: dashed;
    color: #dee2e6;
}
.btn-lg {
    width: 150px;
}
input[type="file"] {
    display: none;
}
.upload__btn p i {
    font-size: 30px;
}
</style>
