<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Candidate List</h3>
            </div>
            <div class="d-flex mt-4">
                <div class="me-3">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Search Owner Name"
                            v-model="search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div>
                    <a
                        href="/crm/partnership/candidate/create"
                        class="btn btn-primary btn-with-search px-5"
                    >
                        Add New
                    </a>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <div class="mt-4" v-if="is_list_ready">
                        <ListLoader />
                    </div>
                    <template v-else>
                        <div class="table-responsive-custom">
                            <table class="table table-bordered mt-4">
                                <thead class="table-light">
                                    <tr>
                                        <th class="text-center" scope="col">
                                            No
                                        </th>
                                        <th class="text-center" scope="col">
                                            Partner Type
                                        </th>
                                        <th class="text-center" scope="col">
                                            Company Name
                                        </th>
                                        <th class="text-center" scope="col">
                                            Owner Name
                                        </th>
                                        <th class="text-center" scope="col">
                                            Phone
                                        </th>
                                        <th class="text-center" scope="col">
                                            Status
                                        </th>
                                        <th class="text-center" scope="col">
                                            L. Count
                                        </th>
                                        <th class="text-center" scope="col">
                                            Leads
                                        </th>
                                        <th class="text-center" scope="col">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(
                                            item, index
                                        ) in candidate.list_data"
                                        :key="index"
                                    >
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="capitalize">
                                            <template
                                                v-for="(
                                                    product, index
                                                ) in item.partner_type"
                                            >
                                                <small
                                                    class="tag green pb-0 pt-0"
                                                    :key="index"
                                                >
                                                    {{ product }}
                                                </small>
                                            </template>
                                        </td>
                                        <td class="capitalize">
                                            <a
                                                :href="
                                                    `/crm/partnership/candidate/detail/` +
                                                    item.id
                                                "
                                                class="text-link"
                                            >
                                                {{
                                                    item.company
                                                        ? item.company
                                                        : "-"
                                                }}
                                            </a>
                                        </td>
                                        <td class="capitalize">
                                            {{ item.pic ? item.pic : "-" }}
                                        </td>
                                        <td class="text-center">
                                            {{ item.phone ? item.phone : "-" }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                                item.status ? item.status : "-"
                                            }}
                                        </td>
                                        <td class="text-center">
                                            {{ item.p_count }}x
                                        </td>
                                        <td class="text-center">
                                            <template
                                                v-if="
                                                    item.status == 'join' ||
                                                    item.exit_count == 3
                                                "
                                            >
                                                <a
                                                    href="javascript:void(0)"
                                                    class="cursor-drop"
                                                >
                                                    <img
                                                        src="../../assets/dashboard/prospect.png"
                                                        alt=""
                                                        width="19"
                                                    />
                                                </a>
                                            </template>
                                            <template v-else>
                                                <a
                                                    :href="
                                                        `/crm/partnership/candidate/prospect/` +
                                                        item.id
                                                    "
                                                >
                                                    <img
                                                        src="../../assets/dashboard/prospect.png"
                                                        alt=""
                                                        width="19"
                                                    />
                                                </a>
                                            </template>
                                        </td>
                                        <td class="text-center">
                                            <template
                                                v-if="
                                                    item.status == 'join' ||
                                                    item.exit_count == 3
                                                "
                                            >
                                                <a
                                                    href="javascript:void(0)"
                                                    class="cursor-drop me-2"
                                                >
                                                    <img
                                                        src="../../assets/dashboard/edit-icon.png"
                                                        alt=""
                                                        width="19"
                                                    />
                                                </a>
                                                <a
                                                    href="javascript:void(0)"
                                                    class="cursor-drop"
                                                >
                                                    <img
                                                        src="../../assets/dashboard/trash-icon.png"
                                                        alt=""
                                                        width="19"
                                                    />
                                                </a>
                                            </template>
                                            <template v-else>
                                                <a
                                                    :href="
                                                        `/crm/partnership/candidate/edit/` +
                                                        item.id
                                                    "
                                                    class="me-2"
                                                >
                                                    <img
                                                        src="../../assets/dashboard/edit-icon.png"
                                                        alt=""
                                                        width="19"
                                                    />
                                                </a>
                                                <a
                                                    href="javascript:void(0)"
                                                    @click="
                                                        openModalDelete(item.id)
                                                    "
                                                >
                                                    <img
                                                        src="../../assets/dashboard/trash-icon.png"
                                                        alt=""
                                                        width="19"
                                                    />
                                                </a>
                                            </template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <template v-if="candidate.list_data.length == 0">
                            <div class="text-center mt-5">
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </template>
                    </template>
                    <div class="d-flex" v-if="candidate.list_data.length > 0">
                        <div class="ms-auto">
                            <div class="d-flex mt-4">
                                <div class="me-3">
                                    <select
                                        class="form-control form-select w-select"
                                        v-model="per_page"
                                        @change="getList()"
                                    >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                                <b-pagination-nav
                                    v-model="currentPage"
                                    :number-of-pages="candidate.total_page"
                                    base-url="#"
                                    first-number
                                    align="right"
                                    @input="changePage"
                                ></b-pagination-nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="showModalDelete"></div>
            <div class="modal-body-chat vsm" v-if="showModalDelete">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/trash.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Are You Sure you want to Delete this Data?
                    </h4>
                </div>
                <div class="mt-4 row">
                    <div class="col-md-6">
                        <a
                            href="javascript:void(0)"
                            @click="showModalDelete = false"
                            class="btn btn-primary w-100 btn-lg"
                        >
                            Cancel
                        </a>
                    </div>
                    <div class="col-md-6">
                        <button
                            class="btn btn-outline-primary w-100 btn-lg"
                            type="button"
                            disabled
                            v-if="is_delete"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Ok
                        </button>
                        <button
                            @click="deleteList()"
                            class="btn btn-outline-primary w-100 btn-lg"
                            v-else
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";

export default {
    components: {
        ListLoader,
    },
    name: "crmPartnershipCandidate",
    data() {
        return {
            search: "",
            candidate: {
                list_data: [],
            },
            per_page: 10,
            currentPage: 1,
            is_list_ready: false,
            id: null,
            showModalDelete: false,
            is_delete: false,
        };
    },
    created() {
        this.getList();
    },
    computed: {},
    methods: {
        changePage() {
            this.getList();
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        getList() {
            let payload = {
                search: this.search,
                limit: this.per_page,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/candidates`,
                {
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.candidate = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        openModalDelete(id) {
            this.showModalDelete = true;
            this.id = id;
        },
        deleteList() {
            this.is_delete = true;
            let data = {
                id: this.id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/candidate/delete`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_delete = false;
                    this.showModalDelete = false;
                    this.getList();
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Deleted Data Success!!",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.is_delete = false;
                    this.showModalDelete = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
</style>
