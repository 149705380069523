<template>
    <div class="padding-container">
        <a
            href="javascript:void(0)"
            class="text-black size-16 text-gray-dashboard fw-semibold pb-3"
            @click="$router.go(-1)"
        >
            <i class="fa fa-angle-left me-2"></i>
            Kembali
        </a>
        <div class="box mt-4">
            <h3 class="mb-4 fw-bold">Ubah Mitra Penjemputan</h3>
            <div class="mb-3">
                <label class="fw-bold mb-2">No Resi</label>
                <div class="form-box">{{ detailData.package_code ?? "-" }}</div>
            </div>
            <div class="mb-3">
                <label class="fw-bold mb-2">Alamat Penjemputan</label>
                <div class="form-box h-50">
                    {{ detailData.sender_address ?? "-" }}
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="fw-bold mb-2">Detail Barang</label>
                        <div class="form-box">
                            <div class="d-flex align-items-center">
                                <div>
                                    {{
                                        detailData?.items[0]?.category_name ??
                                        "-"
                                    }}
                                </div>
                                <div class="ms-auto">
                                    <a
                                        href="javascript:void(0)"
                                        @click="modalContent = true"
                                        class="text-link"
                                    >
                                        Lihat Detail
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="fw-bold mb-2">Mitra Sebelumnya</label>
                        <div class="form-box">
                            {{ detailData.partner_code ?? "-" }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label class="fw-bold mb-2">Biaya Penjemputan</label>
                <div class="form-box">
                    {{ currency(detailData.calculate_data.pickup ?? 0) }}
                </div>
            </div>
            <div>
                <label class="fw-bold mb-2">Mitra Penjemputan</label>
                <multiselect
                    v-model="partner"
                    :options="partners"
                    label="code"
                    track-by="code"
                    placeholder="Pilih Mitra Penjemputan"
                    class="multi-form-custom"
                    @input="getPrice"
                >
                </multiselect>
            </div>
        </div>
        <div class="d-flex mt-4">
            <div class="ms-auto">
                <button
                    class="btn btn-outline-black btn-lg px-4 me-3"
                    @click="$router.go(-1)"
                >
                    Batal
                </button>
                <button
                    class="btn btn-green btn-lg px-5"
                    disabled
                    v-if="is_store"
                >
                    <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    Simpan
                </button>
                <button
                    class="btn btn-green btn-lg px-5"
                    :disabled="partner == '' || partner?.code == null"
                    @click="storePrice()"
                    v-else
                >
                    Simpan
                </button>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalContent"></div>
            <div class="modal-body-chat md" v-if="modalContent">
                <div class="mt-2 mb-1">
                    <template v-for="(item, index) in detailData.items">
                        <div class="mb-3" :key="index">
                            <a
                                href="javascript:void(0)"
                                class="text-black"
                                @click="item.is_collapse = !item.is_collapse"
                            >
                                <div
                                    class="box no-shadow bc-gray p-3 bc-gray-items"
                                >
                                    <h5 class="mb-0 fw-bold">
                                        <div class="d-flex align-items-center">
                                            Data Barang {{ index + 1 }}
                                            <div class="ms-auto">
                                                <template
                                                    v-if="
                                                        item.is_collapse ==
                                                        false
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-angle-down size-20"
                                                    ></i>
                                                </template>
                                                <template v-else>
                                                    <i
                                                        class="fa fa-angle-up size-20"
                                                    ></i>
                                                </template>
                                            </div>
                                        </div>
                                    </h5>
                                </div>
                            </a>
                            <div
                                class="border-gray p-3 border-md"
                                v-if="item.is_collapse"
                            >
                                <table class="w-100">
                                    <tr>
                                        <td class="pb-2" style="width: 200px">
                                            Jumlah Koli
                                        </td>
                                        <td class="pb-2" style="width: 20px">
                                            :
                                        </td>
                                        <td class="fw-semibold pb-2">
                                            {{ item.qty ? item.qty : 0 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pb-2" style="width: 200px">
                                            Deskripsi Barang
                                        </td>
                                        <td class="pb-2" style="width: 20px">
                                            :
                                        </td>
                                        <td class="fw-semibold pb-2">
                                            {{ item.desc ? item.desc : "-" }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pb-2" style="width: 200px">
                                            Dimensi Barang
                                        </td>
                                        <td class="pb-2" style="width: 20px">
                                            :
                                        </td>
                                        <td class="fw-semibold pb-2">
                                            {{ item.length }} x
                                            {{ item.width }} x
                                            {{ item.height }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pb-2" style="width: 200px">
                                            Volume Barang
                                        </td>
                                        <td class="pb-2" style="width: 20px">
                                            :
                                        </td>
                                        <td class="fw-semibold pb-2">
                                            {{ item.weight_borne_volume }}Kg
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pb-2" style="width: 200px">
                                            Berat Barang
                                        </td>
                                        <td class="pb-2" style="width: 20px">
                                            :
                                        </td>
                                        <td class="fw-semibold pb-2">
                                            {{ item.weight_borne_total }}Kg
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pb-2" style="width: 200px">
                                            Perlindungan Barang
                                        </td>
                                        <td class="pb-2" style="width: 20px">
                                            :
                                        </td>
                                        <td class="pb-2">
                                            <template
                                                v-for="(
                                                    handling, index
                                                ) in item.handling"
                                            >
                                                <small
                                                    class="mb-0 tag green px-2"
                                                    :key="index"
                                                >
                                                    <template
                                                        v-if="
                                                            handling ==
                                                            'bubble wrap'
                                                        "
                                                    >
                                                        Bubble Wrap
                                                    </template>
                                                    <template
                                                        v-if="
                                                            handling ==
                                                            'cardboard'
                                                        "
                                                    >
                                                        Kardus
                                                    </template>
                                                    <template
                                                        v-if="
                                                            handling ==
                                                            'plastic'
                                                        "
                                                    >
                                                        Plastik
                                                    </template>
                                                    <template
                                                        v-if="
                                                            handling == 'wood'
                                                        "
                                                    >
                                                        Kayu
                                                    </template>
                                                    <template
                                                        v-if="
                                                            handling ==
                                                            'sandbag sm'
                                                        "
                                                    >
                                                        Karung Kecil
                                                    </template>
                                                    <template
                                                        v-if="
                                                            handling ==
                                                            'sandbag md'
                                                        "
                                                    >
                                                        Karung Sedang
                                                    </template>
                                                    <template
                                                        v-if="
                                                            handling ==
                                                            'sandbag l'
                                                        "
                                                    >
                                                        Karung Besar
                                                    </template>
                                                </small>
                                            </template>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </template>
                    <div class="mt-3">
                        <button
                            class="btn btn-outline-primary px-5"
                            @click="modalContent = false"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalStore"></div>
            <div class="modal-body-chat vsm" v-if="modalStore">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="text-center">
                            <img
                                src="../../assets/dashboard/checklis.png"
                                width="65"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4 text-center">
                    <div class="size-18 fw-ekstra-bold">
                        Mitra Penjemputan Berhasil Diubah!
                    </div>
                    <div class="mt-1">Selamat! Anda berhasil merubah data.</div>
                    <div class="mt-4">
                        <a
                            href="/trawlpack/general/order"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ok
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            partner: "",
            partners: [],
            detailData: {
                calculate_data: {},
                items: [],
            },
            modalContent: false,
            modalStore: false,
            is_store: false,
        };
    },
    created() {
        this.getPartners();
        this.getDetailData();
    },
    methods: {
        getPartners() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/pass/trawlpack/partner`,
                {
                    params: {},
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.partners = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getDetailData() {
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/trawlpack/dashboard/order/detail/${this.$route.params.id}`,
                {
                    params: {},
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var items = res.data.data.items.map(function (key) {
                        if (key.category_item_id == 1) {
                            key.is_collapse = true;
                            return key;
                        } else {
                            key.is_collapse = false;
                            return key;
                        }
                    });
                    this.detailData.items = items;
                    this.detailData = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getPrice() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/pass/trawlpack/prices/pickup`,
                {
                    params: {
                        order_code: this.detailData.package_code,
                        partner_code: this.partner?.code,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detailData.calculate_data.pickup =
                        res.data.data.amount;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        storePrice() {
            this.is_store = true;
            var data = {
                order_code: this.detailData.package_code,
                partner_code: this.partner?.code,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/pass/trawlpack/prices/pickup`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_store = false;
                    this.modalStore = true;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_store = false;
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.border-md {
    border-radius: 15px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.bc-gray-items {
    background: #f4f6f7 !important;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
</style>
