<template>
    <div class="padding-container">
        <h3 class="mb-0 fw-bold">Dashboard Troben Truk Finance</h3>
        <div class="row">
            <div class="col-md-6 mt-4">
                <div class="box driver no-shadow">
                    <div class="d-flex align-items-center">
                        <div class="fw-bold size-16">Total Pendapatan</div>
                        <div class="ms-auto text-main-color size-16">
                            {{
                                moment(dataFinance.year_month).format(
                                    "YYYY MMMM"
                                )
                            }}
                        </div>
                    </div>
                    <div class="mt-4">
                        <h1 class="mb-0 fw-bold">
                            {{
                                currencyFormat(
                                    dataFinance.overview.income.this_month
                                )
                            }}
                            <span
                                class="size-h1"
                                :class="{
                                    'text-green-bold':
                                        dataFinance.overview.income.this_month >
                                        dataFinance.overview.income.last_month,
                                    'text-danger':
                                        dataFinance.overview.income.this_month <
                                        dataFinance.overview.income.last_month,
                                }"
                            >
                                ({{
                                    currencyFormatNoRp(
                                        dataFinance.overview.income.this_month -
                                            dataFinance.overview.income
                                                .last_month
                                    )
                                }})
                            </span>
                        </h1>
                    </div>
                    <div class="mt-3">
                        <h5 class="mb-0 text-main-color">
                            Pendapatan Sebelumnya
                            {{
                                currencyFormat(
                                    dataFinance.overview.income.last_month
                                )
                            }}
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-4">
                <div class="box blue-light no-shadow">
                    <div class="d-flex align-items-center">
                        <div class="fw-bold size-16">Total Transaksi</div>
                    </div>
                    <div class="mt-4">
                        <h1 class="mb-0 fw-bold">
                            {{ dataFinance.overview.transaction.this_month }}
                            Transaksi
                            <span
                                class="size-h1"
                                :class="{
                                    'text-green-bold':
                                        dataFinance.overview.transaction
                                            .this_month >
                                        dataFinance.overview.transaction
                                            .last_month,
                                    'text-danger':
                                        dataFinance.overview.transaction
                                            .this_month <
                                        dataFinance.overview.transaction
                                            .last_month,
                                }"
                            >
                                ({{
                                    dataFinance.overview.transaction
                                        .this_month -
                                    dataFinance.overview.transaction.last_month
                                }})
                            </span>
                        </h1>
                    </div>
                    <div class="mt-3">
                        <h5 class="mb-0 text-main-color">Jumlah Transaksi</h5>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-5">
                <div class="box pb-0">
                    <div class="d-flex align-items-center">
                        <h5 class="mb-0 fw-bold">Transaksi Sedang Berjalan</h5>
                    </div>
                    <hr />
                    <div class="scroll-small-box">
                        <template v-if="dataFinance.order_ongoing.length > 0">
                            <template
                                v-for="(
                                    data, index
                                ) in dataFinance.order_ongoing"
                            >
                                <div :key="index">
                                    <a
                                        :href="
                                            '/trawltruck/ho/order/detail/' +
                                            data.order_id
                                        "
                                        class="text-dark"
                                    >
                                        <div class="d-flex align-items-center">
                                            <div class="">
                                                <div class="size-16 fw-bold">
                                                    {{ data.partner }}
                                                </div>
                                                <div class="mt-2 size-16">
                                                    Harga Sewa:
                                                    <span
                                                        class="text-green-bold size-16"
                                                        >{{
                                                            currencyFormat(
                                                                data.total_amount
                                                            )
                                                        }}</span
                                                    >
                                                </div>
                                            </div>
                                            <div class="ms-auto">
                                                <div
                                                    class="text-right text-gray-dashboard"
                                                >
                                                    Order -
                                                    {{
                                                        moment(
                                                            data.order_date
                                                        ).format("DD MMM YYYY")
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <hr />
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <div class="text-center mb-5 mt-5">
                                <img
                                    src="../../assets/no-data.png"
                                    width="90"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-5">
                <div class="box pb-0">
                    <div class="d-flex align-items-center">
                        <h5 class="mb-0 fw-bold">Berikan Uang Jalan</h5>
                        <div class="ms-auto">
                            <a
                                href="/trawltruck/ho/delivery-payment"
                                class="size-14 text-dark"
                            >
                                Lihat Semua
                            </a>
                        </div>
                    </div>
                    <hr />
                    <div class="scroll-small-box">
                        <template
                            v-if="dataFinance.request_travel_expense.length > 0"
                        >
                            <template
                                v-for="(
                                    data, index
                                ) in dataFinance.request_travel_expense"
                            >
                                <div :key="index">
                                    <div class="d-flex align-items-center">
                                        <div class="">
                                            <div
                                                class="size-16 fw-bold text-green-bold"
                                            >
                                                {{ data.receipt }}
                                            </div>
                                            <div class="mt-2 size-16">
                                                {{ data.driver }} -
                                                {{ data.partner }}
                                            </div>
                                        </div>
                                        <div class="ms-auto">
                                            <div
                                                class="text-right text-gray-dashboard"
                                            >
                                                {{ data.origin_regency }} -
                                                {{ data.origin_destination }}
                                            </div>
                                            <div
                                                class="text-right text-gray-dashboard mt-2"
                                            >
                                                Order -
                                                {{
                                                    moment(
                                                        data.order_date
                                                    ).format("DD MMM YYYY")
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <div class="text-center mb-5 mt-5">
                                <img
                                    src="../../assets/no-data.png"
                                    width="90"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-4">
                <div class="box">
                    <div class="d-flex align-items-center">
                        <h5 class="mb-0 fw-bold">Grafik Pendapatan Mitra</h5>
                    </div>
                    <hr />
                    <div class="mt-3">
                        <apexcharts
                            ref="chartmonthly"
                            width="100%"
                            height="350"
                            type="bar"
                            :options="chartOptions"
                            :series="series"
                        ></apexcharts>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import { Tooltip } from "bootstrap";

export default {
    components: {
        apexcharts: VueApexCharts,
    },
    name: "Dashboard-Trawltruck HO",
    data() {
        return {
            moment: moment,
            params: {
                partner_type: "",
            },
            chartOptions: {
                xaxis: {
                    categories: [],
                    labels: {
                        formatter: function (val) {
                            return moment(val, "YYYY-MM-DD").format("MMM");
                            // return 'Tgl ' + val
                        },
                    },
                },
                colors: ["#E60013"],
                dataLabels: {
                    enabled: false,
                },
            },
            series: [
                {
                    name: "Pendapatan",
                    data: [],
                },
            ],
            req: {
                month: moment(new Date()).format("MMMM YYYY"),
                province: {
                    id: "",
                },
                district: {
                    id: "",
                },
                order_by: "desc",
                date_current: moment(new Date()).format("DD MMMM YYYY"),
            },
            is_data_ready: false,
            dataFinance: {
                income_chart: [],
                order_ongoing: [],
                request_travel_expense: [],
                overview: {
                    income: {},
                    transaction: {},
                },
                year_month: null,
            },
        };
    },
    created() {
        this.getDashboardFinance();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    methods: {
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        currencyFormatNoRp(num) {
            return parseInt(num)
                .toFixed(0)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        },
        getDashboardFinance() {
            this.is_data_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/dashboard-fnc`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.is_data_ready = false;
                    this.dataFinance = data;

                    let minNumber = Math.min(...data.income_chart.val);
                    minNumber = parseInt(minNumber - minNumber * 0.1);
                    minNumber = 0;

                    this.$refs.chartmonthly.updateSeries([
                        {
                            name: "Pendapatan",
                            data: data.income_chart.val,
                        },
                    ]);

                    let that = this;

                    this.$refs.chartmonthly.updateOptions({
                        xaxis: {
                            categories: data.income_chart.key,
                        },
                        yaxis: {
                            min: minNumber,
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return that.currencyFormat(val) + " Kg";
                                },
                            },
                        },
                    });
                })
                .catch((err) => {
                    this.is_data_ready = false;
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped>
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.active {
    background-color: #e60013 !important;
}
.box.mitra {
    background-color: #faccd0;
}
.box.truk {
    background-color: #feead4;
}
.box.driver {
    background-color: #d8e7d3;
}
.box.blue-light {
    background-color: #daecf6;
}
.text-main-color {
    color: #24516a !important;
}
.text-green-dashboard {
    color: #3d8824;
}
.text-green-bold {
    color: #3d8824;
}
.text-red-dashboard {
    color: #e60013;
}
.text-gray-dashboard {
    color: #61616a;
}
.text-right {
    text-align: right;
}
.line-height-custom {
    line-height: 30px;
}
.radius {
    border-radius: 50px;
}
.user-dashboard {
    width: 45px;
    height: 45px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
    border: 2px solid #f7f7f7;
}
.mt-tag {
    margin-top: 5px;
}
.size-h1 {
    font-size: 2.5rem;
}
.scroll-small-box {
    max-height: 320px;
    overflow-y: auto;
    overflow-x: hidden;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
}
</style>
