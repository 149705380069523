<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">List Data Order</h3>
                <div class="ms-auto">
                    <button
                        class="btn btn-green px-4"
                        @click="showModal = true"
                    >
                        Tambah Order
                    </button>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-4">
                    <div class="search-form">
                        <input
                            type="text"
                            class="form-control w-search-leads"
                            @keyup.enter="searchData"
                            v-model="keySearch"
                            placeholder="Cari No Resi"
                        />
                        <button class="btn btn-primary" @click="searchData()">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_my_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <template v-if="data.length > 0">
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        No Resi
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nama Customer
                                    </th>
                                    <th class="text-center" scope="col">
                                        Rute Sewa Truk
                                    </th>
                                    <th class="text-center" scope="col">
                                        Tanggal Sewa
                                    </th>
                                    <th class="text-center" scope="col">
                                        Total Harga Sewa
                                    </th>
                                    <th class="text-center" scope="col">
                                        Driver Ditugaskan
                                    </th>
                                    <th class="text-center" scope="col">
                                        Kendaraan
                                    </th>
                                    <th class="text-center" scope="col">
                                        Status
                                    </th>
                                    <th class="text-center" scope="col">
                                        Aksi
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(item, index) in data">
                                    <tr :key="index">
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="text-center">
                                            <a
                                                :href="
                                                    '/trawltruck/partner/order/detail/' +
                                                    item.id
                                                "
                                                class="text-dark"
                                            >
                                                <u>
                                                    {{ item.code }}
                                                </u>
                                            </a>
                                        </td>
                                        <td>
                                            {{ item.customer }}
                                        </td>
                                        <td style="width: 150px">
                                            <a
                                                href="javascript:void(0)"
                                                class="text-dark"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                :title="item.route"
                                            >
                                                <div class="limit-one-line">
                                                    {{ item.route }}
                                                </div>
                                            </a>
                                        </td>
                                        <td class="text-center">
                                            {{ dateFormat(item.order_date) }}
                                        </td>
                                        <td class="text-center">
                                            {{ currency(item.total_amount) }}
                                        </td>
                                        <td>
                                            {{ item.driver }}
                                        </td>
                                        <td>
                                            {{ item.fleet_type }}
                                        </td>
                                        <td
                                            class="text-center"
                                            style="width: 200px"
                                        >
                                            <template
                                                v-if="item.status == 'cancel'"
                                            >
                                                <small
                                                    class="tag red-solid radius mb-0 w-100 capitalize"
                                                >
                                                    {{ item.status_label }}
                                                </small>
                                            </template>
                                            <template
                                                v-else-if="
                                                    item.status == 'done'
                                                "
                                            >
                                                <small
                                                    class="tag green-solid radius mb-0 w-100 capitalize"
                                                >
                                                    {{ item.status_label }}
                                                </small>
                                            </template>
                                            <template
                                                v-else-if="
                                                    item.status == 'pending'
                                                "
                                            >
                                                <small
                                                    class="tag gray-solid radius mb-0 w-100 capitalize"
                                                >
                                                    {{ item.status_label }}
                                                </small>
                                            </template>
                                            <template v-else>
                                                <small
                                                    class="tag yellow-solid radius mb-0 w-100 capitalize"
                                                >
                                                    {{ item.status_label }}
                                                </small>
                                            </template>
                                        </td>
                                        <td>
                                            <center>
                                                <div class="dropdown">
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="text-dark"
                                                        id="dropdownMenuButton1"
                                                        data-bs-toggle="dropdown"
                                                    >
                                                        <i
                                                            class="fa fa-ellipsis-v"
                                                        ></i>
                                                    </a>
                                                    <ul
                                                        class="dropdown-menu"
                                                        aria-labelledby="dropdownMenuButton1"
                                                    >
                                                        <li>
                                                            <button
                                                                @click="
                                                                    getDetailData(
                                                                        item.id
                                                                    )
                                                                "
                                                                class="dropdown-item"
                                                            >
                                                                Live Tracking
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </center>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-section">
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </template>
            </div>
            <div class="mt-4">
                <b-pagination-nav
                    v-if="data.length > 0"
                    v-model="currentPage"
                    :number-of-pages="totalPage"
                    base-url="#"
                    first-number
                    align="right"
                    @input="changePage"
                ></b-pagination-nav>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="showModal">
                <div class="modal-body-chat md" v-if="showModal">
                    <div class="d-flex bd-highlight align-items-lg-center">
                        <div class="flex-grow-1 bd-highlight">
                            <div class="size-18 fw-ekstra-bold">
                                Tambah Orderan Baru
                            </div>
                        </div>
                        <div class="bd-highlight">
                            <a
                                href="javascript:void(0)"
                                @click="showModal = false"
                                class="size-20 text-primary"
                            >
                                <i class="fa fa-times-circle"></i>
                            </a>
                        </div>
                    </div>
                    <hr />
                    <div class="scroll">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb1" style="color: #8b8d97">
                                    Detail Order
                                </div>
                                <div class="mb1">&#160;</div>
                                <input
                                    class="mb-3 form-input"
                                    @input="fillForm"
                                    v-model="req.origin_name"
                                    placeholder="Nama Customer"
                                />
                                <input
                                    class="mb-3 form-input"
                                    type="number"
                                    @input="fillForm"
                                    v-model="req.origin_phone"
                                    placeholder="Nomor Telepon"
                                />
                                <input
                                    class="mb-3 form-input"
                                    @input="fillForm"
                                    v-model="req.origin_note"
                                    placeholder="Detail Alamat"
                                />
                                <MapPicker
                                    class="mb-3"
                                    @map-data="getLocOrigin"
                                    :title="'Asal'"
                                ></MapPicker>
                                <div v-if="req.type_order == 'schedule'">
                                    <div>Tanggal</div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <input
                                                class="mb-3 form-input"
                                                @input="setTime"
                                                v-model="req.date"
                                                type="date"
                                                style="width: 120px"
                                            />
                                        </div>
                                        <div class="col-md-6">
                                            <input
                                                class="mb-3 form-input"
                                                @input="setTime"
                                                v-model="req.time"
                                                type="time"
                                                style="width: 120px"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>Jenis Pengiriman</div>
                                <select
                                    class="mb-3 form-input"
                                    @input="fillForm"
                                    v-model="req.type_service"
                                >
                                    <option value="" selected hidden>
                                        Pilih Layanan pengiriman
                                    </option>
                                    <option value="standart">Standart</option>
                                    <option value="special">Special</option>
                                </select>
                                <select
                                    v-if="req.type_service == 'special'"
                                    @input="fillForm"
                                    class="mb-3 form-input"
                                    v-model="req.helper_count"
                                >
                                    <option value="" selected hidden>
                                        Pengangkut Barang
                                    </option>
                                    <option value="1">
                                        Menggunakan 1 Kenek
                                    </option>
                                    <option value="2">
                                        Menggunakan 2 Kenek
                                    </option>
                                    <option value="3">
                                        Menggunakan 3 Kenek
                                    </option>
                                    <option value="4">
                                        Menggunakan 4 Kenek
                                    </option>
                                    <option value="5">
                                        Menggunakan 5 Kenek
                                    </option>
                                </select>
                                <div
                                    v-if="req.type_service == 'special'"
                                    class="mb-3 text-danger fw-semibold"
                                >
                                    Rp 50.000/Kenek {{ req.pickup_schedule }}
                                </div>
                                <select
                                    class="mb-3 form-input"
                                    @input="fillForm"
                                    v-model="req.type_order"
                                >
                                    <option value="" selected hidden>
                                        Pilih Layanan
                                    </option>
                                    <option value="quick">Cepat</option>
                                    <option value="schedule">Terjadwal</option>
                                    <option value="fullday">Seharian</option>
                                </select>
                                <select
                                    v-if="req.type_order == 'quick'"
                                    class="mb-3 form-input"
                                    @input="fillForm"
                                    v-model="req.type_ordersub"
                                >
                                    <option value="" selected hidden>
                                        Pilih Waktu Penjemputan
                                    </option>
                                    <option value="1">
                                        Jemput Dalam 1 Jam
                                    </option>
                                    <option value="3">
                                        Jemput Dalam 3 Jam
                                    </option>
                                </select>
                                <select
                                    class="mb-3 form-input"
                                    @change="setFleet"
                                    v-model="fleetSelected"
                                >
                                    <option value="" selected hidden>
                                        Pilih Kendaraan
                                    </option>
                                    <option
                                        v-for="(item, index) in fleetData"
                                        :key="index"
                                        :value="item"
                                    >
                                        {{ item.fleet_label }} ({{
                                            item.fleet_number
                                        }})
                                    </option>
                                </select>
                                <select
                                    class="mb-3 form-input"
                                    @input="fillForm"
                                    v-model="req.id_driver"
                                >
                                    <option value="" selected hidden>
                                        Pilih Driver
                                    </option>
                                    <option
                                        v-for="(item, index) in driverData"
                                        :key="index"
                                        :value="item.id"
                                    >
                                        {{ item.driver }}
                                    </option>
                                </select>
                                <input
                                    class="mb-3 form-input"
                                    v-if="!price"
                                    v-model="priceNullable"
                                    placeholder="Asuransi"
                                />
                                <money
                                    v-else
                                    v-model="req.insurance"
                                    @input="checkInsurance"
                                    v-bind="money"
                                    class="form-input mb-3"
                                    placeholder="Asuransi"
                                >
                                </money>
                                <!-- <div v-if="isInsurance" class="text-danger">Nilai Asuransi Minimal 1 Juta</div> -->
                            </div>
                            <div class="col-md-6">
                                <div class="mb1">&#160;</div>
                                <div class="mb1">Penerima 1</div>
                                <input
                                    class="mb-3 form-input"
                                    @input="fillForm"
                                    v-model="req.dest_name"
                                    placeholder="Nama Penerima"
                                />
                                <input
                                    class="mb-3 form-input"
                                    type="number"
                                    @input="fillForm"
                                    v-model="req.dest_phone"
                                    placeholder="Nomor Telepon"
                                />
                                <input
                                    class="mb-3 form-input"
                                    @input="fillForm"
                                    v-model="req.dest_note"
                                    placeholder="Detail Alamat"
                                />
                                <MapPicker
                                    @map-data="getLocDest"
                                    :title="'Tujuan'"
                                ></MapPicker>
                                <div v-if="add_form">
                                    <hr />
                                    <div class="mb1">Penerima 2</div>
                                    <input
                                        class="mb-3 form-input"
                                        @input="fillForm"
                                        v-model="req.dest2_name"
                                        placeholder="Nama Penerima"
                                    />
                                    <input
                                        class="mb-3 form-input"
                                        type="number"
                                        @input="fillForm"
                                        v-model="req.dest2_phone"
                                        placeholder="Nomor Telepon"
                                    />
                                    <input
                                        class="mb-3 form-input"
                                        @input="fillForm"
                                        v-model="req.dest2_note"
                                        placeholder="Detail Alamat"
                                    />
                                    <MapPicker
                                        @map-data="getLocDest2"
                                        :title="'Tujuan 2'"
                                    ></MapPicker>
                                </div>
                                <hr />
                                <div>Pembayaran</div>
                                <div style="background: #fafafa; padding: 10px">
                                    <div
                                        class="d-flex bd-highlight mb-3 align-items-center"
                                        v-if="req.type_service == 'standart'"
                                    >
                                        <div class="flex-grow-1 bd-highlight">
                                            Layanan Standar
                                        </div>
                                        <div class="bd-highlight">
                                            {{ isPrice(estPrice.service) }}
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex bd-highlight mb-3 align-items-center"
                                        v-if="req.type_service == 'special'"
                                    >
                                        <div class="flex-grow-1 bd-highlight">
                                            Layanan Spesial
                                        </div>
                                        <div class="bd-highlight">
                                            {{ isPrice(estPrice.service) }}
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex bd-highlight mb-3 align-items-center"
                                        v-if="req.type_service == 'special'"
                                    >
                                        <div class="flex-grow-1 bd-highlight">
                                            Layanan Kenek ({{
                                                req.helper_count
                                            }})
                                        </div>
                                        <div class="bd-highlight">
                                            {{ isPrice(estPrice.additional) }}
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex bd-highlight mb-3 align-items-center"
                                    >
                                        <div class="flex-grow-1 bd-highlight">
                                            Asuransi
                                        </div>
                                        <div class="bd-highlight">
                                            {{ isPrice(estPrice.insurance) }}
                                        </div>
                                    </div>
                                    <hr />
                                    <div
                                        class="d-flex bd-highlight mb-3 align-items-center"
                                    >
                                        <div class="flex-grow-1 bd-highlight">
                                            Platform Fee
                                        </div>
                                        <div class="bd-highlight">
                                            {{ isPrice(estPrice.platform_fee) }}
                                        </div>
                                    </div>
                                    <hr />
                                    <div
                                        class="d-flex bd-highlight mb-3 align-items-center"
                                    >
                                        <div class="flex-grow-1 bd-highlight">
                                            Total Harga
                                        </div>
                                        <div class="bd-highlight">
                                            {{ isPrice(estPrice.total) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex gap-2">
                                    <button
                                        v-if="!add_form"
                                        class="button button--add"
                                        @click="addForm"
                                    >
                                        Tambah Tujuan
                                    </button>
                                    <button
                                        v-if="add_form"
                                        class="button button--add"
                                        @click="addForm"
                                    >
                                        Kurangi Tujuan
                                    </button>
                                    <button
                                        class="button button--save"
                                        @click="submitData"
                                        :disabled="isSaving"
                                    >
                                        <span
                                            class="spinner-border spinner-border-sm me-2"
                                            v-if="isSaving"
                                        ></span>
                                        Simpan
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="showModalMap">
                <div class="modal-body-chat lg" v-if="showModalMap">
                    <a
                        href="javascript:void(0)"
                        @click="showModalMap = false"
                        class="size-20 text-primary search-icon"
                    >
                        <i class="fa fa-times-circle"></i>
                    </a>
                    <div class="position-relative" style="z-index: 3">
                        <div
                            class="box position-absolute"
                            style="top: 17px; left: 17px"
                        >
                            <div class="d-flex align-items-center">
                                <div class="me-3">
                                    <img
                                        :src="detailData.driver.avatar"
                                        width="48"
                                        height="48"
                                        style="object-fit: cover"
                                    />
                                </div>
                                <div>
                                    <div class="fw-bold size-16">
                                        Informasi Driver
                                    </div>
                                    <div class="text-gray">
                                        Informasi Driver Pengiriman
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <div
                                    class="d-flex bd-highlight mb-3 align-items-center"
                                >
                                    <div
                                        class="flex-grow-1 bd-highlight fw-bold"
                                    >
                                        No Resi
                                    </div>
                                    <div class="bd-highlight">
                                        {{ detailData.receipt_code }}
                                    </div>
                                </div>
                                <div
                                    class="d-flex bd-highlight mb-3 align-items-center"
                                >
                                    <div
                                        class="flex-grow-1 bd-highlight fw-bold"
                                    >
                                        Nama Driver
                                    </div>
                                    <div class="bd-highlight">
                                        {{ detailData.driver.name }}
                                    </div>
                                </div>
                                <div class="d-flex bd-highlight mb-3">
                                    <div
                                        class="flex-grow-1 bd-highlight fw-bold"
                                    >
                                        Jenis Order
                                    </div>
                                    <div class="bd-highlight text-end">
                                        {{ typeOrder }} <br />
                                        <span class="text-gray"
                                            >Jemput Dalam 3 Jam</span
                                        >
                                    </div>
                                </div>
                                <div
                                    class="d-flex bd-highlight mb-3 align-items-center"
                                >
                                    <div
                                        class="flex-grow-1 bd-highlight fw-bold"
                                    >
                                        No HP
                                    </div>
                                    <div class="bd-highlight">081234567678</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <GmapMap
                        ref="mapRef"
                        :center="driverMarker"
                        :zoom="16"
                        map-type-id="terrain"
                        style="height: 450px"
                    >
                        <GmapMarker
                            ref="marker"
                            :position="driverMarker"
                            :clickable="false"
                            :draggable="false"
                            @click="center = m"
                        >
                        </GmapMarker>
                        <!-- <GmapCircle ref="circle" :radius="1000" :center=map_data :draggable='false' :editable='true' @radius_changed="radiusChanged" @center_changed="centerChanged"></GmapCircle> -->
                    </GmapMap>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";
import MapPicker from "./MapPicker.vue";
import { Money } from "v-money";

export default {
    props: {},
    components: {
        ListLoader,
        MapPicker,
        Money,
    },
    name: "ManagementOrder",
    data() {
        return {
            showModal: false,
            showModalMap: false,
            is_my_list_ready: false,
            is_filled: false,
            isSaving: false,
            isInsurance: true,
            add_form: false,
            data: [],
            detailData: [],
            driverData: [],
            fleetData: [],
            fleetSelected: "",
            estPrice: {},
            address: "",
            price: null,
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "Rp. ",
                suffix: "",
                precision: 0,
                masked: false,
            },
            req: {
                origin_name: "",
                origin_phone: "",
                origin_note: "",
                origin_lat: "",
                origin_lon: "",
                date: "",
                time: "",
                type_service: "",
                type_order: "",
                type_ordersub: "",
                type_fleet: "",
                pickup_schedule: "",
                helper_count: "",
                id_driver: "",
                id_fleet: "",
                dest_name: "",
                dest_phone: "",
                dest_note: "",
                dest_lat: "",
                dest_lon: "",
                dest2_name: "",
                dest2_phone: "",
                dest2_note: "",
                dest2_lat: null,
                dest2_lon: null,
                insurance: "",
            },
            currentPage: 0,
            totalPage: 0,
            limit: 10,
            keySearch: "",
            datePicker: "",
            filter_status: "",
            map_data: { lat: null, lng: null },
            driverMarker: {
                lat: 0,
                lng: 0,
            },
            markers: [
                {
                    lat: -6.233035800000001,
                    lng: 106.8397427,
                },
            ],
            lat_move: "",
            lng_move: "",
            lat_search: "",
            lng_search: "",
            dummy: [
                {
                    Nomor_Resi: "TRT000001",
                    Nama_Customer: "Supratman",
                    Rute_Sewa_Truk: "Jakarta - Makassar",
                    Tanggal_Sewa: "12 Januari 2022",
                    Total_Harga_Sewa: "Rp 560.000.000",
                    Driver_Ditugaskan: "Maman Soeherman",
                    Kendaraan: "CDD Long Box",
                    Status: "Dalam Perjalanan",
                },
                {
                    Nomor_Resi: "TRT000001",
                    Nama_Customer: "Supratman",
                    Rute_Sewa_Truk: "Jakarta - Makassar",
                    Tanggal_Sewa: "12 Januari 2022",
                    Total_Harga_Sewa: "Rp 560.000.000",
                    Driver_Ditugaskan: "Maman Soeherman",
                    Kendaraan: "CDD Long Box",
                    Status: "Dalam Perjalanan",
                },
                {
                    Nomor_Resi: "TRT000001",
                    Nama_Customer: "Supratman",
                    Rute_Sewa_Truk: "Jakarta - Makassar",
                    Tanggal_Sewa: "12 Januari 2022",
                    Total_Harga_Sewa: "Rp 560.000.000",
                    Driver_Ditugaskan: "Maman Soeherman",
                    Kendaraan: "CDD Long Box",
                    Status: "Dalam Perjalanan",
                },
            ],
        };
    },
    created() {
        this.getListData();
        this.getFleet();
        this.getDriver();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    methods: {
        filterData() {
            this.currentPage = 0;
            this.getListData();
        },
        searchData() {
            this.currentPage = 0;
            this.getListData();
        },
        changePage() {
            this.getListData();
        },
        dateFormat(date) {
            return moment(date).format("DD MMMM YYYY");
        },
        addForm() {
            this.add_form = !this.add_form;
            if (!this.add_form) {
                this.req.dest2_lat = null;
                this.req.dest2_lon = null;
                this.req.dest2_name = "";
                this.req.dest2_phone = 0;
                this.req.dest2_note = "";
            }
        },
        getLocOrigin(data) {
            this.req.origin_lat = data.lat;
            this.req.origin_lon = data.lng;
            this.fillForm();
        },
        getLocDest(data) {
            this.req.dest_lat = data.lat;
            this.req.dest_lon = data.lng;
            this.fillForm();
        },
        getLocDest2(data) {
            this.req.dest2_lat = data.lat;
            this.req.dest2_lon = data.lng;
            this.fillForm();
        },
        setTime() {
            this.req.pickup_schedule =
                this.req.date + " " + this.req.time + ":00";
            if (this.req.date != "" && this.req.time != "") {
                this.fillForm();
            }
        },
        setFleet() {
            this.req.type_fleet = this.fleetSelected.fleet_type;
            this.req.id_fleet = this.fleetSelected.id;
            this.fillForm();
        },
        checkInsurance() {
            // if (this.req.insurance < 1000000) {
            //   this.isInsurance = true;
            // } else {
            //   this.isInsurance = false;
            //   this.fillForm();
            // }
            this.fillForm();
        },
        fillForm() {
            if (
                this.req.id_driver != "" &&
                this.req.type_fleet != "" &&
                this.req.type_order != "" &&
                this.req.type_service != "" &&
                this.req.origin_lat != "" &&
                this.req.origin_lon != "" &&
                this.req.dest_lat != "" &&
                this.req.dest_lon != "" &&
                this.req.origin_name != "" &&
                this.req.origin_phone != "" &&
                this.req.origin_note != "" &&
                this.req.dest_name != "" &&
                this.req.dest_phone != "" &&
                this.req.dest_note != "" &&
                this.req.insurance != 0 &&
                this.req.dest2_name == "" &&
                this.req.dest2_phone == 0 &&
                this.req.dest2_note == "" &&
                this.req.dest2_lat == null &&
                this.req.dest2_lon == null
            ) {
                this.getEstimatePrice();
            } else if (
                this.req.id_driver != "" &&
                this.req.type_fleet != "" &&
                this.req.type_order != "" &&
                this.req.type_service != "" &&
                this.req.origin_lat != "" &&
                this.req.origin_lon != "" &&
                this.req.dest_lat != "" &&
                this.req.dest_lon != "" &&
                this.req.origin_name != "" &&
                this.req.origin_phone != "" &&
                this.req.origin_note != "" &&
                this.req.dest_name != "" &&
                this.req.dest_phone != "" &&
                this.req.dest_note != "" &&
                this.req.insurance != 0 &&
                this.req.dest2_name != "" &&
                this.req.dest2_phone != 0 &&
                this.req.dest2_note != "" &&
                this.req.dest2_lat != null &&
                this.req.dest2_lon != null
            ) {
                this.getEstimatePrice();
            }
        },
        getEstimatePrice() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/order/estimate`,
                {
                    params: {
                        id_driver: this.req.id_driver,
                        type_fleet: this.req.type_fleet,
                        type_order: this.req.type_order,
                        type_ordersub: this.req.type_ordersub,
                        pickup_schedule: this.req.pickup_schedule,
                        type_service: this.req.type_service,
                        helper_count: this.req.helper_count,
                        origin_lat: this.req.origin_lat,
                        origin_lon: this.req.origin_lon,
                        dest_lat: this.req.dest_lat,
                        dest_lon: this.req.dest_lon,
                        dest2_lat: this.req.dest2_lat,
                        dest2_lon: this.req.dest2_lon,
                        insurance: this.req.insurance,
                        origin_name: this.req.origin_name,
                        origin_phone: this.req.origin_phone,
                        origin_note: this.req.origin_note,
                        dest_name: this.req.dest_name,
                        dest_phone: this.req.dest_phone,
                        dest_note: this.req.dest_note,
                        dest2_name: this.req.dest2_name,
                        dest2_phone: this.req.dest2_phone,
                        dest2_note: this.req.dest2_note,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.estPrice = res.data.data;
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                });
        },
        getListData() {
            this.is_my_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/order/list`,
                {
                    params: {
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 1,
                        limit: this.limit,
                        status: this.filter_status,
                        search: this.keySearch,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    this.is_my_list_ready = false;
                    console.log(err);
                });
        },
        submitData() {
            this.isSaving = true;
            var data = new FormData();

            data.append("id_driver", this.req.id_driver);
            data.append("id_fleet", this.req.id_fleet);
            data.append("type_order", this.req.type_order);
            data.append("type_ordersub", this.req.type_ordersub);
            data.append("pickup_schedule", this.req.pickup_schedule);
            data.append("type_service", this.req.type_service);
            data.append("helper_count", this.req.helper_count);
            data.append("origin_lat", this.req.origin_lat);
            data.append("origin_lon", this.req.origin_lon);
            data.append("dest_lat", this.req.dest_lat);
            data.append("dest_lon", this.req.dest_lon);
            data.append("dest2_lat", this.req.dest2_lat);
            data.append("dest2_lon", this.req.dest2_lon);
            data.append("good_price", this.req.insurance);
            data.append("origin_name", this.req.origin_name);
            data.append("origin_phone", this.req.origin_phone);
            data.append("origin_note", this.req.origin_note);
            data.append("dest_name", this.req.dest_name);
            data.append("dest_phone", this.req.dest_phone);
            data.append("dest_note", this.req.dest_note);
            data.append("dest2_name", this.req.dest2_name);
            data.append("dest2_phone", this.req.dest2_phone);
            data.append("dest2_note", this.req.dest2_note);

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/order`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.showModal = false;
                    this.isSaving = false;
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Data berhasil dibuat",
                    });
                    this.getListData();
                    this.clear();
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                    this.isSaving = false;
                });
        },
        clear() {
            this.req.id_driver = "";
            this.req.type_fleet = "";
            this.req.type_order = "";
            this.req.type_ordersub = "";
            this.req.pickup_schedule = "";
            this.req.type_service = "";
            this.req.helper_count = "";
            this.req.origin_lat = "";
            this.req.origin_lon = "";
            this.req.dest_lat = "";
            this.req.dest_lon = "";
            this.req.dest2_lat = null;
            this.req.dest2_lon = null;
            this.req.origin_name = "";
            this.req.origin_phone = "";
            this.req.origin_note = "";
            this.req.dest_name = "";
            this.req.dest_phone = "";
            this.req.dest_note = "";
            this.req.dest2_name = "";
            this.req.dest2_phone = 0;
            this.req.dest2_note = "";
        },
        getDetailData(id) {
            console.log("ud", id);
            this.showModalMap = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/order/detail`,
                {
                    params: { order_id: id },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detailData = res.data.data;
                    this.driverMarker = {
                        lat: Number(-6.233058018838069),
                        lng: Number(106.83961562812328),
                    };
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                });
        },
        getFleet() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/order/fleet`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.fleetData = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    this.is_my_list_ready = false;
                    console.log(err);
                });
        },
        getDriver() {
            this.is_my_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/order/driver`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.driverData = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    this.is_my_list_ready = false;
                    console.log(err);
                });
        },
        isPrice(amount) {
            return amount ? this.currency(amount) : "Rp 0";
        },
        currency(number) {
            return new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
            }).format(number);
        },
    },
    computed: {
        typeOrder() {
            if (this.detailData.type_order == "quick") return "Cepat";
            if (this.detailData.type_order == "schedule") return "Terjadwal";
            if (this.detailData.type_order == "fullday") return "Seharian";
            return "";
        },
        priceNullable: {
            get() {
                return this.price ? null : this.price;
            },
            set(val) {
                this.price = val;
            },
        },
    },
    watch: {
        price(val) {
            if (val === 0) this.price = null;
            this.$nextTick(() => {
                if (this.$refs.asd) {
                    const a = this.$refs.asd;
                    a.$el.focus();
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.scroll {
    height: 500px;
    overflow-y: scroll;
}
.row.foto {
    --bs-gutter-x: 0rem !important;
}
.add-button {
    background: #48a2d4;
    border-radius: 8px;
    border: 1px solid #48a2d4;
    color: white;
    padding: 10px 15px;
}
.form-input {
    background: rgba(239, 241, 249, 0.6);
    border-width: 0px;
    border: none;
    outline: none;
    border-radius: 10px;
    width: 258px;
    height: 40px;
    padding: 10px;
}
.button {
    display: flex;
    border-radius: 12px;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 168px;
    height: 44px;
    &--save {
        background: #48a2d4;
        border: 1px solid #48a2d4;
        color: white;
    }
    &--add {
        background: #ffffff;
        border: 1px solid #48a2d4;
        color: #48a2d4;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
</style>
