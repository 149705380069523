<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/carrier/ho/refund" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h4 class="fw-bold mb-0">
                    Detail Pengembalian Dana
                </h4>
            </div>
            <div class="box no-shadow border-gray mt-4"> 
                <div class="row">
                    <div class="col-md-3 fw-bold">
                        Nomor Pengembalian Dana
                    </div>
                    <div class="col-md-1 d-none d-lg-block text-center">
                        :
                    </div>
                    <div class="col-md-8">
                        {{ detail.refund_code }}
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-3 fw-bold">
                        Nomor Resi
                    </div>
                    <div class="col-md-1 d-none d-lg-block text-center">
                        :
                    </div>
                    <div class="col-md-8">
                        {{ detail.order_id }}
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-3 fw-bold">
                        Nama Customer
                    </div>
                    <div class="col-md-1 d-none d-lg-block text-center">
                        :
                    </div>
                    <div class="col-md-8">
                        {{ detail.customer_name }}
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-3 fw-bold">
                        Alasan Pengembalian Dana
                    </div>
                    <div class="col-md-1 d-none d-lg-block text-center">
                        :
                    </div>
                    <div class="col-md-8">
                        {{ detail.reason }}
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-3 fw-bold">
                        Nomor Rekening (Bank)
                    </div>
                    <div class="col-md-1 d-none d-lg-block text-center">
                        :
                    </div>
                    <div class="col-md-8">
                        {{ detail.account_number }} ({{ detail.account_bank }})
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-3 fw-bold">
                        Bank Atas Nama
                    </div>
                    <div class="col-md-1 d-none d-lg-block text-center">
                        :
                    </div>
                    <div class="col-md-8">
                        {{ detail.account_name }}
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-3 fw-bold">
                        Status Pengembalian Dana
                    </div>
                    <div class="col-md-1 d-none d-lg-block text-center">
                        :
                    </div>
                    <div class="col-md-8">
                        <span
                            v-bind:class="{
                                'blue-solid-dark': detail.status == 'request',
                                'yellow-solid': detail.status == 'waiting_for_approval',
                                'red-solid': detail.status == 'rejected',
                                'green-solid': detail.status == 'approved',
                                'blue-solid': detail.status == 'transfered',
                            }"
                            class="tag px-5 radius"
                        >
                            <template v-if="detail.status == 'request'">
                                Pengajuan
                            </template>
                            <template v-if="detail.status == 'waiting_for_approval'">
                                Menunggu Persetujuan
                            </template>
                            <template v-if="detail.status == 'rejected'">
                                Ditolak
                            </template>
                            <template v-if="detail.status == 'approved'">
                                Disetujui
                            </template>
                            <template v-if="detail.status == 'transfered'">
                                Ditransfer
                            </template>
                        </span>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-3 fw-bold">
                        Bukti Transfer
                    </div>
                    <div class="col-md-1 d-none d-lg-block text-center">
                        :
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-6 col-md-2 mt-3">
                                <template v-if="detail.attachment">
                                    <div class="driver-item" :style="{ 'background-image': 'url(' + detail.attachment + ')' }"></div>
                                </template>
                                <template v-else>
                                    <div class="driver-item" :style="{ 'background-image': 'url(' + require('../../assets/no-photo.png') + ')' }"></div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                </div>
                <div class="col-md-6 mt-5">
                    <div class="row">
                        <div class="col-md-6">
                            <button class="btn btn-green btn-lg w-100" :disabled="detail.status != 'waiting_for_approval'" @click="updateStatus(detail.refund_code, status = 'approved')">Setujui Pengajuan</button>
                        </div>
                        <div class="col-md-6">
                            <button class="btn btn-primary btn-lg w-100" :disabled="detail.status != 'waiting_for_approval' && detail.status != 'approved'" @click="updateStatus(detail.refund_code, status = 'rejected')">Tolak Pengajuan</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Api from "../../api/Api";
// import moment from 'moment'
// import DatePicker from 'vue2-datepicker';

export default {
    components: {
    },
    name: "CarrierRefundDetail",
    data() {
        return {
            detail: {},
            is_update: false
        };
    },
    created() {
    },
    mounted() {
        this.getDetail()
    },
    computed: {
    },
    methods: {
        getDetail() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/carrier/ho/refund/detail?code=${this.$route.params.id}`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                    'Accept': 'application/json'
                },
            })
            .then((res) => {
                console.log(res)
                var data = res.data.data
                this.detail = data
            })
            .catch((err) => {
                console.log(err);
            });
        },
        updateStatus(id, status) {
            this.is_update = true
            var data = {
                refund_code: id,
                status: status
            }
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/carrier/ho/refund/update`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                    'Accept': 'application/json'
                },
            })
            .then((res) => {
                console.log(res)
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Berhasil',
                    text: 'Status Berhasil Diubah'
                });
                setTimeout(() => {
                    this.getDetail()
                }, 1000);
                this.is_update = false
            })
            .catch((err) => {
                this.$notify({
                    group: 'foo',
                    type: 'error',
                    title: 'Error',
                    text: err.response.data.data.message
                });
                this.is_update = false
            });
        },
    },
};
</script>

<style>
    .driver-item{
        width: 100%;
        height: 80px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 6px;
        border: 2px solid #e2e3e5;
    }
</style>