<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Lost Result</h3>
            </div>
            <div class="row mt-4">
                <!-- <div class="col-md-6">
                    <label class="fw-bold mb-2">Origin</label>
                    <multiselect
                        v-model="origin_regency"
                        :options="origins"
                        label="name"
                        :loading="is_load"
                        track-by="id"
                        placeholder="Choose Origin"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6">
                    <label class="fw-bold mb-2 m-mt-1">Destination</label>
                    <multiselect
                        v-model="destination_regency"
                        :options="origins"
                        label="name"
                        :loading="is_load"
                        track-by="id"
                        placeholder="Choose Destination"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Price</label>
                    <money v-model="price" v-bind="money" class="form-control">
                    </money>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Weight</label>
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Weight"
                        v-model="weight"
                    />
                </div> -->
                <div v-if="isRole" class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">CRM Specialist</label>
                    <multiselect
                        v-model="crm_specialist_id"
                        :options="crmSpecialist"
                        label="name"
                        :loading="is_load"
                        track-by="id"
                        placeholder="CRM Specialist"
                    >
                    </multiselect>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Description</label>
                    <textarea
                        rows="5"
                        cols="4"
                        class="form-control"
                        placeholder="Description"
                        v-model="description"
                    ></textarea>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <a
                                href="javascript:void(0)"
                                @click="$router.go(-1)"
                                class="btn btn-outline-black btn-lg px-5 me-3 m-w-100"
                                >Cancel</a
                            >
                            <button
                                class="btn btn-green btn-lg px-5 me-3 m-mt-1 m-w-100"
                                type="button"
                                disabled
                                v-if="is_save"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Save
                            </button>
                            <button
                                class="btn btn-green btn-lg px-5 me-3 m-mt-1 m-w-100"
                                @click="storeData()"
                                v-else
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data Saved!!</h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/crm/prospect/today"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalError"></div>
            <div class="modal-body-chat vsm" v-if="modalError">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/close.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Penambahan Data Gagal!</h4>
                    <div class="mt-2">
                        Mohon maaf terjadi kesalahan! Silakan ulangi kembali
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="modalError = false"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { Money } from "v-money";
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
        // Money,
    },
    name: "ManagementMasterRouteCreate",
    data() {
        return {
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "Rp. ",
                suffix: "",
                precision: 0,
                masked: false,
            },
            modalSuccess: false,
            modalError: false,
            origin_regency: null,
            destination_regency: null,
            origins: [],
            crmSpecialist: [],
            is_load: false,
            isRole: false,
            weight: null,
            price: 0,
            crm_specialist_id: "",
            description: "",
            typeResult: "",
            is_save: false,
        };
    },
    created() {
        this.getGeoList();
        this.getCrmSpecialist();
        if (this.$route.params.isRole) {
            localStorage.setItem("isRole", this.$route.params.isRole);
        }
        this.isRole = localStorage.getItem("isRole");
        if (this.$route.params.type) {
            localStorage.setItem("typeResult", this.$route.params.type);
        }
        this.typeResult = localStorage.getItem("typeResult");
    },
    methods: {
        getGeoList() {
            this.is_load = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.origins = data;
                    this.is_load = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load = false;
                });
        },
        getCrmSpecialist() {
            this.is_load = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist?limit=100`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data.list_data;
                    this.crmSpecialist = data;
                    this.is_load = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load = false;
                });
        },
        storeData() {
            this.is_save = true;
            var data = {
                id: this.$route.params.id,
                origin_regency_id: this.origin_regency?.id,
                destination_regency_id: this.destination_regency?.id,
                weight: this.weight,
                amount: this.price,
                price: this.price,
                description: this.description,
            };
            if (this.isRole) {
                data.crm_specialist_id = this.crm_specialist_id.id;
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/prospect/result/${this.typeResult}`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    this.modalSuccess = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
</style>
