<template>
    <div class="padding-container">
        <div class="row">
            <div class="col-md-4">
                <div class="box p-4">
                    <div class="d-flex align-items-center">
                        <div class="size-18 fw-semibold">Customers Data</div>
                        <div class="ms-auto">
                            <select class="pill default" v-model="custFilter">
                                <option value="ordered" selected>
                                    Repeat Order
                                </option>
                                <option value="leads">Prospect</option>
                                <option value="prospect">Leads</option>
                                <option value="registered">Registered</option>
                            </select>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-4">
                        <template v-if="custFilter == 'ordered'">
                            <div class="fw-bold size-20">
                                {{ dashboardData.customers_data.ordered }}
                                Repeat Order
                            </div>
                            <div class="ms-auto">
                                <lottie-animation
                                    style="height: 13vh"
                                    :loop="true"
                                    :animationData="
                                        require('@/assets/lottie/ordered-image.json')
                                    "
                                />
                            </div>
                        </template>
                        <template v-if="custFilter == 'leads'">
                            <div class="fw-bold size-20">
                                {{
                                    decimalFormat(
                                        dashboardData.customers_data.lead
                                            ? dashboardData.customers_data.lead
                                            : 0
                                    )
                                }}
                                Prospect
                            </div>
                            <div class="ms-auto">
                                <lottie-animation
                                    style="height: 13vh"
                                    :loop="true"
                                    :animationData="
                                        require('@/assets/lottie/leads.json')
                                    "
                                />
                            </div>
                        </template>
                        <template v-if="custFilter == 'prospect'">
                            <div class="fw-bold size-20">
                                {{
                                    decimalFormat(
                                        dashboardData.customers_data.prospect
                                            ? dashboardData.customers_data
                                                  .prospect
                                            : 0
                                    )
                                }}
                                Leads
                            </div>
                            <div class="ms-auto">
                                <lottie-animation
                                    style="height: 13vh"
                                    :loop="true"
                                    :animationData="
                                        require('@/assets/lottie/prospect.json')
                                    "
                                />
                            </div>
                        </template>
                        <template v-if="custFilter == 'registered'">
                            <div class="fw-bold size-20">
                                {{
                                    decimalFormat(
                                        dashboardData.customers_data.registered
                                            ? dashboardData.customers_data
                                                  .registered
                                            : 0
                                    )
                                }}
                                Registered
                            </div>
                            <div class="ms-auto">
                                <lottie-animation
                                    style="height: 13vh"
                                    :loop="true"
                                    :animationData="
                                        require('@/assets/lottie/registered.json')
                                    "
                                />
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-md-4 m-mt-2">
                <div class="box p-4">
                    <div class="d-flex align-items-center">
                        <div class="size-18 fw-semibold">CRM Specialist</div>
                        <div class="ms-auto">
                            <select class="pill default" v-model="specFilter">
                                <option value="total" selected>Total</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-4">
                        <template v-if="specFilter == 'total'">
                            <div class="fw-bold size-20">
                                {{
                                    decimalFormat(
                                        dashboardData.specialist.total
                                            ? dashboardData.specialist.total
                                            : 0
                                    )
                                }}
                                Specialist
                            </div>
                            <div class="ms-auto">
                                <lottie-animation
                                    style="height: 13vh"
                                    :loop="true"
                                    :animationData="
                                        require('@/assets/lottie/total.json')
                                    "
                                />
                            </div>
                        </template>
                        <template v-if="specFilter == 'male'">
                            <div class="fw-bold size-20">
                                {{
                                    decimalFormat(
                                        dashboardData.specialist.male
                                            ? dashboardData.specialist.male
                                            : 0
                                    )
                                }}
                                Male
                            </div>
                            <div class="ms-auto">
                                <lottie-animation
                                    style="height: 13vh"
                                    :loop="true"
                                    :animationData="
                                        require('@/assets/lottie/male.json')
                                    "
                                />
                            </div>
                        </template>
                        <template v-if="specFilter == 'female'">
                            <div class="fw-bold size-20">
                                {{
                                    decimalFormat(
                                        dashboardData.specialist.female
                                            ? dashboardData.specialist.female
                                            : 0
                                    )
                                }}
                                Female
                            </div>
                            <div class="ms-auto">
                                <lottie-animation
                                    style="height: 13vh"
                                    :loop="true"
                                    :animationData="
                                        require('@/assets/lottie/female.json')
                                    "
                                />
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-md-4 m-mt-2">
                <div class="box p-4">
                    <div class="d-flex align-items-center">
                        <div class="size-18 fw-semibold">Troben Target</div>
                    </div>
                    <div class="d-flex align-items-center mt-4">
                        <div>
                            <div class="fw-bold size-16">
                                {{
                                    currency(
                                        dashboardData.trawlbens_target
                                            .acquisition
                                            ? dashboardData.trawlbens_target
                                                  .acquisition
                                            : 0
                                    )
                                }}
                                /
                                <span class="text-green size-16">{{
                                    currency(
                                        dashboardData.trawlbens_target.target
                                            ? dashboardData.trawlbens_target
                                                  .target
                                            : 0
                                    )
                                }}</span>
                            </div>
                            <div class="progress green mt-2">
                                <div
                                    class="progress-bar bg-green"
                                    role="progressbar"
                                    :style="{
                                        width:
                                            dashboardData.trawlbens_target
                                                .percent + '%',
                                    }"
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                        </div>
                        <div class="ms-auto">
                            <lottie-animation
                                style="height: 13vh"
                                :loop="true"
                                :animationData="
                                    require('@/assets/lottie/target.json')
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-4">
                <div class="box p-4 position-relative">
                    <div class="info-box" v-show="infoShow">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="size-14">New Order</div>
                                <div class="text-green fw-semibold size-18">
                                    <template v-if="dealFilter == 'all'">
                                        {{
                                            currency(
                                                dashboardData.deal_order.all
                                                    .amount_new_order
                                                    ? dashboardData.deal_order
                                                          .all.amount_new_order
                                                    : 0
                                            )
                                        }}
                                    </template>
                                    <template v-if="dealFilter == 'trawlpack'">
                                        {{
                                            currency(
                                                dashboardData.deal_order
                                                    .trawlpack.amount_new_order
                                                    ? dashboardData.deal_order
                                                          .trawlpack
                                                          .amount_new_order
                                                    : 0
                                            )
                                        }}
                                    </template>
                                    <template
                                        v-if="dealFilter == 'trawlcarrier'"
                                    >
                                        {{
                                            currency(
                                                dashboardData.deal_order
                                                    .trawlcarrier
                                                    .amount_new_order
                                                    ? dashboardData.deal_order
                                                          .trawlcarrier
                                                          .amount_new_order
                                                    : 0
                                            )
                                        }}
                                    </template>
                                    <template v-if="dealFilter == 'trawltruck'">
                                        {{
                                            currency(
                                                dashboardData.deal_order
                                                    .trawltruck.amount_new_order
                                                    ? dashboardData.deal_order
                                                          .trawltruck
                                                          .amount_new_order
                                                    : 0
                                            )
                                        }}
                                    </template>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="size-14">Repeat Order</div>
                                <div class="text-green fw-semibold size-18">
                                    <template v-if="dealFilter == 'all'">
                                        {{
                                            currency(
                                                dashboardData.deal_order.all
                                                    .amount_repeat_order
                                                    ? dashboardData.deal_order
                                                          .all
                                                          .amount_repeat_order
                                                    : 0
                                            )
                                        }}
                                    </template>
                                    <template v-if="dealFilter == 'trawlpack'">
                                        {{
                                            currency(
                                                dashboardData.deal_order
                                                    .trawlpack
                                                    .amount_repeat_order
                                                    ? dashboardData.deal_order
                                                          .trawlpack
                                                          .amount_repeat_order
                                                    : 0
                                            )
                                        }}
                                    </template>
                                    <template
                                        v-if="dealFilter == 'trawlcarrier'"
                                    >
                                        {{
                                            currency(
                                                dashboardData.deal_order
                                                    .trawlcarrier
                                                    .amount_repeat_order
                                                    ? dashboardData.deal_order
                                                          .trawlcarrier
                                                          .amount_repeat_order
                                                    : 0
                                            )
                                        }}
                                    </template>
                                    <template v-if="dealFilter == 'trawltruck'">
                                        {{
                                            currency(
                                                dashboardData.deal_order
                                                    .trawltruck
                                                    .amount_repeat_order
                                                    ? dashboardData.deal_order
                                                          .trawltruck
                                                          .amount_repeat_order
                                                    : 0
                                            )
                                        }}
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="size-18 fw-semibold">Deal</div>
                        <div class="ms-auto">
                            <select class="pill default" v-model="dealFilter">
                                <option value="all" selected>All</option>
                                <option value="trawlpack">Troben Cargo</option>
                                <option value="trawltruck">Troben Truk</option>
                                <option value="trawlcarrier">
                                    Troben Carier
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-4">
                        <div>
                            <div class="d-flex align-items-center">
                                <div class="me-2">
                                    <a
                                        href="javascript:void(0)"
                                        @click="infoShow = !infoShow"
                                    >
                                        <img
                                            src="../../assets/info.png"
                                            width="19"
                                            height="19"
                                            alt=""
                                        />
                                    </a>
                                </div>
                                <div class="fw-bold size-20">
                                    <template v-if="dealFilter == 'all'">
                                        {{
                                            currency(
                                                dashboardData.deal_order.all
                                                    .amount
                                                    ? dashboardData.deal_order
                                                          .all.amount
                                                    : 0
                                            )
                                        }}
                                    </template>
                                    <template v-if="dealFilter == 'trawlpack'">
                                        {{
                                            currency(
                                                dashboardData.deal_order
                                                    .trawlpack.amount
                                                    ? dashboardData.deal_order
                                                          .trawlpack.amount
                                                    : 0
                                            )
                                        }}
                                    </template>
                                    <template
                                        v-if="dealFilter == 'trawlcarrier'"
                                    >
                                        {{
                                            currency(
                                                dashboardData.deal_order
                                                    .trawlcarrier.amount
                                                    ? dashboardData.deal_order
                                                          .trawlcarrier.amount
                                                    : 0
                                            )
                                        }}
                                    </template>
                                    <template v-if="dealFilter == 'trawltruck'">
                                        {{
                                            currency(
                                                dashboardData.deal_order
                                                    .trawltruck.amount
                                                    ? dashboardData.deal_order
                                                          .trawltruck.amount
                                                    : 0
                                            )
                                        }}
                                    </template>
                                </div>
                            </div>
                            <div class="mt-2">Customers</div>
                            <div class="size-16 fw-semibold">
                                <template v-if="dealFilter == 'all'">
                                    {{
                                        decimalFormat(
                                            dashboardData.deal_order.all
                                                .customers
                                                ? dashboardData.deal_order.all
                                                      .customers
                                                : 0
                                        )
                                    }}
                                </template>
                                <template v-if="dealFilter == 'trawlpack'">
                                    {{
                                        decimalFormat(
                                            dashboardData.deal_order.trawlpack
                                                .customers
                                                ? dashboardData.deal_order
                                                      .trawlpack.customers
                                                : 0
                                        )
                                    }}
                                </template>
                                <template v-if="dealFilter == 'trawlcarrier'">
                                    {{
                                        decimalFormat(
                                            dashboardData.deal_order
                                                .trawlcarrier.customers
                                                ? dashboardData.deal_order
                                                      .trawlcarrier.customers
                                                : 0
                                        )
                                    }}
                                </template>
                                <template v-if="dealFilter == 'trawltruck'">
                                    {{
                                        decimalFormat(
                                            dashboardData.deal_order.trawltruck
                                                .customers
                                                ? dashboardData.deal_order
                                                      .trawltruck.customers
                                                : 0
                                        )
                                    }}
                                </template>
                                Customers
                            </div>
                        </div>
                        <div class="ms-auto">
                            <lottie-animation
                                style="height: 15vh"
                                :loop="true"
                                :animationData="
                                    require('@/assets/lottie/deal.json')
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-4">
                <div class="box p-4">
                    <div class="d-flex align-items-center">
                        <div class="size-18 fw-semibold">Prediction</div>
                    </div>
                    <div class="d-flex align-items-center mt-4">
                        <div>
                            <div class="fw-bold size-20">
                                {{
                                    currency(
                                        dashboardData.prediction.amount
                                            ? dashboardData.prediction.amount
                                            : 0
                                    )
                                }}
                            </div>
                            <div class="mt-2">Customers</div>
                            <div class="size-16 fw-semibold">
                                {{
                                    decimalFormat(
                                        dashboardData.prediction.customers
                                            ? dashboardData.prediction.customers
                                            : 0
                                    )
                                }}
                                Customers
                            </div>
                        </div>
                        <div class="ms-auto">
                            <lottie-animation
                                style="height: 15vh"
                                :loop="true"
                                :animationData="
                                    require('@/assets/lottie/prediction.json')
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-4">
                <div class="box p-4">
                    <div class="d-flex align-items-center">
                        <div class="size-18 fw-semibold">Lost</div>
                    </div>
                    <div class="d-flex align-items-center mt-4">
                        <div>
                            <div class="fw-bold size-20">
                                {{
                                    decimalFormat(
                                        dashboardData.lost.customers
                                            ? dashboardData.lost.customers
                                            : 0
                                    )
                                }}
                                Customers
                            </div>
                        </div>
                        <div class="ms-auto">
                            <lottie-animation
                                style="height: 15vh"
                                :loop="true"
                                :animationData="
                                    require('@/assets/lottie/lost-staff.json')
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-4">
                <div class="box p-4">
                    <div class="size-18 fw-semibold">Follow Up Activities</div>
                    <hr />
                    <div class="d-flex align-items-center no-flex">
                        <div>
                            <div class="d-flex align-items-center">
                                <div class="me-4">
                                    <div class="text-gray-dashboard">Today</div>
                                    <div class="mt-2">
                                        <a
                                            href="/crm/prospect/today"
                                            class="size-18 fw-semibold text-blue-link"
                                        >
                                            {{
                                                decimalFormat(
                                                    follow_up_activities_data.today
                                                        ? follow_up_activities_data.today
                                                        : 0
                                                )
                                            }}
                                            Customers
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <div class="text-gray-dashboard">
                                        Tomorrow
                                    </div>
                                    <div class="mt-2">
                                        <a
                                            href="/crm/prospect/tomorrow"
                                            class="size-18 fw-semibold text-blue-link"
                                        >
                                            {{
                                                decimalFormat(
                                                    follow_up_activities_data.tomorrow
                                                        ? follow_up_activities_data.tomorrow
                                                        : 0
                                                )
                                            }}
                                            Customers
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-3">
                                <div class="me-4">
                                    <div class="text-gray-dashboard">
                                        Next 2 Days
                                    </div>
                                    <div class="mt-2">
                                        <a
                                            href="/crm/prospect/next-two-days"
                                            class="size-18 fw-semibold text-blue-link"
                                        >
                                            {{
                                                decimalFormat(
                                                    follow_up_activities_data.next_2_days
                                                        ? follow_up_activities_data.next_2_days
                                                        : 0
                                                )
                                            }}
                                            Customers
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <div class="text-gray-dashboard">
                                        Next Week
                                    </div>
                                    <div class="mt-2">
                                        <a
                                            href="/crm/prospect/next-week"
                                            class="size-18 fw-semibold text-blue-link"
                                        >
                                            {{
                                                decimalFormat(
                                                    follow_up_activities_data.next_week
                                                        ? follow_up_activities_data.next_week
                                                        : 0
                                                )
                                            }}
                                            Customers
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-3">
                                <div class="me-4">
                                    <div class="text-gray-dashboard">
                                        Next Month
                                    </div>
                                    <div class="mt-2">
                                        <a
                                            href="/crm/prospect/next-month"
                                            class="size-18 fw-semibold text-blue-link"
                                        >
                                            {{
                                                decimalFormat(
                                                    follow_up_activities_data.next_month
                                                        ? follow_up_activities_data.next_month
                                                        : 0
                                                )
                                            }}
                                            Customers
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <div class="text-gray-dashboard">
                                        Need Followed Up
                                    </div>
                                    <div class="mt-2">
                                        <a
                                            href="/crm/prospect/need-follow-up"
                                            class="size-18 fw-semibold text-blue-link"
                                        >
                                            {{
                                                decimalFormat(
                                                    follow_up_activities_data.need_follow_up
                                                        ? follow_up_activities_data.need_follow_up
                                                        : 0
                                                )
                                            }}
                                            Customers
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ms-auto m-mt-2">
                            <lottie-animation
                                style="height: 25vh"
                                :loop="true"
                                :animationData="
                                    require('@/assets/lottie/bell.json')
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-4">
                <div class="box p-4">
                    <div class="d-flex align-items-center">
                        <div class="size-18 fw-semibold">Leaderboards</div>
                        <div class="ms-auto">
                            <a
                                href="/crm/target#leaderboard"
                                class="text-blue-link"
                            >
                                Details
                            </a>
                        </div>
                    </div>
                    <hr />
                    <div
                        class="d-flex align-items-center no-flex"
                        v-if="leaderboard_data.leaderboards.length > 0"
                    >
                        <div>
                            <template
                                v-for="(
                                    leaderboard, index
                                ) in leaderboard_data.leaderboards"
                            >
                                <div
                                    class="d-flex align-items-center mt-3"
                                    :key="index"
                                >
                                    <div class="me-3">
                                        <template v-if="leaderboard.rank == 1">
                                            <lottie-animation
                                                style="height: 6vh"
                                                :loop="true"
                                                :animationData="
                                                    require('@/assets/lottie/crown.json')
                                                "
                                            />
                                        </template>
                                        <template v-if="leaderboard.rank == 2">
                                            <lottie-animation
                                                style="height: 6vh"
                                                :loop="true"
                                                :animationData="
                                                    require('@/assets/lottie/silver-coin.json')
                                                "
                                            />
                                        </template>
                                        <template v-if="leaderboard.rank == 3">
                                            <lottie-animation
                                                style="height: 6vh"
                                                :loop="true"
                                                :animationData="
                                                    require('@/assets/lottie/bronze-coin.json')
                                                "
                                            />
                                        </template>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <div
                                                class="user-leaderboard"
                                                v-if="
                                                    leaderboard.avatar != '' ||
                                                    leaderboard.avatar != null
                                                "
                                                :style="{
                                                    'background-image':
                                                        'url(' +
                                                        leaderboard.avatar +
                                                        ')',
                                                }"
                                            ></div>
                                            <div
                                                v-else
                                                class="user-leaderboard"
                                                :style="{
                                                    'background-image':
                                                        'url(' +
                                                        require('../../assets/user.jpg') +
                                                        ')',
                                                }"
                                            ></div>
                                        </div>
                                        <div>
                                            <div class="text-gray-dashboard">
                                                <template
                                                    v-if="leaderboard.rank == 1"
                                                >
                                                    The Winner!
                                                </template>
                                                <template
                                                    v-if="leaderboard.rank == 2"
                                                >
                                                    2nd
                                                </template>
                                                <template
                                                    v-if="leaderboard.rank == 3"
                                                >
                                                    3rd
                                                </template>
                                            </div>
                                            <div
                                                class="fw-bold text-gold size-16"
                                            >
                                                {{ leaderboard.name }}
                                            </div>
                                            <div
                                                class="fw-bold text-green size-16"
                                            >
                                                {{
                                                    currency(
                                                        leaderboard.amount
                                                            ? leaderboard.amount
                                                            : 0
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="ms-auto m-mt-2">
                            <lottie-animation
                                style="height: 25vh"
                                :loop="true"
                                :animationData="
                                    require('@/assets/lottie/leaderboards.json')
                                "
                            />
                        </div>
                    </div>
                    <div class="mt-4 text-center" v-else>
                        <center>
                            <lottie-animation
                                style="height: 20vh"
                                :loop="true"
                                :animationData="
                                    require('@/assets/lottie/empty.json')
                                "
                            />
                            <div class="size-20 text-gray-dashboard fw-medium">
                                Data is Empty
                            </div>
                        </center>
                    </div>
                </div>
            </div>
            <div class="col-md-8 mt-4">
                <div class="box p-4">
                    <div class="d-flex align-items-center mb-4">
                        <div class="size-18 fw-semibold">Departement Goals</div>
                        <div class="ms-auto">
                            <a
                                href="/crm/target#division-target"
                                class="text-blue-link"
                            >
                                Details
                            </a>
                        </div>
                    </div>
                    <template
                        v-for="(
                            departement, index
                        ) in dashboardData.department_target"
                    >
                        <div class="mt-3" :key="index">
                            <div>
                                <div class="size-16 fw-semibold">
                                    {{ departement?.name }}
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="mt-2 size-16">
                                    {{
                                        decimalFormat(
                                            departement.customer_acquisition
                                                ? departement.customer_acquisition
                                                : 0
                                        )
                                    }}/<span class="fw-semibold size-16"
                                        >{{
                                            decimalFormat(
                                                departement.customer_target
                                                    ? departement.customer_target
                                                    : 0
                                            )
                                        }}
                                        Customers</span
                                    >
                                </div>
                                <div class="mt-2 size-16 ms-auto">
                                    {{
                                        currency(
                                            departement.acquisition
                                                ? departement.acquisition
                                                : 0
                                        )
                                    }}/<span class="fw-semibold size-16">{{
                                        currency(
                                            departement.target
                                                ? departement.target
                                                : 0
                                        )
                                    }}</span>
                                </div>
                            </div>
                            <div
                                v-bind:class="{
                                    green: departement.name == 'CS',
                                    blue: departement.name == 'Sales',
                                    yellow: departement.name == 'Telemarketer',
                                }"
                                class="progress mt-3"
                            >
                                <div
                                    v-bind:class="{
                                        'bg-green': departement.name == 'CS',
                                        'bg-blue': departement.name == 'Sales',
                                        'bg-yellow':
                                            departement.name == 'Telemarketer',
                                    }"
                                    class="progress-bar"
                                    role="progressbar"
                                    :style="{
                                        width: departement.percent + '%',
                                    }"
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="col-md-4 mt-4">
                <div class="box p-4">
                    <div class="d-flex align-items-center">
                        <div class="size-18 fw-semibold">
                            Today’s Jurnal Syukur
                        </div>
                        <div class="ms-auto">
                            <a
                                href="/crm/gratitude-journal"
                                class="text-blue-link"
                            >
                                Details
                            </a>
                        </div>
                    </div>
                    <div class="mt-4">
                        <lottie-animation
                            style="height: 25vh"
                            :loop="true"
                            :animationData="
                                require('@/assets/lottie/jurnal-syukur.json')
                            "
                        />
                    </div>
                    <h1 class="mt-4 s-43 text-center fw-bold">
                        {{
                            decimalFormat(
                                gratitude_data.count ? gratitude_data.count : 0
                            )
                        }}/{{
                            decimalFormat(
                                gratitude_data.total ? gratitude_data.total : 0
                            )
                        }}
                        Person
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
import Api from "../../api/Api";
import moment from "moment";

export default {
    components: {
        LottieAnimation,
    },
    name: "crmDashboard",
    data() {
        return {
            moment: moment,
            custFilter: "ordered",
            specFilter: "total",
            dealFilter: "all",
            infoShow: false,
            today: new Date(new Date()),
            dashboardData: {
                department_target: [],
                customers_data: {},
                specialist: {},
                trawlbens_target: {},
                deal_order: {
                    all: {},
                    trawlpack: {},
                    trawlcarrier: {},
                    trawltruck: {},
                },
                prediction: {},
                lost: {},
            },
            follow_up_activities_data: {},
            leaderboard_data: {
                current: {},
                leaderboards: [],
            },
            gratitude_data: {},
        };
    },
    created() {
        this.getDataDashboard();
        this.getFollowUpActivities();
        this.getLeaderboards();
        this.getGratitude();
    },
    methods: {
        getDataDashboard() {
            let payload = {
                date: moment(this.today).format("MM-YYYY"),
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/dashboard-v2`, {
                params: payload,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.dashboardData = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getFollowUpActivities() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/prospect/count`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.follow_up_activities_data = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getLeaderboards() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/leaderboard`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.leaderboard_data = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getGratitude() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/gratitudes/count`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.gratitude_data = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
$pill-width: 110px;
$pill-height: 22px;
$pill-border-radius: 40px;
.pill {
    display: inline-block;
    width: $pill-width;
    height: $pill-height;
    border-radius: $pill-border-radius;
    text-align: center;
    color: #000;
    &.default {
        background-color: #f4f4f4;
        border: 1px solid #f4f4f4;
        outline: 1px solid #f4f4f4;
    }
}
.progress.green {
    height: 8px !important;
    border-radius: 50px !important;
    background-color: #d8e7d3 !important;
}
.bg-green {
    background-color: #3d8824 !important;
    border-radius: 50px !important;
}
.bg-blue {
    background-color: #48a2d4 !important;
    border-radius: 50px !important;
}
.progress.blue {
    height: 8px !important;
    border-radius: 50px !important;
    background-color: #daecf6 !important;
}
.progress.blue {
    height: 8px !important;
    border-radius: 50px !important;
    background-color: #daecf6 !important;
}
.progress.yellow {
    height: 8px !important;
    border-radius: 50px !important;
    background-color: #fee6cb !important;
}
.bg-yellow {
    background-color: #fb9727 !important;
    border-radius: 50px !important;
}
.text-blue-link {
    color: #24516a !important;
    text-decoration: underline;
}
.text-gold {
    color: #fb9727;
}
.s-43 {
    font-size: 43px;
}
.info-box {
    padding: 20px;
    background: #d8e7d3;
    border: 2px solid #bed7b6;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    position: absolute;
    top: 15px;
    left: 35px;
    width: 300px;
    z-index: 9;
}
.user-leaderboard {
    width: 45px;
    height: 45px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
    border: 2px solid #f7f7f7;
}
</style>
