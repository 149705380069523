<template>
  <div class="padding-container">
    <div class="box mb-3">
      <div class="d-flex bd-highlight mb-3 align-items-center">
        <div class="flex-grow-1 bd-highlight">
          <h4 class="fw-bold">Halaman Profile</h4>
        </div>
        <div class="bd-highlight me-3">
          <button class="change-button" @click="showModalPass = true">Ubah Password</button>
        </div>
        <div class="bd-highlight">
          <button class="add-button" @click="showModalEdit = true">Edit Data</button>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <img :src="logoPictureUrl" alt="Avatar" class="avatar" />
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-6">
              <div class="mb-3">
                <div class="mb-1 form-title-font">Kode Mitra</div>
                <div class="form-driver">{{ data.code }}</div>
              </div>
              <div class="mb-3">
                <div class="mb-1 form-title-font">Nama Mitra</div>
                <div class="form-driver">{{ data.name }}</div>
              </div>
              <div class="mb-3">
                <div class="mb-1 form-title-font">Nomor Telepon</div>
                <div class="form-driver">{{ data.phone }}</div>
              </div>
              <div class="mb-3">
                <div class="mb-1 form-title-font">Alamat</div>
                <div class="form-driver"><img src="../../assets/pin-gps-icon.png" />{{ data.address }}</div>
              </div>
              <div class="mb-3">
                <div class="mb-1 fw-bold size-16">Rekening Bank</div>
                <div class="form-bank d-flex">
                  <div class="me-3"><img src="../../assets/visa-icon.png" /></div>
                  <div>
                    <div class="fw-bold">{{ codeBank(data.bank.bank.name) }}</div>
                    <div>{{ data.bank.account_number }}</div>
                    <div class="fw-ekstra-bold mt-2">{{ data.bank.account_name }}</div>
                  </div>
                  <div class="ms-auto position-relative">
                    <a href="javascript:void(0)" @click="showModalEditBank = true">
                      <img class="position-absolute top-0 end-0" src="../../assets/edit-blue-icon-2.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <div class="mb-1 form-title-font">Email</div>
                <div class="form-driver">{{ data.email }}</div>
              </div>
              <div class="mb-3">
                <div class="mb-1 form-title-font">Penanggung Jawab</div>
                <div class="form-driver">{{ data.owner }}</div>
              </div>
              <div class="mb-3">
                <div class="mb-1 form-title-font">Daerah Layanan</div>
                <div class="form-driver">{{ data.address }}</div>
              </div>
              <div class="row mb-5">
                <div class="col-md-6">
                  <div class="form-title-font mb-1">Foto KTP</div>
                  <enlargeable-image class="image" style="width: 200px !important" :src="identityPictureUrl" />
                </div>
                <div class="col-md-6">
                  <div class="form-title-font mb-1">Foto NIB</div>
                  <enlargeable-image class="image" style="width: 200px !important" :src="businessPictureUrl" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box mt-3">
      <div class="d-flex align-items-center">
        <h3 class="mb-4 fw-bold">List Perjanjian Kerjasama</h3>
        <div class="ms-auto">
          <select class="form-control form-select" v-model="filterStatus" @change="searchData" name="" id="" style="width: 175px">
            <option value="" selected>Filter Status</option>
            <option value="pending">Menunggu</option>
            <option value="approved">Diterima</option>
            <option value="reject">Ditolak</option>
          </select>
        </div>
        <div class="ms-2">
          <button class="add-button" @click="showModalAddPartner = true">Tambah Pengajuan Kerjasama</button>
        </div>
      </div>

      <div class="mt-4" v-if="is_my_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">Periode Kerja Sama</div>
            <div class="d-table-cell">Tanggal Pengajuan</div>
            <div class="d-table-cell">Dokumen</div>
            <div class="d-table-cell">Status</div>
            <div class="d-table-cell">Status PKS</div>
          </div>
          <div class="d-table-row" v-for="(item, index) in dataPartner" :key="index">
            <div class="d-table-cell">{{ index + 1 }}</div>
            <div class="d-table-cell">{{ monthFormat(item.date_start) }} - {{ monthFormat(item.date_end) }}</div>
            <div class="d-table-cell">{{ dateFormat(item.created_at) }}</div>
            <div class="d-table-cell">
              Download<a class="ms-2" :href="item.document_url"><img src="../../assets/arrow-down.png" /></a>
            </div>
            <div class="d-table-cell">
              <div class="tag">
                {{ titleCase(item.status_partnership) }}
              </div>
            </div>
            <div class="d-table-cell">{{ statusPKS(item) }}</div>
          </div>
        </div>

        <div v-if="data.length == 0" class="text-center mt-section">
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="showModalEdit" @click="showModalEdit = false"></div>
      <div class="modal-body-chat vsm" v-if="showModalEdit">
        <div class="d-flex bd-highlight align-items-lg-center">
          <div class="flex-grow-1 bd-highlight">
            <div class="size-18 fw-ekstra-bold">Edit Data</div>
          </div>
          <div class="bd-highlight">
            <a href="javascript:void(0)" @click="showModalEdit = false" class="size-20 text-primary">
              <i class="fa fa-times-circle"></i>
            </a>
          </div>
        </div>
        <div class="line"></div>
        <div class="scroll">
          <div>
            <div class="size-16 mb-1">Logo Mitra</div>
            <div class="position-relative" style="width: 280px">
              <EnlargeableImage class="preview" style="width: 280px" :src="logoPictureEdit" />
              <label for="upload" class="upload-icon upload-button cursor-pointer">
                <i class="fa fa-upload"></i>
                <input class="cursor-pointer" id="upload" type="file" name="file" ref="file" @change="uploadFileLogo" />
              </label>
            </div>
          </div>
          <div class="mt-3">
            <input class="mb-3 form-input" v-model="req.name" placeholder="Nama Mitra" />
            <input class="mb-3 form-input" v-model="req.owner" placeholder="Penanggung Jawab" />
            <input class="mb-3 form-input" v-model="req.phone" placeholder="Nomor Telephone" />
            <input class="mb-3 form-input" v-model="req.email" placeholder="Email" />
            <input class="mb-3 form-input" v-model="req.address" placeholder="Alamat" />
          </div>
          <div class="row mb-5">
            <div class="col-md-6">
              <div class="size-16">Foto KTP</div>
              <div class="position-relative" style="width: 125px">
                <EnlargeableImage class="preview-2" :src="identityPictureEdit" />
                <label for="upload-identity" class="upload-icon cursor-pointer">
                  <i class="fa fa-upload"></i>
                  <input class="cursor-pointer" id="upload-identity" type="file" name="file" ref="file" @change="uploadFileIdentity" />
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="size-16">Foto NIB</div>
              <div class="position-relative" style="width: 125px">
                <EnlargeableImage class="preview-2" :src="businessPictureEdit" />
                <label for="upload-business" class="upload-icon cursor-pointer">
                  <i class="fa fa-upload"></i>
                  <input class="cursor-pointer" id="upload-business" type="file" name="file" ref="file" @change="uploadFileBusiness" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex gap-2">
          <button class="button button--cancel" @click="cancel" :disabled="isSaving">Batal</button>
          <button class="button button--save" @click="submitData" :disabled="isSaving">
            <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
            Simpan
          </button>
        </div>
      </div>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="showModalPass" @click="showModalPass = false"></div>
      <div class="modal-body-chat vsm" v-if="showModalPass">
        <div class="d-flex bd-highlight align-items-lg-center">
          <div class="flex-grow-1 bd-highlight">
            <div class="size-18 fw-ekstra-bold">Ubah Password</div>
          </div>
          <div class="bd-highlight">
            <a href="javascript:void(0)" @click="showModalPass = false" class="size-20 text-primary">
              <i class="fa fa-times-circle"></i>
            </a>
          </div>
        </div>
        <div>
          <div class="size-16 mt-3 mb-3">Masukkan Informasi yang dibutuhkan</div>
          <div class="mb-3 position-relative">
            <input :type="[showPassword1 == true ? 'text' : 'password']" class="form-input" v-model="old_password" placeholder="Masukkan Password Lama" />
            <span class="cursor-pointer eye-icon mt-2" @click="toggleShow(1)">
              <i class="fa" :class="{ 'fa-eye-slash': !showPassword1, 'fa-eye': showPassword1 }"></i>
            </span>
          </div>
          <div class="mb-3 position-relative">
            <input :type="[showPassword2 == true ? 'text' : 'password']" class="form-input" v-model="new_password" placeholder="Masukkan Password Baru" />
            <span class="cursor-pointer eye-icon mt-2" @click="toggleShow(2)">
              <i class="fa" :class="{ 'fa-eye-slash': !showPassword2, 'fa-eye': showPassword2 }"></i>
            </span>
          </div>
          <div class="mb-3 position-relative">
            <input :type="[showPassword3 == true ? 'text' : 'password']" class="form-input" v-model="new_password_confirmation" placeholder="Konfirmasi Password Baru" />
            <span class="cursor-pointer eye-icon mt-2" @click="toggleShow(3)">
              <i class="fa" :class="{ 'fa-eye-slash': !showPassword3, 'fa-eye': showPassword3 }"></i>
            </span>
          </div>
        </div>
        <div class="d-flex gap-2">
          <button class="button button--cancel" @click="cancel" :disabled="isSaving">Batal</button>
          <button class="button button--save" @click="changePassword" :disabled="isSaving">
            <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
            Kirim
          </button>
        </div>
      </div>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="showModalAddPartner" @click="showModalAddPartner = false"></div>
      <div class="modal-body-chat vsm" v-if="showModalAddPartner">
        <div class="d-flex bd-highlight align-items-lg-center">
          <div class="flex-grow-1 bd-highlight">
            <div class="size-18 fw-ekstra-bold">Pengajuan Kerja Sama</div>
          </div>
          <div class="bd-highlight">
            <a href="javascript:void(0)" @click="showModalAddPartner = false" class="size-20 text-primary">
              <i class="fa fa-times-circle"></i>
            </a>
          </div>
        </div>
        <div class="size-16 my-3 text-gray">Tambahkan Informasi kerja sama</div>
        <div class="d-flex">
          <input class="form-input" type="date" v-model="startDate" @input="fillForm" placeholder="Awal" />
          <input class="ms-2 form-input" type="date" v-model="endDate" @input="fillForm" placeholder="Akhir" />
        </div>
        <div class="mt-3">
          <input class="mb-3 form-input" v-model="name" @input="fillForm" placeholder="Nama PIC" />
          <div class="position-relative">
            <input class="mb-3 form-input pe-3" v-model="docName" placeholder="Tambahkan Dokumen" disabled />
            <label for="folder-upload" class="folder-icon cursor-pointer">
              <img src="../../assets/Folder-icon.png" />
              <input class="cursor-pointer" id="folder-upload" type="file" name="file" ref="file" @change="uploadDocument" />
            </label>
            <input class="mb-3 form-input" v-model="note" @input="fillForm" placeholder="Note" />
          </div>
        </div>
        <div class="d-flex gap-2 mt-3">
          <button class="button button--cancel" @click="cancel" :disabled="isSaving">Batal</button>
          <button class="button button--save" @click="addPartner" :disabled="isSaving || isFilled">
            <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
            Simpan
          </button>
        </div>
      </div>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="showModalEditBank" @click="showModalEditBank = false"></div>
      <div class="modal-body-chat vsm" v-if="showModalEditBank">
        <div class="d-flex bd-highlight align-items-lg-center">
          <div class="flex-grow-1 bd-highlight">
            <div class="size-18 fw-ekstra-bold">Edit Rekening</div>
          </div>
          <div class="bd-highlight">
            <a href="javascript:void(0)" @click="showModalEditBank = false" class="size-20 text-primary">
              <i class="fa fa-times-circle"></i>
            </a>
          </div>
        </div>
        <div>
          <div class="size-16 mt-4 mb-3">Masukkan Informasi yang dibutuhkan</div>
          <select class="mb-3 form-input" v-model="req.bank_id" @input="fillFormBank">
            <option value="" selected>Pilih Bank</option>
            <option v-for="(item, index) in bankData" :key="index" :value="item.id">{{ item.name }}</option>
          </select>
          <input class="mb-3 form-input" v-model="req.account_number" @input="fillFormBank" placeholder="Masukan nomor rekening" />
          <input class="mb-3 form-input" v-model="req.account_name" @input="fillFormBank" placeholder="Masukan a/n" />
        </div>
        <div class="d-flex gap-2 mt-3">
          <button class="button button--cancel" @click="cancel" :disabled="isSaving">Batal</button>
          <button class="button button--save" @click="submitBank" :disabled="isSaving || isFilled">
            <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
            Kirim
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
import noImage from "../../assets/no-photo.png";
import EnlargeableImage from "@diracleo/vue-enlargeable-image";
import ListLoader from "../../components/ListLoader.vue";

export default {
  components: {
    EnlargeableImage,
    ListLoader,
  },
  data() {
    return {
      showPassword1: false,
      showPassword2: false,
      showPassword3: false,
      showModalEdit: false,
      showModalPass: false,
      showModalEditBank: false,
      showModalAddPartner: false,
      isSaving: false,
      isFilled: true,
      filterStatus: "",
      data: {},
      dataPartner: "",
      logoImage: "",
      identityImage: "",
      businessImage: "",
      logoImageEdit: "",
      bankData: "",
      identityImageEdit: "",
      businessImageEdit: "",
      req: {
        name: "",
        email: "",
        phone: "",
        owner: "",
        address: "",
        bank: "",
        logo_picture: null,
        identity_picture: null,
        business_picture: null,
        bank_id: "",
        account_name: "",
        account_number: "",
      },
      old_password: "",
      new_password: "",
      new_password_confirmation: "",
      startDate: "",
      endDate: "",
      name: "",
      document: "",
      docName: "",
      note: "",
      dataDummy: [
        {
          periode: "Aug 2022 - Jul 2023",
          date: "23 Juli 2022",
          doc: "Download",
          Status: "Diterima",
          pks: "Sedang Berjalan",
        },
      ],
    };
  },
  created() {
    this.getData();
    this.getPartnership();
    this.getBank();
  },
  methods: {
    searchData() {
      this.getPartnership();
    },
    toggleShow(num) {
      if (num == 1) this.showPassword1 = !this.showPassword1;
      if (num == 2) this.showPassword2 = !this.showPassword2;
      if (num == 3) this.showPassword3 = !this.showPassword3;
    },
    clear() {
      this.startDate = "";
      this.endDate = "";
      this.name = "";
      this.document = "";
      this.docName = "";
      this.note = "";
      this.req.bank_id = "";
      this.req.account_name = "";
      this.req.account_number = "";
    },
    getBank() {
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/corporate/bank`, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.bankData = res.data.data;
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          console.log(err);
        });
    },
    addPartner() {
      this.isSaving = true;
      let data = new FormData();

      data.append("date_start", this.startDate);
      data.append("date_end", this.endDate);
      data.append("pic_person", this.name);
      data.append("document", this.document);
      data.append("note", this.note);
      Api.post(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/partnership`, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.showModalAddPartner = false;
          this.$notify({
            group: "foo",
            type: "success",
            title: "Success",
            text: "Data berhasil dibuat",
          });
          this.getPartnership();
          this.clear();
          this.isSaving = false;
        })
        .catch(function (err) {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          console.error(err);
          this.isSaving = false;
        });
    },
    statusPKS(item) {
      let dateNow = this.monthFormat(new Date());
      if (this.monthFormat(item.date_start > dateNow && item.date_end < dateNow)) {
        return "Sedang Berjalan";
      }
      if (this.monthFormat(item.date_start < dateNow)) {
        return "Akan Berjalan";
      }
      if (this.monthFormat(item.date_end > dateNow)) {
        return "Sudah Selesai";
      }
    },
    titleCase(str) {
      return str
        .split(" ")
        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
    },
    fillForm() {
      if (this.startDate != "" && this.endDate != "" && this.name != "" && this.document != "" && this.note != "") {
        this.isFilled = false;
      } else {
        this.isFilled = true;
      }
    },
    fillFormBank() {
      if (this.req.bank_id != "" && this.req.account_name != "" && this.req.account_number != "") {
        this.isFilled = false;
      } else {
        this.isFilled = true;
      }
    },
    uploadDocument(event) {
      var input = event.target;
      this.document = input.files[0];
      this.docName = this.document.name;
      this.fillForm();
    },
    dateFormat(date) {
      return moment(date).format("DD MMM YYYY");
    },
    monthFormat(date) {
      return moment(date).format("MMM YYYY");
    },
    getPartnership() {
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/partnership`, {
        params: { status: this.filterStatus },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.dataPartner = res.data.data;
        })
        .catch(function (err) {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          console.error(err);
        });
    },
    getData() {
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/corporate`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data = res.data.data;
          this.req.name = this.data.name;
          this.req.phone = this.data.phone;
          this.req.email = this.data.email;
          this.req.owner = this.data.owner;
          this.req.address = this.data.address;
          this.req.logo_picture = this.data.logo_picture;
          this.req.identity_picture = this.data.identity_picture;
          this.req.business_picture = this.data.business_picture;
          this.req.bank_id = this.data.bank.bank_id;
          this.req.account_name = this.data.bank.account_name;
          this.req.account_number = this.data.bank.account_number;
          this.logoImage = this.data.logo_picture_url;
          this.identityImage = this.data.identity_picture_url;
          this.businessImage = this.data.business_picture_url;
          this.logoImageEdit = this.data.logo_picture_url;
          this.identityImageEdit = this.data.identity_picture_url;
          this.businessImageEdit = this.data.business_picture_url;
        })
        .catch(function (err) {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          console.error(err);
        });
    },
    submitData() {
      this.isSaving = true;
      var data = new FormData();

      data.append("id", this.$route.params.id);
      data.append("name", this.req.name);
      data.append("email", this.req.email);
      data.append("phone", this.req.phone);
      data.append("owner", this.req.owner);
      data.append("address", this.req.address);
      if (this.req.logo_picture != this.data.logo_picture) {
        data.append("logo_picture", this.req.logo_picture);
      }
      if (this.req.identity_picture != this.data.identity_picture) {
        data.append("identity_picture", this.req.identity_picture);
      }
      if (this.req.business_picture != this.data.business_picture) {
        data.append("business_picture", this.req.business_picture);
      }

      Api.post(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/corporate`, data, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.showModalEdit = false;
          this.$notify({
            group: "foo",
            type: "success",
            title: "Success",
            text: "Data berhasil dibuat",
          });
          this.getData();
          this.isSaving = false;
          location.reload();
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.isSaving = false;
        });
    },
    submitBank() {
      this.isSaving = true;
      var data = new FormData();

      data.append("bank_id", this.req.bank_id);
      data.append("account_name", this.req.account_name);
      data.append("account_number", this.req.account_number);

      Api.post(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/corporate/bank`, data, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.showModalEditBank = false;
          this.$notify({
            group: "foo",
            type: "success",
            title: "Success",
            text: "Data berhasil dibuat",
          });
          this.getData();
          this.isSaving = false;
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.isSaving = false;
        });
    },
    changePassword() {
      this.isSaving = true;

      Api.post(
        `${process.env.VUE_APP_SERVICE_URL}/user/profile/password`,
        {
          old_password: this.old_password,
          new_password: this.new_password,
          new_password_confirmation: this.new_password_confirmation,
        },
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      )
        .then(() => {
          this.showModalPass = false;
          this.$notify({
            group: "foo",
            type: "success",
            title: "Success",
            text: "Password Berhasil di Ubah",
          });
          this.getData();
          this.isSaving = false;
        })
        .catch((err) => {
          let msg = err.message;
          this.isSaving = false;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
        });
    },
    cancel() {
      this.showModalPass = false;
      this.showModalEdit = false;
      this.showModalAddPartner = false;
      this.showModalEditBank = false;
      this.clear();
    },
    uploadFileLogo(event) {
      var input = event.target;
      this.req.logo_picture = input.files[0];
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.logoImageEdit = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    uploadFileIdentity(event) {
      var input = event.target;
      this.req.identity_picture = input.files[0];

      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.identityImageEdit = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    uploadFileBusiness(event) {
      var input = event.target;
      this.req.business_picture = input.files[0];

      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.businessImageEdit = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    codeBank(code) {
      var bank;
      switch (code.toLowerCase()) {
        case "bri":
          bank = "Bank Rakyat Indonesia";
          break;
        case "bca":
          bank = "Bank Central Asia";
          break;
        case "mandiri":
          bank = "Bank Mandiri";
          break;
        case "bni":
          bank = "Bank Negara Indonesia";
          break;
        case "bsi":
          bank = "Bank Syariah Indonesia";
          break;
        case "mega":
          bank = "Bank Mega";
          break;
        case "permata":
          bank = "Bank Permata";
          break;
        case "cimb":
          bank = "Bank CIMB Niaga";
          break;
        case "danamon":
          bank = "Bank Danamon Indonesia";
          break;
        case "btpn":
          bank = "Bank BTPN";
          break;
        case "maybank":
          bank = "Bank Maybank Indonesia";
          break;
        case "ocbc":
          bank = "Bank OCBC NISP";
          break;
        default:
          bank = code.toUpperCase();
      }
      return bank;
    },
  },
  computed: {
    logoPictureUrl() {
      return this.logoImage.length > 0 ? this.logoImage : noImage;
    },
    identityPictureUrl() {
      return this.identityImage.length > 0 ? this.identityImage : noImage;
    },
    businessPictureUrl() {
      return this.businessImage.length > 0 ? this.businessImage : noImage;
    },
    logoPictureEdit() {
      return this.logoImageEdit.length > 0 ? this.logoImageEdit : noImage;
    },
    identityPictureEdit() {
      return this.identityImageEdit.length > 0 ? this.identityImageEdit : noImage;
    },
    businessPictureEdit() {
      return this.businessImageEdit.length > 0 ? this.businessImageEdit : noImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.folder-icon {
  position: absolute;
  top: 12px;
  right: 12px;
}
.avatar {
  margin-left: 25px;
  width: 169px;
  height: 169px;
  object-fit: cover;
  vertical-align: middle;
  border-radius: 10px;
}
.form-driver {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  background: rgba(239, 241, 249, 0.6);
  padding: 10px;
  width: auto;
  height: 50px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.form-bank {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  background: rgba(239, 241, 249, 0.6);
  padding: 10px;
  width: auto;
  height: auto;
  display: flex;
  align-items: start;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.tag {
  display: flex;
  align-content: center;
  justify-content: center;
  color: #000000;
  background: #bed7b6;
  border-radius: 6px;
  width: 134px;
  height: 30px;
}
.form-status {
  display: flex;
  background: #f6f7fb;
  border: 1px solid #48a2d4;
  border-radius: 8px;
  width: 185px;
  height: 52px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  color: #000000;
}
.dot {
  height: 15px;
  width: 15px;
  margin: 10px;
  background-color: #48a2d4;
  border-radius: 50%;
  display: inline-block;
}
.form-doc {
  margin-top: 20px;
  width: 714px;
  display: flex;
  justify-content: space-between;
}
.form-lg {
  width: auto;
  height: 153px;
  display: block;
}
.form-title-font {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.form-input {
  background: rgba(239, 241, 249, 0.6);
  border-width: 0px;
  border: none;
  outline: none;
  border-radius: 10px;
  width: 100%;
  height: 52px;
  padding: 10px;
}
.insert-image {
  border-width: 0px;
  border: none;
  outline: none;
}
.add-button {
  background: #48a2d4;
  border-radius: 8px;
  border: 1px solid #48a2d4;
  color: white;
  padding: 10px 25px;
}
.add-button:is(:hover, :focus) {
  background: #176f9e;
}
.change-button {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #48a2d4;
  color: #48a2d4;
  padding: 10px 25px;
}
.change-button:is(:hover, :focus) {
  background: #176f9e;
  color: #ffffff;
}
.button {
  display: flex;
  border-radius: 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 168px;
  height: 44px;
  &--save {
    background: #48a2d4;
    border: 1px solid #48a2d4;
    color: white;
  }
  &--save:is(:hover, :focus) {
    background: #176f9e;
  }
  &--save:disabled {
    background: #e8e8e9;
    border: 1px solid #ffffff;
  }
  &--cancel {
    background: #ffffff;
    border: 1px solid #e94b58;
    color: #e94b58;
  }
  &--cancel:is(:hover, :focus) {
    background: #e94b58;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
}
.radio {
  background: rgba(239, 241, 249, 0.6);
  border-radius: 10px;
  width: 133px;
  height: 35px;
  padding-top: 2%;
  padding-left: 10%;
}
.eye-icon {
  position: absolute;
  top: 10px;
  right: 12px;
}
input[type="file"] {
  display: none;
}
.preview :deep(img) {
  width: 280px;
  height: 120px;
  object-fit: cover;
}
.preview-2 :deep(img) {
  width: 125px;
  height: 100px;
  object-fit: cover;
}
.image :deep(img) {
  width: 170px;
  height: 100px;
  object-fit: cover;
}
.row.foto {
  --bs-gutter-x: 0rem !important;
}
</style>
