<template>
    <div class="padding-container">
        <div class="d-flex align-items-center bd-highlight">
            <div class="flex-grow-1 bd-highlight">
                <h3 class="fw-bold">
                    List Driver
                </h3>
            </div>
            <div class="bd-highlight">
                <a href="javascript:void(0)" @click="modalAddDriver = true" class="btn btn-green btn-lg px-5">
                    Tambah Driver
                </a>
            </div>
        </div>
        <div class="box mt-4">
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader/>
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-md-5 mt-3">
                        <div class="position-relative">
                            <input type="text" class="form-control" placeholder="Cari driver" v-model="req.keyword" />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="modern-table-boxed mt-4" v-if="searchLists.length > 0">
                    <div class="d-table-row header">
                        <div class="d-table-cell">No</div>
                        <div class="d-table-cell">Nama Driver</div>
                        <div class="d-table-cell">NIK</div>
                        <div class="d-table-cell">Alamat</div>
                        <div class="d-table-cell">No Telepon</div>
                        <div class="d-table-cell">Aksi</div>
                    </div>
                    <template v-for="(list, index) in searchLists">
                        <div class="d-table-row" :key="index">
                            <div class="d-table-cell fw-bold">
                                {{ index+1 }}.
                            </div>
                            <div class="d-table-cell">
                                {{ list.nama_driver }}
                            </div>
                            <div class="d-table-cell">
                                {{ list.nik }}
                            </div>
                            <div class="d-table-cell limit-one-line" style="width: 250px;">
                                <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" :title="list.alamat">
                                    <div class="limit-one-line">
                                        {{ list.alamat }}
                                    </div>
                                </a>
                            </div>
                            <div class="d-table-cell">
                                {{ list.no_telepon }}
                            </div>
                            <div class="d-table-cell shrink">
                                <div class="d-flex">
                                    <div class="me-3">
                                        <a href="javascript:void(0)" class="text-blue" @click="editModal(list.id)">
                                            <i class="fa fa-pencil"></i>
                                        </a>
                                    </div>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" role="switch" :id="list.id" :checked="list.is_verified" v-model="list.is_verified" @change="activateStatus(list.id, list.is_verified)">
                                        <label class="form-check-label" :for="list.id"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="">
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <b-pagination-nav v-if="searchLists.length > 0" :link-gen="linkGen" v-model="currentPage" :number-of-pages="list.total_page" base-url="#" first-number align="right"></b-pagination-nav>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalAddDriver"></div>
            <div class="modal-body-chat sm" v-if="modalAddDriver">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            Tambah Driver
                        </div>
                    </div>
                    <div class="bd-highlight">
                        <a href="javascript:void(0)" @click="modalAddDriver = false" class="size-20 text-primary">
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div>
                    Tambahkan Informasi Driver Anda
                </div>
                <div class="scroll mt-4">
                    <div>
                        <label class="mb-2 fw-bold">Foto Driver</label>
                        <div class="image-view-1" :style="{'background-image': 'url(' + (req.avatar) + ')'}">
                            <div class="mt-4 ml-4">
                                <label for="avatar" class="upload-button single-image"> 
                                    <i class="fa fa-upload"></i>
                                    <input type="file" id="avatar" @change="uploadDriver()">
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Email</label>
                        <input type="email" class="form-control" placeholder="Masukkan email" v-model="req.email">
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Password</label>
                        <div class="position-relative">
                            <input type="text" class="form-control" placeholder="Masukkan password" v-model="req.password" v-if="showPassword">
                            <input type="password" class="form-control" placeholder="Masukkan password" v-model="req.password" v-else>
                            <span class="search-icon cursor-pointer" @click="toggleShow">
                                <i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                            </span>
                        </div>
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Nama Driver</label>
                        <input type="text" class="form-control" placeholder="Masukkan nama driver" v-model="req.name">
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Tanggal Lahir</label>
                        <input type="date" class="form-control" placeholder="Masukkan tanggal lahir" v-model="req.birth">
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Alamat Driver</label>
                        <textarea name="" id="" cols="30" rows="4" class="form-control" placeholder="Masukkan alamat driver" v-model="req.address"></textarea>
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Nomor Telepon</label>
                        <input type="number" class="form-control" placeholder="Masukkan nomor telepon" v-model="req.phone">
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Nomor KTP</label>
                        <input type="number" class="form-control" placeholder="Masukkan nomor KTP" v-model="req.no_ktp">
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Foto KTP</label>
                        <div class="image-view-2" :style="{'background-image': 'url(' + (req.photo_ktp) + ')'}">
                            <div class="mt-4 ml-4">
                                <label for="ktp" class="upload-button single-image"> 
                                    <i class="fa fa-upload"></i>
                                    <input type="file" id="ktp" class="upload__inputfile" @change="uploadKTP()">
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Foto SIM</label>
                        <div class="image-view-3" :style="{'background-image': 'url(' + (req.photo_sim) + ')'}">
                            <div class="mt-4 ml-4">
                                <label for="sim" class="upload-button single-image"> 
                                    <i class="fa fa-upload"></i>
                                    <input type="file" id="sim" @change="uploadSIM()">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <button class="btn btn-green px-5 btn-md me-3" type="button" disabled v-if="is_save">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Simpan
                    </button>
                    <button class="btn btn-green px-5 btn-md me-3" @click="storeData()" v-else>Simpan</button>
                    <a href="javascript:void(0)" @click="closeStoreModal()" class="btn btn-outline-black px-5 btn-md">
                        Batal
                    </a>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalEditDriver"></div>
            <div class="modal-body-chat sm" v-if="modalEditDriver">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            Edit Driver
                        </div>
                    </div>
                    <div class="bd-highlight">
                        <a href="javascript:void(0)" @click="modalEditDriver = false" class="size-20 text-primary">
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div>
                    Edit Informasi Driver Anda
                </div>
                <div class="scroll mt-4">
                    <div>
                        <label class="mb-2 fw-bold">Foto Driver</label>
                        <div class="image-view-1" :style="{'background-image': 'url(' + (detail_driver.avatar) + ')'}">
                            <div class="mt-4 ml-4">
                                <label for="avatar" class="upload-button single-image"> 
                                    <i class="fa fa-upload"></i>
                                    <input type="file" id="avatar" @change="uploadDriver()">
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Email</label>
                        <input type="email" class="form-control" placeholder="Masukkan email" v-model="detail_driver.email" disabled>
                    </div>
                    <div class="mt-3">
                        <div class="d-flex">
                            <label class="mb-2 fw-bold">Password</label>
                            <div class="form-check ms-auto">
                                <input class="form-check-input" type="checkbox" v-model="is_check_password" id="password" @change="is_check_password">
                                <!-- <span class="search-icon cursor-pointer" @click="toggleShow">
                                    <i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                </span> -->
                                <label class="form-check-label" for="password">
                                    Ubah Password
                                </label>
                            </div>
                        </div>
                        <div class="position-relative">
                            <input type="text" class="form-control" placeholder="Masukkan password" v-model="detail_driver.password" :disabled="is_check_password == false" v-if="showPassword">
                            <input type="password" class="form-control" placeholder="Masukkan password" v-model="detail_driver.password" :disabled="is_check_password == false" v-else>
                            <span class="search-icon cursor-pointer" @click="toggleShow">
                                <i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                            </span>
                        </div>
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Nama Driver</label>
                        <input type="text" class="form-control" placeholder="Masukkan nama driver" v-model="detail_driver.nama_driver">
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Tanggal Lahir</label>
                        <input type="date" class="form-control" placeholder="Masukkan tanggal lahir" v-model="detail_driver.birth">
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Alamat Driver</label>
                        <textarea name="" id="" cols="30" rows="4" class="form-control" placeholder="Masukkan alamat driver" v-model="detail_driver.alamat"></textarea>
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Nomor Telepon</label>
                        <input type="number" class="form-control" placeholder="Masukkan nomor telepon" v-model="detail_driver.no_telepon">
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Nomor KTP</label>
                        <input type="number" class="form-control" placeholder="Masukkan nomor KTP" v-model="detail_driver.nik">
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Foto KTP</label>
                        <div class="image-view-2" :style="{'background-image': 'url(' + (detail_driver.photo_ktp) + ')'}">
                            <div class="mt-4 ml-4">
                                <label for="ktp" class="upload-button single-image"> 
                                    <i class="fa fa-upload"></i>
                                    <input type="file" id="ktp" class="upload__inputfile" @change="uploadKTP()">
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Foto SIM</label>
                        <div class="image-view-3" :style="{'background-image': 'url(' + (detail_driver.photo_sim) + ')'}">
                            <div class="mt-4 ml-4">
                                <label for="sim" class="upload-button single-image"> 
                                    <i class="fa fa-upload"></i>
                                    <input type="file" id="sim" @change="uploadSIM()">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <button class="btn btn-green px-5 btn-md me-3" type="button" disabled v-if="is_update">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Update
                    </button>
                    <button class="btn btn-green px-5 btn-md me-3" @click="updateData()" v-else>Update</button>
                    <a href="javascript:void(0)" @click="closeUpdateModal()" class="btn btn-outline-black px-5 btn-md">
                        Batal
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from '../../components/ListLoader.vue';
import { Tooltip } from "bootstrap";
import $ from 'jquery'

export default {
    components: {
        ListLoader
    },
    name: "Driver",
    data() {
        return {
            is_list_ready: false,
            is_check_password: false,
            req: {
                keyword: '',
                avatar: '',
                email: '',
                password: '',
                name: '',
                birth: '',
                address: '',
                phone: '',
                no_ktp: '',
                photo_ktp: '',
                photo_sim: '',
            },
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0
            },
            per_page: 10,
            currentPage: 0,
            modalAddDriver: false,
            is_changing_avatar: false,
            is_changing_sim: false,
            is_changing_ktp: false,
            is_save: false,
            is_update: false,
            modalEditDriver: false,
            detail_driver: {
                avatar: '',
                email: '',
                password: '',
                nama_driver: '',
                birth: '',
                alamat: '',
                no_telepon: '',
                nik: '',
                photo_ktp: '',
                photo_sim: '',
            },
            showPassword: false,
        };
    },
    created() {
        this.getDriverList()
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    computed: {
        searchLists() {
            return this.list.list_data.filter((item) => {
                return item.nama_driver.toLowerCase().includes(this.req.keyword.toLowerCase());
            });
        },
        buttonLabel() {
            return (this.showPassword) ? "Hide" : "Show";
        }
    },
    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        getDriverList() {
            this.is_list_ready = true
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/vehicle/driver/list`, {
                params: { per_page: this.per_page, page: this.$route.query.page},
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                var data = res.data.data
                this.list = data
                this.is_list_ready = false
            })
            .catch((err) => {
                console.log(err);
                this.is_list_ready = false
            });
        },
        uploadDriver() {
            var avatar = new FileReader();
            avatar.onload = function (e) {
                $('.image-view-1').css('background-image', 'url('+ e.target.result + ')')
            };
            avatar.readAsDataURL($('#avatar')[0].files[0]);
            this.is_changing_avatar = true
        },
        uploadKTP() {
            var ktp = new FileReader();
            ktp.onload = function (e) {
                $('.image-view-2').css('background-image', 'url('+ e.target.result + ')')
            };
            ktp.readAsDataURL($('#ktp')[0].files[0]);
            this.is_changing_ktp = true
        },
        uploadSIM() {
            var sim = new FileReader();
            sim.onload = function (e) {
                $('.image-view-3').css('background-image', 'url('+ e.target.result + ')')
            };
            sim.readAsDataURL($('#sim')[0].files[0]);
            this.is_changing_sim = true
        },
        storeData(){
            var avatar = $('#avatar')[0].files[0]
            var ktp = $('#ktp')[0].files[0]
            var sim = $('#sim')[0].files[0]
            var data = new FormData()

            data.append('email', this.req.email)
            data.append('password', this.req.password)
            data.append('name', this.req.name)
            data.append('birth', this.req.birth)
            data.append('address', this.req.address)
            data.append('phone', this.req.phone)
            data.append('no_ktp', this.req.no_ktp)
            if(this.is_changing_avatar) {
                data.append('avatar',avatar)
            }
            if(this.is_changing_ktp) {
                data.append('photo_ktp',ktp)
            }
            if(this.is_changing_sim) {
                data.append('photo_sim',sim)
            }
            this.is_save = true
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/vehicle/driver/create`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            })
            .then((res) => {
                this.is_save = false

                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Success',
                    text: 'Data berhasil dibuat'
                });

                this.is_changing_avatar = false
                this.is_changing_ktp = false
                this.is_changing_sim = false
                this.modalAddDriver = false
                this.req.email = ''
                this.req.password = ''
                this.req.name = ''
                this.req.birth = ''
                this.req.address = ''
                this.req.phone = ''
                this.req.no_ktp = ''

                this.getDriverList()

                console.log(res)
            })
            .catch((err) => {
                this.is_save = false
                this.$notify({
                    group: 'foo',
                    type: 'error',
                    title: 'Error',
                    text: err.response.data.message
                });
            })
        },
        closeStoreModal(){
            this.modalAddDriver = false
            this.is_changing_avatar = false
            this.is_changing_ktp = false
            this.is_changing_sim = false
            this.req.email = ''
            this.req.password = ''
            this.req.name = ''
            this.req.birth = ''
            this.req.address = ''
            this.req.phone = ''
            this.req.no_ktp = ''
        },
        getDetailDriver(id) {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/vehicle/driver/detail/${id}`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                var data = res.data.data
                this.detail_driver = data
            })
            .catch((err) => {
                console.log(err);
            });
        },
        editModal(id){
            this.modalEditDriver = true
            this.getDetailDriver(id)
        },
        updateData(){
            var avatar = $('#avatar')[0].files[0]
            var ktp = $('#ktp')[0].files[0]
            var sim = $('#sim')[0].files[0]
            var data = new FormData()

            data.append('email', this.detail_driver.email)
            data.append('password', this.detail_driver.password)
            data.append('name', this.detail_driver.nama_driver)
            data.append('birth', this.detail_driver.birth)
            data.append('address', this.detail_driver.alamat)
            data.append('phone', this.detail_driver.no_telepon)
            data.append('no_ktp', this.detail_driver.nik)
            if(this.is_changing_avatar) {
                data.append('avatar',avatar)
            }
            if(this.is_changing_ktp) {
                data.append('photo_ktp',ktp)
            }
            if(this.is_changing_sim) {
                data.append('photo_sim',sim)
            }
            this.is_update = true
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/vehicle/driver/update/${this.detail_driver.id}`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            })
            .then((res) => {
                this.is_update = false

                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Success',
                    text: 'Data berhasil diupdate'
                });

                this.is_changing_avatar = false
                this.is_changing_ktp = false
                this.is_changing_sim = false
                this.modalEditDriver = false
                this.detail_driver.email = ''
                this.detail_driver.password = ''
                this.detail_driver.nama_driver = ''
                this.detail_driver.birth = ''
                this.detail_driver.alamat_driver = ''
                this.detail_driver.no_telepon = ''
                this.detail_driver.nik = ''

                this.getDriverList()

                console.log(res)
            })
            .catch((err) => {
                this.is_update = false
                this.$notify({
                    group: 'foo',
                    type: 'error',
                    title: 'Error',
                    text: err.response.data.message
                });
            })
        },
        closeUpdateModal(){
            this.modalEditDriver = false
            this.is_changing_avatar = false
            this.is_changing_ktp = false
            this.is_changing_sim = false
            this.detail_driver.email = ''
            this.detail_driver.password = ''
            this.detail_driver.nama_driver = ''
            this.detail_driver.birth = ''
            this.detail_driver.alamat_driver = ''
            this.detail_driver.no_telepon = ''
            this.detail_driver.nik = ''
            this.detail_driver.photo_ktp = ''
            this.detail_driver.photo_sim = ''
        },
        activateStatus(id, is_verified){
            var data = {
                is_verified: is_verified
            }
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/vehicle/driver/activate/${id}`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            })
            .then((res) => {
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Success',
                    text: 'Status berhasil diubah'
                });
                this.getDriverList()
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
        }
    },
};
</script>

<style scoped>
    .upload__inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
</style>