<template>
  <div class="padding-container">
    <div class="d-flex bd-highlight">
      <div class="flex-grow-1 bd-highlight">
        <h4 class="fw-bold" @click="isDetail = false">Detail Manifest</h4>
      </div>
    </div>
    <div class="box">
      <div class="d-flex justify-content-between">
        <div class="p-2 bd-highlight">
          <h4 class="fw-light fs-6">Pembuat Manifest</h4>
          <h4 class="fw-bold fs-5">{{ creator }}</h4>
        </div>
        <div class="p-2 bd-highlight">
          <h4 class="fw-light fs-6">Driver</h4>
          <h4 class="fw-bold fs-5">{{ driver }}</h4>
        </div>
        <div class="p-2 bd-highlight">
          <h4 class="fw-light fs-6">No Manifest</h4>
          <h4 class="fw-bold fs-5">{{ manifest_code }}</h4>
        </div>
        <div class="p-2 bd-highlight">
          <h4 class="fw-light fs-6">Status Manifest</h4>
          <h4 class="fw-bold fs-5">{{ status }}</h4>
        </div>
        <div class="p-2 bd-highlight">
          <h4 class="fw-light fs-6">Partner Transporter</h4>
          <h4 class="fw-bold fs-5">{{ partner_transporter }}</h4>
        </div>
        <div class="p-2 bd-highlight">
          <h4 class="fw-light fs-6">Jumlah Koli</h4>
          <h4 class="fw-bold fs-5">{{ qty_count }}</h4>
        </div>
      </div>
    </div>

    <div class="box mt-3">
      <div class="d-flex justify-content-end">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="Masukan kode resi" value="code" @keyup.enter="searchManifest" v-model="code" />
          <span class="search-icon">
            <i class="fa fa-search"></i>
          </span>
        </div>
      </div>
      <div class="modern-table-boxed mt-4">
        <div class="d-table-row header">
          <div class="d-table-cell">No Resi</div>
          <div class="d-table-cell">Mitra Pengiriman</div>
          <div class="d-table-cell">Kota Tujuan</div>
          <div class="d-table-cell">Order By</div>
          <div class="d-table-cell">Tanggal Order</div>
        </div>
        <div class="d-table-row" v-for="(item, index) in data" :key="index">
          <div class="d-table-cell">{{ item.receipt_code }}</div>
          <div class="d-table-cell">{{ item.partner_code }}</div>
          <div class="d-table-cell">{{ item.regency_destination }}</div>
          <div class="d-table-cell">{{ item.order_by }}</div>
          <div class="d-table-cell">{{ dateFormat(item.order_create) }}</div>
        </div>
      </div>
    </div>
    <div class="overflow-auto mt-3">
      <b-pagination-nav v-if="data.length > 0" v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav>
    </div>
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import moment from "moment";

export default {
  components: {},
  name: "Detail Manifest",
  data() {
    return {
      showModal: false,
      data: [],
      currentPage: 0,
      totalPage: 0,
      limit: 10,
      code: "",
      creator: "",
      driver: "",
      manifest_code: "",
      status: "",
      partner_transporter: "",
      qty_count: 0,
    };
  },
  created() {
    this.getDetailManifest();
  },
  computed: {},
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
  },
  methods: {
    searchManifest() {
      this.getDetailManifest();
    },
    changePage() {
      this.getDetailManifest();
    },
    getDetailManifest() {
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/admin/fno/tracking/check/manifest/detail`, {
        params: { page: this.currentPage == 0 || this.currentPage == null ? 0 : this.currentPage - 1, limit: this.limit, manifest_code: this.$route.params.code, receipt_code: this.code },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data = res.data.data.manifest_list.list_data;
          this.creator = res.data.data.creator;
          this.driver = res.data.data.driver;
          this.manifest_code = res.data.data.manifest_code;
          this.status = res.data.data.status;
          this.partner_transporter = res.data.data.partner_transporter;
          this.qty_count = res.data.data.qty_count;
          this.totalPage = res.data.data.manifest_list.total_page;
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          console.log(err);
        });
    },
    dateFormat(date) {
      return moment(date).format("DD MMM YYYY");
    },
  },
};
</script>

<style scoped>
.scroll {
  height: 500px;
  overflow-y: scroll;
}
.row.foto {
  --bs-gutter-x: 0rem !important;
}
</style>
