<template>
    <div>
      <warehouse :items="items" />
    </div>
  </template>
  <script>
  import warehouse from "./Warehouse.vue";
  
  export default {
    components: {
      warehouse,
    },
    name: "Dashboard-MPW",
    data() {
      return {
        items: {
          name: "Mitra Pool Warehouse",
          partner_type: "mpw",
          url: "admin-income-mb-detail",
        },
      };
    },
    created() {},
    methods: {},
  };
  </script>
  <style scoped></style>
  