<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Deal Result</h3>
            </div>
            <div class="row mt-4">
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Resi</label>
                    <div class="input-group mb-3">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Masukkan Nomor Resi"
                            v-model="receipt_code"
                        />
                        <button
                            class="btn btn-green"
                            type="button"
                            @click="getReceipt()"
                        >
                            Cek Resi
                        </button>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2 m-mt-1">Customers Name</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Customers Name"
                        v-model="customer_name"
                        disabled
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Price</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="price"
                        v-model="price"
                        disabled
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Route</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Route"
                        v-model="prospect_route"
                        disabled
                    />
                </div>
                <div
                    class="col-md-6 mt-3"
                    v-if="
                        receiptData?.product == 'trawlpack' ||
                        receiptData == null
                    "
                >
                    <label class="fw-bold mb-2">Weight</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Weight"
                        v-model="weight"
                        disabled
                    />
                </div>
                <div
                    class="col-md-6 mt-3"
                    v-if="receiptData?.product == 'trawltruck'"
                >
                    <label class="fw-bold mb-2">Armada</label>
                    <input
                        type="text"
                        class="form-control capitalize"
                        placeholder="Armada"
                        v-model="fleet_type"
                        disabled
                    />
                </div>
                <div
                    class="col-md-6 mt-3"
                    v-if="receiptData?.product == 'trawlcarrier'"
                >
                    <label class="fw-bold mb-2">Service</label>
                    <input
                        type="text"
                        class="form-control capitalize"
                        placeholder="Service"
                        v-model="service_type"
                        disabled
                    />
                </div>
                <div
                    class="col-md-6 mt-3"
                    v-if="receiptData?.product == 'trawlcarrier'"
                >
                    <label class="fw-bold mb-2">Car Type</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Car Type"
                        v-model="car_type"
                        disabled
                    />
                </div>
                <div v-if="isRole" class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">CRM Specialist</label>
                    <multiselect
                        v-model="crm_specialist_id"
                        :options="crmSpecialist"
                        label="name"
                        :loading="is_load"
                        track-by="id"
                        placeholder="CRM Specialist"
                    >
                    </multiselect>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Description</label>
                    <textarea
                        rows="5"
                        cols="4"
                        class="form-control"
                        placeholder="Description"
                        v-model="description"
                    ></textarea>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <a
                                href="javascript:void(0)"
                                @click="$router.go(-1)"
                                class="btn btn-outline-black btn-lg px-5 me-3 m-w-100"
                                >Cancel</a
                            >
                            <button
                                class="btn btn-green btn-lg px-5 me-3 m-w-100 m-mt-1"
                                type="button"
                                disabled
                                v-if="is_save"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Save
                            </button>
                            <button
                                class="btn btn-green btn-lg px-5 me-3 m-w-100 m-mt-1"
                                @click="storeData()"
                                v-else
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data Saved!!</h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/crm/prospect/all"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalError"></div>
            <div class="modal-body-chat vsm" v-if="modalError">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/close.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Penambahan Data Gagal!</h4>
                    <div class="mt-2">
                        Mohon maaf terjadi kesalahan! Silakan ulangi kembali
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="modalError = false"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    name: "ManagementMasterRouteCreate",
    data() {
        return {
            modalSuccess: false,
            modalError: false,
            origin_regency: null,
            destination_regency: null,
            is_load: false,
            isRole: false,
            receiptData: null,
            crmSpecialist: [],
            customer_name: "",
            weight: 0,
            price: 0,
            amount: 0,
            prospect_route: "",
            crm_specialist_id: "",
            origin_regency_id: "",
            destination_regency_id: "",
            receipt_code: "",
            description: "",
            is_save: false,
            fleet_type: "",
            service_type: "",
            car_type: "",
        };
    },
    created() {
        this.getCrmSpecialist();
        if (this.$route.params.isRole) {
            localStorage.setItem("isRole", this.$route.params.isRole);
        }
        this.isRole = localStorage.getItem("isRole");
    },
    methods: {
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getCrmSpecialist() {
            this.is_load = true;
            // todo better handle searching
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist?limit=100`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data.list_data;
                    this.crmSpecialist = data;
                    this.is_load = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load = false;
                });
        },
        storeData() {
            this.is_save = true;
            var data = {
                id: this.$route.params.id,
                customer_name: this.customer_name,
                receipt_code: this.receipt_code,
                origin_regency_id: this.origin_regency_id,
                destination_regency_id: this.destination_regency_id,
                weight: this.weight,
                amount: this.amount,
                description: this.description,
            };
            if (this.isRole) {
                data.crm_specialist_id = this.crm_specialist_id.id;
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/prospect/result/deal`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    this.modalSuccess = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getReceipt() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/order/receipt`, {
                params: {
                    receipt_code: this.receipt_code,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.receiptData = res.data.data;
                    this.customer_name = this.receiptData.customer_name;
                    this.price = this.currencyFormat(this.receiptData.amount);
                    this.prospect_route = `${this.receiptData.origin_regency} - ${this.receiptData.destination_regency}`;
                    this.weight = this.receiptData.weight
                        ? this.receiptData.weight
                        : 0;
                    this.fleet_type = this.receiptData.fleet_type;
                    this.service_type = this.receiptData.service_type
                        .split("_")
                        .join(" ");
                    this.car_type = this.receiptData.car_desc;
                    this.amount = this.receiptData.amount;
                    this.origin_regency_id = this.receiptData.origin_regency_id;
                    this.destination_regency_id =
                        this.receiptData.destination_regency_id;
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                    this.customer_name = "";
                    this.price = 0;
                    this.prospect_route = "";
                    this.weight = 0;
                    this.fleet_type = "";
                    this.service_type = "";
                    this.car_type = "";
                });
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
.money-custom {
    height: 42px;
    font-size: 16px;
    padding-left: 15px;
}
</style>
