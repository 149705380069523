<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="fw-bold">
            <a
                href="/crm/partnership/candidate"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="size-20 fw-bold">Leads</div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <label class="fw-bold">Partner Type</label>
                    <multiselect
                        v-model="req.partner_type"
                        disabled
                        :options="products"
                        :multiple="true"
                        placeholder="Choose Partner Type"
                        class="multi-form-custom tag-custom mt-2"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6">
                    <label class="fw-bold">Activity</label>
                    <select
                        class="form-control form-select mt-2"
                        v-model="req.activity"
                    >
                        <option value="">Choose Activity</option>
                        <option value="canvasing">Canvasing/Visit</option>
                        <option value="meeting">Meeting</option>
                        <option value="other">Other</option>
                    </select>
                </div>
                <div class="col-md-12 mt-3" v-if="req.activity == 'canvasing'">
                    <label class="fw-bold">GMaps</label>
                    <GmapAutocomplete
                        @place_changed="processLocationChanged"
                        placeholder="Pilih lokasi anda"
                        class="form-control mt-2"
                    ></GmapAutocomplete>
                </div>
                <div class="col-md-12 mt-3" v-if="req.activity == 'canvasing'">
                    <label class="fw-bold">Upload Photo</label>
                    <div class="text-gray mb-2">Photo Max 5MB</div>
                    <label
                        type="file"
                        :class="!attachment ? 'upload__btn' : ''"
                    >
                        <img
                            v-if="attachment"
                            class="photo"
                            :src="attachmentPictureUrl"
                        />
                        <p v-else class="mb-0">
                            <i class="fa fa-plus"></i>
                        </p>
                        <input
                            class="cursor-pointer"
                            id="upload"
                            type="file"
                            name="file"
                            ref="file"
                            @change="uploadPhoto"
                        />
                    </label>
                </div>
                <div class="col-md-12 mt-3" v-if="req.activity == 'meeting'">
                    <label class="fw-bold">Meeting Type</label>
                    <select
                        class="form-control form-select mt-2"
                        v-model="req.meeting_type"
                    >
                        <option value="">Choose Meeting Type</option>
                        <option value="offline">Offline</option>
                        <option value="online">Online</option>
                    </select>
                </div>
                <div
                    class="col-md-12 mt-3"
                    v-if="req.activity == 'meeting' || req.activity == 'other'"
                >
                    <label class="fw-bold">Description</label>
                    <textarea
                        v-model="req.description"
                        cols="30"
                        rows="4"
                        class="form-control mt-2"
                        placeholder="Description"
                    ></textarea>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold">Company Name</label>
                    <input
                        v-model="req.company"
                        disabled
                        type="text"
                        class="form-control mt-2"
                        placeholder="Company Name"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold">Owner Name</label>
                    <input
                        v-model="req.pic"
                        disabled
                        type="text"
                        class="form-control mt-2"
                        placeholder="Owner Name"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold">Phone</label>
                    <input
                        v-model="req.phone"
                        disabled
                        type="number"
                        class="form-control mt-2"
                        placeholder="Phone"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold">Email</label>
                    <input
                        v-model="req.email"
                        disabled
                        type="email"
                        class="form-control mt-2"
                        placeholder="Email"
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold">Follow Up</label>
                    <date-picker
                        v-model="req.follow_up"
                        type="date"
                        value-type="format"
                        format="YYYY-MM-DD"
                        :clearable="false"
                        placeholder="Follow Up"
                        class="w-100 mt-2"
                    >
                    </date-picker>
                </div>
                <!-- <div class="col-md-6 mt-3">
                    <label class="fw-bold">Status</label>
                    <select
                        class="form-control form-select mt-2"
                        v-model="req.status"
                    >
                        <option value="process">Process</option>
                        <option value="join">Joined</option>
                        <option value="exit">Exit</option>
                    </select>
                </div> -->
                <div class="col-md-12 mt-3">
                    <label class="fw-bold">Note</label>
                    <textarea
                        v-model="req.note"
                        cols="30"
                        rows="4"
                        class="form-control mt-2"
                        placeholder="Note"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="/crm/partnership/candidate"
                            class="btn btn-outline-black btn-lg w-100"
                            >Cancel</a
                        >
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            class="btn btn-green btn-lg w-100"
                            :disabled="is_store"
                            @click="storeData()"
                        >
                            <span
                                v-if="is_store"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Leads Data Succeeded!</h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/crm/partnership/candidate"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";
import noImage from "../../assets/no-photo.png";
import { gmapApi } from "vue2-google-maps";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

export default {
    components: {
        Multiselect,
        DatePicker,
    },
    name: "crmPartnershipCandidateEdit",
    data() {
        return {
            moment: moment,
            products: ["TroPack", "TroTruck", "TroCarrier"],
            req: {
                pic: "",
                company: "",
                phone: "",
                email: "",
                partner_type: [],
                note: "",
                activity: "",
                attachment: "",
                follow_up: "",
                status: "process",
                meeting_type: "",
                description: "",
            },
            is_store: false,
            modalSuccess: false,
            attachment: "",
            lat_search: "",
            lng_search: "",
            geo_address: "",
        };
    },
    created() {
        this.getList();
    },
    mounted() {},
    computed: {
        attachmentPictureUrl() {
            return this.attachment.length > 0 ? this.attachment : noImage;
        },
        google: gmapApi,
    },
    methods: {
        storeData() {
            this.is_store = true;
            var data = new FormData();
            data.append("candidate_id", this.$route.params.id);
            data.append("activity", this.req.activity);
            data.append("note", this.req.note);
            data.append(
                "followup_at",
                moment(this.req.follow_up).format("YYYY-MM-DD")
            );
            data.append("status", this.req.status);
            if (this.req.activity == "canvasing") {
                if (this.req.attachment) {
                    data.append("photo", this.req.attachment);
                }
                data.append(
                    "geo_location",
                    this.lat_search + "," + this.lng_search
                );
                data.append("geo_address", this.geo_address);
            }
            if (this.req.activity == "meeting") {
                data.append("desc", this.req.description);
                data.append("meeting_type", this.req.meeting_type);
            }
            if (this.req.activity == "other") {
                data.append("desc", this.req.description);
            }
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/prospect/create`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_store = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.is_store = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getList() {
            var payload = {
                id: this.$route.params.id,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/candidates`,
                {
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.req.company = res.data.data.list_data[0].company;
                    this.req.pic = res.data.data.list_data[0].pic;
                    this.req.phone = res.data.data.list_data[0].phone;
                    this.req.email = res.data.data.list_data[0].email;
                    this.req.partner_type =
                        res.data.data.list_data[0].partner_type;
                    this.req.note = res.data.data.list_data[0].note;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        uploadPhoto(event) {
            var input = event.target;
            this.req.attachment = input.files[0];
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.attachment = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
        processLocationChanged(ev) {
            this.lat_search = ev.geometry.location.toJSON().lat;
            this.lng_search = ev.geometry.location.toJSON().lng;
            this.geo_address = ev.formatted_address;
        },
    },
};
</script>

<style scoped>
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    border: dashed;
    border-radius: 10px;
    width: 13vw;
    height: 150px;
    padding: 55px;
    position: relative;
}
.photo {
    border-radius: 10px;
    cursor: pointer;
    width: 13vw;
    height: 150px;
    object-fit: cover;
    border: dashed;
    color: #dee2e6;
}
.btn-lg {
    width: 150px;
}
input[type="file"] {
    display: none;
}
.upload__btn p i {
    font-size: 30px;
}
</style>
