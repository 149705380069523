var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"padding-container"},[_vm._m(0),_c('div',{staticClass:"box mt-4"},[_vm._m(1),_c('div',{staticClass:"row mt-5 m-mt-2"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Nomor Resi")]),_c('div',{staticClass:"form-box"},[_vm._v(" "+_vm._s(_vm.req.receipt_code)+" ")])]),_c('div',{staticClass:"col-md-6 m-mt-1"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Rute Sewa Truk")]),_c('div',{staticClass:"form-box"},[_vm._v(_vm._s(_vm.req.route))])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Kendaraan")]),_c('div',{staticClass:"form-box capitalize"},[_vm._v(" "+_vm._s((_vm.req.type_fleet || "").split("-").join(" "))+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Status Pesanan Terkini")]),_c('div',{staticClass:"form-box"},[_vm._v(" "+_vm._s(_vm.req.status_label)+" ")])]),_c('div',{staticClass:"col-md-12 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Status Pesanan")]),_c('multiselect',{staticClass:"multi-form-custom",attrs:{"options":_vm.options,"label":"label","track-by":"value","placeholder":"Pilih Status Pesanan"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('div',{staticClass:"col-md-12 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Lokasi Driver")]),_c('GmapMap',{ref:"mapRef",staticStyle:{"height":"300px"},attrs:{"center":{
                        lat: -6.233035800000001,
                        lng: 106.8397427,
                    },"zoom":16,"map-type-id":"terrain"}},[_c('div',{staticClass:"row justify-content-center pt-4"},[_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"position-relative"},[_c('GmapAutocomplete',{staticClass:"form-control search-google",attrs:{"value":_vm.address,"placeholder":"Cari Lokasi"},on:{"place_changed":_vm.processLocationChanged}}),_c('span',{staticClass:"search-icon map"},[_c('i',{staticClass:"fa fa-search"})])],1)])]),_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,ref:"marker",refInFor:true,attrs:{"position":m,"clickable":true,"draggable":true},on:{"dragend":_vm.movePlace,"click":function($event){_vm.center = m}}})})],2),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"form-box"},[_vm._v(" "+_vm._s(_vm.address ? _vm.address : "Lokasi driver")+" ")])])],1),(
                    _vm.status?.value == 'loading' ||
                    _vm.status?.value == 'unloading'
                )?_c('div',{staticClass:"col-md-12 mt-3"},[_c('label',{staticClass:"fw-bold"},[_vm._v("Foto Barang")]),_c('div',{staticClass:"text-gray mb-2"},[_vm._v(" Upload foto barang maks 5MB ")]),_c('label',{class:!_vm.attachment ? 'upload__btn' : '',attrs:{"type":"file"}},[(_vm.attachment)?_c('img',{staticClass:"photo",attrs:{"src":_vm.attachmentPictureUrl}}):_c('p',{staticClass:"mb-0"},[_c('i',{staticClass:"fa fa-plus"})]),_c('input',{ref:"file",staticClass:"cursor-pointer",attrs:{"id":"upload","type":"file","name":"file"},on:{"change":_vm.uploadPhoto}})])]):_vm._e()])]),_c('div',{staticClass:"d-flex mt-4"},[_c('div',{staticClass:"ms-auto"},[_c('a',{staticClass:"btn btn-outline-black btn-lg px-5 me-3",attrs:{"href":"/trawltruck/ho/order"}},[_vm._v("Batal")]),(_vm.is_update)?_c('button',{staticClass:"btn btn-green btn-lg px-5",attrs:{"type":"button","disabled":""}},[_vm._m(2)]):_c('button',{staticClass:"btn btn-green btn-lg px-5",attrs:{"disabled":_vm.status == '' || _vm.lat_move == ''},on:{"click":function($event){return _vm.updateData()}}},[_vm._v(" Update ")])])]),_c('div',{staticClass:"modal-vue"},[(_vm.modalSuccess)?_c('div',{staticClass:"overlay"}):_vm._e(),(_vm.modalSuccess)?_c('div',{staticClass:"modal-body-chat vsm"},[_vm._m(3),_vm._m(4)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fw-bold"},[_c('a',{staticClass:"text-gray-dashboard size-16",attrs:{"href":"/trawltruck/ho/order"}},[_c('i',{staticClass:"fa fa-angle-left me-2"}),_vm._v(" Kembali ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('h3',{staticClass:"fw-bold mb-0"},[_vm._v("Ubah Status Pesanan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"spinner-border spinner-border-sm me-2",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" Update ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 text-center"},[_c('img',{attrs:{"src":require("../../assets/dashboard/checklis.png"),"width":"80","alt":""}}),_c('h4',{staticClass:"mb-0 fw-bold mt-4"},[_vm._v(" Status Pesanan Berhasil Disimpan! ")]),_c('div',{staticClass:"mt-2"},[_vm._v(" Selamat! Anda berhasil merubah status pesanan. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('a',{staticClass:"btn btn-green w-100 btn-lg",attrs:{"href":"/trawltruck/ho/order"}},[_vm._v(" Ok ")])])
}]

export { render, staticRenderFns }