<template>
  <div class="padding-container">
    <div class="d-flex bd-highlight">
      <div class="flex-grow-1 bd-highlight">
        <h4 class="fw-bold" @click="isDetail = false">Data Barang</h4>
      </div>
    </div>
    <div class="box">
      <div class="d-flex justify-content-between">
        <div class="">
          <h4 class="fw-light">Kode Mitra</h4>
          <h4 class="fw-bold">{{ $route.params.id }}</h4>
        </div>
        <div class="">
          <h4 class="fw-light">Mitra</h4>
          <h4 class="fw-bold">Mitra Business</h4>
        </div>
        <div class="">
          <h4 class="fw-light">Jumlah Resi</h4>
          <h4 class="fw-bold">{{ totalReceipt }}</h4>
        </div>
      </div>
    </div>

    <div class="box mt-3">
      <div class="d-flex justify-content-end">
        <div class="position-relative">
          <input v-model="noReceipt" @keyup.enter="searchReceipt" type="text" style="width: 150px" class="form-control" placeholder="Search No Resi" />
          <span class="search-icon">
            <i class="fa fa-search"></i>
          </span>
        </div>
      </div>
      <div class="mt-4" v-if="is_my_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">Nomor Resi</div>
            <div class="d-table-cell">Jenis Koli</div>
            <div class="d-table-cell">Total Berat</div>
            <div class="d-table-cell">Status Barang</div>
          </div>
          <div class="d-table-row" v-for="(item, name, index) in data" :key="index">
            <div class="d-table-cell">{{ item.no }}</div>
            <div class="d-table-cell">{{ item.receipt_code }}</div>
            <div class="d-table-cell">{{ item.qty_count }} Koli</div>
            <div class="d-table-cell">{{ item.weight_amount }} Kg</div>
            <div class="d-table-cell">{{ item.status }}</div>
          </div>
        </div>
        <div v-if="data.length == 0" class="text-center mt-section">
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
    </div>
    <div class="overflow-auto mt-3">
      <b-pagination-nav v-if="data.length > 0" v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav>
    </div>
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";

export default {
  components: {
    ListLoader,
  },
  name: "WarehouseMBDetail",
  data() {
    return {
      showModal: false,
      itemDetail: [
        { no: 1, no_resi: "TRB65784394", jumlah_koli: "500 koli", total_berat: "100kg", status: "Ukur Timbang" },
        { no: 2, no_resi: "TRB65784395", jumlah_koli: "500 koli", total_berat: "100kg", status: "Ukur Timbang" },
        { no: 3, no_resi: "TRB65784396", jumlah_koli: "500 koli", total_berat: "100kg", status: "Ukur Timbang" },
        { no: 4, no_resi: "TRB65784397", jumlah_koli: "500 koli", total_berat: "100kg", status: "Ukur Timbang" },
      ],
      items: [
        { no: 1, kode_mitra: "MB-JKT-03", wilayah: "Jln. Rasamala", kota: "Jakarta", total_koli: "100 kilo", total_berat: "500 kg" },
        { no: 2, kode_mitra: "MB-JKT-03", wilayah: "Jln. Rasamala", kota: "Jakarta", total_koli: "100 kilo", total_berat: "500 kg" },
        { no: 3, kode_mitra: "MB-JKT-03", wilayah: "Jln. Rasamala", kota: "Jakarta", total_koli: "100 kilo", total_berat: "500 kg" },
        { no: 4, kode_mitra: "MB-JKT-03", wilayah: "Jln. Rasamala", kota: "Jakarta", total_koli: "100 kilo", total_berat: "500 kg" },
      ],
      isDetail: false,
      currentPage: 0,
      totalPage: 0,
      limit: 10,
      data: [],
      totalReceipt: 0,
      is_my_list_ready: false,
      noReceipt: "",
    };
  },
  created() {
    this.getDetailData();
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
  },
  methods: {
    searchReceipt() {
      this.currentPage = 0;
      this.getDetailData();
    },
    changePage() {
      this.getDetailData();
    },
    getDetailData() {
      this.is_my_list_ready = true;

      Api.get(`${process.env.VUE_APP_SERVICE_URL}/admin/fno/warehouse/detail`, {
        params: { page: this.currentPage == 0 || this.currentPage == null ? 0 : this.currentPage - 1, limit: this.limit, partner_code: this.$route.params.id, search: this.noReceipt },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data = res.data.data.list_data;
          this.totalReceipt = res.data.data.total_data;
          this.totalPage = res.data.data.total_page;
          this.is_my_list_ready = false;
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          console.log(err);
          this.is_my_list_ready = false;
        });
    },
    currency(number) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(number);
    },
  },
};
</script>

<style scoped>
.scroll {
  height: 500px;
  overflow-y: scroll;
}
.row.foto {
  --bs-gutter-x: 0rem !important;
}
</style>
