<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="d-flex align-items-center bd-highlight">
            <div class="flex-grow-1 bd-highlight">
                <h3 class="fw-bold">
                    Tambah Kendaraan
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 mt-4">
                <div class="box">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="mb-2 fw-bold">Nama Kendaraan</label>
                            <input type="text" class="form-control" placeholder="Masukkan nama kendaraan" v-model="req.registration_name">
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="mb-2 fw-bold">Jenis Kendaraan</label>
                            <select name="" class="form-control form-select" v-model="req.type">
                                <option value="">Pilih jenis kendaraan</option>
                                <option value="towing">Towing</option>
                                <option value="self_driver">Self Driver</option>
                                <option value="car_carrier">Car Carrier</option>
                            </select>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="mb-2 fw-bold">Status Kendaraan</label>
                            <select name="" class="form-control form-select" v-model="req.is_verified">
                                <option :value="false">Tidak Aktif</option>
                                <option :value="true">Aktif</option>
                            </select>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="mb-2 fw-bold">Nomor Polisi</label>
                            <input type="text" class="form-control" placeholder="Masukkan nomor polisi" v-model="req.registration_number">
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="mb-2 fw-bold">Tahun Kendaraan</label>
                            <input type="number" class="form-control" placeholder="Masukkan tahun kendaraan" v-model="req.production_year">
                        </div>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold">Foto Kendaraan</label>
                            <div class="row">
                                <div class="col-md-3 mt-3" v-for="(f, index) in images" :key="index">
                                    <label class="upload__btn no-border">
                                        <div class="close-icon-order" @click.prevent="removeImage(index, $event)">
                                            <i class="fa fa-trash"></i>
                                        </div>
                                        <input type="file" :class="`images[] upload__inputfile`" @change="previewImage(index, $event)">
                                        <div :class="'images[' + index + ']-preview'">
                                            <p class="mb-0" style="color: #adb5bd;">
                                                <i class="fa fa-cloud-upload"></i>
                                            </p>
                                        </div>
                                    </label>
                                </div>
                                <div class="col-md-3 mt-3">
                                    <label class="upload__btn" @click.prevent="addNewImage()">
                                        <p class="mb-0" style="color: #adb5bd;">
                                            <i class="fa fa-plus"></i>
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <div class="box no-shadow bc-light-green p-3">
                                Foto kendaraan anda dari Samping, Depan, Belakang dan juga STNK mobil anda
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="mb-2 fw-bold">Foto STNK</label>
                            <div class="image-view-1" :style="{'background-image': 'url(' + (req.image_stnk) + ')'}">
                                <div class="mt-4 ml-4">
                                    <label for="stnk" class="upload-button single-image"> 
                                        <i class="fa fa-upload"></i>
                                        <input type="file" id="stnk" @change="uploadSTNK()">
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <label class="mb-2 fw-bold">Catatan</label>
                            <textarea name="" id="" cols="30" rows="6" class="form-control" placeholder="Masukkan catatan" v-model="req.description"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-4">
                <div class="box" id="sticky">
                    <div class="">
                        <button class="btn btn-green btn-lg w-100" type="button" disabled v-if="is_save">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Simpan
                        </button>
                        <button class="btn btn-green btn-lg w-100" @click="storeData()" v-else>Simpan</button>
                    </div>
                    <div class="mt-3">
                        <a href="/trawlcarrier/partner/fleet" class="btn btn-outline-black btn-lg w-100">Batal</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import Api from "../../api/Api";

export default {
    components: {
    },
    name: "vehicleList",
    data() {
        return {
            req: {
                image_stnk: '',
                registration_name: '',
                type: '',
                registration_number: '',
                production_year: '',
                description: '',
                is_verified: false,
            },
            modalAddVehicle: false,
            is_changing_stnk: false,
            images: [],
            maxImages: 4,
            addImage: 'button.add-image',
            is_save: false
        };
    },
    created() {},
    mounted() {
        $(function(){
            createSticky($("#sticky"));
        });
        function createSticky(sticky) {
            if (typeof sticky !== "undefined") {
                var	pos = sticky.offset().top + 20,
                win = $(window);
                win.on("scroll", function() {
                    win.scrollTop() >= pos ? sticky.addClass("fixed-sticky") : sticky.removeClass("fixed-sticky");      
                });			
            }
        }
    },
    methods: {
        uploadSTNK() {
            var stnk = new FileReader();
            stnk.onload = function (e) {
                $('.image-view-1').css('background-image', 'url('+ e.target.result + ')')
            };
            stnk.readAsDataURL($('#stnk')[0].files[0]);
            this.is_changing_stnk = true
        },
        addNewImage: function() {
            var n = this.maxImages || -1;
            if (n && this.images.length < n) {
                this.images.push('');
            }
            this.checkImages();
        },
        removeImage: function(index) {
            this.images.splice(index, 1);
            this.checkImages();
        },
        checkImages: function() {
            var n = this.maxImages || -1;
            if (n && this.images.length >= n) {
                $(this.addImage, this.el).prop('disabled', true);
            } else {
                $(this.addImage, this.el).prop('disabled', false);
            }
        },
        previewImage: function(index, e) {
            var r = new FileReader(),
            f = e.target.files[0];

            r.addEventListener('load', function() {
                $('[class~="images[' + index + ']-preview"]', this.el).html(
                '<div class="image-preview" style="background-image: url('+ r.result +')"></div>'
                );
            }, false);

            if (f) {
                r.readAsDataURL(f);
            }
        },
        storeData(){
            var stnk = $('#stnk')[0].files[0]
            var data = new FormData()

            data.append('registration_name', this.req.registration_name)
            data.append('registration_number', this.req.registration_number)
            data.append('production_year', this.req.production_year)
            data.append('type', this.req.type)
            data.append('is_verified', this.req.is_verified)
            data.append('description', this.req.description)

            if(this.is_changing_stnk) {
                data.append('image_stnk',stnk)
            }
            $('[class~="images[]"]', this.el).each(function(i) {
                if (i > this.maxImages - 1) {
                return;
                }
                data.append('image[' + i + ']', this.files[0]);
            });

            this.is_save = true

            Api.post(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/vehicle/create`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            })
            .then((res) => {
                this.is_save = false
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Success',
                    text: 'Data berhasil dibuat'
                });
                this.is_changing_stnk = false
                setTimeout(() => {
                    window.location.href = "/trawlcarrier/partner/fleet"
                }, 1000);
                console.log(res)
            })
            .catch((err) => {
                this.is_save = false
                this.$notify({
                    group: 'foo',
                    type: 'error',
                    title: 'Error',
                    text: err.response.data.message
                });
            })
        }
    },
};
</script>

<style scoped>
    .upload__inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    .upload__btn {
        color: #dee2e6;
        text-align: center;
        cursor: pointer;
        background-color: #f8f9fa;
        border-style: dashed;
        border-radius: 10px;
        width: 100%;
        height: 130px;
        padding: 40px;
        position: relative;
    }
    .no-border{
        border: unset;
    }
    .upload__btn p{
        font-size: 30px;
    }
    .upload__img-wrap {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }
    .upload__img-box {
        width: 200px;
        padding: 0 10px;
        margin-bottom: 12px;
    }
    .upload__img-close {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 10px;
        right: 10px;
        text-align: center;
        line-height: 24px;
        z-index: 1;
        cursor: pointer;
    }
    .upload__img-close:after {
        content: "✖";
        font-size: 14px;
        color: white;
    }

    .img-bg {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        padding-bottom: 100%;
    }
    .image-preview-order{
        width: 100%;
        height: 130px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;
        position: relative;
    }
    .close-icon-order{
        position: absolute;
        right: 8px;
        top: 8px;
        color: #E60013;
        font-size: 14px;
        padding: 2px;
        background: #fff;
        border-radius: 50px;
        text-align: center;
        width: 25px;
        height: 25px;
        cursor: pointer;
        z-index: 9;
    }
</style>