<template>
  <div class="padding-container">
    <div class="box">
      <div class="d-flex bd-highlight">
        <div class="flex-grow-1 bd-highlight">
          <h4 class="fw-bold">Pencairan Saldo</h4>
        </div>
      </div>
      <div class="mt-4" v-if="is_my_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">Order id</div>
            <div class="d-table-cell">Type</div>
            <div class="d-table-cell">Pembayaran</div>
            <div class="d-table-cell">Komisi</div>
          </div>
          <div class="d-table-row" v-for="(item, index) in data" :key="index">
            <div class="d-table-cell">{{ index + 1 }}</div>
            <div class="d-table-cell">{{ item.package_id }}</div>
            <div class="d-table-cell">{{ item.profit_type }}</div>
            <div class="d-table-cell">{{ currency(item.service_price) }}</div>
            <div class="d-table-cell">{{ currency(item.income) }}</div>
          </div>
        </div>
        <div v-if="data.length == 0" class="text-center mt-section">
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
      <div class="overflow-auto mt-3">
        <b-pagination-nav v-if="data.length > 0" v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";

export default {
  components: {
    ListLoader,
  },
  name: "IncomeBusinessPartner",
  data() {
    return {
      showModal: false,
      items: [
        { nama: "Ahmad", pencairan_saldo: "Rp. 65.000.000,00", periode: "Bulan Januari", status: "Transfered" },
        { nama: "Budi", pencairan_saldo: "Rp. 65.000.000,00", periode: "Bulan Januari", status: "Waiting" },
        { nama: "Siti", pencairan_saldo: "Rp. 65.000.000,00", periode: "Bulan Januari", status: "On Going" },
        { nama: "Elsa", pencairan_saldo: "Rp. 65.000.000,00", periode: "Bulan Januari", status: "On Going" },
      ],
      itemsDetail: [
        { no: 1, order_id: 98349, type: "Koordinator", pembayaran: "Rp. 500.000", komisi: "Rp. 300.000" },
        { no: 2, order_id: 98349, type: "Mitra", pembayaran: "Rp. 500.000", komisi: "Rp. 300.000" },
        { no: 3, order_id: 98349, type: "Mitra", pembayaran: "ORp. 500.000", komisi: "Rp. 300.000" },
        { no: 4, order_id: 98349, type: "Koordinator", pembayaran: "Rp. 500.000", komisi: "Rp. 300.000" },
      ],
      isDetail: false,
      data: [],
      is_my_list_ready: false,
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
  },
  methods: {
    getData() {
      this.is_my_list_ready = true;
      Api.get(`${process.env.VUE_APP_AE_URL}/agent/disbursementDetail`, {
        params: { user_id: this.$route.params.user_id, month: this.$route.params.periode },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data = res.data.data;
          this.is_my_list_ready = false;
        })
        .catch(function (err) {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          console.error(err);
          this.is_my_list_ready = false;
        });
    },
    currency(number) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(number);
    },
  },
};
</script>

<style scoped>
.scroll {
  height: 500px;
  overflow-y: scroll;
}
.row.foto {
  --bs-gutter-x: 0rem !important;
}
</style>
