var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"padding-container"},[_c('div',{staticClass:"box mt-4"},[_vm._m(0),_vm._m(1),_c('table',{staticClass:"table table-bordered mt-4"},[_vm._m(2),_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" 1 ")]),_vm._m(3),_c('td',{staticClass:"text-center"},[_vm._v(" Rp400.000 ")]),_c('td',{staticClass:"text-center"},[_vm._m(4),_c('a',{staticClass:"text-red-dashboard",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.showModalDelete = true}}},[_c('i',{staticClass:"fa fa-trash"})])])])])])]),_c('div',{staticClass:"modal-vue"},[(_vm.showModalDelete)?_c('div',{staticClass:"overlay"}):_vm._e(),(_vm.showModalDelete)?_c('div',{staticClass:"modal-body-chat vsm"},[_vm._m(5),_c('div',{staticClass:"mt-4 row"},[_c('div',{staticClass:"col-md-6"},[_c('a',{staticClass:"btn btn-primary w-100 btn-lg",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.showModalDelete = false}}},[_vm._v(" Batal ")])]),_c('div',{staticClass:"col-md-6"},[_c('a',{staticClass:"btn btn-outline-primary w-100 btn-lg",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.showModalDelete = false}}},[_vm._v(" Ya ")])])])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('h3',{staticClass:"fw-bold"},[_vm._v(" List Jenis Truk ")]),_c('div',{staticClass:"ms-auto"},[_c('a',{staticClass:"btn btn-primary btn-lg px-5",attrs:{"href":"/trawltruck/ho/master/fleet/create"}},[_vm._v(" Tambah Data ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"position-relative"},[_c('input',{staticClass:"form-control form-control-lg",attrs:{"type":"text","placeholder":"Cari data..."}}),_c('span',{staticClass:"search-icon"},[_c('i',{staticClass:"fa fa-search"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"table-light"},[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("No")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Jenis Truk")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Harga/Km")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Action")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('a',{staticClass:"text-dark",attrs:{"href":"/trawltruck/ho/master/fleet/detail/8"}},[_c('u',[_vm._v(" Blind Van ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/trawltruck/ho/master/fleet/edit/3"}},[_c('i',{staticClass:"fa fa-pencil me-3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 text-center"},[_c('img',{attrs:{"src":require("../../assets/dashboard/trash.png"),"width":"80","alt":""}}),_c('h4',{staticClass:"mb-0 fw-bold mt-4"},[_vm._v(" Penghapusan Data? ")]),_c('div',{staticClass:"mt-2"},[_vm._v(" Apakah Anda yakin ingin hapus data ini? ")])])
}]

export { render, staticRenderFns }