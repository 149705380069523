<template>
    <div class="padding-container">
        <h3 class="mb-0 fw-bold">Dashboard TroCarrier Operation</h3>
        <div class="row">
            <div class="col-md-6 mt-4">
                <div class="box no-shadow border-gray">
                    <div class="d-flex align-items-center">
                        <div>
                            <div class="fw-bold size-18">Total Mitra</div>
                            <div class="mt-3">
                                <div class="size-28 fw-bold">
                                    <span class="text-green fw-bold size-28">{{
                                        decimalFormat(
                                            dashboard_data.partner.total
                                        )
                                    }}</span>
                                    Mitra
                                </div>
                            </div>
                        </div>
                        <div class="ms-auto">
                            <img
                                src="../../assets/dashboard/total-mitra.png"
                                class="w-100"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="mt-4 me-5">
                            <div class="text-gray size-16">Aktif</div>
                            <div class="size-16 fw-bold">
                                {{
                                    decimalFormat(dashboard_data.partner.active)
                                }}
                                Mitra
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="text-gray size-16">Tidak Aktif</div>
                            <div class="size-16 fw-bold">
                                {{
                                    decimalFormat(
                                        dashboard_data.partner.not_active
                                    )
                                }}
                                Mitra
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-4">
                <div class="box no-shadow border-gray">
                    <div class="d-flex align-items-center">
                        <div>
                            <div class="fw-bold size-18">Total Driver</div>
                            <div class="mt-3">
                                <div class="size-28 fw-bold">
                                    <span class="text-green fw-bold size-28">{{
                                        decimalFormat(
                                            dashboard_data.driver.total
                                        )
                                    }}</span>
                                    Driver
                                </div>
                            </div>
                        </div>
                        <div class="ms-auto">
                            <img
                                src="../../assets/dashboard/total-driver.png"
                                class="w-100"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="mt-4 me-5">
                            <div class="text-gray size-16">Aktif</div>
                            <div class="size-16 fw-bold">
                                {{
                                    decimalFormat(dashboard_data.driver.active)
                                }}
                                Driver
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="text-gray size-16">Tidak Aktif</div>
                            <div class="size-16 fw-bold">
                                {{
                                    decimalFormat(
                                        dashboard_data.driver.not_active
                                    )
                                }}
                                Driver
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-4">
                <div class="box no-shadow border-gray">
                    <div class="d-flex align-items-center">
                        <div>
                            <div class="fw-bold size-18">Manifest</div>
                            <div class="mt-3">
                                <div class="size-28 fw-bold">
                                    <span class="text-green fw-bold size-28">{{
                                        decimalFormat(
                                            dashboard_data.manifest.total
                                        )
                                    }}</span>
                                    Manifest
                                </div>
                            </div>
                        </div>
                        <div class="ms-auto">
                            <img
                                src="../../assets/dashboard/manifest.png"
                                class="w-100"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="mt-4 me-5">
                            <div class="text-gray size-16">Berjalan</div>
                            <div class="size-16 fw-bold">
                                {{
                                    decimalFormat(
                                        dashboard_data.manifest.on_progress
                                    )
                                }}
                                Manifest
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="text-gray size-16">Selesai</div>
                            <div class="size-16 fw-bold">
                                {{
                                    decimalFormat(
                                        dashboard_data.manifest.finish
                                    )
                                }}
                                Manifest
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-4">
                <div class="box no-shadow border-gray">
                    <div class="d-flex align-items-center">
                        <div>
                            <div class="fw-bold size-18">Jenis Mobil</div>
                            <div class="mt-3">
                                <div class="size-28 fw-bold">
                                    <span class="text-green fw-bold size-28">{{
                                        decimalFormat(dashboard_data.car.total)
                                    }}</span>
                                    Mobil
                                </div>
                            </div>
                        </div>
                        <div class="ms-auto">
                            <img
                                src="../../assets/dashboard/jenis-mobil.png"
                                class="w-100"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="mt-4 me-5">
                            <div class="text-gray size-16">Standar</div>
                            <div class="size-16 fw-bold">
                                {{
                                    decimalFormat(dashboard_data.car.standard)
                                }}
                                Mobil
                            </div>
                        </div>
                        <div class="mt-4 me-5">
                            <div class="text-gray size-16">Sedang</div>
                            <div class="size-16 fw-bold">
                                {{
                                    decimalFormat(dashboard_data.car.medium)
                                }}
                                Mobil
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="text-gray size-16">Premium</div>
                            <div class="size-16 fw-bold">
                                {{
                                    decimalFormat(dashboard_data.car.premium)
                                }}
                                Mobil + Super Car
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 m-mt-2">
                <div class="box mt-4 m-mt-0 no-shadow border-gray">
                    <div class="d-flex align-items-center">
                        <div>
                            <div class="mb-0 fw-bold size-16 capitalize">
                                List Order
                            </div>
                        </div>
                        <div class="ms-auto">
                            <a
                                href="/trawlcarrier/ho/order"
                                class="text-green size-16"
                            >
                                Lihat Semua
                            </a>
                        </div>
                    </div>
                    <div
                        :class="{
                            '': true,
                            'scroll-dashboard-trawlcarrier-operation':
                                dashboard_data.order.length >= 3,
                        }"
                    >
                        <hr />
                        <template v-if="dashboard_data.order.length > 0">
                            <template
                                v-for="(data, index) in dashboard_data.order"
                            >
                                <div :key="index">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <div class="fw-bold size-16">
                                                {{ data.name }}
                                            </div>
                                            <div class="text-gray">
                                                {{ data.receipt_code }}
                                            </div>
                                        </div>
                                        <div class="ms-auto">
                                            <div
                                                class="fw-bold size-16 text-green"
                                            >
                                                {{ currencyFormat(data.price) }}
                                            </div>
                                            <div class="text-gray">
                                                {{
                                                    moment(
                                                        data.created_at
                                                    ).format("DD MMMM YYYY")
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <div class="text-center mt-4">
                                <img
                                    src="../../assets/no-data.png"
                                    width="70"
                                    alt=""
                                />
                                <div class="fw-bold size-16 text-gray mt-3">
                                    Tidak ada data
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-md-6 m-mt-2">
                <div class="box mt-4 m-mt-0 no-shadow border-gray">
                    <div class="mb-0 fw-bold size-18 capitalize">
                        Wilayah Tujuan Pengiriman
                    </div>
                    <hr />
                    <div class="mt-3">
                        <apexcharts
                            type="pie"
                            width="350"
                            :options="chartOptions"
                            :series="series"
                        ></apexcharts>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
import moment from "moment";
import Api from "../../api/Api";
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        apexcharts: VueApexCharts,
    },
    name: "Dashboard-Trawlcarrier-Operation",
    data() {
        return {
            moment: moment,
            date: moment(new Date()).format("MMMM YYYY"),
            series: [],
            chartOptions: {
                chart: {
                    width: 400,
                    type: "pie",
                },
                labels: [],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
            dashboard_data: {
                car: {},
                driver: {},
                manifest: {},
                order: [],
                partner: {},
                region: {},
            },
        };
    },
    created() {
        this.getDatas();
    },
    mounted() {},
    methods: {
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        decimalFormat(num) {
            return (
                "" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getDatas() {
            Api.get(
                `${
                    process.env.VUE_APP_SERVICE_URL
                }/carrier/ho/dashboard/operation?date=${moment(
                    this.date
                ).format("MM-YYYY")}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.dashboard_data = data;

                    this.series = data.region.val;
                    this.chartOptions = {
                        labels: data.region.key,
                        chart: {
                            width: 400,
                            type: "pie",
                        },
                        dataLabels: {
                            enabled: true,
                        },
                        responsive: [
                            {
                                breakpoint: 500,
                                options: {
                                    chart: {
                                        width: 300,
                                    },
                                    legend: {
                                        position: "bottom",
                                    },
                                },
                            },
                        ],
                    };
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style>
.scroll-dashboard-trawlcarrier-operation {
    height: 252px;
    overflow-y: scroll;
}
.apexcharts-legend-marker {
    border-radius: 3px !important;
    height: 14px !important;
    width: 14px !important;
    margin-right: 8px !important;
    top: 2px !important;
}
.apexcharts-pie-label {
    color: red !important;
}
.apexcharts-pie-label {
    font-size: 12px;
}
</style>
