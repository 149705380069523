<template>
    <div class="padding-container">
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">
                    List Asuransi
                </h3>
            </div>
            <div class="row mt-5">
                <div class="col-md-5">
                    <div class="position-relative">
                        <input type="text" class="form-control form-control-lg" placeholder="Cari data..." />
                        <span class="search-icon">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <table class="table table-bordered mt-4">
                <thead class="table-light">
                    <tr>
                        <th class="text-center" scope="col">No</th>
                        <th class="text-center" scope="col">Customers</th>
                        <th class="text-center" scope="col">Vendor</th>
                        <th class="text-center" scope="col">Tanggal</th>
                        <th class="text-center" scope="col">Besaran Asuransi</th>
                        <th class="text-center" scope="col">Rekening</th>
                        <th class="text-center" scope="col">Status</th>
                        <th class="text-center" scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center">
                            1
                        </td>
                        <td class="text-center">
                            <a href="/trawltruck/ho/topup/detail/8" class="text-dark">
                                <u>
                                    Besi Ayatan
                                </u>
                            </a>
                        </td>
                        <td class="text-center">
                            PT. Cahaya Abadi
                        </td>
                        <td class="text-center">
                            30 September 2020
                        </td>
                        <td class="text-center">
                            Rp4.000.000
                        </td>
                        <td class="text-center">
                            Karto - Mandiri - 1232132141241
                        </td>
                        <td class="text-center">
                            <small class="tag yellow mb-0 px-3">Submited</small>
                        </td>
                        <td class="text-center">
                            <a href="/trawltruck/ho/topup/edit/3" class="">
                                <i class="fa fa-pencil"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        props: "",
        name: "ManagementTopup",
        data() {
            return {
            };
        },
        methods: {
        }
    };
</script>

<style scoped>
    th{
        border-color: #E8E8E9;
        padding: 10px !important;
    }
    tr{
        border-color: #E8E8E9;
        padding: 10px !important;
    }
    td{
        padding: 10px !important;
    }
    .table-light{
        background-color: #F6FAFF !important;
        --bs-table-border-color: #F6FAFF !important;
        --bs-table-bg: #F6FAFF !important;
    }
    .text-red-dashboard{
        color: #E60013;
    }
    .search-icon{
        font-size: 16px;
    }
</style>