<template>
    <div class="padding-container">
        <div class="d-flex align-items-center bd-highlight">
            <div class="d-flex align-items-center">
                <div class="me-3">
                    <a
                        href="/trawlcarrier/partner/manifest"
                        class="text-primary"
                    >
                        <i class="fa fa-angle-left size-24 fw-bold"></i>
                    </a>
                </div>
                <h3 class="fw-bold mb-0">Detail Manifest</h3>
            </div>
        </div>
        <div class="box mt-4">
            <template v-if="is_detail_rady">
                <CardLoader />
            </template>
            <template v-else>
                <div class="row">
                    <div class="col-md-12">
                        <span class="tag green size-18">{{
                            detailManifest.manifest
                        }}</span>
                    </div>
                    <div class="col-md-3 mt-3">
                        <div class="box no-shadow border-gray">
                            <h5 class="fw-bold mb-0 text-gray size-16">
                                Rute Manifest
                            </h5>
                            <div
                                class="fw-ekstra-bold size-24 mt-1"
                                style="width: 200px"
                            >
                                <a
                                    href="javascript:void(0)"
                                    class="text-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="
                                        detailManifest.rute_manifest_from +
                                        ' - ' +
                                        detailManifest.rute_manifest_to
                                    "
                                >
                                    <div class="limit-one-line size-20">
                                        {{ detailManifest.rute_manifest_from }}
                                        - {{ detailManifest.rute_manifest_to }}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <div class="box no-shadow border-gray">
                            <h5 class="fw-bold mb-0 text-gray size-16">
                                Tanggal Manifest
                            </h5>
                            <div class="fw-ekstra-bold size-20 mt-1">
                                {{
                                    moment(detailManifest.tanggal).format(
                                        "DD MMMM YYYY"
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <div class="box no-shadow border-gray">
                            <h5 class="fw-bold mb-0 text-gray size-16">
                                Layanan
                            </h5>
                            <div class="fw-ekstra-bold size-20 mt-1 capitalize">
                                {{
                                    (detailManifest.layanan || "")
                                        .split("_")
                                        .join(" ")
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <div class="box no-shadow border-gray">
                            <h5 class="fw-bold mb-0 text-gray size-16">
                                Status
                            </h5>
                            <div class="fw-ekstra-bold size-18 mt-1">
                                <template v-if="detailManifest.status">
                                    <template
                                        v-if="
                                            detailManifest.status == 'accepted'
                                        "
                                    >
                                        Manifest Baru Dibuat
                                    </template>
                                    <template
                                        v-if="
                                            detailManifest.status == 'ontheway'
                                        "
                                    >
                                        Dalam Perjalanan
                                    </template>
                                    <template
                                        v-if="
                                            detailManifest.status == 'in_port'
                                        "
                                    >
                                        Sudah di Pelabuhan Asal
                                    </template>
                                    <template
                                        v-if="
                                            detailManifest.status == 'onboard'
                                        "
                                    >
                                        Sedang Dalam Kapal
                                    </template>
                                    <template
                                        v-if="
                                            detailManifest.status ==
                                            'arrived_at_pool_transit'
                                        "
                                    >
                                        Sudah di Pool Transit
                                    </template>
                                    <template
                                        v-if="
                                            detailManifest.status ==
                                            'in_port_dest'
                                        "
                                    >
                                        Sudah di Pelabuhan Tujuan
                                    </template>
                                    <template
                                        v-if="
                                            detailManifest.status ==
                                            'in_pool_transit'
                                        "
                                    >
                                        Sedang Menuju Pool Transit
                                    </template>
                                    <template
                                        v-if="
                                            detailManifest.status ==
                                            'in_pool_dest'
                                        "
                                    >
                                        Sedang Menuju Pool Tujuan
                                    </template>
                                    <template
                                        v-if="
                                            detailManifest.status ==
                                            'ontheway_to_dest'
                                        "
                                    >
                                        Sedang Dalam Proses Pengantaran
                                    </template>
                                    <template
                                        v-if="detailManifest.status == 'finish'"
                                    >
                                        Selesai
                                    </template>
                                    <template
                                        v-if="
                                            detailManifest.status == 'canceled'
                                        "
                                    >
                                        Batal
                                    </template>
                                </template>
                                <template v-else> - </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="is_detail_rady">
                <ListLoader />
            </template>
            <template v-else>
                <div class="row mt-4">
                    <div class="col-md-3 mt-3">
                        <div class="position-relative">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Cari id order"
                                v-model="req.keyword"
                            />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-9 mt-3">
                        <div class="pull-right">
                            <button
                                href="javascript:void(0)"
                                class="btn btn-green px-5"
                                @click="showModalUpdateStatus()"
                                :disabled="
                                    detailManifest.status == null ||
                                    detailManifest.status == 'finish' ||
                                    detailManifest.status == 'canceled'
                                "
                            >
                                Update Status Manifest
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    class="modern-table-boxed mt-4"
                    v-if="searchLists.length > 0"
                >
                    <div class="d-table-row header">
                        <div class="d-table-cell">No</div>
                        <div class="d-table-cell">Id Order</div>
                        <div class="d-table-cell">Kendaraan</div>
                        <div class="d-table-cell">Pengirim</div>
                        <div class="d-table-cell">Aksi</div>
                    </div>
                    <template v-for="(list, index) in searchLists">
                        <div class="d-table-row" :key="index">
                            <div
                                class="d-table-cell fw-bold"
                                style="width: 20px"
                            >
                                {{ index + 1 }}.
                            </div>
                            <div class="d-table-cell">
                                {{ list.receipt }}
                            </div>
                            <div class="d-table-cell" style="width: 300px">
                                <a
                                    href="javascript:void(0)"
                                    class="text-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="
                                        list.meta.variant +
                                        ' - ' +
                                        list.meta.plate_number +
                                        ' - ' +
                                        'Tahun ' +
                                        list.meta.year
                                    "
                                >
                                    <div class="limit-one-line">
                                        {{ list.meta.variant }} -
                                        {{ list.meta.plate_number }} - Tahun
                                        {{ list.meta.year }}
                                    </div>
                                </a>
                            </div>
                            <div
                                class="d-table-cell capitalize"
                                style="width: 300px"
                            >
                                <a
                                    href="javascript:void(0)"
                                    class="text-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="
                                        list.name + ' - ' + 'Telp ' + list.phone
                                    "
                                >
                                    <div class="limit-one-line">
                                        {{ list.name }} - Telp {{ list.phone }}
                                    </div>
                                </a>
                            </div>
                            <div class="d-table-cell shrink">
                                <template
                                    v-if="
                                        detailManifest.status ==
                                        'ontheway_to_dest'
                                    "
                                >
                                    <button
                                        @click="accept(list.id)"
                                        class="btn btn-green btn-sm px-4"
                                        :disabled="
                                            list.status ==
                                                'waiting_confirm_by_driver' &&
                                            list.service_type != 'door_to_door'
                                        "
                                    >
                                        Tugaskan Driver
                                    </button>
                                </template>
                                <template v-else> - </template>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </template>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="addResi" @click="addResi = false"></div>
            <div class="modal-body-chat sm" v-if="addResi">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">Tambah Resi</div>
                    </div>
                    <div class="bd-highlight">
                        <a
                            href="javascript:void(0)"
                            @click="addResi = false"
                            class="size-20 text-primary"
                        >
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="mt-2">
                        <label class="plan" for="1">
                            <input type="radio" name="transport" id="1" />
                            <div class="plan-content resi p-3">
                                <div class="d-flex align-items-center">
                                    <div class="me-3">
                                        <div
                                            class="user-resi"
                                            :style="{
                                                'background-image':
                                                    'url(' +
                                                    require('../../assets/user.jpg') +
                                                    ')',
                                            }"
                                        ></div>
                                    </div>
                                    <div>
                                        <h6
                                            class="mb-0 fw-bold size-18 capitalize"
                                        >
                                            Muhammad Saleh
                                        </h6>
                                        <div class="mt-1 size-14 capitalize">
                                            <span class="fw-bold"
                                                >TRB44526739</span
                                            >
                                            -
                                            <span class="text-gray"
                                                >Tujuan (Solo) - Towing</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
                <div class="mt-4">
                    <a href="" class="btn btn-green px-5 btn-md me-3">
                        Tambah
                    </a>
                    <a
                        href="javascript:void(0)"
                        @click="addResi = false"
                        class="btn btn-outline-black px-5 btn-md"
                    >
                        Batal
                    </a>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="updateStatusModal"></div>
            <div class="modal-body-chat sm" v-if="updateStatusModal">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            Perbarui Status Manifest
                        </div>
                    </div>
                    <div class="bd-highlight">
                        <a
                            href="javascript:void(0)"
                            @click="updateStatusModal = false"
                            class="size-20 text-primary"
                        >
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div
                    class="mt-4"
                    :class="{
                        '': true,
                        scroll:
                            this.histories.length > 1 ||
                            req.status == 'in_pool_transit' ||
                            req.status == 'in_pool_dest' ||
                            req.status == 'in_port_dest',
                    }"
                >
                    <div class="mt-2">
                        <label class="fw-bold mb-2">Status</label>
                        <select
                            class="form-control form-select"
                            v-model="req.status"
                        >
                            <option value="">Pilih status</option>
                            <!-- <option value="accepted">Manifest Baru Dibuat</option> -->
                            <template
                                v-if="detailManifest.layanan == 'door_to_door'"
                            >
                                <option value="ontheway">
                                    Dalam Perjalanan
                                </option>
                                <option value="onboard">
                                    Sedang Dalam Kapal
                                </option>
                                <option value="in_port_dest">
                                    Sudah di Pelabuhan Tujuan
                                </option>
                                <option value="in_pool_transit">
                                    Sedang Menuju Pool Transit
                                </option>
                                <option value="ontheway_to_dest">
                                    Sedang Dalam Proses Pengantaran
                                </option>
                            </template>
                            <template
                                v-if="detailManifest.layanan == 'port_to_port'"
                            >
                                <option value="in_port">
                                    Sudah di Pelabuhan Asal
                                </option>
                                <option value="onboard">
                                    Sedang Dalam Kapal
                                </option>
                                <option value="in_port_dest">
                                    Sudah di Pelabuhan Tujuan
                                </option>
                            </template>
                            <template
                                v-if="detailManifest.layanan == 'pool_to_pool'"
                            >
                                <option value="in_pool_transit">
                                    Sedang Menuju Pool Transit
                                </option>
                                <option value="in_pool_dest">
                                    Sedang Menuju Pool Tujuan
                                </option>
                            </template>
                        </select>
                    </div>
                    <div
                        class="mt-3"
                        v-if="
                            req.status == 'in_pool_transit' ||
                            req.status == 'in_pool_dest' ||
                            req.status == 'in_port' ||
                            req.status == 'in_port_dest'
                        "
                    >
                        <label class="fw-bold mb-2">
                            <template
                                v-if="
                                    req.status == 'in_port' ||
                                    req.status == 'in_port_dest'
                                "
                            >
                                Petugas
                            </template>
                            <template v-else> Driver </template>
                        </label>
                        <select
                            class="form-control form-select"
                            v-model="req.transporter_id"
                        >
                            <option value="">
                                <template
                                    v-if="
                                        req.status == 'in_port' ||
                                        req.status == 'in_port_dest'
                                    "
                                >
                                    Pilih Petugas
                                </template>
                                <template v-else> Pilih driver </template>
                            </option>
                            <template v-for="driver in drivers">
                                <option
                                    :key="driver.id"
                                    :value="driver.id"
                                    class="capitalize"
                                >
                                    {{ driver.name }}
                                </option>
                            </template>
                        </select>
                    </div>
                    <div class="mt-3">
                        <label class="fw-bold mb-2">Keterangan Tambahan</label>
                        <textarea
                            cols="30"
                            rows="5"
                            class="form-control"
                            placeholder="Masukkan keterangan tambahan"
                            v-model="req.description"
                        ></textarea>
                    </div>
                    <div>
                        <div
                            class="mt-4"
                            v-if="
                                req.status == 'in_pool_transit' ||
                                req.status == 'in_pool_dest' ||
                                req.status == 'in_port_dest'
                            "
                        >
                            <label class="fw-bold mb-3">Pilih Lokasi</label>
                            <GmapMap
                                ref="mapRef"
                                :center="{
                                    lat: -6.233035800000001,
                                    lng: 106.8397427,
                                }"
                                :zoom="16"
                                map-type-id="terrain"
                                style="height: 300px"
                            >
                                <div class="row justify-content-center pt-4">
                                    <div class="col-md-9">
                                        <div class="position-relative">
                                            <GmapAutocomplete
                                                @place_changed="
                                                    processLocationChanged
                                                "
                                                placeholder="Pilih lokasi anda"
                                                class="form-control search-google"
                                            ></GmapAutocomplete>
                                            <span class="search-icon map">
                                                <i class="fa fa-search"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <GmapMarker
                                    :key="index"
                                    v-for="(m, index) in markers"
                                    ref="marker"
                                    :position="m"
                                    :clickable="true"
                                    :draggable="true"
                                    @dragend="movePlace"
                                    @click="center = m"
                                >
                                </GmapMarker>
                            </GmapMap>
                            <div class="mt-4">
                                <label class="fw-bold mb-2 size-16"
                                    >Alamat Tujuan</label
                                >
                                <div v-if="alamat">
                                    {{ alamat }}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="fw-ekstra-bold size-18">
                            List History Manifest
                        </div>
                        <template v-if="histories.length > 0">
                            <div class="timeline block mt-3">
                                <template v-for="(history, index) in histories">
                                    <div class="tl-item" :key="index">
                                        <div class="tl-dot line-gray"></div>
                                        <div class="tl-content">
                                            <div class="fw-bold size-16">
                                                <!-- <template v-if="history.status == 'accepted'">
                                                    Manifest Baru Dibuat
                                                </template> -->
                                                <template
                                                    v-if="
                                                        history.status ==
                                                        'ontheway'
                                                    "
                                                >
                                                    Dalam Perjalanan
                                                </template>
                                                <template
                                                    v-if="
                                                        history.status ==
                                                        'in_port'
                                                    "
                                                >
                                                    Sudah di Pelabuhan Asal
                                                </template>
                                                <template
                                                    v-if="
                                                        history.status ==
                                                        'onboard'
                                                    "
                                                >
                                                    Sedang Dalam Kapal
                                                </template>
                                                <template
                                                    v-if="
                                                        history.status ==
                                                        'in_port_dest'
                                                    "
                                                >
                                                    Sudah di Pelabuhan Tujuan
                                                </template>
                                                <template
                                                    v-if="
                                                        history.status ==
                                                        'in_pool_transit'
                                                    "
                                                >
                                                    Sedang Menuju Pool Transit
                                                </template>
                                                <template
                                                    v-if="
                                                        history.status ==
                                                        'in_pool_dest'
                                                    "
                                                >
                                                    Sedang Menuju Pool Tujuan
                                                </template>
                                                <template
                                                    v-if="
                                                        history.status ==
                                                        'ontheway_to_dest'
                                                    "
                                                >
                                                    Sedang Dalam Proses
                                                    Pengantaran
                                                </template>
                                                <template
                                                    v-if="
                                                        history.status ==
                                                        'finish'
                                                    "
                                                >
                                                    Selesai
                                                </template>
                                                <template
                                                    v-if="
                                                        history.status ==
                                                        'canceled'
                                                    "
                                                >
                                                    Batal
                                                </template>
                                            </div>
                                            <div
                                                class="tl-date mt-1 size-14 text-gray capitalize"
                                                v-if="history.transporter"
                                            >
                                                {{ history.transporter }}
                                            </div>
                                            <div
                                                class="tl-date mt-1 size-14 text-gray"
                                            >
                                                {{
                                                    moment(
                                                        history.created_at
                                                    ).format(
                                                        "DD MMMM YYYY[,] hh.mm"
                                                    )
                                                }}
                                            </div>
                                            <div
                                                class="mt-2 size-14"
                                                v-if="history.description"
                                            >
                                                {{ history.description }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div class="tl-item">
                                    <div class="line-gray"></div>
                                    <div class="tl-content"></div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div
                                class="mt-3 box no-shadow no-border bc-gray text-center"
                            >
                                <h5 class="mb-0 fw-bold text-gray">
                                    Tidak ada history
                                </h5>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="mt-4">
                    <button
                        class="btn btn-green px-5 btn-md me-3 btn-lg"
                        type="button"
                        disabled
                        v-if="is_status_store"
                    >
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">Loading...</span>
                        Simpan
                    </button>
                    <button
                        class="btn btn-green px-5 btn-md me-3 btn-lg"
                        @click="updateStatus()"
                        v-else
                    >
                        Simpan
                    </button>
                    <a
                        href="javascript:void(0)"
                        @click="updateStatusModal = false"
                        class="btn btn-outline-black px-5 btn-lg"
                    >
                        Batal
                    </a>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="showModalAccept"></div>
            <div class="modal-body-chat lg" v-if="showModalAccept">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-22 fw-ekstra-bold">
                            Tugaskan Driver
                        </div>
                    </div>
                    <div class="bd-highlight">
                        <a
                            href="javascript:void(0)"
                            @click="showModalAccept = false"
                            class="size-20 text-primary"
                        >
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div class="scroll mt-4">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="box no-shadow border-gray">
                                <span class="tag green">
                                    Tanggal Order :
                                    {{
                                        moment(detailOrder.created_at).format(
                                            "DD MMMM YYYY"
                                        )
                                    }}
                                </span>
                                <h5 class="mt-4 mb-0 fw-bold">
                                    <img
                                        src="../../assets/pengirim.png"
                                        width="35"
                                        alt=""
                                    />
                                    Pengirim
                                </h5>
                                <div class="mt-3 fw-medium">
                                    {{ detailOrder.origin.name }}
                                </div>
                                <div class="mt-2 fw-medium">
                                    {{ detailOrder.origin.phone }}
                                </div>
                                <div class="fw-medium mt-2">
                                    {{ detailOrder.origin.address }}
                                </div>
                                <div class="line"></div>
                                <h5 class="mt-3 mb-0 fw-bold">
                                    <img
                                        src="../../assets/pengirim.png"
                                        width="35"
                                        alt=""
                                    />
                                    Penerima
                                </h5>
                                <div class="mt-3 fw-medium">
                                    {{ detailOrder.destination.name }}
                                </div>
                                <div class="mt-2 fw-medium">
                                    {{ detailOrder.destination.phone }}
                                </div>
                                <div class="fw-medium mt-2">
                                    {{ detailOrder.destination.address }}
                                </div>
                            </div>
                            <h5 class="fw-bold mt-5">Mobil</h5>
                            <table class="mt-3">
                                <tr class="p-3">
                                    <td style="width: 120px">Brand Mobil</td>
                                    <td style="width: 30px">:</td>
                                    <td class="fw-medium">
                                        {{ detailOrder.meta.brand }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tipe Mobil</td>
                                    <td style="width: 30px">:</td>
                                    <td class="fw-medium">
                                        {{ detailOrder.meta.variant }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Informasi Mobil</td>
                                    <td style="width: 30px">:</td>
                                    <td class="fw-medium">
                                        {{ detailOrder.meta.note }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Asuransi</td>
                                    <td style="width: 30px">:</td>
                                    <td class="fw-medium">
                                        {{
                                            currencyFormat(
                                                detailOrder.meta.insurance
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tipe Pengiriman</td>
                                    <td style="width: 30px">:</td>
                                    <td class="fw-medium capitalize">
                                        {{
                                            (detailOrder.service_type || "")
                                                .split("_")
                                                .join(" ")
                                        }}
                                    </td>
                                </tr>
                            </table>
                            <h5 class="fw-bold mt-5">Photo Terlampir</h5>
                            <div class="row">
                                <template
                                    v-if="detailOrder.meta.images.length > 0"
                                >
                                    <template
                                        v-for="(image, index) in detailOrder
                                            .meta.images"
                                    >
                                        <div class="col-md-3 mt-2" :key="index">
                                            <div
                                                class="order-photo"
                                                :style="{
                                                    'background-image':
                                                        'url(' +
                                                        image.image +
                                                        ')',
                                                }"
                                            ></div>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="col-md-3 mt-2">
                                        <div
                                            class="order-photo"
                                            :style="{
                                                'background-image':
                                                    'url(' +
                                                    require('../../assets/no-photo.png') +
                                                    ')',
                                            }"
                                        ></div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <h5 class="mb-0 fw-bold">Tugaskan Driver</h5>
                            <div class="mb-3">
                                <div class="search-form-gl w-100">
                                    <input
                                        type="text"
                                        placeholder="Cari driver"
                                        class="search-form-gl-input"
                                        v-model="req.driver_name"
                                    />
                                    <i class="fa fa-search icon"></i>
                                </div>
                            </div>
                            <template v-if="searchDriverList.length > 0">
                                <template
                                    v-for="(driver, index) in searchDriverList"
                                >
                                    <div class="mt-2" :key="index">
                                        <label class="plan" :for="driver.id">
                                            <input
                                                type="radio"
                                                name="transport"
                                                :id="driver.id"
                                                :value="driver"
                                                v-model="req.driver"
                                            />
                                            <div class="plan-content p-3">
                                                <h6
                                                    class="mb-0 fw-bold size-16 capitalize"
                                                >
                                                    {{ driver.name }}
                                                </h6>
                                                <div
                                                    class="mt-1 size-14 capitalize"
                                                >
                                                    {{
                                                        (driver.type || "")
                                                            .split("_")
                                                            .join(" ")
                                                    }}
                                                    -
                                                    {{
                                                        driver.registration_number
                                                    }}
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div class="box no-shadow bc-gray text-center">
                                    <div class="mb-1">
                                        <i class="fa fa-search size-24"></i>
                                    </div>
                                    Driver tidak ditemukan
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="mt-4 row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <button
                            class="btn btn-green w-100 btn-md btn-lg"
                            type="button"
                            disabled
                            v-if="is_assign_driver"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Tugaskan Driver
                        </button>
                        <a
                            href="javascript:void(0)"
                            @click="assignDriver()"
                            class="btn btn-green w-100 btn-md btn-lg"
                            v-else
                        >
                            Tugaskan Driver
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import CardLoader from "../../components/CardLoader.vue";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";
import { gmapApi } from "vue2-google-maps";

export default {
    components: {
        CardLoader,
        ListLoader,
    },
    name: "detailManifest",
    data() {
        return {
            moment: moment,
            addResi: false,
            detailManifest: {
                items: [],
            },
            is_detail_rady: false,
            req: {
                keyword: "",
                status: "",
                description: "",
                transporter_id: "",
                driver: {
                    id: "",
                    type: "",
                },
                driver_name: "",
            },
            user_name: "",
            updateStatusModal: false,
            drivers: [],
            is_status_store: false,
            histories: [],
            map_data: { lat: null, lng: null },
            markers: [
                {
                    lat: -6.233035800000001,
                    lng: 106.8397427,
                },
            ],
            lat_move: "",
            lng_move: "",
            lat_search: "",
            lng_search: "",
            alamat: "",
            showModalAccept: false,
            detailOrder: {
                origin: {},
                destination: {},
                meta: {
                    images: [],
                },
            },
            is_assign_driver: false,
        };
    },
    created() {
        this.getOrderDetail();
        this.getUserLogin();
        this.getDrivers();
        this.getHistoryManifest();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });

        this.$nextTick(() => {
            if (this.$refs.googleMap) {
                this.$refs.mapRef.$mapPromise.then((map) => {
                    map.panTo({ lat: -6.233035800000001, lng: 106.8397427 });
                });
            }
        });
    },
    computed: {
        google: gmapApi,
        searchLists() {
            return this.detailManifest.items.filter((item) => {
                return item.receipt
                    .toLowerCase()
                    .includes(this.req.keyword.toLowerCase());
            });
        },
        searchDriverList() {
            return this.drivers.filter((item) => {
                return item.name
                    .toLowerCase()
                    .includes(this.req.driver_name.toLowerCase());
            });
        },
    },
    methods: {
        currencyFormat(num) {
            return (
                "Rp. " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getOrderDetail() {
            this.is_detail_rady = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/manifest/${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.is_detail_rady = false;
                    this.detailManifest = data;
                })
                .catch((err) => {
                    this.is_detail_rady = false;
                    console.log(err);
                });
        },
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.user_name = res.data.data.name;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getDrivers() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/list-drivers`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var drivers = res.data.data;
                    this.drivers = drivers;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        showModalUpdateStatus() {
            this.updateStatusModal = true;
            this.req.status = "";
            this.req.description = "";
            this.req.transporter_id = "";
            (this.map_data.lat = null), (this.map_data.lng = null);
        },
        updateStatus() {
            var data = {
                status: this.req.status,
                transporter: this.req.transporter_id,
                description: this.req.description,
                lat: this.map_data.lat,
                lon: this.map_data.lng,
            };

            this.is_status_store = true;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/manifest/update/${this.$route.params.id}`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Status berhasil diupdate",
                    });
                    console.log(res);
                    this.updateStatusModal = false;
                    this.is_status_store = false;
                    this.getHistoryManifest();
                    this.getOrderDetail();
                })
                .catch((err) => {
                    this.is_status_store = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getHistoryManifest() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/manifest/history/${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.histories = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        processLocationChanged(ev) {
            this.lat_search = ev.geometry.location.toJSON().lat;
            this.lng_search = ev.geometry.location.toJSON().lng;
            this.map_data = ev.geometry.location.toJSON();

            this.alamat = ev.formatted_address;

            let that = this;
            this.$refs.mapRef.$mapPromise.then((map) => {
                map.panTo(that.map_data);
            });
            this.$refs.marker[0].$markerObject.setPosition(this.map_data);
        },
        movePlace(event) {
            this.lat_move = event.latLng.toJSON().lat;
            this.lng_move = event.latLng.toJSON().lng;
            this.map_data = event.latLng.toJSON();
            console.log(event.domEvent);

            this.decodeLatLong(this.lat_move, this.lng_move);
        },
        decodeLatLong(lat, lng) {
            const geocoder = new this.google.maps.Geocoder();

            geocoder.geocode(
                { location: { lat: lat, lng: lng } },
                (results, status) => {
                    if (status === "OK") {
                        console.log(results[0]);
                        this.alamat = results[0].formatted_address;
                    } else {
                        this.alamat = "";
                        alert("tidak ketemu");
                    }
                }
            );
        },
        accept(id) {
            this.showModalAccept = true;
            this.getOrderDetailOrder(id);
            this.req.driver = {};
        },
        getOrderDetailOrder(id) {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/order/${id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detailOrder = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        assignDriver() {
            var data = {
                status: this.detailManifest.status,
                transporter: this.req.driver.id,
            };
            this.is_assign_driver = true;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/assign/driver/${this.detailOrder.receipt}`,
                data,
                {
                    params: {},
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Driver berhasil ditugaskan",
                    });
                    this.showModalAccept = false;
                    this.getOrderDetail();
                    this.is_assign_driver = false;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_assign_driver = false;

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style>
.scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 450px;
}
.user-resi {
    width: 45px;
    height: 45px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
}
.plan input[type="radio"]:checked + .plan-content.resi {
    background-color: #fff !important;
}
.timeline {
    position: relative;
    padding: 0;
    margin: 0;
}

.p-4 {
    padding: 1.5rem !important;
}

.block,
.card {
    background: #fff;
    border-width: 0;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.tl-item {
    border-radius: 3px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
}

.tl-item > * {
    padding: 10px;
}

.tl-item:last-child .tl-dot:after {
    display: none;
}

.tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-item:last-child .tl-dot:after {
    display: none;
}

.tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, 0.15);
}

.tl-dot:after,
.tl-dot:before {
    content: "";
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
}

.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0;
}

tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, 0.15);
}

.tl-dot:after,
.tl-dot:before {
    content: "";
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
}

.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0;
}

.tl-content p:last-child {
    margin-bottom: 0;
}

.tl-date {
    font-size: 0.85em;
    margin-top: 2px;
    min-width: 100px;
    max-width: 100%;
}
.line-gray {
    border-color: #d5d5d5 !important;
}
</style>
