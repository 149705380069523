<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/trawltruck/ho/order" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="row">
            <div class="col-md-6 mt-4">
                <div class="box">
                    <h3 class="fw-bold">Informasi Pengirim</h3>
                    <table class="w-100 ps-0 fw-bold mt-4" cellpadding="10">
                        <tr class="border-top">
                            <td class="ps-0 w-180">Nama Customer</td>
                            <td class="w-40">:</td>
                            <td>{{ detail?.customer_detail?.name ?? "-" }}</td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Nama Pengirim</td>
                            <td class="w-40">:</td>
                            <td>{{ detail?.origin?.name ?? "-" }}</td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">No. Telepon Pengirim</td>
                            <td class="w-40">:</td>
                            <td>{{ detail?.origin?.phone ?? "-" }}</td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Lokasi Penjemputan</td>
                            <td class="w-40">:</td>
                            <td>{{ detail?.origin?.address ?? "-" }}</td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Detail Lokasi</td>
                            <td class="w-40">:</td>
                            <td>{{ detail?.origin?.note ?? "-" }}</td>
                        </tr>
                    </table>
                    <GmapMap
                        class="mt-3"
                        ref="mapRef"
                        :center="{
                            lat: senders[0].lat,
                            lng: senders[0].lng,
                        }"
                        :zoom="16"
                        map-type-id="terrain"
                        style="height: 300px"
                    >
                        <GmapMarker
                            :key="index"
                            v-for="(m, index) in senders"
                            ref="marker"
                            :position="m"
                        >
                        </GmapMarker>
                    </GmapMap>
                </div>
                <div class="box mt-4">
                    <h3 class="mb-0 fw-bold">Informasi Mitra</h3>
                    <div v-if="detail.status != 'pending'">
                        <table class="w-100 ps-0 fw-bold mt-4" cellpadding="10">
                            <tr class="border-top">
                                <td class="ps-0 w-180">Nama Mitra</td>
                                <td class="w-40">:</td>
                                <td>
                                    <template
                                        v-if="detail?.driver?.partner?.name"
                                    >
                                        {{ detail?.driver?.partner?.name }}
                                    </template>
                                    <template v-else> - </template>
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="ps-0 w-180">Kode Mitra</td>
                                <td class="w-40">:</td>
                                <td>
                                    <template
                                        v-if="detail?.driver?.partner?.code"
                                    >
                                        {{ detail?.driver?.partner?.code }}
                                    </template>
                                    <template v-else> - </template>
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="ps-0 w-180">Berikan Uang jalan</td>
                                <td class="w-40">:</td>
                                <td>
                                    <small
                                        class="tag red-solid mb-0 px-4 radius"
                                        v-if="
                                            detail?.transport_cost == 'no_cost'
                                        "
                                    >
                                        Tidak Ada
                                    </small>
                                    <small
                                        class="tag green-solid mb-0 px-4 radius"
                                        v-if="
                                            detail?.transport_cost == 'success'
                                        "
                                    >
                                        Terpenuhi
                                    </small>
                                    <small
                                        class="tag yellow-solid mb-0 px-4 radius"
                                        v-if="
                                            detail?.transport_cost == 'pending'
                                        "
                                    >
                                        Menunggu
                                    </small>
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="ps-0 w-180">Nama Driver</td>
                                <td class="w-40">:</td>
                                <td>
                                    <p v-if="detail?.driver != null">
                                        {{ detail?.driver?.name ?? "-"
                                        }}<br /><span>{{
                                            detail?.driver?.phone ?? ""
                                        }}</span>
                                    </p>
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="ps-0 w-180">Status Tracking</td>
                                <td class="w-40">:</td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <small
                                            class="tag green-solid px-5 radius"
                                            v-if="detail?.status == 'done'"
                                        >
                                            {{ detail?.status_label }}
                                        </small>
                                        <small
                                            class="tag yellow-solid px-5 radius"
                                            v-else
                                        >
                                            {{ detail?.status_label }}
                                        </small>
                                        <div class="ms-auto">
                                            <a
                                                href="javascript:void(0)"
                                                @click="
                                                    isShowMitraInfo =
                                                        !isShowMitraInfo
                                                "
                                                class="text-black"
                                            >
                                                <template
                                                    v-if="
                                                        isShowMitraInfo == false
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-angle-down size-20"
                                                    ></i>
                                                </template>
                                                <template v-else>
                                                    <i
                                                        class="fa fa-angle-up size-20"
                                                    ></i>
                                                </template>
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <div
                            class="history-tl-container mt-2"
                            v-show="isShowMitraInfo"
                        >
                            <ul class="timeline timeline-split">
                                <template
                                    v-for="(item, index) in detail.status_list"
                                >
                                    <li class="timeline-item" :key="index">
                                        <div class="timeline-info">
                                            <span>
                                                {{
                                                    moment(
                                                        item.timestamp
                                                    ).format("DD MMM | HH:mm")
                                                }}
                                            </span>
                                        </div>
                                        <div
                                            class="timeline-marker"
                                            v-bind:class="{
                                                active: index == 0,
                                            }"
                                        ></div>
                                        <div class="timeline-content">
                                            <div
                                                class="timeline-title"
                                                v-bind:class="{
                                                    'text-green fw-bold':
                                                        index == 0,
                                                }"
                                            >
                                                {{ item.label }}
                                            </div>
                                            <div v-if="item.photos.length > 0">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="timeline-title-image"
                                                    @click="
                                                        showModalImage(item)
                                                    "
                                                >
                                                    <u>
                                                        <i
                                                            class="fa fa-image me-1"
                                                        ></i>
                                                        Lihat foto barang &
                                                        tanda terima
                                                    </u>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                    <div v-else>
                        <div class="mt-5">
                            <center>
                                <img
                                    src="../../assets/no-result.png"
                                    width="150"
                                    alt=""
                                />
                                <div class="mt-3 size-18 text-gray-dashboard">
                                    Orderan belum diambil oleh mitra
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
                <div class="box mt-4">
                    <h3 class="fw-bold">Informasi Total Harga</h3>
                    <table class="w-100 ps-0 fw-bold mt-4" cellpadding="10">
                        <tr class="border-top">
                            <td colspan="3" class="ps-0 w-180">
                                <strong>Pembagian Komisi</strong>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Komisi Mitra</td>
                            <td class="w-40">:</td>
                            <td>
                                {{ currencyFormat(detail?.income_partner) }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td colspan="3" class="ps-0 w-180">
                                <strong>Rincian Biaya</strong>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">
                                Tarif Harga
                                {{
                                    detail?.meta?.distance != null
                                        ? "(" + detail?.meta?.distance + "km)"
                                        : ""
                                }}
                            </td>
                            <td class="w-40">:</td>
                            <td>
                                {{ currencyFormat(detail?.service_price ?? 0) }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Biaya Helper (TKBM)</td>
                            <td class="w-40">:</td>
                            <td>
                                {{
                                    currencyFormat(
                                        detail?.additional_price ?? 0
                                    )
                                }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Asuransi</td>
                            <td class="w-40">:</td>
                            <td>
                                {{
                                    currencyFormat(detail?.insurance_price ?? 0)
                                }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Admin VA</td>
                            <td class="w-40">:</td>
                            <td>
                                {{
                                    currencyFormat(
                                        detail?.payment?.payment_admin_charge ??
                                            0
                                    )
                                }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Biaya Layanan</td>
                            <td class="w-40">:</td>
                            <td>
                                {{ currencyFormat(detail?.admin_fee ?? 0) }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Diskon</td>
                            <td class="w-40">:</td>
                            <td>
                                {{
                                    currencyFormat(detail?.discount_price ?? 0)
                                }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Total Harga</td>
                            <td class="w-40">:</td>
                            <td>
                                <template v-if="detail?.type_payment == 'cash'">
                                    {{
                                        currencyFormat(detail?.total_price ?? 0)
                                    }}
                                </template>
                                <template v-else>
                                    {{
                                        currencyFormat(
                                            detail?.payment?.total_payment ?? 0
                                        )
                                    }}
                                </template>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-md-6 mt-4">
                <div class="box">
                    <h3 class="mb-0 fw-bold">Informasi Penerima</h3>
                    <table class="w-100 ps-0 fw-bold mt-4" cellpadding="10">
                        <tr class="border-top">
                            <td class="ps-0 w-180">Nama Penerima</td>
                            <td class="w-40">:</td>
                            <td>{{ detail?.destination?.name ?? "-" }}</td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">No. Telepon Penerima</td>
                            <td class="w-40">:</td>
                            <td>{{ detail?.destination?.phone ?? "-" }}</td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Lokasi Penerima</td>
                            <td class="w-40">:</td>
                            <td>{{ detail?.destination?.address ?? "-" }}</td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Detail Lokasi</td>
                            <td class="w-40">:</td>
                            <td>{{ detail?.destination?.note ?? "-" }}</td>
                        </tr>
                    </table>
                    <GmapMap
                        class="mt-3"
                        ref="mapRef"
                        :center="{
                            lat: receivers[0].lat,
                            lng: receivers[0].lng,
                        }"
                        :zoom="16"
                        map-type-id="terrain"
                        style="height: 300px"
                    >
                        <GmapMarker
                            :key="index"
                            v-for="(m, index) in receivers"
                            ref="marker"
                            :position="m"
                        >
                        </GmapMarker>
                    </GmapMap>
                </div>
                <div class="box mt-4">
                    <h3 class="mb-0 fw-bold">Informasi Jenis Order</h3>
                    <small
                        class="tag red-solid px-5 radius"
                        v-if="detail?.meta?.cancel_reason ?? null"
                    >
                        {{ detail?.meta?.cancel_reason }}
                    </small>
                    <table class="w-100 ps-0 fw-bold mt-4" cellpadding="10">
                        <tr class="border-top">
                            <td class="ps-0 w-180">Jenis Order</td>
                            <td class="w-40">:</td>
                            <td>
                                <template v-if="detail?.type_order == 'quick'">
                                    Cepat
                                </template>
                                <template v-else>Terjadwal</template>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Tanggal Order</td>
                            <td class="w-40">:</td>
                            <td>
                                {{
                                    moment(detail?.created_at).format(
                                        "DD/MM/YYYY | HH:mm"
                                    )
                                }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Tanggal Jemput</td>
                            <td class="w-40">:</td>
                            <td>
                                {{
                                    moment(detail?.beginpickup_at).format(
                                        "DD/MM/YYYY | HH:mm"
                                    )
                                }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">No. Resi</td>
                            <td class="w-40">:</td>
                            <td>{{ detail?.receipt_code ?? "-" }}</td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Rute</td>
                            <td class="w-40">:</td>
                            <td>{{ detail?.route ?? "-" }}</td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Catatan</td>
                            <td class="w-40">:</td>
                            <td>{{ detail?.note }}</td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Jenis Truk</td>
                            <td class="w-40">:</td>
                            <td class="capitalize">
                                {{
                                    (detail?.type_fleet || "")
                                        .split("-")
                                        .join(" ")
                                }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Jumlah Helper</td>
                            <td class="w-40">:</td>
                            <td>
                                <div class="d-flex align-items-center">
                                    <div>
                                        {{ detail?.meta?.helper_count ?? 0 }}
                                        Orang
                                    </div>
                                    <div
                                        class="ms-auto"
                                        v-if="
                                            detail?.meta?.helper_count_origin ||
                                            detail?.meta
                                                ?.helper_count_destination
                                        "
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            @click="
                                                isShowOrderTypeInfo =
                                                    !isShowOrderTypeInfo
                                            "
                                            class="text-black"
                                        >
                                            <template
                                                v-if="
                                                    isShowOrderTypeInfo == false
                                                "
                                            >
                                                <i
                                                    class="fa fa-angle-down size-20"
                                                ></i>
                                            </template>
                                            <template v-else>
                                                <i
                                                    class="fa fa-angle-up size-20"
                                                ></i>
                                            </template>
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <div
                        class="history-tl-container mt-2"
                        v-show="isShowOrderTypeInfo"
                        v-if="
                            detail?.meta?.helper_count_origin ||
                            detail?.meta?.helper_count_destination
                        "
                    >
                        <table class="w-100 ps-0 fw-bold" cellpadding="10">
                            <tr class="">
                                <td class="ps-0 w-180 text-gray-dashboard">
                                    Helper di Kota Pengirim
                                </td>
                                <td class="w-40">:</td>
                                <td class="text-gray-dashboard">
                                    {{ detail?.meta?.helper_count_origin ?? 0 }}
                                    Orang
                                </td>
                            </tr>
                            <tr class="">
                                <td class="ps-0 w-180 text-gray-dashboard">
                                    Helper di Kota Penerima
                                </td>
                                <td class="w-40">:</td>
                                <td class="text-gray-dashboard">
                                    {{
                                        detail?.meta
                                            ?.helper_count_destination ?? 0
                                    }}
                                    Orang
                                </td>
                            </tr>
                        </table>
                    </div>
                    <table class="w-100 ps-0 fw-bold" cellpadding="10">
                        <tr class="border-top">
                            <td class="ps-0 w-180">Asuransi</td>
                            <td class="w-40">:</td>
                            <td>
                                <template v-if="detail?.insurance_price > 0">
                                    <small
                                        class="tag green-solid px-4 radius mb-0"
                                    >
                                        Ya
                                    </small>
                                </template>
                                <template v-else>
                                    <small
                                        class="tag red-solid px-4 radius mb-0"
                                    >
                                        Tidak
                                    </small>
                                </template>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Harga Barang</td>
                            <td class="w-40">:</td>
                            <td>
                                {{
                                    currencyFormat(
                                        detail?.meta?.good_price ?? 0
                                    )
                                }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-180">Metode Pembayaran</td>
                            <td class="w-40">:</td>
                            <td class="capitalize">
                                <template v-if="detail?.type_payment == 'cash'">
                                    Cash oleh
                                    <span
                                        v-if="
                                            detail?.responsible_for_payment ==
                                            'dest'
                                        "
                                        >Penerima</span
                                    >
                                    <span
                                        v-if="
                                            detail?.responsible_for_payment ==
                                            'origin'
                                        "
                                        >Pengirim</span
                                    >
                                </template>
                                <template v-else>
                                    {{ detail?.type_payment }} -
                                    {{ detail?.payment?.channel_name }}
                                </template>
                            </td>
                        </tr>
                    </table>

                    <hr />

                    <template v-if="detail?.meta?.photos_url">
                        <p class="w-180 capitalize">Foto</p>
                        <div
                            v-for="(image, i) in detail?.meta?.photos_url"
                            class="col-md-2 me-2"
                            :key="i"
                        >
                            <viewer
                                :images="detail?.meta?.photos_url"
                                :options="options"
                            >
                                <img
                                    class="image-product w-100"
                                    :key="image"
                                    :src="image"
                                />
                            </viewer>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="showModal"></div>
            <div class="modal-body-chat sm" v-if="showModal">
                <div>
                    <div class="d-flex align-items-center">
                        <h4 class="fw-bold mb-0">Foto Barang & Tanda Terima</h4>
                        <div class="ms-auto">
                            <a
                                href="javascript:void(0)"
                                class="text-danger size-22"
                                @click="showModal = false"
                            >
                                <i class="fa fa-times-circle"></i>
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mt-4">
                            <label class="fw-semibold mb-2">Foto Barang</label>
                            <div>
                                <viewer :images="[]" :options="options">
                                    <img
                                        class="image-cover"
                                        :src="photos[0].url"
                                    />
                                </viewer>
                            </div>
                        </div>
                        <div class="col-md-6 mt-4">
                            <label class="fw-semibold mb-2">Tanda Tangan</label>
                            <div>
                                <viewer :images="[]" :options="options">
                                    <img
                                        class="image-cover"
                                        :src="photos[1].url"
                                    />
                                </viewer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import moment from "moment";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";

export default {
    name: "ManagementHoOrderDetail",
    components: {
        Viewer,
    },
    data() {
        return {
            moment: moment,
            detail: {
                driver: {},
                origin: {},
                destination: {},
                customer_detail: {},
                meta: {},
            },
            is_list_ready: false,
            senders: [
                {
                    lat: 0,
                    lng: 0,
                },
            ],
            receivers: [
                {
                    lat: 0,
                    lng: 0,
                },
            ],
            isShowMitraInfo: true,
            isShowOrderTypeInfo: true,
            showModal: false,
            photos: [],
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
        };
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    created() {
        this.getDetail();
    },
    methods: {
        currencyFormat(num) {
            return (
                "Rp. " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getDetail() {
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/detail?id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detail = data;
                    this.senders = [
                        {
                            lat: parseFloat(data.origin?.lat),
                            lng: parseFloat(data.origin?.lon),
                        },
                    ];
                    this.receivers = [
                        {
                            lat: parseFloat(data.destination.lat),
                            lng: parseFloat(data.destination.lon),
                        },
                    ];
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        showModalImage(label) {
            this.showModal = true;
            this.photos = label.photos;
        },
    },
};
</script>

<style scoped>
.w-150 {
    width: 150px;
}
.w-180 {
    width: 180px;
}
.w-40 {
    width: 40px;
}
.text-yellow-bold {
    color: #d17e20;
}
.image-cover {
    border-radius: 10px;
    border: 2px solid #e2e3e5;
    width: 100%;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
}
</style>
