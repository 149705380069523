<template>
  <div>
    <GmapMap ref="mapRef" :center="{ lat: -6.233035800000001, lng: 106.8397427 }" :zoom="16" map-type-id="terrain" style="height: 200px">
      <div class="row justify-content-center pt-4">
        <div class="col-md-9">
          <div class="position-relative">
            <GmapAutocomplete @place_changed="processLocationChanged" placeholder="Pilih lokasi anda" class="form-control search-google"></GmapAutocomplete>
            <span class="search-icon map">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </div>
      </div>
      <GmapMarker :key="index" v-for="(m, index) in markers" ref="marker" :position="m" :clickable="true" :draggable="true" @dragend="movePlace" @click="center = m"> </GmapMarker>
      <!-- <GmapCircle ref="circle" :radius="1000" :center=map_data :draggable='false' :editable='true' @radius_changed="radiusChanged" @center_changed="centerChanged"></GmapCircle> -->
    </GmapMap>
    <div class="mt-4">
      <label class="fw-bold mb-2 size-16">Alamat {{ title }}</label>
      <div v-if="address">
        {{ address }}
      </div>
      <div v-else>-</div>
    </div>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";

export default {
  props: {
    title: String,
  },
  data() {
    return {
      address: "",
      map_data: { lat: null, lng: null },
      driverMarker: {
        lat: 0,
        lng: 0,
      },
      markers: [
        {
          lat: -6.233035800000001,
          lng: 106.8397427,
        },
      ],
      lat_move: "",
      lng_move: "",
      lat_search: "",
      lng_search: "",
    };
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    processLocationChanged(ev) {
      this.lat_search = ev.geometry.location.toJSON().lat;
      this.lng_search = ev.geometry.location.toJSON().lng;
      this.map_data = ev.geometry.location.toJSON();

      this.address = ev.formatted_address;

      let that = this;
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo(that.map_data);
      });
      this.$refs.marker[0].$markerObject.setPosition(this.map_data);
      this.$emit("map-data", this.map_data);
    },
    movePlace(event) {
      this.lat_move = event.latLng.toJSON().lat;
      this.lng_move = event.latLng.toJSON().lng;
      this.map_data = event.latLng.toJSON();

      this.decodeLatLong(this.lat_move, this.lng_move);
      this.$emit("map-data", this.map_data);
    },
    decodeLatLong(lat, lng) {
      const geocoder = new this.google.maps.Geocoder();

      geocoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
        if (status === "OK") {
          console.log(results[0]);
          this.address = results[0].formatted_address;
        } else {
          this.address = "";
          alert("tidak ketemu");
        }
      });
    },
  },
};
</script>
