<template>
    <div class="padding-container">
        <div class="tab-content" id="pills-tabContent">
            <div class="box">
                <h3 class="fw-bold">Daftar Assign Register Driver</h3>
                <div class="d-flex align-items-center no-flex mt-4">
                    <div class="me-4 m-mx-0 m-mt-2">
                        <div class="position-relative">
                            <input
                                class="form-control form-control-lg pl-search w-search-leads"
                                v-model="keySearch"
                                @input="searchData()"
                                type="text"
                                style="padding-right: 30px"
                                placeholder="Search"
                            />
                            <span class="search-icon left">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="me-3 m-mx-0 m-mt-2">
                        <button
                            class="btn btn-green btn-with-search px-4"
                            :disabled="valueCheckBox.length == 0"
                            @click="assignModal = true"
                        >
                            Assign To
                        </button>
                    </div>
                </div>
                <div class="mt-4" v-if="is_my_list_ready">
                    <ListLoader />
                </div>
                <div v-else>
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="selectAllLeads"
                                            id="allCheck"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="allCheck"
                                        ></label>
                                    </th>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Nama Driver
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nomor Telepon
                                    </th>
                                    <th class="text-center" scope="col">
                                        Kota/Kabupaten
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nomor Polisi
                                    </th>
                                    <th class="text-center" scope="col">
                                        Jenis Armada
                                    </th>
                                    <th class="text-center" scope="col">
                                        Tanggal Register
                                    </th>
                                    <th class="text-center" scope="col">
                                        Status
                                    </th>
                                    <th class="text-center" scope="col">
                                        Reject Count
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in lists" :key="index">
                                    <td class="text-center">
                                        <b-form-group
                                            v-slot="{ ariaDescribedby }"
                                        >
                                            <b-form-checkbox-group
                                                id="checkbox-group-2"
                                                v-model="valueCheckBox"
                                                :aria-describedby="
                                                    ariaDescribedby
                                                "
                                                name="flavour-2"
                                            >
                                                <b-form-checkbox
                                                    :value="item"
                                                ></b-form-checkbox>
                                            </b-form-checkbox-group>
                                        </b-form-group>
                                    </td>
                                    <td class="text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <td>
                                        <router-link
                                            class="text-link"
                                            :to="{
                                                name: 'assign-driver-detail',
                                                params: {
                                                    id: item.id,
                                                },
                                            }"
                                        >
                                            {{ item.name ? item.name : "-" }}
                                        </router-link>
                                    </td>
                                    <td class="text-center">
                                        {{ item.phone ? item.phone : "-" }}
                                    </td>
                                    <td>
                                        {{
                                            item.driver_truck?.regency?.name
                                                ? item.driver_truck?.regency
                                                      ?.name
                                                : "-"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            item?.fleet_truck?.fleet_number
                                                ? item?.fleet_truck
                                                      ?.fleet_number
                                                : "-"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            item?.fleet_truck?.fleet_type_label
                                                ? item?.fleet_truck
                                                      ?.fleet_type_label
                                                : "-"
                                        }}
                                    </td>
                                    <td class="text-center">
                                        {{
                                            moment(item.created_at).format(
                                                "DD/MM/YYYY | HH:mm"
                                            )
                                        }}
                                    </td>
                                    <td class="text-center">
                                        <div
                                            class="tag radius capitalize px-4"
                                            v-bind:class="{
                                                panding:
                                                    item.driver_truck.status ==
                                                    'pending',
                                                resubmit:
                                                    item.driver_truck.status ==
                                                    'resubmit',
                                                reject:
                                                    item.driver_truck.status ==
                                                    'reject',
                                                banned:
                                                    item.driver_truck.status ==
                                                    'banned',
                                            }"
                                        >
                                            {{ item.driver_truck.status }}
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        {{ item.driver_truck.rejections_count }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div
                        v-if="lists.length == 0"
                        class="text-center mt-section"
                    >
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </div>
                <div class="d-flex mt-4">
                    <div class="ms-auto">
                        <div class="d-flex align-items-center">
                            <div class="me-3">
                                <select
                                    class="form-control form-select w-select"
                                    v-model="limit"
                                    @change="getLists()"
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <b-pagination-nav
                                v-if="lists.length > 0"
                                v-model="currentPage"
                                :number-of-pages="totalPage"
                                base-url="#"
                                first-number
                                align="right"
                                class="pagination-mb"
                                @input="changePage()"
                            ></b-pagination-nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div
                class="overlay"
                v-if="assignModal"
                @click="assignModal = false"
            ></div>
            <div class="modal-body-chat md" v-if="assignModal">
                <div class="mb-3">
                    <h3 class="fw-bold">Assign Register Driver ke</h3>
                </div>
                <div class="position-relative mt-18 mb-35">
                    <input
                        class="form-control form-control-lg pl-search w-100"
                        v-model="searchTerm"
                        type="text"
                        placeholder="Cari nama vendor management"
                    />
                    <span class="search-icon left">
                        <i class="fa fa-search fa-beat fa-sm"></i>
                    </span>
                </div>
                <div
                    class="mb-2"
                    v-bind:class="{
                        scroll: filteredOptions.length > 6,
                    }"
                >
                    <template v-if="filteredOptions.length > 0">
                        <div
                            v-for="(option, index) in filteredOptions"
                            :key="index"
                            class="radio-option fw-medium capitalize"
                        >
                            <label :for="option.id">
                                <input
                                    type="radio"
                                    :id="option.id"
                                    :value="option.id"
                                    v-model="selectedAssign"
                                />
                                <span class="text mt-1">
                                    {{ option.name }}
                                </span>
                            </label>
                        </div>
                    </template>
                    <template v-else>
                        <div class="text-center">
                            <h3 class="mb-0 text-gray">List Tidak Ditemukan</h3>
                        </div>
                    </template>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6">
                        <button
                            class="btn btn-outline-black w-100 btn-lg"
                            @click="assignModal = false"
                        >
                            Batal
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button
                            class="btn btn-green w-100 btn-lg"
                            @click="storeAssign()"
                            :disabled="selectedAssign == null"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_assign"
                            ></span>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Register Driver Berhasil Di-assign
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/trawltruck/assign-driver"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";

export default {
    components: {
        ListLoader,
    },
    name: "assignDriver",
    data() {
        return {
            moment: moment,
            lists: {},
            keySearch: "",
            hash: "",
            is_assign: false,
            is_my_list_ready: false,
            valueCheckBox: [],
            selectedAssign: null,
            searchTerm: "",
            currentPage: 0,
            totalPage: 10,
            limit: 10,
            req: {
                start: "",
                end: "",
            },
            item_id: null,
            type: "",
            start_date: "",
            end_date: "",
            range: "",
            leads_id: null,
            distribute_value: [],
            assignModal: false,
            modalSuccess: false,
            list_users: [],
        };
    },
    created() {
        this.getLists();
        this.getUserAssign();
    },
    methods: {
        getStartDate(value) {
            this.start_date = value;
        },
        searchData() {
            this.getLists();
            this.currentPage = 0;
        },
        changePage() {
            this.getLists();
        },
        getLists() {
            let payload = {
                search: this.keySearch,
                limit: this.limit,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
            };
            this.is_my_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/trawltruck/register/waiting_for_assign`,
                {
                    params: payload,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.lists = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        getUserAssign() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/trawltruck/vm/list`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.list_users = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        storeAssign() {
            this.valueCheckBox.forEach((val) => {
                this.distribute_value.push(val.id);
            });
            let data = {
                assign_id: this.selectedAssign,
                driver_id: this.distribute_value,
            };
            this.is_assign = true;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/admin/trawltruck/register/waiting_for_assign`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.is_assign = false;
                    this.modalSuccess = true;
                    this.assignModal = false;
                    this.getLists();
                })
                .catch((err) => {
                    this.is_assign = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
    computed: {
        filteredOptions() {
            if (this.searchTerm) {
                const regex = new RegExp(this.searchTerm, "i");
                return this.list_users.filter((option) =>
                    option.name.match(regex)
                );
            }
            return this.list_users;
        },
        selectAllLeads: {
            get() {
                return this.valueCheckBox.length === this.lists.length;
            },
            set(value) {
                this.valueCheckBox = [];

                if (value) {
                    this.lists.forEach((select) => {
                        this.valueCheckBox.push(select);
                    });
                }
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.scroll {
    height: 320px;
    overflow-y: scroll;
}
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.active {
    background-color: #e60013 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
}
.bt-size {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 159px;
    height: 44px;
    border-radius: 8px;
}
.search-icon-left {
    top: 11px;
    left: 10px;
}
.excel {
    width: 20px !important;
    height: 19.05px !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.button {
    display: flex;
    border-radius: 12px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    &-save {
        background: #48a2d4;
        border: 1px solid #48a2d4;
        color: white;
    }
    &-save:is(:hover, :focus) {
        background: #176f9e;
    }
    &-delete {
        background: #e60013;
        border: 1px solid #e60013;
        color: white;
    }
    &-delete:is(:hover, :focus) {
        background: #e94b58;
    }
    &-cancel {
        background: #ffffff;
        border: 1px solid #e94b58;
        color: #e94b58;
    }
    &-cancel:is(:hover, :focus) {
        background: #e94b58;
        border: 1px solid #ffffff;
        color: #ffffff;
    }
}
.pill {
    display: inline-block;
    width: 110px;
    height: 24px;
    border-radius: 40px;
    text-align: center;
    color: white;
    &.red {
        background-color: #e60013;
        border: 1px solid #e60013;
        outline: 1px solid #e60013;
    }

    &.green {
        background-color: #3d8824;
        border: 1px solid #3d8824;
        outline: 1px solid #3d8824;
    }
}
.text-link {
    text-decoration: underline;
    color: #24516a;
}
.h-44 {
    height: 44px !important;
}
.radio-option label {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    cursor: pointer;
}
.radio-option label .text {
    font-size: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    border-bottom: 1px solid #f3f4f6;
    padding-bottom: 15px;
}
.radio-option label input {
    display: none;
}
.radio-option label input + .text:after {
    font-family: FontAwesome;
    content: "\f1db";
    line-height: 1;
    display: inline-block;
    color: #f3f4f6;
    font-size: 20px;
}
.radio-option label input:checked + .text:after {
    font-family: FontAwesome;
    content: "\f111";
    color: #3d8824;
}
.mt-18 {
    margin-top: 18px;
}
.mb-24 {
    margin-bottom: 24px;
}
.mb-35 {
    margin-bottom: 35px;
}
.rounded-8 {
    border-radius: 8px !important;
}
.py-10 {
    padding: 10px 0px;
}
.w-search-leads {
    width: 320px;
}
.dropdown-toggle::after {
    display: none;
}
.dropdown-menu.show {
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border: 1px solid #f7f7f7;
    border-radius: 8px;
}
.w-mail-title {
    width: 80px;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    border: dashed;
    border-radius: 10px;
    width: 13vw;
    height: 150px;
    padding: 55px;
    position: relative;
}
.photo {
    border-radius: 10px;
    cursor: pointer;
    width: 13vw;
    height: 150px;
    object-fit: cover;
}
.btn-lg {
    width: 150px;
}
input[type="file"] {
    display: none;
}
.upload__btn p i {
    font-size: 30px;
}
</style>
