<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/trawltruck/ho/order" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Ubah Data Order</h3>
            </div>
            <div class="row mt-5 m-mt-2">
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Nomor Resi</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Nomor Resi"
                        v-model="req.receipt_code"
                        disabled
                    />
                </div>
                <div class="col-md-6 m-mt-1">
                    <label class="fw-bold mb-2">Nama Mitra</label>
                    <select
                        class="form-control form-select"
                        v-model="req.partner_id"
                        v-if="req.partner_id"
                    >
                        <option value="">Pilih Mitra</option>
                        <template v-for="(mitra, index) in mitras">
                            <option :value="mitra.id" :key="index">
                                {{ mitra.name }}
                            </option>
                        </template>
                    </select>
                    <select class="form-control form-select" disabled v-else>
                        <option value="individu">Individu</option>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nama Driver</label>
                    <select
                        class="form-control form-select"
                        v-if="req.partner_id"
                    >
                        <option value="">Pilih Nama Driver</option>
                    </select>
                    <select class="form-control form-select" disabled v-else>
                        <template v-if="req.driver">
                            <option :value="req.driver.name">
                                {{ req.driver.name }}
                            </option>
                        </template>
                        <template v-else>
                            <option value="-">-</option>
                        </template>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Jenis Truk</label>
                    <select
                        class="form-control form-select capitalize"
                        disabled
                    >
                        <option :value="req.type_fleet">
                            {{ (req.type_fleet || "").split("-").join(" ") }}
                        </option>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nama Pengirim</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Nama Pengirim"
                        v-model="address.name"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nomor Pengirim</label>
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Masukkan Nomor Pengirim"
                        v-model="address.phone"
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Catatan</label>
                    <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="4"
                        class="form-control"
                        placeholder="Masukkan Alamat Detail"
                        v-model="address.note"
                    ></textarea>
                </div>
                <div class="col-md-12 mt-3">
                    <div class="box border-gray no-shadow">
                        <div class="box bc-gray no-shadow p-4 pt-3 pb-3">
                            <div class="d-flex align-items-center">
                                <div class="d-flex align-items-center">
                                    <div class="number-point me-3">1</div>
                                    <h5 class="mb-0 fw-bold">Penerima</h5>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mt-3">
                                <label class="fw-bold mb-2"
                                    >Nama Penerima</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Masukkan Nama Penerima"
                                    v-model="req.destination.name"
                                />
                            </div>
                            <div class="col-md-6 mt-3">
                                <label class="fw-bold mb-2"
                                    >Nomor Penerima</label
                                >
                                <input
                                    type="number"
                                    class="form-control"
                                    placeholder="Masukkan Nomor Penerima"
                                    v-model="req.destination.phone"
                                />
                            </div>
                            <div class="col-md-12 mt-3">
                                <label class="fw-bold mb-2">Catatan</label>
                                <textarea
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="4"
                                    class="form-control"
                                    placeholder="Masukkan Alamat Detail"
                                    v-model="req.destination.note"
                                ></textarea>
                            </div>
                            <div class="col-md-12 mt-3">
                                <label class="fw-bold mb-2">Lokasi</label>
                                <GmapMap
                                    ref="mapRef"
                                    :center="{
                                        lat: parseFloat(req.destination.lat),
                                        lng: parseFloat(req.destination.lon),
                                    }"
                                    :zoom="16"
                                    map-type-id="terrain"
                                    style="height: 300px"
                                >
                                    <GmapMarker
                                        :key="index"
                                        v-for="(m, index) in markers"
                                        ref="marker"
                                        :position="m"
                                        :clickable="false"
                                        :draggable="false"
                                        @click="center = m"
                                    >
                                    </GmapMarker>
                                </GmapMap>
                                <div class="mt-4">
                                    <label class="fw-bold mb-2"
                                        >Detail Tujuan</label
                                    >
                                    <template
                                        v-if="
                                            req.destination.address &&
                                            destination_address == ''
                                        "
                                    >
                                        <div>
                                            {{ req.destination.address }}
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div v-if="destination_address">
                                            {{ destination_address }}
                                        </div>
                                        <div v-else>-</div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-3" v-if="req.destination2.name">
                    <div class="box border-gray no-shadow">
                        <div class="box bc-gray no-shadow p-4 pt-3 pb-3">
                            <div class="d-flex align-items-center">
                                <div class="d-flex align-items-center">
                                    <div class="number-point me-3">2</div>
                                    <h5 class="mb-0 fw-bold">Penerima</h5>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mt-3">
                                <label class="fw-bold mb-2"
                                    >Nama Penerima</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Masukkan Nama Penerima"
                                    v-model="req.destination2.name"
                                />
                            </div>
                            <div class="col-md-6 mt-3">
                                <label class="fw-bold mb-2"
                                    >Nomor Penerima</label
                                >
                                <input
                                    type="number"
                                    class="form-control"
                                    placeholder="Masukkan Nomor Penerima"
                                    v-model="req.destination2.phone"
                                />
                            </div>
                            <div class="col-md-12 mt-3">
                                <label class="fw-bold mb-2">Catatan</label>
                                <textarea
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="4"
                                    class="form-control"
                                    placeholder="Masukkan Alamat Detail"
                                    v-model="req.destination2.note"
                                ></textarea>
                            </div>
                            <div class="col-md-12 mt-3">
                                <label class="fw-bold mb-2">Lokasi</label>
                                <GmapMap
                                    ref="mapRef"
                                    :center="{
                                        lat: parseFloat(req.destination2.lat),
                                        lng: parseFloat(req.destination2.lon),
                                    }"
                                    :zoom="16"
                                    map-type-id="terrain"
                                    style="height: 300px"
                                >
                                    <GmapMarker
                                        :key="index"
                                        v-for="(m, index) in markers2"
                                        ref="marker2"
                                        :position="m"
                                        :clickable="false"
                                        :draggable="false"
                                        @click="center = m"
                                    >
                                    </GmapMarker>
                                </GmapMap>
                                <div class="mt-4">
                                    <label class="fw-bold mb-2"
                                        >Detail Tujuan</label
                                    >
                                    <template
                                        v-if="
                                            req.destination2.address &&
                                            destination_address == ''
                                        "
                                    >
                                        <div>
                                            {{ req.destination2.address }}
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div v-if="destination_address">
                                            {{ destination_address }}
                                        </div>
                                        <div v-else>-</div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-4">
                    <label class="fw-bold mb-2">Jenis Order</label>
                    <select
                        class="form-control form-select capitalize"
                        disabled
                    >
                        <option :value="req.type_order">
                            <template v-if="req.type_order == 'quick'">
                                Cepat
                            </template>
                            <template v-if="req.type_order == 'schedule'">
                                Terjadwal
                            </template>
                            <template v-if="req.type_order == 'fullday'">
                                Seharian
                            </template>
                        </option>
                    </select>
                </div>
                <div class="col-md-12 mt-4" v-if="req.type_order == 'quick'">
                    <label class="fw-bold mb-2">Pilihan Waktu</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="1"
                                v-model="req.meta.type_ordersub"
                                disabled
                            />
                            <label class="form-check-label" for="inlineRadio1"
                                >Diantarkan dalam 1 jam</label
                            >
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="3"
                                v-model="req.meta.type_ordersub"
                                disabled
                            />
                            <label class="form-check-label" for="inlineRadio2"
                                >Diantarkan dalam 3 jam</label
                            >
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-4" v-else>
                    <label class="fw-bold mb-2">Pilih Tanggal</label>
                    <date-picker
                        v-model="req.date"
                        type="date"
                        value-type="format"
                        format="YYYY-MM-DD"
                        :clearable="false"
                        placeholder="Masukkan Tanggal"
                        :disabled-date="disabledDate"
                        class="w-100"
                    >
                    </date-picker>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Jenis Layanan</label>
                    <select
                        class="form-control form-select capitalize"
                        disabled
                    >
                        <option :value="req.type_service">
                            {{ req.type_service }}
                        </option>
                    </select>
                </div>
                <div class="col-md-6 mt-3" v-if="req.type_service == 'special'">
                    <label class="fw-bold mb-2">Jumlah Kenek</label>
                    <select
                        class="form-control form-select capitalize"
                        disabled
                    >
                        <option :value="req.meta.helper_count">
                            {{ req.meta.helper_count }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6 mt-5"></div>
                <div class="col-md-6 mt-5 mb-3">
                    <div class="box">
                        <h4 class="mb-0 fw-bold">Pembayaran</h4>
                        <hr />
                        <div class="d-flex align-items-center">
                            <h5 class="mb-0 fw-bold">Biaya Tambahan</h5>
                            <h5 class="mb-0 fw-bold ms-auto">
                                {{ currencyFormat(req.additional_price) }}
                            </h5>
                        </div>
                        <div class="d-flex align-items-center mt-3">
                            <h5 class="mb-0 fw-bold">Tarif Layanan</h5>
                            <h5 class="mb-0 fw-bold ms-auto">
                                {{ currencyFormat(req.service_price) }}
                            </h5>
                        </div>
                        <div class="d-flex align-items-center mt-3">
                            <h5 class="mb-0 fw-bold">Asuransi</h5>
                            <h5 class="mb-0 fw-bold ms-auto">
                                {{ currencyFormat(req.insurance_price) }}
                            </h5>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center mt-3">
                            <h4 class="mb-0 fw-bold text-green">Total Harga</h4>
                            <h4 class="mb-0 fw-bold text-green ms-auto">
                                {{ currencyFormat(req.total_price) }}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex mt-4">
            <div class="ms-auto">
                <a
                    href="/trawltruck/ho/order"
                    class="btn btn-outline-black btn-lg px-5 me-3"
                    >Batal</a
                >
                <button
                    class="btn btn-green btn-lg px-5"
                    type="button"
                    disabled
                    v-if="is_update"
                >
                    <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    Update
                </button>
                <button
                    class="btn btn-green btn-lg px-5"
                    @click="updateData()"
                    v-else
                >
                    Update
                </button>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Perubahan Data Berhasil!</h4>
                    <div class="mt-2">Selamat! Anda berhasil merubah data.</div>
                </div>
                <div class="mt-4">
                    <a
                        href="/trawltruck/ho/order"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Api from "../../api/Api";

export default {
    components: {
        DatePicker,
    },
    props: "",
    name: "ManagementHoOrderEdit",
    data() {
        return {
            modalSuccess: false,
            modalError: false,
            map_data: { lat: null, lng: null },
            markers: [],
            markers2: [],
            lat_move: "",
            lng_move: "",
            lat_search: "",
            lng_search: "",
            destination_address: "",
            req: {
                driver: {},
                origin: {},
                destination: {},
                destination2: {},
            },
            params: {
                date: "",
            },
            mitras: [],
            address: {
                name: "",
                phone: "",
                note: "",
            },
            is_update: false,
        };
    },
    computed: {
        google: gmapApi,
    },
    created() {
        this.getDetail();
        this.getMitra();
    },
    methods: {
        currencyFormat(num) {
            return (
                "Rp. " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getDetail() {
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/detail?id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log();
                    var data = res.data.data;
                    this.req = data;
                    let that = this;

                    this.markers = [
                        {
                            lat: parseFloat(this.req.destination.lat),
                            lng: parseFloat(this.req.destination.lon),
                        },
                    ];

                    this.markers2 = [
                        {
                            lat: parseFloat(this.req.destination2.lat),
                            lng: parseFloat(this.req.destination2.lon),
                        },
                    ];

                    data.address.forEach(function (item) {
                        if (item.type_address == "origin") {
                            that.address = {
                                name: item.name,
                                phone: item.phone,
                                note: item.note,
                            };
                        }
                    });

                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        getMitra() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partner/list`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.mitras = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        disabledDate(date) {
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        updateData() {
            this.is_update = true;
            var data = {
                id: this.req.id,
                date: this.req.date,
                origin_name: this.address.name,
                origin_phone: this.address.phone,
                origin_note: this.address.note,
                dest_name: this.req.destination.name,
                dest_phone: this.req.destination.phone,
                dest_note: this.req.destination.note,
                dest2_name: this.req.destination2.name,
                dest2_phone: this.req.destination2.phone,
                dest2_note: this.req.destination2.note,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/edit`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_update = false;
                    this.modalSuccess = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_update = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
.number-point {
    width: 35px;
    height: 35px;
    background: #e60013;
    color: #fff;
    border-radius: 50px;
    padding: 4px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
</style>
