<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="fw-bold">
            <a
                href="/crm/partnership/candidate"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="size-20 fw-bold">Add New Candidate</div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <label class="fw-bold">Company Name</label>
                    <input
                        v-model="req.company"
                        type="text"
                        class="form-control mt-2"
                        placeholder="Company Name"
                    />
                </div>
                <div class="col-md-6">
                    <label class="fw-bold">Owner Name</label>
                    <input
                        v-model="req.pic"
                        type="text"
                        class="form-control mt-2"
                        placeholder="Owner Name"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold">Phone</label>
                    <input
                        v-model="req.phone"
                        type="number"
                        class="form-control mt-2"
                        placeholder="Phone"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold">Email</label>
                    <input
                        v-model="req.email"
                        type="email"
                        class="form-control mt-2"
                        placeholder="Email"
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold">Partner Type</label>
                    <multiselect
                        v-model="req.partner_type"
                        :options="products"
                        :multiple="true"
                        placeholder="Choose Partner Type"
                        class="multi-form-custom tag-custom mt-2"
                    >
                    </multiselect>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold">Note</label>
                    <textarea
                        v-model="req.note"
                        cols="30"
                        rows="4"
                        class="form-control mt-2"
                        placeholder="Note"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="/crm/partnership/candidate"
                            class="btn btn-outline-black btn-lg w-100"
                            >Cancel</a
                        >
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            class="btn btn-green btn-lg w-100"
                            :disabled="is_store"
                            @click="storeDate()"
                        >
                            <span
                                v-if="is_store"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Add Data Succeeded!</h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/crm/partnership/candidate"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    name: "crmPartnershipCandidateCreate",
    data() {
        return {
            products: ["TroPack", "TroTruck", "TroCarrier"],
            req: {
                pic: "",
                company: "",
                phone: "",
                email: "",
                partner_type: [],
                note: "",
            },
            is_store: false,
            modalSuccess: false,
        };
    },
    created() {},
    mounted() {},
    methods: {
        storeDate() {
            this.is_store = true;
            var data = {
                pic: this.req.pic,
                company: this.req.company,
                phone: this.req.phone,
                email: this.req.email,
                partner_type: this.req.partner_type,
                note: this.req.note,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/candidate/create`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_store = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.is_store = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped></style>
