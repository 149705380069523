<template>
    <div>
        <warehouse :items="items" />
    </div>
</template>
<script>
import warehouse from "./Warehouse.vue";
import Api from "../../api/Api";

export default {
    components: {
        warehouse,
    },
    name: "Ready-To-Delivery",
    data() {
        return {
            items: {
                name: "Manifest Siap Antar",
                partner_type: "mb",
                url: "transit",
                codeStatus: "rtd",
            },
        };
    },
    created() {
        this.getRole();
    },
    methods: {
        getRole() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    let roles = res.data.data.roles;
                    roles.forEach((el) => {
                        if (el == "trawlpack-partner-owner-business") {
                            this.items.partner_type = "mb";
                            return;
                        }
                        if (el == "trawlpack-partner-owner-warehouse") {
                            this.items.partner_type = "mpw";
                            return;
                        }
                        if (el == "trawlpack-partner-owner-transporter") {
                            this.items.partner_type = "mtak";
                            return;
                        }
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>
<style scoped></style>
