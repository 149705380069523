<template>
    <div class="padding-container">
        <div class="row">
            <div class="col-md-12">
                <a
                    href="javascript:void(0)"
                    class="text-black size-16 text-gray-dashboard fw-semibold"
                    @click="$router.go(-1)"
                >
                    <i class="fa fa-angle-left me-2"></i>
                    Kembali
                </a>
            </div>
            <div class="col-md-6 mt-4">
                <div class="box">
                    <div class="d-flex align-items-center">
                        <h3 class="mb-0 fw-bold">Detail Order</h3>
                        <div class="ms-auto">
                            <span
                                class="mb-0 tag yellow-solid px-4 radius capitalize"
                                v-if="data.status == 'waiting'"
                            >
                                {{ data.status }}
                            </span>
                            <span
                                class="mb-0 tag green-solid px-4 radius capitalize"
                                v-else-if="data.status == 'done'"
                            >
                                {{ data.status }}
                            </span>
                            <span
                                class="mb-0 tag red-solid px-4 radius capitalize"
                                v-else-if="data.status == 'unloading'"
                            >
                                {{ data.status }}
                            </span>
                            <span
                                class="mb-0 tag blue-solid px-4 radius capitalize"
                                v-else-if="data.status == 'ontheway'"
                            >
                                {{ data.status }}
                            </span>
                            <span
                                class="mb-0 tag gray-solid px-4 radius capitalize"
                                v-else
                            >
                                {{ data.status }}
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 mt-4">
                            <div class="mb-3">
                                <label class="fw-bold mb-2">Id Order</label>
                                <div class="form-box">
                                    {{ data.receipt_code }}
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="fw-bold mb-2"
                                    >Jenis Orderan</label
                                >
                                <div class="form-box">
                                    {{ data.type_order }}
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="fw-bold mb-2"
                                    >Nomor Telepon</label
                                >
                                <div class="form-box">
                                    {{ data?.origin?.phone ?? "-" }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6 mt-4">
                            <div class="mb-3">
                                <label class="fw-bold mb-2"
                                    >Nama Customer</label
                                >
                                <div class="form-box">
                                    {{ data?.customer?.name }}
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="fw-bold mb-2">Jenis Armada</label>
                                <div class="form-box">
                                    {{ data.type_fleet }}
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="fw-bold mb-2">Pembayaran</label>
                                <div class="form-box">
                                    {{ data.type_payment }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="fw-bold mb-2">Tanggal Order</label>
                        <div class="form-box">
                            {{ dateFormat(data.beginpickup_at) }} -
                            {{ dateFormat(data.order_end_at) }}
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="fw-bold mb-2">Alamat Penjemputan</label>
                        <div class="form-box lg">
                            {{ data?.origin?.address }}
                        </div>
                    </div>
                    <div class="">
                        <label class="fw-bold mb-2">Foto Barang</label>
                        <div class="row">
                            <template v-if="picture.length > 0">
                                <template v-for="(image, index) in data.photos">
                                    <div class="col-md-3 mb-3" :key="index">
                                        <viewer
                                            :images="data.photos"
                                            :options="options"
                                        >
                                            <img
                                                class="image-product"
                                                :key="image.url"
                                                :src="image.url"
                                            />
                                        </viewer>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div class="col-md-3">
                                    <img
                                        class="image-product"
                                        src="../../assets/no-photo.png"
                                    />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-4">
                <div class="box">
                    <h3 class="mb-0 fw-bold">Detail Penerima</h3>
                    <div class="row">
                        <div class="col-6 mt-4">
                            <div class="mb-3">
                                <label class="fw-bold mb-2">Nama Lengkap</label>
                                <div class="form-box">
                                    {{ data?.destination?.name ?? "-" }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6 mt-4">
                            <div class="mb-3">
                                <label class="fw-bold mb-2"
                                    >Nomor Telepone</label
                                >
                                <div class="form-box">
                                    {{ data?.destination?.phone ?? "-" }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label class="fw-bold mb-2">Alamat Penjemputan</label>
                        <div class="form-box md">
                            {{ data?.destination?.address }}
                        </div>
                    </div>
                </div>
                <div v-if="data.destination2 != null" class="box mt-4">
                    <h3 class="mb-0 fw-bold">Detail Penerima 2</h3>
                    <div class="row">
                        <div class="col-6 mt-4">
                            <div class="mb-3">
                                <label class="fw-bold mb-2">Nama Lengkap</label>
                                <div class="form-box">
                                    {{ data?.destination2?.name ?? "-" }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6 mt-4">
                            <div class="mb-3">
                                <label class="fw-bold mb-2"
                                    >Nomor Telepone</label
                                >
                                <div class="form-box">
                                    {{ data?.destination2?.phone ?? "-" }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="fw-bold mb-2">Alamat Penjemputan</label>
                        <div class="form-box md">
                            {{ data?.destination2?.address }}
                        </div>
                    </div>
                </div>
                <div v-if="status != 'pending'" class="box mt-4">
                    <h3 class="mb-0 fw-bold">Detail driver</h3>
                    <div class="row">
                        <div class="col-6 mt-4">
                            <div class="mb-3">
                                <label class="fw-bold mb-2"
                                    >Nomor Telepon Driver</label
                                >
                                <div class="form-box">
                                    {{ data?.driver?.phone ?? "-" }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6 mt-4">
                            <div class="mb-3">
                                <label class="fw-bold mb-2">Nama Driver</label>
                                <div class="form-box">
                                    {{ data?.driver?.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <GmapMap
                        class="mt-3"
                        v-if="
                            status == 'waiting' ||
                            status == 'pickup' ||
                            status == 'loading' ||
                            status == 'ontheway' ||
                            status == 'unloading'
                        "
                        ref="mapRef"
                        :center="{
                            lat: markers[0].lat,
                            lng: markers[0].lng,
                        }"
                        :zoom="16"
                        map-type-id="terrain"
                        style="height: 300px"
                    >
                        <GmapMarker
                            :key="index"
                            v-for="(m, index) in markers"
                            ref="marker"
                            :position="m"
                        >
                        </GmapMarker>
                    </GmapMap>
                </div>
                <div class="box mt-4" v-if="status == 'cancel'">
                    <div class="mb-0 fw-bold mb-0 fw-bold">Alasan Cancel</div>
                    <div class="mb-3">
                        <div class="form-box lg">{{ data.note }}</div>
                    </div>
                </div>
                <div class="box mt-4" v-if="status == 'pending'">
                    <h3 class="mb-0 fw-bold">List Driver</h3>
                    <div class="mt-4">
                        <div class="overflow-scroll" style="height: 315px">
                            <div
                                v-for="(item, index) in dataDriver"
                                :key="index"
                                class="d-flex align-items-center mb-4"
                            >
                                <div class="flex-shrink-0">
                                    <img
                                        v-if="item.avatar_url"
                                        class="user-avatar-sidebar"
                                        :src="item.avatar_url"
                                        alt="avatar"
                                    />
                                    <img
                                        v-else
                                        class="user-avatar-sidebar"
                                        src="../../assets/user.jpg"
                                        alt="avatar"
                                    />
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <div class="fw-semibold size-16">
                                        {{ item.name }}
                                    </div>
                                    <div>{{ item.phone }}</div>
                                </div>
                                <div
                                    class="d-flex flex-grow-1 ms-3 justify-content-end me-2"
                                >
                                    <button
                                        @click="assignDriver(item.id)"
                                        class="btn btn-green btn-sm px-4 radius"
                                    >
                                        Assign
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import moment from "moment";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";

export default {
    components: {
        Viewer,
    },
    data() {
        return {
            data: {},
            picture: [
                "https://i.postimg.cc/W30P2QDw/Rectangle-8531.png",
                "https://i.postimg.cc/W30P2QDw/Rectangle-8531.png",
                "https://i.postimg.cc/W30P2QDw/Rectangle-8531.png",
            ],
            status: "",
            dataCustomer: this.$route.params.dataCustomer,
            dataDriver: [],
            markers: [
                {
                    lat: 0,
                    lng: 0,
                },
            ],
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
        };
    },
    created() {
        this.getTrackingListDetail();
        this.getAccountList();
    },
    methods: {
        dateFormat(date) {
            return moment(date).format("DD MMMM YYYY");
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        getTrackingListDetail() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/trawltruck/order/detail`,
                {
                    params: { order_id: this.$route.params.id },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data;
                    this.status = this.data.status;
                    if (this.data?.driver?.location != null) {
                        this.markers = [
                            {
                                lat: Number(this.data.driver.location.lat),
                                lng: Number(this.data.driver.location.lon),
                            },
                        ];
                    }
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
        getAccountList() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/trawltruck/driver/list-individual`,
                {
                    params: {},
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.dataDriver = res.data.data.list_data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
        assignDriver(id) {
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/admin/trawltruck/order/assign`,
                {
                    user_id: id,
                    order_id: this.data.id,
                },
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Driver berhasil di Assign",
                    });
                    window.location.href = "/trawltruck/order/tracking";
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.lg {
    width: auto;
    height: 128px;
    display: block;
}
.md {
    width: auto;
    height: auto;
    display: block;
}
.user-avatar-sidebar {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border: 2px solid #f8f9fa;
    border-radius: 50px;
}
.image-product {
    border-radius: 10px;
    border: 2px solid #e2e3e5;
    width: 100%;
    height: 120px;
    object-fit: cover;
    cursor: pointer;
}
</style>
