<template>
  <div>{{ countDown }}</div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    date: {
      type: String,
      default: "",
    },
    level: {
      type: Number,
      default: 0,
    },
    isLate: {
      type: Boolean,
      default: null,
    },
    done: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      countDown: "",
    };
  },
  // mounted() {
  //   this.getCountDown();
  // },
  created() {
    this.getCountDown();
  },
  methods: {
    getCountDown() {
      if (!this.date || this.isLate == false) {
        this.countDown = "-";
        return;
      }
      const exp = moment(this.date);

      setTimeout(() => {
        let now = moment();

        let distance = exp - now;
        let diffDuration = moment.duration(exp.diff(now));
        if (this.isLate) {
          if (this.done) {
            let done = moment(this.done);
            let late = exp - done;
            this.countDown = moment(late).format("HH:MM:SS");
          } else if (this.level == 3 && distance <= 0) {
            diffDuration = moment.duration(now.diff(exp));
            this.countDown = `${diffDuration.hours()} : ${diffDuration.minutes()} : ${diffDuration.seconds()}`;
          }
          // if (diffDuration.hours() > 24) {
          //   this.countDown = "+24jam";
          // }
        } else {
          if (distance > 0) {
            this.countDown = `${diffDuration.hours()} : ${diffDuration.minutes()} : ${diffDuration.seconds()}`;
          }
          if (distance <= 0) {
            this.countDown = `00 : 00 : 00`;
          }
        }
        this.getCountDown();
      }, 1000);
    },
  },
};
</script>
