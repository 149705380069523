<template>
    <div class="padding-container">
        <h3 class="mb-0 fw-bold">Dashboard Troben Truk Operation</h3>
        <div class="row">
            <div class="col-md-4 mt-4">
                <a href="/trawltruck/ho/partner">
                    <div class="box mitra no-shadow">
                        <div class="d-flex align-items-center">
                            <div>
                                <img
                                    src="../../assets/dashboard/mitra.png"
                                    width="100%"
                                    alt=""
                                />
                            </div>
                            <div class="ms-auto text-main-color text-right">
                                <div class="fw-bold">Total Mitra</div>
                                <div
                                    class="size-26 fw-bold line-height-25 mt-2"
                                >
                                    {{
                                        dataOprtaions.overview.partner
                                            .not_active +
                                        dataOprtaions.overview.partner.active
                                    }}
                                    Mitra
                                </div>
                                <div
                                    class="size-26 fw-bold text-green-dashboard line-height-custom"
                                >
                                    {{ dataOprtaions.overview.partner.active }}
                                    Aktif
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-4 mt-4">
                <a href="/trawltruck/ho/fleet">
                    <div class="box truk no-shadow">
                        <div class="d-flex align-items-center">
                            <div>
                                <img
                                    src="../../assets/dashboard/truk.png"
                                    width="100%"
                                    alt=""
                                />
                            </div>
                            <div class="ms-auto text-main-color text-right">
                                <div class="fw-bold">Total Truk</div>
                                <div
                                    class="size-26 fw-bold line-height-25 mt-2"
                                >
                                    {{
                                        dataOprtaions.overview.truck
                                            .not_active +
                                        dataOprtaions.overview.truck.active
                                    }}
                                    Truk
                                </div>
                                <div
                                    class="size-26 fw-bold text-green-dashboard line-height-custom"
                                >
                                    {{ dataOprtaions.overview.truck.active }}
                                    Aktif
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-4 mt-4">
                <a href="/trawltruck/ho/driver">
                    <div class="box driver no-shadow">
                        <div class="d-flex align-items-center">
                            <div>
                                <img
                                    src="../../assets/dashboard/driver.png"
                                    width="100%"
                                    alt=""
                                />
                            </div>
                            <div class="ms-auto text-main-color text-right">
                                <div class="fw-bold">Total Driver</div>
                                <div
                                    class="size-26 fw-bold line-height-25 mt-2"
                                >
                                    {{
                                        dataOprtaions.overview.driver
                                            .not_active +
                                        dataOprtaions.overview.driver.active
                                    }}
                                    Driver
                                </div>
                                <div
                                    class="size-26 fw-bold text-green-dashboard line-height-custom"
                                >
                                    {{ dataOprtaions.overview.driver.active }}
                                    Aktif
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-6 mt-5">
                <div class="box pb-0">
                    <div class="d-flex align-items-center">
                        <h5 class="mb-0 fw-bold">Orderan Belum Terambil</h5>
                        <div class="ms-auto">
                            <a
                                href="/trawltruck/ho/order"
                                class="size-14 text-dark"
                            >
                                Lihat Semua
                            </a>
                        </div>
                    </div>
                    <hr />
                    <div class="scroll-small-box">
                        <template v-if="dataOprtaions.order_pending.length > 0">
                            <template
                                v-for="(
                                    data, index
                                ) in dataOprtaions.order_pending"
                            >
                                <div :key="index">
                                    <a
                                        :href="
                                            '/trawltruck/ho/order/detail/' +
                                            data.order_id
                                        "
                                        class="text-dark"
                                    >
                                        <div
                                            class="d-flex align-items-center no-flex"
                                        >
                                            <div
                                                class="box me-3 no-shadow border-gray p-3 m-box-truck"
                                            >
                                                <img
                                                    :src="data.fleet.picture"
                                                    width="45"
                                                    alt=""
                                                />
                                            </div>
                                            <div class="m-mt-1">
                                                <div class="size-16">
                                                    <span
                                                        class="text-green-dashboard size-16"
                                                    >
                                                        {{ data.receipt }}
                                                    </span>
                                                    -
                                                    <span class="size-16">
                                                        <template
                                                            v-if="
                                                                data.order_type ==
                                                                'quick'
                                                            "
                                                        >
                                                            Cepat
                                                        </template>
                                                        <template
                                                            v-if="
                                                                data.order_type ==
                                                                'schedule'
                                                            "
                                                        >
                                                            Terjadwal
                                                        </template>
                                                        <template
                                                            v-if="
                                                                data.order_type ==
                                                                'fullday'
                                                            "
                                                        >
                                                            Seharian
                                                        </template>
                                                    </span>
                                                    <i
                                                        class="fa fa-circle size-13 mx-2 text-red-dashboard"
                                                    ></i>
                                                </div>
                                                <div class="mt-2 size-16">
                                                    {{ data.fleet.name }} -
                                                    {{ data.created_at }}
                                                </div>
                                            </div>
                                            <div class="ms-auto">
                                                <div
                                                    class="address-limit-truck"
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="text-gray-dashboard"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        :title="
                                                            data.origin_regency +
                                                            ' - ' +
                                                            data.origin_destination
                                                        "
                                                    >
                                                        <span
                                                            class="limit-one-line"
                                                        >
                                                            {{
                                                                data.origin_regency
                                                            }}
                                                            -
                                                            {{
                                                                data.origin_destination
                                                            }}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div
                                                    class="text-right text-gray-dashboard mt-2 no-right"
                                                >
                                                    Order -
                                                    {{
                                                        moment(
                                                            data.order_date
                                                        ).format("DD MMM YYYY")
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <hr />
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <div class="text-center mb-5 mt-5">
                                <img
                                    src="../../assets/no-data.png"
                                    width="90"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-5">
                <div class="box pb-0">
                    <div class="d-flex align-items-center">
                        <h5 class="mb-0 fw-bold">Orderan Sedang Berlangsung</h5>
                        <div class="ms-auto">
                            <a
                                href="/trawltruck/ho/order"
                                class="size-14 text-dark"
                            >
                                Lihat Semua
                            </a>
                        </div>
                    </div>
                    <hr />
                    <div class="scroll-small-box">
                        <template v-if="dataOprtaions.order_ongoing.length > 0">
                            <template
                                v-for="(
                                    data, index
                                ) in dataOprtaions.order_ongoing"
                            >
                                <div :key="index">
                                    <a
                                        :href="
                                            '/trawltruck/ho/order/detail/' +
                                            data.order_id
                                        "
                                        class="text-dark"
                                    >
                                        <div class="d-flex align-items-center">
                                            <div
                                                class="box me-3 no-shadow border-gray p-3 m-box-truck"
                                            >
                                                <img
                                                    :src="data.fleet.picture"
                                                    width="45"
                                                    alt=""
                                                />
                                            </div>
                                            <div class="m-mt-1">
                                                <div class="size-16">
                                                    <span
                                                        class="text-green-dashboard size-16"
                                                        >{{
                                                            data.receipt
                                                        }}</span
                                                    >
                                                    -
                                                    <span class="capitalize">
                                                        <template
                                                            v-if="
                                                                data.order_type ==
                                                                'quick'
                                                            "
                                                        >
                                                            Cepat
                                                        </template>
                                                        <template
                                                            v-if="
                                                                data.order_type ==
                                                                'schedule'
                                                            "
                                                        >
                                                            Terjadwal
                                                        </template>
                                                        <template
                                                            v-if="
                                                                data.order_type ==
                                                                'fullday'
                                                            "
                                                        >
                                                            Seharian
                                                        </template>
                                                    </span>
                                                </div>
                                                <div class="mt-2 size-16">
                                                    {{ data.fleet.name }}
                                                </div>
                                            </div>
                                            <div class="ms-auto">
                                                <div
                                                    class="address-limit-truck"
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="text-gray-dashboard"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        :title="
                                                            data.origin_regency +
                                                            ' - ' +
                                                            data.origin_destination
                                                        "
                                                    >
                                                        <span
                                                            class="limit-one-line"
                                                        >
                                                            {{
                                                                data.origin_regency
                                                            }}
                                                            -
                                                            {{
                                                                data.origin_destination
                                                            }}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div
                                                    class="text-right text-gray-dashboard mt-2 no-right"
                                                >
                                                    Order -
                                                    {{
                                                        moment(
                                                            data.order_date
                                                        ).format("DD MMM YYYY")
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <hr />
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <div class="text-center mb-5 mt-5">
                                <img
                                    src="../../assets/no-data.png"
                                    width="90"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-4">
                <div class="box pb-0">
                    <div class="d-flex align-items-center">
                        <h5 class="mb-0 fw-bold">Orderan Terjadwal</h5>
                        <div class="ms-auto">
                            <a
                                href="/trawltruck/ho/order"
                                class="size-14 text-dark"
                            >
                                Lihat Semua
                            </a>
                        </div>
                    </div>
                    <hr />
                    <div class="scroll-small-box">
                        <template
                            v-if="dataOprtaions.order_scheduled.length > 0"
                        >
                            <template
                                v-for="(
                                    data, index
                                ) in dataOprtaions.order_scheduled"
                            >
                                <div :key="index">
                                    <a
                                        :href="
                                            '/trawltruck/ho/order/detail/' +
                                            data.order_id
                                        "
                                        class="text-dark"
                                    >
                                        <div
                                            class="d-flex align-items-center no-flex"
                                        >
                                            <div
                                                class="box me-3 no-shadow border-gray p-3 m-box-truck"
                                            >
                                                <img
                                                    :src="data.fleet.picture"
                                                    width="45"
                                                    alt=""
                                                />
                                            </div>
                                            <div class="m-mt-1">
                                                <div class="size-16">
                                                    <span
                                                        class="text-green-dashboard size-16"
                                                        >{{
                                                            data.receipt
                                                        }}</span
                                                    >
                                                    -
                                                    <span class="capitalize">{{
                                                        data.order_type
                                                    }}</span>
                                                </div>
                                                <div class="mt-2 size-16">
                                                    {{ data.fleet.name }}
                                                </div>
                                            </div>
                                            <div class="ms-auto">
                                                <div
                                                    class="address-limit-truck"
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="text-gray-dashboard"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        :title="
                                                            data.origin_regency +
                                                            ' - ' +
                                                            data.origin_destination
                                                        "
                                                    >
                                                        <span
                                                            class="limit-one-line"
                                                        >
                                                            {{
                                                                data.origin_regency
                                                            }}
                                                            -
                                                            {{
                                                                data.origin_destination
                                                            }}
                                                        </span>
                                                    </a>
                                                </div>
                                                <div
                                                    class="text-right text-gray-dashboard mt-2 no-right"
                                                >
                                                    Order -
                                                    {{
                                                        moment(
                                                            data.created_at
                                                        ).format("DD MMM YYYY")
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <hr />
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <div class="text-center pb-5 mt-5">
                                <img
                                    src="../../assets/no-data.png"
                                    width="90"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-4">
                <div class="box pb-0">
                    <h5 class="mb-0 fw-bold">Driver Bertugas</h5>
                    <hr />
                    <div class="scroll-small-box">
                        <template
                            v-if="dataOprtaions.driver_on_duty.length > 0"
                        >
                            <template
                                v-for="(
                                    data, index
                                ) in dataOprtaions.driver_on_duty"
                            >
                                <div :key="index">
                                    <a
                                        :href="
                                            '/trawltruck/ho/order/detail/' +
                                            data.order_id
                                        "
                                        class="text-dark"
                                    >
                                        <div class="d-flex align-items-center">
                                            <div
                                                class="user-dashboard me-3"
                                                :style="{
                                                    'background-image':
                                                        'url(' +
                                                        data.driver.picture +
                                                        ')',
                                                }"
                                            ></div>
                                            <div class="">
                                                <div
                                                    class="size-16 fw-bold capitalize"
                                                >
                                                    {{ data.driver.name }}
                                                </div>
                                                <div class="mt-tag">
                                                    <small
                                                        class="tag yellow radius capitalize"
                                                    >
                                                        {{ data.status }}
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="ms-auto">
                                                <div
                                                    class="text-right text-gray-dashboard mt-2"
                                                >
                                                    {{
                                                        moment(
                                                            data.date
                                                        ).format("DD MMMM YYYY")
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <hr />
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <div class="text-center pb-5 mt-5">
                                <img
                                    src="../../assets/no-data.png"
                                    width="90"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-4">
                <div class="box scroll-small-box pb-0">
                    <h5 class="mb-0 fw-bold">Truk Balik Stand By</h5>
                    <hr />
                    <template v-if="dataOprtaions.truck_standby.length > 0">
                        <template
                            v-for="(data, index) in dataOprtaions.truck_standby"
                        >
                            <div :key="index">
                                <div class="row">
                                    <div class="col-md-7">
                                        <div class="d-flex align-items-center">
                                            <div
                                                class="box me-3 no-shadow border-gray p-3"
                                            >
                                                <img
                                                    :src="data.fleet.picture"
                                                    width="45"
                                                    alt=""
                                                />
                                            </div>
                                            <div class="">
                                                <div class="size-16 fw-bold">
                                                    {{ data.driver }}
                                                </div>
                                                <div class="mt-2 size-16">
                                                    {{ data.fleet.name }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div
                                            class="text-right text-gray-dashboard"
                                        >
                                            {{ data.partner }}
                                        </div>
                                        <div
                                            class="text-right text-gray-dashboard mt-2"
                                        >
                                            {{ data.regency }} (Stand By)
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="text-center pb-5 mt-5">
                            <img
                                src="../../assets/no-data.png"
                                width="90"
                                alt=""
                            />
                            <h4 class="mb-0 fw-bold mt-4 text-gray">
                                Data Kosong
                            </h4>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
import { Tooltip } from "bootstrap";

export default {
    components: {},
    name: "Dashboard-Trawltruck HO",
    data() {
        return {
            moment: moment,
            params: {
                partner_type: "",
            },
            chartOptions: {
                xaxis: {
                    categories: [],
                    labels: {
                        formatter: function (val) {
                            return moment(val, "YYYY-MM-DD").format("MMM");
                            // return 'Tgl ' + val
                        },
                    },
                },
                colors: ["#E60013"],
                dataLabels: {
                    enabled: false,
                },
            },
            series: [
                {
                    name: "Pendapatan",
                    data: [],
                },
            ],
            req: {
                month: moment(new Date()).format("MMMM YYYY"),
                province: {
                    id: "",
                },
                district: {
                    id: "",
                },
                order_by: "desc",
                date_current: moment(new Date()).format("DD MMMM YYYY"),
            },
            is_data_ready: false,
            dataOprtaions: {
                driver_on_duty: [],
                order_ongoing: [],
                order_pending: [],
                order_scheduled: [],
                truck_standby: [],
                overview: {
                    driver: {},
                    partner: {},
                    truck: {},
                },
                year_month: null,
            },
        };
    },
    created() {
        // this.getDatas()
        this.getDashboardOprations();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    methods: {
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        currencyFormatNoRp(num) {
            return parseInt(num)
                .toFixed(0)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        },
        getDashboardOprations() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/dashboard-ops`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.dataOprtaions = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped>
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.active {
    background-color: #e60013 !important;
}
.box.mitra {
    background-color: #faccd0;
}
.box.truk {
    background-color: #feead4;
}
.box.driver {
    background-color: #d8e7d3;
}
.box.blue-light {
    background-color: #daecf6;
}
.text-main-color {
    color: #24516a !important;
}
.text-green-dashboard {
    color: #3d8824;
}
.text-green-bold {
    color: #3d8824;
}
.text-red-dashboard {
    color: #e60013;
}
.text-gray-dashboard {
    color: #61616a;
}
.text-right {
    text-align: right;
}
.line-height-custom {
    line-height: 30px;
}
.radius {
    border-radius: 50px;
}
.user-dashboard {
    width: 45px;
    height: 45px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
    border: 2px solid #f7f7f7;
}
.mt-tag {
    margin-top: 5px;
}
.size-h1 {
    font-size: 2.5rem;
}
.scroll-small-box {
    max-height: 320px;
    overflow-y: auto;
    overflow-x: hidden;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
}
</style>
