<template>
    <div>
        <div class="notif-body" v-if="showNotification">
            <div
                class="close-notif cursor-pointer"
                @click="closeNotification()"
            >
                <i class="fa fa-times"></i>
            </div>
            <div class="d-flex align-items-center">
                <div class="me-3">
                    <img
                        :src="notificationMessage.image"
                        class="product-image"
                        alt=""
                    />
                </div>
                <div>
                    <div class="d-flex">
                        <div class="fw-semibold text-danger">
                            {{ notificationMessage?.title }}
                        </div>
                        <div class="ms-auto size-12 text-gray">Baru Saja</div>
                    </div>
                    <div>
                        {{ notificationMessage?.body }}
                    </div>
                </div>
            </div>
        </div>
        <component :is="layout" />
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/messaging";

export default {
    name: "App",
    computed: {
        layout() {
            let layoutVar = this.$route?.meta
                ? this.$route?.meta.layout
                : "default";
            if (layoutVar === undefined || layoutVar == "undefined") {
                layoutVar = "default";
            }
            return layoutVar + "-layout";
        },
    },
    data() {
        return {
            jwtToken: localStorage.getItem("token"),
            showNotification: false,
            notificationMessage: null,
            notificationTimeout: null,
        };
    },

    created() {
        if (
            this.jwtToken == null &&
            this.$route.name != "forgot-password" &&
            this.$route.name != "new-password" &&
            this.$route.name != "forgot-otp"
        ) {
            this.$router.push("/");
        }
        var Notification =
            window.Notification ||
            window.mozNotification ||
            window.webkitNotification;
        var was_questioned = false;
        if (Notification.permission == "default") {
            was_questioned = true;
        }
        Notification.requestPermission(function (permission) {
            if (was_questioned) {
                console.log("User was asked. New permission is: " + permission);
            }
            if ("permissions" in navigator) {
                navigator.permissions
                    .query({ name: "notifications" })
                    .then(function (notificationPerm) {
                        notificationPerm.onchange = function () {
                            console.log(
                                "User decided to change his seettings. New permission: " +
                                    notificationPerm.state
                            );
                        };
                    });
            }
        });

        try {
            firebase
                .messaging()
                .requestPermission()
                .then(() => {
                    console.log("Notification permission granted");
                    firebase
                        .messaging()
                        .getToken()
                        .then((token) => {
                            window.console.log("token ", token);
                            this.receiveMessage();
                        });
                })
                .catch((err) => {
                    console.log("Unable to get token ", err);
                });
        } catch (e) {
            console.log(e);
        }
    },
    filters: {
        formatExpTime(expTime) {
            const date = new Date(expTime * 1000);
            return date.toLocaleString();
        },
    },
    methods: {
        receiveMessage() {
            try {
                firebase.messaging().onMessage((payload) => {
                    this.notificationMessage = payload.data;
                    this.showNotification = true;
                    if (this.notificationTimeout) {
                        clearTimeout(this.notificationTimeout);
                    }
                    this.notificationTimeout = setTimeout(() => {
                        this.closeNotification();
                    }, 8000);
                    var audio = new Audio(require("@/assets/notif-sound.mp3"));
                    audio.play();
                });
            } catch (e) {
                console.log(e);
            }
        },
        closeNotification() {
            this.showNotification = false;
        },
    },
};
</script>

<style scoped>
.notif-body {
    width: 350px;
    padding: 25px 15px;
    border-left: 3px solid #3d8824;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 99999;
    background: #fff;
    box-shadow: 0 1px 12px -1px rgb(141 145 162 / 25%);
}
.close-notif {
    position: absolute;
    right: 8px;
    top: 5px;
    color: #1f1e2c;
}
.product-image {
    border-radius: 50px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    object-fit: cover;
}
</style>
