<template>
    <div class="padding-container">
        <div class="d-flex align-items-center bd-highlight">
            <div class="flex-grow-1 bd-highlight">
                <h3 class="fw-bold">
                    Data Pemasukan
                </h3>
            </div>
        </div>
        <div class="box mt-4">
            <div class="mt-4" v-if="is_header_ready">
                <CardLoader/>
            </div>
            <div class="row" v-else>
                <div class="col-md-4">
                    <div class="box no-shadow border-gray bc-gray">
                        <h5 class="fw-bold mb-0 text-gray">
                            Jumlah Transaksi
                        </h5>
                        <div class="fw-ekstra-bold size-24 mt-3">
                            {{ header.transaksi }}
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box no-shadow border-gray bc-gray">
                        <h5 class="fw-bold mb-0 text-gray">
                            Jumlah Pemasukan
                        </h5>
                        <div class="fw-ekstra-bold size-24 mt-3">
                            {{ currencyFormat(header.total_income) }}
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box no-shadow bc-gray">
                        <h5 class="fw-bold mb-0 text-gray">
                            Jumlah Pencairan
                        </h5>
                        <div class="fw-ekstra-bold size-24 mt-3">
                            {{ currencyFormat(header.total_disbursement) }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader/>
            </div>
            <div v-else>
                <div class="row mt-4">
                    <div class="col-md-6 mt-3">
                        <div class="position-relative">
                            <input type="text" class="form-control" placeholder="Cari id order" v-model="req.keyword" />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <input type="date" class="form-control" placeholder="Start date" v-model="req.start" @change="getIncomeList()" />
                    </div>
                    <div class="col-md-3 mt-3">
                        <input type="date" class="form-control" placeholder="End date" v-model="req.end" @change="getIncomeList()" />
                    </div>
                </div>

                <div class="modern-table-boxed mt-4" v-if="searchLists.length > 0">
                    <div class="d-table-row header">
                        <div class="d-table-cell">No</div>
                        <div class="d-table-cell">Id Order</div>
                        <div class="d-table-cell">Tanggal Order</div>
                        <div class="d-table-cell">Pendapatan</div>
                    </div>
                    <template v-for="(list, index) in searchLists">
                        <div class="d-table-row" :key="index">
                            <div class="d-table-cell fw-bold">
                                {{ index+1 }}.
                            </div>
                            <div class="d-table-cell">
                                {{ list.receipt }}
                            </div>
                            <div class="d-table-cell">
                                {{ moment(list.created_at).format('DD MMMM YYYY') }}
                            </div>
                            <div class="d-table-cell">
                                {{ currencyFormat(list.total) }}
                            </div>
                        </div>
                    </template>
                </div>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="">
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <b-pagination-nav v-if="searchLists.length > 0" :link-gen="linkGen" v-model="currentPage" :number-of-pages="lists.total_page" base-url="#" first-number align="right"></b-pagination-nav>
        </div>
    </div>
</template>

<script>

import Api from "../../api/Api";
import ListLoader from '../../components/ListLoader.vue';
import CardLoader from '../../components/CardLoader.vue';
import moment from 'moment'

export default {
    components: {
        ListLoader,
        CardLoader
    },
    name: "IncomeList",
    data() {
        return {
            moment:moment,
            req: {
                keyword: '',
                start: '',
                end: ''
            },
            is_list_ready: false,
            is_header_ready: false,
            lists: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0
            },
            header: {
                total_disbursement: 0,
                total_income: 0,
                transaksi: 0
            },
            per_page: 10,
            currentPage: 0,
        };
    },
    created() {
        this.getIncomeList()
        this.getHeaderData()
    },
    mounted() {
    },
    computed: {
        searchLists() {
            return this.lists.list_data.filter((item) => {
                return item.receipt.toLowerCase().includes(this.req.keyword.toLowerCase());
            });
        }
    },
    methods: {
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        getIncomeList() {
            this.is_list_ready = true
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/wallet/orders?start_date=${this.req.start}&end_date=${this.req.end}`, {
                params: { per_page: this.per_page, page: this.$route.query.page},
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                var data = res.data.data
                this.lists = data
                this.is_list_ready = false
            })
            .catch((err) => {
                console.log(err);
                this.is_list_ready = false
            });
        },
        getHeaderData() {
            this.is_header_ready = true
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/wallet/dashboard`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                var data = res.data.data
                this.header = data
                this.is_header_ready = false
            })
            .catch((err) => {
                console.log(err);
                this.is_header_ready = false
            });
        },
        currencyFormat(num) {
            return (
                "Rp. " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
    },
};
</script>

<style>
</style>