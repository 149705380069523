var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-wrapper chiller-theme toggled"},[_vm._m(0),_c('nav',{staticClass:"sidebar-wrapper",attrs:{"id":"sidebar"}},[_c('div',{staticClass:"sidebar-content"},[_vm._m(1),_c('div',{staticClass:"sidebar-header"},[_c('div',{staticClass:"mt-2"},[_c('center',[_c('img',{staticClass:"radius",attrs:{"src":require("../assets/user.jpg"),"width":"50"}}),_c('div',{staticClass:"mt-3 fw-bold"},[_vm._v("Users")]),_c('div',{staticClass:"size-14"},[_vm._v("Admin Trobens")])])],1)]),_c('div',{staticClass:"sidebar-menu"},[_c('ul',[_vm._m(2),_c('li',{staticClass:"sidebar-dropdown"},[_c('router-link',{class:{
                                '': true,
                                active: this.$route.name == 'Dashboard',
                            },attrs:{"to":{ name: 'Dashboard' }}},[_c('i',{staticClass:"fa fa-home"}),_c('span',[_vm._v("Dashboard")])])],1),_c('li',{staticClass:"sidebar-dropdown"},[_c('router-link',{class:{
                                '': true,
                                active: this.$route.name == 'Chat',
                            },attrs:{"to":{ name: 'Chat' }}},[_c('i',{staticClass:"fa fa-wechat"}),_c('span',[_vm._v("Chat")])])],1),_vm._m(3),_c('li',{staticClass:"sidebar-dropdown"},[_c('router-link',{class:{
                                '': true,
                                active:
                                    this.$route.name ==
                                    'admin-customer-payment',
                            },attrs:{"to":{ name: 'admin-customer-payment' }}},[_c('i',{staticClass:"fa fa-money"}),_c('span',[_vm._v("Customer Payment")])])],1)])])]),_c('div',{staticClass:"sidebar-footer"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.logout()}}},[_c('i',{staticClass:"fa fa-power-off me-1"}),_vm._v(" Logout ")])])]),_c('main',{staticClass:"page-content"},[_c('router-view')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn btn-sm btn-primary",attrs:{"id":"show-sidebar","href":"#"}},[_c('i',{staticClass:"fa fa-angle-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-brand"},[_c('div',{attrs:{"id":"close-sidebar"}},[_c('i',{staticClass:"fa fa-angle-left"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"header-menu mb-3 mt-3"},[_c('span',[_vm._v("Trobens Page")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"sidebar-dropdown"},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-bar-chart"}),_c('span',[_vm._v("Chat Report")])])])
}]

export { render, staticRenderFns }