var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"padding-container"},[_c('div',{staticClass:"box mt-4 m-mt-0"},[_vm._m(0),_c('div',{staticClass:"row mt-5 m-mt-2"},[_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"position-relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.req.search),expression:"req.search"}],staticClass:"form-control form-control-lg",attrs:{"type":"text","placeholder":"Cari data..."},domProps:{"value":(_vm.req.search)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.req, "search", $event.target.value)},function($event){return _vm.changeSearch()}]}}),_vm._m(1)])])]),(_vm.is_list_ready)?_c('div',{staticClass:"mt-4"},[_c('ListLoader')],1):_c('div',[(_vm.list.list_data.length > 0)?[_c('div',{staticClass:"table-responsive-custom"},[_c('table',{staticClass:"table table-bordered mt-4"},[_vm._m(2),_c('tbody',[_vm._l((_vm.list.list_data),function(data,index){return [_c('tr',{key:index},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.regency.name)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.partner.name)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.driver.name)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.status_label)+" ")]),_c('td',{staticClass:"text-center"},[(data.status != 'returned')?[_c('a',{attrs:{"href":'/trawltruck/ho/truck-stand-by/edit/' +
                                                    data.id}},[_c('i',{staticClass:"fa fa-pencil"})])]:[_vm._m(3,true)]],2)])]})],2)])])]:[_vm._m(4)]],2),(_vm.list.list_data.length > 0)?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ms-auto"},[_c('div',{staticClass:"d-flex mt-4"},[_c('div',{staticClass:"me-3"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.per_page),expression:"per_page"}],staticClass:"form-control form-select w-select",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.per_page=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getList()}]}},[_c('option',{attrs:{"value":"10"}},[_vm._v("10")]),_c('option',{attrs:{"value":"25"}},[_vm._v("25")]),_c('option',{attrs:{"value":"50"}},[_vm._v("50")]),_c('option',{attrs:{"value":"100"}},[_vm._v("100")])])]),_c('b-pagination-nav',{attrs:{"number-of-pages":_vm.list.total_page,"base-url":"#","first-number":"","align":"right"},on:{"input":_vm.changePage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('h3',{staticClass:"fw-bold"},[_vm._v("List Truk Stand By")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"search-icon"},[_c('i',{staticClass:"fa fa-search"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"table-light"},[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("No")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Kota Stand By ")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Nama Mitra ")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Nama Driver ")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Status ")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Action ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-gray cursor-drop",attrs:{"href":"javascript:void"}},[_c('i',{staticClass:"fa fa-pencil"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mt-section"},[_c('img',{attrs:{"src":require("../../assets/no-data.png"),"width":"100","alt":""}}),_c('h4',{staticClass:"mb-0 fw-bold mt-4 mb-4 text-gray"},[_vm._v(" Data Kosong ")])])
}]

export { render, staticRenderFns }