<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/trawltruck/ho/truck-stand-by" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">
                    Ubah Data Truk Stand By
                </h3>
            </div>
            <div class="row mt-5">
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Kota Stand By</label>
                    <input type="text" class="form-control" disabled v-model="req.regency.name">
                </div>
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Nama Mitra</label>
                    <input type="text" class="form-control" disabled v-model="req.partner.name">
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nama Driver</label>
                    <input type="text" class="form-control" disabled v-model="req.driver.name">
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Status</label>
                    <select class="form-control form-select" v-model="req.status">
                        <option value="">Pilih Status</option>
                        <option value="standby">{{ req.status_label }}</option>
                        <option value="returned">Kembali Ke Kota Asal</option>
                    </select>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <!-- <a href="/trawltruck/ho/partner" class="btn btn-outline-black btn-lg px-5 me-3">Batal</a> -->
                            <a href="/trawltruck/ho/truck-stand-by" class="btn btn-outline-black btn-lg px-5 me-3">Batal</a>
                            <button class="btn btn-green btn-lg px-5" type="button" disabled v-if="is_update">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Update
                            </button>
                            <button class="btn btn-green btn-lg px-5" @click="updateData()" :disabled="req.status == 'standby' || req.status == ''" v-else>
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img src="../../assets/dashboard/checklis.png" width="80" alt="">
                    <h4 class="mb-0 fw-bold mt-4">
                        Perubahan Data Berhasil!
                    </h4>
                    <div class="mt-2">
                        Selamat! Anda berhasil merubah data.
                    </div>
                </div>
                <div class="mt-4">
                    <a href="/trawltruck/ho/truck-stand-by" class="btn btn-green w-100 btn-lg">
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from "../../api/Api";

    export default {
        name: "ManagementTruckStandByEdit",
        data() {
            return {
                req: {
                },
                modalSuccess: false,
                is_update: false
            };
        },
        created(){
            this.getDetail()
        },
        methods: {
            getDetail() {
                Api.get(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/standby/detail?id=${this.$route.params.id}`, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    var data = res.data.data
                    this.req = data
                })
                .catch((err) => {
                    console.log(err);
                });
            },
            updateData(){
                this.is_update = true
                var data = {
                    id: this.req.id,
                    status: this.req.status
                }

                Api.post(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/standby/update`, data, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    }
                })
                .then((res) => {
                    this.is_update = false
                    this.modalSuccess = true
                    console.log(res)
                })
                .catch((err) => {
                    this.is_update = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.message
                    });
                })
            },
        }
    };
</script>

<style scoped>
    .text-main-color{
        color: #24516A !important;
    }
    .text-gray-dashboard{
        color: #61616A;
    }
</style>