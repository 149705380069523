<template>
  <div class="padding-container">
    <div class="d-flex align-items-center">
      <h3 class="mb-0 fw-bold">Barang Gudang</h3>
      <select class="select-status mt-1 ms-2 py-1 ps-1" v-model="type" @change="searchData">
        <option value="arrival" selected>Kedatangan</option>
        <option value="departure">Keberangkatan</option>
      </select>
      <div class="ms-auto">
        <div class="position-relative">
          <input class="form-control" v-model="selectMonth" type="month" @change="searchMonth" style="border: 1px solid #48a2d4" placeholder="Pilih Bulan" />
          <span class="search-icon">
            <img src="../../assets/calendar-icon.png" />
          </span>
        </div>
      </div>
    </div>
    <div class="box mt-4 gap-2">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <img style="width: 36px; height: 36px" src="../../assets/pie-chart-icon.png" />
          <div class="fw-bold ms-2">Mitra Business</div>
        </div>
        <div class="text-end">{{ monthFormat(selectMonth) }}</div>
      </div>
      <div class="d-flex mt-4" style="gap: 30%">
        <div class="d-flex flex-column">
          <div class="text-gray">Data Barang Masuk</div>
          <div class="fw-bold size-16">{{ currentItem }} Koli</div>
        </div>
        <div class="d-flex flex-column">
          <div class="text-gray">Barang Masuk Sebelumnya</div>
          <div class="fw-bold size-16">{{ previousItem }} Koli</div>
        </div>
        <div class="d-flex flex-column">
          <div class="text-gray">Peningkatan</div>
          <div class="fw-bold size-16">{{ enhancement }} Koli</div>
        </div>
      </div>
    </div>
    <div class="box mt-3">
      <div class="d-flex justify-content-between mb-2">
        <div class="fw-bold">Barang Masuk</div>
        <div>{{ monthFormat(selectMonth) }}</div>
      </div>
      <apexcharts ref="chartmonthly" width="100%" height="350" type="bar" :options="chartOptions" :series="chartSeries"></apexcharts>
    </div>
    <div class="box mt-3">
      <div class="d-flex bd-highlight">
        <div class="flex-grow-1 bd-highlight">
          <h4 class="fw-bold">List Barang di Gudang</h4>
        </div>
        <div class="row">
          <div class="col-md-6">
            <select name="" class="form-control form-select" v-model="status" @change="searchData" style="width: 125px">
              <option value="" selected>Filter Status</option>
              <option value="packed">Packed</option>
              <option value="packing">Packing</option>
              <option value="waiting_for_estimating">Ukur Timbang</option>
              <option value="estimating">Estimating</option>
              <option value="in_transit">Transit</option>
            </select>
          </div>
        </div>
      </div>
      <div class="mt-4" v-if="is_my_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">Tanggal</div>
            <div class="d-table-cell">Nomor Resi</div>
            <div class="d-table-cell">Jumlah Koli</div>
            <div class="d-table-cell">Total Berat Barang</div>
            <div class="d-table-cell">Status Barang</div>
          </div>
          <div class="d-table-row" v-for="(item, index) in data" :key="index">
            <div class="d-table-cell">{{ itemNumber(index) }}</div>
            <div class="d-table-cell">
              {{ item.updated_at }}
            </div>
            <div class="d-table-cell">
              {{ item.code }}
            </div>
            <div class="d-table-cell">{{ item.total_qty }} Koli</div>
            <div class="d-table-cell">{{ item.total_weight }} Kg</div>
            <div class="d-table-cell">
              <!-- <div v-if="item.status == 'packed'" class="tag green"> {{ setStatus(item.status) }} </div>
              <div v-if="item.status == 'packing'" class="tag green"> {{ setStatus(item.status) }} </div>
              <div v-if="item.status == 'waiting_for_estimating'" class="tag green"> {{ setStatus(item.status) }} </div>
              <div v-if="item.status == ''" class="tag green"> {{ setStatus(item.status) }} </div> -->
              <div :class="checkStat(item.status)">{{ setStatus(item.status) }}</div>
            </div>
          </div>
        </div>
        <div v-if="dataItem.length == 0" class="text-center mt-section">
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
    </div>
    <div class="overflow-auto mt-3">
      <b-pagination-nav v-if="data.length > 0" v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav>
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";

export default {
  components: {
    apexcharts: VueApexCharts,
    ListLoader,
  },
  name: "Dashboard-MB",
  data() {
    return {
      moment: moment,
      is_my_list_ready: false,
      type: "arrival",
      status: "",
      selectMonth: moment(new Date()).format("YYYY-MM"),
      data: "",
      dataSummary: "",
      date_income: [],
      val_income: [],
      currentPage: 0,
      totalPage: 0,
      limit: 10,
      dataItem: [
        {
          date: "12 Aug 2022",
          receipt: "TRB000000009",
          total: "600.000 Koli",
          weight: "500 Kg",
          status: "Ukur Timbang",
        },
        {
          date: "12 Aug 2022",
          receipt: "TRB000000009",
          total: "600.000 Koli",
          weight: "500 Kg",
          status: "Ukur Timbang",
        },
        {
          date: "12 Aug 2022",
          receipt: "TRB000000009",
          total: "600.000 Koli",
          weight: "500 Kg",
          status: "Ukur Timbang",
        },
        {
          date: "12 Aug 2022",
          receipt: "TRB000000009",
          total: "600.000 Koli",
          weight: "500 Kg",
          status: "Ukur Timbang",
        },
      ],
      chartOptions: {
        xaxis: {
          categories: [new Date()],
          labels: {
            formatter: function (val) {
              return "Tgl " + moment(val, "YYYY-MM-DD").format("DD");
            },
          },
        },
        colors: ["#48A2D4"],
        dataLabels: {
          enabled: false,
        },
      },
      chartSeries: [
        {
          name: "Pendapatan",
          data: [0],
        },
      ],
    };
  },
  created() {
    this.getData();
    this.getSummary();
  },
  methods: {
    searchData() {
      this.getData();
      this.getSummary();
    },
    searchMonth() {
      this.getSummary();
    },
    changePage() {
      this.getData();
    },
    getData() {
      this.is_my_list_ready = true;
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/dashboard/owner/item-into-warehouse`, {
        params: { page: this.currentPage == 0 || this.currentPage == null ? 1 : this.currentPage, limit: this.limit, type: this.type, status: this.status },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data = res.data.data;
          this.totalPage = res.data.last_page;
          this.is_my_list_ready = false;
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.is_my_list_ready = false;
        });
    },
    getSummary() {
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/dashboard/owner/summary/item-into-warehouse`, {
        params: { date: this.selectMonth == moment(new Date()).format("YYYY-MM") ? moment(new Date()).format("MM-YYYY") : moment(this.selectMonth).format("MM-YYYY"), type: this.type },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.dataSummary = res.data.data;
          this.dataSummary.item_join_perday.forEach((el) => {
            if (el.date == null || el.qty == null) {
              return;
            }
            this.date_income.push(el.date);
            this.val_income.push(el.qty);
          });

          let minNumber = Math.min(...this.val_income);
          minNumber = parseInt(minNumber - minNumber * 0.1);
          minNumber = 0;

          if (this.dataSummary.item_join_perday.length > 0) {
            this.chartOptions.xaxis.categories = this.date_income;
            this.chartSeries[0].data = this.val_income;
            this.$refs.chartmonthly.updateSeries([
              {
                name: "Pendapatan",
                data: this.val_income,
              },
            ]);

            let that = this;

            this.$refs.chartmonthly.updateOptions({
              xaxis: {
                categories: this.date_income,
              },
              yaxis: {
                min: minNumber,
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return that.currencyFormat(val) + " Kg";
                  },
                },
              },
            });
          }
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
        });
    },
    checkStat(status) {
      if (status == "packed") return "tag green";
      if (status == "packing") return "tag yellow";
      if (status == "waiting_for_estimating") return "tag green";
      if (status == "estimating") return "tag green";
      if (status == "in_transit") return "tag blue";
      else return "tag green";
    },
    setStatus(status) {
      if (status == "packed") return "Packed";
      if (status == "packing") return "Packing";
      if (status == "waiting_for_estimating") return "Ukur Timbang";
      if (status == "estimating") return "Estimating";
      if (status == "in_transit") return "Transit";
      else return status;
    },
    dateFormat(date) {
      return moment(date).format("DD MMM YYYY");
    },
    currencyFormat(num) {
      return parseInt(num)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
    monthFormat(date) {
      return moment(date).format("MMMM");
    },
    itemNumber(index) {
      return index + this.startIndex + 1;
    },
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.limit;
    },
    currentItem() {
      return this.dataSummary.total_current_item ? this.dataSummary.total_current_item : 0;
    },
    previousItem() {
      return this.dataSummary.total_previous_item ? this.dataSummary.total_previous_item : 0;
    },
    enhancement() {
      return this.dataSummary.enhancement ? this.dataSummary.enhancement : 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 102px;
  height: 23px;
  &.yellow {
    color: #fb9727;
  }
  &.green {
    color: #519c66;
  }
}
.select-status {
  border: none;
  border-radius: 8px;
  background: rgba(72, 162, 212, 0.1);
  color: #48a2d4;
}
input[type="month"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  position: absolute;
  opacity: 1;
  display: block;
  background: none no-repeat;
  border-width: thin;
  width: 100%;
}
</style>
