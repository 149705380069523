<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Registered List</h3>
            </div>
            <div class="d-flex mt-4">
                <div class="me-3">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Search Partner Code"
                            v-model="search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <div class="mt-4" v-if="is_list_ready">
                        <ListLoader />
                    </div>
                    <template v-else>
                        <div class="table-responsive-custom">
                            <table class="table table-bordered mt-4">
                                <thead class="table-light">
                                    <tr>
                                        <th class="text-center" scope="col">
                                            No
                                        </th>
                                        <th class="text-center" scope="col">
                                            Partner Type
                                        </th>
                                        <th class="text-center" scope="col">
                                            Partner Code
                                        </th>
                                        <th class="text-center" scope="col">
                                            Company Name
                                        </th>
                                        <th class="text-center" scope="col">
                                            Owner Name
                                        </th>
                                        <th class="text-center" scope="col">
                                            Phone
                                        </th>
                                        <th class="text-center" scope="col">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(
                                            item, index
                                        ) in registered.list_data"
                                        :key="index"
                                    >
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="text-center capitalize">
                                            {{
                                                item.partner_type
                                                    ? item.partner_type
                                                    : "-"
                                            }}
                                        </td>
                                        <td class="text-center">
                                            <a
                                                :href="
                                                    `/crm/partnership/registered/detail?id=` +
                                                    item.id +
                                                    `&type=` +
                                                    item.partner_type
                                                "
                                                class="text-link"
                                            >
                                                {{
                                                    item.code ? item.code : "-"
                                                }}
                                            </a>
                                        </td>
                                        <td>
                                            {{
                                                item.company
                                                    ? item.company
                                                    : "-"
                                            }}
                                        </td>
                                        <td>{{ item.pic ? item.pic : "-" }}</td>
                                        <td class="text-center">
                                            {{ item.phone ? item.phone : "-" }}
                                        </td>
                                        <td>
                                            <div
                                                class="tag green-solid radius w-100 text-center mb-0"
                                                v-if="item.status == 'active'"
                                            >
                                                Active
                                            </div>
                                            <div
                                                class="tag red-solid radius w-100 text-center mb-0"
                                                v-else
                                            >
                                                Non Active
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <template v-if="registered.list_data.length == 0">
                            <div class="text-center mt-5">
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </template>
                    </template>
                    <div class="d-flex" v-if="registered.list_data.length > 0">
                        <div class="ms-auto">
                            <div class="d-flex mt-4">
                                <div class="me-3">
                                    <select
                                        class="form-control form-select w-select"
                                        v-model="per_page"
                                        @change="getList()"
                                    >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                                <b-pagination-nav
                                    v-model="currentPage"
                                    :number-of-pages="registered.total_page"
                                    base-url="#"
                                    first-number
                                    align="right"
                                    @input="changePage"
                                ></b-pagination-nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";

export default {
    components: {
        ListLoader,
    },
    name: "crmPartnershipRegistered",
    data() {
        return {
            search: "",
            registered: {
                list_data: [],
            },
            per_page: 10,
            currentPage: 0,
            is_list_ready: false,
        };
    },
    created() {
        this.getList();
    },
    methods: {
        changePage() {
            this.getList();
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        getList() {
            let payload = {
                search: this.search,
                limit: this.per_page,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
            };
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/registered`,
                {
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.registered = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
</style>
