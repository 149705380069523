<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/trawltruck/ho/aggrement" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">
                    Ubah Data Perjanjian Kerjasama
                </h3>
            </div>
            <div class="row mt-5 m-mt-3">
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Kode</label>
                    <input type="text" class="form-control" :value="req.partner.code" disabled>
                </div>
                <div class="col-md-6 m-mt-1">
                    <label class="fw-bold mb-2">Nama Mitra</label>
                    <input type="text" class="form-control" :value="req.partner.name" disabled>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nomor Telepon</label>
                    <input type="number" class="form-control" :value="req.partner.phone" disabled>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Email</label>
                    <input type="email" class="form-control" :value="req.partner.email" disabled>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Periode Kerjasama</label>
                    <div class="row">
                        <div class="col-md-6">
                            <input type="text" class="form-control" :value="moment(req.date_start).format('DD MMMM YYYY')" disabled>
                        </div>
                        <div class="col-md-6 m-mt-1">
                            <input type="text" class="form-control" :value="moment(req.date_end).format('DD MMMM YYYY')" disabled>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Nama Penanggung Jawab</label>
                    <input type="text" class="form-control" :value="req.partner.owner" disabled>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Status Pengajuan</label>
                    <select class="form-control form-select" v-model="req.status">
                        <option value="">Pilih Status Pengajuan</option>
                        <option value="pending">Pending</option>
                        <option value="approved">Approved</option>
                        <option value="reject">Reject</option>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Status PKS</label>
                    <input type="email" class="form-control capitalize" :value="req.status_partnership" disabled>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Alamat</label>
                    <textarea name="" id="" cols="30" rows="6" class="form-control" :value="req.partner.address" disabled></textarea>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Catatan Khusus</label>
                    <textarea name="" id="" cols="30" rows="6" class="form-control" :value="req.note" disabled></textarea>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold">Surat Perjanjian Kerjasama</label>
                    <!-- <div class="text-gray mb-2">
                        Dokumen PDF maks 5MB
                    </div> -->
                    <div class="row mt-4">
                        <div class="col-md-4">
                            <div class="image-view-1" :style="{ 'background-image': 'url(' + req.document_url + ')' }"></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="d-flex no-flex">
                        <div class="ms-auto">
                            <a href="/trawltruck/ho/aggrement" class="btn btn-outline-black btn-lg px-5 me-3 m-w-100">Batal</a>
                            <button class="btn btn-green btn-lg px-5 m-mt-1 m-w-100" type="button" disabled v-if="is_update">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Update
                            </button>
                            <button class="btn btn-green btn-lg px-5 m-mt-1 m-w-100" @click="updateData()" v-else>
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img src="../../assets/dashboard/checklis.png" width="80" alt="">
                    <h4 class="mb-0 fw-bold mt-4">
                        Perubahan Data Berhasil!
                    </h4>
                    <div class="mt-2">
                        Selamat! Anda berhasil merubah data.
                    </div>
                </div>
                <div class="mt-4">
                    <a href="/trawltruck/ho/aggrement" class="btn btn-green w-100 btn-lg">
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from "../../api/Api";
    import moment from "moment";

    export default {
        components: {
        },
        props: "",
        name: "ManagementDeliveryPaymentEdit",
        data() {
            return {
                moment: moment,
                req: {
                    partner: {}
                },
                modalSuccess: false,
                is_update: false
            };
        },
        created(){
            this.getDetail()
        },
        methods: {
            getDetail() {
                Api.get(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partnership/detail?id=${this.$route.params.id}`, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    var data = res.data.data
                    this.req = data
                })
                .catch((err) => {
                    console.log(err);
                });
            },
            updateData(){
                this.is_update = true
                var data = {
                    id: this.req.id,
                    status: this.req.status
                }

                Api.post(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partnership/update`, data, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    }
                })
                .then((res) => {
                    this.is_update = false
                    this.modalSuccess = true
                    console.log(res)
                })
                .catch((err) => {
                    this.is_update = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.message
                    });
                })
            },
        }
    };
</script>

<style scoped>
    .text-main-color{
        color: #24516A !important;
    }
    .text-gray-dashboard{
        color: #61616A;
    }
</style>