<template>
    <div class="padding-container">
        <div class="d-flex align-items-center bd-highlight">
            <div class="flex-grow-1 bd-highlight">
                <h3 class="fw-bold">Send Invoice</h3>
            </div>
        </div>
        <div class="box mt-4">
            <div class="row">
                <div class="col-md-6 mt-3">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Cari Nomor Resi"
                            v-model="req.search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <template v-if="list.data.length > 0">
                    <div class="modern-table-boxed mt-4">
                        <div class="d-table-row header">
                            <div class="d-table-cell">No</div>
                            <div class="d-table-cell">Nomor Resi</div>
                            <div class="d-table-cell">Jenis Order</div>
                            <div class="d-table-cell">Tanggal Pesanan</div>
                            <div class="d-table-cell text-center">Aksi</div>
                        </div>
                        <template v-for="(data, index) in list.data">
                            <div class="d-table-row" :key="index">
                                <div class="d-table-cell">{{ index + 1 }}.</div>
                                <div class="d-table-cell">
                                    {{ data.receipt_code }}
                                </div>
                                <div class="d-table-cell">
                                    {{ data.order_type }}
                                </div>
                                <div class="d-table-cell">
                                    {{
                                        moment(data.created_at).format(
                                            "DD MMM YYYY"
                                        )
                                    }}
                                </div>
                                <div class="d-table-cell shrink">
                                    <a
                                        :href="
                                            '/trawlpack/invoice/send/detail/' +
                                            data.hash
                                        "
                                        class="btn btn-green px-5"
                                    >
                                        Cek
                                    </a>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-section">
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </template>
            </div>
        </div>
        <div class="mt-4">
            <b-pagination-nav
                v-if="list.data.length > 0"
                :link-gen="linkGen"
                v-model="currentPage"
                :number-of-pages="totalPage"
                base-url="#"
                first-number
                align="right"
            ></b-pagination-nav>
            <!-- <b-pagination-nav v-if="list.data.length > 0" v-model="currentPage" :number-of-pages="list.last_page" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav> -->
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";

export default {
    components: {
        ListLoader,
    },
    name: "TrawlpackSendInvoice",
    data() {
        return {
            moment: moment,
            req: {
                search: "",
            },
            list: {
                data: [],
                last_page: 0,
                total_data: 0,
                total: 0,
                current_page: 0,
            },
            currentPage: 0,
            totalPage: 0,
            per_page: 10,
            is_list_ready: false,
        };
    },
    created() {
        this.getListInvoice();
    },
    mounted() {},
    computed: {},
    methods: {
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        changeSearch() {
            this.currentPage = 0;
            this.getListInvoice();
        },
        getListInvoice() {
            this.is_list_ready = true;
            Api.get(`${process.env.VUE_APP_BASE_URL}/partner/cashier/invoice`, {
                params: {
                    status: "estimated",
                    q: this.req.search,
                    per_page: this.per_page,
                    page: this.$route.query.page,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data;
                    this.list = data;
                    this.totalPage = res.data.last_page;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
    },
};
</script>

<style scoped>
.modern-table-boxed .d-table-row {
    box-shadow: 0px 5px 20px 5px rgb(0 0 0 / 3%);
}
</style>
