<template>
    <div>
      <warehouse :items="items" />
    </div>
  </template>
  <script>
  import warehouse from "./Warehouse.vue";
  
  export default {
    components: {
      warehouse,
    },
    name: "Dashboard-MB",
    data() {
      return {
        items: {
          name: "Mitra Business",
          partner_type: "mb",
          url: "admin-income-mb-detail",
        },
      };
    },
    created() {},
    methods: {},
  };
  </script>
  <style scoped></style>
  