<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <h3 class="fw-bold">Pendaftaran Pemasangan Sticker Truck</h3>
            <div class="d-flex mt-4">
                <div class="me-3">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Cari nama driver"
                            v-model="search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <div class="me-2">Rows per page</div>
                        <div>
                            <select
                                class="form-control form-select w-select"
                                v-model="limit"
                                @change="getList()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div class="table-responsive-custom" v-else>
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">Nama Driver</th>
                            <th class="text-center" scope="col">
                                Jenis Armada
                            </th>
                            <th class="text-center" scope="col">
                                Nomor Polisi
                            </th>
                            <th class="text-center" scope="col">
                                Kilometer Saat Ini
                            </th>
                            <th class="text-center" scope="col">
                                Tanggal Pendaftaran
                            </th>
                            <th class="text-center" scope="col">Status</th>
                            <th class="text-center" scope="col">
                                Reject Count
                            </th>
                            <th class="text-center" scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(data, index) in list.list_data"
                            :key="index"
                        >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td>
                                <a
                                    :href="`/truck/sticker/detail/` + data.id"
                                    class="text-link"
                                >
                                    {{ data.driver_name }}
                                </a>
                            </td>
                            <td>{{ data.fleet_type_label }}</td>
                            <td>{{ data.registration_number }}</td>
                            <td class="text-center">{{ data.current_km }}</td>
                            <td class="text-center">{{ data.created_at }}</td>
                            <td class="text-center">
                                <div
                                    class="tag yellow-cro radius mb-0 pb-0"
                                    v-if="data.status == 'pending'"
                                >
                                    Belum Terverifikasi
                                </div>
                                <div
                                    class="tag red-cro radius mb-0 pb-0"
                                    v-if="data.status == 'reject'"
                                >
                                    Ditolak
                                </div>
                                <div
                                    class="tag red-cro radius mb-0 pb-0"
                                    v-if="data.status == 'banned'"
                                >
                                    Banned
                                </div>
                                <div
                                    class="tag green-cro radius mb-0 pb-0"
                                    v-if="data.status == 'accept'"
                                >
                                    Terverifikasi
                                </div>
                            </td>
                            <td class="text-center">
                                <a
                                    href="javascript:void(0)"
                                    class="text-link"
                                    @click="openModalRejectHistory(data)"
                                    v-if="data.reject_count > 0"
                                >
                                    {{ data.reject_count }}
                                </a>
                                <template v-else>
                                    {{ data.reject_count }}
                                </template>
                            </td>
                            <td class="text-center">
                                <a
                                    href="javascript:void(0)"
                                    @click="openModalAccept(data.id)"
                                    v-if="data.status == 'pending'"
                                    class="me-1"
                                >
                                    <img
                                        src="../../assets/check-green.png"
                                        width="16"
                                        alt=""
                                    />
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    @click="openModalReject(data.id)"
                                    v-if="data.status == 'pending'"
                                >
                                    <img
                                        src="../../assets/close-red.png"
                                        width="16"
                                        alt=""
                                    />
                                </a>
                                <img
                                    src="../../assets/check-gray.png"
                                    width="16"
                                    alt=""
                                    class="me-1"
                                    v-if="data.status != 'pending'"
                                />
                                <img
                                    src="../../assets/close-gray.png"
                                    width="16"
                                    alt=""
                                    v-if="data.status != 'pending'"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center mt-5" v-if="list.list_data.length == 0">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="mt-4">
                <b-pagination-nav
                    v-model="currentPage"
                    v-if="list.list_data.length > 0"
                    :number-of-pages="list.total_page"
                    base-url="#"
                    first-number
                    align="right"
                    @input="getList"
                ></b-pagination-nav>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalAccept"></div>
            <div class="modal-body-chat vsm" v-if="modalAccept">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Pengajuan Branding Akan Diverifikasi
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan informasi driver dan armada sudah sesuai
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalAccept = false"
                            class="btn btn-outline-red w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="acceptData()"
                            type="button"
                            class="btn btn-green w-100 btn-lg"
                            :disabled="is_accept"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_accept"
                            ></span>
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessAccept"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessAccept">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Pengajuan Branding Telah Diverifikasi
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="(modalSuccessAccept = false), getList()"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalReject"></div>
            <div class="modal-body-chat vsm" v-if="modalReject">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Pengajuan Branding Akan Ditolak!
                    </h4>
                    <div class="text-gray-dashboard">
                        Mohon lengkapi checklist sheet dan berikan alasan
                        penolakan
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="(modalReject = false), (reject_id = null)"
                            class="btn btn-outline-red w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <a
                            :href="`/truck/sticker/reject/` + reject_id"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ya
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalRejectHistory"></div>
            <div class="modal-body-chat vsm" v-if="modalRejectHistory">
                <h5 class="mb-0 fw-bold">Alasan Penolakan</h5>
                <div class="history-tl-container mt-2">
                    <ul class="timeline timeline-split mt-4">
                        <template v-for="(item, index) in reject_history">
                            <li class="timeline-item" :key="index">
                                <div
                                    class="timeline-marker"
                                    v-bind:class="{
                                        active: index == 0,
                                    }"
                                ></div>
                                <div class="timeline-content">
                                    <div class="timeline-title">
                                        <span
                                            v-bind:class="{
                                                'text-dark': index == 0,
                                            }"
                                        >
                                            <template v-if="index == 0"
                                                >Penolakan Pertama</template
                                            >
                                            <template v-else
                                                >Penolakan Kedua</template
                                            >
                                        </span>
                                        -
                                        <span
                                            v-bind:class="{
                                                'text-primary': index == 0,
                                            }"
                                            >{{
                                                moment(item.rejected_at).format(
                                                    "DD MMM YYYY | hh:mm"
                                                )
                                            }}</span
                                        >
                                    </div>
                                    <div
                                        class="box reject no-shadow p-2 mt-1 fw-semibold"
                                    >
                                        {{ item.note }}
                                    </div>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
                <div>
                    <button
                        class="btn btn-green w-100"
                        @click="modalRejectHistory = false"
                    >
                        Tutup
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";

export default {
    components: {
        ListLoader,
    },
    name: "TruckSticker",
    data() {
        return {
            moment: moment,
            search: "",
            is_list_ready: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            limit: 10,
            currentPage: 0,
            modalReject: false,
            reject_id: null,
            modalAccept: false,
            accept_id: null,
            is_accept: false,
            modalSuccessAccept: false,
            modalRejectHistory: false,
            reject_history: [],
        };
    },
    created() {
        this.getList();
    },
    computed: {},
    methods: {
        getList() {
            this.is_list_ready = true;
            let payload = {
                search: this.search,
                limit: this.limit,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/truck/head-office/branding`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        openModalReject(id) {
            this.reject_id = id;
            this.modalReject = true;
        },
        openModalAccept(id) {
            this.accept_id = id;
            this.modalAccept = true;
        },
        acceptData() {
            var data = {
                brand_id: this.accept_id,
            };
            this.is_accept = true;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/truck/head-office/branding/verification`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_accept = false;
                    this.modalAccept = false;
                    this.modalSuccessAccept = true;
                })
                .catch((err) => {
                    this.is_accept = false;
                    this.modalAccept = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        openModalRejectHistory(data) {
            this.reject_history = data.reject_history;
            this.modalRejectHistory = true;
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.box.reject {
    border: 1px solid #d9d9d9;
    background: #f6faff;
    border-radius: 8px;
}
.timeline-marker.active:before {
    background: #e60013;
}
.timeline-marker:after {
    background: #d9d9d9;
}
.timeline-marker:before {
    background: #f7aab0;
}
</style>
