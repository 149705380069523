<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center no-flex">
                <h3 class="fw-bold">Daftar Pencairan Dana</h3>
            </div>
            <div class="d-flex mt-4">
                <div class="me-3">
                    <date-picker
                        v-model="start_date"
                        range
                        type="date"
                        value-type="format"
                        format="DD MMMM YYYY"
                        :clearable="false"
                        placeholder="Pilih tanggal"
                        @change="getStartDate(start_date)"
                    >
                    </date-picker>
                </div>
                <div class="me-3">
                    <button
                        class="btn btn-green px-3"
                        :disabled="start_date == ''"
                        @click="clearFilter()"
                    >
                        Clear Filter
                    </button>
                </div>
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <div class="me-2">Rows per page</div>
                        <div>
                            <select
                                class="form-control form-select w-select"
                                v-model="per_page"
                                @change="getList()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div class="table-responsive-custom" v-else>
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">
                                Tanggal Pengajuan
                            </th>
                            <th class="text-center" scope="col">
                                Kode Pengajuan
                            </th>
                            <th class="text-center" scope="col">Nama Driver</th>
                            <th class="text-center" scope="col">
                                Nominal Pengajuan
                            </th>
                            <th class="text-center" scope="col">
                                Nominal yang Diterima
                            </th>
                            <th class="text-center" scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(data, index) in list.list_data"
                            :key="index"
                        >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">
                                {{ data.requested_at }}
                            </td>
                            <td class="text-center">
                                <a
                                    :href="
                                        `/trawltruck/disbursement/` + data.code
                                    "
                                    class="text-link"
                                >
                                    {{ data.code }}
                                </a>
                            </td>
                            <td>{{ data.name }}</td>
                            <td>
                                {{
                                    currency(
                                        data.amount_requested
                                            ? data.amount_requested
                                            : 0
                                    )
                                }}
                            </td>
                            <td>
                                {{
                                    currency(
                                        data.amount_accept
                                            ? data.amount_accept
                                            : 0
                                    )
                                }}
                            </td>
                            <td class="text-center">
                                <div
                                    class="pb-0 w-100"
                                    :class="[
                                        data.status == 'accepted'
                                            ? ' tag blue-cro radius px-3'
                                            : data.status == 'transferred'
                                            ? ' tag green-cro radius px-3'
                                            : ' tag yellow-cro radius px-3',
                                    ]"
                                >
                                    <template v-if="data.status == 'accepted'">
                                        Approval
                                    </template>
                                    <template
                                        v-else-if="data.status == 'transferred'"
                                    >
                                        Transferred
                                    </template>
                                    <template v-else> Pending </template>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center mt-5" v-if="list.list_data.length == 0">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="d-flex" v-if="list.list_data.length > 0">
                <div class="ms-auto">
                    <div class="d-flex mt-4">
                        <div class="me-3">
                            <select
                                class="form-control form-select w-select"
                                v-model="per_page"
                                @change="getList()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <b-pagination-nav
                            v-model="currentPage"
                            :number-of-pages="list.total_page"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default {
    components: {
        ListLoader,
        DatePicker,
    },
    name: "disburseIndividu",
    data() {
        return {
            moment: moment,
            is_list_ready: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page: 10,
            currentPage: 0,
            start_date: "",
            end_date: "",
        };
    },
    created() {
        this.getList();
    },
    computed: {},
    mounted() {},
    methods: {
        getList() {
            this.is_list_ready = true;
            let payload = {
                limit: this.per_page,
                type: "driver",
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
            };
            if (this.start_date) {
                payload.start_date = moment(this.start_date[0]).format(
                    "DD-MM-YYYY"
                );
                payload.end_date = moment(this.start_date[1]).format(
                    "DD-MM-YYYY"
                );
            }
            if (this.role) {
                const selectedRoles = this.role.map((item) => item.id);
                payload.role_group = selectedRoles;
            }
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/truck/finance/admin/disburse/lists`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        changePage() {
            this.getList();
        },
        getStartDate(value) {
            this.start_date = value;
            this.getList();
        },
        clearFilter() {
            this.start_date = "";
            this.end_date = "";
            this.getList();
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.dropdown-menu {
    padding: 10px;
    box-shadow: none !important;
    border: 1px solid #d2d2d5 !important;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-left: 10px !important;
}
.table-responsive-custom table tbody tr td {
    white-space: normal;
}
</style>
