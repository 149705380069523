<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="d-flex align-items-center bd-highlight">
            <div class="d-flex align-items-center">
                <div class="me-3">
                    <a href="/trawlcarrier/ho/disbursement" class="text-primary">
                        <i class="fa fa-angle-left size-24 fw-bold"></i>
                    </a>
                </div>
                <h3 class="fw-bold mb-0">
                    Detail Pencairan
                </h3>
            </div>
        </div>
        <div class="box mt-4">
            <div class="row">
                <div class="col-md-4">
                    <label class="fw-bold mb-2">Invoice Id</label>
                    <div class="disabled-box">
                        {{ data.invoice_id }}
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="fw-bold mb-2">Nama PIC</label>
                    <div class="disabled-box">
                        {{ data.name }} / {{ data.phone }}
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="fw-bold mb-2">Mitra</label>
                    <div class="disabled-box">
                        {{ data.mitra }}
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold mb-2">Status Permintaan</label>
                    <div class="disabled-box capitalize">
                        {{ data.current_status }}
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold mb-2">No Rekening</label>
                    <div class="disabled-box">
                        {{ data.account_number }}
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold mb-2">Bank</label>
                    <div class="disabled-box">
                        {{ data.bank }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 mt-3">
                <h4 class="fw-bold mt-4">
                    Bukti Transfer
                </h4>
                <div class="box mt-4">
                    <template v-if="data.attachment">
                        <img :src="data.attachment" class="w-100" alt="">
                    </template>
                    <template v-else>
                        <div class="image-view-1" :style="{'background-image': 'url(' + (req.attachment) + ')'}">
                            <div class="mt-4 ml-4">
                                <label for="attachment" class="upload-button single-image"> 
                                    <i class="fa fa-upload"></i>
                                    <input type="file" id="attachment" @change="uploadTransfer()">
                                </label>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="col-md-5 mt-3">
                <h4 class="fw-bold mt-4">
                    Rincian Pencairan
                </h4>
                <div class="box mt-4">
                    <div class="d-flex align-items-center">
                        <div>
                            <label class="fw-medium size-16">Biaya Pencairan</label>
                        </div>
                        <div class="ms-auto">
                            <label class="fw-medium size-16">
                                {{ currencyFormat(data.amount) }}
                            </label>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="d-flex align-items-center">
                            <div>
                                <label class="fw-medium size-16">
                                    Pajak 11%
                                </label>
                            </div>
                            <div class="ms-auto">
                                <label class="fw-medium size-16">
                                    Rp. 0
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="d-flex align-items-center">
                        <div>
                            <label class="fw-bold size-18">
                                Total Pembayaran
                            </label>
                        </div>
                        <div class="ms-auto">
                            <label class="fw-bold size-18">
                                {{ currencyFormat(data.amount) }}
                            </label>
                        </div>
                    </div>
                    <div class="text-danger mt-2">
                        <i>
                            Setiap Pencairan dipotong pajak 11%
                        </i>
                    </div>
                    <div class="mt-4">
                        <button class="btn btn-green btn-lg w-100" @click="showModalDetail = true" :disabled="is_changing_transfer != true">
                            Proses Pencairan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-7">
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="showModalDetail"></div>
            <div class="modal-body-chat sm" v-if="showModalDetail">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            <img src="../../assets/accept.png" width="65" alt="">
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-ekstra-bold">
                        Rincian pengembalian Dana
                    </div>
                    <div class="mt-2">
                        Anda akan mengirimkan pengembalian dana, sebesar :
                    </div>
                    <div class="box no-shadow no-border bc-gray mt-4">
                        <div class="text-center fw-ekstra-bold size-28">
                            {{ currencyFormat(data.amount) }}
                        </div>
                        <div class="text-center size-16">
                            No Rekening <span class="fw-bold">{{ data.account_number }}</span> A/n <span class="fw-bold">{{ data.account_name }}</span>
                        </div>
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <a href="javascript:void(0)" class="btn btn-outline-black w-100" @click="showModalDetail = false">
                                Batal
                            </a>
                        </div>
                        <div class="col-md-6 mt-4">
                            <button class="btn btn-green w-100" type="button" disabled v-if="is_store">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Kirim
                            </button>
                            <button class="btn btn-green w-100" @click="storeData()" v-else>
                                Kirim
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="showModalSuccess"></div>
            <div class="modal-body-chat sm" v-if="showModalSuccess">
                <div class="text-center">
                    <div class="size-18 fw-ekstra-bold">
                        <img src="../../assets/accept.png" width="65" alt="">
                    </div>
                </div>
                <div class="mt-4">
                    <div class="text-center">
                        <div class="size-18 fw-ekstra-bold">
                            Pengembalian Dana Sukses!
                        </div>
                        <div class="mt-2">
                            Dana telah dikembalikan secara penuh ke rekening customer
                        </div>
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-12 mt-4">
                            <a href="javascript:void(0)" class="btn btn-green btn-lg w-100" @click="closeModalSuccess()">
                                Okay
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Api from "../../api/Api";
// import ListLoader from '../../components/ListLoader.vue';
// import CardLoader from '../../components/CardLoader.vue';
// import moment from 'moment'

import $ from 'jquery'

export default {
    components: {
    },
    name: "TrawlcareerPayment",
    data() {
        return {
            req: {
                attachment: ''
            },
            is_changing_transfer: false,
            is_store: false,
            data: {},
            showModalDetail: false,
            showModalSuccess: false
        };
    },
    created() {
        this.getOrderDetail()
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        currencyFormat(num) {
            return (
                "Rp. " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        uploadTransfer() {
            var attachment = new FileReader();
            attachment.onload = function (e) {
                $('.image-view-1').css('background-image', 'url('+ e.target.result + ')')
            };
            attachment.readAsDataURL($('#attachment')[0].files[0]);
            this.is_changing_transfer = true
        },
        getOrderDetail() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/carrier/ho/wallet/disbursement/detail/${this.$route.params.id}`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                var data = res.data.data
                this.data = data
            })
            .catch((err) => {
                console.log(err);
            });
        },
        storeData(){
            this.is_store = true
            var attachment = $('#attachment')[0].files[0]
            var data = new FormData()
            if(this.is_changing_transfer) {
                data.append('attachment',attachment)
            }
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/carrier/ho/wallet/disbursement/store/${this.$route.params.id}`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            })
            .then((res) => {
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Success',
                    text: 'Berhasil diproses'
                });
                this.is_store = false
                this.showModalSuccess = true
                this.showModalDetail = false
                console.log(res)
            })
            .catch((err) => {
                this.is_store = false
                this.req.attachment = ''
                this.showModalDetail = false
                this.$notify({
                    group: 'foo',
                    type: 'error',
                    title: 'Error',
                    text: err.response.data.message
                });
            })
        },
        closeModalSuccess(){
            this.showModalSuccess = false
            window.location.href = "/trawlcarrier/ho/disbursement"
        }
    },
};
</script>

<style>
    .driver-image-item{
        width: 100%;
        height: 120px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 6px;
        border: 2px solid #e2e3e5;
    }
</style>