var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"padding-container"},[_c('div',{staticClass:"box mt-4 m-mt-0"},[_vm._m(0),_c('div',{staticClass:"d-flex mt-4"},[_c('div',{staticClass:"me-3"},[_c('div',{staticClass:"position-relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control form-control-lg pl-search w-search",attrs:{"type":"text","placeholder":"Search Partner Code"},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing)return;_vm.search=$event.target.value},function($event){return _vm.changeSearch()}]}}),_vm._m(1)])])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-12"},[(_vm.is_list_ready)?_c('div',{staticClass:"mt-4"},[_c('ListLoader')],1):[_c('div',{staticClass:"table-responsive-custom"},[_c('table',{staticClass:"table table-bordered mt-4"},[_vm._m(2),_c('tbody',_vm._l((_vm.registered.list_data),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{staticClass:"text-center capitalize"},[_vm._v(" "+_vm._s(item.partner_type ? item.partner_type : "-")+" ")]),_c('td',{staticClass:"text-center"},[_c('a',{staticClass:"text-link",attrs:{"href":`/crm/partnership/registered/detail?id=` +
                                                item.id +
                                                `&type=` +
                                                item.partner_type}},[_vm._v(" "+_vm._s(item.code ? item.code : "-")+" ")])]),_c('td',[_vm._v(" "+_vm._s(item.company ? item.company : "-")+" ")]),_c('td',[_vm._v(_vm._s(item.pic ? item.pic : "-"))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.phone ? item.phone : "-")+" ")]),_c('td',[(item.status == 'active')?_c('div',{staticClass:"tag green-solid radius w-100 text-center mb-0"},[_vm._v(" Active ")]):_c('div',{staticClass:"tag red-solid radius w-100 text-center mb-0"},[_vm._v(" Non Active ")])])])}),0)])]),(_vm.registered.list_data.length == 0)?[_vm._m(3)]:_vm._e()],(_vm.registered.list_data.length > 0)?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ms-auto"},[_c('div',{staticClass:"d-flex mt-4"},[_c('div',{staticClass:"me-3"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.per_page),expression:"per_page"}],staticClass:"form-control form-select w-select",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.per_page=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getList()}]}},[_c('option',{attrs:{"value":"10"}},[_vm._v("10")]),_c('option',{attrs:{"value":"25"}},[_vm._v("25")]),_c('option',{attrs:{"value":"50"}},[_vm._v("50")]),_c('option',{attrs:{"value":"100"}},[_vm._v("100")])])]),_c('b-pagination-nav',{attrs:{"number-of-pages":_vm.registered.total_page,"base-url":"#","first-number":"","align":"right"},on:{"input":_vm.changePage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]):_vm._e()],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('h3',{staticClass:"fw-bold"},[_vm._v("Registered List")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"search-icon left"},[_c('i',{staticClass:"fa fa-search"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"table-light"},[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" No ")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Partner Type ")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Partner Code ")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Company Name ")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Owner Name ")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Phone ")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Status ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mt-5"},[_c('img',{attrs:{"src":require("../../assets/no-data.png"),"width":"100","alt":""}}),_c('h4',{staticClass:"mb-0 fw-bold mt-4 mb-4 text-gray"},[_vm._v(" Data Kosong ")])])
}]

export { render, staticRenderFns }