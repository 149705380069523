<template>
    <div class="padding-container">
        <div class="d-flex align-items-center bd-highlight">
            <div class="flex-grow-1 bd-highlight">
                <h3 class="fw-bold">
                    List Kendaraan
                </h3>
            </div>
            <div class="bd-highlight">
                <a href="/vehicle/create" class="btn btn-green btn-lg px-5">
                    Tambah Kendaraan
                </a>
            </div>
        </div>
        <div class="box mt-4">
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader/>
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <div class="position-relative">
                            <input type="text" class="form-control" placeholder="Cari no polisi" v-model="req.keyword" />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <input type="date" class="form-control" placeholder="Start date" v-model="req.start" @change="getVehiclesList()" />
                    </div>
                    <div class="col-md-3 mt-3">
                        <input type="date" class="form-control" placeholder="End date" v-model="req.end" @change="getVehiclesList()" />
                    </div>
                </div>
                <div class="modern-table-boxed mt-4" v-if="searchLists.length > 0">
                    <div class="d-table-row header">
                        <div class="d-table-cell">No</div>
                        <div class="d-table-cell">Kendaraan</div>
                        <div class="d-table-cell">No Polisi</div>
                        <div class="d-table-cell">Tahun</div>
                        <div class="d-table-cell">Jenis kendaraan</div>
                        <div class="d-table-cell">Tanggal Dibuat</div>
                        <div class="d-table-cell">Status</div>
                        <div class="d-table-cell">Aksi</div>
                    </div>
                    <template v-for="(list, index) in searchLists">
                        <div class="d-table-row" :key="index">
                            <div class="d-table-cell fw-bold">
                                {{ index+1 }}.
                            </div>
                            <div class="d-table-cell">
                                {{ list.kendaraan }}
                            </div>
                            <div class="d-table-cell">
                                {{ list.no_polisi }}
                            </div>
                            <div class="d-table-cell">
                                {{ list.tahun_kendaraan }}
                            </div>
                            <div class="d-table-cell capitalize">
                                {{ (list.jenis_kendaraan || "").split("_").join(" ") }}
                            </div>
                            <div class="d-table-cell">
                                {{ moment(list.tanggal_dibuat).format('DD MMMM YYYY') }}
                            </div>
                            <div class="d-table-cell">
                                <small class="tag green" v-if="list.status">Aktif</small>
                                <small class="tag red" v-else>Tidak Aktif</small>
                            </div>
                            <div class="d-table-cell shrink">
                                <a :href="`/vehicle/edit/${list.id}`" class="btn btn-green btn-sm px-4">
                                    Edit
                                </a>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="">
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <b-pagination-nav v-if="searchLists.length > 0" :link-gen="linkGen" v-model="currentPage" :number-of-pages="list.total_page" base-url="#" first-number align="right"></b-pagination-nav>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from '../../components/ListLoader.vue';
import moment from 'moment'

export default {
    components: {
        ListLoader
    },
    name: "vehicleList",
    data() {
        return {
            moment: moment,
            is_list_ready: false,
            req: {
                keyword: '',
                start: '',
                end: ''
            },
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0
            },
            per_page: 10,
            currentPage: 0,
        };
    },
    created() {
        this.getVehiclesList()
    },
    mounted() {
    },
    computed: {
        searchLists() {
            return this.list.list_data.filter((item) => {
                return item.no_polisi.toLowerCase().includes(this.req.keyword.toLowerCase());
            });
        }
    },
    methods: {
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        getVehiclesList() {
            this.is_list_ready = true
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/vehicle/list?start_date=${this.req.start}&end_date=${this.req.end}`, {
                params: { per_page: this.per_page, page: this.$route.query.page},
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                var data = res.data.data
                this.list = data
                this.is_list_ready = false
            })
            .catch((err) => {
                console.log(err);
                this.is_list_ready = false
            });
        },
    },
};
</script>

<style scoped>
</style>