<template>
    <div class="padding-container">
        <div class="d-flex align-items-center bd-highlight">
            <div class="flex-grow-1 bd-highlight">
                <h3 class="fw-bold">Data Pencairan</h3>
            </div>
        </div>
        <div class="box mt-4">
            <template v-if="is_card_ready">
                <CardLoader />
            </template>
            <template v-else>
                <div class="row">
                    <div class="col-md-3">
                        <div class="box no-shadow border-gray bc-gray">
                            <h5 class="fw-bold mb-0 text-gray">
                                Total Pencairan
                            </h5>
                            <div class="fw-ekstra-bold size-24 mt-3">
                                {{ currencyFormat(dashboard.total_pencairan) }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="box no-shadow border-gray bc-gray">
                            <h5 class="fw-bold mb-0 text-gray">
                                Belum Dicairkan
                            </h5>
                            <div class="fw-ekstra-bold size-24 mt-3">
                                {{ currencyFormat(dashboard.belum_dicairkan) }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="box no-shadow border-gray bc-gray">
                            <h5 class="fw-bold mb-0 text-gray">
                                Pencairan Ditolak
                            </h5>
                            <div class="fw-ekstra-bold size-24 mt-3">
                                {{
                                    currencyFormat(dashboard.pencairan_ditolak)
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="box no-shadow border-gray bc-gray">
                            <h5 class="fw-bold mb-0 text-gray">
                                pencairan Diterima
                            </h5>
                            <div class="fw-ekstra-bold size-24 mt-3">
                                {{
                                    currencyFormat(dashboard.pencairan_diterima)
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-md-4 mt-4">
                        <div class="position-relative">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Cari invoice"
                                v-model="req.invoice"
                            />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4 mt-4">
                        <div class="position-relative">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Cari mitra"
                                v-model="req.mitra"
                            />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4 mt-4">
                        <select
                            class="form-control form-select"
                            v-model="req.status"
                            @change="getDisbursementList()"
                        >
                            <option value="">Status Permintaan</option>
                            <option value="requested">Diproses</option>
                            <option value="accepted">Diterima</option>
                            <option value="decline">Ditolak</option>
                            <option value="done">Sudah dicairkan</option>
                        </select>
                    </div>
                </div>
                <table
                    class="table position-relative mt-5"
                    v-if="searchLists.length > 0"
                >
                    <thead class="thead">
                        <tr class="tr">
                            <td class="th">No</td>
                            <td class="th">Invoice Id</td>
                            <td class="th">Nama Mitra</td>
                            <td class="th">PIC Mitra</td>
                            <td class="th">Total Pengajuan</td>
                            <td class="th">Tanggal Pengajuan</td>
                            <td class="th">Status permintaan</td>
                            <td class="th">Status pencairan</td>
                        </tr>
                    </thead>
                    <tr>
                        <td>
                            <div></div>
                        </td>
                    </tr>
                    <template v-for="(list, index) in searchLists">
                        <tr class="tr border-body" :key="list.id">
                            <td class="td">
                                <span class="fw-bold">{{ index + 1 }}</span
                                >.
                            </td>
                            <td class="td">
                                {{ list.request_id }}
                            </td>
                            <td class="td">
                                {{ list.mitra }}
                            </td>
                            <td class="td" style="width: 100px">
                                <a
                                    href="javascript:void(0)"
                                    class="text-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="list.pic_mitra"
                                >
                                    <div class="limit-one-line">
                                        {{ list.pic_mitra }}
                                    </div>
                                </a>
                            </td>
                            <td class="td">
                                {{ currencyFormat(list.amount) }}
                            </td>
                            <td class="td">
                                {{
                                    moment(list.tanggal_request).format(
                                        "DD MMMM YYYY"
                                    )
                                }}
                            </td>
                            <td class="td capitalize">
                                {{ list.status }}
                            </td>
                            <td class="td">
                                <template v-if="list.attachment">
                                    <div class="text-green">Selesai</div>
                                </template>
                                <template v-else> - </template>
                            </td>
                        </tr>
                        <tr class="border-footer bc-gray pb-5" :key="index">
                            <td class="p-2 pt-1 pb-1" colspan="8">
                                <div class="d-flex align-items-center">
                                    <div class="">
                                        <a
                                            :href="`/trawlcarrier/ho/disbursement/${list.request_id}`"
                                        >
                                            Lihat Detail
                                        </a>
                                    </div>
                                    <div
                                        class="ms-auto"
                                        v-if="list.status == 'requested'"
                                    >
                                        <button
                                            class="btn btn-green btn-sm me-2 pb-1 pt-1 px-3"
                                            @click="acceptModalOpen(list)"
                                        >
                                            Terima
                                        </button>
                                        <button
                                            class="btn btn-primary btn-sm pb-1 pt-1 px-3"
                                            @click="declineModalOpen(list)"
                                        >
                                            Tolak
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </table>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="d-flex" v-if="searchLists.length > 0">
            <div class="ms-auto">
                <div class="d-flex mt-4">
                    <div class="me-3">
                        <select
                            class="form-control form-select w-select"
                            v-model="per_page"
                            @change="getDisbursementList()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <b-pagination-nav
                        :link-gen="linkGen"
                        v-model="currentPage"
                        :number-of-pages="list.total_page"
                        base-url="#"
                        first-number
                        align="right"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="declineModal"></div>
            <div class="modal-body-chat sm" v-if="declineModal">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            <img
                                src="../../assets/decline.png"
                                width="65"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-ekstra-bold">Tolak?</div>
                    <div class="mt-2">Apakah Anda Yakin Ingin menolak?</div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <a
                                href="javascript:void(0)"
                                class="btn btn-outline-black w-100"
                                @click="declineModal = false"
                            >
                                Batal
                            </a>
                        </div>
                        <div class="col-md-6 mt-4">
                            <a
                                href="javascript:void(0)"
                                class="btn btn-primary w-100"
                                @click="decline(declineData.request_id)"
                            >
                                Tolak
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="acceptModal"></div>
            <div class="modal-body-chat sm" v-if="acceptModal">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            <img
                                src="../../assets/accept.png"
                                width="65"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-ekstra-bold">Terima?</div>
                    <div class="mt-2">Apakah Anda Yakin Ingin Menerima?</div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <a
                                href="javascript:void(0)"
                                class="btn btn-outline-black w-100"
                                @click="acceptModal = false"
                            >
                                Batal
                            </a>
                        </div>
                        <div class="col-md-6 mt-4">
                            <a
                                href="javascript:void(0)"
                                class="btn btn-green w-100"
                                @click="accept(acceptData.request_id)"
                            >
                                Terima
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import CardLoader from "../../components/CardLoader.vue";
import moment from "moment";
import { Tooltip } from "bootstrap";

export default {
    components: {
        ListLoader,
        CardLoader,
    },
    name: "DisbursementList",
    data() {
        return {
            moment: moment,
            is_list_ready: false,
            req: {
                invoice: "",
                mitra: "",
                status: "",
            },
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            dashboard: {
                belum_dicairkan: 0,
                pencairan_diterima: 0,
                pencairan_ditolak: 0,
                total_pencairan: 0,
            },
            is_card_ready: false,
            per_page: 10,
            currentPage: 0,
            declineModal: false,
            acceptModal: false,
            declineData: {},
            acceptData: {},
        };
    },
    created() {
        this.getDisbursementList();
        this.getDashboard();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    computed: {
        searchLists() {
            return this.filterInvoice(this.filterMitra(this.list.list_data));
        },
    },
    methods: {
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        filterInvoice: function (invoice) {
            return invoice.filter(
                (invoice) =>
                    !invoice.request_id
                        .toLowerCase()
                        .indexOf(this.req.invoice.toLowerCase())
            );
        },
        filterMitra: function (mitra) {
            return mitra.filter(
                (mitra) =>
                    !mitra.mitra
                        .toLowerCase()
                        .indexOf(this.req.mitra.toLowerCase())
            );
        },
        currencyFormat(num) {
            return (
                "Rp. " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getDisbursementList() {
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/wallet/disbursement?status=${this.req.status}`,
                {
                    params: {
                        per_page: this.per_page,
                        page: this.$route.query.page,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        getDashboard() {
            this.is_card_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/wallet/disbursement/dashboard`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_card_ready = false;
                    var data = res.data.data;
                    this.dashboard = data;
                })
                .catch((err) => {
                    this.is_card_ready = false;
                    console.log(err);
                });
        },
        declineModalOpen(list) {
            this.declineModal = true;
            this.declineData = list;
        },
        acceptModalOpen(list) {
            this.acceptModal = true;
            this.acceptData = list;
        },
        accept() {
            var data = {
                status: "accepted",
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/wallet/disbursement/accept-or-decline/${this.acceptData.request_id}`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Berhasil diterima",
                    });
                    this.getDisbursementList();
                    this.acceptModal = false;
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        decline() {
            var data = {
                status: "decline",
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/wallet/disbursement/accept-or-decline/${this.declineData.request_id}`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Berhasil ditolak",
                    });
                    this.getDisbursementList();
                    this.declineModal = false;
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style></style>
