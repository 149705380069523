<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Tags</h3>
            </div>
            <div class="d-flex mt-4">
                <div class="me-3">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Search"
                            v-model="req.search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="ms-4 me-3" v-show="isRole(crmRoles)">
                    <button
                        class="btn btn-blue btn-with-search px-4"
                        :disabled="checked.length == 0"
                        @click="distributeModal = true"
                    >
                        Distribution To
                    </button>
                </div>
                <div>
                    <a
                        href="/crm/tags/create"
                        class="btn btn-purple btn-with-search px-4"
                    >
                        Add Tags
                    </a>
                </div>
                <div class="me-auto m-mt-1 ms-3" v-show="isRole(branchRoles)">
                    <button
                        class="btn btn-outline-black btn-filter px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <label class="fw-bold">Filter</label>
                    </div>
                    <div class="col-md-8">
                        <multiselect
                            v-model="branch"
                            :options="branchs"
                            label="name"
                            track-by="id"
                            placeholder="Select Branch"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-outline-black px-5 w-100"
                            :disabled="branch == null"
                            @click="clearFilter()"
                        >
                            Clear
                        </button>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-green px-5 w-100"
                            :disabled="branch == null"
                            @click="filter()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <div class="mt-4" v-if="is_list_ready">
                        <ListLoader />
                    </div>
                    <template v-else>
                        <template v-if="data.list_data.length > 0">
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th
                                                class="text-center"
                                                style="width: 40px"
                                                scope="col"
                                            >
                                                No
                                            </th>
                                            <th
                                                class="text-center"
                                                style="width: 120px"
                                                scope="col"
                                            >
                                                Members
                                            </th>
                                            <th class="text-center" scope="col">
                                                Tag Name
                                            </th>
                                            <th
                                                class="text-center"
                                                scope="col"
                                                v-show="isRole(crmRoles)"
                                            >
                                                Owner
                                            </th>
                                            <th
                                                class="text-center"
                                                scope="col"
                                                v-show="isRole(branchRoles)"
                                            >
                                                Branch Name
                                            </th>
                                            <th
                                                v-show="isRole(crmRoles)"
                                                class="text-center"
                                                style="width: 40px"
                                                scope="col"
                                            >
                                                Distribution
                                            </th>
                                            <th
                                                class="text-center"
                                                style="width: 40px"
                                                scope="col"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(
                                                item, index
                                            ) in data.list_data"
                                            :key="index"
                                        >
                                            <td class="text-center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="text-center">
                                                {{ item.member }}
                                            </td>
                                            <td class="capitalize">
                                                <a
                                                    :href="
                                                        '/crm/tags/detail/' +
                                                        item.id +
                                                        '?tag_name=' +
                                                        item.tag_name
                                                    "
                                                    class="text-dark"
                                                >
                                                    <u>
                                                        {{ item.tag_name }}
                                                    </u>
                                                </a>
                                            </td>
                                            <td
                                                class="text-center"
                                                v-show="isRole(crmRoles)"
                                            >
                                                {{
                                                    item.created_by
                                                        ? item.created_by
                                                        : "-"
                                                }}
                                            </td>
                                            <td
                                                class="text-center"
                                                v-show="isRole(branchRoles)"
                                            >
                                                {{
                                                    item.branch_name
                                                        ? item.branch_name
                                                        : "-"
                                                }}
                                            </td>
                                            <td
                                                class="text-center"
                                                v-show="isRole(crmRoles)"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    v-model="checked"
                                                    :value="item.id"
                                                    id="allCheck"
                                                />
                                            </td>
                                            <td class="text-center">
                                                <a
                                                    href="javascript:void(0)"
                                                    @click="
                                                        openModalDelete(item.id)
                                                    "
                                                >
                                                    <img
                                                        src="../../assets/dashboard/trash-icon.png"
                                                        alt=""
                                                        width="18"
                                                    />
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-center mt-section">
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </template>
                    </template>
                    <div class="d-flex" v-if="data.list_data.length > 0">
                        <div class="ms-auto">
                            <div class="d-flex mt-4">
                                <div class="me-3">
                                    <select
                                        class="form-control form-select w-select"
                                        v-model="per_page"
                                        @change="getList()"
                                    >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                                <b-pagination-nav
                                    v-model="currentPage"
                                    :number-of-pages="data.total_page"
                                    base-url="#"
                                    first-number
                                    align="right"
                                    @input="changePage"
                                ></b-pagination-nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="showModalDelete"></div>
            <div class="modal-body-chat vsm" v-if="showModalDelete">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/trash.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Penghapusan Data?</h4>
                </div>
                <div class="mt-4 row">
                    <div class="col-md-6">
                        <a
                            href="javascript:void(0)"
                            @click="showModalDelete = false"
                            class="btn btn-primary w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6">
                        <button
                            class="btn btn-outline-primary w-100 btn-lg"
                            type="button"
                            disabled
                            v-if="is_delete"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Ya
                        </button>
                        <button
                            @click="deleteList()"
                            class="btn btn-outline-primary w-100 btn-lg"
                            v-else
                        >
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div
                class="overlay"
                v-if="distributeModal"
                @click="distributeModal = false"
            ></div>
            <div class="modal-body-chat md" v-if="distributeModal">
                <div class="mb-3">
                    <h3 class="fw-bold">Distribution To</h3>
                </div>
                <div class="position-relative mt-4">
                    <input
                        class="form-control form-control-lg pl-search w-100"
                        v-model="req.search_distribute"
                        type="text"
                        placeholder="Search CRM Specialist Name"
                    />
                    <span class="search-icon left">
                        <i class="fa fa-search fa-beat fa-sm"></i>
                    </span>
                </div>
                <div
                    :class="{ '': true, scroll: distribute.length > 5 }"
                    class="mb-2 mt-4"
                >
                    <div
                        v-for="(option, index) in distribute"
                        :key="index"
                        class="radio-option fw-medium capitalize"
                    >
                        <label :for="option.id">
                            <input
                                type="radio"
                                :id="option.id"
                                :value="option.id"
                                v-model="specialist_id"
                            />
                            <span class="text mt-1">
                                {{ option.name }}
                            </span>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <button
                            class="btn btn-outline-black w-100 btn-lg"
                            @click="distributeModal = false"
                        >
                            Cancel
                        </button>
                    </div>
                    <div class="col-md-6 mt-3">
                        <button
                            class="btn btn-green w-100 btn-lg"
                            @click="storeDistribute()"
                            :disabled="is_distribute"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_distribute"
                            ></span>
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="showModalSuccessDistribute"></div>
            <div class="modal-body-chat vsm" v-if="showModalSuccessDistribute">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Distribution Successfully!
                    </h4>
                </div>
                <div class="mt-4">
                    <a href="/crm/tags" class="btn btn-green w-100 btn-lg">
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        Multiselect,
    },
    name: "Tags",
    data() {
        return {
            moment: moment,
            req: {
                search: "",
                search_distribute: "",
                start: "",
                end: "",
            },
            start_date: "",
            end_date: "",
            is_list_ready: false,
            data: {
                list_data: [],
            },
            per_page: 10,
            currentPage: 0,
            range: "",
            checked: [],
            id: null,
            showModalDelete: false,
            is_delete: false,
            distributeModal: false,
            distribute: [],
            specialist_id: null,
            is_distribute: false,
            showModalSuccessDistribute: false,
            crmRoles: ["crm-manager", "admin-crm", "admin-super"],
            branchRoles: ["admin-crm"],
            roles: [],
            isFilter: false,
            branch: null,
            branchs: [],
        };
    },
    created() {
        this.getList();
        this.gitListDistribute();
        this.getUserLogin();
        this.getBranchs();
    },
    methods: {
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.roles = res.data.data.roles;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        changePage() {
            this.getList();
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        decimalFormat(num) {
            return (
                "" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getList() {
            let payload = {
                search: this.req.search,
                limit: this.per_page,
                branch_id: this.branch?.id,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            this.is_list_ready = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/tags`, {
                params: payload,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.data = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        openModalDelete(id) {
            this.showModalDelete = true;
            this.id = id;
        },
        deleteList() {
            this.is_delete = true;
            var data = {
                tag_id: this.id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/tags/delete`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_delete = false;
                    this.showModalDelete = false;
                    this.getList();
                    console.log(res);
                })
                .catch((err) => {
                    this.is_delete = false;
                    this.showModalDelete = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        gitListDistribute() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist`,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.distribute = data.list_data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        storeDistribute() {
            this.is_distribute = true;
            var data = {
                specialist_id: this.specialist_id,
                tag_id: this.checked,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/tags/distribute`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_distribute = false;
                    this.showModalSuccessDistribute = true;
                    this.distributeModal = false;
                    this.getList();
                    console.log(res);
                })
                .catch((err) => {
                    this.is_distribute = false;
                    this.showModalSuccessDistribute = false;
                    this.distributeModal = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        clearFilter() {
            this.branch = null;
            this.getList();
        },
        filter() {
            this.getList();
        },
        getBranchs() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/branches/dropdown`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.branchs = res.data.data;
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.text-red-dashboard {
    color: #e60013;
}
.close-icon {
    width: 25px;
    height: 25px;
    background: #e60013;
    color: #fff;
    border-radius: 50px;
    padding: 3px;
    text-align: center;
    font-size: 13px;
    position: absolute;
    right: -5px;
    top: -5px;
}
.radio-option label {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    cursor: pointer;
}
.radio-option label .text {
    font-size: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    border-bottom: 1px solid #f3f4f6;
    padding-bottom: 15px;
}
.radio-option label input {
    display: none;
}
.radio-option label input + .text:after {
    font-family: FontAwesome;
    content: "\f1db";
    line-height: 1;
    display: inline-block;
    color: #f3f4f6;
    font-size: 20px;
}
.radio-option label input:checked + .text:after {
    font-family: FontAwesome;
    content: "\f111";
    color: #3d8824;
}
</style>
