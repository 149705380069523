<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/trawltruck/ho/fleet" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Tambah Data Truk</h3>
            </div>
            <div class="row mt-5 m-mt-2">
                <div class="col-md-12">
                    <label class="fw-bold mb-2">Jenis Truk</label>
                    <select
                        class="form-control form-select"
                        v-model="req.fleet_type"
                    >
                        <option value="">Pilih Jenis Truk</option>
                        <template v-for="(fleet, index) in fleet_types">
                            <option :value="fleet.value" :key="index">
                                {{ fleet.label }}
                            </option>
                        </template>
                    </select>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Keterangan Truk</label>
                    <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="4"
                        placeholder="Masukkan  Keterangan Truk (Merk/Model Truk)"
                        class="form-control"
                        v-model="req.fleet_model"
                    ></textarea>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nama Mitra (Kode)</label>
                    <select
                        class="form-control form-select"
                        v-model="req.partner_id"
                    >
                        <option value="">Pilih Mitra</option>
                        <template v-for="(mitra, index) in mitras">
                            <option :value="mitra.id" :key="index">
                                {{ mitra.name }}
                            </option>
                        </template>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Tahun Kendaraan</label>
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Masukkan Tahun Kendaraan"
                        v-model="req.fleet_year"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nomor Polisi</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Nomor Polisi"
                        v-model="req.fleet_number"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nomor Rangka</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Nomor Rangka"
                        v-model="req.fleet_identity"
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Status</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="accept"
                                value="accept"
                                v-model="req.status"
                            />
                            <label class="form-check-label fw-bold" for="accept"
                                >Aktif</label
                            >
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="reject"
                                value="reject"
                                v-model="req.status"
                            />
                            <label class="form-check-label fw-bold" for="reject"
                                >Tidak Aktif</label
                            >
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold">Foto Truk</label>
                    <div class="text-gray mb-2">
                        Foto full body truk maks 5MB
                    </div>
                    <div
                        class="image-view-1"
                        :style="{
                            'background-image':
                                'url(' + req.fleet_picture + ')',
                        }"
                    >
                        <div class="mt-4 ml-4">
                            <label
                                for="fleet_picture"
                                class="upload-button single-image"
                            >
                                <i class="fa fa-upload"></i>
                                <input
                                    type="file"
                                    id="fleet_picture"
                                    @change="uploadTRUCK()"
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold">Foto BPKB</label>
                    <div class="text-gray mb-2">Foto full BPKB maks 5MB</div>
                    <div
                        class="image-view-2"
                        :style="{
                            'background-image':
                                'url(' + req.ownership_picture + ')',
                        }"
                    >
                        <div class="mt-4 ml-4">
                            <label
                                for="ownership_picture"
                                class="upload-button single-image"
                            >
                                <i class="fa fa-upload"></i>
                                <input
                                    type="file"
                                    id="ownership_picture"
                                    @change="uploadBPKB()"
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold">Foto STNK</label>
                    <div class="text-gray mb-2">Full STNK maks 5MB</div>
                    <div
                        class="image-view-3"
                        :style="{
                            'background-image':
                                'url(' + req.registration_picture + ')',
                        }"
                    >
                        <div class="mt-4 ml-4">
                            <label
                                for="registration_picture"
                                class="upload-button single-image"
                            >
                                <i class="fa fa-upload"></i>
                                <input
                                    type="file"
                                    id="registration_picture"
                                    @change="uploadSTNK()"
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <a
                                href="/trawltruck/ho/fleet"
                                class="btn btn-outline-black btn-lg px-5 me-3 m-w-100"
                                >Batal</a
                            >
                            <button
                                class="btn btn-green btn-lg px-5 me-3 m-w-100 m-mt-1"
                                type="button"
                                disabled
                                v-if="is_save"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Simpan
                            </button>
                            <button
                                class="btn btn-green btn-lg px-5 me-3 m-w-100 m-mt-1"
                                @click="storeData()"
                                v-else
                            >
                                Simpan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data Saved!!</h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/trawltruck/ho/fleet"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalError"></div>
            <div class="modal-body-chat vsm" v-if="modalError">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/close.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Penambahan Data Gagal!</h4>
                    <div class="mt-2">
                        Mohon maaf terjadi kesalahan! Silakan ulangi kembali
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="modalError = false"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import $ from "jquery";

export default {
    props: "",
    name: "ManagementTruckCreate",
    data() {
        return {
            showPassword: false,
            is_changing_fleet_picture: false,
            is_changing_registration_picture: false,
            is_changing_ownership_picture: false,
            req: {
                partner_id: "",
                status: "accept",
                fleet_type: "",
                fleet_model: "",
                fleet_number: "",
                fleet_identity: "",
                fleet_year: null,
                fleet_picture: "",
                registration_picture: "",
                ownership_picture: "",
            },
            modalSuccess: false,
            modalError: false,
            mitras: [],
            fleet_types: [
                {
                    id: 1,
                    value: "blind-van",
                    label: "Blind Van",
                },
                {
                    id: 2,
                    value: "pickup-bak",
                    label: "Pickup Bak",
                },
                {
                    id: 3,
                    value: "pickup-box",
                    label: "Pickup Box",
                },
                {
                    id: 4,
                    value: "cde-bak",
                    label: "Cde Bak",
                },
                {
                    id: 5,
                    value: "cde-box",
                    label: "Cde Box",
                },
                {
                    id: 6,
                    value: "cdelong-box",
                    label: "Cdelong Box",
                },
                {
                    id: 7,
                    value: "cdd-bak",
                    label: "Cdd Bak",
                },
                {
                    id: 8,
                    value: "cdd-box",
                    label: "Cdd Box",
                },
                {
                    id: 9,
                    value: "cdd-reefer",
                    label: "Cdd Reefer",
                },
                {
                    id: 10,
                    value: "cddlong-bak",
                    label: "Cddlong Bak",
                },
                {
                    id: 11,
                    value: "cddlong-box",
                    label: "Cddlong Box",
                },
                {
                    id: 12,
                    value: "fuso-bak",
                    label: "Fuso Bak",
                },
                {
                    id: 13,
                    value: "tronton-bak",
                    label: "Tronton Bak",
                },
                {
                    id: 14,
                    value: "tronton-box",
                    label: "Tronton Box",
                },
                {
                    id: 15,
                    value: "wingbox",
                    label: "Wingbox",
                },
                {
                    id: 16,
                    value: "towing",
                    label: "Towing",
                },
                {
                    id: 17,
                    value: "selfloader",
                    label: "Selfloader",
                },
                {
                    id: 18,
                    value: "trailer-20feet",
                    label: "Trailer 20feet",
                },
                {
                    id: 19,
                    value: "trailer-40feet",
                    label: "Trailer 40feet",
                },
                {
                    id: 20,
                    value: "lowbed",
                    label: "Lowbed",
                },
            ],
            is_save: false,
        };
    },
    created() {
        this.getMitra();
    },
    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        uploadTRUCK() {
            var fleet_picture = new FileReader();
            fleet_picture.onload = function (e) {
                $(".image-view-1").css(
                    "background-image",
                    "url(" + e.target.result + ")"
                );
            };
            fleet_picture.readAsDataURL($("#fleet_picture")[0].files[0]);
            this.is_changing_fleet_picture = true;
        },
        uploadBPKB() {
            var ownership_picture = new FileReader();
            ownership_picture.onload = function (e) {
                $(".image-view-2").css(
                    "background-image",
                    "url(" + e.target.result + ")"
                );
            };
            ownership_picture.readAsDataURL(
                $("#ownership_picture")[0].files[0]
            );
            this.is_changing_ownership_picture = true;
        },
        uploadSTNK() {
            var registration_picture = new FileReader();
            registration_picture.onload = function (e) {
                $(".image-view-3").css(
                    "background-image",
                    "url(" + e.target.result + ")"
                );
            };
            registration_picture.readAsDataURL(
                $("#registration_picture")[0].files[0]
            );
            this.is_changing_registration_picture = true;
        },
        getMitra() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partner/list`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.mitras = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        storeData() {
            var fleet_picture = $("#fleet_picture")[0].files[0];
            var registration_picture = $("#registration_picture")[0].files[0];
            var ownership_picture = $("#ownership_picture")[0].files[0];
            var data = new FormData();

            data.append("partner_id", this.req.partner_id);
            data.append("status", this.req.status);
            data.append("fleet_type", this.req.fleet_type);
            data.append("fleet_model", this.req.fleet_model);
            data.append("fleet_number", this.req.fleet_number);
            data.append("fleet_identity", this.req.fleet_identity);
            data.append("fleet_year", this.req.fleet_year);

            if (this.is_changing_fleet_picture) {
                data.append("fleet_picture", fleet_picture);
            }
            if (this.is_changing_registration_picture) {
                data.append("registration_picture", registration_picture);
            }
            if (this.is_changing_ownership_picture) {
                data.append("ownership_picture", ownership_picture);
            }

            this.is_save = true;

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/fleet`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    this.modalSuccess = true;
                    this.is_changing_fleet_picture = false;
                    this.is_changing_registration_picture = false;
                    this.is_changing_ownership_picture = false;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
</style>
