<template>
  <div>
    <statusManifest :items="items" />
  </div>
</template>
<script>
import statusManifest from "./StatusManifest.vue";

export default {
  components: {
    statusManifest,
  },
  name: "Dashboard-MB",
  data() {
    return {
      items: {
        name: "Mitra Business",
        partner_type: "mb",
        url: "admin-income-mb-detail",
      },
    };
  },
  created() {},
  methods: {},
};
</script>
<style scoped></style>
