<template>
    <div class="padding-container">
        <div class="d-flex align-items-center bd-highlight">
            <div class="flex-grow-1 bd-highlight">
                <h3 class="fw-bold">Invoice</h3>
            </div>
        </div>
        <div class="box mt-4">
            <div class="row">
                <div class="col-md-6 mt-3">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Cari Nomor Resi"
                            v-model="req.search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <table
                    class="table position-relative mt-5"
                    v-if="list.data.length > 0"
                >
                    <thead class="thead">
                        <tr class="tr">
                            <td class="th">No</td>
                            <td class="th">Nomor Resi</td>
                            <td class="th">Jenis Order</td>
                            <td class="th">Tanggal Pesanan</td>
                        </tr>
                    </thead>
                    <tr>
                        <td>
                            <div></div>
                        </td>
                    </tr>
                    <template v-for="(list, index, key) in list.data">
                        <tr class="tr border-body" :key="list.hash">
                            <td class="td fw-bold">{{ index + 1 }}.</td>
                            <td class="td pt-3 pb-3 fw-bold">
                                <a
                                    href="javascript:void(0)"
                                    class="text-dark pl-0"
                                    @click="showDetail(list.hash)"
                                >
                                    {{ list.receipt_code }}
                                </a>
                            </td>
                            <td class="td capitalize">
                                {{ list.order_type }}
                            </td>
                            <td class="td">
                                {{
                                    moment(list.created_at).format(
                                        "DD MMM YYYY"
                                    )
                                }}
                            </td>
                        </tr>
                        <tr class="border-footer bc-gray pb-5" :key="index">
                            <td class="p-2 pt-1 pb-1" colspan="8">
                                <div class="d-flex align-items-center">
                                    <div class="text-warning capitalize">
                                        {{ list.status_label }}
                                    </div>
                                    <div class="ms-auto">
                                        <button
                                            v-if="list.payment_status == 'paid'"
                                            class="btn btn-green btn-sm px-4"
                                            @click="print(list.hash)"
                                        >
                                            <i class="fa fa-print me-1"></i>
                                            Cetak
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr :key="key">
                            <td colspan="9">
                                <div class=""></div>
                            </td>
                        </tr>
                    </template>
                </table>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <b-pagination-nav
                v-if="list.data.length > 0"
                :link-gen="linkGen"
                v-model="currentPage"
                :number-of-pages="totalPage"
                base-url="#"
                first-number
                align="right"
            ></b-pagination-nav>
            <!-- <b-pagination-nav v-if="list.data.length > 0" v-model="currentPage" :number-of-pages="list.last_page" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav> -->
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="showModalPrint"></div>
            <div class="modal-body-chat md" v-if="showModalPrint">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <a
                            href="javascript:void(0)"
                            @click="generatePDF()"
                            class="text-green fw-bold size-16"
                        >
                            <i class="fa fa-print me-1"></i>
                            Cetak Resi [OLD]
                        </a>
                        <a
                            href="javascript:void(0)"
                            @click="generatePDFNew()"
                            class="text-green fw-bold size-16"
                        >
                            <i class="fa fa-print me-1"></i>
                            Cetak Resi [NEW]
                        </a>
                        <vue-html2pdf
                            :show-layout="false"
                            :float-layout="true"
                            :enable-download="false"
                            :preview-modal="true"
                            filename="nightprogrammerpdf"
                            :pdf-quality="2"
                            :manual-pagination="true"
                            pdf-format="a4"
                            :pdf-margin="10"
                            pdf-orientation="portrait"
                            pdf-content-width="750px"
                            @progress="onProgress($event)"
                            ref="html2Pdf"
                        >
                            <section slot="pdf-content">
                                <template v-for="item in detailInvoice.items">
                                    <template
                                        v-for="(print, index) in item.codes"
                                    >
                                        <div class="p-print" :key="index">
                                            <div class="box border-print">
                                                <div
                                                    class="d-flex align-items-center"
                                                >
                                                    <div
                                                        class="size-18 fw-bold text-dark"
                                                    >
                                                        <img
                                                            src="../../assets/trawlpack.png"
                                                            class="me-1"
                                                            width="30"
                                                            alt=""
                                                        />
                                                        TROPACK
                                                    </div>
                                                    <div class="ms-auto">
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <div class="me-3">
                                                                <span
                                                                    class="tag green tag-lg pb-1 px-3 capitalize"
                                                                >
                                                                    <template
                                                                        v-if="
                                                                            detailInvoice.service_code ==
                                                                            'tps'
                                                                        "
                                                                    >
                                                                        Reguler
                                                                    </template>
                                                                    <template
                                                                        v-else
                                                                    >
                                                                        Express</template
                                                                    >
                                                                </span>
                                                            </div>
                                                            <img
                                                                src="../../assets/logo-tb-order.png"
                                                                class="radius-tb-logo"
                                                                width="100"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-5">
                                                    <div class="col-md-8">
                                                        <div class="">
                                                            <h5
                                                                class="mb-0 fw-bold text-dark"
                                                            >
                                                                {{
                                                                    detailInvoice.partner_type
                                                                }}
                                                            </h5>
                                                            <h5
                                                                class="mb-0 fw-bold text-dark mt-2"
                                                            >
                                                                {{
                                                                    detailInvoice.partner_code
                                                                }}
                                                            </h5>
                                                            <h6
                                                                class="mb-0 fw-bold text-dark"
                                                            >
                                                                {{
                                                                    (
                                                                        detailInvoice.created_at ||
                                                                        ""
                                                                    )
                                                                        .split(
                                                                            "-"
                                                                        )
                                                                        .join(
                                                                            " "
                                                                        )
                                                                }}
                                                            </h6>
                                                        </div>
                                                        <div class="mt-3">
                                                            <div
                                                                class="size-18 fw-bold mt-2 text-dark"
                                                            >
                                                                Dari
                                                            </div>
                                                            <div
                                                                class="size-16 fw-bold text-dark"
                                                            >
                                                                {{
                                                                    detailInvoice
                                                                        .origin_address
                                                                        .province
                                                                }},
                                                                {{
                                                                    detailInvoice
                                                                        .origin_address
                                                                        .regency
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div class="mt-3">
                                                            <div
                                                                class="size-18 fw-bold mt-2 text-dark"
                                                            >
                                                                Ke
                                                            </div>
                                                            <div
                                                                class="size-16 fw-bold text-dark"
                                                            >
                                                                {{
                                                                    detailInvoice
                                                                        .destination_address
                                                                        .province
                                                                }},
                                                                {{
                                                                    detailInvoice
                                                                        .destination_address
                                                                        .regency
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <center>
                                                            <vue-qr
                                                                :text="
                                                                    detailInvoice.package_code
                                                                "
                                                                :callback="test"
                                                                qid="testid"
                                                                :size="140"
                                                            ></vue-qr>
                                                        </center>
                                                        <div
                                                            class="size-16 fw-bold mt-2 text-dark"
                                                        >
                                                            No. Resi:
                                                        </div>
                                                        <div
                                                            class="size-26 fw-bold text-dark"
                                                        >
                                                            {{
                                                                detailInvoice.package_code
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 mt-5">
                                                        <div
                                                            class="fw-bold size-18 text-dark"
                                                        >
                                                            <img
                                                                src="../../assets/pengirim.png"
                                                                class="me-2"
                                                                width="35"
                                                                alt=""
                                                            />
                                                            Pengirim
                                                        </div>
                                                        <div
                                                            class="fw-bold size-18 mt-3 text-dark"
                                                        >
                                                            {{
                                                                detailInvoice.sender_name
                                                            }}
                                                        </div>
                                                        <div
                                                            class="fw-bold size-16 text-dark"
                                                        >
                                                            {{
                                                                detailInvoice.sender_phone
                                                            }}
                                                        </div>
                                                        <div
                                                            class="fw-bold size-16 text-dark"
                                                        >
                                                            {{
                                                                detailInvoice.sender_address
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 mt-5">
                                                        <div
                                                            class="fw-bold size-18 text-dark"
                                                        >
                                                            <img
                                                                src="../../assets/receive-item.png"
                                                                class="me-2"
                                                                width="35"
                                                                alt=""
                                                            />
                                                            Penerima
                                                        </div>
                                                        <div
                                                            class="fw-bold size-18 mt-3 text-dark"
                                                        >
                                                            {{
                                                                detailInvoice.receiver_name
                                                            }}
                                                        </div>
                                                        <div
                                                            class="fw-bold size-16 text-dark"
                                                        >
                                                            {{
                                                                detailInvoice.receiver_phone
                                                            }}
                                                        </div>
                                                        <div
                                                            class="fw-bold size-16 text-dark"
                                                        >
                                                            {{
                                                                detailInvoice.receiver_address
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 mt-3">
                                                        <div
                                                            class="fw-bold size-16 text-dark"
                                                        >
                                                            Note:
                                                            {{
                                                                detailInvoice.sender_detail_address
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 mt-3">
                                                        <div
                                                            class="fw-bold size-16 text-dark"
                                                        >
                                                            Note:
                                                            {{
                                                                detailInvoice.receiver_detail_address
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr class="mt-4" />
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="mt-3">
                                                            <center>
                                                                <vue-qr
                                                                    :text="
                                                                        print.content
                                                                    "
                                                                    :callback="
                                                                        test
                                                                    "
                                                                    qid="testid"
                                                                    :size="140"
                                                                ></vue-qr>
                                                            </center>
                                                            <div
                                                                class="size-16 fw-bold mt-2"
                                                            >
                                                                No. Barang:
                                                            </div>
                                                            <div
                                                                class="size-24 fw-bold"
                                                            >
                                                                {{
                                                                    print.content
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="mt-3">
                                                            <div
                                                                class="size-16 text-dark fw-bold"
                                                            >
                                                                Kode Pos :
                                                                {{
                                                                    detailInvoice
                                                                        .destination_address
                                                                        .zip_code
                                                                }}
                                                            </div>
                                                            <div
                                                                class="size-16 mt-1 text-dark fw-bold"
                                                            >
                                                                <template
                                                                    v-if="
                                                                        detailInvoice.is_bike ==
                                                                        true
                                                                    "
                                                                >
                                                                    Merek Motor:
                                                                    <span
                                                                        class="fw-bold size-16"
                                                                    >
                                                                        {{
                                                                            detailInvoice
                                                                                .moto_bike
                                                                                .merk
                                                                        }}cc
                                                                    </span>
                                                                </template>
                                                                <template
                                                                    v-else
                                                                >
                                                                    Ket. Barang
                                                                    :
                                                                    {{
                                                                        item.desc ==
                                                                        ""
                                                                            ? item.name
                                                                            : item.desc
                                                                    }}
                                                                </template>
                                                            </div>
                                                            <div
                                                                class="size-16 mt-1 text-dark fw-bold"
                                                            >
                                                                <template
                                                                    v-if="
                                                                        detailInvoice.is_bike ==
                                                                        true
                                                                    "
                                                                >
                                                                    CC Motor:
                                                                    <span
                                                                        class="fw-bold size-16"
                                                                    >
                                                                        {{
                                                                            detailInvoice
                                                                                .moto_bike
                                                                                .cc
                                                                        }}cc
                                                                    </span>
                                                                </template>
                                                                <template
                                                                    v-else
                                                                >
                                                                    Berat :
                                                                    {{
                                                                        item.weight_borne_total
                                                                    }}
                                                                </template>
                                                            </div>
                                                            <div
                                                                class="size-16 mt-1 text-dark fw-bold"
                                                            >
                                                                <template
                                                                    v-if="
                                                                        detailInvoice.is_bike ==
                                                                        true
                                                                    "
                                                                >
                                                                    Jumlah Unit:
                                                                </template>
                                                                <template
                                                                    v-else
                                                                >
                                                                    Jumlah Koli
                                                                    :
                                                                </template>
                                                                {{ print.qty }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="html2pdf__page-break"
                                            :key="print.content"
                                        ></div>
                                    </template>
                                </template>
                            </section>
                        </vue-html2pdf>
                    </div>
                    <div class="bd-highlight">
                        <a
                            href="javascript:void(0)"
                            @click="showModalPrint = false"
                            class="size-20 text-primary"
                        >
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div>
                    <hr />
                </div>
                <div class="scroll mt-3">
                    <div class="d-flex align-items-center">
                        <div class="size-16">
                            <img
                                src="../../assets/trawlpack.png"
                                class="me-1"
                                width="30"
                                alt=""
                            />
                            TRO<span class="fw-bold size-16">PACK</span>
                        </div>
                        <div class="ms-auto">
                            <div class="d-flex align-items-center">
                                <div class="me-3">
                                    <span
                                        class="tag green pb-1 px-3 capitalize"
                                    >
                                        <template
                                            v-if="
                                                detailInvoice.service_code ==
                                                'tps'
                                            "
                                        >
                                            Reguler
                                        </template>
                                        <template v-else> Express</template>
                                    </span>
                                </div>
                                <img
                                    src="../../assets/logo-tb-order.png"
                                    class="radius-tb-logo"
                                    width="70"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-8">
                            <div class="mt-3">
                                <h6 class="mb-0 fw-bold">
                                    {{ detailInvoice.partner_type }}
                                </h6>
                                <h5 class="mb-0 fw-bold mt-2">
                                    {{ detailInvoice.partner_code }}
                                </h5>
                                <h6 class="mb-0 fw-normal">
                                    {{
                                        (detailInvoice.created_at || "")
                                            .split("-")
                                            .join(" ")
                                    }}
                                </h6>
                            </div>
                            <div class="mt-3">
                                <div class="size-16 fw-bold mt-2 text-danger">
                                    Dari
                                </div>
                                <div class="size-16 fw-bold">
                                    {{ detailInvoice.origin_address.province }},
                                    {{ detailInvoice.origin_address.regency }}
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="size-16 fw-bold mt-2 text-danger">
                                    Ke
                                </div>
                                <div class="size-16 fw-bold">
                                    {{
                                        detailInvoice.destination_address
                                            .province
                                    }},
                                    {{
                                        detailInvoice.destination_address
                                            .regency
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <center>
                                <vue-qr
                                    :text="detailInvoice.package_code"
                                    :callback="test"
                                    qid="testid"
                                    :size="140"
                                ></vue-qr>
                            </center>
                            <div class="size-14 fw-bold mt-2">No. Resi:</div>
                            <div class="size-22 fw-bold">
                                {{ detailInvoice.package_code }}
                            </div>
                        </div>
                        <div class="col-md-6 mt-5">
                            <div class="fw-bold size-16">
                                <img
                                    src="../../assets/pengirim.png"
                                    class="me-2"
                                    width="30"
                                    alt=""
                                />
                                Pengirim
                            </div>
                            <div class="fw-bold size-16 mt-3">
                                {{ detailInvoice.sender_name }}
                            </div>
                            <div class="fw-normal size-14">
                                {{ detailInvoice.sender_phone }}
                            </div>
                            <div class="fw-normal size-14">
                                {{ detailInvoice.sender_address }}
                            </div>
                        </div>
                        <div class="col-md-6 mt-5">
                            <div class="fw-bold size-16">
                                <img
                                    src="../../assets/receive-item.png"
                                    class="me-2"
                                    width="30"
                                    alt=""
                                />
                                Penerima
                            </div>
                            <div class="fw-bold size-16 mt-3">
                                {{ detailInvoice.receiver_name }}
                            </div>
                            <div class="fw-medium size-14">
                                {{ detailInvoice.receiver_phone }}
                            </div>
                            <div class="fw-medium size-14">
                                {{ detailInvoice.receiver_address }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mt-3">
                            <div class="fw-bold size-16">
                                Note:
                                <span class="fw-normal">{{
                                    detailInvoice.sender_detail_address
                                }}</span>
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <div class="fw-bold size-16">
                                Note:
                                <span class="fw-normal">{{
                                    detailInvoice.receiver_detail_address
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <hr class="mt-4" />
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mt-4">
                                <center>
                                    <vue-qr
                                        :text="detailInvoice?.items[0]?.code"
                                        :callback="test"
                                        qid="testid"
                                        :size="140"
                                    ></vue-qr>
                                </center>
                                <div class="size-16 fw-bold mt-2">
                                    No. Barang:
                                </div>
                                <div class="size-22 fw-bold">
                                    {{ detailInvoice?.items[0]?.code }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mt-3">
                                <div class="size-16">
                                    Kode Pos :
                                    <span class="fw-bold size-16">{{
                                        detailInvoice.destination_address
                                            .zip_code
                                    }}</span>
                                </div>
                                <div class="size-16 mt-2">
                                    <template
                                        v-if="detailInvoice.is_bike == true"
                                    >
                                        Merek Motor:
                                        <span class="fw-bold size-16">
                                            {{ detailInvoice.moto_bike.merk }}cc
                                        </span>
                                    </template>
                                    <template v-else>
                                        Ket. Barang :
                                        <span class="fw-bold size-16">{{
                                            detailInvoice?.items[0]?.desc == ""
                                                ? detailInvoice?.items[0]?.name
                                                : detailInvoice?.items[0]?.desc
                                        }}</span>
                                    </template>
                                </div>
                                <div class="size-16 mt-2">
                                    <template
                                        v-if="detailInvoice.is_bike == true"
                                    >
                                        CC Motor:
                                        <span class="fw-bold size-16">
                                            {{ detailInvoice.moto_bike.cc }}cc
                                        </span>
                                    </template>
                                    <template v-else>
                                        Berat :
                                        <span class="fw-bold size-16">
                                            {{
                                                detailInvoice?.items[0]
                                                    ?.weight_borne_total
                                            }}
                                        </span>
                                    </template>
                                </div>
                                <div class="size-16">
                                    <template
                                        v-if="detailInvoice.is_bike == true"
                                    >
                                        Jumlah Unit:
                                    </template>
                                    <template v-else> Jumlah Koli : </template>
                                    <span class="fw-bold size-16">{{
                                        detailInvoice?.items[0]?.qty
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="showModalDetailResi"></div>
            <div class="modal-body-chat md" v-if="showModalDetailResi">
                <div class="ms-1 me-1">
                    <div class="d-flex bd-highlight align-items-lg-center">
                        <div class="flex-grow-1 bd-highlight">
                            <div class="size-22 fw-ekstra-bold">
                                Detail Resi
                            </div>
                        </div>
                        <div class="bd-highlight">
                            <a
                                href="javascript:void(0)"
                                @click="showModalDetailResi = false"
                                class="size-20 text-primary"
                            >
                                <i class="fa fa-times-circle"></i>
                            </a>
                        </div>
                    </div>
                    <div>
                        <hr />
                    </div>
                </div>
                <div class="scroll mt-4">
                    <div class="ms-1 me-1 mb-3">
                        <div class="d-flex">
                            <div class="me-3">
                                <img
                                    src="../../assets/logo-tb-order.png"
                                    class="radius-tb-logo"
                                    width="70"
                                    alt=""
                                />
                            </div>
                            <div>
                                <h5 class="mb-0 fw-bold">
                                    {{ detailInvoice.partner_code }}
                                </h5>
                                <h6 class="mb-0 fw-normal mt-1">
                                    {{
                                        (detailInvoice.created_at || "")
                                            .split("-")
                                            .join(" ")
                                    }}
                                </h6>
                                <h6 class="mb-0 fw-normal mt-1">
                                    {{ detailInvoice.order_type }}
                                </h6>
                            </div>
                            <div class="ms-auto">
                                <h5 class="mb-2 fw-bold">
                                    {{ detailInvoice.package_code }}
                                </h5>
                                <span class="tag green pb-1 px-4 capitalize">
                                    <template
                                        v-if="
                                            detailInvoice.service_code == 'tps'
                                        "
                                    >
                                        Reguler
                                    </template>
                                    <template v-else> Express</template>
                                </span>
                            </div>
                        </div>
                        <div class="box p-0 mt-4">
                            <div
                                class="box no-shadow bc-gray p-3 no-radius-bottom"
                            >
                                <h6 class="mb-0 fw-bold size-16">
                                    <i class="fa fa-send me-2"></i>
                                    Data Pengirim
                                </h6>
                            </div>
                            <div class="p-4">
                                <div class="d-flex">
                                    <div class="me-2">
                                        <img
                                            src="../../assets/pengirim.png"
                                            class="me-2"
                                            width="35"
                                            alt=""
                                        />
                                    </div>
                                    <div>
                                        <div class="fw-bold size-16">
                                            {{ detailInvoice.sender_name }}
                                        </div>
                                        <div class="fw-medium size-14 mt-1">
                                            {{ detailInvoice.sender_phone }}
                                        </div>
                                        <div class="fw-medium size-14 mt-1">
                                            {{ detailInvoice.sender_address }}
                                        </div>
                                        <div class="fw-medium size-14 mt-1">
                                            Note:
                                            <span class="fw-bold">{{
                                                detailInvoice.sender_detail_address
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box p-0 mt-4">
                            <div
                                class="box no-shadow bc-gray p-3 no-radius-bottom"
                            >
                                <h6 class="mb-0 fw-bold size-16">
                                    <i class="fa fa-user me-2"></i>
                                    Data Penerima
                                </h6>
                            </div>
                            <div class="p-4">
                                <div class="d-flex">
                                    <div class="me-2">
                                        <img
                                            src="../../assets/receive-item.png"
                                            class="me-2"
                                            width="35"
                                            alt=""
                                        />
                                    </div>
                                    <div>
                                        <div class="fw-bold size-16">
                                            {{ detailInvoice.receiver_name }}
                                        </div>
                                        <div class="fw-medium size-14 mt-1">
                                            {{ detailInvoice.receiver_phone }}
                                        </div>
                                        <div class="fw-medium size-14 mt-1">
                                            {{ detailInvoice.receiver_address }}
                                        </div>
                                        <div class="fw-medium size-14 mt-1">
                                            Note:
                                            <span class="fw-bold">{{
                                                detailInvoice.receiver_detail_address
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box no-shadow yellow mt-4 p-3">
                            <div class="d-flex align-items-center">
                                <div>
                                    <i class="fa fa-cart-plus size-26"></i>
                                </div>
                                <div class="ms-4">
                                    <div class="fw-bold size-16">Darat</div>
                                    <div class="mt-1 size-13 capitalize">
                                        {{ detailInvoice.estimation_notes }}
                                    </div>
                                </div>
                                <div
                                    class="ms-auto"
                                    v-if="detailInvoice.is_bike == false"
                                >
                                    <div class="size-16 fw-bold">
                                        {{
                                            currencyFormat(
                                                detailInvoice.tier_price
                                            )
                                        }}/Kg
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion mt-4" id="accordionExample">
                            <template v-if="detailInvoice.items.length > 0">
                                <template
                                    v-for="(item, index) in detailInvoice.items"
                                >
                                    <div class="accordion-item" :key="index">
                                        <h2
                                            class="accordion-header"
                                            :id="'heading' + index"
                                        >
                                            <button
                                                class="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                :data-bs-target="
                                                    '#collapse' + index
                                                "
                                                aria-expanded="false"
                                                :aria-controls="
                                                    'collapse' + index
                                                "
                                            >
                                                <i
                                                    class="fa fa-briefcase me-2"
                                                ></i>
                                                <template
                                                    v-if="
                                                        detailInvoice.is_bike ==
                                                        false
                                                    "
                                                >
                                                    Data Barang
                                                    {{ index + 1 }}
                                                </template>
                                                <template v-else>
                                                    Data Motor
                                                </template>
                                            </button>
                                        </h2>
                                        <div
                                            :id="'collapse' + index"
                                            class="accordion-collapse collapse"
                                            :aria-labelledby="'heading' + index"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div class="accordion-body">
                                                <div
                                                    class="d-flex align-items-center"
                                                >
                                                    <div
                                                        class="fw-normal size-14"
                                                    >
                                                        Kategori Barang
                                                    </div>
                                                    <div
                                                        class="fw-normal size-14 ms-auto"
                                                    >
                                                        <template
                                                            v-if="
                                                                item.category_name
                                                            "
                                                        >
                                                            {{
                                                                item.category_name
                                                            }}
                                                        </template>
                                                        <template v-else>
                                                            -
                                                        </template>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div
                                                    class="d-flex align-items-center"
                                                >
                                                    <div
                                                        class="fw-normal size-14"
                                                    >
                                                        <template
                                                            v-if="
                                                                detailInvoice.is_bike ==
                                                                false
                                                            "
                                                        >
                                                            Jumlah Koli
                                                        </template>
                                                        <template v-else>
                                                            CC Motor
                                                        </template>
                                                    </div>
                                                    <div
                                                        class="fw-normal size-14 ms-auto"
                                                    >
                                                        <template
                                                            v-if="
                                                                detailInvoice.is_bike ==
                                                                false
                                                            "
                                                        >
                                                            {{ item.qty }}
                                                        </template>
                                                        <template v-else>
                                                            {{
                                                                detailInvoice
                                                                    ?.moto_bike
                                                                    ?.cc
                                                            }}
                                                            CC
                                                        </template>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div
                                                    class="d-flex align-items-center"
                                                >
                                                    <div
                                                        class="fw-normal size-14"
                                                    >
                                                        <template
                                                            v-if="
                                                                detailInvoice.is_bike ==
                                                                false
                                                            "
                                                        >
                                                            Deskripsi Barang
                                                        </template>
                                                        <template v-else>
                                                            Merk Motor
                                                        </template>
                                                    </div>
                                                    <div
                                                        class="fw-normal size-14 ms-auto"
                                                    >
                                                        <template
                                                            v-if="
                                                                detailInvoice.is_bike ==
                                                                false
                                                            "
                                                        >
                                                            <template
                                                                v-if="item.name"
                                                            >
                                                                {{ item.name }}
                                                            </template>
                                                            <template v-else>
                                                                -
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            {{
                                                                detailInvoice
                                                                    ?.moto_bike
                                                                    ?.merk
                                                            }}
                                                        </template>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div
                                                    class="d-flex align-items-center"
                                                >
                                                    <div
                                                        class="fw-normal size-14"
                                                    >
                                                        <template
                                                            v-if="
                                                                detailInvoice.is_bike ==
                                                                false
                                                            "
                                                        >
                                                            Dimensi Barang
                                                        </template>
                                                        <template v-else>
                                                            Tipe Motor
                                                        </template>
                                                    </div>
                                                    <div
                                                        class="fw-normal size-14 ms-auto capitalize"
                                                    >
                                                        <template
                                                            v-if="
                                                                detailInvoice.is_bike ==
                                                                false
                                                            "
                                                        >
                                                            {{ item.length }}x{{
                                                                item.width
                                                            }}x{{ item.height }}
                                                        </template>
                                                        <template v-else>
                                                            {{
                                                                detailInvoice
                                                                    ?.moto_bike
                                                                    ?.type
                                                            }}
                                                        </template>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div
                                                    class="d-flex align-items-center"
                                                >
                                                    <div
                                                        class="fw-normal size-14"
                                                    >
                                                        <template
                                                            v-if="
                                                                detailInvoice.is_bike ==
                                                                false
                                                            "
                                                        >
                                                            Volume Barang
                                                        </template>
                                                        <template v-else>
                                                            Keluaran Tahun
                                                        </template>
                                                    </div>
                                                    <div
                                                        class="fw-normal size-14 ms-auto"
                                                    >
                                                        <template
                                                            v-if="
                                                                detailInvoice.is_bike ==
                                                                false
                                                            "
                                                        >
                                                            {{
                                                                item.weight_borne_volume
                                                            }}kg
                                                        </template>
                                                        <template v-else>
                                                            {{
                                                                detailInvoice
                                                                    ?.moto_bike
                                                                    ?.years
                                                            }}
                                                        </template>
                                                    </div>
                                                </div>
                                                <hr />
                                                <template
                                                    v-if="
                                                        detailInvoice.is_bike ==
                                                        false
                                                    "
                                                >
                                                    <div
                                                        class="d-flex align-items-center"
                                                    >
                                                        <div
                                                            class="fw-normal size-14"
                                                        >
                                                            Berat Barang
                                                        </div>
                                                        <div
                                                            class="fw-normal size-14 ms-auto"
                                                        >
                                                            {{ item.weight }}kg
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </template>
                                                <div
                                                    class="d-flex align-items-center"
                                                >
                                                    <div
                                                        class="fw-normal size-14"
                                                    >
                                                        <template
                                                            v-if="
                                                                detailInvoice.is_bike ==
                                                                false
                                                            "
                                                        >
                                                            Perlindungan Barang
                                                        </template>
                                                        <template v-else>
                                                            Perlindungan Wajib
                                                        </template>
                                                    </div>
                                                    <div
                                                        class="fw-normal size-14 ms-auto"
                                                    >
                                                        <template
                                                            v-if="
                                                                detailInvoice.is_bike ==
                                                                false
                                                            "
                                                        >
                                                            <template
                                                                v-if="
                                                                    item
                                                                        .handling
                                                                        .length >
                                                                    0
                                                                "
                                                            >
                                                                <template
                                                                    v-for="(
                                                                        handling,
                                                                        index
                                                                    ) in item.handling"
                                                                >
                                                                    <small
                                                                        class="tag green capitalize"
                                                                        :key="
                                                                            index
                                                                        "
                                                                    >
                                                                        <template
                                                                            v-if="
                                                                                handling ==
                                                                                'bubble wrap'
                                                                            "
                                                                        >
                                                                            Bubble
                                                                            Wrap
                                                                        </template>
                                                                        <template
                                                                            v-if="
                                                                                handling ==
                                                                                'cardboard'
                                                                            "
                                                                        >
                                                                            Kardus
                                                                        </template>
                                                                        <template
                                                                            v-if="
                                                                                handling ==
                                                                                'plastic'
                                                                            "
                                                                        >
                                                                            Plastik
                                                                        </template>
                                                                        <template
                                                                            v-if="
                                                                                handling ==
                                                                                'wood'
                                                                            "
                                                                        >
                                                                            Kayu
                                                                        </template>
                                                                        <template
                                                                            v-if="
                                                                                handling ==
                                                                                'sandbag sm'
                                                                            "
                                                                        >
                                                                            Karung
                                                                            Kecil
                                                                        </template>
                                                                        <template
                                                                            v-if="
                                                                                handling ==
                                                                                'sandbag md'
                                                                            "
                                                                        >
                                                                            Karung
                                                                            Sedang
                                                                        </template>
                                                                        <template
                                                                            v-if="
                                                                                handling ==
                                                                                'sandbag l'
                                                                            "
                                                                        >
                                                                            Karung
                                                                            Besar
                                                                        </template>
                                                                    </small>
                                                                </template>
                                                            </template>
                                                            <template v-else>
                                                                -
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            <small
                                                                class="tag green"
                                                                >Bubble
                                                                Wrap</small
                                                            >
                                                            <small
                                                                class="tag green"
                                                                >Karung
                                                                Besar</small
                                                            >
                                                        </template>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div
                                                    class="d-flex align-items-center"
                                                >
                                                    <div
                                                        class="fw-bold size-14"
                                                    >
                                                        Subtotal Barang:
                                                    </div>
                                                </div>
                                                <template
                                                    v-if="
                                                        item.prices.handling
                                                            .length > 0
                                                    "
                                                >
                                                    <template
                                                        v-for="(
                                                            handling_price,
                                                            index
                                                        ) in item.prices
                                                            .handling"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center mt-3"
                                                            :key="index"
                                                        >
                                                            <div
                                                                class="fw-normal size-14"
                                                            >
                                                                Biaya
                                                                Perlindungan
                                                                <template
                                                                    v-if="
                                                                        handling_price.type ==
                                                                        'bubble wrap'
                                                                    "
                                                                >
                                                                    Bubble Wrap
                                                                </template>
                                                                <template
                                                                    v-if="
                                                                        handling_price.type ==
                                                                        'cardboard'
                                                                    "
                                                                >
                                                                    Kardus
                                                                </template>
                                                                <template
                                                                    v-if="
                                                                        handling_price.type ==
                                                                        'plastic'
                                                                    "
                                                                >
                                                                    Plastik
                                                                </template>
                                                                <template
                                                                    v-if="
                                                                        handling_price.type ==
                                                                        'wood'
                                                                    "
                                                                >
                                                                    Kayu
                                                                </template>
                                                                <template
                                                                    v-if="
                                                                        handling_price.type ==
                                                                        'sandbag sm'
                                                                    "
                                                                >
                                                                    Karung Kecil
                                                                </template>
                                                                <template
                                                                    v-if="
                                                                        handling_price.type ==
                                                                        'sandbag md'
                                                                    "
                                                                >
                                                                    Karung
                                                                    Sedang
                                                                </template>
                                                                <template
                                                                    v-if="
                                                                        handling_price.type ==
                                                                        'sandbag l'
                                                                    "
                                                                >
                                                                    Karung Besar
                                                                </template>
                                                            </div>
                                                            <div
                                                                class="fw-normal size-14 ms-auto"
                                                            >
                                                                {{
                                                                    currencyFormat(
                                                                        handling_price.amount
                                                                    )
                                                                }}
                                                            </div>
                                                        </div>
                                                    </template>
                                                </template>
                                                <div
                                                    class="d-flex align-items-center mt-3"
                                                >
                                                    <div
                                                        class="fw-normal size-14"
                                                    >
                                                        Total Biaya Perlindungan
                                                    </div>
                                                    <div
                                                        class="fw-normal size-14 ms-auto"
                                                    >
                                                        {{
                                                            currencyFormat(
                                                                item.prices
                                                                    .total_handling_fee
                                                            )
                                                        }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center mt-3"
                                                >
                                                    <div
                                                        class="fw-normal size-14"
                                                    >
                                                        Biaya Asuransi
                                                    </div>
                                                    <div
                                                        class="fw-normal size-14 ms-auto"
                                                    >
                                                        {{
                                                            currencyFormat(
                                                                item.prices
                                                                    .insurance_fee
                                                            )
                                                        }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center mt-3"
                                                    v-if="
                                                        detailInvoice.is_bike ==
                                                        false
                                                    "
                                                >
                                                    <div
                                                        class="fw-normal size-14"
                                                    >
                                                        Biaya Tambahan
                                                    </div>
                                                    <div
                                                        class="fw-normal size-14 ms-auto"
                                                    >
                                                        {{
                                                            currencyFormat(
                                                                item.prices
                                                                    .additional_fee
                                                            )
                                                        }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center mt-3"
                                                >
                                                    <div
                                                        class="fw-bold text-green size-14"
                                                    >
                                                        Total Pembayaran
                                                    </div>
                                                    <div
                                                        class="fw-bold text-green size-14 ms-auto"
                                                    >
                                                        {{
                                                            currencyFormat(
                                                                item.prices
                                                                    .sub_total_fee
                                                            )
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <div class="box p-0 mt-4">
                            <div
                                class="box no-shadow bc-gray p-3 no-radius-bottom"
                            >
                                <h6 class="mb-0 fw-bold size-16">
                                    <i class="fa fa-camera me-2"></i>
                                    Foto Barang Keseluruhan
                                </h6>
                            </div>
                            <div class="row p-4">
                                <template
                                    v-if="detailInvoice.images.length > 0"
                                >
                                    <template
                                        v-for="(
                                            image, index
                                        ) in detailInvoice.images"
                                    >
                                        <div class="col-md-3" :key="index">
                                            <div
                                                class="box no-shadow invoice-item-image"
                                                v-bind:style="{
                                                    'background-image':
                                                        'url(' +
                                                        image.uri +
                                                        ')',
                                                }"
                                            ></div>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="box no-shadow">
                                        <div class="text-center">
                                            <div>
                                                <img
                                                    src="../../assets/no-data.png"
                                                    width="70"
                                                    alt=""
                                                />
                                            </div>
                                            <div class="mt-2 size-16 fw-bold">
                                                Foto Barang Kosong
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div
                            class="box no-shadow green mt-4 p-3"
                            v-if="detailInvoice.transporter_detail"
                        >
                            <div class="d-flex align-items-center">
                                <div>
                                    <img
                                        :src="
                                            detailInvoice.transporter_detail
                                                .images_url
                                        "
                                        width="70"
                                        alt=""
                                    />
                                </div>
                                <div class="ms-4">
                                    <div class="fw-bold size-16 capitalize">
                                        {{
                                            detailInvoice.transporter_detail
                                                .type
                                        }}
                                    </div>
                                    <div class="mt-1 size-13 capitalize">
                                        Maksimal Dimensi:
                                        {{
                                            detailInvoice.transporter_detail
                                                .max_length
                                        }}x{{
                                            detailInvoice.transporter_detail
                                                .max_height
                                        }}x{{
                                            detailInvoice.transporter_detail
                                                .max_width
                                        }}
                                    </div>
                                    <div class="size-13 capitalize">
                                        Maksimal Berat:
                                        {{
                                            detailInvoice.transporter_detail
                                                .max_weight
                                        }}Kg
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box p-0 mt-4">
                            <div
                                class="box no-shadow bc-gray p-3 no-radius-bottom"
                            >
                                <h6 class="mb-0 fw-bold size-16">
                                    <i class="fa fa-file me-2"></i>
                                    Rincian Pembayaran
                                </h6>
                            </div>
                            <div class="p-4">
                                <div class="d-flex align-items-center">
                                    <div class="fw-bold size-14">
                                        Total Berat
                                    </div>
                                    <div class="fw-normal size-14 ms-auto">
                                        {{
                                            detailInvoice.calculate_data
                                                .total_weight
                                        }}Kg
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mt-3">
                                    <div class="fw-normal size-14">
                                        Total Biaya Admin
                                    </div>
                                    <div class="fw-normal size-14 ms-auto">
                                        {{
                                            currencyFormat(
                                                detailInvoice.calculate_data
                                                    .admin_fee
                                            )
                                        }}
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mt-3">
                                    <div class="fw-normal size-14">
                                        Total Biaya Perlindungan
                                    </div>
                                    <div class="fw-normal size-14 ms-auto">
                                        {{
                                            currencyFormat(
                                                detailInvoice.calculate_data
                                                    .handling
                                            )
                                        }}
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mt-3">
                                    <div class="fw-normal size-14">
                                        Biaya Pengiriman
                                    </div>
                                    <div class="fw-normal size-14 ms-auto">
                                        {{
                                            currencyFormat(
                                                detailInvoice.calculate_data
                                                    .service
                                            )
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="d-flex align-items-center mt-3"
                                    v-if="detailInvoice.is_bike == false"
                                >
                                    <div class="fw-normal size-14">
                                        Biaya Tambahan
                                    </div>
                                    <div class="fw-normal size-14 ms-auto">
                                        {{
                                            currencyFormat(
                                                detailInvoice.calculate_data
                                                    .additional_price
                                            )
                                        }}
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mt-3">
                                    <div class="fw-normal size-14">
                                        Biaya Penjemputan
                                    </div>
                                    <div class="fw-normal size-14 ms-auto">
                                        {{
                                            currencyFormat(
                                                detailInvoice.calculate_data
                                                    .pickup
                                            )
                                        }}
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mt-3">
                                    <div class="fw-normal text-danger size-14">
                                        Potongan Harga
                                    </div>
                                    <div
                                        class="fw-normal text-danger size-14 ms-auto"
                                    >
                                        <template
                                            v-if="
                                                detailInvoice.calculate_data
                                                    .discount == 0
                                            "
                                        >
                                            Rp0
                                        </template>
                                        <template v-else>
                                            -{{
                                                currencyFormat(
                                                    detailInvoice.calculate_data
                                                        .discount
                                                )
                                            }}
                                        </template>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mt-3">
                                    <div class="fw-normal size-14">
                                        Biaya Jasa Aplikasi
                                    </div>
                                    <div class="fw-normal size-14 ms-auto">
                                        {{
                                            currencyFormat(
                                                detailInvoice.calculate_data
                                                    .platform_fee
                                            )
                                        }}
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mt-3">
                                    <div class="fw-bold text-green size-14">
                                        Total Biaya
                                    </div>
                                    <div
                                        class="fw-bold text-green size-14 ms-auto"
                                    >
                                        {{
                                            currencyFormat(
                                                detailInvoice.calculate_data
                                                    .total_amount
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";
import VueQr from "vue-qr";
import VueHtml2pdf from "vue-html2pdf";

export default {
    components: {
        VueQr,
        ListLoader,
        VueHtml2pdf,
    },
    name: "TrawlpackSendInvoice",
    data() {
        return {
            moment: moment,
            size: 50,
            req: {
                search: "",
            },
            list: {
                data: [],
                last_page: 0,
                total_data: 0,
                total: 0,
                current_page: 0,
            },
            currentPage: 0,
            totalPage: 0,
            per_page: 10,
            is_list_ready: false,
            detailInvoice: {
                package_child: [],
                calculate_data: {},
                images: [],
                destination_address: {},
                items: [],
                estimation_kg_prices: {},
                estimation_cubic_prices: {},
                origin_address: {},
                transporter_detail: {},
                moto_bike: {},
            },
            showModalPrint: false,
            showModalDetailResi: false,
        };
    },
    created() {
        this.getListInvoice();
    },
    mounted() {},
    computed: {},
    methods: {
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        test(url, id) {
            console.log(url, id);
        },
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        onProgress(event) {
            console.log(`Processed: ${event} / 100`);
        },
        hasGenerated() {
            alert("PDF generated successfully!");
        },
        generatePDF() {
            this.$refs.html2Pdf.generatePdf();
        },
        generatePDFNew() {
            var data = {
                order_id: this.detailInvoice?.id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/partner/trawlpack/invoice/print`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    window.open(res.data.data.file_url, "_blank");
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        changeSearch() {
            this.currentPage = 0;
            this.getListInvoice();
        },
        getListInvoice() {
            this.is_list_ready = true;
            Api.get(`${process.env.VUE_APP_BASE_URL}/partner/cashier/invoice`, {
                params: {
                    status: "",
                    q: this.req.search,
                    per_page: this.per_page,
                    page: this.$route.query.page,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data;
                    this.totalPage = res.data.last_page;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        print(id) {
            this.showModalPrint = true;
            this.getDetail(id);
        },
        showDetail(id) {
            this.showModalDetailResi = true;
            this.getDetail(id);
        },
        getDetail(id) {
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/trawlpack/dashboard/order/detail/${id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detailInvoice = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped>
.table > thead {
    border-bottom: 0px solid #fff;
    font-weight: 700;
}
.tr {
    transition: all 0.8s ease-in-out;
    margin-top: 15px;
    transition: all 0.2s ease-in-out;
    height: auto;
}

.table .th,
.table .td {
    padding: 0px 12px;
}

.ttable {
    width: 100%;
    margin-right: 5px;
}

.table {
    border: 0 !important;
}

.td {
    margin-bottom: 10px;
    border-top: none !important;
}

.table .td {
    font-size: 14px;
    background-color: #fff;
}
.table .td:nth-child(odd) {
    background-color: #fff;
}
.border-body {
    border-top: 1.5px solid #f7f7f7;
    border-left: 1.5px solid #f7f7f7;
    border-right: 1.5px solid #f7f7f7;
    border-bottom: 1.5px solid #f7f7f7;
    box-shadow: 0 1px 12px -1px rgb(141 145 162 / 25%);
}
.border-footer {
    border-bottom: 1.5px solid #f7f7f7;
    border-left: 1.5px solid #f7f7f7;
    border-right: 1.5px solid #f7f7f7;
    box-shadow: 0 3px 10px -2px #e9ecef;
}
.btn-sm,
.btn-group-sm > .btn {
    padding-top: 5px;
}
.radius-tb-logo {
    border-radius: 10px;
}
canvas.canvas {
    width: 100px !important;
    height: 100px !important;
}
canvas.resi {
    width: 100px !important;
    height: 100px !important;
}
.p-print {
    padding: 40px;
    /* margin-left: 40px; */
    padding-right: 0px !important;
}
.border-print {
    border: 2px solid #000;
    border-radius: 30px;
}
.box.yellow {
    background: #feead4;
}
.accordion-body {
    border: 2px solid #f8f9fa;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-top: unset !important;
}
.accordion-button:not(.collapsed) {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom: unset !important;
    background: #fafafa !important;
}
.accordion-button {
    background: #fafafa !important;
}
.box.invoice-item-image {
    width: 100%;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0px !important;
}
.tag-lg {
    font-size: 18px;
}
.fw-700 {
    font-weight: 700 !important;
}
</style>
