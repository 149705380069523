<template>
    <div class="padding-container">
        <div class="content-load" v-if="loading_data"></div>
        <div id="loader" v-if="loading_data"></div>
        <div class="fw-bold">
            <a href="/truck/sticker" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Detail Informasi Driver</h3>
                <div class="ms-auto">
                    <span
                        class="tag yellow-cro radius mb-0 pb-0 px-3"
                        v-if="detail?.status == 'pending'"
                    >
                        Belum Terverifikasi
                    </span>
                    <span
                        class="tag red-cro radius mb-0 pb-0 px-3"
                        v-if="detail?.status == 'reject'"
                    >
                        Ditolak
                    </span>
                    <span
                        class="tag red-cro radius mb-0 pb-0 px-3"
                        v-if="detail?.status == 'banned'"
                    >
                        Banned
                    </span>
                    <span
                        class="tag green-cro radius mb-0 pb-0 px-3"
                        v-if="detail?.status == 'accept'"
                    >
                        Terverifikasi
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nama Driver</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Nama driver"
                        disabled
                        :value="detail?.driver_name"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Jenis Kendaraan</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Jenis kendaraan"
                        disabled
                        :value="detail?.fleet_type_label"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nomor Kendaraan</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Nomor kendaraan"
                        disabled
                        :value="detail?.registration_number"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Kilometer Saat Ini</label>
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Kilometer saat ini"
                            disabled
                            :value="detail?.current_km"
                        />
                        <span class="input-group-text"> Km </span>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Tanggal Pendaftaran</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Tanggal pendaftaran"
                        disabled
                        :value="detail?.created_at"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Tanggal Verifikasi</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Tanggal verifikasi"
                        disabled
                        :value="
                            detail?.verification_at
                                ? detail?.verification_at
                                : '-'
                        "
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Video Kendaraan</label>
                    <div>
                        <template v-if="detail.url_video">
                        <my-video
                            :sources="[getVideoSource]"
                            :options="options_video"
                        >
                        </my-video>
                        <a
                            class="btn btn-outline-success btn-sm m-2"
                            :href="detail.url_video"
                            download
                            target="_blank"
                        >Link Video</a>
                        </template>
                        <img
                            class="photo"
                            src="../../assets/empty-image.png"
                            v-else
                        />
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Foto KM Kendaraan</label>
                    <div>
                        <template v-if="detail.url_photo">
                            <viewer :images="[]" :options="options">
                                <img
                                    class="photo"
                                    :key="detail.url_photo"
                                    :src="detail.url_photo"
                                />
                            </viewer>
                        </template>
                        <img
                            class="photo"
                            src="../../assets/empty-image.png"
                            v-else
                        />
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <button
                        class="btn btn-outline-primary btn-lg px-5 me-3"
                        @click="openModalReject($route.params.id)"
                        :disabled="detail?.status != 'pending'"
                    >
                        Tolak
                    </button>
                    <button
                        class="btn btn-green btn-lg px-5"
                        @click="modalConfirmation = true"
                        :disabled="detail?.status != 'pending'"
                    >
                        Verifikasi
                    </button>
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <h3 class="mb-0 fw-bold">Riwayat Penolakan</h3>
            <div class="history-tl-container mt-2">
                <ul class="timeline timeline-split mt-4">
                    <template v-for="(item, index) in detail.reject_history">
                        <li class="timeline-item" :key="index">
                            <div
                                class="timeline-marker"
                                v-bind:class="{
                                    active: index == 0,
                                }"
                            ></div>
                            <div class="timeline-content">
                                <div class="timeline-title">
                                    <span
                                        v-bind:class="{
                                            'text-dark': index == 0,
                                        }"
                                    >
                                        <template v-if="index == 0"
                                            >Penolakan Pertama</template
                                        >
                                        <template v-else
                                            >Penolakan Kedua</template
                                        >
                                    </span>
                                    -
                                    <span
                                        v-bind:class="{
                                            'text-primary': index == 0,
                                        }"
                                        >{{
                                            moment(item.rejected_at).format(
                                                "DD MMM YYYY | hh:mm"
                                            )
                                        }}</span
                                    >
                                </div>
                                <div
                                    class="box reject no-shadow p-2 mt-1 fw-semibold"
                                >
                                    {{ item.note }}
                                </div>
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalConfirmation"></div>
            <div class="modal-body-chat vsm" v-if="modalConfirmation">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">Driver Akan Diverifikasi</h4>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin memverifikasi driver ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirmation = false"
                            class="btn btn-outline-red w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-green w-100 btn-lg"
                            :disabled="is_store"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_store"
                            ></span>
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Driver Telah Terverifikasi
                    </h4>
                </div>
                <div class="mt-4">
                    <a href="/truck/sticker" class="btn btn-green w-100 btn-lg">
                        Ok
                    </a>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalReject"></div>
            <div class="modal-body-chat vsm" v-if="modalReject">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Pengajuan Branding Akan Ditolak!
                    </h4>
                    <div class="text-gray-dashboard">
                        Mohon lengkapi checklist sheet dan berikan alasan
                        penolakan
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="(modalReject = false), (reject_id = null)"
                            class="btn btn-outline-red w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <a
                            :href="`/truck/sticker/reject/` + reject_id"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ya
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";
import myVideo from "vue-video";

export default {
    components: {
        Viewer,
        myVideo,
    },
    name: "TruckStickerDetail",
    data() {
        return {
            moment: moment,
            loading_data: false,
            detail: {},
            modalConfirmation: false,
            modalSuccess: false,
            is_store: false,
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
            modalReject: false,
            reject_id: null,
        };
    },
    created() {
        this.getDetail();
    },
    computed: {
        getVideoSource() {
            return {
                src: this.detail.url_video,
                type: "video/mp4",
            };
        },
    },
    methods: {
        getDetail() {
            this.loading_data = true;
            var payload = {
                brand_id: this.$route.params.id,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/truck/head-office/branding/detail`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detail = res.data.data;
                    this.loading_data = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.loading_data = false;
                });
        },
        storeData() {
            this.is_store = true;
            var payload = {
                brand_id: this.$route.params.id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/truck/head-office/branding/verification`,
                payload,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_store = false;
                    this.modalConfirmation = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_store = false;
                });
        },
        openModalReject(id) {
            this.reject_id = id;
            this.modalReject = true;
        },
    },
};
</script>

<style scoped>
.photo {
    border-radius: 10px;
    width: 100%;
    height: 200px;
    object-fit: cover;
    border: 1px solid #e9ecef;
    cursor: pointer;
}
.video {
    border-radius: 10px;
    width: 100%;
    height: 200px;
    border: 1px solid #e9ecef;
}
.input-group-text {
    border: 1px solid #e9ecef;
    background-color: #f3f5f7;
}
.box.reject {
    border: 1px solid #d9d9d9;
    background: #f6faff;
    border-radius: 8px;
}
.timeline-marker.active:before {
    background: #e60013;
}
.timeline-marker:after {
    background: #d9d9d9;
}
.timeline-marker:before {
    background: #f7aab0;
}
</style>
