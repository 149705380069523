<template>
    <div class="padding-container">
        <div class="box quotes">
            <div class="d-flex align-items-center no-flex">
                <div class="me-3">
                    <div
                        class="user-quotes"
                        v-if="
                            dashboardData.user.avatar != '' ||
                            dashboardData.user.avatar != null
                        "
                        :style="{
                            'background-image':
                                'url(' + dashboardData.user.avatar + ')',
                        }"
                    ></div>
                    <div
                        v-else
                        class="user-quotes"
                        :style="{
                            'background-image':
                                'url(' + require('../../assets/user.jpg') + ')',
                        }"
                    ></div>
                </div>
                <div class="quotes-title">
                    <i class="fw-semibold">
                        Halo {{ dashboardData.user.name }}! 🥰<br />
                        Semangat bekerja hari ini, semoga target tercapai dan
                        tidak ada data follow up yang tertinggal ya!👍
                    </i>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 mt-4">
                <div class="box dashboard-red">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="size-20 fw-semibold text-white">
                                My Month Target
                            </div>
                            <div class="mt-5">
                                <div class="fw-bold size-18 text-white">
                                    {{
                                        currencyFormat(
                                            dashboardData.monthly_target
                                                .acquisition
                                        )
                                    }}
                                    /
                                    <span class="text-white size-18">{{
                                        currencyFormat(
                                            dashboardData.monthly_target.target
                                        )
                                    }}</span>
                                </div>
                                <div class="progress red mt-3">
                                    <div
                                        class="progress-bar bg-red"
                                        role="progressbar"
                                        :style="{
                                            width:
                                                dashboardData.monthly_target
                                                    .percent + '%',
                                        }"
                                        aria-valuenow="25"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    ></div>
                                </div>
                            </div>
                            <div class="mt-4">
                                <i class="size-20 fw-semibold text-white">
                                    <template
                                        v-if="
                                            dashboardData.monthly_target
                                                .acquisition == 0 ||
                                            dashboardData.monthly_target
                                                .target == 0
                                        "
                                    >
                                        Ayo capai targetmu dengan optimal!🔥🔥
                                    </template>
                                    <template
                                        v-else-if="
                                            dashboardData.monthly_target
                                                .acquisition >=
                                            dashboardData.monthly_target.target
                                        "
                                    >
                                        Selamat targetmu telah tercapai!🔥🔥
                                    </template>
                                    <template v-else>
                                        Ayo targetmu tersisa
                                        <span
                                            class="text-dark size-20 fw-semibold"
                                        >
                                            <template
                                                v-if="
                                                    dashboardData.monthly_target
                                                        .target == 0
                                                "
                                            >
                                                0%
                                            </template>
                                            <template
                                                v-else-if="
                                                    dashboardData.monthly_target
                                                        .acquisition == 0
                                                "
                                            >
                                                100%
                                            </template>
                                            <template v-else>
                                                {{
                                                    (
                                                        ((dashboardData
                                                            .monthly_target
                                                            .target -
                                                            dashboardData
                                                                .monthly_target
                                                                .acquisition) /
                                                            dashboardData
                                                                .monthly_target
                                                                .target) *
                                                        100
                                                    ).toFixed(2)
                                                }}%
                                            </template>
                                        </span>
                                        lagi, Semangat yak!🔥🔥
                                    </template>
                                </i>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <lottie-animation
                                style="height: 100%"
                                :loop="true"
                                :animationData="
                                    require('@/assets/lottie/monthly.json')
                                "
                            />
                        </div>
                    </div>
                </div>
                <div class="box dashboard-blue mt-4">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="size-20 fw-semibold text-white">
                                My Daily Target
                            </div>
                            <div class="mt-5">
                                <div class="fw-bold size-18 text-white">
                                    {{
                                        currencyFormat(
                                            dashboardData.daily_target
                                                .acquisition
                                        )
                                    }}
                                    /
                                    <span class="text-white size-18">{{
                                        currencyFormat(
                                            dashboardData.daily_target.target
                                        )
                                    }}</span>
                                </div>
                                <div class="progress blue mt-3">
                                    <div
                                        class="progress-bar bg-blue"
                                        role="progressbar"
                                        :style="{
                                            width:
                                                dashboardData.daily_target
                                                    .percent + '%',
                                        }"
                                        aria-valuenow="25"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    ></div>
                                </div>
                            </div>
                            <div class="mt-4">
                                <i class="size-20 fw-semibold text-white">
                                    <template
                                        v-if="
                                            dashboardData.daily_target
                                                .acquisition == 0 ||
                                            dashboardData.daily_target.target ==
                                                0
                                        "
                                    >
                                        Ayo capai targetmu dengan optimal!🔥🔥
                                    </template>
                                    <template
                                        v-else-if="
                                            dashboardData.daily_target
                                                .acquisition >=
                                            dashboardData.daily_target.target
                                        "
                                    >
                                        Selamat targetmu telah tercapai!🔥🔥
                                    </template>
                                    <template v-else>
                                        Ayo targetmu tersisa
                                        <span
                                            class="text-dark size-20 fw-semibold"
                                        >
                                            <template
                                                v-if="
                                                    dashboardData.daily_target
                                                        .target == 0
                                                "
                                            >
                                                0%
                                            </template>
                                            <template
                                                v-else-if="
                                                    dashboardData.daily_target
                                                        .acquisition == 0
                                                "
                                            >
                                                100%
                                            </template>
                                            <template v-else>
                                                {{
                                                    (
                                                        ((dashboardData
                                                            .daily_target
                                                            .target -
                                                            dashboardData
                                                                .daily_target
                                                                .acquisition) /
                                                            dashboardData
                                                                .daily_target
                                                                .target) *
                                                        100
                                                    ).toFixed(2)
                                                }}%
                                            </template>
                                        </span>
                                        lagi, Semangat yak!🔥🔥
                                    </template>
                                </i>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <lottie-animation
                                style="height: 100%"
                                :loop="true"
                                :animationData="
                                    require('@/assets/lottie/target.json')
                                "
                            />
                        </div>
                    </div>
                </div>
                <div class="box dashboard-green mt-4 position-relative">
                    <div class="info-box" v-show="infoShow">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="size-14">New Order</div>
                                <div class="text-green fw-semibold size-18">
                                    <template v-if="dealFilter == 'all'">
                                        {{
                                            currencyFormat(
                                                dashboardData.deal_order.all
                                                    .amount_new_order
                                            )
                                        }}
                                    </template>
                                    <template v-if="dealFilter == 'trawlpack'">
                                        {{
                                            currencyFormat(
                                                dashboardData.deal_order
                                                    .trawlpack.amount_new_order
                                            )
                                        }}
                                    </template>
                                    <template
                                        v-if="dealFilter == 'trawlcarrier'"
                                    >
                                        {{
                                            currencyFormat(
                                                dashboardData.deal_order
                                                    .trawlcarrier
                                                    .amount_new_order
                                            )
                                        }}
                                    </template>
                                    <template v-if="dealFilter == 'trawltruck'">
                                        {{
                                            currencyFormat(
                                                dashboardData.deal_order
                                                    .trawltruck.amount_new_order
                                            )
                                        }}
                                    </template>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="size-14">Repeat Order</div>
                                <div class="text-green fw-semibold size-18">
                                    <template v-if="dealFilter == 'all'">
                                        {{
                                            currencyFormat(
                                                dashboardData.deal_order.all
                                                    .amount_repeat_order
                                            )
                                        }}
                                    </template>
                                    <template v-if="dealFilter == 'trawlpack'">
                                        {{
                                            currencyFormat(
                                                dashboardData.deal_order
                                                    .trawlpack
                                                    .amount_repeat_order
                                            )
                                        }}
                                    </template>
                                    <template
                                        v-if="dealFilter == 'trawlcarrier'"
                                    >
                                        {{
                                            currencyFormat(
                                                dashboardData.deal_order
                                                    .trawlcarrier
                                                    .amount_repeat_order
                                            )
                                        }}
                                    </template>
                                    <template v-if="dealFilter == 'trawltruck'">
                                        {{
                                            currencyFormat(
                                                dashboardData.deal_order
                                                    .trawltruck
                                                    .amount_repeat_order
                                            )
                                        }}
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="size-20 fw-semibold text-white">Deal</div>
                        <div class="ms-auto">
                            <select class="pill default" v-model="dealFilter">
                                <option value="all" selected>All</option>
                                <option value="trawlpack">TroPack</option>
                                <option value="trawltruck">TroTruck</option>
                                <option value="trawlcarrier">TroCarrier</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-9">
                            <div class="mt-5">
                                <div class="d-flex align-items-center">
                                    <div class="me-2">
                                        <a
                                            href="javascript:void(0)"
                                            @click="infoShow = !infoShow"
                                        >
                                            <img
                                                src="../../assets/info-white.png"
                                                width="19"
                                                height="19"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                    <div class="fw-bold size-20 text-white">
                                        <template v-if="dealFilter == 'all'">
                                            {{
                                                currencyFormat(
                                                    dashboardData.deal_order.all
                                                        .amount
                                                )
                                            }}
                                        </template>
                                        <template
                                            v-if="dealFilter == 'trawlpack'"
                                        >
                                            {{
                                                currencyFormat(
                                                    dashboardData.deal_order
                                                        .trawlpack.amount
                                                )
                                            }}
                                        </template>
                                        <template
                                            v-if="dealFilter == 'trawlcarrier'"
                                        >
                                            {{
                                                currencyFormat(
                                                    dashboardData.deal_order
                                                        .trawlcarrier.amount
                                                )
                                            }}
                                        </template>
                                        <template
                                            v-if="dealFilter == 'trawltruck'"
                                        >
                                            {{
                                                currencyFormat(
                                                    dashboardData.deal_order
                                                        .trawltruck.amount
                                                )
                                            }}
                                        </template>
                                    </div>
                                </div>
                                <div class="mt-2 text-white">Customers</div>
                                <div class="size-16 fw-semibold text-white">
                                    <template v-if="dealFilter == 'all'">
                                        {{
                                            decimalFormat(
                                                dashboardData.deal_order.all
                                                    .customers
                                            )
                                        }}
                                    </template>
                                    <template v-if="dealFilter == 'trawlpack'">
                                        {{
                                            decimalFormat(
                                                dashboardData.deal_order
                                                    .trawlpack.customers
                                            )
                                        }}
                                    </template>
                                    <template
                                        v-if="dealFilter == 'trawlcarrier'"
                                    >
                                        {{
                                            decimalFormat(
                                                dashboardData.deal_order
                                                    .trawlcarrier.customers
                                            )
                                        }}
                                    </template>
                                    <template v-if="dealFilter == 'trawltruck'">
                                        {{
                                            decimalFormat(
                                                dashboardData.deal_order
                                                    .trawltruck.customers
                                            )
                                        }}
                                    </template>
                                    Customers
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <lottie-animation
                                style="height: 100%"
                                :loop="true"
                                :animationData="
                                    require('@/assets/lottie/deal.json')
                                "
                            />
                        </div>
                    </div>
                </div>
                <div class="box mt-4">
                    <div class="d-flex align-items-center">
                        <div class="size-20 fw-semibold">Prediction</div>
                    </div>
                    <div class="row">
                        <div class="col-md-9">
                            <div class="mt-5">
                                <div class="fw-bold size-20">
                                    {{
                                        currencyFormat(
                                            dashboardData.prediction.amount
                                        )
                                    }}
                                </div>
                                <div class="mt-2">Customers</div>
                                <div class="size-16 fw-semibold">
                                    {{
                                        decimalFormat(
                                            dashboardData.prediction.customers
                                        )
                                    }}
                                    Customers
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mt-3">
                            <lottie-animation
                                style="height: 100%"
                                :loop="true"
                                :animationData="
                                    require('@/assets/lottie/prediction.json')
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-4">
                <div class="box dashboard-yellow p-4">
                    <div class="size-20 fw-semibold">Follow Up Activities</div>
                    <hr />
                    <div class="d-flex align-items-center no-flex">
                        <div>
                            <div class="d-flex align-items-center">
                                <div class="me-4">
                                    <div class="text-gray-dashboard">Today</div>
                                    <div class="mt-2">
                                        <a
                                            href="/crm/prospect/today"
                                            class="size-18 fw-semibold text-blue-link"
                                        >
                                            {{
                                                decimalFormat(
                                                    follow_up_activities_data.today
                                                )
                                            }}
                                            Customers
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <div class="text-gray-dashboard">
                                        Tomorrow
                                    </div>
                                    <div class="mt-2">
                                        <a
                                            href="/crm/prospect/tomorrow"
                                            class="size-18 fw-semibold text-blue-link"
                                        >
                                            {{
                                                decimalFormat(
                                                    follow_up_activities_data.tomorrow
                                                )
                                            }}
                                            Customers
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-3">
                                <div class="me-4">
                                    <div class="text-gray-dashboard">
                                        Next 2 Days
                                    </div>
                                    <div class="mt-2">
                                        <a
                                            href="/crm/prospect/next-two-days"
                                            class="size-18 fw-semibold text-blue-link"
                                        >
                                            {{
                                                decimalFormat(
                                                    follow_up_activities_data.next_2_days
                                                )
                                            }}
                                            Customers
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <div class="text-gray-dashboard">
                                        Next Week
                                    </div>
                                    <div class="mt-2">
                                        <a
                                            href="/crm/prospect/next-week"
                                            class="size-18 fw-semibold text-blue-link"
                                        >
                                            {{
                                                decimalFormat(
                                                    follow_up_activities_data.next_week
                                                )
                                            }}
                                            Customers
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-3">
                                <div class="me-4">
                                    <div class="text-gray-dashboard">
                                        Next Month
                                    </div>
                                    <div class="mt-2">
                                        <a
                                            href="/crm/prospect/next-month"
                                            class="size-18 fw-semibold text-blue-link"
                                        >
                                            {{
                                                decimalFormat(
                                                    follow_up_activities_data.next_month
                                                )
                                            }}
                                            Customers
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <div class="text-gray-dashboard">
                                        Need Followed Up
                                    </div>
                                    <div class="mt-2">
                                        <a
                                            href="/crm/prospect/need-follow-up"
                                            class="size-18 fw-semibold text-blue-link"
                                        >
                                            {{
                                                decimalFormat(
                                                    follow_up_activities_data.need_follow_up
                                                )
                                            }}
                                            Customers
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ms-auto m-mt-2">
                            <lottie-animation
                                style="height: 25vh"
                                :loop="true"
                                :animationData="
                                    require('@/assets/lottie/bell.json')
                                "
                            />
                        </div>
                    </div>
                </div>
                <div class="box p-4 mt-4">
                    <div class="d-flex align-items-center">
                        <div class="size-20 fw-semibold">Leaderboards</div>
                    </div>
                    <hr />
                    <div
                        class="d-flex align-items-center no-flex"
                        v-if="leaderboard_data.leaderboards.length > 0"
                    >
                        <div>
                            <template
                                v-for="(
                                    leaderboard, index
                                ) in leaderboard_data.leaderboards"
                            >
                                <div
                                    class="d-flex align-items-center mt-3"
                                    :key="index"
                                >
                                    <div class="me-3">
                                        <template v-if="leaderboard.rank == 1">
                                            <lottie-animation
                                                style="height: 6vh"
                                                :loop="true"
                                                :animationData="
                                                    require('@/assets/lottie/crown.json')
                                                "
                                            />
                                        </template>
                                        <template v-if="leaderboard.rank == 2">
                                            <lottie-animation
                                                style="height: 6vh"
                                                :loop="true"
                                                :animationData="
                                                    require('@/assets/lottie/silver-coin.json')
                                                "
                                            />
                                        </template>
                                        <template v-if="leaderboard.rank == 3">
                                            <lottie-animation
                                                style="height: 6vh"
                                                :loop="true"
                                                :animationData="
                                                    require('@/assets/lottie/bronze-coin.json')
                                                "
                                            />
                                        </template>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <div
                                                class="user-leaderboard"
                                                v-if="
                                                    leaderboard.avatar != '' ||
                                                    leaderboard.avatar != null
                                                "
                                                :style="{
                                                    'background-image':
                                                        'url(' +
                                                        leaderboard.avatar +
                                                        ')',
                                                }"
                                            ></div>
                                            <div
                                                v-else
                                                class="user-leaderboard"
                                                :style="{
                                                    'background-image':
                                                        'url(' +
                                                        require('../../assets/user.jpg') +
                                                        ')',
                                                }"
                                            ></div>
                                        </div>
                                        <div>
                                            <div class="text-gray-dashboard">
                                                <template
                                                    v-if="leaderboard.rank == 1"
                                                >
                                                    The Winner!
                                                </template>
                                                <template
                                                    v-if="leaderboard.rank == 2"
                                                >
                                                    2nd
                                                </template>
                                                <template
                                                    v-if="leaderboard.rank == 3"
                                                >
                                                    3rd
                                                </template>
                                            </div>
                                            <div
                                                class="fw-bold text-gold size-16"
                                            >
                                                {{ leaderboard.name }}
                                            </div>
                                            <div
                                                class="fw-bold text-green size-16"
                                            >
                                                {{
                                                    currencyFormat(
                                                        leaderboard.amount
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="ms-auto m-mt-3">
                            <lottie-animation
                                style="height: 20vh"
                                :loop="true"
                                :animationData="
                                    require('@/assets/lottie/leaderboards.json')
                                "
                            />
                            <div class="mt-3">
                                <center>
                                    <span
                                        class="tag yellow-solid size-20 radius px-4"
                                    >
                                        <template
                                            v-if="leaderboard_data.current"
                                        >
                                            🔥You’re At
                                            {{ leaderboard_data.current.rank
                                            }}<template
                                                v-if="
                                                    leaderboard_data.current
                                                        .rank == 1
                                                "
                                                >st</template
                                            >
                                            <template
                                                v-else-if="
                                                    leaderboard_data.current
                                                        .rank == 2
                                                "
                                                >nd</template
                                            >
                                            <template
                                                v-else-if="
                                                    leaderboard_data.current
                                                        .rank == 3
                                                "
                                                >rd</template
                                            >
                                            <template v-else>th</template>
                                            !
                                        </template>
                                        <template v-else>
                                            🔥No Ranking
                                        </template>
                                    </span>
                                </center>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 text-center" v-else>
                        <center>
                            <lottie-animation
                                style="height: 20vh"
                                :loop="true"
                                :animationData="
                                    require('@/assets/lottie/empty.json')
                                "
                            />
                            <div class="size-20 text-gray-dashboard fw-medium">
                                Data is Empty
                            </div>
                        </center>
                    </div>
                </div>
                <div class="box mt-4">
                    <div class="d-flex align-items-center">
                        <div class="size-20 fw-semibold">Lost</div>
                    </div>
                    <div
                        class="row align-content-center justify-content-center"
                    >
                        <div class="col-md-9">
                            <div class="mt-5">
                                <div class="size-24 fw-semibold">
                                    {{
                                        decimalFormat(
                                            dashboardData.lost.customers
                                        )
                                    }}
                                    Customers
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mt-3">
                            <lottie-animation
                                style="height: 100%"
                                :loop="true"
                                :animationData="
                                    require('@/assets/lottie/lost-staff.json')
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import LottieAnimation from "lottie-web-vue";
import moment from "moment";

export default {
    name: "dashboardCRMStaff",
    components: {
        LottieAnimation,
    },
    data() {
        return {
            moment: moment,
            infoShow: false,
            dealFilter: "all",
            dashboardData: {
                department_target: [],
                customers_data: {},
                monthly_target: {},
                daily_target: {},
                specialist: {},
                trawlbens_target: {},
                deal_order: {
                    all: {},
                    trawlpack: {},
                    trawlcarrier: {},
                    trawltruck: {},
                },
                prediction: {},
                lost: {},
                quotes: "",
                user: {},
            },
            follow_up_activities_data: {},
            leaderboard_data: {
                current: {},
                leaderboards: [],
            },
        };
    },
    created() {
        this.getDataDashboard();
        this.getFollowUpActivities();
        this.getLeaderboards();
    },
    methods: {
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        decimalFormat(num) {
            return (
                "" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getDataDashboard() {
            let payload = {
                date: moment(this.today).format("MM-YYYY"),
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/dashboard-v2`, {
                params: payload,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.dashboardData = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getFollowUpActivities() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/prospect/count`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.follow_up_activities_data = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getLeaderboards() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/leaderboard`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.leaderboard_data = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
$pill-width: 110px;
$pill-height: 22px;
$pill-border-radius: 40px;
.pill {
    display: inline-block;
    width: $pill-width;
    height: $pill-height;
    border-radius: $pill-border-radius;
    text-align: center;
    color: #000;
    &.default {
        background-color: #fff;
        border: 1px solid #fff;
        outline: 1px solid #fff;
    }
    &.gray {
        background-color: #f4f4f4;
        border: 1px solid #f4f4f4;
        outline: 1px solid #f4f4f4;
    }
}
.box.quotes {
    background-color: #feead4;
    box-shadow: unset;
}
.quotes-title i {
    font-size: 20px;
    color: #1f1e2c;
}
.dashboard-red {
    background-color: #ee4351;
}
.dashboard-blue {
    background-color: #88bfff;
}
.dashboard-green {
    background-color: #7cce60;
}
.dashboard-yellow {
    background-color: #fffacd;
}
.bg-red {
    background-color: #f7aab0 !important;
    border-radius: 50px !important;
}
.progress.red {
    height: 10px !important;
    border-radius: 50px !important;
    background-color: #fff !important;
}
.bg-blue {
    background-color: #48a2d4 !important;
    border-radius: 50px !important;
}
.progress.blue {
    height: 10px !important;
    border-radius: 50px !important;
    background-color: #fff !important;
}
.text-red {
    color: #e60013;
}
.text-blue-link {
    color: #24516a !important;
    text-decoration: underline;
}
.text-gold {
    color: #fb9727;
}
.info-box {
    padding: 20px;
    background: #fff;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    position: absolute;
    top: 8px;
    left: 40px;
    width: 300px;
    z-index: 9;
}
.user-quotes {
    width: 50px;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
}
</style>
