<template>
  <div class="padding-container">
    <income-mitra-table :items="items" />
  </div>
</template>

<script>
import incomeMitraTable from "../../components/IncomeMitraTable.vue";

export default {
  components: {
    incomeMitraTable,
  },
  name: "IncomeSpacePartner",
  data() {
    return {
      items: {
        name: "Mitra Space",
        partner_type: "space",
        url: "admin-income-ms-detail",
      },
    };
  },
};
</script>

<style scoped>
.scroll {
  height: 500px;
  overflow-y: scroll;
}
.row.foto {
  --bs-gutter-x: 0rem !important;
}
</style>
