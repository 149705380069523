<template>
    <div>
      <incomingOrder :items="items" />
    </div>
  </template>
  <script>
  import incomingOrder from "./IncomingOrder.vue";
  
  export default {
    components: {
      incomingOrder,
    },
    name: "Dashboard-MB",
    data() {
      return {
        items:{
          name: "Mitra Business",
          partner_type: "mb",
          url: "admin-income-mb-detail",
        }
      };
    },
    created() {},
    methods: {},
  };
  </script>
  <style scoped>
  </style>
  