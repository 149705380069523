<template>
    <div>
      <incomingOrder :items="items" />
    </div>
  </template>
  <script>
  import incomingOrder from "./IncomingOrder.vue";
  
  export default {
    components: {
      incomingOrder,
    },
    name: "Dashboard-MPW",
    data() {
      return {
        items:{
        name: "Mitra Pool Warehouse",
        partner_type: "mpw",
        url: "admin-income-mb-detail",
      }
      };
    },
    created() {},
    methods: {},
  };
  </script>
  <style scoped>
  </style>
  