<template>
  <div class="padding-container">
    <div class="box mb-3">
      <div class="d-flex bd-highlight mb-3 align-items-center">
        <div class="flex-grow-1 bd-highlight">
          <h4 class="fw-bold">Detail Driver</h4>
        </div>
        <div class="bd-highlight">
          <button class="add-button" @click="showModal = true">Edit Data</button>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <img :src="driverPictureUrl" alt="Avatar" class="avatar" />
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-6">
              <div class="mb-3">
                <div class="mb-1 form-title-font">Nama Lengkap</div>
                <div class="form-driver"><img class="me-3" src="../../assets/person-plain-icon.png" />{{ data.user.name }}</div>
              </div>
              <div class="mb-3">
                <div class="mb-1 form-title-font">Nomor KTP</div>
                <div class="form-driver">{{ data.identity_number }}</div>
              </div>
              <div class="mb-3">
                <div class="mb-1 form-title-font">Nomor telepone</div>
                <div class="form-driver">{{ data.user.phone }}</div>
              </div>
              <div class="mb-3">
                <div class="mb-1 form-title-font">Address</div>
                <div class="form-driver"><img class="me-3" src="../../assets/pin-gps-icon.png" />{{ data.identity_address }}</div>
              </div>
              <div class="mb-3">
                <div class="mb-1 form-title-font">Status</div>
                <div class="form-status" :style="[data.availability == 'open' ? { border: '1px solid #48a2d4' } : { border: '1px solid #e94b58' }]">
                  <span class="dot" :style="[data.availability == 'open' ? { backgroundColor: '#48a2d4' } : { backgroundColor: '#e94b58' }]"></span>{{ data.availability == "open" ? "Aktif" : "Tidak Aktif" }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <div class="mb-1 form-title-font">Email</div>
                <div class="form-driver"><img class="me-3" src="../../assets/message-plain-icon.png" />{{ data.user.email }}</div>
              </div>
              <div class="mb-3">
                <div class="d-flex gap-3 mb-5">
                  <div>
                    <div class="form-title-font mb-1">Foto KTP</div>
                    <enlargeable-image class="image" style="width: 170px !important" :src="identityPictureUrl" />
                  </div>
                  <div>
                    <div class="form-title-font mb-1">Foto SIM</div>
                    <enlargeable-image class="image" style="width: 170px !important" :src="licensePictureUrl" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="showModal" @click="showModal = false"></div>
      <div class="modal-body-chat vsm" v-if="showModal">
        <div class="d-flex bd-highlight align-items-lg-center">
          <div class="flex-grow-1 bd-highlight">
            <div class="size-18 fw-ekstra-bold">Edit Data Driver</div>
          </div>
          <div class="bd-highlight">
            <a href="javascript:void(0)" @click="showModal = false" class="size-20 text-primary">
              <i class="fa fa-times-circle"></i>
            </a>
          </div>
        </div>
        <div class="line"></div>
        <div class="scroll">
          <div>
            <div class="size-16 mb-1">Foto Driver</div>
            <div class="position-relative" style="width: 280px">
              <EnlargeableImage class="preview" style="width: 280px" :src="driverPictureEdit" />
              <label for="upload" class="upload-icon upload-button cursor-pointer">
                <i class="fa fa-upload"></i>
                <input class="cursor-pointer" id="upload" type="file" name="file" ref="file" @change="uploadFileDriver" />
              </label>
            </div>
          </div>
          <div>
            <div class="size-16 mt-3 mb-1">Status</div>
            <div class="mb-3">
              <div class="radio form-check form-check-inline" :style="[req.availability == 'open' ? { border: '1px solid #48a2d4' } : { border: 'none' }]" for="inlineRadio1">
                <input class="form-check-input" v-model="req.availability" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="open" />
                <label class="form-check-label" for="inlineRadio1">Aktif</label>
              </div>
              <div class="radio form-check form-check-inline" :style="[req.availability == 'close' ? { border: '1px solid #48a2d4' } : { border: 'none' }]" for="inlineRadio2">
                <input class="form-check-input" v-model="req.availability" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="close" />
                <label class="form-check-label" for="inlineRadio2">Tidak Aktif</label>
              </div>
            </div>
            <input class="mb-3 form-input" v-model="req.name" placeholder="Nama Driver" />
            <input class="mb-3 form-input" v-model="req.dob" type="text" onfocus="(this.type='date')" onblur="(this.type='text')" id="date" placeholder="Tanggal Lahir Driver" />
            <input class="mb-3 form-input" v-model="req.identity_address" placeholder="Alamat Sesuai KTP" />
            <input class="mb-3 form-input" v-model="req.domicile_address" placeholder="Alamat Domisili" />
            <input class="mb-3 form-input" v-model="req.identity_number" type="number" placeholder="Nomor KTP" />
            <input class="mb-3 form-input" v-model="req.phone" type="number" placeholder="Nomor Telephone" />
            <input class="mb-3 form-input" v-model="req.email" type="email" placeholder="Email" />
            <!-- <div class="mb-3 position-relative">
              <input :type="[showPassword == true ? 'text' : 'password']" class="form-input" v-model="req.password" placeholder="Password" />
              <span class="cursor-pointer eye-icon" @click="toggleShow">
                <i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
              </span>
            </div> -->
            <!-- <div class="row mb-3">
              <div class="col-md-6">
                <div class="size-16">Foto KTP</div>
                <div class="position-relative" style="width: 125px">
                  <EnlargeableImage class="preview-2" :src="identityPictureEdit" />
                  <label for="upload-identity" class="upload-icon cursor-pointer">
                    <i class="fa fa-upload"></i>
                    <input class="cursor-pointer" id="upload-identity" type="file" name="file" ref="file" @change="uploadFileIdentity" />
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="size-16">Foto SIM</div>
                <div class="position-relative" style="width: 125px">
                  <EnlargeableImage class="preview-2" :src="licensePictureEdit" />
                  <label for="upload-license" class="upload-icon cursor-pointer">
                    <i class="fa fa-upload"></i>
                    <input class="cursor-pointer" id="upload-license" type="file" name="file" ref="file" @change="uploadFileLicense" />
                  </label>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="d-flex gap-2">
          <button class="button button--cancel" @click="cancel" :disabled="isSaving">Batal</button>
          <button class="button button--save" @click="submitData" :disabled="isSaving">
            <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
            Simpan
          </button>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="d-flex bd-highlight">
        <div class="flex-grow-1 bd-highlight">
          <h4 class="fw-bold">Riwayat Driver</h4>
        </div>
        <div class="bd-highlight">
          <div class="position-relative">
            <input v-model="keyword" type="text" class="form-control" style="width: 155px" placeholder="Cari Resi" />
            <span class="search-icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="mt-4" v-if="is_my_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">Tanggal Order</div>
            <div class="d-table-cell">Nomor Resi</div>
            <div class="d-table-cell">Jenis Kendaraan</div>
            <div class="d-table-cell">Rute</div>
          </div>
          <div class="d-table-row" v-for="(item, index) in searching" :key="index">
            <div class="d-table-cell">{{ item.no }}</div>
            <div class="d-table-cell">
              {{ item.order_date }}
              <!-- <router-link class="text-dark fw-bold" :to="{ name: 'detail-management-driver', params: { id: index + 1 } }">{{ item.name }}</router-link> -->
            </div>
            <div class="d-table-cell">
              {{ item.code }}
              <!-- <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" :title="item.partner_address"> {{ item.partner_regency }} <i class="fa fa-info-circle text-blue ms-1"></i> </a> -->
            </div>
            <div class="d-table-cell">{{ item.fleet_type }}</div>
            <div class="d-table-cell">{{ item.route }}</div>
          </div>
        </div>
        <div v-if="data.length > 0" class="text-center mt-section">
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
import noImage from "../../assets/no-photo.png";
import EnlargeableImage from "@diracleo/vue-enlargeable-image";
import ListLoader from "../../components/ListLoader.vue";

export default {
  components: {
    EnlargeableImage,
    ListLoader,
  },
  data() {
    return {
      showPassword: false,
      showModal: false,
      isSaving: false,
      keyword: "",
      data: {},
      dataHistory: {},
      type: "Data",
      radioSelected: "",
      imageDriver: "",
      imageIdentity: "",
      imageLicense: "",
      imageDriverEdit: "",
      imageIdentityEdit: "",
      imageLicenseEdit: "",
      req: {
        name: "",
        email: "",
        phone: "",
        password: "",
        identity_number: "",
        identity_address: "",
        domicile_address: "",
        dob: "",
        availability: "",
        driver_picture: null,
        identity_picture: null,
        license_picture: null,
      },
      dummy: [
        {
          name: "01 Agustus 2022",
          phone: "TRB01082200001",
          email: "CDD Long Box",
          status: "Jakarta - Makassar",
        },
        {
          name: "10 Oktober 2022",
          phone: "TRB10102200002",
          email: "CDD Long Box",
          status: "Jakarta - Bandung",
        },
        {
          name: "03 Desember 2022",
          phone: "TRB03122200003",
          email: "CDD Long Box",
          status: "Jakarta - Semarang",
        },
      ],
    };
  },
  created() {
    this.getData();
    this.getHistory();
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    dateFormat(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    getData() {
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/driver/detail`, {
        params: { id: this.$route.params.id },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data = res.data.data;
          this.imageDriver = this.data.driver_picture_url;
          this.imageIdentity = this.data.identity_picture_url;
          this.imageLicense = this.data.license_picture_url;
          this.imageDriverEdit = this.data.driver_picture_url;
          this.imageIdentityEdit = this.data.identity_picture_url;
          this.imageLicenseEdit = this.data.license_picture_url;
          this.req.name = this.data.user.name;
          this.req.email = this.data.user.email;
          this.req.phone = this.data.user.phone;
          this.req.identity_number = this.data.identity_number;
          this.req.identity_address = this.data.identity_address;
          this.req.domicile_address = this.data.domicile_address;
          this.req.dob = this.data.dob;
          this.req.availability = this.data.availability;
        })
        .catch(function (err) {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          console.error(err);
        });
    },
    getHistory() {
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/driver/history`, {
        params: { id: this.$route.params.id },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.dataHistory = res.data.data;
        })
        .catch(function (err) {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          console.error(err);
        });
    },
    submitData() {
      this.isSaving = true;
      var data = new FormData();

      data.append("id", this.$route.params.id);
      data.append("name", this.req.name);
      data.append("username", this.req.email);
      data.append("email", this.req.email);
      data.append("phone", this.req.phone);
      data.append("password", this.req.password);
      data.append("availability", this.req.availability);
      data.append("identity_number", this.req.identity_number);
      data.append("identity_address", this.req.identity_address);
      data.append("domicile_address", this.req.domicile_address);
      data.append("dob", this.req.dob);
      if (this.imageDriverEdit != this.data.driver_picture_url) {
        data.append("driver_picture", this.req.driver_picture);
      }
      data.append("identity_picture", this.req.identity_picture);
      data.append("license_picture", this.req.license_picture);

      Api.post(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/driver/edit`, data, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.showModal = false;
          this.$notify({
            group: "foo",
            type: "success",
            title: "Success",
            text: "Data berhasil dibuat",
          });
          this.getData();
          this.isSaving = false;
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.isSaving = false;
        });
    },
    cancel() {
      this.showModal = false;
      this.getData();
    },
    uploadFileDriver(event) {
      var input = event.target;
      this.req.driver_picture = input.files[0];
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageDriverEdit = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    uploadFileIdentity(event) {
      var input = event.target;
      this.req.identity_picture = input.files[0];

      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageIdentityEdit = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    uploadFileLicense(event) {
      var input = event.target;
      this.req.license_picture = input.files[0];

      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageLicenseEdit = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
  },
  computed: {
    driverPictureUrl() {
      return this.imageDriver.length > 0 ? this.imageDriver : noImage;
    },
    identityPictureUrl() {
      return this.imageIdentity.length > 0 ? this.imageIdentity : noImage;
    },
    licensePictureUrl() {
      return this.imageLicense.length > 0 ? this.imageLicense : noImage;
    },
    driverPictureEdit() {
      return this.imageDriverEdit.length > 0 ? this.imageDriverEdit : noImage;
    },
    identityPictureEdit() {
      return this.imageIdentityEdit.length > 0 ? this.imageIdentityEdit : noImage;
    },
    licensePictureEdit() {
      return this.imageLicenseEdit.length > 0 ? this.imageLicenseEdit : noImage;
    },
    searching() {
      return this.dataHistory.filter((item) => {
        return this.keyword
          .toLowerCase()
          .split(" ")
          .every((v) => item.code.toLowerCase().includes(v));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  margin-left: 25px;
  width: 169px;
  height: 169px;
  object-fit: cover;
  vertical-align: middle;
  border-radius: 10px;
}
.form-driver {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  background: rgba(239, 241, 249, 0.6);
  padding: 10px;
  width: auto;
  height: 50px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.form-status {
  display: flex;
  background: #f6f7fb;
  border: 1px solid #48a2d4;
  border-radius: 8px;
  width: 185px;
  height: 52px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  color: #000000;
}
.dot {
  height: 15px;
  width: 15px;
  margin: 10px;
  background-color: #48a2d4;
  border-radius: 50%;
  display: inline-block;
}
.form-doc {
  margin-top: 20px;
  width: 714px;
  display: flex;
  justify-content: space-between;
}
.form-lg {
  width: auto;
  height: 153px;
  display: block;
}
.form-title-font {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.form-input {
  background: rgba(239, 241, 249, 0.6);
  border-width: 0px;
  border: none;
  outline: none;
  border-radius: 10px;
  width: 280px;
  height: 40px;
  padding: 10px;
}
.insert-image {
  border-width: 0px;
  border: none;
  outline: none;
}
.add-button {
  background: #48a2d4;
  border-radius: 8px;
  border: 1px solid #48a2d4;
  color: white;
  padding: 10px 25px;
}
.add-button:is(:hover, :focus) {
  background: #176f9e;
}
.button {
  display: flex;
  border-radius: 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 168px;
  height: 44px;
  &--save {
    background: #48a2d4;
    border: 1px solid #48a2d4;
    color: white;
  }
  &--save:is(:hover, :focus) {
    background: #176f9e;
  }
  &--cancel {
    background: #ffffff;
    border: 1px solid #e94b58;
    color: #e94b58;
  }
  &--cancel:is(:hover, :focus) {
    background: #e94b58;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
}
.radio {
  background: rgba(239, 241, 249, 0.6);
  border-radius: 10px;
  width: 120px;
  height: 35px;
  padding-top: 2%;
  padding-left: 10%;
}
.eye-icon {
  position: absolute;
  top: 10px;
  right: 12px;
}
input[type="file"] {
  display: none;
}
.preview :deep(img) {
  width: 280px;
  height: 120px;
  object-fit: cover;
}
.preview-2 :deep(img) {
  width: 125px;
  height: 100px;
  object-fit: cover;
}
.image :deep(img) {
  width: 170px;
  height: 100px;
  object-fit: cover;
}
.foto {
  --bs-gutter-x: 0rem !important;
}
</style>
