<template>
    <div>
      <dashboardItem :items="items" />
    </div>
  </template>
  <script>
  import dashboardItem from "./DashboardItem.vue";
  
  export default {
    components: {
      dashboardItem,
    },
    name: "Dashboard-MPW",
    data() {
      return {
        items:{
          name: "Mitra Pool Warehouse",
          partner_type: "mpw",
          url: "admin-income-mpw-detail",
        }
      };
    },
    created() {},
    methods: {},
  };
  </script>
  <style scoped>
  .select-status {
    border: none;
    border-radius: 8px;
    background: rgba(72, 162, 212, 0.1);
    color: #48a2d4;
  }
  </style>
  