<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center no-flex">
                <h3 class="fw-bold">Master Customer</h3>
            </div>
            <div class="d-flex mt-4">
                <div class="me-3">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Cari nama customer"
                            v-model="req.search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="m-mt-1 ms-3">
                    <button
                        class="btn btn-outline-black btn-filter px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <div class="me-2">Rows per page</div>
                        <div>
                            <select
                                class="form-control form-select w-select"
                                v-model="per_page"
                                @change="getList()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-12">
                        <label class="fw-bold mb-2">Tanggal Bergabung</label>
                    </div>
                    <div class="col-md-8">
                        <date-picker
                            v-model="start_date"
                            range
                            type="date"
                            value-type="format"
                            class="w-100"
                            format="DD MMMM YYYY"
                            :clearable="false"
                            placeholder="Pilih tanggal"
                            @change="getStartDate(start_date)"
                        >
                        </date-picker>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-6">
                                <button
                                    class="btn btn-outline-black w-100"
                                    :disabled="
                                        start_date == '' && end_date == ''
                                    "
                                    @click="clearFilter()"
                                >
                                    Clear
                                </button>
                            </div>
                            <div class="col-md-6">
                                <button
                                    class="btn btn-green w-100"
                                    :disabled="
                                        start_date == '' && end_date == ''
                                    "
                                    @click="filter()"
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div class="table-responsive-custom" v-else>
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">
                                Nama Customer
                            </th>
                            <th class="text-center" scope="col">
                                Nomor Telepon
                            </th>
                            <th class="text-center" scope="col">Email</th>
                            <th class="text-center" scope="col">
                                Tanggal Bergabung
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(data, index) in list.list_data"
                            :key="index"
                        >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td>
                                <div class="d-flex align-items-center">
                                    <a
                                        :href="
                                            `/master/customer/detail/` + data.id
                                        "
                                        class="text-link"
                                    >
                                        {{ data.name }}
                                    </a>
                                    <div
                                        class="dropdown"
                                        v-if="data.deleted_at"
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            :id="`dropdownDeleted` + data.id"
                                            data-bs-toggle="dropdown"
                                        >
                                            <img
                                                src="../assets/info-green.png"
                                                width="16"
                                                class="ms-2"
                                                alt=""
                                                title=""
                                            />
                                        </a>
                                        <div
                                            class="dropdown-menu mt-1"
                                            :aria-labelledby="
                                                `dropdownDeleted` + data.id
                                            "
                                        >
                                            <div>
                                                Telah dihapus pada
                                                {{
                                                    moment(
                                                        data.deleted_at
                                                    ).format("DD MMM YYYY")
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {{ data.phone }}
                            </td>
                            <td>
                                {{ data.email }}
                            </td>
                            <td class="text-center">
                                {{ data.created_at ? data.created_at : "-" }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center mt-5" v-if="list.list_data.length == 0">
                    <img src="../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="mt-4">
                <b-pagination-nav
                    v-if="list.list_data.length > 0"
                    v-model="currentPage"
                    :number-of-pages="list.total_page"
                    base-url="#"
                    first-number
                    align="right"
                    @input="changePage"
                ></b-pagination-nav>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import { Tooltip } from "bootstrap";

export default {
    components: {
        ListLoader,
        DatePicker,
    },
    name: "trawltruckMitra",
    data() {
        return {
            moment: moment,
            isFilter: false,
            is_list_ready: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page: 10,
            currentPage: 0,
            req: {
                search: "",
            },
            start_date: "",
            end_date: "",
        };
    },
    created() {
        this.getList();
    },
    computed: {},
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    methods: {
        getList() {
            this.is_list_ready = true;
            let payload = {
                name: this.req.search,
                limit: this.per_page,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
            };
            if (this.start_date) {
                payload.start_date = moment(this.start_date[0]).format(
                    "YYYY-MM-DD"
                );
                payload.end_date = moment(this.start_date[1]).format(
                    "YYYY-MM-DD"
                );
            }
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/general/master-data/customers`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        changePage() {
            this.getList();
        },
        clearFilter() {
            this.start_date = "";
            this.end_date = "";
            this.getList();
        },
        filter() {
            this.getList();
        },
        getStartDate(value) {
            this.start_date = value;
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.dropdown-menu {
    padding: 10px;
    box-shadow: none !important;
    border: 1px solid #d2d2d5 !important;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-left: 10px !important;
}
.table-responsive-custom table tbody tr td {
    white-space: normal;
}
</style>
