import { render, staticRenderFns } from "./MitraBalanceDetail.vue?vue&type=template&id=25bd3204&scoped=true"
import script from "./MitraBalanceDetail.vue?vue&type=script&lang=js"
export * from "./MitraBalanceDetail.vue?vue&type=script&lang=js"
import style0 from "./MitraBalanceDetail.vue?vue&type=style&index=0&id=25bd3204&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25bd3204",
  null
  
)

export default component.exports