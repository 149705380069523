<template>
    <div class="padding-container">
        <div class="d-flex align-items-center bd-highlight">
            <div class="flex-grow-1 bd-highlight">
                <h3 class="fw-bold">Data Manifest</h3>
            </div>
            <div class="bd-highlight">
                <button
                    @click="modalCreateManifest = true"
                    class="btn btn-green btn-lg px-5"
                >
                    Buat Manifest
                </button>
            </div>
        </div>
        <div class="box mt-4">
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <div class="position-relative">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Cari no manifest"
                                v-model="req.keyword"
                            />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <input
                            type="date"
                            class="form-control"
                            placeholder="Start date"
                            v-model="req.start"
                            @change="getManifestList()"
                        />
                    </div>
                    <div class="col-md-3 mt-3">
                        <input
                            type="date"
                            class="form-control"
                            placeholder="End date"
                            v-model="req.end"
                            @change="getManifestList()"
                        />
                    </div>
                </div>
                <div
                    class="modern-table-boxed mt-4"
                    v-if="searchLists.length > 0"
                >
                    <div class="d-table-row header">
                        <div class="d-table-cell">No</div>
                        <div class="d-table-cell">Nomor Manifest</div>
                        <div class="d-table-cell">Rute</div>
                        <div class="d-table-cell">Total kendaraan</div>
                        <div class="d-table-cell">Layanan</div>
                        <div class="d-table-cell">Tanggal Order</div>
                        <div class="d-table-cell">Status</div>
                        <div class="d-table-cell">Aksi</div>
                    </div>
                    <template v-for="(list, index) in searchLists">
                        <div class="d-table-row" :key="index">
                            <div class="d-table-cell" style="width: 20px">
                                {{ index + 1 }}.
                            </div>
                            <div class="d-table-cell">
                                <a
                                    :href="
                                        `/trawlcarrier/partner/manifest/` +
                                        list.manifest
                                    "
                                    class="text-dark fw-bold"
                                >
                                    {{ list.manifest }}
                                </a>
                            </div>
                            <div class="d-table-cell" style="width: 150px">
                                <a
                                    href="javascript:void(0)"
                                    class="text-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="
                                        list.origin_rute +
                                        ' - ' +
                                        list.destination_rute
                                    "
                                >
                                    <div class="limit-one-line">
                                        <template v-if="list.origin_rute">
                                            {{ list.origin_rute }} -
                                            {{ list.destination_rute }}
                                        </template>
                                        <template v-else> - </template>
                                    </div>
                                </a>
                            </div>
                            <div class="d-table-cell capitalize">
                                {{ list.total }}
                            </div>
                            <div class="d-table-cell capitalize">
                                {{ list.type }} -
                                {{ (list.service || "").split("_").join(" ") }}
                            </div>
                            <div class="d-table-cell">
                                {{
                                    moment(list.created_at).format(
                                        "DD MMM YYYY"
                                    )
                                }}
                            </div>
                            <div class="d-table-cell">
                                <template v-if="list.status != null">
                                    <small
                                        class="tag gray"
                                        v-if="list.status == 'accepted'"
                                    >
                                        Telah Dibuat
                                    </small>
                                    <small
                                        class="tag green"
                                        v-else-if="list.status == 'finish'"
                                    >
                                        Selesai
                                    </small>
                                    <small
                                        class="tag red"
                                        v-else-if="list.status == 'cancel'"
                                    >
                                        Cancel
                                    </small>
                                    <small class="tag gray capitalize" v-else>
                                        {{
                                            (list.status || "")
                                                .split("_")
                                                .join(" ")
                                        }}
                                    </small>
                                </template>
                                <template v-else> - </template>
                            </div>
                            <div class="d-table-cell shrink">
                                <!-- <a :href="`/trawlcarrier/partner/manifest/` + list.manifest" class="btn btn-outline-black btn-sm me-2">
                                    Lihat Detail
                                </a> -->
                                <template v-if="list.service == 'door_to_door'">
                                    <button
                                        class="btn btn-green btn-sm"
                                        disabled
                                    >
                                        Tambah Resi
                                    </button>
                                </template>
                                <template v-else-if="list.status == null">
                                    <button
                                        @click="showModalAddResi(list.manifest)"
                                        class="btn btn-green btn-sm"
                                    >
                                        Tambah Resi
                                    </button>
                                </template>
                                <template v-else-if="list.status == 'accepted'">
                                    <button
                                        @click="showModalAddResi(list.manifest)"
                                        class="btn btn-green btn-sm"
                                    >
                                        Tambah Resi
                                    </button>
                                </template>
                                <template v-else>
                                    <button
                                        class="btn btn-green btn-sm"
                                        disabled
                                    >
                                        Tambah Resi
                                    </button>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="d-flex" v-if="searchLists.length > 0">
            <div class="ms-auto">
                <div class="d-flex mt-4">
                    <div class="me-3">
                        <select
                            class="form-control form-select w-select"
                            v-model="per_page"
                            @change="getManifestList()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <b-pagination-nav
                        :link-gen="linkGen"
                        v-model="currentPage"
                        :number-of-pages="lists.total_page"
                        base-url="#"
                        first-number
                        align="right"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalCreateManifest"></div>
            <div class="modal-body-chat sm" v-if="modalCreateManifest">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-22 fw-ekstra-bold">Buat Manifest</div>
                    </div>
                    <div class="bd-highlight">
                        <a
                            href="javascript:void(0)"
                            @click="modalCreateManifest = false"
                            class="size-20 text-primary"
                        >
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div class="mt-4">
                    <div>
                        <label class="mb-2 fw-bold">Kota Asal</label>
                        <multiselect
                            v-model="create_manifest.origin"
                            :options="originLists"
                            label="label"
                            :loading="is_loading_origin"
                            track-by="regency_id"
                            placeholder="Masukkan kota asal"
                            @search-change="searchOrigins"
                        >
                        </multiselect>
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Kota Tujuan</label>
                        <multiselect
                            v-model="create_manifest.destination"
                            :options="destinationLists"
                            label="label"
                            :loading="is_loading_destination"
                            track-by="regency_id"
                            placeholder="Masukkan kota tujuan"
                            @search-change="searchDestinations"
                        >
                        </multiselect>
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Layanan</label>
                        <select
                            name=""
                            class="form-control form-select"
                            v-model="create_manifest.service"
                        >
                            <option value="">Pilih layanan</option>
                            <option value="pool_to_pool">Pool to Pool</option>
                            <option value="port_to_port">Port to Port</option>
                        </select>
                    </div>
                </div>
                <div class="mt-5">
                    <button
                        class="btn btn-green btn-lg px-5 btn-md me-3"
                        type="button"
                        disabled
                        v-if="is_create_manifest"
                    >
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Tambahkan
                    </button>
                    <button
                        class="btn btn-green btn-lg px-5 btn-md me-3"
                        @click="createManifest()"
                        v-else
                    >
                        Tambahkan
                    </button>
                    <a
                        href="javascript:void(0)"
                        @click="modalCreateManifest = false"
                        class="btn btn-outline-black px-5 btn-lg"
                    >
                        Batal
                    </a>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalAddResi"></div>
            <div class="modal-body-chat sm" v-if="modalAddResi">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-22 fw-ekstra-bold">Tambah Resi</div>
                    </div>
                    <div class="bd-highlight">
                        <a
                            href="javascript:void(0)"
                            @click="modalAddResi = false"
                            class="size-20 text-primary"
                        >
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div>Silahkan pilih resi anda untuk ditambahkan</div>
                <div class="mt-4">
                    <template v-if="receipts.length > 0">
                        <template v-for="(receipt, index) in receipts">
                            <div :key="index">
                                <input
                                    type="checkbox"
                                    :id="receipt.receipt"
                                    name="check-item"
                                    :value="receipt.receipt"
                                    v-model="add_resi"
                                />
                                <label class="manifest" :for="receipt.receipt">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <div class="me-3">
                                                <template
                                                    v-if="
                                                        receipt.avatar == null
                                                    "
                                                >
                                                    <div
                                                        class="user-image-small"
                                                        :style="{
                                                            backgroundImage: `url(${require('../../assets/user.jpg')})`,
                                                        }"
                                                    ></div>
                                                </template>
                                                <template v-else>
                                                    <div
                                                        class="user-image-small"
                                                        :style="{
                                                            'background-image':
                                                                'url(' +
                                                                receipt.avatar +
                                                                ')',
                                                        }"
                                                    ></div>
                                                </template>
                                            </div>
                                        </div>
                                        <div>
                                            <h6
                                                class="mb-0 fw-bold size-16 capitalize"
                                            >
                                                {{ receipt.receipt }}
                                            </h6>
                                            <div
                                                class="mt-1 size-14 capitalize"
                                            >
                                                <span class="text-green">{{
                                                    receipt.variant
                                                }}</span>
                                                - {{ receipt.plate }} -
                                                {{ receipt.tujuan }}
                                            </div>
                                        </div>
                                        <div class="ms-auto">
                                            <div class="check">
                                                <input
                                                    type="checkbox"
                                                    :id="receipt.receipt"
                                                    name="check-item"
                                                    :value="receipt.receipt"
                                                    v-model="add_resi"
                                                />
                                                <label
                                                    class="manifest"
                                                    :for="receipt.receipt"
                                                >
                                                    <i class="fa fa-check"></i>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="box no-shadow no-border bc-gray">
                            <h4 class="mb-0 fw-bold text-gray text-center">
                                Tidak Ada Resi
                            </h4>
                        </div>
                    </template>
                </div>
                <div class="mt-4">
                    <button
                        class="btn btn-green btn-lg px-5 btn-md me-3"
                        type="button"
                        disabled
                        v-if="is_create_resi"
                    >
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Tambahkan
                    </button>
                    <button
                        class="btn btn-green btn-lg px-5 btn-md me-3"
                        @click="createResi()"
                        v-else
                    >
                        Tambahkan
                    </button>
                    <a
                        href="javascript:void(0)"
                        @click="modalAddResi = false"
                        class="btn btn-outline-black px-5 btn-lg"
                    >
                        Batal
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";
import "vue-search-select/dist/VueSearchSelect.css";
import Multiselect from "vue-multiselect";
import { Tooltip } from "bootstrap";

export default {
    components: {
        ListLoader,
        Multiselect,
    },
    name: "Manifest",
    data() {
        return {
            moment: moment,
            modalCreateManifest: false,
            modalAddResi: false,
            lists: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            is_list_ready: false,
            req: {
                keyword: "",
                start: "",
                end: "",
            },
            per_page: 10,
            currentPage: null,
            create_manifest: {
                service: "",
                origin: "",
                destination: "",
            },
            is_create_manifest: false,
            originLists: [],
            searchOrigin: "",
            is_loading_origin: false,
            destinationLists: [],
            searchDestination: "",
            is_loading_destination: false,
            is_create_resi: false,
            data_manifest: "",
            add_resi: [],
            receipts: [],
        };
    },
    created() {
        this.getManifestList();
        this.getReceipts();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    computed: {
        searchLists() {
            return this.lists.list_data.filter((item) => {
                return item.manifest
                    .toLowerCase()
                    .includes(this.req.keyword.toLowerCase());
            });
        },
    },
    methods: {
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        getManifestList() {
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/manifests?start_date=${this.req.start}&end_date=${this.req.end}`,
                {
                    params: {
                        per_page: this.per_page,
                        page: this.$route.query.page,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.lists = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        searchOrigins(searchOrigin) {
            this.searchOrigin = searchOrigin;
            this.is_loading_origin = true;
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/geo/list?type=sub_district&search=${searchOrigin}&page=1&per_page=15`
            )
                .then((res) => {
                    this.originLists = res.data.data;
                    this.is_loading_origin = false;
                })
                .catch((err) => {
                    this.is_loading_origin = false;
                    console.log(err);
                });
        },
        searchDestinations(searchDestination) {
            this.searchDestination = searchDestination;
            this.is_loading_destination = true;
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/geo/list?type=sub_district&search=${searchDestination}&page=1&per_page=15`
            )
                .then((res) => {
                    this.destinationLists = res.data.data;
                    this.is_loading_destination = false;
                })
                .catch((err) => {
                    this.is_loading_destination = false;
                    console.log(err);
                });
        },
        createManifest() {
            var data = {
                service: this.create_manifest.service,
                origin: this.create_manifest.origin.regency_id,
                destination: this.create_manifest.destination.regency_id,
            };
            this.is_create_manifest = true;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/manifest/trip/store`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Berhasil menambahbahkan manifest",
                    });
                    this.is_create_manifest = false;
                    this.modalCreateManifest = false;
                    this.getManifestList();
                    console.log(res);
                })
                .catch((err) => {
                    this.is_create_manifest = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getReceipts() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/list-receipts?manifest=${this.data_manifest}}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.receipts = data.list_data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        showModalAddResi(manifest) {
            this.modalAddResi = true;
            this.data_manifest = manifest;
            this.getReceipts();
        },
        createResi() {
            if (this.add_resi.length == 0) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Pilih resi terlebihdahulu",
                });
            } else {
                var data = {
                    manifest: this.data_manifest,
                    receipt: this.add_resi,
                };
                this.is_create_resi = true;
                Api.post(
                    `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/receipt/manifest/store`,
                    data,
                    {
                        headers: {
                            Authorization:
                                "Bearer" + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        this.$notify({
                            group: "foo",
                            type: "success",
                            title: "Success",
                            text: "Berhasil menambahkan resi",
                        });
                        this.is_create_resi = false;
                        this.modalAddResi = false;
                        this.getManifestList();
                        console.log(res);
                    })
                    .catch((err) => {
                        this.is_create_resi = false;
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    });
            }
        },
    },
};
</script>

<style scoped>
.scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 450px;
}
.user-image-small {
    width: 45px;
    height: 45px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
}
.plan input[type="radio"]:checked + .plan-content.no-bc {
    background: #fff;
}
.check label.manifest {
    width: 27px;
    height: 28px;
    font-size: 14px;
    padding: 3px;
}
label.manifest {
    width: 100%;
    padding: 1rem;
    cursor: pointer;
    background-color: #fff;
    margin-bottom: 10px;
    transition: all 0.2s;
    border: 2px solid #f4f6f7;
    border-radius: 1rem;
}
label.manifest:active {
    border: 2px solid #3d8824;
}
input[type="checkbox"] {
    display: none;
}
input[type="checkbox"]:checked + label {
    border: 2px solid #3d8824;
}
input[type="checkbox"]:checked + label .check label.manifest {
    background: #3d8824;
    border: 1px solid #3d8824;
    color: #fff;
}
</style>
