<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center no-flex">
                <h3 class="fw-bold">Daftar Saldo Mitra</h3>
            </div>
            <div class="d-flex mt-4">
                <div class="me-3">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Cari nama perusahaan/driver"
                            v-model="req.search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="m-mt-1 ms-3">
                    <button
                        class="btn btn-outline-black btn-filter px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <div class="me-2">Rows per page</div>
                        <div>
                            <select
                                class="form-control form-select w-select"
                                v-model="per_page"
                                @change="getList()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <label class="fw-bold mb-1">Jenis Mitra </label>
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <multiselect
                            v-model="mitra"
                            :options="mitras"
                            label="name"
                            track-by="id"
                            placeholder="Pilih jenis mitra"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <button
                                    class="btn btn-outline-black w-100"
                                    :disabled="
                                        mitra == null &&
                                        status_transaction == null &&
                                        start_date == '' &&
                                        end_date == ''
                                    "
                                    @click="clearFilter()"
                                >
                                    Clear
                                </button>
                            </div>
                            <div class="col-md-6">
                                <button
                                    class="btn btn-green w-100"
                                    :disabled="mitra == null"
                                    @click="filter()"
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div class="table-responsive-custom" v-else>
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">
                                Nama Perusahaan/Driver
                            </th>
                            <th class="text-center" scope="col">Jenis Mitra</th>
                            <th class="text-center" scope="col">
                                Nomor Telepon
                            </th>
                            <th class="text-center" scope="col">
                                Saldo Dompet
                            </th>
                            <th class="text-center" scope="col">
                                Saldo Top Up
                            </th>
                            <th class="text-center" scope="col">Total Saldo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(data, index) in list.list_data"
                            :key="index"
                        >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="capitalize">
                                <a
                                    :href="
                                        `/trawltruck/mitra/balance/` +
                                        data.user_id
                                    "
                                    class="text-link"
                                >
                                    {{ data.name ? data.name : "-" }}
                                </a>
                            </td>
                            <td class="text-center">
                                <span
                                    class="tag radius size-12 w-100"
                                    v-bind:class="{
                                        'yellow-cro': data.type == 'driver',
                                        'green-cro': data.type == 'partner',
                                    }"
                                >
                                    <template v-if="data.type == 'driver'"
                                        >Individu</template
                                    >
                                    <template v-if="data.type == 'partner'"
                                        >Corporate</template
                                    >
                                </span>
                            </td>
                            <td class="text-center">
                                {{
                                    data.phone_number ? data.phone_number : "-"
                                }}
                            </td>
                            <td>
                                <div class="float-end">
                                    {{
                                        currency(
                                            data.amount_income
                                                ? data.amount_income
                                                : 0
                                        )
                                    }}
                                </div>
                            </td>
                            <td>
                                <div class="float-end">
                                    {{
                                        currency(
                                            data.amount_wallet
                                                ? data.amount_wallet
                                                : 0
                                        )
                                    }}
                                </div>
                            </td>
                            <td>
                                <div class="float-end">
                                    {{
                                        currency(
                                            data.total_amount
                                                ? data.total_amount
                                                : 0
                                        )
                                    }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center mt-5" v-if="list.list_data.length == 0">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="d-flex" v-if="list.list_data.length > 0">
                <div class="ms-auto">
                    <div class="d-flex mt-4">
                        <div class="me-3">
                            <select
                                class="form-control form-select w-select"
                                v-model="per_page"
                                @change="getList()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <b-pagination-nav
                            v-if="list.list_data.length > 0"
                            v-model="currentPage"
                            :number-of-pages="list.total_page"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        Multiselect,
    },
    name: "mitraBalance",
    data() {
        return {
            moment: moment,
            isFilter: false,
            is_list_ready: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page: 10,
            currentPage: 0,
            req: {
                search: "",
            },
            mitra: null,
            mitras: [
                { name: "Individu", id: "driver" },
                { name: "Corporate", id: "partner" },
            ],
        };
    },
    created() {
        this.getList();
    },
    computed: {},
    mounted() {},
    methods: {
        getList() {
            this.is_list_ready = true;
            let payload = {
                name: this.req.search,
                limit: this.per_page,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
            };
            if (this.mitra) {
                payload.type = this.mitra?.id;
            }
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/truck/finance/admin/wallet`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        clearFilter() {
            this.mitra = null;
            this.getList();
        },
        filter() {
            this.getList();
        },
        changePage() {
            this.getList();
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.table-responsive-custom table tbody tr td {
    white-space: normal;
}
</style>
