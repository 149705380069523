<template>
  <div>{{ countDown }}</div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    date: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      countDown: "",
    };
  },
  mounted() {
    this.getCountDown();
  },
  methods: {
    getCountDown() {
      const exp = moment(this.date);

      setTimeout(() => {
        let now = moment();

        let distance = exp - now;
        let diffDuration = moment.duration(exp.diff(now));
        if (distance > 0) {
          this.countDown = `${diffDuration.days()} : ${diffDuration.hours()} : ${diffDuration.minutes()} : ${diffDuration.seconds()}`;
        }
        if (distance <= 0) {
          this.countDown = `00 : 00 : 00`;
        }

        this.getCountDown();
      }, 1000);
    },
  },
};
</script>
