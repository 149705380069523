<template>
    <div class="padding-container">
        <div class="box mt-3">
            <h4 class="mb-0 fw-bold">Set Harga Layanan</h4>
            <div class="row mt-4">
                <div class="col-md-6">
                    <label class="fw-bold mb-1">
                        Jenis Layanan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="service"
                        :options="services"
                        label="label"
                        track-by="id"
                        placeholder="Pilih Jenis Layanan"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="d-flex mt-3">
                    <div class="ms-auto">
                        <button
                            class="btn btn-outline-yellow px-4 me-2"
                            @click="clearSearch()"
                        >
                            Batal
                        </button>
                        <button
                            class="btn btn-primary px-5"
                            :disabled="!service"
                            @click="showSection()"
                        >
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Section Troben Cargo -->
        <div v-if="showTrobenCargo" class="box mt-3">
            <h4 class="mb-0">Kota Asal</h4>
            <div class="row mt-2">
                <div class="col-md-6 mt-2">
                    <label class="fw-bold mb-1">
                        Kota/Kabupaten Asal
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="regency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten asal"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>

                <hr class="mt-3" />

                <h4 class="mt-2 mb-0">Kota Penerima</h4>
                <div class="row mt-2">
                    <div class="col-md-6 mt-2">
                        <label class="fw-bold mb-1">
                            Kota/Kabupaten
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <multiselect
                            v-model="receiver_regency"
                            :options="receiver_regencies"
                            label="name"
                            track-by="id"
                            placeholder="Pilih kota/kabupaten"
                            class="multi-form-custom"
                            @input="getDistrict"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mt-2">
                        <label class="fw-bold mb-1">
                            Kecamatan
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <multiselect
                            v-model="district"
                            :options="districts"
                            label="name"
                            track-by="id"
                            placeholder="Pilih kecamatan"
                            class="multi-form-custom"
                            @input="getSubDistrict"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mt-2">
                        <label class="fw-bold mb-1">
                            Kelurahan
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <multiselect
                            v-model="sub_district"
                            :options="sub_districts"
                            label="name"
                            track-by="id"
                            placeholder="Pilih Kelurahan"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                </div>

                <hr class="mt-3" />

                <h4 class="mt-2 mb-0">Harga Pengiriman</h4>
                <div class="row mt-2">
                    <div class="col-md-6 mt-2">
                        <label class="fw-bold mb-1">
                            Jenis Pengiriman
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <multiselect
                            v-model="typeof_delivery"
                            :options="typeof_deliveries"
                            label="label"
                            track-by="id"
                            placeholder="Pilih Jenis Layanan"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mt-2">
                        <label class="fw-bold mb-1">
                            Harga Pengiriman
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <div class="input-group mb-0">
                            <span class="input-group-text">Rp</span>
                            <input
                                type="number"
                                class="form-control form-phone"
                                placeholder="Masukkan harga pengiriman"
                            />
                        </div>
                    </div>
                    <div class="d-flex mt-3">
                        <div class="ms-auto">
                            <button
                                class="btn btn-primary px-5"
                                :disabled="search == '' || is_search"
                                @click="searchOrder()"
                            >
                                <span
                                    class="spinner-border spinner-border-sm me-2"
                                    v-if="is_search"
                                ></span>
                                Simpan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Troben Cargo-specific content goes here -->
        </div>

        <!-- Section Troben Truk -->
        <div v-if="showTrobenTruk" class="box mt-3">
            <div class="row mt-2">
                <div class="col-md-6 mt-2">
                    <label class="fw-bold mb-1">
                        Kota Asal
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="regency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten asal"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-2">
                    <label class="fw-bold mb-1">
                        Kota Tujuan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="receiver_regency"
                        :options="receiver_regencies"
                        label="name"
                        track-by="id"
                        placeholder="Pilih kota tujuan"
                        class="multi-form-custom"
                        @input="getDistrict"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
            </div>
            <hr class="mt-3" />
            <div class="row mt-2">
                <div class="col-md-6 mt-2">
                    <label class="fw-bold mb-1">
                        Jenis Armada
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="fleet"
                        :options="fleets"
                        label="label"
                        track-by="id"
                        placeholder="Pilih Jenis Armada"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-2">
                    <label class="fw-bold mb-1">
                        Harga Sewa
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="number"
                            class="form-control form-phone"
                            placeholder="Masukkan harga pengiriman"
                        />
                    </div>
                </div>
                <div class="d-flex mt-3">
                    <div class="ms-auto">
                        <button
                            class="btn btn-primary px-5"
                            :disabled="search == '' || is_search"
                            @click="searchOrder()"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_search"
                            ></span>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>

            <!-- Troben Truk-specific content goes here -->
        </div>

        <!-- Section Troben Carier -->
        <div v-if="showTrobenCarier" class="box mt-3">
            <div class="row mt-2">
                <div class="col-md-6 mt-2">
                    <label class="fw-bold mb-1">
                        Kota Asal
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="regency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten asal"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-2">
                    <label class="fw-bold mb-1">
                        Kota Tujuan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="receiver_regency"
                        :options="receiver_regencies"
                        label="name"
                        track-by="id"
                        placeholder="Pilih kota tujuan"
                        class="multi-form-custom"
                        @input="getDistrict"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
            </div>
            <hr class="mt-3" />
            <div class="row mt-2">
                <div class="col-md-6 mt-2">
                    <label class="fw-bold mb-1">
                        Kategori Mobil
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="carType"
                        :options="carTypes"
                        label="label"
                        track-by="id"
                        placeholder="Pilih Kategori Mobil"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-2">
                    <label class="fw-bold mb-1">
                        Jenis Layanan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="typeof_service"
                        :options="typeof_services"
                        label="label"
                        track-by="id"
                        placeholder="Pilih Jenis  Layanan"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-2">
                    <label class="fw-bold mb-1">
                        Harga Sewa
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="number"
                            class="form-control form-phone"
                            placeholder="Masukkan harga sewa"
                        />
                    </div>
                </div>
                <div class="d-flex mt-3">
                    <div class="ms-auto">
                        <button
                            class="btn btn-primary px-5"
                            :disabled="search == '' || is_search"
                            @click="searchOrder()"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_search"
                            ></span>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
            <!-- Troben Carier-specific content goes here -->
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Api from "../api/Api";

const CarType = Object.freeze({
    STANDARD: "Standard",
    PREMIUM: "Premium",
    SUPER: "Super",
});

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            service: null,
            typeof_delivery: null,
            fleet: null,
            regencies: [],
            receiver_regencies: [],
            receiver_regency: {
                id: "",
                name: "",
            },
            regency: {
                id: "",
                name: "",
            },
            district: {
                id: "",
                name: "",
            },
            sub_district: {
                id: "",
                name: "",
            },
            typeof_deliveries: [
                {
                    id: "pengirimanbarang",
                    label: "Pengiriman Barang",
                },
                {
                    id: "pengirimanmotor",
                    label: "Pengiriman Motor",
                },
            ],
            fleets: [],
            typeof_service: null,
            typeof_services: [
                {
                    id: "door-to-door",
                    label: "Door to door",
                },
                {
                    id: "pool-to-pool",
                    label: "Pool to pool",
                },
                {
                    id: "port-to-port",
                    label: "Port to port",
                },
            ],
            services: [
                {
                    id: "trobencargo",
                    label: "Troben Cargo",
                },
                {
                    id: "trobentruk",
                    label: "Troben Truk",
                },
                {
                    id: "trobencarier",
                    label: "Troben Carier",
                },
            ],
            carType: null,
            carTypes: [
                { id: CarType.STANDARD, label: "Standard" },
                { id: CarType.PREMIUM, label: "Premium" },
                { id: CarType.SUPER, label: "Super" },
            ],
            showTrobenCargo: false,
            showTrobenTruk: false,
            showTrobenCarier: false,
            req: {
                sender_name: "",
                sender_phone: "",
                sender_regency: null,
                sender_address: "",
                receiver_name: "",
                receiver_phone: "",
                receiver_zipcode: "",
                receiver_address: "",
            },
            districts: [],
            sub_districts: [],
        };
    },
    created() {
        this.getRegency();
        this.getDetailDeal();
    },
    watch: {
        "receiver_regency.id": function (newId) {
            if (newId) {
                this.getDistrict();
            }
        },
        "district.id": function (newId) {
            if (newId) {
                this.getSubDistrict();
            }
        },
    },
    methods: {
        clearSearch() {
            this.service = null;
            this.resetSections();
        },
        showSection() {
            this.resetSections();
            if (this.service.id === "trobencargo") {
                this.showTrobenCargo = true;
            } else if (this.service.id === "trobentruk") {
                this.showTrobenTruk = true;
            } else if (this.service.id === "trobencarier") {
                this.showTrobenCarier = true;
            }
        },
        resetSections() {
            this.showTrobenCargo = false;
            this.showTrobenTruk = false;
            this.showTrobenCarier = false;
        },
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.regencies = data;
                    this.receiver_regencies = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getDistrict() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/district?regency_id=${this.receiver_regency?.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.districts = data;
                    // Reset sub-districts if the district changes
                    this.sub_districts = [];
                    this.sub_district = { id: "", name: "" };
                    if (this.district.id) {
                        this.getSubDistrict();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getSubDistrict() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/subdistrict?district_id=${this.district?.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.sub_districts = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getFleet() {
            const baseUrl = process.env.VUE_APP_BASE_URL_APIV2; // Use the correct environment variable for the base URL
            Api.get(`${baseUrl}/master/trawltruck/fleet`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    const data = res.data.data;
                    this.fleets = data;
                })
                .catch((err) => {
                    console.error("Error fetching fleet data:", err);
                });
        },
    },
};
</script>
