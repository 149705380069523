<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center no-flex">
                <h3 class="fw-bold">Pengembalian Dana</h3>
            </div>
            <div class="row mt-5 m-mt-2">
                <div class="col-md-5">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg"
                            placeholder="Cari Order"
                            v-model="req.search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="col-md-3">
                    <select
                        class="form-control form-control-lg form-select"
                        v-model="req.status"
                        @change="changeSearch()"
                    >
                        <option value="">Pilih Status Refund</option>
                        <option value="request">Pengajuan</option>
                        <option value="waiting_for_approval">
                            Menunggu Persetujuan
                        </option>
                        <option value="rejected">Ditolak</option>
                        <option value="approved">Disetujui</option>
                        <option value="transfered">Ditransfer</option>
                    </select>
                </div>
            </div>
            <div class="table-responsive-custom">
                <div class="mt-4" v-if="is_list_ready">
                    <ListLoader />
                </div>
                <div v-else>
                    <template v-if="list.list_data.length > 0">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Nomor Refund
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nomor Resi
                                    </th>
                                    <th class="text-center" scope="col">
                                        Tanggal Pengajuan
                                    </th>
                                    <th class="text-center" scope="col">
                                        Jumlah Pengembalian
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nama Customer
                                    </th>
                                    <th class="text-center" scope="col">
                                        Status Refund
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template
                                    v-for="(data, index) in list.list_data"
                                >
                                    <tr :key="index">
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="text-center">
                                            <a
                                                :href="
                                                    '/carrier/ho/refund/detail/' +
                                                    data.refund_code
                                                "
                                                class="text-dark"
                                            >
                                                <u>
                                                    {{ data.refund_code }}
                                                </u>
                                            </a>
                                        </td>
                                        <td class="text-center">
                                            {{ data.order_id }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                                moment(data.created_at).format(
                                                    "DD MMMM YYYY"
                                                )
                                            }}
                                        </td>
                                        <td class="text-center">
                                            {{ currencyFormat(data.amount) }}
                                        </td>
                                        <td class="text-center">
                                            {{ data.customer_name }}
                                        </td>
                                        <td class="text-center">
                                            <div
                                                class="btn-group display-block"
                                            >
                                                <span
                                                    v-bind:class="{
                                                        'blue-solid-dark':
                                                            data.status ==
                                                            'request',
                                                        'yellow-solid':
                                                            data.status ==
                                                            'waiting_for_approval',
                                                        'red-solid':
                                                            data.status ==
                                                            'rejected',
                                                        'green-solid':
                                                            data.status ==
                                                            'approved',
                                                        'blue-solid':
                                                            data.status ==
                                                            'transfered',
                                                    }"
                                                    class="tag dropdown-toggle w-100 radius btn-sm cursor-pointer capitalize"
                                                    id="statusRefund"
                                                    data-bs-toggle="dropdown"
                                                    data-bs-auto-close="true"
                                                    aria-expanded="false"
                                                >
                                                    <template
                                                        v-if="
                                                            data.status ==
                                                            'request'
                                                        "
                                                    >
                                                        Pengajuan
                                                    </template>
                                                    <template
                                                        v-if="
                                                            data.status ==
                                                            'waiting_for_approval'
                                                        "
                                                    >
                                                        Menunggu Persetujuan
                                                    </template>
                                                    <template
                                                        v-if="
                                                            data.status ==
                                                            'rejected'
                                                        "
                                                    >
                                                        Ditolak
                                                    </template>
                                                    <template
                                                        v-if="
                                                            data.status ==
                                                            'approved'
                                                        "
                                                    >
                                                        Disetujui
                                                    </template>
                                                    <template
                                                        v-if="
                                                            data.status ==
                                                            'transfered'
                                                        "
                                                    >
                                                        Ditransfer
                                                    </template>
                                                    <i
                                                        class="fa fa-angle-down ms-1"
                                                        v-if="
                                                            data.status !=
                                                                'rejected' &&
                                                            data.status !=
                                                                'transfered'
                                                        "
                                                    ></i>
                                                </span>
                                                <ul
                                                    class="dropdown-menu"
                                                    aria-labelledby="statusRefund"
                                                    v-if="
                                                        data.status !=
                                                            'transfered' &&
                                                        data.status !=
                                                            'rejected'
                                                    "
                                                >
                                                    <template
                                                        v-if="
                                                            data.status !=
                                                                'request' &&
                                                            data.status !=
                                                                'waiting_for_approval' &&
                                                            data.status !=
                                                                'approved'
                                                        "
                                                    >
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                href="javascript:void(0)"
                                                                @click="
                                                                    updateStatus(
                                                                        data.refund_code,
                                                                        (status =
                                                                            'request')
                                                                    )
                                                                "
                                                                >Pengajuan</a
                                                            >
                                                        </li>
                                                    </template>
                                                    <template
                                                        v-if="
                                                            data.status !=
                                                                'waiting_for_approval' &&
                                                            data.status !=
                                                                'approved'
                                                        "
                                                    >
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                href="javascript:void(0)"
                                                                @click="
                                                                    updateStatus(
                                                                        data.refund_code,
                                                                        (status =
                                                                            'waiting_for_approval')
                                                                    )
                                                                "
                                                                >Menunggu
                                                                Persetujuan</a
                                                            >
                                                        </li>
                                                    </template>
                                                    <template
                                                        v-if="
                                                            data.status !=
                                                            'rejected'
                                                        "
                                                    >
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                href="javascript:void(0)"
                                                                @click="
                                                                    updateStatus(
                                                                        data.refund_code,
                                                                        (status =
                                                                            'rejected')
                                                                    )
                                                                "
                                                                >Ditolak</a
                                                            >
                                                        </li>
                                                    </template>
                                                    <template
                                                        v-if="
                                                            data.status !=
                                                            'approved'
                                                        "
                                                    >
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                href="javascript:void(0)"
                                                                @click="
                                                                    updateStatus(
                                                                        data.refund_code,
                                                                        (status =
                                                                            'approved')
                                                                    )
                                                                "
                                                                >Disetujui</a
                                                            >
                                                        </li>
                                                    </template>
                                                    <template
                                                        v-if="
                                                            data.status !=
                                                                'transfered' &&
                                                            data.status !=
                                                                'waiting_for_approval'
                                                        "
                                                    >
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                href="javascript:void(0)"
                                                                @click="
                                                                    showModal(
                                                                        data.refund_code,
                                                                        (status =
                                                                            'transfered')
                                                                    )
                                                                "
                                                                >Ditransfer</a
                                                            >
                                                        </li>
                                                    </template>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </template>
                    <template v-else>
                        <div class="text-center mt-section">
                            <img
                                src="../../assets/no-data.png"
                                width="100"
                                alt=""
                            />
                            <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                Data Kosong
                            </h4>
                        </div>
                    </template>
                </div>
            </div>

            <div class="d-flex" v-if="list.list_data.length > 0">
                <div class="ms-auto">
                    <div class="d-flex mt-4">
                        <div class="me-3">
                            <select
                                class="form-control form-select w-select"
                                v-model="per_page"
                                @change="getList()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <b-pagination-nav
                            v-model="currentPage"
                            :number-of-pages="list.total_page"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalTransfer"></div>
            <div class="modal-body-chat vsm" v-if="modalTransfer">
                <div class="text-center mt-4">
                    <img
                        src="../../assets/dashboard/total-pencairan.png"
                        width="50"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Ubah Status Refund</h4>
                    <div class="mt-2">Upload Bukti Transfer</div>
                    <div class="mt-3">
                        <div
                            class="transfer-view"
                            :style="{
                                'background-image': 'url(' + req.transfer + ')',
                            }"
                        >
                            <div class="mt-4 cursor-pointer">
                                <label
                                    for="transfer"
                                    class="upload-button-transfer single-image"
                                >
                                    <i class="fa fa-upload"></i>
                                    <input
                                        type="file"
                                        id="transfer"
                                        @change="uploadTransfer()"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4 row">
                    <div class="col-md-6">
                        <button
                            class="btn btn-outline-primary w-100 btn-lg"
                            @click="modalTransfer = false"
                        >
                            Batal
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button
                            class="btn btn-green w-100 btn-lg"
                            type="button"
                            disabled
                            v-if="is_update"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Save
                        </button>
                        <button
                            class="btn btn-green w-100 btn-lg"
                            :disabled="is_changing_transfer == false"
                            @click="updateStatus()"
                            v-else
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import $ from "jquery";
import moment from "moment";

export default {
    components: {
        ListLoader,
    },
    name: "CarrierRefund",
    data() {
        return {
            moment: moment,
            modalTransfer: false,
            is_changing_transfer: false,
            req: {
                transfer: "",
                search: "",
                status: "",
            },
            is_list_ready: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page: 10,
            currentPage: 0,
            id: "",
            status: "",
            is_update: false,
        };
    },
    created() {
        this.getList();
    },
    methods: {
        showModal(id, status) {
            this.modalTransfer = true;
            this.id = id;
            this.status = status;
        },
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        changePage() {
            this.getList();
        },
        getList() {
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/refund/list`,
                {
                    params: {
                        search: this.req.search,
                        status: this.req.status,
                        limit: this.per_page,
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        updateStatus(id, status) {
            this.is_update = true;
            var data = new FormData();

            if (this.is_changing_transfer) {
                var attachment = $("#transfer")[0].files[0];
                data.append("attachment", attachment);
                data.append("refund_code", this.id);
                data.append("status", this.status);
            } else {
                data.append("refund_code", id);
                data.append("status", status);
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/refund/update`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                        Accept: "application/json",
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Berhasil",
                        text: "Status Berhasil Diubah",
                    });
                    setTimeout(() => {
                        this.getList();
                    }, 1000);
                    this.is_changing_transfer = false;
                    this.modalTransfer = false;
                    this.is_update = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.data.message,
                    });
                    this.is_update = false;
                });
        },
        uploadTransfer() {
            var transfer = new FileReader();
            transfer.onload = function (e) {
                $(".transfer-view").css(
                    "background-image",
                    "url(" + e.target.result + ")"
                );
            };
            transfer.readAsDataURL($("#transfer")[0].files[0]);
            this.is_changing_transfer = true;
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.text-red-dashboard {
    color: #e60013;
}
.search-icon {
    font-size: 16px;
}
.dropdown-toggle::after {
    display: none;
}
.dropdown-menu.show {
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border: 1px solid #f7f7f7;
    padding-bottom: 2px;
}
.transfer-view {
    background: #f8f9fa;
    border: 2px solid #e9ecef;
    box-sizing: border-box;
    border-radius: 20px;
    height: 150px;
    width: 100%;
    background-size: cover;
    background-position: center;
}
.upload-button-transfer {
    border-radius: 50px;
    position: absolute;
    background: #fff;
    cursor: pointer;
    padding: 4px 15px;
    color: #000;
    font-weight: 600;
    left: 50px;
}
.display-block {
    display: block !important;
}
</style>
