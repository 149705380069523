import { render, staticRenderFns } from "./AssignTransporter.vue?vue&type=template&id=f2d242a2&scoped=true"
import script from "./AssignTransporter.vue?vue&type=script&lang=js"
export * from "./AssignTransporter.vue?vue&type=script&lang=js"
import style0 from "./AssignTransporter.vue?vue&type=style&index=0&id=f2d242a2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2d242a2",
  null
  
)

export default component.exports