<template>
    <div class="padding-container">
        <div class="box">
            <div class="mb-3">
                <h3 class="fw-bold">Tracking Order</h3>
            </div>
            <div class="row mt-4">
                <div class="col-md-4">
                    <div class="search-form">
                        <input
                            type="text"
                            class="form-control w-search-leads"
                            @keyup.enter="changeSearch"
                            v-model="search"
                            placeholder="Cari Resi"
                        />
                        <button class="btn btn-primary" @click="changeSearch()">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_load">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">No Resi</th>
                                <th class="text-center" scope="col">
                                    Nama Customer
                                </th>
                                <th class="text-center" scope="col">
                                    Jenis Orderan
                                </th>
                                <th class="text-center" scope="col">Tanggal</th>
                                <th class="text-center" scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in data.list_data"
                                :key="index"
                            >
                                <td class="text-center" style="width: 50px">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-center">
                                    <router-link
                                        class="text-link"
                                        :to="{
                                            name: 'tracking-driver-detail',
                                            params: {
                                                id: item.id,
                                                dataCustomer: item.customer,
                                            },
                                        }"
                                        >{{ item.receipt_code }}</router-link
                                    >
                                </td>
                                <td>
                                    {{ item.customer?.name }}
                                </td>
                                <td class="text-center capitalize">
                                    {{ item.type_order }}
                                </td>
                                <td class="text-center">
                                    {{ dateFormat(item.created_at) }}
                                </td>
                                <td class="text-center">
                                    <small
                                        class="mb-0 tag yellow-solid w-100 radius capitalize"
                                        v-if="item.status == 'waiting'"
                                    >
                                        {{ item.status }}
                                    </small>
                                    <small
                                        class="mb-0 tag green-solid w-100 radius capitalize"
                                        v-else-if="item.status == 'done'"
                                    >
                                        {{ item.status }}
                                    </small>
                                    <small
                                        class="mb-0 tag red-solid w-100 radius capitalize"
                                        v-else-if="item.status == 'unloading'"
                                    >
                                        {{ item.status }}
                                    </small>
                                    <small
                                        class="mb-0 tag blue-solid w-100 radius capitalize"
                                        v-else-if="item.status == 'ontheway'"
                                    >
                                        {{ item.status }}
                                    </small>
                                    <small
                                        class="mb-0 tag gray-solid w-100 radius capitalize"
                                        v-else
                                    >
                                        {{ item.status }}
                                    </small>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-if="data.list_data.length == 0"
                    class="text-center mt-section"
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="d-flex" v-if="data.list_data.length > 0">
            <div class="ms-auto">
                <div class="d-flex mt-4">
                    <div class="me-3">
                        <select
                            class="form-control form-select w-select"
                            v-model="limit"
                            @change="getList()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <b-pagination-nav
                        v-model="currentPage"
                        :number-of-pages="data.total_page"
                        base-url="#"
                        first-number
                        align="right"
                        @input="changeSearch"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";

export default {
    components: {
        ListLoader,
    },
    data() {
        return {
            data: {
                list_data: [],
            },
            currentPage: 0,
            limit: 10,
            search: "",
            is_load: false,
        };
    },
    created() {
        this.getList();
    },
    computed: {},
    mounted() {},
    methods: {
        changeSearch() {
            this.getList();
        },
        getList() {
            this.is_load = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/trawltruck/order/list`,
                {
                    params: {
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 1,
                        limit: this.limit,
                        search: this.search,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data;
                    this.is_load = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.data.message,
                    });
                    this.is_load = false;
                });
        },
        dateFormat(date) {
            return moment(date).format("DD MMMM YYYY");
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
</style>
