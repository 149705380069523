<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_create"></div>
        <div id="loader" v-if="is_create"></div>
        <div class="fw-bold">
            <a href="/trawltruck/ho/order" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <label class="fw-bold mb-2">
                Jadwal Pemesanan Truk
                <span class="text-danger">*</span>
            </label>
            <date-picker
                v-model="req.pickup_schedule"
                type="datetime"
                value-type="format"
                class="w-100"
                format="DD MMMM YYYY hh:mm"
                :clearable="false"
                placeholder="Pilih tanggal dan waktu pemesanan truk"
            >
            </date-picker>
        </div>
        <div class="box mt-4">
            <h5 class="mb-0 fw-bold">
                <i class="fa fa-send me-1"></i>
                Informasi Pengirim
            </h5>
            <div class="row">
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        Nomor Telepon Pengirim
                        <span class="text-danger">*</span>
                    </label>
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Masukkan nomor telepon pengirim"
                            v-model="req.origin_phone"
                            @input="validateNumberOrigin()"
                        />
                        <button
                            class="btn btn-green"
                            @click="searchPhoneNumber()"
                        >
                            <i class="fa fa-search me-1 ms-1"></i>
                        </button>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        Nama Pengirim
                        <span class="text-danger">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="data_phone.name"
                        placeholder="Masukkan nama pengirim"
                        disabled
                        v-if="is_phone"
                    />
                    <input
                        type="text"
                        class="form-control"
                        v-model="req.origin_name"
                        placeholder="Masukkan nama pengirim"
                        v-else
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">
                        Alamat Pengirim
                        <span class="text-danger">*</span>
                    </label>
                    <div>
                        <GmapMap
                            ref="mapRefOrigin"
                            :center="{
                                lat: -6.233035800000001,
                                lng: 106.8397427,
                            }"
                            :zoom="16"
                            map-type-id="terrain"
                            style="height: 300px"
                        >
                            <div class="row justify-content-center pt-4">
                                <div class="col-md-9">
                                    <div class="position-relative">
                                        <GmapAutocomplete
                                            @place_changed="
                                                processLocationChangedOrigin
                                            "
                                            :value="address_origin"
                                            placeholder="Cari Lokasi"
                                            class="form-control search-google"
                                        ></GmapAutocomplete>
                                        <span class="search-icon map">
                                            <i class="fa fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers_origin"
                                ref="marker"
                                :position="m"
                                :clickable="true"
                                :draggable="true"
                                @dragend="movePlaceOrigin"
                                @click="center = m"
                            >
                            </GmapMarker>
                        </GmapMap>
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">
                        Detail Alamat Pengirim
                        <span class="text-danger">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="req.origin_note"
                        placeholder="Masukkan detail alamat pengirim"
                    />
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <h5 class="mb-0 fw-bold">
                <i class="fa fa-user me-1"></i>
                Informasi Penerima
            </h5>
            <div class="row">
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        Nomor Telepon Penerima
                        <span class="text-danger">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="req.dest_phone"
                        placeholder="Masukkan nomor telepon penerima"
                        @input="validateNumberDest()"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        Nama penerima
                        <span class="text-danger">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="req.dest_name"
                        placeholder="Masukkan nama penerima"
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">
                        Alamat Penerima
                        <span class="text-danger">*</span>
                    </label>
                    <div>
                        <GmapMap
                            ref="mapRefDest"
                            :center="{
                                lat: -6.233035800000001,
                                lng: 106.8397427,
                            }"
                            :zoom="16"
                            map-type-id="terrain"
                            style="height: 300px"
                        >
                            <div class="row justify-content-center pt-4">
                                <div class="col-md-9">
                                    <div class="position-relative">
                                        <GmapAutocomplete
                                            @place_changed="
                                                processLocationChangedDest
                                            "
                                            :value="address_dest"
                                            placeholder="Cari Lokasi"
                                            class="form-control search-google"
                                        ></GmapAutocomplete>
                                        <span class="search-icon map">
                                            <i class="fa fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers_dest"
                                ref="marker"
                                :position="m"
                                :clickable="true"
                                :draggable="true"
                                @dragend="movePlaceDest"
                                @click="center = m"
                            >
                            </GmapMarker>
                        </GmapMap>
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">
                        Detail Alamat Penerima
                        <span class="text-danger">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="req.dest_note"
                        placeholder="Masukkan detail alamat penerima"
                    />
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <h5 class="mb-0 fw-bold">
                <i class="fa fa-truck me-1"></i>
                Jenis Armada & Mitra
            </h5>
            <div class="row">
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        Jenis Armada
                        <span class="text-danger">*</span>
                    </label>
                    <multiselect
                        v-model="fleet"
                        :options="fleets"
                        :show-labels="false"
                        label="name"
                        track-by="code"
                        placeholder="Cari dan pilih jenis armada"
                        class="multi-form-custom"
                        @input="checkRoute"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        Pilih Mitra
                        <span class="text-danger">*</span>
                    </label>
                    <multiselect
                        v-model="partner"
                        :options="partners"
                        :show-labels="false"
                        label="name"
                        track-by="id"
                        placeholder="Cari dan pilih mitra"
                        class="multi-form-custom"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        Biaya Pengiriman
                        <span class="text-danger">*</span>
                    </label>
                    <div class="input-group">
                        <span class="input-group-text"> Rp </span>
                        <money
                            v-model="req.service_price"
                            v-bind="money"
                            class="form-control"
                            placeholder="Masukkan biaya pengiriman"
                        >
                        </money>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        Komisi Mitra
                        <span class="text-danger">*</span>
                    </label>
                    <div class="input-group">
                        <span class="input-group-text"> Rp </span>
                        <money
                            v-model="req.commission_partner"
                            v-bind="money"
                            class="form-control"
                            placeholder="Masukkan komisi mitra"
                        >
                        </money>
                    </div>
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <h5 class="mb-0 fw-bold">
                <i class="fa fa-tag me-1"></i>
                Layanan Tambahan
            </h5>
            <div class="row">
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2"> Jumlah Helper </label>
                    <div class="input-group">
                        <button
                            class="btn btn-outline-gray"
                            @click="changeCounter('-1', req)"
                            :disabled="
                                req.helper_count == 0 ||
                                estimated_route?.helper?.max_total == 0
                            "
                        >
                            <img
                                src="../../assets/minus.png"
                                width="12"
                                alt=""
                            />
                        </button>
                        <input
                            type="text"
                            class="form-control text-center"
                            v-model="req.helper_count"
                            :disabled="estimated_route?.helper?.max_total == 0"
                        />
                        <button
                            class="btn btn-outline-gray"
                            @click="changeCounter('1', req)"
                            :disabled="
                                estimated_route?.helper?.max_total == 0 ||
                                req.helper_count >= 2
                            "
                        >
                            <img
                                src="../../assets/plus-black.png"
                                width="12"
                                alt=""
                            />
                        </button>
                    </div>
                    <div class="text-yellow mt-1" v-if="req.helper_count > 0">
                        <i class="fa fa-info-circle"></i>
                        Maksimal 2 helper
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2"> Harga Helper </label>
                    <div class="input-group">
                        <span class="input-group-text"> Rp </span>
                        <money
                            v-model="req.helper_price"
                            v-bind="money"
                            class="form-control"
                            placeholder="Masukkan harga helper"
                            :disabled="estimated_route?.helper?.max_total == 0"
                        >
                        </money>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2"> Harga Barang </label>
                    <div class="input-group">
                        <span class="input-group-text"> Rp </span>
                        <money
                            v-model="req.good_price"
                            v-bind="money"
                            class="form-control"
                            placeholder="Masukkan harga barang"
                        >
                        </money>
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            :disabled="req.good_price < 1000000"
                            v-model="req.is_insurance"
                            id="insurance"
                            :value="true"
                        />
                        <label class="form-check-label" for="insurance">
                            Asuransikan Barang
                        </label>
                    </div>
                    <template v-if="req.good_price != 0">
                        <div
                            class="text-yellow"
                            v-if="req.good_price < 1000000"
                        >
                            <i class="fa fa-info-circle"></i>
                            Barang tidak dapat diasuransikan karena harga barang
                            dibawah Rp 1.000.000
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <h5 class="mb-0 fw-bold">
                <i class="fa fa-camera me-1"></i>
                Informasi Barang
            </h5>
            <div class="row">
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">
                        Deskripsi Barang
                        <span class="text-danger">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="req.note"
                        placeholder="Masukkan deskripsi barang"
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold">Foto Barang</label>
                    <div class="row">
                        <div
                            class="col-md-3 mt-3"
                            v-for="(f, index) in images"
                            :key="index"
                        >
                            <label class="upload__btn no-border">
                                <div
                                    class="close-icon-order"
                                    @click.prevent="removeImage(index, $event)"
                                >
                                    <i class="fa fa-trash"></i>
                                </div>
                                <input
                                    type="file"
                                    :class="`images[] upload__inputfile`"
                                    @change="previewImage(index, $event)"
                                />
                                <div :class="'images[' + index + ']-preview'">
                                    <p class="mb-0" style="color: #adb5bd">
                                        <i class="fa fa-cloud-upload"></i>
                                    </p>
                                </div>
                            </label>
                        </div>
                        <div class="col-md-3 mt-3">
                            <label
                                class="upload__btn"
                                @click.prevent="addNewImage()"
                            >
                                <p class="mb-0" style="color: #adb5bd">
                                    <i class="fa fa-plus"></i>
                                </p>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <h5 class="mb-0 fw-bold">
                <i class="fa fa-credit-card me-1"></i>
                Metode Pembayaran
            </h5>
            <div class="row">
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">
                        Bank Pembayaran
                        <span class="text-danger">*</span>
                    </label>
                    <multiselect
                        v-model="payment_channel"
                        placeholder="Pilih bank pembayaran"
                        label="full_name_label"
                        track-by="id"
                        :options="options"
                    >
                        <template #singleLabel="props">
                            <div class="d-flex align-items-center">
                                <img :src="props.option.icon" width="40" />
                                <div class="ms-2">
                                    {{ props.option.full_name_label }}
                                </div>
                            </div>
                        </template>
                        <template #option="props">
                            <div class="d-flex align-items-center">
                                <img :src="props.option.icon" width="40" />
                                <div class="ms-2">
                                    {{ props.option.full_name_label }}
                                </div>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-7"></div>
            <div class="col-md-5">
                <div class="box mt-4">
                    <h5 class="mb-0 fw-bold">
                        <i class="fa fa-file me-1"></i>
                        Rincian Komisi Mitra
                    </h5>
                    <div class="d-flex align-items-center mt-4">
                        <h5 class="mb-0 fw-medium size-16">Komisi Mitra</h5>
                        <h5 class="mb-0 fw-bold ms-auto size-16">
                            {{ currency(req.commission_partner) }}
                        </h5>
                    </div>
                    <div class="d-flex align-items-center mt-2">
                        <h5 class="mb-0 fw-medium size-16">Helper</h5>
                        <h5 class="mb-0 fw-bold ms-auto size-16">
                            {{ currency(req.helper_price) }}
                        </h5>
                    </div>
                    <hr />
                    <div class="d-flex align-items-center mt-2">
                        <h5 class="mb-0 fw-bold text-green size-16">
                            Total Komisi Mitra
                        </h5>
                        <h5 class="mb-0 fw-bold ms-auto text-green size-16">
                            {{
                                currency(
                                    req.commission_partner + req.helper_price
                                )
                            }}
                        </h5>
                    </div>
                </div>
                <div class="box mt-4">
                    <h5 class="mb-0 fw-bold">
                        <i class="fa fa-file me-1"></i>
                        Rincian Pembayaran Customer
                    </h5>
                    <div class="d-flex align-items-center mt-4">
                        <h5 class="mb-0 fw-medium size-16">Tarif Harga</h5>
                        <h5 class="mb-0 fw-bold ms-auto size-16">
                            {{ currency(req.service_price) }}
                        </h5>
                    </div>
                    <div class="d-flex align-items-center mt-2">
                        <h5 class="mb-0 fw-medium size-16">Biaya Helper</h5>
                        <h5 class="mb-0 fw-bold ms-auto size-16">
                            {{ currency(req.helper_price) }}
                        </h5>
                    </div>
                    <div class="d-flex align-items-center mt-2">
                        <h5 class="mb-0 fw-medium size-16">Asuransi</h5>
                        <h5 class="mb-0 fw-bold ms-auto size-16">
                            {{ currency(calculatedPrice) }}
                        </h5>
                    </div>
                    <div class="d-flex align-items-center mt-2">
                        <h5 class="mb-0 fw-medium size-16">Biaya Layanan</h5>
                        <h5 class="mb-0 fw-bold ms-auto size-16">
                            {{ currency(3000) }}
                        </h5>
                    </div>
                    <hr />
                    <div class="d-flex align-items-center mt-2">
                        <h5 class="mb-0 fw-bold size-16 text-green">
                            Total Pembayaran
                        </h5>
                        <h5 class="mb-0 fw-bold ms-auto size-16 text-green">
                            {{
                                currency(
                                    req.service_price +
                                        req.helper_price +
                                        calculatedPrice +
                                        3000
                                )
                            }}
                        </h5>
                    </div>
                    <div class="mt-3">
                        <button
                            class="btn btn-green w-100"
                            :disabled="
                                is_create ||
                                req.pickup_schedule == '' ||
                                req.origin_phone == '' ||
                                map_data_origin.lat == null ||
                                req.dest_note == '' ||
                                req.dest_phone == '' ||
                                req.dest_name == '' ||
                                req.origin_note == '' ||
                                map_data_dest.lat == null ||
                                fleet == null ||
                                partner == null ||
                                req.service_price == 0 ||
                                req.commission_partner == 0 ||
                                req.note == '' ||
                                payment_channel == null
                            "
                            @click="storeData()"
                        >
                            Buat Project On Call
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Project On Cll Berhasil Dibuat
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/trawltruck/ho/order"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import { gmapApi } from "vue2-google-maps";
import $ from "jquery";
import { Money } from "v-money";

export default {
    name: "ManagementHoOrderCreate",
    components: {
        DatePicker,
        Multiselect,
        Money,
    },
    data() {
        return {
            moment: moment,
            req: {
                pickup_schedule: "",
                origin_phone: "",
                origin_name: "",
                origin_note: "",
                dest_name: "",
                dest_phone: "",
                dest_note: "",
                helper_count: 0,
                service_price: 0,
                helper_price: 0,
                commission_partner: 0,
                good_price: 0,
                is_insurance: false,
                note: "",
            },
            map_data_origin: { lat: null, lng: null },
            markers_origin: [
                {
                    lat: -6.233035800000001,
                    lng: 106.8397427,
                },
            ],
            lat_search_origin: "",
            lng_search_origin: "",
            lat_move_origin: "",
            lng_move_origin: "",
            address_origin: "",
            map_data_dest: { lat: null, lng: null },
            markers_dest: [
                {
                    lat: -6.233035800000001,
                    lng: 106.8397427,
                },
            ],
            lat_search_dest: "",
            lng_search_dest: "",
            lat_move_dest: "",
            lng_move_dest: "",
            address_dest: "",
            fleet: null,
            fleets: [],
            partner: null,
            partners: [],
            images: [],
            maxImages: 4,
            payment_channel: null,
            options: [],
            is_create: false,
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 0,
                masked: false,
            },
            estimated_route: {
                helper: {
                    max_total: 0,
                },
            },
            data_phone: {},
            is_phone: false,
            modalSuccess: false,
        };
    },
    mounted() {},
    created() {
        this.getBank();
        this.getFleets();
    },
    computed: {
        google: gmapApi,
        calculatedPrice() {
            if (!this.req.is_insurance) {
                return 0;
            }
            return (this.req.good_price * 0.2) / 100;
        },
    },
    watch: {
        "req.good_price"(newValue) {
            if (newValue < 1000000) {
                this.req.is_insurance = false;
            }
        },
    },
    methods: {
        validateNumberOrigin() {
            this.req.origin_phone = this.req.origin_phone.replace(
                /[^0-9]/g,
                ""
            );
        },
        validateNumberDest() {
            this.req.dest_phone = this.req.dest_phone.replace(/[^0-9]/g, "");
        },
        processLocationChangedOrigin(ev) {
            this.lat_search_origin = ev.geometry.location.toJSON().lat;
            this.lng_search_origin = ev.geometry.location.toJSON().lng;
            this.map_data_origin = ev.geometry.location.toJSON();

            this.address_origin = ev.formatted_address;

            let that = this;
            this.$refs.mapRefOrigin.$mapPromise.then((map) => {
                map.panTo(that.map_data_origin);
            });
            this.$refs.marker[0].$markerObject.setPosition(
                this.map_data_origin
            );
        },
        movePlaceOrigin(event) {
            this.lat_move_origin = event.latLng.toJSON().lat;
            this.lng_move_origin = event.latLng.toJSON().lng;
            this.map_data_origin = event.latLng.toJSON();
            console.log(event.domEvent);

            this.decodeLatLongOrigin(
                this.lat_move_origin,
                this.lng_move_origin
            );
        },
        decodeLatLongOrigin(lat, lng) {
            const geocoder = new this.google.maps.Geocoder();

            geocoder.geocode(
                { location: { lat: lat, lng: lng } },
                (results, status) => {
                    if (status === "OK") {
                        console.log(results[0]);
                        this.address_origin = results[0].formatted_address;
                    } else {
                        this.address_origin = "";
                        alert("tidak ketemu");
                    }
                }
            );
        },
        processLocationChangedDest(ev) {
            this.lat_search_dest = ev.geometry.location.toJSON().lat;
            this.lng_search_dest = ev.geometry.location.toJSON().lng;
            this.map_data_dest = ev.geometry.location.toJSON();

            this.address_dest = ev.formatted_address;

            let that = this;
            this.$refs.mapRefDest.$mapPromise.then((map) => {
                map.panTo(that.map_data_dest);
            });
            this.$refs.marker[0].$markerObject.setPosition(this.map_data_dest);
        },
        movePlaceDest(event) {
            this.lat_move_dest = event.latLng.toJSON().lat;
            this.lng_move_dest = event.latLng.toJSON().lng;
            this.map_data_dest = event.latLng.toJSON();
            console.log(event.domEvent);

            this.decodeLatLongDest(this.lat_move_dest, this.lng_move_dest);
        },
        decodeLatLongDest(lat, lng) {
            const geocoder = new this.google.maps.Geocoder();

            geocoder.geocode(
                { location: { lat: lat, lng: lng } },
                (results, status) => {
                    if (status === "OK") {
                        console.log(results[0]);
                        this.address_dest = results[0].formatted_address;
                    } else {
                        this.address_dest = "";
                        alert("tidak ketemu");
                    }
                }
            );
        },
        changeCounter(num, data) {
            data.helper_count += +num;
            !isNaN(data.helper_count) && data.helper_count > 0
                ? data.helper_count
                : (data.helper_count = 0);
        },
        addNewImage: function () {
            var n = this.maxImages || -1;
            if (n && this.images.length < n) {
                this.images.push("");
            }
            this.checkImages();
        },
        removeImage: function (index) {
            this.images.splice(index, 1);
            this.checkImages();
        },
        checkImages: function () {
            var n = this.maxImages || -1;
            if (n && this.images.length >= n) {
                $(this.addImage, this.el).prop("disabled", true);
            } else {
                $(this.addImage, this.el).prop("disabled", false);
            }
        },
        previewImage: function (index, e) {
            var r = new FileReader(),
                f = e.target.files[0];

            r.addEventListener(
                "load",
                function () {
                    $('[class~="images[' + index + ']-preview"]', this.el).html(
                        '<div class="image-preview" style="background-image: url(' +
                            r.result +
                            ')"></div>'
                    );
                },
                false
            );

            if (f) {
                r.readAsDataURL(f);
            }
        },
        getBank() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/oncall/payment`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.options = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getFleets() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/trawltruck-fleet`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.fleets = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getPartners() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/oncall/partner?type_fleet=${this.fleet.code}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.partners = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        checkRoute() {
            this.getPartners();
            var data = {
                type_fleet: this.fleet.code,
                type_order: "quick",
                type_ordersub: 1,
                type_service: "special",
                helper_count: 0,
                origin_lat: this.map_data_origin.lat,
                origin_lon: this.map_data_origin.lng,
                dest_lat: this.map_data_dest.lat,
                dest_lon: this.map_data_dest.lng,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/estimate`,
                {
                    params: data,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.estimated_route = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        searchPhoneNumber() {
            if (this.req.origin_phone == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Nomor telepon pengirim wajib diisi",
                });
            } else {
                Api.get(
                    `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/oncall/sender?phone=${this.req.origin_phone}`,
                    {
                        headers: {
                            Authorization:
                                "Bearer" + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        this.data_phone = res.data.data;
                        this.is_phone = true;
                    })
                    .catch((err) => {
                        console.log(err);
                        this.is_phone = false;
                        this.$notify({
                            group: "foo",
                            type: "warn",
                            title: "Nomor Telepon Pengirim Tidak Ditemukan",
                            text: "Mohon isi nama pengirim",
                        });
                    });
            }
        },
        storeData() {
            var data = new FormData();
            data.append(
                "pickup_schedule",
                moment(this.req.pickup_schedule).format("YYYY-MM-DD HH:mm:ss")
            );
            data.append("origin_phone", this.req.origin_phone);
            if (this.is_phone) {
                data.append("origin_name", this.data_phone?.name);
            } else {
                data.append("origin_name", this.req.origin_name);
            }
            data.append("origin_lat", this.map_data_origin.lat);
            data.append("origin_lon", this.map_data_origin.lng);
            data.append("origin_note", this.req.origin_note);
            data.append("dest_name", this.req.dest_name);
            data.append("dest_phone", this.req.dest_phone);
            data.append("dest_lat", this.map_data_dest.lat);
            data.append("dest_lon", this.map_data_dest.lng);
            data.append("dest_note", this.req.dest_note);
            if (this.fleet) {
                data.append("type_fleet", this.fleet?.code);
            }
            if (this.partner) {
                data.append("assign_order", this.partner?.id);
            }
            data.append("service_price", this.req.service_price);
            data.append("commission_partner", this.req.commission_partner);
            data.append("helper_count", this.req.helper_count);
            data.append("helper_price", this.req.helper_price);
            data.append("good_price", this.req.good_price);
            if (this.req.is_insurance == true) {
                data.append("is_insurance", "y");
            } else {
                data.append("is_insurance", "n");
            }
            data.append("note", this.req.note);
            $('[class~="images[]"]', this.el).each(function (i) {
                if (i > this.maxImages - 1) {
                    return;
                }
                data.append("photos[" + i + "]", this.files[0]);
            });
            if (this.payment_channel) {
                data.append("payment_channel", this.payment_channel?.id);
            }

            this.is_create = true;

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/oncall`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_create = false;
                    console.log(res);
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.is_create = false;
                    this.modalSuccess = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
    },
};
</script>

<style scoped>
.input {
    display: flex;
    align-items: center;
    margin-left: auto;
}
button.plus-minus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border: 1px solid #9b59b6;
    border-radius: 50px;
    background-color: #fff;
}
button.plus-minus[disabled] {
    opacity: 0.4;
    pointer-events: none;
}

.number {
    font-size: 16px;
    min-width: 30px;
    text-align: center;
}
.upload__inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #f8f9fa;
    border-style: dashed;
    border-radius: 10px;
    width: 100%;
    height: 130px;
    padding: 40px;
    position: relative;
}
.no-border {
    border: unset;
}
.upload__btn p {
    font-size: 30px;
}
.upload__img-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.upload__img-box {
    width: 200px;
    padding: 0 10px;
    margin-bottom: 12px;
}
.upload__img-close {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: center;
    line-height: 24px;
    z-index: 1;
    cursor: pointer;
}
.upload__img-close:after {
    content: "✖";
    font-size: 14px;
    color: white;
}

.img-bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    padding-bottom: 100%;
}
.image-preview-order {
    width: 100%;
    height: 130px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    position: relative;
}
.close-icon-order {
    position: absolute;
    right: 8px;
    top: 8px;
    color: #e60013;
    font-size: 14px;
    padding: 2px;
    background: #fff;
    border-radius: 50px;
    text-align: center;
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 9;
}
</style>
