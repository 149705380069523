<template>
    <div class="padding-container">
        <div class="d-flex align-items-center bd-highlight">
            <div class="flex-grow-1 bd-highlight">
                <h3 class="fw-bold">
                    List Confirmation
                </h3>
            </div>
        </div>
        <div class="box mt-4">
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader/>
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <div class="position-relative">
                            <input type="text" class="form-control" placeholder="Cari customer" v-model="req.keyword" />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <input type="date" class="form-control" placeholder="Start date" v-model="req.start" @change="getConfirmationList()" />
                    </div>
                    <div class="col-md-3 mt-3">
                        <input type="date" class="form-control" placeholder="End date" v-model="req.end" @change="getConfirmationList()" />
                    </div>
                </div>
                <div class="modern-table-boxed mt-4" v-if="searchLists.length > 0">
                    <div class="d-table-row header">
                        <div class="d-table-cell">No</div>
                        <div class="d-table-cell">Customer</div>
                        <div class="d-table-cell">Jenis Mobil</div>
                        <div class="d-table-cell">Harga Mobil</div>
                        <div class="d-table-cell">Lokasi Jemput</div>
                        <div class="d-table-cell">Tujuan</div>
                        <div class="d-table-cell">Catatan</div>
                        <div class="d-table-cell">Pembuat</div>
                        <div class="d-table-cell">Tanggal</div>
                        <div class="d-table-cell">Aksi</div>
                    </div>
                    <template v-for="(list, index) in searchLists">
                        <div class="d-table-row" :key="index">
                            <div class="d-table-cell fw-bold" style="width: 20px;">
                                {{ index+1 }}.
                            </div>
                            <div class="d-table-cell">
                                <span class="fw-bold capitalize">
                                    {{ list.nama_customer }}
                                </span>
                                <div class="">
                                    <a href="javascript:void(0)" @click="showImage(list)" class="size-12">
                                        <i class="fa fa-image me-1"></i>
                                        Lihat Gambar
                                    </a>
                                </div>
                            </div>
                            <div class="d-table-cell">
                                {{ list.jenis_mobil }}
                            </div>
                            <div class="d-table-cell">
                                {{ currencyFormat(list.harga_mobil) }}
                            </div>
                            <div class="d-table-cell" style="width: 100px;">
                                <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" :title="list.lokasi_penjembutan">
                                    <div class="limit-one-line">
                                        {{ list.lokasi_penjembutan }}
                                    </div>
                                </a>
                            </div>
                            <div class="d-table-cell" style="width: 100px;">
                                <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" :title="list.lokasi_tujuan">
                                    <div class="limit-one-line">
                                        {{ list.lokasi_tujuan }}
                                    </div>
                                </a>
                            </div>
                            <div class="d-table-cell" style="width: 100px;">
                                <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" :title="list.catatan">
                                    <div class="limit-one-line">
                                        {{ list.catatan }}
                                    </div>
                                </a>
                            </div>
                            <div class="d-table-cell">
                                {{ list.pembuat }}
                            </div>
                            <div class="d-table-cell">
                                {{ moment(list.created_at).format('DD MMM YYYY') }}
                            </div>
                            <div class="d-table-cell shrink">
                                <a href="javascript:void(0)" @click="accept(list.id)" class="btn btn-green btn-sm me-2">Setuju</a>
                                <a href="javascript:void(0)" @click="declineModalOpen(list)" class="btn btn-primary btn-sm">Tolak</a>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="">
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <b-pagination-nav v-if="searchLists.length > 0" :link-gen="linkGen" v-model="currentPage" :number-of-pages="list.total_page" base-url="#" first-number align="right"></b-pagination-nav>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="imageModal"></div>
            <div class="modal-body-chat sm" v-if="imageModal">
                <div>
                    <div class="d-flex align-items-center">
                        <div class="size-18 fw-ekstra-bold">
                            List Gambar
                        </div>
                        <div class="ms-auto">
                            <a href="javascript:void(0)" @click="imageModal = false" class="size-20 text-primary">
                                <i class="fa fa-times-circle"></i>
                            </a>
                        </div>
                    </div>
                    <div>
                        List gambar yang telah diinput
                    </div>
                    <div class="row mt-4">
                        <template v-for="(image, index) in showImageData.images">
                            <template v-if="showImageData.images.length > 0">
                                <div class="col-md-6 mt-3" :key="index">
                                    <div class="modal-image-preview" :style="{'background-image': 'url(' + (image.image) + ')'}"></div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="col-md-6 mt-3" :key="index">
                                    <div class="modal-image-preview" :style="{ 'background-image': 'url(' + require('../../assets/no-photo.png') + ')' }"></div>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="declineModal"></div>
            <div class="modal-body-chat sm" v-if="declineModal">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            <img src="../../assets/decline.png" width="65" alt="">
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-ekstra-bold">
                        Tidak Setuju?
                    </div>
                    <div class="mt-2">
                        Anda yakin untuk tidak menyetujui pesanan ini?
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <a href="javascript:void(0)" class="btn btn-outline-black w-100" @click="declineModal = false">
                                Batal
                            </a>
                        </div>
                        <div class="col-md-6 mt-4">
                            <a href="javascript:void(0)" @click="decline()" class="btn btn-primary w-100">
                                Tolak
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from '../../components/ListLoader.vue';
import moment from 'moment'
import { Tooltip } from "bootstrap";

export default {
    components: {
        ListLoader
    },
    name: "orderConfirmation",
    data() {
        return {
            moment: moment,
            declineModal: false,
            imageModal: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0
            },
            is_list_ready: false,
            req: {
                keyword: '',
                status: '',
                start: '',
                end: ''
            },
            per_page: 10,
            currentPage: 0,
            showImageData: null,
            declineData: null
        };
    },
    created() {
        this.getConfirmationList()
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    computed: {
        searchLists() {
            return this.list.list_data.filter((item) => {
                return (
                    item.nama_customer.toLowerCase().includes(this.req.keyword.toLowerCase())
                )
            });
        }
    },
    methods: {
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        getConfirmationList() {
            this.is_list_ready = true
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/partner-verification-order?start_date=${this.req.start}&end_date=${this.req.end}`, {
                params: { per_page: this.per_page, page: this.$route.query.page},
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                var data = res.data.data
                this.list = data
                this.is_list_ready = false
            })
            .catch((err) => {
                console.log(err);
                this.is_list_ready = false
            });
        },
        currencyFormat(num) {
            return (
                "Rp. " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        showImage(list){
            this.imageModal = true
            this.showImageData = list
        },
        declineModalOpen(list){
            this.declineModal = true
            this.declineData = list
        },
        decline(){
            var data = {
                type: 'declined_by_partner'
            }
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/partner-verification-order/${this.declineData.id}`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            })
            .then((res) => {
                this.declineModal = false
                this.getConfirmationList()
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
        },
        accept(id){
            var data = {
                type: 'accepted_by_partner'
            }
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/partner-verification-order/${id}`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            })
            .then((res) => {
                this.getConfirmationList()
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
        }
    },
};
</script>

<style>
.modal-image-preview{
    width: 100%;
    height: 120px;
    border: 2px solid #e9ecef;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
}
</style>