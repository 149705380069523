<template>
    <div class="padding-container">
        <div class="box mt-4">
            <div class="mb-5">
                <h3 class="fw-bold">List Order Troben Carier</h3>
                <div class="row">
                    <div class="col-md-4">
                        <div class="search-form mt-3">
                            <input
                                type="text"
                                class="form-control w-search-leads"
                                @keyup.enter="searchData"
                                v-model="search"
                                placeholder="Search Receipt"
                            />
                            <button
                                class="btn btn-primary"
                                @click="searchData()"
                            >
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <table
                    class="table position-relative"
                    v-if="list.list_data.length > 0"
                >
                    <thead class="thead">
                        <tr class="tr">
                            <td class="th">No</td>
                            <td class="th">Receipt</td>
                            <td class="th">Tujuan</td>
                            <td class="th">Nama Pemesanan</td>
                            <td class="th">Jenis Kendaraan</td>
                            <td class="th">Layanan</td>
                            <td class="th">Harga Layanan</td>
                            <td class="th">Status</td>
                        </tr>
                    </thead>
                    <tr>
                        <td>
                            <div></div>
                        </td>
                    </tr>
                    <template v-for="(list, index, key) in list.list_data">
                        <tr class="tr border-body" :key="list.id">
                            <td class="td">{{ index + 1 }}.</td>
                            <td class="td">
                                <a
                                    :href="`/trawlcarrier/ho/order/assign-mitra/${list.id}`"
                                    class="fw-bold text-dark"
                                >
                                    {{ list.receipt }}
                                </a>
                            </td>
                            <td class="td" style="width: 150px">
                                <a
                                    href="javascript:void(0)"
                                    class="text-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="list.tujuan"
                                >
                                    <div class="limit-one-line">
                                        {{ list.tujuan }}
                                    </div>
                                </a>
                            </td>
                            <td class="td">
                                {{ list.nama_pemesan }}
                            </td>
                            <td class="td">
                                {{ list.jenis_kendaraan }}
                            </td>
                            <td class="td capitalize">
                                {{ (list.layanan || "").split("_").join(" ") }}
                            </td>
                            <td class="td">
                                {{ currencyFormat(list.harga_layanan) }}
                            </td>
                            <td class="td capitalize">
                                {{ (list.status || "").split("_").join(" ") }}
                            </td>
                        </tr>
                        <tr class="border-footer bc-gray pb-5" :key="index">
                            <td class="p-2 pt-1 pb-1" colspan="8">
                                <div class="d-flex align-items-center">
                                    <div class="">
                                        <i class="fa fa-calendar me-2"></i>
                                        {{
                                            moment(list.tanggal_pesan).format(
                                                "DD MMMM YYYY"
                                            )
                                        }}
                                    </div>
                                    <div class="ms-auto">
                                        <template
                                            v-if="
                                                list.status ==
                                                'waiting_received_by_partner'
                                            "
                                        >
                                            <a
                                                :href="`/trawlcarrier/ho/order/assign-mitra/${list.id}`"
                                                class="btn btn-green btn-sm pb-1 pt-1 px-3"
                                            >
                                                Tugaskan Mitra
                                            </a>
                                        </template>
                                        <template v-else>
                                            <button
                                                class="btn btn-green btn-sm pb-1 pt-1 px-3"
                                                disabled
                                            >
                                                Tugaskan Mitra
                                            </button>
                                        </template>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr :key="key">
                            <td colspan="9">
                                <div class=""></div>
                            </td>
                        </tr>
                    </template>
                </table>
                <div class="text-center mt-5" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="d-flex" v-if="list.list_data.length > 0">
            <div class="ms-auto">
                <div class="d-flex mt-4">
                    <div class="me-3">
                        <select
                            class="form-control form-select w-select"
                            v-model="per_page"
                            @change="getList()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <b-pagination-nav
                        :link-gen="linkGen"
                        v-model="currentPage"
                        :number-of-pages="list.total_page"
                        base-url="#"
                        first-number
                        align="right"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import { Tooltip } from "bootstrap";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";

export default {
    components: {
        ListLoader,
    },
    name: "TrawlcareerOrder",
    data() {
        return {
            moment: moment,
            is_list_ready: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page: 10,
            currentPage: 0,
            search: "",
        };
    },
    created() {
        this.getList();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    computed: {},
    methods: {
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        getList() {
            this.is_list_ready = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/carrier/ho/order`, {
                params: {
                    search: this.search,
                    per_page: this.per_page,
                    page: this.$route.query.page,
                },
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        searchData() {
            this.getList();
            this.currentPage = 0;
        },
        currencyFormat(num) {
            return (
                "Rp. " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
    },
};
</script>

<style>
.table > thead {
    border-bottom: 0px solid #fff;
    font-weight: 700;
}
.tr {
    transition: all 0.8s ease-in-out;
    margin-top: 15px;
    transition: all 0.2s ease-in-out;
    height: auto;
}

.table .th,
.table .td {
    padding: 0px 12px;
}

.ttable {
    width: 100%;
    margin-right: 5px;
}

.table {
    border: 0 !important;
}

.td {
    margin-bottom: 10px;
    border-top: none !important;
}

.table .td {
    font-size: 14px;
    background-color: #fff;
}
.table .td:nth-child(odd) {
    background-color: #fff;
}
.border-body {
    border-top: 1.5px solid #f7f7f7;
    border-left: 1.5px solid #f7f7f7;
    border-right: 1.5px solid #f7f7f7;
    border-bottom: 1.5px solid #f7f7f7;
    box-shadow: 0 1px 12px -1px rgb(141 145 162 / 25%);
}
.border-footer {
    border-bottom: 1.5px solid #f7f7f7;
    border-left: 1.5px solid #f7f7f7;
    border-right: 1.5px solid #f7f7f7;
    box-shadow: 0 3px 10px -2px #e9ecef;
}
</style>
