<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="row justify-content-center">
            <div class="col-md-9">
                <div class="box">
                    <div class="box no-shadow border-gray">
                        <div class="d-flex align-items-center">
                            <div>
                                <h5 class="mb-0">Total Pembayaran</h5>
                            </div>
                            <div class="ms-auto">
                                <div class="size-18 text-green fw-bold">
                                    {{
                                        currencyFormat(
                                            data.payment.total_payment
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="d-flex">
                            <div>
                                <h5 class="mb-0">Jatuh Tempo</h5>
                            </div>
                            <div class="ms-auto">
                                <template
                                    v-if="
                                        moment(data.payment.expired_at).format(
                                            'YYYY-MM-DD hh:mm:ss'
                                        ) <
                                        moment().format('YYYY-MM-DD hh:mm:ss')
                                    "
                                >
                                    <div class="size-18 text-primary fw-bold">
                                        {{ expired }}
                                    </div>
                                </template>
                                <template v-else>
                                    <div
                                        id="countdown"
                                        class="size-18 text-primary fw-bold"
                                    ></div>
                                </template>
                                <div class="text-gray">
                                    Jatuh tempo
                                    {{
                                        moment(data.payment.expired_at).format(
                                            "DD MMMM YYYY hh:mm:ss"
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="box me-3 mt-3 p-3 no-shadow border-gray">
                            <img
                                :src="data.payment.bank.picture"
                                width="100%"
                                alt=""
                            />
                        </div>
                        <div class="mt-3 size-20 fw-bold capitalize">
                            {{ data.payment.bank.name }}
                        </div>
                    </div>
                    <div class="text-gray mt-3">No Rekening</div>
                    <div class="row">
                        <div class="col-md-10 mt-2">
                            <input
                                type="text"
                                :value="data.payment.payment_content"
                                class="form-control info-rek"
                                disabled
                                id="myInput"
                            />
                        </div>
                        <div class="col-md-2 mt-2">
                            <button
                                class="btn btn-green w-100 btn-lg"
                                @click="copy"
                            >
                                Copy
                            </button>
                        </div>
                    </div>
                    <div
                        class="box no-shadow bc-light-green mt-3 p-3 border-small"
                    >
                        <i class="fa fa-info-circle me-2"></i>
                        Proses Verifikasi 10 menit setelah pembayaran berhasil
                    </div>
                    <div class="mt-3">
                        Bayar pesanan ke Virtual Account di atas sebelum membuat
                        pesanan kembali dengan Virtual Account agar nomor tetap
                        sama
                    </div>
                    <div class="mt-2">
                        Menerima transfer dari semua bank termasuk bank Syariah
                    </div>
                    <div class="accordion mt-3" id="paymentCollapse">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="oneStep">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="false"
                                    aria-controls="collapseOne"
                                >
                                    Petunjuk Transfer mBanking
                                </button>
                            </h2>
                            <div
                                id="collapseOne"
                                class="accordion-collapse collapse show"
                                aria-labelledby="oneStep"
                                data-bs-parent="#paymentCollapse"
                            >
                                <div class="accordion-body">
                                    <ol
                                        v-if="
                                            data.payment.bank.name == 'mandiri'
                                        "
                                    >
                                        <li class="payment-li">
                                            Login ke aplikasi Livin' by Mandiri
                                        </li>
                                        <li class="payment-li">
                                            Pilih PAYMENT > MAKE NEW PAYMENT >
                                            MULTI PAYMENT
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nomor Virtual Account PT
                                            Trawlbens Teknologi Anak Indonesia
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nominal angka pembayaran
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaksi
                                        </li>
                                    </ol>
                                    <ol v-if="data.payment.bank.name == 'bni'">
                                        <li class="payment-li">
                                            Login ke BNI Mobile Banking App
                                        </li>
                                        <li class="payment-li">
                                            Pilih TRANSFER > VIRTUAL ACCOUNT
                                            BILLING
                                        </li>
                                        <li class="payment-li">
                                            Pilih tab "Input Baru" dan masukkan
                                            nominal angka pembayaran
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaksi
                                        </li>
                                    </ol>
                                    <ol v-if="data.payment.bank.name == 'bca'">
                                        <li class="payment-li">
                                            Masuk/Login ke akun mobile banking
                                            Anda
                                        </li>
                                        <li class="payment-li">
                                            Pilih m Transfer > BCA Virtual
                                            Account
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nomor Virtual Account PT
                                            Trawlbens Teknologi Anak Indonesia
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nominal angka pembayaran
                                        </li>
                                        <li class="payment-li">
                                            Masukkan mPin BCA Anda
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaksi
                                        </li>
                                    </ol>
                                    <ol
                                        v-if="
                                            data.payment.bank.name == 'permata'
                                        "
                                    >
                                        <li class="payment-li">
                                            Login
                                            <strong>Mobile Banking</strong>.
                                        </li>
                                        <li class="payment-li">
                                            Pilih
                                            <strong>Pembayaran Tagihan</strong>.
                                        </li>
                                        <li class="payment-li">
                                            Pilih
                                            <strong>Virtual Account</strong>.
                                        </li>
                                        <li class="payment-li">
                                            Input
                                            <strong
                                                >Nomor Virtual Account</strong
                                            >, misal.
                                            <strong>8625XXXXXXXXXXXX</strong>
                                            sebagai
                                            <strong>No. Virtual Account</strong
                                            >.
                                        </li>
                                        <li class="payment-li">
                                            Input
                                            <strong>Nominal</strong> misal.
                                            <strong>10000</strong>.
                                        </li>
                                        <li class="payment-li">
                                            Klik <strong>Kirim</strong>.
                                        </li>
                                        <li class="payment-li">
                                            Input <strong>Token</strong>.
                                        </li>
                                        <li class="payment-li">
                                            Klik <strong>Kirim</strong>.
                                        </li>
                                        <li class="payment-li">
                                            Ambil
                                            <strong>bukti bayar</strong> akan
                                            ditampilkan.
                                        </li>
                                        <li class="payment-li">Selesai.</li>
                                    </ol>
                                    <ol v-if="data.payment.bank.name == 'bri'">
                                        <li class="payment-li">
                                            Login ke aplikasi BRIMO > Pilih
                                            BRIVA
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nomor Virtual Account PT
                                            Trawlbens Teknologi Anak Indonesia
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nominal angka pembayaran
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaksi
                                        </li>
                                    </ol>
                                    <ol v-if="data.payment.bank.name == 'cimb'">
                                        <li class="payment-li">
                                            Login ke Go Mobile
                                        </li>
                                        <li class="payment-li">
                                            Pilih TRANSFER > TRANSFER TO OTHER
                                            CIMB NIAGA ACCOUNT
                                        </li>
                                        <li class="payment-li">
                                            Pilih sumber dana
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nomor Virtual Account PT
                                            Trawlbens Teknologi Anak Indonesia
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nominal angka pembayaran
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaksi
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="twoStep">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                >
                                    Petunjuk Transfer iBanking
                                </button>
                            </h2>
                            <div
                                id="collapseTwo"
                                class="accordion-collapse collapse"
                                aria-labelledby="twoStep"
                                data-bs-parent="#paymentCollapse"
                            >
                                <div class="accordion-body">
                                    <ol
                                        v-if="
                                            data.payment.bank.name == 'mandiri'
                                        "
                                    >
                                        <li class="payment-li">
                                            Login ke ibank.bankmandiri.co.id
                                        </li>
                                        <li class="payment-li">
                                            Pilih PAYMENT > MULTI PAYMENT
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nomor Virtual Account PT
                                            Trawlbens Teknologi Anak Indonesia
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nominal angka pembayaran
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaksi
                                        </li>
                                    </ol>
                                    <ol v-if="data.payment.bank.name == 'bni'">
                                        <li class="payment-li">
                                            Masuk/Login ke ib.bri.co.id
                                        </li>
                                        <li class="payment-li">
                                            Pilih Pembayaran > BRIVA
                                        </li>
                                        <li class="payment-li">
                                            Pilih sumber dana, masukkan nomor
                                            Virtual Account PT Trawlbens Teknologi
                                            Anak Indonesia dan pilih kirim
                                        </li>
                                        <li class="payment-li">
                                            Masukan nominal angka pembayaran
                                            kemudan klik "Kirim". Jika nomor
                                            Virtual Account sudah benar, maka
                                            informasi dari transaksi akan muncul
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaksi
                                        </li>
                                    </ol>
                                    <ol v-if="data.payment.bank.name == 'bca'">
                                        <li class="payment-li">
                                            Masuk/Login ke akun internet banking
                                            Anda
                                        </li>
                                        <li class="payment-li">
                                            Pilih Fund Transfer
                                        </li>
                                        <li class="payment-li">
                                            Pilih Transfer to (Bank) Virtual
                                            Account
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nomor Virtual Account PT
                                            Trawlbens Teknologi Anak Indonesia
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nominal angka pembayaran
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaksi
                                        </li>
                                    </ol>
                                    <ol
                                        v-if="
                                            data.payment.bank.name == 'permata'
                                        "
                                    >
                                        <li class="payment-li">
                                            Login ke new.permatanet.com
                                        </li>
                                        <li class="payment-li">
                                            Pilih TRANSFER > KE BANK LAIN >
                                            ONLINE
                                        </li>
                                        <li class="payment-li">
                                            Pilih sumber dana
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nomor Virtual Account PT
                                            Trawlbens Teknologi Anak Indonesia
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nominal angka pembayaran
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaksi
                                        </li>
                                    </ol>
                                    <ol v-if="data.payment.bank.name == 'bri'">
                                        <li class="payment-li">
                                            Masuk/Login ke ib.bri.co.id
                                        </li>
                                        <li class="payment-li">
                                            Pilih Pembayaran > BRIVA
                                        </li>
                                        <li class="payment-li">
                                            Pilih sumber dana, masukkan nomor
                                            Virtual Account PT Trawlbens Teknologi
                                            Anak Indonesia dan pilih kirim
                                        </li>
                                        <li class="payment-li">
                                            Masukan nominal angka pembayaran
                                            kemudan klik "Kirim". Jika nomor
                                            Virtual Account sudah benar, maka
                                            informasi dari transaksi akan muncul
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaksi
                                        </li>
                                    </ol>
                                    <ol v-if="data.payment.bank.name == 'cimb'">
                                        <li class="payment-li">
                                            Login ke CIMB Clicks
                                        </li>
                                        <li class="payment-li">
                                            Pilih TRANSFER > TRANSFER FROM
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nomor Virtual Account PT
                                            Trawlbens Teknologi Anak Indonesia
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nominal angka pembayaran
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaks
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="threeStep">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                >
                                    Petunjuk Transfer ATM
                                </button>
                            </h2>
                            <div
                                id="collapseThree"
                                class="accordion-collapse collapse"
                                aria-labelledby="threeStep"
                                data-bs-parent="#paymentCollapse"
                            >
                                <div class="accordion-body">
                                    <ol
                                        v-if="
                                            data.payment.bank.name == 'mandiri'
                                        "
                                    >
                                        <li class="payment-li">
                                            Masukkan Kartu > Pilih Bahasa >
                                            Masukkan PIN
                                        </li>
                                        <li class="payment-li">
                                            Pilih PAYMENT/PURCHASE > MULTI
                                            PAYMENT
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nomor Virtual Account PT
                                            Trawlbens Teknologi Anak Indonesia
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nominal angka pembayaran
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaksi
                                        </li>
                                    </ol>
                                    <ol v-if="data.payment.bank.name == 'bni'">
                                        <li class="payment-li">
                                            Masukkan kartu Anda
                                        </li>
                                        <li class="payment-li">
                                            Pilih bahasa yang Anda inginkan
                                        </li>
                                        <li class="payment-li">
                                            Masukkan PIN Anda
                                        </li>
                                        <li class="payment-li">
                                            Pilih Other Menu >TRANSFER > FROM
                                            SAVING ACCOUNT > VIRTUAL ACCOUNT
                                            BILLING
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nomor Virtual Account PT
                                            Trawlbens Teknologi Anak Indonesia
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nominal angka pembayaran
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaksi
                                        </li>
                                    </ol>
                                    <ol v-if="data.payment.bank.name == 'bca'">
                                        <li class="payment-li">
                                            Masukkan kartu Anda
                                        </li>
                                        <li class="payment-li">
                                            Pilih bahasa yang Anda inginkan
                                        </li>
                                        <li class="payment-li">
                                            Masukkan PIN Anda
                                        </li>
                                        <li class="payment-li">
                                            Pilih Menu
                                            Pembayaran/Payment/Purchase
                                        </li>
                                        <li class="payment-li">
                                            Pilih Menu Multi Payment
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nominal angka pembayaran
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nomor Virtual Account PT
                                            Trawlbens Teknologi Anak Indonesia
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nominal angka pembayaran
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaksi
                                        </li>
                                    </ol>
                                    <ol
                                        v-if="
                                            data.payment.bank.name == 'permata'
                                        "
                                    >
                                        <li class="payment-li">
                                            Masukkan kartu > pilih bahasa >
                                            masukkan PIN
                                        </li>
                                        <li class="payment-li">
                                            Pilih TRANSAKSI LAINNYA > TRANSFER >
                                            KE BANK LAIN
                                        </li>
                                        <li class="payment-li">
                                            Masukkan kode bank Permata (013)
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nomor Virtual Account PT
                                            Trawlbens Teknologi Anak Indonesia
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nominal angka pembayaran
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaksi
                                        </li>
                                    </ol>
                                    <ol v-if="data.payment.bank.name == 'bri'">
                                        <li class="payment-li">
                                            Masukkan kartu Anda
                                        </li>
                                        <li class="payment-li">
                                            Pilih bahasa yang Anda inginkan
                                        </li>
                                        <li class="payment-li">
                                            Masukkan PIN Anda
                                        </li>
                                        <li class="payment-li">
                                            Pilih Other Menu
                                            >Pembayaran/Payment/Purchase > Pilih
                                            Other Payment > Pilih BRIVA
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nomor Virtual Account PT
                                            Trawlbens Teknologi Anak Indonesia
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nominal angka pembayaran
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaksi
                                        </li>
                                    </ol>
                                    <ol v-if="data.payment.bank.name == 'cimb'">
                                        <li class="payment-li">
                                            Masukkan kartu Anda
                                        </li>
                                        <li class="payment-li">
                                            Masukkan PIN Anda
                                        </li>
                                        <li class="payment-li">
                                            Pilih TRANSFER > REKENING CIMB NIAGA
                                            > REKENING CIMB NIAGA LAINNYA
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nomor Virtual Account PT
                                            Trawlbens Teknologi Anak Indonesia
                                        </li>
                                        <li class="payment-li">
                                            Masukkan nominal angka pembayaran
                                        </li>
                                        <li class="payment-li">
                                            Ikuti instruksi pembayaran yang
                                            tertera pada layar untuk
                                            menyelesaikan transaksi
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        href="/trawlpack/general/order"
                        class="btn btn-outline-black btn-lg w-100"
                    >
                        Kembali Kehalaman Utama
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
// import $ from 'jquery'

export default {
    components: {},
    name: "OrderDetail",
    data() {
        return {
            moment: moment,
            today: new Date(new Date()),
            data: {
                payment: {
                    bank: {
                        name: "",
                        picture: "",
                    },
                },
            },
            expired_at: "",
            expired: "",
        };
    },
    created() {
        this.getOrderDetail();
    },
    computed: {},
    mounted() {},
    methods: {
        currencyFormat(num) {
            return (
                "Rp. " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getOrderDetail() {
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/partner/corporate/order-detail?package_id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.data = data;

                    if (
                        moment(data.payment.expired_at).format(
                            "YYYY-MM-DD hh:mm:ss"
                        ) < moment().format("YYYY-MM-DD hh:mm:ss")
                    ) {
                        this.expired = "Expired";
                    } else {
                        const element = document.getElementById("countdown");
                        const exp = moment(data.payment.expired_at);

                        setInterval(function () {
                            let now = moment().format();
                            let diffDuration = moment.duration(exp.diff(now));
                            element.innerHTML =
                                diffDuration.days() +
                                " " +
                                "hari" +
                                " " +
                                diffDuration.hours() +
                                " " +
                                "jam" +
                                " " +
                                diffDuration.minutes() +
                                " " +
                                "menit" +
                                " " +
                                diffDuration.seconds() +
                                " " +
                                "detik";
                        }, 1000);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        copy() {
            var copyText = document.getElementById("myInput");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);

            this.$notify({
                group: "foo",
                type: "success",
                title: "Yeeey",
                text: "No rekening berhasil dicopy",
            });
        },
    },
};
</script>

<style>
.box-small-bank {
    width: 100px;
    border-radius: 10px;
}
.border-small {
    border-radius: 10px;
}
.form-control.info-rek {
    font-size: 20px;
    font-weight: 600;
    color: #3d8824;
}
.form-control.info-rek:disabled {
    background: #fff !important;
}
.accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: #fff !important;
    box-shadow: unset !important;
}
.accordion-item {
    border: unset !important;
}
.accordion-button {
    font-size: 14px !important;
    font-weight: 600 !important;
    background-color: #fff !important;
    margin-top: 10px !important;
    border-radius: 7px !important;
    border: 2px solid #f8f9fa !important;
}
.payment-li {
    line-height: 25px;
}
.accordion-button:focus {
    box-shadow: unset !important;
}
.payment-number {
    width: 25px;
    height: 25px;
    text-align: center;
    border-radius: 50px;
    font-weight: 600;
    padding: 4px;
    background: #e9ecef;
    font-size: 13px;
}
</style>
