<template>
    <div class="padding-container">
        <a
            href="/trawltruck/disbursement"
            class="text-gray-dashboard size-16 fw-bold"
        >
            <i class="fa fa-angle-left me-2"></i>
            Kembali
        </a>
        <div class="row">
            <div class="col-md-6 mt-4">
                <div class="box">
                    <div class="d-flex align-items-center">
                        <h4 class="mb-0 fw-bold">
                            Detail Pengajuan Pencairan Dana
                        </h4>
                        <div
                            class="pb-0 px-3 ms-auto"
                            :class="[
                                detail.status == 'accepted'
                                    ? ' tag blue-cro radius'
                                    : detail.status == 'transferred'
                                    ? ' tag green-cro radius'
                                    : ' tag yellow-cro radius',
                            ]"
                        >
                            <template v-if="detail.status == 'accepted'">
                                Approval
                            </template>
                            <template
                                v-else-if="detail.status == 'transferred'"
                            >
                                Transferred
                            </template>
                            <template v-else> Pending </template>
                        </div>
                    </div>
                    <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                        <tr>
                            <td class="ps-0 w-200">Tanggal Pengajuan</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    detail.requested_at
                                        ? detail.requested_at
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-200">Nomor Pengajuan</td>
                            <td class="w-30">:</td>
                            <td class="capitalize">
                                {{ detail.code ? detail.code : "-" }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-200">Nama Driver</td>
                            <td class="w-30">:</td>
                            <td>{{ detail.name ? detail.name : "-" }}</td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-200">Bank Tujuan</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    detail?.bank_account?.bank_name
                                        ? detail?.bank_account?.bank_name
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-200">Nomor Rekening</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    detail?.bank_account?.account_number
                                        ? detail?.bank_account?.account_number
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-200">Atas Nama</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    detail?.bank_account?.account_name
                                        ? detail?.bank_account?.account_name
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-200">Nominal Pengajuan</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    currency(
                                        detail.amount_requested
                                            ? detail.amount_requested
                                            : 0
                                    )
                                }}
                            </td>
                        </tr>
                    </table>
                    <div class="d-flex mt-4">
                        <div class="ms-auto">
                            <button
                                class="btn btn-green btn-lg px-4"
                                :disabled="detail.status != 'requested'"
                                @click="showModalApprove = true"
                            >
                                Setujui Pencairan Dana
                            </button>
                        </div>
                    </div>
                </div>
                <div class="box mt-4" v-if="detail.status != 'requested'">
                    <h4 class="mb-0 fw-bold">Bukti Transfer Pencairan Dana</h4>
                    <div class="mt-2 mb-2">
                        <label class="fw-bold">Bukti Transfer</label>
                        <div calss="text-gray size-12">
                            <i> Format hanya JPG, JPEG, PNG </i>
                        </div>
                    </div>
                    <label
                        type="file"
                        :class="!imagePhoto ? 'upload__btn' : 'pencil'"
                        class="position-relative"
                    >
                        <img
                            v-if="imagePhoto"
                            class="photo"
                            :src="driverPictureUrl"
                        />
                        <p v-else class="mb-0" style="color: #adb5bd">
                            <i class="fa fa-plus"></i>
                        </p>
                        <p
                            v-if="imagePhoto && photo == ''"
                            class="mb-0"
                            style="color: #adb5bd"
                        >
                            <i class="fa fa-pencil"></i>
                        </p>
                        <input
                            class="cursor-pointer"
                            id="upload"
                            type="file"
                            name="file"
                            ref="file"
                            @change="uploadPhoto"
                        />
                    </label>
                    <div class="d-flex mt-2">
                        <div class="ms-auto">
                            <button
                                @click="modalUpload = true"
                                class="btn btn-green btn-lg px-5"
                                :disabled="photo == ''"
                            >
                                Simpan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-4">
                <div class="box">
                    <h4 class="mb-0 fw-bold">Detail Resi</h4>
                    <div class="d-flex align-items-center">
                        <h5 class="mb-0 fw-bold mt-3">
                            {{ detail.items.length }} Resi
                        </h5>
                        <h5 class="mb-0 fw-bold mt-3 ms-auto">
                            {{ currency(total_price) }}
                        </h5>
                    </div>
                    <div class="accordion mt-4" id="itemsReceipt">
                        <div
                            class="accordion-item"
                            v-for="(item, index) in detail.items"
                            :key="index"
                        >
                            <h2 class="accordion-header" id="productTropack">
                                <button
                                    class="accordion-button size-16 fw-bold"
                                    :class="
                                        `` + (index != 0 ? `collapsed` : ``)
                                    "
                                    type="button"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="`#itemReceipt` + index"
                                    :aria-expanded="
                                        `` + (index == 0 ? `true` : `false`)
                                    "
                                    :aria-controls="`itemReceipt` + index"
                                >
                                    {{ item.receipt_code }}
                                </button>
                            </h2>
                            <div
                                :id="`itemReceipt` + index"
                                :class="
                                    `accordion-collapse collapse ` +
                                    (index == 0 ? `show` : ``)
                                "
                                aria-labelledby="productTropack"
                                data-bs-parent="#itemsReceipt"
                            >
                                <div class="accordion-body">
                                    <div class="d-flex align-items-center">
                                        <div class="mb-0 text-gray-dashboard">
                                            Biaya Pengiriman
                                        </div>
                                        <div
                                            class="mb-0 text-gray-dashboard ms-auto"
                                        >
                                            {{ currency(item.service_price) }}
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mt-2">
                                        <div class="mb-0 text-gray-dashboard">
                                            Biaya Helper
                                        </div>
                                        <div
                                            class="mb-0 text-gray-dashboard ms-auto"
                                        >
                                            {{ currency(item.helper_price) }}
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mt-2">
                                        <div class="mb-0 fw-bold">Jumlah</div>
                                        <div class="mb-0 fw-bold ms-auto">
                                            {{ currency(item.total_price) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="showModalApprove"></div>
            <div class="modal-body-chat sm" v-if="showModalApprove">
                <div class="mt-4">
                    <center>
                        <img
                            src="../../assets/info-new.png"
                            width="80"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4">
                            Konfirmasi Pencairan Dana
                        </h4>
                    </center>
                    <table class="mt-3">
                        <tr>
                            <td class="w-table-modal">Nama Driver</td>
                            <td class="w-30">:</td>
                            <td>{{ detail.name }}</td>
                        </tr>
                        <tr>
                            <td class="w-table-modal">Jumlah Resi</td>
                            <td class="w-30">:</td>
                            <td>{{ detail.items.length }} Resi</td>
                        </tr>
                        <tr>
                            <td class="w-table-modal">Nominal Pengajuan</td>
                            <td class="w-30">:</td>
                            <td>{{ currency(total_price) }}</td>
                        </tr>
                    </table>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6">
                        <a
                            href="javascript:void(0)"
                            @click="showModalApprove = false"
                            class="btn btn-outline-primary w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6">
                        <a
                            href="javascript:void(0)"
                            @click="approveDisburse()"
                            :disabled="is_approve"
                            class="btn btn-green w-100 btn-lg"
                        >
                            <span
                                v-if="is_approve"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Konfirmasi
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Pencairan Dana Telah Disetujui
                    </h4>
                    <div class="text-gray-dashboard">
                        Mohon unggah bukti transfer pada tahap selanjutnya
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        :href="`/trawltruck/disbursement/` + detail.code"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalUpload"></div>
            <div class="modal-body-chat vsm" v-if="modalUpload">
                <div class="mt-4">
                    <center>
                        <img
                            src="../../assets/info-new.png"
                            width="80"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4">
                            Bukti Transfer Akan Diunggah
                        </h4>
                        <div class="text-gray-dashboard">
                            Apakah Anda yakin ingin mengunggah bukti transfer
                            ini?
                        </div>
                    </center>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6">
                        <a
                            href="javascript:void(0)"
                            @click="modalUpload = false"
                            class="btn btn-outline-primary w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6">
                        <a
                            href="javascript:void(0)"
                            :disabled="is_upload"
                            @click="approveUpload()"
                            class="btn btn-green w-100 btn-lg"
                        >
                            <span
                                v-if="is_upload"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Ya
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessUpload"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessUpload">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Bukti Transfer Berhasil Diunggah
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        :href="`/trawltruck/disbursement/` + detail.code"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import noImage from "../../assets/no-photo.png";

export default {
    components: {},
    name: "disburseDetail",
    data() {
        return {
            detail: {
                bank_account: {},
                items: [],
            },
            total_price: 0,
            showModalApprove: false,
            modalSuccess: false,
            is_approve: false,
            imagePhoto: "",
            photo: "",
            is_upload: false,
            modalUpload: false,
            modalSuccessUpload: false,
        };
    },
    created() {
        this.getDetail();
    },
    computed: {
        driverPictureUrl() {
            return this.imagePhoto.length > 0 ? this.imagePhoto : noImage;
        },
    },
    methods: {
        getDetail() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/truck/finance/admin/disburse/detail?receipt=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detail = data;
                    this.imagePhoto = data.proof_attachment;
                    this.total_price = data.items.reduce(
                        (sum, item) => sum + item.total_price,
                        0
                    );
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        approveDisburse() {
            this.is_approve = true;
            var payload = {
                receipt: this.$route.params.id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/truck/finance/admin/disburse/accepted`,
                payload,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_approve = false;
                    console.log(res);
                    this.modalSuccess = true;
                    this.showModalApprove = false;
                })
                .catch((err) => {
                    this.is_approve = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        uploadPhoto(event) {
            var input = event.target;
            this.photo = input.files[0];
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.imagePhoto = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
        approveUpload() {
            this.is_upload = true;
            var data = new FormData();
            data.append("receipt", this.$route.params.id);
            if (this.photo) {
                data.append("attachment", this.photo);
            }
            var url_upload = "";
            if (this.detail.proof_attachment) {
                url_upload = "/truck/finance/admin/disburse/proof/edit";
            } else {
                url_upload = "/truck/finance/admin/disburse/proof";
            }
            Api.post(`${process.env.VUE_APP_SERVICE_URL}${url_upload}`, data, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.is_upload = false;
                    console.log(res);
                    this.modalSuccessUpload = true;
                    this.modalUpload = false;
                })
                .catch((err) => {
                    this.is_upload = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
    },
};
</script>

<style scoped>
.text-gray-dashboard {
    color: #61616a;
}
.w-200 {
    width: 200px;
}
.w-30 {
    width: 30px;
}
.w-table-modal {
    width: 160px;
}
.accordion-button:not(.collapsed) {
    background-color: #bed7b6 !important;
    margin-bottom: 0px !important;
    border: 0px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.accordion-body {
    border: 2px solid #f4f6f7 !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.accordion-button {
    border: 2px solid #f4f6f7 !important;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    border: dashed;
    border-radius: 10px;
    width: 13vw;
    height: 150px;
    padding: 55px;
    position: relative;
}
.photo {
    border-radius: 10px;
    cursor: pointer;
    width: 13vw;
    height: 150px;
    object-fit: cover;
    border: dashed;
    color: #dee2e6;
}
input[type="file"] {
    display: none;
}
.upload__btn p i {
    cursor: pointer;
    font-size: 36px;
}
.pencil p i {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 37%;
    color: #3d8824;
    width: 42px;
    height: 42px;
    line-height: 43px;
    background: #f7f7f7;
    border-radius: 10px;
    position: absolute !important;
    font-size: 24px;
    cursor: pointer;
}
</style>
