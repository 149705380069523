var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"padding-container"},[_vm._m(0),_c('div',{staticClass:"box mt-4"},[_vm._m(1),_c('div',{staticClass:"row mt-5"},[_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"col-md-12 mt-4"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ms-auto"},[_c('a',{staticClass:"btn btn-outline-black btn-lg px-5 me-3",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.modalError = true}}},[_vm._v("Batal")]),_c('a',{staticClass:"btn btn-primary btn-lg px-5",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.modalSuccess = true}}},[_vm._v("Update")])])])])])]),_c('div',{staticClass:"modal-vue"},[(_vm.modalSuccess)?_c('div',{staticClass:"overlay"}):_vm._e(),(_vm.modalSuccess)?_c('div',{staticClass:"modal-body-chat vsm"},[_vm._m(5),_c('div',{staticClass:"mt-4"},[_c('a',{staticClass:"btn btn-green w-100 btn-lg",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.modalSuccess = false}}},[_vm._v(" Ok ")])])]):_vm._e()]),_c('div',{staticClass:"modal-vue"},[(_vm.modalError)?_c('div',{staticClass:"overlay"}):_vm._e(),(_vm.modalError)?_c('div',{staticClass:"modal-body-chat vsm"},[_vm._m(6),_c('div',{staticClass:"mt-4"},[_c('a',{staticClass:"btn btn-primary w-100 btn-lg",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.modalError = false}}},[_vm._v(" Ok ")])])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fw-bold"},[_c('a',{staticClass:"text-gray-dashboard size-16",attrs:{"href":"/trawltruck/ho/master/fleet"}},[_c('i',{staticClass:"fa fa-angle-left me-2"}),_vm._v(" Kembali ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('h3',{staticClass:"fw-bold mb-0"},[_vm._v("Ubah Data Jenis Truk")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Jenis Truk")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan Jenis Truk"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Harga Per Km")]),_c('input',{staticClass:"form-control",attrs:{"type":"number","placeholder":"Masukkan Harga Per Km"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Jumlah Truk")]),_c('input',{staticClass:"form-control",attrs:{"type":"number","placeholder":"Masukkan Jumlah Truk"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 text-center"},[_c('img',{attrs:{"src":require("../../assets/dashboard/checklis.png"),"width":"80","alt":""}}),_c('h4',{staticClass:"mb-0 fw-bold mt-4"},[_vm._v("Data Saved!!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 text-center"},[_c('img',{attrs:{"src":require("../../assets/dashboard/close.png"),"width":"80","alt":""}}),_c('h4',{staticClass:"mb-0 fw-bold mt-4"},[_vm._v("Penambahan Data Gagal!")]),_c('div',{staticClass:"mt-2"},[_vm._v(" Mohon maaf terjadi kesalahan! Silakan ulangi kembali ")])])
}]

export { render, staticRenderFns }