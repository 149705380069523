var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"padding-container"},[_vm._m(0),_c('div',{staticClass:"box mt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1"},[(_vm.detail.avatar)?_c('div',{staticClass:"avatar-image",style:({
                        'background-image': 'url(' + _vm.detail.avatar + ')',
                    })}):_c('div',{staticClass:"avatar-image",style:({
                        backgroundImage: `url(${require('../assets/user-dummy.png')})`,
                    })})]),_c('div',{staticClass:"col-md-11"},[_c('h3',{staticClass:"fw-bold"},[_vm._v("Detail Informasi Customer")]),_c('div',{staticClass:"mt-4 row"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Nama Customer")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.name ? _vm.detail.name : "-")+" ")])]),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Tanggal Bergabung")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.created_at ? _vm.detail.created_at : "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Nomor Telepon")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.phone ? _vm.detail.phone : "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Email")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.email ? _vm.detail.email : "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Media Sosial")]),(_vm.detail.social_type.length > 0)?_c('div',{staticClass:"d-flex"},[(_vm.detail.social_type.includes(`google`))?_c('div',{staticClass:"me-2"},[_c('img',{attrs:{"src":require("../assets/google.png"),"width":"20","alt":""}})]):_vm._e(),(
                                    _vm.detail.social_type.includes(`facebook`)
                                )?_c('div',{staticClass:"me-2"},[_c('img',{attrs:{"src":require("../assets/facebook.png"),"width":"20","alt":""}})]):_vm._e(),(_vm.detail.social_type.includes(`apple`))?_c('div',{staticClass:"me-2"},[_c('img',{attrs:{"src":require("../assets/apple.png"),"width":"20","alt":""}})]):_vm._e()]):_c('div',[_vm._v("-")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fw-bold"},[_c('a',{staticClass:"text-gray-dashboard size-16",attrs:{"href":"/master/customer"}},[_c('i',{staticClass:"fa fa-angle-left me-2"}),_vm._v(" Kembali ")])])
}]

export { render, staticRenderFns }