<template>
    <div class="padding-container">
        <div v-if="!isDetail">
            <div class="box mt-3">
                <div class="d-flex bd-highlight">
                    <div class="flex-grow-1 bd-highlight">
                        <h4 class="text-gray">Jumlah Request Mitra</h4>
                        <h3 class="fw-bold">{{ countData }}</h3>
                    </div>
                    <div class="bd-highlight">
                        <h4 class="text-gray">Total Request</h4>
                        <h3 class="fw-bold">{{ currency(amountData) }}</h3>
                    </div>
                </div>
            </div>

            <div class="box mt-3">
                <div class="d-flex bd-highlight">
                    <h3 class="fw-bold">Data Pencairan Mitra</h3>
                    <div class="ms-auto">
                        <div class="dropdown">
                            <button
                                class="btn btn-green dropdown-toggle px-4"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                :disabled="
                                    !filter.start_date || !filter.end_date
                                "
                                aria-expanded="false"
                            >
                                <i class="fa fa-file-excel-o me-1"></i>
                                Export
                            </button>
                            <ul
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                            >
                                <li>
                                    <button
                                        @click="exportReceipt"
                                        class="dropdown-item"
                                    >
                                        Resi Paid
                                    </button>
                                </li>
                                <li>
                                    <button
                                        @click="exportDisbursement"
                                        class="dropdown-item"
                                    >
                                        Pencairan Mitra
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-4">
                        <div class="position-relative">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Cari kode mitra"
                                v-model="search"
                                @input="searchPartner"
                            />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="fw-bold mb-1">
                                        Pilih Mitra
                                    </label>
                                    <multiselect
                                        v-model="partner"
                                        :options="partners"
                                        label="name"
                                        track-by="id"
                                        multiple
                                        placeholder="Pilih mitra"
                                        class="multi-form-custom"
                                        @input="getWithdrawalData"
                                    >
                                    </multiselect>
                                </div>
                                <div class="col-md-3">
                                    <label class="fw-bold mb-1">
                                        Pilih Status
                                    </label>
                                    <select
                                        name=""
                                        @change="getWithdrawalData"
                                        class="form-control form-select"
                                        v-model="filter_status"
                                    >
                                        <option :value="null">
                                            Filter Status
                                        </option>
                                        <option value="approved">
                                            Approved
                                        </option>
                                        <option value="transferred">
                                            Transferred
                                        </option>
                                        <option value="requested">
                                            Request
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-5">
                                    <label class="fw-bold mb-1">
                                        Pilih Tanggal
                                    </label>
                                    <div
                                        class="d-flex bd-highlight align-items-center"
                                    >
                                        <div
                                            class="me-2 flex-grow-1 bd-highlight"
                                        >
                                            <input
                                                type="date"
                                                class="form-control"
                                                v-model="filter.start_date"
                                                placeholder="Start Date"
                                                valueFormat="YYYY-MM-DD"
                                            />
                                        </div>
                                        <div class="bd-highlight">
                                            <div class="fw-bold size-14 fs-6">
                                                s/d
                                            </div>
                                        </div>
                                        <div
                                            class="ms-2 flex-grow-1 bd-highlight"
                                        >
                                            <input
                                                type="date"
                                                class="form-control"
                                                v-model="filter.end_date"
                                                :disabled="!filter.start_date"
                                                valueFormat="YYYY-MM-DD"
                                                @change="getWithdrawalData"
                                                placeholder="End Date"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4" v-if="is_my_list_ready">
                    <ListLoader />
                </div>
                <div v-else>
                    <div class="modern-table-boxed mt-4">
                        <div class="d-table-row header">
                            <div class="d-table-cell">No</div>
                            <div class="d-table-cell">Kode Mitra</div>
                            <div class="d-table-cell">Jumlah Request</div>
                            <div class="d-table-cell">Jumlah Pencairan</div>
                            <div class="d-table-cell">Jenis Mitra</div>
                            <div class="d-table-cell">Tanggal</div>
                            <div class="d-table-cell">Status</div>
                        </div>
                        <div
                            class="d-table-row"
                            v-for="(item, index) in data"
                            :key="index"
                        >
                            <div class="d-table-cell">{{ index + 1 }}</div>
                            <div class="d-table-cell">
                                <router-link
                                    class="text-link"
                                    :to="{
                                        name: 'partner-disbursement-detail',
                                        params: { partnerid: item.id },
                                    }"
                                    >{{ item.partner_id.code }}</router-link
                                >
                            </div>
                            <div class="d-table-cell">
                                {{ currency(item.first_balance) }}
                            </div>
                            <div class="d-table-cell">
                                {{ currency(item.amount) }}
                            </div>
                            <div class="d-table-cell">
                                <small
                                    class="tag green-cro pb-0 mb-0 px-2 radius"
                                    v-for="(
                                        partner, index
                                    ) in item.partner_type"
                                    :key="index"
                                    >{{ partner }}</small
                                >
                            </div>
                            <div class="d-table-cell">
                                {{ setDate(item.created_at) }}
                            </div>
                            <div
                                class="pb-0"
                                :class="[
                                    item.status == 'approved'
                                        ? ' tag green-cro radius px-3'
                                        : item.status == 'transferred'
                                        ? ' tag blue-cro radius px-3'
                                        : ' tag yellow-cro radius px-3',
                                ]"
                            >
                                {{ capitalizeFirstLetter(item.status) }}
                            </div>
                        </div>
                    </div>
                    <div v-if="data.length == 0" class="text-center mt-section">
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </div>

                <div class="d-flex" v-if="data.length > 0">
                    <div class="ms-auto">
                        <div class="d-flex mt-4">
                            <div class="me-3">
                                <select
                                    class="form-control form-select w-select"
                                    v-model="limit"
                                    @change="getWithdrawalData()"
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div class="overflow-auto">
                                <b-pagination-nav
                                    v-model="currentPage"
                                    :number-of-pages="totalPage"
                                    base-url="#"
                                    first-number
                                    align="right"
                                    @input="changePage"
                                ></b-pagination-nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        Multiselect,
    },
    name: "IncomeBusinessPartner",
    data() {
        return {
            data: [],
            showModal: false,
            items: [
                {
                    no: 1,
                    kode_mitra: "MB-JKT-01",
                    jumlah_request: "Rp. 18.265.355,00",
                    jumlah_pencairan: "Rp. 609.590,00",
                    tanggal: "Mon, 05 Sep 2022",
                    status: "Request",
                },
                {
                    no: 2,
                    kode_mitra: "MB-JKT-01",
                    jumlah_request: "Rp. 18.265.355,00",
                    jumlah_pencairan: "Rp. 609.590,00",
                    tanggal: "Mon, 05 Sep 2022",
                    status: "Request",
                },
                {
                    no: 3,
                    kode_mitra: "MB-JKT-01",
                    jumlah_request: "Rp. 18.265.355,00",
                    jumlah_pencairan: "Rp. 609.590,00",
                    tanggal: "Mon, 05 Sep 2022",
                    status: "Approved",
                },
                {
                    no: 4,
                    kode_mitra: "MB-JKT-01",
                    jumlah_request: "Rp. 18.265.355,00",
                    jumlah_pencairan: "Rp. 609.590,00",
                    tanggal: "Mon, 05 Sep 2022",
                    status: "Approved",
                },
            ],
            itemsDetail: [
                {
                    no_resi: "TRB19122200003",
                    total_payement: "Rp. 99.352.462",
                    total_penerimaan: "Rp. 18.265.355,00",
                },
                {
                    no_resi: "TRB19122200004",
                    total_payement: "Rp. 99.352.462",
                    total_penerimaan: "Rp. 18.265.355,00",
                },
                {
                    no_resi: "TRB19122200005",
                    total_payement: "Rp. 99.352.462",
                    total_penerimaan: "Rp. 18.265.355,00",
                },
                {
                    no_resi: "TRB19122200006",
                    total_payement: "Rp. 99.352.462",
                    total_penerimaan: "Rp. 18.265.355,00",
                },
            ],
            isDetail: false,
            filter_status: null,
            filter: {
                code: null,
                start_date: null,
                end_date: null,
            },
            countData: 0,
            amountData: 0,
            search: "",
            is_my_list_ready: false,
            currentPage: 0,
            totalPage: 0,
            limit: 10,
            partner: null,
            partners: [
                { name: "Pengiriman", id: "service" },
                { name: "Delivery", id: "delivery" },
                { name: "Warehouse", id: "warehouse" },
                { name: "Dooring", id: "dooring" },
            ],
        };
    },
    created() {
        this.getWithdrawalData();
        this.getCount();
        this.getAmount();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    methods: {
        changePage() {
            this.getWithdrawalData();
        },
        exportReceipt() {
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/internal/finance/withdrawal/report`,
                {
                    params: {
                        start: this.filter.start_date,
                        end: this.filter.end_date,
                    },
                    responseType: "blob",
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data_excel_request = res.data;
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        moment().format("DD MMM YYYY") + `.xls`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
        exportDisbursement() {
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/internal/finance/withdrawal/export`,
                {
                    params: {
                        start: this.filter.start_date,
                        end: this.filter.end_date,
                    },
                    responseType: "blob",
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data_excel_request = res.data;
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        moment().format("DD MMM YYYY") + `.xls`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        currency(number) {
            return new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
            }).format(number);
        },
        setDate(date) {
            return moment(date).format("ddd, DD MMM YYYY");
        },
        toggleDetail() {
            this.isDetail = true;
        },
        searchPartner() {
            this.getWithdrawalData();
        },
        getWithdrawalData() {
            this.is_my_list_ready = true;
            let payload = {
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
                limit: this.limit,
                partner_code: this.search,
                status: this.filter_status,
                start_date: this.filter.start_date,
                end_date: this.filter.end_date,
            };
            if (this.partner) {
                const selectPartners = this.partner.map((item) => item.id);
                payload.partner_type = selectPartners;
            }
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/internal/finance/withdrawal/list`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        getCount() {
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/internal/finance/withdrawal/count`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.countData = res.data.data.count;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
        getAmount() {
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/internal/finance/withdrawal/count/amount`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.amountData = res.data.data.amount;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.scroll {
    height: 500px;
    overflow-y: scroll;
}
.row.foto {
    --bs-gutter-x: 0rem !important;
}

.trapdoor {
    -webkit-transform: translateZ(0px);
    -webkit-font-smoothing: antialiased;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -25px;
    width: 200px;
    height: 50px;
    box-shadow: inset -7px 0px 12px -8px rgba(0, 0, 0, 0.3),
        inset 7px 0px 12px -8px rgba(0, 0, 0, 0.3);
    background: rgba(0, 0, 0, 0.3);
    -webkit-transition: background 400ms ease-in-out;
    -moz-transition: background 400ms ease-in-out;
    -ms-transition: background 400ms ease-in-out;
    -o-transition: background 400ms ease-in-out;
    transition: background 400ms ease-in-out;

    &:hover {
        background: #fff;

        .door {
            box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.4);
            -webkit-transform: scale(1.08);
            -moz-transform: scale(1.08);
            -ms-transform: scale(1.08);
            -o-transform: scale(1.08);
            transform: scale(1.08);
        }

        .top {
            top: -50%;
        }

        .bottom {
            top: 100%;
        }
    }

    // .twitter-follow-button {
    //   margin-left: -77px;
    //   position: absolute !important;
    //   margin-top: -14px;
    //   left: 50%;
    //   top: 50%;
    // }
}

.top {
    top: 0%;
    left: 0px;

    &:before {
        top: 5px;
    }
}

.bottom {
    top: 50%;
    left: 0px;

    &:before {
        top: -20px;
    }
}

.door {
    -webkit-transition: top 400ms, box-shadow 200ms, -webkit-transform 300ms;
    -moz-transition: top 400ms, box-shadow 200ms, -moz-transform 300ms;
    -ms-transition: top 400ms, box-shadow 200ms, -ms-transform 300ms;
    -o-transition: top 400ms, box-shadow 200ms, -o-transform 300ms;
    transition: top 400ms, box-shadow 200ms, transform 300ms;
    -webkit-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    background-color: #fff;
    position: absolute;
    overflow: hidden;
    width: 100%;
    z-index: 2;
    height: 50%;

    &:before {
        font-family: "tweet", sans-serif;
        position: absolute;
        margin-left: -20px;
        font-size: 35px;
        display: block;
        color: #4099ff;
        content: "\e000";
        width: 0px;
        height: 20px;
        left: 52%;
    }
}
</style>
