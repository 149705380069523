<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="/trawltruck/ho/partner"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4 pb-0">
            <div class="d-flex align-items-center mb-5">
                <h3 class="fw-bold mb-0">Informasi Data Mitra</h3>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">Kode</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.code }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">Nama Mitra</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.name }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">Nomor Telepon</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.phone }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">Email</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.email }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">Jumlah Truk</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.fleet_count }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">Jenis Truk</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    <template v-for="(data, index) in detail.fleet_overview">
                        <div :key="index">
                            {{ data.label }} - {{ data.count }} Unit
                        </div>
                    </template>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">Jumlah Driver</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.driver_count }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">Penanggung Jawab</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.owner.name }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">Nomor Telepon PJ</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.owner.phone }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">Nama Bank</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.bank.bank }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">
                    Nomor Rekening Penanggung Jawab
                </div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.bank.number }} ({{ detail.bank.account }})
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">Alamat</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.address }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">Status</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    <template v-if="detail.status == 'active'">
                        <small class="tag green mb-0 px-3 capitalize">
                            Aktif
                        </small>
                    </template>
                    <template v-else>
                        <small class="tag red mb-0 px-3 capitalize">
                            Tidak Aktif
                        </small>
                    </template>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">Foto KTP PJ</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    <div class="d-flex align-items-center">
                        <div>
                            <img
                                src="../../assets/dashboard/image.png"
                                width="30"
                                alt=""
                            />
                        </div>
                        <div class="ms-auto">
                            <a
                                :href="detail.logo_picture_url"
                                class="text-green"
                                target="_blank"
                                download
                            >
                                Download Foto KTP PJ
                                <i class="fa fa-download ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">File NIB</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    <div class="d-flex align-items-center">
                        <div>
                            <img
                                src="../../assets/dashboard/pdf.png"
                                width="30"
                                alt=""
                            />
                        </div>
                        <div class="ms-auto">
                            <a
                                :href="detail.identity_picture_url"
                                class="text-green"
                                target="_blank"
                                download
                            >
                                Download File NIB
                                <i class="fa fa-download ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">Berkas Compro</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    <div class="d-flex align-items-center">
                        <div>
                            <img
                                src="../../assets/dashboard/pdf.png"
                                width="30"
                                alt=""
                            />
                        </div>
                        <div class="ms-auto">
                            <a
                                :href="detail.business_picture_url"
                                class="text-green"
                                target="_blank"
                                download
                            >
                                Download Berkas Compro
                                <i class="fa fa-download ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </div>
        <div class="box mt-5">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item w-33" role="presentation">
                    <button
                        class="nav-link dashboard left w-100 active"
                        id="income-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#income"
                        type="button"
                        role="tab"
                        aria-controls="income"
                        aria-selected="true"
                    >
                        Informasi Pendapatan
                    </button>
                </li>
                <li class="nav-item w-33 m-mt-1" role="presentation">
                    <button
                        class="nav-link dashboard center w-100"
                        id="pills-truk-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-truk"
                        type="button"
                        role="tab"
                        aria-controls="pills-truk"
                        aria-selected="false"
                    >
                        Informasi Truk
                    </button>
                </li>
                <li class="nav-item w-33 m-mt-1" role="presentation">
                    <button
                        class="nav-link dashboard right w-100"
                        id="pills-driver-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-driver"
                        type="button"
                        role="tab"
                        aria-controls="pills-driver"
                        aria-selected="false"
                    >
                        Informasi Driver
                    </button>
                </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
                <div
                    class="tab-pane fade show active"
                    id="income"
                    role="tabpanel"
                    aria-labelledby="income-tab"
                >
                    <div class="row mt-5">
                        <div class="col-md-5">
                            <div class="position-relative">
                                <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    placeholder="Cari data..."
                                    v-model="req.search_income"
                                    @input="changeSearchIncome()"
                                />
                                <span class="search-icon">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4" v-if="is_income_ready">
                        <ListLoader />
                    </div>
                    <div v-else>
                        <template v-if="incomes.list_data.length > 0">
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                No
                                            </th>
                                            <th class="text-center" scope="col">
                                                No Resi
                                            </th>
                                            <th class="text-center" scope="col">
                                                Tanggal
                                            </th>
                                            <th class="text-center" scope="col">
                                                Pendapatan
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template
                                            v-for="(
                                                data, index
                                            ) in incomes.list_data"
                                        >
                                            <tr :key="index">
                                                <td class="text-center">
                                                    {{ index + 1 }}
                                                </td>
                                                <td class="text-center">
                                                    {{ data.receipt }}
                                                </td>
                                                <td class="text-center">
                                                    {{
                                                        moment(
                                                            data.date
                                                        ).format("DD MMMM YYYY")
                                                    }}
                                                </td>
                                                <td class="text-center">
                                                    {{
                                                        currencyFormat(
                                                            data.income
                                                        )
                                                    }}
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-center mt-section">
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </template>
                    </div>
                    <div class="mt-4">
                        <b-pagination-nav
                            v-if="incomes.list_data.length > 0"
                            v-model="currentPageIncome"
                            :number-of-pages="incomes.total_page"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePageIncome"
                        ></b-pagination-nav>
                    </div>
                </div>
                <div
                    class="tab-pane fade"
                    id="pills-truk"
                    role="tabpanel"
                    aria-labelledby="pills-truk-tab"
                >
                    <div class="row mt-5">
                        <div class="col-md-5">
                            <div class="position-relative">
                                <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    placeholder="Cari data..."
                                    v-model="req.search_truck"
                                    @input="changeSearchTruck()"
                                />
                                <span class="search-icon">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4" v-if="is_truck_ready">
                        <ListLoader />
                    </div>
                    <div v-else>
                        <template v-if="trucks.list_data.length > 0">
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                No
                                            </th>
                                            <th class="text-center" scope="col">
                                                Jenis Kendaraan
                                            </th>
                                            <th class="text-center" scope="col">
                                                Tahun Kendaraan
                                            </th>
                                            <th class="text-center" scope="col">
                                                No Polisi
                                            </th>
                                            <th class="text-center" scope="col">
                                                Foto Kendaraan
                                            </th>
                                            <th class="text-center" scope="col">
                                                Foto STNK
                                            </th>
                                            <th class="text-center" scope="col">
                                                Status
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template
                                            v-for="(
                                                data, index
                                            ) in trucks.list_data"
                                        >
                                            <tr :key="index">
                                                <td class="text-center">
                                                    {{ index + 1 }}
                                                </td>
                                                <td
                                                    class="text-center capitalize"
                                                >
                                                    {{
                                                        data.fleet_type
                                                            .split("-")
                                                            .join(" ")
                                                    }}
                                                </td>
                                                <td class="text-center">
                                                    {{ data.fleet_year }}
                                                </td>
                                                <td class="text-center">
                                                    {{ data.fleet_number }}
                                                </td>
                                                <td class="text-center">
                                                    <center>
                                                        <viewer
                                                            :images="[]"
                                                            :options="options"
                                                        >
                                                            <img
                                                                class="image-product"
                                                                :key="
                                                                    data.fleet_picture_url
                                                                "
                                                                :src="
                                                                    data.fleet_picture_url
                                                                "
                                                            />
                                                        </viewer>
                                                    </center>
                                                </td>
                                                <td class="text-center">
                                                    <center>
                                                        <viewer
                                                            :images="[]"
                                                            :options="options"
                                                        >
                                                            <img
                                                                class="image-product"
                                                                :key="
                                                                    data.registration_picture_url
                                                                "
                                                                :src="
                                                                    data.registration_picture_url
                                                                "
                                                            />
                                                        </viewer>
                                                    </center>
                                                </td>
                                                <td class="text-center">
                                                    <template
                                                        v-if="
                                                            data.status ==
                                                            'accept'
                                                        "
                                                    >
                                                        <small
                                                            class="tag green mb-0 px-3 capitalize"
                                                        >
                                                            Aktif
                                                        </small>
                                                    </template>
                                                    <template v-else>
                                                        <small
                                                            class="tag red mb-0 px-3 capitalize"
                                                        >
                                                            Tidak Aktif
                                                        </small>
                                                    </template>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-center mt-section">
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </template>
                    </div>
                    <div class="mt-4">
                        <b-pagination-nav
                            v-if="trucks.list_data.length > 0"
                            v-model="currentPageTruck"
                            :number-of-pages="trucks.total_page"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePageTruck"
                        ></b-pagination-nav>
                    </div>
                </div>
                <div
                    class="tab-pane fade"
                    id="pills-driver"
                    role="tabpanel"
                    aria-labelledby="pills-driver-tab"
                >
                    <div class="row mt-5">
                        <div class="col-md-5">
                            <div class="position-relative">
                                <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    placeholder="Cari data..."
                                    v-model="req.search_driver"
                                    @input="changeSearchDriver()"
                                />
                                <span class="search-icon">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4" v-if="is_driver_ready">
                        <ListLoader />
                    </div>
                    <div v-else>
                        <template v-if="drivers.list_data.length > 0">
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                No
                                            </th>
                                            <th class="text-center" scope="col">
                                                Nama Driver
                                            </th>
                                            <th class="text-center" scope="col">
                                                Nomor Telp
                                            </th>
                                            <th class="text-center" scope="col">
                                                Usia
                                            </th>
                                            <th class="text-center" scope="col">
                                                Alamat
                                            </th>
                                            <th class="text-center" scope="col">
                                                Status
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template
                                            v-for="(
                                                data, index
                                            ) in drivers.list_data"
                                        >
                                            <tr :key="index">
                                                <td class="text-center">
                                                    {{ index + 1 }}
                                                </td>
                                                <td class="text-center">
                                                    {{ data.driver_name }}
                                                </td>
                                                <td class="text-center">
                                                    {{ data.driver_phone }}
                                                </td>
                                                <td class="text-center">
                                                    {{ data.age }}
                                                </td>
                                                <td style="width: 300px">
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="text-dark"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        :title="
                                                            data.domicile_address
                                                        "
                                                    >
                                                        <div
                                                            class="limit-one-line"
                                                        >
                                                            {{
                                                                data.domicile_address
                                                            }}
                                                        </div>
                                                    </a>
                                                </td>
                                                <td class="text-center">
                                                    <template
                                                        v-if="
                                                            data.status ==
                                                            'accept'
                                                        "
                                                    >
                                                        <small
                                                            class="tag green mb-0 px-3 capitalize"
                                                        >
                                                            Aktif
                                                        </small>
                                                    </template>
                                                    <template v-else>
                                                        <small
                                                            class="tag red mb-0 px-3 capitalize"
                                                        >
                                                            Tidak Aktif
                                                        </small>
                                                    </template>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-center mt-section">
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </template>
                    </div>
                    <div class="mt-4">
                        <b-pagination-nav
                            v-if="drivers.list_data.length > 0"
                            v-model="currentPageDriver"
                            :number-of-pages="drivers.total_page"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePageDriver"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import { Tooltip } from "bootstrap";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";

export default {
    components: {
        ListLoader,
        Viewer,
    },
    name: "ManagementMitraDetail",
    data() {
        return {
            moment: moment,
            is_list_ready: false,
            is_income_ready: false,
            is_truck_ready: false,
            is_driver_ready: false,
            detail: {
                bank: {},
            },
            per_page_income: 10,
            currentPageIncome: 0,
            incomes: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            trucks: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page_truck: 10,
            currentPageTruck: 0,
            drivers: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page_driver: 10,
            currentPageDriver: 0,
            req: {
                search_income: "",
                search_truck: "",
                search_driver: "",
            },
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
        };
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    created() {
        this.getDetail();
        this.getListIncomes();
        this.getListTrucks();
        this.getListDrivers();
    },
    computed: {},
    methods: {
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getDetail() {
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partner/detail?id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detail = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        changePageIncome() {
            this.getListIncomes();
        },
        changeSearchIncome() {
            this.currentPageIncome = 0;
            this.getListIncomes();
        },
        getListIncomes() {
            this.is_income_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partner/income?id=${this.$route.params.id}`,
                {
                    params: {
                        search: this.req.search_income,
                        limit: this.per_page_income,
                        page:
                            this.currentPageIncome == 0 ||
                            this.currentPageIncome == null
                                ? 0
                                : this.currentPageIncome - 1,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.incomes = data;
                    this.is_income_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_income_ready = false;
                });
        },
        changePageTruck() {
            this.getListTrucks();
        },
        changeSearchTruck() {
            this.currentPageTruck = 0;
            this.getListTrucks();
        },
        getListTrucks() {
            this.is_truck_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partner/fleet?id=${this.$route.params.id}`,
                {
                    params: {
                        search: this.req.search_truck,
                        limit: this.per_page_truck,
                        page:
                            this.currentPageTruck == 0 ||
                            this.currentPageTruck == null
                                ? 0
                                : this.currentPageTruck - 1,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.trucks = data;
                    this.is_truck_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_truck_ready = false;
                });
        },
        changePageDriver() {
            this.getListDrivers();
        },
        changeSearchDriver() {
            this.currentPageDriver = 0;
            this.getListDrivers();
        },
        getListDrivers() {
            this.is_driver_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partner/driver?id=${this.$route.params.id}`,
                {
                    params: {
                        search: this.req.search_driver,
                        limit: this.per_page_driver,
                        page:
                            this.currentPageDriver == 0 ||
                            this.currentPageDriver == null
                                ? 0
                                : this.currentPageDriver - 1,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.drivers = data;
                    this.is_driver_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_driver_ready = false;
                });
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
.foto-box {
    width: 100px;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    position: relative;
}
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}
.nav-link.dashboard.center {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.nav-link.dashboard.active {
    background-color: #e60013 !important;
}
.w-33 {
    width: 33.33%;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.image-product {
    border-radius: 10px;
    border: 2px solid #e2e3e5;
    width: 80px;
    height: 80px;
    object-fit: cover;
    cursor: pointer;
}
</style>
