<template>
    <div class="padding-container">
        <div class="box">
            <div class="d-flex bd-highlight">
                <div class="flex-grow-1 bd-highlight">
                    <h4 class="fw-bold">Gudang Mitra Business</h4>
                </div>
                <div class="row">
                    <!-- <div class="col-md-6">
            <select v-model="regency" style="width: 140px" name="" class="form-control form-select">
              <option value="">Pilih Kabupaten</option>
              <option value="">Kab. Banyumas</option>
              <option value="">Kab. Purwokerto</option>
              <option value="">Kab. Tebet</option>
            </select>
          </div> -->
                    <div class="col-md-12">
                        <select
                            v-model="order"
                            @change="getListData"
                            style="width: 160px"
                            name=""
                            class="form-control form-select"
                        >
                            <option value="">Filter Data Gudang</option>
                            <option value="asc">Terkecil ke Terbesar</option>
                            <option value="desc">Terbesar ke Terkecil</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_my_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <div class="modern-table-boxed mt-4">
                    <div class="d-table-row header">
                        <div class="d-table-cell">No</div>
                        <div class="d-table-cell">Kode Mitra</div>
                        <div class="d-table-cell">Wilayah</div>
                        <div class="d-table-cell">Total Koli</div>
                        <div class="d-table-cell">Total Berat Barang</div>
                    </div>
                    <div
                        class="d-table-row"
                        v-for="(item, name, index) in data"
                        :key="index"
                    >
                        <div class="d-table-cell">{{ item.no }}</div>
                        <div class="d-table-cell">
                            <router-link
                                :to="{
                                    name: 'warehouse-mb-detail',
                                    params: { id: item.partner_code },
                                }"
                                class="text-link"
                                >{{ item.partner_code }}</router-link
                            >
                        </div>
                        <div class="d-table-cell">
                            <a
                                href="javascript:void(0)"
                                class="text-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                :title="item.partner_address"
                            >
                                {{ item.partner_regency }}
                                <i class="fa fa-info-circle text-blue ms-1"></i>
                            </a>
                        </div>
                        <div class="d-table-cell">
                            {{ item.qty_count }} Koli
                        </div>
                        <div class="d-table-cell">
                            {{ item.weight_amount }} Kg
                        </div>
                    </div>
                </div>
                <div v-if="data.length == 0" class="text-center mt-section">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="d-flex" v-if="data.length > 0">
            <div class="ms-auto">
                <div class="d-flex mt-4">
                    <div class="me-3">
                        <select
                            class="form-control form-select w-select"
                            v-model="limit"
                            @change="getListData()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <b-pagination-nav
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="changePage"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";

export default {
    components: {
        ListLoader,
    },
    name: "WarehouseMB",
    data() {
        return {
            showModal: false,
            itemDetail: [
                {
                    no: 1,
                    no_resi: "TRB65784394",
                    jumlah_koli: "500 koli",
                    total_berat: "100kg",
                    status: "Ukur Timbang",
                },
                {
                    no: 2,
                    no_resi: "TRB65784395",
                    jumlah_koli: "500 koli",
                    total_berat: "100kg",
                    status: "Ukur Timbang",
                },
                {
                    no: 3,
                    no_resi: "TRB65784396",
                    jumlah_koli: "500 koli",
                    total_berat: "100kg",
                    status: "Ukur Timbang",
                },
                {
                    no: 4,
                    no_resi: "TRB65784397",
                    jumlah_koli: "500 koli",
                    total_berat: "100kg",
                    status: "Ukur Timbang",
                },
            ],
            items: [
                {
                    no: 1,
                    kode_mitra: "MB-JKT-03",
                    wilayah: "Jln. Rasamala",
                    kota: "Jakarta",
                    total_koli: "100 kilo",
                    total_berat: "500 kg",
                },
                {
                    no: 2,
                    kode_mitra: "MB-JKT-03",
                    wilayah: "Jln. Rasamala",
                    kota: "Jakarta",
                    total_koli: "100 kilo",
                    total_berat: "500 kg",
                },
                {
                    no: 3,
                    kode_mitra: "MB-JKT-03",
                    wilayah: "Jln. Rasamala",
                    kota: "Jakarta",
                    total_koli: "100 kilo",
                    total_berat: "500 kg",
                },
                {
                    no: 4,
                    kode_mitra: "MB-JKT-03",
                    wilayah: "Jln. Rasamala",
                    kota: "Jakarta",
                    total_koli: "100 kilo",
                    total_berat: "500 kg",
                },
            ],
            isDetail: false,
            currentPage: 0,
            totalPage: 0,
            limit: 10,
            data: [],
            is_my_list_ready: false,
            order: "",
            regency: "",
        };
    },
    created() {
        this.getListData();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    methods: {
        searchPartner() {
            this.currentPage = 0;
            this.getListData();
        },
        changePage() {
            this.getListData();
        },
        getListData() {
            this.is_my_list_ready = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/admin/fno/warehouse`, {
                params: {
                    page:
                        this.currentPage == 0 || this.currentPage == null
                            ? 0
                            : this.currentPage - 1,
                    limit: this.limit,
                    partner_type: "business",
                    order: this.order,
                    regency: this.regency,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.data = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        currency(number) {
            return new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
            }).format(number);
        },
    },
};
</script>

<style scoped>
.scroll {
    height: 500px;
    overflow-y: scroll;
}
.row.foto {
    --bs-gutter-x: 0rem !important;
}
</style>
