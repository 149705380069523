import { render, staticRenderFns } from "./crmPartnershipProspect.vue?vue&type=template&id=4a939d0c&scoped=true"
import script from "./crmPartnershipProspect.vue?vue&type=script&lang=js"
export * from "./crmPartnershipProspect.vue?vue&type=script&lang=js"
import style0 from "./crmPartnershipProspect.vue?vue&type=style&index=0&id=4a939d0c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a939d0c",
  null
  
)

export default component.exports