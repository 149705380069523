<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/trawltruck/ho/master/fleet" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4 pb-0">
            <div class="d-flex align-items-center mb-5">
                <h3 class="fw-bold mb-0">
                    Detail Truk
                </h3>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Jenis Truk
                </div>
                <div class="col-md-1">
                    :
                </div>
                <div class="col-md-9">
                    Blind Van
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Harga
                </div>
                <div class="col-md-1">
                    :
                </div>
                <div class="col-md-9">
                    Rp400.000/Km
                </div>
            </div>
            <hr>
        </div>
        <div class="box mt-5">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">
                    Informasi Truk
                </h3>
            </div>
            <div class="row mt-5">
                <div class="col-md-5">
                    <div class="position-relative">
                        <input type="text" class="form-control form-control-lg" placeholder="Cari data..." />
                        <span class="search-icon">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <table class="table table-bordered mt-4">
                <thead class="table-light">
                    <tr>
                        <th class="text-center" scope="col">No</th>
                        <th class="text-center" scope="col">Mitra</th>
                        <th class="text-center" scope="col">Jumlah Truk</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center">
                            1
                        </td>
                        <td class="text-center">
                            PT. Angkasapura
                        </td>
                        <td class="text-center">
                            10
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        props: "",
        name: "ManagementTruckDetail",
        data() {
            return {
            };
        },
        mounted() {
        },
        methods: {
        }
    };
</script>

<style scoped>
    .text-main-color{
        color: #24516A !important;
    }
    .text-gray-dashboard{
        color: #61616A;
    }
    .foto-box{
        width: 100px;
        height: 50px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 5px;
        position: relative;
    }.nav-link.dashboard.center{
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    .nav-link.dashboard.left{
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    .nav-link.dashboard.active{
        background-color: #E60013 !important;
    }
    .w-33{
        width: 33.33%;
    }
    th{
        border-color: #E8E8E9;
        padding: 10px !important;
    }
    tr{
        border-color: #E8E8E9;
        padding: 10px !important;
    }
    td{
        padding: 10px !important;
    }
    .table-light{
        background-color: #F6FAFF !important;
        --bs-table-border-color: #F6FAFF !important;
        --bs-table-bg: #F6FAFF !important;
    }
</style>