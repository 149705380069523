<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="/trawlcarrier/partner/order"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Detail Order</h3>
            </div>
            <div class="box no-shadow border-gray mt-3">
                <div class="row">
                    <div class="col-md-2 fw-bold">Nama Pengirim</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{ detailOrder?.origin?.name }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Kontak Pengirim</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{ detailOrder?.origin?.phone }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Lokasi Pengirim</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{ detailOrder?.origin?.address }}
                    </div>
                </div>
            </div>
            <div class="box no-shadow border-gray mt-3">
                <div class="row">
                    <div class="col-md-2 fw-bold">Nama Penerima</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{ detailOrder?.destination?.name }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Kontak Penerima</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{ detailOrder?.destination?.phone }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Lokasi Penerima</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{ detailOrder?.destination?.address }}
                    </div>
                </div>
            </div>
            <div class="box no-shadow border-gray mt-3">
                <div class="row">
                    <div class="col-md-2 fw-bold">Tanggal Order</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{
                            moment(detailOrder.created_at).format(
                                "DD MMMM YYYY"
                            )
                        }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Tanggal Pickup</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{
                            moment(detailOrder.pickup_at).format("DD MMMM YYYY")
                        }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Kategori Kendaraan</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9 capitalize">
                        {{ detailOrder?.meta?.class }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Brand Kendaraan</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{ detailOrder?.meta?.brand }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Tipe Kendaraan</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{ detailOrder?.meta?.variant }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Nomor Polisi</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{ detailOrder?.meta?.plate_number }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">
                        Perkiraan Harga Kendaraan
                    </div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{ currencyFormat(detailOrder?.meta?.price) }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Foto Kendaraan</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        <div class="row">
                            <template
                                v-if="
                                    detailOrder.meta &&
                                    detailOrder?.meta?.images.length > 0
                                "
                            >
                                <template
                                    v-for="(image, index) in detailOrder.meta
                                        .images"
                                >
                                    <div class="col-md-2" :key="index">
                                        <viewer
                                            :images="detailOrder?.meta?.images"
                                            :options="options"
                                        >
                                            <img
                                                class="image-product"
                                                :key="image.image"
                                                :src="image.image"
                                            />
                                        </viewer>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div class="col-md-2">
                                    <div
                                        class="foto-box"
                                        :style="{
                                            'background-image':
                                                'url(' +
                                                require('../../assets/no-photo.png') +
                                                ')',
                                        }"
                                    ></div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Foto STNK</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-2">
                                <template
                                    v-if="
                                        (detailOrder.meta &&
                                            detailOrder?.meta?.stnk != null) ||
                                        detailOrder?.meta?.stnk != ''
                                    "
                                >
                                    <viewer :images="[]" :options="options">
                                        <img
                                            class="image-product"
                                            :key="detailOrder?.meta?.stnk"
                                            :src="detailOrder?.meta?.stnk"
                                        />
                                    </viewer>
                                </template>
                                <template v-else>
                                    <div
                                        class="foto-box"
                                        :style="{
                                            'background-image':
                                                'url(' +
                                                require('../../assets/no-photo.png') +
                                                ')',
                                        }"
                                    ></div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Keterangan Tambahan</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{ detailOrder?.meta?.note }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Jenis Layanan</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9 capitalize">
                        {{
                            (detailOrder.service_type || "")
                                .split("_")
                                .join(" ")
                        }}
                    </div>
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Rincian Harga</h3>
            </div>
            <div class="box no-shadow border-gray mt-3">
                <div class="row">
                    <div class="col-md-2 fw-bold">Harga Layanan</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{ currencyFormat(detailOrder.delivery_price) }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Asuransi</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{ currencyFormat(detailOrder.insurance_price) }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Diskon</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{ currencyFormat(detailOrder.discount_price) }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Denda</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9 text-danger">
                        {{ currencyFormat(detailOrder.penalty_price) }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Biaya Jasa Aplikai</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{ currencyFormat(detailOrder.platform_fee) }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Total Biaya</div>
                    <div class="col-md-1">:</div>
                    <div class="col-md-9">
                        {{ currencyFormat(detailOrder.total_price) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";

export default {
    components: {
        Viewer,
    },
    name: "OrderDetail",
    data() {
        return {
            moment: moment,
            detailOrder: {
                destination: {},
                origin: {},
                meta: {
                    images: [],
                    stnk: "",
                },
            },
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
        };
    },
    mounted() {},
    created() {
        this.getOrderDetail();
    },
    computed: {},
    methods: {
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getOrderDetail() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/order/${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detailOrder = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
.foto-box {
    width: 100%;
    height: 70px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    position: relative;
}
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
.nav-link.dashboard.center {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.active {
    background-color: #e60013 !important;
}
.w-33 {
    width: 33.33%;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.image-product {
    border-radius: 10px;
    border: 2px solid #e2e3e5;
    width: 100%;
    height: 120px;
    object-fit: cover;
    cursor: pointer;
}
</style>
