<template>
    <div class="padding-container">
        <div class="d-flex align-items-center no-flex">
            <h3 class="fw-bold" v-show="isRole(crmSpecialist)">
                <date-picker
                    v-model="filter_date"
                    type="month"
                    value-type="format"
                    class="w-100 mt-1"
                    format="MMMM YYYY"
                    :clearable="false"
                    placeholder="Start"
                >
                </date-picker>
            </h3>
            <div class="ms-auto" v-show="isRole(crmSpecialist)">
                <a
                    href="/crm/target/form"
                    class="btn btn-green btn-lg px-5 m-w-100 m-mt-1"
                    >New Target</a
                >
            </div>
        </div>
        <div class="row mt-4" v-show="isRole(crmSpecialist)">
            <div class="col-md-6">
                <div class="box">
                    <h5 class="fw-bold">Customers Target</h5>
                    <div class="d-flex align-items-center">
                        <h3 class="fw-bold mt-4 text-green">
                            {{
                                formatDecimal(
                                    card.customer_target
                                        ? card.customer_target
                                        : 0
                                )
                            }}
                            Customers
                        </h3>
                        <div class="ms-auto">
                            <img
                                src="../../assets/target.png"
                                width="60"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 m-mt-2">
                <div class="box">
                    <h5 class="fw-bold">Nominal Target</h5>
                    <div class="d-flex align-items-center">
                        <h3 class="fw-bold mt-4 text-green">
                            {{
                                currencyFormat(
                                    card.amount_target ? card.amount_target : 0
                                )
                            }}
                        </h3>
                        <div class="ms-auto">
                            <img
                                src="../../assets/nominal-target.png"
                                width="60"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="division-target"></div>
        <h3 class="fw-bold mt-5">Division Target</h3>
        <template v-for="(item, index) in dataTargets">
            <div class="box mt-4" :key="index">
                <h4 class="fw-bold mb-0">{{ item.label }}</h4>
                <table class="w-100 ps-0 ms-0 mt-3">
                    <tr>
                        <td class="p-0 w-20-table">
                            <div class="text-gray-dashboard size-14">
                                Customer Count
                            </div>
                            <div class="size-20 fw-bold">
                                {{ formatDecimal(item.customer_achieved) }}
                            </div>
                        </td>
                        <td class="p-0 w-20-table">
                            <div class="text-gray-dashboard size-14">
                                Nominal Target
                            </div>
                            <div class="size-20 fw-bold">
                                {{ currencyFormat(item.nominal_target) }}
                            </div>
                        </td>
                        <td class="p-0 w-20-table">
                            <div class="text-gray-dashboard size-14">
                                Nominal Achieved
                            </div>
                            <div class="size-20 fw-bold">
                                {{ currencyFormat(item.nominal_achieved) }}
                            </div>
                        </td>
                        <td class="p-0 w-20-table">
                            <div class="text-gray-dashboard size-14">Gap</div>
                            <div
                                class="size-20 fw-bold"
                                v-bind:class="{
                                    'text-green': item.status == 'achieved',
                                    'text-danger':
                                        item.status == 'not_achieved',
                                }"
                            >
                                <template v-if="item.status == 'achieved'">
                                    +{{ currencyFormat(item.gap) }}
                                </template>
                                <template v-if="item.status == 'not_achieved'">
                                    -{{ currencyFormat(item.gap) }}
                                </template>
                            </div>
                        </td>
                        <td class="p-0 w-20-table">
                            <div class="text-gray-dashboard size-14">
                                Status
                            </div>
                            <div class="size-20 fw-bold">
                                <span
                                    class="tag green-solid px-5 radius"
                                    v-if="item.status == 'achieved'"
                                >
                                    Achieved
                                </span>
                                <span class="tag red-solid px-5 radius" v-else>
                                    Not Achieved
                                </span>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </template>
        <div id="leaderboard"></div>
        <div class="box mt-5">
            <h3 class="fw-bold">Company Target</h3>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-4 m-mx-0">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Search"
                            v-model="req.search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="me-auto m-mt-1" v-show="isRole(branchRoles)">
                    <button
                        class="btn btn-outline-black btn-filter px-4"
                        @click="isFilterCompanyTarget = !isFilterCompanyTarget"
                    >
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div
                class="box mt-3 position-relative"
                v-if="isFilterCompanyTarget"
            >
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <label class="fw-bold">Filter</label>
                    </div>
                    <div class="col-md-8">
                        <multiselect
                            v-model="branch_company_target"
                            :options="branchs"
                            label="name"
                            track-by="id"
                            placeholder="Select Branch"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-outline-black px-5 w-100"
                            :disabled="branch_company_target == null"
                            @click="clearFilterCompanyTarget()"
                        >
                            Clear
                        </button>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-green px-5 w-100"
                            :disabled="branch_company_target == null"
                            @click="filterCompanyTarget()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_load_specialist_target">
                <ListLoader />
            </div>
            <template v-else>
                <template v-if="dataSpecialistTarget.list_data.length > 0">
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        CRM Specialist Name
                                    </th>
                                    <th
                                        class="text-center"
                                        scope="col"
                                        v-show="isRole(branchRoles)"
                                    >
                                        Branch Name
                                    </th>
                                    <th class="text-center" scope="col">
                                        Customers
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nominal Target
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nominal Achieved
                                    </th>
                                    <th class="text-center" scope="col">Gap</th>
                                    <th class="text-center" scope="col">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template
                                    v-for="(
                                        item, index
                                    ) in dataSpecialistTarget.list_data"
                                >
                                    <tr :key="index">
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td>
                                            {{
                                                item.specialist
                                                    ? item.specialist
                                                    : "-"
                                            }}
                                        </td>
                                        <td v-show="isRole(branchRoles)">
                                            {{
                                                item.branch_name
                                                    ? item.branch_name
                                                    : "-"
                                            }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                                item.customer_count
                                                    ? item.customer_count
                                                    : 0
                                            }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                                currencyFormat(
                                                    item.nominal_target
                                                        ? item.nominal_target
                                                        : 0
                                                )
                                            }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                                currencyFormat(
                                                    item.nominal_achieved
                                                        ? item.nominal_achieved
                                                        : 0
                                                )
                                            }}
                                        </td>
                                        <td class="text-center">
                                            <template
                                                v-if="item.status == 'achieved'"
                                            >
                                                +{{ currencyFormat(item.gap) }}
                                            </template>
                                            <template
                                                v-if="
                                                    item.status ==
                                                    'not_achieved'
                                                "
                                            >
                                                -{{ currencyFormat(item.gap) }}
                                            </template>
                                        </td>
                                        <td class="text-center">
                                            <span
                                                class="tag green-solid w-100 radius"
                                                v-if="item.status == 'achieved'"
                                            >
                                                Achieved
                                            </span>
                                            <span
                                                class="tag red-solid w-100 radius"
                                                v-else
                                            >
                                                Not Achieved
                                            </span>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-section">
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </template>
            </template>
            <div
                class="d-flex"
                v-if="dataSpecialistTarget.list_data.length > 0"
            >
                <div class="ms-auto">
                    <div class="d-flex mt-4">
                        <div class="me-3">
                            <select
                                class="form-control form-select w-select"
                                v-model="limitSpecialist"
                                @change="getSpecialistTarget()"
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <b-pagination-nav
                            v-if="dataSpecialistTarget.list_data.length > 0"
                            v-model="currentPageSpecialist"
                            :number-of-pages="dataSpecialistTarget.total_page"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePageSpecialist()"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="box mt-5">
            <div class="d-flex align-items-center no-flex">
                <h3 class="fw-bold">Target History</h3>
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <button
                            class="btn btn-green px-4 m-w-100 m-mt-1"
                            disabled
                        >
                            <img
                                src="../../assets/excel.png"
                                width="20"
                                class="me-1"
                                alt=""
                            />
                            Export
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-auto m-mt-1" v-show="isRole(branchRoles)">
                    <button
                        class="btn btn-outline-black btn-filter px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <label class="fw-bold">Filter</label>
                    </div>
                    <div class="col-md-8">
                        <multiselect
                            v-model="branch"
                            :options="branchs"
                            label="name"
                            track-by="id"
                            placeholder="Select Branch"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-outline-black px-5 w-100"
                            :disabled="branch == null"
                            @click="clearFilter()"
                        >
                            Clear
                        </button>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-green px-5 w-100"
                            :disabled="branch == null"
                            @click="filter()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_load_history">
                <ListLoader />
            </div>
            <template v-else>
                <template v-if="history.list_data.length > 0">
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Month, Year
                                    </th>
                                    <th
                                        class="text-center"
                                        scope="col"
                                        v-show="isRole(branchRoles)"
                                    >
                                        Branch Name
                                    </th>
                                    <th class="text-center" scope="col">
                                        Customers Target
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nominal Target
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nominal Achieved
                                    </th>
                                    <th class="text-center" scope="col">
                                        Status
                                    </th>
                                    <th class="text-center" scope="col">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template
                                    v-for="(
                                        history, index
                                    ) in history.list_data"
                                >
                                    <tr :key="index">
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td>
                                            {{
                                                moment(history.date).format(
                                                    "MMMM, YYYY"
                                                )
                                            }}
                                        </td>
                                        <td v-show="isRole(branchRoles)">
                                            {{
                                                history.branch_name
                                                    ? history.branch_name
                                                    : "-"
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                formatDecimal(
                                                    history.customer_target
                                                )
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                currencyFormat(
                                                    history.amount_target
                                                )
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                currencyFormat(
                                                    history.amount_acieved
                                                )
                                            }}
                                        </td>
                                        <td class="text-center">
                                            <template
                                                v-if="
                                                    history.is_achieved == true
                                                "
                                            >
                                                <div
                                                    class="w-100 tag green-solid radius"
                                                >
                                                    Achieved
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div
                                                    class="w-100 tag red-solid radius"
                                                >
                                                    Not Achieved
                                                </div>
                                            </template>
                                        </td>
                                        <td class="text-center">
                                            <template
                                                v-if="isRole(crmSpecialist)"
                                            >
                                                <a
                                                    :href="
                                                        `/crm/target/edit/` +
                                                        moment(
                                                            String(history.date)
                                                        ).format('MM-YYYY')
                                                    "
                                                >
                                                    <img
                                                        src="../../assets/pencil-icon.png"
                                                        width="20"
                                                        class="me-1"
                                                        alt=""
                                                    />
                                                </a>
                                            </template>
                                            <template v-else> - </template>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-section">
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        DatePicker,
        Multiselect,
    },
    name: "CrmOrder",
    data() {
        return {
            moment: moment,
            filter_date: moment(new Date(new Date())).format("MMMM YYYY"),
            is_load_order: false,
            is_load_history: false,
            history: {
                list_data: [],
            },
            order: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page_order: 10,
            currentPageOrder: 0,
            req: {
                order_search: "",
                search: "",
            },
            roles: [],
            // rolesForOrder: ["admin-crm", "admin-super"],
            crmSpecialist: ["admin-crm", "admin-super", "crm-manager"],
            branchRoles: ["admin-crm"],
            target_data: {},
            is_load_specialist_target: false,
            limitSpecialist: 5,
            currentPageSpecialist: 0,
            dataSpecialistTarget: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            is_load_division_target: false,
            dataTargets: [],
            search_target_history: "",
            isFilter: false,
            isFilterCompanyTarget: false,
            branch: null,
            branch_company_target: null,
            branchs: [],
            card: {},
        };
    },
    created() {},
    mounted() {
        this.getTargetHistory();
        this.getOrderList();
        this.getUserLogin();
        this.getSpecialistTarget();
        this.getTargetDivision();
        this.getBranchs();
        this.getCard();
    },
    methods: {
        changeSearch() {
            this.getSpecialistTarget();
        },
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.roles = res.data.data.roles;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        linkGenOrder(pageNumOrder) {
            return pageNumOrder === 1 ? `?` : `?page=${pageNumOrder}`;
        },
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        formatDecimal(num) {
            return (
                "" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        changeSearchTargetHistory() {
            this.getTargetHistory();
        },
        getTargetHistory() {
            let payload = {
                search: this.req.search_target_history,
                branch_id: this.branch?.id,
            };
            this.is_load_history = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/targets`, {
                params: payload,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.history = data;
                    this.is_load_history = false;

                    data.list_data.forEach((value) => {
                        if (
                            moment(value.date).format("MMMM YYYY") ==
                            moment(this.today).format("MMMM YYYY")
                        ) {
                            this.target_data = value;
                        }
                    });
                })
                .catch((err) => {
                    this.is_load_history = false;
                    console.log(err);
                });
        },
        changePage() {
            this.getTargetHistory();
        },
        getOrderList() {
            this.is_load_order = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/orders`, {
                params: {
                    search: this.req.order_search,
                    per_page: this.per_page_order,
                    page: this.$route.query.page,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.order = data;
                    this.is_load_order = false;
                })
                .catch((err) => {
                    this.is_load_order = false;
                    console.log(err);
                });
        },
        getSpecialistTarget() {
            let payload = {
                search: this.req.search,
                limit: this.limitSpecialist,
                branch_id: this.branch_company_target?.id,
                page:
                    this.currentPageSpecialist == 0 ||
                    this.currentPageSpecialist == null
                        ? 0
                        : this.currentPageSpecialist - 0,
            };
            this.is_load_specialist_target = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/targets/specialist`,
                {
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.dataSpecialistTarget = data;
                    this.is_load_specialist_target = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_specialist_target = false;
                });
        },
        getTargetDivision() {
            this.is_load_division_target = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/targets/division`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.dataTargets = data;
                    this.is_load_division_target = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_division_target = false;
                });
        },
        changePageSpecialist() {
            this.getSpecialistTarget();
        },
        clearFilter() {
            this.branch = null;
            this.getTargetHistory();
        },
        filter() {
            this.getTargetHistory();
        },
        getBranchs() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/branches/dropdown`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.branchs = res.data.data;
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        clearFilterCompanyTarget() {
            this.branch_company_target = null;
            this.getSpecialistTarget();
        },
        filterCompanyTarget() {
            this.getSpecialistTarget();
        },
        getCard() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/targets/card`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.card = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped>
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.active {
    background-color: #e60013 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px;
}
tr {
    border-color: #e8e8e9;
    padding: 10px;
}
td {
    padding: 10px;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.text-red-dashboard {
    color: #e60013;
}
.w-20-table {
    width: 20%;
}
</style>
