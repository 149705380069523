<template>
  <div class="padding-container">
    <div class="d-flex align-items-center bd-highlight">
      <div class="flex-grow-1 bd-highlight">
        <h3 class="fw-bold">List Incoming Order</h3>
      </div>
      <div class="ms-auto">
        <a
          href="/trawlpack/general/order/create"
          class="btn btn-green btn-lg px-5"
        >
          Buat Pesanan
        </a>
      </div>
    </div>
    <div class="box mt-4">
      <div class="mt-4" v-if="is_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div class="row">
          <div class="col-md-6 mt-3">
            <div class="position-relative">
              <input
                type="text"
                class="form-control"
                placeholder="Cari id order"
                v-model="req.order_id"
              />
              <span class="search-icon">
                <i class="fa fa-search"></i>
              </span>
            </div>
          </div>
        </div>
        <table
          class="table position-relative mt-5"
          v-if="filterDataList.length > 0"
        >
          <thead class="thead">
            <tr class="tr">
              <td class="th">No</td>
              <td class="th">Id Order</td>
              <td class="th">Type</td>
              <td class="th">Armada</td>
              <td class="th">Lokasi Penjemputan</td>
              <td class="th">Jenis Order</td>
              <td class="th">Tanggal Order</td>
              <td class="th">Biaya Penjemputan</td>
            </tr>
          </thead>
          <tr>
            <td>
              <div></div>
            </td>
          </tr>
          <template v-for="(list, index, key) in filterDataList">
            <tr class="tr border-body" :key="list.hash">
              <td class="td fw-bold">{{ index + 1 }}.</td>
              <td class="td">
                <template
                  v-for="(code, index) in list.package.multi_package_code"
                >
                  <div class="p-0" :key="index">
                    {{ code.code }}
                  </div>
                </template>
              </td>
              <td class="td capitalize">
                {{ list.package.order_type }}
              </td>
              <td class="td capitalize">
                {{ list.package.transporter_type }}
              </td>
              <td class="td" style="width: 250px">
                <a
                  href="javascript:void(0)"
                  class="text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="list.package.pickup_address"
                >
                  <div class="limit-one-line">
                    {{ list.package.pickup_address }}
                  </div>
                </a>
              </td>
              <td class="td">
                {{ list.package.order_mode }}
              </td>
              <td class="td capitalize">
                {{ moment(list.package.created_at).format("DD MMMM YYYY") }}
              </td>
              <td class="td">
                {{ currencyFormat(list.package.pickup_price) }}
              </td>
            </tr>
            <tr class="border-footer bc-gray pb-5" :key="index">
              <td class="p-2 pt-1 pb-1" colspan="8">
                <div class="d-flex align-items-center">
                  <div class="">
                    {{ list.package.description_status }}
                  </div>
                  <div class="ms-auto">
                    <!-- <a href="" class="btn btn-danger me-2 btn-sm px-4">
                                            Tolak
                                        </a> -->
                    <template v-if="list.status != 'accepted'">
                      <a
                        href="javascript:void(0)"
                        @click="accept(list.package.hash)"
                        class="btn btn-green me-2 btn-sm px-4"
                      >
                        Terima
                      </a>
                    </template>
                    <template v-else>
                      <a
                        :href="'/trawlpack/general/order/' + list.package.hash"
                        class="btn btn-green me-2 btn-sm px-4"
                      >
                        <i class="fa fa-pencil me-1"></i>
                        Edit
                      </a>
                    </template>
                  </div>
                </div>
              </td>
            </tr>
            <tr :key="key">
              <td colspan="9">
                <div class=""></div>
              </td>
            </tr>
          </template>
        </table>
        <div class="text-center mt-section" v-else>
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <b-pagination-nav
        v-if="filterDataList.length > 0"
        :link-gen="linkGen"
        v-model="currentPage"
        :number-of-pages="totalPage"
        base-url="#"
        first-number
        align="right"
      ></b-pagination-nav>
    </div>

    <!-- MODAL -->
    <div class="modal-vue">
      <div class="overlay" v-if="showModalAccept"></div>
      <div class="modal-body-chat lg" v-if="showModalAccept">
        <div class="row ms-1 me-1">
          <div class="col-md-12">
            <div class="d-flex bd-highlight align-items-lg-center">
              <div class="flex-grow-1 bd-highlight">
                <div class="size-22 fw-ekstra-bold">Assign Driver</div>
              </div>
              <div class="bd-highlight">
                <a
                  href="javascript:void(0)"
                  @click="showModalAccept = false"
                  class="size-20 text-primary"
                >
                  <i class="fa fa-times-circle"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="scroll mt-4">
          <div class="row ms-1 me-1 mb-3">
            <div class="col-md-6">
              <div class="box p-0 mt-4">
                <div class="box no-shadow bc-gray p-3 no-radius-bottom">
                  <h6 class="mb-0 fw-bold size-16">
                    <i class="fa fa-send me-2"></i>
                    Data Pengirim
                  </h6>
                </div>
                <div class="p-4">
                  <div class="d-flex">
                    <div class="me-2">
                      <img
                        src="../../assets/pengirim.png"
                        class="me-2"
                        width="35"
                        alt=""
                      />
                    </div>
                    <div>
                      <div class="fw-bold size-16">
                        {{ detailOrder.sender_name }}
                      </div>
                      <div class="fw-medium size-14 mt-1">
                        {{ detailOrder.sender_phone }}
                      </div>
                      <div class="fw-medium size-14 mt-1">
                        {{ detailOrder.sender_address }}
                      </div>
                      <div class="fw-medium size-14 mt-1">
                        Note:
                        <span class="fw-bold">{{
                          detailOrder.sender_detail_address
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <template v-for="(data, index) in detailOrder.package_child">
                <div class="box p-0 mt-4" :key="index">
                  <div class="box no-shadow bc-gray p-3 no-radius-bottom">
                    <h6 class="mb-0 fw-bold size-16">
                      <i class="fa fa-user me-2"></i>
                      Data Penerima {{ index + 1 }}
                    </h6>
                  </div>
                  <div class="p-4">
                    <div class="d-flex">
                      <div class="me-2">
                        <img
                          src="../../assets/receive-item.png"
                          class="me-2"
                          width="35"
                          alt=""
                        />
                      </div>
                      <div>
                        <div class="fw-bold size-16">
                          {{ data.receiver_name }}
                        </div>
                        <div class="fw-medium size-14 mt-1">
                          {{ data.receiver_phone }}
                        </div>
                        <div class="fw-medium size-14 mt-1">
                          {{ data.receiver_address }}
                        </div>
                        <div class="fw-medium size-14 mt-1">
                          Note:
                          <span class="fw-bold">{{
                            data.receiver_address
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <template v-if="data.items.length > 0">
                      <template v-for="(item, index) in data.items">
                        <div class="box mt-4 p-0" :key="item.id">
                          <div
                            v-b-toggle="'collapse-' + data.id"
                            class="header-collapse fw-bold"
                          >
                            <div
                              class="d-flex align-items-center fw-bold size-16"
                            >
                              <i class="fa fa-briefcase me-2"></i>
                              Data Barang
                              {{ index + 1 }}
                              <div class="ms-auto">
                                <i class="fa fa-angle-down size-20"></i>
                              </div>
                            </div>
                          </div>
                          <b-collapse
                            class="body-collapse"
                            :id="'collapse-' + data.id"
                          >
                            <div class="d-flex align-items-center">
                              <div class="fw-normal size-14">
                                Kategori Barang
                              </div>
                              <div class="fw-normal size-14 ms-auto">
                                <template v-if="item.category_name">
                                  {{ item.category_name }}
                                </template>
                                <template v-else> - </template>
                              </div>
                            </div>
                            <hr />
                            <div class="d-flex align-items-center">
                              <div class="fw-normal size-14">
                                <template v-if="detailOrder.is_bike == false">
                                  Jumlah Koli
                                </template>
                                <template v-else> CC Motor </template>
                              </div>
                              <div class="fw-normal size-14 ms-auto">
                                <template v-if="detailOrder.is_bike == false">
                                  {{ item.qty }}
                                </template>
                                <template v-else>
                                  {{ data?.moto_bike?.cc }}
                                  CC
                                </template>
                              </div>
                            </div>
                            <hr />
                            <div class="d-flex align-items-center">
                              <div class="fw-normal size-14">
                                <template v-if="detailOrder.is_bike == false">
                                  Deskripsi Barang
                                </template>
                                <template v-else> Merk Motor </template>
                              </div>
                              <div class="fw-normal size-14 ms-auto">
                                <template v-if="detailOrder.is_bike == false">
                                  <template v-if="item.name">
                                    {{ item.name }}
                                  </template>
                                  <template v-else> - </template>
                                </template>
                                <template v-else>
                                  {{ data?.moto_bike?.merk }}
                                </template>
                              </div>
                            </div>
                            <hr />
                            <div class="d-flex align-items-center">
                              <div class="fw-normal size-14">
                                <template v-if="detailOrder.is_bike == false">
                                  Dimensi Barang
                                </template>
                                <template v-else> Tipe Motor </template>
                              </div>
                              <div class="fw-normal size-14 ms-auto capitalize">
                                <template v-if="detailOrder.is_bike == false">
                                  {{ item.length }}x{{ item.width }}x{{
                                    item.height
                                  }}
                                </template>
                                <template v-else>
                                  {{ data?.moto_bike?.type }}
                                </template>
                              </div>
                            </div>
                            <hr />
                            <div class="d-flex align-items-center">
                              <div class="fw-normal size-14">
                                <template v-if="detailOrder.is_bike == false">
                                  Volume Barang
                                </template>
                                <template v-else> Keluaran Tahun </template>
                              </div>
                              <div class="fw-normal size-14 ms-auto">
                                <template v-if="detailOrder.is_bike == false">
                                  {{ item.weight_borne_volume }}kg
                                </template>
                                <template v-else>
                                  {{ data?.moto_bike?.years }}
                                </template>
                              </div>
                            </div>
                            <hr />
                            <template v-if="detailOrder.is_bike == false">
                              <div class="d-flex align-items-center">
                                <div class="fw-normal size-14">
                                  Berat Barang
                                </div>
                                <div class="fw-normal size-14 ms-auto">
                                  {{ item.weight }}kg
                                </div>
                              </div>
                              <hr />
                            </template>
                            <div class="d-flex align-items-center">
                              <div class="fw-normal size-14">
                                <template v-if="detailOrder.is_bike == false">
                                  Perlindungan Barang
                                </template>
                                <template v-else> Perlindungan Wajib </template>
                              </div>
                              <div class="fw-normal size-14 ms-auto">
                                <template v-if="detailOrder.is_bike == true">
                                  <small class="tag green capitalize">
                                    Bubble Wrap
                                  </small>
                                  <small class="tag green capitalize">
                                    Karung Besar
                                  </small>
                                </template>
                                <template v-else>
                                  <template v-if="item.handling.length > 0">
                                    <template
                                      v-for="(handling, index) in item.handling"
                                    >
                                      <small
                                        class="tag green capitalize"
                                        :key="index"
                                      >
                                        <template
                                          v-if="handling == 'bubble wrap'"
                                        >
                                          Bubble Wrap
                                        </template>
                                        <template
                                          v-if="handling == 'cardboard'"
                                        >
                                          Kardus
                                        </template>
                                        <template v-if="handling == 'plastic'">
                                          Plastik
                                        </template>
                                        <template v-if="handling == 'wood'">
                                          Kayu
                                        </template>
                                        <template
                                          v-if="handling == 'sandbag sm'"
                                        >
                                          Karung Kecil
                                        </template>
                                        <template
                                          v-if="handling == 'sandbag md'"
                                        >
                                          Karung Sedang
                                        </template>
                                        <template
                                          v-if="handling == 'sandbag l'"
                                        >
                                          Karung Besar
                                        </template>
                                      </small>
                                    </template>
                                  </template>
                                  <template v-else> - </template>
                                </template>
                              </div>
                            </div>
                          </b-collapse>
                        </div>
                      </template>
                    </template>
                    <div class="box p-0 mt-4" v-if="data.images.length > 0">
                      <div class="box no-shadow bc-gray p-3 no-radius-bottom">
                        <h6 class="mb-0 fw-bold size-16">
                          <i class="fa fa-camera me-2"></i>
                          Foto Barang Keseluruhan
                        </h6>
                      </div>
                      <div class="row p-4">
                        <template v-for="(image, index) in data.images">
                          <div class="col-md-3" :key="index">
                            <div
                              class="box no-shadow invoice-item-image"
                              v-bind:style="{
                                'background-image': 'url(' + image.uri + ')',
                              }"
                            ></div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="col-md-6">
              <div class="box p-0 mt-4">
                <div class="box no-shadow bc-gray p-3 no-radius-bottom">
                  <h6 class="mb-0 fw-bold size-16">
                    <i class="fa fa-users me-2"></i>
                    Daftar Driver
                  </h6>
                </div>
                <div class="p-4">
                  <template v-if="searchDriverList?.length > 0">
                    <template v-for="(driver, index) in searchDriverList">
                      <div class="mt-2" :key="index">
                        <label class="plan" :for="driver.driver.hash">
                          <input
                            type="radio"
                            name="transport"
                            :id="driver.driver.hash"
                            :value="driver"
                            v-model="req.driver"
                          />
                          <div class="plan-content p-3">
                            <h6 class="mb-0 fw-bold size-16 capitalize">
                              {{ driver.driver.name }}
                            </h6>
                            <div class="mt-1 size-14 capitalize">
                              {{ (driver.type || "").split("_").join(" ") }}
                              -
                              {{ driver.registration_number }}
                            </div>
                          </div>
                        </label>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div class="box no-shadow bc-gray text-center fw-bold">
                      <div class="mb-1">
                        <i class="fa fa-search size-24"></i>
                      </div>
                      Driver tidak ditemukan
                    </div>
                  </template>
                  <div class="mt-4">
                    <button
                      class="btn btn-green w-100 btn-md btn-lg"
                      type="button"
                      disabled
                      v-if="is_assign_driver"
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Tugaskan
                    </button>
                    <button
                      @click="assignDriver()"
                      class="btn btn-green w-100 btn-md btn-lg"
                      :disabled="req.driver == null"
                      v-else
                    >
                      Tugaskan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../api/Api";
import { Tooltip } from "bootstrap";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";

export default {
  components: {
    ListLoader,
  },
  name: "IncomingOrder",
  data() {
    return {
      moment: moment,
      is_list_ready: false,
      list: {
        data: [],
        total: 0,
      },
      per_page: 10,
      currentPage: 0,
      totalPage: 0,
      req: {
        order_id: "",
        driver_search: "",
        driver: null,
      },
      is_assign_driver: false,
      showModalAccept: false,
      detailOrder: {
        package_child: [],
      },
      drivers: [],
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
  },
  computed: {
    filterDataList() {
      return this.list.data.filter((item) => {
        return item.package.code
          .toLowerCase()
          .includes(this.req.order_id.toLowerCase());
      });
    },
    searchDriverList() {
      return this.drivers.filter((item) => {
        return item.driver.name
          .toLowerCase()
          .includes(this.req.driver_search.toLowerCase());
      });
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? `?` : `?page=${pageNum}`;
    },
    getList() {
      this.is_list_ready = true;
      Api.get(`${process.env.VUE_APP_BASE_URL}/trawlpack/dashboard/order`, {
        params: {
          per_page: this.per_page,
          page: this.$route.query.page,
        },
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          var data = res.data;
          this.totalPage = res.data.last_page;
          this.list = data;
          this.is_list_ready = false;
        })
        .catch((err) => {
          console.log(err);
          this.is_list_ready = false;
        });
    },
    currencyFormat(num) {
      return (
        "Rp. " +
        parseInt(num)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
      );
    },
    accept(id) {
      this.showModalAccept = true;
      this.getOrderDetail(id);
      this.req.driver = null;
      this.pickup_date = null;
    },
    getOrderDetail(id) {
      Api.get(
        `${process.env.VUE_APP_BASE_URL}/trawlpack/dashboard/order/detail/${id}`,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          console.log(res);
          var data = res.data.data;
          this.detailOrder = data;

          this.getListDriver();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getListDriver() {
      Api.get(
        `${process.env.VUE_APP_BASE_URL}/trawlpack/dashboard/order/list/drivers?type=${this.detailOrder.transporter_type}`,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          console.log(res);
          var data = res.data.data;
          this.drivers = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    assignDriver() {
      this.is_assign_driver = true;
      Api.patch(
        `${process.env.VUE_APP_BASE_URL}/trawlpack/dashboard/order/assign/${this.detailOrder.hash}/${this.req.driver.driver.hash}/drivers`,
        "",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.showModalAccept = false;
          this.getList();
          this.is_assign_driver = false;
          console.log(res);
          this.$notify({
            group: "foo",
            type: "success",
            title: "Success",
            text: "Driver berhasil ditugaskan",
          });
        })
        .catch((err) => {
          this.is_assign_driver = false;

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: err.response.data.message,
          });
        });
    },
  },
};
</script>

<style scoped>
.table > thead {
  border-bottom: 0px solid #fff;
  font-weight: 700;
}
.tr {
  transition: all 0.8s ease-in-out;
  margin-top: 15px;
  transition: all 0.2s ease-in-out;
  height: auto;
}

.table .th,
.table .td {
  padding: 0px 12px;
}

.ttable {
  width: 100%;
  margin-right: 5px;
}

.table {
  border: 0 !important;
}

.td {
  margin-bottom: 10px;
  border-top: none !important;
}

.table .td {
  font-size: 14px;
  background-color: #fff;
}
.table .td:nth-child(odd) {
  background-color: #fff;
}
.border-body {
  border-top: 1.5px solid #f7f7f7;
  border-left: 1.5px solid #f7f7f7;
  border-right: 1.5px solid #f7f7f7;
  border-bottom: 1.5px solid #f7f7f7;
  box-shadow: 0 1px 12px -1px rgb(141 145 162 / 25%);
}
.border-footer {
  border-bottom: 1.5px solid #f7f7f7;
  border-left: 1.5px solid #f7f7f7;
  border-right: 1.5px solid #f7f7f7;
  box-shadow: 0 3px 10px -2px #e9ecef;
}
.header-collapse {
  background: #fafafa;
  border-radius: 12px;
  padding: 1rem;
}
.body-collapse {
  padding: 1rem;
}
</style>
