<template>
    <div class="padding-container">
        <div class="d-flex align-items-center bd-highlight">
            <div class="d-flex align-items-center">
                <div class="me-3">
                    <a href="/trawlcarrier/ho/driver" class="text-primary">
                        <i class="fa fa-angle-left size-24 fw-bold"></i>
                    </a>
                </div>
                <h3 class="fw-bold mb-0">
                    Riwayat Driver
                </h3>
            </div>
        </div>
        <div class="box mt-4">
            <div class="mt-4" v-if="is_data_ready">
                <CardLoader/>
            </div>
            <div class="row" v-else>
                <div class="col-md-4">
                    <div class="box no-shadow border-gray bc-gray">
                        <h5 class="fw-bold mb-0 text-gray">
                            Nama Driver
                        </h5>
                        <div class="fw-ekstra-bold size-24 mt-3">
                            {{ datas.dashboard.nama_driver }}
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box no-shadow border-gray bc-gray">
                        <h5 class="fw-bold mb-0 text-gray">
                            Mitra Driver
                        </h5>
                        <div class="fw-ekstra-bold size-24 mt-3">
                            {{ datas.dashboard.mitra }}
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box no-shadow border-gray bc-gray">
                        <h5 class="fw-bold mb-0 text-gray">
                            Status
                        </h5>
                        <div class="fw-ekstra-bold size-24 mt-3">
                            {{ datas.dashboard.status }}
                        </div>
                    </div>
                </div>
            </div>
            <div id="map"></div>
        </div>
    </div>
</template>

<script>

import Api from "../../api/Api";
import CardLoader from '../../components/CardLoader.vue';

export default {
    components: {
        CardLoader
    },
    name: "DriverTracking",
    data() {
        return {
            datas: {
                dashboard: {}
            },
            is_data_ready: false
        };
    },
    created() {
        this.detDatas()
    },
    mounted() {
    },
    methods: {
        detDatas() {
            this.is_data_ready = true
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/carrier/ho/driver/tracking/${this.$route.params.id}`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                var data = res.data.data
                this.datas = data
                this.is_data_ready = false
            })
            .catch((err) => {
                console.log(err);
                this.is_data_ready = false
            });
        },
    },
};
</script>

<style>
</style>