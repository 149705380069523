<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">List Truk Stand By</h3>
            </div>
            <div class="row mt-5 m-mt-2">
                <div class="col-md-5">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg"
                            placeholder="Cari data..."
                            v-model="req.search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <template v-if="list.list_data.length > 0">
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Kota Stand By
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nama Mitra
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nama Driver
                                    </th>
                                    <th class="text-center" scope="col">
                                        Status
                                    </th>
                                    <th class="text-center" scope="col">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template
                                    v-for="(data, index) in list.list_data"
                                >
                                    <tr :key="index">
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="text-center">
                                            {{ data.regency.name }}
                                        </td>
                                        <td class="text-center">
                                            {{ data.partner.name }}
                                        </td>
                                        <td class="text-center">
                                            {{ data.driver.name }}
                                        </td>
                                        <td class="text-center">
                                            {{ data.status_label }}
                                        </td>
                                        <td class="text-center">
                                            <template
                                                v-if="data.status != 'returned'"
                                            >
                                                <a
                                                    :href="
                                                        '/trawltruck/ho/truck-stand-by/edit/' +
                                                        data.id
                                                    "
                                                >
                                                    <i class="fa fa-pencil"></i>
                                                </a>
                                            </template>
                                            <template v-else>
                                                <a
                                                    href="javascript:void"
                                                    class="text-gray cursor-drop"
                                                >
                                                    <i class="fa fa-pencil"></i>
                                                </a>
                                            </template>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-section">
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </template>
            </div>
            <div class="d-flex" v-if="list.list_data.length > 0">
                <div class="ms-auto">
                    <div class="d-flex mt-4">
                        <div class="me-3">
                            <select
                                class="form-control form-select w-select"
                                v-model="per_page"
                                @change="getList()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <b-pagination-nav
                            v-model="currentPage"
                            :number-of-pages="list.total_page"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";

export default {
    components: {
        ListLoader,
    },
    name: "ManagementTruckStandBy",
    data() {
        return {
            req: {
                search: "",
            },
            is_list_ready: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page: 10,
            currentPage: 0,
        };
    },
    created() {
        this.getList();
    },
    computed: {},
    methods: {
        changePage() {
            this.getList();
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        getList() {
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/standby`,
                {
                    params: {
                        search: this.req.search,
                        limit: this.per_page,
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 1,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.text-red-dashboard {
    color: #e60013;
}
.search-icon {
    font-size: 16px;
}
</style>
