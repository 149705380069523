<template>
  <div class="padding-container">
    <div class="d-flex align-items-center">
      <h3 class="mb-4 fw-bold">{{ items.name }}</h3>
    </div>
    <div class="box">
      <div class="d-flex align-items-center">
        <h3 class="mb-0 fw-bold">List {{ items.name }}</h3>
        <div class="ms-auto">
          <div class="position-relative">
            <input class="form-control" type="text" v-model="date" onfocus="(this.type='date')" @change="searchData" style="border: 1px solid #48a2d4" placeholder="Pilih Tanggal" />
            <span class="search-icon">
              <img src="../../assets/calendar-icon.png" />
            </span>
          </div>
        </div>
        <div v-if="items.codeStatus != 'rtd'" class="ms-2">
          <select class="form-control form-select" name="" id="" v-model="filterStatus" @change="searchData" style="width: 175px">
            <option value="" selected>Filter Status</option>
            <option value="done">Selesai</option>
            <option value="not">Belum Selesai</option>
          </select>
        </div>
        <div class="ms-2">
          <div class="position-relative">
            <input v-model="keySearch" @keyup.enter="searchData" type="text" class="form-control pe-5" style="width: 155px" placeholder="Search" />
            <span class="search-icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="mt-4" v-if="is_my_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div v-if="list_status == 'kedatangan'" class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">Tanggal</div>
            <div class="d-table-cell">{{ items.codeStatus == "rtd" ? "No.Manifest" : "No.Resi" }}</div>
            <div class="d-table-cell">Nama Pegawai</div>
            <div v-if="items.codeStatus == 'rtd'" class="d-table-cell">Partner Tujuan</div>
            <div v-if="items.codeStatus != 'mw'" class="d-table-cell">SLA Leadtime</div>
            <div v-if="items.codeStatus != 'mw'" class="d-table-cell">Keterlambatan</div>
            <div v-if="items.codeStatus != 'rtd'" class="d-table-cell">Status</div>
          </div>
          <div class="d-table-row" v-for="(item, index) in data" :key="index">
            <div class="d-table-cell">{{ numbering(index) }}</div>
            <div class="d-table-cell">
              {{ dateFormat(item.created_at) }}
            </div>
            <div class="d-table-cell">
              {{ item.code }}
            </div>
            <div class="d-table-cell font-countdown">{{ item.employee_name ? item.employee_name : "-" }}</div>
            <div v-if="items.codeStatus == 'rtd'" class="d-table-cell">{{ item.destination_partner ? item.destination_partner : "-" }}</div>
            <div v-if="items.codeStatus != 'mw'" class="d-table-cell">
              <Timer :class="['font-countdown', item.sla?.level == 1 ? 'tag green' : item.sla?.level == 2 ? 'yellow' : item.sla?.level == 3 ? 'red' : '']" :date="item.sla?.deadline_at"></Timer>
            </div>
            <div v-if="items.codeStatus != 'mw'" class="d-table-cell">
              <Timer class="font-countdown red" :date="item.sla?.deadline_at" :isLate="item.sla?.is_late" :done="item.sla?.late_at" :level="item.sla?.level"></Timer>
            </div>
            <div v-if="items.status != 'rtd'" :class="['d-table-cell', 'font-countdown', item.status == 'Selesai' ? 'green' : 'red']">{{ item.status }}</div>
          </div>
        </div>

        <div v-if="data.length == 0" class="text-center mt-section">
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
    </div>
    <div class="overflow-auto mt-3">
      <b-pagination-nav v-if="data.length > 0" v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav>
    </div>
  </div>
</template>
<script>
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";
import Timer from "./timer.vue";

export default {
  props: {
    items: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ListLoader,
    Timer,
  },
  name: "Warehouse",
  data() {
    return {
      moment: moment,
      filterStatus: "",
      is_my_list_ready: false,
      keySearch: "",
      date: "",
      list_status: "kedatangan",
      data: "",
      currentPage: 0,
      totalPage: 0,
      limit: 10,
      dataItem: [
        {
          date: "12 Aug 2022",
          name: "Dedi Sumarno",
          receipt: "MNF0000001",
          Tipe_Manifest: "PIckup",
          Status_Manifest: "En Route",
          Partner_Tujuan: "MPW-JKT-001",
          Armada: "Mobil Pickup",
          SLA: "00 : 20 : 00",
          Keterlambatan: "Tidak Ada Keterlambatan",
          status: "Selesai",
        },
        {
          date: "13 Aug 2022",
          name: "Hendra Setiawan",
          receipt: "MNF0000003",
          Tipe_Manifest: "Dooring",
          Status_Manifest: "Finished",
          Partner_Tujuan: "MPW-JKT-003",
          Armada: "Mobil Pickup",
          SLA: "00 : 00 : 00",
          Keterlambatan: "00 : 10 : 45",
          status: "Belum Selesai",
        },
        {
          date: "14 Aug 2022",
          name: "Budi Nugroho",
          receipt: "MNF0000004",
          Tipe_Manifest: "PIckup",
          Status_Manifest: "En Route",
          Partner_Tujuan: "MPW-JKT-004",
          Armada: "Mobil Pickup",
          SLA: "00 : 59 : 00",
          Keterlambatan: "Tidak Ada Keterlambatan",
          status: "Selesai",
        },
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    searchData() {
      this.getData();
    },
    changePage() {
      this.getData();
    },
    numbering(index) {
      let number = this.itemNumber(index);
      return (number < 10 ? "0" : "") + number;
    },
    getData() {
      this.is_my_list_ready = true;
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/dashboard/owner/warehouse/${this.items.url}`, {
        params: {
          page: this.currentPage == 0 || this.currentPage == null ? 1 : this.currentPage,
          per_page: this.limit,
          status: this.filterStatus ? this.filterStatus : "''",
          date: this.date ? this.date : "''",
          code: this.keySearch ? this.keySearch : "''",
        },
        // params: { date: this.monthFilter,  },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data = res.data.data;
          this.totalPage = res.data.last_page;
          this.is_my_list_ready = false;
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          this.is_my_list_ready = false;
          console.log(err);
        });
    },
    dateFormat(date) {
      return moment(date).format("DD MMM YYYY");
    },
    itemNumber(index) {
      return index + this.startIndex + 1;
    },
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.limit;
    },
  },
};
</script>
<style scoped>
.select-status {
  border: none;
  border-radius: 8px;
  background: rgba(72, 162, 212, 0.1);
  color: #48a2d4;
}
.label-req {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 29px;
  background: #519c66;
  border: none;
  border-radius: 6px;
  color: white;
}
.font-countdown {
  font-weight: 600;
}
.green {
  color: #3d8824;
}
.yellow {
  color: #fb9727;
}
.red {
  color: #e60013;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: none no-repeat;
  border-width: thin;
  z-index: 9;
}
</style>
