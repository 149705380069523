<template>
    <div class="padding-container">
        <div class="box">
            <div class="d-flex bd-highlight">
                <div class="flex-grow-1 bd-highlight">
                    <h4 class="fw-bold">Assign Transporter</h4>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        <div class="position-relative">
                            <input
                                v-model="code"
                                value="code"
                                @keyup.enter="searchManifest"
                                type="text"
                                class="form-control"
                                placeholder="Kode Manifest"
                            />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div class="mt-5" v-else>
                <table class="table position-relative" v-if="data.length > 0">
                    <thead class="thead">
                        <tr class="tr">
                            <td class="th">No</td>
                            <td class="th">Nomor Manifest</td>
                            <td class="th">Mitra Asal</td>
                            <td class="th">Mitra Tujuan</td>
                            <td class="th">Tanggal Manifest</td>
                        </tr>
                    </thead>
                    <tr>
                        <td>
                            <div></div>
                        </td>
                    </tr>
                    <template v-for="(item, index, key) in data">
                        <tr
                            class="tr border-body"
                            style="vertical-align: text-top; border-top: hidden"
                            :key="index"
                        >
                            <td class="td">
                                <b> {{ itemNumber(index) }}. </b>
                            </td>
                            <td class="td" style="width: 150px">
                                <div class="">
                                    {{ item.manifest_code }}
                                    <span
                                        ><br />
                                        Jumlah Kg :
                                        {{ item.total_weight }}</span
                                    >
                                </div>
                            </td>
                            <td class="td">
                                <a
                                    href="javascript:void(0)"
                                    class="text-dark"
                                    :data-bs-toggle="
                                        item.origin_partner.code
                                            ? 'tooltip'
                                            : ''
                                    "
                                    data-bs-placement="top"
                                    :title="
                                        item.origin_partner.address
                                            ? item.origin_partner.address
                                            : '-'
                                    "
                                >
                                    {{
                                        item.origin_partner.code
                                            ? item.origin_partner.code
                                            : "-"
                                    }}
                                    <i
                                        v-if="item.origin_partner.code"
                                        class="fa fa-info-circle text-blue ms-1"
                                    ></i>
                                </a>
                            </td>
                            <td class="td">
                                <a
                                    href="javascript:void(0)"
                                    class="text-dark"
                                    :data-bs-toggle="
                                        item.destination_partner.code
                                            ? 'tooltip'
                                            : ''
                                    "
                                    data-bs-placement="top"
                                    :title="
                                        item.destination_partner.address
                                            ? item.destination_partner.address
                                            : '-'
                                    "
                                >
                                    {{
                                        item.destination_partner.code
                                            ? item.destination_partner.code
                                            : "-"
                                    }}
                                    <i
                                        v-if="item.destination_partner.code"
                                        class="fa fa-info-circle text-blue ms-1"
                                    ></i>
                                </a>
                            </td>
                            <td class="td">
                                <div>
                                    {{ dateFormat(item.created_at) }}
                                    <span
                                        ><br />
                                        {{ clockFormat(item.created_at) }}</span
                                    ><br />
                                    <button
                                        type="button"
                                        class="btn btn-success"
                                        @click="toggleModal(item)"
                                    >
                                        Assign Mitra
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr class="border-footer bc-gray pb-5" :key="index">
                            <td class="p-2 pt-1 pb-1" colspan="8">
                                <div
                                    class="d-flex align-items-center"
                                    style="color: #fb9727"
                                >
                                    <div class="">
                                        [{{ item.notes.title }}]
                                        {{ item.notes.desc }}
                                    </div>
                                    <div class="ms-auto"></div>
                                </div>
                            </td>
                        </tr>
                        <tr :key="key">
                            <td colspan="8">
                                <div class=""></div>
                            </td>
                        </tr>
                    </template>
                </table>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="d-flex" v-if="data.length > 0">
            <div class="ms-auto">
                <div class="d-flex mt-4">
                    <div class="me-3">
                        <select
                            class="form-control form-select w-select"
                            v-model="limit"
                            @change="getTransporter()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <b-pagination-nav
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="changePage"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div
                class="overlay"
                v-if="showModal"
                @click="showModal = false"
            ></div>
            <div class="modal-body-chat lg" v-if="showModal">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            Assign Mitra Transport
                        </div>
                    </div>
                    <div class="bd-highlight">
                        <a
                            href="javascript:void(0)"
                            @click="showModal = false"
                            class="size-20 text-primary"
                        >
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div class="line"></div>
                <div>
                    <div class="row">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-2">
                                    <img src="../../assets/send-item.png" />
                                </div>
                                <div class="col-10">
                                    <div class="text-dark size-14">
                                        Mitra Asal
                                    </div>
                                    <div class="my-1 size-16 fw-bold">
                                        {{
                                            dataDetail.origin_partner.code
                                                ? dataDetail.origin_partner.code
                                                : "-"
                                        }}
                                    </div>
                                    <div class="text-dark size-14">
                                        {{
                                            dataDetail.origin_partner.address
                                                ? dataDetail.origin_partner
                                                      .address
                                                : "-"
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-2">
                                    <img src="../../assets/receive-item.png" />
                                </div>
                                <div class="col-10">
                                    <div class="text-dark size-14">
                                        Mitra Tujuan
                                    </div>
                                    <div class="my-1 size-16 fw-bold">
                                        {{
                                            dataDetail.destination_partner.code
                                                ? dataDetail.destination_partner
                                                      .code
                                                : "-"
                                        }}
                                    </div>
                                    <div class="text-dark size-14">
                                        {{
                                            dataDetail.destination_partner
                                                .address
                                                ? dataDetail.destination_partner
                                                      .address
                                                : "-"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="line-v" /> -->
                        <div class="col-6">
                            <div class="size-18 fw-ekstra-bold">
                                Pilih Mitra
                            </div>
                            <input
                                v-model="partnerCode"
                                @keyup.enter="searchPartner"
                                type="text"
                                class="form-control"
                                placeholder="Cari mitra"
                            />
                            <div class="line"></div>
                            <div class="scroll">
                                <div
                                    v-for="(item, index) in dataPartner"
                                    :key="index"
                                >
                                    <div
                                        class="mb-2 d-flex bd-highlight align-items-lg-center"
                                    >
                                        <div class="flex-grow-1 bd-highlight">
                                            <div class="size-16 fw-bold">
                                                {{ item.code }}
                                            </div>
                                            <div class="fw-bolder">
                                                {{ item.name }}
                                            </div>
                                            <div class="row">
                                                <div class="col-1">
                                                    <img
                                                        src="../../assets/home.png"
                                                    />
                                                </div>
                                                <div class="col-10">
                                                    {{ item.address }}
                                                </div>
                                                <!-- <span><img src="../../assets/home.png" /></span> {{ item.address }} -->
                                            </div>
                                        </div>
                                        <div class="form-check bd-highlight">
                                            <input
                                                @click="partnerHash = item.hash"
                                                class="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="flexRadioDefault2"
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="text-end">
                        <button
                            type="button"
                            class="mt-3 btn btn-success"
                            @click="assignTransporter"
                            :disabled="!partnerHash"
                        >
                            Assign
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";

export default {
    components: {
        ListLoader,
    },
    name: "Assign Transporter",
    data() {
        return {
            showModal: false,
            is_list_ready: false,
            data: [],
            dataDetail: [],
            dataPartner: [],
            currentPage: 0,
            totalPage: 0,
            limit: 10,
            code: "",
            partnerCode: "",
            deliveryHash: "",
            partnerHash: "",
            items: [
                {
                    no: 1,
                    manifest: "MNF11062200023",
                    partner_origin: "MB-PNK-01",
                    partner_destination: "MB-PNK-01",
                    created: "Sabtu, 11 Jun 2022",
                },
                {
                    no: 2,
                    manifest: "MNF11062200023",
                    partner_origin: "MB-PNK-01",
                    partner_destination: "MB-PNK-01",
                    created: "Sabtu, 11 Jun 2022",
                },
                {
                    no: 3,
                    manifest: "MNF11062200023",
                    partner_origin: "MB-PNK-01",
                    partner_destination: "MB-PNK-01",
                    created: "Sabtu, 11 Jun 2022",
                },
                {
                    no: 4,
                    manifest: "MNF11062200023",
                    partner_origin: "MB-PNK-01",
                    partner_destination: "MB-PNK-01",
                    created: "Sabtu, 11 Jun 2022",
                },
                {
                    no: 5,
                    manifest: "MNF11062200023",
                    partner_origin: "MB-PNK-01",
                    partner_destination: "MB-PNK-01",
                    created: "Sabtu, 11 Jun 2022",
                },
                {
                    no: 6,
                    manifest: "MNF11062200023",
                    partner_origin: "MB-PNK-01",
                    partner_destination: "MB-PNK-01",
                    created: "Sabtu, 11 Jun 2022",
                },
            ],
        };
    },
    created() {
        this.getTransporter();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    methods: {
        searchManifest() {
            this.currentPage = 0;
            this.getTransporter();
        },
        searchPartner() {
            this.currentPage = 0;
            this.getPartner();
        },
        changePage() {
            this.getTransporter();
        },
        toggleModal(item) {
            // this.getTransporter();
            this.dataDetail = item;
            this.deliveryHash = item.hash;
            this.partnerCode = "";
            this.getPartner();
            this.showModal = true;
            // this.getDetailReceipt(receipt);
        },
        getPartner() {
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/fno/tracking/request/transporter`,
                {
                    params: {
                        partner: true,
                        search: this.partnerCode,
                        delivery_hash: this.deliveryHash,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.dataPartner = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
        getTransporter() {
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/fno/tracking/request/transporter`,
                {
                    params: {
                        page: this.currentPage,
                        per_page: this.limit,
                        search: this.code,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data;
                    this.totalPage = res.data.last_page;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        assignTransporter() {
            Api.patch(
                `${process.env.VUE_APP_BASE_URL}/fno/tracking/assign/${this.deliveryHash}/${this.partnerHash}`,
                {},
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Berhasil di Assign",
                    });
                    this.showModal = false;
                    this.getTransporter();
                    console.log(res);
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
        dateFormat(date) {
            return moment(date).format("DD MMM YYYY");
        },
        clockFormat(clock) {
            return moment(clock).format("HH:MM:SS");
        },
        itemNumber(index) {
            return index + this.startIndex + 1;
        },
    },
    computed: {
        startIndex() {
            return (this.currentPage - 1) * this.limit;
        },
    },
};
</script>

<style scoped>
.scroll {
    height: 300px;
    overflow-y: scroll;
}
.row.foto {
    --bs-gutter-x: 0rem !important;
}
.table > thead {
    border-bottom: 0px solid #fff;
    font-weight: 700;
}
.tr {
    transition: all 0.8s ease-in-out;
    margin-top: 15px;
    transition: all 0.2s ease-in-out;
    height: auto;
}

.table .th,
.table .td {
    padding: 0px 12px;
}

.ttable {
    width: 100%;
    margin-right: 5px;
}

.table {
    border: 0 !important;
}

.td {
    margin-bottom: 10px;
    border-top: none !important;
}

.table .td {
    font-size: 14px;
    background-color: #fff;
}
.table .td:nth-child(odd) {
    background-color: #fff;
}
.border-body {
    border-top: 1.5px solid #f7f7f7;
    border-left: 1.5px solid #f7f7f7;
    border-right: 1.5px solid #f7f7f7;
    border-bottom: 1.5px solid #f7f7f7;
    box-shadow: 0 1px 12px -1px rgb(141 145 162 / 25%);
}
.border-footer {
    border-bottom: 1.5px solid #f7f7f7;
    border-left: 1.5px solid #f7f7f7;
    border-right: 1.5px solid #f7f7f7;
    box-shadow: 0 3px 10px -2px #e9ecef;
}
</style>
