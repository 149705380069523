<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="/trawltruck/ho/master/route"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Tambah Data Rute Kendaraan</h3>
            </div>
            <div class="row mt-5 m-mt-2">
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Kota Asal</label>
                    <multiselect
                        v-model="origin_regency"
                        :options="origins"
                        label="name"
                        :loading="is_load"
                        track-by="id"
                        placeholder="Pilih Kota Asal"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 m-mt-1">
                    <label class="fw-bold mb-2">Kota Tujuan</label>
                    <multiselect
                        v-model="destination_regency"
                        :options="origins"
                        label="name"
                        :loading="is_load"
                        track-by="id"
                        placeholder="Pilih Kota Tujuan"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Jenis Kendaraan</label>
                    <multiselect
                        v-model="fleet"
                        :options="fleets"
                        label="name"
                        :loading="is_fleet_loading"
                        track-by="code"
                        placeholder="Pilih Jenis Kendaraan"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Harga</label>
                    <money
                        v-model="req.price"
                        v-bind="money"
                        class="form-control money-custom"
                    >
                    </money>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="d-flex no-flex">
                        <div class="ms-auto">
                            <a
                                href="/trawltruck/ho/master/route"
                                class="btn btn-outline-black btn-lg px-5 me-3 m-w-100"
                                >Batal</a
                            >
                            <button
                                class="btn btn-green btn-lg px-5 m-mt-1 m-w-100"
                                type="button"
                                disabled
                                v-if="is_save"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Simpan
                            </button>
                            <button
                                class="btn btn-green btn-lg px-5 m-mt-1 m-w-100"
                                @click="storeData()"
                                v-else
                            >
                                Simpan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data Saved!!</h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/trawltruck/ho/master/route"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalError"></div>
            <div class="modal-body-chat vsm" v-if="modalError">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/close.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Penambahan Data Gagal!</h4>
                    <div class="mt-2">
                        Mohon maaf terjadi kesalahan! Silakan ulangi kembali
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="modalError = false"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Money } from "v-money";
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
        Money,
    },
    name: "ManagementMasterRouteCreate",
    data() {
        return {
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "Rp. ",
                suffix: "",
                precision: 0,
                masked: false,
            },
            req: {
                origin_province: "",
                origin_regency: "",
                destination_province: "",
                destination_regency: "",
                fleet: "",
                price: 0,
            },
            modalSuccess: false,
            modalError: false,
            origin_regency: null,
            destination_regency: null,
            origins: [],
            is_load: false,
            fleets: [],
            fleet: null,
            is_fleet_loading: false,
            is_save: false,
        };
    },
    created() {
        this.getGeoList();
        this.getFleet();
    },
    methods: {
        getGeoList() {
            this.is_load = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.origins = data;
                    this.is_load = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load = false;
                });
        },
        getFleet() {
            this.is_fleet_loading = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/trawltruck-fleet`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_fleet_loading = false;
                    var data = res.data.data;
                    this.fleets = data;
                })
                .catch((err) => {
                    this.is_fleet_loading = false;
                    console.log(err);
                });
        },
        storeData() {
            if (this.origin_regency == null) {
                return this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Kota Asal Wajib diisi",
                });
            }
            if (this.destination_regency == null) {
                return this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Kota Tujuan Wajib diisi",
                });
            }
            if (this.fleet == null) {
                return this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Jenis Kendaraan Wajib diisi",
                });
            }
            if (this.req.price == 0) {
                return this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Harga Tidak Boleh 0",
                });
            }
            this.is_save = true;
            var data = {
                origin_regency: this.origin_regency.id,
                destination_regency: this.destination_regency.id,
                fleet: this.fleet.code,
                price: this.req.price,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/pricing`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    this.modalSuccess = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
.money-custom {
    height: 42px;
    font-size: 16px;
    padding-left: 15px;
}
</style>
