<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="mb-3">
                <h3 class="fw-bold capitalize">
                    Leads List - {{ prospect_type.split("_").join(" ") }}
                </h3>
            </div>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-3 m-mx-0">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Search Activity"
                            v-model="search"
                            @input="searchPage()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_load_data">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Follow Up
                                </th>
                                <th class="text-center" scope="col">
                                    Partner Type
                                </th>
                                <th class="text-center" scope="col">
                                    Activity
                                </th>
                                <th class="text-center" scope="col">
                                    Company Name
                                </th>
                                <th class="text-center" scope="col">Branch</th>
                                <th class="text-center" scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in prospect.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td class="text-center">
                                    {{
                                        moment(item.followup_at).format(
                                            "DD/MM/YYYY"
                                        )
                                    }}
                                </td>
                                <td class="capitalize">
                                    <template
                                        v-for="(
                                            product, index
                                        ) in item.partner_type"
                                    >
                                        <small
                                            class="tag green pb-0 pt-0"
                                            :key="index"
                                        >
                                            {{ product }}
                                        </small>
                                    </template>
                                </td>
                                <td class="text-center capitalize">
                                    {{ item.activity ? item.activity : "-" }}
                                </td>
                                <td class="capitalize">
                                    <a
                                        :href="
                                            `/crm/partnership/prospect/detail/` +
                                            item.id
                                        "
                                        class="text-link"
                                    >
                                        {{ item.company ? item.company : "-" }}
                                    </a>
                                </td>
                                <td class="capitalize">
                                    {{
                                        item.branch_name
                                            ? item.branch_name
                                            : "-"
                                    }}
                                </td>
                                <td class="text-center" style="width: 150px">
                                    <a
                                        href="javascript:void(0)"
                                        id="changeStatus"
                                        data-bs-toggle="dropdown"
                                    >
                                        <small
                                            class="tag blue-solid w-100 radius mb-0"
                                        >
                                            Process
                                            <i
                                                class="fa fa-angle-down ms-2"
                                            ></i>
                                        </small>
                                    </a>
                                    <ul
                                        class="dropdown-menu"
                                        aria-labelledby="changeStatus"
                                    >
                                        <li>
                                            <a
                                                href="javascript:void(0)"
                                                @click="openModalJoin(item.id)"
                                                class="dropdown-item"
                                            >
                                                Join
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="javascript:void(0)"
                                                @click="openModalExit(item.id)"
                                                class="dropdown-item"
                                            >
                                                Exit
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-if="prospect.list_data.length == 0"
                    class="text-center mt-section"
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="d-flex" v-if="prospect.list_data.length > 0">
                <div class="ms-auto">
                    <div class="d-flex mt-4">
                        <div class="me-3">
                            <select
                                class="form-control form-select w-select"
                                v-model="limit"
                                @change="getList()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <b-pagination-nav
                            v-model="currentPage"
                            :number-of-pages="prospect.total_page"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="showModalJoin"></div>
            <div class="modal-body-chat sm" v-if="showModalJoin">
                <div class="fw-bold size-18">Join Form</div>
                <div class="mt-4">
                    <label for="" class="fw-semibold">Partner Type</label>
                    <select
                        class="form-control form-select mt-2"
                        v-model="req.partner_type"
                    >
                        <option value="">Select Partner Type</option>
                        <option value="trawlpack">TroPack</option>
                        <option value="trawltruck">TroTruck</option>
                        <option value="trawlcarrier">TroCarrier</option>
                    </select>
                </div>
                <div class="mt-3">
                    <label class="fw-semibold">Partner Code</label>
                    <input
                        type="text"
                        v-model="req.partner_code"
                        :disabled="req.partner_type == ''"
                        class="form-control mt-2"
                        placeholder="Partner Code"
                    />
                </div>
                <div class="mt-4 row">
                    <div class="col-md-6">
                        <button
                            class="btn btn-outline-black btn-lg w-100"
                            @click="cancelJoinModal()"
                        >
                            Cancel
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button
                            class="btn btn-primary btn-lg w-100"
                            @click="storeJoin()"
                            :disabled="
                                req.partner_type == '' ||
                                req.partner_code == '' ||
                                is_store_join
                            "
                        >
                            <span
                                v-if="is_store_join"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="showModalExit"></div>
            <div class="modal-body-chat sm" v-if="showModalExit">
                <div class="fw-bold size-18">Exit Form</div>
                <div class="mt-4">
                    <label class="fw-semibold">Reason</label>
                    <textarea
                        rows="4"
                        cols="10"
                        class="form-control mt-2"
                        placeholder="Reason"
                        v-model="req.reason"
                    ></textarea>
                </div>
                <div class="mt-4 row">
                    <div class="col-md-6">
                        <button
                            class="btn btn-outline-black btn-lg w-100"
                            @click="cancelExitModal()"
                        >
                            Cancel
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button
                            class="btn btn-primary btn-lg w-100"
                            @click="storeExit()"
                            :disabled="req.reason == '' || is_store_exit"
                        >
                            <span
                                v-if="is_store_exit"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Change Status Succeeded!!</h4>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="$router.go(0)"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";

export default {
    components: {
        ListLoader,
    },
    props: {
        items: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            moment: moment,
            prospect_type: "",
            is_load_data: false,
            search: "",
            prospect: {
                list_data: [],
            },
            limit: 10,
            currentPage: 0,
            join_id: null,
            showModalJoin: false,
            req: {
                partner_type: "",
                partner_code: "",
                reason: "",
            },
            is_store_join: false,
            exit_id: null,
            showModalExit: false,
            is_store_exit: false,
            modalSuccess: false,
        };
    },
    created() {
        this.prospect_type = this.items.prospect_type;
        this.getList();
    },
    methods: {
        getList() {
            this.is_load_data = true;
            var params = {
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
                limit: this.limit,
                search: this.search,
                filter: this.prospect_type,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/prospects`,
                {
                    params: params,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.prospect = data;
                    this.is_load_data = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_data = false;
                });
        },
        changePage() {
            this.getList();
        },
        searchPage() {
            this.getList();
            this.currentPage = 0;
        },
        openModalJoin(id) {
            this.showModalJoin = true;
            this.join_id = id;
        },
        cancelJoinModal() {
            this.showModalJoin = false;
            this.req.partner_type = "";
            this.req.partner_code = "";
        },
        openModalExit(id) {
            this.showModalExit = true;
            this.exit_id = id;
        },
        cancelExitModal() {
            this.showModalExit = false;
            this.req.reason = "";
        },
        storeJoin() {
            this.is_store_join = true;
            var data = {
                prospect_id: this.join_id,
                partner_type: this.req.partner_type,
                partner_code: this.req.partner_code,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/prospect/join`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.showModalJoin = false;
                    this.is_store_join = false;
                    this.modalSuccess = true;
                    // window.history.go(0);
                    // this.$notify({
                    //     group: "foo",
                    //     type: "success",
                    //     title: "Success",
                    //     text: "Change Status Succeeded!!",
                    // });
                })
                .catch((err) => {
                    console.log(err);
                    this.is_store_join = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.data.message,
                    });
                });
        },
        storeExit() {
            this.is_store_exit = true;
            var data = {
                prospect_id: this.exit_id,
                reason: this.req.reason,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/prospect/exit`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_store_exit = false;
                    this.showModalExit = false;
                    this.modalSuccess = true;
                    // this.$notify({
                    //     group: "foo",
                    //     type: "success",
                    //     title: "Success",
                    //     text: "Change Status Succeeded!!",
                    // });
                })
                .catch((err) => {
                    console.log(err);
                    this.is_store_exit = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.data.message,
                    });
                });
        },
    },
    computed: {},
};
</script>
<style lang="scss" scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
</style>
