<template>
    <div class="padding-container">
        <div class="content-load" v-if="loading_data"></div>
        <div id="loader" v-if="loading_data"></div>
        <div class="fw-bold">
            <a
                href="/truck/commission/sticker"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Detail Pencairan Komisi</h3>
                <div class="ms-auto">
                    <div
                        class="tag yellow-cro radius mb-0 pb-0"
                        v-if="detail?.status == 'accept'"
                    >
                        Disetujui
                    </div>
                    <div class="tag green-cro radius mb-0 pb-0" v-else>
                        Telah Ditransfer
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nomor Pengajuan Klaim</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Nama driver"
                        disabled
                        :value="detail?.claim_code"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nama Driver</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Nama driver"
                        disabled
                        :value="detail?.driver_name"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Jenis Kendaraan</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Jenis kendaraan"
                        disabled
                        :value="detail?.fleet_type_label"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nomor Kendaraan</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Nomor kendaraan"
                        disabled
                        :value="detail?.registration_number"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Tanggal Pengajuan</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Tanggal pengajuan"
                        disabled
                        :value="detail?.created_at"
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <hr />
                </div>
                <div class="col-md-4">
                    <label class="fw-bold mb-2">Nama Bank</label>
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            disabled
                            :value="detail.bank_account.bank"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="fw-bold mb-2">Rekening Bank</label>
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            disabled
                            :value="detail.bank_account.account_number"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="fw-bold mb-2">Nama Pemilik Rekening </label>
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            disabled
                            :value="detail.bank_account.account_name"
                        />
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <hr />
                </div>
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Kilometer Sebelumnya</label>
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Kilometer sebelumnya"
                            disabled
                            :value="detail?.km_before"
                        />
                        <span class="input-group-text"> Km </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Kilometer Saat Ini</label>
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Kilometer saat ini"
                            disabled
                            :Value="detail?.km_current"
                        />
                        <span class="input-group-text"> Km </span>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold mb-2">Selisih Kilometer</label>
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Selisih kilometer"
                            disabled
                            :value="detail?.km_diff"
                        />
                        <span class="input-group-text"> Km </span>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold mb-2">Persen Komisi</label>
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Persen komisi"
                            disabled
                            :value="detail?.commision_percentage"
                        />
                        <span class="input-group-text"> % </span>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold mb-2">Jumlah Komisi</label>
                    <div class="input-group">
                        <span class="input-group-text"> Rp </span>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Jumlah komisi"
                            disabled
                            :value="detail?.commision_amount"
                        />
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Video Kendaraan</label>
                    <div>
                        <template v-if="detail.url_video">
                        <my-video
                            :sources="[getVideoSource]"
                            :options="options_video"
                        >
                        </my-video>
                        <a
                            class="btn btn-outline-success btn-sm m-2"
                            :href="detail.url_video"
                            download
                            target="_blank"
                        >Link Video</a>
                        </template>
                        <img
                            class="photo"
                            src="../../assets/empty-image.png"
                            v-else
                        />
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Foto KM Kendaraan</label>
                    <div>
                        <template v-if="detail.url_photo">
                            <viewer :images="[]" :options="options">
                                <img
                                    class="photo"
                                    :key="detail.url_photo"
                                    :src="detail.url_photo"
                                />
                            </viewer>
                        </template>
                        <img
                            class="photo"
                            src="../../assets/empty-image.png"
                            v-else
                        />
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <div>
                        <label class="fw-bold mb-2">Bukti Transfer</label>
                    </div>
                    <label
                        type="file"
                        :class="!imagePhoto ? 'upload__btn' : 'pencil'"
                        class="position-relative"
                    >
                        <img
                            v-if="imagePhoto"
                            class="upload_photo"
                            :src="driverPictureUrl"
                        />
                        <p v-else class="mb-0" style="color: #adb5bd">
                            <img
                                src="../../assets/upload-icon.png"
                                class="mb-1"
                                alt=""
                            />
                            <br />
                            Pilih File
                        </p>
                        <input
                            class="cursor-pointer"
                            id="upload"
                            type="file"
                            name="file"
                            ref="file"
                            @change="uploadPhoto"
                        />
                    </label>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <button
                        class="btn btn-green btn-lg px-5"
                        @click="modalConfirmation = true"
                        :disabled="
                            imagePhoto == null ||
                            (detail?.url_proof_transfer != null &&
                                imagePhoto != '')
                        "
                    >
                        Simpan
                    </button>
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <h3 class="fw-bold mb-0">Riwayat Klaim Komisi</h3>
            <div class="position-relative mt-3">
                <input
                    type="text"
                    class="form-control form-control-lg pl-search w-search"
                    placeholder="Cari nomor pengajuan"
                    v-model="claim_code"
                    @input="changeSearch()"
                />
                <span class="search-icon left">
                    <i class="fa fa-search"></i>
                </span>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div class="table-responsive-custom" v-else>
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">
                                Tanggal Pengajuan
                            </th>
                            <th class="text-center" scope="col">
                                No. Pengajuan Klaim
                            </th>
                            <th class="text-center" scope="col">
                                Kilometer Sebelum
                            </th>
                            <th class="text-center" scope="col">
                                Kilometer Saat Ini
                            </th>
                            <th class="text-center" scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(data, index) in list.list_data"
                            :key="index"
                        >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">{{ data.created_at }}</td>
                            <td class="text-center">{{ data.claim_code }}</td>
                            <td class="text-center">{{ data.km_before }} Km</td>
                            <td class="text-center">
                                {{ data.km_current }} Km
                            </td>
                            <td class="text-center">
                                <span
                                    class="tag yellow-cro radius mb-0"
                                    v-if="data.status == 'accept'"
                                >
                                    Disetujui
                                </span>
                                <span
                                    class="tag green-cro radius mb-0 pb-0"
                                    v-else
                                >
                                    Telah Ditransfer
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center mt-5" v-if="list.list_data.length == 0">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="mt-4">
                <b-pagination-nav
                    v-model="currentPage"
                    v-if="list.list_data.length > 0"
                    :number-of-pages="list.total_page"
                    base-url="#"
                    first-number
                    align="right"
                    @input="getHistory"
                ></b-pagination-nav>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalConfirmation"></div>
            <div class="modal-body-chat vsm" v-if="modalConfirmation">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Bukti Transfer Akan Dikirimkan
                    </h4>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin mengirimkan bukti transfer
                        kepada driver ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirmation = false"
                            class="btn btn-outline-red w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-green w-100 btn-lg"
                            :disabled="is_store"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_store"
                            ></span>
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Bukti Transfer Sudah Dikirim
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/truck/commission/sticker"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
import noImage from "../../assets/empty-image.png";
import ListLoader from "../../components/ListLoader.vue";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";
import myVideo from "vue-video";

export default {
    components: {
        ListLoader,
        Viewer,
        myVideo,
    },
    name: "TruckCommissionStickerDetail",
    data() {
        return {
            moment: moment,
            loading_data: false,
            detail: {
                bank_account: {},
            },
            modalConfirmation: false,
            modalSuccess: false,
            is_store: false,
            imagePhoto: "",
            photo: "",
            claim_code: "",
            is_list_ready: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            limit: 10,
            currentPage: 0,
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
            options_video: {
                autoplay: false,
                volume: 0.6,
                poster: "http://covteam.u.qiniudn.com/poster.png",
            },
        };
    },
    created() {
        this.getDetail();
        this.getHistory();
    },
    computed: {
        driverPictureUrl() {
            return this.imagePhoto.length > 0 ? this.imagePhoto : noImage;
        },
        getVideoSource() {
            return {
                src: this.detail.url_video,
                type: "video/mp4",
            };
        },
    },
    methods: {
        getDetail() {
            this.loading_data = true;
            var payload = {
                claim_id: this.$route.params.id,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/truck/head-office/disbursment/commission/detail`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detail = res.data.data;
                    this.imagePhoto = res.data.data.url_proof_transfer;
                    this.loading_data = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.loading_data = false;
                });
        },
        getHistory() {
            this.is_list_ready = true;
            let payload = {
                search: this.claim_code,
                limit: this.limit,
                claim_id: this.$route.params.id,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/truck/head-office/disbursment/commission/detail/history`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        changeSearch() {
            this.currentPage = 0;
            this.getHistory();
        },
        storeData() {
            var data = new FormData();
            data.append("claim_id", this.$route.params.id);
            if (this.photo) {
                data.append("photo", this.photo);
            }
            this.is_store = true;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/truck/head-office/disbursment/commission/upload`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_store = false;
                    console.log(res);
                    this.modalConfirmation = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.is_store = false;
                    this.modalConfirmation = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        uploadPhoto(event) {
            var input = event.target;
            this.photo = input.files[0];
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.imagePhoto = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
    },
};
</script>

<style scoped>
.photo {
    border-radius: 10px;
    width: 100%;
    height: 200px;
    object-fit: cover;
    border: 1px solid #e9ecef;
    cursor: pointer;
}
.video {
    border-radius: 10px;
    width: 100%;
    height: 200px;
    border: 1px solid #e9ecef;
}
.input-group-text {
    border: 1px solid #e9ecef;
    background-color: #f3f5f7;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    border: dashed;
    border-radius: 10px;
    width: 100%;
    height: 200px;
    padding: 65px;
    position: relative;
}
.upload_photo {
    border-radius: 10px;
    cursor: pointer;
    width: 33.7vw;
    height: 200px;
    object-fit: cover;
    border: dashed;
    color: #dee2e6;
}
input[type="file"] {
    display: none;
}
.upload__btn p img {
    cursor: pointer;
    width: 35px;
    font-size: 14px;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
</style>
