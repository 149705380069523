<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/master/user" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div
            class="accordion mt-4"
            id="bassicInfo"
            v-if="!checkRoleExists('ho')"
        >
            <div class="accordion-item">
                <h2 class="accordion-header" id="bassicInfo1">
                    <button
                        class="accordion-button size-16 fw-bold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                    >
                        <template v-if="checkRoleExists('crm')">
                            Detail Informasi User CRM
                        </template>
                        <template v-else> Detail Informasi Basic </template>
                    </button>
                </h2>
                <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="bassicInfo1"
                    data-bs-parent="#bassicInfo"
                >
                    <div class="accordion-body">
                        <div class="box no-shadow border-gray mb-2">
                            <h5 class="mb-0 fw-bold">Informasi Basic</h5>
                            <center>
                                <div
                                    class="avatar-image mt-3"
                                    v-if="detail.avatar"
                                    :style="{
                                        'background-image':
                                            'url(' + detail.avatar + ')',
                                    }"
                                ></div>
                                <div
                                    class="avatar-image mt-3"
                                    v-else
                                    :style="{
                                        backgroundImage: `url(${require('../assets/user-dummy.png')})`,
                                    }"
                                ></div>
                            </center>
                            <div class="row">
                                <div class="col-md-6 mt-3">
                                    <label class="fw-bold mb-2">Nama</label>
                                    <div class="form-box bg-white">
                                        {{ detail.name ?? "-" }}
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label class="fw-bold mb-2">Username</label>
                                    <div class="form-box bg-white">
                                        {{ detail.username ?? "-" }}
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label class="fw-bold mb-2"
                                        >Nomor Telepon</label
                                    >
                                    <div class="form-box bg-white">
                                        {{ detail.phone ?? "-" }}
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label class="fw-bold mb-2">Email</label>
                                    <div class="form-box bg-white">
                                        {{ detail.email ?? "-" }}
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label class="fw-bold mb-2"
                                        >Tanggal Bergabung</label
                                    >
                                    <div class="form-box bg-white">
                                        {{ detail.created_at ?? "-" }}
                                    </div>
                                </div>
                                <template v-if="checkRoleExists('crm')">
                                    <div class="col-md-6 mt-3">
                                        <label class="fw-bold mb-2"
                                            >Gender</label
                                        >
                                        <div class="form-box bg-white">
                                            <template
                                                v-if="
                                                    detail.meta.crm.gender ==
                                                    'male'
                                                "
                                            >
                                                Laki-Laki
                                            </template>
                                            <template v-else
                                                >Perempuan</template
                                            >
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label class="fw-bold mb-2"
                                            >Cabang</label
                                        >
                                        <div class="form-box bg-white">
                                            {{ detail.meta.crm.address }}
                                        </div>
                                    </div>
                                </template>
                                <div class="col-md-6 mt-3">
                                    <label class="fw-bold mb-2">Role</label>
                                    <div class="form-box bg-white">
                                        <template
                                            v-if="detail.roles.length > 0"
                                        >
                                            <div
                                                v-for="(
                                                    role, index
                                                ) in detail.roles"
                                                :key="index"
                                                class="tag radius px-2 size-12 mb-0"
                                                v-bind:class="{
                                                    'green-cro':
                                                        role.group == 'crm',
                                                    'red-cro':
                                                        role.group == 'pack',
                                                    'yellow-cro':
                                                        role.group ==
                                                            'truck_c' ||
                                                        role.group == 'truck_i',
                                                    'blue-cro':
                                                        role.group == 'carrier',
                                                    'purple-cro':
                                                        role.group == 'ho',
                                                }"
                                            >
                                                {{ role.label }}
                                            </div>
                                        </template>
                                        <template v-else> - </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion mt-4" id="HO" v-if="checkRoleExists('ho')">
            <div class="accordion-item">
                <h2 class="accordion-header" id="HO1">
                    <button
                        class="accordion-button size-16 fw-bold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                    >
                        Detail Informasi User Head Office
                    </button>
                </h2>
                <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="HO1"
                    data-bs-parent="#HO"
                >
                    <div class="accordion-body">
                        <div class="box no-shadow border-gray mb-2">
                            <h5 class="mb-0 fw-bold">Informasi Basic</h5>
                            <center>
                                <div
                                    class="avatar-image mt-3"
                                    v-if="detail.avatar"
                                    :style="{
                                        'background-image':
                                            'url(' + detail.avatar + ')',
                                    }"
                                ></div>
                                <div
                                    class="avatar-image mt-3"
                                    v-else
                                    :style="{
                                        backgroundImage: `url(${require('../assets/user-dummy.png')})`,
                                    }"
                                ></div>
                                <template v-if="detail.roles.length > 0">
                                    <div
                                        v-for="(role, index) in detail.roles"
                                        :key="index"
                                        class="tag radius px-2 size-12 mb-0 mt-2"
                                        v-bind:class="{
                                            'green-cro': role.group == 'crm',
                                            'red-cro': role.group == 'pack',
                                            'yellow-cro':
                                                role.group == 'truck_c' ||
                                                role.group == 'truck_i',
                                            'blue-cro': role.group == 'carrier',
                                            'purple-cro': role.group == 'ho',
                                        }"
                                    >
                                        {{ role.label }}
                                    </div>
                                </template>
                            </center>
                            <div class="row">
                                <div class="col-md-6 mt-3">
                                    <label class="fw-bold mb-2">Nama</label>
                                    <div class="form-box bg-white">
                                        {{ detail.name ?? "-" }}
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label class="fw-bold mb-2">Username</label>
                                    <div class="form-box bg-white">
                                        {{ detail.username ?? "-" }}
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label class="fw-bold mb-2"
                                        >Nomor Telepon</label
                                    >
                                    <div class="form-box bg-white">
                                        {{ detail.phone ?? "-" }}
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label class="fw-bold mb-2">Email</label>
                                    <div class="form-box bg-white">
                                        {{ detail.email ?? "-" }}
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label class="fw-bold mb-2"
                                        >Tanggal Bergabung</label
                                    >
                                    <div class="form-box bg-white">
                                        {{ detail.created_at ?? "-" }}
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label class="fw-bold mb-2">Role</label>
                                    <div class="form-box bg-white">
                                        <template
                                            v-if="detail.roles.length > 0"
                                        >
                                            <div
                                                v-for="(
                                                    role, index
                                                ) in detail.roles"
                                                :key="index"
                                                class="tag radius px-2 size-12 mb-0"
                                                v-bind:class="{
                                                    'green-cro':
                                                        role.group == 'crm',
                                                    'red-cro':
                                                        role.group == 'pack',
                                                    'yellow-cro':
                                                        role.group ==
                                                            'truck_c' ||
                                                        role.group == 'truck_i',
                                                    'blue-cro':
                                                        role.group == 'carrier',
                                                    'purple-cro':
                                                        role.group == 'ho',
                                                }"
                                            >
                                                {{ role.label }}
                                            </div>
                                        </template>
                                        <template v-else> - </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion mt-4" id="productCollapseGeneral">
            <div
                class="accordion-item"
                v-if="detail.meta.pack && checkRoleExists('pack')"
            >
                <h2 class="accordion-header" id="productTropack">
                    <button
                        class="accordion-button size-16 fw-bold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTropack"
                        aria-expanded="true"
                        aria-controls="collapseTropack"
                    >
                        Detail Informasi User Troben Cargo
                    </button>
                </h2>
                <div
                    id="collapseTropack"
                    class="accordion-collapse collapse show"
                    aria-labelledby="productTropack"
                    data-bs-parent="#productCollapseGeneral"
                >
                    <div class="accordion-body">
                        <template v-for="(role_card, index) in detail.roles">
                            <div
                                class="box no-shadow border-gray mb-2"
                                v-if="
                                    role_card.group != 'ho' &&
                                    role_card.group != 'crm' &&
                                    role_card.group != 'carrier' &&
                                    role_card.group != 'truck_c' &&
                                    role_card.group != 'truck_i' &&
                                    role_card.role != 'trawlpack-partner-owner'
                                "
                                :key="index"
                            >
                                <h5 class="mb-0 fw-bold">
                                    Informasi Sub: {{ role_card.label }}
                                </h5>
                                <div class="row">
                                    <div class="col-md-6 mt-3">
                                        <label class="fw-bold mb-2"
                                            >Kode Mitra</label
                                        >
                                        <div class="form-box bg-white">
                                            {{
                                                detail.meta.pack.partners
                                                    .code ?? "-"
                                            }}
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label class="fw-bold mb-2"
                                            >Kecamatan/Kabupaten/Kota</label
                                        >
                                        <div class="form-box bg-white">
                                            {{
                                                detail.meta.pack.partners
                                                    .address ?? "-"
                                            }}
                                        </div>
                                    </div>
                                    <div
                                        class="col-md-12 mt-3"
                                        v-if="
                                            role_card.role ==
                                                'trawlpack-partner-driver' ||
                                            role_card.role ==
                                                'trawlpack-partner-owner-transporter' ||
                                            role_card.role ==
                                                'trawlpack-partner-owner-business'
                                        "
                                    >
                                        <label class="fw-bold mb-2"
                                            >Jenis Armada</label
                                        >
                                        <div
                                            class="row"
                                            v-if="
                                                detail.meta.pack.fleets.length >
                                                0
                                            "
                                        >
                                            <div
                                                class="col-md-6 mb-2"
                                                v-for="(fleet, index) in detail
                                                    .meta.pack.fleets"
                                                :key="index"
                                            >
                                                <div class="form-box bg-white">
                                                    <div class="capitalize">
                                                        {{ fleet }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" v-else>
                                            <div class="col-md-6 mb-2">
                                                <div class="form-box bg-white">
                                                    <div class="capitalize">
                                                        -
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div
                class="accordion-item"
                v-if="detail.meta.truck_c && checkRoleExists('truck_c')"
            >
                <h2 class="accordion-header" id="productTruckC">
                    <button
                        class="accordion-button"
                        v-bind:class="{
                            collapsed: detail.meta.pack != null,
                        }"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTruckC"
                        aria-expanded="true"
                        aria-controls="collapseTruckC"
                    >
                        <div class="d-flex align-items-center">
                            <div class="size-16 fw-bold">
                                Detail Informasi User Troben Truk
                            </div>
                            <div class="tag yellow-cro ms-3 mb-0 radius">
                                Corporate
                            </div>
                        </div>
                    </button>
                </h2>
                <div
                    id="collapseTruckC"
                    class="accordion-collapse collapse"
                    v-bind:class="{
                        show: detail.meta.pack == null,
                    }"
                    aria-labelledby="productTruckC"
                    data-bs-parent="#productCollapseGeneral"
                >
                    <div class="accordion-body">
                        <template v-for="(role_card, index) in detail.roles">
                            <div
                                class="box no-shadow border-gray mb-2"
                                :key="index"
                                v-if="role_card.group == 'truck_c'"
                            >
                                <h5 class="mb-0 fw-bold">Informasi Sub</h5>
                                <div class="row">
                                    <div class="col-md-6 mt-3">
                                        <label class="fw-bold mb-2"
                                            >Kode Mitra</label
                                        >
                                        <div class="form-box bg-white">
                                            {{
                                                detail.meta.truck_c.partners
                                                    .code ?? "-"
                                            }}
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label class="fw-bold mb-2"
                                            >Nama Mitra</label
                                        >
                                        <div
                                            class="form-box bg-white capitalize"
                                        >
                                            {{
                                                detail.meta.truck_c.partners
                                                    .name ?? "-"
                                            }}
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                        <label class="fw-bold mb-2"
                                            >Alamat Domisili</label
                                        >
                                        <div
                                            class="form-box bg-white h-textarea"
                                        >
                                            {{
                                                detail.meta.truck_c.partners
                                                    .domicile_address
                                                    ? detail.meta.truck_c
                                                          .partners
                                                          .domicile_address
                                                    : "-"
                                            }}
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6 mt-3">
                                        <label class="fw-bold mb-2"
                                            >Alamat KTP</label
                                        >
                                        <div class="form-box bg-white">
                                            {{
                                                detail.meta.truck_c.partners
                                                    .identity_address
                                                    ? detail.meta.truck_c
                                                          .partners
                                                          .identity_address
                                                    : "-"
                                            }}
                                        </div>
                                    </div> -->
                                    <div
                                        class="col-md-12 mt-3"
                                        v-if="
                                            role_card.role ==
                                                'trawltruck-partner-owner' ||
                                            role_card.role ==
                                                'trawltruck-driver-partner'
                                        "
                                    >
                                        <label class="fw-bold mb-2"
                                            >Jenis Armada</label
                                        >
                                        <div
                                            class="row"
                                            v-if="
                                                detail.meta.truck_c.fleets
                                                    .length > 0
                                            "
                                        >
                                            <div
                                                class="col-md-6 mb-2"
                                                v-for="(fleet, index) in detail
                                                    .meta.truck_c.fleets"
                                                :key="index"
                                            >
                                                <div class="form-box bg-white">
                                                    <div class="capitalize">
                                                        {{ fleet }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" v-else>
                                            <div class="col-md-6 mb-2">
                                                <div class="form-box bg-white">
                                                    <div class="capitalize">
                                                        -
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div
                class="accordion-item"
                v-if="detail.meta.truck_i && checkRoleExists('truck_i')"
            >
                <h2 class="accordion-header" id="productTruckC">
                    <button
                        class="accordion-button"
                        v-bind:class="{
                            collapsed: detail.meta.pack != null,
                        }"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTruckC"
                        aria-expanded="true"
                        aria-controls="collapseTruckC"
                    >
                        <div class="d-flex align-items-center">
                            <div class="size-16 fw-bold">
                                Detail Informasi User Troben Truk
                            </div>
                            <div class="tag yellow-cro ms-3 mb-0 radius">
                                Individu
                            </div>
                        </div>
                    </button>
                </h2>
                <div
                    id="collapseTruckC"
                    class="accordion-collapse collapse"
                    v-bind:class="{
                        show: detail.meta.pack == null,
                    }"
                    aria-labelledby="productTruckC"
                    data-bs-parent="#productCollapseGeneral"
                >
                    <div class="accordion-body">
                        <template v-for="(role_card, index) in detail.roles">
                            <div
                                class="box no-shadow border-gray mb-2"
                                :key="index"
                                v-if="role_card.group == 'truck_i'"
                            >
                                <h5 class="mb-0 fw-bold">Informasi Sub</h5>
                                <div class="row">
                                    <div class="col-md-6 mt-3">
                                        <label class="fw-bold mb-2"
                                            >Alamat Domisili</label
                                        >
                                        <div
                                            class="form-box bg-white h-textarea"
                                        >
                                            {{
                                                detail.meta.truck_i.partners
                                                    .domicile_address
                                                    ? detail.meta.truck_i
                                                          .partners
                                                          .domicile_address
                                                    : "-"
                                            }}
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label class="fw-bold mb-2"
                                            >Alamat KTP</label
                                        >
                                        <div
                                            class="form-box bg-white h-textarea"
                                        >
                                            {{
                                                detail.meta.truck_i.partners
                                                    .identity_address
                                                    ? detail.meta.truck_i
                                                          .partners
                                                          .identity_address
                                                    : "-"
                                            }}
                                        </div>
                                    </div>
                                    <div
                                        class="col-md-12 mt-3"
                                        v-if="
                                            role_card.role ==
                                            'trawltruck-driver-individual'
                                        "
                                    >
                                        <label class="fw-bold mb-2"
                                            >Jenis Armada</label
                                        >
                                        <div
                                            class="row"
                                            v-if="
                                                detail.meta.truck_i.fleets
                                                    .length > 0
                                            "
                                        >
                                            <div
                                                class="col-md-6 mb-2"
                                                v-for="(fleet, index) in detail
                                                    .meta.truck_i.fleets"
                                                :key="index"
                                            >
                                                <div class="form-box bg-white">
                                                    <div class="capitalize">
                                                        {{ fleet }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" v-else>
                                            <div class="col-md-6 mb-2">
                                                <div class="form-box bg-white">
                                                    <div class="capitalize">
                                                        -
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div
                class="accordion-item"
                v-if="detail.meta.carrier && checkRoleExists('carrier')"
            >
                <h2 class="accordion-header" id="productCarrier">
                    <button
                        class="accordion-button"
                        v-bind:class="{
                            collapsed: detail.meta.pack != null,
                        }"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseCarrier"
                        aria-expanded="true"
                        aria-controls="collapseCarrier"
                    >
                        <div class="d-flex align-items-center">
                            <div class="size-16 fw-bold">
                                Detail Informasi User Troben Carier
                            </div>
                        </div>
                    </button>
                </h2>
                <div
                    id="collapseCarrier"
                    class="accordion-collapse collapse"
                    v-bind:class="{
                        show: detail.meta.pack == null,
                    }"
                    aria-labelledby="productCarrier"
                    data-bs-parent="#productCollapseGeneral"
                >
                    <div class="accordion-body">
                        <template v-for="(role_card, index) in detail.roles">
                            <div
                                class="box no-shadow border-gray mb-2"
                                :key="index"
                                v-if="role_card.group == 'carrier'"
                            >
                                <h5 class="mb-0 fw-bold">Informasi Sub</h5>
                                <div class="row">
                                    <div class="col-md-6 mt-3">
                                        <label class="fw-bold mb-2"
                                            >Nama Perusahaan</label
                                        >
                                        <div class="form-box bg-white">
                                            {{
                                                detail.meta.carrier.partners
                                                    .name
                                                    ? detail.meta.carrier
                                                          .partners.name
                                                    : "-"
                                            }}
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label class="fw-bold mb-2"
                                            >Tahun Berdiri</label
                                        >
                                        <div class="form-box bg-white">
                                            {{
                                                detail.meta.carrier.partners
                                                    .year
                                                    ? detail.meta.carrier
                                                          .partners.year
                                                    : "-"
                                            }}
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label class="fw-bold mb-2"
                                            >Nomor Induk Perusahaan</label
                                        >
                                        <div class="form-box bg-white">
                                            {{
                                                detail.meta.carrier.partners.nip
                                                    ? detail.meta.carrier
                                                          .partners.nip
                                                    : "-"
                                            }}
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label class="fw-bold mb-2">NPWP</label>
                                        <div class="input-group mb-3">
                                            <span class="input-group-text">
                                                <img
                                                    src="../assets/protect-green.png"
                                                    width="13"
                                                    v-if="
                                                        detail.meta.carrier
                                                            .partners
                                                            .is_uploaded_npwp
                                                    "
                                                    alt=""
                                                />
                                                <img
                                                    src="../assets/protect-red.png"
                                                    width="13"
                                                    v-else
                                                    alt=""
                                                />
                                            </span>
                                            <input
                                                type="text"
                                                class="form-control"
                                                disabled
                                                :value="
                                                    detail.meta.carrier.partners
                                                        .is_uploaded_npwp ==
                                                    true
                                                        ? 'Sudah Diupload'
                                                        : 'Belum Diupload'
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";

export default {
    name: "MasterUserDetail",
    components: {},
    data() {
        return {
            detail: {
                roles: [],
                meta: {
                    pack: {
                        partners: {},
                        fleets: {},
                    },
                    carrier: {},
                    crm: {},
                    ho: {},
                    truck_c: {
                        fleets: [],
                        partners: {},
                    },
                    truck_i: {},
                },
            },
        };
    },
    created() {
        this.getDetail();
    },
    computed: {},
    methods: {
        getDetail() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/general/master-data/user/detail?user_id=${this.$route.params.id}`,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detail = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        checkRoleExists(name) {
            return this.detail.roles.some((role) => role.group === name);
        },
    },
};
</script>

<style lang="scss" scoped>
.avatar-image {
    width: 70px;
    height: 70px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    border: 2px solid #e9ecef;
}
.accordion-button:not(.collapsed) {
    background-color: #bed7b6 !important;
    margin-bottom: 0px !important;
    border: 0px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.accordion-body {
    border: 2px solid #f4f6f7 !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.accordion-button {
    border: 2px solid #f4f6f7 !important;
}
.form-control:disabled {
    background-color: #fff !important;
    color: #000 !important;
}
.tag {
    padding: 0px 10px 0px 10px !important;
}
</style>
