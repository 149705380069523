<template>
    <div class="padding-container">
        <div class="d-flex align-items-center no-flex">
            <h3 class="mb-0 fw-bold">Dashboard Finance</h3>
            <div class="ms-auto">
                <button
                    class="btn btn-green px-4 me-3 m-mt-1"
                    @click="showModalFilter()"
                >
                    Filter Pendapatan Mitra
                </button>
                <button
                    class="btn btn-outline-black px-4 m-mt-1"
                    type="button"
                    disabled
                    v-if="is_data_filter"
                >
                    <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    Clear Filter
                </button>
                <button
                    class="btn btn-outline-black px-4 m-mt-1"
                    @click="clearFilter()"
                    v-else
                    :disabled="
                        req.month == '' &&
                        req.province.id == '' &&
                        req.district.id == ''
                    "
                >
                    Clear Filter
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9 mt-4">
                <div class="box">
                    <div class="d-flex align-items-center">
                        <div class="me-3">
                            <img
                                src="../../assets/icon-m.png"
                                width="43"
                                alt=""
                            />
                        </div>
                        <div>
                            <div class="text-gray">Total Pendapatan</div>
                            <div class="fw-bold size-16">Seluruh Mitra</div>
                        </div>
                        <div class="ms-auto">
                            <span
                                class="tag yellow px-3 full-radius"
                                v-if="req.month"
                            >
                                {{ moment(req.month).format("MMMM YYYY") }}
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mt-3">
                            <div class="text-gray">Pendapatan Sekarang</div>
                            <div class="fw-bold">
                                <div class="size-16">
                                    {{
                                        currencyFormat(
                                            dashboard_data.income_total.total[1]
                                                ?.income
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-3">
                            <div class="text-gray">Pendapatan Sebelumnya</div>
                            <div class="fw-bold">
                                <div class="size-16">
                                    {{
                                        currencyFormat(
                                            dashboard_data.income_total.total[0]
                                                ?.income
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-3">
                            <div class="text-gray">Peningkatan Pendapatan</div>
                            <div
                                class="fw-bold size-16"
                                :class="{
                                    'text-danger': true,
                                    'text-green':
                                        dashboard_data.income_total.total[1]
                                            ?.income >=
                                        dashboard_data.income_total.total[0]
                                            ?.income,
                                }"
                            >
                                <template
                                    v-if="
                                        dashboard_data.income_total.total[1]
                                            ?.income == 0 &&
                                        dashboard_data.income_total.total[0]
                                            ?.income == 0
                                    "
                                >
                                    0%
                                </template>
                                <template v-else>
                                    {{
                                        currencyFormat(
                                            dashboard_data.income_total.total[1]
                                                ?.income -
                                                dashboard_data.income_total
                                                    .total[0]?.income
                                        )
                                    }}
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 mt-4">
                <div class="box">
                    <div class="d-flex align-items-center">
                        <div class="me-3">
                            <img
                                src="../../assets/cart-icon.png"
                                width="43"
                                alt=""
                            />
                        </div>
                        <div>
                            <div class="text-gray">Pendapatan</div>
                            <div class="fw-bold size-16">Mitra Business</div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-3">
                        <div>
                            <div class="text-gray">Pendapatan</div>
                            <div class="fw-bold">
                                <template
                                    v-for="(income, index) in dashboard_data
                                        .income_total.business"
                                >
                                    <div class="size-16" :key="index">
                                        <template
                                            v-if="
                                                income.caption == 'this_month'
                                            "
                                        >
                                            {{ currencyFormat(income.income) }}
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="ms-auto">
                            <div class="text-gray">Peningkatan</div>
                            <div
                                class="fw-bold size-16"
                                :class="{
                                    'text-danger': true,
                                    'text-green':
                                        dashboard_data.income_total.business[1]
                                            ?.income >=
                                        dashboard_data.income_total.business[0]
                                            ?.income,
                                }"
                            >
                                {{
                                    currencyFormat(
                                        dashboard_data.income_total.business[1]
                                            ?.income -
                                            dashboard_data.income_total
                                                .business[0]?.income
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 mt-4">
                <div class="box">
                    <div class="d-flex align-items-center">
                        <div class="me-3">
                            <img
                                src="../../assets/icon-mitra-space.png"
                                width="43"
                                alt=""
                            />
                        </div>
                        <div>
                            <div class="text-gray">Pendapatan</div>
                            <div class="fw-bold size-16">Mitra Space</div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-3">
                        <div>
                            <div class="text-gray">Pendapatan</div>
                            <div class="fw-bold">
                                <template
                                    v-for="(income, index) in dashboard_data
                                        .income_total.space"
                                >
                                    <div class="size-16" :key="index">
                                        <template
                                            v-if="
                                                income.caption == 'this_month'
                                            "
                                        >
                                            {{ currencyFormat(income.income) }}
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="ms-auto">
                            <div class="text-gray">Peningkatan</div>
                            <div
                                class="fw-bold size-16"
                                :class="{
                                    'text-danger': true,
                                    'text-green':
                                        dashboard_data.income_total.space[1]
                                            ?.income >=
                                        dashboard_data.income_total.space[0]
                                            ?.income,
                                }"
                            >
                                {{
                                    currencyFormat(
                                        dashboard_data.income_total.space[1]
                                            ?.income -
                                            dashboard_data.income_total.space[0]
                                                ?.income
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 mt-4">
                <div class="box">
                    <div class="d-flex align-items-center">
                        <div class="me-3">
                            <img
                                src="../../assets/icon-mitra-warehouse.png"
                                width="43"
                                alt=""
                            />
                        </div>
                        <div>
                            <div class="text-gray">Pendapatan</div>
                            <div class="fw-bold size-16">Mitra Warehouse</div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-3">
                        <div>
                            <div class="text-gray">Pendapatan</div>
                            <div class="fw-bold">
                                <template
                                    v-for="(income, index) in dashboard_data
                                        .income_total.warehouse"
                                >
                                    <div class="size-16" :key="index">
                                        <template
                                            v-if="
                                                income.caption == 'this_month'
                                            "
                                        >
                                            {{ currencyFormat(income.income) }}
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="ms-auto">
                            <div class="text-gray">Peningkatan</div>
                            <div
                                class="fw-bold size-16"
                                :class="{
                                    'text-danger': true,
                                    'text-green':
                                        dashboard_data.income_total.warehouse[1]
                                            ?.income >=
                                        dashboard_data.income_total.warehouse[0]
                                            ?.income,
                                }"
                            >
                                {{
                                    currencyFormat(
                                        dashboard_data.income_total.warehouse[1]
                                            ?.income -
                                            dashboard_data.income_total
                                                .warehouse[0]?.income
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 mt-4">
                <div class="box">
                    <div class="d-flex align-items-center">
                        <div class="me-3">
                            <img
                                src="../../assets/icon-mitra-transporter.png"
                                width="43"
                                alt=""
                            />
                        </div>
                        <div>
                            <div class="text-gray">Pendapatan</div>
                            <div class="fw-bold size-16">Mitra Transporter</div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-3">
                        <div>
                            <div class="text-gray">Pendapatan</div>
                            <div class="fw-bold">
                                <template
                                    v-for="(income, index) in dashboard_data
                                        .income_total.transporter"
                                >
                                    <div class="size-16" :key="index">
                                        <template
                                            v-if="
                                                income.caption == 'this_month'
                                            "
                                        >
                                            {{ currencyFormat(income.income) }}
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="ms-auto">
                            <div class="text-gray">Peningkatan</div>
                            <div
                                class="fw-bold size-16"
                                :class="{
                                    'text-danger': true,
                                    'text-green':
                                        dashboard_data.income_total
                                            .transporter[1]?.income >=
                                        dashboard_data.income_total
                                            .transporter[0]?.income,
                                }"
                            >
                                {{
                                    currencyFormat(
                                        dashboard_data.income_total
                                            .transporter[1]?.income -
                                            dashboard_data.income_total
                                                .transporter[0]?.income
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 mt-4">
                <div class="box">
                    <div class="d-flex align-items-center">
                        <div class="me-3">
                            <img
                                src="../../assets/icon-mitra-pos.png"
                                width="43"
                                alt=""
                            />
                        </div>
                        <div>
                            <div class="text-gray">Pendapatan</div>
                            <div class="fw-bold size-16">Mitra Pos</div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-3">
                        <div>
                            <div class="text-gray">Pendapatan</div>
                            <div class="fw-bold">
                                <template
                                    v-for="(income, index) in dashboard_data
                                        .income_total.pos"
                                >
                                    <div class="size-16" :key="index">
                                        <template
                                            v-if="
                                                income.caption == 'this_month'
                                            "
                                        >
                                            {{ currencyFormat(income.income) }}
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="ms-auto">
                            <div class="text-gray">Peningkatan</div>
                            <div
                                class="fw-bold size-16"
                                :class="{
                                    'text-danger': true,
                                    'text-green':
                                        dashboard_data.income_total.pos[1]
                                            ?.income >=
                                        dashboard_data.income_total.pos[0]
                                            ?.income,
                                }"
                            >
                                {{
                                    currencyFormat(
                                        dashboard_data.income_total.pos[1]
                                            ?.income -
                                            dashboard_data.income_total.pos[0]
                                                ?.income
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 mt-4">
                <div class="row">
                    <div class="col-md-8 mt-1">
                        <h4 class="mb-0 fw-bold">Filter Mitra</h4>
                    </div>
                    <div class="col-md-4 m-mt-1">
                        <select
                            class="form-select custom-color-default"
                            v-model="params.partner_type"
                            @change="getDatas()"
                        >
                            <option value="">Pilih mitra</option>
                            <option value="business">Mitra Business</option>
                            <option value="space">Mitra Space</option>
                            <option value="transporter">
                                Mitra Transporter
                            </option>
                            <option value="pos">Mitra Pos</option>
                            <option value="warehouse">
                                Mitra Pool Warehouse
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="col-md-7">
                <div class="box mt-4">
                    <div class="d-flex align-items-center">
                        <div class="mb-0 fw-bold size-18 capitalize">
                            Pendapatan Mitra {{ params.partner_type }}
                        </div>
                        <div class="ms-auto">
                            <span
                                class="tag yellow px-3 full-radius"
                                v-if="req.month"
                            >
                                {{ moment(req.month).format("MMMM YYYY") }}
                            </span>
                        </div>
                    </div>
                    <div class="mt-3">
                        <apexcharts
                            ref="chartmonthly"
                            width="100%"
                            height="350"
                            type="bar"
                            :options="chartOptions"
                            :series="series"
                        ></apexcharts>
                    </div>
                </div>
                <div class="box mt-4">
                    <div class="d-flex align-items-center no-flex">
                        <div class="mb-0 fw-bold size-18 capitalize">
                            Pendapatan Mitra {{ params.partner_type }}
                        </div>
                        <div class="ms-auto m-mt-1">
                            <date-picker
                                v-model="req.date_current"
                                type="date"
                                value-type="format"
                                class="m-w-100"
                                format="DD MMMM YYYY"
                                :clearable="false"
                                placeholder="Masukkan tanggal"
                                style="width: 200px"
                                :disabled-date="notBeforeToday"
                                @change="getDatas()"
                            >
                            </date-picker>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="row">
                        <div class="col-md-6 mt-2">
                            <div
                                class="box no-shadow green-thin-box text-center"
                            >
                                <template
                                    v-for="(
                                        income_mitra, index
                                    ) in dashboard_data.income_this_day"
                                >
                                    <div class="fw-bold size-24" :key="index">
                                        <template
                                            v-if="
                                                income_mitra.caption ==
                                                'this_day'
                                            "
                                        >
                                            {{
                                                currencyFormat(
                                                    income_mitra.income
                                                )
                                            }}
                                        </template>
                                    </div>
                                </template>
                                <div class="size-16">Pendapatan Hari Ini</div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-2">
                            <div
                                class="box no-shadow orange-thin-box text-center"
                            >
                                <template
                                    v-for="(
                                        income_mitra, index
                                    ) in dashboard_data.income_this_day"
                                >
                                    <div class="fw-bold size-24" :key="index">
                                        <template
                                            v-if="
                                                income_mitra.caption ==
                                                'last_day'
                                            "
                                        >
                                            {{
                                                currencyFormat(
                                                    income_mitra.income
                                                )
                                            }}
                                        </template>
                                    </div>
                                </template>
                                <div class="size-16">
                                    Pendapatan Hari Sebelumnya
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box bc-red mt-4 text-white">
                    <div class="d-flex align-items-center no-flex">
                        <div>
                            <div class="d-flex align-items-center">
                                <div class="me-3">
                                    <img
                                        src="../../assets/tb-icon.png"
                                        width="30"
                                        alt=""
                                    />
                                </div>
                                <div class="fw-bold size-18">
                                    Pendapatan Troben
                                </div>
                            </div>
                        </div>
                        <div class="ms-auto m-mt-1">
                            <date-picker
                                v-model="req.year_month_company"
                                type="month"
                                class="bc-company-income-date m-w-100"
                                value-type="format"
                                format="MMMM YYYY"
                                :clearable="false"
                                placeholder="Masukkan tanggal"
                                style="width: 170px"
                                @change="getDatas()"
                            >
                            </date-picker>
                        </div>
                    </div>
                    <div class="d-flex align-items-center no-flex">
                        <div class="mt-4">
                            <div class="">Pendapatan Sekarang</div>
                            <div class="size-24 fw-bold">
                                {{
                                    currencyFormat(
                                        dashboard_data.income_company
                                            .current_income
                                    )
                                }}
                            </div>
                        </div>
                        <div class="mt-4 ms-auto">
                            <div class="">Pendapatan Sebelumnya</div>
                            <div class="size-24 fw-bold">
                                {{
                                    currencyFormat(
                                        dashboard_data.income_company
                                            .last_income
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-5">
                <div class="box mt-4">
                    <div class="d-flex align-items-center">
                        <div>
                            <div class="mb-0 fw-bold size-18 capitalize">
                                List Mitra {{ params.partner_type }}
                            </div>
                        </div>
                        <div class="ms-auto">
                            <select
                                class="form-select custom-color"
                                v-model="req.order_by"
                                @change="getDatas()"
                            >
                                <option value="desc">Tertinggi</option>
                                <option value="asc">Terendah</option>
                            </select>
                        </div>
                    </div>
                    <div class="scroll-mitra-list">
                        <template
                            v-if="
                                dashboard_data.income_this_month_detail.length >
                                0
                            "
                        >
                            <template
                                v-for="(
                                    mitra, index
                                ) in dashboard_data.income_this_month_detail"
                            >
                                <div
                                    class="mt-3 box no-shadow border-gray p-3"
                                    :key="index"
                                >
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img
                                                src="../../assets/cart-icon.png"
                                                width="40"
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <div class="text-gray">
                                                {{ mitra.mitra_code }}
                                            </div>
                                            <div class="fw-bold size-16">
                                                {{
                                                    currencyFormat(
                                                        mitra.current_income
                                                    )
                                                }}
                                            </div>
                                        </div>
                                        <div class="ms-auto">
                                            <div class="text-gray">
                                                {{
                                                    moment(
                                                        mitra.date_income
                                                    ).format("DD MMM YYYY")
                                                }}
                                            </div>
                                            <div
                                                class="fw-bold size-14"
                                                :class="{
                                                    'text-danger': true,
                                                    'text-green':
                                                        mitra.current_income >=
                                                        mitra.last_income,
                                                }"
                                            >
                                                {{
                                                    currencyFormat(
                                                        mitra.current_income -
                                                            mitra.last_income
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <div class="mt-mitra-list-fc text-center">
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Tidak ada data
                                </h4>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="box mt-4">
                    <div class="d-flex align-items-center no-flex">
                        <div class="mb-0 fw-bold size-18">
                            Pendapatan Sales Agen
                        </div>
                        <div class="ms-auto m-mt-1">
                            <date-picker
                                v-model="req.date_sales"
                                type="date"
                                value-type="format"
                                format="DD MMMM YYYY"
                                class="m-w-100"
                                :clearable="false"
                                placeholder="Masukkan bulan"
                                style="width: 150px"
                                @change="getDatas()"
                            >
                            </date-picker>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="row mt-2">
                        <div class="col-md-12">
                            <div class="fw-bold size-16">Koordinator</div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <div
                                class="box no-shadow green-thin-box text-center"
                            >
                                <div class="size-14">Pendapatan</div>
                                <div class="fw-bold size-20">
                                    <template
                                        v-for="(
                                            income_coordinator, index
                                        ) in dashboard_data.income_sales_agent"
                                    >
                                        <div
                                            class="fw-bold size-20"
                                            :key="index"
                                        >
                                            <template
                                                v-if="
                                                    income_coordinator.caption ==
                                                        'this_day' &&
                                                    income_coordinator.profit_type ==
                                                        'coordinator'
                                                "
                                            >
                                                {{
                                                    currencyFormat(
                                                        income_coordinator.income
                                                    )
                                                }}
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <div
                                class="box no-shadow text-center"
                                :class="{
                                    'orange-thin-box': true,
                                    'green-thin-box':
                                        dashboard_data.income_sales_agent[3]
                                            ?.income >
                                        dashboard_data.income_sales_agent[1]
                                            ?.income,
                                }"
                            >
                                <div class="size-14">Peningkatan</div>
                                <div
                                    class="fw-bold size-20"
                                    :class="{
                                        'text-danger': true,
                                        'text-dark':
                                            dashboard_data.income_sales_agent[3]
                                                ?.income >
                                            dashboard_data.income_sales_agent[1]
                                                ?.income,
                                    }"
                                >
                                    {{
                                        currencyFormat(
                                            dashboard_data.income_sales_agent[3]
                                                ?.income -
                                                dashboard_data
                                                    .income_sales_agent[1]
                                                    ?.income
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mt-3">
                            <div class="fw-bold size-16">Agen</div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <div
                                class="box no-shadow green-thin-box text-center"
                            >
                                <div class="size-14">Pendapatan</div>
                                <div class="fw-bold size-20">
                                    <template
                                        v-for="(
                                            income_agent, index
                                        ) in dashboard_data.income_sales_agent"
                                    >
                                        <div
                                            class="fw-bold size-20"
                                            :key="index"
                                        >
                                            <template
                                                v-if="
                                                    income_agent.caption ==
                                                        'this_day' &&
                                                    income_agent.profit_type ==
                                                        'agent'
                                                "
                                            >
                                                {{
                                                    currencyFormat(
                                                        income_agent.income
                                                    )
                                                }}
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <div
                                class="box no-shadow text-center"
                                :class="{
                                    'orange-thin-box': true,
                                    'green-thin-box':
                                        dashboard_data.income_sales_agent[2]
                                            ?.income >
                                        dashboard_data.income_sales_agent[0]
                                            ?.income,
                                }"
                            >
                                <div class="size-14">Peningkatan</div>
                                <div
                                    class="fw-bold size-20"
                                    :class="{
                                        'text-danger': true,
                                        'text-dark':
                                            dashboard_data.income_sales_agent[2]
                                                ?.income >
                                            dashboard_data.income_sales_agent[0]
                                                ?.income,
                                    }"
                                >
                                    {{
                                        currencyFormat(
                                            dashboard_data.income_sales_agent[2]
                                                ?.income -
                                                dashboard_data
                                                    .income_sales_agent[0]
                                                    ?.income
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalFilter"></div>
            <div class="modal-body-chat sm" v-if="modalFilter">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            Filter Pendapatan
                        </div>
                    </div>
                    <div class="bd-highlight">
                        <a
                            href="javascript:void(0)"
                            @click="modalFilter = false"
                            class="size-20 text-primary"
                        >
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div class="mt-4">
                    <label class="mb-2 fw-bold">Bulan dan Tahun</label>
                    <date-picker
                        v-model="req.month"
                        type="month"
                        value-type="format"
                        :clearable="false"
                        format="MMMM YYYY"
                        placeholder="Masukkan tahun dan bulan"
                        class="w-100"
                    >
                    </date-picker>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Provinsi</label>
                        <multiselect
                            v-model="req.province"
                            :options="provinceLists"
                            label="name"
                            :loading="is_load_province"
                            track-by="id"
                            placeholder="Ketik untuk mencari provinsi"
                            @search-change="searchProvinces"
                        >
                        </multiselect>
                    </div>
                    <div class="mt-3">
                        <label class="mb-2 fw-bold">Kabupaten</label>
                        <multiselect
                            v-model="req.district"
                            :options="districtLists"
                            :disabled="req.province.id == ''"
                            label="name"
                            :loading="is_load_district"
                            track-by="id"
                            placeholder="Ketik untuk mencari kabupaten"
                            @search-change="searchDistricts"
                        >
                        </multiselect>
                    </div>
                </div>
                <div class="mt-4 mb-3">
                    <button
                        class="btn btn-green px-5 btn-lg me-3"
                        @click="filterGlobal()"
                        :disabled="
                            req.month == '' &&
                            req.province.id == '' &&
                            req.district.id == ''
                        "
                    >
                        Lihat Pendapatan
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue-search-select/dist/VueSearchSelect.css";
import Multiselect from "vue-multiselect";
// import DashboardLoader from "../../components/DashboardLoader.vue";

export default {
    components: {
        apexcharts: VueApexCharts,
        DatePicker,
        Multiselect,
        // DashboardLoader
    },
    name: "Dashboard-Finacnce",
    data() {
        return {
            moment: moment,
            tab_menu: "coordinator",
            params: {
                partner_type: "",
            },
            dashboard_data: {
                income_this_day: [],
                income_sales_agent: [],
                income_this_month: [],
                income_this_month_detail: [],
                income_company: [],
                income_total: {
                    business: [],
                    pos: [],
                    space: [],
                    transporter: [],
                    total: [],
                    warehouse: [],
                },
            },
            chartOptions: {
                xaxis: {
                    categories: [],
                    labels: {
                        formatter: function (val) {
                            return (
                                "Tgl " + moment(val, "YYYY-MM-DD").format("D")
                            );
                        },
                    },
                },
                colors: ["#ff9f43"],
                dataLabels: {
                    enabled: false,
                },
            },
            series: [
                {
                    name: "Pendapatan",
                    data: [],
                },
            ],
            modalFilter: false,
            req: {
                month: moment(new Date()).format("MMMM YYYY"),
                year_month_company: moment(new Date()).format("MMMM YYYY"),
                province: {
                    id: "",
                },
                district: {
                    id: "",
                },
                order_by: "desc",
                date_current: moment(new Date()).format("DD MMMM YYYY"),
                date_sales: moment(new Date()).format("DD MMMM YYYY"),
            },
            provinceLists: [],
            searchProvince: "",
            is_load_province: false,
            districtLists: [],
            searchDistrict: "",
            is_load_district: false,
            is_data_ready: false,
            is_data_filter: false,
        };
    },
    created() {
        this.getDatas();
    },
    mounted() {},
    methods: {
        notBeforeToday(date) {
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        showModalFilter() {
            this.modalFilter = true;
        },
        clearFilter() {
            this.is_data_filter = true;
            Api.get(
                `${
                    process.env.VUE_APP_SERVICE_URL
                }/admin/fno/dashboard-fnc?partner_type=&year_month=${moment(
                    new Date()
                ).format("YYYY-MM")}&year_month_company=${moment(
                    new Date()
                ).format("YYYY-MM")}&province_id=&regency_id=&order_by=${
                    this.req.order_by
                }&date_current=${moment(this.req.date_current).format(
                    "DD"
                )}&date_sales=${moment(this.req.date_sales).format(
                    "YYYY-MM-DD"
                )}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.is_data_filter = false;
                    this.dashboard_data = data;
                    this.req.province.id = "";
                    this.req.district.id = "";
                    this.req.province.name = "";
                    this.req.district.name = "";

                    let minNumber = Math.min(...data.income_this_month.val);
                    minNumber = parseInt(minNumber - minNumber * 0.1);
                    minNumber = 0;

                    this.$refs.chartmonthly.updateSeries([
                        {
                            name: "Pendapatan",
                            data: data.income_this_month.val,
                        },
                    ]);

                    let that = this;

                    this.$refs.chartmonthly.updateOptions({
                        xaxis: {
                            categories: data.income_this_month.key,
                        },
                        yaxis: {
                            min: minNumber,
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return that.currencyFormat(val);
                                },
                            },
                        },
                    });

                    this.req.month = moment(new Date()).format("MMMM YYYY");
                    this.req.province.id = "";
                    this.req.district.id = "";
                })
                .catch((err) => {
                    this.is_data_filter = false;
                    console.log(err);
                });
        },
        filterGlobal() {
            this.getDatas();
            this.modalFilter = false;
        },
        currencyFormat(num) {
            return (
                "Rp " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getDatas() {
            this.is_data_ready = true;
            Api.get(
                `${
                    process.env.VUE_APP_SERVICE_URL
                }/admin/fno/dashboard-fnc?partner_type=${
                    this.params.partner_type
                }&year_month=${moment(this.req.month).format(
                    "YYYY-MM"
                )}&year_month_company=${moment(
                    this.req.year_month_company
                ).format("YYYY-MM")}&province_id=${
                    this.req.province.id
                }&regency_id=${this.req.district.id}&order_by=${
                    this.req.order_by
                }&date_current=${moment(this.req.date_current).format(
                    "DD"
                )}&date_sales=${moment(this.req.date_sales).format(
                    "YYYY-MM-DD"
                )}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.is_data_ready = false;
                    this.dashboard_data = data;

                    let minNumber = Math.min(...data.income_this_month.val);
                    minNumber = parseInt(minNumber - minNumber * 0.1);
                    minNumber = 0;

                    this.$refs.chartmonthly.updateSeries([
                        {
                            name: "Pendapatan",
                            data: data.income_this_month.val,
                        },
                    ]);

                    let that = this;

                    this.$refs.chartmonthly.updateOptions({
                        xaxis: {
                            categories: data.income_this_month.key,
                        },
                        yaxis: {
                            min: minNumber,
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return that.currencyFormat(val);
                                },
                            },
                        },
                    });
                })
                .catch((err) => {
                    this.is_data_ready = false;
                    console.log(err);
                });
        },
        searchProvinces(searchProvince) {
            this.searchProvince = searchProvince;
            this.is_load_province = true;
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/geo?type=province&search=${searchProvince}&page=1&per_page=15`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.provinceLists = res.data.data;
                    this.is_load_province = false;
                })
                .catch((err) => {
                    this.is_load_province = false;
                    console.log(err);
                });
        },
        searchDistricts(searchDistrict) {
            this.searchDistrict = searchDistrict;
            this.is_load_district = true;
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/geo?type=regency&search=${searchDistrict}&province_id=${this.req.province.id}&page=1&per_page=15`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.districtLists = res.data.data;
                    this.is_load_district = false;
                })
                .catch((err) => {
                    this.is_load_district = false;
                    console.log(err);
                });
        },
    },
};
</script>
<style scoped>
.blue-thin-box {
    background: #edf6fb !important;
    border: 2px solid #48a2d4 !important;
}
.orange-thin-box {
    background: #fdf0e4 !important;
    border: 2px solid #f2994a !important;
}
.green-thin-box {
    background: #dff3e7 !important;
    border: 2px solid #27ae60 !important;
}
.dashboard-menu-tab {
    font-weight: 600;
    color: #ccc;
    cursor: pointer;
}
.dashboard-menu-tab.active {
    color: #3d8824;
}
.dot-menu.active {
    width: 10px;
    height: 10px;
    background: #3d8824;
    margin-right: 8px;
    border-radius: 10px;
}
.bc-red {
    background: #eb5757 !important;
}
.scroll-mitra-list {
    height: 374px;
    overflow-y: scroll;
}
.form-select.custom-color {
    background-color: #fff !important;
    color: #000 !important;
    border: 1px solid #f2994a !important;
    border-radius: 20px;
    padding: 0.375rem 2.4rem 0.375rem 1rem;
}
.form-select:focus.custom-color {
    box-shadow: unset !important;
}
.form-select.custom-color-default {
    background-color: #fff !important;
    color: #000 !important;
    border: 1px solid #e2e3e5 !important;
    border-radius: 20px;
    padding: 0.375rem 2.4rem 0.375rem 1rem;
}
.form-select:focus.custom-color-default {
    box-shadow: unset !important;
}
.full-radius {
    border-radius: 20px !important;
}
.mt-mitra-list-fc {
    margin-top: 100px;
}
</style>
