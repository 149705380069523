<template>
    <div>
        <div>
            <div class="box">
                <div class="d-flex bd-highlight">
                    <div class="flex-grow-1 bd-highlight">
                        <h4 class="fw-bold">{{ items.name }}</h4>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <input
                                type="date"
                                class="form-control"
                                v-model="filter.date_start"
                                placeholder="Start Date"
                                valueFormat="YYYY-MM-DD"
                            />
                        </div>
                        <div class="col-md-1 d-flex align-items-center">
                            <div class="fw-bold fs-6">s/d</div>
                        </div>
                        <div class="col-md-4">
                            <input
                                type="date"
                                class="form-control"
                                v-model="filter.date_end"
                                :disabled="!filter.date_start"
                                valueFormat="YYYY-MM-DD"
                                @change="searchPartner"
                                placeholder="End Date"
                            />
                        </div>
                        <div class="col-md-3">
                            <div class="position-relative">
                                <input
                                    v-model="partnerCode"
                                    @keyup.enter="searchPartner"
                                    type="text"
                                    class="form-control"
                                    style="width: 125px"
                                    placeholder="Kode Mitra"
                                />
                                <span class="search-icon">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4" v-if="is_my_list_ready">
                    <ListLoader />
                </div>
                <div v-else>
                    <div class="modern-table-boxed mt-4">
                        <div class="d-table-row header">
                            <div class="d-table-cell">No</div>
                            <div class="d-table-cell">Kode Mitra</div>
                            <div class="d-table-cell">Wilayah</div>
                            <div class="d-table-cell">Tipe Mitra</div>
                            <div class="d-table-cell">Tanggal</div>
                            <div class="d-table-cell">Total Penerimaan</div>
                        </div>
                        <div
                            class="d-table-row"
                            v-for="(item, index) in data"
                            :key="index"
                        >
                            <div class="d-table-cell">{{ item.no }}</div>
                            <div class="d-table-cell">
                                <router-link
                                    class="text-dark fw-bold"
                                    :to="{
                                        name: items.url,
                                        params: {
                                            partnerid: item.partner_code,
                                        },
                                    }"
                                    >{{ item.partner_code }}</router-link
                                >
                                <!-- <a href="javascript:void(0)" class="text-dark fw-bold" @click="addData(item.partner_code)"> {{ item.partner_code }} </a> -->
                            </div>
                            <!-- <div class="d-table-cell">{{ item.partner_code }}</div> -->
                            <div class="d-table-cell">
                                <a
                                    href="javascript:void(0)"
                                    class="text-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="item.partner_address"
                                >
                                    {{ item.partner_regency }}
                                    <i
                                        class="fa fa-info-circle text-blue ms-1"
                                    ></i>
                                </a>
                            </div>
                            <div class="d-table-cell">
                                {{ item.income_type }}
                            </div>
                            <div class="d-table-cell">
                                {{ dateFormat(item.datetime) }}
                            </div>
                            <div class="d-table-cell">
                                {{ currency(item.amount) }}
                            </div>
                        </div>
                    </div>
                    <div v-if="data.length == 0" class="text-center mt-section">
                        <img src="../assets/no-data.png" width="100" alt="" />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </div>
            </div>
            <div class="d-flex" v-if="data.length">
                <div class="ms-auto">
                    <div class="d-flex mt-4">
                        <div class="me-3">
                            <select
                                class="form-control form-select w-select"
                                v-model="limit"
                                @change="getIncome()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <div class="">
                            <b-pagination-nav
                                v-model="currentPage"
                                :number-of-pages="totalPage"
                                base-url="#"
                                first-number
                                align="right"
                                @input="changePage"
                            ></b-pagination-nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../api/Api";
import moment from "moment";
import ListLoader from "../components/ListLoader.vue";

export default {
    props: {
        items: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        ListLoader,
    },
    name: "IncomeMitraTable",
    data() {
        return {
            showModal: false,
            is_my_list_ready: false,
            data: [],
            detailData: [],
            currentPage: 0,
            totalPage: 0,
            limit: 10,
            partnerCode: "",
            filter: {
                date_start: null,
                date_end: null,
            },
        };
    },
    created() {
        this.getIncome();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    methods: {
        searchPartner() {
            this.currentPage = 0;
            this.getIncome();
        },
        changePage() {
            this.getIncome();
        },
        dateFormat(date) {
            return moment(date).format("ddd, DD MMM YYYY");
        },
        getIncome() {
            this.is_my_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/fno/income/partner`,
                {
                    params: {
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 1,
                        limit: this.limit,
                        partner_type: this.items.partner_type,
                        search: this.partnerCode,
                        date_start: this.filter.date_start,
                        date_end: this.filter.date_end,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.data = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    this.is_my_list_ready = false;
                    console.log(err);
                });
        },
        // getDetailIncome(code) {
        //   console.log(">>>>", code, this.items.partner_type);
        //   Api.get(`${process.env.VUE_APP_SERVICE_URL}/admin/fno/income/partner/business`, {
        //     params: { page: this.currentPage == 0 || this.currentPage == null ? 0 : this.currentPage - 1, limit: this.limit, partner_code: `MB-JKT-003` },
        //     headers: {
        //       Authorization: "Bearer" + localStorage.getItem("token"),
        //     },
        //   })
        //     .then((res) => {
        //       console.log(">", res.data.data.list_data);
        //       this.detailData = res.data.data.list_data;
        //       console.log(">>>", this.detailData);
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     });
        // },
        currency(number) {
            return new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
            }).format(number);
        },
        // addData(data) {
        //   // this.getDetailIncome(data);
        //   console.log(">>", data);
        // },
    },
};
</script>

<style scoped>
.scroll {
    height: 500px;
    overflow-y: scroll;
}
.row.foto {
    --bs-gutter-x: 0rem !important;
}
.search-icon {
    position: absolute;
    top: 6px;
    right: -5px;
}
</style>
