<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="d-flex align-items-center bd-highlight">
            <div class="flex-grow-1 bd-highlight">
                <h3 class="fw-bold">Daftar Pesanan</h3>
            </div>
        </div>
        <div class="box mt-4">
            <template v-if="is_card_ready">
                <CardLoader />
            </template>
            <template v-else>
                <div class="row">
                    <div class="col-md-3">
                        <div class="box no-shadow border-gray">
                            <h5 class="fw-bold mb-0 text-gray">
                                Jumlah Permintaan
                            </h5>
                            <div class="fw-ekstra-bold size-24 mt-3">
                                {{ dashboard.pending }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="box no-shadow border-gray">
                            <h5 class="fw-bold mb-0 text-gray">
                                Jumlah Diproses
                            </h5>
                            <div class="fw-ekstra-bold size-24 mt-3">
                                {{ dashboard.process }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="box no-shadow border-gray">
                            <h5 class="fw-bold mb-0 text-gray">
                                Jumlah Selesai
                            </h5>
                            <div class="fw-ekstra-bold size-24 mt-3">
                                {{ dashboard.success }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="box no-shadow border-gray">
                            <h5 class="fw-bold mb-0 text-gray">
                                Jumlah Cancel
                            </h5>
                            <div class="fw-ekstra-bold size-24 mt-3">
                                {{ dashboard.cancel }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6 mt-3">
                        <div class="position-relative">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Cari id order"
                                v-model="req.receipt"
                            />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <input
                            type="date"
                            class="form-control"
                            placeholder="Start date"
                            v-model="req.start"
                            @change="getOrderLists()"
                        />
                    </div>
                    <div class="col-md-3 mt-3">
                        <input
                            type="date"
                            class="form-control"
                            placeholder="End date"
                            v-model="req.end"
                            @change="getOrderLists()"
                        />
                    </div>
                </div>
            </template>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <table
                    class="table position-relative mt-5"
                    v-if="searchReceiptOrder.length > 0"
                >
                    <thead class="thead">
                        <tr class="tr">
                            <td class="th">No</td>
                            <td class="th">ID Order</td>
                            <td class="th">Armada</td>
                            <td class="th">Lokasi Asal</td>
                            <td class="th">Layanan</td>
                            <td class="th">Status</td>
                            <td class="th">Tanggal Order</td>
                            <td class="th">Tanggal Pickup</td>
                            <!-- <td class="th">
                                SLA Leadtime
                            </td> -->
                        </tr>
                    </thead>
                    <tr>
                        <td>
                            <div></div>
                        </td>
                    </tr>
                    <template v-for="(list, index) in searchReceiptOrder">
                        <tr class="tr border-body" :key="index">
                            <td class="td">
                                <b> {{ index + 1 }}. </b>
                            </td>
                            <td class="td">
                                <a
                                    :href="
                                        '/trawlcarrier/partner/order/' + list.id
                                    "
                                    class="fw-bold text-dark"
                                >
                                    {{ list.receipt }}
                                </a>
                            </td>
                            <td class="td capitalize">
                                {{ list.order_type }}
                            </td>
                            <td class="td" style="width: 180px">
                                <a
                                    href="javascript:void(0)"
                                    class="text-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="list.partner.address"
                                >
                                    <div class="limit-one-line">
                                        {{ list.partner.address }}
                                    </div>
                                </a>
                            </td>
                            <td class="td capitalize">
                                {{
                                    (list.service_type || "")
                                        .split("_")
                                        .join(" ")
                                }}
                            </td>
                            <td class="td capitalize">
                                <small
                                    class="tag yellow pb-1 pt-1"
                                    v-if="list.status == 'pending'"
                                >
                                    Pending
                                </small>
                                <small
                                    class="tag red pb-1 pt-1"
                                    v-else-if="list.status == 'cancel'"
                                >
                                    Cancel
                                </small>
                                <small
                                    class="tag green pb-1 pt-1"
                                    v-else-if="list.status == 'success'"
                                >
                                    Success
                                </small>
                                <small class="tag gray pb-1 pt-1" v-else>
                                    {{
                                        (list.status || "").split("_").join(" ")
                                    }}
                                </small>
                            </td>
                            <td class="td">
                                {{
                                    moment(list.created_at).format(
                                        "DD MMMM YYYY"
                                    )
                                }}
                            </td>
                            <td class="td capitalize">
                                <template v-if="list.pickup_at != null">
                                    {{
                                        moment(list.pickup_at).format(
                                            "DD MMMM YYYY"
                                        )
                                    }}
                                </template>
                                <template v-else> - </template>
                            </td>
                            <!-- <td class="td capitalize">
                                00 : 59 : 00
                            </td> -->
                        </tr>
                        <tr
                            class="border-footer bc-gray pb-5"
                            v-if="list.status == 'accepted'"
                            :key="list.receipt"
                        >
                            <td class="p-2 pt-1 pb-1" colspan="9">
                                <div class="d-flex align-items-center">
                                    <!-- <div style="width: 250px;">
                                        <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" :title="list.partner.address">
                                            <div class="limit-one-line">
                                                <i class="fa fa-map-marker me-1"></i>
                                                {{ list.partner.address }}
                                                <i class="fa fa-info-circle text-blue ms-1"></i>
                                            </div>
                                        </a>
                                    </div> -->
                                    <div class="ms-auto">
                                        <template
                                            v-if="list.status == 'accepted'"
                                        >
                                            <a
                                                href="javascript:void(0)"
                                                @click="accept(list.id)"
                                                class="btn btn-green btn-sm me-3 px-3"
                                            >
                                                Terima
                                            </a>
                                        </template>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr :key="list.id">
                            <td colspan="9">
                                <div class=""></div>
                            </td>
                        </tr>
                    </template>
                </table>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <b-pagination-nav
                v-if="searchReceiptOrder.length > 0"
                :link-gen="linkGen"
                v-model="currentPage"
                :number-of-pages="lists.total_page"
                base-url="#"
                first-number
                align="right"
            ></b-pagination-nav>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="declineModal"></div>
            <div class="modal-body-chat sm" v-if="declineModal">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            <img
                                src="../../assets/decline.png"
                                width="65"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-ekstra-bold">Tolak Pesanan?</div>
                    <div class="mt-2">
                        Anda yakin untuk menolak pesanan ini? Jika pesanan
                        ditolak maka akan terhapus secara permanent
                    </div>
                    <div class="mt-2">
                        <textarea
                            cols="30"
                            rows="4"
                            class="form-control"
                            placeholder="Masukkan alasan menolak"
                            v-model="reason_message"
                        ></textarea>
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <a
                                href="javascript:void(0)"
                                class="btn btn-outline-black w-100"
                                @click="declineModal = false"
                            >
                                Batal
                            </a>
                        </div>
                        <div class="col-md-6 mt-4">
                            <a
                                href="javascript:void(0)"
                                class="btn btn-primary w-100"
                                @click="decline(declineData.id)"
                            >
                                Tolak
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="showModalAccept"></div>
            <div class="modal-body-chat lg" v-if="showModalAccept">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-22 fw-ekstra-bold">Data Pemesanan</div>
                    </div>
                    <div class="bd-highlight">
                        <a
                            href="javascript:void(0)"
                            @click="showModalAccept = false"
                            class="size-20 text-primary"
                        >
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div class="scroll mt-4">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="box no-shadow border-gray">
                                <span class="tag green">
                                    Tanggal Order :
                                    {{
                                        moment(detailOrder.created_at).format(
                                            "DD MMMM YYYY"
                                        )
                                    }}
                                </span>
                                <h5 class="mt-4 mb-0 fw-bold">
                                    <img
                                        src="../../assets/pengirim.png"
                                        width="35"
                                        alt=""
                                    />
                                    Pengirim
                                </h5>
                                <div class="mt-3 fw-medium">
                                    {{ detailOrder.origin.name }}
                                </div>
                                <div class="mt-2 fw-medium">
                                    {{ detailOrder.origin.phone }}
                                </div>
                                <div class="fw-medium mt-2">
                                    {{ detailOrder.origin.address }}
                                </div>
                                <div class="line"></div>
                                <h5 class="mt-3 mb-0 fw-bold">
                                    <img
                                        src="../../assets/pengirim.png"
                                        width="35"
                                        alt=""
                                    />
                                    Penerima
                                </h5>
                                <div class="mt-3 fw-medium">
                                    {{ detailOrder.destination.name }}
                                </div>
                                <div class="mt-2 fw-medium">
                                    {{ detailOrder.destination.phone }}
                                </div>
                                <div class="fw-medium mt-2">
                                    {{ detailOrder.destination.address }}
                                </div>
                            </div>
                            <div
                                class="mt-4 box no-shadow no-border bc-yellow-thin p-3 d-flex align-items-center"
                                v-if="
                                    detailOrder.service_type == 'door_to_door'
                                "
                            >
                                <img
                                    src="../../assets/warning.png"
                                    width="25"
                                    class="me-2"
                                    alt=""
                                />
                                Kendaraan siap di pickup pada tanggal
                                <span
                                    class="fw-bold ms-1"
                                    v-if="detailOrder.pickup_at != null"
                                    >{{
                                        moment(detailOrder.pickup_at).format(
                                            "DD MMMM YYYY"
                                        )
                                    }}</span
                                >
                                <span class="fw-bold ms-1" v-else>-</span>
                            </div>
                            <h5 class="fw-bold mt-5">Mobil</h5>
                            <table class="mt-3">
                                <tr class="p-3">
                                    <td style="width: 120px">Brand Mobil</td>
                                    <td style="width: 30px">:</td>
                                    <td class="fw-medium">
                                        {{ detailOrder.meta.brand }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tipe Mobil</td>
                                    <td style="width: 30px">:</td>
                                    <td class="fw-medium">
                                        {{ detailOrder.meta.variant }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Informasi Mobil</td>
                                    <td style="width: 30px">:</td>
                                    <td class="fw-medium">
                                        {{ detailOrder.meta.note }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Asuransi</td>
                                    <td style="width: 30px">:</td>
                                    <td class="fw-medium">
                                        {{
                                            currencyFormat(
                                                detailOrder.insurance_price
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tipe Pengiriman</td>
                                    <td style="width: 30px">:</td>
                                    <td class="fw-medium capitalize">
                                        {{
                                            (detailOrder.service_type || "")
                                                .split("_")
                                                .join(" ")
                                        }}
                                    </td>
                                </tr>
                            </table>
                            <h5 class="fw-bold mt-5">Photo Terlampir</h5>
                            <div class="row">
                                <template
                                    v-if="detailOrder.meta.images.length > 0"
                                >
                                    <template
                                        v-for="(image, index) in detailOrder
                                            .meta.images"
                                    >
                                        <div class="col-md-3 mt-2" :key="index">
                                            <div
                                                class="order-photo"
                                                :style="{
                                                    'background-image':
                                                        'url(' +
                                                        image.image +
                                                        ')',
                                                }"
                                            ></div>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="col-md-3 mt-2">
                                        <div
                                            class="order-photo"
                                            :style="{
                                                'background-image':
                                                    'url(' +
                                                    require('../../assets/no-photo.png') +
                                                    ')',
                                            }"
                                        ></div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div
                                v-if="
                                    detailOrder.service_type == 'door_to_door'
                                "
                            >
                                <h5 class="mb-0 fw-bold">Tugaskan Driver</h5>
                                <div class="mb-3">
                                    <div class="search-form-gl w-100">
                                        <input
                                            type="text"
                                            placeholder="Cari driver"
                                            class="search-form-gl-input"
                                            v-model="req.keyword"
                                        />
                                        <i class="fa fa-search icon"></i>
                                    </div>
                                </div>
                                <template v-if="searchDriverList.length > 0">
                                    <template
                                        v-for="(
                                            driver, index
                                        ) in searchDriverList"
                                    >
                                        <div class="mt-2" :key="index">
                                            <label
                                                class="plan"
                                                :for="driver.id"
                                            >
                                                <input
                                                    type="radio"
                                                    name="transport"
                                                    :id="driver.id"
                                                    :value="driver"
                                                    v-model="req.driver"
                                                />
                                                <div class="plan-content p-3">
                                                    <h6
                                                        class="mb-0 fw-bold size-16 capitalize"
                                                    >
                                                        {{ driver.name }}
                                                    </h6>
                                                    <div
                                                        class="mt-1 size-14 capitalize"
                                                    >
                                                        {{
                                                            (driver.type || "")
                                                                .split("_")
                                                                .join(" ")
                                                        }}
                                                        -
                                                        {{
                                                            driver.registration_number
                                                        }}
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div
                                        class="box no-shadow bc-gray text-center"
                                    >
                                        <div class="mb-1">
                                            <i class="fa fa-search size-24"></i>
                                        </div>
                                        Driver tidak ditemukan
                                    </div>
                                </template>
                                <div class="row">
                                    <div class="col-md-6 mt-4">
                                        <label class="plan" for="ya">
                                            <input
                                                checked
                                                type="radio"
                                                name="insurance"
                                                id="ya"
                                                v-model="is_transit"
                                                :value="true"
                                            />
                                            <div
                                                class="plan-content insurance map p-3"
                                            >
                                                <h6 class="mb-0 fw-bold">
                                                    Pengiriman Transit
                                                </h6>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <label class="plan" for="tidak">
                                            <input
                                                type="radio"
                                                id="tidak"
                                                name="insurance"
                                                v-model="is_transit"
                                                :value="false"
                                            />
                                            <div
                                                class="plan-content insurance map p-3"
                                            >
                                                <h6 class="mb-0 fw-bold">
                                                    Kirim Langsung
                                                </h6>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="mt-3" v-if="is_transit == true">
                                    <label class="fw-bold mb-2"
                                        >Lokasi Transit</label
                                    >
                                    <select
                                        class="form-control form-select"
                                        v-model="transit"
                                    >
                                        <option value="">
                                            Pilih lokasi transit
                                        </option>
                                        <option value="pool">Pool</option>
                                        <option value="port">Port</option>
                                    </select>
                                </div>
                            </div>
                            <div v-else>
                                <h5 class="mb-0 fw-bold">Tanggal Pengiriman</h5>
                                <div class="mt-4">
                                    <date-picker
                                        v-model="pickup_date"
                                        type="datetime"
                                        format="DD MMMM YYYY, Pukul HH.mm"
                                        value-type="format"
                                        placeholder="Masukkan tanggal pengiriman"
                                        class="w-100"
                                    >
                                    </date-picker>
                                </div>
                            </div>
                            <div class="mt-4" v-if="is_transit == true">
                                <GmapMap
                                    ref="mapRef"
                                    :center="{
                                        lat: -6.233035800000001,
                                        lng: 106.8397427,
                                    }"
                                    :zoom="16"
                                    map-type-id="terrain"
                                    style="height: 300px"
                                >
                                    <div
                                        class="row justify-content-center pt-4"
                                    >
                                        <div class="col-md-9">
                                            <div class="position-relative">
                                                <GmapAutocomplete
                                                    @place_changed="
                                                        processLocationChanged
                                                    "
                                                    placeholder="Pilih lokasi anda"
                                                    class="form-control search-google"
                                                ></GmapAutocomplete>
                                                <span class="search-icon map">
                                                    <i class="fa fa-search"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <GmapMarker
                                        :key="index"
                                        v-for="(m, index) in markers"
                                        ref="marker"
                                        :position="m"
                                        :clickable="true"
                                        :draggable="true"
                                        @dragend="movePlace"
                                        @click="center = m"
                                    >
                                    </GmapMarker>
                                    <!-- <GmapCircle ref="circle" :radius="1000" :center=map_data :draggable='false' :editable='true' @radius_changed="radiusChanged" @center_changed="centerChanged"></GmapCircle> -->
                                </GmapMap>
                                <div class="mt-4">
                                    <label class="fw-bold mb-2 size-16"
                                        >Alamat Tujuan</label
                                    >
                                    <div v-if="alamat">
                                        {{ alamat }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-6">
                        </div> -->
                    </div>
                </div>
                <div class="mt-4 row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <button
                            class="btn btn-green w-100 btn-md btn-lg"
                            type="button"
                            disabled
                            v-if="is_assign_driver"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <template
                                v-if="
                                    detailOrder.service_type == 'door_to_door'
                                "
                            >
                                Tugaskan Driver
                            </template>
                            <template v-else> Kirim ke Pelanggan </template>
                        </button>
                        <a
                            href="javascript:void(0)"
                            @click="assignDriver()"
                            class="btn btn-green w-100 btn-md btn-lg"
                            v-else
                        >
                            <template
                                v-if="
                                    detailOrder.service_type == 'door_to_door'
                                "
                            >
                                Tugaskan Driver
                            </template>
                            <template v-else> Kirim ke Pelanggan </template>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import { Tooltip } from "bootstrap";
import ListLoader from "../../components/ListLoader.vue";
import CardLoader from "../../components/CardLoader.vue";
import moment from "moment";
import { gmapApi } from "vue2-google-maps";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
    components: {
        ListLoader,
        CardLoader,
        DatePicker,
    },
    name: "OrderList",
    data() {
        return {
            alamat: "",
            moment: moment,
            dashboard: {
                process: 0,
                pending: 0,
                success: 0,
                cancel: 0,
            },
            lists: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            is_list_ready: false,
            is_card_ready: false,
            declineModal: false,
            reason_message: "",
            showModalAccept: false,
            detailOrder: {
                origin: {},
                destination: {},
                meta: {
                    images: [],
                },
            },
            drivers: [],
            req: {
                receipt: "",
                start: "",
                end: "",
                keyword: "",
                driver: {
                    id: "",
                    type: "",
                },
                pickup_date: null,
            },
            is_assign_driver: false,
            per_page: 10,
            currentPage: null,
            declineData: null,
            map_data: { lat: null, lng: null },
            markers: [
                {
                    lat: -6.233035800000001,
                    lng: 106.8397427,
                },
            ],
            lat_move: "",
            lng_move: "",
            lat_search: "",
            lng_search: "",
            is_transit: true,
            transit: "",
            pickup_date: null,
            service_text: null,
        };
    },
    created() {
        this.getDashboard();
        this.getOrderLists();
        this.getListDriver();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });

        this.$nextTick(() => {
            if (this.$refs.googleMap) {
                this.$refs.mapRef.$mapPromise.then((map) => {
                    map.panTo({ lat: -6.233035800000001, lng: 106.8397427 });
                });
            }
        });
    },
    computed: {
        google: gmapApi,
        searchDriverList() {
            return this.drivers.filter((item) => {
                return item.name
                    .toLowerCase()
                    .includes(this.req.keyword.toLowerCase());
            });
        },
        searchReceiptOrder() {
            return this.lists.list_data.filter((item) => {
                return item.receipt
                    .toLowerCase()
                    .includes(this.req.receipt.toLowerCase());
            });
        },
    },
    methods: {
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        currencyFormat(num) {
            return (
                "Rp. " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getDashboard() {
            this.is_card_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/dashboard`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_card_ready = false;
                    var data = res.data.data;
                    this.dashboard = data;
                })
                .catch((err) => {
                    this.is_card_ready = false;
                    console.log(err);
                });
        },
        getOrderLists() {
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/orders?start_date=${this.req.start}&end_date=${this.req.end}`,
                {
                    params: {
                        per_page: this.per_page,
                        page: this.$route.query.page,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.lists = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        declineModalOpen(list) {
            this.declineModal = true;
            this.declineData = list;
        },
        decline() {
            if (this.reason_message == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Isi alasan anda menolak",
                });
            } else {
                var data = {
                    reason_to_cancel: this.reason_message,
                };
                Api.post(
                    `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/order/cancel/${this.declineData.id}`,
                    data,
                    {
                        headers: {
                            Authorization:
                                "Bearer" + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        this.declineModal = false;
                        this.getOrderLists();
                        console.log(res);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        accept(id) {
            this.showModalAccept = true;
            this.getOrderDetail(id);
            this.req.driver = {};
            this.pickup_date = null;
        },
        getOrderDetail(id) {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/order/${id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detailOrder = data;
                    if (this.detailOrder.service_type == "pool_to_pool") {
                        this.service_text = "Pool";
                    } else {
                        this.service_text = "Port";
                    }

                    this.pickup_date = this.moment(data.pickup_at).format(
                        "DD MMMM YYYY, [Pukul] HH.mm"
                    );
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getListDriver() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/list-drivers`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.drivers = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        assignDriver() {
            var data = {
                service: this.detailOrder.service_type,
                driver: this.req.driver.id,
                receipt: this.detailOrder.receipt,
                is_transit: this.is_transit,
                lat: this.map_data.lat,
                lon: this.map_data.lng,
                pickup_text:
                    `Batas waktu pengiriman mobil anda ke ${this.service_text} tanggal ` +
                    this.pickup_date,
                transit: this.transit,
            };
            this.is_assign_driver = true;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/manifest/store/`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.showModalAccept = false;
                    this.getOrderLists();
                    this.is_assign_driver = false;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_assign_driver = false;

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        processLocationChanged(ev) {
            this.lat_search = ev.geometry.location.toJSON().lat;
            this.lng_search = ev.geometry.location.toJSON().lng;
            this.map_data = ev.geometry.location.toJSON();

            this.alamat = ev.formatted_address;

            let that = this;
            this.$refs.mapRef.$mapPromise.then((map) => {
                map.panTo(that.map_data);
            });
            this.$refs.marker[0].$markerObject.setPosition(this.map_data);
        },
        movePlace(event) {
            this.lat_move = event.latLng.toJSON().lat;
            this.lng_move = event.latLng.toJSON().lng;
            this.map_data = event.latLng.toJSON();
            console.log(event.domEvent);

            this.decodeLatLong(this.lat_move, this.lng_move);
        },
        decodeLatLong(lat, lng) {
            const geocoder = new this.google.maps.Geocoder();

            geocoder.geocode(
                { location: { lat: lat, lng: lng } },
                (results, status) => {
                    if (status === "OK") {
                        console.log(results[0]);
                        this.alamat = results[0].formatted_address;
                    } else {
                        this.alamat = "";
                        alert("tidak ketemu");
                    }
                }
            );
        },
    },
};
</script>

<style>
.scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 450px;
}
.plan input[type="radio"]:checked + .plan-content.insurance.map {
    background: #fff;
}
.mx-input {
    box-shadow: unset !important;
    border: 1px solid #e9ecef !important;
}
</style>
