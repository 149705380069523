<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4 m-mt-0 p-0">
            <div class="pe-4 ps-4">
                <div class="d-flex align-items-center no-flex">
                    <h3 class="fw-bold mb-0 mt-4">Tambah Data Visit</h3>
                </div>
            </div>
            <div class="pe-4 ps-4 mt-4">
                <label class="fw-bold mb-2">Jenis Mitra</label>
                <multiselect
                    v-model="mitra"
                    :options="mitras"
                    label="name"
                    track-by="type"
                    placeholder="Jenis Mitra"
                    @input="clearForm()"
                >
                </multiselect>
            </div>
            <div class="p-4" v-if="mitra == null">
                <center>
                    <div>
                        <img
                            src="../../assets/no-mitra.png"
                            width="100"
                            alt=""
                        />
                    </div>
                    <div class="mt-2 fw-medium text-gray-dashboard size-16">
                        Pilih Jenis Mitra Terlebih Dahulu
                    </div>
                </center>
            </div>
            <div class="mt-4" v-if="mitra">
                <hr />
            </div>
            <div class="row ps-4 pr-4" v-if="mitra">
                <div class="col-md-12">
                    <h3 class="fw-bold mb-0">
                        <template v-if="mitra.type == 'individual'">
                            Informasi Driver
                        </template>
                        <template v-else> Informasi Perusahaan </template>
                    </h3>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        <template v-if="mitra.type == 'individual'">
                            Nama Calon Driver
                        </template>
                        <template v-else> Nama Perusahaan </template>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="req.name_visit"
                        :placeholder="
                            mitra.type == 'individual'
                                ? 'Masukkan nama calon driver'
                                : 'Masukkan nama perusahaan'
                        "
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        <template v-if="mitra.type == 'individual'">
                            Nomor Telepon
                        </template>
                        <template v-else>Nomor Telepon Perusahaan </template>
                    </label>
                    <input
                        type="number"
                        class="form-control"
                        v-model="req.phone_visit"
                        :placeholder="
                            mitra.type == 'individual'
                                ? 'Masukkan nomor telepon'
                                : 'Masukkan nomor telepon perusahaan'
                        "
                    />
                </div>
                <div class="col-md-6 mt-3" v-if="mitra.type == 'corporate'">
                    <label class="fw-bold mb-2"
                        >Nama Penanggung Jawab (Opsional)
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="req.pic_name"
                        placeholder="Masukkan nama penanggung jawab"
                    />
                </div>
                <div class="col-md-6 mt-3" v-if="mitra.type == 'corporate'">
                    <label class="fw-bold mb-2">
                        Nomor Telepon Penanggung Jawab (Opsional)
                    </label>
                    <input
                        type="number"
                        class="form-control"
                        v-model="req.pic_phone"
                        placeholder="Masukkan nomor telepon penanggung jawab"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Kota/Kabupaten</label>
                    <multiselect
                        v-model="regency"
                        :options="regencies"
                        label="name"
                        :loading="is_load_regency"
                        track-by="id"
                        placeholder="Cari Kota/Kabupaten"
                        @search-change="searchRegency"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        <template v-if="mitra.type == 'individual'">
                            Alamat Domisili
                        </template>
                        <template v-else>Alamat Perusahaan </template>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="req.address_visit"
                        :placeholder="
                            mitra.type == 'individual'
                                ? 'Masukkan alamat domisili calon driver'
                                : 'Masukkan alamat perusahaan'
                        "
                    />
                </div>
            </div>
            <div class="mt-4" v-if="mitra">
                <hr />
            </div>
            <div class="row pe-4 ps-4 pb-4" v-if="mitra">
                <div class="col-md-12">
                    <h3 class="fw-bold mb-0">Informasi Armada</h3>
                </div>
                <div
                    class="row"
                    v-for="(item, index) in fleet_inputs"
                    :key="index"
                >
                    <div
                        class="mt-3"
                        v-bind:class="{
                            'col-md-6': mitra.type == 'corporate',
                            'col-md-12': mitra.type == 'individual',
                        }"
                    >
                        <multiselect
                            v-model="item.fleet_value"
                            :options="fleets"
                            label="name"
                            track-by="code"
                            placeholder="Pilih jenis armada"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mt-3" v-if="mitra.type == 'corporate'">
                        <div class="position-relative">
                            <money
                                v-model="item.fleet_count"
                                v-bind="fleet_format"
                                class="form-control"
                            >
                            </money>
                            <span class="pcs-label cursor-pointer"> Pcs </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-4" v-if="mitra.type == 'corporate'">
                    <button
                        class="btn btn-dashed-green w-100"
                        @click="addFleet()"
                    >
                        <i class="fa fa-plus me-1"></i>
                        Tambah Armada
                    </button>
                </div>
                <div class="col-md-12 mt-3 pb-2">
                    <div class="mt-3 pull-right">
                        <button
                            @click="$router.go(-1)"
                            class="btn btn-outline-primary btn-lg px-5 me-2"
                        >
                            Batal
                        </button>
                        <button
                            class="btn btn-green btn-lg px-5"
                            :disabled="
                                is_store == true ||
                                req.name_visit == '' ||
                                req.phone_visit == '' ||
                                req.address_visit == '' ||
                                regency == null
                            "
                            @click="storeData()"
                        >
                            <span
                                v-if="is_store"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data Berhasil Disimpan!!</h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/trawltruck/mitra"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";
import { Money } from "v-money";

export default {
    components: {
        Multiselect,
        Money,
    },
    name: "trawltruckMitraEdit",
    data() {
        return {
            req: {
                name_visit: "",
                phone_visit: "",
                address_visit: "",
                pic_name: "",
                pic_phone: "",
            },
            mitra: null,
            mitras: [
                {
                    type: "individual",
                    name: "Individual",
                },
                {
                    type: "corporate",
                    name: "Corporate",
                },
            ],
            regency: null,
            regencies: [],
            fleet: null,
            fleets: [],
            fleet_inputs: [{ fleet_value: null, fleet_count: 0 }],
            search_regency: "",
            is_load_regency: false,
            is_store: false,
            modalSuccess: false,
            fleet_format: {
                decimal: ",",
                thousands: ".",
                suffix: "",
                precision: 0,
                masked: false,
            },
        };
    },
    created() {
        this.getFleets();
        this.searchRegency();
    },
    computed: {},
    mounted() {},
    methods: {
        searchRegency(search_regency) {
            this.search_regency = search_regency;
            this.is_load_regency = true;
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/geo/list?type=regency&q=${search_regency}&page=1&per_page=15`
            )
                .then((res) => {
                    var data = res.data.data;
                    this.regencies = data;
                    this.is_load_regency = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_regency = false;
                });
        },
        getFleets() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/trawltruck/fleet`
            )
                .then((res) => {
                    this.fleets = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        storeData() {
            this.is_store = true;
            const selectedFleet = this.fleet_inputs.map((item) => ({
                type: item?.fleet_value?.code,
                count: item?.fleet_count,
            }));
            var data = {
                type: this.mitra.type,
                name_visit: this.req.name_visit,
                phone_visit: this.req.phone_visit,
                address_visit: this.req.address_visit,
                pic_name: this.req.pic_name,
                pic_phone: this.req.pic_phone,
                regency_visit: this.regency?.id,
                fleet: selectedFleet,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/partner/truck/vm-report/store`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_store = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.is_store = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        clearForm() {
            this.req.name_visit = "";
            this.req.phone_visit = "";
            this.req.address_visit = "";
            this.req.pic_name = "";
            this.req.pic_phone = "";
            this.regency = null;
            this.fleet_inputs = [{ fleet_value: null, fleet_count: 0 }];
        },
        addFleet() {
            this.fleet_inputs.push({
                fleet_value: null,
                fleet_count: 0,
            });
        },
    },
};
</script>

<style scoped></style>
