<template>
    <div class="padding-container">
        <div class="">
            <div class="d-flex bd-highlight">
                <div class="flex-grow-1 bd-highlight">
                    <h4 class="fw-bold">
                        {{ items.name }}
                    </h4>
                </div>
                <div class="row">
                    <div class="col-md-4 text-end">
                        <button @click="getExport" class="btn btn-primary px-4">
                            <i class="fa fa-file-excel-o me-1"></i>
                            Export
                        </button>
                    </div>
                    <div class="col-md-4">
                        <select
                            v-model="incomeType"
                            style="width: 150px"
                            @change="getDetailIncome"
                            name="receive_type"
                            class="form-control form-select"
                            placeholder="Type Penerimaan"
                        >
                            <option value="" disabled selected hidden>
                                Type Penerimaan
                            </option>
                            <option value="">Semua</option>
                            <option value="pickup">Pick-up</option>
                            <option value="dooring">Dooring</option>
                            <option value="delivery">Delivery</option>
                            <option value="additional">Additional</option>
                            <option value="request">Request</option>
                            <option value="insurance">Insurance</option>
                            <option value="handling">Handling</option>
                            <option value="transit">Transit</option>
                            <option value="service">Service</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative">
                            <input
                                v-model="noReceipt"
                                @keyup.enter="searchReceipt"
                                type="text"
                                class="form-control"
                                placeholder="Search No.Resi"
                            />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4" v-if="is_my_list_ready">
            <ListLoader />
        </div>
        <div v-else class="scroll-x">
            <div class="modern-table-boxed mt-4 scr scroll-x">
                <div class="d-table-row header">
                    <div class="d-table-cell">No Resi</div>
                    <div class="d-table-cell">Biaya Ongkir</div>
                    <div class="d-table-cell">Biaya Packing</div>
                    <div class="d-table-cell">Biaya Asuransi</div>
                    <div class="d-table-cell">Diskon</div>
                    <div class="d-table-cell">Total Penerimaan</div>
                    <div class="d-table-cell">Type Penerimaan</div>
                </div>
                <div
                    class="d-table-row"
                    v-for="(item, name, index) in filterIncomeType(
                        detailData,
                        incomeType
                    )"
                    :key="index"
                >
                    <div class="d-table-cell">{{ item.receipt_code }}</div>
                    <div class="d-table-cell">
                        {{ currency(item.price_service) }}
                    </div>
                    <div class="d-table-cell">
                        {{ currency(item.price_packing) }}
                    </div>
                    <div class="d-table-cell">
                        {{ currency(item.price_insurance) }}
                    </div>
                    <div class="d-table-cell">
                        {{ currency(item.discount) }}
                    </div>
                    <div class="d-table-cell">
                        {{ currency(item.total_amount) }}
                    </div>
                    <div class="d-table-cell">
                        <span class="tag green">{{ item.income_type }}</span>
                    </div>
                </div>
            </div>
            <div v-if="detailData.length == 0" class="text-center mt-section">
                <img src="../../assets/no-data.png" width="100" alt="" />
                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
            </div>
        </div>
        <div class="overflow-auto mt-3">
            <b-pagination-nav
                v-if="detailData.length > 0"
                v-model="currentPage"
                :number-of-pages="totalPage"
                base-url="#"
                first-number
                align="right"
                @input="changePage"
            ></b-pagination-nav>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";

export default {
    components: {
        ListLoader,
    },
    name: "IncomeSpacePartnerDetail",
    data() {
        return {
            moment: moment,
            items: {
                name: "Mitra Space",
                partner_type: "space",
            },
            detailData: [],
            currentPage: 0,
            totalPage: 0,
            limit: 10,
            incomeType: "",
            is_my_list_ready: false,
            noReceipt: "",
        };
    },
    created() {
        this.getDetailIncome();
    },
    methods: {
        searchReceipt() {
            this.getDetailIncome();
        },
        changePage() {
            this.getDetailIncome();
        },
        getExport() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/fno/income/partner/space/export`,
                {
                    params: {
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 1,
                        limit: this.limit,
                        search: this.noReceipt,
                        type: this.incomeType,
                        partner_code: this.$route.params.partnerid,
                    },
                    responseType: "blob",
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data_excel_request = res.data;
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        moment().format("DD MMM YYYY") + `.xls`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
        getDetailIncome() {
            this.is_my_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/fno/income/partner/space`,
                {
                    params: {
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 1,
                        limit: this.limit,
                        partner_code: this.$route.params.partnerid,
                        search: this.noReceipt,
                        type: this.incomeType,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detailData = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        currency(number) {
            return new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
            }).format(number);
        },
        filterIncomeType(data, type) {
            if (data && data.length) {
                if (type == "pickup") {
                    let result = data.filter((el) => {
                        if (el.income_type == "Pickup") {
                            return el;
                        }
                    });

                    return result;
                }
                if (type == "dooring") {
                    let result = data.filter((el) => {
                        if (el.income_type == "Dooring") {
                            return el;
                        }
                    });
                    return result;
                }

                return data;
            }

            return null;
        },
    },
};
</script>

<style scoped>
.scroll {
    height: 500px;
    overflow-y: scroll;
}
.scroll-x {
    /* height: 500px; */
    overflow-x: scroll;
}
.row.foto {
    --bs-gutter-x: 0rem !important;
}
</style>
