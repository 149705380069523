<template>
  <div class="padding-container">
    <div class="d-flex bd-highlight mb-3 align-items-center">
      <div class="flex-grow-1 bd-highlight">
        <h4 class="fw-bold">Incoming Data Order</h4>
      </div>
    </div>
    <div class="box">
      <div class="d-flex bd-highlight">
        <div class="flex-grow-1 bd-highlight">
          <h4 class="fw-bold">Incoming Order</h4>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="position-relative">
              <input v-model="keySearch" @keyup.enter="searchData" type="text" class="form-control" style="width: 155px" placeholder="Cari No Resi" />
              <span class="search-icon">
                <i class="fa fa-search"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4" v-if="is_my_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">Nomor Resi</div>
            <div class="d-table-cell">Nama Customer</div>
            <div class="d-table-cell">Rute Sewa Truk</div>
            <div class="d-table-cell">Tanggal Sewa</div>
            <div class="d-table-cell">Total Harga Sewa</div>
            <div class="d-table-cell">Kendaraan</div>
          </div>
          <div class="d-table-row" v-for="(item, index) in data" :key="index">
            <div class="d-table-cell">{{ item.no }}</div>
            <div class="d-table-cell">
              {{ item.receipt_code }}
              <!-- <router-link class="text-dark fw-bold" :to="{ name: items.url, params: { partnerid: item.partner_code } }">{{ item.partner_code }}</router-link> -->
            </div>
            <div class="d-table-cell">
              {{ item.customer.name }}
              <!-- <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" :title="item.partner_address"> {{ item.partner_regency }} <i class="fa fa-info-circle text-blue ms-1"></i> </a> -->
            </div>
            <div class="d-table-cell">{{ item.route }}</div>
            <div class="d-table-cell">{{ dateFormat2(item.beginpickup_at) }}</div>
            <div class="d-table-cell">{{ currency(item.total_price) }}</div>
            <div class="d-table-cell">{{ fleetType(item.type_fleet) }}</div>
            <div class="d-table-cell">
              <button class="add-button" @click="getDetailData(item.id)">Terima</button>
            </div>
          </div>
        </div>
        <div v-if="data.length == 0" class="text-center mt-section">
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
    </div>
    <div class="overflow-auto mt-3">
      <b-pagination-nav v-if="data.length > 0" v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="showModalOrder">
        <div class="modal-body-chat sm" v-if="showModalOrder">
          <div class="d-flex bd-highlight align-items-lg-center">
            <div class="flex-grow-1 bd-highlight">
              <div class="d-flex align-items-center">
                <div class="me-3">
                  <img src="../../assets/icon-people.png" width="48" height="48" style="object-fit: cover" />
                </div>
                <div>
                  <div class="fw-bold size-16">Order Baru</div>
                  <div class="text-gray">Tentukan apakah mau menerima orderan?</div>
                </div>
              </div>
            </div>
            <div class="bd-highlight">
              <a href="javascript:void(0)" @click="showModalOrder = false" class="size-20 text-primary">
                <i class="fa fa-times-circle"></i>
              </a>
            </div>
          </div>
          <hr />
          <div>
            <div class="d-flex bd-highlight mb-3 align-items-center">
              <div class="flex-grow-1 bd-highlight fw-bold">Nama Customer</div>
              <div class="bd-highlight">{{ detailData.customer }}</div>
            </div>
            <div class="d-flex bd-highlight mb-3 align-items-center">
              <div class="flex-grow-1 bd-highlight fw-bold">Tujuan</div>
              <div class="bd-highlight">{{ detailData.route }}</div>
            </div>
            <div class="d-flex bd-highlight mb-3">
              <div class="flex-grow-1 bd-highlight fw-bold">Harga Sewa</div>
              <div class="bd-highlight text-end">{{ currency(detailData.total_price) }}</div>
            </div>
            <div class="d-flex bd-highlight mb-3 align-items-center">
              <div class="flex-grow-1 bd-highlight fw-bold">Tanggal Order</div>
              <div class="bd-highlight">{{ dateFormat(detailData.created_at) }}</div>
            </div>
            <div class="d-flex bd-highlight mb-3 align-items-center">
              <div class="flex-grow-1 bd-highlight fw-bold">Jenis Kendaraan</div>
              <div class="bd-highlight">{{ fleetType(detailData.type_fleet) }}</div>
            </div>
            <div class="d-flex bd-highlight mb-3">
              <div class="flex-grow-1 bd-highlight fw-bold">Jenis Order</div>
              <div class="bd-highlight text-end">
                {{ typeOrder(detailData.type_order) }}<br />
                <span class="text-gray">{{ dateFormat2(detailData.beginpickup_at) }}</span>
              </div>
            </div>
          </div>
          <div>
            <div class="fw-bold size-16 mb-2 mt-4">Pilih Kendaraan Dan Driver</div>
            <select class="mb-3 form-input" @change="setFleet" v-model="fleetSelected">
              <option value="" selected hidden>Pilih Kendaraan</option>
              <option v-for="(item, index) in fleetData" :key="index" :value="item">{{ item.fleet_type }} ({{ item.fleet_number }})</option>
            </select>
            <select class="mb-3 form-input" @change="fillForm" v-model="req.id_driver">
              <option value="" selected hidden>Pilih Driver</option>
              <option v-for="(item, index) in driverData" :key="index" :value="item.id">{{ item.driver }}</option>
            </select>
          </div>

          <div class="d-flex gap-2 mt-3">
            <button class="button button--save" @click="submitData" :disabled="isSaving || isFilled">
              <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
              Tugaskan
            </button>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <div class="modal-vue">
              <div class="overlay" v-if="showModalWarning1">
                <div class="modal-body-chat vsm" v-if="showModalWarning1">
                  <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                      <div class="d-flex align-items-center">
                        <div class="me-3">
                          <img src="../../assets/cde-blue-icon.png" width="48" height="48" style="object-fit: cover" />
                        </div>
                      </div>
                    </div>
                    <div class="bd-highlight">
                      <a href="javascript:void(0)" @click="showModalWarning1 = false" class="size-20 text-primary">
                        <i class="fa fa-times-circle"></i>
                      </a>
                    </div>
                  </div>
                  <div class="fw-ekstra-bold size-20 mt-2 mb-1">Kendaraan Anda Tidak Sesuai</div>
                  <div class="size-16">Sesuaikan kendaraan dengan jenis kendaraan yang dipilih customer</div>
                  <button class="button button--save mt-4" @click="showModalWarning1 = false" :disabled="isSaving">Oke</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";

export default {
  props: {},
  components: {
    ListLoader,
  },
  name: "ManagementOrder",
  data() {
    return {
      showModal: false,
      showModalOrder: false,
      showModalWarning1: false,
      is_my_list_ready: false,
      isFilled: true,
      isSaving: false,
      add_form: false,
      data: [],
      detailData: [],
      driverData: [],
      fleetData: [],
      fleetSelected: "",
      driverSelected: "",
      estPrice: {},
      address: "",
      req: {
        origin_name: "",
        origin_phone: "",
        origin_note: "",
        origin_lat: "",
        origin_lon: "",
        date: "",
        time: "",
        type_service: "",
        type_order: "",
        type_ordersub: "",
        type_fleet: "",
        pickup_schedule: "",
        helper_count: "",
        id_order: "",
        id_driver: "",
        id_fleet: "",
        dest_name: "",
        dest_phone: "",
        dest_note: "",
        dest_lat: "",
        dest_lon: "",
        dest2_name: "",
        dest2_phone: 0,
        dest2_note: "",
        dest2_lat: null,
        dest2_lon: null,
        insurance: "",
      },
      currentPage: 0,
      totalPage: 0,
      limit: 10,
      keySearch: "",
      datePicker: "",
      filter_status: "",
      map_data: { lat: null, lng: null },
      driverMarker: {
        lat: 0,
        lng: 0,
      },
      markers: [
        {
          lat: -6.233035800000001,
          lng: 106.8397427,
        },
      ],
      lat_move: "",
      lng_move: "",
      lat_search: "",
      lng_search: "",
      dummy: [
        {
          Nomor_Resi: "TRT000001",
          Nama_Customer: "Supratman",
          Rute_Sewa_Truk: "Jakarta - Makassar",
          Tanggal_Sewa: "12 Januari 2022",
          Total_Harga_Sewa: "Rp 560.000.000",
          Driver_Ditugaskan: "Maman Soeherman",
          Kendaraan: "CDD Long Box",
          Status: "Dalam Perjalanan",
        },
        {
          Nomor_Resi: "TRT000001",
          Nama_Customer: "Supratman",
          Rute_Sewa_Truk: "Jakarta - Makassar",
          Tanggal_Sewa: "12 Januari 2022",
          Total_Harga_Sewa: "Rp 560.000.000",
          Driver_Ditugaskan: "Maman Soeherman",
          Kendaraan: "CDD Long Box",
          Status: "Dalam Perjalanan",
        },
        {
          Nomor_Resi: "TRT000001",
          Nama_Customer: "Supratman",
          Rute_Sewa_Truk: "Jakarta - Makassar",
          Tanggal_Sewa: "12 Januari 2022",
          Total_Harga_Sewa: "Rp 560.000.000",
          Driver_Ditugaskan: "Maman Soeherman",
          Kendaraan: "CDD Long Box",
          Status: "Dalam Perjalanan",
        },
      ],
    };
  },
  created() {
    this.getListData();
    this.getFleet();
    this.getDriver();
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
  },
  methods: {
    filterData() {
      this.currentPage = 0;
      this.getListData();
    },
    searchData() {
      this.currentPage = 0;
      this.getListData();
    },
    changePage() {
      this.getListData();
    },
    typeOrder(value) {
      switch (value) {
        case "quick":
          return "Cepat";
        case "schedule":
          return "Terjadwal";
        case "fullday":
          return "Seharian";
        default:
          return value;
      }
    },
    fleetType(value) {
      switch (value) {
        case "bike":
          return "Sepeda Motor";
        case "mpv":
          return "MPV";
        case "pickup":
          return "Pickup";
        case "pickup-box":
          return "Pickup Box";
        case "pickup-bak":
          return "Pickup Bak";
        case "cde engkel":
          return "(CDE) Engkel";
        case "cde-bak":
          return "(CDE) Engkel Bak";
        case "cde-box":
          return "(CDE) Engkel Box";
        case "cdelong-box":
          return "(CDE) Long Box";
        case "cdd-bak":
          return "(CDD) Colt Diesel Double Bak";
        case "cdd-box":
          return "(CDD) Colt Diesel Double Box";
        case "cddlong-bak":
          return "(CDD) Long Bak";
        case "cddlong-box":
          return "(CDD) Long Box";
        case "cdd-reefer":
          return "(CDD) Thermo King/Reefer";
        case "fuso-bak":
          return "Fuso Bak";
        case "fuso-box":
          return "Fuso Box";
        case "engkel box":
          return "Engkel Box";
        case "engkel double":
          return "Engkel Double";
        case "engkel double box":
          return "Engkel Double Box";
        case "fuso 6m":
          return "Fuso 6M";
        case "fuso 9m":
          return "Fuso 9M";
        case "tronton-bak":
          return "Tronton Bak";
        case "tronton-box":
          return "Tronton Box";
        case "wingbox":
          return "Wingbox";
        case "van":
          return "Van";
        case "blind-van":
          return "Blind Van";
        case "towing":
          return "Towing";
        case "selfloader":
          return "Selfloader";
        case "trailer-20feet":
          return "Trailer 20ft";
        case "trailer-40feet":
          return "Trailer 40ft";
        case "lowbed":
          return "Lowbed";
        default:
          return value;
      }
    },
    dateFormat(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    addForm() {
      this.add_form = !this.add_form;
      if (!this.add_form) {
        this.req.dest2_lat = null;
        this.req.dest2_lon = null;
        this.req.dest2_name = "";
        this.req.dest2_phone = 0;
        this.req.dest2_note = "";
      }
    },
    getLocOrigin(data) {
      this.req.origin_lat = data.lat;
      this.req.origin_lon = data.lng;
      this.fillForm();
    },
    getLocDest(data) {
      this.req.dest_lat = data.lat;
      this.req.dest_lon = data.lng;
      this.fillForm();
    },
    getLocDest2(data) {
      this.req.dest2_lat = data.lat;
      this.req.dest2_lon = data.lng;
      this.fillForm();
    },
    setTime() {
      this.req.pickup_schedule = this.req.date + " " + this.req.time;
      if (this.req.date != "" && this.req.time != "") {
        this.fillForm();
      }
    },
    setFleet() {
      if (this.fleetSelected.fleet_type != this.detailData.type_fleet) {
        this.showModalWarning1 = true;
        this.req.id_fleet = "";
        this.fleetSelected = "";
        this.fillForm();
      } else {
        this.req.type_fleet = this.fleetSelected.fleet_type;
        this.req.id_fleet = this.fleetSelected.id;
        this.fillForm();
      }
    },
    fillForm() {
      console.log("1", this.req.id_driver, "2", this.req.id_fleet);
      if (this.req.id_driver != "" && this.req.id_fleet != "") {
        this.isFilled = false;
      } else {
        this.isFilled = true;
      }
    },
    getEstimatePrice() {
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/order/estimate`, {
        params: {
          id_driver: this.req.id_driver,
          type_fleet: this.req.type_fleet,
          type_order: this.req.type_order,
          type_ordersub: this.req.type_ordersub,
          pickup_schedule: this.req.pickup_schedule,
          type_service: this.req.type_service,
          helper_count: this.req.helper_count,
          origin_lat: this.req.origin_lat,
          origin_lon: this.req.origin_lon,
          dest_lat: this.req.dest_lat,
          dest_lon: this.req.dest_lon,
          dest2_lat: this.req.dest2_lat,
          dest2_lon: this.req.dest2_lon,
          insurance: this.req.insurance,
          origin_name: this.req.origin_name,
          origin_phone: this.req.origin_phone,
          origin_note: this.req.origin_note,
          dest_name: this.req.dest_name,
          dest_phone: this.req.dest_phone,
          dest_note: this.req.dest_note,
          dest2_name: this.req.dest2_name,
          dest2_phone: this.req.dest2_phone,
          dest2_note: this.req.dest2_note,
        },
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.estPrice = res.data.data;
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
        });
    },
    getListData() {
      this.is_my_list_ready = true;
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/order/pending`, {
        params: { page: this.currentPage == 0 || this.currentPage == null ? 0 : this.currentPage - 1, limit: this.limit, availability: this.filter_status, search: this.keySearch },
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data = res.data.data;
          this.totalPage = res.data.data.total_page;
          this.is_my_list_ready = false;
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          this.is_my_list_ready = false;
          console.log(err);
        });
    },
    submitData() {
      this.isSaving = true;
      var data = new FormData();

      data.append("driver_id", this.req.id_driver);
      data.append("fleet_id", this.req.id_fleet);
      data.append("order_id", this.req.id_order);

      Api.post(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/order/pending`, data, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.showModalOrder = false;
          this.isSaving = false;
          this.$notify({
            group: "foo",
            type: "success",
            title: "Success",
            text: "Data berhasil dibuat",
          });
          this.getListData();
          this.clear();
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.isSaving = false;
        });
    },
    clear() {
      this.req.id_driver = "";
      this.req.type_fleet = "";
      this.req.type_order = "";
      this.req.type_ordersub = "";
      this.req.pickup_schedule = "";
      this.req.type_service = "";
      this.req.helper_count = "";
      this.req.origin_lat = "";
      this.req.origin_lon = "";
      this.req.dest_lat = "";
      this.req.dest_lon = "";
      this.req.dest2_lat = null;
      this.req.dest2_lon = null;
      this.req.origin_name = "";
      this.req.origin_phone = "";
      this.req.origin_note = "";
      this.req.dest_name = "";
      this.req.dest_phone = "";
      this.req.dest_note = "";
      this.req.dest2_name = "";
      this.req.dest2_phone = 0;
      this.req.dest2_note = "";
    },
    getDetailData(id) {
      this.showModalOrder = true;
      this.req.id_order = id;
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/order/detail`, {
        params: { order_id: id },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.detailData = res.data.data;
        })
        .catch(function (err) {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          console.error(err);
        });
    },
    getFleet() {
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/order/fleet`, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.fleetData = res.data.data;
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          this.is_my_list_ready = false;
          console.log(err);
        });
    },
    getDriver() {
      this.is_my_list_ready = true;
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/order/driver`, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.driverData = res.data.data;
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          this.is_my_list_ready = false;
          console.log(err);
        });
    },
    isPrice(amount) {
      return amount ? this.currency(amount) : "Rp 0";
    },
    currency(number) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(number);
    },
    dateFormat2(date) {
      return moment(date).format("DD MMMM YYYY HH:mm");
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.scroll {
  height: 500px;
  overflow-y: scroll;
}
.row.foto {
  --bs-gutter-x: 0rem !important;
}
.add-button {
  background: #48a2d4;
  border-radius: 8px;
  border: 1px solid #48a2d4;
  color: white;
  padding: 5px 15px;
}
.add-button:is(:hover, :focus) {
  background: #176f9e;
}
.form-input {
  background: rgba(239, 241, 249, 0.6);
  border-width: 0px;
  border: none;
  outline: none;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  padding: 10px;
}
.button {
  display: flex;
  border-radius: 12px;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  &--save {
    background: #48a2d4;
    border: 1px solid #48a2d4;
    color: white;
  }
  &--save:is(:hover, :focus) {
    background: #176f9e;
  }
  &--save:disabled {
    background: #e8e8e9;
    border: 1px solid #ffffff;
  }
  &--add {
    background: #ffffff;
    border: 1px solid #48a2d4;
    color: #48a2d4;
  }
}
</style>
