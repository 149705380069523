<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="mb-3">
                <h3 class="fw-bold capitalize">Requested Troben Carier List</h3>
            </div>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-3 m-mx-0">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Search Company Name or Owner Name"
                            v-model="search"
                            @input="searchPage()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_load_data">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Company Name
                                </th>
                                <th class="text-center" scope="col">
                                    Owner Name
                                </th>
                                <th class="text-center" scope="col">Phone</th>
                                <th class="text-center" scope="col">
                                    Created At
                                </th>
                                <th class="text-center" scope="col">
                                    L. Count
                                </th>
                                <th class="text-center" scope="col">Leads</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in requested.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td class="capitalize">
                                    <a
                                        v-if="item.candidate_id"
                                        :href="
                                            `/crm/partnership/requested-trawlcarrier/detail?id=` +
                                            item.id +
                                            `&candidate_id=` +
                                            item.candidate_id
                                        "
                                        class="text-link"
                                    >
                                        {{
                                            item.company_name
                                                ? item.company_name
                                                : "-"
                                        }}
                                    </a>
                                    <a
                                        v-else
                                        href="javascript:void(0)"
                                        @click="clickProspect(item.id)"
                                        class="text-link"
                                    >
                                        {{
                                            item.company_name
                                                ? item.company_name
                                                : "-"
                                        }}
                                    </a>
                                </td>
                                <td class="capitalize">
                                    {{ item.pic_name ? item.pic_name : "-" }}
                                </td>
                                <td class="text-center capitalize">
                                    {{ item.pic_phone ? item.pic_phone : "-" }}
                                </td>
                                <td class="text-center">
                                    {{
                                        moment(item.created_at).format(
                                            "DD/MM/YYYY"
                                        )
                                    }}
                                </td>
                                <td class="text-center">
                                    {{ item.prospect_count }}x
                                </td>
                                <td class="text-center">
                                    <a
                                        :href="
                                            `/crm/partnership/candidate/prospect/` +
                                            item.candidate_id
                                        "
                                        v-if="item.candidate_id"
                                        target="_blank"
                                    >
                                        <img
                                            src="../../assets/dashboard/prospect.png"
                                            alt=""
                                            width="19"
                                        />
                                    </a>
                                    <a
                                        href="javascript:void(0)"
                                        @click="clickProspect(item.id)"
                                        v-else
                                    >
                                        <img
                                            src="../../assets/dashboard/prospect.png"
                                            alt=""
                                            width="19"
                                        />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-if="requested.list_data.length == 0"
                    class="text-center mt-section"
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="d-flex" v-if="requested.list_data.length > 0">
                <div class="ms-auto">
                    <div class="d-flex mt-4">
                        <div class="me-3">
                            <select
                                class="form-control form-select w-select"
                                v-model="limit"
                                @change="getList()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <b-pagination-nav
                            v-model="currentPage"
                            :number-of-pages="requested.total_page"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="showModalProspect"></div>
            <div class="modal-body-chat vsm" v-if="showModalProspect">
                <div class="mt-2 text-center">
                    <img src="../../assets/info-yellow.png" width="70" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Are you Sure you want to Prospect this Data?
                    </h4>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6">
                        <button
                            class="btn btn-outline-black btn-lg w-100"
                            @click="showModalProspect = false"
                        >
                            Cancel
                        </button>
                    </div>
                    <div class="col-md-6">
                        <a
                            href="javascript:void(0)"
                            @click="submitProspect()"
                            :disabled="is_submit_prospect"
                            class="btn btn-green w-100 btn-lg"
                        >
                            <span
                                v-if="is_submit_prospect"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Ok
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";

export default {
    name: "crmPartnershipRequestedTrawlcarrier",
    components: {
        ListLoader,
    },
    props: {},
    data() {
        return {
            moment: moment,
            is_load_data: false,
            search: "",
            requested: {
                list_data: [],
            },
            limit: 10,
            currentPage: 0,
            prospect_id: null,
            showModalProspect: false,
            is_submit_prospect: false,
        };
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            this.is_load_data = true;
            var params = {
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
                limit: this.limit,
                search: this.search,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/requested/trawlcarrier`,
                {
                    params: params,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.requested = data;
                    this.is_load_data = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_data = false;
                });
        },
        changePage() {
            this.getList();
        },
        searchPage() {
            this.getList();
            this.currentPage = 0;
        },
        clickProspect(id) {
            this.prospect_id = id;
            this.showModalProspect = true;
        },
        submitProspect() {
            this.is_submit_prospect = true;
            var data = {
                id: this.prospect_id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/requested/trawlcarrier/prospect`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    // this.getList();
                    this.showModalProspect = false;
                    this.is_submit_prospect = false;
                    window.location.href = "/crm/partnership/prospect/today";
                })
                .catch((err) => {
                    console.log(err);
                    this.is_submit_prospect = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.data.message,
                    });
                });
        },
    },
    computed: {},
};
</script>
<style lang="scss" scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
</style>
