<template>
    <div class="mt-4">
        <crmPartnershipProspect :items="items" />
    </div>
</template>

<script>
import crmPartnershipProspect from "./crmPartnershipProspect.vue";

export default {
    components: {
        crmPartnershipProspect,
    },
    data() {
        return {
            items: {
                name: "Prospect Next month",
                prospect_type: "next_month",
            },
        };
    },
};
</script>
