<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/trawltruck/ho/fleet" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4 pb-0">
            <div class="d-flex align-items-center mb-5">
                <h3 class="fw-bold mb-0">
                    Informasi Data Truk
                </h3>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">
                    Jenis Truk
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9 capitalize">
                    {{ (detail.fleet_type || "").split("-").join(" ") }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">
                    Nama Mitra (Kode Mitra)
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.partner_name }} ({{ detail.partner_code }})
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">
                    Tahun Kendaraan
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.fleet_year }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">
                    Nomor Polisi
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.fleet_number }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">
                    Nomor Rangka
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.fleet_identity }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">
                    Status
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    <template v-if="detail.status == 'accept'">
                        <small class="tag green mb-0 px-3 capitalize">
                            Aktif
                        </small>
                    </template>
                    <template v-else>
                        <small class="tag red mb-0 px-3 capitalize">
                            Tidak Aktif
                        </small>
                    </template>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">
                    Foto Truk
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    <div class="d-flex align-items-center">
                        <div>
                            <img src="../../assets/dashboard/image.png" width="30" alt="" />
                        </div>
                        <div class="ms-auto">
                            <a :href="detail.fleet_picture_url" target="_blank" download class="text-green">
                                Download Foto Truk
                                <i class="fa fa-download ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">
                    Foto BPKB
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    <div class="d-flex align-items-center">
                        <div>
                            <img src="../../assets/dashboard/image.png" width="30" alt="" />
                        </div>
                        <div class="ms-auto">
                            <a :href="detail.ownership_picture_url" target="_blank" download class="text-green">
                                Download Foto BPKB
                                <i class="fa fa-download ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 col-md-2 fw-bold">
                    Foto STNK
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    <div class="d-flex align-items-center">
                        <div>
                            <img src="../../assets/dashboard/image.png" width="30" alt="" />
                        </div>
                        <div class="ms-auto">
                            <a :href="detail.registration_picture_url" target="_blank" download class="text-green">
                                Download Foto STNK
                                <i class="fa fa-download ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
        </div>
        <div class="box mt-5">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">
                    Riwayat Perjalanan
                </h3>
            </div>
            <div class="row mt-5 m-mt-1">
                <div class="col-md-5">
                    <div class="position-relative">
                        <input type="text" class="form-control form-control-lg" placeholder="Cari data..." v-model="req.search" @input="changeSearch()" />
                        <span class="search-icon">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_history_ready">
                <ListLoader/>
            </div>
            <div v-else>
                <template v-if="histories.list_data.length > 0">
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">No Resi</th>
                                    <th class="text-center" scope="col">Tanggal</th>
                                    <th class="text-center" scope="col">Rute</th>
                                    <th class="text-center" scope="col">Driver</th>
                                    <th class="text-center" scope="col">Mitra</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(data, index) in histories.list_data">
                                    <tr :key="index">
                                        <td class="text-center">
                                            {{ index+1 }}
                                        </td>
                                        <td class="text-center">
                                            {{ data.code }}
                                        </td>
                                        <td class="text-center">
                                            {{ moment(data.order_date).format("DD MMMM YYYY") }}
                                        </td>
                                        <td class="text-center" style="width: 150px;">
                                            <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" :title="data.route">
                                                <div class="limit-one-line">
                                                    {{ data.route }}
                                                </div>
                                            </a>
                                        </td>
                                        <td class="text-center capitalize">
                                            {{ data.driver }}
                                        </td>
                                        <td class="text-center">
                                            {{ data.partner_name }}
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-section">
                        <img src="../../assets/no-data.png" width="100" alt="">
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </template>
            </div>

            <div class="mt-4">
                <b-pagination-nav v-if="histories.list_data.length > 0" v-model="currentPage" :number-of-pages="histories.total_page" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav>
            </div>
        </div>
    </div>
</template>

<script>
    import { Tooltip } from "bootstrap";
    import Api from "../../api/Api";
    import moment from 'moment';
    import ListLoader from '../../components/ListLoader.vue';

    export default {
        components: {
            ListLoader
        },
        name: "ManagementTruckDetail",
        data() {
            return {
                moment: moment,
                is_list_ready: false,
                is_history_ready: false,
                detail: {},
                histories: {
                    list_data: [],
                    next_page: 0,
                    total_data: 0,
                    total_page: 0,
                    current_page: 0
                },
                per_page: 10,
                currentPage: 0,
                req: {
                    search: ''
                }
            };
        },
        mounted() {
            new Tooltip(document.body, {
                selector: "[data-bs-toggle='tooltip']",
            });
        },
        created() {
            this.getDetail()
            this.getHistories()
        },
        computed: {
        },
        methods: {
            changePage() {
                this.getHistories();
            },
            changeSearch(){
                this.currentPage = 0
                this.getHistories()
            },
            getDetail() {
                this.is_list_ready = true
                Api.get(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/fleet/detail?id=${this.$route.params.id}`, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    var data = res.data.data
                    this.detail = data
                    this.is_list_ready = false
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false
                });
            },
            getHistories() {
                this.is_history_ready = true
                Api.get(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/fleet/history?id=${this.$route.params.id}`, {
                    params: {
                        search: this.req.search,
                        limit: this.per_page,
                        page: this.currentPage == 0 || this.currentPage == null ? 0 : this.currentPage - 1
                    },

                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log(res)
                    var data = res.data.data
                    this.histories = data
                    this.is_history_ready = false
                })
                .catch((err) => {
                    console.log(err);
                    this.is_history_ready = false
                });
            },
        }
    };
</script>

<style scoped>
    .text-main-color{
        color: #24516A !important;
    }
    .text-gray-dashboard{
        color: #61616A;
    }
    .foto-box{
        width: 100px;
        height: 50px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 5px;
        position: relative;
    }.nav-link.dashboard.center{
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    .nav-link.dashboard.left{
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    .nav-link.dashboard.active{
        background-color: #E60013 !important;
    }
    .w-33{
        width: 33.33%;
    }
    th{
        border-color: #E8E8E9;
        padding: 10px !important;
    }
    tr{
        border-color: #E8E8E9;
        padding: 10px !important;
    }
    td{
        padding: 10px !important;
    }
    .table-light{
        background-color: #F6FAFF !important;
        --bs-table-border-color: #F6FAFF !important;
        --bs-table-bg: #F6FAFF !important;
    }
</style>