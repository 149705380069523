<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/trawltruck/ho/partner" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center no-flex">
                <h3 class="fw-bold mb-0">
                    Ubah Data Mitra
                </h3>
                <div class="ms-auto m-mt-1">
                    <a href="javascript:void(0)" class="btn btn-green px-4 m-w-100" @click="modalChangePassword = true">
                        Ubah Password
                    </a>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Kode Mitra</label>
                    <input type="text" class="form-control" placeholder="Masukkan Kode Mitra" v-model="req.code" disabled>
                </div>
                <div class="col-md-6">
                    <label class="fw-bold mb-2 m-mt-1">Email</label>
                    <input type="email" class="form-control" placeholder="Masukkan Alamat Email" v-model="req.email">
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nama Mitra</label>
                    <input type="text" class="form-control" placeholder="Masukkan Nama Mitra" v-model="req.name">
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nomor Telepon</label>
                    <input type="number" class="form-control" placeholder="Masukkan  Nomor Telepon" v-model="req.phone">
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nama Penanggung Jawab</label>
                    <input type="text" class="form-control" placeholder="Masukkan Nama Penanggung Jawab" v-model="req.owner.name">
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nomor Telepon Penanggung Jawab</label>
                    <input type="number" class="form-control" placeholder="Masukkan  Nomor Telepon" v-model="req.owner.phone">
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Nama Bank</label>
                    <select class="form-control form-select" v-model="req.bank.bank_id">
                        <option value="">Pilih Bank</option>
                        <template v-for="(bank, index) in banks">
                            <option :value="bank.id" :key="index">
                                {{ bank.name }}
                            </option>
                        </template>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nomor Rekening Penanggung Jawab </label>
                    <input type="number" class="form-control" placeholder="Masukkan Nomor Rekening Penanggung Jawab" v-model="req.bank.number">
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Atas Nama </label>
                    <input type="text" class="form-control" placeholder="Masukkan Atas Nama" v-model="req.bank.account">
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Alamat</label>
                    <textarea rows="5" cols="4" class="form-control" placeholder="Masukkan Alamat" v-model="req.address"></textarea>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Status</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="active" value="active" v-model="req.status">
                            <label class="form-check-label fw-bold" for="active">Aktif</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="disable" value="disable" v-model="req.status">
                            <label class="form-check-label fw-bold" for="disable">Tidak Aktif</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold">Foto KTP Penanggung Jawab</label>
                    <div class="text-gray mb-2">
                        Foto full KTP maks 5MB
                    </div>
                    <div class="image-view-1" :style="{'background-image': 'url(' + (req.logo_picture_url) + ')'}">
                        <div class="mt-4 ml-4">
                            <label for="logo_picture" class="upload-button single-image"> 
                                <i class="fa fa-upload"></i>
                                <input type="file" id="logo_picture" @change="uploadKTP()">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold">NIB Perusahaan</label>
                    <div class="text-gray mb-2">
                        File PDF maks 5MB
                    </div>
                    <div class="image-view-2" :style="{'background-image': 'url(' + (req.identity_picture_url) + ')'}">
                        <div class="mt-4 ml-4">
                            <label for="identity_picture" class="upload-button single-image"> 
                                <i class="fa fa-upload"></i>
                                <input type="file" id="identity_picture" @change="uploadNIB()">
                            </label>
                        </div>
                    </div>
                </div>  
                <div class="col-md-4 mt-3">
                    <label class="fw-bold">Berkas Compro (Optional)</label>
                    <div class="text-gray mb-2">
                        File PDF maks 5MB
                    </div>
                    <div class="image-view-3" :style="{'background-image': 'url(' + (req.business_picture_url) + ')'}">
                        <div class="mt-4 ml-4">
                            <label for="business_picture" class="upload-button single-image"> 
                                <i class="fa fa-upload"></i>
                                <input type="file" id="business_picture" @change="uploadCOMPRO()">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <!-- <a href="/trawltruck/ho/partner" class="btn btn-outline-black btn-lg px-5 me-3">Batal</a> -->
                            <a href="/trawltruck/ho/partner" class="btn btn-outline-black btn-lg px-5 me-3 m-w-100">Batal</a>
                            <button class="btn btn-green btn-lg px-5 me-3 m-w-100 m-mt-1" type="button" disabled v-if="is_save">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Update
                            </button>
                            <button class="btn btn-green btn-lg px-5 me-3 m-w-100 m-mt-1" @click="updateData()" v-else>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img src="../../assets/dashboard/checklis.png" width="80" alt="">
                    <h4 class="mb-0 fw-bold mt-4">
                        Perubahan Data Berhasil!
                    </h4>
                    <div class="mt-2">
                        Selamat! Anda berhasil merubah data.
                    </div>
                </div>
                <div class="mt-4">
                    <a href="/trawltruck/ho/partner" class="btn btn-green w-100 btn-lg">
                        Ok
                    </a>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessChangePassword"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessChangePassword">
                <div class="mt-4 text-center">
                    <img src="../../assets/dashboard/checklis.png" width="80" alt="">
                    <h4 class="mb-0 fw-bold mt-4">
                        Perubahan Password Berhasil!
                    </h4>
                    <div class="mt-2">
                        Selamat! Anda berhasil merubah password.
                    </div>
                </div>
                <div class="mt-4">
                    <a href="javascript:void(0)" class="btn btn-green w-100 btn-lg" @click="clearPassword()">
                        Ok
                    </a>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalChangePassword"></div>
            <div class="modal-body-chat vsm" v-if="modalChangePassword">
                <h4 class="mb-0 fw-bold mt-3">
                    Ubah Password Anda!
                </h4>
                <div class="mt-4">
                    <div class="position-relative">
                        <input type="text" class="form-control" id="password" placeholder="Password" v-model="params.password" v-if="showPassword" />
                        <input type="password" class="form-control" id="password" placeholder="Password" v-model="params.password" v-else />
                        <span class="search-icon cursor-pointer" @click="toggleShow">
                            <i class="fa" :class="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"></i>
                        </span>
                    </div>
                </div>
                <div class="mt-3">
                    <div class="position-relative">
                        <input type="text" class="form-control" id="password" placeholder="Konfirmasi Password" v-model="params.password_confirmation" v-if="showPasswordConfirmation" />
                        <input type="password" class="form-control" id="password" placeholder="Konfirmasi Password" v-model="params.password_confirmation" v-else />
                        <span class="search-icon cursor-pointer" @click="toggleShowConfirmation">
                            <i class="fa" :class="{ 'fa-eye': showPasswordConfirmation, 'fa-eye-slash': !showPasswordConfirmation }"></i>
                        </span>
                    </div>
                </div>
                <div class="mt-4">
                    <button class="btn btn-green w-100 btn-lg" type="button" disabled v-if="is_update_password">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Ok
                    </button>
                    <button class="btn btn-green w-100 btn-lg" @click="updatePassword()" v-else>
                        Ok
                    </button>
                </div>
                <div class="mt-3">
                    <button class="btn btn-outline-black btn-lg w-100" @click="modalChangePassword = false">
                        Batal
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery'
    import Api from "../../api/Api";

    export default {
        props: "",
        name: "ManagementMitraEdit",
        data() {
            return {
                showPassword: false,
                showPasswordConfirmation: false,
                is_changing_logo_picture: false,
                is_changing_identity_picture: false,
                is_changing_business_picture: false,
                req:{},
                modalSuccess: false,
                modalSuccessChangePassword: false,
                modalChangePassword: false,
                modalError: false,
                banks: [],
                is_save: false,
                is_update_password: false,
                params: {
                    password: '',
                    password_confirmation: '',
                }
            };
        },
        created(){
            this.getBank()
            this.getDetail()
        },
        methods: {
            clearPassword(){
                this.params.password = ''
                this.params.password_confirmation = ''
                this.modalSuccessChangePassword = false
            },
            toggleShow() {
                this.showPassword = !this.showPassword;
            },
            toggleShowConfirmation(){
                this.showPasswordConfirmation = !this.showPasswordConfirmation;
            },
            uploadKTP() {
                var logo_picture = new FileReader();
                logo_picture.onload = function (e) {
                    $('.image-view-1').css('background-image', 'url('+ e.target.result + ')')
                };
                logo_picture.readAsDataURL($('#logo_picture')[0].files[0]);
                this.is_changing_logo_picture = true
            },
            uploadNIB() {
                var identity_picture = new FileReader();
                identity_picture.onload = function (e) {
                    $('.image-view-2').css('background-image', 'url('+ e.target.result + ')')
                };
                identity_picture.readAsDataURL($('#identity_picture')[0].files[0]);
                this.is_changing_identity_picture = true
            },
            uploadCOMPRO() {
                var business_picture = new FileReader();
                business_picture.onload = function (e) {
                    $('.image-view-3').css('background-image', 'url('+ e.target.result + ')')
                };
                business_picture.readAsDataURL($('#business_picture')[0].files[0]);
                this.is_changing_business_picture = true
            },
            getDetail() {
                Api.get(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partner/detail?id=${this.$route.params.id}`, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log()
                    var data = res.data.data
                    this.req = data
                })
                .catch((err) => {
                    console.log(err);
                });
            },
            getBank() {
                Api.get(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partner/bank`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    var data = res.data.data
                    this.banks = data
                })
                .catch((err) => {
                    console.log(err);
                });
            },
            updateData(){
                var logo_picture = $('#logo_picture')[0].files[0]
                var identity_picture = $('#identity_picture')[0].files[0]
                var business_picture = $('#business_picture')[0].files[0]
                var data = new FormData()

                data.append('id', this.req.id)
                data.append('name', this.req.name)
                data.append('phone_partner', this.req.phone)
                data.append('owner_name', this.req.owner.name)
                data.append('phone', this.req.owner.phone)
                data.append('bank_id', this.req.bank.bank_id)
                data.append('bank_account', this.req.bank.number)
                data.append('bank_name', this.req.bank.account)
                data.append('address', this.req.address)
                data.append('status', this.req.status)

                if(this.is_changing_logo_picture) {
                    data.append('logo_picture',logo_picture)
                }
                if(this.is_changing_identity_picture) {
                    data.append('identity_picture',identity_picture)
                }
                if(this.is_changing_business_picture) {
                    data.append('business_picture',business_picture)
                }

                this.is_save = true

                Api.post(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partner/edit`, data, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    }
                })
                .then((res) => {
                    this.is_save = false
                    this.modalSuccess = true
                    this.is_changing_logo_picture = false
                    this.is_changing_identity_picture = false
                    this.is_changing_business_picture = false
                    console.log(res)
                })
                .catch((err) => {
                    this.is_save = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.message
                    });
                })
            },
            updatePassword(){
                this.is_update_password = true
                var data = {
                    id: this.req.id,
                    password: this.params.password,
                    password_confirmation: this.params.password_confirmation,
                }

                Api.post(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partner/password`, data, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    }
                })
                .then((res) => {
                    this.is_update_password = false
                    this.modalSuccessChangePassword = true
                    this.modalChangePassword = false
                    console.log(res)
                })
                .catch((err) => {
                    this.is_update_password = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.message
                    });
                })
            },
        }
    };
</script>

<style scoped>
    .text-main-color{
        color: #24516A !important;
    }
    .text-gray-dashboard{
        color: #61616A;
    }
</style>