var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-sidebar"},[_c('div',{staticClass:"p-4 mt-1"},[_c('center',[_c('span',{staticClass:"logo-white"},[_c('img',{staticClass:"m-none mt-3 mb-3",attrs:{"src":_vm.logo,"alt":""}})]),_c('div',{staticClass:"size-18 fw-medium mt-2"},[_vm._v("Trobens Admin")])])],1),_c('ul',{staticClass:"sidebar-menu",attrs:{"id":"system-menu"}},[_c('li',{class:{
                'sidebar-menu-list has-child': true,
                active: this.$route.name == 'Dashboard',
            }},[_c('router-link',{staticClass:"has-child sidebar-menu-link",attrs:{"to":{ name: 'Dashboard' }}},[_c('i',{staticClass:"fa fa-home me-2"}),_vm._v(" Dashboard ")])],1),_c('li',{staticClass:"sidebar-menu-list has-childa line m-none"}),_vm._m(0),_c('li',{class:{
                'sidebar-menu-list has-child': true,
                active: this.$route.name == 'Chat',
            }},[_c('router-link',{staticClass:"has-child sidebar-menu-link",attrs:{"to":{ name: 'Chat' }}},[_c('i',{staticClass:"fa fa-wechat me-2"}),_vm._v(" Chat ")])],1),_c('li',{staticClass:"sidebar-menu-list has-child"},[_c('router-link',{staticClass:"has-child sidebar-menu-link",attrs:{"to":{ name: 'admin-slider' }}},[_c('i',{staticClass:"fa fa-bar-chart-o me-2"}),_vm._v(" Chat Report ")])],1),_c('li',{staticClass:"sidebar-menu-list has-child"},[_c('router-link',{staticClass:"has-child sidebar-menu-link",attrs:{"to":{ name: 'admin-slider' }}},[_c('i',{staticClass:"fa fa-money me-2"}),_vm._v(" Pembayaran Customer ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"sidebar-menu-list has-child"},[_c('div',{staticClass:"has-child sidebar-menu-link text-gray"},[_vm._v(" TRAWLBENS PAGES ")])])
}]

export { render, staticRenderFns }