<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Cek Estimasi Tarif</h3>
            </div>
            <div class="row">
                <div class="col-md-7 mt-4">
                    <div class="box no-shadow border-gray">
                        <h6 class="mb-0 fw-bold">Alamat Penjemputan</h6>
                        <div class="mt-3">
                            <a
                                href="javascript:void(0)"
                                class="text-dark"
                                @click="openModalPickupAddress = true"
                            >
                                <div class="form-box">
                                    <div class="d-flex align-item-center">
                                        {{ address ? address : "Buka Maps" }}
                                        <div class="ms-auto">
                                            <i class="fa fa-pencil size-14"></i>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="box no-shadow border-gray mt-4">
                        <h6 class="mb-0 fw-bold">Alamat Penerima</h6>
                        <div class="mt-3">
                            <a
                                href="javascript:void(0)"
                                class="text-dark"
                                @click="openModalReceiver = true"
                            >
                                <div class="form-box">
                                    <div class="d-flex align-items-center">
                                        {{
                                            address_receiver
                                                ? address_receiver
                                                : "Buka Maps"
                                        }}
                                        <div class="ms-auto">
                                            <i class="fa fa-pencil size-14"></i>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="box no-shadow border-gray mt-4">
                        <h6 class="mb-0 fw-bold">Jenis Armada</h6>
                        <div class="mt-3">
                            <multiselect
                                v-model="fleet_type"
                                :options="armadas"
                                label="name"
                                track-by="code"
                                placeholder="Pilih Jenis Armada"
                            >
                            </multiselect>
                        </div>
                    </div>
                    <div class="mt-4 pull-right">
                        <button
                            class="btn btn-outline-primary btn-lg px-5 me-3"
                            @click="reset()"
                        >
                            Reset
                        </button>
                        <button
                            class="btn btn-green btn-lg px-5"
                            @click="cekTarif()"
                            :disabled="
                                map_data.lat == null ||
                                map_data.lng == null ||
                                map_data_receiver.lat == null ||
                                map_data_receiver.lng == null ||
                                fleet_type == '' ||
                                is_cek_tarif
                            "
                        >
                            <span
                                v-if="is_cek_tarif"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Cek Tarif
                        </button>
                    </div>
                </div>
                <div class="col-md-5 mt-4">
                    <div class="box no-shadow border-gray" v-if="code == ''">
                        <center>
                            <img
                                src="../../assets/search-map.png"
                                width="150"
                                alt=""
                            />
                            <div class="mt-3 size-18 text-gray">
                                Mohon pilih alamat dan armada terlebih dahulu
                            </div>
                        </center>
                    </div>
                    <div
                        class="box no-shadow border-gray"
                        v-if="code == '400' || code == '500'"
                    >
                        <center>
                            <img
                                src="../../assets/search-map-not-found.png"
                                width="150"
                                alt=""
                            />
                            <div class="mt-3 size-18 text-gray">
                                Mohon maaf data belum tersedia, mohon masukkan
                                lokasi lain
                            </div>
                        </center>
                    </div>
                    <template v-if="code == '200'">
                        <div class="box no-shadow border-gray p-3">
                            <div class="accordion" id="accordionFirstSection">
                                <div class="accordion-item">
                                    <h2
                                        class="accordion-header"
                                        id="headingOne"
                                    >
                                        <button
                                            class="accordion-button first mt-0"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            Rincian Tarif
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionFirstSection"
                                    >
                                        <div class="accordion-body">
                                            <table>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Jenis Armada
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            fleet_type.name
                                                                ? fleet_type.name
                                                                : "-"
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Jarak Tempuh
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            result.result_meta
                                                                .distance
                                                                ? result
                                                                      .result_meta
                                                                      .distance
                                                                : "-"
                                                        }}
                                                        Km
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Lokasi Tarif
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            result.result_meta
                                                                .destination_exact_exact
                                                                ? result
                                                                      .result_meta
                                                                      .destination_exact_exact
                                                                : "-"
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Lokasi Base
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            currency(
                                                                result
                                                                    .result_meta
                                                                    .base
                                                                    ? result
                                                                          .result_meta
                                                                          .base
                                                                    : 0
                                                            )
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Lokasi Km
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            currency(
                                                                result
                                                                    .result_meta
                                                                    .add_price
                                                                    ? result
                                                                          .result_meta
                                                                          .add_price
                                                                    : 0
                                                            )
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Minimal Km
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            result.result_meta
                                                                .km_min
                                                                ? result
                                                                      .result_meta
                                                                      .km_min
                                                                : 0
                                                        }}
                                                        Km
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Lokasi Final
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            currency(
                                                                result
                                                                    .result_meta
                                                                    .final
                                                                    ? result
                                                                          .result_meta
                                                                          .final
                                                                    : 0
                                                            )
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Platform Fee
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            currency(
                                                                result.platform_fee
                                                                    ? result.platform_fee
                                                                    : 0
                                                            )
                                                        }}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion" id="accordionSecondSection">
                            <div class="box no-shadow border-gray p-3 mt-4">
                                <div class="accordion-item">
                                    <h2
                                        class="accordion-header"
                                        id="headingOne"
                                    >
                                        <button
                                            class="accordion-button mt-0"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            Alamat Penjemputan
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionSecondSection"
                                    >
                                        <div class="accordion-body">
                                            <table>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Alamat Lengkap
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            address
                                                                ? address
                                                                : "-"
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Provinsi
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            result.origin
                                                                .province_name
                                                                .name
                                                                ? result.origin
                                                                      .province_name
                                                                      .name
                                                                : "-"
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Kota/Kabupaten
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            result.origin
                                                                .regency_name
                                                                .name
                                                                ? result.origin
                                                                      .regency_name
                                                                      .name
                                                                : "-"
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Kecamatan
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            result.origin
                                                                .district_name
                                                                .name
                                                                ? result.origin
                                                                      .district_name
                                                                      .name
                                                                : "-"
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Kelurahan
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            result.origin
                                                                .subdistrict_name
                                                                .name
                                                                ? result.origin
                                                                      .subdistrict_name
                                                                      .name
                                                                : "-"
                                                        }}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box no-shadow border-gray p-3 mt-4">
                                <div class="accordion-item">
                                    <h2
                                        class="accordion-header"
                                        id="headingTwo"
                                    >
                                        <button
                                            class="accordion-button collapsed mt-0"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            Alamat Penerima
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionSecondSection"
                                    >
                                        <div class="accordion-body">
                                            <table>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Alamat Lengkap
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            address_receiver
                                                                ? address_receiver
                                                                : "-"
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Provinsi
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            result.dest
                                                                .province_name
                                                                .name
                                                                ? result.dest
                                                                      .province_name
                                                                      .name
                                                                : "-"
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Kota/Kabupaten
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            result.dest
                                                                .regency_name
                                                                .name
                                                                ? result.dest
                                                                      .regency_name
                                                                      .name
                                                                : "-"
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Kecamatan
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            result.dest
                                                                .district_name
                                                                .name
                                                                ? result.dest
                                                                      .district_name
                                                                      .name
                                                                : "-"
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 200px"
                                                    >
                                                        Kelurahan
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                        style="width: 25px"
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        class="pt-2 fw-semibold"
                                                    >
                                                        {{
                                                            result.dest
                                                                .subdistrict_name
                                                                .name
                                                                ? result.dest
                                                                      .subdistrict_name
                                                                      .name
                                                                : "-"
                                                        }}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="openModalPickupAddress"></div>
            <div class="modal-body-chat md" v-if="openModalPickupAddress">
                <div class="mt-2 mb-1">
                    <div class="mt-3">
                        <label class="fw-semibold mb-3"
                            >Pilih Alamat Penjemputan</label
                        >
                        <GmapMap
                            ref="mapRef"
                            :center="{
                                lat: -6.233035800000001,
                                lng: 106.8397427,
                            }"
                            :zoom="16"
                            map-type-id="terrain"
                            style="height: 300px"
                        >
                            <div class="row justify-content-center pt-4">
                                <div class="col-md-9">
                                    <div class="position-relative">
                                        <GmapAutocomplete
                                            @place_changed="
                                                processLocationChanged
                                            "
                                            :value="address"
                                            placeholder="Cari Lokasi"
                                            class="form-control search-google"
                                        ></GmapAutocomplete>
                                        <span class="search-icon map">
                                            <i class="fa fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                ref="marker"
                                :position="m"
                                :clickable="true"
                                :draggable="true"
                                @dragend="movePlace"
                                @click="center = m"
                            >
                            </GmapMarker>
                        </GmapMap>
                        <div class="mt-4">
                            <div class="form-box">
                                {{ address ? address : "Lokasi Penjemputan" }}
                            </div>
                        </div>
                        <div class="d-flex mt-4">
                            <div class="ms-auto">
                                <button
                                    class="btn btn-outline-black btn-lg px-5 me-3"
                                    @click="openModalPickupAddress = false"
                                >
                                    Keluar
                                </button>
                                <button
                                    class="btn btn-green btn-lg px-5"
                                    :disabled="address == ''"
                                    @click="openModalPickupAddress = false"
                                >
                                    Pilih
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="openModalReceiver"></div>
            <div class="modal-body-chat md" v-if="openModalReceiver">
                <div class="mt-2 mb-1">
                    <div class="mt-3">
                        <label class="fw-semibold mb-3"
                            >Pilih Alamat Penjemputan</label
                        >
                        <GmapMap
                            ref="mapRefReceiver"
                            :center="{
                                lat: -6.233035800000001,
                                lng: 106.8397427,
                            }"
                            :zoom="16"
                            map-type-id="terrain"
                            style="height: 300px"
                        >
                            <div class="row justify-content-center pt-4">
                                <div class="col-md-9">
                                    <div class="position-relative">
                                        <GmapAutocomplete
                                            :value="address_receiver"
                                            @place_changed="
                                                processLocationChangedReceiver
                                            "
                                            placeholder="Cari Lokasi"
                                            class="form-control search-google"
                                        ></GmapAutocomplete>
                                        <span class="search-icon map">
                                            <i class="fa fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers_receiver"
                                ref="markerReceiver"
                                :position="m"
                                :clickable="true"
                                :draggable="true"
                                @dragend="movePlaceReceiver"
                                @click="center = m"
                            >
                            </GmapMarker>
                        </GmapMap>
                        <div class="mt-4">
                            <div class="form-box">
                                {{
                                    address_receiver
                                        ? address_receiver
                                        : "Lokasi Penerima"
                                }}
                            </div>
                        </div>
                        <div class="d-flex mt-4">
                            <div class="ms-auto">
                                <button
                                    class="btn btn-outline-black btn-lg px-5 me-3"
                                    @click="openModalReceiver = false"
                                >
                                    Keluar
                                </button>
                                <button
                                    class="btn btn-green btn-lg px-5"
                                    :disabled="address_receiver == ''"
                                    @click="openModalReceiver = false"
                                >
                                    Pilih
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import { gmapApi } from "vue2-google-maps";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    name: "trawltruckHoCekTarif",
    data() {
        return {
            map_data: { lat: null, lng: null },
            markers: [
                {
                    lat: -6.233035800000001,
                    lng: 106.8397427,
                },
            ],
            lat_search: "",
            lng_search: "",
            lat_move: "",
            lng_move: "",
            address: "",
            map_data_receiver: { lat: null, lng: null },
            markers_receiver: [
                {
                    lat: -6.233035800000001,
                    lng: 106.8397427,
                },
            ],
            lat_search_receiver: "",
            lng_search_receiver: "",
            lat_move_receiver: "",
            lng_move_receiver: "",
            address_receiver: "",
            fleet_type: "",
            armadas: [],
            is_cek_tarif: false,
            code: "",
            result: {
                result_meta: {},
            },
            openModalPickupAddress: false,
            openModalReceiver: false,
            showPickupAddress: false,
            showReceiver: false,
        };
    },
    created() {
        this.getListArmada();
    },
    computed: {
        google: gmapApi,
    },
    methods: {
        processLocationChanged(ev) {
            this.lat_search = ev.geometry.location.toJSON().lat;
            this.lng_search = ev.geometry.location.toJSON().lng;
            this.map_data = ev.geometry.location.toJSON();

            this.address = ev.formatted_address;

            let that = this;
            this.$refs.mapRef.$mapPromise.then((map) => {
                map.panTo(that.map_data);
            });
            this.$refs.marker[0].$markerObject.setPosition(this.map_data);
        },
        movePlace(event) {
            this.lat_move = event.latLng.toJSON().lat;
            this.lng_move = event.latLng.toJSON().lng;
            this.map_data = event.latLng.toJSON();
            console.log(event.domEvent);

            this.decodeLatLong(this.lat_move, this.lng_move);
        },
        decodeLatLong(lat, lng) {
            const geocoder = new this.google.maps.Geocoder();

            geocoder.geocode(
                { location: { lat: lat, lng: lng } },
                (results, status) => {
                    if (status === "OK") {
                        console.log(results[0]);
                        this.address = results[0].formatted_address;
                    } else {
                        this.address = "";
                        alert("tidak ketemu");
                    }
                }
            );
        },
        processLocationChangedReceiver(ev) {
            this.lat_search_receiver = ev.geometry.location.toJSON().lat;
            this.lng_search_receiver = ev.geometry.location.toJSON().lng;
            this.map_data_receiver = ev.geometry.location.toJSON();

            this.address_receiver = ev.formatted_address;

            let that = this;
            this.$refs.mapRefReceiver.$mapPromise.then((map) => {
                map.panTo(that.map_data_receiver);
            });
            this.$refs.markerReceiver[0].$markerObject.setPosition(
                this.map_data_receiver
            );
        },
        movePlaceReceiver(event) {
            this.lat_move_receiver = event.latLng.toJSON().lat;
            this.lng_move_receiver = event.latLng.toJSON().lng;
            this.map_data_receiver = event.latLng.toJSON();
            console.log(event.domEvent);

            this.decodeLatLongReceiver(
                this.lat_move_receiver,
                this.lng_move_receiver
            );
        },
        decodeLatLongReceiver(lat, lng) {
            const geocoder = new this.google.maps.Geocoder();

            geocoder.geocode(
                { location: { lat: lat, lng: lng } },
                (results, status) => {
                    if (status === "OK") {
                        console.log(results[0]);
                        this.address_receiver = results[0].formatted_address;
                    } else {
                        this.address_receiver = "";
                        alert("tidak ketemu");
                    }
                }
            );
        },
        getListArmada() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/truck/master/fleet`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.armadas = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        cekTarif() {
            this.is_cek_tarif = true;
            var data = {
                type_fleet: this.fleet_type.code,
                type_order: "quick",
                type_ordersub: 1,
                type_service: "special",
                helper_count: 0,
                origin_lat: this.map_data.lat,
                origin_lon: this.map_data.lng,
                dest_lat: this.map_data_receiver.lat,
                dest_lon: this.map_data_receiver.lng,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/estimate`,
                {
                    params: data,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.result = res.data.data;
                    this.code = res.data.code;
                    this.is_cek_tarif = false;
                })
                .catch((err) => {
                    this.code = err.response.data.code;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_cek_tarif = false;
                });
        },
        reset() {
            (this.map_data.lat = null),
                (this.map_data.lng = null),
                (this.lat_search = ""),
                (this.lng_search = ""),
                (this.address = ""),
                (this.map_data_receiver.lat = null),
                (this.map_data_receiver.lng = null),
                (this.lat_search_receiver = ""),
                (this.lng_search_receiver = ""),
                (this.address_receiver = ""),
                (this.fleet_type = "");
            this.code = "";
        },
    },
};
</script>

<style scoped>
.accordion-button {
    background-color: #e2edde !important;
    border: 2px solid #e2edde !important;
    box-shadow: unset !important;
}
.accordion-button.first:not(.collapsed)::after {
    background-image: unset !important;
}
.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon) !important;
}
</style>
