<template>
  <div>
    <dashboardIncome :items="items" />
  </div>
</template>
<script>
import dashboardIncome from "./DashboardIncome.vue";

export default {
  components: {
    dashboardIncome,
  },
  name: "Dashboard-MB",
  data() {
    return {
      items:{
        name: "Mitra Business",
        partner_type: "mb",
        url: "admin-income-mb-detail",
      }
    };
  },
  created() {},
  methods: {},
};
</script>
<style scoped>

</style>
