<template>
    <div class="padding-container">
        <div class="box">
            <div class="d-flex bd-highlight">
                <div class="flex-grow-1 bd-highlight">
                    <h4 class="fw-bold">Cek Nomor Resi</h4>
                </div>
                <div class="bd-highlight">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Cari No Resi"
                            @keyup.enter="searchReceipt"
                            v-model="findReceipt"
                        />
                        <span class="search-icon">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="modern-table-boxed mt-4">
                <div class="d-table-row header">
                    <div class="d-table-cell">No</div>
                    <div class="d-table-cell">Tanggal Pembuatan</div>
                    <div class="d-table-cell">No. Resi</div>
                    <div class="d-table-cell">Status</div>
                    <div class="d-table-cell">Partner Asal</div>
                    <div class="d-table-cell">Partner Tujuan</div>
                    <div class="d-table-cell">Armada</div>
                    <div class="d-table-cell">Nama Driver</div>
                    <div class="d-table-cell">SLA Leadtime</div>
                    <div class="d-table-cell">Keterlambatan</div>
                    <div class="d-table-cell">Selesai</div>
                </div>
                <div
                    class="d-table-row"
                    v-for="(item, index) in data"
                    :key="index"
                >
                    <div class="d-table-cell">{{ item.no }}</div>
                    <div class="d-table-cell">
                        {{ setDate(item.create_timestamp) }}
                    </div>
                    <div class="d-table-cell">
                        <div
                            v-if="
                                item.status_package == 'in_transit' ||
                                item.status_package == 'delivered'
                            "
                        >
                            <a
                                href="javascript:void(0)"
                                class="text-link"
                                @click="
                                    toggleModal(
                                        item.package_id,
                                        item.receipt_code,
                                        item.status_package,
                                        item.partner_origin
                                            ? item.partner_origin
                                            : '-'
                                    )
                                "
                            >
                                {{ item.receipt_code }}
                            </a>
                        </div>
                        <div
                            v-if="
                                item.status_package != 'in_transit' &&
                                item.status_package != 'delivered'
                            "
                            class="text-dark fw-bold"
                        >
                            {{ item.receipt_code }}
                        </div>
                    </div>
                    <div class="d-table-cell">
                        {{ trackingStatuses(item.status_package) }}
                    </div>
                    <div class="d-table-cell text-center">
                        {{ item.partner_origin ? item.partner_origin : "-" }}
                    </div>
                    <div class="d-table-cell text-center">
                        {{
                            item.partner_destination
                                ? item.partner_destination
                                : "-"
                        }}
                    </div>
                    <div class="d-table-cell text-center">
                        {{
                            item.transporter_type ? item.transporter_type : "-"
                        }}
                    </div>
                    <div class="d-table-cell text-center">
                        {{
                            item.transporter_driver
                                ? item.transporter_driver
                                : "-"
                        }}
                    </div>
                    <div class="d-table-cell text-center">
                        <Timer
                            :class="[
                                'font-countdown',
                                item.sla_level == 1
                                    ? 'green'
                                    : item.sla_level == 2
                                    ? 'yellow'
                                    : item.sla_level == 3
                                    ? 'red'
                                    : '',
                            ]"
                            :date="item.sla_deadline"
                        ></Timer>
                    </div>
                    <div class="d-table-cell text-center">
                        <Timer
                            class="font-countdown red"
                            :date="item.sla_deadline"
                            :isLate="item.is_sla_late"
                            :done="item.sla_late"
                            :level="item.sla_level"
                        ></Timer>
                    </div>
                    <div class="d-table-cell">
                        {{ item.sla_late ? setDate(item.sla_late) : "-" }}
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div
                class="overlay"
                v-if="showModal"
                @click="showModal = false"
            ></div>
            <div
                class="modal-body-chat sm"
                v-if="showModal && status == 'in_transit'"
            >
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            {{ receipt }}
                            <span class="tag green">{{ status }}</span>
                        </div>
                    </div>
                    <div class="bd-highlight">
                        <a
                            href="javascript:void(0)"
                            @click="showModal = false"
                            class="size-20 text-primary"
                        >
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div class="line"></div>
                <div class="scroll">
                    <div>
                        <div class="font-thin">Alamat Pengirim</div>
                        <div class="font-thick">
                            {{ detailData.sender_address }}
                        </div>
                    </div>
                    <hr class="my-3" />
                    <div>
                        <div class="font-thin">Alamat Penerima</div>
                        <div class="font-thick">
                            {{ detailData.receiver_address }}
                        </div>
                    </div>
                    <hr class="my-3" />
                    <div>
                        <div class="font-thin">Mitra Asal</div>
                        <div class="font-thick">{{ partnerOrigin }}</div>
                    </div>
                    <hr class="my-3" />
                    <div>
                        <div class="font-thin">Akun Driver</div>
                        <div class="font-thick">Muhammad Kurniawan</div>
                    </div>
                </div>
            </div>

            <div
                class="modal-body-chat md"
                v-if="showModal && status == 'delivered'"
            >
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            {{ receipt }}
                            <span class="tag green">{{ status }}</span>
                        </div>
                    </div>
                    <div class="bd-highlight">
                        <a
                            href="javascript:void(0)"
                            @click="showModal = false"
                            class="size-20 text-primary"
                        >
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div class="line"></div>
                <div class="scroll">
                    <div>
                        <div class="font-thin">Nama Penerima</div>
                        <div class="font-thick">
                            {{ detailData.received_by }}
                        </div>
                    </div>
                    <hr class="my-3" />
                    <div>
                        <div class="font-thin">Tanggal Diterima</div>
                        <div class="font-thick">
                            {{ dateFormat(detailData.received_at) }}
                        </div>
                    </div>
                    <hr class="my-3" />
                    <div>
                        <div class="font-thick">Bukti Foto</div>
                        <div class="d-flex flex-wrap gap-1 foto mt-3">
                            <template v-if="detailData.attachments.length > 0">
                                <template
                                    v-for="(
                                        image, index
                                    ) in detailData.attachments"
                                >
                                    <div :key="index">
                                        <div
                                            class="items-image"
                                            :style="{
                                                'background-image':
                                                    'url(' + image.uri + ')',
                                            }"
                                        ></div>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div>
                                    <div
                                        class="items-image"
                                        :style="{
                                            'background-image':
                                                'url(' +
                                                require('../../assets/no-photo.png') +
                                                ')',
                                        }"
                                    ></div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex" v-if="data.length > 0">
            <div class="ms-auto">
                <div class="d-flex mt-4">
                    <div class="me-3">
                        <select
                            class="form-control form-select w-select"
                            v-model="limit"
                            @change="getReceipt()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <b-pagination-nav
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="changePage"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import moment from "moment";
import Timer from "./timer.vue";

export default {
    components: {
        Timer,
    },
    name: "Receipt",
    data() {
        return {
            data: [],
            detailData: [],
            showModal: false,
            isDetail: false,
            receipt: "",
            packageId: null,
            partnerOrigin: "",
            status: "",
            findReceipt: "",
            currentPage: 0,
            totalPage: 0,
            limit: 10,
        };
    },
    created() {
        this.getReceipt();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    methods: {
        trackingStatuses(status) {
            const statuses = [
                { status: "cancel", title: "Dibatalkan" },
                { status: "created", title: "Dibentuk" },
                { status: "pending", title: "Menunggu" },
                { status: "lost", title: "Hilang" },
                {
                    status: "waiting_for_pickup",
                    title: "Menunggu untuk dijemput",
                },
                { status: "picked_up", title: "Dijemput" },
                {
                    status: "waiting_for_estimating",
                    title: "Menunggu untuk ditimbang",
                },
                { status: "estimating", title: "Sedang ditimbang" },
                { status: "estimated", title: "Telah ditimbang" },
                {
                    status: "waiting_for_approval",
                    title: "Menunggu untuk ditimbang",
                },
                { status: "revamp", title: "Direvisi oleh customer" },
                { status: "accepted", title: "Diterima oleh mitra" },
                {
                    status: "waiting_for_packing",
                    title: "Menunggu untuk dipacking",
                },
                { status: "packing", title: "Sedang dipacking" },
                { status: "packed", title: "Telah dipacking" },
                {
                    status: "manifested",
                    title: "Telah ditambah kedalam Manifest",
                },
                { status: "in_transit", title: "Dalam proses transit" },
                { status: "with_courier", title: "Dalam pengantaran kurir" },
                { status: "delivered", title: "Telah diantar" },
            ];
            let title = status;
            statuses.forEach((el) => {
                if (el.status == status) {
                    title = el.title;
                }
            });
            return title;
        },
        toggleModal(packageId, code, status, partnerOrigin) {
            this.packageId = packageId;
            this.receipt = code;
            this.status = status;
            this.partnerOrigin = partnerOrigin;
            this.showModal = true;
            this.getDetailReceipt();
        },
        changePage() {
            this.getReceipt();
        },
        searchReceipt() {
            this.getReceipt();
        },
        setDate(date) {
            return moment(date).format("DD MMM YYYY");
        },
        dateFormat(date) {
            return moment(date).format("DD MMMM YYYY HH:MM:SS");
        },
        getReceipt() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/fno/tracking/check/receipt`,
                {
                    params: {
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 1,
                        limit: this.limit,
                        search: this.findReceipt,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
        getDetailReceipt() {
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/partner/corporate/order-detail`,
                {
                    params: { package_id: this.packageId },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detailData = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
        store() {
            this.req.checked.forEach((item) => {
                Api.get(
                    `${process.env.VUE_APP_AE_URL}/agent/setDisbursementStatus`,
                    {
                        params: { user_id: item.user_id, month: item.periode },
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                )
                    .then(() => {
                        this.getDatas();
                        this.$message.success(`Change status success`);
                        this.req.checked = [];
                    })
                    .catch(function (error) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: `${error}`,
                        });
                        console.log(error);
                    });
            });
        },
    },
};
</script>

<style scoped>
.scroll {
    height: 500px;
    overflow-y: scroll;
}
.row.foto {
    --bs-gutter-x: 0rem !important;
}
.text-green {
    color: #51ff00;
}
.fw-medium {
    font-weight: 600;
}
.text-gray {
    color: #bfbfbf;
}
.trawl-button-success {
    padding: 0 40px;
    height: 40px;
    border-radius: 50px !important;
}
.font-thin {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #a0a0a0;
}
.font-thick {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}
.font-countdown {
    font-weight: 600;
}
.picture {
    width: 144px;
    height: 121px;
    border-radius: 6px;
}
.green {
    color: #3d8824;
}
.yellow {
    color: #fb9727;
}
.red {
    color: #e60013;
}
.items-image {
    width: 163px;
    height: 128px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    border: 2px solid #e2e3e5;
}
</style>
