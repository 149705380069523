<template>
    <div class="padding-container">
        <div class="d-flex align-items-center bd-highlight">
            <div class="flex-grow-1 bd-highlight">
                <h3 class="fw-bold">List Manifest</h3>
            </div>
        </div>
        <div class="box mt-4">
            <div v-if="is_list_ready">
                <ListLoader />
            </div>
            <div class="mt-3" v-else>
                <div class="row">
                    <div class="col-md-6">
                        <div class="position-relative">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Cari manifest"
                                v-model="req.keyword"
                            />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <select
                            class="form-control form-select"
                            v-model="req.mitra"
                            @change="getList()"
                        >
                            <option value="">Pilih mitra</option>
                            <template v-for="(mitra, index) in mitra.list_data">
                                <option :value="mitra.id" :key="index">
                                    {{ mitra.nama_mitra }}
                                </option>
                            </template>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <button class="btn btn-green w-100 btn-md" disabled>
                            Tambah Manifest
                        </button>
                    </div>
                </div>
                <table
                    class="table position-relative mt-5"
                    v-if="searchLists.length > 0"
                >
                    <thead class="thead">
                        <tr class="tr">
                            <td class="th">No</td>
                            <td class="th">Manifest</td>
                            <td class="th">Rute</td>
                            <td class="th">Layanan</td>
                            <td class="th">Total kendaraan</td>
                            <td class="th">Tanggal Manifest</td>
                            <td class="th">Status</td>
                        </tr>
                    </thead>
                    <tr>
                        <td>
                            <div></div>
                        </td>
                    </tr>
                    <template v-for="(list, index, key) in searchLists">
                        <tr class="tr border-body" :key="list.id">
                            <td class="td">
                                <b>{{ index + 1 }}</b
                                >.
                            </td>
                            <td class="td">
                                {{ list.manifest }}
                            </td>
                            <td class="td" style="width: 150px">
                                <a
                                    href="javascript:void(0)"
                                    class="text-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="list.rute"
                                >
                                    <div class="limit-one-line">
                                        {{ list.rute }}
                                    </div>
                                </a>
                            </td>
                            <td class="td capitalize">
                                {{ list.layanan }}
                            </td>
                            <td class="td">
                                {{ list.total_kendaraan }}
                            </td>
                            <td class="td">
                                {{
                                    moment(list.tanggal_manifest).format(
                                        "DD MMMM YYYY"
                                    )
                                }}
                            </td>
                            <td class="td capitalize">
                                {{ list.status }}
                            </td>
                        </tr>
                        <tr class="border-footer bc-gray pb-5" :key="index">
                            <td class="p-2 pt-1 pb-1" colspan="8">
                                <div class="d-flex align-items-center">
                                    <div class="">
                                        <a
                                            :href="`/trawlcarrier/ho/manifest/${list.id}`"
                                        >
                                            Lihat Detail
                                        </a>
                                    </div>
                                    <div class="ms-auto">
                                        <button
                                            class="btn btn-green btn-sm me-2 pb-1 pt-1 px-3"
                                            disabled
                                        >
                                            Tambah Order
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr :key="key">
                            <td colspan="8">
                                <div class=""></div>
                            </td>
                        </tr>
                    </template>
                </table>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="d-flex" v-if="searchLists.length > 0">
            <div class="ms-auto">
                <div class="d-flex mt-4">
                    <div class="me-3">
                        <select
                            class="form-control form-select w-select"
                            v-model="per_page"
                            @change="getList()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <b-pagination-nav
                        :link-gen="linkGen"
                        v-model="currentPage"
                        :number-of-pages="list.total_page"
                        base-url="#"
                        first-number
                        align="right"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import { Tooltip } from "bootstrap";
import moment from "moment";

export default {
    components: {
        ListLoader,
    },
    name: "TrawlcareerManifest",
    data() {
        return {
            moment: moment,
            req: {
                keyword: "",
                mitra: "",
            },
            is_list_ready: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            mitra: {
                list_data: [],
            },
            per_page: 10,
            currentPage: 0,
        };
    },
    created() {
        this.getMitra();
        this.getList();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    computed: {
        searchLists() {
            return this.list.list_data.filter((item) => {
                return item.manifest
                    .toLowerCase()
                    .includes(this.req.keyword.toLowerCase());
            });
        },
    },
    methods: {
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        getList() {
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/manifest?partner=${this.req.mitra}`,
                {
                    params: {
                        per_page: this.per_page,
                        page: this.$route.query.page,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        getMitra() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/partner?per_page=50`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.mitra = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style></style>
