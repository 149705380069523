<template>
  <div class="padding-container">
    <div class="d-flex align-items-center mb-4">
      <h3 class="fw-bold">Incoming Order</h3>
      <div class="ms-auto">
        <div class="position-relative">
          <input class="form-control" v-model="monthFilter" type="month" @change="searchSummary" style="border: 1px solid #48a2d4" placeholder="Pilih Bulan" />
          <span class="search-icon">
            <img src="../../assets/calendar-icon.png" />
          </span>
        </div>
      </div>
    </div>
    <div class="d-flex gap-2">
      <div class="box mb-3" style="width: 100%">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <img style="width: 36px; height: 36px" src="../../assets/icon-people.png" />
            <div class="fw-bold ms-2 me-4">Total Order</div>
          </div>
          <div class="align-self-center text-end ms-5">{{ monthFormat(monthFilter) }}</div>
        </div>
        <div class="d-flex flex-column mt-3">
          <div class="text-gray">Total Resi</div>
          <div class="fw-bold size-16">{{ dataSummary.total_order }}</div>
        </div>
      </div>
      <div class="box mb-3" style="width: 100%">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <img style="width: 36px; height: 36px" src="../../assets/pie-chart-blue-icon.png" />
            <div class="fw-bold ms-2">Order Kedatangan</div>
          </div>
          <div class="align-self-center text-end ms-5">{{ monthFormat(monthFilter) }}</div>
        </div>
        <div class="d-flex gap-5">
          <div class="d-flex flex-column mt-3">
            <div class="text-gray">Jumlah Resi</div>
            <div class="fw-bold size-16">{{ dataSummary.order_come }} Resi</div>
          </div>
          <div class="d-flex flex-column mt-3">
            <div class="text-gray">Peningkatan</div>
            <div class="fw-bold size-16">{{ dataSummary.order_come_increased }} Resi</div>
          </div>
        </div>
      </div>
      <div class="box mb-3" style="width: 100%">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <img style="width: 36px; height: 36px" src="../../assets/pie-chart-blue-icon.png" />
            <div class="fw-bold ms-2">Order Keberangkatan</div>
          </div>
          <div class="align-self-center text-end ms-5">{{ monthFormat(monthFilter) }}</div>
        </div>
        <div class="d-flex gap-5">
          <div class="d-flex flex-column mt-3">
            <div class="text-gray">Jumlah Resi</div>
            <div class="fw-bold size-16">{{ dataSummary.order_out }} Resi</div>
          </div>
          <div class="d-flex flex-column mt-3">
            <div class="text-gray">Peningkatan</div>
            <div class="fw-bold size-16">{{ dataSummary.order_out_increased }} Resi</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="d-flex align-items-center">
        <h3 class="mb-0 fw-bold">List</h3>
        <select class="select-status mt-1 ms-2 py-1 ps-1" v-model="filterStatus" @change="searchData">
          <option value="arrival">Kedatangan</option>
          <option value="departure">Keberangkatan</option>
        </select>
        <div class="ms-auto">
          <select class="form-control form-select" v-model="filterCategory" @change="searchData" name="" id="" style="width: 175px">
            <option value="" selected>Filter Kategori Barang</option>
            <option v-for="(item, index) in catItem" :key="index" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="ms-2">
          <select class="form-control form-select" v-model="filterType" @change="searchData" name="" id="" style="width: 175px">
            <option value="" selected>Filter Jenis Pengiriman</option>
            <option value="tps">Reguler</option>
            <option value="tpx">Express</option>
          </select>
        </div>
        <div class="ms-2">
          <div class="position-relative">
            <input v-model="keySearch" @keyup.enter="searchData" type="text" class="form-control" style="width: 155px" placeholder="Search" />
            <span class="search-icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="mt-4" v-if="is_my_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div v-if="list_status == 'arrival'" class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">Tanggal Orderan</div>
            <div class="d-table-cell">No Resi</div>
            <div class="d-table-cell">Jenis Pengiriman</div>
            <div class="d-table-cell">Kategori Barang</div>
            <div class="d-table-cell">Jumlah Koli</div>
            <div v-if="items.partner_type != 'mtak'" class="d-table-cell">Berat Barang</div>
            <div v-if="items.partner_type != 'mtak'" class="d-table-cell">Alamat Penjemputan</div>
            <div v-if="items.partner_type == 'mtak'" class="d-table-cell">Partner Asal</div>
            <div v-if="items.partner_type == 'mtak'" class="d-table-cell">Driver</div>
          </div>
          <div class="d-table-row" v-for="(item, index) in data" :key="index">
            <div class="d-table-cell">{{ numbering(index) }}</div>
            <div class="d-table-cell">
              {{ item.created_at ? dateFormat(item.created_at) : "-" }}
            </div>
            <div class="d-table-cell">
              {{ item.code }}
            </div>
            <div class="d-table-cell">{{ item.service_type }}</div>
            <div class="d-table-cell">{{ item.category_name ? item.category_name : "-" }}</div>
            <div class="d-table-cell">{{ item.total_qty }} Koli</div>
            <div class="d-table-cell">{{ items.partner_type == "mtak" ? item.partner : item.total_weight }} Kg</div>
            <div v-if="items.partner_type == 'mtak'" class="d-table-cell">{{ item.driver }}</div>
            <div v-if="items.partner_type != 'mtak'" class="d-table-cell">
              <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" :title="item.sender_address"> {{ item.origin_regency }} <i class="fa fa-info-circle text-blue ms-1"></i> </a>
            </div>
          </div>
        </div>

        <div v-if="list_status == 'departure'" class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">No Resi</div>
            <div class="d-table-cell">Jenis Pengiriman</div>
            <div class="d-table-cell">Kategori Barang</div>
            <div class="d-table-cell">Jumlah Koli</div>
            <div class="d-table-cell">Total Berat</div>
            <div class="d-table-cell">Alamat Mitra Tujuan</div>
          </div>
          <div class="d-table-row" v-for="(item, index) in data" :key="index">
            <div class="d-table-cell">{{ numbering(index) }}</div>
            <div class="d-table-cell">
              {{ item.code }}
            </div>
            <div class="d-table-cell">{{ item.service_type }}</div>
            <div class="d-table-cell">{{ item.category_name ? item.category_name : "-" }}</div>
            <div class="d-table-cell">{{ item.total_qty }} Koli</div>
            <div class="d-table-cell">{{ item.total_weight }} Kg</div>
            <div class="d-table-cell">
              <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" :title="item.sender_address"> {{ item.origin_regency }} <i class="fa fa-info-circle text-blue ms-1"></i> </a>
            </div>
          </div>
        </div>

        <div v-if="data.length == 0" class="text-center mt-section">
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
    </div>
    <div class="overflow-auto mt-3">
      <b-pagination-nav v-if="data.length > 0" v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav>
    </div>
  </div>
</template>
<script>
import Api from "../../api/Api";
import { Tooltip } from "bootstrap";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";

export default {
  props: {
    items: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ListLoader,
  },
  name: "Dashboard-MB",
  data() {
    return {
      moment: moment,
      filterStatus: "arrival",
      monthFilter: moment(new Date()).format("YYYY-MM"),
      filterCategory: "",
      filterType: "",
      keySearch: "",
      is_my_list_ready: false,
      list_status: "arrival",
      data: "",
      dataSummary: "",
      catItem: "",
      currentPage: 0,
      totalPage: 0,
      limit: 10,
      dataItem: [
        {
          date: "12 Aug 2022",
          receipt: "TRB000000009",
          total: "Rp 600.000.000",
          weight: "Rp 500.000.000",
          type: "Reguler",
          category: "Baju",
          address: "Jl.Rasamala, No 35, Menteng",
          partner: "MB-JKT-01",
          driver: "Hendra Kurniawan",
          status: "Approved",
        },
        {
          date: "12 Aug 2022",
          receipt: "TRB000000009",
          total: "Rp 600.000.000",
          weight: "Rp 500.000.000",
          type: "Express",
          category: "Baju",
          address: "Jl.Rasamala, No 35, Menteng",
          partner: "MB-JKT-01",
          driver: "Hendra Kurniawan",
          status: "Approved",
        },
        {
          date: "12 Aug 2022",
          receipt: "TRB000000009",
          total: "Rp 600.000.000",
          weight: "Rp 500.000.000",
          type: "Express",
          category: "Baju",
          address: "Jl.Rasamala, No 35, Menteng",
          partner: "MB-JKT-01",
          driver: "Hendra Kurniawan",
          status: "Approved",
        },
        {
          date: "12 Aug 2022",
          receipt: "TRB000000009",
          total: "Rp 600.000.000",
          weight: "Rp 500.000.000",
          type: "Express",
          category: "Baju",
          address: "Jl.Rasamala, No 35, Menteng",
          partner: "MB-JKT-01",
          driver: "Hendra Kurniawan",
          status: "Approved",
        },
      ],
    };
  },
  created() {
    this.getData();
    this.getSummary();
    this.getItem();
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
  },
  methods: {
    searchData() {
      this.currentPage = 0;
      window.location.hash = `${this.currentPage}`;
      this.getData();
    },
    searchSummary() {
      this.getSummary();
    },
    changePage() {
      this.getData();
    },
    numbering(index) {
      let number = this.itemNumber(index);
      return (number < 10 ? "0" : "") + number;
    },
    getData() {
      this.is_my_list_ready = true;
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/dashboard/owner/incoming-orders`, {
        params: {
          page: this.currentPage == 0 || this.currentPage == null ? 1 : this.currentPage,
          per_page: this.limit,
          list_type: this.filterStatus,
          category_id: this.filterCategory ? this.filterCategory : "''",
          service_type: this.filterType ? this.filterType : "''",
          receipt_code: this.keySearch ? this.keySearch : "''",
        },
        // params: { list_type : '', category_id: '',service_type: '',receipt_code:'' },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data = res.data.data;
          this.totalPage = res.data.last_page;
          this.is_my_list_ready = false;
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.is_my_list_ready = false;
        });
    },
    getSummary() {
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/dashboard/owner/summary/incoming-orders`, {
        params: { date: this.monthFilter == moment(new Date()).format("YYYY-MM") ? moment(new Date()).format("MM-YYYY") : moment(this.monthFilter).format("MM-YYYY") },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.dataSummary = res.data.data;
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
        });
    },
    getItem() {
      Api.get(`${process.env.VUE_APP_BASE_URL}/trawlpack/dashboard/order/list/category`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.catItem = res.data.data;
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
        });
    },
    dateFormat(date) {
      return moment(date, "DDMMYYYY").format("DD MMMM YYYY");
    },
    monthFormat(date) {
      return moment(date).format("MMMM");
    },
    currency(number) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(number);
    },
    itemNumber(index) {
      return index + this.startIndex + 1;
    },
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.limit;
    },
  },
};
</script>
<style scoped>
.select-status {
  border: none;
  border-radius: 8px;
  background: rgba(72, 162, 212, 0.1);
  color: #48a2d4;
}
.label-req {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 29px;
  background: #519c66;
  border: none;
  border-radius: 6px;
  color: white;
}
input[type="month"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  position: absolute;
  opacity: 1;
  display: block;
  background: none no-repeat;
  border-width: thin;
  width: 100%;
}
</style>
