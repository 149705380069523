<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/trawltruck/ho/topup" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Ubah Data Asuransi</h3>
            </div>
            <div class="row mt-5">
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Customers</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Customers"
                    />
                </div>
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Vendor Customers</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Vendor Customers"
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Tanggal Pengajuan</label>
                    <date-picker
                        type="date"
                        value-type="format"
                        format="DD MMMM YYYY"
                        :clearable="false"
                        placeholder="Masukkan Tanggal Pengajuan"
                        class="w-100"
                    >
                    </date-picker>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Harga Total Barang</label>
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Masukkan Harga Total Barang"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Besaran Asuransi</label>
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Masukkan Besaran Asuransi"
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Jenis Barang</label>
                    <select class="form-control form-select">
                        <option value="">Pilih Jenis Barang</option>
                    </select>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold mb-2">Bank</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Bank"
                    />
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold mb-2">Nomor Rekening</label>
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Masukkan Nomor Rekening"
                    />
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold mb-2">Atas Nama</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Atas Nama"
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Status</label>
                    <select
                        class="form-control form-select"
                        v-model="req.status"
                    >
                        <option value="">Pilih Jenis Barang</option>
                        <option value="submited">Submited</option>
                        <option value="accepted">Accepted</option>
                        <option value="rejected">Rejected</option>
                        <option value="paid">Paid</option>
                    </select>
                </div>
                <div class="col-md-12 mt-3" v-if="req.status == 'paid'">
                    <label class="fw-bold mb-2"
                        >Upload Bukti Pembayaran Klaim</label
                    >
                    <input class="form-control" type="file" />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold">Dokumen</label>
                    <div class="text-gray mb-2">Dokumen PDF maks 5MB</div>
                    <input
                        class="form-control"
                        type="file"
                        accept="application/pdf"
                    />
                </div>
                <div class="col-md-12 mt-4">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <!-- <a href="/trawltruck/ho/partner" class="btn btn-outline-black btn-lg px-5 me-3">Batal</a> -->
                            <a
                                href="javascript:void(0)"
                                class="btn btn-outline-black btn-lg px-5 me-3"
                                @click="modalError = true"
                                >Batal</a
                            >
                            <a
                                href="javascript:void(0)"
                                class="btn btn-primary btn-lg px-5"
                                @click="modalSuccess = true"
                                >Update</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data Saved!!</h4>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="modalSuccess = false"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalError"></div>
            <div class="modal-body-chat vsm" v-if="modalError">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/close.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Penambahan Data Gagal!</h4>
                    <div class="mt-2">
                        Mohon maaf terjadi kesalahan! Silakan ulangi kembali
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="modalError = false"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
    components: {
        DatePicker,
    },
    props: "",
    name: "ManagementTopupEdit",
    data() {
        return {
            req: {
                status: "",
            },
            modalSuccess: false,
            modalError: false,
        };
    },
    methods: {},
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
</style>
