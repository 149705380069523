<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">List Data Uang Jalan</h3>
            </div>
            <div class="row mt-5 m-mt-2">
                <div class="col-md-5">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg"
                            placeholder="Cari data..."
                            v-model="req.search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <template v-if="list.list_data.length > 0">
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        No Resi
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nama Driver
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nama Mitra
                                    </th>
                                    <th class="text-center" scope="col">
                                        Rute Sewa
                                    </th>
                                    <th class="text-center" scope="col">
                                        Jenis Truk
                                    </th>
                                    <th class="text-center" scope="col">
                                        Status
                                    </th>
                                    <th class="text-center" scope="col">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template
                                    v-for="(data, index) in list.list_data"
                                >
                                    <tr :key="index">
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="text-center">
                                            <a
                                                :href="
                                                    '/trawltruck/ho/delivery-payment/detail/' +
                                                    data.id
                                                "
                                                class="text-dark"
                                            >
                                                <u>
                                                    {{ data.receipt }}
                                                </u>
                                            </a>
                                        </td>
                                        <td class="text-center">
                                            {{ data.driver }}
                                        </td>
                                        <td class="text-center">
                                            {{ data.partner_name }}
                                        </td>
                                        <td style="width: 150px">
                                            <a
                                                href="javascript:void(0)"
                                                class="text-dark"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                :title="data.route"
                                            >
                                                <div class="limit-one-line">
                                                    {{ data.route }}
                                                </div>
                                            </a>
                                        </td>
                                        <td class="text-center">
                                            {{ data.fleet_type }}
                                        </td>
                                        <td class="text-center">
                                            <template
                                                v-if="data.status == 'success'"
                                            >
                                                <small
                                                    class="tag green mb-0 px-3 capitalize"
                                                >
                                                    Terpenuhi
                                                </small>
                                            </template>
                                            <template v-else>
                                                <small
                                                    class="tag red mb-0 px-3 capitalize"
                                                >
                                                    Tidak Terpenuhi
                                                </small>
                                            </template>
                                        </td>
                                        <td class="text-center">
                                            <template
                                                v-if="data.status == 'success'"
                                            >
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-gray cursor-drop"
                                                >
                                                    <i
                                                        class="fa fa-pencil me-3"
                                                    ></i>
                                                </a>
                                            </template>
                                            <template v-else>
                                                <a
                                                    :href="
                                                        '/trawltruck/ho/delivery-payment/edit/' +
                                                        data.id
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-pencil me-3"
                                                    ></i>
                                                </a>
                                            </template>
                                            <a
                                                href="javascript:void(0)"
                                                @click="openModal(data.id)"
                                                class="text-red-dashboard"
                                            >
                                                <i class="fa fa-trash"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-section">
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </template>
            </div>
            <div class="d-flex" v-if="list.list_data.length > 0">
                <div class="ms-auto">
                    <div class="d-flex mt-4">
                        <div class="me-3">
                            <select
                                class="form-control form-select w-select"
                                v-model="per_page"
                                @change="getList()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <b-pagination-nav
                            v-model="currentPage"
                            :number-of-pages="list.total_page"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="showModalDelete"></div>
            <div class="modal-body-chat vsm" v-if="showModalDelete">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/trash.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Penghapusan Data?</h4>
                    <div class="mt-2">
                        Apakah Anda yakin ingin hapus data ini?
                    </div>
                </div>
                <div class="mt-4 row">
                    <div class="col-md-6">
                        <a
                            href="javascript:void(0)"
                            @click="showModalDelete = false"
                            class="btn btn-primary w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6">
                        <button
                            class="btn btn-outline-primary w-100 btn-lg"
                            type="button"
                            disabled
                            v-if="is_delete"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Ya
                        </button>
                        <button
                            @click="deleteList()"
                            class="btn btn-outline-primary w-100 btn-lg"
                            v-else
                        >
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessDelete"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessDelete">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data Berhasil Dihapus!</h4>
                    <div class="mt-2">
                        Selamat! Anda berhasil menghapus data.
                    </div>
                </div>
                <div class="mt-4">
                    <button
                        @click="successDeletModal()"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import { Tooltip } from "bootstrap";

export default {
    components: {
        ListLoader,
    },
    name: "ManagementDeliveryPayment",
    data() {
        return {
            showModalDelete: false,
            modalSuccessDelete: false,
            is_list_ready: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page: 10,
            currentPage: 0,
            req: {
                search: "",
            },
            id_list: 0,
            is_delete: false,
        };
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    created() {
        this.getList();
    },
    computed: {},
    methods: {
        openModal(id) {
            this.showModalDelete = true;
            this.id_list = id;
        },
        changePage() {
            this.getList();
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        getList() {
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/transport`,
                {
                    params: {
                        search: this.req.search,
                        limit: this.per_page,
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 1,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        deleteList() {
            this.is_delete = true;
            var data = {
                id: this.id_list,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/transport/delete`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_delete = false;
                    this.showModalDelete = false;
                    this.modalSuccessDelete = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_delete = false;
                    this.showModalDelete = false;
                    console.log(err);
                });
        },
        successDeletModal() {
            this.modalSuccessDelete = false;
            this.getList();
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.text-red-dashboard {
    color: #e60013;
}
.search-icon {
    font-size: 16px;
}
</style>
