<template>
    <div class="padding-container">
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">
                    List Jenis Truk
                </h3>
                <div class="ms-auto">
                    <a href="/trawltruck/ho/master/fleet/create" class="btn btn-primary btn-lg px-5">
                        Tambah Data
                    </a>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-5">
                    <div class="position-relative">
                        <input type="text" class="form-control form-control-lg" placeholder="Cari data..." />
                        <span class="search-icon">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <table class="table table-bordered mt-4">
                <thead class="table-light">
                    <tr>
                        <th class="text-center" scope="col">No</th>
                        <th class="text-center" scope="col">Jenis Truk</th>
                        <th class="text-center" scope="col">Harga/Km</th>
                        <th class="text-center" scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center">
                            1
                        </td>
                        <td class="text-center">
                            <a href="/trawltruck/ho/master/fleet/detail/8" class="text-dark">
                                <u>
                                    Blind Van
                                </u>
                            </a>
                        </td>
                        <td class="text-center">
                            Rp400.000
                        </td>
                        <td class="text-center">
                            <a href="/trawltruck/ho/master/fleet/edit/3" class="">
                                <i class="fa fa-pencil me-3"></i>
                            </a>
                            <a href="javascript:void(0)" @click="showModalDelete = true" class="text-red-dashboard">
                                <i class="fa fa-trash"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="showModalDelete"></div>
            <div class="modal-body-chat vsm" v-if="showModalDelete">
                <div class="mt-4 text-center">
                    <img src="../../assets/dashboard/trash.png" width="80" alt="">
                    <h4 class="mb-0 fw-bold mt-4">
                        Penghapusan Data?
                    </h4>
                    <div class="mt-2">
                        Apakah Anda yakin ingin hapus data ini?
                    </div>
                </div>
                <div class="mt-4 row">
                    <div class="col-md-6">
                        <a href="javascript:void(0)" @click="showModalDelete = false" class="btn btn-primary w-100 btn-lg">
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6">
                        <a href="javascript:void(0)" @click="showModalDelete = false" class="btn btn-outline-primary w-100 btn-lg">
                            Ya
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: "",
        name: "ManagementMasterFleet",
        data() {
            return {
                showModalDelete: false,
            };
        },
        methods: {
        }
    };
</script>

<style scoped>
    th{
        border-color: #E8E8E9;
        padding: 10px !important;
    }
    tr{
        border-color: #E8E8E9;
        padding: 10px !important;
    }
    td{
        padding: 10px !important;
    }
    .table-light{
        background-color: #F6FAFF !important;
        --bs-table-border-color: #F6FAFF !important;
        --bs-table-bg: #F6FAFF !important;
    }
    .text-red-dashboard{
        color: #E60013;
    }
    .search-icon{
        font-size: 16px;
    }
</style>