<template>
    <div
        class="padding-container"
        @click="(is_show_search_box = false), (is_show_search_box_merk = false)"
    >
        <div class="content-load" v-if="is_find_price"></div>
        <div id="loader" v-if="is_find_price"></div>
        <div class="box mt-4 m-mt-0">
            <h3 class="fw-bold mb-0">Cek Harga TroCarrier</h3>

            <div class="row">
                <div class="col-md-6 mt-3">
                    <div class="box no-shadow border-gray">
                        <div>
                            <label class="fw-bold mb-2">Dari</label>
                            <multiselect
                                v-model="origin"
                                :options="origins"
                                label="name"
                                track-by="id"
                                placeholder="Pilih Kota Asal"
                                class="multi-form-custom"
                                @input="getDestination()"
                            >
                            </multiselect>
                        </div>
                        <div class="mt-2">
                            <label class="fw-bold mb-2">Ke</label>
                            <multiselect
                                v-model="destination"
                                :options="destinations"
                                label="name"
                                track-by="id"
                                placeholder="Pilih Kota Tujuan"
                                class="multi-form-custom"
                            >
                            </multiselect>
                        </div>
                        <div class="mt-2">
                            <label class="fw-bold mb-2">Tipe Mobil</label>
                            <div
                                class="car-type-box-body ml-auto position-relative"
                            >
                                <input
                                    type="text"
                                    class="car-type-box form-control"
                                    placeholder="Masukkan Tipe Mobil"
                                    v-model="search"
                                    @input="changeSearch()"
                                />
                                <div
                                    class="search-box-car"
                                    v-if="is_show_search_box"
                                >
                                    <div class="text-title-car">
                                        Pilih atau Tambahkan Merk Mobil
                                    </div>
                                    <div
                                        v-bind:class="{
                                            'scroll-dropdown':
                                                filteredSearch.length > 8,
                                        }"
                                    >
                                        <template
                                            v-if="filteredSearch.length > 0"
                                        >
                                            <div
                                                v-for="(
                                                    data, index
                                                ) in filteredSearch"
                                                :key="index"
                                            >
                                                <div
                                                    class="search-title-car"
                                                    @click="
                                                        onInputSearch(
                                                            data,
                                                            index
                                                        )
                                                    "
                                                >
                                                    {{ data.variant }}
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="text-gray">
                                                <i>
                                                    Tipe Mobil Tidak Ditemukan
                                                </i>
                                            </div>
                                        </template>
                                    </div>
                                    <hr class="mb-2 mt-2" />
                                    <div class="text-title-car">
                                        Tambahkan :
                                        <a
                                            href="javascript:void(0)"
                                            v-if="filteredSearch.length == 0"
                                            @click="addNewCarType(search)"
                                            class="text-dark"
                                            >{{ search }}</a
                                        >
                                        <span v-else>-</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="mt-2"
                            v-if="filteredSearch.length == 0 && search"
                        >
                            <label class="fw-bold mb-2">Merk Mobil</label>
                            <div
                                class="car-type-box-body ml-auto position-relative"
                            >
                                <input
                                    type="text"
                                    class="car-type-box form-control"
                                    placeholder="Masukkan Merk Mobil"
                                    v-model="search_merk"
                                    @input="changeSearchMerk()"
                                />
                                <div
                                    class="search-box-car"
                                    v-if="is_show_search_box_merk"
                                >
                                    <div class="text-title-car">
                                        Pilih atau Tambahkan Merk Mobil
                                    </div>
                                    <div
                                        v-bind:class="{
                                            'scroll-dropdown':
                                                filteredSearchMerk.length > 8,
                                        }"
                                    >
                                        <template
                                            v-if="filteredSearchMerk.length > 0"
                                        >
                                            <div
                                                v-for="(
                                                    data, index
                                                ) in filteredSearchMerk"
                                                :key="index"
                                            >
                                                <div
                                                    class="search-title-car"
                                                    @click="
                                                        onInputSearchMerk(
                                                            data,
                                                            index
                                                        )
                                                    "
                                                >
                                                    {{ data.brand }}
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="text-gray">
                                                <i>
                                                    Merk Mobil Tidak Ditemukan
                                                </i>
                                            </div>
                                        </template>
                                    </div>
                                    <hr class="mb-2 mt-2" />
                                    <div class="text-title-car">
                                        Tambahkan :
                                        <a
                                            href="javascript:void(0)"
                                            v-if="
                                                filteredSearchMerk.length == 0
                                            "
                                            @click="addNewMerk(search_merk)"
                                            class="text-dark"
                                            >{{ search_merk }}</a
                                        >
                                        <span v-else>-</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2" v-if="search_merk">
                            <label class="fw-bold mb-2">Tahun Produksi</label>
                            <input
                                type="number"
                                class="form-control"
                                placeholder="Masukkan Tahun Kendaraan"
                                v-model="brand_year"
                            />
                        </div>
                        <div class="mt-2" v-if="brand_year">
                            <label class="fw-bold mb-2">Kategori Harga</label>
                            <multiselect
                                v-model="brand_class"
                                :options="car_prices"
                                label="name"
                                track-by="value"
                                placeholder="Kategori Harga"
                                class="multi-form-custom"
                                @input="getService"
                            >
                            </multiselect>
                        </div>
                        <div class="mt-2" v-if="services.length > 0">
                            <label class="fw-bold mb-2">Jenis Layanan</label>
                            <multiselect
                                v-model="service"
                                :options="services"
                                label="service_label"
                                track-by="service"
                                placeholder="Pilih Jenis Layanan"
                                class="multi-form-custom"
                            >
                            </multiselect>
                        </div>
                        <div
                            class="mt-2"
                            v-if="service && service.service == 'door_to_door'"
                        >
                            <label class="fw-bold mb-2">Pilih Layanan</label>
                            <multiselect
                                v-model="option"
                                :options="service_options"
                                label="option_label"
                                track-by="option"
                                placeholder="Pilih Layanan"
                                class="multi-form-custom"
                            >
                            </multiselect>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="mt-3 ms-auto">
                            <button
                                class="btn btn-outline-red btn-lg px-5 me-3"
                                @click="resetForm()"
                            >
                                Reset
                            </button>
                            <button
                                class="btn btn-green btn-lg px-5"
                                type="button"
                                disabled
                                v-if="is_find_price"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Cek Harga
                            </button>
                            <button
                                type="submit"
                                class="btn btn-green btn-lg px-5"
                                @click="checkPrice()"
                                :disabled="
                                    origin == null ||
                                    destination == null ||
                                    search == '' ||
                                    service == ''
                                "
                                v-else
                            >
                                Cek Harga
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <div class="box no-shadow border-gray">
                        <template v-if="code == '200' && price != null">
                            <center>
                                <h4 class="mb-0 fw-bold">
                                    Ongkos kirim Mobil dari
                                    {{ price.origin_regency.name }} ke
                                    {{ price.destination_regency.name }}
                                </h4>
                                <div class="text-gray">
                                    stimasi pengiriman sampai dalam
                                    <span class="text-dark fw-bold">{{
                                        price.estimate
                                    }}</span>
                                    kerja sejak kendaraan dikirim
                                </div>
                                <hr />
                                <h4 class="fw-bold mt-3 mb-0 capitalize">
                                    {{ price.service.split("_").join(" ") }}
                                </h4>
                                <div
                                    class="size-16 capitalize"
                                    v-if="price.service_option != null"
                                >
                                    {{
                                        price.service_option
                                            .split("_")
                                            .join(" ")
                                    }}
                                </div>
                                <div class="size-16 capitalize">
                                    Jenis Mobil: {{ search }}
                                </div>
                                <div class="mt-3">
                                    <img
                                        src="../../assets/trocarrier-icon.png"
                                        width="200"
                                        lt=""
                                    />
                                </div>
                                <div class="mt-3">
                                    <h1 class="mb-0 text-green fw-bold">
                                        {{ currency(price.price) }}
                                    </h1>
                                </div>
                            </center>
                        </template>
                        <template
                            v-if="
                                code == '400' || code == '500' || price == null
                            "
                        >
                            <center>
                                <img
                                    src="../../assets/no-mitra.png"
                                    width="140"
                                    alt=""
                                />
                                <div class="mt-3 text-gray">
                                    Mohon lengkapi data terlebih dahulu
                                </div>
                            </center>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    name: "TrocarrierPricing",
    data() {
        return {
            origin: null,
            origins: [],
            destination: null,
            destinations: [],
            vehicles: [],
            search: "",
            filteredSearch: [],
            is_show_search_box: false,
            is_click_search: false,
            brand_class: null,
            search_merk: "",
            filteredSearchMerk: [],
            is_show_search_box_merk: false,
            is_click_search_merk: false,
            merk_class: null,
            merks: [],
            service: "",
            services: [],
            option: "",
            service_options: [],
            is_find_price: false,
            price: null,
            brand_year: "",
            car_prices: [
                {
                    name: "standard (<800 Juta)",
                    value: "standard",
                },
                {
                    name: "Premium (>800 Juta - <2 Milliar)",
                    value: "premium",
                },
                {
                    name: "Super Car (>2 Milliar)",
                    value: "super",
                },
            ],
            code: "",
        };
    },
    created() {
        this.getOrigin();
    },
    computed: {},
    methods: {
        getOrigin() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/pricing/origin`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.origins = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getDestination() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/pricing/destination?origin_id=${this.origin?.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.destinations = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        onInputSearch(data, index) {
            this.brand_class = data.brand_class;
            this.is_click_search = true;
            this.search = data.variant;
            this.vehicles[index].is_show_search_box =
                !this.vehicles[index].is_show_search_box;
            this.getService();
            var filteredSearch = this.vehicles.filter((item) => {
                return item.variant
                    .toLowerCase()
                    .includes(this.search.toLowerCase());
            });
            return (this.filteredSearch = filteredSearch);
        },
        changeSearch() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/pricing/vehicles/list`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.vehicles = data;
                    this.is_show_search_box = true;
                    var filteredSearch = data.filter((item) => {
                        return item.variant
                            .toLowerCase()
                            .includes(this.search.toLowerCase());
                    });
                    return (this.filteredSearch = filteredSearch);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        addNewCarType(search) {
            console.log(search);
        },
        onInputSearchMerk(data, index) {
            this.merk_class = data.merk_class;
            this.is_click_search_merk = true;
            this.search_merk = data.brand;
            this.merks[index].is_show_search_box_merk =
                !this.merks[index].is_show_search_box_merk;
            var filteredSearchMerk = this.merks.filter((item) => {
                return item.brand
                    .toLowerCase()
                    .includes(this.search.toLowerCase());
            });
            return (this.filteredSearchMerk = filteredSearchMerk);
        },
        changeSearchMerk() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/pricing/vehicles/brand/list`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data.list_data;
                    this.merks = data;
                    this.is_show_search_box_merk = true;
                    var filteredSearchMerk = data.filter((item) => {
                        return item.brand
                            .toLowerCase()
                            .includes(this.search_merk.toLowerCase());
                    });
                    return (this.filteredSearchMerk = filteredSearchMerk);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        addNewMerk(search_merk) {
            console.log(search_merk);
        },
        getService() {
            var params = {
                origin_regency_id: this.origin?.id,
                destination_regency_id: this.destination?.id,
            };
            if (this.brand_year != "") {
                params.brand_class = this.brand_class.value;
            } else {
                params.brand_class = this.brand_class;
            }
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/pricing/services/list`,
                {
                    params: params,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.services = data.services;
                    this.service_options = data.service_option;
                    if (
                        data.services.length == 0 &&
                        data.service_option.length == 0
                    ) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Lokasi atau Kategori Harga Tidak Sesuai!",
                        });
                    }
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        checkPrice() {
            this.is_find_price = true;
            var params = {
                origin_regency: this.origin?.name,
                destination_regency: this.destination?.name,
                service: this.service.service,
            };
            if (this.service.service == "door_to_door") {
                params.service_option = this.option.option;
            }
            if (this.brand_year != "") {
                params.brand_class = this.brand_class.value;
            } else {
                params.brand_class = this.brand_class;
            }
            if (this.filteredSearch.length == 0) {
                params.brand_type = this.search;
                params.brand_name = this.search_merk;
                params.brand_year = this.brand_year;
            }
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/pricing/services/pricing`,
                {
                    params: params,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.price = res.data.data;
                    this.is_find_price = false;
                    this.code = res.data.code;
                })
                .catch((err) => {
                    this.code = err.response.data.code;
                    this.is_find_price = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        resetForm() {
            this.origin = null;
            this.destination = null;
            this.search = "";
            this.filteredSearch = [];
            (this.search_merk = ""), (this.filteredSearchMerk = []);
            this.services = [];
            this.service = "";
            this.service_options = [];
            (this.option = ""), (this.brand_class = null);
            this.merk_class = null;
            this.brand_year = "";
            this.price = null;
        },
    },
};
</script>

<style scoped>
.car-type-box-body {
    width: 100%;
    height: 34px;
}
.car-type-box {
    width: 100%;
    height: 100%;
    padding: 0 13px 0 13px;
    padding-bottom: 2px;
    border-radius: 5px;
    font-size: 14px;
    background: #fff;
    border: 1px solid #e9ecef !important;
}
.search-box-car {
    box-shadow: 0 1px 12px -1px rgb(141 145 162 / 25%);
    position: absolute;
    width: 100%;
    background: #fff;
    top: 40px;
    z-index: 9999;
    padding: 13px;
    border-radius: 10px;
}
.search-title-car {
    padding: 6px 10px;
    cursor: pointer;
}
.search-title-car:hover {
    background: #f7f7f7;
    transition: 0.3s;
    border-radius: 4px;
}
.scroll-dropdown {
    height: 200px;
    overflow-y: scroll;
}
.text-title-car {
    color: #8e8e94;
}
</style>
