<template>
    <div class="padding-container">
        <div class="tab-content" id="pills-tabContent">
            <div v-if="distribType == 'order'">
                <div class="box" v-if="page_content == 'list_ordered'">
                    <div class="mb-4">
                        <h3 class="fw-bold">Ordered Data</h3>
                    </div>
                    <div class="d-flex align-items-center no-flex">
                        <div class="me-4 m-mx-0 m-mt-2">
                            <div class="position-relative">
                                <input
                                    class="form-control form-control-lg pl-search w-search-leads"
                                    v-model="keySearch"
                                    @input="searchData()"
                                    type="text"
                                    style="padding-right: 30px"
                                    placeholder="Search"
                                />
                                <span class="search-icon left">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </div>
                        <div
                            class="m-mx-0 m-mt-2 me-3"
                            v-if="checkRole(crmRoles)"
                        >
                            <button
                                class="btn btn-outline-black btn-filter px-4"
                                @click="isFilter = !isFilter"
                            >
                                <img
                                    src="../../assets/dashboard/filter.png"
                                    class="me-1"
                                    width="18"
                                    alt=""
                                />
                                Filter
                            </button>
                        </div>
                        <div
                            v-if="checkRole(crmRoles)"
                            class="me-3 m-mx-0 m-mt-2"
                        >
                            <button
                                class="btn btn-green btn-with-search px-4"
                                @click="getExport"
                                disabled
                            >
                                <img
                                    src="../../assets/excel.png"
                                    width="17"
                                    class="me-1"
                                    alt=""
                                />
                                Export
                            </button>
                        </div>
                        <div
                            v-if="checkRole(crmRoles)"
                            class="me-3 m-mx-0 m-mt-2"
                        >
                            <button
                                class="btn btn-blue btn-with-search px-4"
                                @click="distribModal = true"
                                :disabled="valueCheckBox.length == 0"
                            >
                                Distribution To
                            </button>
                        </div>
                        <div
                            v-if="checkRole(crmRoles)"
                            class="me-auto m-mx-0 m-mt-2"
                        >
                            <button
                                class="btn btn-purple btn-with-search px-4"
                                @click="addTagsOrdered()"
                                :disabled="valueCheckBox.length == 0"
                            >
                                Add Tags
                            </button>
                        </div>
                    </div>
                    <div class="box mt-3 position-relative" v-if="isFilter">
                        <div class="row">
                            <div class="col-md-4">
                                <div>
                                    <label class="fw-bold">Date Range</label>
                                    <select
                                        class="form-control form-lg form-select mt-1"
                                        v-model="range"
                                        @change="changeDateRange()"
                                    >
                                        <option value="">Select Range</option>
                                        <option value="custom">Custom</option>
                                        <option value="today">Today</option>
                                        <option value="yesterday">
                                            Yesterday
                                        </option>
                                        <option value="this_week">
                                            This Week
                                        </option>
                                        <option value="last_week">
                                            Last Week
                                        </option>
                                        <option value="to_weeks_ago">
                                            Two Weeks Ago
                                        </option>
                                        <option value="this_month">
                                            This Month
                                        </option>
                                        <option value="last_month">
                                            Last Month
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label class="fw-bold">Start</label>
                                <date-picker
                                    v-model="req.start"
                                    type="date"
                                    value-type="format"
                                    class="w-100 mt-1"
                                    format="DD MMMM YYYY"
                                    :clearable="false"
                                    placeholder="Start"
                                >
                                </date-picker>
                            </div>
                            <div class="col-md-4">
                                <label class="fw-bold">End</label>
                                <date-picker
                                    v-model="req.end"
                                    type="date"
                                    value-type="format"
                                    class="w-100 mt-1"
                                    format="DD MMMM YYYY"
                                    :clearable="false"
                                    placeholder="End"
                                >
                                </date-picker>
                            </div>
                        </div>
                        <div class="mt-3">
                            <button
                                class="btn btn-outline-black px-5 me-3"
                                :disabled="range == ''"
                                @click="clearFilter()"
                            >
                                Clear
                            </button>
                            <button
                                class="btn btn-green px-5 me-3"
                                :disabled="range == ''"
                                @click="filterDate()"
                            >
                                Filter
                            </button>
                        </div>
                    </div>
                    <div class="mt-4" v-if="is_my_list_ready">
                        <ListLoader />
                    </div>
                    <div v-else>
                        <div class="table-responsive-custom">
                            <table class="table table-bordered mt-4">
                                <thead class="table-light">
                                    <tr>
                                        <th
                                            v-if="checkRole(crmRoles)"
                                            class="text-center"
                                            scope="col"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="selectAllOrdered"
                                                id="allCheck"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="allCheck"
                                            ></label>
                                        </th>
                                        <th class="text-center" scope="col">
                                            No
                                        </th>
                                        <th class="text-center" scope="col">
                                            Customers Name
                                        </th>
                                        <th class="text-center" scope="col">
                                            Phone
                                        </th>
                                        <th class="text-center" scope="col">
                                            Money Paid
                                        </th>
                                        <th class="text-center" scope="col">
                                            Last Order
                                        </th>
                                        <th class="text-center" scope="col">
                                            Prospect Count
                                        </th>
                                        <th class="text-center" scope="col">
                                            Prospect
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(item, index) in orderData"
                                        :key="index"
                                    >
                                        <td
                                            v-if="checkRole(crmRoles)"
                                            class="text-center"
                                        >
                                            <b-form-group
                                                v-slot="{ ariaDescribedby }"
                                            >
                                                <b-form-checkbox-group
                                                    id="checkbox-group-2"
                                                    v-model="valueCheckBox"
                                                    :aria-describedby="
                                                        ariaDescribedby
                                                    "
                                                    name="flavour-2"
                                                >
                                                    <b-form-checkbox
                                                        :value="item"
                                                    ></b-form-checkbox>
                                                </b-form-checkbox-group>
                                            </b-form-group>
                                        </td>
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="">
                                            <div class="d-flex">
                                                <div>
                                                    <router-link
                                                        class="text-link"
                                                        :to="{
                                                            name: 'crm-customers-order-detail',
                                                            params: {
                                                                id: item.id,
                                                            },
                                                        }"
                                                        >{{
                                                            item.name
                                                                ? item.name
                                                                : "-"
                                                        }}</router-link
                                                    >
                                                </div>
                                                <div class="ms-auto">
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="dropdown-toggle"
                                                        id="statusRefund"
                                                        data-bs-toggle="dropdown"
                                                        data-bs-auto-close="true"
                                                        aria-expanded="false"
                                                    >
                                                        <img
                                                            src="../../assets/dashboard/ellipsis.png"
                                                            width="18"
                                                            alt=""
                                                        />
                                                    </a>
                                                    <ul
                                                        class="dropdown-menu"
                                                        aria-labelledby="statusRefund"
                                                    >
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                :href="
                                                                    '/crm/customers/order/prospect/' +
                                                                    item.id +
                                                                    '?type=mail'
                                                                "
                                                            >
                                                                <img
                                                                    src="../../assets/dashboard/email.png"
                                                                    width="18"
                                                                    class="me-2"
                                                                    alt=""
                                                                />
                                                                Send Mail
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                href="javascript:void(0)"
                                                                @click="
                                                                    clickNotes(
                                                                        item
                                                                    )
                                                                "
                                                            >
                                                                <img
                                                                    src="../../assets/dashboard/notes.png"
                                                                    width="18"
                                                                    class="me-2"
                                                                    alt=""
                                                                />
                                                                Notes
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                :href="
                                                                    '/crm/customers/order/prospect/' +
                                                                    item.id +
                                                                    '?type=meeting'
                                                                "
                                                            >
                                                                <img
                                                                    src="../../assets/dashboard/meeting.png"
                                                                    width="18"
                                                                    class="me-2"
                                                                    alt=""
                                                                />
                                                                Meeting
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                href="javascript:void(0)"
                                                                @click="
                                                                    clickWA(
                                                                        item.id,
                                                                        item.phone
                                                                    )
                                                                "
                                                            >
                                                                <img
                                                                    src="../../assets/dashboard/wa.png"
                                                                    width="18"
                                                                    class="me-2"
                                                                    alt=""
                                                                />
                                                                WhatsApp
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{ item.phone ? item.phone : "-" }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                                currency(item.paid_order_amount)
                                            }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                                item.last_order
                                                    ? item.last_order
                                                    : "-"
                                            }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                                item.prospect_count
                                                    ? item.prospect_count + "x"
                                                    : "-"
                                            }}
                                        </td>
                                        <td class="text-center">
                                            <router-link
                                                class="text-link"
                                                :to="{
                                                    name: 'crm-customers-order-prospect',
                                                    params: {
                                                        id: item.id,
                                                        item: item,
                                                        type: 'order',
                                                        isRole: checkRole(
                                                            crmRoles
                                                        ),
                                                    },
                                                }"
                                                ><img
                                                    src="../../assets/book-icon.png"
                                            /></router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div
                            v-if="orderData.length == 0"
                            class="text-center mt-section"
                        >
                            <img
                                src="../../assets/no-data.png"
                                width="100"
                                alt=""
                            />
                            <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                Data Kosong
                            </h4>
                        </div>
                    </div>
                    <div class="overflow-auto mt-3">
                        <b-pagination-nav
                            v-if="orderData.length > 0"
                            v-model="currentPage"
                            :number-of-pages="totalPage"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage()"
                        ></b-pagination-nav>
                    </div>
                </div>
                <div v-if="page_content == 'notes'">
                    <div class="fw-bold">
                        <a
                            href="javascript:void(0)"
                            @click="page_content = 'list_ordered'"
                            class="text-gray-dashboard size-16"
                        >
                            <i class="fa fa-angle-left me-2"></i>
                            Back
                        </a>
                    </div>
                    <div class="box mt-4">
                        <div class="d-flex align-items-center">
                            <h3 class="fw-bold mb-3">Add New Notes</h3>
                        </div>
                        <div class="mt-3">
                            <label class="fw-bold mb-2">Notes</label>
                            <textarea
                                rows="7"
                                cols="30"
                                class="form-control"
                                placeholder="Type Here"
                                v-model="req.notes"
                            ></textarea>
                        </div>
                        <div class="mt-3">
                            <label class="fw-bold">Document (Optional)</label>
                            <div class="text-gray mb-2">Document max 5MB</div>
                            <label
                                type="file"
                                :class="!attachment ? 'upload__btn' : ''"
                            >
                                <img
                                    v-if="attachment"
                                    class="photo"
                                    :src="attachmentPictureUrl"
                                />
                                <p v-else class="mb-0">
                                    <i class="fa fa-plus"></i>
                                </p>
                                <input
                                    class="cursor-pointer"
                                    id="upload"
                                    type="file"
                                    name="file"
                                    ref="file"
                                    @change="uploadPhoto"
                                />
                            </label>
                        </div>
                        <div class="mt-4">
                            <button
                                class="btn btn-green btn-lg px-5"
                                type="button"
                                disabled
                                v-if="is_save_notes"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Save
                            </button>
                            <button
                                type="submit"
                                v-else
                                class="btn btn-green btn-lg px-5"
                                @click="saveNotes()"
                                :disabled="req.notes == ''"
                            >
                                Save
                            </button>
                        </div>
                    </div>

                    <div class="modal-vue">
                        <div class="overlay" v-if="modalSuccessNotes"></div>
                        <div
                            class="modal-body-chat vsm"
                            v-if="modalSuccessNotes"
                        >
                            <div class="mt-4 text-center">
                                <img
                                    src="../../assets/dashboard/checklis.png"
                                    width="80"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4">
                                    Create Notes Succeeded!
                                </h4>
                            </div>
                            <div class="mt-4">
                                <template v-if="distribType == 'lead'">
                                    <a
                                        href="/crm/customers/leads"
                                        class="btn btn-green w-100 btn-lg"
                                    >
                                        Ok
                                    </a>
                                </template>
                                <template v-else-if="distribType == 'order'">
                                    <a
                                        href="/crm/customers/ordered"
                                        class="btn btn-green w-100 btn-lg"
                                    >
                                        Ok
                                    </a>
                                </template>
                                <template v-else>
                                    <a
                                        href="/crm/customers/registered"
                                        class="btn btn-green w-100 btn-lg"
                                    >
                                        Ok
                                    </a>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <template v-if="page_content == 'page_tags'">
                    <div class="fw-bold">
                        <a
                            href="/crm/customers/ordered"
                            class="text-gray-dashboard size-16"
                        >
                            <i class="fa fa-angle-left me-2"></i>
                            Back
                        </a>
                    </div>
                    <div class="box mt-4 m-mt-0">
                        <h3 class="fw-bold mb-0">Add New Tags</h3>
                        <div class="mt-3">
                            <label class="mb-2 fw-bold">Tags Name</label>
                            <input-tag
                                v-model="tags"
                                class="input-tag mb-3"
                                placeholder="Enter Your Tags"
                            ></input-tag>
                        </div>
                        <div class="d-flex mt-4">
                            <div class="ms-auto">
                                <a
                                    href="/crm/customers/leads"
                                    class="btn btn-outline-black btn-lg px-5 me-2"
                                >
                                    Cancel
                                </a>
                                <button
                                    class="btn btn-green btn-lg px-5"
                                    type="button"
                                    disabled
                                    v-if="is_save_tags"
                                >
                                    <span
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Save
                                </button>
                                <button
                                    v-else
                                    class="btn btn-green btn-lg px-5"
                                    @click="saveTags()"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <div v-if="distribType == 'lead'">
                <div class="box" v-if="page_content_leads == 'list_leads'">
                    <div class="mb-3">
                        <div class="d-flex">
                            <h3 class="fw-bold">Prospect and Leads Data</h3>
                            <div class="ms-auto">
                                <router-link
                                    :to="{ name: 'crm-customers-form' }"
                                    class="btn btn-primary px-5 btn-with-search"
                                >
                                    Add New Prospect / Leads
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center no-flex mt-4">
                        <div class="me-4 m-mx-0 m-mt-2">
                            <div class="position-relative">
                                <input
                                    class="form-control form-control-lg pl-search w-search-leads"
                                    v-model="keySearch"
                                    @input="searchData()"
                                    type="text"
                                    style="padding-right: 30px"
                                    placeholder="Search"
                                />
                                <span class="search-icon left">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </div>
                        <div class="m-mx-0 m-mt-2 me-3">
                            <button
                                class="btn btn-outline-black btn-filter px-4"
                                @click="isFilter = !isFilter"
                            >
                                <img
                                    src="../../assets/dashboard/filter.png"
                                    class="me-1"
                                    width="18"
                                    alt=""
                                />
                                Filter
                            </button>
                        </div>
                        <div
                            v-if="checkRole(crmRoles)"
                            class="me-3 m-mx-0 m-mt-2"
                        >
                            <button
                                class="btn btn-green btn-with-search px-4"
                                @click="getExport"
                                disabled
                            >
                                <img
                                    src="../../assets/excel.png"
                                    width="17"
                                    class="me-1"
                                    alt=""
                                />
                                Export
                            </button>
                        </div>
                        <div
                            v-if="checkRole(crmRoles)"
                            class="me-3 m-mx-0 m-mt-2"
                        >
                            <button
                                class="btn btn-blue btn-with-search px-4"
                                @click="distribModal = true"
                                :disabled="valueCheckBox.length == 0"
                            >
                                Distribution To
                            </button>
                        </div>
                        <div
                            v-if="checkRole(crmRoles)"
                            class="me-auto m-mx-0 m-mt-2"
                        >
                            <button
                                class="btn btn-purple btn-with-search px-4"
                                @click="addTagsLeads()"
                                :disabled="valueCheckBox.length == 0"
                            >
                                Add Tags
                            </button>
                        </div>
                    </div>
                    <div class="box mt-3 position-relative" v-if="isFilter">
                        <div class="row">
                            <div
                                class="col-md-12 mb-2"
                                v-show="checkRole(branchRoles)"
                            >
                                <label class="fw-bold mb-1">Branch</label>
                                <multiselect
                                    v-model="branch"
                                    :options="branchs"
                                    label="name"
                                    track-by="id"
                                    placeholder="Select Branch"
                                    class="multi-form-custom"
                                >
                                </multiselect>
                            </div>
                            <div
                                class="col-md-6 mb-2"
                                v-if="checkRole(crmRoles)"
                            >
                                <label class="fw-bold mb-1">Tags</label>
                                <multiselect
                                    v-model="tag"
                                    :options="tag_filters"
                                    label="tag"
                                    track-by="tag"
                                    placeholder="Select Tags"
                                    class="multi-form-custom"
                                >
                                </multiselect>
                            </div>
                            <div
                                class="col-md-6 mb-2"
                                v-if="checkRole(crmRoles)"
                            >
                                <label class="fw-bold mb-1">Type</label>
                                <multiselect
                                    v-model="type_filter"
                                    :options="types"
                                    label="label"
                                    track-by="type"
                                    placeholder="Select Type"
                                    class="multi-form-custom"
                                >
                                </multiselect>
                            </div>
                            <div
                                class="col-md-12 mb-2"
                                v-show="checkRole(crmRoles)"
                            >
                                <label class="fw-bold mb-1"
                                    >CRM Specialist</label
                                >
                                <multiselect
                                    v-model="specialist"
                                    :options="specialists"
                                    label="name"
                                    track-by="id"
                                    placeholder="Select CRM Specialist"
                                    class="multi-form-custom"
                                >
                                </multiselect>
                            </div>
                            <div class="col-md-4">
                                <div>
                                    <label class="fw-bold">Date Range</label>
                                    <select
                                        class="form-control form-lg form-select mt-1"
                                        v-model="range"
                                        @change="changeDateRange()"
                                    >
                                        <option value="">Select Range</option>
                                        <option value="custom">Custom</option>
                                        <option value="today">Today</option>
                                        <option value="yesterday">
                                            Yesterday
                                        </option>
                                        <option value="this_week">
                                            This Week
                                        </option>
                                        <option value="last_week">
                                            Last Week
                                        </option>
                                        <option value="to_weeks_ago">
                                            Two Weeks Ago
                                        </option>
                                        <option value="this_month">
                                            This Month
                                        </option>
                                        <option value="last_month">
                                            Last Month
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label class="fw-bold">Start</label>
                                <date-picker
                                    v-model="req.start"
                                    type="date"
                                    value-type="format"
                                    class="w-100 mt-1"
                                    format="DD MMMM YYYY"
                                    :clearable="false"
                                    placeholder="Start"
                                    @change="getStartDate(req.start)"
                                >
                                </date-picker>
                            </div>
                            <div class="col-md-4">
                                <label class="fw-bold">End</label>
                                <date-picker
                                    v-model="req.end"
                                    type="date"
                                    value-type="format"
                                    class="w-100 mt-1"
                                    format="DD MMMM YYYY"
                                    :clearable="false"
                                    placeholder="End"
                                    @change="getStartDate(req.end)"
                                >
                                </date-picker>
                            </div>
                        </div>
                        <div class="mt-3">
                            <button
                                class="btn btn-outline-black px-5 me-3"
                                :disabled="
                                    range == '' &&
                                    branch == null &&
                                    tag == null &&
                                    type_filter == null
                                "
                                @click="clearFilter()"
                            >
                                Clear
                            </button>
                            <button
                                class="btn btn-green px-5 me-3"
                                :disabled="
                                    range == '' &&
                                    branch == null &&
                                    specialist == null &&
                                    tag == null &&
                                    type_filter == null
                                "
                                @click="filterDate()"
                            >
                                Filter
                            </button>
                        </div>
                    </div>
                    <div class="mt-4" v-if="is_my_list_ready">
                        <ListLoader />
                    </div>
                    <div v-else>
                        <div class="table-responsive-custom">
                            <table class="table table-bordered mt-4">
                                <thead class="table-light">
                                    <tr>
                                        <th
                                            v-if="checkRole(crmRoles)"
                                            class="text-center"
                                            scope="col"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="selectAllLeads"
                                                id="allCheck"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="allCheck"
                                            ></label>
                                        </th>
                                        <th class="text-center" scope="col">
                                            No
                                        </th>
                                        <th class="text-center" scope="col">
                                            Full Name
                                        </th>
                                        <th class="text-center" scope="col">
                                            Phone
                                        </th>
                                        <th
                                            class="text-center"
                                            scope="col"
                                            v-if="checkRole(crmRoles)"
                                        >
                                            CRM Specialist
                                        </th>
                                        <th class="text-center" scope="col">
                                            Created
                                        </th>
                                        <th class="text-center" scope="col">
                                            Tags
                                        </th>
                                        <th class="text-center" scope="col">
                                            Source Data
                                        </th>
                                        <th class="text-center" scope="col">
                                            Data Type
                                        </th>
                                        <th class="text-center" scope="col">
                                            Last Status
                                        </th>
                                        <th
                                            class="text-center"
                                            scope="col"
                                            v-show="checkRole(branchRoles)"
                                        >
                                            Branch Name
                                        </th>
                                        <th class="text-center" scope="col">
                                            Follow up
                                        </th>
                                        <th class="text-center" scope="col">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(item, index) in leadsData"
                                        :key="index"
                                    >
                                        <td
                                            v-if="checkRole(crmRoles)"
                                            class="text-center"
                                        >
                                            <b-form-group
                                                v-slot="{ ariaDescribedby }"
                                            >
                                                <b-form-checkbox-group
                                                    id="checkbox-group-2"
                                                    v-model="valueCheckBox"
                                                    :aria-describedby="
                                                        ariaDescribedby
                                                    "
                                                    name="flavour-2"
                                                >
                                                    <b-form-checkbox
                                                        :value="item"
                                                    ></b-form-checkbox>
                                                </b-form-checkbox-group>
                                            </b-form-group>
                                        </td>
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="">
                                            <div class="d-flex">
                                                <div>
                                                    <img
                                                        src="../../assets/qontak.png"
                                                        width="16"
                                                        class="me-1"
                                                        v-if="
                                                            item.is_qontak ==
                                                            true
                                                        "
                                                    />
                                                    <a
                                                        :href="
                                                            '/crm/customers/leads/detail/' +
                                                            item.id
                                                        "
                                                        class="text-link"
                                                    >
                                                        {{
                                                            item.name
                                                                ? item.name
                                                                : "-"
                                                        }}
                                                    </a>
                                                </div>
                                                <div class="ms-auto">
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="dropdown-toggle"
                                                        id="statusRefund"
                                                        data-bs-toggle="dropdown"
                                                        data-bs-auto-close="true"
                                                        aria-expanded="false"
                                                    >
                                                        <img
                                                            src="../../assets/dashboard/ellipsis.png"
                                                            width="18"
                                                            alt=""
                                                        />
                                                    </a>
                                                    <ul
                                                        class="dropdown-menu"
                                                        aria-labelledby="statusRefund"
                                                    >
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                :href="
                                                                    '/crm/customers/leads/prospect/' +
                                                                    item.id +
                                                                    '?type=mail'
                                                                "
                                                            >
                                                                <img
                                                                    src="../../assets/dashboard/email.png"
                                                                    width="18"
                                                                    class="me-2"
                                                                    alt=""
                                                                />
                                                                Send Mail
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                href="javascript:void(0)"
                                                                @click="
                                                                    clickNotesLeads(
                                                                        item
                                                                    )
                                                                "
                                                            >
                                                                <img
                                                                    src="../../assets/dashboard/notes.png"
                                                                    width="18"
                                                                    class="me-2"
                                                                    alt=""
                                                                />
                                                                Notes
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                :href="
                                                                    '/crm/customers/order/prospect/' +
                                                                    item.id +
                                                                    '?type=meeting'
                                                                "
                                                            >
                                                                <img
                                                                    src="../../assets/dashboard/meeting.png"
                                                                    width="18"
                                                                    class="me-2"
                                                                    alt=""
                                                                />
                                                                Meeting
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                href="javascript:void(0)"
                                                                @click="
                                                                    clickWALeads(
                                                                        item.id,
                                                                        item.phone
                                                                    )
                                                                "
                                                            >
                                                                <img
                                                                    src="../../assets/dashboard/wa.png"
                                                                    width="18"
                                                                    class="me-2"
                                                                    alt=""
                                                                />
                                                                WhatsApp
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{ item.phone }}
                                        </td>
                                        <td
                                            class="text-center"
                                            v-if="checkRole(crmRoles)"
                                        >
                                            {{
                                                item.created_by
                                                    ? item.created_by
                                                    : "-"
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                item.created_at
                                                    ? item.created_at
                                                    : "-"
                                            }}
                                        </td>
                                        <td>
                                            <div
                                                v-if="
                                                    Array.isArray(item.tag) &&
                                                    item.tag.length
                                                "
                                                class="d-flex"
                                            >
                                                <div
                                                    v-b-tooltip.hover.bottom="
                                                        tag
                                                    "
                                                    v-for="(
                                                        tag, index
                                                    ) in item.tag"
                                                    :key="index"
                                                    class="bg-tag rounded-pill px-3 py-1 me-2 mb-2 text-capitalize"
                                                >
                                                    {{
                                                        tag.length > 10
                                                            ? tag.slice(0, 10) +
                                                              "..."
                                                            : tag
                                                    }}
                                                </div>
                                            </div>
                                            <div v-else>-</div>
                                        </td>
                                        <td class="capitalize text-center">
                                            {{
                                                item.source ? item.source : "-"
                                            }}
                                        </td>
                                        <td>
                                            <div class="d-flex">
                                                <div
                                                    v-b-tooltip.hover.bottom="
                                                        item.type === 'leads'
                                                            ? 'New Customer'
                                                            : item.type ===
                                                              'prospect'
                                                            ? 'Repeat Order'
                                                            : ''
                                                    "
                                                    :class="[
                                                        'rounded-pill text-capitalize text-center mb-2 me-2 py-1 px-3',
                                                        item.type === 'prospect'
                                                            ? 'bg-type-prospect fc-type-prospect'
                                                            : '',
                                                        item.type === 'leads'
                                                            ? 'bg-type-leads fc-type-leads'
                                                            : '',
                                                    ]"
                                                >
                                                    {{
                                                        item.type
                                                            ? item.type
                                                            : "-"
                                                    }}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex">
                                                <div
                                                    v-if="item.last_status"
                                                    v-b-tooltip.hover.bottom="
                                                        item.last_status.caption
                                                    "
                                                    :class="[
                                                        'rounded-pill px-3 py-1 me-2 mb-2 text-center text-capitalize',
                                                        item.last_status
                                                            .status === 'deal'
                                                            ? 'bg-type-prospect fc-type-prospect'
                                                            : '',
                                                        item.last_status
                                                            .status === 'lost'
                                                            ? 'bg-status-lost fc-status-lost'
                                                            : '',
                                                        item.last_status
                                                            .status ===
                                                        'follow_up'
                                                            ? 'bg-type-leads fc-type-leads'
                                                            : '',
                                                    ]"
                                                >
                                                    {{
                                                        item.last_status
                                                            .status ===
                                                        "follow_up"
                                                            ? "Follow Up"
                                                            : item.last_status
                                                                  .status
                                                    }}
                                                </div>
                                                <div v-else>-</div>
                                            </div>
                                        </td>
                                        <td
                                            class="text-center"
                                            v-show="checkRole(branchRoles)"
                                        >
                                            {{ item.branch_name }}
                                        </td>
                                        <td class="text-center">
                                            <router-link
                                                class="text-link"
                                                :to="{
                                                    name: 'crm-customers-leads-prospect',
                                                    params: {
                                                        id: item.id,
                                                        item: item,
                                                        type: 'lead',
                                                        isRole: checkRole(
                                                            crmRoles
                                                        ),
                                                    },
                                                }"
                                            >
                                                <img
                                                    src="../../assets/dashboard/prospect.png"
                                                    width="20"
                                                />
                                            </router-link>
                                        </td>
                                        <td class="text-center">
                                            <a
                                                :href="
                                                    '/crm/customers/leads/form/' +
                                                    item.id
                                                "
                                            >
                                                <img
                                                    src="../../assets/dashboard/edit-icon.png"
                                                    width="20"
                                                />
                                            </a>
                                            <a
                                                href="javascript:void(0)"
                                                @click="
                                                    showModalDeleteLeads(
                                                        item.id
                                                    )
                                                "
                                                class="ms-2"
                                            >
                                                <img
                                                    src="../../assets/dashboard/trash-icon.png"
                                                    width="20"
                                                />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div
                            v-if="leadsData.length == 0"
                            class="text-center mt-section"
                        >
                            <img
                                src="../../assets/no-data.png"
                                width="100"
                                alt=""
                            />
                            <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                Data Kosong
                            </h4>
                        </div>
                    </div>
                    <div class="d-flex" v-if="leadsData.length > 0">
                        <div class="ms-auto">
                            <div class="d-flex mt-4">
                                <div class="me-3">
                                    <select
                                        class="form-control form-select w-select"
                                        v-model="limit"
                                        @change="getLeadsData()"
                                    >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                                <b-pagination-nav
                                    v-model="currentPage"
                                    :number-of-pages="totalPage"
                                    base-url="#"
                                    first-number
                                    align="right"
                                    @input="changePage()"
                                ></b-pagination-nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="page_content_leads == 'notes'">
                    <div class="fw-bold">
                        <a
                            href="javascript:void(0)"
                            @click="page_content_leads = 'list_leads'"
                            class="text-gray-dashboard size-16"
                        >
                            <i class="fa fa-angle-left me-2"></i>
                            Back
                        </a>
                    </div>
                    <div class="box mt-4">
                        <div class="d-flex align-items-center">
                            <h3 class="fw-bold mb-3">Add New Notes</h3>
                        </div>
                        <div class="mt-3">
                            <label class="fw-bold mb-2">Notes</label>
                            <textarea
                                rows="7"
                                cols="30"
                                class="form-control"
                                placeholder="Type Here"
                                v-model="req.notes"
                            ></textarea>
                        </div>
                        <div class="mt-3">
                            <label class="fw-bold">Document (Optional)</label>
                            <div class="text-gray mb-2">Document max 5MB</div>
                            <label
                                type="file"
                                :class="!attachment ? 'upload__btn' : ''"
                            >
                                <img
                                    v-if="attachment"
                                    class="photo"
                                    :src="attachmentPictureUrl"
                                />
                                <p v-else class="mb-0">
                                    <i class="fa fa-plus"></i>
                                </p>
                                <input
                                    class="cursor-pointer"
                                    id="upload"
                                    type="file"
                                    name="file"
                                    ref="file"
                                    @change="uploadPhoto"
                                />
                            </label>
                        </div>
                        <div class="mt-4">
                            <button
                                class="btn btn-green btn-lg px-5"
                                type="button"
                                disabled
                                v-if="is_save_notes"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Save
                            </button>
                            <button
                                type="submit"
                                v-else
                                class="btn btn-green btn-lg px-5"
                                @click="saveNotes()"
                                :disabled="req.notes == ''"
                            >
                                Save
                            </button>
                        </div>
                    </div>

                    <div class="modal-vue">
                        <div class="overlay" v-if="modalSuccessNotes"></div>
                        <div
                            class="modal-body-chat vsm"
                            v-if="modalSuccessNotes"
                        >
                            <div class="mt-4 text-center">
                                <img
                                    src="../../assets/dashboard/checklis.png"
                                    width="80"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4">
                                    Create Notes Succeeded!
                                </h4>
                            </div>
                            <div class="mt-4">
                                <template v-if="distribType == 'lead'">
                                    <a
                                        href="/crm/customers/leads"
                                        class="btn btn-green w-100 btn-lg"
                                    >
                                        Ok
                                    </a>
                                </template>
                                <template v-else-if="distribType == 'order'">
                                    <a
                                        href="/crm/customers/ordered"
                                        class="btn btn-green w-100 btn-lg"
                                    >
                                        Ok
                                    </a>
                                </template>
                                <template v-else>
                                    <a
                                        href="/crm/customers/registered"
                                        class="btn btn-green w-100 btn-lg"
                                    >
                                        Ok
                                    </a>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <template v-if="page_content_leads == 'page_tags'">
                    <div class="fw-bold">
                        <a
                            href="/crm/customers/leads"
                            class="text-gray-dashboard size-16"
                        >
                            <i class="fa fa-angle-left me-2"></i>
                            Back
                        </a>
                    </div>
                    <div class="box mt-4 m-mt-0">
                        <h3 class="fw-bold mb-0">Add New Tags</h3>
                        <div class="mt-3">
                            <label class="mb-2 fw-bold">Tags Name</label>
                            <input-tag
                                v-model="tags"
                                class="input-tag mb-3"
                                placeholder="Enter Your Tags"
                            ></input-tag>
                        </div>
                        <div class="d-flex mt-4">
                            <div class="ms-auto">
                                <a
                                    href="/crm/customers/leads"
                                    class="btn btn-outline-black btn-lg px-5 me-2"
                                >
                                    Cancel
                                </a>
                                <button
                                    class="btn btn-green btn-lg px-5"
                                    type="button"
                                    disabled
                                    v-if="is_save_tags"
                                >
                                    <span
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Save
                                </button>
                                <button
                                    v-else
                                    class="btn btn-green btn-lg px-5"
                                    @click="saveTags()"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="modal-vue">
                    <div class="overlay" v-if="modalSuccessDeleteLeads"></div>
                    <div
                        class="modal-body-chat vsm"
                        v-if="modalSuccessDeleteLeads"
                    >
                        <div class="mt-4 text-center">
                            <img
                                src="../../assets/exclamation-icon.png"
                                width="80"
                                alt=""
                            />
                            <h4 class="mb-0 fw-bold mt-4">
                                Are You Sure You Want To Delete This Data?
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mt-4">
                                <a
                                    href="javascript:void(0)"
                                    @click="modalSuccessDeleteLeads = false"
                                    class="btn btn-outline-black w-100 btn-lg"
                                >
                                    No
                                </a>
                            </div>
                            <div class="col-md-6 mt-4">
                                <button
                                    class="btn btn-green w-100 btn-lg"
                                    type="button"
                                    disabled
                                    v-if="is_delete_leads"
                                >
                                    <span
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Yes
                                </button>
                                <button
                                    v-else
                                    @click="deleteLeads()"
                                    type="button"
                                    class="btn btn-green w-100 btn-lg"
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="distribType == 'registered'">
                <div
                    class="box"
                    v-if="page_content_registered == 'list_registered'"
                >
                    <div class="mb-4">
                        <h3 class="fw-bold">Registered Data</h3>
                    </div>
                    <div class="d-flex align-items-center no-flex">
                        <div class="me-4 m-mx-0 m-mt-2">
                            <div class="position-relative">
                                <input
                                    class="form-control form-control-lg pl-search w-search-leads"
                                    v-model="keySearch"
                                    @input="searchData()"
                                    type="text"
                                    style="padding-right: 30px"
                                    placeholder="Search"
                                />
                                <span class="search-icon left">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </div>
                        <div
                            class="m-mx-0 m-mt-2 me-3"
                            v-if="checkRole(crmRoles)"
                        >
                            <button
                                class="btn btn-outline-black btn-filter px-4"
                                @click="isFilter = !isFilter"
                            >
                                <img
                                    src="../../assets/dashboard/filter.png"
                                    class="me-1"
                                    width="18"
                                    alt=""
                                />
                                Filter
                            </button>
                        </div>
                        <div
                            v-if="checkRole(crmRoles)"
                            class="me-3 m-mx-0 m-mt-2"
                        >
                            <button
                                class="btn btn-green btn-with-search px-4"
                                @click="getExport"
                                disabled
                            >
                                <img
                                    src="../../assets/excel.png"
                                    width="17"
                                    class="me-1"
                                    alt=""
                                />
                                Export
                            </button>
                        </div>
                        <div
                            v-if="checkRole(crmRoles)"
                            class="me-3 m-mx-0 m-mt-2"
                        >
                            <button
                                class="btn btn-blue btn-with-search px-4"
                                @click="distribModal = true"
                                :disabled="valueCheckBox.length == 0"
                            >
                                Distribution To
                            </button>
                        </div>
                        <div
                            v-if="checkRole(crmRoles)"
                            class="me-auto m-mx-0 m-mt-2"
                        >
                            <button
                                class="btn btn-purple btn-with-search px-4"
                                @click="addTagsRegistered()"
                                :disabled="valueCheckBox.length == 0"
                            >
                                Add Tags
                            </button>
                        </div>
                    </div>
                    <div class="box mt-3 position-relative" v-if="isFilter">
                        <div class="row">
                            <div class="col-md-4">
                                <div>
                                    <label class="fw-bold">Date Range</label>
                                    <select
                                        class="form-control form-lg form-select mt-1"
                                        v-model="range"
                                        @change="changeDateRange()"
                                    >
                                        <option value="">Select Range</option>
                                        <option value="custom">Custom</option>
                                        <option value="today">Today</option>
                                        <option value="yesterday">
                                            Yesterday
                                        </option>
                                        <option value="this_week">
                                            This Week
                                        </option>
                                        <option value="last_week">
                                            Last Week
                                        </option>
                                        <option value="to_weeks_ago">
                                            Two Weeks Ago
                                        </option>
                                        <option value="this_month">
                                            This Month
                                        </option>
                                        <option value="last_month">
                                            Last Month
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label class="fw-bold">Start</label>
                                <date-picker
                                    v-model="req.start"
                                    type="date"
                                    value-type="format"
                                    class="w-100 mt-1"
                                    format="DD MMMM YYYY"
                                    :clearable="false"
                                    placeholder="Start"
                                >
                                </date-picker>
                            </div>
                            <div class="col-md-4">
                                <label class="fw-bold">End</label>
                                <date-picker
                                    v-model="req.end"
                                    type="date"
                                    value-type="format"
                                    class="w-100 mt-1"
                                    format="DD MMMM YYYY"
                                    :clearable="false"
                                    placeholder="End"
                                >
                                </date-picker>
                            </div>
                        </div>
                        <div class="mt-3">
                            <button
                                class="btn btn-outline-black px-5 me-3"
                                :disabled="range == ''"
                                @click="clearFilter()"
                            >
                                Clear
                            </button>
                            <button
                                class="btn btn-green px-5 me-3"
                                :disabled="range == ''"
                                @click="filterDate()"
                            >
                                Filter
                            </button>
                        </div>
                    </div>
                    <div class="mt-4" v-if="is_my_list_ready">
                        <ListLoader />
                    </div>
                    <div v-else>
                        <div class="table-responsive-custom">
                            <table class="table table-bordered mt-4">
                                <thead class="table-light">
                                    <tr>
                                        <th
                                            v-if="checkRole(crmRoles)"
                                            class="text-center"
                                            scope="col"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="selectAllRegistered"
                                                id="allCheck"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="allCheck"
                                            ></label>
                                        </th>
                                        <th class="text-center" scope="col">
                                            No
                                        </th>
                                        <th class="text-center" scope="col">
                                            Customers Name
                                        </th>
                                        <th class="text-center" scope="col">
                                            Phone
                                        </th>
                                        <th class="text-center" scope="col">
                                            Created At
                                        </th>
                                        <th class="text-center" scope="col">
                                            Prospect Count
                                        </th>
                                        <th class="text-center" scope="col">
                                            Prospect
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(item, index) in registeredData"
                                        :key="index"
                                    >
                                        <td
                                            v-if="checkRole(crmRoles)"
                                            class="text-center"
                                        >
                                            <b-form-group
                                                v-slot="{ ariaDescribedby }"
                                            >
                                                <b-form-checkbox-group
                                                    id="checkbox-group-2"
                                                    v-model="valueCheckBox"
                                                    :aria-describedby="
                                                        ariaDescribedby
                                                    "
                                                    name="flavour-2"
                                                >
                                                    <b-form-checkbox
                                                        :value="item"
                                                    ></b-form-checkbox>
                                                </b-form-checkbox-group>
                                            </b-form-group>
                                        </td>
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="">
                                            <div class="d-flex">
                                                <div>
                                                    <router-link
                                                        class="text-link"
                                                        :to="{
                                                            name: 'crm-customers-registered-detail',
                                                            params: {
                                                                id: item.id,
                                                            },
                                                        }"
                                                        >{{
                                                            item.name
                                                                ? item.name
                                                                : "-"
                                                        }}</router-link
                                                    >
                                                </div>
                                                <div class="ms-auto">
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="dropdown-toggle"
                                                        id="statusRefund"
                                                        data-bs-toggle="dropdown"
                                                        data-bs-auto-close="true"
                                                        aria-expanded="false"
                                                    >
                                                        <img
                                                            src="../../assets/dashboard/ellipsis.png"
                                                            width="18"
                                                            alt=""
                                                        />
                                                    </a>
                                                    <ul
                                                        class="dropdown-menu"
                                                        aria-labelledby="statusRefund"
                                                    >
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                :href="
                                                                    '/crm/customers/registered/prospect/' +
                                                                    item.id +
                                                                    '?type=mail'
                                                                "
                                                            >
                                                                <img
                                                                    src="../../assets/dashboard/email.png"
                                                                    width="18"
                                                                    class="me-2"
                                                                    alt=""
                                                                />
                                                                Send Mail
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                href="javascript:void(0)"
                                                                @click="
                                                                    clickNotesRegistered(
                                                                        item
                                                                    )
                                                                "
                                                            >
                                                                <img
                                                                    src="../../assets/dashboard/notes.png"
                                                                    width="18"
                                                                    class="me-2"
                                                                    alt=""
                                                                />
                                                                Notes
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                :href="
                                                                    '/crm/customers/registered/prospect/' +
                                                                    item.id +
                                                                    '?type=meeting'
                                                                "
                                                            >
                                                                <img
                                                                    src="../../assets/dashboard/meeting.png"
                                                                    width="18"
                                                                    class="me-2"
                                                                    alt=""
                                                                />
                                                                Meeting
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                href="javascript:void(0)"
                                                                @click="
                                                                    clickWARegistered(
                                                                        item.id,
                                                                        item.phone
                                                                    )
                                                                "
                                                            >
                                                                <img
                                                                    src="../../assets/dashboard/wa.png"
                                                                    width="18"
                                                                    class="me-2"
                                                                    alt=""
                                                                />
                                                                WhatsApp
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{ item.phone ? item.phone : "-" }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                                moment(
                                                    item.date ? item.date : "-"
                                                ).format("DD/MM/YYYY")
                                            }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                                item.prospect_count
                                                    ? item.prospect_count + "x"
                                                    : "-"
                                            }}
                                        </td>
                                        <td class="text-center">
                                            <router-link
                                                class="text-link"
                                                :to="{
                                                    name: 'crm-customers-registered-prospect',
                                                    params: {
                                                        id: item.id,
                                                        item: item,
                                                        type: 'registered',
                                                        isRole: checkRole(
                                                            crmRoles
                                                        ),
                                                    },
                                                }"
                                                ><img
                                                    src="../../assets/book-icon.png"
                                            /></router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div
                            v-if="registeredData.length == 0"
                            class="text-center mt-section"
                        >
                            <img
                                src="../../assets/no-data.png"
                                width="100"
                                alt=""
                            />
                            <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                Data Kosong
                            </h4>
                        </div>
                    </div>
                    <div class="overflow-auto mt-3">
                        <b-pagination-nav
                            v-if="registeredData.length > 0"
                            v-model="currentPage"
                            :number-of-pages="totalPage"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage()"
                        ></b-pagination-nav>
                    </div>
                </div>
                <div v-if="page_content_registered == 'notes'">
                    <div class="fw-bold">
                        <a
                            href="javascript:void(0)"
                            @click="page_content = 'list_ordered'"
                            class="text-gray-dashboard size-16"
                        >
                            <i class="fa fa-angle-left me-2"></i>
                            Back
                        </a>
                    </div>
                    <div class="box mt-4">
                        <div class="d-flex align-items-center">
                            <h3 class="fw-bold mb-3">Add New Notes</h3>
                        </div>
                        <div class="mt-3">
                            <label class="fw-bold mb-2">Notes</label>
                            <textarea
                                rows="7"
                                cols="30"
                                class="form-control"
                                placeholder="Type Here"
                                v-model="req.notes"
                            ></textarea>
                        </div>
                        <div class="mt-3">
                            <label class="fw-bold">Document (Optional)</label>
                            <div class="text-gray mb-2">Document max 5MB</div>
                            <label
                                type="file"
                                :class="!attachment ? 'upload__btn' : ''"
                            >
                                <img
                                    v-if="attachment"
                                    class="photo"
                                    :src="attachmentPictureUrl"
                                />
                                <p v-else class="mb-0">
                                    <i class="fa fa-plus"></i>
                                </p>
                                <input
                                    class="cursor-pointer"
                                    id="upload"
                                    type="file"
                                    name="file"
                                    ref="file"
                                    @change="uploadPhoto"
                                />
                            </label>
                        </div>
                        <div class="mt-4">
                            <button
                                class="btn btn-green btn-lg px-5"
                                type="button"
                                disabled
                                v-if="is_save_notes"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Save
                            </button>
                            <button
                                type="submit"
                                v-else
                                class="btn btn-green btn-lg px-5"
                                @click="saveNotes()"
                                :disabled="req.notes == ''"
                            >
                                Save
                            </button>
                        </div>
                    </div>

                    <div class="modal-vue">
                        <div class="overlay" v-if="modalSuccessNotes"></div>
                        <div
                            class="modal-body-chat vsm"
                            v-if="modalSuccessNotes"
                        >
                            <div class="mt-4 text-center">
                                <img
                                    src="../../assets/dashboard/checklis.png"
                                    width="80"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4">
                                    Create Notes Succeeded!
                                </h4>
                            </div>
                            <div class="mt-4">
                                <template v-if="distribType == 'order'">
                                    <a
                                        href="/crm/customers/ordered"
                                        class="btn btn-green w-100 btn-lg"
                                    >
                                        Ok
                                    </a>
                                </template>
                                <template v-else-if="distribType == 'lead'">
                                    <a
                                        href="/crm/customers/leads"
                                        class="btn btn-green w-100 btn-lg"
                                    >
                                        Ok
                                    </a>
                                </template>
                                <template v-else>
                                    <a
                                        href="/crm/customers/registered"
                                        class="btn btn-green w-100 btn-lg"
                                    >
                                        Ok
                                    </a>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <template v-if="page_content_registered == 'page_tags'">
                    <div class="fw-bold">
                        <a
                            href="/crm/customers/registered"
                            class="text-gray-dashboard size-16"
                        >
                            <i class="fa fa-angle-left me-2"></i>
                            Back
                        </a>
                    </div>
                    <div class="box mt-4 m-mt-0">
                        <h3 class="fw-bold mb-0">Add New Tags</h3>
                        <div class="mt-3">
                            <label class="mb-2 fw-bold">Tags Name</label>
                            <input-tag
                                v-model="tags"
                                class="input-tag mb-3"
                                placeholder="Enter Your Tags"
                            ></input-tag>
                        </div>
                        <div class="d-flex mt-4">
                            <div class="ms-auto">
                                <a
                                    href="/crm/customers/leads"
                                    class="btn btn-outline-black btn-lg px-5 me-2"
                                >
                                    Cancel
                                </a>
                                <button
                                    class="btn btn-green btn-lg px-5"
                                    type="button"
                                    disabled
                                    v-if="is_save_tags"
                                >
                                    <span
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Save
                                </button>
                                <button
                                    v-else
                                    class="btn btn-green btn-lg px-5"
                                    @click="saveTags()"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div
                class="overlay"
                v-if="distribModal"
                @click="distribModal = false"
            ></div>
            <div class="modal-body-chat md" v-if="distribModal">
                <div class="mb-3">
                    <h3 class="fw-bold">Distribution To</h3>
                </div>
                <div class="position-relative mt-18 mb-35">
                    <input
                        class="form-control form-control-lg pl-search w-100"
                        v-model="searchTerm"
                        type="text"
                        placeholder="Search CRM Specialist Name"
                    />
                    <span class="search-icon left">
                        <i class="fa fa-search fa-beat fa-sm"></i>
                    </span>
                </div>
                <div class="scroll mb-2">
                    <div
                        v-for="(option, index) in filteredOptions"
                        :key="index"
                        class="radio-option fw-medium capitalize"
                    >
                        <label :for="option.id">
                            <input
                                type="radio"
                                :id="option.id"
                                :value="option.id"
                                v-model="selectedOption"
                            />
                            <span class="text mt-1">
                                {{ option.name }}
                            </span>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <button
                            class="btn btn-outline-black w-100 btn-lg"
                            @click="distribModal = false"
                        >
                            Cancel
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button
                            class="btn btn-green w-100 btn-lg"
                            @click="storeDistribute()"
                            :disabled="selectedOption == null"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isSaving"
                            ></span>
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Distribution Successfully!
                    </h4>
                </div>
                <div class="mt-4">
                    <template v-if="distribType == 'order'">
                        <a
                            href="/crm/customers/ordered"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ok
                        </a>
                    </template>
                    <template v-if="distribType == 'lead'">
                        <a
                            href="/crm/customers/leads"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ok
                        </a>
                    </template>
                    <template v-if="distribType == 'registered'">
                        <a
                            href="/crm/customers/registered"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ok
                        </a>
                    </template>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessTags"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessTags">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Create Tags Succeeded!</h4>
                </div>
                <div class="mt-4">
                    <template v-if="distribType == 'order'">
                        <a
                            href="/crm/customers/ordered"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ok
                        </a>
                    </template>
                    <template v-if="distribType == 'lead'">
                        <a
                            href="/crm/customers/leads"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ok
                        </a>
                    </template>
                    <template v-if="distribType == 'registered'">
                        <a
                            href="/crm/customers/registered"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ok
                        </a>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import DatePicker from "vue2-datepicker";
import noImage from "../../assets/no-photo.png";
import moment from "moment";
import InputTag from "vue-input-tag";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        DatePicker,
        InputTag,
        Multiselect,
    },
    name: "customersPage",
    props: {
        items: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            moment: moment,
            leadsData: "",
            orderData: "",
            specialistData: "",
            keySearch: "",
            distribType: "order",
            hash: "",
            roles: "",
            crmRoles: ["admin-crm", "crm-manager", "admin-super"],
            isSaving: false,
            is_my_list_ready: false,
            distribModal: false,
            modalSuccess: false,
            modalError: false,
            valueCheckBox: [],
            selectedOption: null,
            searchTerm: "",
            currentPage: 1,
            totalPage: 10,
            limit: 10,
            isFilter: false,
            req: {
                start: "",
                end: "",
                mail_from: "sales@trawlbens.id",
                mail_to: "",
                mail_cc: [],
                mail_subject: "",
                mail_content: "",
                notes: "",
                attachment: "",
            },
            tags: [],
            page_content: "list_ordered",
            page_content_leads: "list_leads",
            is_save_mail: false,
            item_id: null,
            attachment: "",
            is_save_notes: false,
            modalSuccessNotes: false,
            type: "",
            start_date: "",
            end_date: "",
            range: "",
            tags_value: [],
            modalSuccessTags: false,
            is_save_tags: false,
            modalSuccessDeleteLeads: false,
            leads_id: null,
            is_delete_leads: false,
            registeredData: [],
            page_content_registered: "list_registered",
            distribute_value: [],
            branch: null,
            branchs: [],
            branchRoles: ["admin-crm"],
            tag: "",
            tag_filters: [],
            type_filter: "",
            types: [
                {
                    type: "prospect",
                    label: "Prospect",
                },
                {
                    type: "leads",
                    label: "Leads",
                },
            ],
            specialist: null,
            specialists: [],
        };
    },
    created() {
        this.distribType = this.items.distribType;
        if (this.distribType == "order") this.getOrderData();
        if (this.distribType == "lead") this.getLeadsData();
        if (this.distribType == "registered") this.getRegisteredData();
        this.getSpecialistData();
        this.getBranchs();
        this.getUserLogin();
        this.getTags();
        this.getSpecialists();
    },
    methods: {
        clickWA(id, phone) {
            if (phone.startsWith("0")) phone = "62" + phone.slice(1);
            window.open("https://wa.me/" + phone, "_blank");
            window.location.href =
                "/crm/customers/order/prospect/" + id + "?type=whatsapp";
        },
        clickWALeads(id, phone) {
            if (phone.startsWith("0")) phone = "62" + phone.slice(1);
            window.open("https://wa.me/" + phone, "_blank");
            window.location.href =
                "/crm/customers/leads/prospect/" + id + "?type=whatsapp";
        },
        clickWARegistered(id, phone) {
            if (phone.startsWith("0")) phone = "62" + phone.slice(1);
            window.open("https://wa.me/" + phone, "_blank");
            window.location.href =
                "/crm/customers/registered/prospect/" + id + "?type=whatsapp";
        },
        clickNotes(item) {
            this.page_content = "notes";
            this.item_id = item.id;
        },
        clickNotesLeads(item) {
            this.page_content_leads = "notes";
            this.item_id = item.id;
        },
        clickNotesRegistered(item) {
            this.page_content_registered = "notes";
            this.item_id = item.id;
        },
        cancelFilter() {
            this.isFilter = false;
            this.range = "";
            this.req.start = "";
            this.req.end = "";
            this.start_date = "";
            this.end_date = "";
        },
        filterDate() {
            this.start_date = this.req.start;
            this.end_date = this.req.end;
            if (this.distribType == "order") this.getOrderData();
            if (this.distribType == "lead") this.getLeadsData();
            if (this.distribType == "registered") this.getRegisteredData();
        },
        clearFilter() {
            this.range = "";
            this.req.start = "";
            this.req.end = "";
            this.start_date = "";
            this.end_date = "";
            this.currentPage = 0;
            this.specialist = null;
            this.branch = null;
            this.tag = null;
            this.type_filter = null;
            if (this.distribType == "order") this.getOrderData();
            if (this.distribType == "lead") this.getLeadsData();
            if (this.distribType == "registered") this.getRegisteredData();
        },
        changeDateRange() {
            var today = new Date();
            var thisWeekStart = moment().clone().startOf("isoWeek");
            var thisWeekEnd = moment().clone().endOf("isoWeek");
            var lastWeekStart = moment()
                .subtract(1, "weeks")
                .startOf("isoWeek");
            var lastWeekEnd = moment().subtract(1, "weeks").endOf("isoWeek");
            var yesterday = new Date().setDate(new Date().getDate() - 1);
            var toWeekAgo = moment().subtract(14, "d");
            var thisMonthStart = moment().startOf("month");
            var thisMonthEnd = moment().endOf("month");
            var lastMonthStart = moment()
                .subtract(1, "months")
                .startOf("month");
            var lastMonthEnd = moment().subtract(1, "months").endOf("month");

            if (this.range == "custom") {
                this.req.start = moment(today).format("DD MMMM YYYY");
                this.req.end = moment(today).format("DD MMMM YYYY");
            } else if (this.range == "today") {
                this.req.start = moment(today).format("DD MMMM YYYY");
                this.req.end = moment(today).format("DD MMMM YYYY");
            } else if (this.range == "yesterday") {
                this.req.start = moment(yesterday).format("DD MMMM YYYY");
                this.req.end = moment(yesterday).format("DD MMMM YYYY");
            } else if (this.range == "this_week") {
                this.req.start = moment(thisWeekStart).format("DD MMMM YYYY");
                this.req.end = moment(thisWeekEnd).format("DD MMMM YYYY");
            } else if (this.range == "last_week") {
                this.req.start = moment(lastWeekStart).format("DD MMMM YYYY");
                this.req.end = moment(lastWeekEnd).format("DD MMMM YYYY");
            } else if (this.range == "to_weeks_ago") {
                this.req.start = moment(toWeekAgo).format("DD MMMM YYYY");
                this.req.end = moment(today).format("DD MMMM YYYY");
            } else if (this.range == "this_month") {
                this.req.start = moment(thisMonthStart).format("DD MMMM YYYY");
                this.req.end = moment(thisMonthEnd).format("DD MMMM YYYY");
            } else if (this.range == "last_month") {
                this.req.start = moment(lastMonthStart).format("DD MMMM YYYY");
                this.req.end = moment(lastMonthEnd).format("DD MMMM YYYY");
            } else {
                this.req.start = "";
                this.req.end = "";
                this.start_date = "";
                this.end_date = "";
            }
        },
        searchData() {
            if (this.distribType == "order") {
                this.getOrderData();
                this.currentPage = 0;
            } else if (this.distribType == "lead") {
                this.getLeadsData();
                this.currentPage = 0;
            } else {
                this.getRegisteredData();
                this.currentPage = 1;
            }
        },
        getExport() {},
        changePage() {
            if (this.distribType == "order") this.getOrderData();
            if (this.distribType == "lead") this.getLeadsData();
            if (this.distribType == "registered") this.getRegisteredData();
        },
        getSpecialistData() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist`,
                {
                    params: {
                        page: -1,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.specialistData = res.data.data.list_data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getLeadsData() {
            let payload = {
                search: this.keySearch,
                limit: this.limit,
                branch_id: this.branch?.id,
                user_id: this.specialist?.id,
                tag: this.tag?.tag,
                type: this.type_filter?.type,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
            };
            if (this.start_date) {
                payload.start_date = moment(this.start_date).format(
                    "DD-MM-YYYY"
                );
            }
            if (this.end_date) {
                payload.end_date = moment(this.end_date).format("DD-MM-YYYY");
            }
            this.is_my_list_ready = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/customer/leads`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.leadsData = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        getOrderData() {
            let payload = {
                search: this.keySearch,
                limit: this.limit,
                type: "ordered",
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            if (this.start_date) {
                payload.start_date = moment(this.start_date).format(
                    "DD-MM-YYYY"
                );
            }
            if (this.end_date) {
                payload.end_date = moment(this.end_date).format("DD-MM-YYYY");
            }

            this.is_my_list_ready = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/customer/orders`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.orderData = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        getRegisteredData() {
            let payload = {
                search: this.keySearch,
                limit: this.limit,
                type: "registered",
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            if (this.start_date) {
                payload.start_date = moment(this.start_date).format(
                    "DD-MM-YYYY"
                );
            }
            if (this.end_date) {
                payload.end_date = moment(this.end_date).format("DD-MM-YYYY");
            }

            this.is_my_list_ready = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/customer/orders`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.registeredData = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        storeDistribute() {
            this.valueCheckBox.forEach((val) => {
                this.distribute_value.push(val.id);
            });
            if (this.distribute_value.length > 0) {
                if (this.distribType == "lead") {
                    this.type = "lead";
                } else if (this.distribType == "registered") {
                    this.type = "order";
                } else {
                    this.type = "order";
                }

                let data = {
                    specialist_id: this.selectedOption,
                    id: this.distribute_value,
                    type: this.type,
                };

                this.isSaving = true;

                Api.post(
                    `${process.env.VUE_APP_SERVICE_URL}/crm/customer/distribute`,
                    data,
                    {
                        headers: {
                            Authorization:
                                "Bearer" + localStorage.getItem("token"),
                        },
                    }
                )
                    .then(() => {
                        this.isSaving = false;
                        this.modalSuccess = true;
                        this.distribModal = false;
                    })
                    .catch((err) => {
                        this.isSaving = false;
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    });
            }
        },
        itemNumber(index) {
            let number = index + this.startIndex + 1;
            return (number < 10 ? "" : "") + number;
        },
        addTagsLeads() {
            this.page_content_leads = "page_tags";
            var data = this.valueCheckBox.map(function (val) {
                return {
                    id: val.id,
                    type: "leads",
                };
            });
            this.tags_value = data;
        },
        addTagsOrdered() {
            this.page_content = "page_tags";
            var data = this.valueCheckBox.map(function (val) {
                return {
                    id: val.id,
                    type: "ordered",
                };
            });
            this.tags_value = data;
        },
        addTagsRegistered() {
            this.page_content_registered = "page_tags";
            var data = this.valueCheckBox.map(function (val) {
                return {
                    id: val.id,
                    type: "registered",
                };
            });
            this.tags_value = data;
        },
        saveTags() {
            this.is_save_tags = true;
            var data = {
                tags: this.tags,
                customers: this.tags_value,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/tags/create/batch`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_save_tags = false;
                    this.modalSuccessTags = true;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_save_tags = false;
                });
        },
        showModalDeleteLeads(id) {
            this.modalSuccessDeleteLeads = true;
            this.leads_id = id;
        },
        deleteLeads() {
            this.is_delete_leads = true;
            var data = {
                id: this.leads_id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/leads/delete`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.modalSuccessDeleteLeads = false;
                    this.is_delete_leads = false;
                    this.getLeadsData();
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_delete_leads = false;
                    this.modalSuccessDeleteLeads = false;
                });
        },
        uploadPhoto(event) {
            var input = event.target;
            this.req.attachment = input.files[0];
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.attachment = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
        saveNotes() {
            this.is_save_notes = true;
            var data = new FormData();

            if (this.distribType == "lead") {
                this.type = "lead";
            } else if (this.distribType == "registered") {
                this.type = "order";
            } else {
                this.type = "order";
            }

            data.append("customer_id", this.item_id);
            data.append("type", this.type);
            data.append("content", this.req.notes);
            data.append("attachment", this.req.attachment);
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/note/create`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_save_notes = false;
                    this.modalSuccessNotes = true;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_save_notes = false;
                });
        },
        getBranchs() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/branches/dropdown`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.branchs = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getTags() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/tags/all`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.tag_filters = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.roles = res.data.data.roles;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        checkRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        getSpecialists() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist/dropdown`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    computed: {
        attachmentPictureUrl() {
            return this.attachment.length > 0 ? this.attachment : noImage;
        },
        startIndex() {
            return (this.currentPage - 1) * this.limit;
        },
        filteredOptions() {
            if (this.searchTerm) {
                const regex = new RegExp(this.searchTerm, "i");
                return this.specialistData.filter((option) =>
                    option.name.match(regex)
                );
            }
            return this.specialistData;
        },
        selectAllLeads: {
            get() {
                return this.valueCheckBox.length === this.leadsData.length;
            },
            set(value) {
                this.valueCheckBox = [];

                if (value) {
                    this.leadsData.forEach((select) => {
                        this.valueCheckBox.push(select);
                    });
                }
            },
        },
        selectAllOrdered: {
            get() {
                return this.valueCheckBox.length === this.orderData.length;
            },
            set(value) {
                this.valueCheckBox = [];

                if (value) {
                    this.orderData.forEach((select) => {
                        this.valueCheckBox.push(select);
                    });
                }
            },
        },
        selectAllRegistered: {
            get() {
                return this.valueCheckBox.length === this.registeredData.length;
            },
            set(value) {
                this.valueCheckBox = [];

                if (value) {
                    this.registeredData.forEach((select) => {
                        this.valueCheckBox.push(select);
                    });
                }
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.scroll {
    height: 320px;
    overflow-y: scroll;
}
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.active {
    background-color: #e60013 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
}
.bt-size {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 159px;
    height: 44px;
    border-radius: 8px;
}
.search-icon-left {
    top: 11px;
    left: 10px;
}
.excel {
    width: 20px !important;
    height: 19.05px !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.button {
    display: flex;
    border-radius: 12px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    &-save {
        background: #48a2d4;
        border: 1px solid #48a2d4;
        color: white;
    }
    &-save:is(:hover, :focus) {
        background: #176f9e;
    }
    &-delete {
        background: #e60013;
        border: 1px solid #e60013;
        color: white;
    }
    &-delete:is(:hover, :focus) {
        background: #e94b58;
    }
    &-cancel {
        background: #ffffff;
        border: 1px solid #e94b58;
        color: #e94b58;
    }
    &-cancel:is(:hover, :focus) {
        background: #e94b58;
        border: 1px solid #ffffff;
        color: #ffffff;
    }
}
.pill {
    display: inline-block;
    width: 110px;
    height: 24px;
    border-radius: 40px;
    text-align: center;
    color: white;
    &.red {
        background-color: #e60013;
        border: 1px solid #e60013;
        outline: 1px solid #e60013;
    }

    &.green {
        background-color: #3d8824;
        border: 1px solid #3d8824;
        outline: 1px solid #3d8824;
    }
}
.text-link {
    text-decoration: underline;
    color: #24516a;
}
.h-44 {
    height: 44px !important;
}
.radio-option label {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    cursor: pointer;
}
.radio-option label .text {
    font-size: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    border-bottom: 1px solid #f3f4f6;
    padding-bottom: 15px;
}
.radio-option label input {
    display: none;
}
.radio-option label input + .text:after {
    font-family: FontAwesome;
    content: "\f1db";
    line-height: 1;
    display: inline-block;
    color: #f3f4f6;
    font-size: 20px;
}
.radio-option label input:checked + .text:after {
    font-family: FontAwesome;
    content: "\f111";
    color: #3d8824;
}
.mt-18 {
    margin-top: 18px;
}
.mb-24 {
    margin-bottom: 24px;
}
.mb-35 {
    margin-bottom: 35px;
}
.rounded-8 {
    border-radius: 8px !important;
}
.py-10 {
    padding: 10px 0px;
}
.w-search-leads {
    width: 320px;
}
.dropdown-toggle::after {
    display: none;
}
.dropdown-menu.show {
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border: 1px solid #f7f7f7;
    border-radius: 8px;
}
.w-mail-title {
    width: 80px;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    border: dashed;
    border-radius: 10px;
    width: 13vw;
    height: 150px;
    padding: 55px;
    position: relative;
}
.photo {
    border-radius: 10px;
    cursor: pointer;
    width: 13vw;
    height: 150px;
    object-fit: cover;
}
.btn-lg {
    width: 150px;
}
input[type="file"] {
    display: none;
}
.upload__btn p i {
    font-size: 30px;
}
.bg-tag {
    background-color: #d9d9d9;
}
.bg-type-leads {
    background-color: #daecf6;
}
.fc-type-leads {
    color: #3c87b1;
}
.bg-type-prospect {
    background-color: #d8e7d3;
}
.fc-type-prospect {
    color: #33711e;
}
.bg-status-lost {
    background-color: #f7aab0;
}
.fc-status-lost {
    background-color: #730009;
}
</style>
