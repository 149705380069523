<template>
  <div class="padding-container">
    <div class="fw-bold">
      <a href="/trawlpack/invoice/send" class="text-gray-dashboard size-16">
        <i class="fa fa-angle-left me-2"></i>
        Kembali
      </a>
    </div>
    <h3 class="fw-bold mt-3">Buat Invoice</h3>
    <div class="box p-0 mt-4">
      <div class="box no-shadow bc-gray p-4 no-radius-bottom">
        <h5 class="mb-0 fw-bold">
          <i class="fa fa-send me-2"></i>
          Pengirim
        </h5>
      </div>
      <div class="row p-4">
        <div class="col-md-6">
          <label class="fw-bold mb-0">Nama Pengirim</label>
          <input
            type="text"
            class="form-control mt-2"
            placeholder="Masukkan Nama Pengirim"
            v-model="detail.sender_name"
            disabled
          />
        </div>
        <div class="col-md-6">
          <label class="fw-bold mb-0">Nomor Pengirim</label>
          <input
            type="number"
            class="form-control mt-2"
            placeholder="Masukkan Nomor Pengirim"
            v-model="detail.sender_phone"
            disabled
          />
        </div>
        <div class="col-md-12 mt-3">
          <label class="fw-bold mb-0">Alamat Pengirim</label>
          <textarea
            cols="30"
            rows="5"
            class="form-control mt-2"
            placeholder="Masukkan Alamat Pengirim"
            v-model="detail.sender_address"
            disabled
          ></textarea>
        </div>
        <div class="col-md-12 mt-3">
          <label class="fw-bold mb-0">Detail Alamat Pengirim</label>
          <input
            type="text"
            class="form-control mt-2"
            placeholder="Masukkan Detail Alamat Pengirim"
            v-model="detail.sender_detail_address"
            disabled
          />
        </div>
      </div>
    </div>
    <div class="box p-0 mt-5">
      <div class="box no-shadow bc-gray p-4 no-radius-bottom">
        <h5 class="mb-0 fw-bold">
          <i class="fa fa-user me-2"></i>
          Penerima
        </h5>
      </div>
      <div class="row p-4">
        <div class="col-md-4">
          <label class="fw-bold mb-0">Nama Penerima</label>
          <input
            type="text"
            class="form-control mt-2"
            placeholder="Masukkan Nama Penerima"
            v-model="detail.receiver_name"
            disabled
          />
        </div>
        <div class="col-md-4">
          <label class="fw-bold mb-0">Nomor Penerima</label>
          <input
            type="number"
            class="form-control mt-2"
            placeholder="Masukkan Nomor Penerima"
            v-model="detail.receiver_phone"
            disabled
          />
        </div>
        <div class="col-md-4">
          <label class="fw-bold mb-0">Kota/Kabupaten/Kecamatan</label>
          <input
            type="text"
            class="form-control mt-2"
            placeholder="Masukkan Kota/Kabupaten/Kecamatan"
            :value="
              detail.destination_address.regency +
              ', ' +
              detail.destination_address.district +
              ', ' +
              detail.destination_address.province
            "
            disabled
          />
        </div>
        <div class="col-md-12 mt-3">
          <label class="fw-bold mb-0">Alamat Penerima</label>
          <textarea
            cols="30"
            rows="5"
            class="form-control mt-2"
            placeholder="Masukkan Alamat Penerima"
            v-model="detail.receiver_address"
            disabled
          ></textarea>
        </div>
        <div class="col-md-12 mt-3">
          <label class="fw-bold mb-0">Detail Alamat Penerima</label>
          <input
            type="text"
            class="form-control mt-2"
            placeholder="Masukkan Detail Alamat Penerima"
            v-model="detail.receiver_detail_address"
            disabled
          />
        </div>
      </div>
    </div>
    <div class="box no-shadow yellow mt-5">
      <div class="d-flex align-items-center">
        <div>
          <i class="fa fa-cart-plus size-26"></i>
        </div>
        <div class="ms-4">
          <h5 class="fw-bold mb-0">Darat</h5>
          <div class="mt-1 size-13 capitalize">
            {{ detail.estimation_notes }}
          </div>
        </div>
        <div class="ms-auto" v-if="detail.is_bike == false">
          <h5 class="mb-0 fw-bold">
            {{ currencyFormat(detail.tier_price) }}/Kg
          </h5>
        </div>
      </div>
    </div>
    <div class="box p-0 mt-5">
      <div class="box no-shadow bc-gray p-4 no-radius-bottom">
        <div class="d-flex align-items-center">
          <h5 class="mb-0 fw-bold">
            <i class="fa fa-briefcase me-2"></i>
            Daftar Barang
          </h5>
          <div class="ms-auto">
            <button class="btn btn-green btn-md px-4" disabled>
              Tambah Barang
            </button>
          </div>
        </div>
      </div>
      <div class="p-4">
        <div class="modern-table-boxed">
          <template v-if="detail.items.length > 0">
            <div class="d-table-row header">
              <div class="d-table-cell">No</div>
              <div class="d-table-cell">Kategori Barang</div>
              <div class="d-table-cell">
                <template v-if="detail.is_bike == false">
                  Deskripsi Barang
                </template>
                <template v-else> CC Motor </template>
              </div>
              <div class="d-table-cell">
                <template v-if="detail.is_bike == false"> Berat </template>
                <template v-else> Merk Motor </template>
              </div>
              <div class="d-table-cell">
                <template v-if="detail.is_bike == false"> Dimensi </template>
                <template v-else> Tipe Motor </template>
              </div>
              <div class="d-table-cell">
                <template v-if="detail.is_bike == false"> Volume </template>
                <template v-else> Tahun </template>
              </div>
              <div class="d-table-cell" v-if="detail.is_bike == false">Qty</div>
              <div class="d-table-cell">Perlindungan</div>
              <div class="d-table-cell">Asuransi</div>
            </div>
            <template v-for="(item, index) in detail.items">
              <div class="d-table-row" :key="index">
                <div class="d-table-cell">{{ index + 1 }}.</div>
                <div class="d-table-cell capitalize">
                  <template v-if="item.category_name">
                    {{ item.category_name }}
                  </template>
                  <template v-else> - </template>
                </div>
                <div class="d-table-cell">
                  <template v-if="detail.is_bike == false">
                    <template v-if="item.name">
                      {{ item.name }}
                    </template>
                    <template v-else> - </template>
                  </template>
                  <template v-else> {{ detail?.moto_bike?.cc }} CC </template>
                </div>
                <div class="d-table-cell">
                  <template v-if="detail.is_bike == false">
                    {{ item.weight }}kg
                  </template>
                  <template v-else>
                    {{ detail?.moto_bike?.merk }}
                  </template>
                </div>
                <div class="d-table-cell capitalize">
                  <template v-if="detail.is_bike == false">
                    {{ item.length }}x{{ item.width }}x{{ item.height }}
                  </template>
                  <template v-else>
                    {{ detail?.moto_bike?.type }}
                  </template>
                </div>
                <div class="d-table-cell">
                  <template v-if="detail.is_bike == false">
                    {{ item.weight_borne_volume }}kg
                  </template>
                  <template v-else>
                    {{ detail?.moto_bike?.years }}
                  </template>
                </div>
                <div class="d-table-cell" v-if="detail.is_bike == false">
                  {{ item.qty }}
                </div>
                <div class="d-table-cell">
                  <template v-if="detail.is_bike == true">
                    <small class="tag green capitalize"> Bubble Wrap </small>
                    <small class="tag green capitalize"> Karung Besar </small>
                  </template>
                  <template v-else>
                    <template v-if="item.handling.length > 0">
                      <template v-for="(handling, index) in item.handling">
                        <small class="tag green capitalize" :key="index">
                          <template v-if="handling == 'bubble wrap'">
                            Bubble Wrap
                          </template>
                          <template v-if="handling == 'cardboard'">
                            Kardus
                          </template>
                          <template v-if="handling == 'plastic'">
                            Plastik
                          </template>
                          <template v-if="handling == 'wood'"> Kayu </template>
                          <template v-if="handling == 'sandbag sm'">
                            Karung Kecil
                          </template>
                          <template v-if="handling == 'sandbag md'">
                            Karung Sedang
                          </template>
                          <template v-if="handling == 'sandbag l'">
                            Karung Besar
                          </template>
                        </small>
                      </template>
                    </template>
                    <template v-else> - </template>
                  </template>
                </div>
                <div class="d-table-cell">
                  <template v-if="(item.is_insured = true)">
                    {{ currencyFormat(item.price) }}
                  </template>
                  <template v-else> - </template>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="box no-shadow mt-4">
              <div class="text-center">
                <div>
                  <img src="../../assets/no-data.png" width="100" alt="" />
                </div>
                <div class="mt-3 size-16 fw-bold">Data Barang Kosong</div>
              </div>
            </div>
          </template>
        </div>
        <div class="row" v-if="detail.items.length > 0">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <div class="box mt-4">
              <div class="d-flex align-items-center">
                <h6 class="mb-0 fw-bold">Biaya Pengiriman</h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold">
                    {{ currencyFormat(detail.calculate_data.service) }}
                  </h6>
                </div>
              </div>
              <div class="d-flex align-items-center mt-4">
                <h6 class="mb-0 fw-bold">Biaya Asuransi</h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold">
                    {{
                      currencyFormat(
                        detail.calculate_data.insurance_price_total
                      )
                    }}
                  </h6>
                </div>
              </div>
              <div class="d-flex align-items-center mt-4">
                <h6 class="mb-0 fw-bold">Biaya Perlindungan</h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold">
                    {{ currencyFormat(detail.calculate_data.handling) }}
                  </h6>
                </div>
              </div>
              <div
                class="d-flex align-items-center mt-4"
                v-if="detail.is_bike == false"
              >
                <h6 class="mb-0 fw-bold">Biaya Tambahan</h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold">
                    {{ currencyFormat(detail.calculate_data.additional_price) }}
                  </h6>
                </div>
              </div>
              <div class="box no-shadow green mt-3">
                <div class="d-flex align-items-center">
                  <h5 class="mb-0 fw-bold">Total Pembayaran</h5>
                  <div class="ms-auto">
                    <h5 class="mb-0 fw-bold">
                      {{
                        currencyFormat(detail.calculate_data.total_amount_item)
                      }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box p-0 mt-5">
      <div class="box no-shadow bc-gray p-4 no-radius-bottom">
        <h5 class="mb-0 fw-bold">
          <i class="fa fa-camera me-2"></i>
          Foto Barang Keseluruhan
        </h5>
      </div>
      <div class="row p-4">
        <template v-if="detail.images.length > 0">
          <template v-for="(image, index) in detail.images">
            <div class="col-md-3" :key="index">
              <div
                class="box no-shadow invoice-item-image"
                v-bind:style="{
                  'background-image': 'url(' + image.uri + ')',
                }"
              ></div>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="box no-shadow mt-4">
            <div class="text-center">
              <div>
                <img src="../../assets/no-data.png" width="100" alt="" />
              </div>
              <div class="mt-3 size-16 fw-bold">Foto Barang Kosong</div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="box p-0 mt-5">
      <div class="box no-shadow bc-gray p-4 no-radius-bottom">
        <h5 class="mb-0 fw-bold">
          <i class="fa fa-tag me-2"></i>
          Potongan Biaya Pengiriman Atau Penjemputan
        </h5>
      </div>
      <div class="row p-4" v-if="! detail.is_promo_discount">
        <div class="col-md-6 mt-2">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="discount"
              id="discount1"
              v-model="discount"
              value="service"
              @click="resetDsicount()"
            />
            <label class="mb-0 fw-bold" for="discount1">
              Berikan Diskon Pengiriman
            </label>
          </div>
        </div>
        <div class="col-md-6 mt-2">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="pickup"
              id="pickup1"
              v-model="discount"
              value="pickup"
              @click="resetDsicount()"
            />
            <label class="mb-0 fw-bold" for="pickup1">
              Berikan Diskon Penjemputan
            </label>
          </div>
        </div>
        <div class="col-md-12 mt-3" v-if="discount == 'service'">
          <money v-model="discount_price" v-bind="money" class="form-control">
          </money>
          <div class="mt-2 text-gray solid">
            Maksimal pemberian diskon 30% dari biaya Pengiriman
          </div>
        </div>
        <div class="col-md-12 mt-3" v-if="discount == 'pickup'">
          <money v-model="discount_price" v-bind="money" class="form-control">
          </money>
          <div class="mt-2 text-gray solid">
            Maksimal pemberian diskon 30% dari biaya Penjemputan
          </div>
        </div>
      </div>
      <div class="row p-4" v-else>
        <div class="d-flex align-items-center">
          Sudah terdapat Promo, tidak dapat menambah Potongan Biaya
        </div>
      </div>
      
    </div>
    <div class="box p-0 mt-5">
      <div class="box no-shadow bc-gray p-4 no-radius-bottom">
        <h5 class="mb-0 fw-bold">
          <i class="fa fa-calculator me-2"></i>
          Pilih Perhitungan
        </h5>
      </div>
      <div class="row p-4">
        <div class="col-md-6 mt-2">
          <label class="fw-bold">Perhitungan Menggunakan Kilogram (Kg)</label>
          <label class="plan mt-3" for="kg">
            <input
              type="radio"
              id="kg"
              name="calculate"
              v-model="calculate"
              value="kg"
            />
            <div class="plan-content calculate">
              <h6 class="mb-0 fw-bold">Perhitungan Kilogram (Kg)</h6>
            </div>
          </label>
        </div>

        <div class="col-md-6 mt-2" v-if="detail.is_bike == false">
          <label class="fw-bold">Perhitungan Menggunakan Kubikasi</label>
          <label class="plan mt-3" for="cubic">
            <input
              checked
              type="radio"
              name="calculate"
              id="cubic"
              v-model="calculate"
              value="cubic"
            />
            <div class="plan-content calculate">
              <h6 class="mb-0 fw-bold">Perhitungan Kubikasi</h6>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6"></div>
      <div class="col-md-6">
        <div class="box p-0">
          <div class="box no-shadow bc-gray p-4 no-radius-bottom">
            <h5 class="mb-0 fw-bold">Estimasi Pembayaran</h5>
          </div>
          <div class="p-4">
            <template v-if="calculate == 'kg'">
              <div class="d-flex align-items-center">
                <h6 class="mb-0 fw-bold">Total Biaya Penjemputan</h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold">
                    {{ currencyFormat(detail.estimation_kg_prices.pickup_fee) }}
                  </h6>
                </div>
              </div>
              <div class="d-flex align-items-center mt-4">
                <h6 class="mb-0 fw-bold">Total Biaya Pengiriman</h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold">
                    {{
                      currencyFormat(detail.estimation_kg_prices.service_fee)
                    }}
                  </h6>
                </div>
              </div>
              <div class="d-flex align-items-center mt-4">
                <h6 class="mb-0 fw-bold">
                  <template v-if="detail.is_bike == false">
                    Total Biaya Perlindungan
                  </template>
                  <template v-else> Total Biaya Perlindungan Wajib </template>
                </h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold">
                    {{
                      currencyFormat(detail.estimation_kg_prices.handling_fee)
                    }}
                  </h6>
                </div>
              </div>
              <div class="d-flex align-items-center mt-4">
                <h6 class="mb-0 fw-bold">Total Biaya Asuransi</h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold">
                    {{
                      currencyFormat(detail.estimation_kg_prices.insurance_fee)
                    }}
                  </h6>
                </div>
              </div>
              <div class="d-flex align-items-center mt-4">
                <h6 class="mb-0 fw-bold">Biaya Jasa Aplikasi</h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold">
                    {{
                      currencyFormat(detail.estimation_kg_prices.platform_fee)
                    }}
                  </h6>
                </div>
              </div>
              <div
                class="d-flex align-items-center mt-4"
                v-if="detail.is_bike == false"
              >
                <h6 class="mb-0 fw-bold">Total Biaya Tambahan</h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold">
                    {{
                      currencyFormat(detail.estimation_kg_prices.additional_fee)
                    }}
                  </h6>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="d-flex align-items-center">
                <h6 class="mb-0 fw-bold">Total Biaya Penjemputan</h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold">
                    {{
                      currencyFormat(detail.estimation_cubic_prices.pickup_fee)
                    }}
                  </h6>
                </div>
              </div>
              <div class="d-flex align-items-center mt-4">
                <h6 class="mb-0 fw-bold">Total Biaya Pengiriman</h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold">
                    {{
                      currencyFormat(detail.estimation_cubic_prices.service_fee)
                    }}
                  </h6>
                </div>
              </div>
              <div class="d-flex align-items-center mt-4">
                <h6 class="mb-0 fw-bold">
                  <template v-if="detail.is_bike == false">
                    Total Biaya Perlindungan
                  </template>
                  <template v-else> Total Biaya Perlindungan Wajib </template>
                </h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold">
                    {{
                      currencyFormat(
                        detail.estimation_cubic_prices.handling_fee
                      )
                    }}
                  </h6>
                </div>
              </div>
              <div class="d-flex align-items-center mt-4">
                <h6 class="mb-0 fw-bold">Total Biaya Asuransi</h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold">
                    {{
                      currencyFormat(
                        detail.estimation_cubic_prices.insurance_fee
                      )
                    }}
                  </h6>
                </div>
              </div>
              <div class="d-flex align-items-center mt-4">
                <h6 class="mb-0 fw-bold">Biaya Jasa Aplikasi</h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold">
                    {{
                      currencyFormat(
                        detail.estimation_cubic_prices.platform_fee
                      )
                    }}
                  </h6>
                </div>
              </div>
              <div
                class="d-flex align-items-center mt-4"
                v-if="detail.is_bike == false"
              >
                <h6 class="mb-0 fw-bold">Total Biaya Tambahan</h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold">
                    {{
                      currencyFormat(
                        detail.estimation_cubic_prices.additional_fee
                      )
                    }}
                  </h6>
                </div>
              </div>
            </template>
            <template v-if="discount_price != 0">
              <div
                class="d-flex align-items-center mt-4"
                v-if="discount == 'service'"
              >
                <h6 class="mb-0 fw-bold">Diskon Biaya Pengiriman</h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold text-danger">
                    -{{ currencyFormat(discount_price) }}
                  </h6>
                </div>
              </div>
              <div class="d-flex align-items-center mt-4" v-else>
                <h6 class="mb-0 fw-bold">Diskon Biaya Penjemputan</h6>
                <div class="ms-auto">
                  <h6 class="mb-0 fw-bold text-danger">
                    -{{ currencyFormat(discount_price) }}
                  </h6>
                </div>
              </div>
            </template>
            <div class="mt-4 mb-4">
              <hr />
            </div>
            <template v-if="calculate == 'kg'">
              <div class="d-flex align-items-center">
                <h5 class="mb-0 fw-bold text-green">Total Pembayaran</h5>
                <div class="ms-auto">
                  <h5 class="mb-0 fw-bold text-green">
                    {{
                      currencyFormat(
                        detail.estimation_kg_prices.total_amount -
                          discount_price
                      )
                    }}
                  </h5>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="d-flex align-items-center">
                <h5 class="mb-0 fw-bold text-green">Total Pembayaran</h5>
                <div class="ms-auto">
                  <h5 class="mb-0 fw-bold text-green">
                    {{
                      currencyFormat(
                        detail.estimation_cubic_prices.total_amount
                      )
                    }}
                  </h5>
                </div>
              </div>
            </template>
            <div class="mt-4">
              <button
                class="btn btn-green btn-lg w-100"
                type="button"
                disabled
                v-if="is_send"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Kirim ke Pelanggan
              </button>
              <button
                class="btn btn-green btn-lg w-100"
                @click="sendInvoice()"
                v-else
              >
                Kirim ke Pelanggan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL -->
    <div class="modal-vue">
      <div class="overlay" v-if="showModalSuccess"></div>
      <div class="modal-body-chat sm" v-if="showModalSuccess">
        <div class="text-center">
          <div class="size-18 fw-ekstra-bold">
            <img src="../../assets/accept.png" width="65" alt="" />
          </div>
        </div>
        <div class="mt-4">
          <div class="text-center">
            <div class="size-18 fw-ekstra-bold">
              Data Berhasil Dikirim ke Pelanggan!
            </div>
            <div class="mt-2">Selamat! Anda berhasil mengirim data.</div>
          </div>
          <div class="mt-3 row">
            <div class="col-md-12 mt-4">
              <a
                href="javascript:void(0)"
                class="btn btn-green btn-lg w-100"
                @click="closeModalSuccess()"
              >
                Ok
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../api/Api";
import { Money } from "v-money";

export default {
  components: {
    Money,
  },
  props: "",
  name: "TrawlpackSendInvoiceDetail",
  data() {
    return {
      discount_price: 0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "Rp",
        suffix: "",
        precision: 0,
        masked: false,
      },
      discount: "service",
      calculate: "kg",
      detail: {
        package_child: [],
        calculate_data: {},
        images: [],
        destination_address: {},
        items: [],
        estimation_kg_prices: {},
        estimation_cubic_prices: {},
        moto_bike: {},
      },
      is_send: false,
      showModalSuccess: false,
    };
  },
  created() {
    this.getDetailInvoice();
  },
  methods: {
    currencyFormat(num) {
      return (
        "Rp" +
        parseInt(num)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
      );
    },
    getDetailInvoice() {
      Api.get(
        `${process.env.VUE_APP_BASE_URL}/trawlpack/dashboard/order/detail/${this.$route.params.id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          var data = res.data.data;
          this.detail = data;

          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendInvoice() {
      var data = {
        discount: this.discount_price,
        type: this.discount,
        caculate_type: this.calculate,
      };
      this.is_send = true;
      Api.patch(
        `${process.env.VUE_APP_BASE_URL}/partner/cashier/invoice/send/${this.$route.params.id}`,
        data,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.is_send = false;
          this.showModalSuccess = true;
          console.log(res);
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: err.response.data.message,
          });
          this.is_send = false;
        });
    },
    closeModalSuccess() {
      this.showModalSuccess = false;
      window.location.href = "/trawlpack/invoice/send";
    },
    resetDsicount() {
      this.discount_price = 0;
    },
  },
};
</script>

<style scoped>
.text-main-color {
  color: #24516a !important;
}
.text-gray-dashboard {
  color: #61616a;
}
.no-radius-bottom {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.box.yellow {
  background: #feead4;
}
.modern-table-boxed .d-table-row {
  box-shadow: 0px 5px 20px 5px rgb(0 0 0 / 3%);
}
.box.invoice-item-image {
  width: 100%;
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px;
}
.text-gray.solid {
  color: #61616a !important;
}
.plan input[type="radio"]:checked + .plan-content.calculate {
  background: unset;
  border: 2px solid #f4f6f7;
}
.plan .plan-content.calculate {
  padding: 12px;
}
.plan input[type="radio"]:checked + .plan-content.calculate:after {
  right: 0;
  left: 15px;
  top: 16px;
  width: 9px;
  height: 9px;
}
.plan input[type="radio"]:checked + .plan-content.calculate h6 {
  margin-left: 25px;
}
.plan .plan-content.calculate:hover {
  box-shadow: unset;
}
.form-check-input:checked {
  background: #28a745;
  border-color: #28a745;
}
.form-check-input:checked[type="radio"] {
  background-image: unset;
}
.form-check-input:focus {
  box-shadow: unset;
}
.form-check-input:active {
  filter: brightness(100%);
}
</style>
