import { render, staticRenderFns } from "./WithdrawDetail.vue?vue&type=template&id=ba46c0b8&scoped=true"
import script from "./WithdrawDetail.vue?vue&type=script&lang=js"
export * from "./WithdrawDetail.vue?vue&type=script&lang=js"
import style0 from "./WithdrawDetail.vue?vue&type=style&index=0&id=ba46c0b8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba46c0b8",
  null
  
)

export default component.exports