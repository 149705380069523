var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"padding-container"},[_vm._m(0),(!_vm.checkRoleExists('ho'))?_c('div',{staticClass:"accordion mt-4",attrs:{"id":"bassicInfo"}},[_c('div',{staticClass:"accordion-item"},[_c('h2',{staticClass:"accordion-header",attrs:{"id":"bassicInfo1"}},[_c('button',{staticClass:"accordion-button size-16 fw-bold",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#collapseOne","aria-expanded":"true","aria-controls":"collapseOne"}},[(_vm.checkRoleExists('crm'))?[_vm._v(" Detail Informasi User CRM ")]:[_vm._v(" Detail Informasi Basic ")]],2)]),_c('div',{staticClass:"accordion-collapse collapse show",attrs:{"id":"collapseOne","aria-labelledby":"bassicInfo1","data-bs-parent":"#bassicInfo"}},[_c('div',{staticClass:"accordion-body"},[_c('div',{staticClass:"box no-shadow border-gray mb-2"},[_c('h5',{staticClass:"mb-0 fw-bold"},[_vm._v("Informasi Basic")]),_c('center',[(_vm.detail.avatar)?_c('div',{staticClass:"avatar-image mt-3",style:({
                                    'background-image':
                                        'url(' + _vm.detail.avatar + ')',
                                })}):_c('div',{staticClass:"avatar-image mt-3",style:({
                                    backgroundImage: `url(${require('../assets/user-dummy.png')})`,
                                })})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Nama")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.name ?? "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Username")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.username ?? "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Nomor Telepon")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.phone ?? "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Email")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.email ?? "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Tanggal Bergabung")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.created_at ?? "-")+" ")])]),(_vm.checkRoleExists('crm'))?[_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Gender")]),_c('div',{staticClass:"form-box bg-white"},[(
                                                _vm.detail.meta.crm.gender ==
                                                'male'
                                            )?[_vm._v(" Laki-Laki ")]:[_vm._v("Perempuan")]],2)]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Cabang")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.meta.crm.address)+" ")])])]:_vm._e(),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Role")]),_c('div',{staticClass:"form-box bg-white"},[(_vm.detail.roles.length > 0)?_vm._l((_vm.detail.roles),function(role,index){return _c('div',{key:index,staticClass:"tag radius px-2 size-12 mb-0",class:{
                                                'green-cro':
                                                    role.group == 'crm',
                                                'red-cro':
                                                    role.group == 'pack',
                                                'yellow-cro':
                                                    role.group ==
                                                        'truck_c' ||
                                                    role.group == 'truck_i',
                                                'blue-cro':
                                                    role.group == 'carrier',
                                                'purple-cro':
                                                    role.group == 'ho',
                                            }},[_vm._v(" "+_vm._s(role.label)+" ")])}):[_vm._v(" - ")]],2)])],2)],1)])])])]):_vm._e(),(_vm.checkRoleExists('ho'))?_c('div',{staticClass:"accordion mt-4",attrs:{"id":"HO"}},[_c('div',{staticClass:"accordion-item"},[_vm._m(1),_c('div',{staticClass:"accordion-collapse collapse show",attrs:{"id":"collapseOne","aria-labelledby":"HO1","data-bs-parent":"#HO"}},[_c('div',{staticClass:"accordion-body"},[_c('div',{staticClass:"box no-shadow border-gray mb-2"},[_c('h5',{staticClass:"mb-0 fw-bold"},[_vm._v("Informasi Basic")]),_c('center',[(_vm.detail.avatar)?_c('div',{staticClass:"avatar-image mt-3",style:({
                                    'background-image':
                                        'url(' + _vm.detail.avatar + ')',
                                })}):_c('div',{staticClass:"avatar-image mt-3",style:({
                                    backgroundImage: `url(${require('../assets/user-dummy.png')})`,
                                })}),(_vm.detail.roles.length > 0)?_vm._l((_vm.detail.roles),function(role,index){return _c('div',{key:index,staticClass:"tag radius px-2 size-12 mb-0 mt-2",class:{
                                        'green-cro': role.group == 'crm',
                                        'red-cro': role.group == 'pack',
                                        'yellow-cro':
                                            role.group == 'truck_c' ||
                                            role.group == 'truck_i',
                                        'blue-cro': role.group == 'carrier',
                                        'purple-cro': role.group == 'ho',
                                    }},[_vm._v(" "+_vm._s(role.label)+" ")])}):_vm._e()],2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Nama")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.name ?? "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Username")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.username ?? "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Nomor Telepon")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.phone ?? "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Email")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.email ?? "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Tanggal Bergabung")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.created_at ?? "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Role")]),_c('div',{staticClass:"form-box bg-white"},[(_vm.detail.roles.length > 0)?_vm._l((_vm.detail.roles),function(role,index){return _c('div',{key:index,staticClass:"tag radius px-2 size-12 mb-0",class:{
                                                'green-cro':
                                                    role.group == 'crm',
                                                'red-cro':
                                                    role.group == 'pack',
                                                'yellow-cro':
                                                    role.group ==
                                                        'truck_c' ||
                                                    role.group == 'truck_i',
                                                'blue-cro':
                                                    role.group == 'carrier',
                                                'purple-cro':
                                                    role.group == 'ho',
                                            }},[_vm._v(" "+_vm._s(role.label)+" ")])}):[_vm._v(" - ")]],2)])])],1)])])])]):_vm._e(),_c('div',{staticClass:"accordion mt-4",attrs:{"id":"productCollapseGeneral"}},[(_vm.detail.meta.pack && _vm.checkRoleExists('pack'))?_c('div',{staticClass:"accordion-item"},[_vm._m(2),_c('div',{staticClass:"accordion-collapse collapse show",attrs:{"id":"collapseTropack","aria-labelledby":"productTropack","data-bs-parent":"#productCollapseGeneral"}},[_c('div',{staticClass:"accordion-body"},[_vm._l((_vm.detail.roles),function(role_card,index){return [(
                                role_card.group != 'ho' &&
                                role_card.group != 'crm' &&
                                role_card.group != 'carrier' &&
                                role_card.group != 'truck_c' &&
                                role_card.group != 'truck_i' &&
                                role_card.role != 'trawlpack-partner-owner'
                            )?_c('div',{key:index,staticClass:"box no-shadow border-gray mb-2"},[_c('h5',{staticClass:"mb-0 fw-bold"},[_vm._v(" Informasi Sub: "+_vm._s(role_card.label)+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Kode Mitra")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.meta.pack.partners .code ?? "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Kecamatan/Kabupaten/Kota")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.meta.pack.partners .address ?? "-")+" ")])]),(
                                        role_card.role ==
                                            'trawlpack-partner-driver' ||
                                        role_card.role ==
                                            'trawlpack-partner-owner-transporter' ||
                                        role_card.role ==
                                            'trawlpack-partner-owner-business'
                                    )?_c('div',{staticClass:"col-md-12 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Jenis Armada")]),(
                                            _vm.detail.meta.pack.fleets.length >
                                            0
                                        )?_c('div',{staticClass:"row"},_vm._l((_vm.detail
                                                .meta.pack.fleets),function(fleet,index){return _c('div',{key:index,staticClass:"col-md-6 mb-2"},[_c('div',{staticClass:"form-box bg-white"},[_c('div',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(fleet)+" ")])])])}),0):_c('div',{staticClass:"row"},[_vm._m(3,true)])]):_vm._e()])]):_vm._e()]})],2)])]):_vm._e(),(_vm.detail.meta.truck_c && _vm.checkRoleExists('truck_c'))?_c('div',{staticClass:"accordion-item"},[_c('h2',{staticClass:"accordion-header",attrs:{"id":"productTruckC"}},[_c('button',{staticClass:"accordion-button",class:{
                        collapsed: _vm.detail.meta.pack != null,
                    },attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#collapseTruckC","aria-expanded":"true","aria-controls":"collapseTruckC"}},[_vm._m(4)])]),_c('div',{staticClass:"accordion-collapse collapse",class:{
                    show: _vm.detail.meta.pack == null,
                },attrs:{"id":"collapseTruckC","aria-labelledby":"productTruckC","data-bs-parent":"#productCollapseGeneral"}},[_c('div',{staticClass:"accordion-body"},[_vm._l((_vm.detail.roles),function(role_card,index){return [(role_card.group == 'truck_c')?_c('div',{key:index,staticClass:"box no-shadow border-gray mb-2"},[_c('h5',{staticClass:"mb-0 fw-bold"},[_vm._v("Informasi Sub")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Kode Mitra")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.meta.truck_c.partners .code ?? "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Nama Mitra")]),_c('div',{staticClass:"form-box bg-white capitalize"},[_vm._v(" "+_vm._s(_vm.detail.meta.truck_c.partners .name ?? "-")+" ")])]),_c('div',{staticClass:"col-md-12 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Alamat Domisili")]),_c('div',{staticClass:"form-box bg-white h-textarea"},[_vm._v(" "+_vm._s(_vm.detail.meta.truck_c.partners .domicile_address ? _vm.detail.meta.truck_c .partners .domicile_address : "-")+" ")])]),(
                                        role_card.role ==
                                            'trawltruck-partner-owner' ||
                                        role_card.role ==
                                            'trawltruck-driver-partner'
                                    )?_c('div',{staticClass:"col-md-12 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Jenis Armada")]),(
                                            _vm.detail.meta.truck_c.fleets
                                                .length > 0
                                        )?_c('div',{staticClass:"row"},_vm._l((_vm.detail
                                                .meta.truck_c.fleets),function(fleet,index){return _c('div',{key:index,staticClass:"col-md-6 mb-2"},[_c('div',{staticClass:"form-box bg-white"},[_c('div',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(fleet)+" ")])])])}),0):_c('div',{staticClass:"row"},[_vm._m(5,true)])]):_vm._e()])]):_vm._e()]})],2)])]):_vm._e(),(_vm.detail.meta.truck_i && _vm.checkRoleExists('truck_i'))?_c('div',{staticClass:"accordion-item"},[_c('h2',{staticClass:"accordion-header",attrs:{"id":"productTruckC"}},[_c('button',{staticClass:"accordion-button",class:{
                        collapsed: _vm.detail.meta.pack != null,
                    },attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#collapseTruckC","aria-expanded":"true","aria-controls":"collapseTruckC"}},[_vm._m(6)])]),_c('div',{staticClass:"accordion-collapse collapse",class:{
                    show: _vm.detail.meta.pack == null,
                },attrs:{"id":"collapseTruckC","aria-labelledby":"productTruckC","data-bs-parent":"#productCollapseGeneral"}},[_c('div',{staticClass:"accordion-body"},[_vm._l((_vm.detail.roles),function(role_card,index){return [(role_card.group == 'truck_i')?_c('div',{key:index,staticClass:"box no-shadow border-gray mb-2"},[_c('h5',{staticClass:"mb-0 fw-bold"},[_vm._v("Informasi Sub")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Alamat Domisili")]),_c('div',{staticClass:"form-box bg-white h-textarea"},[_vm._v(" "+_vm._s(_vm.detail.meta.truck_i.partners .domicile_address ? _vm.detail.meta.truck_i .partners .domicile_address : "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Alamat KTP")]),_c('div',{staticClass:"form-box bg-white h-textarea"},[_vm._v(" "+_vm._s(_vm.detail.meta.truck_i.partners .identity_address ? _vm.detail.meta.truck_i .partners .identity_address : "-")+" ")])]),(
                                        role_card.role ==
                                        'trawltruck-driver-individual'
                                    )?_c('div',{staticClass:"col-md-12 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Jenis Armada")]),(
                                            _vm.detail.meta.truck_i.fleets
                                                .length > 0
                                        )?_c('div',{staticClass:"row"},_vm._l((_vm.detail
                                                .meta.truck_i.fleets),function(fleet,index){return _c('div',{key:index,staticClass:"col-md-6 mb-2"},[_c('div',{staticClass:"form-box bg-white"},[_c('div',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(fleet)+" ")])])])}),0):_c('div',{staticClass:"row"},[_vm._m(7,true)])]):_vm._e()])]):_vm._e()]})],2)])]):_vm._e(),(_vm.detail.meta.carrier && _vm.checkRoleExists('carrier'))?_c('div',{staticClass:"accordion-item"},[_c('h2',{staticClass:"accordion-header",attrs:{"id":"productCarrier"}},[_c('button',{staticClass:"accordion-button",class:{
                        collapsed: _vm.detail.meta.pack != null,
                    },attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#collapseCarrier","aria-expanded":"true","aria-controls":"collapseCarrier"}},[_vm._m(8)])]),_c('div',{staticClass:"accordion-collapse collapse",class:{
                    show: _vm.detail.meta.pack == null,
                },attrs:{"id":"collapseCarrier","aria-labelledby":"productCarrier","data-bs-parent":"#productCollapseGeneral"}},[_c('div',{staticClass:"accordion-body"},[_vm._l((_vm.detail.roles),function(role_card,index){return [(role_card.group == 'carrier')?_c('div',{key:index,staticClass:"box no-shadow border-gray mb-2"},[_c('h5',{staticClass:"mb-0 fw-bold"},[_vm._v("Informasi Sub")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Nama Perusahaan")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.meta.carrier.partners .name ? _vm.detail.meta.carrier .partners.name : "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Tahun Berdiri")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.meta.carrier.partners .year ? _vm.detail.meta.carrier .partners.year : "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("Nomor Induk Perusahaan")]),_c('div',{staticClass:"form-box bg-white"},[_vm._v(" "+_vm._s(_vm.detail.meta.carrier.partners.nip ? _vm.detail.meta.carrier .partners.nip : "-")+" ")])]),_c('div',{staticClass:"col-md-6 mt-3"},[_c('label',{staticClass:"fw-bold mb-2"},[_vm._v("NPWP")]),_c('div',{staticClass:"input-group mb-3"},[_c('span',{staticClass:"input-group-text"},[(
                                                    _vm.detail.meta.carrier
                                                        .partners
                                                        .is_uploaded_npwp
                                                )?_c('img',{attrs:{"src":require("../assets/protect-green.png"),"width":"13","alt":""}}):_c('img',{attrs:{"src":require("../assets/protect-red.png"),"width":"13","alt":""}})]),_c('input',{staticClass:"form-control",attrs:{"type":"text","disabled":""},domProps:{"value":_vm.detail.meta.carrier.partners
                                                    .is_uploaded_npwp ==
                                                true
                                                    ? 'Sudah Diupload'
                                                    : 'Belum Diupload'}})])])])]):_vm._e()]})],2)])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fw-bold"},[_c('a',{staticClass:"text-gray-dashboard size-16",attrs:{"href":"/master/user"}},[_c('i',{staticClass:"fa fa-angle-left me-2"}),_vm._v(" Kembali ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"accordion-header",attrs:{"id":"HO1"}},[_c('button',{staticClass:"accordion-button size-16 fw-bold",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#collapseOne","aria-expanded":"true","aria-controls":"collapseOne"}},[_vm._v(" Detail Informasi User Head Office ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"accordion-header",attrs:{"id":"productTropack"}},[_c('button',{staticClass:"accordion-button size-16 fw-bold",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#collapseTropack","aria-expanded":"true","aria-controls":"collapseTropack"}},[_vm._v(" Detail Informasi User Troben Cargo ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 mb-2"},[_c('div',{staticClass:"form-box bg-white"},[_c('div',{staticClass:"capitalize"},[_vm._v(" - ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"size-16 fw-bold"},[_vm._v(" Detail Informasi User Troben Truk ")]),_c('div',{staticClass:"tag yellow-cro ms-3 mb-0 radius"},[_vm._v(" Corporate ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 mb-2"},[_c('div',{staticClass:"form-box bg-white"},[_c('div',{staticClass:"capitalize"},[_vm._v(" - ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"size-16 fw-bold"},[_vm._v(" Detail Informasi User Troben Truk ")]),_c('div',{staticClass:"tag yellow-cro ms-3 mb-0 radius"},[_vm._v(" Individu ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 mb-2"},[_c('div',{staticClass:"form-box bg-white"},[_c('div',{staticClass:"capitalize"},[_vm._v(" - ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"size-16 fw-bold"},[_vm._v(" Detail Informasi User Troben Carier ")])])
}]

export { render, staticRenderFns }