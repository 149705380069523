<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/trawltruck/ho/aggrement" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4 pb-0">
            <div class="d-flex align-items-center mb-5 m-mb-2">
                <h3 class="fw-bold mb-0">
                    Detail Perjanjian Kerjasama
                </h3>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Kode
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.partner.code }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Nama Mitra
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.partner.name }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Nomor Telepon
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.partner.phone }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Email
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.partner.email }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Periode Kerjasama
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ moment(detail.date_start).format('DD MMMM YYYY') }} - {{ moment(detail.date_end).format('DD MMMM YYYY') }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Penanggung Jawab
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.partner.owner }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Status Pengajuan
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    <template v-if="detail.status == 'pending'">
                        <small class="tag gray px-4 m-mt-1">
                            {{ detail.status }}
                        </small>
                    </template>
                    <template v-else>
                        <small class="tag green px-4 m-mt-1">
                            {{ detail.status }}
                        </small>
                    </template>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Status PKS
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    <template v-if="detail.status_partnership == 'pending'">
                        <small class="tag gray px-4 m-mt-1">
                            {{ detail.status }}
                        </small>
                    </template>
                    <template v-else>
                        <small class="tag green px-4 m-mt-1">
                            {{ detail.status }}
                        </small>
                    </template>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Alamat
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.partner.address }}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Surat Kerjasama
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    <div class="d-flex align-items-center">
                        <div class="m-mt-0-5">
                            <img src="../../assets/dashboard/pdf.png" width="30" alt="" />
                        </div>
                        <div class="ms-auto">
                            <a :href="detail.document_url" target="_blank" download class="text-green">
                                Download Surat Kerjasama
                                <i class="fa fa-download ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-2 fw-bold">
                    Catatan Khusus
                </div>
                <div class="col-md-1 d-none d-lg-block">
                    :
                </div>
                <div class="col-md-9">
                    {{ detail.note }}
                </div>
            </div>
            <hr>
        </div>
    </div>
</template>

<script>
    import Api from "../../api/Api";
    import moment from "moment";

    export default {
        name: "ManagementAggrementDetail",
        data() {
            return {
                moment: moment,
                detail: {
                    partner: {}
                }
            };
        },
        mounted() {
        },
        created(){
            this.getDetail()
        },
        methods: {
            getDetail() {
                Api.get(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partnership/detail?id=${this.$route.params.id}`, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    var data = res.data.data
                    this.detail = data
                })
                .catch((err) => {
                    console.log(err);
                });
            },
        }
    };
</script>

<style scoped>
    .text-main-color{
        color: #24516A !important;
    }
    .text-gray-dashboard{
        color: #61616A;
    }
    .foto-box{
        width: 100px;
        height: 50px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 5px;
        position: relative;
    }.nav-link.dashboard.center{
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    .nav-link.dashboard.left{
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    .nav-link.dashboard.active{
        background-color: #E60013 !important;
    }
    .w-33{
        width: 33.33%;
    }
</style>