<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">List Troben Branch</h3>
            </div>
            <div class="d-flex mt-4">
                <div class="me-3">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Search Brand or Phone"
                            v-model="req.search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div>
                    <a
                        href="/crm/branchs/create"
                        class="btn btn-primary btn-with-search px-5"
                    >
                        Add New
                    </a>
                </div>
                <div class="me-auto m-mt-1 ms-3">
                    <button
                        class="btn btn-outline-black btn-filter px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <label class="fw-bold">Filter</label>
                    </div>
                    <div class="col-md-8">
                        <multiselect
                            v-model="regency"
                            :options="regencies"
                            label="name"
                            track-by="id"
                            placeholder="Select Regency"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-outline-black px-5 w-100"
                            :disabled="regency == null"
                            @click="clearFilter()"
                        >
                            Clear
                        </button>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-green px-5 w-100"
                            :disabled="regency == null"
                            @click="filter()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <div class="mt-4" v-if="is_list_ready">
                        <ListLoader />
                    </div>
                    <template v-else>
                        <div class="table-responsive-custom">
                            <table class="table table-bordered mt-4">
                                <thead class="table-light">
                                    <tr>
                                        <th class="text-center" scope="col">
                                            No
                                        </th>
                                        <th class="text-center" scope="col">
                                            Branch Code
                                        </th>
                                        <th class="text-center" scope="col">
                                            Brand Name
                                        </th>
                                        <th class="text-center" scope="col">
                                            Regency
                                        </th>
                                        <th class="text-center" scope="col">
                                            Phone
                                        </th>
                                        <th class="text-center" scope="col">
                                            Employee
                                        </th>
                                        <th class="text-center" scope="col">
                                            Total Income
                                        </th>
                                        <th class="text-center" scope="col">
                                            Status
                                        </th>
                                        <th class="text-center" scope="col">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(item, index) in data.list_data"
                                        :key="index"
                                    >
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td>
                                            <a
                                                :href="
                                                    '/crm/branchs/detail/' +
                                                    item.id
                                                "
                                                class="text-link"
                                            >
                                                {{ item.code }}
                                            </a>
                                        </td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.regency_name }}</td>
                                        <td class="text-center">
                                            {{ item.phone }}
                                        </td>
                                        <td class="text-center">
                                            {{ item.employee }}
                                        </td>
                                        <td>
                                            {{
                                                currencyFormat(
                                                    item.total_income
                                                )
                                            }}
                                        </td>
                                        <td>
                                            <div
                                                class="form-check form-switch"
                                                v-if="item.status == 'active'"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    :id="item.id"
                                                    @change="
                                                        showDeleteModal(item.id)
                                                    "
                                                    :checked="
                                                        item.status == 'active'
                                                    "
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="item.id"
                                                >
                                                    Active
                                                </label>
                                            </div>
                                            <div
                                                class="form-check form-switch"
                                                v-else
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    @change="
                                                        showNonActiveModal(
                                                            item.id
                                                        )
                                                    "
                                                    :id="item.id"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="item.id"
                                                >
                                                    Non Active
                                                </label>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <a
                                                :href="
                                                    '/crm/branchs/edit/' +
                                                    item.id
                                                "
                                                v-if="item.status == 'active'"
                                            >
                                                <img
                                                    src="../../assets/pencil-icon.png"
                                                    width="19"
                                                    alt=""
                                                />
                                            </a>
                                            <a
                                                href="javascript:void(0)"
                                                v-else
                                                class="cursor-drop"
                                            >
                                                <img
                                                    src="../../assets/pencil-icon.png"
                                                    width="19"
                                                    alt=""
                                                />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <template v-if="data.list_data.length == 0">
                            <div class="text-center mt-5">
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </template>
                    </template>
                    <div class="d-flex" v-if="data.list_data.length > 0">
                        <div class="ms-auto">
                            <div class="d-flex mt-4">
                                <div class="me-3">
                                    <select
                                        class="form-control form-select w-select"
                                        v-model="per_page"
                                        @change="getList()"
                                    >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                                <b-pagination-nav
                                    v-model="currentPage"
                                    :number-of-pages="data.total_page"
                                    base-url="#"
                                    first-number
                                    align="right"
                                    @input="changePage"
                                ></b-pagination-nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="deleteModal"></div>
            <div class="modal-body-chat vsm" v-if="deleteModal">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold text-center">
                            <img
                                src="../../assets/info-yellow.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-ekstra-bold text-center">
                        Branch Status Will Be Changed
                    </div>
                    <div class="text-center size-14">
                        Are you sure you want to change branch status?
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-outline-black w-100 btn-lg"
                                @click="deleteModal = false"
                            >
                                Cancel
                            </button>
                        </div>
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-primary w-100 btn-lg"
                                @click="deleteData()"
                                :disabled="is_delete"
                            >
                                <span
                                    class="spinner-border spinner-border-sm me-2"
                                    v-if="is_delete"
                                ></span>
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="nonActiveModal"></div>
            <div class="modal-body-chat vsm" v-if="nonActiveModal">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold text-center">
                            <img
                                src="../../assets/info-yellow.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-ekstra-bold text-center">
                        Branch Status Will Be Changed
                    </div>
                    <div class="text-center size-14">
                        Are you sure you want to change branch status?
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-outline-black w-100 btn-lg"
                                @click="nonActiveModal = false"
                            >
                                Cancel
                            </button>
                        </div>
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-primary w-100 btn-lg"
                                @click="returnAction()"
                                :disabled="is_non_active"
                            >
                                <span
                                    class="spinner-border spinner-border-sm me-2"
                                    v-if="is_non_active"
                                ></span>
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";
import ListLoader from "../../components/ListLoader.vue";

export default {
    components: {
        ListLoader,
        Multiselect,
    },
    name: "crmBranchs",
    data() {
        return {
            is_list_ready: false,
            isFilter: false,
            regency: null,
            regencies: [],
            data: {
                list_data: [],
            },
            req: {
                search: "",
            },
            per_page: 10,
            currentPage: 0,
            deleteModal: false,
            list_id: null,
            is_delete: false,
            is_non_active: false,
            nonActiveModal: false,
            non_active_id: null,
        };
    },
    created() {
        this.getRegencies();
        this.getList();
    },
    methods: {
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        clearFilter() {
            this.regency = null;
            this.getList();
        },
        filter() {
            this.getList();
        },
        getList() {
            let payload = {
                search: this.req.search,
                limit: this.per_page,
                regency_id: this.regency?.id,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/branches`,
                {
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.data = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        changePage() {
            this.getList();
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        getRegencies() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/regencies`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.regencies = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        showDeleteModal(id) {
            this.list_id = id;
            this.deleteModal = true;
        },
        deleteData() {
            this.is_delete = true;
            var data = {
                id: this.list_id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/branches/delete`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.deleteModal = false;
                    this.is_delete = false;
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Change Status Succeeded!",
                    });
                    this.getList();
                })
                .catch((err) => {
                    console.log(err);
                    this.deleteModal = false;
                    this.is_delete = false;
                });
        },
        showNonActiveModal(id) {
            this.non_active_id = id;
            this.nonActiveModal = true;
        },
        returnAction() {
            this.is_non_active = true;
            var data = {
                id: this.non_active_id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/branches/restore`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_non_active = false;
                    this.nonActiveModal = false;
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Change Status Succeeded!",
                    });
                    this.getList();
                })
                .catch((err) => {
                    console.log(err);
                    this.nonActiveModal = false;
                    this.is_non_active = false;
                });
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.form-check-input:checked {
    background-color: #3d8824 !important;
    border-color: #3d8824 !important;
}
</style>
