<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_loading"></div>
        <div id="loader" v-if="is_loading"></div>
        <notifications group="foo" position="top right" />
        <h3 class="fw-bold">Edit Pesanan</h3>
        <div class="box mt-5">
            <div class="row">
                <div class="col-md-12">
                    <div class="box no-shadow bc-gray p-3">
                        <h4 class="mb-0 fw-bold">
                            <i class="fa fa-rocket me-2"></i>
                            Pengirim
                        </h4>
                    </div>
                </div>
                <div
                    class="mt-4"
                    :class="user.is_ho == true ? 'col-md-7' : 'col-md-12'"
                >
                    <div class="row">
                        <div class="col-md-6">
                            <label class="fw-bold mb-3">Nomor Telepon</label>
                            <div class="input-group mb-3">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Nomor pengirim"
                                    disabled
                                    aria-describedby="button-addon2"
                                    v-model="sender.phone_number"
                                />
                                <button
                                    class="btn btn-outline-secondary"
                                    type="button"
                                    id="button-addon2"
                                    @click="searchPhoneNumber()"
                                >
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="fw-bold mb-3">Nama Pengirim</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="data_phone.name"
                                placeholder="Nama pengirim"
                                disabled
                                v-if="is_customer"
                            />
                            <input
                                type="text"
                                class="form-control"
                                disabled
                                v-model="sender.sender_name"
                                placeholder="Nama pengirim"
                                v-else
                            />
                        </div>
                        <div class="col-md-12 mt-3" v-if="user.is_ho">
                            <label class="fw-bold mb-3"
                                >Kota/Kabupaten/Kecamatan</label
                            >
                            <model-list-select
                                :isDisabled="true"
                                :list="geoLists"
                                option-value="sub_district_id"
                                option-text="label"
                                v-model="geoListSetItems"
                                :placeholder="senderValuePlaceholder"
                                @searchchange="searchGeoList"
                            >
                            </model-list-select>
                        </div>
                        <!-- <div class="col-md-6 mt-3" v-if="user.is_ho">
                            <label class="fw-bold mb-3">Mitra</label>
                            <model-list-select
                                :list="partners"
                                v-model="mitra_item"
                                option-value="code"
                                option-text="code"
                                :isDisabled="sender.destination == ''"
                                placeholder="Pilih mitra"
                            >
                            </model-list-select>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-5 mt-4" v-if="user.is_ho">
                    <label class="fw-bold mb-3">Alamat Pengirim</label>
                    <textarea
                        class="form-control"
                        placeholder="Masukkan alamat pengirim"
                        disabled
                        v-model="sender.note"
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                    ></textarea>
                </div>
            </div>
        </div>

        <div v-for="(receiver, index) in receivers" :key="index">
            <div class="box mt-5 position-relative">
                <div class="number-point">
                    {{ index + 1 }}
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="box no-shadow bc-gray p-3">
                            <div class="d-flex align-items-center">
                                <h4 class="mb-0 fw-bold">
                                    <i class="fa fa-cube me-2"></i>
                                    Penerima
                                </h4>
                                <template v-if="receiver.hash == ''">
                                    <div
                                        class="ms-auto"
                                        v-if="receivers.length > 1"
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            class="btn btn-primary px-4"
                                            @click="deleteRereceiver(index)"
                                        >
                                            Hapus Penerima
                                        </a>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 mt-4">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="fw-bold mb-3"
                                    >Nama Penerima</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Masukkan nama penerima"
                                    v-model="receiver.receiver_name"
                                    :name="`receivers[${index}][receiver_name]`"
                                />
                            </div>
                            <div class="col-md-6">
                                <label class="fw-bold mb-3"
                                    >Nomor Telepon</label
                                >
                                <input
                                    type="number"
                                    class="form-control"
                                    placeholder="Masukkan nomor telepon"
                                    v-model="receiver.receiver_phone"
                                    :name="`receivers[${index}][phone_number]`"
                                />
                            </div>
                            <div class="col-md-12 mt-3">
                                <label class="fw-bold mb-3"
                                    >Kota/Kabupaten/Kecamatan</label
                                >
                                <model-list-select
                                    :list="receiverList"
                                    v-model="receiver.destination_address"
                                    option-value="sub_district_id"
                                    option-text="label"
                                    :placeholder="
                                        receiver.destination_address_caption
                                    "
                                    @searchchange="searchReceivers"
                                >
                                </model-list-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 mt-4">
                        <label class="fw-bold mb-3">Alamat Penerima</label>
                        <textarea
                            class="form-control"
                            placeholder="Masukkan alamat penerima"
                            cols="30"
                            rows="5"
                            v-model="receiver.receiver_address"
                            :name="`receivers[${index}][note]`"
                        >
                        </textarea>
                    </div>
                    <div class="col-md-12 mt-4">
                        <label class="fw-bold mb-3">
                            Upload Gambar
                            <span class="text-gray">(Optional)</span>
                        </label>
                        <div class="row">
                            <div
                                class="col-md-2 mb-3"
                                v-for="(f, index_item) in receiver.images"
                                :key="index_item"
                            >
                                <label class="upload__btn no-border">
                                    <div
                                        class="close-icon-order"
                                        @click.prevent="
                                            removeImage(index, $event)
                                        "
                                    >
                                        <i class="fa fa-trash"></i>
                                    </div>
                                    <input
                                        type="file"
                                        :class="`images[] upload__inputfile`"
                                        @change="
                                            previewImage(
                                                index_item,
                                                index,
                                                $event
                                            )
                                        "
                                    />

                                    <div
                                        :class="
                                            'images[' +
                                            index +
                                            index_item +
                                            ']-preview'
                                        "
                                    >
                                        <div
                                            class="image-preview"
                                            :style="{
                                                'background-image':
                                                    'url(' + f.uri + ')',
                                            }"
                                        ></div>
                                        <p class="mb-0" style="color: #adb5bd">
                                            <i class="fa fa-cloud-upload"></i>
                                        </p>
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-2 mb-3">
                                <label
                                    class="upload__btn"
                                    @click.prevent="addNewImage(index)"
                                >
                                    <p class="mb-0" style="color: #adb5bd">
                                        <i class="fa fa-plus"></i>
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="box no-shadow bc-light-green mt-4">
                            <div class="d-flex align-items-center">
                                <div class="me-3">
                                    <img
                                        src="../../assets/shopping-cart.png"
                                        width="35"
                                        alt=""
                                    />
                                </div>
                                <div>
                                    <div class="size-20 fw-bold">Darat</div>
                                    <div class="size-14 capitalize">
                                        {{ detailOrder.estimation_notes }}
                                    </div>
                                </div>
                                <div class="ms-auto size-20 fw-bold">
                                    {{
                                        currencyFormat(detailOrder.tier_price)
                                    }}/Kg
                                </div>
                            </div>
                        </div>
                        <div class="box no-shadow border-gray mt-4">
                            <div class="d-flex align-items-center">
                                <h5 class="mb-0 fw-bold">
                                    <i class="fa fa-dropbox me-2"></i>
                                    Daftar Barang
                                </h5>
                                <div
                                    class="ms-auto"
                                    v-if="receivers[0].is_bike == false"
                                >
                                    <template v-if="user.is_ho">
                                        <button
                                            class="btn btn-green px-4"
                                            @click="
                                                modalAddItems = true;
                                                receiver_index = index;
                                            "
                                            :disabled="
                                                mitra_item.id == '' &&
                                                sender.destination
                                            "
                                        >
                                            Tambah Barang
                                        </button>
                                    </template>
                                    <template v-else>
                                        <button
                                            class="btn btn-green px-4"
                                            @click="
                                                modalAddItems = true;
                                                receiver_index = index;
                                            "
                                            :disabled="
                                                receiver.destination_address
                                                    .sub_district_id == ''
                                            "
                                        >
                                            Tambah Barang
                                        </button>
                                    </template>
                                </div>
                            </div>
                            <template v-if="receiver.items.length > 0">
                                <div class="modern-table-boxed mt-3">
                                    <div class="d-table-row header">
                                        <div class="d-table-cell">No</div>
                                        <div class="d-table-cell">Kategori</div>
                                        <div
                                            class="d-table-cell"
                                            v-if="receivers[0].is_bike == true"
                                        >
                                            Jenis Motor
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="receivers[0].is_bike == true"
                                        >
                                            Merek Motor
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="receivers[0].is_bike == true"
                                        >
                                            CC Motor
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="receivers[0].is_bike == true"
                                        >
                                            Tahun Motor
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="receivers[0].is_bike == false"
                                        >
                                            Deskripsi
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="receivers[0].is_bike == false"
                                        >
                                            Berat
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="receivers[0].is_bike == false"
                                        >
                                            Dimensi (p x l x t)
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="receivers[0].is_bike == false"
                                        >
                                            Perlindungan
                                        </div>
                                        <div class="d-table-cell">Asuransi</div>
                                        <div class="d-table-cell">Aksi</div>
                                    </div>
                                    <div
                                        class="d-table-row"
                                        v-for="(
                                            list_item, item_index
                                        ) in receiver.items"
                                        :key="item_index"
                                    >
                                        <div
                                            class="d-table-cell fw-bold"
                                            style="width: 20px"
                                        >
                                            {{ item_index + 1 }}.
                                        </div>
                                        <div class="d-table-cell capitalize">
                                            {{
                                                (list_item.category_name || "")
                                                    .split("_")
                                                    .join(" ")
                                            }}
                                        </div>
                                        <div
                                            class="d-table-cell capitalize"
                                            v-if="receivers[0].is_bike == true"
                                        >
                                            {{ receiver?.moto_bike.type }}
                                        </div>
                                        <div
                                            class="d-table-cell capitalize"
                                            v-if="receivers[0].is_bike == true"
                                        >
                                            {{ receiver?.moto_bike.merk }}
                                        </div>
                                        <div
                                            class="d-table-cell capitalize"
                                            v-if="receivers[0].is_bike == true"
                                        >
                                            {{ receiver?.moto_bike.cc }} CC
                                        </div>
                                        <div
                                            class="d-table-cell capitalize"
                                            v-if="receivers[0].is_bike == true"
                                        >
                                            {{ receiver?.moto_bike?.years }}
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="receivers[0].is_bike == false"
                                        >
                                            <template v-if="list_item.desc">
                                                {{ list_item.desc }}
                                            </template>
                                            <template v-else> - </template>
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="receivers[0].is_bike == false"
                                        >
                                            {{ list_item.weight }} Kg
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="receivers[0].is_bike == false"
                                        >
                                            {{ list_item.length }} x
                                            {{ list_item.width }} x
                                            {{ list_item.height }}
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="receivers[0].is_bike == false"
                                        >
                                            <template
                                                v-if="
                                                    list_item.handling.length >
                                                    0
                                                "
                                            >
                                                <template
                                                    v-for="(
                                                        handling, index
                                                    ) in list_item.handling"
                                                >
                                                    <small
                                                        class="tag gray"
                                                        :key="index"
                                                    >
                                                        <template
                                                            v-if="
                                                                handling ==
                                                                'bubble wrap'
                                                            "
                                                        >
                                                            Bubble Wrap
                                                        </template>
                                                        <template
                                                            v-if="
                                                                handling ==
                                                                'cardboard'
                                                            "
                                                        >
                                                            Kardus
                                                        </template>
                                                        <template
                                                            v-if="
                                                                handling ==
                                                                'plastic'
                                                            "
                                                        >
                                                            Plastik
                                                        </template>
                                                        <template
                                                            v-if="
                                                                handling ==
                                                                'wood'
                                                            "
                                                        >
                                                            Kayu
                                                        </template>
                                                        <template
                                                            v-if="
                                                                handling ==
                                                                'sandbag sm'
                                                            "
                                                        >
                                                            Karung Kecil
                                                        </template>
                                                        <template
                                                            v-if="
                                                                handling ==
                                                                'sandbag md'
                                                            "
                                                        >
                                                            Karung Sedang
                                                        </template>
                                                        <template
                                                            v-if="
                                                                handling ==
                                                                'sandbag l'
                                                            "
                                                        >
                                                            Karung Besar
                                                        </template>
                                                    </small>
                                                </template>
                                            </template>
                                            <template v-else> - </template>
                                        </div>
                                        <div class="d-table-cell">
                                            <template
                                                v-if="
                                                    list_item.is_insured ==
                                                        true &&
                                                    list_item.price != 0
                                                "
                                            >
                                                {{
                                                    currencyFormat(
                                                        list_item.price
                                                    )
                                                }}
                                            </template>
                                            <template v-else>
                                                <small class="tag red"
                                                    >Tidak</small
                                                >
                                            </template>
                                        </div>
                                        <div class="d-table-cell">
                                            <a
                                                href="javascript:void(0)"
                                                class="text-primary"
                                                @click="
                                                    showDeleteModal(
                                                        index,
                                                        item_index
                                                    )
                                                "
                                            >
                                                <i class="fa fa-trash"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="box no-shadow border-gray mt-4">
                                    <div class="text-center">
                                        <div>
                                            <img
                                                src="../../assets/no-data.png"
                                                width="100"
                                                alt=""
                                            />
                                        </div>
                                        <div class="mt-3 size-16 fw-medium">
                                            Data Barang Kosong
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div class="row" v-if="receiver.items.length > 0">
                                <div class="col-md-7"></div>
                                <div class="col-md-5 mt-3">
                                    <div class="box no-shadow bc-gray">
                                        <div
                                            class="d-flex align-items-center mb-3"
                                        >
                                            <div>Biaya Pengiriman</div>
                                            <div class="ms-auto fw-bold">
                                                {{
                                                    currencyFormat(
                                                        receiver.calculate_data
                                                            .service
                                                    )
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="d-flex align-items-center mb-3"
                                        >
                                            <div>Biaya Packing</div>
                                            <div class="ms-auto fw-bold">
                                                {{
                                                    currencyFormat(
                                                        receiver.calculate_data
                                                            .handling
                                                    )
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="d-flex align-items-center mb-3"
                                        >
                                            <div>Biaya Asuransi</div>
                                            <div class="ms-auto fw-bold">
                                                {{
                                                    currencyFormat(
                                                        receiver.calculate_data
                                                            .insurance_price_total
                                                    )
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="d-flex align-items-center mb-3"
                                        >
                                            <div>Biaya Tambahan</div>
                                            <div class="ms-auto fw-bold">
                                                {{
                                                    currencyFormat(
                                                        receiver.calculate_data
                                                            .additional_price
                                                    )
                                                }}
                                            </div>
                                        </div>
                                        <div class="line"></div>
                                        <div class="d-flex align-items-center">
                                            <div class="size-18 fw-bold">
                                                Total Biaya
                                            </div>
                                            <div
                                                class="ms-auto fw-bold size-18"
                                            >
                                                {{
                                                    currencyFormat(
                                                        receiver.calculate_data
                                                            .total_amount
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="box no-shadow green mt-3" v-if="receiver.calculate_data.price != null && receiver.items.length > 0">
                <div class="d-flex align-items-center">
                    <div>
                        <i class="fa fa-cart-plus size-26"></i>
                    </div>
                    <div class="ms-4">
                        <h5 class="fw-bold mb-0">Darat</h5>
                        <div class="mt-1 size-13 capitalize">
                            {{ receiver.calculate_data.price.notes }}
                        </div>
                    </div>
                    <div class="ms-auto">
                        <h5 class="mb-0 fw-bold">
                            {{ currencyFormat(receiver.calculate_data.tier) }}/Kg
                        </h5>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="mt-4">
            <a
                href="javascript:void(0)"
                class="btn btn-green btn-lg px-5"
                @click="addReceiver()"
            >
                Tambah Penerima
            </a>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="deleteModal"></div>
            <div class="modal-body-chat sm" v-if="deleteModal">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            <img
                                src="../../assets/trash-modal.png"
                                width="65"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-ekstra-bold">Hapus Barang</div>
                    <div class="mt-2">
                        Anda yakin untuk menghapus barang dari daftar barang?
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <a
                                href="javascript:void(0)"
                                class="btn btn-outline-black w-100"
                                @click="deleteModal = false"
                            >
                                Batal
                            </a>
                        </div>
                        <div class="col-md-6 mt-4">
                            <a
                                href="javascript:void(0)"
                                class="btn btn-primary w-100"
                                @click="deleteItems()"
                            >
                                Hapus
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div
                class="overlay"
                v-if="modalAddItems"
                @click="modalAddItems = false"
            ></div>
            <div class="modal-body-chat" v-if="modalAddItems">
                <div class="box no-shadow bc-gray p-3">
                    <div class="d-flex bd-highlight align-items-lg-center">
                        <div class="flex-grow-1 bd-highlight">
                            <div class="size-18 fw-ekstra-bold">
                                Tambah Barang
                            </div>
                        </div>
                        <div class="bd-highlight">
                            <a
                                href="javascript:void(0)"
                                @click="modalAddItems = false"
                                class="size-20 text-primary"
                            >
                                <i class="fa fa-times-circle"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="scroll mt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="fw-bold mb-2">Kategori Barang</label>
                            <multiselect
                                v-model="categoryItem"
                                :options="categories"
                                label="name"
                                track-by="name"
                                placeholder="Pilih kategori"
                                @input="changeCategory"
                            >
                            </multiselect>
                        </div>
                        <div class="col-md-12 mt-4">
                            <div class="box no-shadow border-gray bc-gray p-3">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        v-model="item.is_glassware"
                                        id="is_glassware"
                                        :value="true"
                                    />
                                    <label
                                        class="form-check-label"
                                        for="is_glassware"
                                    >
                                        Barang mudah
                                        <span class="text-green">pecah</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="fw-bold mb-2"
                                >Berat
                                <span class="text-gray">(Kg)</span></label
                            >
                            <input
                                type="number"
                                class="form-control"
                                v-model="item.weight"
                                placeholder="Masukkan berat barang"
                            />
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="fw-bold mb-2">Jumlah Paket</label>
                            <input
                                type="number"
                                class="form-control"
                                v-model="item.qty"
                                placeholder="Masukkan berat barang"
                            />
                        </div>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-2">Dimensi</label>
                            <div class="row">
                                <div class="col-md-4">
                                    <input
                                        type="number"
                                        v-model="item.length"
                                        class="form-control"
                                        placeholder="Panjang (cm)"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <input
                                        type="number"
                                        v-model="item.width"
                                        class="form-control"
                                        placeholder="Lebar (cm)"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <input
                                        type="number"
                                        v-model="item.height"
                                        class="form-control"
                                        placeholder="Tinggi (cm)"
                                    />
                                </div>
                            </div>
                        </div>
                        <template v-if="item.is_glassware == false">
                            <div class="col-md-12 mt-3">
                                <label class="fw-bold mb-2"
                                    >Asuransi Barang</label
                                >
                            </div>
                            <div class="col-md-6 mt-2">
                                <label class="plan" for="tidak">
                                    <input
                                        type="radio"
                                        id="tidak"
                                        name="insurance"
                                        v-model="item.is_insured"
                                        :value="false"
                                    />
                                    <div class="plan-content insurance p-3">
                                        <h6 class="mb-0 fw-bold">
                                            Tidak Pakai Asuransi Barang
                                        </h6>
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label class="plan" for="ya">
                                    <input
                                        checked
                                        type="radio"
                                        name="insurance"
                                        id="ya"
                                        v-model="item.is_insured"
                                        :value="true"
                                    />
                                    <div class="plan-content insurance p-3">
                                        <h6 class="mb-0 fw-bold">
                                            Pakai Asuransi Barang
                                        </h6>
                                    </div>
                                </label>
                            </div>
                            <div
                                class="col-md-12 mt-3"
                                v-if="item.is_insured == true"
                            >
                                <label class="fw-bold mb-2">Harga</label>
                                <money
                                    v-model="item.price"
                                    v-bind="money"
                                    class="form-control"
                                >
                                </money>
                            </div>
                        </template>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-2">Perlindungan</label>
                            <div class="row">
                                <div class="col-md-12">
                                    <div
                                        class="form-check form-check-inline"
                                        v-for="(handling, index) in handling"
                                        :key="index"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            :id="handling.id"
                                            :value="handling.value"
                                            v-model="item.handling"
                                        />
                                        <label
                                            class="form-check-label"
                                            :for="handling.id"
                                        >
                                            {{ handling.name }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-2">Deskripsi</label>
                            <textarea
                                name=""
                                id=""
                                cols="30"
                                rows="5"
                                class="form-control"
                                v-model="item.desc"
                                placeholder="Masukkan deskripsi barang"
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <a
                        href="javascript:void(0)"
                        class="btn btn-green btn-lg px-5"
                        @click="addItems()"
                    >
                        Simpan
                    </a>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div
                class="overlay"
                v-if="modalEditItems"
                @click="modalEditItems = false"
            ></div>
            <div class="modal-body-chat" v-if="modalEditItems">
                <div class="box no-shadow bc-gray p-3">
                    <div class="d-flex bd-highlight align-items-lg-center">
                        <div class="flex-grow-1 bd-highlight">
                            <div class="size-18 fw-ekstra-bold">
                                Edit Barang
                            </div>
                        </div>
                        <div class="bd-highlight">
                            <a
                                href="javascript:void(0)"
                                @click="modalEditItems = false"
                                class="size-20 text-primary"
                            >
                                <i class="fa fa-times-circle"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="scroll mt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="fw-bold mb-2">Kategori Barang</label>
                            <multiselect
                                v-model="editCategoryItem"
                                :options="categories"
                                label="name"
                                track-by="name"
                                placeholder="Pilih kategori"
                                @input="changeEditCategory"
                            >
                            </multiselect>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="fw-bold mb-2"
                                >Berat
                                <span class="text-gray">(Kg)</span></label
                            >
                            <input
                                type="number"
                                class="form-control"
                                v-model="edit_item.weight"
                                placeholder="Masukkan berat barang"
                            />
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="fw-bold mb-2">Jumlah Paket</label>
                            <input
                                type="number"
                                class="form-control"
                                v-model="edit_item.qty"
                                placeholder="Masukkan berat barang"
                            />
                        </div>
                        <div class="col-md-12 mt-4">
                            <div class="box no-shadow border-gray bc-gray p-3">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        v-model="edit_item.is_glassware"
                                        id="is_glassware"
                                        :value="true"
                                    />
                                    <label
                                        class="form-check-label"
                                        for="is_glassware"
                                    >
                                        Barang mudah
                                        <span class="text-green">pecah</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-2">Dimensi</label>
                            <div class="row">
                                <div class="col-md-4">
                                    <input
                                        type="number"
                                        v-model="edit_item.length"
                                        class="form-control"
                                        placeholder="Panjang (cm)"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <input
                                        type="number"
                                        v-model="edit_item.width"
                                        class="form-control"
                                        placeholder="Lebar (cm)"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <input
                                        type="number"
                                        v-model="edit_item.height"
                                        class="form-control"
                                        placeholder="Tinggi (cm)"
                                    />
                                </div>
                            </div>
                        </div>
                        <template v-if="edit_item.is_glassware == false">
                            <div class="col-md-12 mt-3">
                                <label class="fw-bold mb-2"
                                    >Asuransi Barang</label
                                >
                            </div>
                            <div class="col-md-6 mt-2">
                                <label class="plan" for="tidak">
                                    <input
                                        type="radio"
                                        id="tidak"
                                        name="insurance"
                                        v-model="edit_item.is_insured"
                                        :value="false"
                                    />
                                    <div class="plan-content insurance p-3">
                                        <h6 class="mb-0 fw-bold">
                                            Tidak Pakai Asuransi Barang
                                        </h6>
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label class="plan" for="ya">
                                    <input
                                        checked
                                        type="radio"
                                        name="insurance"
                                        id="ya"
                                        v-model="edit_item.is_insured"
                                        :value="true"
                                    />
                                    <div class="plan-content insurance p-3">
                                        <h6 class="mb-0 fw-bold">
                                            Pakai Asuransi Barang
                                        </h6>
                                    </div>
                                </label>
                            </div>
                            <div
                                class="col-md-12 mt-3"
                                v-if="edit_item.is_insured == true"
                            >
                                <label class="fw-bold mb-2">Harga</label>
                                <money
                                    v-model="edit_item.price"
                                    v-bind="money"
                                    class="form-control"
                                >
                                </money>
                            </div>
                        </template>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-2">Perlindungan</label>
                            <div class="row">
                                <div class="col-md-12">
                                    <div
                                        class="form-check form-check-inline"
                                        v-for="(handling, index) in handling"
                                        :key="index"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            :id="handling.id"
                                            :value="handling.value"
                                            v-model="edit_item.handling"
                                        />
                                        <label
                                            class="form-check-label"
                                            :for="handling.id"
                                        >
                                            {{ handling.name }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-2">Deskripsi</label>
                            <textarea
                                name=""
                                id=""
                                cols="30"
                                rows="5"
                                class="form-control"
                                v-model="edit_item.desc"
                                placeholder="Masukkan deskripsi barang"
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <a
                        href="javascript:void(0)"
                        class="btn btn-green btn-lg px-5"
                        @click="editItems()"
                    >
                        Update
                    </a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-7"></div>
            <div class="col-md-5">
                <div class="box">
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium">Biaya Pengiriman</div>
                        <div class="ms-auto fw-bold">
                            {{
                                currencyFormat(
                                    all_total_payment.total_service_fee
                                )
                            }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium">Biaya Packing</div>
                        <div class="ms-auto fw-bold">
                            {{
                                currencyFormat(
                                    all_total_payment.total_handling_fee
                                )
                            }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium">Biaya Asuransi</div>
                        <div class="ms-auto fw-bold">
                            {{
                                currencyFormat(
                                    all_total_payment.total_insurance_fee
                                )
                            }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium">Biaya Tambahan</div>
                        <div class="ms-auto fw-bold">
                            {{
                                currencyFormat(
                                    all_total_payment.total_additional_fee
                                )
                            }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium">Biaya Jasa Aplikasi</div>
                        <div class="ms-auto fw-bold">
                            {{
                                currencyFormat(
                                    detailOrder.calculate_data.platform_fee
                                )
                            }}
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="d-flex align-items-center">
                        <div class="size-18 fw-bold text-green">
                            Total Pembayaran
                        </div>
                        <div class="ms-auto fw-bold text-green size-18">
                            {{ currencyFormat(all_total_payment.total_amount) }}
                        </div>
                    </div>
                    <div class="mt-4">
                        <button
                            class="btn btn-green btn-lg w-100"
                            @click="createOrder()"
                        >
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelListSelect } from "vue-search-select";
import Multiselect from "vue-multiselect";
import Api from "../../api/Api";
import $ from "jquery";
import { Money } from "v-money";

export default {
    components: {
        ModelListSelect,
        Multiselect,
        Money,
    },
    name: "CorporateCreate",
    data() {
        return {
            price: 0,
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "Rp. ",
                suffix: "",
                precision: 0,
                masked: false,
            },
            geoListSetItems: null,
            senderValuePlaceholder: "Cari kota/kabupaten/kecamatan",
            parent_id: null,
            child_id: [],
            child_count: 0,
            req: {
                is_payment_method: "",
                is_bank: "",
            },
            receiver_index: 0,
            geoLists: [],
            modalAddItems: false,
            deleteModal: false,
            searchTextGeo: "",
            service_code: "tps",
            sender: {
                sender_name: "",
                phone_number: "",
                destination: "",
                note: "",
                mitra: "",
            },
            mitra_item: {
                code: "",
                id: "",
            },
            receivers: [],
            handling: [
                { id: 1, name: "Bubble Wrap", value: "bubble wrap" },
                { id: 2, name: "Kardus", value: "cardboard" },
                { id: 3, name: "Plastik", value: "plastic" },
                { id: 4, name: "Kayu", value: "wood" },
                { id: 5, name: "Karung Kecil", value: "sandbag sm" },
                { id: 6, name: "Karung Sedang", value: "sandbag md" },
                { id: 7, name: "Karung Besar", value: "sandbag l" },
            ],
            item: {
                name: "",
                category_item_id: null,
                desc: "",
                length: "",
                width: "",
                height: "",
                weight: "",
                qty: "",
                price: 0,
                order_type: "other",
                handling: [],
                is_insured: false,
                is_glassware: false,
                protection: false,
            },
            edit_item: {
                name: "",
                category_item_id: null,
                desc: "",
                length: "",
                width: "",
                height: "",
                weight: "",
                qty: "",
                price: 0,
                order_type: "other",
                handling: [],
                is_insured: false,
                is_glassware: false,
                protection: false,
            },
            partners: [],
            total_amount: 0,
            additional_price: 0,
            insurance_price_total: 0,
            handling_count: 0,
            service_count: 0,
            data_phone: {},
            user: {},
            searchReceiver: "",
            receiverList: [],
            destination_regency_id: "",
            maxImages: 5,
            addImage: "button.add-image",
            is_loading: false,
            showModalPaymentMethod: false,
            payment_lists: [],
            is_payment_loading: false,
            payment_result: {},
            is_customer: true,
            delete_value: null,
            delete_value_array: null,
            edit_value: null,
            categories: [],
            detailOrder: {},
            all_total_payment: {
                total_additional_fee: 0,
                total_amount: 0,
                total_handling_fee: 0,
                total_insurance_fee: 0,
                total_service_fee: 0,
            },
            package_id: null,
            categoryItem: null,
            editCategoryItem: null,
            modalEditItems: false,
            hash: [],
        };
    },
    created() {
        this.getPartners();
        this.getUserLogin();
        this.calcultaeOrder();
        this.getCategoryItem();
        this.getOrderDetail();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    watch: {
        receivers: {
            deep: true,
            handler: function (newval) {
                if (this.user.is_ho) {
                    if (this.geoLists.length == 0) {
                        return;
                    }
                    newval.forEach((r, i) => {
                        this.receivers[i].destination_address_temp =
                            r.destination_address.sub_district_id;
                        this.receivers[i].destination_id =
                            r.destination_address.sub_district_id;
                        this.receivers[i].destination_address_caption =
                            r.destination_address.label;
                    });
                } else {
                    newval.forEach((r, i) => {
                        this.receivers[i].destination_address_temp =
                            r.destination_address.sub_district_id;
                        this.receivers[i].destination_id =
                            r.destination_address.sub_district_id;
                        if (r.package_hash == "") {
                            this.receivers[i].package_hash =
                                this.$route.params.id;
                        }
                        this.receivers[i].destination_address_caption =
                            r.destination_address.label;
                    });
                }
            },
        },
        sender: {
            destination(oldVal, newVal) {
                console.log("", [oldVal, newVal]);
            },
        },
        geoListSetItems(newVal) {
            let selectRow = this.geoLists.filter(function (vv) {
                return vv.sub_district_id == newVal;
            });
            if (selectRow.length) {
                this.sender.destination = selectRow[0].sub_district_id;
                let r = selectRow[0];
                this.senderValuePlaceholder =
                    r.regency + ", " + r.district + ", " + r.sub_district;
            } else {
                this.sender.destination = "";
                this.senderValuePlaceholder = "Cari kota/kabupaten/kecamatan";
            }
        },
    },
    computed: {
        package_child_hash() {
            let package_child_hash = [];

            this.receivers.forEach((item) => {
                package_child_hash.push(item.package_hash);
            });

            return package_child_hash.splice(1);
        },
    },
    methods: {
        getCategoryItem() {
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/trawlpack/dashboard/order/list/category`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.categories = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        showDeleteModal(index, item_index) {
            this.deleteModal = true;
            this.delete_value_array = index;
            this.delete_value = item_index;
        },
        showEditModal(edit) {
            this.edit_item = edit;
            this.editCategoryItem = {
                id: edit.category_item_id,
                name: edit.name,
            };

            this.edit_item.handling = this.edit_item.handling.map((item) => {
                return item.type;
            });

            this.modalEditItems = true;
        },
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.user = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        searchGeoList(searchTextGeo) {
            this.searchTextGeo = searchTextGeo;
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/geo/list?type=sub_district&search=${searchTextGeo}&page=1&per_page=15`
            )
                .then((res) => {
                    this.geoLists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        searchReceivers(searchReceiver) {
            this.searchReceiver = searchReceiver;
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/geo/list?type=sub_district&search=${searchReceiver}&page=1&per_page=15`
            )
                .then((res) => {
                    this.receiverList = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        searchPhoneNumber() {
            if (this.sender.phone_number == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Nomor telepon wajib diisi",
                });
            } else {
                Api.get(
                    `${process.env.VUE_APP_BASE_URL}/partner/corporate/customer?phone=${this.sender.phone_number}`,
                    {
                        headers: {
                            Authorization:
                                "Bearer" + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        this.data_phone = res.data.data;
                        this.is_customer = true;
                    })
                    .catch((err) => {
                        console.log(err);
                        this.is_customer = false;
                        this.$notify({
                            group: "foo",
                            type: "warn",
                            title: "Warning",
                            text: "Anda belum menjadi customer, silahkan input nama anda untuk melanjutkan.",
                            // text: err.response.data.message
                        });
                    });
            }
        },
        addReceiver() {
            this.receivers.push({
                receiver_name: "",
                phone_number: "",
                note: "",
                destination: "",
                destination_id: "",
                package_hash: "",
                hash: "",
                items: [],
                images: [],
                destination_address_temp: "",
                destination_address_caption: "Pilih kota/kabupaten/kecamatan",
                destination_address: {
                    district_id: "",
                    regency_id: "",
                    sub_district_id: "",
                },
                calculate_data: {
                    service: 0,
                    total_amount: 0,
                    additional_price: 0,
                    handling: 0,
                    insurance_price_total: 0,
                },
            });
        },
        deleteRereceiver(index) {
            this.receivers.splice(index, 1);
            this.getCalculatePrice();
        },
        addItems() {
            if (this.item.name == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Kategori barang tidak boleh kosong",
                });
                return true;
            }
            if (this.item.weight == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Berat barang tidak boleh kosong",
                });

                return true;
            }
            if (this.item.qty == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Jumlah paket tidak boleh kosong",
                });
                return true;
            }
            if (this.item.length == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Panjang tidak boleh kosong",
                });
                return true;
            }
            if (this.item.width == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Lebar barang tidak boleh kosong",
                });
                return true;
            }
            if (this.item.height == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Tinggi barang tidak boleh kosong",
                });
                return true;
            }
            if (this.item.height == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Tinggi barang tidak boleh kosong",
                });
                return true;
            }
            if (this.item.is_insured == null) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Pilih asuransi terlebih dahulu",
                });
                return true;
            }
            if (
                this.item.is_insured != null &&
                this.item.is_insured != false &&
                this.item.price < 10000
            ) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Asuransi tidak boleh kurang dari Rp.10.000",
                });
                return true;
            }

            this.receivers[this.receiver_index].items.push({
                name: this.item.name,
                desc: this.item.desc,
                length: parseInt(this.item.length),
                width: parseInt(this.item.width),
                height: parseInt(this.item.height),
                weight: parseInt(this.item.weight),
                qty: parseInt(this.item.qty),
                price: parseInt(this.item.price),
                order_type: "other",
                handling: this.item.handling,
                is_insured: this.item.is_insured,
                protection: this.item.protection,
            });

            this.item.name = "";
            this.item.desc = "";
            this.item.length = "";
            this.item.width = "";
            this.item.height = "";
            this.item.weight = "";
            (this.item.qty = ""), (this.item.price = 0);
            this.item.order_type = "";
            this.item.handling = [];
            this.item.is_insured = false;
            this.item.is_glassware = false;
            this.item.protection = "";
            this.modalAddItems = false;
            this.getCalculatePrice();
        },
        deleteItems() {
            var receiver = this.receivers[this.delete_value_array];
            receiver.items.splice(this.delete_value, 1);
            this.deleteModal = false;
            this.getCalculatePrice();
        },
        getPartners() {
            Api.get(`${process.env.VUE_APP_BASE_URL}/partner/corporate`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    Accept: "application/json",
                },
            })
                .then((res) => {
                    this.partners = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getCalculatePrice() {
            this.receivers.forEach((value, index) => {
                if (value.package_hash != "") {
                    var data1 = {
                        package_hash: value.package_hash,
                        destination_id: value.destination_id,
                        items: value.items,
                    };
                    Api.post(
                        `${process.env.VUE_APP_BASE_URL}/trawlpack/dashboard/order/price/estimation`,
                        data1,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                        .then((res) => {
                            this.receivers[index].calculate_data =
                                res.data.data;
                            this.getCalculatePriceTotal();
                        })
                        .catch(function (error) {
                            console.error(error);
                        });
                } else {
                    var data2 = {
                        package_hash: this.$route.params.id,
                        destination_id: value.destination_id,
                        items: value.items,
                    };
                    Api.post(
                        `${process.env.VUE_APP_BASE_URL}/trawlpack/dashboard/order/price/estimation`,
                        data2,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                        .then((res) => {
                            this.receivers[index].calculate_data =
                                res.data.data;
                            this.getCalculatePriceTotal();
                        })
                        .catch(function (error) {
                            console.error(error);
                        });
                }
            });
        },
        getCalculatePriceTotal() {
            var data = {
                orders: this.receivers,
            };
            Api.post(
                `${process.env.VUE_APP_BASE_URL}/trawlpack/dashboard/order/price/estimation/total`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.all_total_payment = res.data.data;
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
        calcultaeOrder() {
            let total_amount = 0;
            let additional_price = 0;
            let insurance_price_total = 0;
            let handling = 0;
            let service = 0;
            this.receivers.forEach((val) => {
                total_amount += Number(val.calculate_data.total_amount);
                additional_price += Number(val.calculate_data.additional_price);
                insurance_price_total += Number(
                    val.calculate_data.insurance_price_total
                );
                handling += Number(val.calculate_data.handling);
                service += Number(val.calculate_data.service);
            });

            this.total_amount = total_amount;
            this.additional_price = additional_price;
            this.insurance_price_total = insurance_price_total;
            this.handling_count = handling;
            this.service_count = service;
        },
        addNewImage: function (receiver_index) {
            console.log(receiver_index);
            var n = this.maxImages || -1;
            if (n && this.receivers[receiver_index].images.length < n) {
                this.receivers[receiver_index].images.push("");
            }
            this.checkImages();
        },
        removeImage: function (receiver_index, index) {
            this.receivers[receiver_index].images.splice(index, 1);
            this.checkImages();
        },
        checkImages: function () {
            var n = this.maxImages || -1;
            if (n && this.receivers[this.receiver_index].images.length >= n) {
                $(this.addImage, this.el).prop("disabled", true);
            } else {
                $(this.addImage, this.el).prop("disabled", false);
            }
        },
        previewImage: function (index_item, index, e) {
            var r = new FileReader(),
                f = e.target.files[0];

            r.addEventListener(
                "load",
                function () {
                    $(
                        '[class~="images[' + index + index_item + ']-preview"]',
                        this.el
                    ).html(
                        '<div class="image-preview" style="background-image: url(' +
                            r.result +
                            ')"></div>'
                    );
                },
                false
            );

            if (f) {
                r.readAsDataURL(f);
            }

            if (this.receivers[index]) {
                this.receivers[index].images[index_item] = {
                    id: null,
                    uri: f,
                };
            } else {
                this.receivers[index].images.push({
                    id: null,
                    uri: f,
                });
            }
        },
        createOrder() {
            this.receivers.forEach((receiver) => {
                if (receiver.hash != "") {
                    if (receiver.receiver_name == "") {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Nama penerima tidak boleh kosong",
                        });
                        return;
                    }
                    if (receiver.phone_number == "") {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Nomor telepon penerima tidak boleh kosong",
                        });
                        return;
                    }
                    if (receiver.destination_address.district_id == "") {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Kota/Kabupaten/Kecamatan tidak boleh kosong",
                        });
                        return;
                    }
                    if (receiver.receiver_address == "") {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Catatan tidak boleh kosong",
                        });
                        return;
                    }
                    if (receiver.items.length == 0) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Tambahka barang terlebih dahulu",
                        });
                        return;
                    }

                    var data = new FormData();
                    receiver.images.forEach((image) => {
                        if (image.id == null) {
                            data.append("photos[]", image.uri);
                        }
                    });

                    data.append("order_type", receiver.order_type);
                    data.append("receiver_name", receiver.receiver_name);
                    data.append("receiver_phone", receiver.receiver_phone);
                    data.append("receiver_address", receiver.receiver_address);
                    data.append(
                        "receiver_way_point",
                        receiver.receiver_detail_address
                    );
                    data.append(
                        "destination_regency_id",
                        receiver.destination_address.regency_id
                    );
                    data.append(
                        "destination_district_id",
                        receiver.destination_address.district_id
                    );
                    data.append(
                        "destination_sub_district_id",
                        receiver.destination_address.sub_district_id
                    );
                    data.append("items", JSON.stringify(receiver.items));

                    this.is_loading = true;

                    Api.post(
                        `${process.env.VUE_APP_BASE_URL}/trawlpack/dashboard/order/${receiver.package_hash}/update`,
                        data,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    )

                        .then((res) => {
                            this.is_loading = false;
                            window.location.href =
                                "/trawlpack/partner/incoming-order";
                            console.log(res);
                        })
                        .catch((err) => {
                            this.is_loading = false;
                            return err;
                        });
                } else {
                    if (receiver.receiver_name == "") {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Nama penerima tidak boleh kosong",
                        });
                        return;
                    }
                    if (receiver.receiver_phone == "") {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Nomor telepon penerima tidak boleh kosong",
                        });
                        return;
                    }
                    if (receiver.destination_address.district_id == "") {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Kota/Kabupaten/Kecamatan tidak boleh kosong",
                        });
                        return;
                    }
                    if (receiver.receiver_address == "") {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Catatan tidak boleh kosong",
                        });
                        return;
                    }
                    if (receiver.items.length == 0) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Tambahka barang terlebih dahulu",
                        });
                        return;
                    }

                    var dataCreate = new FormData();
                    receiver.images.forEach((image) => {
                        if (image.id == null) {
                            data.append("photos[]", image.uri);
                        }
                    });

                    dataCreate.append(
                        "package_parent_hash",
                        this.detailOrder.package_hash
                    );
                    dataCreate.append("receiver_name", receiver.receiver_name);
                    dataCreate.append(
                        "receiver_phone",
                        receiver.receiver_phone
                    );
                    dataCreate.append(
                        "receiver_address",
                        receiver.receiver_address
                    );
                    dataCreate.append(
                        "receiver_way_point",
                        this.detailOrder.receiver_detail_address
                    );
                    dataCreate.append(
                        "destination_regency_id",
                        receiver.destination_address.regency_id
                    );
                    dataCreate.append(
                        "destination_district_id",
                        receiver.destination_address.district_id
                    );
                    dataCreate.append(
                        "destination_sub_district_id",
                        receiver.destination_address.sub_district_id
                    );
                    dataCreate.append("items", JSON.stringify(receiver.items));

                    this.is_loading = true;

                    Api.post(
                        `${process.env.VUE_APP_BASE_URL}/trawlpack/dashboard/order/create`,
                        dataCreate,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    )

                        .then((res) => {
                            console.log(res);
                            this.hash = [res.data.data.hash];
                            this.is_loading = false;
                            this.createUpdateOrder();
                            window.location.href =
                                "/trawlpack/partner/incoming-order";
                        })
                        .catch((err) => {
                            this.is_loading = false;
                            return err;
                        });
                }
            });
        },
        createUpdateOrder() {
            var data = {
                package_parent_hash: this.detailOrder.package_hash,
                package_child_hash: this.hash,
            };
            Api.post(
                `${process.env.VUE_APP_BASE_URL}/trawlpack/dashboard/order/create/multi-destination`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
        getPaymentList() {
            if (
                this.req.is_payment_method == "cash" ||
                this.req.is_payment_method == "cash"
            ) {
                return;
            }
            if (this.child_count == 0) {
                Api.get(
                    `${process.env.VUE_APP_BASE_URL}/partner/corporate/order/payment?package_id=${this.package_id}`,
                    {
                        headers: {
                            Authorization:
                                "Bearer" + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        this.payment_lists = res.data.data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                Api.get(
                    `${process.env.VUE_APP_BASE_URL}/partner/corporate/order/payment?package_id=${this.child_id[0]}`,
                    {
                        headers: {
                            Authorization:
                                "Bearer" + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        this.payment_lists = res.data.data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        createPayment() {
            if (this.child_count == 0) {
                var data = {
                    package_id: this.package_id,
                    payment_channel: this.req.is_bank,
                };
                this.is_payment_loading = true;
                Api.post(
                    `${process.env.VUE_APP_BASE_URL}/partner/corporate/order/payment`,
                    data,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        this.is_payment_loading = false;
                        this.payment_result = res.data.data;
                        window.location.href = `/corporate/order-detail/${this.package_id}`;
                    })
                    .catch((err) => {
                        this.is_payment_loading = false;
                        console.log(err);
                    });
            } else {
                var data1 = {
                    package_id: this.child_id[0],
                    payment_channel: this.req.is_bank,
                };
                this.is_payment_loading = true;
                Api.post(
                    `${process.env.VUE_APP_BASE_URL}/partner/corporate/order/payment`,
                    data1,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        this.is_payment_loading = false;
                        this.payment_result = res.data.data;
                        window.location.href = `/corporate/order-detail/${this.child_id[0]}`;
                    })
                    .catch((err) => {
                        this.is_payment_loading = false;
                        console.log(err);
                    });
            }
        },
        getOrderDetail() {
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/trawlpack/dashboard/order/detail/${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detailOrder = data;
                    this.receivers = data.package_child.map((val) => {
                        val.destination_address.label =
                            val.destination_address.province +
                            ", " +
                            val.destination_address.regency +
                            ", " +
                            val.destination_address.sub_district;
                        val.destination_id =
                            val.destination_address.sub_district_id;
                        this.package_id = val.package_hash;
                        return val;
                    });

                    this.sender.phone_number = data.sender_phone;
                    this.sender.sender_name = data.sender_name;
                    this.data_phone.name = data.sender_name;
                    this.sender.note = data.sender_address;
                    this.senderValuePlaceholder =
                        data.origin_address.province +
                        ", " +
                        data.origin_address.regency;

                    this.calcultaeOrder();
                    this.getCalculatePrice();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        changeCategory() {
            this.item.category_item_id = this.categoryItem.id;
            this.item.name = this.categoryItem.name;
        },
        changeEditCategory() {
            this.edit_item.category_item_id = this.editCategoryItem.id;
            this.edit_item.name = this.editCategoryItem.name;
        },
    },
};
</script>

<style scoped>
.upload__inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #f8f9fa;
    border-style: dashed;
    border-radius: 10px;
    width: 100%;
    height: 130px;
    padding: 40px;
    position: relative;
}
.no-border {
    border: unset;
}
.upload__btn p {
    font-size: 30px;
}
.upload__img-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.upload__img-box {
    width: 200px;
    padding: 0 10px;
    margin-bottom: 12px;
}
.upload__img-close {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: center;
    line-height: 24px;
    z-index: 1;
    cursor: pointer;
}
.upload__img-close:after {
    content: "✖";
    font-size: 14px;
    color: white;
}

.img-bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    padding-bottom: 100%;
}
.image-preview-order {
    width: 100%;
    height: 130px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    position: relative;
}
.close-icon-order {
    position: absolute;
    right: 8px;
    top: 8px;
    color: #e60013;
    font-size: 14px;
    padding: 2px;
    background: #fff;
    border-radius: 50px;
    text-align: center;
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 9;
}
.btn-outline-secondary {
    color: #fff !important;
    border-color: #e60013 !important;
    background: #e60013 !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
}
.btn-outline-secondary:hover {
    color: #fff !important;
    border-color: #e60013 !important;
    background: #e60013 !important;
}
.scroll {
    height: 400px;
    overflow-y: scroll;
}
.ui.disabled.dropdown,
.ui.dropdown .menu > .disabled.item {
    opacity: 1;
    background: #e9ecef;
    border: 1px solid #e9ecef;
}
.number-point {
    background: #016703;
    width: 40px;
    height: 40px;
    position: absolute;
    left: -14px;
    top: -16px;
    text-align: center;
    border-radius: 50px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    padding: 4px;
}
</style>
