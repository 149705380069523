<template>
    <div class="padding-container">
        <div class="d-flex align-items-center bd-highlight">
            <div class="d-flex align-items-center">
                <div class="me-3">
                    <a href="/trawlcarrier/ho/driver" class="text-primary">
                        <i class="fa fa-angle-left size-24 fw-bold"></i>
                    </a>
                </div>
                <h3 class="fw-bold mb-0">
                    Riwayat Driver
                </h3>
            </div>
        </div>
        <div class="box mt-4">
            <div class="mt-4" v-if="is_list_ready">
                <CardLoader/>
            </div>
            <div class="row" v-else>
                <div class="col-md-4">
                    <div class="box no-shadow border-gray bc-gray">
                        <h5 class="fw-bold mb-0 text-gray">
                            Nama Driver
                        </h5>
                        <div class="fw-ekstra-bold size-24 mt-3">
                            {{ list.dashboard.nama_driver }}
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box no-shadow border-gray bc-gray">
                        <h5 class="fw-bold mb-0 text-gray">
                            Mitra Driver
                        </h5>
                        <div class="fw-ekstra-bold size-24 mt-3">
                            {{ list.dashboard.mitra }}
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box no-shadow border-gray bc-gray">
                        <h5 class="fw-bold mb-0 text-gray">
                            Kontak Driver
                        </h5>
                        <div class="fw-ekstra-bold size-24 mt-3">
                            {{ list.dashboard.contact }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader/>
            </div>
            <div v-else>
                <div class="row mt-4">
                    <div class="col-md-3 mt-3">
                        <input type="date" class="form-control" placeholder="Start date" v-model="req.start" @change="getLists()" />
                    </div>
                    <div class="col-md-3 mt-3">
                        <input type="date" class="form-control" placeholder="End date" v-model="req.end" @change="getLists()" />
                    </div>
                </div>

                <div class="modern-table-boxed mt-4" v-if="list.list_data.length > 0">
                    <div class="d-table-row header">
                        <div class="d-table-cell">No</div>
                        <div class="d-table-cell">Lokasi Asal</div>
                        <div class="d-table-cell">Lokasi Tujuan</div>
                        <div class="d-table-cell">Manifest</div>
                        <div class="d-table-cell">Tanggal Penugasan</div>
                        <div class="d-table-cell">Status permintaan</div>
                    </div>
                    <template v-for="(list, index) in list.list_data">
                        <div class="d-table-row" :key="index">
                            <div class="d-table-cell fw-bold">
                                {{ index+1 }}.
                            </div>
                            <div class="d-table-cell">
                                {{ list.lokasi_asal }}
                            </div>
                            <div class="d-table-cell">
                                {{ list.lokasi_tujuan }}
                            </div>
                            <div class="d-table-cell">
                                {{ list.manifest }} - <span class="capitalize">{{ list.type }}</span>
                            </div>
                            <div class="d-table-cell">
                                {{ moment(list.tanggal_penugasan).format("DD MMMM YYYY") }}
                            </div>
                            <div class="d-table-cell capitalize">
                                {{ list.status }}
                            </div>
                        </div>
                    </template>
                </div>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="">
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <b-pagination-nav v-if="list.list_data.length > 0" :link-gen="linkGen" v-model="currentPage" :number-of-pages="list.total_page" base-url="#" first-number align="right"></b-pagination-nav>
        </div>
    </div>
</template>

<script>

import Api from "../../api/Api";
import ListLoader from '../../components/ListLoader.vue';
import CardLoader from '../../components/CardLoader.vue';
import moment from 'moment'

export default {
    components: {
        ListLoader,
        CardLoader
    },
    name: "DriverHistory",
    data() {
        return {
            moment:moment,
            req: {
                keyword: '',
                start: '',
                end: ''
            },
            is_list_ready: false,
            list: {
                list_data: [],
                dashboard: {},
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0
            },
            per_page: 10,
            currentPage: 0,
        };
    },
    created() {
        this.getLists()
    },
    mounted() {
    },
    methods: {
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        getLists() {
            this.is_list_ready = true
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/carrier/ho/driver/history/${this.$route.params.id}?start_date=${this.req.start}&end_date=${this.req.end}`, {
                params: { per_page: this.per_page, page: this.$route.query.page},
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                var data = res.data.data
                this.list = data
                this.is_list_ready = false
            })
            .catch((err) => {
                console.log(err);
                this.is_list_ready = false
            });
        },
        currencyFormat(num) {
            return (
                "Rp. " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
    },
};
</script>

<style>
</style>