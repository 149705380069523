<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/crm/tags" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold capitalize">
                    {{ $route.query.tag_name }} Detail
                </h3>
            </div>
            <div class="d-flex mt-4">
                <div>
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Search"
                            v-model="req.search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="ms-4 me-3">
                    <a
                        href="javascript:void(0)"
                        @click="leadsModal = true"
                        class="btn btn-primary btn-with-search px-4"
                    >
                        <img
                            src="../../assets/dashboard/plus.png"
                            class="vertical-align-unset me-1"
                            width="10"
                            alt=""
                        />
                        Add Leads
                    </a>
                </div>
                <div class="me-3">
                    <a
                        href="javascript:void(0)"
                        @click="registeredModal = true"
                        class="btn btn-yellow btn-with-search px-4"
                    >
                        <img
                            src="../../assets/dashboard/plus.png"
                            class="vertical-align-unset me-1"
                            width="10"
                            alt=""
                        />
                        Add Registered
                    </a>
                </div>
                <div>
                    <a
                        href="javascript:void(0)"
                        @click="orderedModal = true"
                        class="btn btn-blue btn-with-search px-4"
                    >
                        <img
                            src="../../assets/dashboard/plus.png"
                            class="vertical-align-unset me-1"
                            width="10"
                            alt=""
                        />
                        Add Ordered
                    </a>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <div class="mt-4" v-if="is_list_ready">
                        <ListLoader />
                    </div>
                    <template v-else>
                        <template v-if="list.list_data.length > 0">
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr class="custom">
                                            <th
                                                class="text-center custom"
                                                style="width: 40px"
                                                scope="col"
                                            >
                                                No
                                            </th>
                                            <th
                                                class="text-center custom"
                                                scope="col"
                                            >
                                                Full Name
                                            </th>
                                            <th
                                                class="text-center custom"
                                                style="width: 170px"
                                                scope="col"
                                            >
                                                Phone
                                            </th>
                                            <th
                                                class="text-center custom"
                                                style="width: 250px"
                                                scope="col"
                                            >
                                                Customers Type
                                            </th>
                                            <th
                                                class="text-center custom"
                                                style="width: 40px"
                                                scope="col"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            class="custom"
                                            v-for="(
                                                item, index
                                            ) in list.list_data"
                                            :key="index"
                                        >
                                            <td class="text-center custom">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="custom">
                                                {{
                                                    item.name ? item.name : "-"
                                                }}
                                            </td>
                                            <td
                                                class="capitalize text-center custom"
                                            >
                                                {{
                                                    item.phone
                                                        ? item.phone
                                                        : "-"
                                                }}
                                            </td>
                                            <td
                                                class="text-center capitalize custom"
                                            >
                                                {{
                                                    item.type ? item.type : "-"
                                                }}
                                            </td>
                                            <td class="text-center custom">
                                                <a
                                                    href="javascript:void(0)"
                                                    @click="
                                                        openModalDelete(
                                                            item.id,
                                                            item.type
                                                        )
                                                    "
                                                >
                                                    <img
                                                        src="../../assets/dashboard/trash-icon.png"
                                                        alt=""
                                                        width="18"
                                                    />
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-center mt-section">
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </template>
                    </template>
                    <div class="mt-4">
                        <b-pagination-nav
                            v-if="list.list_data.length > 0"
                            v-model="currentPage"
                            :number-of-pages="list.total_page"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="showModalDelete"></div>
            <div class="modal-body-chat vsm" v-if="showModalDelete">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/trash.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Penghapusan Data?</h4>
                </div>
                <div class="mt-4 row">
                    <div class="col-md-6">
                        <a
                            href="javascript:void(0)"
                            @click="showModalDelete = false"
                            class="btn btn-primary w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6">
                        <button
                            class="btn btn-outline-primary w-100 btn-lg"
                            type="button"
                            disabled
                            v-if="is_delete"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Ya
                        </button>
                        <button
                            @click="deleteList()"
                            class="btn btn-outline-primary w-100 btn-lg"
                            v-else
                        >
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Congratulation</h4>
                    <div class="mt-2">Your Tags is Added!</div>
                </div>
                <div class="mt-4">
                    <a
                        href="javscript:void(0)"
                        @click="changeListCustomer()"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div
                class="overlay"
                v-if="leadsModal"
                @click="leadsModal = false"
            ></div>
            <div class="modal-body-chat md" v-if="leadsModal">
                <div class="mb-3">
                    <h3 class="fw-bold">Leads Data</h3>
                </div>
                <div class="position-relative mt-4">
                    <input
                        class="form-control form-control-lg pl-search w-100"
                        v-model="req.search_leads"
                        @input="changeSearchLeads()"
                        type="text"
                        placeholder="Search Leads"
                    />
                    <span class="search-icon left">
                        <i class="fa fa-search fa-beat fa-sm"></i>
                    </span>
                </div>
                <template v-if="leads.length > 0">
                    <div
                        :class="{ '': true, scroll: leads.length > 5 }"
                        class="mb-2 mt-4"
                    >
                        <div
                            v-for="(data, index) in leads"
                            :key="index"
                            class="checkbox-option fw-medium capitalize"
                        >
                            <label :for="data.id">
                                <input
                                    type="checkbox"
                                    :id="data.id"
                                    :value="data"
                                    v-model="req.customers"
                                />
                                <span class="text mt-1">
                                    <table>
                                        <tr class="p-0">
                                            <td class="p-0" style="width: 70px">
                                                Name
                                            </td>
                                            <td class="p-0" style="width: 20px">
                                                :
                                            </td>
                                            <td class="p-0">
                                                {{
                                                    data.name ? data.name : "-"
                                                }}
                                            </td>
                                        </tr>
                                        <tr class="p-0">
                                            <td class="p-0" style="width: 70px">
                                                Phone
                                            </td>
                                            <td class="p-0" style="width: 20px">
                                                :
                                            </td>
                                            <td class="p-0">
                                                {{
                                                    data.phone
                                                        ? data.phone
                                                        : "-"
                                                }}
                                            </td>
                                        </tr>
                                    </table>
                                </span>
                            </label>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-5">
                        <img src="../../assets/no-data.png" width="80" alt="" />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            No Result
                        </h4>
                    </div>
                </template>
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <button
                            class="btn btn-outline-black w-100 btn-lg"
                            @click="leadsModal = false"
                        >
                            Cancel
                        </button>
                    </div>
                    <div class="col-md-6 mt-3">
                        <button
                            class="btn btn-green w-100 btn-lg"
                            type="button"
                            disabled
                            v-if="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Submit
                        </button>
                        <button
                            v-else
                            class="btn btn-green w-100 btn-lg"
                            @click="saveTags()"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div
                class="overlay"
                v-if="registeredModal"
                @click="registeredModal = false"
            ></div>
            <div class="modal-body-chat md" v-if="registeredModal">
                <div class="mb-3">
                    <h3 class="fw-bold">Registered Data</h3>
                </div>
                <div class="position-relative mt-4">
                    <input
                        class="form-control form-control-lg pl-search w-100"
                        v-model="req.search_registered"
                        @input="changeSearchRegistered()"
                        type="text"
                        placeholder="Search Registered"
                    />
                    <span class="search-icon left">
                        <i class="fa fa-search fa-beat fa-sm"></i>
                    </span>
                </div>
                <template v-if="registered.length > 0">
                    <div
                        :class="{ '': true, scroll: registered.length > 5 }"
                        class="mb-2 mt-4"
                    >
                        <div
                            v-for="(data, index) in registered"
                            :key="index"
                            class="checkbox-option fw-medium capitalize"
                        >
                            <label :for="data.id">
                                <input
                                    type="checkbox"
                                    :id="data.id"
                                    :value="data"
                                    v-model="req.customers"
                                />
                                <span class="text mt-1">
                                    <table>
                                        <tr class="p-0">
                                            <td class="p-0" style="width: 70px">
                                                Name
                                            </td>
                                            <td class="p-0" style="width: 20px">
                                                :
                                            </td>
                                            <td class="p-0">
                                                {{
                                                    data.name ? data.name : "-"
                                                }}
                                            </td>
                                        </tr>
                                        <tr class="p-0">
                                            <td class="p-0" style="width: 70px">
                                                Phone
                                            </td>
                                            <td class="p-0" style="width: 20px">
                                                :
                                            </td>
                                            <td class="p-0">
                                                {{
                                                    data.phone
                                                        ? data.phone
                                                        : "-"
                                                }}
                                            </td>
                                        </tr>
                                    </table>
                                </span>
                            </label>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-5">
                        <img src="../../assets/no-data.png" width="80" alt="" />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            No Result
                        </h4>
                    </div>
                </template>
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <button
                            class="btn btn-outline-black w-100 btn-lg"
                            @click="registeredModal = false"
                        >
                            Cancel
                        </button>
                    </div>
                    <div class="col-md-6 mt-3">
                        <button
                            class="btn btn-green w-100 btn-lg"
                            type="button"
                            disabled
                            v-if="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Submit
                        </button>
                        <button
                            v-else
                            class="btn btn-green w-100 btn-lg"
                            @click="saveTags()"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div
                class="overlay"
                v-if="orderedModal"
                @click="orderedModal = false"
            ></div>
            <div class="modal-body-chat md" v-if="orderedModal">
                <div class="mb-3">
                    <h3 class="fw-bold">Ordered Data</h3>
                </div>
                <div class="position-relative mt-4">
                    <input
                        class="form-control form-control-lg pl-search w-100"
                        v-model="req.search_ordered"
                        @input="changeSearchOrdered()"
                        type="text"
                        placeholder="Search Ordered"
                    />
                    <span class="search-icon left">
                        <i class="fa fa-search fa-beat fa-sm"></i>
                    </span>
                </div>
                <template v-if="ordered.length > 0">
                    <div
                        :class="{ '': true, scroll: ordered.length > 5 }"
                        class="mb-2 mt-4"
                    >
                        <div
                            v-for="(data, index) in ordered"
                            :key="index"
                            class="checkbox-option fw-medium capitalize"
                        >
                            <label :for="data.id">
                                <input
                                    type="checkbox"
                                    :id="data.id"
                                    :value="data"
                                    v-model="req.customers"
                                />
                                <span class="text mt-1">
                                    <table>
                                        <tr class="p-0">
                                            <td class="p-0" style="width: 70px">
                                                Name
                                            </td>
                                            <td class="p-0" style="width: 20px">
                                                :
                                            </td>
                                            <td class="p-0">
                                                {{
                                                    data.name ? data.name : "-"
                                                }}
                                            </td>
                                        </tr>
                                        <tr class="p-0">
                                            <td class="p-0" style="width: 70px">
                                                Phone
                                            </td>
                                            <td class="p-0" style="width: 20px">
                                                :
                                            </td>
                                            <td class="p-0">
                                                {{
                                                    data.phone
                                                        ? data.phone
                                                        : "-"
                                                }}
                                            </td>
                                        </tr>
                                    </table>
                                </span>
                            </label>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-5">
                        <img src="../../assets/no-data.png" width="80" alt="" />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            No Result
                        </h4>
                    </div>
                </template>
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <button
                            class="btn btn-outline-black w-100 btn-lg"
                            @click="orderedModal = false"
                        >
                            Cancel
                        </button>
                    </div>
                    <div class="col-md-6 mt-3">
                        <button
                            class="btn btn-green w-100 btn-lg"
                            type="button"
                            disabled
                            v-if="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Submit
                        </button>
                        <button
                            v-else
                            class="btn btn-green w-100 btn-lg"
                            @click="saveTags()"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";

export default {
    components: {
        ListLoader,
    },
    name: "Tags",
    data() {
        return {
            moment: moment,
            req: {
                search: "",
                search_distribute: "",
                search_leads: "",
                search_registered: "",
                search_ordered: "",
                customers: [],
            },
            is_list_ready: false,
            per_page: 10,
            currentPage: 0,
            range: "",
            isFilter: false,
            checked: [],
            id: null,
            showModalDelete: false,
            is_delete: false,
            distribute: [],
            specialist_id: null,
            is_distribute: false,
            modalSuccess: false,
            detail: {},
            list: {},
            customer_id: null,
            type: "",
            tag_id: null,
            leadsModal: false,
            per_page_leads: 10,
            currentPageLeads: 0,
            leads: [],
            is_save: false,
            registeredModal: false,
            per_page_registered: 10,
            currentPageRegistered: 0,
            registered: [],
            per_page_ordered: 10,
            currentPageOrdered: 0,
            ordered: [],
            orderedModal: false,
        };
    },
    created() {
        this.getDetailTags();
        this.getListCustomers();
        this.getListLeads();
        this.getListRegistered();
        this.getListOrdered();
    },
    methods: {
        changeListCustomer() {
            this.getListCustomers();
            this.modalSuccess = false;
            this.req.customers = [];
        },
        changePage() {
            this.getListCustomers();
        },
        changeSearch() {
            this.currentPage = 0;
            this.getListCustomers();
        },
        decimalFormat(num) {
            return (
                "" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        openModalDelete(id, type) {
            this.showModalDelete = true;
            this.tag_id = this.$route.params.id;
            this.customer_id = id;
            this.type = type;
        },
        deleteList() {
            this.is_delete = true;
            var data = {
                tag_id: parseInt(this.tag_id),
                customer_id: parseInt(this.customer_id),
                type: this.type,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/tags/customer/delete`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_delete = false;
                    this.showModalDelete = false;
                    this.getListCustomers();
                    console.log(res);
                })
                .catch((err) => {
                    this.is_delete = false;
                    this.showModalDelete = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.data.message,
                    });
                });
        },
        getDetailTags() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/tags?id=${this.$route.params.id}`,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detail = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getListCustomers() {
            let payload = {
                search: this.req.search,
                limit: this.per_page,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/tag/customer?id=${this.$route.params.id}`,
                {
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        getListLeads() {
            let data = {
                search: this.req.search_leads,
                limit: this.per_page_leads,
                page:
                    this.currentPageLeads == 0 || this.currentPageLeads == null
                        ? 0
                        : this.currentPageLeads - 0,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/customer/leads`, {
                params: data,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data.list_data.map(function (key) {
                        key.type = "leads";
                        return key;
                    });
                    this.leads = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        changeSearchLeads() {
            this.getListLeads();
        },
        getListRegistered() {
            let data = {
                type: "registered",
                search: this.req.search_registered,
                limit: this.per_page_registered,
                page:
                    this.currentPageRegistered == 0 ||
                    this.currentPageRegistered == null
                        ? 0
                        : this.currentPageRegistered - 1,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/customer/orders`, {
                params: data,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data.list_data.map(function (key) {
                        key.type = "registered";
                        return key;
                    });
                    this.registered = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        changeSearchRegistered() {
            this.getListRegistered();
        },
        getListOrdered() {
            let data = {
                type: "ordered",
                search: this.req.search_ordered,
                limit: this.per_page_ordered,
                page:
                    this.currentPageOrdered == 0 ||
                    this.currentPageOrdered == null
                        ? 0
                        : this.currentPageOrdered - 1,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/customer/orders`, {
                params: data,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data.list_data.map(function (key) {
                        key.type = "ordered";
                        return key;
                    });
                    this.ordered = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        changeSearchOrdered() {
            this.getListOrdered();
        },
        saveTags() {
            this.is_save = true;
            var data = {
                tag_id: this.$route.params.id,
                customers: this.req.customers,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/tags/customer/add`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    this.modalSuccess = true;
                    this.leadsModal = false;
                    this.registeredModal = false;
                    this.orderedModal = false;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    this.modalSuccess = false;
                    this.leadsModal = false;
                    this.registeredModal = false;
                    this.orderedModal = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
th.custom {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr.custom {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td.custom {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.text-red-dashboard {
    color: #e60013;
}
.close-icon {
    width: 25px;
    height: 25px;
    background: #e60013;
    color: #fff;
    border-radius: 50px;
    padding: 3px;
    text-align: center;
    font-size: 13px;
    position: absolute;
    right: -5px;
    top: -5px;
}
.checkbox-option label {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    cursor: pointer;
}
.checkbox-option label .text {
    font-size: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    border-bottom: 1px solid #f3f4f6;
    padding-bottom: 15px;
}
.checkbox-option label input {
    display: none;
}
.checkbox-option label input + .text:after {
    font-family: FontAwesome;
    content: "\f096";
    line-height: 1;
    display: inline-block;
    color: #d9d9d9;
    font-size: 20px;
}
.checkbox-option label input:checked + .text:after {
    font-family: FontAwesome;
    content: "\f046";
    color: #3d8824;
    font-size: 20px;
}
.vertical-align-unset {
    vertical-align: unset;
}
</style>
