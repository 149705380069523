<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="/trawltruck/ho/partner"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Tambah Data Mitra</h3>
            </div>
            <div class="row mt-5">
                <div class="col-md-12">
                    <label class="fw-bold mb-2">Email</label>
                    <input
                        type="email"
                        class="form-control"
                        placeholder="Masukkan Alamat Email"
                        v-model="req.email"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Password</label>
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control"
                            id="password"
                            placeholder="Password"
                            v-model="req.password"
                            v-if="showPassword"
                        />
                        <input
                            type="password"
                            class="form-control"
                            id="password"
                            placeholder="Password"
                            v-model="req.password"
                            v-else
                        />
                        <span
                            class="search-icon cursor-pointer"
                            @click="toggleShow"
                        >
                            <i
                                class="fa"
                                :class="{
                                    'fa-eye': showPassword,
                                    'fa-eye-slash': !showPassword,
                                }"
                            ></i>
                        </span>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nama Mitra</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Nama Mitra"
                        v-model="req.name"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nomor Telepon</label>
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Masukkan  Nomor Telepon"
                        v-model="req.phone"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nama Penanggung Jawab</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Nama Penanggung Jawab"
                        v-model="req.owner_name"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2"
                        >Nomor Telepon Penanggung Jawab</label
                    >
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Masukkan  Nomor Telepon"
                        v-model="req.phone_partner"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nama Bank</label>
                    <select
                        class="form-control form-select"
                        v-model="req.bank_id"
                    >
                        <option value="">Pilih Bank</option>
                        <template v-for="(bank, index) in banks">
                            <option :value="bank.id" :key="index">
                                {{ bank.name }}
                            </option>
                        </template>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2"
                        >Nomor Rekening Penanggung Jawab
                    </label>
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Masukkan Nomor Rekening Penanggung Jawab"
                        v-model="req.bank_account"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Atas Nama </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Atas Nama"
                        v-model="req.bank_name"
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Alamat</label>
                    <textarea
                        rows="5"
                        cols="4"
                        class="form-control"
                        placeholder="Masukkan Alamat"
                        v-model="req.address"
                    ></textarea>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Status</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="active"
                                value="active"
                                v-model="req.status"
                            />
                            <label class="form-check-label fw-bold" for="active"
                                >Aktif</label
                            >
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="disable"
                                value="disable"
                                v-model="req.status"
                            />
                            <label
                                class="form-check-label fw-bold"
                                for="disable"
                                >Tidak Aktif</label
                            >
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold">Foto KTP Penanggung Jawab</label>
                    <div class="text-gray mb-2">Foto full KTP maks 5MB</div>
                    <div
                        class="image-view-1"
                        :style="{
                            'background-image': 'url(' + req.logo_picture + ')',
                        }"
                    >
                        <div class="mt-4 ml-4">
                            <label
                                for="logo_picture"
                                class="upload-button single-image"
                            >
                                <i class="fa fa-upload"></i>
                                <input
                                    type="file"
                                    id="logo_picture"
                                    @change="uploadKTP()"
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold">NIB Perusahaan</label>
                    <div class="text-gray mb-2">File PDF maks 5MB</div>
                    <div
                        class="image-view-2"
                        :style="{
                            'background-image':
                                'url(' + req.identity_picture + ')',
                        }"
                    >
                        <div class="mt-4 ml-4">
                            <label
                                for="identity_picture"
                                class="upload-button single-image"
                            >
                                <i class="fa fa-upload"></i>
                                <input
                                    type="file"
                                    id="identity_picture"
                                    @change="uploadNIB()"
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold">Berkas Compro (Optional)</label>
                    <div class="text-gray mb-2">File PDF maks 5MB</div>
                    <div
                        class="image-view-3"
                        :style="{
                            'background-image':
                                'url(' + req.business_picture + ')',
                        }"
                    >
                        <div class="mt-4 ml-4">
                            <label
                                for="business_picture"
                                class="upload-button single-image"
                            >
                                <i class="fa fa-upload"></i>
                                <input
                                    type="file"
                                    id="business_picture"
                                    @change="uploadCOMPRO()"
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <!-- <a href="/trawltruck/ho/partner" class="btn btn-outline-black btn-lg px-5 me-3">Batal</a> -->
                            <a
                                href="javascript:void(0)"
                                class="btn btn-outline-black btn-lg px-5 me-3 m-w-100"
                                @click="modalError = true"
                                >Batal</a
                            >
                            <button
                                class="btn btn-green btn-lg px-5 me-3 m-mt-1 m-w-100"
                                type="button"
                                disabled
                                v-if="is_save"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Simpan
                            </button>
                            <button
                                class="btn btn-green btn-lg px-5 me-3 m-mt-1 m-w-100"
                                @click="storeData()"
                                v-else
                            >
                                Simpan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data Saved!!</h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/trawltruck/ho/partner"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import Api from "../../api/Api";

export default {
    props: "",
    name: "ManagementMitraCreate",
    data() {
        return {
            showPassword: false,
            is_changing_logo_picture: false,
            is_changing_identity_picture: false,
            is_changing_business_picture: false,
            req: {
                // partner_id: '',
                name: "",
                email: "",
                phone: "",
                password: "",
                owner_name: "",
                bank_id: "",
                bank_account: "",
                bank_name: "",
                address: "",
                status: "active",
                logo_picture: "",
                identity_picture: "",
                business_picture: "",
            },
            modalSuccess: false,
            modalError: false,
            banks: [],
            is_save: false,
        };
    },
    created() {
        this.getBank();
    },
    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        uploadKTP() {
            var logo_picture = new FileReader();
            logo_picture.onload = function (e) {
                $(".image-view-1").css(
                    "background-image",
                    "url(" + e.target.result + ")"
                );
            };
            logo_picture.readAsDataURL($("#logo_picture")[0].files[0]);
            this.is_changing_logo_picture = true;
        },
        uploadNIB() {
            var identity_picture = new FileReader();
            identity_picture.onload = function (e) {
                $(".image-view-2").css(
                    "background-image",
                    "url(" + e.target.result + ")"
                );
            };
            identity_picture.readAsDataURL($("#identity_picture")[0].files[0]);
            this.is_changing_identity_picture = true;
        },
        uploadCOMPRO() {
            var business_picture = new FileReader();
            business_picture.onload = function (e) {
                $(".image-view-3").css(
                    "background-image",
                    "url(" + e.target.result + ")"
                );
            };
            business_picture.readAsDataURL($("#business_picture")[0].files[0]);
            this.is_changing_business_picture = true;
        },
        getBank() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partner/bank`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.banks = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        storeData() {
            var logo_picture = $("#logo_picture")[0].files[0];
            var identity_picture = $("#identity_picture")[0].files[0];
            var business_picture = $("#business_picture")[0].files[0];
            var data = new FormData();

            data.append("name", this.req.name);
            data.append("email", this.req.email);
            data.append("phone_partner", this.req.phone);
            data.append("password", this.req.password);
            data.append("owner_name", this.req.owner_name);
            data.append("phone", this.req.phone_partner);
            data.append("bank_id", this.req.bank_id);
            data.append("bank_account", this.req.bank_account);
            data.append("bank_name", this.req.bank_name);
            data.append("address", this.req.address);
            data.append("status", this.req.status);

            if (this.is_changing_logo_picture) {
                data.append("logo_picture", logo_picture);
            }
            if (this.is_changing_identity_picture) {
                data.append("identity_picture", identity_picture);
            }
            if (this.is_changing_business_picture) {
                data.append("business_picture", business_picture);
            }

            this.is_save = true;

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partner`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    this.modalSuccess = true;
                    this.is_changing_logo_picture = false;
                    this.is_changing_identity_picture = false;
                    this.is_changing_business_picture = false;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
</style>
