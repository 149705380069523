<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="/trawltruck/ho/delivery-payment"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4 pb-0">
            <div class="d-flex align-items-center mb-5">
                <h3 class="fw-bold mb-0">Detail Uang Jalan</h3>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-2 fw-bold">Nomor Resi</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.receipt }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-2 fw-bold">Nama Driver</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.driver.name }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-2 fw-bold">Nama Mitra</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.partner.name }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-2 fw-bold">Rute Sewa</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.route }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-2 fw-bold">Tanggal Sewa</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ moment(detail.beginpickup_at).format("DD MMMM YYYY") }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-2 fw-bold">Jenis Truk</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.fleet_type }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-2 fw-bold">Jenis Order</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    <template v-if="detail.order.type_order == 'quick'">
                        Cepat
                    </template>
                    <template v-if="detail.order.type_order == 'schedule'">
                        Terjadwal
                    </template>
                    <template v-if="detail.order.type_order == 'fullday'">
                        Seharian
                    </template>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-2 fw-bold">Jenis Layanan</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ detail.order.type_service }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-2 fw-bold">Harga Total Sewa</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{
                        currencyFormat(
                            detail.order.service_price +
                                detail.order.additional_price
                        )
                    }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-2 fw-bold">Margin Trobens</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ currencyFormat(detail.company_amount) }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-2 fw-bold">Total Dana</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{
                        currencyFormat(
                            detail.amount_hold + detail.transport_amount
                        )
                    }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-2 fw-bold">30% dari Total Dana</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    {{ currencyFormat(detail.transport_amount) }}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-2 fw-bold">Rekening MItra (Atas Nama)</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    <template v-if="detail.partner.bankaccount">
                        {{ detail.partner.bankaccount.account_number }} ({{
                            detail.partner.bankaccount.account_name
                        }})
                    </template>
                    <template v-else> - </template>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-2 fw-bold">Bukti Transfer</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    <div class="d-flex align-items-center">
                        <div>
                            <img
                                src="../../assets/dashboard/image.png"
                                width="30"
                                alt=""
                            />
                        </div>
                        <div class="ms-auto">
                            <a
                                :href="detail.photo_url"
                                target="_blank"
                                download
                                class="text-green"
                            >
                                Download Bukti Transfer
                                <i class="fa fa-download ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-2 fw-bold">Status</div>
                <div class="d-none d-lg-block col-md-1">:</div>
                <div class="col-md-9">
                    <template v-if="detail.status == 'success'">
                        <small class="tag green px-4">
                            {{ detail.status }}
                        </small>
                    </template>
                    <template v-else>
                        <small class="tag gray px-4">
                            {{ detail.status }}
                        </small>
                    </template>
                </div>
            </div>
            <hr />
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import { Tooltip } from "bootstrap";
import moment from "moment";

export default {
    props: "",
    name: "ManagementDeliveryPaymentDetail",
    data() {
        return {
            moment: moment,
            detail: {
                driver: {},
                partner: {
                    bankaccount: {},
                },
                order: {},
            },
        };
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    created() {
        this.getDetail();
    },
    methods: {
        currencyFormat(num) {
            return (
                "Rp. " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getDetail() {
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/transport/detail?id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detail = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
.foto-box {
    width: 100px;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    position: relative;
}
.nav-link.dashboard.center {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.active {
    background-color: #e60013 !important;
}
.w-33 {
    width: 33.33%;
}
</style>
