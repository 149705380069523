<template>
    <div class="padding-container">
        <div class="d-flex align-items-center">
            <h3 class="mb-0 fw-bold">Dashboard TroTruck</h3>
            <!-- <div class="ms-auto">
        <div class="position-relative">
          <input class="form-control" v-model="filter_date" type="text" onfocus="(this.type='date')" @change="getData" style="border: 1px solid #48a2d4" placeholder="Pilih Tanggal" />
          <span class="search-icon">
            <img src="../../assets/calendar-icon.png" />
          </span>
        </div>
      </div> -->
        </div>
        <div class="box mt-4">
            <div class="d-flex justify-content-between">
                <img src="../../assets/chart-icon.png" />
                <div>{{ dateFormat(filter_date) }}</div>
            </div>
            <div class="d-flex mt-4" style="gap: 10%">
                <div class="d-flex flex-column">
                    <div class="text-gray">Total Saldo</div>
                    <div class="fw-bold size-16">
                        {{ currency(data_dashboard.balance) }}
                        <span style="color: #48a2d4"
                            >({{ data_dashboard.order_count }} Transaksi)</span
                        >
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <div class="text-gray">
                        Pendapatan Bulan {{ thisMonth() }}
                    </div>
                    <div class="fw-bold size-16">
                        {{ currency(data_dashboard.income_this_month) }}
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <div class="text-gray">Pendapatan Bulan Sebelumnya</div>
                    <div class="fw-bold size-16">
                        {{ currency(data_dashboard.income_last_month) }}
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <div class="text-gray">Peningkatan</div>
                    <div
                        class="fw-bold size-16"
                        :class="
                            data_dashboard.income_this_month >
                            data_dashboard.income_last_month
                                ? 'text-green'
                                : 'text-danger'
                        "
                    >
                        {{
                            currency(
                                data_dashboard.income_this_month -
                                    data_dashboard.income_last_month
                            )
                        }}
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-container mt-4">
            <div class="box item-1">
                <div class="d-flex align-items-center">
                    <div class="mb-0 fw-bold size-18 capitalize">
                        Perbandingan
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <apexcharts
                        ref="donutweekly"
                        width="300px"
                        type="donut"
                        :options="donutOptions"
                        :series="donutSeries"
                    ></apexcharts>
                </div>
            </div>
            <div class="box item-2" style="background-color: #48a2d4">
                <img src="../../assets/white-truck-icon.png" /><span
                    class="fw-bold size-16 text-white ms-2"
                    >Total Truk</span
                >
                <div class="d-flex mt-4" style="gap: 40%">
                    <div class="d-flex flex-column">
                        <div class="text-white">Total Truk</div>
                        <div class="fw-bold size-16 text-white">
                            {{ data_dashboard.count_fleet }}
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="text-white">Bertugas</div>
                        <div class="fw-bold size-16 text-white">
                            {{ data_dashboard.count_fleet_on_duty }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="box item-3">
                <img src="../../assets/people-icon.png" /><span
                    class="fw-bold size-16 ms-2"
                    >Total Driver</span
                >
                <div class="d-flex mt-4" style="gap: 40%">
                    <div class="d-flex flex-column">
                        <div class="">Total Driver</div>
                        <div class="fw-bold size-16">
                            {{ data_dashboard.count_driver }}
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="">Bertugas</div>
                        <div class="fw-bold size-16">
                            {{ data_dashboard.count_driver_on_duty }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="box item-4">
                <div class="d-flex align-items-center">
                    <div class="mb-0 fw-bold size-18 capitalize">
                        Total Pendapatan
                    </div>
                </div>
                <div class="mt-3">
                    <apexcharts
                        ref="chartweekly"
                        width="100%"
                        height="350"
                        type="bar"
                        :options="chartOptions"
                        :series="chartSeries"
                    ></apexcharts>
                </div>
            </div>
            <div class="box item-5">
                <div class="d-flex align-items-center">
                    <div class="mb-0 fw-bold size-18 capitalize">
                        List Order Masuk
                    </div>
                </div>
                <div class="scroll-order-list">
                    <template v-if="data_dashboard.list_order.length > 0">
                        <template
                            v-for="(item, index) in data_dashboard.list_order"
                        >
                            <div
                                class="mt-3 border-bottom no-shadow p-3 pe-0 me-0"
                                :key="index"
                            >
                                <div class="d-flex align-items-center">
                                    <div class="me-3">
                                        <img
                                            class="img-vehicle"
                                            :src="item.fleet_picture"
                                        />
                                    </div>
                                    <div
                                        class="d-flex flex-column justify-content-between"
                                        style="width: -webkit-fill-available"
                                    >
                                        <div
                                            class="d-flex justify-content-between"
                                        >
                                            <div class="mb-2 me-auto">
                                                {{ fleetType(item.fleet) }}
                                                <span class="ms-1">
                                                    ({{ item.receipt }})</span
                                                >
                                            </div>
                                            <div
                                                class="text-gray text-end mb-2 me-2 ms-auto"
                                            >
                                                {{
                                                    moment(item.date).format(
                                                        "DD MMM YYYY"
                                                    )
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="d-flex justify-content-between"
                                        >
                                            <div
                                                class="fw-bold size-16 me-auto"
                                            >
                                                {{ item.driver }}
                                            </div>
                                            <div
                                                class="fw-bold size-14 ms-auto"
                                                :class="
                                                    item.status == 'done'
                                                        ? 'tag green'
                                                        : 'tag yellow'
                                                "
                                            >
                                                {{ item.status_label }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="mt-mitra-list-fc text-center">
                            <img
                                src="../../assets/no-data.png"
                                width="100"
                                alt=""
                            />
                            <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                Tidak ada data
                            </h4>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import moment from "moment";
import "vue2-datepicker/index.css";
import "vue-search-select/dist/VueSearchSelect.css";
import VueApexCharts from "vue-apexcharts";
// import DatePicker from "vue2-datepicker";
// import Multiselect from "vue-multiselect";

export default {
    components: {
        apexcharts: VueApexCharts,
        // DatePicker,
        // Multiselect,
    },
    name: "Dashboard-Trawltruck",
    data() {
        return {
            moment: moment,
            data_dashboard: {},
            date_income: [],
            val_income: [],
            filter_date: "",
            donutOptions: {
                chart: {
                    type: "donut",
                },
                series: [],
                labels: ["Kendaraan TroTruck", "Driver TroTruck "],
                legend: {
                    position: "top",
                    markers: {
                        colors: ["#48A2D4", "#FFCC91"],
                    },
                },
            },
            donutSeries: [],
            chartOptions: {
                xaxis: {
                    categories: [],
                    labels: {
                        formatter: function (val) {
                            return moment(val, "YYYY-MM-DD").format("MMM-DD");
                        },
                    },
                },
                colors: ["#48A2D4"],
                dataLabels: {
                    enabled: false,
                },
            },
            chartSeries: [
                {
                    name: "Pendapatan",
                    data: [],
                },
            ],
            req: {
                month: "",
            },

            modalFilter: false,
            is_data_ready: false,
            is_data_filter: false,
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/dashboard`,
                {
                    params: { date: this.filter_date },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    //   let that = this;
                    this.data_dashboard = res.data.data;

                    this.date_income = [];
                    this.val_income = [];
                    this.donutSeries = [];

                    this.data_dashboard.income_per_day.forEach((el) => {
                        this.date_income.push(el.date);
                        this.val_income.push(el.income);
                    });

                    this.donutSeries.push(this.data_dashboard.count_fleet);
                    this.donutSeries.push(this.data_dashboard.count_driver);
                    this.chartOptions.xaxis.categories = this.date_income;
                    this.chartSeries[0].data = this.val_income;

                    //   let minNumber = Math.min(...this.val_income);
                    //   minNumber = parseInt(minNumber - minNumber * 0.1);
                    //   minNumber = 0;

                    //   this.$refs.chartweekly.updateSeries([
                    //     {
                    //       name: "Pendapatan",
                    //       data: this.val_income,
                    //     },
                    //   ]);
                    //   this.$refs.chartweekly.updateOptions({
                    //     xaxis: {
                    //       categories: this.date_income,
                    //     },
                    //     yaxis: {
                    //       min: minNumber,
                    //     },
                    //     tooltip: {
                    //       y: {
                    //         formatter: function (val) {
                    //           return that.currency(val);
                    //         },
                    //       },
                    //     },
                    //   });
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
        fleetType(value) {
            switch (value) {
                case "bike":
                    return "Sepeda Motor";
                case "mpv":
                    return "MPV";
                case "pickup":
                    return "Pickup";
                case "pickup-box":
                    return "Pickup Box";
                case "pickup-bak":
                    return "Pickup Bak";
                case "cde engkel":
                    return "(CDE) Engkel";
                case "cde-bak":
                    return "(CDE) Engkel Bak";
                case "cde-box":
                    return "(CDE) Engkel Box";
                case "cdelong-box":
                    return "(CDE) Long Box";
                case "cdd-bak":
                    return "(CDD) Colt Diesel Double Bak";
                case "cdd-box":
                    return "(CDD) Colt Diesel Double Box";
                case "cddlong-bak":
                    return "(CDD) Long Bak";
                case "cddlong-box":
                    return "(CDD) Long Box";
                case "cdd-reefer":
                    return "(CDD) Thermo King/Reefer";
                case "fuso-bak":
                    return "Fuso Bak";
                case "fuso-box":
                    return "Fuso Box";
                case "engkel box":
                    return "Engkel Box";
                case "engkel double":
                    return "Engkel Double";
                case "engkel double box":
                    return "Engkel Double Box";
                case "fuso 6m":
                    return "Fuso 6M";
                case "fuso 9m":
                    return "Fuso 9M";
                case "tronton-bak":
                    return "Tronton Bak";
                case "tronton-box":
                    return "Tronton Box";
                case "wingbox":
                    return "Wingbox";
                case "van":
                    return "Van";
                case "blind-van":
                    return "Blind Van";
                case "towing":
                    return "Towing";
                case "selfloader":
                    return "Selfloader";
                case "trailer-20feet":
                    return "Trailer 20ft";
                case "trailer-40feet":
                    return "Trailer 40ft";
                case "lowbed":
                    return "Lowbed";
                default:
                    return value;
            }
        },

        codeStatus(value) {
            var status;
            switch (value) {
                case "ontheway":
                    status = "Dalam Perjalanan";
                    break;
                case "onboard":
                    status = "Sedang Dalam Kapal";
                    break;
                case "in_port_dest":
                    status = "Sudah di Pelabuhan Tujuan";
                    break;
                case "in_pool_transit":
                    status = "Sedang Menuju Pool Transit";
                    break;
                case "ontheway_to_dest":
                    status = "Sedang Dalam Proses Pengantaran";
                    break;
                case "done":
                    status = "Selesai";
                    break;
                case "pickup":
                    status = "Menuju Lokasi Penjemputan";
                    break;
                default:
                    status = "";
            }
            return status;
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        dateFormat(date) {
            let isDate = date ? date : new Date();
            moment.locale("id");
            return moment(isDate).format("dddd, DD MMMM YYYY");
        },
        thisMonth() {
            return moment(new Date()).format("MMMM");
        },
        currency(number) {
            return new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
            }).format(number);
        },
    },
};
</script>
<style scoped>
.grid-container {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    grid-gap: 20px;
}
.item-1 {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
}
.item-2 {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}
.item-3 {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}
.item-4 {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
}
.item-5 {
    grid-column: 3 / 4;
    grid-row: 1 / 4;
}
.scroll-order-list {
    height: 750px;
    overflow-y: scroll;
}
.img-vehicle {
    width: 49px;
    height: 49px;
    box-sizing: border-box;
    border: 1px solid #f1f3f9;
    border-radius: 8px;
    object-fit: contain;
    padding: 0 2px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    position: absolute;
    opacity: 1;
    display: block;
    background: none no-repeat;
    border-width: thin;
    width: 100%;
}
</style>
