<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <h3 class="fw-bold">Daftar Pencairan Komisi Sticker Troben Truk</h3>
            <div class="d-flex mt-4">
                <div class="me-3">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Cari nama driver/No. Pengajuan Klaim"
                            v-model="req.search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="m-mt-1 ms-3">
                    <button
                        class="btn btn-outline-black btn-filter px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
                <div class="ms-3 m-mx-0 m-mt-2">
                    <button
                        class="btn btn-green btn-with-search px-4 d-flex align-items-center"
                        @click="exportData()"
                        :disabled="is_export"
                    >
                        Export
                        <img
                            src="../../assets/download.png"
                            width="13"
                            class="ms-2"
                            alt=""
                        />
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-4 mb-2">
                        <label class="fw-bold mb-1">Tanggal Pengajuan</label>
                        <date-picker
                            v-model="date_convert"
                            type="date"
                            value-type="format"
                            class="w-100"
                            format="DD MMMM YYYY"
                            :clearable="false"
                            placeholder="Pilih tanggal pengajuan"
                        >
                        </date-picker>
                    </div>
                    <div class="col-md-8 mb-2">
                        <label class="fw-bold mb-1">Jenis Kendaraan </label>
                        <div class="row">
                            <div class="col-md-6">
                                <multiselect
                                    v-model="fleet_type"
                                    :options="fleets"
                                    :show-labels="false"
                                    label="name"
                                    track-by="code"
                                    placeholder="Pilih jenis kendaraan"
                                    class="multi-form-custom"
                                >
                                </multiselect>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-6">
                                        <button
                                            class="btn btn-outline-black w-100"
                                            :disabled="
                                                date_convert == '' &&
                                                fleet_type == null
                                            "
                                            @click="clearFilter()"
                                        >
                                            Clear
                                        </button>
                                    </div>
                                    <div class="col-md-6">
                                        <button
                                            class="btn btn-green w-100"
                                            :disabled="
                                                date_convert == '' &&
                                                fleet_type == null
                                            "
                                            @click="filter()"
                                        >
                                            Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div class="table-responsive-custom" v-else>
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">Nama Driver</th>
                            <th class="text-center" scope="col">
                                No. Pengajuan Klaim
                            </th>
                            <th class="text-center" scope="col">
                                Jenis Armada
                            </th>
                            <th class="text-center" scope="col">
                                Tanggal Pengajuan
                            </th>
                            <th
                                class="text-center position-relative"
                                scope="col"
                            >
                                <a
                                    href="javascript:void(0)"
                                    class="dropdown-toggle text-dark"
                                    id="status"
                                    data-bs-toggle="dropdown"
                                    data-bs-auto-close="true"
                                    aria-expanded="false"
                                >
                                    Status
                                    <i class="fa fa-filter"></i>
                                </a>
                                <ul
                                    class="dropdown-menu"
                                    aria-labelledby="status"
                                >
                                    <li>
                                        <a
                                            class="dropdown-item"
                                            href="javascript:void(0)"
                                            @click="filterBy('accept')"
                                        >
                                            Disetujui
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            class="dropdown-item"
                                            href="javascript:void(0)"
                                            @click="filterBy('transfer')"
                                        >
                                            Telah Ditransfer
                                        </a>
                                    </li>
                                </ul>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(data, index) in list.list_data"
                            :key="index"
                        >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td>
                                <a
                                    :href="
                                        `/truck/commission/sticker/detail/` +
                                        data.id
                                    "
                                    class="text-link"
                                >
                                    {{ data.driver_name }}
                                </a>
                            </td>
                            <td>{{ data.claim_code }}</td>
                            <td>{{ data.fleet_type_label }}</td>
                            <td class="text-center">{{ data.created_at }}</td>
                            <td class="text-center">
                                <div
                                    class="tag yellow-cro radius mb-0 pb-0"
                                    v-if="data.status == 'accept'"
                                >
                                    Disetujui
                                </div>
                                <div
                                    class="tag green-cro radius mb-0 pb-0"
                                    v-else
                                >
                                    Telah Ditransfer
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center mt-5" v-if="list.list_data.length == 0">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="mt-4">
                <b-pagination-nav
                    v-if="list.list_data.length > 0"
                    v-model="currentPage"
                    :number-of-pages="list.total_page"
                    base-url="#"
                    first-number
                    align="right"
                    @input="getList"
                ></b-pagination-nav>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        DatePicker,
        Multiselect,
    },
    name: "TruckCommisionSticker",
    data() {
        return {
            moment: moment,
            req: {
                search: "",
            },
            date_convert: "",
            is_list_ready: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            limit: 10,
            currentPage: 0,
            isFilter: false,
            filter_at: "",
            fleet_type: null,
            fleets: [],
            is_export: false,
        };
    },
    created() {
        this.getList();
        this.getFleets();
    },
    computed: {},
    methods: {
        getList() {
            this.is_list_ready = true;
            let payload = {
                search: this.req.search,
                limit: this.limit,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
            };
            if (this.date_convert) {
                payload.date = moment(this.date_convert).format("YYYY-MM-DD");
            }
            if (this.fleet_type) {
                payload.fleet_type = this.fleet_type?.code;
            }
            if (this.filter_at) {
                payload.status = this.filter_at;
            }
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/truck/head-office/disbursment/commission`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        resetFilter() {
            this.date = "";
            this.date_convert = "";
            this.isFilter = false;
            this.getList();
        },
        filterBy(value) {
            this.filter_at = value;
            this.getList();
        },
        clearFilter() {
            this.date_convert = "";
            this.fleet_type = null;
            this.getList();
        },
        filter() {
            this.getList();
        },
        getFleets() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/trawltruck-fleet`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.fleets = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        exportData() {
            this.is_export = true;
            let payload = {
                search: this.req.search,
                limit: this.limit,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
            };
            if (this.date_convert) {
                payload.date = moment(this.date_convert).format("YYYY-MM-DD");
            }
            if (this.fleet_type) {
                payload.fleet_type = this.fleet_type?.code;
            }
            if (this.filter_at) {
                payload.status = this.filter_at;
            }
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/truck/head-office/disbursement/commission/export/excel`,
                "",
                {
                    params: payload,
                    responseType: "blob",
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data_excel_request = res.data;
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        "Komisi Sticker" +
                            " " +
                            moment().format("DD MMM YYYY") +
                            `.xls`
                    );
                    document.body.appendChild(link);
                    link.click();
                    this.is_export = false;
                })
                .catch((err) => {
                    this.is_export = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.compare-data .mx-datepicker-main {
    border: none !important;
}
.compare-data .mx-datepicker-footer {
    border-top: none !important;
}
.dropdown-item:focus,
.dropdown-item:hover {
    background: unset;
    color: #000;
}
.dropdown-toggle::after {
    display: none;
}
</style>
