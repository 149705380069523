<template>
    <div class="padding-container">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item w-50" role="presentation">
                <button class="nav-link dashboard left w-100 active" id="pills-operation-tab" data-bs-toggle="pill" data-bs-target="#pills-operation" type="button" role="tab" aria-controls="pills-operation" aria-selected="true">
                    Operation
                </button>
            </li>
            <li class="nav-item w-50" role="presentation">
                <button class="nav-link dashboard right w-100" id="pills-finance-tab" data-bs-toggle="pill" data-bs-target="#pills-finance" type="button" role="tab" aria-controls="pills-finance" aria-selected="false">
                    Finance
                </button>
            </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-operation" role="tabpanel" aria-labelledby="pills-operation-tab">
                <div class="row">
                    <div class="col-md-4 mt-3">
                        <a href="/trawltruck/ho/partner">
                            <div class="box mitra no-shadow">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <img src="../../assets/dashboard/mitra.png" width="100%" alt="">
                                    </div>
                                    <div class="ms-auto text-main-color text-right">
                                        <div class="fw-bold">
                                            Total Mitra
                                        </div>
                                        <div class="size-26 fw-bold line-height-25 mt-2">
                                            {{ dataOprtaions.overview.partner.not_active + dataOprtaions.overview.partner.active }} Mitra
                                        </div>
                                        <div class="size-26 fw-bold text-green-dashboard line-height-custom">
                                            {{ dataOprtaions.overview.partner.active }} Aktif
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-4 mt-3">
                        <a href="/trawltruck/ho/fleet">
                            <div class="box truk no-shadow">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <img src="../../assets/dashboard/truk.png" width="100%" alt="">
                                    </div>
                                    <div class="ms-auto text-main-color text-right">
                                        <div class="fw-bold">
                                            Total  Truk
                                        </div>
                                        <div class="size-26 fw-bold line-height-25 mt-2">
                                            {{ dataOprtaions.overview.truck.not_active + dataOprtaions.overview.truck.active }} Truk
                                        </div>
                                        <div class="size-26 fw-bold text-green-dashboard line-height-custom">
                                            {{ dataOprtaions.overview.truck.active }} Aktif
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-4 mt-3">
                        <a href="/trawltruck/ho/driver">
                            <div class="box driver no-shadow">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <img src="../../assets/dashboard/driver.png" width="100%" alt="">
                                    </div>
                                    <div class="ms-auto text-main-color text-right">
                                        <div class="fw-bold">
                                            Total  Driver
                                        </div>
                                        <div class="size-26 fw-bold line-height-25 mt-2">
                                            {{ dataOprtaions.overview.driver.not_active + dataOprtaions.overview.driver.active }} Driver
                                        </div>
                                        <div class="size-26 fw-bold text-green-dashboard line-height-custom">
                                            {{ dataOprtaions.overview.driver.active }} Aktif
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-6 mt-5">
                        <div class="box pb-0">
                            <div class="d-flex align-items-center">
                                <h5 class="mb-0 fw-bold">Orderan Belum Terambil</h5>
                                <div class="ms-auto">
                                    <a href="/trawltruck/ho/order" class="size-14 text-dark">
                                        Lihat Semua
                                    </a>
                                </div>
                            </div>
                            <hr>
                            <div class="scroll-small-box">
                                <template v-if="dataOprtaions.order_pending.length > 0">
                                    <template v-for="(data, index) in dataOprtaions.order_pending">
                                        <div :key="index">
                                            <a :href="'/trawltruck/ho/order/detail/' + data.order_id" class="text-dark">
                                                <div class="d-flex align-items-center">
                                                    <div class="box me-3 no-shadow border-gray p-3">
                                                        <img :src="data.fleet.picture" width="45" alt="">
                                                    </div>
                                                    <div>
                                                        <div class="size-16">
                                                            <span class="text-green-dashboard size-16">
                                                                {{ data.receipt }}
                                                            </span> - 
                                                            <span class="size-16">
                                                                <template v-if="data.order_type == 'quick'">
                                                                    Cepat
                                                                </template>
                                                                <template v-if="data.order_type == 'schedule'">
                                                                    Terjadwal
                                                                </template>
                                                                <template v-if="data.order_type == 'fullday'">
                                                                    Seharian
                                                                </template>
                                                            </span> 
                                                            <i class="fa fa-circle size-13 mx-2 text-red-dashboard"></i>
                                                        </div>
                                                        <div class="mt-2 size-16">
                                                            {{ data.fleet.name }} - {{ data.created_at }}
                                                        </div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <div style="width: 122px">
                                                            <a href="javascript:void(0)" class="text-gray-dashboard" data-bs-toggle="tooltip" data-bs-placement="top" :title="data.origin_regency +  ' - ' + data.origin_destination">
                                                                <span class="limit-one-line">
                                                                    {{ data.origin_regency }} - {{ data.origin_destination }}
                                                                </span>
                                                            </a>
                                                        </div>
                                                        <div class="text-right text-gray-dashboard mt-2">
                                                            Order - {{ moment(data.order_date).format("DD MMM YYYY") }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <hr>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="text-center mb-5 mt-5">
                                        <img src="../../assets/no-data.png" width="90" alt="" />
                                        <h4 class="mb-0 fw-bold mt-4 text-gray">Data Kosong</h4>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-5">
                        <div class="box pb-0">
                            <div class="d-flex align-items-center">
                                <h5 class="mb-0 fw-bold">Orderan Sedang Berlangsung</h5>
                                <div class="ms-auto">
                                    <a href="/trawltruck/ho/order" class="size-14 text-dark">
                                        Lihat Semua
                                    </a>
                                </div>
                            </div>
                            <hr>
                            <div class="scroll-small-box">
                                <template v-if="dataOprtaions.order_ongoing.length > 0">
                                    <template v-for="(data, index) in dataOprtaions.order_ongoing">
                                        <div :key="index">
                                            <a :href="'/trawltruck/ho/order/detail/' + data.order_id" class="text-dark">
                                                <div class="d-flex align-items-center">
                                                    <div class="box me-3 no-shadow border-gray p-3">
                                                        <img :src="data.fleet.picture" width="45" alt="">
                                                    </div>
                                                    <div>
                                                        <div class="size-16">
                                                            <span class="text-green-dashboard size-16">{{ data.receipt }}</span> - 
                                                            <span class="capitalize">
                                                                <template v-if="data.order_type == 'quick'">
                                                                    Cepat
                                                                </template>
                                                                <template v-if="data.order_type == 'schedule'">
                                                                    Terjadwal
                                                                </template>
                                                                <template v-if="data.order_type == 'fullday'">
                                                                    Seharian
                                                                </template>
                                                            </span>
                                                        </div>
                                                        <div class="mt-2 size-16">
                                                            {{ data.fleet.name }}
                                                        </div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <div style="width: 122px">
                                                            <a href="javascript:void(0)" class="text-gray-dashboard" data-bs-toggle="tooltip" data-bs-placement="top" :title="data.origin_regency +  ' - ' + data.origin_destination">
                                                                <span class="limit-one-line">
                                                                    {{ data.origin_regency }} - {{ data.origin_destination }}
                                                                </span>
                                                            </a>
                                                        </div>
                                                        <div class="text-right text-gray-dashboard mt-2">
                                                            Order - {{ moment(data.order_date).format("DD MMM YYYY") }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <hr>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="text-center mb-5 mt-5">
                                        <img src="../../assets/no-data.png" width="90" alt="" />
                                        <h4 class="mb-0 fw-bold mt-4 text-gray">Data Kosong</h4>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-4">
                        <div class="box pb-0">
                            <div class="d-flex align-items-center">
                                <h5 class="mb-0 fw-bold">Orderan Terjadwal</h5>
                                <div class="ms-auto">
                                    <a href="/trawltruck/ho/order" class="size-14 text-dark">
                                        Lihat Semua
                                    </a>
                                </div>
                            </div>
                            <hr>
                            <div class="scroll-small-box">
                                <template v-if="dataOprtaions.order_scheduled.length > 0">
                                    <template v-for="(data, index) in dataOprtaions.order_scheduled">
                                        <div :key="index">
                                            <a :href="'/trawltruck/ho/order/detail/' + data.order_id" class="text-dark">
                                                <div class="d-flex align-items-center">
                                                    <div class="box me-3 no-shadow border-gray p-3">
                                                        <img :src="data.fleet.picture" width="45" alt="">
                                                    </div>
                                                    <div>
                                                        <div class="size-16">
                                                            <span class="text-green-dashboard size-16">{{ data.receipt }}</span> - <span class="capitalize">{{ data.order_type }}</span>
                                                        </div>
                                                        <div class="mt-2 size-16">
                                                            {{ data.fleet.name }}
                                                        </div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <div style="width: 122px">
                                                            <a href="javascript:void(0)" class="text-gray-dashboard" data-bs-toggle="tooltip" data-bs-placement="top" :title="data.origin_regency +  ' - ' + data.origin_destination">
                                                                <span class="limit-one-line">
                                                                    {{ data.origin_regency }} - {{ data.origin_destination }}
                                                                </span>
                                                            </a>
                                                        </div>
                                                        <div class="text-right text-gray-dashboard mt-2">
                                                            Order - {{ moment(data.created_at).format("DD MMM YYYY") }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <hr>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="text-center pb-5 mt-5">
                                        <img src="../../assets/no-data.png" width="90" alt="" />
                                        <h4 class="mb-0 fw-bold mt-4 text-gray">Data Kosong</h4>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-4">
                        <div class="box pb-0">
                            <h5 class="mb-0 fw-bold">Driver Bertugas</h5>
                            <hr>
                            <div class="scroll-small-box">
                                <template v-if="dataOprtaions.driver_on_duty.length > 0">
                                    <template v-for="(data, index) in dataOprtaions.driver_on_duty">
                                        <div :key="index">
                                                <a :href="'/trawltruck/ho/order/detail/' + data.order_id" class="text-dark">
                                                    <div class="d-flex align-items-center">
                                                        <div class="user-dashboard me-3" :style="{ 'background-image': 'url(' + data.driver.picture + ')' }"></div>
                                                        <div class="">
                                                            <div class="size-16 fw-bold capitalize">
                                                                {{ data.driver.name }}
                                                            </div>
                                                            <div class="mt-tag">
                                                                <small class="tag yellow radius capitalize">
                                                                    {{ data.status }}
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="ms-auto">
                                                            <div class="text-right text-gray-dashboard mt-2">
                                                                {{ moment(data.date).format("DD MMMM YYYY") }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            <hr>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="text-center pb-5 mt-5">
                                        <img src="../../assets/no-data.png" width="90" alt="" />
                                        <h4 class="mb-0 fw-bold mt-4 text-gray">Data Kosong</h4>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-4">
                        <div class="box scroll-small-box pb-0">
                            <h5 class="mb-0 fw-bold">Truk Balik Stand By</h5>
                            <hr>
                            <template v-if="dataOprtaions.truck_standby.length > 0">
                                <template v-for="(data, index) in dataOprtaions.truck_standby">
                                    <div :key="index">
                                        <div class="row">
                                            <div class="col-md-7">
                                                <div class="d-flex align-items-center">
                                                    <div class="box me-3 no-shadow border-gray p-3">
                                                        <img :src="data.fleet.picture" width="45" alt="">
                                                    </div>
                                                    <div class="">
                                                        <div class="size-16 fw-bold">
                                                            {{ data.driver }}
                                                        </div>
                                                        <div class="mt-2 size-16">
                                                            {{ data.fleet.name }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-5">
                                                <div class="text-right text-gray-dashboard">
                                                    {{ data.partner }}
                                                </div>
                                                <div class="text-right text-gray-dashboard mt-2">
                                                    {{ data.regency }} (Stand By)
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div class="text-center pb-5 mt-5">
                                    <img src="../../assets/no-data.png" width="90" alt="" />
                                    <h4 class="mb-0 fw-bold mt-4 text-gray">Data Kosong</h4>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="pills-finance" role="tabpanel" aria-labelledby="pills-finance-tab">
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <div class="box driver no-shadow">
                            <div class="d-flex align-items-center">
                                <div class="fw-bold size-16">
                                    Total Pendapatan
                                </div>
                                <div class="ms-auto text-main-color size-16">
                                    {{ (moment(dataFinance.year_month).format("YYYY MMMM")) }}
                                </div>
                            </div>
                            <div class="mt-4">
                                <h1 class="mb-0 fw-bold">
                                    {{ currencyFormat(dataFinance.overview.income.this_month) }} 
                                    <span class="size-h1" :class="{ 'text-green-bold': dataFinance.overview.income.this_month > dataFinance.overview.income.last_month, 'text-danger': dataFinance.overview.income.this_month < dataFinance.overview.income.last_month }">
                                        ({{ currencyFormatNoRp(dataFinance.overview.income.this_month - dataFinance.overview.income.last_month) }})
                                    </span>
                                </h1>
                            </div>
                            <div class="mt-3">
                                <h5 class="mb-0 text-main-color">
                                    Pendapatan Sebelumnya {{ currencyFormat(dataFinance.overview.income.last_month) }}
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div class="box blue-light no-shadow">
                            <div class="d-flex align-items-center">
                                <div class="fw-bold size-16">
                                    Total Transaksi
                                </div>
                            </div>
                            <div class="mt-4">
                                <h1 class="mb-0 fw-bold">
                                    {{ dataFinance.overview.transaction.this_month }} Transaksi 
                                    <span class="size-h1" :class="{ 'text-green-bold': dataFinance.overview.transaction.this_month > dataFinance.overview.transaction.last_month, 'text-danger': dataFinance.overview.transaction.this_month < dataFinance.overview.transaction.last_month }">
                                        ({{ dataFinance.overview.transaction.this_month - dataFinance.overview.transaction.last_month }})
                                    </span>
                                </h1>
                            </div>
                            <div class="mt-3">
                                <h5 class="mb-0 text-main-color">
                                    Jumlah Transaksi
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-5">
                        <div class="box pb-0">
                            <div class="d-flex align-items-center">
                                <h5 class="mb-0 fw-bold">Transaksi Sedang Berjalan</h5>
                            </div>
                            <hr>
                            <div class="scroll-small-box">
                                <template v-if="dataFinance.order_ongoing.length > 0">
                                    <template v-for="(data, index) in dataFinance.order_ongoing">
                                        <div :key="index">
                                            <a :href="'/trawltruck/ho/order/detail/' + data.order_id" class="text-dark">
                                                <div class="d-flex align-items-center">
                                                    <div class="">
                                                        <div class="size-16 fw-bold">
                                                            {{ data.partner }}
                                                        </div>
                                                        <div class="mt-2 size-16">
                                                            Harga Sewa: <span class="text-green-bold size-16">{{ currencyFormat(data.total_amount) }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <div class="text-right text-gray-dashboard">
                                                            Order - {{ moment(data.order_date).format("DD MMM YYYY") }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <hr>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="text-center mb-5 mt-5">
                                        <img src="../../assets/no-data.png" width="90" alt="" />
                                        <h4 class="mb-0 fw-bold mt-4 text-gray">Data Kosong</h4>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-5">
                        <div class="box pb-0">
                            <div class="d-flex align-items-center">
                                <h5 class="mb-0 fw-bold">Berikan Uang Jalan</h5>
                                <div class="ms-auto">
                                    <a href="/trawltruck/ho/delivery-payment" class="size-14 text-dark">
                                        Lihat Semua
                                    </a>
                                </div>
                            </div>
                            <hr>
                            <div class="scroll-small-box">
                                <template v-if="dataFinance.request_travel_expense.length > 0">
                                    <template v-for="(data, index) in dataFinance.request_travel_expense">
                                        <div :key="index">
                                            <div class="d-flex align-items-center">
                                                <div class="">
                                                    <div class="size-16 fw-bold text-green-bold">
                                                        {{ data.receipt }}
                                                    </div>
                                                    <div class="mt-2 size-16">
                                                        {{ data.driver }} - {{ data.partner }}
                                                    </div>
                                                </div>
                                                <div class="ms-auto">
                                                    <div class="text-right text-gray-dashboard">
                                                        {{ data.origin_regency }} - {{ data.origin_destination }}
                                                    </div>
                                                    <div class="text-right text-gray-dashboard mt-2">
                                                        Order - {{ moment(data.order_date).format("DD MMM YYYY") }}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="text-center mb-5 mt-5">
                                        <img src="../../assets/no-data.png" width="90" alt="" />
                                        <h4 class="mb-0 fw-bold mt-4 text-gray">Data Kosong</h4>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-4">
                        <div class="box">
                            <div class="d-flex align-items-center">
                                <h5 class="mb-0 fw-bold">Grafik Pendapatan Mitra</h5>
                            </div>
                            <hr>
                            <div class="mt-3">
                                <apexcharts ref="chartmonthly" width="100%" height="350" type="bar" :options="chartOptions" :series="series"></apexcharts>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from "../../api/Api";
    import moment from 'moment'
    import VueApexCharts from 'vue-apexcharts'
    import { Tooltip } from "bootstrap";

    export default {
        components: {
            apexcharts: VueApexCharts,
        },
        name: "Dashboard-Trawltruck HO",
        data() {
            return {
                moment: moment,
                params: {
                    partner_type: ''
                },
                chartOptions: {
                    xaxis: {
                        categories: [],
                        labels: {
                            formatter: function (val) {
                                return moment(val, 'YYYY-MM-DD').format("MMM")
                                // return 'Tgl ' + val
                            }
                        }
                    },
                    colors: ['#E60013'],
                    dataLabels: {
                        enabled: false
                    },
                },
                series: [{
                    name: 'Pendapatan',
                    data: []
                }],
                req: {
                    month: moment(new Date()).format("MMMM YYYY"),
                    province: {
                        id: ''
                    },
                    district: {
                        id: ''
                    },
                    order_by: 'desc',
                    date_current: moment(new Date()).format("DD MMMM YYYY"),
                },
                is_data_ready: false,
                dataOprtaions: {
                    driver_on_duty: [],
                    order_ongoing: [],
                    order_pending: [],
                    order_scheduled: [],
                    truck_standby: [],
                    overview: {
                        driver: {},
                        partner: {},
                        truck: {}
                    },
                    year_month: null
                },
                dataFinance: {
                    income_chart: [],
                    order_ongoing: [],
                    request_travel_expense: [],
                    overview: {
                        income: {},
                        transaction: {}
                    },
                    year_month: null
                }
            };
        },
        created() {
            // this.getDatas()
            this.getDashboardOprations()
            this.getDashboardFinance()
        },
        mounted() {
            new Tooltip(document.body, {
                selector: "[data-bs-toggle='tooltip']",
            });
        },
        methods: {
            currencyFormat(num) {
                return (
                    "Rp" +
                    parseInt(num)
                        .toFixed(0)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                );
            },
            currencyFormatNoRp(num) {
                return (
                    parseInt(num)
                        .toFixed(0)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                );
            },
            getDashboardOprations() {
                Api.get(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/dashboard-ops`, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    var data = res.data.data
                    this.dataOprtaions = data
                })
                .catch((err) => {
                    console.log(err);
                });
            },
            getDashboardFinance() {
                this.is_data_ready = true
                Api.get(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/dashboard-fnc`, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    var data = res.data.data
                    this.is_data_ready = false
                    this.dataFinance = data

                    let minNumber = Math.min(...data.income_chart.val)
                    minNumber = parseInt(minNumber - (minNumber * 0.1))
                    minNumber = 0

                    this.$refs.chartmonthly.updateSeries([{
                        name: 'Pendapatan',
                        data: data.income_chart.val
                    }])

                    let that = this

                    this.$refs.chartmonthly.updateOptions({
                        xaxis: {
                            categories: data.income_chart.key
                        },
                        yaxis: {
                            min: minNumber,
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return that.currencyFormat(val) + ' Kg'
                                }
                            }
                        },
                    })
                })
                .catch((err) => {
                    this.is_data_ready = false
                    console.log(err);
                });
            },
        },
    };
</script>

<style scoped>
    .nav-link.dashboard{
        background: #F5F5F5;
        color: #8E8E94;
    }
    .nav-link.dashboard.right{
        border-bottom-left-radius: 0px !important;
        border-top-left-radius: 0px !important;
    }
    .nav-link.dashboard.left{
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    .nav-link.dashboard.active{
        background-color: #E60013 !important;
    }
    .box.mitra{
        background-color: #FACCD0;
    }
    .box.truk{
        background-color: #FEEAD4;
    }
    .box.driver{
        background-color: #D8E7D3;
    }
    .box.blue-light{
        background-color: #DAECF6;
    }
    .text-main-color{
        color: #24516A !important;
    }
    .text-green-dashboard{
        color: #3D8824;
    }
    .text-green-bold{
        color: #3D8824;
    }
    .text-red-dashboard{
        color: #E60013;
    }
    .text-gray-dashboard{
        color: #61616A;
    }
    .text-right{
        text-align: right;
    }
    .line-height-custom{
        line-height: 30px;
    }
    .radius{
        border-radius: 50px;
    }
    .user-dashboard {
        width: 45px;
        height: 45px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50px;
        position: relative;
        border: 2px solid #f7f7f7;
    }
    .mt-tag{
        margin-top: 5px;
    }
    .size-h1{
        font-size: 2.5rem;
    }
    .scroll-small-box{
        max-height: 320px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
        color: #fff !important;
    }
</style>
