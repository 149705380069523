<template>
    <div class="padding-container">
        <div class="d-flex bd-highlight">
            <div class="flex-grow-1 bd-highlight">
                <h3 class="fw-bold" @click="isDetail = false">
                    Data Pendapatan Mitra
                    <span class="text-green size-22">{{ partnerCode }}</span>
                </h3>
            </div>
        </div>
        <div class="box mt-3">
            <div class="d-flex justify-content-between">
                <div class="p-2 bd-highlight">
                    <h4 class="text-gray">Total</h4>
                    <h3 class="fw-bold mb-0">{{ currency(total) }}</h3>
                </div>
                <div class="p-2 bd-highlight">
                    <h4 class="text-gray">Total Unapproved</h4>
                    <h3 class="fw-bold mb-0">
                        {{ currency(total_unapproved) }}
                    </h3>
                </div>
                <div class="p-2 bd-highlight">
                    <h4 class="text-gray">Total Approved</h4>
                    <h3 class="fw-bold mb-0">{{ currency(total_approved) }}</h3>
                </div>
            </div>
        </div>

        <div class="box mt-3">
            <div class="d-flex gap-1">
                <div class="me-auto bd-highlight">
                    <div class="position-relative">
                        <input
                            v-model="noReceipt"
                            @keyup.enter="searchReceipt"
                            type="text"
                            class="form-control"
                            placeholder="Search No.Resi"
                        />
                        <span class="search-icon">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="px-1 bd-highlight">
                    <select
                        name=""
                        @change="getData"
                        class="form-control form-select"
                        v-model="filter_status"
                        style="width: 125px"
                    >
                        <option :value="null">Filter Status</option>
                        <option value="pending">Pending</option>
                        <option value="success">Success</option>
                    </select>
                </div>
                <div class="bd-highlight">
                    <input
                        type="date"
                        class="form-control"
                        v-model="filter.date_start"
                        placeholder="Start Date"
                        valueFormat="YYYY-MM-DD"
                    />
                </div>
                <div class="bd-highlight d-flex align-items-center">
                    <div class="fw-bold fs-6">s/d</div>
                </div>
                <div class="bd-highlight">
                    <input
                        type="date"
                        class="form-control"
                        v-model="filter.date_end"
                        :disabled="!filter.date_start"
                        valueFormat="YYYY-MM-DD"
                        @change="getData"
                        placeholder="End Date"
                    />
                </div>
                <div class="bd-highlight ps-2">
                    <template
                        v-if="approved_at != null && transferred_at == null"
                    >
                        <button
                            type="button"
                            class="btn btn-success w-100"
                            @click="showModal()"
                        >
                            <icon type="upload"></icon> Upload Bukti Transfer
                        </button>
                    </template>
                    <template v-else-if="transferred_at != null">
                        <button
                            type="button"
                            class="btn btn-green px-4"
                            @click="showModal()"
                        >
                            <icon type="upload"></icon>Lihat Bukti Transfer
                        </button>
                    </template>
                    <!-- <input type="text" class="form-control" placeholder="Cari Nomor Resi" /> -->
                </div>
            </div>

            <div>
                <b-modal v-model="visible" id="bv-modal-example" hide-footer>
                    <b-alert
                        :show="dismissCountDown"
                        dismissible
                        variant="warning"
                        @dismissed="dismissCountDown = 0"
                        @dismiss-count-down="countDownChanged"
                    >
                        <p>Gagal Upload Foto!</p>
                    </b-alert>
                    <template #modal-title>
                        <div class="red-color">
                            <template v-if="transferred_at == null">
                                Upload Bukti Transfer
                            </template>
                            <template v-else> Bukti Transfer </template>
                        </div>
                    </template>
                    <div class="d-block">
                        <div class="row">
                            <div class="col">
                                <template v-if="transferred_at == null">
                                    <input
                                        class="mb-2"
                                        type="file"
                                        name="file"
                                        @change="uploadFile"
                                        ref="file"
                                    />
                                    <div class="image-success-upload">
                                        <center>
                                            <img
                                                class="preview"
                                                :src="imageData"
                                                v-if="imageData.length > 0"
                                            />
                                            <img
                                                src="../../assets/no-photo.png"
                                                class="preview"
                                                v-else
                                            />
                                        </center>
                                    </div>
                                    <div class="mt-2">
                                        <button
                                            class="primary pr-2 pl-2"
                                            @click="submitFile"
                                            :disabled="isSaving"
                                        >
                                            <span
                                                class="spinner-border spinner-border-sm me-2"
                                                v-if="isSaving"
                                            ></span>
                                            Submit
                                        </button>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="image-success-upload">
                                        <center>
                                            <img
                                                :src="attachment_transfer"
                                                alt=""
                                            />
                                        </center>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <template #modal-footer="{ submitFile }">
                        <button
                            class="mt-3 btn btn-success"
                            @click="submitFile"
                            :disabled="isSaving"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isSaving"
                            ></span>
                            Submit
                        </button>
                    </template>
                </b-modal>
            </div>
            <div class="mt-4" v-if="is_my_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <div class="modern-table-boxed mt-4">
                    <div class="d-table-row header">
                        <div class="d-table-cell">No Resi</div>
                        <div class="d-table-cell">Total Payment</div>
                        <div class="d-table-cell">Total Penerimaan</div>
                        <div class="d-table-cell">Is Aproved</div>
                    </div>
                    <div
                        class="d-table-row"
                        v-for="(item, name, index) in lists"
                        :key="index"
                    >
                        <div class="d-table-cell">
                            <a
                                href="javascript:void(0)"
                                @click="openModalReceipt(item)"
                                class="text-link"
                            >
                                {{ item.receipt }}
                            </a>
                        </div>
                        <div class="d-table-cell">
                            {{ currency(item.total_payment) }}
                        </div>
                        <div class="d-table-cell">
                            {{ currency(item.total_accepted) }}
                        </div>
                        <div class="d-table-cell">
                            <template v-if="item.approved == 'pending'">
                                <template v-if="approved_at">
                                    <input type="checkbox" disabled />
                                </template>
                                <template v-else>
                                    <input
                                        type="checkbox"
                                        v-model="receipt"
                                        :value="item"
                                    />
                                </template>
                                Pending
                            </template>
                            <template v-else>
                                <span class="text-green fw-medium">
                                    <a-icon type="check" />
                                    Success
                                </span>
                            </template>
                        </div>
                    </div>
                </div>
                <div v-if="lists.length == 0" class="text-center mt-section">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
                <div class="box wrapper">
                    <div class="d-flex justify-content-between">
                        <div class="bd-highlight">
                            <template
                                v-if="
                                    this.approved_at != null &&
                                    transferred_at == null
                                "
                            >
                                Approved At
                                <span class="fw-medium">
                                    {{ setDate(approved_at) }}
                                </span>
                            </template>
                            <template v-if="transferred_at != null">
                                Transferred At
                                <span class="fw-bold">
                                    {{ setDate(approved_at) }}
                                </span>
                            </template>
                        </div>
                        <div class="bd-highlight">
                            <button
                                type="button"
                                class="me-3 btn btn-outline-black"
                            >
                                <router-link
                                    class="text-dark"
                                    :to="{ name: 'partner-disbursement' }"
                                    >Kembali</router-link
                                >
                            </button>
                            <button
                                type="button"
                                class="btn btn-green px-4"
                                @click="store()"
                                :disabled="receipt.length == 0"
                            >
                                Cairkan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overflow-auto mt-3 mb-5">
            <b-pagination-nav
                v-if="lists.length > 0"
                v-model="currentPage"
                :number-of-pages="totalPage"
                base-url="#"
                first-number
                align="right"
                @input="changePage"
            ></b-pagination-nav>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalDeatailReceipt"></div>
            <div class="modal-body-chat sm" v-if="modalDeatailReceipt">
                <h4 class="mb-0 fw-bold">Detail Income</h4>
                <hr />
                <div v-for="(data, index) in data_receipt.incomes" :key="index">
                    <div class="d-flex align-items-center mt-3">
                        <div class="fw-bold size-14">{{ data.label }}</div>
                        <div class="fw-bold ms-auto size-14">
                            {{ currency(data.amount) }}
                        </div>
                    </div>
                </div>
                <hr />
                <div class="d-flex align-items-center mt-3">
                    <div class="fw-bold size-18">Total</div>
                    <div class="fw-bold ms-auto size-18">
                        {{ currency(data_receipt.total) }}
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="modalDeatailReceipt = false"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";

export default {
    components: {
        ListLoader,
    },
    name: "IncomeBusinessPartner",
    data() {
        return {
            visible: false,
            isSaving: false,
            items: [
                {
                    no: 1,
                    kode_mitra: "MB-JKT-01",
                    jumlah_request: "Rp. 18.265.355,00",
                    jumlah_pencairan: "Rp. 609.590,00",
                    tanggal: "Mon, 05 Sep 2022",
                    status: "Request",
                },
                {
                    no: 2,
                    kode_mitra: "MB-JKT-01",
                    jumlah_request: "Rp. 18.265.355,00",
                    jumlah_pencairan: "Rp. 609.590,00",
                    tanggal: "Mon, 05 Sep 2022",
                    status: "Request",
                },
                {
                    no: 3,
                    kode_mitra: "MB-JKT-01",
                    jumlah_request: "Rp. 18.265.355,00",
                    jumlah_pencairan: "Rp. 609.590,00",
                    tanggal: "Mon, 05 Sep 2022",
                    status: "Approved",
                },
                {
                    no: 4,
                    kode_mitra: "MB-JKT-01",
                    jumlah_request: "Rp. 18.265.355,00",
                    jumlah_pencairan: "Rp. 609.590,00",
                    tanggal: "Mon, 05 Sep 2022",
                    status: "Approved",
                },
            ],
            itemsDetail: [
                {
                    no_resi: "TRB19122200003",
                    total_payement: "Rp. 99.352.462",
                    total_penerimaan: "Rp. 18.265.355,00",
                },
                {
                    no_resi: "TRB19122200004",
                    total_payement: "Rp. 99.352.462",
                    total_penerimaan: "Rp. 18.265.355,00",
                },
                {
                    no_resi: "TRB19122200005",
                    total_payement: "Rp. 99.352.462",
                    total_penerimaan: "Rp. 18.265.355,00",
                },
                {
                    no_resi: "TRB19122200006",
                    total_payement: "Rp. 99.352.462",
                    total_penerimaan: "Rp. 18.265.355,00",
                },
            ],
            isDetail: false,
            receipt: [],
            approved_at: "",
            transferred_at: "",
            lists: [],
            data_total: {},
            partner_code: "",
            attachment_transfer: "",
            images: null,
            imageData: "",
            total: 0,
            total_unapproved: "",
            total_approved: "",
            dismissSecs: 3,
            is_my_list_ready: false,
            dismissCountDown: 0,
            noReceipt: "",
            filter: {
                date_start: null,
                date_end: null,
            },
            modalDeatailReceipt: false,
            detail_data: {},
            data_receipt: {
                incomes: [],
                total: 0,
            },
            currentPage: 0,
            totalPage: 0,
            limit: 10,
            filter_status: null,
        };
    },
    created() {
        this.getData();
    },
    mounted() {},
    computed: {
        partnerCode() {
            let code = "";
            if (this.partner_code != null) {
                code = this.partner_code;
            }
            if (code != "") {
                code = ": " + code;
            }
            return code;
        },
    },
    methods: {
        openModalReceipt(item) {
            this.receipt_code = item.receipt;
            this.getDataReceipt();
        },
        getDataReceipt() {
            var data = {
                receipt_code: this.receipt_code,
                partner_code: this.detail_data.partner_code,
            };
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/internal/finance/withdrawal/components`,
                {
                    params: data,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data_receipt = res.data.data;
                    this.modalDeatailReceipt = true;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        searchReceipt() {
            if (this.noReceipt != "") {
                this.getSearch();
            } else {
                this.getData();
            }
        },
        changePage() {
            // this.getData();
        },
        getSearch() {
            this.is_my_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/internal/finance/withdrawal/detail/${this.$route.params.partnerid}/find/receipt`,
                {
                    params: { receipt: this.noReceipt },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.lists = res.data.data;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        onCancel() {
            this.visible = false;
        },
        showModal() {
            this.visible = true;
        },
        setDate(date) {
            return moment(date).format("ddd, DD MMM YYYY HH:mm:ss");
        },
        toggleDetail() {
            this.isDetail = true;
        },
        store() {
            var receipt = [];
            this.receipt.forEach((item) => {
                var object = item.receipt;
                receipt.push(object);
            });

            Api.post(
                `${process.env.VUE_APP_BASE_URL}/internal/finance/withdrawal/detail/${this.$route.params.partnerid}/approve`,
                {
                    receipt,
                },
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.getData();
                    this.approved_at = res.data.data.action_at;
                    this.receipt = [];
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Success",
                    });
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                });
        },
        uploadFile(event) {
            this.images = this.$refs.file.files[0];

            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.imageData = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
        submitFile() {
            //   this.dismissCountDown = this.dismissSecs;
            this.isSaving = true;
            const formData = new FormData();
            formData.append("attachment_transfer", this.images);

            Api.post(
                `${process.env.VUE_APP_BASE_URL}/internal/finance/withdrawal/attachment_transfer/${this.$route.params.partnerid}`,
                formData,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Bukti transfer berhasil di simpan",
                    });
                    this.visible = false;
                    this.isSaving = false;
                    window.location.href = "/trawlpack/ho/disbursement/partner";
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    this.isSaving = false;
                    console.log(err);
                });
        },
        getData() {
            this.is_my_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/internal/finance/withdrawal/detail/${this.$route.params.partnerid}/ajax`,
                {
                    params: {
                        date_start: this.filter.date_start,
                        date_end: this.filter.date_end,
                        status: this.filter_status,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detail_data = res.data.data;
                    this.lists = res.data.data.rows;
                    this.data_total = res.data.data;
                    this.partner_code = res.data.data.partner_code;
                    this.total_unapproved = res.data.data.total_unapproved;
                    this.total_approved = res.data.data.total_approved;
                    this.total = this.total_unapproved + this.total_approved;
                    this.approved_at = res.data.data.approved_at;
                    this.transferred_at = res.data.data.transferred_at;
                    this.attachment_transfer =
                        res.data.data.attachment_transfer;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs;
        },
    },
};
</script>

<style lang="scss">
.text-green {
    color: #3d8824;
}
.primary {
    background: #3d8824;
    border-radius: 8px;
    color: #ffffff;
}
.text-gray {
    color: #bfbfbf;
}
.fw-medium {
    font-weight: 600;
}
.scroll {
    height: 500px;
    overflow-y: scroll;
}
.row.foto {
    --bs-gutter-x: 0rem !important;
}
.red-color {
    color: #e60013 !important;
}
.mr-1 {
    margin-right: 10px;
}
.mb-table {
    margin-bottom: 100px;
}
.mt-2 {
    margin-top: 20px;
}
.mb-1 {
    margin-bottom: 10px;
}
.w-100 {
    width: 100% !important;
}
.image-success-upload {
    border: 2px solid #e8e8e8;
    border-radius: 10px;
    padding: 10px;
}
.image-success-upload img {
    width: 100%;
}
.pr-2 {
    padding-right: 30px;
}
.pl-2 {
    padding-left: 23px;
}
.wrapper {
    position: fixed;
    bottom: 0;
    left: 311px;
    right: 41px;
    z-index: 1;
}
</style>
