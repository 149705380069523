<template>
    <div class="padding-container">
        <div class="content-load" v-if="loading_data"></div>
        <div id="loader" v-if="loading_data"></div>
        <div class="fw-bold">
            <a href="/trawltruck/ho/order" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="box mt-4">
                    <div class="d-flex align-items-center">
                        <h4 class="fw-bold mb-0">Informasi Basic</h4>
                        <div class="ms-2">
                            <small
                                class="tag green-cro mb-0 pb-0 px-3 radius"
                                v-if="detail.create_via == 'app'"
                            >
                                By App
                            </small>
                            <small
                                class="tag blue-cro mb-0 pb-0 px-3 radius"
                                v-else
                            >
                                On Call
                            </small>
                        </div>
                        <div class="ms-auto">
                            <a
                                href="javascript:void(0)"
                                @click="is_edit_basic = !is_edit_basic"
                                v-if="!is_edit_basic"
                            >
                                Edit <i class="fa fa-pencil ms-1"></i>
                            </a>
                            <a
                                href="javascript:void(0)"
                                class="text-danger"
                                @click="is_edit_basic = !is_edit_basic"
                                v-else
                            >
                                Batal <i class="fa fa-close ms-1"></i>
                            </a>
                        </div>
                    </div>
                    <table class="w-100 ps-0 fw-bold mt-4" cellpadding="10">
                        <tr class="border-top">
                            <td class="ps-0 w-160">Nomor Resi</td>
                            <td class="w-40">:</td>
                            <td>
                                {{ detail.receipt_code }}
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-160">Jadwal Pemesanan</td>
                            <td class="w-40">:</td>
                            <td>
                                <date-picker
                                    v-model="beginpickup_at"
                                    type="datetime"
                                    value-type="format"
                                    format="DD/MM/YYYY HH:mm"
                                    :disabled="!is_edit_basic"
                                    v-bind:class="
                                        !is_edit_basic ? 'view' : 'edit'
                                    "
                                    :clearable="false"
                                    placeholder="-"
                                    style="width: 250px"
                                >
                                </date-picker>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-160">Nama Pengirim</td>
                            <td class="w-40">:</td>
                            <td>
                                <input
                                    class="form-control fw-bold"
                                    type="text"
                                    placeholder="-"
                                    v-model="detail.origin.name"
                                    :disabled="!is_edit_basic"
                                    v-bind:class="
                                        !is_edit_basic ? 'view' : 'edit'
                                    "
                                />
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-160">No Telp Pengirim</td>
                            <td class="w-40">:</td>
                            <td>
                                <input
                                    class="form-control fw-bold"
                                    type="number"
                                    placeholder="-"
                                    v-model="detail.origin.phone"
                                    :disabled="!is_edit_basic"
                                    v-bind:class="
                                        !is_edit_basic ? 'view' : 'edit'
                                    "
                                />
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-160">Lokasi Penjemputan</td>
                            <td class="w-40">:</td>
                            <td>
                                <a
                                    href="javascript:void(0)"
                                    class="text-dark limit-one-line"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="detail?.origin?.address"
                                >
                                    {{ detail?.origin?.address ?? "-" }}
                                </a>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-160">
                                Detail Alamat Penjemputan
                            </td>
                            <td class="w-40">:</td>
                            <td>
                                <input
                                    class="form-control fw-bold"
                                    type="text"
                                    placeholder="-"
                                    v-model="detail.origin.note"
                                    :disabled="!is_edit_basic"
                                    v-bind:class="
                                        !is_edit_basic ? 'view' : 'edit'
                                    "
                                />
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-160">Nama Penerima</td>
                            <td class="w-40">:</td>
                            <td>
                                <input
                                    class="form-control fw-bold"
                                    type="text"
                                    placeholder="-"
                                    v-model="detail.destination.name"
                                    :disabled="!is_edit_basic"
                                    v-bind:class="
                                        !is_edit_basic ? 'view' : 'edit'
                                    "
                                />
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-160">No Telp Penerima</td>
                            <td class="w-40">:</td>
                            <td>
                                <input
                                    class="form-control fw-bold"
                                    type="number"
                                    placeholder="-"
                                    v-model="detail.destination.phone"
                                    :disabled="!is_edit_basic"
                                    v-bind:class="
                                        !is_edit_basic ? 'view' : 'edit'
                                    "
                                />
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-160">Lokasi Penerima</td>
                            <td class="w-40">:</td>
                            <td>
                                <a
                                    href="javascript:void(0)"
                                    class="text-dark limit-one-line"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="detail?.destination?.address"
                                >
                                    {{ detail?.destination?.address ?? "-" }}
                                </a>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-160">Detail Alamat Penerima</td>
                            <td class="w-40">:</td>
                            <td>
                                <input
                                    class="form-control fw-bold"
                                    type="text"
                                    placeholder="-"
                                    v-model="detail.destination.note"
                                    :disabled="!is_edit_basic"
                                    v-bind:class="
                                        !is_edit_basic ? 'view' : 'edit'
                                    "
                                />
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-160">Jenis Armada</td>
                            <td class="w-40">:</td>
                            <td>
                                <multiselect
                                    v-model="fleet"
                                    :options="fleets"
                                    :show-labels="false"
                                    label="name"
                                    track-by="code"
                                    placeholder="-"
                                    class="multi-form-custom edit"
                                    v-bind:class="
                                        !is_edit_basic ? 'view' : 'edit'
                                    "
                                >
                                </multiselect>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-160">Jumlah Helper</td>
                            <td class="w-40">:</td>
                            <td>
                                <input
                                    class="form-control fw-bold"
                                    type="number"
                                    placeholder="-"
                                    v-model="detail.helper_count"
                                    :disabled="!is_edit_basic"
                                    v-bind:class="
                                        !is_edit_basic ? 'view' : 'edit'
                                    "
                                />
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-160">
                                Rincian Pembayaran Customer
                            </td>
                            <td class="w-40">:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="ps-0 w-160 fw-normal p-1 ps-3">
                                Tarif Harga
                            </td>
                            <td class="w-40 fw-normal p-1 ps-total">:</td>
                            <td class="fw-normal p-1">
                                {{ currency(detail?.service_price ?? 0) }}
                            </td>
                        </tr>
                        <tr>
                            <td class="ps-0 w-160 fw-normal p-1 ps-3">
                                Biaya Helper
                            </td>
                            <td class="w-40 fw-normal p-1 ps-total">:</td>
                            <td class="fw-normal p-1">
                                {{ currency(detail?.additional_price ?? 0) }}
                            </td>
                        </tr>
                        <tr>
                            <td class="ps-0 w-160 fw-normal p-1 ps-3">
                                Asuransi
                            </td>
                            <td class="w-40 fw-normal p-1 ps-total">:</td>
                            <td class="fw-normal p-1">
                                {{ currency(detail?.insurance_price ?? 0) }}
                            </td>
                        </tr>
                        <tr>
                            <td class="ps-0 w-160 fw-normal p-1 ps-3">
                                Biaya Layanan
                            </td>
                            <td class="w-40 fw-normal p-1 ps-total">:</td>
                            <td class="fw-normal p-1">
                                {{ currency(detail?.service_price ?? 0) }}
                            </td>
                        </tr>
                        <tr>
                            <td class="ps-0 w-160 fw-normal p-1 ps-3">
                                Total Pembayaran
                            </td>
                            <td class="w-40 fw-normal p-1 ps-total">:</td>
                            <td class="fw-normal p-1">
                                {{ currency(detail?.total_price ?? 0) }}
                            </td>
                        </tr>
                    </table>
                    <div class="mt-3" v-if="is_edit_basic">
                        <button
                            class="btn btn-green w-100"
                            @click="storeBasic()"
                            :disabled="is_store_basic"
                        >
                            <span
                                v-if="is_store_basic"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Simpan Perubahan
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="box mt-4">
                    <div class="d-flex align-items-center">
                        <h4 class="fw-bold mb-0">Informasi Barang</h4>
                        <div class="ms-auto">
                            <a
                                href="javascript:void(0)"
                                @click="is_edit_good = !is_edit_good"
                                v-if="!is_edit_good"
                            >
                                Edit <i class="fa fa-pencil ms-1"></i>
                            </a>
                            <a
                                href="javascript:void(0)"
                                class="text-danger"
                                @click="is_edit_good = !is_edit_good"
                                v-else
                            >
                                Batal <i class="fa fa-close ms-1"></i>
                            </a>
                        </div>
                    </div>
                    <table class="w-100 ps-0 fw-bold mt-4" cellpadding="10">
                        <tr class="border-top">
                            <td class="ps-0 w-160">Deskripsi Barang</td>
                            <td class="w-40">:</td>
                            <td class="pe-0 ps-0">
                                <template v-if="!is_edit_good">
                                    {{ detail.note }}
                                </template>
                            </td>
                        </tr>
                        <tr v-if="is_edit_good">
                            <td class="p-0" colspan="3">
                                <textarea
                                    rows="2"
                                    cols="30"
                                    class="form-control mb-3"
                                    v-model="detail.note"
                                ></textarea>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-160">Harga Barang</td>
                            <td class="w-40">:</td>
                            <td class="ps-0 pe-0">
                                <template v-if="!is_edit_good">
                                    {{
                                        currency(
                                            detail.meta.good_price
                                                ? detail.meta.good_price
                                                : 0
                                        )
                                    }}
                                </template>
                                <div class="input-group" v-if="is_edit_good">
                                    <span class="input-group-text"> Rp </span>
                                    <money
                                        v-model="detail.meta.good_price"
                                        v-bind="money"
                                        class="form-control"
                                        placeholder="0"
                                    >
                                    </money>
                                </div>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-160">Foto Barang</td>
                            <td class="w-40">:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="4" class="p-0">
                                <div class="row">
                                    <template v-if="detail.photos.length > 0">
                                        <div
                                            class="col-md-3 mb-3 position-relative"
                                            v-for="(
                                                photo, index
                                            ) in detail.photos"
                                            :key="index"
                                        >
                                            <div
                                                class="delete-icon"
                                                @click="
                                                    modalRemoveImage(photo.path)
                                                "
                                                v-if="is_edit_good"
                                            >
                                                <i class="fa fa-trash"></i>
                                            </div>
                                            <viewer
                                                :images="[]"
                                                :options="options"
                                            >
                                                <img
                                                    class="photo"
                                                    :key="photo.url"
                                                    :src="photo.url"
                                                />
                                            </viewer>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div
                                            class="col-md-3"
                                            v-if="!is_edit_good"
                                        >
                                            <img
                                                class="photo"
                                                src="../../assets/empty-image.png"
                                            />
                                        </div>
                                    </template>
                                    <template v-if="is_edit_good">
                                        <div
                                            class="col-md-3 mb-3"
                                            v-if="detail.photos.length < 4"
                                        >
                                            <label
                                                class="upload__btn no-border"
                                            >
                                                <input
                                                    type="file"
                                                    :class="`images[] upload__inputfile`"
                                                    @change="storeImage()"
                                                />
                                                <div>
                                                    <p
                                                        class="mb-0"
                                                        style="color: #adb5bd"
                                                    >
                                                        <i
                                                            class="fa fa-plus"
                                                        ></i>
                                                    </p>
                                                </div>
                                            </label>
                                        </div>
                                    </template>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <div class="mt-3" v-if="is_edit_good">
                        <button
                            class="btn btn-green w-100"
                            @click="storeGood()"
                            :disabled="is_store_good"
                        >
                            <span
                                v-if="is_store_good"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Simpan Perubahan
                        </button>
                    </div>
                </div>
                <div class="box mt-4">
                    <div class="d-flex align-items-center">
                        <h4 class="fw-bold mb-0">Mitra Penjemputan</h4>
                        <div class="ms-auto">
                            <a
                                href="javascript:void(0)"
                                @click="is_edit_mitra = !is_edit_mitra"
                                v-if="!is_edit_mitra"
                            >
                                Edit <i class="fa fa-pencil ms-1"></i>
                            </a>
                            <a
                                href="javascript:void(0)"
                                class="text-danger"
                                @click="is_edit_mitra = !is_edit_mitra"
                                v-else
                            >
                                Batal <i class="fa fa-close ms-1"></i>
                            </a>
                        </div>
                    </div>
                    <table class="w-100 ps-0 fw-bold mt-4" cellpadding="10">
                        <tr class="border-top">
                            <td class="ps-0 w-160">Mitra Pickup</td>
                            <td class="w-40">:</td>
                            <td>
                                <multiselect
                                    v-model="partner"
                                    :options="partners"
                                    :show-labels="false"
                                    label="name"
                                    track-by="id"
                                    placeholder="-"
                                    class="multi-form-custom edit"
                                    v-bind:class="
                                        !is_edit_mitra ? 'view' : 'edit'
                                    "
                                >
                                </multiselect>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-160">Komisi Mitra</td>
                            <td class="w-40">:</td>
                            <td>
                                <template v-if="!is_edit_mitra">
                                    {{ currency(detail?.income_partner) }}
                                </template>
                                <div class="input-group" v-if="is_edit_mitra">
                                    <span class="input-group-text"> Rp </span>
                                    <money
                                        v-model="detail.income_partner"
                                        v-bind="money"
                                        class="form-control"
                                        placeholder="0"
                                    >
                                    </money>
                                </div>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-160">Helper</td>
                            <td class="w-40">:</td>
                            <td>
                                <template v-if="!is_edit_mitra">
                                    {{
                                        currency(detail?.additional_price ?? 0)
                                    }}
                                </template>
                                <div class="input-group" v-if="is_edit_mitra">
                                    <span class="input-group-text"> Rp </span>
                                    <money
                                        v-model="detail.additional_price"
                                        v-bind="money"
                                        class="form-control"
                                        placeholder="0"
                                    >
                                    </money>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <div class="mt-3" v-if="is_edit_mitra">
                        <button
                            class="btn btn-green w-100"
                            @click="storeMitra()"
                            :disabled="is_store_mitra"
                        >
                            <span
                                v-if="is_store_mitra"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Simpan Perubahan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="isModalDeleteImage"></div>
            <div class="modal-body-chat vsm" v-if="isModalDeleteImage">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Apakah Anda Yakin Ingin Menghapus Foto Ini?
                    </h4>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6">
                        <button
                            class="btn btn-outline-red w-100 btn-lg"
                            @click="isModalDeleteImage = false"
                        >
                            Batal
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button
                            @click="removeImage()"
                            class="btn btn-green w-100 btn-lg"
                            :disabled="is_delete_image"
                        >
                            <span
                                v-if="is_delete_image"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
import { Tooltip } from "bootstrap";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import { Money } from "v-money";
import $ from "jquery";

export default {
    name: "ManagementHoOrderDetail",
    components: {
        Viewer,
        DatePicker,
        Multiselect,
        Money,
    },
    data() {
        return {
            moment: moment,
            loading_data: false,
            detail: {
                driver: {},
                origin: {},
                destination: {},
                customer_detail: {},
                meta: {},
                photos: [],
            },
            isShowMitraInfo: true,
            isShowOrderTypeInfo: true,
            showModal: false,
            photos: [],
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
            is_edit_basic: false,
            beginpickup_at: "",
            fleet: {
                name: "",
                code: "",
            },
            fleets: [],
            is_edit_good: false,
            is_store_basic: false,
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 0,
                masked: false,
            },
            is_store_good: false,
            maxImages: 4,
            addImage: "button.add-image",
            images: [],
            isModalDeleteImage: false,
            photo_path: null,
            is_delete_image: false,
            is_edit_mitra: false,
            is_store_mitra: false,
            partner: null,
            partners: [],
        };
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    created() {
        this.getDetail();
        this.getFleets();
    },
    methods: {
        getDetail() {
            this.loading_data = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/detail?id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.loading_data = false;
                    var data = res.data.data;
                    this.detail = data;
                    this.beginpickup_at = moment(data.beginpickup_at).format(
                        "DD/MM/YYYY HH:mm"
                    );
                    this.fleet.name = data.type_fleet_label;
                    this.fleet.code = data.type_fleet;
                    this.partner = data.assigned_partner;
                    this.getPartners();
                })
                .catch((err) => {
                    this.loading_data = false;
                    console.log(err);
                });
        },
        getFleets() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/trawltruck-fleet`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.fleets = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        showModalImage(label) {
            this.showModal = true;
            this.photos = label.photos;
        },
        storeBasic() {
            this.is_store_basic = true;
            var data = {
                order_id: this.$route.params.id,
                type_fleet: this.fleet?.code,
                pickup_schedule: moment(this.pickup_schedule).format(
                    "YYYY-MM-DD HH:mm:ss"
                ),
                origin_name: this.detail.origin.name,
                origin_phone: this.detail.origin.phone,
                origin_note: this.detail.origin.note,
                dest_name: this.detail.destination.name,
                dest_phone: this.detail.destination.phone,
                dest_note: this.detail.destination.note,
                helper_count: this.detail.helper_count,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/update_info`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_store_basic = false;
                    this.is_edit_basic = false;
                    this.getDetail();
                    console.log(res);
                })
                .catch((err) => {
                    this.is_store_basic = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        storeGood() {
            this.is_store_good = true;
            var data = {
                order_id: this.$route.params.id,
                note: this.detail.note,
                good_price: this.detail.meta.good_price,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/update_good`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_store_good = false;
                    this.is_edit_good = false;
                    this.getDetail();
                    console.log(res);
                })
                .catch((err) => {
                    this.is_store_good = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        addNewImage() {
            this.images.push("");
        },
        storeImage() {
            var data = new FormData();
            data.append("order_id", this.$route.params.id);
            $('[class~="images[]"]', this.el).each(function () {
                data.append("photo", this.files[0]);
            });

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/add_picture`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.getDetail();
                    this.is_edit_good = true;
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        modalRemoveImage(path) {
            this.isModalDeleteImage = true;
            this.photo_path = path;
        },
        removeImage() {
            this.is_delete_image = true;
            var data = {
                order_id: this.$route.params.id,
                photo_path: this.photo_path,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/remove_picture`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_delete_image = false;
                    this.is_edit_good = true;
                    this.isModalDeleteImage = false;
                    this.getDetail();
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                    this.is_delete_image = false;
                });
        },
        getPartners() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/oncall/partner?type_fleet=${this.detail.type_fleet}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.partners = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        storeMitra() {
            this.is_store_mitra = true;
            var data = {
                order_id: this.$route.params.id,
                assign_order: this.partner?.id,
                additional_price: this.detail.additional_price,
                commission_partner: this.detail.income_partner,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/update_assign`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_store_mitra = false;
                    this.is_edit_mitra = false;
                    this.getDetail();
                    console.log(res);
                })
                .catch((err) => {
                    this.is_store_mitra = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
    },
};
</script>

<style scoped>
td {
    /* vertical-align: top; */
}
.w-150 {
    width: 150px;
}
.w-160 {
    width: 160px;
}
.w-40 {
    width: 40px;
}
.text-yellow-bold {
    color: #d17e20;
}
.image-cover {
    border-radius: 10px;
    border: 2px solid #e2e3e5;
    width: 100%;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
}
.ps-total {
    padding-left: 10px !important;
}
.photo {
    border-radius: 10px;
    width: 100%;
    height: 80px;
    object-fit: cover;
    border: 1px solid #e9ecef;
    cursor: pointer;
}
.view {
    border-color: transparent;
    background-color: transparent !important;
    color: initial;
    padding: 0px;
}
.form-control.edit:disabled {
    background: transparent !important;
    font-weight: 600;
}
.form-control.edit {
    padding: 2px 8px;
    color: #000;
    background: transparent !important;
}
.form-control::placeholder {
    color: #000;
}
.upload__inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #f8f9fa;
    border-style: dashed;
    border-radius: 10px;
    width: 100%;
    height: 80px;
    padding: 23px;
    position: relative;
}
.delete-icon {
    position: absolute;
    right: 14px;
    top: 6px;
    color: #e60013;
    font-size: 14px;
    padding: 2px;
    background: #fff;
    border-radius: 50px;
    text-align: center;
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 9;
}
.upload__btn p {
    font-size: 23px;
}
</style>
