<script>
  import { ContentLoader } from "vue-content-loader"

  export default {
    components: { ContentLoader }
  }
</script>

<template>
    <div class="d-flex">
        <content-loader
            :speed="3"
            primaryColor="#f8f9fa"
            secondaryColor="#e2e3e5"
        >
            <rect x="0" y="0" rx="8" ry="8" width="185" height="120" />
            <rect x="200" y="0" rx="8" ry="8" width="185" height="120" /> 
        </content-loader>
        <content-loader
            :speed="3"
            primaryColor="#f8f9fa"
            secondaryColor="#e2e3e5"
        >
            <rect x="0" y="0" rx="8" ry="8" width="185" height="120" />
            <rect x="200" y="0" rx="8" ry="8" width="185" height="120" /> 
        </content-loader>
    </div>
</template>