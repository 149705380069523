<template>
  <div class="padding-container">
    <div class="d-flex align-items-center mb-4">
      <h3 class="fw-bold">Manajemen Kendaraan</h3>
      <div class="ms-auto">
        <button class="add-button" @click="setModalAdd">
          Tambah Kendaraan <span class="ms-4"><img class="pb-1" src="../../assets/plus-icon.png" /></span>
        </button>
      </div>
    </div>
    <div class="box">
      <div class="d-flex align-items-center">
        <h3 class="mb-0 fw-bold">Daftar Kendaraan</h3>
        <div class="ms-auto">
          <div class="position-relative">
            <input v-model="keySearch" @keyup.enter="searchData" type="text" class="form-control" style="width: 155px; padding-right: 30px" placeholder="Search" />
            <span class="search-icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="mt-4" v-if="is_my_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">Jenis Kendaraan</div>
            <div class="d-table-cell">Nomor Polisi</div>
            <div class="d-table-cell">Driver</div>
            <div class="d-table-cell">Tahun Kendaraan</div>
            <div class="d-table-cell">Status</div>
            <div class="d-table-cell">Action</div>
          </div>
          <div class="d-table-row" v-for="(item, index) in data" :key="index">
            <div class="d-table-cell">{{ numbering(index) }}</div>
            <div class="d-table-cell">
              {{ item.type }}
            </div>
            <div class="d-table-cell">
              {{ item.registration_number }}
            </div>
            <div class="d-table-cell">{{ item.name }}</div>
            <div class="d-table-cell">{{ item.year }}</div>
            <div class="d-table-cell">
              <div class="tag_stat blue" v-if="item.status == 'Active'">Aktif</div>
              <div class="tag_stat red" v-else-if="item.status == 'Non Active'">Non Aktif</div>
              <div class="tag_stat orange" v-else>Request</div>
            </div>
            <div class="d-table-cell">
              <div class="d-flex gap-2">
                <a href="javascript:void(0)" @click="setEdit(item)"><img src="../../assets/edit-icon.png" /></a>
                <a href="javascript:void(0)" @click="setDelete(item.hash)"><img src="../../assets/delete-icon.png" /></a>
              </div>
            </div>
          </div>
        </div>

        <div v-if="data.length == 0" class="text-center mt-section">
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
    </div>
    <div class="overflow-auto mt-3">
      <b-pagination-nav v-if="data.length > 0" v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="editModal || addModal" @click="close"></div>
      <div class="modal-body-chat md" v-if="editModal || addModal">
        <div class="d-flex bd-highlight align-items-lg-center">
          <div class="flex-grow-1 bd-highlight">
            <img src="../../assets/flag-icon.png" />
          </div>
          <div class="bd-highlight">
            <a href="javascript:void(0)" @click="close" class="size-20 text-primary">
              <i class="fa fa-times-circle"></i>
            </a>
          </div>
        </div>
        <div class="size-18 fw-ekstra-bold mt-4">{{ addModal ? "Tambah Kendaraan" : "Ubah Data Kendaraan" }}</div>
        <div>{{ addModal ? "Masukan data lengkap kendaraan baru" : "Ubah data kendaraan" }}</div>
        <div class="line"></div>
        <div class="scroll">
          <div class="mb-2">
            <div class="size-16 mt-3 mb-1">Jenis Kendaraan</div>
            <select class="mb-2 form-input" v-model="req.type">
              <option value="" selected hidden>Pilih Jenis Kendaraan</option>
              <option v-for="(item, index) in dataTransporter" :key="index" :value="item.name">{{ capitalizeFirstLetter(item.name) }}</option>
            </select>

            <div class="size-16 mt-3 mb-1">Nomor Polisi</div>
            <input class="mb-2 form-input" v-model="req.registration_number" placeholder="Nomor Polisi" />

            <div class="size-16 mt-3 mb-1">Tahun Kendaraan</div>
            <input class="mb-2 form-input" v-model="req.production_year" placeholder="Tahun Kendaraan" />

            <div class="size-16 mt-3 mb-1">Nomor Rangka</div>
            <input class="mb-2 form-input" v-model="req.vehicle_number" placeholder="Nomor Rangka" />

            <div class="size-16 mt-3 mb-1">Nama STNK</div>
            <input class="mb-2 form-input" v-model="req.registration_name" placeholder="Nama STNK" />

            <div v-if="!addModal">
              <div class="size-16 mt-3 mb-1">Status</div>
              <select class="mb-2 form-input" v-model="req.status">
                <option value="" selected hidden>Pilih Status</option>
                <option :value="1">Aktif</option>
                <option :value="0">Tidak Aktif</option>
              </select>
            </div>
          </div>
          <div class="row mb-2">
            <div class="size-16 fw-bold mb-1">Foto Kendaraan</div>
            <div class="col-md-6">
              <div class="position-relative" style="width: 100%">
                <EnlargeableImage class="preview-2" :src="identityFleet1Url" />
                <label for="upload-identity" class="upload-icon cursor-pointer">
                  <i class="fa fa-upload"></i>
                  <input class="cursor-pointer" id="upload-identity" type="file" name="file" ref="file" @change="uploadFleet1" />
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="position-relative" style="width: 100%">
                <EnlargeableImage class="preview-2" :src="licenseFleet2Url" />
                <label for="upload-license" class="upload-icon cursor-pointer">
                  <i class="fa fa-upload"></i>
                  <input class="cursor-pointer" id="upload-license" type="file" name="file" ref="file" @change="uploadFleet2" />
                </label>
              </div>
            </div>
          </div>
          <div>
            <div class="size-16 fw-bold mb-1">Foto STNK</div>
            <div class="position-relative" style="width: 100%">
              <EnlargeableImage class="preview" style="width: 100%" :src="STNKPictureUrl" />
              <label for="upload" class="upload-icon upload-button cursor-pointer">
                <i class="fa fa-upload"></i>
                <input class="cursor-pointer" id="upload" type="file" name="file" ref="file" @change="uploadSTNK" />
              </label>
            </div>
          </div>
          <div>
            <div class="size-16 mt-3 mb-1">Driver</div>
            <select class="mb-2 form-input" v-model="req.driver">
              <option value="" selected hidden>Pilih Driver</option>
              <option v-for="(item, index) in dataDriver" :key="index" :value="item.name">{{ capitalizeFirstLetter(item.name) }}</option>
            </select>
          </div>
        </div>
        <div class="d-flex gap-2 mt-5">
          <button class="button button-cancel" @click="cancel" :disabled="isSaving">Batal</button>
          <button v-if="editModal" class="button button-save" @click="editData" :disabled="isSaving">
            <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
            Simpan
          </button>
          <button v-if="addModal" class="button button-save" @click="addData" :disabled="isSaving">
            <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
            Tambahkan
          </button>
        </div>
      </div>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="deleteModal" @click="deleteModal = false"></div>
      <div class="modal-body-chat sm" v-if="deleteModal">
        <div class="d-flex align-items-lg-center justify-content-center flex-column">
          <img class="mb-3" src="../../assets/delete-circle-icon.png" />
          <div class="size-18 fw-ekstra-bold">Hapus Data</div>
        </div>
        <div class="d-flex align-items-lg-center justify-content-center fw-bold mb-5 mt-4">Apakah Anda yakin ingin menghapus data ini?</div>
        <div class="d-flex gap-2">
          <button class="button button-cancel" @click="deleteModal = false" :disabled="isSaving">Batal</button>
          <button class="button button-delete" @click="deleteData" :disabled="isSaving">
            <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
            Ya
          </button>
        </div>
      </div>
    </div>

    <PopupNotif :isModal="isModal" :title="title" :info="info" @send="receive"></PopupNotif>
  </div>
</template>
<script>
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";
import EnlargeableImage from "@diracleo/vue-enlargeable-image";
import noImage from "../../assets/no-photo.png";
import PopupNotif from "./PopupNotif.vue";

export default {
  props: {
    items: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ListLoader,
    EnlargeableImage,
    PopupNotif,
  },
  name: "Transporter",
  data() {
    return {
      moment: moment,
      isSaving: false,
      showPassword: false,
      is_my_list_ready: false,
      editModal: false,
      deleteModal: false,
      isModal: "",
      title: "",
      info: "",
      addModal: false,
      keySearch: "",
      hash: "",
      data: "",
      imageSTNK: "",
      imageFleet1: "",
      imageFleet2: "",
      req: {
        type: "",
        registration_number: "",
        registration_name: "",
        production_year: "",
        vehicle_number: "",
        status: "",
        driver: "",
        vehicler_reg: null,
        fleet1: null,
        fleet2: null,
      },
      currentPage: 0,
      totalPage: 0,
      limit: 10,
    };
  },
  created() {
    this.getData();
    this.getTransporter();
    this.getDriver();
  },
  methods: {
    searchData() {
      this.getData();
    },
    changePage() {
      this.getData();
    },
    setModalAdd() {
      this.clear();
      this.addModal = true;
    },
    numbering(index) {
      let number = this.itemNumber(index);
      return (number < 10 ? "0" : "") + number;
    },
    receive(data) {
      this.isModal = data;
    },
    capitalizeFirstLetter(str) {
      return str
        .split(" ")
        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
    },
    close() {
      this.addModal = false;
      this.editModal = false;
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    setEdit(item) {
      this.req.type = item.type;
      this.req.registration_number = item.registration_number;
      this.req.registration_name = item.name;
      this.req.registration_year = item.year;
      this.req.production_year = item.year;
      this.req.vehicle_number = item.vehicle_number ? item.vehicle_number : "";
      this.req.status = item.is_active ? item.is_active : "";
      this.req.driver = item.driver ? item.driver : "";
      this.imageFleet1 = item.images ? item.images[0]?.url : "";
      this.imageFleet2 = item.images ? item.images[1]?.url : "";
      this.imageSTNK = item.vehicle_identification ? item.vehicle_identification : "";
      this.editModal = true;
      this.hash = item.hash;
    },
    setDelete(hash) {
      this.deleteModal = true;
      this.hash = hash;
    },
    cancel() {
      this.close();
      this.clear();
    },
    clear() {
      this.req.type = "";
      this.req.registration_number = "";
      this.req.registration_name = "";
      this.req.registration_year = "";
      this.req.production_year = "";
      this.req.vehicle_number = "";
      this.req.status = "";
      this.req.driver = "";
      this.req.fleet1 = "";
      this.req.fleet2 = "";
      this.req.vehicler_reg = "";
      this.imageSTNK = "";
      this.imageFleet1 = "";
      this.imageFleet2 = "";
    },
    getData() {
      this.is_my_list_ready = true;
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/asset`, {
        params: {
          page: this.currentPage == 0 || this.currentPage == null ? 1 : this.currentPage,
          per_page: this.limit,
          search: this.keySearch ? this.keySearch : "''",
          type: "transporter",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data = res.data.data;
          this.totalPage = res.data.last_page;
          this.is_my_list_ready = false;
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.is_my_list_ready = false;
        });
    },
    addData() {
      this.isSaving = true;
      if (!this.req.fleet1) {
        this.$notify({
          group: "foo",
          type: "error",
          title: "Error",
          text: `Foto kendaraan 1 belum di isi`,
        });
        this.isSaving = false;
        return;
      }
      if (!this.req.fleet2) {
        this.$notify({
          group: "foo",
          type: "error",
          title: "Error",
          text: `Foto kendaraan 2 belum di isi`,
        });
        this.isSaving = false;
        return;
      }
      if (!this.req.vehicler_reg) {
        this.$notify({
          group: "foo",
          type: "error",
          title: "Error",
          text: `Foto STNK belum di isi`,
        });
        this.isSaving = false;
        return;
      }
      var data = new FormData();

      data.append("type", this.req.type);
      data.append("registration_number", this.req.registration_number);
      data.append("registration_name", this.req.registration_name);
      data.append("registration_year", this.req.production_year);
      data.append("production_year", this.req.production_year);
      data.append("vehicle_number", this.req.vehicle_number);
      data.append("driver", this.req.driver);
      data.append("photo[0]", this.req.fleet1);
      data.append("photo[1]", this.req.fleet2);
      data.append("vehicle_reg", this.req.vehicler_reg);

      Api.post(`${process.env.VUE_APP_BASE_URL}/partner/asset/transporter`, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.addModal = false;
          this.isSaving = false;
          // this.$notify({
          //   group: "foo",
          //   type: "success",
          //   title: "Success",
          //   text: "Data berhasil di edit",
          // });
          this.title = "Data Berhasil Ditambahkan!";
          this.info = "Selamat! Anda telah berhasil menambah data.";
          this.isModal = "success";
          this.getData();
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.addModal = false;
          this.title = "Data Gagal Ditambahkan!";
          this.info = "Data gagal ditambahkan! Silakan ulangi kembali";
          this.isModal = "fail";
          this.isSaving = false;
        });
    },
    editData() {
      this.isSaving = true;
      var data = new FormData();

      data.append("type", this.req.type);
      data.append("registration_number", this.req.registration_number);
      data.append("registration_name", this.req.registration_name);
      data.append("registration_year", this.req.production_year);
      data.append("production_year", this.req.production_year);
      data.append("vehicle_number", this.req.vehicle_number);
      data.append("driver", this.req.driver);
      if (this.req.status) {
        data.append("is_active", this.req.status);
      }
      if (this.req.fleet1) {
        data.append("photo[0]", this.req.fleet1);
      }
      if (this.req.fleet2) {
        data.append("photo[1]", this.req.fleet2);
      }
      if (this.req.vehicler_reg) {
        data.append("vehicle_reg", this.req.vehicler_reg);
      }

      Api.post(`${process.env.VUE_APP_BASE_URL}/partner/asset/transporter/${this.hash}/update`, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.editModal = false;
          this.isSaving = false;
          // this.$notify({
          //   group: "foo",
          //   type: "success",
          //   title: "Success",
          //   text: "Data berhasil di edit",
          // });
          this.title = "Data Berhasil Diperbarui!";
          this.info = "Selamat! Anda telah berhasil merubah data.";
          this.isModal = "success";
          this.clear();
          this.getData();
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.editModal = false;
          this.title = "Data Gagal Diperbarui!";
          this.info = "Data gagal diperbarui! Silakan ulangi kembali";
          this.isModal = "fail";
          this.isSaving = false;
        });
    },
    deleteData() {
      this.isSaving = true;

      Api.delete(`${process.env.VUE_APP_BASE_URL}/partner/asset/transporter/${this.hash}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.deleteModal = false;
          this.isSaving = false;
          // this.$notify({
          //   group: "foo",
          //   type: "success",
          //   title: "Success",
          //   text: "Data berhasil di hapus",
          // });
          this.title = "Data Berhasil Dihapus!";
          this.info = "Selamat! Anda telah berhasil menghapus data.";
          this.isModal = "success";
          this.getData();
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.deleteModal = false;
          this.title = "Data Gagal Dihapus!";
          this.info = "Data gagal dihapus! Silakan ulangi kembali";
          this.isModal = "fail";
          this.isSaving = false;
        });
    },
    getTransporter() {
      Api.get(`${process.env.VUE_APP_BASE_URL}/transporter`, {
        params: { service_code: "tps", type: "other", details: 1 },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.dataTransporter = res.data.data;
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
        });
    },
    getDriver() {
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/asset`, {
        params: { type: "employee", driver: 1 },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.dataDriver = res.data.data;
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
        });
    },
    uploadSTNK(event) {
      var input = event.target;
      this.req.vehicler_reg = input.files[0];
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageSTNK = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    uploadFleet1(event) {
      var input = event.target;
      this.req.fleet1 = input.files[0];

      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageFleet1 = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    uploadFleet2(event) {
      var input = event.target;
      this.req.fleet2 = input.files[0];

      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageFleet2 = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    dateFormat(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    currency(number) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(number);
    },
    roleEmployee(item) {
      return item.role?.length > 0 ? item.role.join(", ") : "-";
    },
    itemNumber(index) {
      return index + this.startIndex + 1;
    },
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.limit;
    },
    STNKPictureUrl() {
      return this.imageSTNK.length > 0 ? this.imageSTNK : noImage;
    },
    identityFleet1Url() {
      return this.imageFleet1.length > 0 ? this.imageFleet1 : noImage;
    },
    licenseFleet2Url() {
      return this.imageFleet2.length > 0 ? this.imageFleet2 : noImage;
    },
  },
};
</script>
<style lang="scss" scoped>
.scroll {
  height: 300px;
  overflow-y: scroll;
}
.add-button {
  background: #48a2d4;
  border-radius: 8px;
  border: 1px solid #48a2d4;
  color: white;
  padding: 10px 15px;
}
.add-button:is(:hover, :focus) {
  background: #176f9e;
}
.button {
  display: flex;
  border-radius: 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  &-save {
    background: #48a2d4;
    border: 1px solid #48a2d4;
    color: white;
  }
  &-save:is(:hover, :focus) {
    background: #176f9e;
  }
  &-delete {
    background: #e60013;
    border: 1px solid #e60013;
    color: white;
  }
  &-delete:is(:hover, :focus) {
    background: #e94b58;
  }
  &-cancel {
    background: #ffffff;
    border: 1px solid #e94b58;
    color: #e94b58;
  }
  &-cancel:is(:hover, :focus) {
    background: #e94b58;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
}
.form-input {
  background: rgba(239, 241, 249, 0.6);
  border-width: 0px;
  border: none;
  outline: none;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  padding: 10px;
}
.eye-icon {
  position: absolute;
  top: 10px;
  right: 12px;
}
input[type="file"] {
  display: none;
}
.preview :deep(img) {
  width: 39vw;
  height: 120px;
  object-fit: cover;
}
.preview-2 :deep(img) {
  width: 19vw;
  height: 100px;
  object-fit: cover;
}
.tag_stat {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: 134px;
  height: 30px;
  background: #a0d4ff;
  border-radius: 6px;
  font-weight: 600;
  color: black;
}
.tag_stat.blue {
  background: #deeee8;
}
.tag_stat.orange {
  background: #efa77e;
}
.tag_stat.red {
  background: #ef7e87;
}
</style>
