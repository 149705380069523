<template>
    <div class="padding-container">
        <div class="d-flex align-items-center bd-highlight">
            <div class="d-flex align-items-center">
                <div class="me-3">
                    <a href="/trawlcarrier/partner/wallet/disbursement" class="text-primary">
                        <i class="fa fa-angle-left size-24 fw-bold"></i>
                    </a>
                </div>
                <h3 class="fw-bold mb-0">
                    Data Request Pencairan
                </h3>
            </div>
        </div>
        <div class="box mt-4">
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader/>
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <div class="position-relative">
                            <input type="text" class="form-control" placeholder="Cari id order" v-model="req.keyword" />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-3 mt-3">
                    </div>
                    <div class="col-md-3 mt-3">
                        <button class="btn btn-green w-100" type="button" disabled v-if="is_disabled">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Request Pencairan
                        </button>
                        <button class="btn btn-green w-100" :disabled="req.receipt.length == 0" @click="requestDisbursement()" v-else>
                            Request Pencairan
                        </button>
                    </div>
                </div>
                <div class="modern-table-boxed mt-4" v-if="searchLists.length > 0">
                    <div class="d-table-row header">
                        <div class="d-table-cell">No</div>
                        <div class="d-table-cell">Id Order</div>
                        <div class="d-table-cell">Nama pengirim</div>
                        <div class="d-table-cell">Kota Asal</div>
                        <div class="d-table-cell">Kota Tujuan</div>
                        <div class="d-table-cell">Harga Layanan</div>
                    </div>
                    <template v-for="(list, index) in searchLists">
                        <div class="d-table-row" :key="index">
                            <div class="d-table-cell fw-bold">
                                {{ index+1 }}.
                            </div>
                            <div class="d-table-cell">
                                {{ list.receipt }}
                            </div>
                            <div class="d-table-cell">
                                {{ list.nama_pengirim }}
                            </div>
                            <div class="d-table-cell">
                                {{ list.kota_asal }}
                            </div>
                            <div class="d-table-cell">
                                {{ list.kota_tujuan }}
                            </div>
                            <div class="d-table-cell">
                                {{ currencyFormat(list.harga_layanan) }}
                            </div>
                            <div class="d-table-cell">
                                <div class="check">
                                    <input type="checkbox" :id="list.receipt" name="check-item" :value="list.receipt" v-model="req.receipt" />
                                    <label :for="list.receipt">
                                        <i class="fa fa-check"></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="">
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>

        <div class="mt-4">
            <b-pagination-nav v-if="searchLists.length > 0" :link-gen="linkGen" v-model="currentPage" :number-of-pages="lists.total_page" base-url="#" first-number align="right"></b-pagination-nav>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalDisbersment"></div>
            <div class="modal-body-chat sm" v-if="modalDisbersment">
                <div class="close-position">
                    <a href="javascript:void(0)" @click="modalDisbersment = false" class="size-20 text-primary">
                        <i class="fa fa-times-circle"></i>
                    </a>
                </div>
                <div>
                    <div class="text-center">
                        <center>
                            <div class="user-avatar" :style="{'background-image': 'url(' + (request_data.avatar) + ')'}" v-if="request_data.avatar != ''"></div>
                            <div class="user-avatar" :style="{ 'background-image': 'url(' + require('../../assets/user.jpg') + ')' }" v-else></div>
                        </center>
                        <h5 class="mb-0 mt-3 fw-bold">
                            {{ request_data.name }}
                        </h5>
                        <div class="mt-1 text-gray">
                            {{ request_data.code }}
                        </div>
                    </div>
                    <div class="mt-4">
                        <label for="" class="fw-normal text-gray mb-3">Jumlah Pencairan</label>
                        <money
                            disabled
                            v-model="request_data.total"
                            v-bind="money"
                            class="form-control big-form"
                        >
                        </money>
                    </div>
                    <div class="mt-4">
                        <div class="mt-3">
                            <label class="plan" style="cursor: default">
                                <div class="plan-content p-3">
                                    <div class="d-flex align-items-center">
                                        <div class="me-4">
                                            <img :src="request_data.bank_url" width="90" alt="">
                                        </div>
                                        <div>
                                            <div class="text-gray size-12">
                                                {{ request_data.bank }}
                                            </div>
                                            <h6 class="mb-0 fw-ekstra-bold size-16 mt-3">
                                                {{ request_data.account_number }}
                                            </h6>
                                            <div class="mt-1 size-14">
                                                a.n 
                                                <span class="fw-bold">
                                                    {{ request_data.account_name }}    
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <button class="btn btn-green px-5 btn-lg w-100" type="button" disabled v-if="is_store_request">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Request Sekarang
                    </button>
                    <button class="btn btn-green px-5 btn-lg w-100"  @click="storeRequest()" v-else>
                        Request Sekarang
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Money} from 'v-money'
import Api from "../../api/Api";
import ListLoader from '../../components/ListLoader.vue';

export default {
    props: {},
    components: {
        Money,
        ListLoader,
    },
    name: "DisbursementListRequest",
    data() {
        return {
            modalDisbersment: false,
            price: 0,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'Rp. ',
                suffix: '',
                precision: 0,
                masked: false
            },
            req: {
                keyword: '',
                receipt: []
            },
            lists: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0
            },
            is_list_ready: false,
            is_disabled: false,
            is_store_request: false,
            request_data: {
                account_name: '',
                account_number: '',
                avatar: '',
                bank: '',
                code: '',
                name: '',
                total: ''
            },
            per_page: 10,
            currentPage: 0,
        };
    },
    created() {
        this.getDisbursementRequestList()
    },
    mounted() {
    },
    computed: {
        searchLists() {
            return this.lists.list_data.filter((item) => {
                return (
                    item.receipt.toLowerCase().includes(this.req.keyword.toLowerCase())
                )
            });
        }
    },
    methods: {
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        getDisbursementRequestList() {
            this.is_list_ready = true
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/wallet/disbursement/request`, {
                params: { per_page: this.per_page, page: this.$route.query.page},
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                var data = res.data.data
                this.lists = data
                this.is_list_ready = false
            })
            .catch((err) => {
                console.log(err);
                this.is_list_ready = false
            });
        },
        currencyFormat(num) {
            return (
                "Rp. " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        requestDisbursement(){
            this.is_disabled = true
            var data = {
                receipt: this.req.receipt
            }
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/wallet/disbursement/request/confirmation`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            })
            .then((res) => {
                this.is_disabled = false
                this.modalDisbersment = true
                this.request_data = res.data.data
            })
            .catch((err) => {
                this.is_disabled = false
                this.modalDisbersment = false
                console.log(err)
            })
        },
        storeRequest(){
            this.is_store_request = true
            var data = {
                receipt: this.req.receipt
            }
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/wallet/disbursement/request/store`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            })
            .then((res) => {
                this.is_store_request = false
                this.modalDisbersment = false
                window.location.href = "/trawlcarrier/partner/wallet/disbursement"
                console.log(res)
            })
            .catch((err) => {
                this.modalDisbersment = false
                this.is_store_request = false
                console.log(err)
            })
        },
    },
};
</script>

<style>
    .user-avatar{
        width: 60px;
        height: 60px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 50px;
        border: 2px solid #e2e3e5;
    }
    .form-control.big-form{
        border: 1px solid #e9ecef;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        background: #fafafa;
        border-radius: 5px;
        font-size: 24px;
        display: block;
        width: 100%;
        padding: 15px 15px;
        font-weight: 700;
        line-height: 1.5;
        color: #495057;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .form-control.big-form:focus{
        border-color: #e9ecef;
    }
    .close-position{
        position: absolute;
        right: 20px;
        top: 15px;
    }
    .check input[type="checkbox"] {
        display:none;
    }
    .check label {
        width: 34px;
        height: 34px;
        border-radius: 50px;
        font-size: 16px;
        text-align: center;
        padding: 5px;
        display: inline-block;
        border: 1px solid #e2e3e5;
        color: #495057;
        cursor: pointer;
    }
    .check input[type="checkbox"]:checked + label {
        background: #3D8824;
        border: 1px solid #3D8824;
        color: #fff;
    }
    .form-control.big-form:disabled{
        background: #fafafa !important;
    }
</style>