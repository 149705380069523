<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="/trawltruck/ho/delivery-payment"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Ubah Data Uang Jalan</h3>
            </div>
            <div class="row mt-5 m-mt-2">
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Nomor Resi</label>
                    <input
                        type="text"
                        class="form-control"
                        :value="detail.receipt"
                        disabled
                    />
                </div>
                <div class="col-md-6">
                    <label class="fw-bold mb-2 m-mt-1">Nama Driver</label>
                    <input
                        type="text"
                        class="form-control"
                        :value="detail.driver.name"
                        disabled
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Nama Mitra</label>
                    <input
                        type="text"
                        class="form-control"
                        :value="detail.partner_name"
                        disabled
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Rute Sewa</label>
                    <input
                        type="text"
                        class="form-control"
                        :value="detail.route"
                        disabled
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Tanggal Sewa</label>
                    <input
                        type="text"
                        class="form-control"
                        :value="
                            moment(detail.beginpickup_at).format('DD MMMM YYYY')
                        "
                        disabled
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Jenis Truk</label>
                    <input
                        type="text"
                        class="form-control"
                        :value="detail.fleet_type"
                        disabled
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Jenis Order</label>
                    <select class="form-select" disabled>
                        <option value="">
                            <template v-if="detail.order.type_order == 'quick'">
                                Cepat
                            </template>
                            <template
                                v-if="detail.order.type_order == 'schedule'"
                            >
                                Terjadwal
                            </template>
                            <template
                                v-if="detail.order.type_order == 'fullday'"
                            >
                                Seharian
                            </template>
                        </option>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Jenis Layanan</label>
                    <!-- <input type="text" class="form-control capitalize" :value="detail.order.type_service" disabled> -->
                    <select class="form-select capitalize" disabled>
                        <option value="">
                            <template
                                v-if="detail.order.type_service == 'standart'"
                            >
                                Standard
                            </template>
                            <template v-else>
                                {{ detail.order.type_service }}
                            </template>
                        </option>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Harga Total Sewa</label>
                    <input
                        type="text"
                        class="form-control"
                        :value="currencyFormat(detail.order.total_price)"
                        disabled
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Margin Trobens 10%</label>
                    <input
                        type="text"
                        class="form-control"
                        :value="currencyFormat(detail.company_amount)"
                        disabled
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Total 90%</label>
                    <input
                        type="text"
                        class="form-control"
                        :value="currencyFormat(detail.partner_amount)"
                        disabled
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Total 30%/90%</label>
                    <input
                        type="text"
                        class="form-control"
                        :value="currencyFormat(detail.transport_amount)"
                        disabled
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2"
                        >Nomor Rekening (Atas Nama)</label
                    >
                    <input
                        type="text"
                        class="form-control"
                        :value="
                            detail.partner.bankaccount.account_number +
                            ' (' +
                            detail.partner.bankaccount.account_name +
                            ')'
                        "
                        disabled
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Status</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="success"
                                :value="detail.status"
                                v-model="detail.status"
                                disabled
                            />
                            <label
                                class="form-check-label fw-bold"
                                for="success"
                                >Success</label
                            >
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="tidak"
                                :value="detail.status"
                                v-model="detail.status"
                                disabled
                            />
                            <label class="form-check-label fw-bold" for="tidak"
                                >Pending</label
                            >
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold">Bukti Transfer</label>
                    <div class="text-gray mb-2">Foto Struk</div>
                    <div
                        class="image-view-1"
                        :style="{
                            'background-image': 'url(' + req.photo + ')',
                        }"
                    >
                        <div class="mt-4 ml-4">
                            <label
                                for="photo"
                                class="upload-button single-image"
                            >
                                <i class="fa fa-upload"></i>
                                <input
                                    type="file"
                                    id="photo"
                                    @change="uploadTransfer()"
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <!-- <a href="/trawltruck/ho/partner" class="btn btn-outline-black btn-lg px-5 me-3">Batal</a> -->
                            <a
                                href="/trawltruck/ho/delivery-payment"
                                class="btn btn-outline-black btn-lg px-5 me-3 m-w-100"
                                >Batal</a
                            >
                            <button
                                class="btn btn-green btn-lg px-5 me-3 m-w-100 m-mt-1"
                                type="button"
                                disabled
                                v-if="is_save"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Update
                            </button>
                            <template v-else>
                                <button
                                    class="btn btn-green btn-lg px-5 me-3 m-w-100 m-mt-1"
                                    @click="updateData()"
                                    :disabled="is_changing_photo == false"
                                >
                                    Update
                                </button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Perubahan Data Berhasil!</h4>
                    <div class="mt-2">Selamat! Anda berhasil merubah data.</div>
                </div>
                <div class="mt-4">
                    <a
                        href="/trawltruck/ho/delivery-payment"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import $ from "jquery";
import moment from "moment";

export default {
    props: "",
    name: "ManagementDeliveryPaymentEdit",
    data() {
        return {
            moment: moment,
            showPassword: false,
            is_changing_photo: false,
            req: {
                photo: "",
            },
            modalSuccess: false,
            modalError: false,
            detail: {
                driver: {},
                partner: {
                    bankaccount: {},
                },
                order: {},
            },
            is_save: false,
        };
    },
    created() {
        this.getDetail();
    },
    methods: {
        currencyFormat(num) {
            return (
                "Rp. " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        uploadTransfer() {
            var photo = new FileReader();
            photo.onload = function (e) {
                $(".image-view-1").css(
                    "background-image",
                    "url(" + e.target.result + ")"
                );
            };
            photo.readAsDataURL($("#photo")[0].files[0]);
            this.is_changing_photo = true;
        },
        getDetail() {
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/transport/detail?id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detail = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        updateData() {
            var photo = $("#photo")[0].files[0];
            var data = new FormData();

            data.append("id", this.detail.id);

            if (this.is_changing_photo) {
                data.append("photo", photo);
            }

            this.is_save = true;

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/transport/update`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    this.modalSuccess = true;
                    this.is_changing_photo = false;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
</style>
