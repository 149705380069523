<template>
  <div class="padding-container">
    <div class="d-flex bd-highlight mb-3 align-items-center">
      <div class="flex-grow-1 bd-highlight">
        <h4 class="fw-bold">List Data Pencairan</h4>
      </div>
      <div class="bd-highlight">
        <button class="add-button" @click="showModal = true">
          <span class="me-3"><img class="pb-1" width="25px" src="../../assets/dollarArrow-icon.png" /></span> Ajukan Pengajuan
        </button>
      </div>
    </div>
    <div class="box">
      <div class="d-flex bd-highlight">
        <div class="flex-grow-1 bd-highlight">
          <div class="d-flex gap-2">
            <h4 class="fw-bold">List Pencairan</h4>
            <select class="form-control form-select" style="width: 145px" v-model="filter_status" @change="filterData">
              <option value="" selected>Filter Status</option>
              <option value="approved">Approved</option>
              <option value="pending">Pending</option>
              <option value="transfered">Transfered</option>
              <option value="submit">Submit</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <select name="" @change="filterData" class="form-control form-select" v-model="monthPicker" style="width: 145px">
              <option value="" selected>Pilih Bulan</option>
              <option v-for="(item, index) in month" :key="index" :value="item.value">{{ item.label }}</option>
            </select>
          </div>
          <div class="col-md-6">
            <select name="" @change="filterData" class="form-control form-select" v-model="yearPicker" style="width: 145px">
              <option value="" selected>Pilih Tahun</option>
              <option v-for="(item, index) in year" :key="index" :value="item">{{ item }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="mt-4" v-if="is_my_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">Tanggal Pengajuan</div>
            <div class="d-table-cell">Total Pengajuan</div>
            <div class="d-table-cell">Total Penerimaan</div>
            <div class="d-table-cell">Penanggung Jawab</div>
            <div class="d-table-cell">Status</div>
          </div>
          <div class="d-table-row" v-for="(item, index) in data" :key="index">
            <div class="d-table-cell">{{ item.no }}</div>
            <div class="d-table-cell">
              {{ item.disburse_date }}
              <!-- <router-link class="text-dark fw-bold" :to="{ name: items.url, params: { partnerid: item.partner_code } }">{{ item.partner_code }}</router-link> -->
            </div>
            <div class="d-table-cell">
              {{ currency(item.amount) }}
              <!-- <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" :title="item.partner_address"> {{ item.partner_regency }} <i class="fa fa-info-circle text-blue ms-1"></i> </a> -->
            </div>
            <div class="d-table-cell">{{ currency(item.received) }}</div>
            <div class="d-table-cell">
              {{ item.disburse.bank.toUpperCase() }}<a href="javascript:void(0)" @click="modalDetail(item.disburse)"><br />{{ item.disburse.name }}</a>
            </div>
            <div class="d-table-cell">
              <span class="tag green">
                {{ item.status == "ontheway" ? "Dalam Perjalanan" : item.status }}
              </span>
            </div>
          </div>
        </div>
        <div v-if="data.length == 0" class="text-center mt-section">
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
    </div>
    <div class="overflow-auto mt-3">
      <b-pagination-nav v-if="data.length > 0" v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="showModal">
        <div class="modal-body-chat sm" v-if="showModal">
          <div class="d-flex bd-highlight align-items-lg-center mb-2">
            <div class="flex-grow-1 bd-highlight">
              <img src="../../assets/checklist.png" />
            </div>
            <div class="bd-highlight">
              <a href="javascript:void(0)" @click="showModal = false" class="size-20 text-primary">
                <i class="fa fa-times-circle"></i>
              </a>
            </div>
          </div>
          <div class="scroll p-1">
            <div class="size-18 fw-ekstra-bold">Pengajuan Pencairan</div>
            <div class="mt-1 me-2">Pilih nomor resi dan juga bank untuk pencairan dana</div>
            <div class="my-2">
              <input class="form-check-input me-2" name="selectDisbursement" type="checkbox" value="" id="checkAll" @change="toggleAll" />
              <label class="form-check-label" for="checkAll"> Pilih Semua </label>
            </div>
            <div class="bg-box p-2">
              <div class="scroll-receipt">
                <div class="d-flex justify-content-between mb-3" v-for="(item, index) in dataReceipt" :key="index">
                  <div>
                    <input class="form-check-input me-2" name="selectDisbursement" :id="'check_' + index" type="checkbox" @change="getTotal" v-model="selectedItems" :value="item.receipt" />
                    <label class="form-check-label fw-ekstra-bold" :for="'check_' + index"> {{ item.receipt }} </label>
                  </div>
                  <div>{{ currency(item.amount) }}</div>
                </div>
              </div>
            </div>
            <div class="bg-box p-2 mt-2">
              <div class="d-flex justify-content-between">
                <div>Jumlah</div>
                <div class="fw-bold me-1">{{ currency(totalAmount) }}</div>
              </div>
            </div>
            <div class="border border-dashed my-3"></div>
            <div class="size-18 fw-ekstra-bold mb-2">Pilih Bank Untuk Pencairan</div>
            <div class="box">
              <div class="position-relative">
                <img class="position-absolute top-0 end-0" src="../../assets/shield-icon.png" />
              </div>
              <div class="d-flex gap-2">
                <img src="../../assets/visa-icon.png" style="height: 32px" />
                <div>
                  <div class="d-flex flex-column mb-2">
                    <div class="fw-bold">{{ dataAccount.bank }}</div>
                    <span>{{ dataAccount.account }}</span>
                  </div>
                  <div class="fw-bold">{{ dataAccount.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex gap-2 mt-2">
            <button class="button button--cancel" @click="cancel" :disabled="isSaving">Batal</button>
            <button class="button button--save" @click="submitData" :disabled="isSaving">
              <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="showModalDetail" @click="showModalDetail = false">
        <div class="modal-body-chat sm" v-if="showModalDetail">
          <div class="position-relative">
            <a href="javascript:void(0)" @click="showModalDetail = false" class="size-20 text-primary position-absolute top-0 end-0">
              <i class="fa fa-times-circle"></i>
            </a>
          </div>
          <div class="d-flex bd-highlight align-items-lg-center">
            <div class="flex-grow-1 bd-highlight">
              <img src="../../assets/dollar-icon.png" />
            </div>
          </div>
          <div class="size-18 fw-ekstra-bold mt-2">Detail Penanggung Jawab</div>
          <div class="box">
            <div class="position-relative">
              <img class="position-absolute top-0 end-0" src="../../assets/shield-icon.png" />
            </div>
            <div class="d-flex gap-2">
              <img src="../../assets/visa-icon.png" style="height: 50px" />
              <div>
                <div class="d-flex flex-column mb-2">
                  <div class="fw-bold">{{ codeBank(dataBank.bank) }}</div>
                  <div>{{ dataBank.account }}</div>
                </div>
                <div class="fw-bold">{{ capitalizeFirstLetter(dataBank.name) }}</div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button class="button button--save" style="width: 100%">Oke</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";

export default {
  props: {},
  components: {
    ListLoader,
  },
  name: "ManagementDisbursement",
  data() {
    return {
      showModal: false,
      showModalMap: false,
      showModalDetail: false,
      is_my_list_ready: false,
      isSaving: false,
      data: [],
      dataBank: {},
      dataReceipt: [],
      dataAccount: {},
      selectedItems: [],
      totalAmount: 0,
      req: {},
      currentPage: 0,
      totalPage: 0,
      limit: 10,
      datePicker: "",
      monthPicker: "",
      yearPicker: "",
      filter_status: "",
      dummy: [
        {
          Tanggal_Pengajuan: "11 Januari 2022",
          Total_Pengajuan: "Rp 65.000.000",
          Total_Penerimaan: "Rp 65.000.000",
          Bank: "BRI",
          Penanggung_Jawab: "Bambang Sudiyono",
          Status: "Diterima",
        },
        {
          Tanggal_Pengajuan: "12 Januari 2022",
          Total_Pengajuan: "Rp 75.000.000",
          Total_Penerimaan: "Rp 75.000.000",
          Bank: "BCA",
          Penanggung_Jawab: "Agus Subagjo",
          Status: "Diterima",
        },
        {
          Tanggal_Pengajuan: "13 Januari 2022",
          Total_Pengajuan: "Rp 85.000.000",
          Total_Penerimaan: "Rp 78.000.000",
          Bank: "Mandiri",
          Penanggung_Jawab: "Supratman",
          Status: "Diterima",
        },
      ],
      dataCheckbox: [
        {
          receipt: "TRT0000000001",
          amount: "Rp 560.000",
        },
        {
          receipt: "TRT0000000002",
          amount: "Rp 160.000",
        },
        {
          receipt: "TRT0000000003",
          amount: "Rp 260.000",
        },
        {
          receipt: "TRT0000000004",
          amount: "Rp 360.000",
        },
        {
          receipt: "TRT0000000005",
          amount: "Rp 460.000",
        },
        {
          receipt: "TRT0000000006",
          amount: "Rp 660.000",
        },
        {
          receipt: "TRT0000000007",
          amount: "Rp 760.000",
        },
        {
          receipt: "TRT0000000008",
          amount: "Rp 860.000",
        },
        {
          receipt: "TRT0000000009",
          amount: "Rp 960.000",
        },
        {
          receipt: "TRT0000000010",
          amount: "Rp 110.000",
        },
        {
          receipt: "TRT0000000011",
          amount: "Rp 120.000",
        },
        {
          receipt: "TRT0000000012",
          amount: "Rp 130.000",
        },
      ],
      month: [
        { label: "Januari", value: "01" },
        { label: "Februari", value: "02" },
        { label: "Maret", value: "03" },
        { label: "April", value: "04" },
        { label: "Mei", value: "05" },
        { label: "Juni", value: "06" },
        { label: "Juli", value: "07" },
        { label: "Agustus", value: "08" },
        { label: "September", value: "09" },
        { label: "Oktober", value: "10" },
        { label: "November", value: "11" },
        { label: "Desember", value: "12" },
      ],
      year: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
    };
  },
  created() {
    this.getListData();
    this.getReceipt();
    this.getAccount();
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
  },
  methods: {
    filterData() {
      this.currentPage = 0;
      this.datePicker = "";
      if (this.monthPicker && !this.yearPicker) {
        this.datePicker = `${moment(new Date()).format("YYYY")}-${this.monthPicker}`;
      }
      if (!this.monthPicker && this.yearPicker) {
        this.datePicker = `${this.yearPicker}-${moment(new Date()).format("MM")}`;
      }
      if (this.monthPicker && this.yearPicker) {
        this.datePicker = `${this.yearPicker}-${this.monthPicker}`;
      }
      this.getListData();
    },
    changePage() {
      this.getListData();
    },
    modalDetail(dataBank) {
      this.showModalDetail = true;
      this.dataBank = dataBank;
    },
    toggleAll() {
      let checkboxs = document.getElementsByName("selectDisbursement");
      for (let i = 1; i < checkboxs.length; i++) {
        if (checkboxs[i].type == "checkbox") {
          if (checkboxs[0].checked) {
            if (!checkboxs[i].checked) {
              this.selectedItems.push(checkboxs[i].value);
            }
            checkboxs[i].checked = true;
          } else {
            checkboxs[i].checked = false;
            this.selectedItems = [];
          }
        }
      }
      this.getTotal();
    },
    getTotal() {
      this.totalAmount = 0;

      this.selectedItems.forEach((el) => {
        this.dataReceipt.forEach((item) => {
          if (el == item.receipt) {
            this.totalAmount += item.amount;
          }
        });
      });
    },
    getListData() {
      this.is_my_list_ready = true;
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/disburse/list`, {
        params: { page: this.currentPage == 0 || this.currentPage == null ? 0 : this.currentPage - 1, limit: this.limit, status: this.filter_status, year_month: this.datePicker },
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data = res.data.data.list_data;
          this.totalPage = res.data.data.total_page;
          this.is_my_list_ready = false;
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          this.is_my_list_ready = false;
          console.log(err);
        });
    },
    getReceipt() {
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/disburse/receipt`, {
        params: { page: this.currentPage == 0 || this.currentPage == null ? 0 : this.currentPage - 1, limit: this.limit },
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.dataReceipt = res.data.data.list_data;
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          console.log(err);
        });
    },
    getAccount() {
      Api.get(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/disburse/account`, {
        params: { page: this.currentPage == 0 || this.currentPage == null ? 0 : this.currentPage - 1, limit: this.limit },
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.dataAccount = res.data.data;
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          console.log(err);
        });
    },
    submitData() {
      this.isSaving = true;
      var data = new FormData();
      this.selectedItems.forEach((el) => {
        data.append("receipt[]", el);
      });

      Api.post(`${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/disburse`, data, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.showModal = false;
          this.isSaving = false;
          this.$notify({
            group: "foo",
            type: "success",
            title: "Success",
            text: "Data berhasil dibuat",
          });
          this.getListData();
          this.getReceipt();
          this.clear();
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.isSaving = false;
        });
    },
    cancel() {
      this.clear();
      this.showModal = false;
    },
    clear() {
      this.selectedItems = [];
    },
    codeBank(code) {
      var bank;
      switch (code) {
        case "bri":
          bank = "Bank Rakyat Indonesia";
          break;
        case "bca":
          bank = "Bank Central Asia";
          break;
        case "mandiri":
          bank = "Bank Mandiri";
          break;
        case "bni":
          bank = "Bank Negara Indonesia";
          break;
        case "bsi":
          bank = "Bank Syariah Indonesia";
          break;
        case "mega":
          bank = "Bank Mega";
          break;
        case "permata":
          bank = "Bank Permata";
          break;
        case "cimb":
          bank = "Bank CIMB Niaga";
          break;
        case "danamon":
          bank = "Bank Danamon Indonesia";
          break;
        case "btpn":
          bank = "Bank BTPN";
          break;
        case "maybank":
          bank = "Bank Maybank Indonesia";
          break;
        case "ocbc":
          bank = "Bank OCBC NISP";
          break;
        default:
          bank = code.toUpperCase();
      }
      return bank;
    },
    currency(number) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(number);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.row.foto {
  --bs-gutter-x: 0rem !important;
}
.scroll-receipt {
  height: 200px;
  overflow-y: scroll;
}
.add-button {
  background: #48a2d4;
  border-radius: 8px;
  border: 1px solid #48a2d4;
  color: white;
  padding: 10px 15px;
}
.form-input {
  background: rgba(239, 241, 249, 0.6);
  border-width: 0px;
  border: none;
  outline: none;
  border-radius: 10px;
  width: 258px;
  height: 40px;
  padding: 10px;
}
.button {
  display: flex;
  border-radius: 12px;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  &--save {
    background: #48a2d4;
    border: 1px solid #48a2d4;
    color: white;
  }
  &--save:is(:hover, :focus) {
    background: #176f9e;
  }
  &--add {
    background: #ffffff;
    border: 1px solid #48a2d4;
    color: #48a2d4;
  }
  &--cancel {
    background: #ffffff;
    border: 1px solid #d0d5dd;
    color: #000000;
  }
  &--cancel:is(:hover, :focus) {
    background: #d0d5dd;
    border: 1px solid #d0d5dd;
    color: #000000;
  }
}
.bg-box {
  background: #f6f7fb;
  border-radius: 12px;
}
</style>
