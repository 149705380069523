<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/trawltruck/ho/order" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Ubah Status Pesanan</h3>
            </div>
            <div class="row mt-5 m-mt-2">
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Nomor Resi</label>
                    <div class="form-box">
                        {{ req.receipt_code }}
                    </div>
                </div>
                <div class="col-md-6 m-mt-1">
                    <label class="fw-bold mb-2">Rute Sewa Truk</label>
                    <div class="form-box">{{ req.route }}</div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Kendaraan</label>
                    <div class="form-box capitalize">
                        {{ (req.type_fleet || "").split("-").join(" ") }}
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Status Pesanan Terkini</label>
                    <div class="form-box">
                        {{ req.status_label }}
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Status Pesanan</label>
                    <multiselect
                        v-model="status"
                        :options="options"
                        label="label"
                        track-by="value"
                        placeholder="Pilih Status Pesanan"
                        class="multi-form-custom"
                    >
                    </multiselect>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Lokasi Driver</label>
                    <GmapMap
                        ref="mapRef"
                        :center="{
                            lat: -6.233035800000001,
                            lng: 106.8397427,
                        }"
                        :zoom="16"
                        map-type-id="terrain"
                        style="height: 300px"
                    >
                        <div class="row justify-content-center pt-4">
                            <div class="col-md-9">
                                <div class="position-relative">
                                    <GmapAutocomplete
                                        @place_changed="processLocationChanged"
                                        :value="address"
                                        placeholder="Cari Lokasi"
                                        class="form-control search-google"
                                    ></GmapAutocomplete>
                                    <span class="search-icon map">
                                        <i class="fa fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <GmapMarker
                            :key="index"
                            v-for="(m, index) in markers"
                            ref="marker"
                            :position="m"
                            :clickable="true"
                            :draggable="true"
                            @dragend="movePlace"
                            @click="center = m"
                        >
                        </GmapMarker>
                    </GmapMap>
                    <div class="mt-4">
                        <div class="form-box">
                            {{ address ? address : "Lokasi driver" }}
                        </div>
                    </div>
                </div>
                <div
                    class="col-md-12 mt-3"
                    v-if="
                        status?.value == 'loading' ||
                        status?.value == 'unloading'
                    "
                >
                    <label class="fw-bold">Foto Barang</label>
                    <div class="text-gray mb-2">
                        Upload foto barang maks 5MB
                    </div>
                    <label
                        type="file"
                        :class="!attachment ? 'upload__btn' : ''"
                    >
                        <img
                            v-if="attachment"
                            class="photo"
                            :src="attachmentPictureUrl"
                        />
                        <p v-else class="mb-0">
                            <i class="fa fa-plus"></i>
                        </p>
                        <input
                            class="cursor-pointer"
                            id="upload"
                            type="file"
                            name="file"
                            ref="file"
                            @change="uploadPhoto"
                        />
                    </label>
                </div>
            </div>
        </div>
        <div class="d-flex mt-4">
            <div class="ms-auto">
                <a
                    href="/trawltruck/ho/order"
                    class="btn btn-outline-black btn-lg px-5 me-3"
                    >Batal</a
                >
                <button
                    class="btn btn-green btn-lg px-5"
                    type="button"
                    disabled
                    v-if="is_update"
                >
                    <div class="d-flex align-items-center">
                        <span
                            class="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Update
                    </div>
                </button>
                <button
                    class="btn btn-green btn-lg px-5"
                    :disabled="status == '' || lat_move == ''"
                    @click="updateData()"
                    v-else
                >
                    Update
                </button>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Status Pesanan Berhasil Disimpan!
                    </h4>
                    <div class="mt-2">
                        Selamat! Anda berhasil merubah status pesanan.
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        href="/trawltruck/ho/order"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import "vue2-datepicker/index.css";
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";
import noImage from "../../assets/no-photo.png";

export default {
    components: {
        Multiselect,
    },
    props: "",
    name: "ManagementHoOrderEdit",
    data() {
        return {
            status: "",
            options: [
                {
                    value: "waiting",
                    label: "Driver Ditemukan",
                },
                {
                    value: "pickup",
                    label: "Driver menuju lokasi penjemputan",
                },
                {
                    value: "loading",
                    label: "Loading Barang / Driver sampai di lokasi penjemputan",
                },
                {
                    value: "ontheway",
                    label: "Driver menuju lokasi penerima",
                },
                {
                    value: "unloading",
                    label: "Driver sampai di lokasi penerima",
                },
                {
                    value: "done",
                    label: "Selesai",
                },
            ],
            req: {
                attachment: "",
            },
            map_data: { lat: null, lng: null },
            markers: [
                {
                    lat: -6.233035800000001,
                    lng: 106.8397427,
                },
            ],
            lat_search: "",
            lng_search: "",
            lat_move: "",
            lng_move: "",
            address: "",
            attachment: "",
            modalSuccess: false,
            is_update: false,
        };
    },
    computed: {
        google: gmapApi,
        attachmentPictureUrl() {
            return this.attachment.length > 0 ? this.attachment : noImage;
        },
    },
    created() {
        this.getDetail();
    },
    methods: {
        getDetail() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/detail?id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.req = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        processLocationChanged(ev) {
            this.lat_search = ev.geometry.location.toJSON().lat;
            this.lng_search = ev.geometry.location.toJSON().lng;
            this.map_data = ev.geometry.location.toJSON();

            this.address = ev.formatted_address;

            let that = this;
            this.$refs.mapRef.$mapPromise.then((map) => {
                map.panTo(that.map_data);
            });
            this.$refs.marker[0].$markerObject.setPosition(this.map_data);
        },
        movePlace(event) {
            this.lat_move = event.latLng.toJSON().lat;
            this.lng_move = event.latLng.toJSON().lng;
            this.map_data = event.latLng.toJSON();
            console.log(event.domEvent);

            this.decodeLatLong(this.lat_move, this.lng_move);
        },
        decodeLatLong(lat, lng) {
            const geocoder = new this.google.maps.Geocoder();

            geocoder.geocode(
                { location: { lat: lat, lng: lng } },
                (results, status) => {
                    if (status === "OK") {
                        console.log(results[0]);
                        this.address = results[0].formatted_address;
                    } else {
                        this.address = "";
                        alert("tidak ketemu");
                    }
                }
            );
        },
        uploadPhoto(event) {
            var input = event.target;
            this.req.attachment = input.files[0];
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.attachment = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
        updateData() {
            this.is_update = true;
            var data = new FormData();
            data.append("id", this.$route.params.id);
            data.append("status", this.status?.value);
            data.append("driver_lat", this.map_data.lat);
            data.append("driver_lon", this.map_data.lng);
            if (
                this.status?.value == "loading" ||
                this.status?.value == "unloading"
            ) {
                data.append("photo", this.req.attachment);
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/status`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_update = false;
                    this.modalSuccess = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_update = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    border: dashed;
    border-radius: 10px;
    width: 13vw;
    height: 150px;
    padding: 55px;
    position: relative;
}
.photo {
    border-radius: 10px;
    cursor: pointer;
    width: 13vw;
    height: 150px;
    object-fit: cover;
}
.btn-lg {
    width: 150px;
}
input[type="file"] {
    display: none;
}
.upload__btn p i {
    font-size: 30px;
}
</style>
