<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="/crm/partnership/requested-trawlcarrier"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Detail Requested TroCarrier</h3>
            </div>
            <div class="mt-4">
                <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                    <tr class="">
                        <td class="ps-0 w-200">Company Name</td>
                        <td class="w-40">:</td>
                        <td class="capitalize">
                            {{
                                detail.company_name ? detail.company_name : "-"
                            }}
                        </td>
                    </tr>
                    <tr class="">
                        <td class="ps-0 w-200">Owner Name</td>
                        <td class="w-40">:</td>
                        <td class="capitalize">
                            {{ detail.pic_name ? detail.pic_name : "-" }}
                        </td>
                    </tr>
                    <tr class="">
                        <td class="ps-0 w-200">Partner Type</td>
                        <td class="w-40">:</td>
                        <td class="capitalize">TroCarrier</td>
                    </tr>
                    <tr class="">
                        <td class="ps-0 w-200">Phone</td>
                        <td class="w-40">:</td>
                        <td class="capitalize">
                            {{
                                detail.company_phone
                                    ? detail.company_phone
                                    : "-"
                            }}
                        </td>
                    </tr>
                    <tr class="">
                        <td class="ps-0 w-200">Email</td>
                        <td class="w-40">:</td>
                        <td class="capitalize">
                            {{
                                detail.company_email
                                    ? detail.company_email
                                    : "-"
                            }}
                        </td>
                    </tr>
                    <tr class="">
                        <td class="ps-0 w-200">Founded</td>
                        <td class="w-40">:</td>
                        <td class="capitalize">
                            {{ moment(detail.created_at).format("DD/MM/YYYY") }}
                        </td>
                    </tr>
                    <tr class="">
                        <td class="ps-0 w-200">Address</td>
                        <td class="w-40">:</td>
                        <td class="capitalize">
                            {{
                                detail.company_address
                                    ? detail.company_address
                                    : "-"
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">KTP</td>
                        <td class="w-40">:</td>
                        <td>
                            <div
                                class="d-flex align-items-center"
                                v-if="detail.identity_document"
                            >
                                <div>
                                    <img
                                        src="../../assets/dashboard/image.png"
                                        width="30"
                                        alt=""
                                    />
                                </div>
                                <div class="ms-auto">
                                    <a
                                        :href="detail.identity_document"
                                        target="_blank"
                                        download
                                        class="text-green"
                                    >
                                        Download
                                        <i class="fa fa-download ms-2"></i>
                                    </a>
                                </div>
                            </div>
                            <template v-else> - </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="ps-0 w-200">NPWP</td>
                        <td class="w-40">:</td>
                        <td>
                            <div
                                class="d-flex align-items-center"
                                v-if="detail.business_document"
                            >
                                <div>
                                    <img
                                        src="../../assets/dashboard/image.png"
                                        width="30"
                                        alt=""
                                    />
                                </div>
                                <div class="ms-auto">
                                    <a
                                        :href="detail.business_document"
                                        target="_blank"
                                        download
                                        class="text-green"
                                    >
                                        Download
                                        <i class="fa fa-download ms-2"></i>
                                    </a>
                                </div>
                            </div>
                            <template v-else> - </template>
                        </td>
                    </tr>
                    <tr class="">
                        <td class="ps-0 w-200">Leads Count</td>
                        <td class="w-40">:</td>
                        <td class="capitalize">{{ detail.prospect_count }}x</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-3 me-auto">Leads History</h3>
            </div>
            <div class="mt-4" v-if="is_prospect">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Created At
                                </th>
                                <th class="text-center" scope="col">
                                    Follow Up Date
                                </th>
                                <th class="text-center" scope="col">
                                    Partner Type
                                </th>
                                <th class="text-center" scope="col">
                                    Activity
                                </th>
                                <th class="text-center" scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in prospect.list_data"
                                :key="index"
                            >
                                <td class="text-center" style="width: 50px">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-center" style="width: 200px">
                                    {{
                                        moment(
                                            item.created_at
                                                ? item.created_at
                                                : "-"
                                        ).format("DD/MM/YYYY")
                                    }}
                                </td>
                                <td class="text-center" style="width: 200px">
                                    {{
                                        moment(
                                            item.follow_up
                                                ? item.follow_up
                                                : "-"
                                        ).format("DD/MM/YYYY")
                                    }}
                                </td>
                                <td class="capitalize">
                                    <template
                                        v-for="(
                                            product, index
                                        ) in item.partner_type"
                                    >
                                        <small
                                            class="tag green pb-0 pt-0"
                                            :key="index"
                                        >
                                            {{ product }}
                                        </small>
                                    </template>
                                </td>
                                <td class="capitalize text-center">
                                    {{ item.activity ? item.activity : "-" }}
                                </td>
                                <td class="text-center" style="width: 200px">
                                    <div
                                        class="tag blue-solid w-100 radius mb-0"
                                        v-if="item.status == 'process'"
                                    >
                                        Process
                                    </div>
                                    <div
                                        class="tag green-solid w-100 radius mb-0"
                                        v-else-if="item.status == 'join'"
                                    >
                                        Join
                                    </div>
                                    <div
                                        class="tag red-solid w-100 radius mb-0"
                                        v-else
                                    >
                                        Exit
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div
                    v-if="prospect.list_data.length == 0"
                    class="text-center mt-section"
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="overflow-auto mt-3">
                <b-pagination-nav
                    v-if="prospect.list_data.length > 0"
                    v-model="currentPageProspect"
                    :number-of-pages="prospect.total_page"
                    base-url="#"
                    first-number
                    align="right"
                    @input="changePageProspect"
                ></b-pagination-nav>
            </div>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-3 me-auto">Activity History</h3>
            </div>
            <ul class="nav nav-pills mt-4" id="pills-tab" role="tablist">
                <li class="nav-item w-33" role="presentation">
                    <button
                        class="nav-link dashboard left w-100 active"
                        id="pills-meeting-tab"
                        @click="
                            getTabType('meeting'), (currentPageActivity = 0)
                        "
                        data-bs-toggle="pill"
                        data-bs-target="#pills-meeting"
                        type="button"
                        role="tab"
                        aria-controls="pills-meeting"
                        aria-selected="true"
                    >
                        Meeting
                    </button>
                </li>
                <li class="nav-item w-33 m-mt-1" role="presentation">
                    <button
                        class="nav-link dashboard mid w-100"
                        id="pills-canvasing-tab"
                        @click="
                            getTabType('canvasing'), (currentPageActivity = 0)
                        "
                        data-bs-toggle="pill"
                        data-bs-target="#pills-canvasing"
                        type="button"
                        role="tab"
                        aria-controls="pills-canvasing"
                        aria-selected="false"
                    >
                        Canvasing/Visit
                    </button>
                </li>
                <li class="nav-item w-33 m-mt-1" role="presentation">
                    <button
                        class="nav-link dashboard right w-100"
                        id="pills-other-tab"
                        @click="getTabType('other'), (currentPageActivity = 0)"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-other"
                        type="button"
                        role="tab"
                        aria-controls="pills-other"
                        aria-selected="false"
                    >
                        Other
                    </button>
                </li>
            </ul>
            <div class="mt-4" v-if="is_activity">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">Date</th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-if="type == 'meeting'"
                                >
                                    Title
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-if="type == 'meeting'"
                                >
                                    Meeting Type
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-if="type == 'meeting' || type == 'other'"
                                >
                                    Description
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-if="type == 'canvasing'"
                                >
                                    Files
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-if="type == 'canvasing'"
                                >
                                    GMaps
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in activity.list_data"
                                :key="index"
                            >
                                <td class="text-center" style="width: 50px">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-center" style="width: 200px">
                                    {{ item.date ? item.date : "-" }}
                                </td>
                                <td class="capitalize" v-if="type == 'meeting'">
                                    {{ item.title ? item.title : "-" }}
                                </td>
                                <td
                                    class="text-center"
                                    style="width: 200px"
                                    v-if="type == 'meeting'"
                                >
                                    <span
                                        class="tag blue-solid w-100 radius mb-0"
                                        v-if="item.meeting_type == 'offline'"
                                    >
                                        Offline
                                    </span>
                                    <span
                                        class="tag blue-solid w-100 radius mb-0"
                                        v-else
                                    >
                                        Online
                                    </span>
                                </td>
                                <td v-if="type == 'meeting' || type == 'other'">
                                    {{ item.desc ? item.desc : "-" }}
                                </td>
                                <td v-if="type == 'canvasing'">
                                    <div class="d-flex">
                                        <div class="me-4">Image.png</div>
                                        <div class="ms-auto">
                                            <a
                                                href="javascript:void(0)"
                                                @click="showImage(index)"
                                            >
                                                <img
                                                    src="../../assets/eyes.png"
                                                    width="19"
                                                    alt=""
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </td>
                                <td v-if="type == 'canvasing'">
                                    {{
                                        item.geo_address
                                            ? item.geo_address
                                            : "-"
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div
                    v-if="activity.list_data.length == 0"
                    class="text-center mt-section"
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="overflow-auto mt-3">
                <b-pagination-nav
                    v-if="activity.list_data.length > 0"
                    v-model="currentPageActivity"
                    :number-of-pages="activity.total_page"
                    base-url="#"
                    first-number
                    align="right"
                    @input="changePageActivity"
                ></b-pagination-nav>
            </div>

            <div class="modal-vue">
                <div class="overlay" v-if="showModal"></div>
                <div class="modal-body-chat vsm" v-if="showModal">
                    <div class="close-modal cursor-pointer" @click="closeModal">
                        <i class="fa fa-times-circle text-danger size-20"></i>
                    </div>
                    <div class="text-center">
                        <img
                            :src="selectedImage.image"
                            alt="Preview"
                            class="w-100"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="showModal"></div>
            <div class="modal-body-chat vsm" v-if="showModal">
                <div class="close-modal cursor-pointer" @click="closeModal">
                    <i class="fa fa-times-circle text-danger size-20"></i>
                </div>
                <div class="text-center">
                    <img
                        :src="selectedImage.image"
                        alt="Preview"
                        class="w-100"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";

export default {
    name: "crmPartnershipRequestedDetail",
    components: {
        ListLoader,
    },
    data() {
        return {
            moment: moment,
            detail: {},
            prospect: {
                list_data: [],
            },
            is_prospect: false,
            per_page_prospect: 10,
            currentPageProspect: 0,
            activity: {
                list_data: [],
            },
            is_activity: false,
            per_page_activity: 10,
            currentPageActivity: 0,
            type: "meeting",
            showModal: false,
            selectedImage: null,
        };
    },
    created() {
        this.getDetail();
        this.getProspect();
    },
    computed: {},
    methods: {
        getDetail() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/requested/trawlcarrier/detail?id=${this.$route.query.id}`,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detail = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getProspect() {
            let payload = {
                search: this.search,
                candidate_id: this.$route.query.candidate_id,
                limit: this.per_page_prospect,
                page:
                    this.currentPageProspect == 0 ||
                    this.currentPageProspect == null
                        ? 0
                        : this.currentPageProspect - 0,
            };
            this.is_prospect = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/prospect/history`,
                {
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.prospect = data;
                    this.is_prospect = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_prospect = false;
                });
        },
        changePageProspect() {
            this.getProspect();
        },
        getActivitiesHistory() {
            let payload = {
                type: this.type,
                id: this.$route.query.candidate_id,
                limit: this.per_page_activity,
                page:
                    this.currentPageActivity == 0 ||
                    this.currentPageActivity == null
                        ? 0
                        : this.currentPageActivity - 0,
            };
            this.is_activity = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/activity/history`,
                {
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.activity = data;
                    this.is_activity = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_activity = false;
                });
        },
        changePageActivity() {
            this.getActivitiesHistory();
        },
        getTabType(value) {
            this.type = value;
            this.getActivitiesHistory();
        },
        showImage(index) {
            this.selectedImage = this.activity.list_data[index];
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.selectedImage = null;
        },
    },
};
</script>

<style lang="scss" scoped>
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px;
}
tr {
    border-color: #e8e8e9;
    padding: 10px;
}
td {
    padding: 10px;
}
.w-200 {
    width: 200px;
}
.w-40 {
    width: 40px;
}
.pill {
    display: inline-block;
    width: 110px;
    height: 24px;
    border-radius: 40px;
    text-align: center;
    color: white;
    &.red {
        background-color: #e60013;
        border: 1px solid #e60013;
        outline: 1px solid #e60013;
    }
    &.gray {
        color: #000 !important;
        background-color: #f4f6f8;
        border: 1px solid #f4f6f8;
        outline: 1px solid #f4f6f8;
    }
    &.green {
        background-color: #3d8824;
        border: 1px solid #3d8824;
        outline: 1px solid #3d8824;
    }
    &.yellow {
        background-color: #fb9727;
        border: 1px solid #fb9727;
        outline: 1px solid #fb9727;
    }
    &.blue {
        background-color: #48a2d4;
        border: 1px solid #48a2d4;
        outline: 1px solid #48a2d4;
    }
}
.bt-size {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 159px !important;
    height: 44px !important;
    border-radius: 8px;
}
.text-gray-dashboard {
    color: #61616a;
}
.search-icon-left {
    top: 11px;
    left: 10px;
}
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}
.nav-link.dashboard.mid {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.nav-link.dashboard.active {
    background-color: #e60013 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
}
.w-33 {
    width: 33%;
}
.w-34 {
    width: 34%;
}
.close-modal {
    position: absolute;
    right: 20px;
}
</style>
