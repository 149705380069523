<template>
    <div class="padding-container">
        <div class="d-flex bd-highlight mb-3 align-items-center">
            <div class="flex-grow-1 bd-highlight">
                <h4 class="fw-bold">List Data Kendaraan</h4>
            </div>
            <div class="bd-highlight">
                <button class="add-button" @click="showModal = true">
                    <span class="me-3"
                        ><img class="pb-1" src="../../assets/plus-icon.png"
                    /></span>
                    Tambah Kendaran
                </button>
            </div>
        </div>
        <div class="box">
            <div class="d-flex bd-highlight">
                <div class="flex-grow-1 bd-highlight">
                    <h4 class="fw-bold">List Kendaraan</h4>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <select
                            name=""
                            @change="filterData"
                            class="form-control form-select"
                            v-model="filter_status"
                            style="width: 160px"
                        >
                            <option value="" selected>Filter Status</option>
                            <option value="open">Aktif</option>
                            <option value="close">Tidak aktif</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <div class="position-relative">
                            <input
                                v-model="keySearch"
                                @keyup.enter="searchData"
                                type="text"
                                class="form-control"
                                style="width: 145px"
                                placeholder="Cari Kendaraan"
                            />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_my_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <div class="modern-table-boxed mt-4">
                    <div class="d-table-row header">
                        <div class="d-table-cell">No</div>
                        <div class="d-table-cell">Jenis Kendaraan</div>
                        <div class="d-table-cell">Nomor Polisi</div>
                        <div class="d-table-cell">Tahun Kendaraan</div>
                        <div class="d-table-cell">Status</div>
                    </div>
                    <div
                        class="d-table-row"
                        v-for="(item, index) in data"
                        :key="index"
                    >
                        <div class="d-table-cell">{{ item.no }}</div>
                        <div class="d-table-cell">
                            <!-- {{ item.name }} -->
                            <router-link
                                class="text-dark fw-bold"
                                :to="{
                                    name: 'detail-management-fleet',
                                    params: { id: item.id },
                                }"
                                >{{ fleetType(item.fleet_type) }}</router-link
                            >
                        </div>
                        <div class="d-table-cell">
                            {{ item.fleet_number }}
                            <!-- <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" :title="item.partner_address"> {{ item.partner_regency }} <i class="fa fa-info-circle text-blue ms-1"></i> </a> -->
                        </div>
                        <div class="d-table-cell">{{ item.fleet_year }}</div>
                        <div class="d-table-cell">
                            <small
                                class="tag green"
                                v-if="item.availability == 'open'"
                            >
                                Aktif
                            </small>
                            <small class="tag red" v-else> Tidak Aktif </small>
                        </div>
                    </div>
                </div>
                <div v-if="data.length == 0" class="text-center mt-section">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="overflow-auto mt-3">
            <b-pagination-nav
                v-if="data.length > 0"
                v-model="currentPage"
                :number-of-pages="totalPage"
                base-url="#"
                first-number
                align="right"
                @input="changePage"
            ></b-pagination-nav>
        </div>
        <div class="modal-vue">
            <div
                class="overlay"
                v-if="showModal"
                @click="showModal = false"
            ></div>
            <div class="modal-body-chat sm" v-if="showModal">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            Tambah Kendaraan
                        </div>
                    </div>
                    <div class="bd-highlight">
                        <a
                            href="javascript:void(0)"
                            @click="showModal = false"
                            class="size-20 text-primary"
                        >
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div class="line"></div>
                <div class="scroll">
                    <div>
                        <label class="fw-semibold mb-1">Foto Kendaraan</label>
                        <div class="position-relative" style="width: 280px">
                            <EnlargeableImage
                                class="preview"
                                style="width: 280px"
                                :src="fleetPictureUrl"
                            />
                            <label
                                for="upload"
                                class="upload-icon upload-button cursor-pointer"
                            >
                                <i class="fa fa-upload"></i>
                                <input
                                    class="cursor-pointer"
                                    id="upload"
                                    type="file"
                                    name="file"
                                    ref="file"
                                    @change="uploadFilefleet"
                                />
                            </label>
                        </div>
                    </div>
                    <div>
                        <label class="fw-semibold mb-1 mt-3">Status</label>
                        <div class="mb-3">
                            <div
                                class="radio form-check form-check-inline"
                                :style="[
                                    req.availability == 'open'
                                        ? { border: '1px solid #48a2d4' }
                                        : { border: 'none' },
                                ]"
                                for="inlineRadio1"
                            >
                                <input
                                    class="form-check-input"
                                    v-model="req.availability"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio1"
                                    value="open"
                                    @input="fillForm"
                                />
                                <label
                                    class="form-check-label"
                                    for="inlineRadio1"
                                    >Aktif</label
                                >
                            </div>
                            <div
                                class="radio form-check form-check-inline"
                                :style="[
                                    { marginRight: '0%' },
                                    req.availability == 'close'
                                        ? { border: '1px solid #48a2d4' }
                                        : { border: 'none' },
                                ]"
                                for="inlineRadio2"
                            >
                                <input
                                    class="form-check-input"
                                    v-model="req.availability"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio2"
                                    value="close"
                                    @input="fillForm"
                                />
                                <label
                                    class="form-check-label"
                                    for="inlineRadio2"
                                    >Tidak Aktif</label
                                >
                            </div>
                        </div>
                        <label class="fw-semibold mb-1">Jenis Kendaraan</label>
                        <select
                            class="mb-3 form-control form-select"
                            v-model="req.fleet_type"
                            @input="fillForm"
                        >
                            <option value="" selected hidden>
                                Jenis Kendaraan
                            </option>
                            <option
                                v-for="(item, index) in fleetData"
                                :key="index"
                                :value="item.code"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                        <label class="fw-semibold mb-1"
                            >Keterangan Kendaraan
                        </label>
                        <input
                            class="mb-3 form-control"
                            v-model="req.fleet_brand"
                            placeholder="Keterangan Kendaraan"
                            @input="fillForm"
                        />
                        <label class="fw-semibold mb-1">Model Kendaraan </label>
                        <input
                            class="mb-3 form-control"
                            v-model="req.fleet_model"
                            placeholder="Model Kendaraan"
                            @input="fillForm"
                        />
                        <label class="fw-semibold mb-1"> Nomor Polisi </label>
                        <input
                            class="mb-3 form-control"
                            v-model="req.fleet_number"
                            placeholder="Nomor Polisi"
                            @input="fillForm"
                        />
                        <label class="fw-semibold mb-1">
                            Nomor Rangka (Optional)
                        </label>
                        <input
                            class="mb-3 form-control"
                            v-model="req.fleet_identity"
                            placeholder="Nomor Rangka"
                            @input="fillForm"
                        />
                        <label class="fw-semibold mb-1">Tahun Kendaraan </label>
                        <input
                            class="mb-3 form-control"
                            v-model="req.fleet_year"
                            type="number"
                            placeholder="Tahun Kendaraan"
                            @input="fillForm"
                        />
                    </div>
                    <div class="row mb-5">
                        <div class="col-md-6">
                            <label class="fw-semibold mb-1"> Foto STNK </label>
                            <div class="position-relative" style="width: 160px">
                                <EnlargeableImage
                                    class="preview-2"
                                    :src="registrationPictureUrl"
                                />
                                <label
                                    for="upload-identity"
                                    class="upload-icon cursor-pointer"
                                >
                                    <i class="fa fa-upload"></i>
                                    <input
                                        class="cursor-pointer"
                                        id="upload-identity"
                                        type="file"
                                        name="file"
                                        ref="file"
                                        @change="uploadFileRegistration"
                                    />
                                </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="fw-semibold mb-1"> Foto BPKB </label>
                            <div class="position-relative" style="width: 160px">
                                <EnlargeableImage
                                    class="preview-2"
                                    :src="ownershipPictureUrl"
                                />
                                <label
                                    for="upload-license"
                                    class="upload-icon cursor-pointer"
                                >
                                    <i class="fa fa-upload"></i>
                                    <input
                                        class="cursor-pointer"
                                        id="upload-license"
                                        type="file"
                                        name="file"
                                        ref="file"
                                        @change="uploadFileOwnership"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <button
                            class="btn btn-outline-red btn-lg w-100"
                            @click="cancel"
                            :disabled="isSaving"
                        >
                            Batal
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button
                            class="btn btn-green btn-lg w-100"
                            @click="submitData"
                            :disabled="isSaving || isFilled"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isSaving"
                            ></span>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import moment from "moment";
import noImage from "../../assets/no-photo.png";
import ListLoader from "../../components/ListLoader.vue";
import EnlargeableImage from "@diracleo/vue-enlargeable-image";

export default {
    props: {
        items: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        ListLoader,
        EnlargeableImage,
    },
    name: "IncomeMitraTable",
    data() {
        return {
            showModal: false,
            is_my_list_ready: false,
            isSaving: false,
            isFilled: true,
            data: [],
            fleetData: [],
            imageFleet: "",
            imageOwnership: "",
            imageRegistration: "",
            req: {
                availability: "",
                fleet_type: "",
                fleet_brand: "",
                fleet_model: "",
                fleet_number: "",
                fleet_identity: "",
                fleet_year: "",
                ownership_picture: "",
                registration_picture: "",
                fleet_picture: "",
            },
            currentPage: 0,
            totalPage: 0,
            limit: 10,
            keySearch: "",
            filter_status: "",
            dummy: [
                {
                    name: "Blind Van",
                    phone: "B 1234 RR",
                    email: "Tahun 2020",
                    status: "aktif",
                },
                {
                    name: "Pick-up Bak",
                    phone: "B 1234 RR",
                    email: "Tahun 2020",
                    status: "tidak aktif",
                },
                {
                    name: "CDE Engkel Bak",
                    phone: "B 1234 RR",
                    email: "Tahun 2020",
                    status: "aktif",
                },
            ],
        };
    },
    created() {
        this.getListData();
        this.getFleetData();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    methods: {
        filterData() {
            this.currentPage = 0;
            this.getListData();
        },
        searchData() {
            this.currentPage = 0;
            this.getListData();
        },
        changePage() {
            this.getListData();
        },
        dateFormat(date) {
            return moment(date).format("ddd, DD MMM YYYY");
        },
        getListData() {
            this.is_my_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/fleet/list`,
                {
                    params: {
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 1,
                        limit: this.limit,
                        availability: this.filter_status,
                        search: this.keySearch,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    this.is_my_list_ready = false;
                    console.log(err);
                });
        },
        getFleetData() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/trawltruck-fleet`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.fleetData = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
        submitData() {
            this.isSaving = true;
            var data = new FormData();

            data.append("availability", this.req.availability);
            data.append("fleet_type", this.req.fleet_type);
            data.append("fleet_brand", this.req.fleet_brand);
            data.append("fleet_model", this.req.fleet_model);
            data.append("fleet_number", this.req.fleet_number);
            data.append("fleet_identity", this.req.fleet_identity);
            data.append("fleet_year", this.req.fleet_year);
            data.append("ownership_picture", this.req.ownership_picture);
            data.append("registration_picture", this.req.registration_picture);
            data.append("fleet_picture", this.req.fleet_picture);

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/partner/trawltruck/fleet`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.showModal = false;
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Data berhasil dibuat",
                    });
                    this.getListData();
                    this.clear();
                    this.isSaving = false;
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                    this.isSaving = false;
                });
        },
        cancel() {
            this.clear();
            this.showModal = false;
        },
        clear() {
            this.req.availability = "";
            this.req.fleet_type = "";
            this.req.fleet_brand = "";
            this.req.fleet_model = "";
            this.req.fleet_number = "";
            this.req.fleet_identity = "";
            this.req.fleet_year = "";
            this.req.ownership_picture = "";
            this.req.registration_picture = "";
            this.req.fleet_picture = "";
            this.imageFleet = "";
            this.imageOwnership = "";
            this.imageRegistration = "";
        },
        fillForm() {
            if (
                this.req.fleet_picture != "" &&
                this.req.registration_picture != "" &&
                this.req.ownership_picture != "" &&
                this.req.fleet_year != "" &&
                this.req.fleet_identity != "" &&
                this.req.fleet_number != "" &&
                this.req.fleet_model != "" &&
                this.req.fleet_brand != "" &&
                this.req.availability != "" &&
                this.req.fleet_type != ""
            ) {
                this.isFilled = false;
            } else {
                this.isFilled = true;
            }
        },
        fleetType(value) {
            switch (value) {
                case "bike":
                    return "Sepeda Motor";
                case "mpv":
                    return "MPV";
                case "pickup":
                    return "Pickup";
                case "pickup-box":
                    return "Pickup Box";
                case "pickup-bak":
                    return "Pickup Bak";
                case "cde engkel":
                    return "(CDE) Engkel";
                case "cde-bak":
                    return "(CDE) Engkel Bak";
                case "cde-box":
                    return "(CDE) Engkel Box";
                case "cdelong-box":
                    return "(CDE) Long Box";
                case "cdd-bak":
                    return "(CDD) Colt Diesel Double Bak";
                case "cdd-box":
                    return "(CDD) Colt Diesel Double Box";
                case "cddlong-bak":
                    return "(CDD) Long Bak";
                case "cddlong-box":
                    return "(CDD) Long Box";
                case "cdd-reefer":
                    return "(CDD) Thermo King/Reefer";
                case "fuso-bak":
                    return "Fuso Bak";
                case "fuso-box":
                    return "Fuso Box";
                case "engkel box":
                    return "Engkel Box";
                case "engkel double":
                    return "Engkel Double";
                case "engkel double box":
                    return "Engkel Double Box";
                case "fuso 6m":
                    return "Fuso 6M";
                case "fuso 9m":
                    return "Fuso 9M";
                case "tronton-bak":
                    return "Tronton Bak";
                case "tronton-box":
                    return "Tronton Box";
                case "wingbox":
                    return "Wingbox";
                case "van":
                    return "Van";
                case "blind-van":
                    return "Blind Van";
                case "towing":
                    return "Towing";
                case "selfloader":
                    return "Selfloader";
                case "trailer-20feet":
                    return "Trailer 20ft";
                case "trailer-40feet":
                    return "Trailer 40ft";
                case "lowbed":
                    return "Lowbed";
                default:
                    return value;
            }
        },
        currency(number) {
            return new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
            }).format(number);
        },
        uploadFilefleet(event) {
            var input = event.target;
            this.req.fleet_picture = input.files[0];
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.imageFleet = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
            this.fillForm();
        },
        uploadFileOwnership(event) {
            var input = event.target;
            this.req.ownership_picture = input.files[0];

            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.imageOwnership = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
            this.fillForm();
        },
        uploadFileRegistration(event) {
            var input = event.target;
            this.req.registration_picture = input.files[0];

            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.imageRegistration = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
            this.fillForm();
        },
    },
    computed: {
        fleetPictureUrl() {
            return this.imageFleet.length > 0 ? this.imageFleet : noImage;
        },
        ownershipPictureUrl() {
            return this.imageOwnership.length > 0
                ? this.imageOwnership
                : noImage;
        },
        registrationPictureUrl() {
            return this.imageRegistration.length > 0
                ? this.imageRegistration
                : noImage;
        },
    },
};
</script>

<style lang="scss" scoped>
.row.foto {
    --bs-gutter-x: 0rem !important;
}
.add-button {
    background: #48a2d4;
    border-radius: 8px;
    border: 1px solid #48a2d4;
    color: white;
    padding: 10px 15px;
}
.add-button:is(:hover, :focus) {
    background: #176f9e;
}
.form-input {
    background: rgba(239, 241, 249, 0.6);
    border-width: 0px;
    border: none;
    outline: none;
    border-radius: 10px;
    width: 280px;
    height: 52px;
    padding: 10px;
}
.tag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 134px;
    height: 30px;
}
.insert-image {
    border-width: 0px;
    border: none;
    outline: none;
}
.button {
    display: flex;
    border-radius: 12px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 168px;
    height: 44px;
    &--save {
        background: #48a2d4;
        border: 1px solid #48a2d4;
        color: white;
    }
    &--save:is(:hover, :focus) {
        background: #176f9e;
    }
    &--save:disabled {
        background: #e8e8e9;
        border: 1px solid #ffffff;
    }
    &--cancel {
        background: #ffffff;
        border: 1px solid #e94b58;
        color: #e94b58;
    }
    &--cancel:is(:hover, :focus) {
        background: #e94b58;
        border: 1px solid #ffffff;
        color: #ffffff;
    }
}
.radio {
    background: rgba(239, 241, 249, 0.6);
    border-radius: 10px;
    width: 133px;
    height: 35px;
    padding-top: 2%;
    padding-left: 10%;
}
.eye-icon {
    position: absolute;
    top: 10px;
    right: 12px;
}
input[type="file"] {
    display: none;
}
.preview :deep(img) {
    width: 280px;
    height: 120px;
    object-fit: cover;
    border-radius: 10px;
}
.preview-2 :deep(img) {
    width: 160px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
</style>
