<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_find_price"></div>
        <div id="loader" v-if="is_find_price"></div>
        <div class="box mt-4 m-mt-0">
            <h3 class="fw-bold mb-0">Cek Harga Kubikasi</h3>
            <h5 class="mb-0 fw-bold mt-4">Data Alamat</h5>
            <div class="row">
                <div class="col-md-6 mt-3">
                    <div class="box no-shadow border-gray">
                        <label class="fw-bold mb-2">Alamat Penjemputan</label>
                        <multiselect
                            v-model="pickup_address"
                            :options="pickups_address"
                            label="label"
                            :loading="is_pickup_load"
                            track-by="regency_id"
                            placeholder="Cari Alamat Penjemputan"
                            @search-change="searchPickupAddress"
                        >
                        </multiselect>
                        <div class="mt-3">
                            <label class="fw-bold mb-2">Alamat Penerima</label>
                            <multiselect
                                v-model="receiver_address"
                                :options="receivers_address"
                                label="label"
                                :loading="is_receiver_load"
                                track-by="sub_district_id"
                                placeholder="Cari Alamat Penerima"
                                @search-change="searchReceiverAddress"
                            >
                            </multiselect>
                        </div>
                    </div>
                    <h5 class="mb-0 fw-bold mt-4">Data Barang</h5>
                    <div class="box no-shadow border-gray mt-3">
                        <template v-for="(item, index) in items">
                            <div class="mb-3" :key="index">
                                <label class="fw-bold">
                                    Dimensi Barang {{ index + 1 }}
                                </label>
                                <div class="row">
                                    <div class="col-md-4 mt-2">
                                        <div class="position-relative">
                                            <money
                                                v-model="item.length"
                                                v-bind="money_format"
                                                class="form-control form-cubic"
                                            >
                                            </money>
                                            <span class="search-icon">
                                                Cm
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-2">
                                        <div class="position-relative">
                                            <money
                                                v-model="item.width"
                                                v-bind="money_format"
                                                class="form-control form-cubic"
                                            >
                                            </money>
                                            <span class="search-icon">
                                                Cm
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        class="col-md-4 mt-2 position-relative"
                                    >
                                        <div class="position-relative">
                                            <money
                                                v-model="item.height"
                                                v-bind="money_format"
                                                class="form-control form-cubic"
                                            >
                                            </money>
                                            <span class="search-icon">
                                                Cm
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="mt-3">
                            <button
                                class="btn btn-dashed-green w-100"
                                @click="addItems()"
                            >
                                <i class="fa fa-plus me-1"></i>
                                Tambah Barang
                            </button>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="mt-3 ms-auto">
                            <button
                                class="btn btn-outline-red btn-lg px-5 me-3"
                                @click="resetItems()"
                            >
                                Reset
                            </button>
                            <button
                                class="btn btn-green btn-lg px-5"
                                type="button"
                                disabled
                                v-if="is_find_price"
                            >
                                <span class="visually-hidden">Loading...</span>
                                Cek Harga
                            </button>
                            <button
                                class="btn btn-green btn-lg px-5"
                                v-else
                                @click="findPrice()"
                                :disabled="
                                    pickup_address == null &&
                                    receiver_address == null
                                "
                            >
                                Cek Harga
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <template
                        v-if="
                            result.dimensions.length > 0 && error_code == null
                        "
                    >
                        <div class="box no-shadow border-gray">
                            <div
                                class="box no-shadow bc-light-green p-2 ps-3 pe-3 fw-bold"
                            >
                                Rincian Alamat
                            </div>
                            <div class="p-2">
                                <table class="table mt-1 mb-0">
                                    <tr>
                                        <td style="width: 150px">
                                            Alamat Penjemputan
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td>
                                            {{
                                                result.origin_address
                                                    ? result.origin_address
                                                    : "-"
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 150px">
                                            Alamat Penerima
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td>
                                            {{
                                                result.destination_address
                                                    ? result.destination_address
                                                    : "-"
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 150px">
                                            Harga per Cubic
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td>
                                            {{ currency(result.tier_price) }} /
                                            Cubic
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <template
                            v-for="(dimension, index) in result.dimensions"
                        >
                            <div
                                class="box no-shadow border-gray mt-3"
                                :key="index"
                            >
                                <div
                                    class="box no-shadow bc-light-green p-2 ps-3 pe-3 fw-bold"
                                >
                                    Rincian Barang {{ index + 1 }}
                                </div>
                                <div class="p-2">
                                    <table class="table mt-1 mb-0">
                                        <tr>
                                            <td style="width: 150px">
                                                Dimensi Barang
                                            </td>
                                            <td style="width: 30px">:</td>
                                            <td>
                                                {{
                                                    decimalFormat(
                                                        dimension.length
                                                    )
                                                }}cm X
                                                {{
                                                    decimalFormat(
                                                        dimension.width
                                                    )
                                                }}cm X
                                                {{
                                                    decimalFormat(
                                                        dimension.height
                                                    )
                                                }}cm
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 150px">
                                                Berat Kubikasi
                                            </td>
                                            <td style="width: 30px">:</td>
                                            <td>
                                                {{
                                                    decimalFormat(
                                                        dimension.weight
                                                    )
                                                }}cm<sup class="p-0">3</sup>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </template>
                        <div class="box no-shadow border-gray mt-3">
                            <div
                                class="box no-shadow bc-light-green p-2 ps-3 pe-3 fw-bold"
                            >
                                Total
                            </div>
                            <div class="p-2">
                                <table class="table mt-1 mb-0">
                                    <tr>
                                        <td style="width: 150px">
                                            Total Charge Weight
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td>
                                            {{ result.total_weight }}cm<sup
                                                class="p-0"
                                                >3</sup
                                            >
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 150px">
                                            Biaya Pengiriman
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td>
                                            {{
                                                currency(result.service_amount)
                                            }}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="box no-shadow border-gray">
                            <center>
                                <img
                                    src="../../assets/no-result-cubic.png"
                                    width="150"
                                    alt=""
                                />
                                <div class="mt-3 text-gray">
                                    Mohon maaf rute belum tersedia, mohon
                                    masukkan rute lain
                                </div>
                            </center>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";
import { Money } from "v-money";

export default {
    components: {
        Multiselect,
        Money,
    },
    name: "TrawltruckCubicPrice",
    data() {
        return {
            money_format: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 0,
                masked: false,
            },
            items: [
                {
                    width: 0,
                    length: 0,
                    height: 0,
                },
            ],
            is_find_price: false,
            result: {
                dimensions: [],
                service_amount: 0,
                total_weight: 0,
            },
            error_code: null,
            pickup_address: null,
            pickups_address: [],
            search_pickup_address: "",
            is_pickup_load: false,
            receivers_address: [],
            receiver_address: null,
            search_receiver_address: "",
            is_receiver_load: false,
        };
    },
    created() {},
    methods: {
        searchPickupAddress(search_pickup_address) {
            this.search_pickup_address = search_pickup_address;
            this.is_pickup_load = true;
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/geo/list?type=sub_district&search=${search_pickup_address}&page=1&per_page=15`
            )
                .then((res) => {
                    var data = res.data.data;
                    this.pickups_address = data;
                    this.is_pickup_load = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_pickup_load = false;
                });
        },
        searchReceiverAddress(search_receiver_address) {
            this.search_receiver_address = search_receiver_address;
            this.is_receiver_load = true;
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/geo/list?type=sub_district&search=${search_receiver_address}&page=1&per_page=15`
            )
                .then((res) => {
                    var data = res.data.data;
                    this.receivers_address = data;
                    this.is_receiver_load = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_receiver_load = false;
                });
        },
        addItems() {
            this.items.push({
                width: 0,
                length: 0,
                height: 0,
            });
        },
        resetItems() {
            this.items = [
                {
                    width: 0,
                    length: 0,
                    height: 0,
                },
            ];
            this.pickup_address = null;
            this.receiver_address = null;
            this.result.dimensions = [];
            this.result.service_amount = 0;
            this.result.total_weight = 0;
        },
        findPrice() {
            this.is_find_price = true;
            var data = {
                origin_id: this.pickup_address.regency_id,
                destination_id: this.receiver_address.sub_district_id,
                items: this.items,
            };
            Api.post(
                `${process.env.VUE_APP_BASE_URL}/pricing/calculator/cubic`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.result = res.data.data;
                    this.is_find_price = false;
                    this.error_code = null;
                })
                .catch((err) => {
                    this.error_code = err.response.data.code;
                    this.is_find_price = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
    },
};
</script>

<style scoped>
.search-icon {
    top: 0.26rem !important;
    font-size: 16px;
    right: 5px;
    background: #e9ecef;
    border-radius: 4px;
    padding: 4px 10px;
}
.form-cubic {
    padding: 0.5rem 0.9rem;
    font-size: 16px;
    border-radius: 6px;
}
</style>
