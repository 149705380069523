<template>
    <div class="padding-container">
        <div class="content-load" v-if="isLoadData"></div>
        <div id="loader" v-if="isLoadData"></div>
        <a
            href="/trawltruck/driver/registration"
            class="text-black size-16 text-gray-dashboard fw-semibold"
        >
            <i class="fa fa-angle-left me-2"></i>
            Kembali
        </a>
        <div class="box mt-4">
            <h3 class="mb-0 fw-bold">Reject Driver TroTruck</h3>
            <h5 class="mt-3 mb-0 fw-bold">Checklist Sheet</h5>
            <div class="text-gray-dashboard">
                Mohon tandai berkas driver yang tidak sesuai
            </div>
            <div class="row">
                <div class="col-md-4 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="bank_id"
                            value="bank_id"
                            v-model="req.fields"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="bank_id"
                        >
                            Nama Bank
                        </label>
                        <div class="form-box mt-2">
                            {{
                                data?.bank_account?.bank?.name
                                    ? data?.bank_account?.bank?.name
                                    : "-"
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="account_number"
                            value="account_number"
                            v-model="req.fields"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="account_number"
                        >
                            Nomor Rekening
                        </label>
                        <div class="form-box mt-2">
                            {{
                                data?.bank_account?.account_number
                                    ? data?.bank_account?.account_number
                                    : "-"
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="fleet_number"
                            value="fleet_number"
                            v-model="req.fields"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="fleet_number"
                        >
                            Nomor Kendaraan
                        </label>
                        <div class="form-box mt-2">
                            {{
                                data?.driver_truck?.fleet?.fleet_number
                                    ? data?.driver_truck?.fleet?.fleet_number
                                    : "-"
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="driver_picture"
                            v-model="req.fields"
                            value="driver_picture"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="driver_picture"
                        >
                            Foto Driver
                        </label>
                        <div class="mt-2">
                            <template
                                v-if="data?.driver_truck?.driver_picture_url"
                            >
                                <viewer :images="[]" :options="options">
                                    <img
                                        class="image-cover"
                                        :key="
                                            data?.driver_truck
                                                ?.driver_picture_url
                                        "
                                        :src="
                                            data?.driver_truck
                                                ?.driver_picture_url
                                        "
                                    />
                                </viewer>
                            </template>
                            <template v-else>
                                <div
                                    class="image-default"
                                    :style="{
                                        'background-image':
                                            'url(' +
                                            require('../../assets/empty.png') +
                                            ')',
                                    }"
                                ></div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="identity_picture"
                            value="identity_picture"
                            v-model="req.fields"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="identity_picture"
                        >
                            Foto KTP
                        </label>
                        <div class="mt-2">
                            <template
                                v-if="data?.driver_truck.identity_picture_url"
                            >
                                <viewer :images="[]" :options="options">
                                    <img
                                        class="image-cover"
                                        :key="
                                            data?.driver_truck
                                                .identity_picture_url
                                        "
                                        :src="
                                            data?.driver_truck
                                                .identity_picture_url
                                        "
                                    />
                                </viewer>
                            </template>
                            <template v-else>
                                <div
                                    class="image-default"
                                    :style="{
                                        'background-image':
                                            'url(' +
                                            require('../../assets/empty.png') +
                                            ')',
                                    }"
                                ></div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="license_picture"
                            value="license_picture"
                            v-model="req.fields"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="license_picture"
                        >
                            Foto SIM
                        </label>
                        <div class="mt-2">
                            <template
                                v-if="data?.driver_truck.license_picture_url"
                            >
                                <viewer :images="[]" :options="options">
                                    <img
                                        class="image-cover"
                                        :key="
                                            data?.driver_truck
                                                .license_picture_url
                                        "
                                        :src="
                                            data?.driver_truck
                                                .license_picture_url
                                        "
                                    />
                                </viewer>
                            </template>
                            <template v-else>
                                <div
                                    class="image-default"
                                    :style="{
                                        'background-image':
                                            'url(' +
                                            require('../../assets/empty.png') +
                                            ')',
                                    }"
                                ></div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="skck_picture"
                            value="skck_picture"
                            v-model="req.fields"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="skck_picture"
                        >
                            Foto SKCK (Opsional)
                        </label>
                        <div class="mt-2">
                            <template
                                v-if="data?.driver_truck?.skck_picture_url"
                            >
                                <viewer :images="[]" :options="options">
                                    <img
                                        class="image-cover"
                                        :key="
                                            data?.driver_truck?.skck_picture_url
                                        "
                                        :src="
                                            data?.driver_truck?.skck_picture_url
                                        "
                                    />
                                </viewer>
                            </template>
                            <template v-else>
                                <div
                                    class="image-default"
                                    :style="{
                                        'background-image':
                                            'url(' +
                                            require('../../assets/empty.png') +
                                            ')',
                                    }"
                                ></div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="registration_picture"
                            value="registration_picture"
                            v-model="req.fields"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="registration_picture"
                        >
                            Foto STNK
                        </label>
                        <div class="mt-2">
                            <template
                                v-if="
                                    data?.driver_truck.fleet
                                        ?.registration_picture_url
                                "
                            >
                                <viewer :images="[]" :options="options">
                                    <img
                                        class="image-cover"
                                        :key="
                                            data?.driver_truck.fleet
                                                ?.registration_picture_url
                                        "
                                        :src="
                                            data?.driver_truck.fleet
                                                ?.registration_picture_url
                                        "
                                    />
                                </viewer>
                            </template>
                            <template v-else>
                                <div
                                    class="image-default"
                                    :style="{
                                        'background-image':
                                            'url(' +
                                            require('../../assets/empty.png') +
                                            ')',
                                    }"
                                ></div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="kir_picture"
                            value="kir_picture"
                            v-model="req.fields"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="kir_picture"
                        >
                            Foto KIR
                        </label>
                        <div class="mt-2">
                            <template v-if="data?.driver_truck.kir_picture_url">
                                <viewer :images="[]" :options="options">
                                    <img
                                        class="image-cover"
                                        :key="
                                            data?.driver_truck.kir_picture_url
                                        "
                                        :src="
                                            data?.driver_truck.kir_picture_url
                                        "
                                    />
                                </viewer>
                            </template>
                            <template v-else>
                                <div
                                    class="image-default"
                                    :style="{
                                        'background-image':
                                            'url(' +
                                            require('../../assets/empty.png') +
                                            ')',
                                    }"
                                ></div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="fleet_picture"
                            value="fleet_picture"
                            v-model="req.fields"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="fleet_picture"
                        >
                            Foto Armada (Depan)
                        </label>
                        <div class="mt-2">
                            <template
                                v-if="
                                    data?.driver_truck?.fleet?.fleet_picture_url
                                "
                            >
                                <viewer :images="[]" :options="options">
                                    <img
                                        class="image-cover"
                                        :key="
                                            data?.driver_truck?.fleet
                                                ?.fleet_picture_url
                                        "
                                        :src="
                                            data?.driver_truck?.fleet
                                                ?.fleet_picture_url
                                        "
                                    />
                                </viewer>
                            </template>
                            <template v-else>
                                <div
                                    class="image-default"
                                    :style="{
                                        'background-image':
                                            'url(' +
                                            require('../../assets/empty.png') +
                                            ')',
                                    }"
                                ></div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="fleet_picture_side"
                            value="fleet_picture_side"
                            v-model="req.fields"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="fleet_picture_side"
                        >
                            Foto Armada (Samping)
                        </label>
                        <div class="mt-2">
                            <template
                                v-if="
                                    data?.driver_truck.fleet
                                        ?.fleet_picture_side_url
                                "
                            >
                                <viewer :images="[]" :options="options">
                                    <img
                                        class="image-cover"
                                        :key="
                                            data?.driver_truck.fleet
                                                ?.fleet_picture_side_url
                                        "
                                        :src="
                                            data?.driver_truck.fleet
                                                ?.fleet_picture_side_url
                                        "
                                    />
                                </viewer>
                            </template>
                            <template v-else>
                                <div
                                    class="image-default"
                                    :style="{
                                        'background-image':
                                            'url(' +
                                            require('../../assets/empty.png') +
                                            ')',
                                    }"
                                ></div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="fleet_picture_back"
                            value="fleet_picture_back"
                            v-model="req.fields"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="fleet_picture_back"
                        >
                            Foto Armada (Belakang)
                        </label>
                        <div class="mt-2">
                            <template
                                v-if="
                                    data?.driver_truck?.fleet
                                        ?.fleet_picture_back_url
                                "
                            >
                                <viewer :images="[]" :options="options">
                                    <img
                                        class="image-cover"
                                        :key="
                                            data?.driver_truck?.fleet
                                                ?.fleet_picture_back_url
                                        "
                                        :src="
                                            data?.driver_truck?.fleet
                                                ?.fleet_picture_back_url
                                        "
                                    />
                                </viewer>
                            </template>
                            <template v-else>
                                <div
                                    class="image-default"
                                    :style="{
                                        'background-image':
                                            'url(' +
                                            require('../../assets/empty.png') +
                                            ')',
                                    }"
                                ></div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <label>Alasan Penolakan</label>
                    <textarea
                        cols="30"
                        rows="5"
                        class="form-control mt-2"
                        v-model="req.reason"
                        placeholder="Tuliskan alasan penolakan driver"
                    ></textarea>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <a
                        href="/trawltruck/driver/registration"
                        class="btn btn-outline-red btn-lg px-4 me-3"
                    >
                        Batal
                    </a>
                    <button
                        class="btn btn-green btn-lg px-5"
                        :disabled="req.reason.length == 0 || req.reason == ''"
                        @click="openModalSubmit = true"
                    >
                        Simpan
                    </button>
                </div>
            </div>
        </div>
        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="openModalSubmit"></div>
            <div class="modal-body-chat vsm" v-if="openModalSubmit">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-bold text-center">
                            <img
                                src="../../assets/info-yellow.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-bold text-center">
                        Registrasi driver ini akan ditolak
                    </div>
                    <div class="mt-3">
                        <label class="fw-semibold text-gray-dashboard"
                            >Alasan Penolakan</label
                        >
                        <div class="form-box mt-2">‘{{ req.reason }}’</div>
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-outline-red w-100 btn-lg"
                                @click="openModalSubmit = false"
                            >
                                Batal
                            </button>
                        </div>
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-green w-100 btn-lg"
                                @click="storeReject()"
                                :disabled="is_reject"
                            >
                                <span
                                    v-if="is_reject"
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="openModalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="openModalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Registrasi driver ini sudah ditolak!
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/trawltruck/driver/registration"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";

export default {
    components: {
        Viewer,
    },
    data() {
        return {
            openModalSubmit: false,
            openModalSuccess: false,
            data: {
                bank_account: {
                    bank: {
                        name: "",
                        account_number: "",
                    },
                },
                driver_truck: {
                    fleet: {},
                },
            },
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
            isLoadData: false,
            req: {
                reason: "",
                fields: [],
            },
            is_reject: false,
        };
    },
    created() {
        this.getData();
        this.req.fields.toString();
    },
    computed: {},
    methods: {
        getData() {
            this.isLoadData = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/trawltruck/driver/detail`,
                {
                    params: { user_id: this.$route.params.id },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data;
                    this.isLoadData = false;
                })
                .catch((err) => {
                    this.isLoadData = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        storeReject() {
            this.is_reject = true;
            var fields = this.req.fields.toString();
            var data = {
                user_id: this.$route.params.id,
                reason: this.req.reason,
                fields: fields,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/admin/trawltruck/register/reject`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.openModalSuccess = true;
                    this.openModalSubmit = false;
                    this.is_reject = false;
                })
                .catch((err) => {
                    this.openModalSubmit = false;
                    this.is_reject = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
    },
};
</script>

<style scoped>
.form-input {
    background: rgba(239, 241, 249, 0.6);
    border-width: 0px;
    border: none;
    outline: none;
    border-radius: 10px;
    width: 258px;
    height: 40px;
    padding: 10px;
}
.image-cover {
    border-radius: 10px;
    width: 100%;
    height: 150px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid #f7f7f7;
}
.image-default {
    width: 100%;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    border: 2px solid #f7f7f7;
}
.checkbox input[type="checkbox"] {
    display: none;
}
.form-check-input {
    display: none !important;
}
.form-check label {
    padding-left: 0;
    cursor: pointer;
}

.form-check label:before {
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px;
    line-height: 18px;
    text-align: center;
    border: 1px solid #ccc;
    background: #fafafa;
    border-radius: 3px;
    font-family: "FontAwesome";
    cursor: pointer;
}
.form-check input[type="checkbox"]:checked + label::before {
    content: "\f00d";
    background: #faccd0;
    color: #e60013;
    border: 1px solid #e60013;
}
</style>
