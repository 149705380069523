import moment from "moment";

function titleCase(str) {
    return str.toLowerCase().replace(/(^|\s)\S/g, (L) => L.toUpperCase());
}

function numbering(index, currentPage, limitPerPage) {
    let number = index + startIndex(currentPage, limitPerPage) + 1;
    return (number < 10 ? "0" : "") + number;
}

function startIndex(currentPage, limitPerPage) {
    return (currentPage - 1) * limitPerPage;
}

function dateFormat(date, format, dateSet = "DDMMYYYY") {
    return moment(date, dateSet).format(format);
}

function currency(num) {
    return (
        "Rp" +
        parseInt(num)
            .toFixed(0)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
    );
}

function decimalFormat(num) {
    return (
        "" +
        parseInt(num)
            .toFixed(0)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
    );
}

function checkRole(roles) {
    let user_roles = localStorage.getItem("roles");
    if (user_roles == null) user_roles = [];
    let result = roles.some((el) => user_roles.includes(el));
    return result;
}

export default {
    titleCase,
    numbering,
    dateFormat,
    currency,
    checkRole,
    decimalFormat,
};
