<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/master/customer" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="row">
                <div class="col-md-1">
                    <div
                        class="avatar-image"
                        v-if="detail.avatar"
                        :style="{
                            'background-image': 'url(' + detail.avatar + ')',
                        }"
                    ></div>
                    <div
                        class="avatar-image"
                        v-else
                        :style="{
                            backgroundImage: `url(${require('../assets/user-dummy.png')})`,
                        }"
                    ></div>
                </div>
                <div class="col-md-11">
                    <h3 class="fw-bold">Detail Informasi Customer</h3>
                    <div class="mt-4 row">
                        <div class="col-md-6">
                            <label class="fw-bold mb-2">Nama Customer</label>
                            <div class="form-box bg-white">
                                {{ detail.name ? detail.name : "-" }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="fw-bold mb-2"
                                >Tanggal Bergabung</label
                            >
                            <div class="form-box bg-white">
                                {{
                                    detail.created_at ? detail.created_at : "-"
                                }}
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="fw-bold mb-2">Nomor Telepon</label>
                            <div class="form-box bg-white">
                                {{ detail.phone ? detail.phone : "-" }}
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="fw-bold mb-2">Email</label>
                            <div class="form-box bg-white">
                                {{ detail.email ? detail.email : "-" }}
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="fw-bold mb-2">Media Sosial</label>
                            <div
                                class="d-flex"
                                v-if="detail.social_type.length > 0"
                            >
                                <div
                                    class="me-2"
                                    v-if="detail.social_type.includes(`google`)"
                                >
                                    <img
                                        src="../assets/google.png"
                                        width="20"
                                        alt=""
                                    />
                                </div>
                                <div
                                    class="me-2"
                                    v-if="
                                        detail.social_type.includes(`facebook`)
                                    "
                                >
                                    <img
                                        src="../assets/facebook.png"
                                        width="20"
                                        alt=""
                                    />
                                </div>
                                <div
                                    class="me-2"
                                    v-if="detail.social_type.includes(`apple`)"
                                >
                                    <img
                                        src="../assets/apple.png"
                                        width="20"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";

export default {
    name: "MasterCustomerDetail",
    components: {},
    data() {
        return {
            detail: {},
        };
    },
    created() {
        this.getDetail();
    },
    computed: {},
    methods: {
        getDetail() {
            var payload = {
                customer_id: this.$route.params.id,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/general/master-data/customer/detail`,
                {
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detail = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.avatar-image {
    width: 70px;
    height: 70px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    border: 2px solid #e9ecef;
}
</style>
