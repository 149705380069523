<template>
    <div class="padding-container">
        <div class="d-flex align-items-center bd-highlight">
            <div class="d-flex align-items-center">
                <div class="me-3">
                    <a href="/trawlcarrier/ho/manifest" class="text-primary">
                        <i class="fa fa-angle-left size-24 fw-bold"></i>
                    </a>
                </div>
                <h3 class="fw-bold mb-0">
                    Detail Manifest
                </h3>
            </div>
        </div>
        <div class="box mt-4">
            <div class="mt-4" v-if="is_data_ready">
                <CardLoader/>
            </div>
            <div class="row" v-else>
                <div class="col-md-12">
                    <span class="tag green size-18">{{ list.dashboard.manifest }}</span>
                </div>
                <div class="col-md-3 mt-3">
                    <div class="box no-shadow border-gray bc-gray">
                        <h5 class="fw-bold mb-0 text-gray">
                            Rute Manifest
                        </h5>
                        <div class="fw-ekstra-bold size-24 mt-3" style="width: 200px;">
                            <a href="javascript:void(0)" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" :title="list.dashboard.rute">
                                <div class="limit-one-line" style="font-size: 24px;">
                                    {{ list.dashboard.rute }}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mt-3">
                    <div class="box no-shadow border-gray bc-gray">
                        <h5 class="fw-bold mb-0 text-gray">
                            Tanggal Manifest
                        </h5>
                        <div class="fw-ekstra-bold size-24 mt-3">
                            {{ moment(list.dashboard.tanggal).format('DD MMM YYYY') }}
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mt-3">
                    <div class="box no-shadow border-gray bc-gray">
                        <h5 class="fw-bold mb-0 text-gray">
                            Layanan
                        </h5>
                        <div class="fw-ekstra-bold size-24 mt-3 capitalize">
                            {{ (list.dashboard.layanan_type || "").split("_").join(" ") }}
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mt-3">
                    <div class="box no-shadow border-gray bc-gray">
                        <h5 class="fw-bold mb-0 text-gray">
                            Layanan Pengiriman
                        </h5>
                        <div class="fw-ekstra-bold size-24 mt-3 capitalize">
                            {{ (list.dashboard.layanan || "").split("_").join(" ") }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_data_ready">
                <ListLoader/>
            </div>
            <div v-else>
                <div class="row mt-4">
                    <div class="col-md-2 mt-3">
                        <button class="btn btn-outline-black btn-md w-100" disabled>
                            Tambah Resi
                        </button>
                    </div>
                    <div class="col-md-3 mt-3">
                        <button class="btn btn-green btn-md w-100" disabled>
                            Selesai Tambah Resi
                        </button>
                    </div>
                </div>

                <div class="modern-table-boxed mt-4">
                    <div class="d-table-row header">
                        <div class="d-table-cell">No</div>
                        <div class="d-table-cell">Id Order</div>
                        <div class="d-table-cell">kendaraan</div>
                        <div class="d-table-cell">Pengirim</div>
                        <div class="d-table-cell">Aksi</div>
                    </div>
                    <div class="d-table-row">
                        <div class="d-table-cell fw-bold">
                            1.
                        </div>
                        <div class="d-table-cell fw-bold">
                            {{ list.order_id }}
                        </div>
                        <div class="d-table-cell fw-bold">
                            {{ list.kendaraan }}
                        </div>
                        <div class="d-table-cell fw-bold">
                            {{ list.pengirim }}
                        </div>
                        <div class="d-table-cell fw-bold">
                            <button class="btn btn-outline-primary btn-sm px-4" disabled>Batalkan</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Api from "../../api/Api";
import ListLoader from '../../components/ListLoader.vue';
import CardLoader from '../../components/CardLoader.vue';
import moment from 'moment'
import { Tooltip } from "bootstrap";

export default {
    components: {
        ListLoader,
        CardLoader
    },
    name: "DetailManifest",
    data() {
        return {
            moment:moment,
            is_data_ready: false,
            list: {
                dashboard: {}
            },
        };
    },
    created() {
        this.getIncomeList()
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    methods: {
        getIncomeList() {
            this.is_data_ready = true
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/carrier/ho/manifest/detail/${this.$route.params.id}`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                var data = res.data.data
                this.list = data
                this.is_data_ready = false
            })
            .catch((err) => {
                console.log(err);
                this.is_data_ready = false
            });
        },
    },
};
</script>

<style>
</style>