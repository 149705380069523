<template>
    <div class="padding-container">
        <a
            href="/trawltruck/withdraw/individu/"
            class="text-gray-dashboard size-16 fw-bold"
        >
            <i class="fa fa-angle-left me-2"></i>
            Kembali
        </a>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h4 class="mb-0 fw-bold">Detail Tarik Dana</h4>
                <div
                    class="tag radius ms-auto"
                    v-bind:class="{
                        'green-cro': detail.status == 'success',
                        'yellow-cro': detail.status == 'pending',
                    }"
                >
                    <template v-if="detail.status == 'success'"
                        >Telah Ditransfer</template
                    >
                    <template v-if="detail.status == 'pending'"
                        >Mengajukan</template
                    >
                </div>
            </div>
            <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                <tr>
                    <td class="ps-0 w-200">Tanggal Pengajuan</td>
                    <td class="w-30">:</td>
                    <td>
                        {{ detail.requested_at ? detail.requested_at : "-" }}
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Nomor Transaksi</td>
                    <td class="w-30">:</td>
                    <td class="capitalize">
                        {{
                            detail.transaction_code
                                ? detail.transaction_code
                                : "-"
                        }}
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Nama Perusahaan/Driver</td>
                    <td class="w-30">:</td>
                    <td>{{ detail.name ? detail.name : "-" }}</td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Jenis Mitra</td>
                    <td class="w-30">:</td>
                    <td>
                        <template v-if="detail.type == 'driver'"
                            >Individu</template
                        >
                        <template v-else>Corporate</template>
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Nama Bank</td>
                    <td class="w-30">:</td>
                    <td>
                        {{
                            detail?.bank?.name_name
                                ? detail?.bank?.name_name
                                : "-"
                        }}
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Nomor Rekening</td>
                    <td class="w-30">:</td>
                    <td>
                        {{
                            detail?.bank?.account_number
                                ? detail?.bank?.account_number
                                : "-"
                        }}
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Jumlah Pengajuan Tarik Dana</td>
                    <td class="w-30">:</td>
                    <td>
                        {{ currency(detail.amount ? detail.amount : 0) }}
                    </td>
                </tr>
            </table>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <button
                        class="btn btn-green btn-lg px-4"
                        :disabled="detail.status != 'pending'"
                        @click="modalUplaod = true"
                    >
                        Approve
                    </button>
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <h3 class="fw-bold mb-3">Riwayat Pengajuan Tarik Dana</h3>
            <button
                class="btn btn-outline-black btn-filter px-4"
                @click="isFilter = !isFilter"
            >
                <img
                    src="../../assets/dashboard/filter.png"
                    class="me-1"
                    width="18"
                    alt=""
                />
                Filter
            </button>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <label class="fw-bold mb-1">Status Transaksi </label>
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <multiselect
                            v-model="status_transaction"
                            :options="status_transactions"
                            label="name"
                            track-by="id"
                            placeholder="Pilih status transaksi"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <button
                                    class="btn btn-outline-black w-100"
                                    :disabled="status_transaction == null"
                                    @click="clearFilter()"
                                >
                                    Clear
                                </button>
                            </div>
                            <div class="col-md-6">
                                <button
                                    class="btn btn-green w-100"
                                    :disabled="status_transaction == null"
                                    @click="filter()"
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div class="table-responsive-custom" v-else>
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">
                                Tanggal Pengajuan
                            </th>
                            <th class="text-center" scope="col">
                                Nomor Transaksi
                            </th>
                            <th class="text-center" scope="col">
                                Nama Perusahaan/Driver
                            </th>
                            <th class="text-center" scope="col">
                                Jumlah Pengajuan Tarik Dana
                            </th>
                            <th class="text-center" scope="col">
                                Status Transaksi
                            </th>
                            <th class="text-center" scope="col">Lampiran</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(data, index) in list.list_data"
                            :key="index"
                        >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">
                                {{
                                    data.requested_at ? data.requested_at : "-"
                                }}
                            </td>
                            <td class="text-center">
                                {{
                                    data.transaction_code
                                        ? data.transaction_code
                                        : "-"
                                }}
                            </td>
                            <td>
                                <a
                                    :href="
                                        `/trawltruck/withdraw/individu/` +
                                        data.transaction_code
                                    "
                                    class="text-link"
                                >
                                    {{ data.name ? data.name : "-" }}
                                </a>
                            </td>
                            <td>
                                {{ currency(data.amount ? data.amount : 0) }}
                            </td>
                            <td class="text-center">
                                <span
                                    class="tag radius size-12 w-100"
                                    v-bind:class="{
                                        'green-cro': data.status == 'success',
                                        'yellow-cro': data.status == 'pending',
                                    }"
                                >
                                    <template v-if="data.status == 'success'"
                                        >Telah Ditransfer</template
                                    >
                                    <template v-if="data.status == 'pending'"
                                        >Mengajukan</template
                                    >
                                </span>
                            </td>
                            <td class="text-center">
                                <template v-if="data.attachment">
                                    <a
                                        href="javascript:void(0)"
                                        @click="showUploadModal(data)"
                                        class="text-link"
                                    >
                                        <div class="d-flex align-items-center">
                                            <div class="me-1">
                                                <img
                                                    src="../../assets/file-upload.png"
                                                    width="20"
                                                    alt=""
                                                />
                                            </div>
                                            <div>Lihat Bukti Transfer</div>
                                        </div>
                                    </a>
                                </template>
                                <template v-else>-</template>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center mt-5" v-if="list.list_data.length == 0">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalUplaod"></div>
            <div class="modal-body-chat vsm" v-if="modalUplaod">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-new.png" width="70" alt="" />
                    <h4 class="mb-0 fw-bold mt-3">
                        Penarikan Dana Akan Disetujui
                    </h4>
                    <div class="mb-3 text-gray-dashboard">
                        Mohon upload bukti transfer dana
                    </div>
                    <label
                        type="file"
                        :class="!imagePhoto ? 'upload__btn' : 'pencil'"
                        class="position-relative"
                    >
                        <img
                            v-if="imagePhoto"
                            class="photo"
                            :src="driverPictureUrl"
                        />
                        <p v-else class="mb-0" style="color: #adb5bd">
                            <img
                                src="../../assets/upload-image.png"
                                class="me-1"
                                alt=""
                            />
                            Tambah Gambar
                        </p>
                        <input
                            class="cursor-pointer"
                            id="upload"
                            type="file"
                            name="file"
                            ref="file"
                            @change="uploadPhoto"
                        />
                    </label>
                </div>
                <div class="mt-4">
                    <button
                        class="btn btn-green w-100 btn-lg"
                        :disabled="is_upload"
                        @click="approveUpload()"
                    >
                        <span
                            v-if="is_upload"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Simpan
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalShowImage"></div>
            <div class="modal-body-chat vsm" v-if="modalShowImage">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-new.png" width="60" alt="" />
                    <h4 class="mb-0 fw-bold mt-3">Bukti transfer dana</h4>
                    <div class="mt-4">
                        <label type="file" class="position-relative">
                            <img
                                class="photo-show"
                                :src="upload_data.attachment"
                            />
                        </label>
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="modalShowImage = false"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessUpload"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessUpload">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Penarikan Dana Telah Disetujui
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        :href="
                            `/trawltruck/withdraw/individu/` +
                            detail.transaction_code
                        "
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import noImage from "../../assets/no-photo.png";
import Multiselect from "vue-multiselect";
import ListLoader from "../../components/ListLoader.vue";

export default {
    components: { Multiselect, ListLoader },
    name: "withdrawIndividuDetail",
    data() {
        return {
            detail: {
                bank: {},
            },
            modalUplaod: false,
            imagePhoto: "",
            photo: "",
            is_list_ready: false,
            list: {
                list_data: [],
            },
            status_transaction: null,
            status_transactions: [
                { name: "Telah Ditransfer", id: "success" },
                { name: "Mengajukan", id: "pending" },
            ],
            isFilter: false,
            modalShowImage: false,
            upload_data: {},
            is_upload: false,
            modalSuccessUpload: false,
        };
    },
    created() {
        this.getDetail();
    },
    computed: {
        driverPictureUrl() {
            return this.imagePhoto.length > 0 ? this.imagePhoto : noImage;
        },
    },
    methods: {
        getDetail() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/truck/finance/admin/withdraw/detail?receipt=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detail = data;
                    this.getList();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        uploadPhoto(event) {
            var input = event.target;
            this.photo = input.files[0];
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.imagePhoto = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
        getList() {
            this.is_list_ready = true;
            let payload = {
                id: this.detail.user_id,
            };
            if (this.status_transaction) {
                payload.status_trx = this.status_transaction?.id;
            }
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/truck/finance/admin/withdraw/lists`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        clearFilter() {
            this.status_transaction = null;
            this.getList();
        },
        filter() {
            this.getList();
        },
        showUploadModal(data) {
            this.upload_data = data;
            this.modalShowImage = true;
        },
        approveUpload() {
            this.is_upload = true;
            var data = new FormData();
            data.append("receipt", this.$route.params.id);
            if (this.photo) {
                data.append("attachment", this.photo);
            }
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/truck/finance/admin/withdraw/approve`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_upload = false;
                    console.log(res);
                    this.modalSuccessUpload = true;
                    this.modalUplaod = false;
                })
                .catch((err) => {
                    this.is_upload = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
    },
};
</script>

<style scoped>
.text-gray-dashboard {
    color: #61616a;
}
.w-200 {
    width: 200px;
}
.w-30 {
    width: 30px;
}
.w-table-modal {
    width: 160px;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    border: dashed;
    border-radius: 10px;
    width: 100%;
    height: 150px;
    padding: 55px;
    position: relative;
}
.photo {
    border-radius: 10px;
    cursor: pointer;
    width: 20vw;
    height: 150px;
    object-fit: cover;
    border: dashed;
    color: #dee2e6;
}
.photo-show {
    border-radius: 10px;
    cursor: pointer;
    width: 20vw;
    height: 150px;
    object-fit: cover;
    border: 2px solid #dee2e6;
    color: #dee2e6;
}
input[type="file"] {
    display: none;
}
.upload__btn p img {
    cursor: pointer;
    width: 28px;
    font-size: 14px;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
</style>
