<template>
  <div v-if="!isDetail" class="padding-container">
    <div class="d-flex align-items-center">
      <h3 class="mb-0 fw-bold">Pendapatan {{ items.name }}</h3>
      <div class="ms-auto">
        <div class="position-relative">
          <input class="form-control" v-model="monthFilter" type="month" @change="getSummary" style="border: 1px solid #48a2d4" placeholder="Pilih Bulan" />
          <span class="search-icon">
            <img src="../../assets/calendar-icon.png" />
          </span>
        </div>
      </div>
    </div>
    <div class="box mt-4 gap-2">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <img style="width: 36px; height: 36px" src="../../assets/pie-chart-icon.png" />
          <div class="fw-bold ms-2">{{ items.name }}</div>
        </div>
        <div class="text-end">{{ monthFormat(monthFilter) }}</div>
      </div>
      <div class="d-flex mt-4" style="gap: 8%">
        <div class="d-flex flex-column">
          <div class="text-gray">Total Saldo Mitra Sekarang</div>
          <div class="fw-bold size-16">{{ currency(dataSummary.income?.balance) }}</div>
        </div>
        <div class="d-flex flex-column">
          <div class="text-gray">Pendapatan Mitra Sekarang</div>
          <div class="fw-bold size-16">
            {{ currency(dataSummary.income?.current_income) }} <span><button class="btn-detail ms-4" @click="changeToDetail">Lihat Detail</button></span>
          </div>
        </div>
        <div class="d-flex flex-column">
          <div class="text-gray">Pendapatan Mitra Sebelumnya</div>
          <div class="fw-bold size-16">{{ currency(dataSummary.income?.previous_income) }}</div>
        </div>
        <div class="d-flex flex-column">
          <div class="text-gray">Peningkatan</div>
          <div class="fw-bold size-16">{{ currency(dataSummary.income?.increased_income) }}</div>
        </div>
      </div>
    </div>
    <div class="box mt-3">
      <div class="d-flex justify-content-between mb-2">
        <div class="fw-bold">Pendapatan Mitra</div>
        <div>{{ monthFormat(monthFilter) }}</div>
      </div>
      <apexcharts ref="chartmonthly" width="100%" height="350" type="bar" :options="chartOptions" :series="chartSeries"></apexcharts>
    </div>
    <div class="box mt-3">
      <div class="d-flex bd-highlight">
        <div class="flex-grow-1 bd-highlight">
          <h4 class="fw-bold">List Request Pencairan</h4>
        </div>
        <div class="align-self-center me-3">
          <button class="label-req" @click="showModal = true">Request Pencairan</button>
        </div>
        <div class="ms-2">
          <div class="position-relative">
            <input v-model="keySearch" @keyup.enter="searchData" type="text" class="form-control" style="width: 155px; padding-right: 30px" placeholder="Search" />
            <span class="search-icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </div>
        <div class="ms-2">
          <!-- <input class="form-control" style="width: 120px" type="text" onfocus="(this.type='date')" v-model="datePicker" @change="searchData" placeholder="Pilih Tanggal" /> -->
          <div class="position-relative">
            <input class="form-control" v-model="datePicker" type="text" onfocus="(this.type='date')" @change="searchData" style="border: 1px solid #48a2d4" placeholder="Pilih Tanggal" />
            <span class="search-icon">
              <img src="../../assets/calendar-icon.png" />
            </span>
          </div>
        </div>
      </div>
      <div class="mt-4" v-if="is_my_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">Tanggal</div>
            <div class="d-table-cell">Nomor Request</div>
            <div class="d-table-cell">Total Request</div>
            <div class="d-table-cell">Total Penerimaan</div>
            <div class="d-table-cell">Status Request</div>
          </div>
          <div class="d-table-row" v-for="(item, index) in data" :key="index">
            <div class="d-table-cell">{{ itemNumber(index) }}</div>
            <div class="d-table-cell">
              {{ dateFormat(item.created_at) }}
            </div>
            <div class="d-table-cell">
              <a href="javascript:void(0)" @click="reqDetail(item)">
                {{ item.transaction_code }}
              </a>
            </div>
            <div class="d-table-cell">{{ currency(item.request_amount) }}</div>
            <div class="d-table-cell">{{ currency(item.total_accepted) }}</div>
            <div class="d-table-cell">
              <small :class="['tag_stat', item.status == 'transferred' ? 'tag red' : item.status == 'pending' ? 'tag yellow' : item.status == 'request' ? 'tag blue' : item.status == 'approved' ? 'tag green' : 'tag green']">
                {{ capitalizeFirstLetter(item.status) }}
              </small>
            </div>
          </div>
        </div>
        <div v-if="dataItem.length == 0" class="text-center mt-section">
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
    </div>
    <div class="overflow-auto mt-3">
      <b-pagination-nav v-if="data.length > 0" v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="isReqDetail" @click="isReqDetail = false"></div>
      <div class="modal-body-chat sm" v-if="isReqDetail">
        <div class="d-flex bd-highlight">
          <div class="flex-grow-1 bd-highlight">
            <div class="d-flex">
              <div class="align-self-center">
                <img v-if="dataReqDetail.status == 'requested'" class="me-3" src="../../assets/papper-icon.png" />
                <img v-if="dataReqDetail.status == 'approved'" class="me-3" src="../../assets/accept.png" />
                <img v-if="dataReqDetail.status == 'transferred'" class="me-3" src="../../assets/clock-icon.png" />
              </div>
              <div>
                <div class="size-18 fw-ekstra-bold">{{ capitalizeFirstLetter(dataReqDetail.status) }}</div>
                <div class="size-14">{{ dateFormat(dataReqDetail.created_at) }}</div>
                <div class="size-14 text-blue">{{ dataReqDetail.transaction_code }}</div>
              </div>
            </div>
          </div>
          <div>
            <a href="javascript:void(0)" @click="isReqDetail = false" class="size-20 text-primary">
              <img src="../../assets/cross-gray-icon.png" />
            </a>
          </div>
        </div>
        <hr />
        <div class="">
          <div class="mt-4" v-if="is_list_detail">
            <ListLoader />
          </div>
          <div class="scroll mb-4" v-else>
            <div class="modern-table-boxed">
              <div class="d-table-row header">
                <div class="d-table-cell">No.</div>
                <div class="d-table-cell">Nomor Resi</div>
                <div class="d-table-cell">Pembayaran</div>
                <div class="d-table-cell">Komisi</div>
              </div>
              <div class="d-table-row" v-for="(item, index) in listReqDetail" :key="index">
                <div class="d-table-cell">{{ itemNumber(index) }}</div>
                <div class="d-table-cell">
                  {{ item.receipt }}
                </div>
                <div class="d-table-cell">
                  {{ currency(item.total_payment) }}
                </div>
                <div class="d-table-cell">{{ currency(item.total_accepted) }}</div>
              </div>
            </div>
            <div v-if="listReqDetail.length == 0" class="text-center mt-section">
              <img src="../../assets/no-data.png" width="100" alt="" />
              <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
            </div>
          </div>
          <div class="d-flex">
            <button class="req-button size-16 fw-bold" @click="exportData(dataReqDetail.hash)">
              <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
              Export
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-vue">
      <div class="overlay" v-if="showModal" @click="showModal = false"></div>
      <div class="modal-body-chat sm" v-if="showModal">
        <div class="d-flex align-items-lg-center justify-content-center">
          <img class="me-3" src="../../assets/wallet-icon.png" />
          <div class="size-18 fw-ekstra-bold">Request Pencairan</div>
        </div>
        <hr />
        <div class="p-3">
          <div class="row mb-2">
            <div class="col-md-4 fw-bolder">Jumlah Saldo</div>
            <div class="col-md-1">:</div>
            <div class="col-md-4 text-gray">{{ currency(dataReqDisburs?.balance) }}</div>
          </div>
          <div class="row mb-2">
            <div class="col-md-4 fw-bolder">Bank</div>
            <div class="col-md-1">:</div>
            <div class="col-md-4 text-gray">{{ dataReqDisburs?.bank_name }}</div>
          </div>
          <div class="row mb-2">
            <div class="col-md-4 fw-bolder">Nomor Rekening</div>
            <div class="col-md-1">:</div>
            <div class="col-md-4 text-gray">{{ dataReqDisburs?.account_number }}</div>
          </div>
          <div class="row mb-5">
            <div class="col-md-4 fw-bolder">A/N</div>
            <div class="col-md-1">:</div>
            <div class="col-md-4 text-gray">{{ dataReqDisburs?.account_name }}</div>
          </div>

          <ol class="ps-3 fw-bold">
            <li class="mb-2">Permintaan pencairan dapat direquest seminggu sekali.</li>
            <li class="mb-2">Saldo dapat cair maksimal H+3 setelah direquest.</li>
            <li class="mb-2">Wajib mencairkan semua saldo yang ada.</li>
            <li class="mb-2">Saldo yang dicairkan apalabila menggunakan bank selain bank BCA, akan dikenakan potongan admin.</li>
          </ol>

          <div class="form-check mb-4">
            <input class="form-check-input" type="checkbox" v-model="isChecked" value="true" unchecked-value="false" id="flexCheckDefault" />
            <label class="form-check-label fw-bold" for="flexCheckDefault"> Menyetujui <span class="text-green">Syarat dan Ketentuan</span> </label>
          </div>

          <div class="d-flex">
            <button class="req-button size-16 fw-bold" :disabled="!isChecked || isSaving" @click="reqWithdrawal">
              <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
              Request Pencairan
            </button>
          </div>
        </div>
      </div>
    </div>
    <PopupNotif :isModal="isModal" :title="title" :info="info" @send="receive"></PopupNotif>
  </div>

  <div v-else-if="isDetail" class="padding-container">
    <div class="d-flex align-items-center">
      <div class="me-3">
        <a href="javascript:void(0)" @click="getBack" class="text-primary">
          <i class="fa fa-angle-left size-24 fw-bold"></i>
        </a>
      </div>
      <h3 class="fw-bold mb-0">Pendapatan Mitra</h3>
    </div>
    <div class="box mt-4">
      <div class="d-flex bd-highlight">
        <div class="flex-grow-1 bd-highlight">
          <h4 class="fw-bold">Pendapatan Mitra</h4>
        </div>
        <div class="row">
          <div class="col-md-6">
            <select name="" class="form-control form-select" v-model="receivedType" style="width: 150px">
              <option value="" selected hidden>Tipe Penerimaan</option>
              <option value="">Dooring</option>
              <option value="">Transit</option>
              <option value="">Pickup</option>
              <option value="">Walk In</option>
            </select>
          </div>
          <div class="col-md-6">
            <div class="position-relative">
              <input v-model="searchReceipe" value="code" @keyup.enter="searchManifest" type="text" class="form-control" placeholder="Search" />
              <span class="search-icon">
                <i class="fa fa-search"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4" v-if="is_my_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">No Resi</div>
            <div class="d-table-cell">Biaya Pick-up</div>
            <div class="d-table-cell">Biaya Pengiriman</div>
            <div class="d-table-cell">Biaya Asuransi</div>
            <div class="d-table-cell">Biaya Packing</div>
            <div class="d-table-cell">Biaya Dooring</div>
            <div class="d-table-cell">Biaya Transit</div>
            <div class="d-table-cell">Diskon</div>
            <div class="d-table-cell">Total Penerimaan</div>
            <div class="d-table-cell">Jenis Penerimaan</div>
          </div>
          <div class="d-table-row" v-for="(item, index) in dataDetail" :key="index">
            <div class="d-table-cell">{{ itemNumber(index) }}</div>
            <div class="d-table-cell">
              {{ item.package_code }}
            </div>
            <div class="d-table-cell">
              {{ currency(item.pickup_fee) }}
            </div>
            <div class="d-table-cell">{{ currency(item.service_fee) }}</div>
            <div class="d-table-cell">{{ currency(item.insurance_fee) }}</div>
            <div class="d-table-cell">{{ currency(item.handling_fee) }}</div>
            <div class="d-table-cell">{{ currency(item.dooring_fee) }}</div>
            <div class="d-table-cell">{{ currency(item.transit_fee) }}</div>
            <div class="d-table-cell">{{ currency(item.discount_fee) }}</div>
            <div class="d-table-cell">{{ currency(item.total_amount) }}</div>
            <div class="d-table-cell">
              <small class="tag green"> {{ capitalizeFirstLetter(item.type) }} </small>
            </div>
          </div>
        </div>
        <div v-if="dataDetail.length == 0" class="text-center mt-section">
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
    </div>
    <div class="overflow-auto mt-3">
      <b-pagination-nav v-if="dataDetail.length > 0" v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number align="right" @input="changePageDetail"></b-pagination-nav>
    </div>
  </div>
</template>
<script>
import PopupNotif from "../dashboardOwner/PopupNotif.vue";
import VueApexCharts from "vue-apexcharts";
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";

export default {
  props: {
    items: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    apexcharts: VueApexCharts,
    ListLoader,
    PopupNotif,
  },
  name: "Dashboard-MB",
  data() {
    return {
      moment: moment,
      filter_status: "",
      is_my_list_ready: false,
      is_list_detail: false,
      isReqDetail: false,
      isModal: "",
      title: "",
      info: "",
      isSaving: false,
      isChecked: false,
      showModal: false,
      keySearch: "",
      searchReceipe: "",
      receivedType: "",
      datePicker: "",
      monthFilter: moment(new Date()).format("YYYY-MM"),
      data: "",
      dataReqDetail: "",
      dataReqDisburs: "",
      listReqDetail: "",
      listData: [],
      dataSummary: "",
      date_income: [],
      val_income: [],
      isDetail: false,
      currentPage: 0,
      totalPage: 0,
      limit: 10,
      dataDetail: [
        {
          package_code: "TRB000000009",
          pickup_fee: "Rp 62.000",
          service_fee: "Rp 550.000",
          insurance_fee: "Rp 950.000",
          handling_fee: "Rp 850.000",
          dooring_fee: "Rp 550.000",
          transit_fee: "Rp 650.000",
          discount_fee: "Rp 550.000",
          total_amount: "Rp 550.000",
          type: "Dooring",
        },
        {
          package_code: "TRB000000009",
          pickup_fee: "Rp 62.000",
          service_fee: "Rp 550.000",
          insurance_fee: "Rp 950.000",
          handling_fee: "Rp 850.000",
          dooring_fee: "Rp 550.000",
          transit_fee: "Rp 650.000",
          discount_fee: "Rp 550.000",
          total_amount: "Rp 550.000",
          type: "Dooring",
        },
      ],
      dataItem: [
        {
          date: "12 Aug 2022",
          receipt: "TRB000000009",
          total: "Rp 600.000.000",
          weight: "Rp 500.000.000",
          status: "Approved",
        },
        {
          date: "12 Aug 2022",
          receipt: "TRB000000009",
          total: "Rp 600.000.000",
          weight: "Rp 500.000.000",
          status: "Approved",
        },
        {
          date: "12 Aug 2022",
          receipt: "TRB000000009",
          total: "Rp 600.000.000",
          weight: "Rp 500.000.000",
          status: "Approved",
        },
        {
          date: "12 Aug 2022",
          receipt: "TRB000000009",
          total: "Rp 600.000.000",
          weight: "Rp 500.000.000",
          status: "Approved",
        },
      ],
      chartOptions: {
        xaxis: {
          categories: [new Date()],
          labels: {
            formatter: function (val) {
              return "Tgl " + moment(val, "YYYY-MM-DD").format("DD");
            },
          },
        },
        colors: ["#48A2D4"],
        dataLabels: {
          enabled: false,
        },
      },
      chartSeries: [
        {
          name: "Pendapatan",
          data: [0],
        },
      ],
    };
  },
  created() {
    this.getData();
    this.getSummary();
    this.getReqDisburs();
  },
  methods: {
    searchData() {
      this.currentPage = 0;
      this.getData();
    },
    reqDetail(item) {
      this.getReqDetail(item.hash);
      this.dataReqDetail = item;
      this.isReqDetail = true;
    },
    getBack() {
      this.isDetail = false;
      this.getData();
    },
    searchManifest() {
      this.currentPage = 0;
      this.getDetail();
    },
    changePage() {
      this.getData();
    },
    changePageDetail() {
      this.getDetail();
    },
    capitalizeFirstLetter(str) {
      return str
        .split(" ")
        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
    },
    getReqDetail(hash) {
      this.is_list_detail = true;
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/owner/withdrawal/detail/${hash}`, {
        params: { type: "web" },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.listReqDetail = res.data.data;
          this.is_list_detail = false;
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.is_list_detail = false;
        });
    },
    getReqDisburs() {
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/dashboard/owner/profile/bank-account`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.dataReqDisburs = res.data.data;
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
        });
    },
    getData() {
      this.is_my_list_ready = true;
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/dashboard/owner/income`, {
        params: { page: this.currentPage == 0 || this.currentPage == null ? 1 : this.currentPage, per_page: this.limit, date: this.datePicker ? this.datePicker : "''", search: this.keySearch ? this.keySearch : "''" },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data = res.data.data;
          this.listData.push(this.data[0]);
          this.totalPage = res.data.last_page;
          this.is_my_list_ready = false;
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.is_my_list_ready = false;
        });
    },
    getSummary() {
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/dashboard/owner/summary/income`, {
        params: { date: this.monthFilter },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.dataSummary = res.data.data;
          this.dataSummary.income_per_day.forEach((el) => {
            if (el.date == null || el.amount == null) {
              return;
            }
            this.date_income.push(el.date);
            this.val_income.push(el.amount);
          });

          let minNumber = Math.min(...this.val_income);
          minNumber = parseInt(minNumber - minNumber * 0.1);
          minNumber = 0;

          if (this.dataSummary.income_per_day.length > 0) {
            this.chartOptions.xaxis.categories = this.date_income;
            this.chartSeries[0].data = this.val_income;
            this.$refs.chartmonthly.updateSeries([
              {
                name: "Pendapatan",
                data: this.val_income,
              },
            ]);

            let that = this;

            this.$refs.chartmonthly.updateOptions({
              xaxis: {
                categories: this.date_income,
              },
              yaxis: {
                min: minNumber,
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return that.currency(val);
                  },
                },
              },
            });
          }
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
        });
    },
    exportData(hash) {
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/owner/withdrawal/export/${hash}`, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data_excel_request = res.data;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xls");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
        });
    },
    getDetail() {
      this.is_my_list_ready = true;
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/dashboard/owner/income/detail`, {
        params: {
          page: this.currentPage == 0 || this.currentPage == null ? 1 : this.currentPage,
          received_type: this.receivedType ? this.receivedType : "''",
          search: this.searchReceipe ? this.searchReceipe : "''",
          per_page: this.limit,
          date: this.monthFilter,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.dataDetail = res.data.data;
          this.totalPage = res.data.last_page;
          this.is_my_list_ready = false;
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.is_my_list_ready = false;
        });
    },
    reqWithdrawal() {
      this.isSaving = true;
      Api.post(`${process.env.VUE_APP_BASE_URL}/partner/owner/withdrawal/store`, null, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.showModal = false;
          this.isSaving = false;
          // this.$notify({
          //   group: "foo",
          //   type: "success",
          //   title: "Success",
          //   text: "Request Success",
          // });
          this.title = "Request Pencairan Berhasil";
          this.info = "Selamat! Anda telah berhasil merequest pencairan dana.";
          this.isModal = "success";
          this.getData();
          this.getReqDisburs();
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }
          this.showModal = false;
          this.title = "Request Pencairan Gagal";
          this.info = "Pencairan data gagal! Silakan ulangi kembali";
          this.isModal = "fail";
          // this.$notify({
          //   group: "foo",
          //   type: "error",
          //   title: "Error",
          //   text: `${msg}`,
          // });
          console.log(msg);
          this.isSaving = false;
        });
    },
    changeToDetail() {
      this.isDetail = true;
      this.currentPage = 0;
      this.totalPage = 0;
      this.limit = 10;
      this.getDetail();
    },
    currency(number) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(number);
    },
    dateFormat(date) {
      return moment(date).format("DD MMM YYYY");
    },
    monthFormat(date) {
      return moment(date).format("MMMM");
    },
    itemNumber(index) {
      return index + this.startIndex + 1;
    },
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.limit;
    },
  },
};
</script>
<style scoped>
.scroll {
  height: 300px;
  overflow-y: scroll;
}
.select-status {
  border: none;
  border-radius: 8px;
  background: rgba(72, 162, 212, 0.1);
  color: #48a2d4;
}
.label-req {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 29px;
  background: #519c66;
  border: none;
  border-radius: 6px;
  color: white;
}
.btn-detail {
  background: #48a2d4;
  border: none;
  border-radius: 8px;
  color: white;
  width: 123px;
  height: 37px;
}
.req-button {
  background: #48a2d4;
  border: 1px solid #48a2d4;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #fff;
  width: max-content;
  height: 44px;
  width: 100%;
}
.req-button:disabled {
  background: #e8e8e9;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #fff;
  width: max-content;
  height: 44px;
  width: 100%;
}
input[type="month"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  position: absolute;
  opacity: 1;
  display: block;
  background: none no-repeat;
  border-width: thin;
  width: 100%;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #28a745;
  border-color: #28a745;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  color: #fff;
  background-color: #28a745;
}
.tag_stat {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: 88px;
  height: 23px;
  border-radius: 6px;
  font-weight: 600;
}
</style>
