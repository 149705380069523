<template>
    <div class="padding-container">
        <div class="box">
            <div class="d-flex bd-highlight">
                <div class="flex-grow-1 bd-highlight">
                    <h4 class="fw-bold">Cek Manifest</h4>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <div class="position-relative">
                            <input
                                v-model="code"
                                @keyup.enter="searchManifest"
                                type="text"
                                class="form-control"
                                placeholder="Kode Manifest"
                            />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <select
                            style="width: 170px"
                            v-model="statusManifest"
                            name="status_manifest"
                            class="form-control form-select"
                            @change="getStatus"
                        >
                            <option value="" disabled selected hidden>
                                Filter Status Manifest
                            </option>
                            <option value="en-route">En Route</option>
                            <option value="accepted">Accepted</option>
                            <option value="finished">Finished</option>
                            <option value="waiting_assign_partner">
                                Waiting Assign Partner
                            </option>
                            <option value="waiting_assign_package">
                                Waiting Assign Package
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="scroll-x">
                <div class="modern-table-boxed mt-4 scr scroll-x">
                    <div class="d-table-row header">
                        <div class="d-table-cell">No</div>
                        <div class="d-table-cell">Tanggal Manifest</div>
                        <div class="d-table-cell">No Manifest</div>
                        <div class="d-table-cell">Type</div>
                        <div class="d-table-cell">Status</div>
                        <div class="d-table-cell">Partner Asal</div>
                        <div class="d-table-cell">Partner Tujuan</div>
                        <div class="d-table-cell">Armada</div>
                        <div class="d-table-cell">Sla Leadtime</div>
                        <div class="d-table-cell">Keterlambatan</div>
                        <div class="d-table-cell">Selesai</div>
                    </div>
                    <div
                        class="d-table-row"
                        v-for="(item, index) in data"
                        :key="index"
                    >
                        <div class="d-table-cell">{{ item.no }}.</div>
                        <div class="d-table-cell">
                            {{ dateFormat(item.create_timestamp) }}
                        </div>
                        <div class="d-table-cell">
                            <router-link
                                class="text-link"
                                :to="{
                                    name: 'detail-manifest',
                                    params: { code: item.receipt_code },
                                }"
                                >{{ item.receipt_code }}</router-link
                            >
                        </div>
                        <div class="d-table-cell">{{ item.manifest_type }}</div>
                        <div class="d-table-cell">{{ item.status }}</div>
                        <div class="d-table-cell">
                            {{ item.partner_origin }}
                        </div>
                        <div class="d-table-cell">
                            {{ item.partner_destination }}
                        </div>
                        <div class="d-table-cell">
                            {{ item.transporter_type }}
                        </div>
                        <div class="d-table-cell">
                            <Timer
                                :class="[
                                    'font-countdown',
                                    item.level == 1
                                        ? 'green'
                                        : item.level == 2
                                        ? 'yellow'
                                        : item.level == 3
                                        ? 'red'
                                        : '',
                                ]"
                                :date="item.sla_deadline"
                            ></Timer>
                        </div>
                        <div class="d-table-cell">
                            <Timer
                                class="font-countdown red"
                                :date="item.sla_deadline"
                                :isLate="item.is_sla_late"
                                :done="item.sla_late"
                                :level="item.level"
                            ></Timer>
                        </div>
                        <div class="d-table-cell">
                            {{
                                item.sla_late ? dateFormat(item.sla_late) : "-"
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex" v-if="data.length > 0">
            <div class="ms-auto">
                <div class="d-flex mt-4">
                    <div class="me-3">
                        <select
                            class="form-control form-select w-select"
                            v-model="limit"
                            @change="getManifest()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <b-pagination-nav
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="changePage"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import moment from "moment";
import Timer from "./timer.vue";

export default {
    components: {
        Timer,
    },
    name: "Manifest",
    data() {
        return {
            showModal: false,
            data: [],
            currentPage: 0,
            totalPage: 0,
            limit: 10,
            code: "",
            countDown: "",
            statusManifest: "",
        };
    },
    created() {
        this.getManifest();
        this.getCountDown();
    },
    computed: {},
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    methods: {
        searchManifest() {
            this.currentPage = 0;
            this.getManifest();
        },
        changePage() {
            this.getManifest();
        },
        getStatus() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/fno/tracking/check/manifest`,
                {
                    params: {
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 1,
                        limit: this.limit,
                        manifest_code: this.code,
                        status: this.statusManifest,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
        getManifest() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/fno/tracking/check/manifest`,
                {
                    params: {
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 1,
                        limit: this.limit,
                        manifest_code: this.code,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.log(err);
                });
        },
        dateFormat(date) {
            return moment(date).format("DD MMM YYYY");
        },
        getCountDown(date) {
            var countDownDate = new Date(date).getTime();
            const setCountDown = setInterval(() => {
                var now = new Date().getTime();
                var distance = countDownDate - now;

                // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor(
                    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                );
                var minutes = Math.floor(
                    (distance % (1000 * 60 * 60)) / (1000 * 60)
                );
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                var time = `${hours} : ${minutes} : ${seconds}`;
                this.countDown = time;
                if (distance < 0) {
                    clearInterval(setCountDown);
                    this.countDown = "00 : 00 : 00";
                }
            }, 1000);
            return setCountDown;
        },
    },
};
</script>

<style scoped>
.scroll {
    height: 500px;
    overflow-y: scroll;
}
.row.foto {
    --bs-gutter-x: 0rem !important;
}
.font-countdown {
    font-weight: 600;
}
.green {
    color: #3d8824;
}
.yellow {
    color: #fb9727;
}
.red {
    color: #e60013;
}
</style>
