<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4 m-mt-0">
            <h3 class="fw-bold mb-3">Tambah Jadwal Kapal</h3>
            <div class="row">
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Nama Kapal</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama kapal"
                        v-model="ship_name"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Kota Asal</label>
                    <multiselect
                        v-model="origin"
                        :options="origins"
                        label="regency_name"
                        track-by="regency_id"
                        placeholder="Pilih kota keberangkatan"
                        class="multi-form-custom"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Kota Tujuan</label>
                    <multiselect
                        v-model="destination"
                        :options="destinations"
                        label="regency_name"
                        track-by="regency_id"
                        placeholder="Masukkan kota tujuan"
                        class="multi-form-custom"
                    >
                    </multiselect>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Tanggal Keberangkatan</label>
                </div>
                <div
                    class="col-md-6"
                    v-for="(date, index) in dates"
                    :key="index"
                >
                    <date-picker
                        v-model="date.date"
                        type="date"
                        value-type="format"
                        class="w-100 mb-3"
                        format="DD MMMM YYYY"
                        :clearable="false"
                        placeholder="Pilih tanggal keberangkatan"
                    >
                    </date-picker>
                </div>
                <div class="col-md-6">
                    <button
                        class="btn btn-dashed-green w-100"
                        @click="addDate()"
                    >
                        <i class="fa fa-plus me-1"></i>
                        Tambah Tanggal Keberangkatan
                    </button>
                </div>
            </div>
            <div class="d-flex">
                <div class="mt-3 ms-auto">
                    <a
                        href="/harbors"
                        class="btn btn-outline-red btn-lg px-5 me-3"
                    >
                        Batal
                    </a>
                    <button
                        class="btn btn-green btn-lg px-5"
                        :disabled="
                            is_save ||
                            ship_name == '' ||
                            origin == null ||
                            destination == null ||
                            dates[0].date == ''
                        "
                        @click="saveData()"
                    >
                        <span
                            v-if="is_save"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        >
                        </span>
                        Simpan
                    </button>
                </div>
            </div>
        </div>
        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Jadwal Kapal Berhasil Ditambahkan
                    </h4>
                </div>
                <div class="mt-4">
                    <a href="/harbors" class="btn btn-green w-100 btn-lg">
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../api/Api";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default {
    components: {
        Multiselect,
        DatePicker,
    },
    name: "HarborsCreate",
    data() {
        return {
            moment: moment,
            ship_name: "",
            origin: null,
            origins: [],
            destination: null,
            destinations: [],
            date: "",
            dates: [{ date: "" }],
            is_save: false,
            modalSuccess: false,
        };
    },
    created() {
        this.getOrigin();
        this.getDestination();
    },
    computed: {},
    methods: {
        getOrigin() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/trawlpack/harbors/regency/origin`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.origins = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getDestination() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/trawlpack/harbors/regency/destination`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.destinations = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        addDate() {
            this.dates.push({
                date: "",
            });
        },
        saveData() {
            this.is_save = true;
            const selectedDates = this.dates.map((item) =>
                moment(item.date).format("YYYY-MM-DD")
            );
            var data = {
                origin_regency: this.origin?.regency_id,
                destination_regency: this.destination?.regency_id,
                ship_name: this.ship_name,
                departed_at: selectedDates,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/trawlpack/harbors/create`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_save = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.is_save = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
    },
};
</script>
<style scoped></style>
