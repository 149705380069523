<template>
    <div class="padding-container">
        <div class="d-flex align-items-center no-flex">
            <h3 class="mb-0 fw-bold">Dashboard TroCarrier Finance</h3>
            <div class="ms-auto m-mt-1">
                <date-picker
                    v-model="income_date"
                    type="month"
                    value-type="format"
                    format="MMMM YYYY"
                    class="background-date-finance"
                    :clearable="false"
                    placeholder="Masukkan tanggal"
                    style="width: 160px"
                    @change="getDatas()"
                >
                </date-picker>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 m-mt-2">
                <div class="box mt-4 m-mt-0 no-shadow border-gray">
                    <div class="d-flex align-items-center mb-4">
                        <img
                            src="../../assets/dashboard/pendapatan.png"
                            width="40"
                            alt=""
                        />
                        <div class="ms-3 size-22 fw-bold">Pendapatan</div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-md-4 mt-4">
                            <div class="d-flex align-items-center">
                                <div>
                                    <img
                                        src="../../assets/dashboard/pendapatan-sekarang.png"
                                        width="40"
                                        alt=""
                                    />
                                </div>
                                <div class="ms-3">
                                    <div class="text-gray size-14">
                                        Pendapatan Sekarang
                                    </div>
                                    <div class="text-green size-20 fw-bold">
                                        {{
                                            currencyFormat(
                                                dashboard_data.income.amount
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4">
                            <div class="d-flex align-items-center">
                                <div>
                                    <img
                                        src="../../assets/dashboard/pendapatan-sebelumnya.png"
                                        width="40"
                                        alt=""
                                    />
                                </div>
                                <div class="ms-3">
                                    <div class="text-gray size-14">
                                        Pendapatan Sebelumnya
                                    </div>
                                    <div class="text-green size-20 fw-bold">
                                        {{
                                            currencyFormat(
                                                dashboard_data.income
                                                    .prev_amount
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4">
                            <div class="d-flex align-items-center">
                                <div>
                                    <img
                                        src="../../assets/dashboard/peningkatan-pendapatan.png"
                                        width="40"
                                        alt=""
                                    />
                                </div>
                                <div class="ms-3">
                                    <div class="text-gray size-14">
                                        Peningkatan Pendapatan
                                    </div>
                                    <div
                                        class="size-20 fw-bold"
                                        v-bind:class="
                                            dashboard_data.income.amount <
                                            dashboard_data.income.prev_amount
                                                ? 'text-danger'
                                                : 'text-green'
                                        "
                                    >
                                        {{
                                            currencyFormat(
                                                dashboard_data.income.amount -
                                                    dashboard_data.income
                                                        .prev_amount
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4">
                            <div class="d-flex align-items-center">
                                <div>
                                    <img
                                        src="../../assets/dashboard/total-penghasilan.png"
                                        width="40"
                                        alt=""
                                    />
                                </div>
                                <div class="ms-3">
                                    <div class="text-gray size-14">
                                        Total Resi Berhasil
                                    </div>
                                    <div class="text-green size-20 fw-bold">
                                        {{
                                            decimalFormat(
                                                dashboard_data.income
                                                    .total_success
                                            )
                                        }}
                                        Order
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4">
                            <div class="d-flex align-items-center">
                                <div>
                                    <img
                                        src="../../assets/dashboard/total-resi-cancel.png"
                                        width="40"
                                        alt=""
                                    />
                                </div>
                                <div class="ms-3">
                                    <div class="text-gray size-14">
                                        Total Resi Cancel
                                    </div>
                                    <div class="text-green size-20 fw-bold">
                                        {{
                                            decimalFormat(
                                                dashboard_data.income
                                                    .total_cancel
                                            )
                                        }}
                                        Order
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 m-mt-2">
                <div class="box mt-4 m-mt-0 no-shadow border-gray">
                    <div class="d-flex align-items-center mb-4">
                        <img
                            src="../../assets/dashboard/pencairan.png"
                            width="40"
                            alt=""
                        />
                        <div class="ms-3 size-22 fw-bold">Pencairan</div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-md-4 mt-4">
                            <div class="d-flex align-items-center">
                                <div>
                                    <img
                                        src="../../assets/dashboard/total-pengajuan.png"
                                        width="40"
                                        alt=""
                                    />
                                </div>
                                <div class="ms-3">
                                    <div class="text-gray size-14">
                                        Total Pengajuan
                                    </div>
                                    <div class="text-green size-20 fw-bold">
                                        {{
                                            currencyFormat(
                                                dashboard_data.disbursement
                                                    .request_amount
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4">
                            <div class="d-flex align-items-center">
                                <div>
                                    <img
                                        src="../../assets/dashboard/pendapatan-sebelumnya.png"
                                        width="40"
                                        alt=""
                                    />
                                </div>
                                <div class="ms-3">
                                    <div class="text-gray size-14">
                                        Jumlah Pengajuan
                                    </div>
                                    <div class="text-green size-20 fw-bold">
                                        {{
                                            decimalFormat(
                                                dashboard_data.disbursement
                                                    .request_total
                                            )
                                        }}
                                        Pengajuan
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4">
                            <div class="d-flex align-items-center">
                                <div>
                                    <img
                                        src="../../assets/dashboard/total-pencairan.png"
                                        width="40"
                                        alt=""
                                    />
                                </div>
                                <div class="ms-3">
                                    <div class="text-gray size-14">
                                        Total Pencairan
                                    </div>
                                    <div class="text-green size-20 fw-bold">
                                        {{
                                            currencyFormat(
                                                dashboard_data.disbursement
                                                    .disbursement_amount
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4">
                            <div class="d-flex align-items-center">
                                <div>
                                    <img
                                        src="../../assets/dashboard/jumlah-pencairan.png"
                                        width="40"
                                        alt=""
                                    />
                                </div>
                                <div class="ms-3">
                                    <div class="text-gray size-14">
                                        Jumlah Pencairan
                                    </div>
                                    <div class="text-green size-20 fw-bold">
                                        {{
                                            decimalFormat(
                                                dashboard_data.disbursement
                                                    .disbursement_total
                                            )
                                        }}
                                        Pencairan
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-7 m-mt-2">
                <div class="box mt-4 m-mt-0 no-shadow border-gray pb-0">
                    <div class="d-flex align-items-center no-flex">
                        <div class="size-22 fw-bold">
                            Grafik Pendapatan Mitra
                        </div>
                        <div class="ms-auto m-mt-1">
                            <div
                                class="d-flex align-items-center date-custom-form"
                            >
                                <img
                                    src="../../assets/dashboard/calendar.png"
                                    width="18"
                                    alt=""
                                />
                                <div class="ms-2">
                                    {{ moment(income_date).format("YYYY") }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <apexcharts
                            class="carrier"
                            ref="chartmonthly"
                            width="100%"
                            height="350"
                            type="bar"
                            :options="chartOptions"
                            :series="series"
                        ></apexcharts>
                    </div>
                </div>
            </div>
            <div class="col-md-5 m-mt-2">
                <div class="box mt-4 m-mt-0 no-shadow border-gray">
                    <div class="d-flex align-items-center">
                        <div>
                            <div class="mb-0 fw-bold size-16 capitalize">
                                List Order
                            </div>
                        </div>
                        <div class="ms-auto">
                            <a
                                href="/trawlcarrier/ho/order"
                                class="text-green size-16"
                            >
                                Lihat Semua
                            </a>
                        </div>
                    </div>
                    <div
                        :class="{
                            '': true,
                            'scroll-dashboard-trawlcarrier-finance':
                                dashboard_data.orders.length >= 5,
                        }"
                    >
                        <hr />
                        <template v-if="dashboard_data.orders.length > 0">
                            <template
                                v-for="(data, index) in dashboard_data.orders"
                            >
                                <div :key="index">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <div class="fw-bold size-16">
                                                {{ data.name }}
                                            </div>
                                            <div class="text-gray">
                                                {{ data.receipt_code }}
                                            </div>
                                        </div>
                                        <div class="ms-auto">
                                            <div
                                                class="fw-bold size-16 text-green"
                                            >
                                                {{ currencyFormat(data.price) }}
                                            </div>
                                            <div class="text-gray">
                                                {{
                                                    moment(
                                                        data.created_at
                                                    ).format("DD MMMM YYYY")
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <div class="text-center mt-4">
                                <img
                                    src="../../assets/no-data.png"
                                    width="70"
                                    alt=""
                                />
                                <div class="fw-bold size-16 text-gray mt-3">
                                    Tidak ada data
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import Api from "../../api/Api";
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        DatePicker,
        apexcharts: VueApexCharts,
    },
    name: "Dashboard-Trawlcarrier-Finance",
    data() {
        return {
            moment: moment,
            income_date: moment(new Date()).format("MMMM YYYY"),
            mitra_date: moment(new Date()).format("YYYY"),
            dashboard_data: {
                disbursement: {},
                income: {},
                orders: [],
                partner_income: {},
            },
            chartOptions: {
                xaxis: {
                    categories: [],
                    labels: {
                        formatter: function (val) {
                            return moment(val, "YYYY-MM-DD").format("MMM");
                        },
                    },
                },
                colors: ["#BED7B6"],
                dataLabels: {
                    enabled: false,
                },
            },
            series: [
                {
                    name: "Pendapatan",
                    data: [],
                },
            ],
        };
    },
    created() {
        this.getDatas();
    },
    mounted() {},
    methods: {
        getDatas() {
            Api.get(
                `${
                    process.env.VUE_APP_SERVICE_URL
                }/carrier/ho/dashboard/finance?date=${moment(
                    this.income_date
                ).format("MM-YYYY")}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.dashboard_data = data;

                    let minNumber = Math.min(...data.partner_income.val);
                    minNumber = parseInt(minNumber - minNumber * 0.1);
                    minNumber = 0;

                    this.$refs.chartmonthly.updateSeries([
                        {
                            name: "Pendapatan",
                            data: data.partner_income.val,
                        },
                    ]);

                    let that = this;

                    this.$refs.chartmonthly.updateOptions({
                        xaxis: {
                            categories: data.partner_income.key,
                        },
                        yaxis: {
                            min: minNumber,
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return that.currencyFormat(val);
                                },
                            },
                        },
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        decimalFormat(num) {
            return (
                "" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
    },
};
</script>

<style>
.scroll-dashboard-trawlcarrier-finance {
    height: 350px;
    overflow-y: scroll;
}
.carrier .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
    clip-path: inset(0% 0% -11% 0% round 16px);
}
</style>
