<template>
  <div class="padding-container">
    <div class="d-flex align-items-center">
      <h3 class="mb-4 fw-bold">Daftar Manifest</h3>
    </div>
    <div class="box">
      <div class="d-flex align-items-center">
        <h3 class="mb-0 fw-bold">Daftar Manifest</h3>
        <div class="ms-auto">
          <select class="form-control form-select" v-model="filterStatus" @change="searchData" name="" id="" style="width: 175px">
            <option value="" selected>Filter Status Manifest</option>
            <option value="en-route">En Route</option>
            <option value="finished">Finished</option>
          </select>
        </div>
        <div class="ms-2">
          <select class="form-control form-select" v-model="filterType" @change="searchData" name="" id="" style="width: 175px">
            <option value="" selected>Filter Tipe Manifest</option>
            <option value="pickup">Pickup</option>
            <option value="transit">Transit</option>
            <option value="dooring">Dooring</option>
          </select>
        </div>
        <div class="ms-2">
          <div class="position-relative">
            <input v-model="keySearch" @keyup.enter="searchData" type="text" class="form-control pe-5" style="width: 155px" placeholder="Search" />
            <span class="search-icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="mt-4" v-if="is_my_list_ready">
        <ListLoader />
      </div>
      <div v-else>
        <div class="modern-table-boxed mt-4">
          <div class="d-table-row header">
            <div class="d-table-cell">No</div>
            <div class="d-table-cell">Tanggal Manifest</div>
            <div class="d-table-cell">No.Manifest</div>
            <div class="d-table-cell">Tipe Manifest</div>
            <div class="d-table-cell">Status Manifest</div>
            <div class="d-table-cell">Partner Tujuan</div>
            <div class="d-table-cell">Armada</div>
            <div class="d-table-cell">SLA</div>
            <div class="d-table-cell">Keterlambatan</div>
          </div>
          <div class="d-table-row" v-for="(item, index) in data" :key="index">
            <div class="d-table-cell">{{ numbering(index) }}</div>
            <div class="d-table-cell">
              {{ dateFormat(item.created_at) }}
            </div>
            <div class="d-table-cell">
              {{ item.code?.content }}
            </div>
            <div class="d-table-cell">{{ item.type }}</div>
            <div class="d-table-cell">{{ item.status }}</div>
            <div class="d-table-cell">{{ item.partner?.code ? item.partner?.code : "-" }}</div>
            <div class="d-table-cell">{{ item.transporter?.type ? titleCase(item.transporter?.type) : "-" }}</div>
            <div class="d-table-cell">
              <Timer :class="['font-countdown', item.sla?.level == 1 ? 'green' : item.sla?.level == 2 ? 'yellow' : item.sla?.level == 3 ? 'red' : '']" :date="item.sla?.deadline_at"></Timer>
            </div>
            <div class="d-table-cell">
              <Timer class="font-countdown red" :date="item.sla?.deadline_at" :isLate="item.sla?.is_late" :done="item.sla?.late_at" :level="item.sla?.level"></Timer>
            </div>
          </div>
        </div>

        <div v-if="data.length == 0" class="text-center mt-section">
          <img src="../../assets/no-data.png" width="100" alt="" />
          <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
        </div>
      </div>
    </div>
    <div class="overflow-auto mt-3">
      <b-pagination-nav v-if="data.length > 0" v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number align="right" @input="changePage"></b-pagination-nav>
    </div>
  </div>
</template>
<script>
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";
import Timer from "./timer.vue";

export default {
  props: {
    items: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ListLoader,
    Timer,
  },
  name: "Dashboard-MB",
  data() {
    return {
      moment: moment,
      is_my_list_ready: false,
      filterStatus: "",
      filterType: "",
      keySearch: null,
      data: "",
      currentPage: 0,
      totalPage: 0,
      limit: 10,
      dataItem: [
        {
          date: "12 Aug 2022",
          receipt: "MNF0000001",
          Tipe_Manifest: "PIckup",
          Status_Manifest: "En Route",
          Partner_Tujuan: "MPW-JKT-001",
          Armada: "Mobil Pickup",
          SLA: "00 : 20 : 00",
          Keterlambatan: "Tidak Ada Keterlambatan",
        },
        {
          date: "13 Aug 2022",
          receipt: "MNF0000003",
          Tipe_Manifest: "Dooring",
          Status_Manifest: "Finished",
          Partner_Tujuan: "MPW-JKT-003",
          Armada: "Mobil Pickup",
          SLA: "00 : 00 : 00",
          Keterlambatan: "00 : 10 : 45",
        },
        {
          date: "14 Aug 2022",
          receipt: "MNF0000004",
          Tipe_Manifest: "PIckup",
          Status_Manifest: "En Route",
          Partner_Tujuan: "MPW-JKT-004",
          Armada: "Mobil Pickup",
          SLA: "00 : 59 : 00",
          Keterlambatan: "Tidak Ada Keterlambatan",
        },
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    searchData() {
      this.currentPage = 0;
      this.getData();
    },
    numbering(index) {
      let number = this.itemNumber(index);
      return (number < 10 ? "0" : "") + number;
    },
    changePage() {
      this.getData();
    },
    getData() {
      this.is_my_list_ready = true;
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/dashboard/owner/manifest`, {
        params: {
          page: this.currentPage == 0 || this.currentPage == null ? 1 : this.currentPage,
          per_page: this.limit,
          search: this.keySearch,
          status: this.filterStatus ? this.filterStatus : null,
          type: this.filterType ? this.filterType : null,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.is_my_list_ready = false;
          this.data = res.data.data;
          this.totalPage = res.data.last_page;
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          this.is_my_list_ready = false;
          console.log(err);
        });
    },
    dateFormat(date) {
      return moment(date).format("DD MMM YYYY");
    },
    titleCase(str) {
      return str
        .split(" ")
        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
    },
    itemNumber(index) {
      return index + this.startIndex + 1;
    },
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.limit;
    },
  },
};
</script>
<style scoped>
.select-status {
  border: none;
  border-radius: 8px;
  background: rgba(72, 162, 212, 0.1);
  color: #48a2d4;
}
.label-req {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 29px;
  background: #519c66;
  border: none;
  border-radius: 6px;
  color: white;
}
.font-countdown {
  font-weight: 600;
}
.green {
  color: #3d8824;
}
.yellow {
  color: #fb9727;
}
.red {
  color: #e60013;
}
</style>
