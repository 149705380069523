<template>
  <div class="padding-container">
    <div class="box p-0 m-0">
      <div class="position-relative height">
        <img class="w-100 img-fluid banner" src="../../assets/tb-banner.png" />
        <img class="photo-profile rounded-circle" :src="AvatarPictureUrl" />
        <label for="upload-identity" class="edit-profile cursor-pointer">
          <img class="edit-icon" src="../../assets/edit-blue-icon.png" />
          <input class="cursor-pointer" id="upload-identity" type="file" name="file" ref="file" @change="uploadFileAvatar" />
        </label>
        <a v-if="isPassword == false" class="change-pass" href="javascript:void(0)" @click="isPassword = true">
          <img class="sz-img" src="../../assets/change-password.png" />
        </a>
      </div>
      <div class="px-4">
        <div v-if="!isPassword" class="row">
          <div class="col-md-6">
            <div>
              <div class="size-16 mt-3 mb-1">Nama</div>
              <input class="mb-2 form-input dis" v-model="req.name" placeholder="Masukkan Nama" disabled />
            </div>

            <div>
              <div class="size-16 mt-3 mb-1">Email</div>
              <input class="mb-2 form-input" v-model="req.email" placeholder="Masukkan Email" />
            </div>

            <div>
              <div class="size-16 mt-3 mb-1">No Telepon</div>
              <input class="mb-2 form-input" type="tel" v-model="req.phone" placeholder="Masukkan No Telepon" />
            </div>

            <div>
              <div class="size-16 mt-3 mb-1">Alamat</div>
              <input class="mb-2 form-input" v-model="req.address" placeholder="Masukkan Alamat" />
            </div>
          </div>
          <div class="col-md-6">
            <div>
              <div class="size-16 mt-3 mb-1">Kode Mitra</div>
              <input class="mb-2 form-input dis" v-model="req.code" placeholder="Masukkan Kode Mitra" disabled />
            </div>

            <div>
              <div class="size-16 mt-3 mb-1">Nama Bank</div>
              <select class="form-input mb-2" v-model="req.bank">
                <option value="" selected>Pilih Bank</option>
                <option v-for="(item, index) in bankData" :key="index" :value="item?.id">{{ codeBank(item.name) }}</option>
              </select>
            </div>

            <div>
              <div class="size-16 mt-3 mb-1">Nomor Rekening</div>
              <input class="mb-2 form-input" type="number" v-model="req.bank_account_number" placeholder="Masukkan Nomor Rekening" />
            </div>

            <div>
              <div class="size-16 mt-3 mb-1">A/N</div>
              <input class="mb-2 form-input" v-model="req.bank_account_name" placeholder="Masukkan A/N" />
            </div>

            <div class="d-flex gap-2 mt-5 mb-4">
              <button class="button button-cancel" @click="cancel" :disabled="isSaving">Batal</button>
              <button class="button button-save" @click="submitData" :disabled="isSaving">
                <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
                Simpan
              </button>
            </div>
          </div>
        </div>
        <div v-if="isPassword" class="row">
          <div>
            <div class="size-16 mt-3 mb-1">Masukkan Kata Sandi</div>
            <div class="mb-3 position-relative">
              <input :type="[showPassword1 == true ? 'text' : 'password']" class="form-input" v-model="req.old_password" placeholder="Password" />
              <span class="cursor-pointer eye-icon" @click="toggleShow(1)">
                <i class="fa" :class="{ 'fa-eye-slash': showPassword1, 'fa-eye': !showPassword1 }"></i>
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div>
              <div class="size-16 mt-3 mb-1">Kata Sandi Baru</div>
              <div class="mb-3 position-relative">
                <input :type="[showPassword2 == true ? 'text' : 'password']" class="form-input" v-model="req.new_password" @blur="validatePassword" placeholder="Password" />
                <span class="cursor-pointer eye-icon" @click="toggleShow(2)">
                  <i class="fa" :class="{ 'fa-eye-slash': showPassword2, 'fa-eye': !showPassword2 }"></i>
                </span>
                <p v-if="passwordError" style="color: red">{{ passwordError }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div>
              <div class="size-16 mt-3 mb-1">Konfirmasi Kata Sandi</div>
              <div class="mb-3 position-relative">
                <input :type="[showPassword3 == true ? 'text' : 'password']" class="form-input" v-model="req.new_password_confirmation" placeholder="Password" />
                <span class="cursor-pointer eye-icon" @click="toggleShow(3)">
                  <i class="fa" :class="{ 'fa-eye-slash': showPassword3, 'fa-eye': !showPassword3 }"></i>
                </span>
              </div>
            </div>
            <div class="d-flex gap-2 mt-5 mb-4">
              <button class="button button-cancel" @click="cancelPassword" :disabled="isSaving">Batal</button>
              <button class="button button-save" @click="updatePassword" :disabled="isSaving">
                <span class="spinner-border spinner-border-sm me-2" v-if="isSaving"></span>
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
      <PopupNotif :isModal="isModal" :title="title" :info="info" @send="receive"></PopupNotif>
    </div>
  </div>
</template>

<script>
import Api from "../../api/Api";
import noImage from "../../assets/user.jpg";
import PopupNotif from "./PopupNotif.vue";

export default {
  components: {
    PopupNotif,
  },
  data() {
    return {
      isSaving: false,
      isPassword: false,
      isModal: "",
      title: "",
      info: "",
      showPassword1: false,
      showPassword2: false,
      showPassword3: false,
      data: "",
      bankData: "",
      imageAvatar: "",
      passwordError: "",
      req: {
        name: "",
        email: "",
        phone: "",
        address: "",
        code: "",
        bank: "",
        bank_account_number: "",
        bank_account_name: "",
        avatar: "",
        old_password: "",
        new_password: "",
        new_password_confirmation: "",
      },
    };
  },
  created() {
    this.getData();
    this.getBank();
  },
  methods: {
    receive(data) {
      this.isModal = data;
    },
    toggleShow(num) {
      if (num == 1) this.showPassword1 = !this.showPassword1;
      if (num == 2) this.showPassword2 = !this.showPassword2;
      if (num == 3) this.showPassword3 = !this.showPassword3;
    },
    cancel() {
      this.req.name = this.data.name;
      this.req.email = this.data.email;
      this.req.phone = this.data.phone;
      this.req.address = this.data.partner.address;
      this.req.code = this.data.partner.code;
      this.req.bank = this.data.bank_account.id;
      this.req.bank_account_name = this.data.bank_account.account_name;
      this.req.bank_account_number = this.data.bank_account.account_number;
      this.imageAvatar = this.data.partner.avatar;
    },
    validatePassword() {
      if (this.req.new_password.length < 8) {
        this.passwordError = "Password must be at least 8 characters long.";
      } else {
        this.passwordError = "";
      }
    },
    cancelPassword() {
      this.clearPassword();
      this.isPassword = false;
    },
    getData() {
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/dashboard/owner/profile`, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.data = res.data.data;
          this.req.name = this.data.name;
          this.req.email = this.data.email;
          this.req.phone = parseInt(this.data.phone);
          this.req.address = this.data.partner.address;
          this.req.code = this.data.partner.code;
          this.req.bank = this.data.bank_account.id;
          this.req.bank_account_name = this.data.bank_account.account_name;
          this.req.bank_account_number = this.data.bank_account.account_number;
          this.imageAvatar = this.data.avatar ? this.data.avatar : [];
        })
        .catch((err) => {
          // this.$notify({
          //   group: "foo",
          //   type: "error",
          //   title: "Error",
          //   text: `${err}`,
          // });
          console.log(err);
        });
    },
    submitData() {
      this.isSaving = true;
      var data = new FormData();

      data.append("email", this.req.email);
      data.append("phone", "+" + this.req.phone);
      data.append("address", this.req.address);
      data.append("bank_id", this.req.bank);
      data.append("bank_account_name", this.req.bank_account_name);
      data.append("bank_account_number", this.req.bank_account_number);
      if (this.imageAvatar != this.data.avatar) {
        data.append("avatar", this.req.avatar);
      }

      Api.post(`${process.env.VUE_APP_BASE_URL}/partner/dashboard/owner/profile/update`, data, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.isSaving = false;
          // this.$notify({
          //   group: "foo",
          //   type: "success",
          //   title: "Success",
          //   text: "Data berhasil dibuat",
          // });
          this.title = "Data Berhasil Diperbarui!";
          this.info = "Selamat! Anda telah berhasil merubah data.";
          this.isModal = "success";
          this.getData();
          location.reload();
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.title = "Data Gagal Diperbarui!";
          this.info = "Data gagal diperbarui! Silakan ulangi kembali";
          this.isModal = "fail";
          this.isSaving = false;
        });
    },
    clearPassword() {
      this.req.old_password = "";
      this.req.new_password = "";
      this.req.new_password_confirmation = "";
    },
    updatePassword() {
      this.isSaving = true;
      var data = new FormData();

      data.append("old_password", this.req.old_password);
      data.append("new_password", this.req.new_password);
      data.append("new_password_confirmation", this.req.new_password_confirmation);

      Api.post(`${process.env.VUE_APP_BASE_URL}/partner/dashboard/owner/profile/update/password`, data, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.isSaving = false;
          // this.$notify({
          //   group: "foo",
          //   type: "success",
          //   title: "Success",
          //   text: "Data berhasil dibuat",
          // });
          this.title = "Kata Sandi Berhasil Diperbarui!";
          this.info = "Selamat! Anda telah berhasil merubah kata sandi.";
          this.isModal = "success";
          this.clearPassword();
        })
        .catch((err) => {
          let msg = err.message;

          if (err.response) {
            // Request made and server responded
            msg = err.response.data.message;
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            msg = "no response was received";
          } else {
            // Something happened in setting up the request that triggered an err
            console.log("err", err.message);
            msg = "err: " + err.message;
          }

          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${msg}`,
          });
          console.log(err);
          this.title = "Kata Sandi Gagal Diperbarui!";
          this.info = "Kata sandi gagal diperbarui. Silakan ulangi kembali!";
          this.isModal = "fail";
          this.isSaving = false;
        });
    },
    getBank() {
      Api.get(`${process.env.VUE_APP_BASE_URL}/partner/owner/withdrawal/bank`, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.bankData = res.data.data;
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Error",
            text: `${err}`,
          });
          console.log(err);
        });
    },
    uploadFileAvatar(event) {
      var input = event.target;
      this.req.avatar = input.files[0];

      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageAvatar = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    codeBank(code) {
      var bank;
      switch (code.toUpperCase()) {
        case "BRI":
          bank = "Bank Rakyat Indonesia";
          break;
        case "BCA":
          bank = "Bank Central Asia";
          break;
        case "MANDIRI":
          bank = "Bank Mandiri";
          break;
        case "BNI":
          bank = "Bank Negara Indonesia";
          break;
        case "BSI":
          bank = "Bank Syariah Indonesia";
          break;
        case "MEGA":
          bank = "Bank Mega";
          break;
        case "PERMATA":
          bank = "Bank Permata";
          break;
        case "CIMB":
          bank = "Bank CIMB Niaga";
          break;
        case "DANAMON":
          bank = "Bank Danamon Indonesia";
          break;
        case "BTPN":
          bank = "Bank BTPN";
          break;
        case "BTN":
          bank = "Bank BTN";
          break;
        case "MAYBANK":
          bank = "Bank Maybank Indonesia";
          break;
        case "OCBC":
          bank = "Bank OCBC NISP";
          break;
        default:
          bank = code.toUpperCase();
      }
      return bank;
    },
  },
  computed: {
    AvatarPictureUrl() {
      return this.imageAvatar.length == 0 ? noImage : this.imageAvatar != "nopic.png" ? this.imageAvatar : noImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.photo-profile {
  position: absolute;
  border: 8px solid #ffffff;
  box-sizing: border-box;
  top: 16vh;
  left: 2vw;
  height: 17vh;
  width: 17vh;
}
.edit-profile {
  position: absolute;
  top: 28vh;
  left: 8vw;
}
.change-pass {
  position: absolute;
  bottom: 2%;
  right: 2%;
}
.sz-img {
  width: 154px;
  height: 24px;
}
.height {
  height: 34vh;
}
.form-input {
  background: rgba(239, 241, 249, 0.6);
  border-width: 0px;
  border: none;
  outline: none;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  padding: 10px;
}
.dis {
  background: #d0d5dd;
}
.button {
  display: flex;
  border-radius: 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  &-save {
    background: #48a2d4;
    border: 1px solid #48a2d4;
    color: white;
  }
  &-save:is(:hover, :focus) {
    background: #176f9e;
  }
  &-cancel {
    background: #ffffff;
    border: 1px solid #e94b58;
    color: #e94b58;
  }
  &-cancel:is(:hover, :focus) {
    background: #e94b58;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
}
.banner {
  height: 25vh !important;
  object-fit: cover;
}
.eye-icon {
  position: absolute;
  top: 10px;
  right: 12px;
}
.edit-icon {
  height: 4vh;
}
input[type="file"] {
  display: none;
}
</style>
