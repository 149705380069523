<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Leads Prediction</h3>
            </div>
            <div class="row mt-4">
                <div class="col-md-12">
                    <label class="fw-bold mb-2">Services</label>
                    <select
                        class="form-control form-select"
                        v-model="product"
                        @click="clearForm()"
                    >
                        <option value="trawlpack-goods">
                            Troben Cargo Items
                        </option>
                        <option value="trawlpack-bike">
                            Troben Cargo Bike
                        </option>
                        <option value="trawltruck">Troben Truk</option>
                        <option value="trawlcarrier">Troben Carier</option>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Origin</label>
                    <multiselect
                        v-model="origin_regency"
                        v-if="product == 'trawlpack-goods'"
                        :options="origins"
                        label="name"
                        :loading="is_load"
                        track-by="id"
                        placeholder="Select Origin"
                        class="multi-form-custom"
                        @input="getPriceTrawlpackGoods()"
                    >
                    </multiselect>
                    <multiselect
                        v-model="origin_regency"
                        v-if="product == 'trawlpack-bike'"
                        :options="origins"
                        label="name"
                        :loading="is_load"
                        track-by="id"
                        placeholder="Select Origin"
                        class="multi-form-custom"
                        @input="getPriceTrawlpackBike()"
                    >
                    </multiselect>
                    <multiselect
                        v-model="origin_regency"
                        v-if="product == 'trawltruck'"
                        :options="origins"
                        label="name"
                        :loading="is_load"
                        track-by="id"
                        placeholder="Select Origin"
                        class="multi-form-custom"
                        @input="getPriceTrawltruck()"
                    >
                    </multiselect>
                    <multiselect
                        v-model="origin_regency"
                        v-if="product == 'trawlcarrier'"
                        :options="origins"
                        label="name"
                        :loading="is_load"
                        track-by="id"
                        placeholder="Select Origin"
                        class="multi-form-custom"
                        @input="getPriceTrawlcarrier()"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Destination</label>
                    <multiselect
                        v-model="destination_regency"
                        v-if="product == 'trawlpack-goods'"
                        :options="origins"
                        label="name"
                        :loading="is_load"
                        track-by="id"
                        placeholder="Select Destination"
                        @input="getPriceTrawlpackGoods()"
                    >
                    </multiselect>
                    <multiselect
                        v-model="destination_regency"
                        v-if="product == 'trawlpack-bike'"
                        :options="origins"
                        label="name"
                        :loading="is_load"
                        track-by="id"
                        placeholder="Select Destination"
                        @input="getPriceTrawlpackBike()"
                    >
                    </multiselect>
                    <multiselect
                        v-model="destination_regency"
                        v-if="product == 'trawltruck'"
                        :options="origins"
                        label="name"
                        :loading="is_load"
                        track-by="id"
                        placeholder="Select Destination"
                        @input="getPriceTrawltruck()"
                    >
                    </multiselect>
                    <multiselect
                        v-model="destination_regency"
                        v-if="product == 'trawlcarrier'"
                        :options="origins"
                        label="name"
                        :loading="is_load"
                        track-by="id"
                        placeholder="Select Destination"
                        @input="getPriceTrawlcarrier()"
                    >
                    </multiselect>
                </div>
                <div class="col-md-12 mt-3" v-if="product == 'trawlpack-goods'">
                    <label class="fw-bold mb-2">Weight (kg)</label>
                    <money
                        v-model="weight"
                        v-bind="weight_input"
                        class="form-control"
                        @input="getPriceTrawlpackGoods()"
                    >
                    </money>
                </div>
                <div class="col-md-12 mt-3" v-if="product == 'trawlpack-bike'">
                    <label class="fw-bold mb-2">Category</label>
                    <select
                        class="form-control form-select"
                        v-model="category"
                        @click="getPriceTrawlpackBike()"
                    >
                        <option value="">Select Category</option>
                        <option value="lower">{{ lowerTitle }}</option>
                        <option value="middle">{{ middleTitle }}</option>
                        <option value="high">{{ highTitle }}</option>
                    </select>
                </div>
                <div class="col-md-12 mt-3" v-if="product == 'trawltruck'">
                    <label class="fw-bold mb-2">Armada</label>
                    <multiselect
                        v-model="fleet_type"
                        :options="armadas"
                        label="name"
                        :loading="is_load"
                        track-by="code"
                        placeholder="Select Armada"
                        @input="getPriceTrawltruck()"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3" v-if="product == 'trawlcarrier'">
                    <label class="fw-bold mb-2">Service</label>
                    <select
                        class="form-control form-select"
                        v-model="service_type"
                        @click="getPriceTrawlcarrier()"
                    >
                        <option value="">Select Service</option>
                        <option value="door_to_door">Door to Door</option>
                        <option value="port_to_port">Port to Port</option>
                        <option value="pool_to_pool">Pool to Pool</option>
                    </select>
                </div>
                <div class="col-md-6 mt-3" v-if="product == 'trawlcarrier'">
                    <label class="fw-bold mb-2">Car Type</label>
                    <multiselect
                        v-model="car_type"
                        :options="cars"
                        label="variant"
                        :loading="is_load"
                        track-by="id"
                        placeholder="Select Car Type"
                        @input="getPriceTrawlcarrier()"
                    >
                    </multiselect>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Price</label>
                    <money v-model="price" v-bind="money" class="form-control">
                    </money>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Description</label>
                    <textarea
                        rows="5"
                        cols="4"
                        class="form-control"
                        placeholder="Description"
                        v-model="description"
                    ></textarea>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <a
                                href="javascript:void(0)"
                                @click="$router.go(-1)"
                                class="btn btn-outline-black btn-lg px-5 me-3 m-w-100"
                                >Cancel</a
                            >
                            <button
                                class="btn btn-green btn-lg px-5 me-3 m-mt-1 m-w-100"
                                type="button"
                                disabled
                                v-if="is_save"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Save
                            </button>
                            <button
                                class="btn btn-green btn-lg px-5 me-3 m-mt-1 m-w-100"
                                @click="storeData()"
                                v-else
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data Saved!</h4>
                </div>
                <div class="mt-4">
                    <a
                        :href="`/crm/prospect/detail/${$route.params.id}`"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Money } from "v-money";
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
        Money,
    },
    name: "ManagementMasterRouteCreate",
    data() {
        return {
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "Rp. ",
                suffix: "",
                precision: 0,
                masked: false,
            },
            weight_input: {
                decimal: ",",
                thousands: ".",
                suffix: "",
                precision: 0,
                masked: false,
            },
            modalSuccess: false,
            origin_regency: null,
            destination_regency: null,
            origins: [],
            is_load: false,
            weight: 1,
            category: "",
            price: 0,
            description: "",
            is_save: false,
            product: "trawlpack-goods",
            service_type: "",
            car_type: null,
            fleet_type: "",
            armadas: [],
            cars: [],
            detail_prospect: {},
            lowerTitle: "100-149 CC",
            middleTitle: "150-249 CC",
            highTitle: ">250 CC",
        };
    },
    created() {
        this.getGeoList();
        this.getListArmada();
        this.getCars();
        this.getDetailProspect();
    },
    methods: {
        getGeoList() {
            this.is_load = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.origins = data;
                    this.is_load = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load = false;
                });
        },
        getListArmada() {
            Api.get(`https://apiv2.trawlbens.com/web/v2/trawltruck/fleet`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.armadas = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getCars() {
            Api.get(
                `https://apiv2.trawlbens.com/carrier/landing/vehicles/list`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.cars = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getPriceTrawlpackGoods() {
            let params = {
                origin_regency: this.origin_regency.name,
                destination_regency: this.destination_regency.name,
            };
            Api.get(`https://apiv2.trawlbens.com/web/trawlpack/pricing`, {
                params,
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    if (data.price.price != 0) {
                        this.price = data.price.price_min * this.weight;
                    } else {
                        this.price = this.detail_prospect.price * this.weight;
                    }
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getPriceTrawlpackBike() {
            let params = {
                origin_regency: this.origin_regency.name,
                destination_regency: this.destination_regency.name,
                type_cc: this.category,
            };
            Api.get(`https://apiv2.trawlbens.com/web/trawlpack-bike/pricing`, {
                params,
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.price = data.price.price_min;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getPriceTrawltruck() {
            let params = {
                origin_regency: this.origin_regency.name,
                destination_regency: this.destination_regency.name,
                type_fleet: this.fleet_type?.code,
            };
            Api.get(`https://apiv2.trawlbens.com/web/v2/trawltruck/pricing`, {
                params,
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.price = data.list_price[0].price;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getPriceTrawlcarrier() {
            let params = {
                origin_regency_id: this.origin_regency.id,
                origin_regency: this.origin_regency.name,
                destination_regency_id: this.destination_regency.id,
                destination_regency: this.destination_regency.name,
                variant: this.car_type?.variant,
            };
            Api.post(
                `https://apiv2.trawlbens.com/carrier/services/overview`,
                params
            )
                .then((res) => {
                    var data = res.data.data;
                    data.services.forEach((data) => {
                        if (data.service == this.service_type) {
                            this.price = data.price;
                        } else {
                            this.price = 0;
                        }
                    });
                })
                .catch((err) => {
                    console.log(err);
                    // this.$notify({
                    //     group: "foo",
                    //     type: "error",
                    //     title: "Error",
                    //     text: err.response.data.message,
                    // });
                });
        },
        clearForm() {
            this.fleet_type = "";
            this.car_type = null;
            this.service_type = "";
            this.origin_regency = "";
            this.destination_regency = "";
            this.weight = 1;
            this.price = 0;
            this.description = "";
            this.category = "";
        },

        getDetailProspect() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/prospect/prediction/detail?prospect_id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;

                    this.detail_prospect = data;
                    this.product = data.product;
                    this.weight = data.weight;
                    this.category = data.cc;
                    this.origin_regency = data.origin_regency;
                    this.destination_regency = data.destination_regency;
                    this.price = data.price * data.weight;
                    this.fleet_type = data.fleet_type;
                    this.car_type = data.car_type;
                    this.service_type = data.service_type;
                    this.description = data.description;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        storeData() {
            if (this.origin_regency == null) {
                return this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Origin Is Required",
                });
            }
            if (this.destination_regency == null) {
                return this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Destination Is Required",
                });
            }
            if (this.price !== this.price || this.price == 0) {
                return this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Price Cannot Be 0",
                });
            }
            if (this.product == "trawlpack-bike") {
                if (this.category == "") {
                    return this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Category Is Required",
                    });
                }
            }
            if (this.product == "trawltruck") {
                if (this.fleet_type == "") {
                    return this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Armada Is Required",
                    });
                }
            }
            if (this.product == "trawlcarrier") {
                if (this.service_type == "") {
                    return this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Service Is Required",
                    });
                }
                if (this.car_type == "") {
                    return this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Car Type Is Required",
                    });
                }
            }
            if (this.description == "") {
                return this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Description Is Required",
                });
            }
            this.is_save = true;
            var data = {
                id: this.$route.params.id,
                product: this.product,
                fleet_type: this.fleet_type?.code,
                car_id: this.car_type?.id,
                service_type: this.service_type,
                origin_regency_id: this.origin_regency?.id,
                cc: this.category,
                destination_regency_id: this.destination_regency?.id,
                weight: this.weight,
                price: this.price,
                description: this.description,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/prospect/prediction/create`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    this.modalSuccess = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
.money-custom {
    height: 42px;
    font-size: 16px;
    padding-left: 15px;
}
.multi-form-custom {
}
.multiselect__placeholder {
    margin-bottom: 7px !important;
    font-size: 13px !important;
    margin-top: 1px !important;
    color: #6c757d !important;
}
.multiselect__tags {
    min-height: 34px !important;
    padding: 4px 40px 0 7px !important;
}
.multiselect__select {
    right: 0 !important;
}
.multiselect__select:before {
    top: 57% !important;
}
.multiselect,
.multiselect__input,
.multiselect__single {
    font-size: 13px !important;
}
.multiselect__input,
.multiselect__single {
    margin-top: 5px;
}
.multiselect__input,
.multiselect__single {
    min-height: 17px !important;
}
</style>
