<template>
    <div class="padding-container">
        <div class="box mt-4">
            <div class="d-flex">
                <div class="crm-avatar" :style="{'background-image': 'url(' + (profile.avatar) + ')'}"></div>
                <div class="ms-auto">
                    <a href="/crm/profile/edit" class="btn btn-green px-4 btn-lg">
                        Edit Profile
                        <img src="../../assets/edit.png" width="18" class="ms-2" alt="">
                    </a>
                </div>
            </div>
            <div class="size-24 fw-bold mt-3">
                {{ profile.name }}
            </div>
            <template v-if="profile.is_active == true">
                <span class="tag green-solid radius px-5 mt-2">Active</span>
            </template>
            <template v-else>
                <span class="tag red-solid radius px-5 mt-2">Not Active</span>
            </template>
            <div class="mt-4">
                <hr>
            </div>
            <div class="d-flex align-items-center">
                <img src="../../assets/gender.png" width="20" alt="">
                <div class="ms-3 size-16">
                    <template v-if="profile.gender == 'male'">
                        Pria
                    </template>
                    <template v-else>
                        Wanita
                    </template>
                </div>
            </div>
            <div class="d-flex align-items-center mt-3">
                <img src="../../assets/phone.png" width="18" alt="">
                <div class="ms-3 size-16">
                    {{ profile.phone }}
                </div>
            </div>
            <div class="d-flex align-items-center mt-3">
                <img src="../../assets/email.png" width="18" alt="">
                <div class="ms-3 size-16">
                    {{ profile.email }}
                </div>
            </div>
            <div class="d-flex align-items-center mt-3">
                <img src="../../assets/location.png" width="18" alt="">
                <div class="ms-3 size-16">
                    {{ profile.address }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from "../../api/Api";

    export default {
        components: {
        },
        name: "CrmProfile",
        data() {
            return {
                profile: {}
            }
        },
        created() {
            this.getProfile()
        },
        methods: {
            getProfile() {
                Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/account`, {
                    headers: {
                        'Accept': 'application/json',
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    var data = res.data.data
                    this.profile = data
                })
                .catch((err) => {
                    console.log(err);
                });
            },
        }
    };
</script>

<style scoped>
    .crm-avatar {
        width: 100px;
        height: 100px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50px;
        position: relative;
        border: 1px solid #E60013;
    }
</style>