<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="/trawltruck/ho/master/fleet"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Tambah Data Jenis Truk</h3>
            </div>
            <div class="row mt-5">
                <div class="col-md-12">
                    <label class="fw-bold mb-2">Jenis Truk</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Jenis Truk"
                    />
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Harga Per Km</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Harga Per Km"
                    />
                </div>
                <div class="col-md-12 mt-4">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <!-- <a href="/trawltruck/ho/partner" class="btn btn-outline-black btn-lg px-5 me-3">Batal</a> -->
                            <a
                                href="javascript:void(0)"
                                class="btn btn-outline-black btn-lg px-5 me-3"
                                @click="modalError = true"
                                >Batal</a
                            >
                            <a
                                href="javascript:void(0)"
                                class="btn btn-primary btn-lg px-5"
                                @click="modalSuccess = true"
                                >Simpan</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data Saved!!</h4>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="modalSuccess = false"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalError"></div>
            <div class="modal-body-chat vsm" v-if="modalError">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/close.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Penambahan Data Gagal!</h4>
                    <div class="mt-2">
                        Mohon maaf terjadi kesalahan! Silakan ulangi kembali
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="modalError = false"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";

export default {
    props: "",
    name: "ManagementMasterFleetCreate",
    data() {
        return {
            showPassword: false,
            is_changing_driver: false,
            is_changing_ktp: false,
            is_changing_sim: false,
            req: {
                driver: "",
                ktp: "",
                sim: "",
            },
            modalSuccess: false,
            modalError: false,
        };
    },
    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        uploadDriver() {
            var driver = new FileReader();
            driver.onload = function (e) {
                $(".image-view-1").css(
                    "background-image",
                    "url(" + e.target.result + ")"
                );
            };
            driver.readAsDataURL($("#driver")[0].files[0]);
            this.is_changing_driver = true;
        },
        uploadKTP() {
            var ktp = new FileReader();
            ktp.onload = function (e) {
                $(".image-view-2").css(
                    "background-image",
                    "url(" + e.target.result + ")"
                );
            };
            ktp.readAsDataURL($("#ktp")[0].files[0]);
            this.is_changing_ktp = true;
        },
        uploadSim() {
            var sim = new FileReader();
            sim.onload = function (e) {
                $(".image-view-3").css(
                    "background-image",
                    "url(" + e.target.result + ")"
                );
            };
            sim.readAsDataURL($("#sim")[0].files[0]);
            this.is_changing_sim = true;
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
</style>
