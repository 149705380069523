<template>
    <div class="padding-container">
        <template v-if="page_show == 'main_page'">
            <div class="fw-bold">
                <a
                    href="javascript:void(0)"
                    @click="$router.go(-1)"
                    class="text-gray-dashboard size-16"
                >
                    <i class="fa fa-angle-left me-2"></i>
                    Back
                </a>
            </div>
            <div class="box mt-4">
                <div class="d-flex align-items-center">
                    <h3 class="fw-bold mb-0">Make A Leads/Prospect</h3>
                </div>
                <div class="row mt-4">
                    <div class="col-md-12">
                        <label class="fw-bold mb-2">Activities</label>
                        <select
                            class="form-control form-select"
                            v-model="req.subject"
                        >
                            <option value="">Choose Activities</option>
                            <option value="call">Call</option>
                            <option value="mail">Send Mail</option>
                            <option value="meeting">Meeting</option>
                            <option value="whatsapp">Chat WhatsApp</option>
                            <option value="follow_up">Follow Up</option>
                            <option value="canvasing">Canvasing</option>
                            <option value="respond_customer">
                                Respond Customers
                            </option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div class="col-md-12 mt-3" v-if="req.subject == 'mail'">
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="mail"
                                id="mail1"
                                value="application_email"
                                v-model="req.mail_type"
                            />
                            <label class="fw-medium" for="mail1"
                                >Via Aplikasi</label
                            >
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="mail"
                                id="mail2"
                                value="email"
                                v-model="req.mail_type"
                            />
                            <label class="fw-medium" for="mail2"
                                >Via Email</label
                            >
                        </div>
                    </div>
                    <div class="col-md-12 mt-3" v-if="req.subject == 'meeting'">
                        <label class="fw-bold mb-2">Title</label>
                        <input
                            class="form-control"
                            placeholder="Title"
                            v-model="req.meeting_title"
                        />
                        <div class="mt-3">
                            <label class="fw-bold mb-2">Date Time</label>
                            <date-picker
                                v-model="req.meeting_date"
                                type="datetime"
                                value-type="format"
                                class="w-100 mt-1"
                                format="DD-MM-YYYY hh:mm"
                                :clearable="false"
                                placeholder="Start"
                            >
                            </date-picker>
                        </div>
                        <div class="form-check form-check-inline mt-3">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="meeting_type"
                                id="online"
                                value="online"
                                v-model="req.meeting_type"
                            />
                            <label class="fw-medium" for="online">Online</label>
                        </div>
                        <div class="form-check form-check-inline mt-3">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="meeting_type"
                                id="offline"
                                value="offline"
                                v-model="req.meeting_type"
                            />
                            <label class="fw-medium" for="offline"
                                >Offline</label
                            >
                        </div>
                        <div class="mt-3">
                            <label class="fw-bold mb-2">Description</label>
                            <textarea
                                cols="30"
                                rows="4"
                                class="form-control"
                                placeholder="Description"
                                v-model="req.meeting_desc"
                            ></textarea>
                        </div>
                    </div>
                    <div
                        class="col-md-12 mt-3"
                        v-if="req.subject == 'canvasing'"
                    >
                        <label class="fw-bold">Photo</label>
                        <div class="text-gray mb-2">Photo Max 5MB</div>
                        <label
                            type="file"
                            :class="!attachment ? 'upload__btn' : ''"
                        >
                            <img
                                v-if="attachment"
                                class="photo"
                                :src="attachmentPictureUrl"
                            />
                            <p v-else class="mb-0">
                                <i class="fa fa-plus"></i>
                            </p>
                            <input
                                class="cursor-pointer"
                                id="upload"
                                type="file"
                                name="file"
                                ref="file"
                                @change="uploadPhoto"
                            />
                        </label>
                    </div>
                    <div
                        class="col-md-12 mt-3"
                        v-if="req.subject == 'respond_customer'"
                    >
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="respond_customer"
                                id="package_delivery"
                                value="package_delivery"
                                v-model="req.respond_customer"
                            />
                            <label class="fw-medium" for="package_delivery"
                                >Package Delivery</label
                            >
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="respond_customer"
                                id="car_delivery"
                                value="car_delivery"
                                v-model="req.respond_customer"
                            />
                            <label class="fw-medium" for="car_delivery"
                                >Car Delivery</label
                            >
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="respond_customer"
                                id="truck_rental"
                                value="truck_rental"
                                v-model="req.respond_customer"
                            />
                            <label class="fw-medium" for="truck_rental"
                                >Truck Rental</label
                            >
                        </div>
                    </div>
                    <div class="col-md-12 mt-3" v-if="req.subject == 'other'">
                        <label class="fw-bold mb-2">Description</label>
                        <textarea
                            cols="30"
                            rows="4"
                            class="form-control"
                            placeholder="Description"
                            v-model="req.subject_description"
                        ></textarea>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label class="fw-bold mb-2">Full Name</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Full Name"
                            v-model="data_user.name"
                            disabled
                        />
                    </div>
                    <div class="col-md-6 mt-3">
                        <label class="fw-bold mb-2">Phone Number</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Phone Number"
                            v-model="data_user.phone"
                            disabled
                        />
                    </div>
                    <div class="col-md-6 mt-3">
                        <label class="fw-bold mb-2">Follow Up</label>
                        <date-picker
                            v-model="req.follow_up"
                            type="date"
                            value-type="format"
                            class="w-100"
                            format="YYYY-MM-DD"
                            :clearable="false"
                            placeholder="Follow Up"
                        >
                        </date-picker>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label class="fw-bold mb-2">Priority</label>
                        <select
                            v-model="req.priority"
                            class="form-control form-select"
                        >
                            <option value="">Choose Priority</option>
                            <option value="cold">low priority</option>
                            <option value="hot">high priority</option>
                        </select>
                    </div>
                    <div v-show="isRole(rolesOwner)" class="col-md-12 mt-3">
                        <label class="fw-bold mb-2">Owner</label>
                        <multiselect
                            v-model="crm_specialist_id"
                            :options="crmSpecialist"
                            label="name"
                            :loading="is_load"
                            track-by="id"
                            placeholder="Owner"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-12 mt-4">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="req.is_prediction"
                                id="flexCheckDefault"
                            />
                            <label
                                class="form-check-label"
                                for="flexCheckDefault"
                            >
                                No Prediction
                            </label>
                        </div>
                    </div>
                    <template v-if="req.is_prediction == false">
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-2">Services</label>
                            <select
                                class="form-control form-select"
                                v-model="product"
                                @click="clearForm()"
                            >
                                <option value="trawlpack-goods">
                                    TroPack Items
                                </option>
                                <option value="trawlpack-bike">
                                    TroPack Bike
                                </option>
                                <option value="trawltruck">TroTruck</option>
                                <option value="trawlcarrier">TroCarrier</option>
                            </select>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="fw-bold mb-2">Origin</label>
                            <multiselect
                                v-model="origin_regency"
                                v-if="product == 'trawlpack-goods'"
                                :options="origins"
                                label="name"
                                :loading="is_load"
                                track-by="id"
                                placeholder="Select Origin"
                                class="multi-form-custom"
                                @input="getPriceTrawlpackGoods()"
                            >
                            </multiselect>
                            <multiselect
                                v-model="origin_regency"
                                v-if="product == 'trawlpack-bike'"
                                :options="origins"
                                label="name"
                                :loading="is_load"
                                track-by="id"
                                placeholder="Select Origin"
                                class="multi-form-custom"
                                @input="getPriceTrawlpackBike()"
                            >
                            </multiselect>
                            <multiselect
                                v-model="origin_regency"
                                v-if="product == 'trawltruck'"
                                :options="origins"
                                label="name"
                                :loading="is_load"
                                track-by="id"
                                placeholder="Select Origin"
                                class="multi-form-custom"
                                @input="getPriceTrawltruck()"
                            >
                            </multiselect>
                            <multiselect
                                v-model="origin_regency"
                                v-if="product == 'trawlcarrier'"
                                :options="origins"
                                label="name"
                                :loading="is_load"
                                track-by="id"
                                placeholder="Select Origin"
                                class="multi-form-custom"
                                @input="getPriceTrawlcarrier()"
                            >
                            </multiselect>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="fw-bold mb-2">Destination</label>
                            <multiselect
                                v-model="destination_regency"
                                v-if="product == 'trawlpack-goods'"
                                :options="origins"
                                label="name"
                                :loading="is_load"
                                track-by="id"
                                placeholder="Select Destination"
                                @input="getPriceTrawlpackGoods()"
                            >
                            </multiselect>
                            <multiselect
                                v-model="destination_regency"
                                v-if="product == 'trawlpack-bike'"
                                :options="origins"
                                label="name"
                                :loading="is_load"
                                track-by="id"
                                placeholder="Select Destination"
                                @input="getPriceTrawlpackBike()"
                            >
                            </multiselect>
                            <multiselect
                                v-model="destination_regency"
                                v-if="product == 'trawltruck'"
                                :options="origins"
                                label="name"
                                :loading="is_load"
                                track-by="id"
                                placeholder="Select Destination"
                                @input="getPriceTrawltruck()"
                            >
                            </multiselect>
                            <multiselect
                                v-model="destination_regency"
                                v-if="product == 'trawlcarrier'"
                                :options="origins"
                                label="name"
                                :loading="is_load"
                                track-by="id"
                                placeholder="Select Destination"
                                @input="getPriceTrawlcarrier()"
                            >
                            </multiselect>
                        </div>
                        <div
                            class="col-md-12 mt-3"
                            v-if="product == 'trawlpack-goods'"
                        >
                            <label class="fw-bold mb-2">Weight (kg)</label>
                            <money
                                v-model="weight"
                                v-bind="weight_input"
                                class="form-control"
                                @input="getPriceTrawlpackGoods()"
                            >
                            </money>
                        </div>
                        <div
                            class="col-md-12 mt-3"
                            v-if="product == 'trawlpack-bike'"
                        >
                            <label class="fw-bold mb-2">Category</label>
                            <select
                                class="form-control form-select"
                                v-model="category"
                                @click="getPriceTrawlpackBike()"
                            >
                                <option value="">Select Category</option>
                                <option value="lower">{{ lowerTitle }}</option>
                                <option value="middle">
                                    {{ middleTitle }}
                                </option>
                                <option value="high">{{ highTitle }}</option>
                            </select>
                        </div>
                        <div
                            class="col-md-12 mt-3"
                            v-if="product == 'trawltruck'"
                        >
                            <label class="fw-bold mb-2">Armada</label>
                            <multiselect
                                v-model="fleet_type"
                                :options="armadas"
                                label="name"
                                :loading="is_load"
                                track-by="code"
                                placeholder="Select Armada"
                                @input="getPriceTrawltruck()"
                            >
                            </multiselect>
                        </div>
                        <div
                            class="col-md-6 mt-3"
                            v-if="product == 'trawlcarrier'"
                        >
                            <label class="fw-bold mb-2">Service</label>
                            <select
                                class="form-control form-select"
                                v-model="service_type"
                                @click="getPriceTrawlcarrier()"
                            >
                                <option value="">Select Service</option>
                                <option value="door_to_door">
                                    Door to Door
                                </option>
                                <option value="port_to_port">
                                    Port to Port
                                </option>
                                <option value="pool_to_pool">
                                    Pool to Pool
                                </option>
                            </select>
                        </div>
                        <div
                            class="col-md-6 mt-3"
                            v-if="product == 'trawlcarrier'"
                        >
                            <label class="fw-bold mb-2">Car Type</label>
                            <multiselect
                                v-model="car_type"
                                :options="cars"
                                label="variant"
                                :loading="is_load"
                                track-by="id"
                                placeholder="Select Car Type"
                                @input="getPriceTrawlcarrier()"
                            >
                            </multiselect>
                        </div>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-2">Price</label>
                            <money
                                v-model="price"
                                v-bind="money"
                                class="form-control"
                            >
                            </money>
                        </div>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-2">Description</label>
                            <textarea
                                rows="5"
                                cols="4"
                                class="form-control"
                                placeholder="Description"
                                v-model="description"
                            ></textarea>
                        </div>
                    </template>
                </div>
                <div class="d-flex mt-4">
                    <div class="ms-auto">
                        <div class="d-flex">
                            <a
                                href="/crm/customers/leads"
                                class="btn btn-outline-black btn-lg mt-3 px-5 me-3"
                            >
                                Cancel
                            </a>
                            <button
                                class="btn btn-green btn-lg mt-3 px-5"
                                @click="storeData()"
                                :disabled="is_save"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    v-if="is_save"
                                ></span>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- modal -->
            <div class="modal-vue">
                <div class="overlay" v-if="modalSuccess"></div>
                <div class="modal-body-chat vsm" v-if="modalSuccess">
                    <div class="mt-4 text-center">
                        <img
                            src="../../assets/dashboard/checklis.png"
                            width="80"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4">Data Saved!!</h4>
                    </div>
                    <div class="mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="okModalSuccess()"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ok
                        </a>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="page_show == 'mail_page'">
            <div class="fw-bold">
                <a
                    href="javascript:void(0)"
                    @click="page_show = 'main_page'"
                    class="text-gray-dashboard size-16"
                >
                    <i class="fa fa-angle-left me-2"></i>
                    Back
                </a>
            </div>
            <div class="box mt-4">
                <div class="d-flex align-items-center">
                    <h3 class="fw-bold mb-3">Email</h3>
                </div>
                <div class="mt-3 d-flex bd-highligh align-items-center">
                    <div class="bd-highlight w-mail-title">
                        <label class="fw-medium text-gray-dashboard">
                            From
                        </label>
                    </div>
                    <div class="flex-grow-1 bd-highlight">
                        <input
                            type="text"
                            class="form-control w-100"
                            placeholder="From"
                            v-model="req.mail_from"
                        />
                    </div>
                </div>
                <div class="mt-3 d-flex bd-highligh align-items-center">
                    <div class="bd-highlight w-mail-title">
                        <label class="fw-medium text-gray-dashboard">
                            To
                        </label>
                    </div>
                    <div class="flex-grow-1 bd-highlight">
                        <input
                            type="text"
                            class="form-control w-100"
                            placeholder="To"
                            v-model="req.mail_to"
                        />
                    </div>
                </div>
                <div class="mt-3 d-flex bd-highligh align-items-center">
                    <div class="bd-highlight w-mail-title">
                        <label class="fw-medium text-gray-dashboard">
                            CC
                        </label>
                    </div>
                    <div class="flex-grow-1 bd-highlight">
                        <input-tag
                            v-model="req.mail_cc"
                            class="input-tag"
                            placeholder="Type and Enter Email CC"
                        ></input-tag>
                    </div>
                </div>
                <div class="mt-3 d-flex bd-highligh align-items-center">
                    <div class="bd-highlight w-mail-title">
                        <label class="fw-medium text-gray-dashboard">
                            Subject
                        </label>
                    </div>
                    <div class="flex-grow-1 bd-highlight">
                        <input
                            type="text"
                            class="form-control w-100"
                            placeholder="Subject"
                            v-model="req.mail_subject"
                        />
                    </div>
                </div>
                <div class="mt-3">
                    <textarea
                        rows="7"
                        cols="30"
                        class="form-control"
                        placeholder="Type Here"
                        v-model="req.mail_content"
                    ></textarea>
                </div>
                <div class="mt-3">
                    <button
                        class="btn btn-green btn-lg px-5"
                        type="button"
                        disabled
                        v-if="is_save_mail"
                    >
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Save
                    </button>
                    <button
                        type="submit"
                        v-else
                        class="btn btn-green btn-lg px-5"
                        @click="saveMail()"
                    >
                        Save
                    </button>
                </div>
            </div>

            <div class="modal-vue">
                <div class="overlay" v-if="modalSuccessEmail"></div>
                <div class="modal-body-chat vsm" v-if="modalSuccessEmail">
                    <div class="mt-4 text-center">
                        <img
                            src="../../assets/dashboard/checklis.png"
                            width="80"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4">Send Mail Succeeded!</h4>
                    </div>
                    <div class="mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="okModalSuccessEmail()"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ok
                        </a>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import Api from "../../api/Api";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import Multiselect from "vue-multiselect";
import { Money } from "v-money";
import noImage from "../../assets/no-photo.png";
import InputTag from "vue-input-tag";

export default {
    components: {
        DatePicker,
        Multiselect,
        Money,
        InputTag,
    },
    name: "MakeAProspectLeads",
    data() {
        return {
            moment: moment,
            today: new Date(),
            req: {
                follow_up: moment(this.today).format("YYYY-MM-DD"),
                priority: "cold",
                product: "trawlpack",
                weight: 1,
                price: 0,
                description: "",
                is_prediction: false,
                subject: this.$route.query.type ? this.$route.query.type : "",
                subject_description: null,
                mail_type: "application_email",
                meeting_date: moment(this.today).format("DD-MM-YYYY hh:mm"),
                meeting_type: "online",
                attachment: "",
                meeting_title: "",
                meeting_desc: "",
                respond_customer: "package_delivery",
                mail_from: "cs@trawlbens.id",
                mail_to: "zulaika@gmail.com",
                mail_cc: [],
                mail_subject: "",
                mail_content: "",
            },
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "Rp. ",
                suffix: "",
                precision: 0,
                masked: false,
            },
            weight_input: {
                decimal: ",",
                thousands: ".",
                suffix: "",
                precision: 0,
                masked: false,
            },
            modalSuccess: false,
            origin_regency: null,
            destination_regency: null,
            origins: [],
            weight: 1,
            category: "",
            price: 0,
            description: "",
            product: "trawlpack-goods",
            service_type: "",
            car_type: null,
            fleet_type: "",
            armadas: [],
            cars: [],
            detail_prospect: {},
            lowerTitle: "<150 cc",
            middleTitle: "150 cc - 250 cc",
            highTitle: ">250 cc",
            is_load: false,
            is_save: false,
            crm_specialist_id: "",
            crmSpecialist: [],
            attachment: "",
            page_show: "main_page",
            is_save_mail: false,
            modalSuccessEmail: false,
            data_user: null,
            rolesOwner: ["crm-manager", "admin-crm", "admin-super"],
            roles: [],
            prospect_id: null,
            activity_id: null,
        };
    },
    created() {
        this.getCrmSpecialist();
        this.getUserLogin();
        this.getGeoList();
        this.getListArmada();
        this.getCars();
        this.getUserData();
    },
    computed: {
        attachmentPictureUrl() {
            return this.attachment.length > 0 ? this.attachment : noImage;
        },
    },
    methods: {
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.roles = res.data.data.roles;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        getGeoList() {
            this.is_load = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.origins = data;
                    this.is_load = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load = false;
                });
        },
        getListArmada() {
            Api.get(`https://apiv2.trawlbens.com/web/v2/trawltruck/fleet`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.armadas = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getCars() {
            Api.get(
                `https://apiv2.trawlbens.com/carrier/landing/vehicles/list`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.cars = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getPriceTrawlpackGoods() {
            let params = {
                origin_regency: this.origin_regency?.name,
                destination_regency: this.destination_regency?.name,
            };
            Api.get(`https://apiv2.trawlbens.com/web/trawlpack/pricing`, {
                params,
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    if (data.price.price != 0) {
                        this.price = data.price.price_min * this.weight;
                    } else {
                        this.price = this.detail_prospect.price * this.weight;
                    }
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getPriceTrawlpackBike() {
            let params = {
                origin_regency: this.origin_regency?.name,
                destination_regency: this.destination_regency?.name,
                type_cc: this.category,
            };
            Api.get(`https://apiv2.trawlbens.com/web/trawlpack-bike/pricing`, {
                params,
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.price = data.price.price_min;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getPriceTrawltruck() {
            let params = {
                origin_regency: this.origin_regency?.name,
                destination_regency: this.destination_regency?.name,
                type_fleet: this.fleet_type?.code,
            };
            Api.get(`https://apiv2.trawlbens.com/web/v2/trawltruck/pricing`, {
                params,
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.price = data.list_price[0].price;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getPriceTrawlcarrier() {
            let params = {
                origin_regency_id: this.origin_regency?.id,
                origin_regency: this.origin_regency?.name,
                destination_regency_id: this.destination_regency?.id,
                destination_regency: this.destination_regency?.name,
                variant: this.car_type?.variant,
            };
            Api.post(
                `https://apiv2.trawlbens.com/carrier/services/overview`,
                params
            )
                .then((res) => {
                    var data = res.data.data;
                    data.services.forEach((data) => {
                        if (data.service == this.service_type) {
                            this.price = data.price;
                        } else {
                            this.price = 0;
                        }
                    });
                })
                .catch((err) => {
                    console.log(err);
                    // this.$notify({
                    //     group: "foo",
                    //     type: "error",
                    //     title: "Error",
                    //     text: err.response.data.message,
                    // });
                });
        },
        clearForm() {
            this.fleet_type = "";
            this.car_type = null;
            this.service_type = "";
            this.origin_regency = "";
            this.destination_regency = "";
            this.weight = 1;
            this.price = 0;
            this.description = "";
            this.category = "";
        },
        getCrmSpecialist() {
            this.is_load = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist`,
                {
                    params: {
                        page: -1,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.crmSpecialist = res.data.data.list_data;
                    this.is_load = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load = false;
                });
        },
        okModalSuccess() {
            if (this.req.subject == "whatsapp") {
                window.open("https://wa.me/6281399000000", "_blank");
                this.modalSuccess = false;
                window.location.href = "/crm/prospect/today";
            } else if (
                this.req.subject == "mail" &&
                this.req.mail_type == "email"
            ) {
                this.modalSuccess = false;
                window.location.href = "/crm/prospect/today";
            } else {
                this.modalSuccess = false;
                window.location.href = "/crm/prospect/today";
            }
        },
        getUserData() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/lead/detail?id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data_user = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        storeWithMeeting() {
            this.is_save = true;
            var data = new FormData();
            data.append("prospect_id", this.prospect_id);
            data.append("meeting_type", this.req.meeting_type);
            data.append("meeting_title", this.req.meeting_title);
            data.append("meeting_desc", this.req.meeting_desc);
            data.append("meeting_date", this.req.meeting_date);
            let api_url = "";
            api_url = "/crm/customer/meet/create";
            Api.post(`${process.env.VUE_APP_SERVICE_URL}${api_url}`, data, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    console.log(res);
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        storePrediction() {
            this.is_save = true;
            var data = {
                id: this.prospect_id,
                product: this.product,
                fleet_type: this.fleet_type?.code,
                car_id: this.car_type?.id,
                service_type: this.service_type,
                origin_regency_id: this.origin_regency?.id,
                cc: this.category,
                destination_regency_id: this.destination_regency?.id,
                weight: this.weight,
                price: this.price,
                description: this.description,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/prospect/prediction/create`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    this.modalSuccess = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        storeData() {
            if (this.req.subject == "") {
                return this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Activities Is Required",
                });
            }
            if (this.isRole(this.rolesOwner)) {
                if (this.crm_specialist_id == "") {
                    return this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Owner Is Required",
                    });
                }
            }
            if (this.req.is_prediction == false) {
                if (this.origin_regency == null) {
                    return this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Origin Is Required",
                    });
                }
                if (this.destination_regency == null) {
                    return this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Destination Is Required",
                    });
                }
                if (this.price !== this.price || this.price == 0) {
                    return this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Price Not Found",
                    });
                }
                if (this.product == "trawlpack-bike") {
                    if (this.category == "") {
                        return this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Category Is Required",
                        });
                    }
                }
                if (this.product == "trawltruck") {
                    if (this.fleet_type == "") {
                        return this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Armada Is Required",
                        });
                    }
                }
                if (this.product == "trawlcarrier") {
                    if (this.service_type == "") {
                        return this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Service Is Required",
                        });
                    }
                    if (this.car_type == "") {
                        return this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Car Type Is Required",
                        });
                    }
                }
                if (this.description == "") {
                    return this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Description Is Required",
                    });
                }
            }

            this.is_save = true;
            var data = new FormData();
            data.append("id", this.$route.params.id);
            data.append("type", "lead");
            data.append("name", this.data_user.name);
            data.append("phone", this.data_user.phone);
            data.append("priority", this.req.priority);
            data.append("follow_up", this.req.follow_up);
            if (
                this.req.subject == "call" ||
                this.req.subject == "whatsapp" ||
                this.req.subject == "follow_up" ||
                this.req.subject == "mail" ||
                this.req.subject == "meeting"
            ) {
                data.append("subject", this.req.subject);
            }
            if (this.req.subject == "respond_customer") {
                data.append("subject", this.req.respond_customer);
            }
            if (this.req.subject == "other") {
                data.append("subject", this.req.subject);
                data.append(
                    "subject_description",
                    this.req.subject_description
                );
            }
            if (this.req.subject == "canvasing") {
                data.append("subject", this.req.subject);
                data.append("attachment", this.req.attachment);
            }
            data.append(
                "specialist_id",
                this.crm_specialist_id.id ? this.crm_specialist_id.id : null
            );
            let api_url = "";
            api_url = "/crm/prospect/create";
            Api.post(`${process.env.VUE_APP_SERVICE_URL}${api_url}`, data, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    console.log(res);
                    this.activity_id = res.data.data.activity_id;
                    this.prospect_id = res.data.data.prospect_id;
                    this.is_save = false;
                    if (this.req.is_prediction == true) {
                        if (
                            this.req.subject == "mail" &&
                            this.req.mail_type == "application_email"
                        ) {
                            this.page_show = "mail_page";
                        } else if (this.req.subject == "meeting") {
                            this.prospect_id = res.data.data.prospect_id;
                            this.storeWithMeeting();
                        } else {
                            this.modalSuccess = true;
                        }
                    } else {
                        if (
                            this.req.subject == "mail" &&
                            this.req.mail_type == "application_email"
                        ) {
                            this.page_show = "mail_page";
                        } else if (this.req.subject == "meeting") {
                            this.prospect_id = res.data.data.prospect_id;
                            this.storeWithMeeting();
                        } else {
                            this.prospect_id = res.data.data.prospect_id;
                        }
                        this.storePrediction();
                    }
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_save = false;
                });
        },
        saveMail() {
            this.is_save_mail = true;
            var data = {
                from: this.req.mail_from,
                to: this.req.mail_to,
                cc: this.req.mail_cc,
                subject: this.req.mail_subject,
                content: this.req.mail_content,
                id: this.activity_id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/email/send`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_save_mail = false;
                    this.modalSuccessEmail = true;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_save_mail = false;
                });
        },
        okModalSuccessEmail() {
            this.modalSuccessEmail = false;
            window.location.href = "/crm/prospect/today";
        },
        uploadPhoto(event) {
            var input = event.target;
            this.req.attachment = input.files[0];
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.attachment = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
.money-custom {
    height: 42px;
    font-size: 16px;
    padding-left: 15px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    /* color: rgba(0, 0, 0, 0); */
    position: absolute;
    /* opacity: 1; */
    /* display: block; */
    background: none no-repeat;
    /* border-width: thin; */
    width: 100%;
}
.vertical-align-unset {
    vertical-align: unset;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    border: dashed;
    border-radius: 10px;
    width: 13vw;
    height: 150px;
    padding: 55px;
    position: relative;
}
.photo {
    border-radius: 10px;
    cursor: pointer;
    width: 13vw;
    height: 150px;
    object-fit: cover;
}
.btn-lg {
    width: 150px;
}
input[type="file"] {
    display: none;
}
.upload__btn p i {
    font-size: 30px;
}
.w-mail-title {
    width: 80px;
}
</style>
