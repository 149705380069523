<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center no-flex">
                <h3 class="fw-bold">Daftar Tarik Dana</h3>
            </div>
            <div class="d-flex mt-4">
                <div class="me-3">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Cari nama perusahaan/driver"
                            v-model="req.search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="m-mt-1 ms-3">
                    <button
                        class="btn btn-outline-black btn-filter px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-4 mb-2">
                        <label class="fw-bold mb-1">Jenis Mitra </label>
                        <multiselect
                            v-model="mitra"
                            :options="mitras"
                            label="name"
                            track-by="id"
                            placeholder="Pilih jenis mitra"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4 mb-2">
                        <label class="fw-bold mb-1">Status Transaksi </label>
                        <multiselect
                            v-model="status_transaction"
                            :options="status_transactions"
                            label="name"
                            track-by="id"
                            placeholder="Pilih status transaksi"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4 mb-2">
                        <label class="fw-bold mb-1">Tanggal Pengajuan</label>
                        <date-picker
                            v-model="start_date"
                            range
                            type="date"
                            value-type="format"
                            class="w-100"
                            format="DD MMMM YYYY"
                            :clearable="false"
                            placeholder="Pilih tanggal pengajuan"
                            @change="getStartDate(start_date)"
                        >
                        </date-picker>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-6">
                                <button
                                    class="btn btn-outline-black w-100"
                                    :disabled="
                                        mitra == null &&
                                        status_transaction == null &&
                                        start_date == '' &&
                                        end_date == ''
                                    "
                                    @click="clearFilter()"
                                >
                                    Clear
                                </button>
                            </div>
                            <div class="col-md-6">
                                <button
                                    class="btn btn-green w-100"
                                    :disabled="
                                        mitra == null &&
                                        status_transaction == null &&
                                        start_date == '' &&
                                        end_date == ''
                                    "
                                    @click="filter()"
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div class="table-responsive-custom" v-else>
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">
                                Tanggal Pengajuan
                            </th>
                            <th class="text-center" scope="col">
                                Nomor Transaksi
                            </th>
                            <th class="text-center" scope="col">
                                Nama Perusahaan/Driver
                            </th>
                            <th class="text-center" scope="col">
                                Jumlah Pengajuan Tarik Dana
                            </th>
                            <th class="text-center" scope="col">
                                Status Transaksi
                            </th>
                            <th class="text-center" scope="col">Lampiran</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(data, index) in list.list_data"
                            :key="index"
                        >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">
                                {{
                                    data.requested_at ? data.requested_at : "-"
                                }}
                            </td>
                            <td class="text-center">
                                {{
                                    data.transaction_code
                                        ? data.transaction_code
                                        : "-"
                                }}
                            </td>
                            <td>
                                <a
                                    :href="
                                        `/trawltruck/withdraw/individu/` +
                                        data.transaction_code
                                    "
                                    class="text-link"
                                >
                                    {{ data.name ? data.name : "-" }}
                                </a>
                            </td>
                            <td>
                                <div class="float-end">
                                    {{
                                        currency(data.amount ? data.amount : 0)
                                    }}
                                </div>
                            </td>
                            <td class="text-center">
                                <span
                                    class="tag radius size-12 w-100"
                                    v-bind:class="{
                                        'green-cro': data.status == 'success',
                                        'yellow-cro': data.status == 'pending',
                                    }"
                                >
                                    <template v-if="data.status == 'success'"
                                        >Telah Ditransfer</template
                                    >
                                    <template v-if="data.status == 'pending'"
                                        >Mengajukan</template
                                    >
                                </span>
                            </td>
                            <td class="text-center">
                                <template v-if="data.attachment">
                                    <a
                                        href="javascript:void(0)"
                                        @click="showUploadModal(data)"
                                        class="text-link"
                                    >
                                        <div class="d-flex align-items-center">
                                            <div class="me-1">
                                                <img
                                                    src="../../assets/file-upload.png"
                                                    width="20"
                                                    alt=""
                                                />
                                            </div>
                                            <div>Lihat Bukti Transfer</div>
                                        </div>
                                    </a>
                                </template>
                                <template v-else>-</template>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center mt-5" v-if="list.list_data.length == 0">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalShowImage"></div>
            <div class="modal-body-chat vsm" v-if="modalShowImage">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-new.png" width="60" alt="" />
                    <h4 class="mb-0 fw-bold mt-3">Bukti transfer dana</h4>
                    <div class="mt-4">
                        <label type="file" class="position-relative">
                            <img class="photo" :src="upload_data.attachment" />
                        </label>
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="modalShowImage = false"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        DatePicker,
        Multiselect,
    },
    name: "WithdrawIndividuList",
    data() {
        return {
            moment: moment,
            isFilter: false,
            is_list_ready: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            req: {
                search: "",
            },
            start_date: "",
            end_date: "",
            mitra: null,
            mitras: [
                { name: "Individu", id: "driver" },
                { name: "Corporate", id: "partner" },
            ],
            status_transaction: null,
            status_transactions: [
                { name: "Telah Ditransfer", id: "success" },
                { name: "Mengajukan", id: "pending" },
            ],
            modalShowImage: false,
            upload_data: {},
        };
    },
    created() {
        this.getList();
    },
    computed: {},
    mounted() {},
    methods: {
        getList() {
            this.is_list_ready = true;
            let payload = {
                name: this.req.search,
            };
            if (this.start_date) {
                payload.start_date = moment(this.start_date[0]).format(
                    "DD-MM-YYYY"
                );
                payload.end_date = moment(this.start_date[1]).format(
                    "DD-MM-YYYY"
                );
            }
            if (this.mitra) {
                payload.type = this.mitra?.id;
            }
            if (this.status_transaction) {
                payload.status_trx = this.status_transaction?.id;
            }
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/truck/finance/admin/withdraw/lists`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        clearFilter() {
            this.start_date = "";
            this.end_date = "";
            this.mitra = null;
            this.status_transaction = null;
            this.getList();
        },
        filter() {
            this.getList();
        },
        getStartDate(value) {
            this.start_date = value;
        },
        showUploadModal(data) {
            this.upload_data = data;
            this.modalShowImage = true;
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.table-responsive-custom table tbody tr td {
    white-space: normal;
}
.photo {
    border-radius: 10px;
    cursor: pointer;
    width: 20vw;
    height: 150px;
    object-fit: cover;
    border: 2px solid #dee2e6;
    color: #dee2e6;
}
</style>
