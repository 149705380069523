var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"padding-container"},[_c('h3',{staticClass:"mb-0 fw-bold"},[_vm._v("Dashboard Troben Truk Finance")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mt-4"},[_c('div',{staticClass:"box driver no-shadow"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"fw-bold size-16"},[_vm._v("Total Pendapatan")]),_c('div',{staticClass:"ms-auto text-main-color size-16"},[_vm._v(" "+_vm._s(_vm.moment(_vm.dataFinance.year_month).format( "YYYY MMMM" ))+" ")])]),_c('div',{staticClass:"mt-4"},[_c('h1',{staticClass:"mb-0 fw-bold"},[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.dataFinance.overview.income.this_month ))+" "),_c('span',{staticClass:"size-h1",class:{
                                'text-green-bold':
                                    _vm.dataFinance.overview.income.this_month >
                                    _vm.dataFinance.overview.income.last_month,
                                'text-danger':
                                    _vm.dataFinance.overview.income.this_month <
                                    _vm.dataFinance.overview.income.last_month,
                            }},[_vm._v(" ("+_vm._s(_vm.currencyFormatNoRp( _vm.dataFinance.overview.income.this_month - _vm.dataFinance.overview.income .last_month ))+") ")])])]),_c('div',{staticClass:"mt-3"},[_c('h5',{staticClass:"mb-0 text-main-color"},[_vm._v(" Pendapatan Sebelumnya "+_vm._s(_vm.currencyFormat( _vm.dataFinance.overview.income.last_month ))+" ")])])])]),_c('div',{staticClass:"col-md-6 mt-4"},[_c('div',{staticClass:"box blue-light no-shadow"},[_vm._m(0),_c('div',{staticClass:"mt-4"},[_c('h1',{staticClass:"mb-0 fw-bold"},[_vm._v(" "+_vm._s(_vm.dataFinance.overview.transaction.this_month)+" Transaksi "),_c('span',{staticClass:"size-h1",class:{
                                'text-green-bold':
                                    _vm.dataFinance.overview.transaction
                                        .this_month >
                                    _vm.dataFinance.overview.transaction
                                        .last_month,
                                'text-danger':
                                    _vm.dataFinance.overview.transaction
                                        .this_month <
                                    _vm.dataFinance.overview.transaction
                                        .last_month,
                            }},[_vm._v(" ("+_vm._s(_vm.dataFinance.overview.transaction .this_month - _vm.dataFinance.overview.transaction.last_month)+") ")])])]),_vm._m(1)])]),_c('div',{staticClass:"col-md-6 mt-5"},[_c('div',{staticClass:"box pb-0"},[_vm._m(2),_c('hr'),_c('div',{staticClass:"scroll-small-box"},[(_vm.dataFinance.order_ongoing.length > 0)?[_vm._l((_vm.dataFinance.order_ongoing),function(data,index){return [_c('div',{key:index},[_c('a',{staticClass:"text-dark",attrs:{"href":'/trawltruck/ho/order/detail/' +
                                        data.order_id}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{},[_c('div',{staticClass:"size-16 fw-bold"},[_vm._v(" "+_vm._s(data.partner)+" ")]),_c('div',{staticClass:"mt-2 size-16"},[_vm._v(" Harga Sewa: "),_c('span',{staticClass:"text-green-bold size-16"},[_vm._v(_vm._s(_vm.currencyFormat( data.total_amount )))])])]),_c('div',{staticClass:"ms-auto"},[_c('div',{staticClass:"text-right text-gray-dashboard"},[_vm._v(" Order - "+_vm._s(_vm.moment( data.order_date ).format("DD MMM YYYY"))+" ")])])])]),_c('hr')])]})]:[_vm._m(3)]],2)])]),_c('div',{staticClass:"col-md-6 mt-5"},[_c('div',{staticClass:"box pb-0"},[_vm._m(4),_c('hr'),_c('div',{staticClass:"scroll-small-box"},[(_vm.dataFinance.request_travel_expense.length > 0)?[_vm._l((_vm.dataFinance.request_travel_expense),function(data,index){return [_c('div',{key:index},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{},[_c('div',{staticClass:"size-16 fw-bold text-green-bold"},[_vm._v(" "+_vm._s(data.receipt)+" ")]),_c('div',{staticClass:"mt-2 size-16"},[_vm._v(" "+_vm._s(data.driver)+" - "+_vm._s(data.partner)+" ")])]),_c('div',{staticClass:"ms-auto"},[_c('div',{staticClass:"text-right text-gray-dashboard"},[_vm._v(" "+_vm._s(data.origin_regency)+" - "+_vm._s(data.origin_destination)+" ")]),_c('div',{staticClass:"text-right text-gray-dashboard mt-2"},[_vm._v(" Order - "+_vm._s(_vm.moment( data.order_date ).format("DD MMM YYYY"))+" ")])])]),_c('hr')])]})]:[_vm._m(5)]],2)])]),_c('div',{staticClass:"col-md-12 mt-4"},[_c('div',{staticClass:"box"},[_vm._m(6),_c('hr'),_c('div',{staticClass:"mt-3"},[_c('apexcharts',{ref:"chartmonthly",attrs:{"width":"100%","height":"350","type":"bar","options":_vm.chartOptions,"series":_vm.series}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"fw-bold size-16"},[_vm._v("Total Transaksi")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('h5',{staticClass:"mb-0 text-main-color"},[_vm._v("Jumlah Transaksi")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('h5',{staticClass:"mb-0 fw-bold"},[_vm._v("Transaksi Sedang Berjalan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mb-5 mt-5"},[_c('img',{attrs:{"src":require("../../assets/no-data.png"),"width":"90","alt":""}}),_c('h4',{staticClass:"mb-0 fw-bold mt-4 text-gray"},[_vm._v(" Data Kosong ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('h5',{staticClass:"mb-0 fw-bold"},[_vm._v("Berikan Uang Jalan")]),_c('div',{staticClass:"ms-auto"},[_c('a',{staticClass:"size-14 text-dark",attrs:{"href":"/trawltruck/ho/delivery-payment"}},[_vm._v(" Lihat Semua ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mb-5 mt-5"},[_c('img',{attrs:{"src":require("../../assets/no-data.png"),"width":"90","alt":""}}),_c('h4',{staticClass:"mb-0 fw-bold mt-4 text-gray"},[_vm._v(" Data Kosong ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('h5',{staticClass:"mb-0 fw-bold"},[_vm._v("Grafik Pendapatan Mitra")])])
}]

export { render, staticRenderFns }