<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center no-flex">
                <h3 class="fw-bold">Cek Jadwal Kapal</h3>
                <div class="ms-auto">
                    <div class="d-flex">
                        <div>
                            <a
                                href="/harbors/create"
                                class="btn btn-green btn-with-search px-4"
                            >
                                <img
                                    src="../assets/plus.png"
                                    width="12"
                                    class="me-1"
                                    alt=""
                                />
                                Tambah Jadwal Kapal
                            </a>
                        </div>
                        <div class="me-auto m-mt-1 ms-3">
                            <button
                                class="btn btn-outline-black btn-filter px-4"
                                @click="isFilter = !isFilter"
                            >
                                <img
                                    src="../assets/dashboard/filter.png"
                                    class="me-1"
                                    width="18"
                                    alt=""
                                />
                                Filter
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4"></div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-4">
                        <label class="fw-bold mb-2">Dari</label>
                        <multiselect
                            v-model="origin"
                            :options="origins"
                            label="regency_name"
                            track-by="regency_id"
                            placeholder="Pilik kota keberangkatan"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4">
                        <label class="fw-bold mb-2">Ke</label>
                        <multiselect
                            v-model="destination"
                            :options="destinations"
                            label="regency_name"
                            track-by="regency_id"
                            placeholder="Masukkan kota tujuan"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4">
                        <label class="fw-bold mb-2">Tanggal</label>
                        <date-picker
                            v-model="date"
                            type="date"
                            range
                            value-type="format"
                            class="w-100"
                            format="DD MMMM YYYY"
                            :clearable="false"
                            placeholder="Pilih tanggal"
                        >
                        </date-picker>
                    </div>
                    <div class="col-md-6 mt-3">
                        <button
                            :disabled="
                                origin == null &&
                                destination == null &&
                                date == ''
                            "
                            class="btn btn-outline-black px-5 me-2"
                            @click="clearFilter()"
                        >
                            Clear
                        </button>
                        <button class="btn btn-green px-5" @click="filter()">
                            Cari
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div class="table-responsive-custom" v-else>
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">
                                Nama Kapal
                                <a
                                    href="javascript:void(0)"
                                    id="filter"
                                    data-bs-toggle="dropdown"
                                    data-bs-auto-close="true"
                                    aria-expanded="false"
                                >
                                    <img
                                        src="../assets/filter.png"
                                        width="10"
                                        class="ms-1"
                                        alt=""
                                    />
                                </a>
                                <ul
                                    class="dropdown-menu mt-2"
                                    aria-labelledby="filter"
                                >
                                    <li>
                                        <a
                                            class="dropdown-item"
                                            href="javascript:void(0)"
                                            @click="sortOrder('asc')"
                                        >
                                            A - Z
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            class="dropdown-item"
                                            href="javascript:void(0)"
                                            @click="sortOrder('desc')"
                                        >
                                            Z - A
                                        </a>
                                    </li>
                                </ul>
                            </th>
                            <th class="text-center" scope="col">
                                Kota Keberangkatan
                            </th>
                            <th class="text-center" scope="col">
                                Kota Keberangkatan
                            </th>
                            <th class="text-center" scope="col">
                                Jadwal Closing
                            </th>
                            <th class="text-center" scope="col">
                                Jadwal Keberangkatan
                            </th>
                            <th class="text-center" scope="col">Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(data, index) in list.list_data"
                            :key="index"
                        >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">
                                {{ data.ship_name }}
                            </td>
                            <td class="text-center">
                                {{ data.origin }}
                            </td>
                            <td class="text-center">
                                {{ data.destination }}
                            </td>
                            <td class="text-center">
                                {{
                                    moment(data.closing).format("DD MMMM YYYY")
                                }}
                            </td>
                            <td class="text-center">
                                {{
                                    moment(data.departure).format(
                                        "DD MMMM YYYY"
                                    )
                                }}
                            </td>
                            <td class="text-center">
                                <a
                                    :href="`/harbors/edit/${data.id}`"
                                    class="me-2"
                                >
                                    <img
                                        src="../assets/pencil-icon.png"
                                        width="18"
                                        alt=""
                                    />
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    @click="deleteModal(data.id)"
                                >
                                    <img
                                        src="../assets/trash-icon.png"
                                        width="18"
                                        alt=""
                                    />
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center mt-5" v-if="list.list_data.length == 0">
                    <img src="../assets/maps.png" width="160" alt="" />
                    <h5 class="mb-0 fw-semibold mt-3 mb-4 text-gray">
                        <template
                            v-if="
                                list.list_data.length == 0 && isFilter == true
                            "
                        >
                            Jadwal kapal tidak tersedia
                        </template>
                        <template v-else>
                            Mohon lengkapi kota keberangkatan dan tujuan
                            terlebih dahulu
                        </template>
                    </h5>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="showModalDelete"></div>
            <div class="modal-body-chat vsm" v-if="showModalDelete">
                <div class="mt-4 text-center">
                    <img src="../assets/info-new.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">Jadwal Kapal akan DIhapus</h4>
                    <div class="mt-1 text-gray size-14">
                        Apakah Anda yakin ingin menghapus informasi jadwal kapal
                        ini?
                    </div>
                </div>
                <div class="mt-4 row">
                    <div class="col-md-6">
                        <a
                            href="javascript:void(0)"
                            @click="showModalDelete = false"
                            class="btn btn-outline-primary w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6">
                        <button
                            class="btn btn-green w-100 btn-lg"
                            type="button"
                            disabled
                            v-if="is_delete"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Ya
                        </button>
                        <button
                            @click="deleteList()"
                            class="btn btn-green w-100 btn-lg"
                            v-else
                        >
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="showModalSuccessDelete"></div>
            <div class="modal-body-chat vsm" v-if="showModalSuccessDelete">
                <div class="mt-4 text-center">
                    <img src="../assets/check-new.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Informasi Jadwal Kapal Berhasil Dihapus
                    </h4>
                </div>
                <div class="mt-4">
                    <a href="/harbors" class="btn btn-green w-100 btn-lg">OK</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

export default {
    components: {
        ListLoader,
        Multiselect,
        DatePicker,
    },
    name: "Harbors",
    data() {
        return {
            moment: moment,
            isFilter: false,
            is_list_ready: false,
            list: {
                list_data: [],
            },
            per_page: 10,
            currentPage: 0,
            origin: null,
            origins: [],
            destination: null,
            destinations: [],
            date: "",
            sort_order: "",
            showModalDelete: false,
            id: 0,
            showModalSuccessDelete: false,
            is_delete: false,
        };
    },
    created() {
        this.getList();
        this.getOrigin();
        this.getDestination();
    },
    computed: {},
    methods: {
        getList() {
            this.is_list_ready = true;
            let payload = {
                origin_regency_id: this.origin?.regency_id,
                destination_regency_id: this.destination?.regency_id,
            };
            if (this.date) {
                payload.start = moment(this.date[0]).format("DD-MM-YYYY");
                payload.end = moment(this.date[1]).format("DD-MM-YYYY");
            }
            if (this.sort_order) {
                payload.sort_order = this.sort_order;
            }
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/trawlpack/harbors`, {
                params: payload,
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        getOrigin() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/trawlpack/harbors/regency/origin`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.origins = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getDestination() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/trawlpack/harbors/regency/destination`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.destinations = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        changePage() {
            this.getList();
        },
        clearFilter() {
            this.date = "";
            this.origin = null;
            this.destination = null;
            this.getList();
        },
        filter() {
            this.getList();
        },
        sortOrder(value) {
            this.sort_order = value;
            this.getList();
        },
        deleteModal(id) {
            this.showModalDelete = true;
            this.id = id;
        },
        deleteList() {
            this.is_delete = true;
            let data = {
                id: this.id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/trawlpack/harbors/delete`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_delete = false;
                    this.showModalDelete = false;
                    this.showModalSuccessDelete = true;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_delete = false;
                    this.showModalDelete = false;
                });
        },
    },
};
</script>
<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
</style>
