<template>
    <div class="padding-container">
        <a
            href="/trawltruck/mitra/balance"
            class="text-gray-dashboard size-16 fw-bold"
        >
            <i class="fa fa-angle-left me-2"></i>
            Kembali
        </a>
        <div class="box mt-4">
            <h4 class="mb-0 fw-bold">Detail Saldo Mitra</h4>
            <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                <tr>
                    <td class="ps-0 w-200">Nama Perusahaan/Driver</td>
                    <td class="w-30">:</td>
                    <td class="capitalize">
                        {{ detail.name ? detail.name : "-" }}
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Jenis Mitra</td>
                    <td class="w-30">:</td>
                    <td class="capitalize">
                        <template v-if="detail.type == 'driver'"
                            >Individu</template
                        >
                        <template v-else>Corporate</template>
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Nomor Telepon</td>
                    <td class="w-30">:</td>
                    <td>
                        {{ detail.phone_number ? detail.phone_number : "-" }}
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Total Saldo Dompet</td>
                    <td class="w-30">:</td>
                    <td>
                        {{
                            currency(
                                detail.amount_income ? detail.amount_income : 0
                            )
                        }}
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Total Saldo Top Up</td>
                    <td class="w-30">:</td>
                    <td>
                        {{
                            currency(
                                detail.amount_wallet ? detail.amount_wallet : 0
                            )
                        }}
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Total Saldo</td>
                    <td class="w-30">:</td>
                    <td>
                        {{
                            currency(
                                detail.total_amount ? detail.total_amount : 0
                            )
                        }}
                    </td>
                </tr>
            </table>
        </div>
        <div class="box mt-4">
            <ul class="nav nav-pills mt-4" id="pills-tab" role="tablist">
                <li class="nav-item w-50" role="presentation">
                    <button
                        class="nav-link dashboard left w-100 active"
                        id="pills-wallet-tab"
                        @click="getTabType('wallet')"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-wallet"
                        type="button"
                        role="tab"
                        aria-controls="pills-wallet"
                        aria-selected="true"
                    >
                        Saldo Dompet
                    </button>
                </li>
                <li class="nav-item w-50 m-mt-1" role="presentation">
                    <button
                        class="nav-link dashboard mid w-100"
                        id="pills-topup-tab"
                        @click="getTabType('topup')"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-topup"
                        type="button"
                        role="tab"
                        aria-controls="pills-topup"
                        aria-selected="false"
                    >
                        Saldo Top Up
                    </button>
                </li>
            </ul>
            <div class="mt-3" v-if="type == 'wallet'">
                <button
                    class="btn btn-outline-black btn-filter px-4 me-2"
                    @click="isFilter = !isFilter"
                >
                    <img
                        src="../../assets/dashboard/filter.png"
                        class="me-1"
                        width="18"
                        alt=""
                    />
                    Filter
                </button>
                <button
                    @click="exportDataWallet()"
                    :disabled="is_export_wallet"
                    class="btn btn-green px-4 btn-filter"
                >
                    <i class="fa fa-file-excel-o me-1"></i>
                    <span
                        v-if="is_export_wallet"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    Export
                </button>
                <div class="box mt-3 position-relative" v-if="isFilter">
                    <label class="fw-bold mb-2">Status Transaksi </label>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <multiselect
                                v-model="status"
                                :options="statuss"
                                label="name"
                                track-by="id"
                                placeholder="Pilih status transaksi"
                                class="multi-form-custom"
                            >
                            </multiselect>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <button
                                        class="btn btn-outline-black w-100"
                                        @click="clearFilter()"
                                    >
                                        Clear
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button
                                        class="btn btn-green w-100"
                                        :disabled="status == null"
                                        @click="filter()"
                                    >
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4" v-if="is_list_ready_wallet">
                    <ListLoader />
                </div>
                <div class="table-responsive-custom" v-else>
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Tanggal & Waktu
                                </th>
                                <th class="text-center" scope="col">
                                    Nomor Resi
                                </th>
                                <th class="text-center" scope="col">
                                    Nomor Transaksi
                                </th>
                                <th class="text-center" scope="col">
                                    Status Transaksi
                                </th>
                                <th class="text-center" scope="col">
                                    Pembayaran Customer
                                </th>
                                <th class="text-center" scope="col">
                                    Total Pencairan
                                </th>
                                <th class="text-center" scope="col">
                                    Status Uang Jalan
                                </th>
                                <th class="text-center" scope="col">
                                    Nominal Uang Jalan
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(data, index) in list.list_data"
                                :key="index"
                            >
                                <td>{{ index + 1 }}</td>
                                <td>
                                    {{
                                        data.created_at ? data.created_at : "-"
                                    }}
                                </td>
                                <td>
                                    {{
                                        data.receipt_code
                                            ? data.receipt_code
                                            : "-"
                                    }}
                                </td>
                                <td>
                                    {{
                                        data.transaction_code
                                            ? data.transaction_code
                                            : "-"
                                    }}
                                </td>
                                <td class="text-center capitalize">
                                    <span
                                        class="tag radius size-12 w-100"
                                        v-bind:class="{
                                            'yellow-cro':
                                                data.status == 'requested',
                                            'blue-cro':
                                                data.status == 'accepted',
                                            'green-cro':
                                                data.status == 'transferred',
                                            'red-cro': data.status == 'expired',
                                        }"
                                    >
                                        <template
                                            v-if="data.status == 'requested'"
                                        >
                                            Requested
                                        </template>
                                        <template
                                            v-if="data.status == 'accepted'"
                                        >
                                            Approved
                                        </template>
                                        <template
                                            v-if="data.status == 'transferred'"
                                        >
                                            Transferred
                                        </template>
                                        <template
                                            v-if="data.status == 'expired'"
                                        >
                                            Expired
                                        </template>
                                    </span>
                                </td>
                                <td>
                                    <div class="float-end">
                                        {{
                                            currency(
                                                data.customer_payment
                                                    ? data.customer_payment
                                                    : 0
                                            )
                                        }}
                                    </div>
                                </td>
                                <td>
                                    <div class="float-end">
                                        {{
                                            currency(
                                                data.total_disburse
                                                    ? data.total_disburse
                                                    : 0
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="text-center capitalize">
                                    <span
                                        class="tag radius size-12 w-100"
                                        v-bind:class="{
                                            'red-cro':
                                                data.status_transport ==
                                                'no-transport',
                                            'green-cro':
                                                data.status_transport ==
                                                'transport',
                                        }"
                                    >
                                        <template
                                            v-if="
                                                data.status_transport ==
                                                'no-transport'
                                            "
                                        >
                                            Tidak Ada
                                        </template>
                                        <template v-else>
                                            Transferred
                                        </template>
                                    </span>
                                </td>
                                <td>
                                    <div class="float-end">
                                        {{
                                            currency(
                                                data.amount_transport
                                                    ? data.amount_transport
                                                    : 0
                                            )
                                        }}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="text-center mt-5"
                        v-if="list.list_data.length == 0"
                    >
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </div>
            </div>
            <div class="mt-3" v-if="type == 'topup'">
                <button
                    class="btn btn-outline-black btn-filter px-4 me-2"
                    @click="isFilterTopup = !isFilterTopup"
                >
                    <img
                        src="../../assets/dashboard/filter.png"
                        class="me-1"
                        width="18"
                        alt=""
                    />
                    Filter
                </button>
                <button
                    @click="exportDataTopup()"
                    :disabled="is_export_topup"
                    class="btn btn-green px-4 btn-filter"
                >
                    <i class="fa fa-file-excel-o me-1"></i>
                    <span
                        v-if="is_export_topup"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    Export
                </button>
                <div class="box mt-3 position-relative" v-if="isFilterTopup">
                    <label class="fw-bold mb-2">Status Transaksi </label>
                    <div class="row">
                        <div class="col-md-4 mb-2">
                            <multiselect
                                v-model="status_type"
                                :options="status_types"
                                label="name"
                                track-by="id"
                                placeholder="Pilih jenis transaksi"
                                class="multi-form-custom"
                                @input="changeStatusTransactions"
                            >
                            </multiselect>
                        </div>
                        <div class="col-md-4 mb-2">
                            <multiselect
                                v-model="status_transaction"
                                :options="status_transactions"
                                label="name"
                                track-by="id"
                                placeholder="Pilih status transaksi"
                                class="multi-form-custom"
                            >
                            </multiselect>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-6">
                                    <button
                                        class="btn btn-outline-black w-100"
                                        @click="clearFilterTopup()"
                                    >
                                        Clear
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button
                                        class="btn btn-green w-100"
                                        @click="filterTopup()"
                                    >
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4" v-if="is_list_ready_topup">
                    <ListLoader />
                </div>
                <div class="table-responsive-custom" v-else>
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Tanggal & Waktu
                                </th>
                                <th class="text-center" scope="col">
                                    Jenis Transaksi
                                </th>
                                <th class="text-center" scope="col">
                                    Nomor Transaksi
                                </th>
                                <th class="text-center" scope="col">Nominal</th>
                                <th class="text-center" scope="col">
                                    Status Transaksi
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(data, index) in list_topup.list_data"
                                :key="index"
                            >
                                <td>{{ index + 1 }}</td>
                                <td class="text-center">
                                    {{
                                        data.requested_at
                                            ? data.requested_at
                                            : "-"
                                    }}
                                </td>
                                <td class="text-center">
                                    <span
                                        class="tag radius size-12 px-4"
                                        v-bind:class="{
                                            'green-cro':
                                                data.trx_type == 'Topup',
                                            'yellow-cro':
                                                data.trx_type == 'Order Cash',
                                            'blue-cro':
                                                data.trx_type == 'Tarik Dana',
                                        }"
                                    >
                                        {{
                                            data.trx_type ? data.trx_type : "-"
                                        }}
                                    </span>
                                </td>
                                <td class="text-center">
                                    {{
                                        data.transaction_code
                                            ? data.transaction_code
                                            : "-"
                                    }}
                                </td>
                                <td class="text-center capitalize">
                                    <div class="float-end">
                                        {{
                                            currency(
                                                data.amount ? data.amount : 0
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="text-center">
                                    <span
                                        class="tag radius size-12 px-4"
                                        v-bind:class="{
                                            'red-cro': data.status == 'expired',
                                            'green-cro':
                                                data.status == 'success',
                                            'blue-cro':
                                                data.status == 'process',
                                            'yellow-cro':
                                                data.status == 'pending',
                                        }"
                                    >
                                        <template
                                            v-if="data.status == 'expired'"
                                        >
                                            Gagal
                                        </template>
                                        <template
                                            v-if="data.status == 'success'"
                                        >
                                            Berhasil
                                        </template>
                                        <template
                                            v-if="
                                                data.status == 'process' &&
                                                data.trx_type == 'Tarik Dana'
                                            "
                                        >
                                            Disetujui
                                        </template>
                                        <template
                                            v-if="
                                                data.status == 'pending' &&
                                                data.trx_type == 'Topup'
                                            "
                                        >
                                            Menunggu Pembayaran
                                        </template>
                                        <template
                                            v-if="
                                                data.status == 'pending' &&
                                                data.trx_type != 'Topup'
                                            "
                                            >Mengajukan
                                        </template>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="text-center mt-5"
                        v-if="list_topup.list_data.length == 0"
                    >
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";

export default {
    components: { Multiselect, ListLoader },
    name: "mitraBalanceDetail",
    data() {
        return {
            detail: {},
            type: "wallet",
            is_list_ready_wallet: false,
            list: {
                list_data: [],
            },
            list_topup: {
                list_data: [],
            },
            is_list_ready_topup: false,
            status: null,
            statuss: [
                { name: "Requested", id: "requested" },
                { name: "Approved", id: "accepted" },
                { name: "Transferred", id: "transferred" },
            ],
            isFilter: false,
            status_type: null,
            status_types: [
                { name: "Order Cash", id: "deduction" },
                { name: "Top Up", id: "topup" },
                { name: "Tarik Dana", id: "withdraw" },
            ],
            status_transaction: null,
            status_transactions: [
                { name: "Menunggu Pembayaran", id: "pending" },
                { name: "Mengajukan", id: "pending" },
                { name: "Disetujui", id: "process" },
                { name: "Berhasil", id: "success" },
                { name: "Gagal", id: "expired" },
            ],
            moment: moment,
            isFilterTopup: false,
            is_export_wallet: false,
            is_export_topup: false,
        };
    },
    created() {
        this.getDetail();
        this.getListWallet();
        this.getListTopup();
    },
    computed: {},
    methods: {
        getDetail() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/truck/finance/admin/wallet/detail?id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detail = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getTabType(value) {
            this.type = value;
        },
        getListWallet() {
            this.is_list_ready_wallet = true;
            let payload = {
                id: this.$route.params.id,
            };
            if (this.status) {
                payload.status = this.status?.id;
            }
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/truck/finance/admin/wallet/transaction?type=income`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready_wallet = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready_wallet = false;
                });
        },
        getListTopup() {
            this.is_list_ready_topup = true;
            let payload = {
                id: this.$route.params.id,
            };
            if (this.status_type) {
                payload.trx_type = this.status_type?.id;
            }
            if (this.status_transaction) {
                payload.status = this.status_transaction?.id;
            }
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/truck/finance/admin/wallet/transaction?type=wallet`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list_topup = data;
                    this.is_list_ready_topup = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready_topup = false;
                });
        },
        filter() {
            this.getListWallet();
        },
        clearFilter() {
            this.status = null;
            this.getListWallet();
        },
        filterTopup() {
            this.getListTopup();
        },
        clearFilterTopup() {
            this.status_type = null;
            this.status_transaction = null;
            this.getListTopup();
        },
        changeStatusTransactions() {
            if (this.status_type?.id == "topup") {
                this.status_transactions = [
                    { name: "Menunggu Pembayaran", id: "pending" },
                    { name: "Disetujui", id: "process" },
                    { name: "Berhasil", id: "success" },
                    { name: "Gagal", id: "expired" },
                ];
            }
            if (this.status_type?.id == "withdraw") {
                this.status_transactions = [
                    { name: "Mengajukan", id: "pending" },
                    { name: "Disetujui", id: "process" },
                    { name: "Berhasil", id: "success" },
                ];
            }
            if (this.status_type?.id == "deduction") {
                this.status_transactions = [
                    { name: "Berhasil", id: "success" },
                    { name: "Gagal", id: "expired" },
                ];
            }
            this.status_transaction = null;
        },
        exportDataWallet() {
            this.is_export_wallet = true;
            let payload = {
                id: this.$route.params.id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/truck/finance/admin/wallet/transaction/export?type=income`,
                "",
                {
                    responseType: "blob",
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data_excel_request = res.data;
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        "Saldo Dompet" +
                            " " +
                            moment().format("DD MMM YYYY") +
                            `.xls`
                    );
                    document.body.appendChild(link);
                    link.click();
                    this.is_export_wallet = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_export_wallet = false;
                });
        },
        exportDataTopup() {
            this.is_export_topup = true;
            let payload = {
                id: this.$route.params.id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/truck/finance/admin/wallet/transaction/export?type=wallet`,
                "",
                {
                    responseType: "blob",
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data_excel_request = res.data;
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        "Saldo Top Up" +
                            " " +
                            moment().format("DD MMM YYYY") +
                            `.xls`
                    );
                    document.body.appendChild(link);
                    link.click();
                    this.is_export_topup = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_export_topup = false;
                });
        },
    },
};
</script>

<style scoped>
.text-gray-dashboard {
    color: #61616a;
}
.w-200 {
    width: 200px;
}
.w-30 {
    width: 30px;
}
.w-table-modal {
    width: 160px;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    border: dashed;
    border-radius: 10px;
    width: 100%;
    height: 150px;
    padding: 55px;
    position: relative;
}
.photo {
    border-radius: 10px;
    cursor: pointer;
    width: 20vw;
    height: 150px;
    object-fit: cover;
    border: dashed;
    color: #dee2e6;
}
.photo-show {
    border-radius: 10px;
    cursor: pointer;
    width: 20vw;
    height: 150px;
    object-fit: cover;
    border: 2px solid #dee2e6;
    color: #dee2e6;
}
input[type="file"] {
    display: none;
}
.upload__btn p img {
    cursor: pointer;
    width: 28px;
    font-size: 14px;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.pill {
    display: inline-block;
    width: 110px;
    height: 24px;
    border-radius: 40px;
    text-align: center;
    color: white;
    &.red {
        background-color: #e60013;
        border: 1px solid #e60013;
        outline: 1px solid #e60013;
    }
    &.gray {
        color: #000 !important;
        background-color: #f4f6f8;
        border: 1px solid #f4f6f8;
        outline: 1px solid #f4f6f8;
    }
    &.green {
        background-color: #3d8824;
        border: 1px solid #3d8824;
        outline: 1px solid #3d8824;
    }
    &.yellow {
        background-color: #fb9727;
        border: 1px solid #fb9727;
        outline: 1px solid #fb9727;
    }
    &.blue {
        background-color: #48a2d4;
        border: 1px solid #48a2d4;
        outline: 1px solid #48a2d4;
    }
}
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}
.nav-link.dashboard.mid {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.nav-link.dashboard.active {
    background-color: #3d8824 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
}
</style>
