<template>
    <div class="padding-container">
        <div class="content-load" v-if="loading_data"></div>
        <div id="loader" v-if="loading_data"></div>
        <div class="fw-bold">
            <a
                :href="
                    `/truck/commission/sticker-ga/detail/` + $route.params.id
                "
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <h3 class="mb-0 fw-bold">Tolak Klaim Komisi</h3>
            <h5 class="mt-3 mb-0 fw-bold">Checklist Sheet</h5>
            <div class="text-gray-dashboard">
                Mohon tandai berkas driver yang tidak sesuai
            </div>

            <div class="row">
                <div class="col-md-6 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="verification_video"
                            v-model="req.verification_video"
                            :true-value="false"
                            :false-value="true"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="verification_video"
                        >
                            Video Kendaraan
                        </label>
                        <ul>
                            <li>
                                <div class="mt-2">
                                    <template v-if="detail.url_video">
                                        <video
                                            controls
                                            class="video"
                                            v-if="detail.url_video"
                                        >
                                            <source
                                                :src="detail.url_video"
                                                type="video/mp4"
                                            />
                                        </video>
                                        <a
                                            class="btn btn-outline-success btn-sm m-2"
                                            :href="detail.url_video"
                                            download
                                            target="_blank"
                                        >Link Video</a>
                                    </template>
                                    <img
                                        class="photo"
                                        src="../../assets/empty-image.png"
                                        v-else
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="verification_photo"
                            v-model="req.verification_photo"
                            :true-value="false"
                            :false-value="true"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="verification_photo"
                        >
                            Foto KM Kendaraan
                        </label>
                        <ul>
                            <li>
                                <div class="mt-2">
                                    <template v-if="detail.url_photo">
                                        <viewer :images="[]" :options="options">
                                            <img
                                                class="photo"
                                                :key="detail.url_photo"
                                                :src="detail.url_photo"
                                            />
                                        </viewer>
                                    </template>
                                    <img
                                        class="photo"
                                        src="../../assets/empty-image.png"
                                        v-else
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="verification_km"
                            v-model="req.verification_km"
                            :true-value="false"
                            :false-value="true"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="verification_km"
                        >
                            Kilometer Saat Ini
                        </label>
                        <ul>
                            <li>
                                <div class="input-group mt-2">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Kilometer saat ini"
                                        disabled
                                        :Value="detail.km_current"
                                    />
                                    <span class="input-group-text"> Km </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="form-check-label fw-semibold">
                        Alasan Penolakan
                    </label>
                    <div class="mt-2">
                        <textarea
                            cols="30"
                            rows="4"
                            class="form-control"
                            placeholder="Tuliskan alasan penolakan klaim komisi"
                            v-model="req.note"
                        ></textarea>
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <a
                        :href="
                            `/truck/commission/sticker-ga/detail/` +
                            $route.params.id
                        "
                        class="btn btn-outline-red btn-lg px-5 me-3"
                    >
                        Batal
                    </a>
                    <button
                        class="btn btn-green btn-lg px-5"
                        :disabled="
                            (req.verification_video == true &&
                                req.verification_photo == true &&
                                req.verification_km == true) ||
                            req.note == ''
                        "
                        @click="modalConfirmationReject = true"
                    >
                        Simpan
                    </button>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalConfirmationReject"></div>
            <div class="modal-body-chat vsm" v-if="modalConfirmationReject">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">Klaim Komisi Akan Ditolak</h4>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menolak pengajuan komisi ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirmationReject = false"
                            class="btn btn-outline-red w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-green w-100 btn-lg"
                            :disabled="is_reject"
                            @click="storeReject()"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_reject"
                            ></span>
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSucess"></div>
            <div class="modal-body-chat vsm" v-if="modalSucess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Klaim Komisi Sudah Ditolak
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        :href="`/truck/commission/sticker-ga`"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";

export default {
    components: {
        Viewer,
    },
    name: "TruckCommissionStickerGaReject",
    data() {
        return {
            detail: {},
            loading_data: false,
            req: {
                verification_video: true,
                verification_photo: true,
                verification_km: true,
                note: "",
            },
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
            modalConfirmationReject: false,
            is_reject: false,
            modalSucess: false,
        };
    },
    created() {
        this.getDetail();
    },
    computed: {},
    methods: {
        getDetail() {
            this.loading_data = true;
            var payload = {
                claim_id: this.$route.params.id,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/truck/head-office/claim/detail`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detail = res.data.data;
                    this.loading_data = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.loading_data = false;
                });
        },
        storeReject() {
            var data = {
                claim_id: this.$route.params.id,
                verification_video: this.req.verification_video,
                verification_photo: this.req.verification_photo,
                verification_km: this.req.verification_km,
                note: this.req.note,
            };
            this.is_reject = true;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/truck/head-office/claim/reject`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_reject = false;
                    this.modalConfirmationReject = false;
                    this.modalSucess = true;
                })
                .catch((err) => {
                    this.is_reject = false;
                    this.modalConfirmationReject = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.photo {
    border-radius: 10px;
    width: 100%;
    height: 200px;
    object-fit: cover;
    border: 1px solid #e9ecef;
    cursor: pointer;
}
.video {
    border-radius: 10px;
    width: 100%;
    height: 200px;
    border: 1px solid #e9ecef;
}
.checkbox input[type="checkbox"] {
    display: none;
}
.form-check-input {
    display: none !important;
}
.form-check label {
    padding-left: 0;
    cursor: pointer;
}

.form-check label:before {
    content: "";
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px;
    line-height: 17px;
    text-align: center;
    border: 1px solid #ccc;
    background: #fafafa;
    border-radius: 3px;
    font-family: "FontAwesome";
    cursor: pointer;
}
.form-check input[type="checkbox"]:checked + label::before {
    content: "\f00d";
    background: #faccd0;
    color: #e60013;
    border: 1px solid #e60013;
}
ul {
    list-style-type: none;
}
</style>
