<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="d-flex align-items-center bd-highlight">
            <div class="flex-grow-1 bd-highlight">
                <h3 class="fw-bold">
                    List Penugasan
                </h3>
            </div>
            <div class="bd-highlight">
                <a href="javascript:void(0)" @click="modalAssignDriver = true" class="btn btn-green btn-lg px-5">
                    Tambah Penugasan
                </a>
            </div>
        </div>
        <div class="box mt-4">
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader/>
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-md-5 mt-3">
                        <div class="position-relative">
                            <input type="text" class="form-control" placeholder="Cari driver" v-model="req.keyword" />
                            <span class="search-icon">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="modern-table-boxed mt-4" v-if="searchLists.length > 0">
                    <div class="d-table-row header">
                        <div class="d-table-cell">No</div>
                        <div class="d-table-cell">Nama Driver</div>
                        <div class="d-table-cell">Armada</div>
                        <div class="d-table-cell">No Polisi</div>
                        <div class="d-table-cell">Tahun Kendaraan</div>
                    </div>
                    <template v-for="(list, index) in searchLists">
                        <div class="d-table-row" :key="index">
                            <div class="d-table-cell fw-bold">
                                {{ index+1 }}.
                            </div>
                            <div class="d-table-cell">
                                {{ list.registration_name }}
                            </div>
                            <div class="d-table-cell capitalize">
                                {{ (list.type || "").split("_").join(" ") }}
                            </div>
                            <div class="d-table-cell limit-one-line">
                                {{ list.registration_number }}
                            </div>
                            <div class="d-table-cell">
                                {{ list.registration_year }}
                            </div>
                        </div>
                    </template>
                </div>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="">
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalAssignDriver"></div>
            <div class="modal-body-chat sm" v-if="modalAssignDriver">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            Tambah Penugasan
                        </div>
                    </div>
                    <div class="bd-highlight">
                        <a href="javascript:void(0)" @click="modalAssignDriver = false" class="size-20 text-primary">
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </div>
                </div>
                <div>
                    Pilih Driver dan Tugaskan
                </div>
                <div class="mt-4">
                    <multiselect
                        v-model="req.driver"
                        :options="list.drivers"
                        label="name"
                        track-by="user_id"
                        placeholder="Pilih driver yang tersedia"
                    >
                    </multiselect>
                    <div class="mt-3">
                        <multiselect
                            v-model="req.vehicle"
                            :options="list.vehicles"
                            label="registration_name"
                            track-by="transporter_id"
                            placeholder="Pilih kendaraan"
                        >
                        </multiselect>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-5">
                        <button class="btn btn-green px-5 btn-md me-3" type="button" disabled v-if="is_assign">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Update
                        </button>
                        <button class="btn btn-green w-100 btn-md me-3" @click="assignDriver()" :disabled="req.driver == null || req.vehicle == null" v-else>
                            Simpan
                        </button>
                    </div>
                    <div class="col-md-6 mt-5">
                        <a href="javascript:void(0)" class="btn btn-outline-black btn-md w-100" @click="modalAssignDriver = false">
                            Batal
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from '../../components/ListLoader.vue';
import 'vue-search-select/dist/VueSearchSelect.css'
import Multiselect from 'vue-multiselect'

export default {
    components: {
        ListLoader,
        Multiselect
    },
    name: "Assignment",
    data() {
        return {
            is_list_ready: false,
            req: {
                keyword: '',
                driver: null,
                vehicle: null,
            },
            list: {
                drivers: [],
                vehicles: [],
            },
            modalAssignDriver: false,
            is_assign: false
        };
    },
    created() {
        this.getAssignmentList()
    },
    mounted() {
    },
    computed: {
        searchLists() {
            return this.list.vehicles.filter((item) => {
                return item.registration_name.toLowerCase().includes(this.req.keyword.toLowerCase());
            });
        }
    },
    methods: {
        getAssignmentList() {
            this.is_list_ready = true
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/vehicle/assignation`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                var data = res.data.data
                this.list = data
                this.is_list_ready = false
            })
            .catch((err) => {
                console.log(err);
                this.is_list_ready = false
            });
        },
        assignDriver(){
            this.is_assign = true
            var data = {
                user_id: this.req.driver.user_id,
                transporter_id: this.req.vehicle.transporter_id
            }
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/carrier/partner/vehicle/assignation`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            })
            .then((res) => {
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Success',
                    text: 'Berhasil ditambahkan'
                });
                this.getAssignmentList()
                this.modalAssignDriver = false
                this.is_assign = false
                this.req.driver = null
                this.req.vehicle = null
                console.log(res)
            })
            .catch((err) => {
                this.modalAssignDriver = false
                this.is_assign = false
                console.log(err)
            })
        }
    },
};
</script>

<style scoped></style>