<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/trawltruck/ho/fleet" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">
                    Ubah Data Truk
                </h3>
            </div>
            <div class="row mt-5 m-mt-2">
                <div class="col-md-12">
                    <label class="fw-bold mb-2">Jenis Truk</label>
                    <select class="form-control form-select" v-model="req.fleet_type" disabled>
                        <option value="">Pilih Jenis Truk</option>
                        <template v-for="(fleet, index) in fleet_types">
                            <option :value="fleet.value" :key="index">
                                {{ fleet.label }}
                            </option>
                        </template>
                    </select>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Keterangan Truk</label>
                    <textarea name="" id="" cols="30" rows="4" placeholder="Masukkan  Keterangan Truk (Merk/Model Truk)" class="form-control" v-model="req.fleet_model"></textarea>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nama Mitra (Kode)</label>
                    <select class="form-control form-select" v-model="req.partner_id" disabled>
                        <option value="">Pilih Mitra</option>
                        <template v-for="(mitra, index) in mitras">
                            <option :value="mitra.id" :key="index">
                                {{ mitra.name }}
                            </option>
                        </template>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Tahun Kendaraan</label>
                    <input type="number" class="form-control" placeholder="Masukkan Tahun Kendaraan" v-model="req.fleet_year">
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nomor Polisi</label>
                    <input type="text" class="form-control" placeholder="Masukkan Nomor Polisi" v-model="req.fleet_number">
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Nomor Rangka</label>
                    <input type="text" class="form-control" placeholder="Masukkan Nomor Rangka" v-model="req.fleet_identity">
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Status</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="accept" value="accept" v-model="req.status">
                            <label class="form-check-label fw-bold" for="accept">Aktif</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="reject" value="reject" v-model="req.status">
                            <label class="form-check-label fw-bold" for="reject">Tidak Aktif</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold">Foto Truk</label>
                    <div class="text-gray mb-2">
                        Foto full body truk maks 5MB
                    </div>
                    <div class="image-view-1" :style="{'background-image': 'url(' + (req.fleet_picture_url) + ')'}">
                        <div class="mt-4 ml-4">
                            <label for="fleet_picture" class="upload-button single-image"> 
                                <i class="fa fa-upload"></i>
                                <input type="file" id="fleet_picture" @change="uploadTRUCK()">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold">Foto BPKB</label>
                    <div class="text-gray mb-2">
                        Foto full BPKB maks 5MB
                    </div>
                    <div class="image-view-2" :style="{'background-image': 'url(' + (req.ownership_picture_url) + ')'}">
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold">Foto STNK</label>
                    <div class="text-gray mb-2">
                        Full STNK maks 5MB
                    </div>
                    <div class="image-view-3" :style="{'background-image': 'url(' + (req.registration_picture_url) + ')'}">
                    </div>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <a href="/trawltruck/ho/fleet" class="btn btn-outline-black btn-lg px-5 me-3 m-w-100">Batal</a>
                            <button class="btn btn-green btn-lg px-5 me-3 m-w-100 m-mt-1" type="button" disabled v-if="is_save">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Update
                            </button>
                            <button class="btn btn-green btn-lg px-5 me-3 m-w-100 m-mt-1" @click="updateData()" v-else>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img src="../../assets/dashboard/checklis.png" width="80" alt="">
                    <h4 class="mb-0 fw-bold mt-4">
                        Perubahan Data Berhasil!
                    </h4>
                    <div class="mt-2">
                        Selamat! Anda berhasil merubah data.
                    </div>
                </div>
                <div class="mt-4">
                    <a href="/trawltruck/ho/fleet" class="btn btn-green w-100 btn-lg">
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery'
    import Api from "../../api/Api";

    export default {
        props: "",
        name: "ManagementTruckEdit",
        data() {
            return {
                showPassword: false,
                is_changing_fleet_picture: false,
                req: {},
                modalSuccess: false,
                modalError: false,
                mitras: [],
                fleet_types: [
                    {
                        id: 1,
                        value: 'blind-van',
                        label: 'Blind Van',
                    },
                    {
                        id: 2,
                        value: 'pickup-bak',
                        label: 'Pickup Bak',
                    },
                    {
                        id: 3,
                        value: 'pickup-box',
                        label: 'Pickup Box',
                    },
                    {
                        id: 4,
                        value: 'cde-bak',
                        label: 'Cde Bak',
                    },
                    {
                        id: 5,
                        value: 'cde-box',
                        label: 'Cde Box',
                    },
                    {
                        id: 6,
                        value: 'cdelong-box',
                        label: 'Cdelong Box',
                    },
                    {
                        id: 7,
                        value: 'cdd-bak',
                        label: 'Cdd Bak',
                    },
                    {
                        id: 8,
                        value: 'cdd-box',
                        label: 'Cdd Box',
                    },
                    {
                        id: 9,
                        value: 'cdd-reefer',
                        label: 'Cdd Reefer',
                    },
                    {
                        id: 10,
                        value: 'cddlong-bak',
                        label: 'Cddlong Bak',
                    },
                    {
                        id: 11,
                        value: 'cddlong-box',
                        label: 'Cddlong Box',
                    },{
                        id: 12,
                        value: 'fuso-bak',
                        label: 'Fuso Bak',
                    },
                    {
                        id: 13,
                        value: 'fuso-box',
                        label: 'Fuso Box',
                    },
                    {
                        id: 14,
                        value: 'tronton-bak',
                        label: 'Tronton Bak',
                    },
                    {
                        id: 15,
                        value: 'tronton-box',
                        label: 'Tronton Box',
                    },
                    {
                        id: 16,
                        value: 'wingbox',
                        label: 'Wingbox',
                    },
                    {
                        id: 17,
                        value: 'towing',
                        label: 'Towing',
                    },
                    {
                        id: 18,
                        value: 'selfloader',
                        label: 'Selfloader',
                    },
                    {
                        id: 19,
                        value: 'trailer-20feet',
                        label: 'Trailer 20feet',
                    },
                    {
                        id: 20,
                        value: 'trailer-40feet',
                        label: 'Trailer 40feet',
                    },
                    {
                        id: 21,
                        value: 'lowbed',
                        label: 'Lowbed',
                    },

                ],
                is_save: false
            };
        },
        created(){
            this.getMitra()
            this.getDetail()
        },
        methods: {
            toggleShow() {
                this.showPassword = !this.showPassword;
            },
            uploadTRUCK() {
                var fleet_picture = new FileReader();
                fleet_picture.onload = function (e) {
                    $('.image-view-1').css('background-image', 'url('+ e.target.result + ')')
                };
                fleet_picture.readAsDataURL($('#fleet_picture')[0].files[0]);
                this.is_changing_fleet_picture = true
            },
            getDetail() {
                Api.get(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/fleet/detail?id=${this.$route.params.id}`, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    var data = res.data.data
                    this.req = data
                })
                .catch((err) => {
                    console.log(err);
                });
            },
            getMitra() {
                Api.get(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/partner/list`, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    var data = res.data.data
                    this.mitras = data
                })
                .catch((err) => {
                    console.log(err);
                });
            },
            updateData(){
                var fleet_picture = $('#fleet_picture')[0].files[0]
                var data = new FormData()

                data.append('id', this.req.id)
                data.append('status', this.req.status)
                data.append('fleet_model', this.req.fleet_model)
                data.append('fleet_number', this.req.fleet_number)
                data.append('fleet_identity', this.req.fleet_identity)
                data.append('fleet_year', this.req.fleet_year)

                if(this.is_changing_fleet_picture) {
                    data.append('fleet_picture',fleet_picture)
                }

                this.is_save = true

                Api.post(`${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/fleet/edit`, data, {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    }
                })
                .then((res) => {
                    this.is_save = false
                    this.modalSuccess = true
                    this.is_changing_fleet_picture = false
                    this.is_changing_registration_picture = false
                    this.is_changing_ownership_picture = false
                    console.log(res)
                })
                .catch((err) => {
                    this.is_save = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.message
                    });
                })
            }
        }
    };
</script>

<style scoped>
    .text-main-color{
        color: #24516A !important;
    }
    .text-gray-dashboard{
        color: #61616A;
    }
</style>