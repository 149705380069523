<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_loading"></div>
        <div id="loader" v-if="is_loading"></div>
        <notifications group="foo" position="top right" />
        <h3 class="fw-bold">Buat Pesanan</h3>
        <div class="box mt-5">
            <div class="row">
                <div class="col-md-12">
                    <div class="box no-shadow bc-gray p-3">
                        <h5 class="mb-0 fw-bold">
                            <i class="fa fa-rocket me-2"></i>
                            Pengirim
                        </h5>
                    </div>
                </div>
                <div
                    class="mt-4"
                    :class="user.is_ho == true ? 'col-md-7' : 'col-md-12'"
                >
                    <div class="row">
                        <div class="col-md-6">
                            <label class="fw-bold mb-3">Nomor Telepon</label>
                            <div class="input-group mb-3">
                                <input
                                    type="number"
                                    class="form-control"
                                    placeholder="Nomor pengirim"
                                    aria-describedby="button-addon2"
                                    v-model="sender.phone_number"
                                />
                                <button
                                    class="btn btn-outline-secondary"
                                    type="button"
                                    id="button-addon2"
                                    @click="searchPhoneNumber()"
                                >
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="fw-bold mb-3">Nama Pengirim</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="data_phone.name"
                                placeholder="Nama pengirim"
                                disabled
                                v-if="is_customer"
                            />
                            <input
                                type="text"
                                class="form-control"
                                v-model="sender.sender_name"
                                placeholder="Nama pengirim"
                                v-else
                            />
                        </div>
                        <div class="col-md-6 mt-3" v-if="user.is_ho">
                            <label class="fw-bold mb-3"
                                >Kota/Kabupaten/Kecamatan</label
                            >
                            <model-list-select
                                :list="geoLists"
                                option-value="sub_district_id"
                                option-text="label"
                                v-model="geoListSetItems"
                                :placeholder="senderValuePlaceholder"
                                @searchchange="searchGeoList"
                            >
                            </model-list-select>
                        </div>
                        <div class="col-md-6 mt-3" v-if="user.is_ho">
                            <label class="fw-bold mb-3">Mitra</label>
                            <model-list-select
                                :list="partners"
                                v-model="mitra_item"
                                option-value="code"
                                option-text="code"
                                :isDisabled="sender.destination == ''"
                                placeholder="Pilih mitra"
                            >
                            </model-list-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 mt-4" v-if="user.is_ho">
                    <label class="fw-bold mb-3">Alamat Pengirim</label>
                    <textarea
                        class="form-control"
                        placeholder="Masukkan alamat pengirim"
                        v-model="sender.note"
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                    ></textarea>
                </div>
            </div>
        </div>

        <div v-for="(receiver, index) in receivers" :key="index">
            <div class="box mt-5">
                <div class="row">
                    <div class="col-md-12">
                        <div class="box no-shadow bc-gray p-3">
                            <div class="d-flex align-items-center">
                                <h5 class="mb-0 fw-bold">
                                    <i class="fa fa-cube me-2"></i>
                                    Penerima {{ index + 1 }}
                                </h5>
                                <div
                                    class="ms-auto"
                                    v-if="receivers.length > 1"
                                >
                                    <a
                                        href="javascript:void(0)"
                                        class="btn btn-primary px-4"
                                        @click="deleteRereceiver(index)"
                                    >
                                        Hapus Penerima
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 mt-4">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="fw-bold mb-3"
                                    >Nama Penerima</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Masukkan nama penerima"
                                    v-model="receiver.receiver_name"
                                    :name="`receivers[${index}][receiver_name]`"
                                />
                            </div>
                            <div class="col-md-6">
                                <label class="fw-bold mb-3"
                                    >Nomor Telepon</label
                                >
                                <input
                                    type="number"
                                    class="form-control"
                                    placeholder="Masukkan nomor telepon"
                                    v-model="receiver.phone_number"
                                    :name="`receivers[${index}][phone_number]`"
                                />
                            </div>
                            <div class="col-md-12 mt-3">
                                <label class="fw-bold mb-3"
                                    >Kota/Kabupaten/Kecamatan</label
                                >
                                <model-list-select
                                    :list="receiverList"
                                    v-model="receiver.receiver_destination"
                                    option-value="sub_district_id"
                                    option-text="label"
                                    :placeholder="
                                        receiver.receiver_destination_caption
                                    "
                                    @searchchange="searchReceivers"
                                >
                                </model-list-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 mt-4">
                        <label class="fw-bold mb-3">Alamat Penerima</label>
                        <textarea
                            class="form-control"
                            placeholder="Masukkan alamat penerima"
                            cols="30"
                            rows="5"
                            v-model="receiver.note"
                            :name="`receivers[${index}][note]`"
                        >
                        </textarea>
                    </div>
                    <div class="col-md-12 mt-4">
                        <label class="fw-bold mb-3">
                            Upload Gambar
                            <span class="text-gray">(Optional)</span>
                        </label>
                        <div class="row">
                            <div
                                class="col-md-2 mb-3"
                                v-for="(f, index_item) in receiver.images"
                                :key="index_item"
                            >
                                <label class="upload__btn no-border">
                                    <div
                                        class="close-icon-order"
                                        @click.prevent="
                                            removeImage(index, $event)
                                        "
                                    >
                                        <i class="fa fa-trash"></i>
                                    </div>
                                    <input
                                        type="file"
                                        :class="`images[] upload__inputfile`"
                                        @change="
                                            previewImage(
                                                index_item,
                                                index,
                                                $event
                                            )
                                        "
                                    />
                                    <div
                                        :class="
                                            'images[' +
                                            index +
                                            index_item +
                                            ']-preview'
                                        "
                                    >
                                        <p class="mb-0" style="color: #adb5bd">
                                            <i class="fa fa-cloud-upload"></i>
                                        </p>
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-2 mb-3">
                                <label
                                    class="upload__btn"
                                    @click.prevent="addNewImage(index)"
                                >
                                    <p class="mb-0" style="color: #adb5bd">
                                        <i class="fa fa-plus"></i>
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="box no-shadow border-gray mt-4">
                            <div class="d-flex align-items-center">
                                <h5 class="mb-0 fw-bold">
                                    <i class="fa fa-dropbox me-2"></i>
                                    Daftar Barang
                                </h5>
                                <div class="ms-auto">
                                    <template v-if="user.is_ho">
                                        <button
                                            v-if="
                                                receiver?.list_items[0]?.name !=
                                                'bike'
                                            "
                                            class="btn btn-green px-4"
                                            @click="
                                                modalAddItems = true;
                                                receiver_index = index;
                                            "
                                            :disabled="
                                                mitra_item.id == '' &&
                                                sender.destination
                                            "
                                        >
                                            Tambah Barang
                                        </button>
                                    </template>
                                    <template v-else>
                                        <button
                                            v-if="
                                                receiver?.list_items[0]?.name !=
                                                'bike'
                                            "
                                            class="btn btn-green px-4"
                                            @click="
                                                modalAddItems = true;
                                                receiver_index = index;
                                            "
                                            :disabled="
                                                receiver.receiver_destination
                                                    .sub_district_id == ''
                                            "
                                        >
                                            Tambah Barang
                                        </button>
                                    </template>
                                </div>
                            </div>
                            <template v-if="receiver.list_items.length > 0">
                                <div class="modern-table-boxed mt-3">
                                    <div class="d-table-row header">
                                        <div class="d-table-cell">No</div>
                                        <div class="d-table-cell">Kategori</div>
                                        <div
                                            class="d-table-cell"
                                            v-if="
                                                receiver?.list_items[0]?.name ==
                                                'bike'
                                            "
                                        >
                                            Jenis Motor
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="
                                                receiver?.list_items[0]?.name ==
                                                'bike'
                                            "
                                        >
                                            Merek Motor
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="
                                                receiver?.list_items[0]?.name ==
                                                'bike'
                                            "
                                        >
                                            CC Motor
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="
                                                receiver?.list_items[0]?.name ==
                                                'bike'
                                            "
                                        >
                                            Tahun Motor
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="
                                                receiver?.list_items[0]?.name !=
                                                'bike'
                                            "
                                        >
                                            Deskripsi
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="
                                                receiver?.list_items[0]?.name !=
                                                'bike'
                                            "
                                        >
                                            Berat
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="
                                                receiver?.list_items[0]?.name !=
                                                'bike'
                                            "
                                        >
                                            Dimensi (p x l x t)
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="
                                                receiver?.list_items[0]?.name !=
                                                'bike'
                                            "
                                        >
                                            Perlindungan
                                        </div>
                                        <div class="d-table-cell">Asuransi</div>
                                        <div class="d-table-cell">Aksi</div>
                                    </div>
                                    <div
                                        class="d-table-row"
                                        v-for="(
                                            list_item, item_index
                                        ) in receiver.list_items"
                                        :key="item_index"
                                    >
                                        <div
                                            class="d-table-cell fw-bold"
                                            style="width: 20px"
                                        >
                                            {{ item_index + 1 }}.
                                        </div>
                                        <div class="d-table-cell capitalize">
                                            <template
                                                v-if="
                                                    list_item.name == 'clothes'
                                                "
                                                >Pakaian</template
                                            >
                                            <template
                                                v-if="
                                                    list_item.name ==
                                                    'cosmetics'
                                                "
                                                >Kosmetik</template
                                            >
                                            <template
                                                v-if="
                                                    list_item.name ==
                                                    'accessories'
                                                "
                                                >Aksesoris</template
                                            >
                                            <template
                                                v-if="
                                                    list_item.name ==
                                                    'non_frozen_food'
                                                "
                                                >Makanan Non Frozen</template
                                            >
                                            <template
                                                v-if="
                                                    list_item.name ==
                                                    'electronic'
                                                "
                                                >Elektronik</template
                                            >
                                            <template
                                                v-if="
                                                    list_item.name ==
                                                    'furniture'
                                                "
                                                >Parabotan</template
                                            >
                                            <template
                                                v-if="list_item.name == 'bike'"
                                                >Motor</template
                                            >
                                            <template
                                                v-if="list_item.name == 'other'"
                                                >Lainnya</template
                                            >
                                            <!-- {{ (list_item.name || "").split("_").join(" ") }} -->
                                        </div>
                                        <div
                                            class="d-table-cell capitalize"
                                            v-if="list_item.name == 'bike'"
                                        >
                                            {{ list_item.moto_type }}
                                        </div>
                                        <div
                                            class="d-table-cell capitalize"
                                            v-if="list_item.name == 'bike'"
                                        >
                                            {{ list_item.moto_merk }}
                                        </div>
                                        <div
                                            class="d-table-cell capitalize"
                                            v-if="list_item.name == 'bike'"
                                        >
                                            {{ list_item.moto_cc }} CC
                                        </div>
                                        <div
                                            class="d-table-cell capitalize"
                                            v-if="list_item.name == 'bike'"
                                        >
                                            {{ list_item.moto_year }}
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="list_item.name != 'bike'"
                                        >
                                            <template v-if="list_item.desc">
                                                {{ list_item.desc }}
                                            </template>
                                            <template v-else> - </template>
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="list_item.name != 'bike'"
                                        >
                                            {{ list_item.weight }} Kg
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="list_item.name != 'bike'"
                                        >
                                            <template
                                                v-if="
                                                    list_item.handling.includes(
                                                        `wood`
                                                    )
                                                "
                                            >
                                                {{
                                                    parseInt(list_item.length) +
                                                    8
                                                }}
                                                x
                                                {{
                                                    parseInt(list_item.width) +
                                                    8
                                                }}
                                                x
                                                {{
                                                    parseInt(list_item.height) +
                                                    8
                                                }}
                                            </template>
                                            <template v-else>
                                                {{ list_item.length }} x
                                                {{ list_item.width }} x
                                                {{ list_item.height }}
                                            </template>
                                        </div>
                                        <div
                                            class="d-table-cell"
                                            v-if="list_item.name != 'bike'"
                                        >
                                            <template
                                                v-if="
                                                    list_item.handling.length >
                                                    0
                                                "
                                            >
                                                <template
                                                    v-for="(
                                                        handling, index
                                                    ) in list_item.handling"
                                                >
                                                    <small
                                                        class="tag gray"
                                                        :key="index"
                                                    >
                                                        <template
                                                            v-if="
                                                                handling ==
                                                                'bubble wrap'
                                                            "
                                                        >
                                                            Bubble Wrap
                                                        </template>
                                                        <template
                                                            v-if="
                                                                handling ==
                                                                'cardboard'
                                                            "
                                                        >
                                                            Kardus
                                                        </template>
                                                        <template
                                                            v-if="
                                                                handling ==
                                                                'plastic'
                                                            "
                                                        >
                                                            Plastik
                                                        </template>
                                                        <template
                                                            v-if="
                                                                handling ==
                                                                'wood'
                                                            "
                                                        >
                                                            Kayu
                                                        </template>
                                                        <template
                                                            v-if="
                                                                handling ==
                                                                'sandbag sm'
                                                            "
                                                        >
                                                            Karung Kecil
                                                        </template>
                                                        <template
                                                            v-if="
                                                                handling ==
                                                                'sandbag md'
                                                            "
                                                        >
                                                            Karung Sedang
                                                        </template>
                                                        <template
                                                            v-if="
                                                                handling ==
                                                                'sandbag l'
                                                            "
                                                        >
                                                            Karung Besar
                                                        </template>
                                                    </small>
                                                </template>
                                            </template>
                                            <template v-else> - </template>
                                        </div>
                                        <div class="d-table-cell">
                                            <template
                                                v-if="
                                                    list_item.is_insured ==
                                                        true &&
                                                    list_item.price != 0
                                                "
                                            >
                                                {{
                                                    currencyFormat(
                                                        list_item.price
                                                    )
                                                }}
                                            </template>
                                            <template v-else>
                                                <small class="tag red"
                                                    >Tidak</small
                                                >
                                            </template>
                                        </div>
                                        <div class="d-table-cell">
                                            <a
                                                href="javascript:void(0)"
                                                class="text-primary"
                                                @click="
                                                    showDeleteModal(item_index)
                                                "
                                            >
                                                <i class="fa fa-trash"></i>
                                            </a>
                                            <!-- <a href="javascript:void(0)" class="text-blue ms-3" @click="showEditModal(item_index)">
                                                <i class="fa fa-pencil"></i>
                                            </a> -->
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="box no-shadow border-gray mt-4">
                                    <div class="text-center">
                                        <div>
                                            <img
                                                src="../../assets/no-data.png"
                                                width="100"
                                                alt=""
                                            />
                                        </div>
                                        <div class="mt-3 size-16 fw-medium">
                                            Data Barang Kosong
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="box no-shadow border-gray mt-4">
                            <h5 class="mb-0 fw-bold">
                                <i class="fa fa-rub me-2"></i>
                                Potongan Biaya Pengiriman
                            </h5>
                            <div class="form-check mt-3">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="receiver.is_discount"
                                    :value="true"
                                    :id="`discount[${index}]`"
                                />
                                <label
                                    class="form-check-label fw-semibold"
                                    :for="`discount[${index}]`"
                                >
                                    Berikan Diskon Pengiriman
                                </label>
                            </div>
                            <div class="mt-3" v-if="receiver.is_discount">
                                <money
                                    v-model="receiver.discount_service"
                                    v-bind="money"
                                    class="form-control mt-2"
                                    :name="`receivers[${index}][discount_service]`"
                                    @input="calculatePrice()"
                                >
                                </money>
                            </div>
                            <div class="mt-2 size-14 text-gray-dashboard">
                                Maximal pemberian diskon 30% dari biaya
                                pengiriman
                            </div>
                        </div>
                        <div
                            class="row mt-4"
                            v-if="receiver.list_items.length > 0"
                        >
                            <div class="col-md-7"></div>
                            <div class="col-md-5">
                                <div class="box no-shadow bc-gray">
                                    <div class="d-flex align-items-center mb-3">
                                        <div>Biaya Pengiriman</div>
                                        <div class="ms-auto fw-bold">
                                            <template
                                                v-if="
                                                    receiver.list_items[0]
                                                        .name == 'bike'
                                                "
                                            >
                                                {{
                                                    currencyFormat(
                                                        receiver.calcultade_data
                                                            ?.details
                                                            ?.service_price
                                                    )
                                                }}
                                            </template>
                                            <template v-else>
                                                {{
                                                    currencyFormat(
                                                        receiver.calcultade_data
                                                            ?.result?.service
                                                    )
                                                }}
                                            </template>
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex align-items-center mb-3">
                                        <div class="text-danger">Diskon</div>
                                        <div
                                            class="ms-auto fw-bold text-danger"
                                        >
                                            -
                                            <template
                                                v-if="
                                                    receiver.list_items[0]
                                                        .name == 'bike'
                                                "
                                            >
                                                {{
                                                    currencyFormat(
                                                        receiver.calcultade_data
                                                            ?.details
                                                            ?.discount_service
                                                    )
                                                }}
                                            </template>
                                            <template v-else>
                                                {{
                                                    currencyFormat(
                                                        receiver?.discount_service
                                                    )
                                                }}
                                            </template>
                                        </div>
                                    </div> -->
                                    <div class="d-flex align-items-center mb-3">
                                        <div>Biaya Packing</div>
                                        <div class="ms-auto fw-bold">
                                            <template
                                                v-if="
                                                    receiver.list_items[0]
                                                        .name == 'bike'
                                                "
                                            >
                                                {{
                                                    currencyFormat(
                                                        receiver.calcultade_data
                                                            ?.details
                                                            ?.handling_price
                                                    )
                                                }}
                                            </template>
                                            <template v-else>
                                                {{
                                                    currencyFormat(
                                                        receiver.calcultade_data
                                                            ?.result?.handling
                                                    )
                                                }}
                                            </template>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-3">
                                        <div>Biaya Asuransi</div>
                                        <div class="ms-auto fw-bold">
                                            <template
                                                v-if="
                                                    receiver.list_items[0]
                                                        .name == 'bike'
                                                "
                                            >
                                                {{
                                                    currencyFormat(
                                                        receiver.calcultade_data
                                                            ?.details
                                                            ?.insurance_price
                                                    )
                                                }}
                                            </template>
                                            <template v-else>
                                                {{
                                                    currencyFormat(
                                                        receiver.calcultade_data
                                                            ?.result
                                                            ?.insurance_price_total
                                                    )
                                                }}
                                            </template>
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex align-items-center mb-3">
                                        <div>Biaya Jasa Aplikasi</div>
                                        <div class="ms-auto fw-bold">
                                            {{
                                                currencyFormat(
                                                    receiver.calcultade_data
                                                        ?.result?.platform_fee
                                                )
                                            }}
                                        </div>
                                    </div> -->
                                    <div class="d-flex align-items-center mb-3">
                                        <div>Biaya Tambahan</div>
                                        <div class="ms-auto fw-bold">
                                            <template
                                                v-if="
                                                    receiver.list_items[0]
                                                        .name == 'bike'
                                                "
                                            >
                                                {{
                                                    currencyFormat(
                                                        receiver.calcultade_data
                                                            ?.details
                                                            ?.handling_additional_price
                                                    )
                                                }}
                                            </template>
                                            <template v-else>
                                                {{
                                                    currencyFormat(
                                                        receiver.calcultade_data
                                                            ?.result
                                                            ?.additional_price
                                                    )
                                                }}
                                            </template>
                                        </div>
                                    </div>
                                    <div class="line"></div>
                                    <div class="d-flex align-items-center">
                                        <div class="size-18 fw-bold">
                                            Total Biaya
                                        </div>
                                        <div class="ms-auto fw-bold size-18">
                                            <template
                                                v-if="
                                                    receiver.list_items[0]
                                                        .name == 'bike'
                                                "
                                            >
                                                {{
                                                    currencyFormat(
                                                        receiver.calcultade_data
                                                            ?.total_amount
                                                    )
                                                }}
                                            </template>
                                            <template v-else>
                                                {{
                                                    currencyFormat(
                                                        receiver.calcultade_data
                                                            ?.result
                                                            ?.total_amount
                                                    )
                                                }}
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="box no-shadow green mt-3"
                v-if="
                    receiver.calcultade_data.price != null &&
                    receiver.list_items.length > 0
                "
            >
                <div class="d-flex align-items-center">
                    <div>
                        <i class="fa fa-cart-plus size-26"></i>
                    </div>
                    <div class="ms-4">
                        <h5 class="fw-bold mb-0">Darat</h5>
                        <div class="mt-1 size-13 capitalize">
                            {{ receiver.calcultade_data.price.notes }}
                        </div>
                    </div>
                    <div class="ms-auto">
                        <h5 class="mb-0 fw-bold">
                            {{
                                currencyFormat(
                                    receiver.calcultade_data.result.tier
                                )
                            }}/Kg
                        </h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-4" v-if="receivers[0]?.list_items[0]?.name != 'bike'">
            <button class="btn btn-green btn-lg px-5" @click="addReceiver()">
                Tambah Penerima
            </button>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="deleteModal"></div>
            <div class="modal-body-chat sm" v-if="deleteModal">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            <img
                                src="../../assets/trash-modal.png"
                                width="65"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-ekstra-bold">Hapus Barang</div>
                    <div class="mt-2">
                        Anda yakin untuk menghapus barang dari daftar barang?
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <a
                                href="javascript:void(0)"
                                class="btn btn-outline-black w-100"
                                @click="deleteModal = false"
                            >
                                Batal
                            </a>
                        </div>
                        <div class="col-md-6 mt-4">
                            <a
                                href="javascript:void(0)"
                                class="btn btn-primary w-100"
                                @click="deleteItems()"
                            >
                                Hapus
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div
                class="overlay"
                v-if="modalAddItems"
                @click="modalAddItems = false"
            ></div>
            <div class="modal-body-chat" v-if="modalAddItems">
                <div class="box no-shadow bc-gray p-3">
                    <div class="d-flex bd-highlight align-items-lg-center">
                        <div class="flex-grow-1 bd-highlight">
                            <div class="size-18 fw-ekstra-bold">
                                Tambah Barang
                            </div>
                        </div>
                        <div class="bd-highlight">
                            <a
                                href="javascript:void(0)"
                                @click="modalAddItems = false"
                                class="size-20 text-primary"
                            >
                                <i class="fa fa-times-circle"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div :class="{ 'mt-4': true, scroll: item.name != 'bike' }">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="fw-bold mb-2">Kategori Barang</label>
                            <select class="form-select" v-model="item.name">
                                <option value="">Pilih Kategori</option>
                                <template
                                    v-if="receivers[0]?.list_items.length > 0"
                                >
                                    <template
                                        v-for="(item, index) in categories"
                                    >
                                        <option
                                            :disabled="
                                                item.name == 'bike' &&
                                                receivers[0]?.list_items[0]
                                                    ?.name != 'bike'
                                            "
                                            :key="index"
                                            :value="item.name"
                                        >
                                            {{ item.desc }}
                                        </option>
                                    </template>
                                </template>
                                <template v-else>
                                    <template
                                        v-for="(item, index) in categories"
                                    >
                                        <option :key="index" :value="item.name">
                                            {{ item.desc }}
                                        </option>
                                    </template>
                                </template>
                            </select>
                            <!-- <model-list-select
                                :list="categories"
                                v-model="item.name"
                                option-value="name"
                                :option-disabled="item.name === 'bike'"
                                option-text="desc"
                                placeholder="Pilih kategori"
                            >
                            </model-list-select> -->
                        </div>
                        <div class="col-md-6 mt-3" v-if="item.name == 'bike'">
                            <label class="fw-bold mb-2">Jenis Motor</label>
                            <select
                                class="form-select"
                                v-model="item.moto_type"
                            >
                                <option value="">Pilih Jenis Motor</option>
                                <option value="matic">Matic</option>
                                <option value="kopling">Kopling</option>
                                <option value="gigi">Gigi</option>
                            </select>
                        </div>
                        <div class="col-md-6 mt-3" v-if="item.name == 'bike'">
                            <label class="fw-bold mb-2">Merek Motor</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="item.moto_merk"
                                placeholder="Masukkan Merek Motor"
                            />
                        </div>
                        <div class="col-md-6 mt-3" v-if="item.name == 'bike'">
                            <label class="fw-bold mb-2">CC Motor</label>
                            <select class="form-select" v-model="item.moto_cc">
                                <option value="">Pilih CC Motor</option>
                                <option value="110">110cc</option>
                                <option value="125">125cc</option>
                                <option value="135">135cc</option>
                                <option value="150">150cc</option>
                                <option value="250">250cc</option>
                                <option value="500">500cc</option>
                                <option value="1000">1000cc</option>
                            </select>
                        </div>
                        <div class="col-md-6 mt-3" v-if="item.name == 'bike'">
                            <label class="fw-bold mb-2">Tahun Motor</label>
                            <input
                                type="number"
                                class="form-control"
                                v-model="item.moto_year"
                                placeholder="Masukkan Tahun Motor"
                            />
                        </div>
                        <div class="col-md-6 mt-3" v-if="item.name != 'bike'">
                            <label class="fw-bold mb-2"
                                >Berat
                                <span class="text-gray">(Kg)</span></label
                            >
                            <input
                                type="number"
                                class="form-control"
                                v-model="item.weight"
                                placeholder="Masukkan berat barang"
                            />
                        </div>
                        <div class="col-md-6 mt-3" v-if="item.name != 'bike'">
                            <label class="fw-bold mb-2">Jumlah Paket</label>
                            <input
                                type="number"
                                class="form-control"
                                v-model="item.qty"
                                placeholder="Masukkan berat barang"
                            />
                        </div>
                        <div class="col-md-12 mt-4" v-if="item.name != 'bike'">
                            <div class="box no-shadow border-gray bc-gray p-3">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        v-model="item.is_fragile"
                                        id="is_fragile"
                                        :value="true"
                                    />
                                    <label
                                        class="form-check-label"
                                        for="is_fragile"
                                    >
                                        Barang mudah
                                        <span class="text-green">pecah</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3" v-if="item.name != 'bike'">
                            <label class="fw-bold mb-2">Dimensi</label>
                            <div class="row">
                                <div class="col-md-4">
                                    <input
                                        type="number"
                                        v-model="item.length"
                                        class="form-control"
                                        placeholder="Panjang (cm)"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <input
                                        type="number"
                                        v-model="item.width"
                                        class="form-control"
                                        placeholder="Lebar (cm)"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <input
                                        type="number"
                                        v-model="item.height"
                                        class="form-control"
                                        placeholder="Tinggi (cm)"
                                    />
                                </div>
                            </div>
                        </div>
                        <template v-if="item.is_fragile == false">
                            <div class="col-md-12 mt-3">
                                <label class="fw-bold mb-2"
                                    >Asuransi Barang</label
                                >
                            </div>
                            <div class="col-md-6 mt-2">
                                <label class="plan" for="tidak">
                                    <input
                                        type="radio"
                                        id="tidak"
                                        name="insurance"
                                        v-model="item.is_insured"
                                        :value="false"
                                    />
                                    <div class="plan-content insurance p-3">
                                        <h6 class="mb-0 fw-bold">
                                            Tidak Pakai Asuransi Barang
                                        </h6>
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label class="plan" for="ya">
                                    <input
                                        checked
                                        type="radio"
                                        name="insurance"
                                        id="ya"
                                        v-model="item.is_insured"
                                        :value="true"
                                    />
                                    <div class="plan-content insurance p-3">
                                        <h6 class="mb-0 fw-bold">
                                            Pakai Asuransi Barang
                                        </h6>
                                    </div>
                                </label>
                            </div>
                            <div
                                class="col-md-12 mt-3"
                                v-if="item.is_insured == true"
                            >
                                <label class="fw-bold mb-2">Harga</label>
                                <money
                                    v-model="item.price"
                                    v-bind="money"
                                    class="form-control"
                                >
                                </money>
                            </div>
                        </template>
                        <div class="col-md-12 mt-3" v-if="item.name != 'bike'">
                            <label class="fw-bold mb-2">Perlindungan</label>
                            <div class="row">
                                <div class="col-md-12">
                                    <div
                                        class="form-check form-check-inline"
                                        v-for="(handling, index) in handling"
                                        :key="index"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            :id="handling.id"
                                            :value="handling.value"
                                            v-model="item.handling"
                                        />
                                        <label
                                            class="form-check-label"
                                            :for="handling.id"
                                        >
                                            {{ handling.name }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3" v-if="item.name != 'bike'">
                            <label class="fw-bold mb-2">Deskripsi</label>
                            <textarea
                                name=""
                                id=""
                                cols="30"
                                rows="5"
                                class="form-control"
                                v-model="item.desc"
                                placeholder="Masukkan deskripsi barang"
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <a
                        href="javascript:void(0)"
                        class="btn btn-green btn-lg px-5"
                        @click="addItems()"
                    >
                        Simpan
                    </a>
                </div>
            </div>
        </div>

        <!-- <div class="modal-vue">
            <div class="overlay" v-if="modalEditItems" @click="modalEditItems = false"></div>
            <div class="modal-body-chat" v-if="modalEditItems">
                <div class="box no-shadow bc-gray p-3">
                    <div class="d-flex bd-highlight align-items-lg-center">
                        <div class="flex-grow-1 bd-highlight">
                            <div class="size-18 fw-ekstra-bold">Tambah Barang</div>
                        </div>
                        <div class="bd-highlight">
                            <a href="javascript:void(0)" @click="modalEditItems = false" class="size-20 text-primary">
                                <i class="fa fa-times-circle"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="scroll mt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="fw-bold mb-2">Kategori Barang</label>
                            <model-list-select :list="categories"
                                v-model="item.name"
                                option-value="name"
                                option-text="desc"
                                placeholder="Pilih kategori"
                            >
                            </model-list-select>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="fw-bold mb-2">Berat <span class="text-gray">(Kg)</span></label>
                            <input type="number" class="form-control" v-model="item.weight" placeholder="Masukkan berat barang">
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="fw-bold mb-2">Jumlah Paket</label>
                            <input type="number" class="form-control" v-model="item.qty" placeholder="Masukkan berat barang">
                        </div>
                        <div class="col-md-12 mt-4">
                            <div class="box no-shadow border-gray bc-gray p-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" v-model="item.is_fragile" id="is_fragile" :value="true">
                                    <label class="form-check-label" for="is_fragile">
                                        Barang mudah <span class="text-green">pecah</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-2">Dimensi</label>
                            <div class="row">
                                <div class="col-md-4">
                                    <input type="number" v-model="item.length" class="form-control" placeholder="Panjang (cm)">
                                </div>
                                <div class="col-md-4">
                                    <input type="number" v-model="item.width" class="form-control" placeholder="Lebar (cm)">
                                </div>
                                <div class="col-md-4">
                                    <input type="number" v-model="item.height" class="form-control" placeholder="Tinggi (cm)">
                                </div>
                            </div>
                        </div>
                        <template v-if="item.is_fragile == false">
                            <div class="col-md-12 mt-3">
                                <label class="fw-bold mb-2">Asuransi Barang</label>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label class="plan" for="tidak">
                                    <input type="radio" id="tidak" name="insurance" v-model="item.is_insured" :value="false" />
                                    <div class="plan-content insurance p-3">
                                        <h6 class="mb-0 fw-bold">
                                            Tidak Pakai Asuransi Barang
                                        </h6>
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label class="plan" for="ya">
                                    <input checked type="radio" name="insurance" id="ya" v-model="item.is_insured" :value="true" />
                                    <div class="plan-content insurance p-3">
                                        <h6 class="mb-0 fw-bold">
                                            Pakai Asuransi Barang
                                        </h6>
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-12 mt-3" v-if="item.is_insured == true">
                                <label class="fw-bold mb-2">Harga</label>
                                <money
                                    v-model="item.price"
                                    v-bind="money"
                                    class="form-control"
                                >
                                </money>
                            </div>
                        </template>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-2">Perlindungan</label>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-check form-check-inline" v-for="(handling, index) in handling" :key="index">
                                        <input class="form-check-input" type="checkbox" :id="handling.id" :value="handling.value" v-model="item.handling">
                                        <label class="form-check-label" :for="handling.id">
                                            {{ handling.name }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-2">Deskripsi</label>
                            <textarea name="" id="" cols="30" rows="5" class="form-control" v-model="item.desc" placeholder="Masukkan deskripsi barang"></textarea>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <a
                        href="javascript:void(0)"
                        class="btn btn-green btn-lg px-5"
                        @click="addItems()"
                    >
                        Simpan
                    </a>
                </div>
            </div>
        </div> -->

        <div class="modal-vue">
            <div class="overlay" v-if="showModalPaymentMethod"></div>
            <div class="modal-body-chat sm" v-if="showModalPaymentMethod">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <h5 class="mb-0 fw-bold">Pilih Bank Pembayaran</h5>
                    </div>
                </div>
                <div class="mt-2">
                    Pilih bank dibawah ini untuk melanjutkan proses pembayaran
                    anda
                </div>
                <div class="mt-4">
                    <div class="row mt-3">
                        <template v-for="(item, index) in payment_lists">
                            <div class="col-md-6 mt-3" :key="index">
                                <label class="plan payment" :for="item.channel">
                                    <input
                                        type="radio"
                                        :id="item.channel"
                                        name="bank-item"
                                        v-model="req.is_bank"
                                        :value="item.channel"
                                    />
                                    <div
                                        class="plan-content text-center capitalize fw-bold p-3"
                                    >
                                        <img
                                            :src="item.picture"
                                            width="100"
                                            alt=""
                                        />
                                    </div>
                                </label>
                            </div>
                        </template>
                    </div>
                    <div class="mt-5">
                        <button
                            class="btn btn-green btn-lg w-100"
                            type="button"
                            disabled
                            v-if="is_payment_loading"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Loading...</span>
                            Lanjut
                        </button>
                        <button
                            class="btn btn-green btn-lg w-100"
                            @click="createPayment()"
                            :disabled="req.is_bank == ''"
                            v-else
                        >
                            Lanjut
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="box mt-5">
            <div class="row">
                <div class="col-md-12">
                    <div class="box no-shadow bc-gray p-3">
                        <h5 class="mb-0 fw-bold">
                            <i class="fa fa-truck me-2"></i>
                            Metode pengiriman
                        </h5>
                    </div>
                </div>
                <div class="col-md-6 mt-4">
                    <label class="plan" for="reguler">
                        <input
                            checked
                            type="radio"
                            name="plan"
                            id="reguler"
                            v-model="service_code"
                            value="tps"
                            @change="calculateAllPrice()"
                        />
                        <div class="plan-content">
                            <h5 class="mb-0 fw-bold">Pengiriman Reguler</h5>
                        </div>
                    </label>
                </div>
                <div class="col-md-6 mt-4">
                    <label class="plan" for="express">
                        <input
                            type="radio"
                            id="express"
                            name="plan"
                            v-model="service_code"
                            value="tpx"
                            @change="calculateAllPrice()"
                        />
                        <div class="plan-content">
                            <h5 class="mb-0 fw-bold">Pengiriman Express</h5>
                        </div>
                    </label>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-md-12">
                <label class="plan transfer" for="transfer">
                    <input
                        type="radio"
                        id="transfer"
                        name="method"
                        v-model="req.is_payment_method"
                        value="va"
                    />
                    <div class="plan-content">
                        <div class="d-flex align-items-center">
                            <div class="me-4">
                                <img
                                    src="../../assets/visa.png"
                                    width="110"
                                    alt=""
                                />
                            </div>
                            <div>
                                <h5 class="mb-0 fw-bold">Virtual Account</h5>
                                <div class="mt-2">
                                    Pembayaran dapat dilakukan secara transfer
                                    dan dilakukan diawal pemesanan
                                </div>
                            </div>
                        </div>
                    </div>
                </label>
            </div>
            <div class="col-md-12 mt-3" v-if="user.is_ho == true">
                <label class="plan cash" for="cash">
                    <input
                        type="radio"
                        id="cash"
                        name="method"
                        v-model="req.is_payment_method"
                        value="cash"
                    />
                    <div class="plan-content">
                        <div class="d-flex align-items-center">
                            <div class="me-4">
                                <img
                                    src="../../assets/cash.png"
                                    width="110"
                                    alt=""
                                />
                            </div>
                            <div>
                                <h5 class="mb-0 fw-bold">CASH</h5>
                                <div class="mt-2">
                                    Pembayaran dilakukan diawal pesanan secara
                                    cash
                                </div>
                            </div>
                        </div>
                    </div>
                </label>
            </div>
            <div class="col-md-12 mt-3" v-if="user.is_ho">
                <label class="plan top" for="top">
                    <input
                        type="radio"
                        id="top"
                        name="method"
                        v-model="req.is_payment_method"
                        value="top"
                    />
                    <div class="plan-content">
                        <div class="d-flex align-items-center">
                            <div class="me-4">
                                <img
                                    src="../../assets/top.png"
                                    width="110"
                                    alt=""
                                />
                            </div>
                            <div>
                                <h5 class="mb-0 fw-bold">
                                    TOP (Turn Off Payment)
                                </h5>
                                <div class="mt-2">
                                    Pembayaran dilakukan secara flexsibel sesuai
                                    perjanjian
                                </div>
                            </div>
                        </div>
                    </div>
                </label>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-7"></div>
            <div class="col-md-5 mt-3">
                <div
                    class="box"
                    v-if="receivers[0]?.list_items[0]?.name == 'bike'"
                >
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium">Biaya Pengiriman</div>
                        <div class="ms-auto fw-bold">
                            {{
                                currencyFormat(
                                    receivers[0]?.calcultade_data?.details
                                        ?.service_price
                                )
                            }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium">Biaya Packing</div>
                        <div class="ms-auto fw-bold">
                            {{
                                currencyFormat(
                                    receivers[0]?.calcultade_data?.details
                                        ?.handling_price
                                )
                            }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium">Biaya Asuransi</div>
                        <div class="ms-auto fw-bold">
                            {{
                                currencyFormat(
                                    receivers[0]?.calcultade_data?.details
                                        ?.insurance_price
                                )
                            }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium">Biaya Jasa Aplikasi</div>
                        <div class="ms-auto fw-bold">
                            {{
                                currencyFormat(
                                    receivers[0]?.calcultade_data?.details
                                        ?.platform_fee
                                )
                            }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium">Biaya Tambahan</div>
                        <div class="ms-auto fw-bold">
                            {{
                                currencyFormat(
                                    receivers[0]?.calcultade_data?.details
                                        ?.handling_additional_price
                                )
                            }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium text-danger">Diskon</div>
                        <div class="ms-auto fw-bold text-danger">
                            -
                            {{
                                currencyFormat(
                                    receivers[0]?.calcultade_data?.details
                                        ?.discount_service
                                )
                            }}
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="d-flex align-items-center">
                        <div class="size-18 fw-bold text-green">
                            Total Pembayaran
                        </div>
                        <div class="ms-auto fw-bold text-green size-18">
                            {{
                                currencyFormat(
                                    receivers[0]?.calcultade_data?.total_amount
                                )
                            }}
                        </div>
                    </div>
                    <div class="mt-4">
                        <button
                            class="btn btn-green btn-lg w-100"
                            @click="createOrder()"
                            :disabled="
                                receivers[receiver_index]?.list_items.length ==
                                    0 || req.is_payment_method == ''
                            "
                        >
                            Pesan
                        </button>
                    </div>
                </div>
                <div class="box" v-else>
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium">Biaya Pengiriman</div>
                        <div class="ms-auto fw-bold">
                            {{ currencyFormat(service_count) }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium">Biaya Packing</div>
                        <div class="ms-auto fw-bold">
                            {{ currencyFormat(handling_count) }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium">Biaya Asuransi</div>
                        <div class="ms-auto fw-bold">
                            {{ currencyFormat(insurance_price_total) }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium">Biaya Jasa Aplikasi</div>
                        <div class="ms-auto fw-bold">
                            {{ currencyFormat(platform_fee) }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium">Biaya Tambahan</div>
                        <div class="ms-auto fw-bold">
                            {{ currencyFormat(additional_price) }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="fw-medium text-danger">Diskon</div>
                        <div class="ms-auto fw-bold text-danger">
                            -{{ currencyFormat(discount_service) }}
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="d-flex align-items-center">
                        <div class="size-18 fw-bold text-green">
                            Total Pembayaran
                        </div>
                        <div class="ms-auto fw-bold text-green size-18">
                            {{ currencyFormat(total_amount) }}
                        </div>
                    </div>
                    <div class="mt-4">
                        <button
                            class="btn btn-green btn-lg w-100"
                            @click="createOrder()"
                            :disabled="
                                receivers[receiver_index]?.list_items.length ==
                                    0 || req.is_payment_method == ''
                            "
                        >
                            Pesan
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelListSelect } from "vue-search-select";
import Api from "../../api/Api";
import $ from "jquery";
import { Money } from "v-money";

export default {
    components: {
        ModelListSelect,
        Money,
    },
    name: "CorporateCreate",
    data() {
        return {
            price: 0,
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "Rp",
                suffix: "",
                precision: 0,
                masked: false,
            },
            geoListSetItems: null,
            senderValuePlaceholder: "Cari kota/kabupaten/kecamatan",
            parent_id: null,
            child_id: [],
            child_count: 0,
            req: {
                is_payment_method: "",
                is_bank: "",
            },
            receiver_index: 0,
            geoLists: [],
            modalAddItems: false,
            deleteModal: false,
            searchTextGeo: "",
            service_code: "tps",
            sender: {
                sender_name: "",
                phone_number: "",
                destination: "",
                note: "",
                mitra: "",
            },
            mitra_item: {
                code: "",
                id: "",
            },
            receivers: [
                {
                    receiver_name: "",
                    phone_number: "",
                    discount_service: 0,
                    is_discount: false,
                    note: "",
                    destination: "",
                    list_items: [],
                    images: [],
                    receiver_destination_temp: "",
                    receiver_destination_caption:
                        "Pilih kota/kabupaten/kecamatan",
                    receiver_destination: {
                        district_id: "",
                        regency_id: "",
                        sub_district_id: "",
                    },
                    calcultade_data: {
                        total_amount: 0,
                        details: {},
                        result: {
                            tier: 0,
                            service: 0,
                            total_amount: 0,
                            additional_price: 0,
                            handling: 0,
                            insurance_price_total: 0,
                        },
                        price: {
                            notes: "",
                        },
                    },
                },
            ],
            handling: [
                { id: 1, name: "Bubble Wrap", value: "bubble wrap" },
                { id: 2, name: "Kardus", value: "cardboard" },
                { id: 3, name: "Plastik", value: "plastic" },
                { id: 4, name: "Kayu", value: "wood" },
                { id: 5, name: "Karung Kecil", value: "sandbag sm" },
                { id: 6, name: "Karung Sedang", value: "sandbag md" },
                { id: 7, name: "Karung Besar", value: "sandbag l" },
            ],
            item: {
                moto_merk: "",
                moto_cc: "",
                moto_type: "",
                moto_year: "",
                name: "",
                desc: "",
                length: 0,
                width: 0,
                height: 0,
                weight: 0,
                qty: 1,
                price: 0,
                order_type: "other",
                handling: [],
                is_insured: false,
                is_fragile: false,
                protection: false,
            },
            partners: [],
            total_amount: 0,
            additional_price: 0,
            insurance_price_total: 0,
            platform_fee: 0,
            handling_count: 0,
            service_count: 0,
            discount_service: 0,
            data_phone: {},
            user: {},
            searchReceiver: "",
            receiverList: [],
            destination_regency_id: "",
            maxImages: 5,
            addImage: "button.add-image",
            is_loading: false,
            showModalPaymentMethod: false,
            package_id: null,
            payment_lists: [],
            is_payment_loading: false,
            payment_result: {},
            is_customer: true,
            delete_value: null,
            edit_value: null,
            categories: [],
            is_discount: false,
        };
    },
    created() {
        this.getPartners();
        this.getUserLogin();
        this.calcultaeOrder();
        this.getCategoryItem();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    watch: {
        receivers: {
            deep: true,
            handler: function (newval) {
                if (this.user.is_ho) {
                    if (this.geoLists.length == 0) {
                        return;
                    }
                    newval.forEach((r, i) => {
                        this.receivers[i].receiver_destination_temp =
                            r.receiver_destination.sub_district_id;
                        this.receivers[i].receiver_destination_caption =
                            r.receiver_destination.label;
                    });
                } else {
                    newval.forEach((r, i) => {
                        this.receivers[i].receiver_destination_temp =
                            r.receiver_destination.sub_district_id;
                        this.receivers[i].receiver_destination_caption =
                            r.receiver_destination.label;
                    });
                }
            },
        },
        sender: {
            destination(oldVal, newVal) {
                console.log("", [oldVal, newVal]);
            },
        },
        geoListSetItems(newVal) {
            let selectRow = this.geoLists.filter(function (vv) {
                return vv.sub_district_id == newVal;
            });
            if (selectRow.length) {
                this.sender.destination = selectRow[0].sub_district_id;
                let r = selectRow[0];
                this.senderValuePlaceholder =
                    r.regency + ", " + r.district + ", " + r.sub_district;
            } else {
                this.sender.destination = "";
                this.senderValuePlaceholder = "Cari kota/kabupaten/kecamatan";
            }
        },
    },
    methods: {
        getCategoryItem() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/trawlpack/customer/item/category?service_code=${this.service_code}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.categories = res.data.data;
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        showDeleteModal(item_index) {
            this.deleteModal = true;
            this.delete_value = item_index;
        },
        // showEditModal(item_index){
        //     this.modalEditItems = true
        //     this.edit_value = item_index
        // },
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.user = res.data.data;
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        searchGeoList(searchTextGeo) {
            this.searchTextGeo = searchTextGeo;
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/geo/list?type=sub_district&search=${searchTextGeo}&page=1&per_page=15`
            )
                .then((res) => {
                    this.geoLists = res.data.data;
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        searchReceivers(searchReceiver) {
            this.searchReceiver = searchReceiver;
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/geo/list?type=sub_district&search=${searchReceiver}&page=1&per_page=15`
            )
                .then((res) => {
                    this.receiverList = res.data.data;
                    this.calculatePrice();
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        searchPhoneNumber() {
            if (this.sender.phone_number == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Nomor telepon wajib diisi",
                });
            } else {
                Api.get(
                    `${process.env.VUE_APP_BASE_URL}/partner/corporate/customer?phone=${this.sender.phone_number}`,
                    {
                        headers: {
                            Authorization:
                                "Bearer" + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        this.data_phone = res.data.data;
                        this.is_customer = true;
                    })
                    .catch((err) => {
                        console.log(err);
                        this.is_customer = false;
                        this.$notify({
                            group: "foo",
                            type: "warn",
                            title: "Warning",
                            text: "Anda belum menjadi customer, silahkan input nama anda untuk melanjutkan.",
                            // text: err.response.data.message
                        });
                    });
            }
        },
        addReceiver() {
            this.receivers.push({
                receiver_name: "",
                phone_number: "",
                discount_service: 0,
                is_discount: false,
                note: "",
                destination: "",
                list_items: [],
                images: [],
                receiver_destination_temp: "",
                receiver_destination_caption: "Pilih kota/kabupaten/kecamatan",
                receiver_destination: {
                    district_id: "",
                    regency_id: "",
                    sub_district_id: "",
                },
                calcultade_data: {
                    total_amount: 0,
                    details: {},
                    result: {
                        tier: 0,
                        service: 0,
                        total_amount: 0,
                        additional_price: 0,
                        handling: 0,
                        insurance_price_total: 0,
                        platform_fee: 0,
                    },
                    price: {
                        notes: "",
                    },
                },
            });
        },
        deleteRereceiver(index) {
            this.receivers.splice(index, 1);
        },
        addItems() {
            if (this.item.name == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Kategori barang tidak boleh kosong",
                });
                return true;
            }
            if (this.item.name != "bike") {
                if (this.item.weight == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Berat barang tidak boleh kosong",
                    });

                    return true;
                }
                if (this.item.qty == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Jumlah paket tidak boleh kosong",
                    });
                    return true;
                }
                if (this.item.length == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Panjang tidak boleh kosong",
                    });
                    return true;
                }
                if (this.item.width == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Lebar barang tidak boleh kosong",
                    });
                    return true;
                }
                if (this.item.height == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Tinggi barang tidak boleh kosong",
                    });
                    return true;
                }
                if (this.item.height == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Tinggi barang tidak boleh kosong",
                    });
                    return true;
                }
            }
            if (this.item.name == "bike") {
                if (this.item.moto_type == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Jenis Motor Tidak Boleh Kosong",
                    });

                    return true;
                }
                if (this.item.moto_merk == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Merek Motor Tidak Boleh Kosong",
                    });

                    return true;
                }
                if (this.item.moto_cc == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "CC Motor Tidak Boleh Kosong",
                    });

                    return true;
                }
                if (this.item.moto_year == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Tahun Motor Tidak Boleh Kosong",
                    });

                    return true;
                }
            }
            if (this.item.is_insured == null) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Pilih asuransi terlebih dahulu",
                });
                return true;
            }
            if (
                this.item.is_insured != null &&
                this.item.is_insured != false &&
                this.item.price < 10000
            ) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Asuransi tidak boleh kurang dari Rp10.000",
                });
                return true;
            }

            let length = this.item.length;
            let width = this.item.width;
            let height = this.item.height;

            // if (this.item.handling.includes("wood")) {
            //     length = parseInt(length) + 7;
            //     width = parseInt(width) + 7;
            //     height = parseInt(height) + 7;
            // }

            this.receivers[this.receiver_index].list_items.push({
                name: this.item.name,
                desc: this.item.desc,
                length: length,
                width: width,
                height: height,
                weight: this.item.weight,
                qty: this.item.qty,
                price: this.item.price,
                order_type: "other",
                handling: this.item.handling,
                is_insured: this.item.is_insured,
                protection: this.item.protection,
                moto_merk: this.item.moto_merk,
                moto_cc: this.item.moto_cc,
                moto_year: this.item.moto_year,
                moto_type: this.item.moto_type,
            });

            this.item.moto_merk = "";
            this.item.moto_cc = "";
            this.item.moto_type = "";
            this.item.moto_year = "";

            this.item.name = "";
            this.item.desc = "";
            this.item.length = "";
            this.item.width = "";
            this.item.height = "";
            this.item.weight = "";
            (this.item.qty = ""), (this.item.price = 0);
            this.item.order_type = "";
            this.item.handling = [];
            this.item.is_insured = false;
            this.item.is_fragile = false;
            this.item.protection = "";
            this.modalAddItems = false;
            this.calculatePrice();
        },
        deleteItems() {
            var receiver = this.receivers[this.receiver_index];
            receiver.list_items.splice(this.delete_value, 1);
            this.deleteModal = false;
            // if(receiver.list_items.length > 1){
            this.calculatePrice();
            // }
        },
        // senderValueSelect (item) {
        //     return `${item.regency}, ${item.district}, ${item.sub_district}`
        // },
        // receiverValueSelect (item) {
        //     return `${item.regency} - ${item.district} - ${item.sub_district}`
        // },
        getPartners() {
            Api.get(`${process.env.VUE_APP_BASE_URL}/partner/corporate`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    Accept: "application/json",
                },
            })
                .then((res) => {
                    this.partners = res.data.data;
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        calculateAllPrice() {
            this.receivers.forEach((item, index) => {
                var data;

                var path = "pricing/calculator/corporate";

                if (item.list_items[0].name == "bike") {
                    path = "partner/corporate/pricing-bike";
                    data = {
                        destination_id:
                            item.receiver_destination.sub_district_id,
                        partner_code: this.mitra_item.code,
                        moto_type: item.list_items[0].moto_type,
                        moto_merk: item.list_items[0].moto_merk,
                        moto_cc: item.list_items[0].moto_cc,
                        discount_service:
                            this.receivers[index].discount_service,
                        price: item.list_items[0].price,
                    };
                } else {
                    data = {
                        destination_id:
                            item.receiver_destination.sub_district_id,
                        partner_id: this.mitra_item.id,
                        items: this.receivers[index].list_items,
                        discount_service:
                            this.receivers[index].discount_service,
                        service_code: this.service_code,
                    };
                }

                Api.post(`${process.env.VUE_APP_BASE_URL}/${path}`, data, {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                })
                    .then((res) => {
                        if (res.data.data.message) {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: res.data.data.message,
                            });
                            this.service_code = "tps";
                        } else {
                            this.receivers[index].calcultade_data =
                                res.data.data;
                            this.calcultaeOrder();
                        }
                    })
                    .catch((err) => {
                        if (err.response.data.data == null) {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.message,
                            });
                        } else {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.data.message,
                            });
                        }
                        this.service_code = "tps";
                    });
            });
        },
        calculatePrice() {
            if (this.user.is_ho) {
                var datas;

                var path_url = "";

                if (
                    this.receivers[this.receiver_index].list_items[0].name ==
                    "bike"
                ) {
                    path_url = `${process.env.VUE_APP_BASE_URL}/partner/corporate/pricing-bike`;
                    datas = {
                        destination_id: this.sender.destination,
                        partner_code: this.mitra_item.code,
                        moto_type:
                            this.receivers[this.receiver_index].list_items[0]
                                .moto_type,
                        moto_merk:
                            this.receivers[this.receiver_index].list_items[0]
                                .moto_merk,
                        moto_cc:
                            this.receivers[this.receiver_index].list_items[0]
                                .moto_cc,
                        discount_service:
                            this.receivers[this.receiver_index]
                                .discount_service,
                        price: this.receivers[this.receiver_index].list_items[0]
                            .price,
                    };
                } else {
                    path_url = `${process.env.VUE_APP_BASE_URL}/pricing/calculator/corporate`;
                    datas = {
                        destination_id: this.sender.destination,
                        partner_id: this.mitra_item.id,
                        items: this.receivers[this.receiver_index].list_items,
                        discount_service:
                            this.receivers[this.receiver_index]
                                .discount_service,
                        service_code: this.service_code,
                    };
                }

                Api.post(`${path_url}`, datas, {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                })
                    .then((res) => {
                        this.receivers[this.receiver_index].calcultade_data =
                            res.data.data;
                        this.calcultaeOrder();
                    })
                    .catch((err) => {
                        if (err.response.data.data == null) {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.message,
                            });
                        } else {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.data.message,
                            });
                        }
                    });
            } else {
                var data;

                var path_url_link = "";

                if (
                    this.receivers[this.receiver_index].list_items[0].name ==
                    "bike"
                ) {
                    path_url_link = `${process.env.VUE_APP_BASE_URL}/partner/corporate/pricing-bike`;
                    data = {
                        destination_id:
                            this.receivers[this.receiver_index]
                                .receiver_destination.sub_district_id,
                        partner_code: this.mitra_item.code,
                        moto_type:
                            this.receivers[this.receiver_index].list_items[0]
                                .moto_type,
                        moto_merk:
                            this.receivers[this.receiver_index].list_items[0]
                                .moto_merk,
                        moto_cc:
                            this.receivers[this.receiver_index].list_items[0]
                                .moto_cc,

                        discount_service:
                            this.receivers[this.receiver_index]
                                .discount_service,
                        price: this.receivers[this.receiver_index].list_items[0]
                            .price,
                    };
                } else {
                    path_url_link = `${process.env.VUE_APP_BASE_URL}/pricing/calculator/corporate`;
                    data = {
                        destination_id:
                            this.receivers[this.receiver_index]
                                .receiver_destination.sub_district_id,
                        partner_id: this.user.partner_id,
                        items: this.receivers[this.receiver_index].list_items,
                        service_code: this.service_code,
                        discount_service:
                            this.receivers[this.receiver_index]
                                .discount_service,
                    };
                }

                Api.post(`${path_url_link}`, data, {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                })
                    .then((res) => {
                        this.receivers[this.receiver_index].calcultade_data =
                            res.data.data;
                        this.calcultaeOrder();
                    })
                    .catch((err) => {
                        if (err.response.data.data == null) {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.message,
                            });
                        } else {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.data.message,
                            });
                        }
                    });
            }
        },
        calcultaeOrder() {
            let total_amount = 0;
            let additional_price = 0;
            let insurance_price_total = 0;
            let platform_fee = 0;
            let handling = 0;
            let service = 0;
            let discount_service = 0;

            let isBike = false;

            this.receivers.forEach((val) => {
                if (
                    val.list_items &&
                    val.list_items.length > 0 &&
                    val.list_items[0].name == "bike"
                ) {
                    isBike = true;
                }
                total_amount += Number(val.calcultade_data.result.total_amount);
                additional_price += Number(
                    val.calcultade_data.result.additional_price
                );
                insurance_price_total += Number(
                    val.calcultade_data.result.insurance_price_total
                );
                platform_fee += Number(
                    val.calcultade_data.result.platform_fee ?? 0
                );
                handling += Number(val.calcultade_data.result.handling);
                service += Number(val.calcultade_data.result.service);
                discount_service += Number(val.discount_service);
            });
            // add manual platform fee bike
            if (isBike) total_amount = total_amount + 3000;
            this.total_amount = total_amount;
            this.additional_price = additional_price;
            this.insurance_price_total = insurance_price_total;
            this.platform_fee = platform_fee;
            this.handling_count = handling;
            this.service_count = service;
            this.discount_service = discount_service;
        },
        addNewImage: function (receiver_index) {
            var n = this.maxImages || -1;
            if (n && this.receivers[receiver_index].images.length < n) {
                this.receivers[receiver_index].images.push("");
            }
            this.checkImages();
        },
        removeImage: function (receiver_index, index) {
            this.receivers[receiver_index].images.splice(index, 1);
            this.checkImages();
        },
        checkImages: function () {
            var n = this.maxImages || -1;
            if (n && this.receivers[this.receiver_index].images.length >= n) {
                $(this.addImage, this.el).prop("disabled", true);
            } else {
                $(this.addImage, this.el).prop("disabled", false);
            }
        },
        previewImage: function (index_item, index, e) {
            var r = new FileReader(),
                f = e.target.files[0];

            r.addEventListener(
                "load",
                function () {
                    $(
                        '[class~="images[' + index + index_item + ']-preview"]',
                        this.el
                    ).html(
                        '<div class="image-preview" style="background-image: url(' +
                            r.result +
                            ')"></div>'
                    );
                },
                false
            );

            if (f) {
                r.readAsDataURL(f);
            }
        },
        createOrderSingle: async function (receiver) {
            var data = new FormData();
            $('[class~="images[]', this.el).each(function (i) {
                if (i > this.maxImages - 1) {
                    return;
                }
                data.append("photos[" + i + "]", this.files[0]);
            });
            data.append("payment_method", this.req.is_payment_method);
            data.append("service_code", this.service_code);
            if (this.is_customer == false) {
                data.append("customer_name", this.sender.sender_name);
                data.append("customer_phone", this.sender.phone_number);
                data.append("sender_name", this.sender.sender_name);
                data.append("sender_phone", this.sender.phone_number);
            } else {
                data.append("customer_id", this.data_phone.id);
                data.append("sender_phone", this.data_phone.phone);
                data.append("sender_name", this.data_phone.name);
            }
            data.append("sender_address", this.sender.note);
            data.append("partner_code", this.mitra_item.code);
            data.append("receiver_name", receiver.receiver_name);
            data.append("discount_service", receiver.discount_service);
            data.append("receiver_phone", receiver.phone_number);
            data.append("receiver_address", receiver.note);
            data.append("items", JSON.stringify(receiver.list_items));
            data.append("origin_regency_id", this.sender.destination);
            data.append(
                "destination_regency_id",
                receiver.receiver_destination.regency_id
            );
            data.append(
                "destination_district_id",
                receiver.receiver_destination.district_id
            );
            data.append(
                "destination_sub_district_id",
                receiver.receiver_destination.sub_district_id
            );

            this.is_loading = true;

            let path_create_order_single = "";
            if (receiver.list_items[0].name == "bike") {
                path_create_order_single = `${process.env.VUE_APP_BASE_URL}/partner/corporate/order-bike`;
            } else {
                path_create_order_single = `${process.env.VUE_APP_BASE_URL}/partner/corporate/order`;
            }

            try {
                let res = await Api.post(`${path_create_order_single}`, data, {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "multipart/form-data",
                    },
                });

                this.package_id = res.data.data.id;
                let child_id = this.child_id;
                child_id.push(this.package_id);
                this.child_id = child_id;
                this.is_loading = false;
                if (this.child_id.length == this.child_count) {
                    this.createBundle();
                }
            } catch (err) {
                this.is_loading = false;
                return err;
            }
        },
        createBundle() {
            var data = new FormData();

            var packagesId = this.child_id;
            if (packagesId.length >= 2) {
                data.append("package_parent_id", packagesId[0]);
                for (let index = 0; index < packagesId.length; index++) {
                    if (index == 0) {
                        continue;
                    }

                    data.append("package_child_ids[]", packagesId[index]);
                }
            }

            this.is_loading = true;

            Api.post(
                `${process.env.VUE_APP_BASE_URL}/partner/corporate/order/multi`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
                .then(() => {
                    this.package_id = null;
                    this.is_loading = false;
                    this.getPaymentList();
                    if (this.req.is_payment_method == "va") {
                        this.showModalPaymentMethod = true;
                    } else {
                        window.location.href = "/trawlpack/general/order";
                    }
                })
                .catch((err) => {
                    this.is_loading = false;
                    return err;
                });
        },
        createOrder() {
            if (this.sender.phone_number == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Nomor telepon pengirim tidak boleh kosong",
                });
                return;
            }
            if (this.is_customer) {
                if (this.data_phone == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Nama penerima tidak boleh kosong",
                    });
                    return;
                }
            } else {
                if (this.sender.sender_name == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Nama pengirim tidak boleh kosong",
                    });
                    return;
                }
            }
            if (this.user.is_ho) {
                if (this.sender.destination == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Kota/kabupaten/kecamatan tidak boleh kosong",
                    });
                    return;
                }
                if (this.mitra_item.code == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Mitra tidak boleh kosong",
                    });
                    return;
                }
            }
            this.receivers.forEach((receiver) => {
                if (receiver.receiver_name == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Nama penerima tidak boleh kosong",
                    });
                    return;
                }
                if (receiver.phone_number == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Nomor telepon penerima tidak boleh kosong",
                    });
                    return;
                }
                if (receiver.receiver_destination.district_id == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Kota/Kabupaten/Kecamatan tidak boleh kosong",
                    });
                    return;
                }
                if (receiver.note == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Catatan tidak boleh kosong",
                    });
                    return;
                }
                if (receiver.list_items.length == 0) {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Tambahka barang terlebih dahulu",
                    });
                    return;
                }
                if (this.req.is_payment_method == "") {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Pilih metode pembayaran terlebih dahulu",
                    });
                    return;
                }
            });
            if (this.receivers.length >= 2) {
                let that = this;
                this.child_count = this.receivers.length;
                this.receivers.forEach(async (receiver) => {
                    await that.createOrderSingle(receiver);
                    await that.sleepCall(1000);
                });
            } else {
                var data1 = new FormData();
                $('[class~="images[]', this.el).each(function (i) {
                    if (i > this.maxImages - 1) {
                        return;
                    }
                    data1.append("photos[" + i + "]", this.files[0]);
                });
                data1.append("payment_method", this.req.is_payment_method);
                data1.append("service_code", this.service_code);
                if (this.is_customer == false) {
                    data1.append("customer_name", this.sender.sender_name);
                    data1.append("customer_phone", this.sender.phone_number);
                    data1.append("sender_name", this.sender.sender_name);
                    data1.append("sender_phone", this.sender.phone_number);
                } else {
                    data1.append("customer_id", this.data_phone.id);
                    data1.append("sender_phone", this.data_phone.phone);
                    data1.append("sender_name", this.data_phone.name);
                }
                data1.append("sender_address", this.sender.note);
                data1.append("partner_code", this.mitra_item.code);
                data1.append(
                    "receiver_name",
                    this.receivers[this.receiver_index].receiver_name
                );
                data1.append(
                    "discount_service",
                    this.receivers[this.receiver_index].discount_service
                );
                data1.append(
                    "receiver_phone",
                    this.receivers[this.receiver_index].phone_number
                );
                data1.append(
                    "receiver_address",
                    this.receivers[this.receiver_index].note
                );
                data1.append(
                    "items",
                    JSON.stringify(
                        this.receivers[this.receiver_index].list_items
                    )
                );
                data1.append("origin_regency_id", this.sender.destination);
                data1.append(
                    "destination_regency_id",
                    this.receivers[this.receiver_index].receiver_destination
                        .regency_id
                );
                data1.append(
                    "destination_district_id",
                    this.receivers[this.receiver_index].receiver_destination
                        .district_id
                );
                data1.append(
                    "destination_sub_district_id",
                    this.receivers[this.receiver_index].receiver_destination
                        .sub_district_id
                );

                this.is_loading = true;

                let path_create_order_multi = "";
                if (this.receivers[0].list_items[0].name == "bike") {
                    path_create_order_multi = `${process.env.VUE_APP_BASE_URL}/partner/corporate/order-bike`;
                } else {
                    path_create_order_multi = `${process.env.VUE_APP_BASE_URL}/partner/corporate/order`;
                }

                Api.post(`${path_create_order_multi}`, data1, {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "multipart/form-data",
                    },
                })
                    .then((res) => {
                        console.log(res, "multi");
                        this.package_id = res.data.data.id;
                        this.is_loading = false;
                        this.getPaymentList();
                        if (this.req.is_payment_method == "va") {
                            this.showModalPaymentMethod = true;
                        } else {
                            window.location.href = "/trawlpack/general/order";
                        }
                    })
                    .catch((err) => {
                        if (err.response.data.data == null) {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.message,
                            });
                        } else {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.data.message,
                            });
                        }
                        this.is_loading = false;
                    });
            }
        },
        sleepCall: async function (ms) {
            new Promise((resolve) => setTimeout(resolve, ms));
        },
        getPaymentList() {
            if (
                this.req.is_payment_method == "cash" ||
                this.req.is_payment_method == "cash"
            ) {
                return;
            }
            if (this.child_count == 0) {
                Api.get(
                    `${process.env.VUE_APP_BASE_URL}/partner/corporate/order/payment?package_id=${this.package_id}`,
                    {
                        headers: {
                            Authorization:
                                "Bearer" + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        this.payment_lists = res.data.data;
                    })
                    .catch((err) => {
                        if (err.response.data.data == null) {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.message,
                            });
                        } else {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.data.message,
                            });
                        }
                    });
            } else {
                Api.get(
                    `${process.env.VUE_APP_BASE_URL}/partner/corporate/order/payment?package_id=${this.child_id[0]}`,
                    {
                        headers: {
                            Authorization:
                                "Bearer" + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        this.payment_lists = res.data.data;
                    })
                    .catch((err) => {
                        if (err.response.data.data == null) {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.message,
                            });
                        } else {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.data.message,
                            });
                        }
                    });
            }
        },
        createPayment() {
            if (this.child_count == 0) {
                var data = {
                    package_id: this.package_id,
                    payment_channel: this.req.is_bank,
                };
                this.is_payment_loading = true;
                Api.post(
                    `${process.env.VUE_APP_BASE_URL}/partner/corporate/order/payment`,
                    data,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        this.is_payment_loading = false;
                        this.payment_result = res.data.data;
                        window.location.href = `/corporate/order-detail/${this.package_id}`;
                    })
                    .catch((err) => {
                        if (err.response.data.data == null) {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.message,
                            });
                        } else {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.data.message,
                            });
                        }
                        this.is_payment_loading = false;
                    });
            } else {
                var data1 = {
                    package_id: this.child_id[0],
                    payment_channel: this.req.is_bank,
                };
                this.is_payment_loading = true;
                Api.post(
                    `${process.env.VUE_APP_BASE_URL}/partner/corporate/order/payment`,
                    data1,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        this.is_payment_loading = false;
                        this.payment_result = res.data.data;
                        window.location.href = `/corporate/order-detail/${this.child_id[0]}`;
                    })
                    .catch((err) => {
                        if (err.response.data.data == null) {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.message,
                            });
                        } else {
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.data.message,
                            });
                        }
                        this.is_payment_loading = false;
                    });
            }
        },
    },
};
</script>

<style scoped>
.upload__inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #f8f9fa;
    border-style: dashed;
    border-radius: 10px;
    width: 100%;
    height: 130px;
    padding: 40px;
    position: relative;
}
.no-border {
    border: unset;
}
.upload__btn p {
    font-size: 30px;
}
.upload__img-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.upload__img-box {
    width: 200px;
    padding: 0 10px;
    margin-bottom: 12px;
}
.upload__img-close {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: center;
    line-height: 24px;
    z-index: 1;
    cursor: pointer;
}
.upload__img-close:after {
    content: "✖";
    font-size: 14px;
    color: white;
}

.img-bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    padding-bottom: 100%;
}
.image-preview-order {
    width: 100%;
    height: 130px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    position: relative;
}
.close-icon-order {
    position: absolute;
    right: 8px;
    top: 8px;
    color: #e60013;
    font-size: 14px;
    padding: 2px;
    background: #fff;
    border-radius: 50px;
    text-align: center;
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 9;
}
.btn-outline-secondary {
    color: #fff !important;
    border-color: #e60013 !important;
    background: #e60013 !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
}
.btn-outline-secondary:hover {
    color: #fff !important;
    border-color: #e60013 !important;
    background: #e60013 !important;
}
.scroll {
    height: 400px;
    overflow-y: scroll;
}
</style>
