<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="d-flex align-items-center bd-highlight">
            <div class="flex-grow-1 bd-highlight">
                <h3 class="fw-bold">
                    Tambah Driver
                </h3>
            </div>
        </div>
        <div class="box mt-4">
            <template v-if="req.avatar == ''">
                <div class="avatar-preview" :style="{ 'background-image': 'url(' + require('../../assets/user.jpg') + ')' }">
                    <div class="mt-4 ml-4">
                        <label for="avatar" class="upload-button avatar single-image"> 
                            <i class="fa fa-upload"></i>
                            <input type="file" id="avatar" @change="uploadAvatar()">
                        </label>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="avatar-preview" :style="{'background-image': 'url(' + (req.avatar) + ')'}">
                    <div class="mt-4 ml-4">
                        <label for="avatar" class="upload-button avatar single-image"> 
                            <i class="fa fa-upload"></i>
                            <input type="file" id="avatar" @change="uploadAvatar()">
                        </label>
                    </div>
                </div>
            </template>
            <div class="row">
                <div class="col-md-4 mt-3">
                    <label class="fw-bold mb-2">Email</label>
                    <input type="email" class="form-control" placeholder="Masukkan email" v-model="req.email">
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold mb-2">Password</label>
                    <div class="position-relative">
                        <input type="text" class="form-control" placeholder="Masukkan password" v-model="req.password" v-if="showPassword">
                        <input type="password" class="form-control" placeholder="Masukkan password" v-model="req.password" v-else>
                        <span class="search-icon cursor-pointer" @click="toggleShow">
                            <i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                        </span>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold mb-2">Nama Driver</label>
                    <input type="text" class="form-control" placeholder="Masukkan nama driver" v-model="req.name">
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold mb-2">Tanggal Lahir</label>
                    <input type="date" class="form-control" v-model="req.birth">
                </div>
                <div class="col-md-8 mt-3">
                    <label class="fw-bold mb-2">Alamat Driver</label>
                    <input type="text" class="form-control" placeholder="Masukkan alamat driver" v-model="req.address">
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold mb-2">Nomor Telepon</label>
                    <input type="number" class="form-control" placeholder="Masukkan nomor telepon" v-model="req.phone">
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold mb-2">Nomor KTP</label>
                    <input type="number" class="form-control" placeholder="Masukkan nomor KTP" v-model="req.no_ktp">
                </div>
                <div class="col-md-4 mt-3">
                    <label class="fw-bold mb-2">Asal Mitra</label>
                    <select class="form-control form-select" v-model="req.partner_id">
                        <option value="">Pilih mitra</option>
                        <template v-for="(mitra, index) in mitra.list_data">
                            <option :value="mitra.id" :key="index">
                                {{ mitra.nama_mitra }}
                            </option>
                        </template>
                    </select>
                </div>
                <div class="col-md-4 mt-4">
                    <label class="mb-2 fw-bold">Foto KTP</label>
                    <div class="image-view-1" :style="{'background-image': 'url(' + (req.ktp) + ')'}">
                        <div class="mt-4 ml-4">
                            <label for="ktp" class="upload-button single-image"> 
                                <i class="fa fa-upload"></i>
                                <input type="file" id="ktp" @change="uploadKTP()">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-4">
                    <label class="mb-2 fw-bold">Foto SIM</label>
                    <div class="image-view-2" :style="{'background-image': 'url(' + (req.sim) + ')'}">
                        <div class="mt-4 ml-4">
                            <label for="sim" class="upload-button single-image"> 
                                <i class="fa fa-upload"></i>
                                <input type="file" id="sim" @change="uploadSIM()">
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <button class="btn btn-green btn-lg px-5 me-3" type="button" disabled v-if="is_save">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Simpan
            </button>
            <button class="btn btn-green btn-lg px-5 me-3" @click="storeData()" v-else>Simpan</button>
            <a href="/trawlcarrier/ho/driver" class="btn btn-outline-black btn-lg px-5 me-3">Kembali</a>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import Api from "../../api/Api";

export default {
    components: {
    },
    name: "TrawlcareerCreateDriver",
    data() {
        return {
            req: {
                ktp: '',
                sim: '',
                avatar: '',
                name: '',
                birth: '',
                no_ktp: '',
                phone: '',
                email: '',
                password: '',
                partner_id: '',
                address: '',
            },
            is_changing_ktp: false,
            is_changing_sim: false,
            is_changing_avatar: false,
            is_save: false,
            mitra: {
                list_data: []
            },
            showPassword: false,
        };
    },
    created() {
        this.getMitra()
    },
    mounted() {
    },
    computed: {
        buttonLabel() {
            return (this.showPassword) ? "Hide" : "Show";
        }
    },
    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        uploadKTP() {
            var ktp = new FileReader();
            ktp.onload = function (e) {
                $('.image-view-1').css('background-image', 'url('+ e.target.result + ')')
            };
            ktp.readAsDataURL($('#ktp')[0].files[0]);
            this.is_changing_ktp = true
        },
        uploadSIM() {
            var sim = new FileReader();
            sim.onload = function (e) {
                $('.image-view-2').css('background-image', 'url('+ e.target.result + ')')
            };
            sim.readAsDataURL($('#sim')[0].files[0]);
            this.is_changing_sim = true
        },
        uploadAvatar() {
            var avatar = new FileReader();
            avatar.onload = function (e) {
                $('.avatar-preview').css('background-image', 'url('+ e.target.result + ')')
            };
            avatar.readAsDataURL($('#avatar')[0].files[0]);
            this.is_changing_avatar = true
        },
        getMitra() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/carrier/ho/partner?per_page=50`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                var data = res.data.data
                this.mitra = data
            })
            .catch((err) => {
                console.log(err);
            });
        },
        storeData(){
            var avatar = $('#avatar')[0].files[0]
            var ktp = $('#ktp')[0].files[0]
            var sim = $('#sim')[0].files[0]
            var data = new FormData()

            data.append('email', this.req.email)
            data.append('password', this.req.password)
            data.append('name', this.req.name)
            data.append('birth', this.req.birth)
            data.append('address', this.req.address)
            data.append('phone', this.req.phone)
            data.append('no_ktp', this.req.no_ktp)
            data.append('partner_id', this.req.partner_id)

            if(this.is_changing_avatar) {
                data.append('avatar',avatar)
            }
            if(this.is_changing_ktp) {
                data.append('photo_ktp',ktp)
            }
            if(this.is_changing_sim) {
                data.append('photo_sim',sim)
            }

            this.is_save = true

            Api.post(`${process.env.VUE_APP_SERVICE_URL}/carrier/ho/driver/create`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                }
            })
            .then((res) => {
                this.is_save = false
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Success',
                    text: 'Data berhasil dibuat'
                });
                this.is_changing_avatar = false
                this.is_changing_ktp = false
                this.is_changing_sim = false
                window.location.href = "/trawlcarrier/ho/driver"
                console.log(res)
            })
            .catch((err) => {
                this.is_save = false
                this.$notify({
                    group: 'foo',
                    type: 'error',
                    title: 'Error',
                    text: err.response.data.message
                });
            })
        }
    },
};
</script>

<style scoped>
    .avatar-preview{
        background: #f8f9fa;
        border: 2px solid #e9ecef;
        box-sizing: border-box;
        border-radius: 100px;
        width: 100px;
        height: 100px;
        background-size: cover;
        background-position: center;
    }
    .upload-button.avatar{
        margin-left: 75px;
        background: #fff;
        border: 1px solid #f8f9fa;
        box-shadow: 0 2px 5px rgb(196 205 213 / 25%);
        color: #dc3545;
        margin-top: -50px;
        padding: 0px;
        width: 33px;
        height: 33px;
        padding: 5px;
    }
</style>